import { FULL_RESET_STORE } from '../actionTypes/actionTypes';
import {ON_GO_TO_CHAT} from '@tuqqi/web'

const initialState = {
    todoTasks: [],
    count: 0,
    updateRequired: false,
};

const myToDoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
                return initialState
        case 'SET_TODO_TASKS': 
            return {
                ...state, 
               todoTasks: action.todoTasks,
               updateRequired: false
            }
        case 'SET_TODO_COUNT': 
            return {
                ...state, 
               count: action.count,
            }
        case 'DELETE_DOCUMENT_REQUEST_SUCCESS': {
            const {result} = action
            if(!result || !result._id) return state
            const docId = result._id
            const exists = state.todoTasks.find(t => !!t.items.find(i => i.docId === docId))
            if(!exists) return state
            return {
                ...state,
                updateRequired: true,
            }
        }
        case 'MOVE_DOCUMENT_BETWEEN_LANES_REQUEST_SUCCESS': {
            const {result} = action
            if(!result || !result.documentId) return state
            return {
                ...state,
                updateRequired: true,
            }
        }
        case 'FINISH_TODO_UPDATE_ITEMS': {
             return {
                ...state,
                updateRequired: false,
            }
        }
        default:
            return state
    }
}

export default myToDoReducer