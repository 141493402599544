import { FULL_RESET_STORE } from "../actionTypes/actionTypes"
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

// allowedOptions = // {id: number, referenceUid: string, customTitle: string, customIcon: string, categoryId: number, type: 0|1|2|3|10, order: number}[]
// viewModal: 'landing' | 'edit' | 'add_item'
const initialState = {
    isFocused: false,
    pageType: 'landing',
    selectedCategory: 0,
    editCategory: 0,
    isAddingCategory: false,
    allowedOptions: [],
    allOptionsAllowed: true
}

export const launchpadNavigationReducer = (state = initialState, action) => {
    switch(action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
        case ON_GO_TO_ITEM_NOTIF:
            return initialState
        case "FOCUS_LAUNCHPAD":
            return { ...state, isFocused: true, pageType: 'landing', selectedCategory: 0, editCategory: 0, isAddingCategory: false }
        case "TOGGLE_LAUNCHPAD":
            return { ...state, isFocused: !state.isFocused, pageType: 'landing',selectedCategory: 0, editCategory: 0, isAddingCategory: false }
        case "BLUR_LAUNCHPAD":
        case "TOGGLE_USER_MODAL":
        case "TOGGLE_CREATENEW_MODAL":
        case "TOGGLE_CHATS_MODAL":
        case "TOGGLE_NOTIFICATIONS_MODAL":
        case 'SEARCH_INPUT_FOCUSED': 
            return { ...state, isFocused: false, pageType: 'landing', selectedCategory: 0, editCategory: 0, isAddingCategory: false }
        case "IS_ADDING_CATEGORY":
            return { ...state, isAddingCategory: true, }
        case "IS_EDIT_NAME_CATEGORY":
            return { ...state, editCategory: action.editCategory, }
        case "IS_NOT_ADDING_CATEGORY":
            return { ...state, isAddingCategory: false, }
        case "SET_LAUNCHPAD_OPTIONS":
            return { ...state, allowedOptions: action.allowedOptions.sort((a, b) => a.order - b.order) }
        case "SET_LAUNCHPAD_PAGE_TYPE":
            return { ...state, pageType: action.pageType, selectedCategory: action.pageType === 'add_item' ? action.selectedCategory : 0, isAddingCategory: false, editCategory: 0, }
        case 'ADD_LAUNCHPAD_OPTION': {
                const {item} = action
                const newItems = [...state.allowedOptions, item].sort((a, b) => a.order - b.order)
                return {
                    ...state,
                    allowedOptions: newItems
                }
            }
            case 'UPDATE_LAUNCHPAD_OPTION': {
                const {item} = action
                const newItems = [
                    ...state.allowedOptions.filter((it) => it.id !== item.id), 
                    item
                ].sort((a, b) => a.order - b.order)
                return {
                    ...state,
                    allowedOptions: newItems
                }
            }
            case 'DELETE_LAUNCHPAD_OPTION': {
                const {id} = action
                const newItems = state.allowedOptions.filter((item) => item.id !== id)
                return {
                    ...state,
                    allowedOptions: newItems
                }
            }
        default:
            return state
    }
}