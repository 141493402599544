import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from './withErrorCatcher';

const maxSize = 2

const MemberImageSize = 24
const MembersMarginBottom = 2
const MembersMarginRight = 2

export const MembersContainerMarginTop = 8
export const MEMBERS_ROW_HEIGHT = MemberImageSize + MembersMarginBottom
export const MEMBER_WIDTH = MemberImageSize + MembersMarginRight

const styles = theme => ({
    container: {
        marginTop: MembersContainerMarginTop,
        display: 'inline-flex',
   
    },
    avatarContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: MemberImageSize,
        height: MemberImageSize,
        border: `solid 1px #ffffff`,
        borderRadius: '50%',
        boxSizing: "border-box",
        marginLeft: -8,
        '&:first-child': {
            marginLeft: 0,
        },
    },
    avatar: {
        width: '100%',
        height: '100%',
        display: 'block',
    },

    plusAvatarsContainer: {
            width: MemberImageSize -1,
            height: MemberImageSize -1,
            cursor: 'pointer',
            border: 'solid 1px #fff',
            marginLeft: -8,
            zIndex: 2,
            borderRadius: '100%',
            background: '#daddee',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            color: theme.customColors.primary.main,
            fontWeight: 500,
            fontSize: 16,
            fontFamily: 'Rubik',
    },
})

class TableDocMembers extends PureComponent {

    members = () => {
        const { docMembers, users } = this.props

        const members = []

        docMembers.forEach(member => {
            const user = users.find(u => u.auth0_id == member.userUUID)
            if (user) {
                members.push({
                    userName: member.userName,
                    profileImageUrl: user.profileImageUrl
                })
            }
        })

        return members

    }

    render() {
        const { classes, docMembers, users, } = this.props

        let members = docMembers.map(member => {
            const user = users.find(u => u.auth0_id === member.userUUID)
            return (
                <span className={classes.avatarContainer}>
                <img key={member.userUUID} key={member.userUUID}  className={classes.avatar} src={user.profileImageUrl} alt={member.userName} style={{}} />
            </span>
            )
        })

        if (maxSize) {
            members = docMembers.slice(0, maxSize).map(member => {
                const user = users.find(u => u.auth0_id === member.userUUID)
                return (
                <span className={classes.avatarContainer}>
                    <img key={member.userUUID} key={member.userUUID} className={classes.avatar} src={user.profileImageUrl} alt={member.userName} style={{}} />
                 </span>
                )
            })

        }

        return (
            <Grid className={classes.container}>
                {members}
                {maxSize && docMembers.length - maxSize > 0 ?
                    <span className={classes.plusAvatarsContainer}>
                        <Typography style={{  marginRight: 2, fontSize: 10, color: '#8645F9' }}>{'+'+(docMembers.length - maxSize)}</Typography>
                    </span>
                    : null}
            </Grid>
        )
    }
}


const mapStateToProps = (state) => ({
    users: state.globalData.users
})

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        null
    ))(TableDocMembers);