import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CodeInput from './ItemEditViews/code/codeInput';

// Apply some reset
const styles = theme => ({
    preview: {
        paddingRight: 30, paddingLeft: 30
    },
    progress: {
        margin: 'auto',
        marginBottom: 16,
        display: 'inherit'
    }
});

class ItemFullViewContent extends Component {
    showFullViewContent = () => {
        const { item } = this.props;

        // Check if custom mapping exists
        

        switch (item.dataType) {
            case 'code':
                return <CodeInput /> // TODO

        }




    }

    checkCustomInputs = (dataType) => {
        const mapping = this.props.mappings.find(mapping => mapping.customDataMapping.dataTypeFilter == dataType)

        return mapping;
    }

    render() {
        const { classes, isLoaded, isNew } = this.props;

        return <div data-intrcm-id="itemFullViewEdit_m5boqv0i" className={classes.preview}>
            {isLoaded || isNew ? this.showFullViewContent() : <CircularProgress className={classes.progress} />}
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    searchQuery: state.search,
    view: state.view,
    mappings: state.globalData.inputMappings,
    ...state.fullView,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemFullViewContent);