import React from 'react';
import { compose } from 'recompose'
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import Header from '../../components/App/PageComponents/pageContentHeader';
import { AddCircle } from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import SingleColumnView from '../../components/App/singleColumnView.js';
import AppContent from '../../components/App/appContent.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  paper: {
    padding: 30
  },
  divid: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  buttonIc: {
    marginTop: '8px',
    fontSize: '14px'
  },
  headerIcon:{
    height: 48, width: 48
  }
})

class AdminEditContainer extends React.Component {

  render() {
    const { classes, children, save, title, subtitle, description,t, isNew } = this.props;


    return (
      <AppContent >
        <SingleColumnView isFull>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={isNew ? 0 : 16} justify="space-between">
              <Grid item >
                <Header classes={classes} title={title} subtitle={subtitle} description={description}
                  avatar={<ListItemIcon>
                    <AddCircle className={classes.headerIcon}/>
                  </ListItemIcon>} />
              </Grid>
              <Grid>
                <Button variant="raised" onClick={save} className={classes.buttonIc}>{t('Save changes')}</Button>
              </Grid>
            </Grid>
            <Divider className={classes.divid} />
            {children}
          </Paper>
        </SingleColumnView>
      </AppContent>)
  }

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
    goTo: path => { dispatch(push(path)) }
  }
}

export default compose(withStyles(styles),
  withErrorCatcher(),
  withTranslation('admin'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(AdminEditContainer);