import React, { useCallback, useEffect, useState } from 'react'
import {Paper, withStyles, Drawer, Button } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import MenuNavigation from './Navigation/MenuNavigation'
import StoriesContentView from './View/StoriesContentView'
import IconRightMinimize from '../Common/Icons/IconRightMinimize';
import IconCircleArrow from '../Common/Icons/IconCircleArrow'
import withErrorCatcher from '../Common/withErrorCatcher'

const styles = (theme) => ({
    container: {
        zIndex: '1202!important',
        position: 'absolute',
        width: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        right: 0,
        bottom: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'transparent!important',
    },
    containerDrawer: {
        width: 378,
        backgroundColor: 'transparent!important',
        border: 0,
    },
    containerDrawerSmall: {
        width: 95,
        border: 0,
    },
    containerDark: {
        zIndex: '1201!important',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        '-webkit-backdrop-filter': 'blur(2px)',
        'backdrop-filter': 'blur(2px)',
        'background-color': 'rgba(0, 0, 0, 0.6)'
    },
    hidden: {
        zIndex: -1,
        display: 'none',
    },
    iconDrawerLeftHolder: {
        width: 34,
        height: 34,
        backgroundColor: '#fff',
        borderRadius: '50%',
        overflow: 'hidden'
    },
    icon: {
        color: '#979aaa',
        fontSize: 35,
        cursor: 'pointer'
    },
    drawerHandlerOpen: {
        position: 'absolute',
        top: 61,
        left: 338,
    },
    drawerHandlerHide: {
        position: 'absolute',
        top: 22,
        left: 41
    },
    seeItemButton: {
        
    }
})

const StoriesWindow = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [menuNavigationWidth, setMenuNavigationWidth] = useState(358)
    const [storiesContentWidth, setStoriesContentWidth] = useState(window.innerWidth - 358)

    const {classes, showStoriesWindow} = props

    const updateWindowWidth = useCallback(() => {
        setWindowWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth()
        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }
    }, [updateWindowWidth])

    useEffect(() => {
        setStoriesContentWidth(windowWidth - menuNavigationWidth)
    }, [menuNavigationWidth, windowWidth])

    useEffect(() => {
        if(isDrawerOpen) {
            setMenuNavigationWidth(358)
        } else {
            setMenuNavigationWidth(95)
        }
    }, [isDrawerOpen])

    useEffect(() => {
        if(showStoriesWindow) {
            document.body.style.overflow = 'hidden'
            return
        }
        document.body.style.overflow = 'auto'
    }, [showStoriesWindow])

    const drawerHandler = () => setIsDrawerOpen(!isDrawerOpen)

    const wrapperClassname = [classes.containerDark, !showStoriesWindow ? classes.hidden : ''].join(" ");
    if(!showStoriesWindow) return null
    return <div data-intrcm-id="StoriesWindow_ik3gn1r8" className={wrapperClassname}>
            <Paper className={classes.container} >
                <Drawer variant="persistent" open={true} PaperProps={{width: menuNavigationWidth}} classes={{paper: isDrawerOpen ? classes.containerDrawer : classes.containerDrawerSmall}}>
                    <div data-intrcm-id="StoriesWindow_1jtj2s1h" className={isDrawerOpen ? classes.drawerHandlerOpen : classes.drawerHandlerHide} onClick={drawerHandler}>
                        {isDrawerOpen ? <div data-intrcm-id="StoriesWindow_of114owh" className={classes.iconDrawerLeftHolder}><IconCircleArrow  className={classes.icon}/></div> : <IconRightMinimize className={classes.icon}/>} 
                    </div>
                    <MenuNavigation width={menuNavigationWidth} isDrawerOpen={isDrawerOpen}/>
                </Drawer>

                <StoriesContentView width={storiesContentWidth} />
            </Paper>
        </div>

}

const mapStateToProps = (state) => ({
    showStoriesWindow: state.data.storiesNavigation.open,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoriesWindow)