import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { APPLY_LOCAL_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../../actionTypes/actionTypes';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import MenuButton from '../MenuButton';
import MenuTableToolbar from './MenuTableToolbar';

const DataTypeSelectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="DataTypeSelectionIcon__a" d="M.71 1.71L3.3 4.3c.39.39 1.02.39 1.41 0L7.3 1.71C7.93 1.08 7.48 0 6.59 0H1.41C.52 0 .08 1.08.71 1.71z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(8 10)">
            <mask id="DataTypeSelectionIcon__b" fill="#fff">
                <use href="#DataTypeSelectionIcon__a" />
            </mask>
            <g fill="#21212D" mask="url(#DataTypeSelectionIcon__b)">
                <path d="M0 0H24V24H0z" transform="translate(-8 -10)" />
            </g>
        </g>
    </svg>
)
const styles = theme => ({
    menuTitle: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        marginRight: 8,
        "color": theme.newUI.color.gray
    },
    selectedDataType: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        "color": theme.newUI.color.commonBlack
    },
    button: {
        width: 301,
        marginLeft: 16

    },
    menu: {
        width: 301
    }
});

const mustReadQuery = {
    query: '',
    isUnseen: false,
    tags: [],
    users: [],
    dataTypes: [],
    labels: [],
    colors: [],
    groups: [],
    dateFrom: null,
    dateTo: null,
    isAuthor: false,
    page: 0,
    isMyItem: false,
    isTrending: false,
    hasLocation: false,
    sortField: '',
    sortOrder: null,
    sortType: null,
    confirmed: false,
    mustread: true,
}

const confirmedQuery = {
    query: '',
    isUnseen: false,
    tags: [],
    users: [],
    dataTypes: [],
    labels: [],
    colors: [],
    groups: [],
    dateFrom: null,
    dateTo: null,
    isAuthor: false,
    page: 0,
    isMyItem: false,
    isTrending: false,
    hasLocation: false,
    sortField: '',
    sortOrder: null,
    sortType: null,
    confirmed: true,
    mustread: false,
}

const myRequests = {
    query: '',
    isUnseen: false,
    tags: [],
    users: [],
    dataTypes: [],
    labels: [],
    colors: [],
    groups: [],
    dateFrom: null,
    dateTo: null,
    isAuthor: false,
    page: 0,
    isMyItem: false,
    isTrending: false,
    hasLocation: false,
    sortField: '',
    sortOrder: null,
    sortType: null,
    confirmed: false,
    mustread: false,
    mustReadMyRequests: true,
}

class SavedReportSelection extends Component {
    static defaultProps = {
        mode: 'default', // 'default' | 'navigation',
        onSelect: undefined, // undefined | cb
        onInit: undefined // undefined | cb
    }
    defaultDataType = 'All'

    get defaultOption() {
        const {mode, t} = this.props
        return {
            selected: true,
            key: 0,
            label: mode === 'navigation' ? t("Select saved filter") : t("My Report")
        }
    }

    state = {
        selectedReport: {

        },
        selectedReportId: 0,
        originalSearchQuery: {}
    }

    setFilterSelectedFields = (fields) => {
        this.props.dispatch({
            type: 'SET_SELECTED_FIELD_SOURCES',
            selectedFieldSources: fields
        })
    }

    onChoose = (selectedReport, allowCb = false) => {
        const { dispatch, savedReports, mixpanelTrack, onSelect, mode, searchQuery: {query: queryRequest} } = this.props

        if(mode === 'navigation' && !allowCb) {
            this.setState({ selectedReport: selectedReport, selectedReportId: selectedReport.key })
            return
        }
        if (selectedReport.key === this.defaultOption.key) {
            dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...this.state.originalSearchQuery, page: 0, query: queryRequest } })
            this.setFilterSelectedFields([])
        } else if (selectedReport.key === 1) {
            dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...this.state.originalSearchQuery, ...mustReadQuery, page: 0, query: queryRequest } })
            this.setFilterSelectedFields([])
        } else if (selectedReport.key === 2) {
            dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...this.state.originalSearchQuery, ...confirmedQuery, page: 0, query: queryRequest } })
            this.setFilterSelectedFields([])
        } else if (selectedReport.key === 3) {
            dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...this.state.originalSearchQuery, ...myRequests, page: 0, query: queryRequest } })
            this.setFilterSelectedFields([])
        } else {
            const report = savedReports.filter(x => x.id === selectedReport.key)
            const reportQuery = JSON.parse(report[0].reportJSON);
            let query = {}
            let filters = {}

            if('selectedFieldSources' in reportQuery) {
                this.setFilterSelectedFields(reportQuery.selectedFieldSources)
                filters = reportQuery?.filters ?? {}
                query = {...reportQuery.query}
            } else {
                this.setFilterSelectedFields([])
                filters = reportQuery?.filters ?? {}
                query = {...reportQuery}
            }
            if (query) {
                dispatch({ type: APPLY_LOCAL_FILTER, filter: filters })
                dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...query, page: 0, query: 'query' in query ? query.query : queryRequest } })
            }
        }

        dispatch({ type: DATA_UPDATE });
        if(selectedReport.key > 3){
            mixpanelTrack('Load saved report', {});
        } else{
            mixpanelTrack('Load preconfigured report', {});
        }
        
        mode === 'navigation' && allowCb && onSelect?.(selectedReport)
        this.props.dispatch({
            type: 'SET_CURRENT_SAVED_REPORT',
            currentSavedReport: selectedReport
        })
    }

    buildOptions() {
        const { t, savedReports } = this.props;

        // const selectedDataType = this.getSelectedDataType()

        const options = savedReports.map(report => ({
            selected: report.id === this.state.selectedReportId,
            key: report.id,
            label: report.reportName,
        }))

        options.unshift({
            selected: this.state.selectedReportId === 2,
            key: 2,
            label: t("Signed items")
        })
        options.unshift({
            selected: this.state.selectedReportId === 1,
            key: 1,
            label: t("Pending - Read & Confirm")
        });
        options.unshift({
            selected: this.state.selectedReportId === 3,
            key: 3,            
            label: t("Read & Confirm - My Requests")
        });
        options.unshift(this.defaultOption);
       

       

        return options.map(o => ({...o, selected: o.key === this.defaultOption.key}))
    }


    getSelectedDataType() {
        const { selectedDataTypes } = this.props;

        const dataTypeSelected = selectedDataTypes.length === 1 ? selectedDataTypes[0] : this.defaultDataType

        return dataTypeSelected
    }

    checkQueryStringParam = () => {
        var url = new URL(window.location);
        var searchParams = new URLSearchParams(url.search);
        if (searchParams.has("reportId")) {
            return searchParams.get("reportId")
        }

        return this.defaultOption.key
    }

    getIdByLabel = (label, options) => {
        return options.find(o => o.label === label)?.key ?? this.defaultOption.key
    }

    onInit = (selectedReport) => {
        const {t, onInit, savedReports, mode} = this.props
        if(!onInit) return;

        if(mode === 'navigation' && selectedReport.label === this.defaultOption.label) {
            onInit({}, true)
            return
        }
        if(selectedReport.label === t("Signed items")) {
            onInit({ ...this.state.originalSearchQuery, ...confirmedQuery })
            return
        }
        if(selectedReport.label === t("Pending - Read & Confirm")) {
            onInit({ ...this.state.originalSearchQuery, ...mustReadQuery })
            return
        }
        if(selectedReport.label === t("Read & Confirm - My Requests")) {
            onInit({ ...this.state.originalSearchQuery, ...myRequests })
            return
        }
        if(selectedReport.label === t("My Report")) {
            onInit({ ...this.state.originalSearchQuery})
            return
        }
        const report = savedReports.find(r => r.reportName === selectedReport.label)
        if(!report) return
        try {
            onInit({ ...JSON.parse(report.reportJSON) })
        } catch (error) {
            console.log({error});
            return   
        }
    }

    initReports = (mounted = true)=>{
        const { searchQuery: {selectedReportName}, mode } = this.props;

        const options = this.buildOptions();
        const labels = options.map(o => o.label)

        let reportIdFromQueryString = this.checkQueryStringParam();
        if(reportIdFromQueryString === this.defaultOption.key) {
            if(mode === 'navigation' && !!selectedReportName && labels.includes(selectedReportName)) (reportIdFromQueryString = this.getIdByLabel(selectedReportName, options))
            else (reportIdFromQueryString = this.defaultOption.key)
        }

        const selectedReport = options.find(x => x.key.toString() === reportIdFromQueryString.toString())
        this.setState({
            originalSearchQuery: this.props.searchQuery,            
            options: options
        })
        mode === 'navigation' && !mounted && this.onInit(selectedReport)
        this.onChoose(selectedReport)
    }

    listenCleanSelected = (prevProps) => {
        const {searchQuery: {selectedReportName}} = prevProps
        const {t, mode, searchQuery: {selectedReportName: nextSelectedReportName}} = this.props
        if(selectedReportName === nextSelectedReportName || mode !== 'navigation' || nextSelectedReportName !== '') return
        
        this.setState({selectedReportId: -1, selectedReport: this.defaultOption})
    }

    listenCurrent = (prevProps) => {
        const {currentSavedReport: prevCurrentSavedReport} = prevProps
        const {currentSavedReport} = this.props
        if(JSON.stringify(currentSavedReport) === JSON.stringify(prevCurrentSavedReport)) return
        if(!!prevCurrentSavedReport && !currentSavedReport) {
            this.setState({selectedReportId: -1, selectedReport: this.defaultOption})
            return
        }
        if(!prevCurrentSavedReport && !!currentSavedReport) {
            this.setState({selectedReportId: currentSavedReport.key, selectedReport: currentSavedReport})
            return
        }
        if(!!prevCurrentSavedReport && !!currentSavedReport && prevCurrentSavedReport.key !== currentSavedReport.key) {
            this.setState({selectedReportId: currentSavedReport.key, selectedReport: currentSavedReport})
            return
        }
    }

    componentDidUpdate(prevProps){
        this.listenCurrent(prevProps)
        if(prevProps.savedReports.length !== this.props.savedReports.length){
            this.initReports()
        }
        
    }

    componentDidMount() {      
        this.initReports(false)
        this.listenCurrent({currentSavedReport: null})
    }

    render() {
        const { classes, t } = this.props;
        const { selectedReport, options } = this.state;

        return (
            <MenuTableToolbar
                title={t('Select report')}
                classes={{ menu: classes.menu }}
                button={<MenuButton
                    classes={{ button: classes.button }}
                    menuTitle={``}
                    selectedValue={selectedReport ? t(selectedReport.label) : t("Cancel")}
                />}
                options={options}
                onChoose={(report) => this.onChoose(report, true)}
            />
        )
    }

}

const mapStateToProps = (state, ownProps) => ({

    selectedDataTypes: state.search.dataTypes,
    optionalDataTypes: state.results.dataTypes,
    inputMappings: state.globalData.inputMappings,
    searchQuery: state.search,
    currentSavedReport: state.localFilter.currentSavedReport
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('myReportsView'),
    withErrorCatcher(),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(SavedReportSelection);