import { getStringAsNum } from "@tuqqi/common"
import i18n from "../../../../../i18n"
import { getBusinessIdFielName, withBusinessId } from "../../../../../utils/withBusinessId"

const getContactFields = (t) => [
    { fieldName: t("Company"), sourceField: 'company', fieldIcon: 'business', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Job title"), sourceField: 'jobtitle', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Phone"), sourceField: 'phone', fieldIcon: 'local_phone', type: 'phone', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Email"), sourceField: 'email', fieldIcon: 'mail', type: 'email', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Address"), sourceField: 'address', fieldIcon: 'place', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Birthday"), sourceField: 'birthday', fieldIcon: 'cake', type: 'date', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Website url"), sourceField: 'websiteurl', fieldIcon: 'link', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Linkedin url"), sourceField: 'linkedinurl', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Facebook url"), sourceField: 'facebookurl', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Additional information"), sourceField: 'additional', fieldIcon: 'edit', type: 'paragraph', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
]

const getCompanyFields = (t) => [
    { fieldName: t("Id"), sourceField: 'id', fieldIcon: '', type: "text", toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Phone"), sourceField: 'phone', fieldIcon: 'local_phone', type: 'phone', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Email"), sourceField: 'email', fieldIcon: 'mail', type: 'email', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Address"), sourceField: 'address', fieldIcon: 'place', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Founding year"), sourceField: 'foundingyear', fieldIcon: 'cake', type: 'number', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Website url"), sourceField: 'websiteurl', fieldIcon: 'link', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Industry"), sourceField: 'industry', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("CEO"), sourceField: 'ceo', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Number of employees"), sourceField: 'numberOfEmployees', fieldIcon: '', type: 'number', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Linkedin url"), sourceField: 'linkedinurl', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Facebook url"), sourceField: 'facebookurl', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Type"), sourceField: 'type', fieldIcon: '', type: 'text', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
    { fieldName: t("Additional information"), sourceField: 'additional', fieldIcon: 'edit', type: 'paragraph', toAggregate: false, fieldDescription: '', unit: '', aggregateField: '', options: null, dataType: null },
]

    const findInputMappingByDataType = (selectedDataType, inputMappings) => {
        return inputMappings.find(inputMapping =>
            inputMapping.customDataMapping.dataTypeFilter === selectedDataType)
    }

    const getJsonData = (data) => {
        if (data.parsedData) {
            return data.parsedData
        }

        let parsedData = {}
        try {
            parsedData = data.jsonData && JSON.parse(data.jsonData)

            data.parsedData = parsedData
        } catch (error) {

        }
        return parsedData
    }

    export const getOptionsForInputMapping = (selectedDataType, inputMappings) => {
        const t = i18n.getFixedT(i18n.language, 'myReportsView')
        let inputMapping = findInputMappingByDataType(selectedDataType, inputMappings)

        if (selectedDataType === 'contact' || selectedDataType === 'company') {
            inputMapping = {
                customDataMapping: {
                    customFieldsMappings: selectedDataType === 'contact' ? getContactFields(t) : getCompanyFields(t)
                }
            }
        }
        if (!inputMapping) {
            return []
        }

        let allowedFieldTypes = ['title', 'text', 'paragraph', 'select', 'multiSelect', 'date', 'dateTime', 'number', 'location', 'checkbox', 'phone', 'email', 'calculation']

        const fields = inputMapping.customDataMapping.customFieldsMappings
            .filter(field => allowedFieldTypes.includes(field.type))
            .map(field => ({
                ...field,
                // type: field.sourceField,
                extractData: (row) => {
                    const value = getJsonData(row)[field.sourceField]
                    if (field.type === 'calculation') {
                        const val = !value || value === 'error' ? '' : value
                        return getStringAsNum(val)
                    }
                    if (field.type === 'number') {
                        // return number
                        if (value) {
                            return getStringAsNum(value)
                        } else {
                            return 0
                        }

                    }
                    return value
                }
            }))

        if (inputMapping.isEnableLinks) {
            fields.push({
                fieldName: 'Link',
                sourceField: 'links',
                type: 'links',
                extractData: (row) => row.links

            })
        }

        if (withBusinessId(inputMapping)) {
            fields.push({
                fieldName: getBusinessIdFielName(inputMapping),
                sourceField: 'businessId',
                type: 'businessId',
                extractData: (row) => row.businessId ?? ''

            })
        }

        return fields.map(field => ({
            ...field,
            label: t(field.fieldName),
            key: field.fieldName,
            selected: true
        }))
    }