import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
// import Header from '../Header/header'
import { withStyles } from '@material-ui/core';
import HeaderMobile from '../header/headerMobile';
// Drawers
// import Drawers from '../App/Drawers/drawers'
// import InputFAB from './inputFAB'
// import SnackBar from '../../../components/Common/snackBar/snackBar'
// import NoMobileSupport from './components/messagePages/noMobileSupport';


//const animationName = 'rag-fadeIn'
const styles = theme => ({

    root: {
        // display: 'flex',
        alignItems: 'stretch',
        minHeight: '100vh',
        width: '100%',
    },
    content: {
        paddingTop: 64,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    },

});

class MobileWrapper extends Component {

    render() {
        const { children, classes, location } = this.props;
        return (
            <div data-intrcm-id="mobileWrapper_355w8mj3">
                <HeaderMobile location={location} />
                <div data-intrcm-id="mobileWrapper_j4he6s6r" className={classes.content}>
                    {children}
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(MobileWrapper);
