import { Grid, withStyles, Tooltip, Typography, Divider } from '@material-ui/core';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import WidgetContainer from './WidgetContainer.js';
import parseImageUrl from '../../../../components/Common/parseImagUrl';
import { FULLVIEW_OPEN_LOAD } from '../../../../../actionTypes/actionTypes.js';
import { getSearchQueryItemsByDataType } from '../../groupUtils.js';
import AddItem from './AddItem.js';
import { withTranslation } from 'react-i18next';
import TrendingWidgetPlaceholder from '../../../../components/Placeholders/TrendingWidgetPlaceholder.js';


const styles = theme => ({
    container: {
        "borderRadius": "8px",
        "backgroundColor": "#f3f6fb",

        position: 'sticky',
        top: 100
    },
    title: {
        padding: 16,
        "fontFamily": "Rubik",
        "fontSize": "20px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "normal",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    itemContainer: {
        padding: '0px 16px 24px 16px',

    },
    tagHeader: {
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        "color": theme.newUI.color.gray,
        textAlign: 'left'
    },
    tagName: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        "color": theme.newUI.color.commonBlack
    },
    tagNumItems: {
        "width": "74px",
        "height": "20px",
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.gray,
        textAlign: 'left'
    },
    divider: {
        marginBottom: 16,
        opacity: 0.2,
        backgroundColor: theme.newUI.color.commonBlack
    }
});



class TrendingWidget extends React.PureComponent {


    render() {
        const { classes, relatedTags, collectionTitle, t } = this.props;


        // if (!relatedTags || relatedTags && relatedTags.length < 4) {
        //     return <TrendingWidgetPlaceholder />
        // }

        let contentOld = <Grid container item className={classes.container} direction='column'>
            <Typography className={classes.title}>{t('TrendingIn', { collectionTitle })}</Typography>
            <Divider className={classes.divider} />
            {
                relatedTags.slice(0, 4).map((relatedTag, idx) => (
                    <Grid key={relatedTag.Key} container item className={classes.itemContainer} direction='column'>
                        <span className={classes.tagHeader}>{idx + 1} · {t('Trending')}</span>
                        <span className={classes.tagName}>#{relatedTag.Key}</span>
                        <span dir='auto' className={classes.tagNumItems}>{t('totalItems', { total: relatedTag.DocCount })}</span>

                    </Grid>
                ))
            }
        </Grid>

        let content = relatedTags.slice(0, 4).map((relatedTag, idx) => (
            <Grid key={relatedTag.Key} container item className={classes.itemContainer} direction='column'>
                <span className={classes.tagHeader}>{idx + 1} · {t('Trending')}</span>
                <span className={classes.tagName}>#{relatedTag.Key}</span>
                <span dir='auto' className={classes.tagNumItems}>{t('totalItems', { total: relatedTag.DocCount })}</span>

            </Grid>
        ))

        if (!relatedTags || relatedTags && relatedTags.length < 4) {
            content = <TrendingWidgetPlaceholder />
        }
        return (<WidgetContainer hideSeeAll title={t('TrendingIn', { collectionTitle })} isLoading={false} dataType='media'>
            {content}
        </WidgetContainer>)
    }

}

const mapStateToProps = (state, ownProps) => ({
    collectionTitle: state.results.channel.title
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('groupWidgetTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TrendingWidget);