import React from 'react' 
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { BuildFormula } from './BuildFormula'
import { SelectUnit } from './SelectUnit'

const styles = (theme) => ({

})

const __CalculationField = (props) => {
    const {options, isNewField, numberFields} = props
    return <>
        <BuildFormula options={options} isNewField={isNewField} numberFields={numberFields} />
        <SelectUnit options={options} isNewField={isNewField} />
    </>
}

export const CalculationField = compose(
    withStyles(styles, {withTheme: true})
)(__CalculationField)