import { LOCATION_CHANGE } from 'react-router-redux'
import { DRAWER_TOGGLE, FULL_RESET_STORE, USE_FULL_SCREEN } from '../actionTypes/actionTypes'

const initialState = {
  userSettingsOpen: false,
  moreInputsOpen: false,
  aboutTuqqiOpen: false,
  scoreboardOpen: false,
  favoritesOpen: false,
  trendsOpen: false,
  sourcesOpen: false,
  TagListOpen: false,
  notificationsOpen: false,
  suspendedError: false,
  categoryListState: undefined
}

const isMainFeedView = (pathname) => {
  const feedRules = [pathname === '', pathname === '/', pathname === '/feed']
  return feedRules.some(val => !!val)
}

const isFeedView = (pathname) => {
    const feedRules = [isMainFeedView(pathname), pathname === '/mypage', pathname === '/tag', pathname === "user/overview", pathname.includes("/search"), pathname.includes('/feed'), pathname.includes("/group/knowledge")]
    return feedRules.some(val => !!val)
}

const wrapper = (state = initialState, action) => {
  switch (action.type) {

    case FULL_RESET_STORE: {
      return initialState
    }
    case "SET_SUSPENDED_ERROR": {
      return {...state, suspendedError: true}
    }
    case "SET_CATEGORYLIST_STATE": {
      return {...state, categoryListState: action.categoryListState}
    }
    case "CLEAR_SUSPENDED_ERROR": {
      return {...state, suspendedError: false}
    }

    case DRAWER_TOGGLE:
      let all = {...initialState}

      Object.assign(all, action.toggled)

      return {
        ...state,
        ...all
      }
    case LOCATION_CHANGE:

      const pathname = action.payload.pathname

      let canOpenMenu = false;
      let fullViewWithDrawer = false;
      let leftWidgetDrawer = true;
      let showFullSreen = false
      let isFeed = false
      let showFullHightSreen = false

      if (isFeedView(pathname)) {
        isFeed = true
      }
      if (isMainFeedView(pathname)) {
        canOpenMenu = true
      }
      if (pathname.includes("orgchart")) {
        showFullSreen = true;
        showFullHightSreen = true

      }

      if (// check if pathname is library and not contain other path 
        pathname.replace(/library|\//gi, '') === '' ||

        pathname.includes("library")
      ) {
        //check it
        canOpenMenu = true
      }

      if (pathname.includes("kanban") ||
        pathname.includes("favorites")) {
        fullViewWithDrawer = true
      }
      if (pathname.includes("library")) {
        fullViewWithDrawer = false
        showFullSreen = false;
        canOpenMenu = false;
      }

      if (pathname.includes("topicresults") ||
        pathname.includes("group") ||
        pathname.includes("creategroup") ||
        pathname.includes('mypage') ||
        pathname.includes("settings")) {
        fullViewWithDrawer = false;
        leftWidgetDrawer = false;
        canOpenMenu = false;
      }

      if (pathname.includes("group") || pathname.includes('mypage')) {
        showFullSreen = true;
        fullViewWithDrawer = false;
        leftWidgetDrawer = false;
        canOpenMenu = false;
      }
      // if (pathname.includes("favourites") ||
      //   pathname.includes('tasks')) {
      //   fullViewWithDrawer = true;
      // }
      if(pathname.includes('/mypage/ongoing')) {
        showFullSreen = true;
        fullViewWithDrawer = false;
      }
      if(pathname.includes('/user/ongoing')) {
        showFullSreen = true;
        fullViewWithDrawer = false;
      }
      if(pathname.includes("orgcalendarview")) {
        showFullSreen = true;
        fullViewWithDrawer = false;
      }
      return {
        ...state,
        canOpenMenu,
        isFeed,
        fullViewWithDrawer,
        leftWidgetDrawer,
        pathname,
        showFullSreen,
        showFullHightSreen
      }

    case USE_FULL_SCREEN:
      showFullSreen = true;
      fullViewWithDrawer = false;
      leftWidgetDrawer = false;
      canOpenMenu = false;
      return {
        ...state,
        showFullSreen,
        fullViewWithDrawer,
        leftWidgetDrawer,
        canOpenMenu
      }

    default:
      return state
  }
}

export default wrapper