import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Clear from '@material-ui/icons/Clear';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../../actions/appActions';
import HelpButtonTooltip from '../../../components/Common/HelpButton/HelpButtonTooltip.js';
import '../../../components/Header/styles/themeAutoSuggest.scss';
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';
import { withMixpanel } from '../../../components/Common/withMixpanel';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import { useTranslation, withTranslation } from 'react-i18next';

registerPlugin(FilePondPluginFileRename)

function renderSuggestion(suggestion, { query, isHighlighted }) {
    var avatar = <div data-intrcm-id="topicsSettings_8ioval0j" />
    if (typeof suggestion.avatar !== 'undefined') {
        if (suggestion.avatar !== '') {
            avatar = <Avatar src={suggestion.avatar} />
        }
    }

    var valueForSuggest = query;
    var vals = query.split(" ");

    if (vals.length > 1) {
        valueForSuggest = vals[vals.length - 1];
    }

    let prefix = valueForSuggest[0] === '@' ? '@' : '#';
    var suggestionText = suggestion.value;
    if (suggestion.tag) {
        suggestionText = suggestion.tag
    }

    // if (suggestionText != null) {
    const matches = match(prefix + suggestionText, valueForSuggest);
    const parts = parse(prefix + suggestionText, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            {avatar}
            <div data-intrcm-id="topicsSettings_gzjbmj8e" style={{ paddingLeft: 10 }}>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    ) : (
                            <span key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </span>
                        );
                })}
            </div>
        </MenuItem>
    );
}

function renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref,t, ...other } = inputProps;

    return (
        <TextField
            label={t("Enter a tag then press enter")}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

// Apply some reset
const styles = theme => ({
    root: {
        height: '80%',
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paper: {
        padding: 16
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paperStyle: {
        marginBottom: 40
    },
    newButton: {
        margin: '8px 22px 8px'
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
    inputContainer: {
        // width: '100%',
        flex: '1 2 calc(100% - 110px)',
        display: 'flex',
        flexFlow: 'nowrap',
        alignItems: 'center',
    },
    filePond: {
        marginTop: 10,
        marginBottom: -15
    }
});

class TopicsSettings extends Component {
    state = {
        suggestionSelected: false,
        isLoading: true,
        topic_tag: '',
        topic_description: '',
        topic_background: '',
        topic_name: '',
        topic_icon: '',
        open: false,
        chipData: [],
        suggestions: [],
        options: [""],
    };

    handleClickSave = () => {
        const { adminService, dispatch, mixpanelTrack } = this.props;
        const { topic_name, topic_description, topic_background, topic_icon, chipData } = this.state;

        var topic = {
            Tag: topic_name.replace(/[ ]/g, "_"),
            Description: topic_description,
            TopicBackgroungUrl: topic_background,
            TopicTags: chipData,
            TopicIcon: topic_icon
        }

        adminService.addTopic(topic).then(() => {

            mixpanelTrack('Create topic', {})

            setTimeout(() => {
                this.getAll();
                dispatch(showSnackbar('Saved!'));
            }, 1000);
        })

        dispatch(showSnackbar('Saving...'));
        this.handleClose();
    }

    handleClose = () => {
        this.setState({
            open: false, topic: null, chipData: [], topic_description: '',
            topic_name: '', topic_tag: '', topic_icon: ''
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        var valueForSuggest = value;
        var vals = value.split(" ");
        if (vals.length > 1) {
            valueForSuggest = vals[vals.length - 1];
        }

        if (valueForSuggest.charAt(0) === '@' && valueForSuggest.length > 1) {
            this.setState({
                suggestions: this.state.userMentions.filter(x => x.displayName.includes(valueForSuggest.slice(1)))
            });
        }
        else {
            this.getSuggestions('#' + valueForSuggest).then((values) => {
                this.setState({
                    suggestions: values
                });
            })
        }
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue,
        });
    };

    getSuggestionValue = suggestion => {
        return '#' + suggestion.value.toLowerCase()
    };

    getSuggestions = value => {
        this.query = value;
        return this.props.queryService.autosuggest('#' + value.toString());
    }

    onChange = name => event => {
        this.setState({ [name]: event.target.value });

        if (name === "topic_name") {
            if (event.target.value) {
                this.setState({ isTitle: true });
            }
            else {
                this.setState({ isTitle: false });
            }
        }
    }

    addTagToTopic = value => {
        var tag = value.startsWith('#') ? value.substring(1) : value;
        tag = tag.replace(/[ ]/g, "_").toLowerCase();

        if (this.state.chipData.find(chip => chip === tag)) {
            this.setState({
                topic_tag: ''
            })
        }
        else {
            this.setState({
                chipData: [...this.state.chipData, tag],
                topic_tag: ''
            })
        }
    }

    onKeyPress = (event) => {
        // enter key pressed
        if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();

            if (event.target.value) {
                this.addTagToTopic(event.target.value)
            }
        }
    }

    getChipTags = () => {
        const { classes } = this.props;

        return this.state.chipData.map(data => {
            let avatar = <Avatar> # </Avatar>;

            return (
                <Chip
                    key={data.key}
                    avatar={avatar}
                    label={data}
                    onDelete={this.handleDelete(data)}
                    className={classes.chip}
                />
            );
        })
    }

    handleDelete = data => () => {
        this.setState(state => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(data);
            chipData.splice(chipToDelete, 1);
            return { chipData };
        });
    };

    getAutoTags = () => {
        const { classes,t } = this.props;

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion,
        };

        return <Autosuggest
            {...autosuggestProps}
            onSuggestionSelected={(event, { suggestion }) => this.addTagToTopic(suggestion.value)}
            inputProps={{
                t,
                classes,
                onKeyPress: this.onKeyPress,
                //placeholder: 'Enter a tag then press enter',
                value: this.state.topic_tag,
                onChange: this.handleChange('topic_tag'),
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
                <Paper elevation={options.children && options.children.props.items.length ? 2 : 0}
                    {...options.containerProps} square
                >
                    {options.children}
                </Paper>
            )}
        />
    }

    onLoadImg = (res) => {
        const file = JSON.parse(res);
        this.setState({"topic_background" : file.link})
    }

    uploadImg() {
        const {classes,t} = this.props
        return (
        <div data-intrcm-id="topicsSettings_ju6i3fl8" className={classes.filePond}>
            <FilePond  allowMultiple='false'
                labelIdle={t("Upload Topic Background")}
                acceptedFileTypes={['image/*']}
                fileRenameFunction={file => `topic_background_${this.state.topic_name}${file.extension}`}
                server={{
                        url: process.env.API_SERVER + '/files/s3',
                        revert: null,
                        restore: null,
                        load: null,
                        fetch: null,
                        process: {
                            headers: {
                                'Authorization': 'Bearer ' + this.props.userData.idToken,
                            },
                            onload: this.onLoadImg
                        }
                    }
                }
            />
        </div>)
    }

    getModal = () => {
        const { topic_name, topic_description, topic_background, topic_icon, topic } = this.state;
        const { classes,t } = this.props;
        let icons = ['headset_mic', /*'people'*/, 'help_outline',
            'group_add', 'perm_phone_msg', 'perm_contact_calendar', 'contact_mail',
            'mail',
            'radio', 'multiline_chart',
            'account_circle', 'card_travel',
            'account_balance', 'gavel',
            'bug_report', 'code', 'devices',
            'important_devices', 'desktop_mac',
            'android', 'phonelink_setup',
            'done', 'description', 'list_alt',
            'face', 'supervisor_account',
            'vpn_key', 'monetization_on',
            'touch_app', 'compare_arrows',
            'format_paint', 'wallpaper', 'color_lens',
            'vpn_lock', 'screen_lock_portrait',
            'public', 'star_border', 'textsms'
        ];

        let title = t("New Topic");

        if (topic && topic.tag) {
            title = t("Edit ") + topic.tag;
        }

        return <Dialog open={this.state.open} onRequestClose={this.handleClose} classes={{ paper: classes.root }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField fullWidth label={t("Name")} id="topic_name" InputLabelProps={{ shrink: true }} value={topic_name} margin="normal"
                    onChange={this.onChange("topic_name")} />
                <TextField fullWidth label={t("Description")} id="topic_description" InputLabelProps={{ shrink: true }} value={topic_description} margin="normal"
                    onChange={this.onChange("topic_description")} />
                {this.uploadImg()}
                {/* <TextField fullWidth label="Topic Background" id="topic_background" InputLabelProps={{ shrink: true }} value={topic_background} margin="normal"
                    onChange={this.onChange("topic_background")} /> */}
                <TextField id="topic_icon" select label={t("Icon")} fullWidth className={classes.textField} value={topic_icon} margin="normal"
                    onChange={this.onChange("topic_icon")} SelectProps={{ MenuProps: { className: classes.menu } }} helperText={t("Select icon")} >
                    {icons.map(icon => (
                        <MenuItem key={icon} value={icon}>
                            <Icon style={{ marginRight: 15, marginBottom: -3 }}>{icon}</Icon>
                            {icon}
                        </MenuItem>
                    ))}
                </TextField>
                {this.getAutoTags()}
                {this.getChipTags()}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                {t('Cancel')}
                    </Button>
                <Button onClick={this.handleClickSave} color="primary" disabled={!this.state.isTitle}>
                {t('Save')}
                    </Button>
            </DialogActions>
        </Dialog>
    }

    componentDidMount = () => this.getAll()

    delete = topic => {
        const { adminService, dispatch,t } = this.props;

        adminService.deleteUserTag(topic.tag).then(() => {
            setTimeout(() => {
                dispatch(showSnackbar('Deleted!'));
                this.getAll();
            }, 1000);
        })

        dispatch(showSnackbar('Deleting...'));
    }

    getAll = () => {
        this.props.adminService.getAllTopics().then((topics) => {
            this.setState({
                topics: topics,
                isLoading: false
            })
        })
    }

    OpenEdit = (topic) => {
        this.setState({
            open: true, topic: topic, topic_name: topic.tag, topic_description: topic.description,
            topic_background: topic.topicBackgroungUrl, topic_icon: topic.topicIcon, chipData: topic.topicTags, isTitle: true
        });
    }

    render() {
        const { classes,t } = this.props;
        const { topics } = this.state;

        const spinner = <div data-intrcm-id="topicsSettings_k1t9e27a" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>
        let content = spinner;

        if (!this.state.isLoading) {
            content = <List className={classes.listStyle}>
                {topics.map(topic => {
                    return <ListItem divider button onClick={() => this.OpenEdit(topic)}>
                        <ListItemText
                            primary={topic.tag}
                            secondary={topic.description} />
                        <ListItemSecondaryAction style={{ paddingRight: 16, cursor: 'pointer' }} onClick={() => this.delete(topic)}>
                            <Clear />
                        </ListItemSecondaryAction>
                    </ListItem>
                })}
            </List>
        }

        return <div data-intrcm-id="topicsSettings_rqmyaio3" >
            <HelpButtonTooltip link='articles/5390923-topics-tags'>
                <AdminSettingsTitle text={t('Topics')} />
            </HelpButtonTooltip>
            <Paper className={classes.paperStyle}>
                {content}

                <Button color="primary" variant="raised" className={classes.newButton}
                    onClick={() => { this.setState({ open: true }) }} >
                    {t('New')}
                </Button>
            </Paper>
            {this.getModal()}
            {/* <TopicModal open={this.state.open} /> */}
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), withTranslation('admin'), withMixpanel, connect(mapStateToProps, mapDispatchToProps))(TopicsSettings);