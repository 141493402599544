import { getKnowledgeNavigationOpts} from "./groupCategoryDrawerNavigationHelpers"
import { getGroupNavigationOpts } from "./groupDrawerNavigationHelpers"
import { getMyPageNavigationOpts } from "./myDrawerNavigationHelpers"
import { navOptions } from "./navigationHelpers"
import { getSearchNavigationOpts } from "./searchDrawerNavigationHelpers"
import { getUserNavigationOpts } from "./userDrawerNavigationHelpers"
import {reduxDispatch} from '../reduxHelpers'
// import { getGanttNavigationOpts } from "./groupGanttDrawerNavigationHelpers"
// import { getSchedulerNavigationOpts } from "./groupSchedulerDrawerNavigationHelpers"

export const getNavigationOptions = (mode, t,additionalOptions, settings) => {
    const dispatch = reduxDispatch()
    switch (mode) {
        case navOptions.MY_PAGE:
            return getMyPageNavigationOpts(t, dispatch, settings)
        case navOptions.GROUP:
        case navOptions.GANTT:
        case navOptions.GROUP_SCHEDULER:
            return getGroupNavigationOpts(t, dispatch,additionalOptions, settings)
        case navOptions.GROUP_CATEGORY:
            return getKnowledgeNavigationOpts(t, dispatch,additionalOptions, settings)
        //  case navOptions.GANTT:
        //     return getGanttNavigationOpts(t, dispatch,additionalOptions, settings)
        //  case navOptions.GROUP_SCHEDULER:
        //     return getSchedulerNavigationOpts(t, dispatch,additionalOptions, settings)
        case navOptions.SEARCH:
            return getSearchNavigationOpts(t, dispatch)
        case navOptions.USER_FOUND:
            return getUserNavigationOpts(t, dispatch)
        default:
            return []
    }
}
