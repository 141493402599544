import React, { useEffect, useState } from 'react' 
import { Menu, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { prepareBeforeStartUnits } from './helpers'
import GeneralButton from '../../../../../components/Common/AllButton/AllButton';
import { hexToRGB } from '@tuqqi/common';
import { OuterOperations } from './outerChangeOperations';

const styles = (theme) => ({
    label: {
        marginTop: 16,
        color: '#979AAA',
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Rubik',
        lineHeight: 1,
    },
    arrow: {
        marginLeft: 8,
        transform: 'rotate(270deg)'
    },
    row: {
        maxWidth: 630,
        marginTop: -4,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    selectorContainer: {
        marginTop: 8,
        paddingRight: 10,
        paddingLeft: 10,
        marginRight: 0,
        marginLeft: 0,
        borderRadius: 4,
        boxShadow: 'none',
        width: 'fit-content',
        height: 26,
        backgroundColor: '#fff',
        color: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        '&:hover': {
            boxShadow: 'none',
            color: theme.customColors.primary.main,
            background: hexToRGB(theme.customColors.primary.main, 0.16),
        },
    },
    selectorText: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: '400',
        letterSpacing: 0.4,
    },
    menu: {
        minWidth: 137,
        background: '#FFFFFF!important',
        border: '1px solid #D7D7DB',
        boxShadow: '0px 6px 8px rgba(11, 12, 12, 0.32)',
        borderRadius: 4,
        outline: 'none!important',
    },
    contentMenu: {
        outline: 'none!important',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 16,
    },
    menuContentLabel: {
        fontSize: 14,
        marginBottom: 8,
        lineHeight: '20px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.4,
        color: '#0C0C0D',
    },
    rowField: {
        cursor: 'pointer',
        marginBottom: 8,
        display: 'block'
    },
    rowFieldText: {
        fontSize: 14,
        height: 20,
        lineHeight: '20px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.24,
        color: '#0C0C0D',
    },
    input: {
        height: 28,
    },
    list: {
        outline: 'none!important',
    },
    inputText: {
        height: 28,
        paddingRight: 8,
        paddingLeft: 8,
        border: 'none',
        background: '#EDEDF0',
        borderRadius: 4,
        outline: 'none',
        '&::placeholder': {
            color: '#B1B1B3',
        }
    }
})

const units = ['$', '%']

const __SelectUnit = (props) => {
    const {classes, options } = props
    const [anchor, setAnchor] = useState(null)
    const [constValue, setConstValue] = useState('')
    const [unitValue, setUnitValue] = useState(prepareBeforeStartUnits(options))

    useEffect(() => {
        OuterOperations.setUnits(unitValue)
    }, [unitValue])

    const openSelector = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setAnchor(e.currentTarget)
    }

    const closeSelector = () => {
        setAnchor(null)
        if(!constValue) return
        onSelectSymbol(constValue, true)
        setConstValue('')
    }

    const onSelectSymbol = (label, denyClose = false) => {
        setUnitValue(label)
        {!denyClose && closeSelector()}
    }

    const renderRow = (label, onClick) => {
        return <div data-intrcm-id="SelectUnit_svu385a5" className={classes.rowField} onClick={onClick}>
            <div data-intrcm-id="SelectUnit_0njzn1a6" className={classes.rowFieldText}>{label}</div>
        </div>
    }

    const onChangeConst = (e) => {
        const val = e?.target?.value ?? ''
        setConstValue(val);
    }

    const onKeyDown = (e) => {    
        if (e.key !== 'Enter') return;
        e.preventDefault()
        e.stopPropagation()
        if(!constValue) return
        onSelectSymbol(constValue, true)
        setAnchor(null)
        setConstValue('')
    }

    const renderConstInput = () => {
        return <div data-intrcm-id="SelectUnit_rukhanb3" className={`${classes.rowField} ${classes.input}`}>
            <input onKeyDown={onKeyDown} onChange={onChangeConst} type="text" className={`${classes.rowFieldText} ${classes.inputText}`} value={constValue} placeholder={"Other..."} />
        </div>
    }

    const renderSelectorContent = () => {
        return <div data-intrcm-id="SelectUnit_w0bxf63n" className={classes.contentMenu}>
            <div data-intrcm-id="SelectUnit_p7busbm0" className={classes.menuContentLabel}>Units</div>
            {units.map((op, i) => <React.Fragment key={i}>{renderRow(op, () => onSelectSymbol(op))}</React.Fragment>)}
            {renderConstInput()}
        </div>
    }

    const MenuInner = !!anchor && <Menu
        anchorOrigin={{ vertical: "center", horizontal: 'left' }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
        classes={{ paper: classes.menu }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={closeSelector}
        MenuListProps={{classes: {padding: classes.list, root: classes.list}}}
        elevation={0} >
        {renderSelectorContent()}
    </Menu>

    const Selector =  <>
        <GeneralButton iconAfterText icon={<div data-intrcm-id="SelectUnit_bvkst119" className={classes.arrow}>{'\u2039'}</div>} onClick={openSelector} containerStyle={`${classes.selectorContainer}`} textStyle={classes.selectorText} title={unitValue || `Select unit`} />
        {MenuInner}
    </>
    return <>
        <div data-intrcm-id="SelectUnit_h5zfg4hs" className={`${classes.label}`}>Choose the unit of measure</div>
        <div data-intrcm-id="SelectUnit_501g6if0" className={classes.row}>
            {Selector}
        </div>
    </>
}

export const SelectUnit = compose(
    withStyles(styles, {withTheme: true})
)(__SelectUnit)