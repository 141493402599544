import { FormControl, Typography, withStyles } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionGroupSettings } from '../../../../../actions/collectionActions.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../../actions/upgradeSubscriptionActions.js';
import CollectionPermissionSettingsOption from './CollectionPermissionSettingsOption.js';
import EditWarning from './EditWarning.js';

const styles = theme => ({
    radioGroup: {
        paddingLeft: 20,
        paddingTop: 16
    },
    container: {
        marginBottom: 24
    }
})

class CollectionPermissionType extends PureComponent {

    getSelectedValue(props) {

        let selectedValue
        const { isEnableMembersAsAuthors, isLimitedGroupEnabled } = this.props.group

        if (isLimitedGroupEnabled) {
            selectedValue = this.isLimitedGroupEnabled
        } else if (isEnableMembersAsAuthors) {
            selectedValue = this.coWorkingValue
        } else {
            selectedValue = this.defaultPermissionValue
        }

        return selectedValue
    }

    onChange = (event, value) => {
        const { dispatch } = this.props;

        const updateModel = {
            isEnableMembersAsAuthors: false,
            isLimitedGroupEnabled: false
        }

        switch (value) {
            case this.defaultPermissionValue:
                updateModel.isEnableMembersAsAuthors = false
                break;
            case this.coWorkingValue:
                updateModel.isEnableMembersAsAuthors = true
                break;
            case this.isLimitedGroupEnabled:
                updateModel.isLimitedGroupEnabled = true
                break;
            default:
                break;
        }
        this.setState({ value })
        dispatch(updateCollectionGroupSettings(updateModel))
    }

    coWorkingValue = 'coWorking'
    defaultPermissionValue = 'defaultPermission'
    isLimitedGroupEnabled = 'limitedGroupEnable'

    canChooseClassified = () => {
        const { subscription } = this.props;
        if([0, 1].includes(subscription.subscriptionType)) return false
        return true
    }
    openSubscriptionDialog = () => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({reason: 'classifiedGroup', mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }


    render() {
        const { classes, isEnableMembersAsAuthors, disabled, isEditMode, t, group } = this.props;

        const allowClassified = this.canChooseClassified()

        return (
            <FormControl container direction='column' component="fieldset" className={classes.container}>
                <Typography variant='h6'>{t('Permissions')}</Typography>
                <RadioGroup
                    aria-label="Group Access Level"
                    value={this.getSelectedValue()}
                    onChange={this.onChange}>
                    <CollectionPermissionSettingsOption
                        // disabled={group.disableChangeIsLimitedGroupEnabled}
                        disabled={isEditMode}
                        key={this.coWorkingValue}
                        value={this.coWorkingValue}
                        primary={t('Co - Working')}
                        secondry={t('everyone can do everything in the group')}
                    />
                    <CollectionPermissionSettingsOption
                        // disabled={group.disableChangeIsLimitedGroupEnabled}
                        disabled={isEditMode}
                        key={this.defaultPermissionValue}
                        value={this.defaultPermissionValue}
                        primary={t('Default permissions')}
                        secondry={t('Admin can do anything, user limited to their own Items')}
                    />
                    <CollectionPermissionSettingsOption
                        // disabled={!group.isClosed}
                        disabled={isEditMode || !allowClassified}
                        withUpgradePlan={!isEditMode ? !allowClassified : false}
                        upgradeText={t("Upgrade")}
                        onClickUpgrage={this.openSubscriptionDialog}
                        key={this.isLimitedGroupEnabled}
                        value={this.isLimitedGroupEnabled}
                        primary={t('Classified Group')}
                        secondry={t('Two types of users, regular users and classified users that see only the items they are members of')}
                    />

                </RadioGroup>
                <EditWarning text={t('classifiedWarning')} />
            </FormControl>)

    }
}
const mapStateToProps = (state) => ({
    group: state.collection.group,
    isEditMode: !state.collection.isNew,
    subscription: state.globalData.currentSubscription,
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('newGroupDialog'),
    connect(mapStateToProps, mapDispatchToProps))(CollectionPermissionType);