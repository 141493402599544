import { BryntumButton } from '@bryntum/gantt-react'
import { withStyles } from '@material-ui/core'
import { hexToRGB } from '@tuqqi/common'
import React, { useCallback } from 'react'

const styles = (theme) => ({
    row: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        top: 73,
        right: 8,
        alignItems: 'center'
    },
    in: {
        marginRight: 8
    },
    wrapBtn: {
        backgroundColor: '#fff'
    },
    customBtn: {
        color: `${theme.customColors.primary.main}!important`,
        backgroundColor: `${hexToRGB(theme.customColors.primary.main, 0.04)}!important`,
        border: `1px solid ${hexToRGB(theme.customColors.primary.main, 0.3)}!important`,
    }
})

export const GanttZoom = withStyles(styles)((props) => {
    const {ganttRef, classes} = props

    const onZoomIn = useCallback(() => {
        ganttRef.current.instance.zoomIn()
    }, [])

    const onZoomOut = useCallback(() => {
        ganttRef.current.instance.zoomOut()
    }, [])

    return <>
    <div data-intrcm-id="GanttZoom_vaxhkxjt" className={classes.row}>
        <div data-intrcm-id="GanttZoom_j0sk6vlm" className={`${classes.wrapBtn} ${classes.in}`}>
        <BryntumButton
            cls='b-raised'
            color={`${classes.customBtn}`}
            pressed={false}
            onAction={onZoomIn}
            icon="b-icon-search-plus"
        />
        </div>
        <div data-intrcm-id="GanttZoom_fu71ax2r" className={classes.wrapBtn}>
        <BryntumButton
            cls='b-raised'
            color={classes.customBtn}
            pressed={false}
            onAction={onZoomOut}
            icon="b-icon-search-minus"
        />
        </div>
    </div>
    </>
})