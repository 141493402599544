import mimeTypes from "mime-types";
import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js';



class FileIcon extends PureComponent {

    path = '/system/fileicons/'
    defaultExtention = 'file'

    existsExtentions = [
        'aac', 'ai', 'bmp', 'cdr', 'doc', 'docx', 'eps', 'gif', 'jpg', 'mkv', 'mov', 'mp3', 'mpeg', 'mpg', 'pdf', 'png', 'ppt', 'ps', 'psd', 'svg', 'trf', 'txt', 'wma', 'xls', 'xlxs', 'zip', 'sketch'
    ]

    getImgUrl = () => {
        let extension = this.getFileExtention()
        const url = `${this.path}${extension}.png`

        return url
    }

    getFileExtention = () => {
        const { mimeType, extension } = this.props

        let res

        if (extension) {
            res = extension
        } else if (mimeType) {
            res = mimeTypes.extension(mimeType)
        }

        if (!res || !this.existsExtentions.includes(res)) {
            res = this.defaultExtention
        }

        return res
    }

    onErrorInImg = (e) => {
        e.target.src = `${this.path}${this.defaultExtention}.png`
    }

    render() {
        const { className } = this.props;

        return (
        <img 
            onError={this.onErrorInImg} 
            src={this.getImgUrl()} 
            className={this.props.className} />
        )
    }
}

export default withErrorCatcher()(FileIcon);
