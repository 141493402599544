import { CircularProgress, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFileFormat } from '../../../utils/filesUtils';
import ChatComment from './chatComment';
// import parseImageUrl from '../../../ui/components/Common/parseImagUrl';
import LightboxModal from './LightboxModal';

const top = 60;

// Apply some reset
const styles = theme => ({
    drawer: {
        position: 'fixed',
        zIndex: 1,
        height: `calc(100% - ${top}px)`,
        overflowY: 'auto'
    },
    fullViewWithDrawer: {
        marginRight: 27,
        marginLeft: 350,
        width: `calc(100% - 350px)`,
    },
    drawerContainer: {
        position: 'fixed',
        left: 0,
        top: top,
        zIndex: 5
    },
    progress: {
        margin: 'auto'
    },
});

class ChatContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            results: [],
            loadingDocuments: true,
            commentsLoading: false,
            selected: '',
            page: 0,
            hasMore: true,
            isLoadingMore: false,
            openLightbox: false,
            selectedImage: 0,
            images: []
        }
    }
    setOpenLightbox = (openLightbox) => {
        this.setState({ openLightbox })
    }
    setSelectedImage = (selectedImage) => {
        this.setState({ selectedImage })
    }

    componentWillUnmount() {
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps) {
        if (prevProps.comments.length !== this.props.comments.length) {
            const images = this.props.comments
                .filter((comment) => {
                    if (comment && comment.attachments) {


                        const { attachments } = comment
                        if (attachments.length === 0) return false
                        if (getFileFormat(attachments[0]) === 'image') return true
                        return false
                    }
                    return false
                })
                // .map((comment) => parseImageUrl(this.props.cloudfront, comment.attachments[0].fileUid));
                .map((comment) => comment.attachments[0].fileUid);
            this.setState({ images })
        }
    }

    updateResults = (docId, comment) => {
        let results = [...this.state.results];
        let comments = [...this.state.comments]
        let index = results.findIndex(res => res.docId == docId)

        if (docId == this.state.selected) {
            comments.push(comment)
        }
        else {
            results[index].newComments += 1;
        }

        results[index].commentText = comment.text;
        this.setState({ comments, results });
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ behavior: "auto" });
        }
    }
    handleOpenLightbox = (url) => {
        const selectedImage = this.state.images.findIndex((index) => index === url);
        this.setOpenLightbox(true);
        this.setSelectedImage(selectedImage);
    };

    render() {
        const { classes, comments, loadMore, hasMore, commentsLoading } = this.props;

        const spinner = <CircularProgress className={classes.progress} />




        return (
            <React.Fragment>
                {commentsLoading
                    ? spinner
                    : <InfiniteScroll
                        isReverse
                        pageStart={0}
                        initialLoad={false}
                        threshold={300}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={spinner}
                        useWindow={false} >
                        {comments.map((comment, idx) => <ChatComment onOpenLightbox={this.handleOpenLightbox} key={idx} comment={comment} />)}
                    </InfiniteScroll>}
                <LightboxModal
                    images={this.state.images}
                    photoIndex={this.state.selectedImage}
                    setPhotoIndex={this.setSelectedImage}
                    isOpen={this.state.openLightbox}
                    onClose={() => this.setOpenLightbox(false)}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatContent);