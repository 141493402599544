const notificationsApiPath = '/Notifications/'

export default class NotificationService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    headers = () => new Headers({
        'Authorization': 'Bearer ' + this.token,
        'Content-Type': 'application/json'
    });

    getNotifications() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + notificationsApiPath + 'usernotifications', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }
    userSawNotifications() {
        var headers = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
        fetch(process.env.API_SERVER + notificationsApiPath + 'userSawNotifications', {
            method: 'GET',
            headers: this.headers()
        })
    }

    activateRule(id, isActive) {

        return fetch(process.env.API_SERVER + notificationsApiPath + `activateRule?id=${id}&isActive=${isActive}`, {
            method: 'POST',
            body: JSON.stringify({ id, isActive }),
            headers: this.headers()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }, function (rejec) {
            console.warn('---------------------reject request because: ', rejec)
        }).catch(function (error) {
            console.warn('---------------------error in request because: ', error)
        });
    }

    updateRuleHandler(id, handlerSettings) {

        return fetch(process.env.API_SERVER + notificationsApiPath + `updateRuleHandler?id=${id}`, {
            method: 'POST',
            body: JSON.stringify(handlerSettings),
            headers: this.headers()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }, function (rejec) {
            console.warn('---------------------reject request because: ', rejec)
        }).catch(function (error) {
            console.warn('---------------------error in request because: ', error)
        });
    }

    getDocNotificationRule(docId) {

        return fetch(process.env.API_SERVER + notificationsApiPath + 'getDocRule/' + docId, {
            method: 'GET',
            headers: this.headers()
        })
    }

    createItemIdSeenRule(docId, users, specifiType) {

        return fetch(process.env.API_SERVER + notificationsApiPath + 'createItemIdSeenRule', {
            method: 'PUT',
            body: JSON.stringify({ itemId: docId, eventType: specifiType, /*specify: users, */actionSpecify: JSON.stringify(users) }),
            headers: this.headers()
        })
      
    }


    // get the subscribedTags with the num of the new items 
    subscribedTags(lastVisit) {

        return fetch(process.env.API_SERVER + notificationsApiPath + 'subscribedTags?lastVisit=' + lastVisit, {
            method: 'GET',
            headers: this.headers()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }, function (rejec) {
            console.warn('---------------------reject request because: ', rejec)
        }).catch(function (error) {
            console.warn('---------------------error in request because: ', error)
        });
    }

    // get the groups with the num of the new items 
    collections(lastVisit) {

        return fetch(process.env.API_SERVER + notificationsApiPath + 'collections?lastVisit=' + lastVisit, {
            method: 'GET',
            headers: this.headers()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }, function (reject) {
            console.warn('---------------------reject request because: ', reject)
        }).catch(function (error) {
            console.warn('---------------------error in request because: ', error)
        });
    }

    async sawNotification(id, platform) {
        try {
            const address = `${process.env.API_SERVER}/Notifications/userSawNotification?notificationId=${id}&platform=${platform}`
            const res = await fetch(address, {
                method: 'GET',
                headers: this.headers(),
            })
               
            if(!res.status.toString().startsWith('2')) {
                return { val: false, isAborted: false }
            }
            return { val: true, isAborted: false }
            
        }
        catch(err) {
            //@ts-ignore
            if (err.name === 'AbortError') {
                return { val: false, isAborted: true }
            }
            
            return { val: false, isAborted: false }
        }
    }
    async sawAllNotifications() {
        try {
            const address = `${process.env.API_SERVER}/Notifications/userSawNotifications`
            const res = await fetch(address, {
                method: 'GET',
                headers: this.headers(),
            })
               
            if(!res.status.toString().startsWith('2')) {
                return { val: false, isAborted: false }
            }
            return { val: true, isAborted: false }
            
        }
        catch(err) {
            //@ts-ignore
            if (err.name === 'AbortError') {
                return { val: false, isAborted: true }
            }
            
            return { val: false, isAborted: false }
        }
    }
}