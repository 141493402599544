import { withStyles, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher';

const styles = theme => ({
});

const rules = {
    incoming: [
        { value: "checklist", title: "Add a checklist to the item", disabled: false },
        { value: "member", title: "Add an assignee to the item", disabled: false },
        { value: "setMembers", title: "Set assignees of the item", disabled: false },
        // { value: "comment", title: "Add a comment to the item", disabled: true },
        // { value: "duedate", title: "Add a due date to the item", disabled: true },
        { value: "label", title: "Add a label to the item", disabled: false },
        { value: "mustread", title: "Set the item as a must-read for specific users", disabled: false },
        { value: "integration", title: "Send item to the integrated sevice", disabled: false },
        // { value: "classification", title: "Add a classification for the item", disabled: true },
        // { value: "group", title: "Move to a group", disabled: true },
        // { value: "link", title: "Link items", disabled: true },
    ],
    outgoing: [
        // { value: "soon", title: "Coming soon", disabled: true }
    ]
}

class ListRulesSelect extends React.PureComponent {
    render() {
        const { onChange, t, ruleType, triggerType, width } = this.props;

        const menuItems = rules[triggerType];

        return <Select dir='auto' style={{ width }} value={ruleType} onChange={onChange('ruleType')} fullWidth >
            {menuItems.map(rule => <MenuItem dir='auto' value={rule.value} key={rule.value} disabled={rule.disabled} >
                {t(rule.title)}
            </MenuItem>)}
        </Select>
    }
}

export default compose(withStyles(styles), withTranslation('listRules'))(ListRulesSelect);