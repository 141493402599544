import { Button, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { goTo } from "../../../../actions/goToActions";

// Apply some reset
const styles = theme => ({
    title: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "rgba(0, 0, 0, 0.87)"
    },
    pointer: {
        cursor: 'pointer'
    }
});

class Authors extends PureComponent {

    state = {
        userName: ''
    }

    openUser = (userTag) => {
        return goTo('@' + userTag.replace(' ', '').toLowerCase(), this.props.dispatch)
    }

    componentDidMount(){
        const { users, UserTag } = this.props
        const  user = this.props.users.find(user => user.tag === this.props.UserTag)
        const userName = user ? `${user.firstname} ${user.lastname}` : ''

        this.setState({ userName })
    }

    render() {
        const { classes, UserName, UserTag, author, members } = this.props;
        const userToShow = 2;
        
        if (members && (!members.length || members.length === 1)) {
            return <div data-intrcm-id="Authors_tq2zsc0i" className={classes.pointer}>
                <span className={classes.title} onClick={this.openUser(UserTag)}>{this.state.userName}</span>
                {author}
            </div>
        }

        let users = members.slice(0, userToShow).map(member => member.userName).join(", ")

        if (members.length > userToShow) {
            let more = members.length - userToShow
            users += " & " + more + (more > 1 ? " others" : " other")
        }

        let tooltipUsers = members.map(member => member.userName).join(", ")

        return <Grid container wrap='wrap'>
            <Tooltip title={tooltipUsers}>
                <Button disableRipple style={{ textTransform: 'none', padding: 0, marginRight: 2 }} className={classes.title}>{users}</Button>
            </Tooltip>
            {author}
        </Grid>
    }


}



const mapStateToProps = (state, ownProps) => ({
    users: state.globalData.users
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Authors);