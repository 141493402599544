import React from 'react';
import { withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import {getMenuItemType, getMenuItemTypeValByLabel} from '../../../../../utils/feedNavigation/getMenuItemType'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = theme => ({
    formControl: {
        margin: '0 5px',
        marginBottom: 5,
        display: 'flex'
    },
    selectMenu: { display: 'flex', alignItems: 'center' },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto'
    },
})

const options = [
    {key: 1, title: getMenuItemType(1)},
    {key: 2, title: getMenuItemType(2)},
    {key: 10, title: getMenuItemType(10)},
]

class _SelectMenuItemType extends React.Component {
    _onChange = (e, a) => {
        e.persist()
        this.props.onChange(getMenuItemTypeValByLabel(e.target.value))
    }

    render() {
        const { classes, type,t } = this.props;
        
        return <FormControl className={classes.formControl}>
            <InputLabel htmlFor="icon">{t('Type')}</InputLabel>
            <Select
                classes={{ selectMenu: classes.selectMenu }}
                value={getMenuItemType(type === 0 ? 1 : (type ?? 1))}
                onChange={this._onChange}
                inputProps={{ name: 'type', id: 'type', }}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.title}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }
}

export const SelectMenuItemType = compose(withStyles(styles),
    withTranslation('admin'),
    )(_SelectMenuItemType);
  