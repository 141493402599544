import React, { PureComponent } from 'react';

export const UserScoreboardIcon = class UserScoreboardIcon extends PureComponent {

    render() {
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9971 0C15.6239 0 14.4951 1.12971 14.4951 2.50293V17.5C14.4951 18.8732 15.6239 20.0029 16.9971 20.0029C18.3703 20.0029 19.5 18.8732 19.5 17.5V2.50293C19.5 1.12971 18.3703 0 16.9971 0ZM16.9971 1.5C17.5599 1.5 18 1.94015 18 2.50293V17.5C18 18.0628 17.5599 18.5029 16.9971 18.5029C16.4343 18.5029 15.9951 18.0628 15.9951 17.5V2.50293C15.9951 1.94015 16.4343 1.5 16.9971 1.5ZM2.99707 4.50293C1.62385 4.50293 0.495117 5.63167 0.495117 7.00488V17.5C0.495117 18.8732 1.62385 20.0029 2.99707 20.0029C4.37029 20.0029 5.5 18.8732 5.5 17.5V7.00488C5.5 5.63167 4.37029 4.50293 2.99707 4.50293ZM2.99707 6.00293C3.55985 6.00293 4 6.4421 4 7.00488V17.5C4 18.0628 3.55985 18.5029 2.99707 18.5029C2.43429 18.5029 1.99512 18.0628 1.99512 17.5V7.00488C1.99512 6.4421 2.43429 6.00293 2.99707 6.00293ZM10 9C8.62678 9 7.49707 10.1297 7.49707 11.5029V17.5C7.49707 18.8732 8.62678 20.0029 10 20.0029C11.3732 20.0029 12.5029 18.8732 12.5029 17.5V11.5029C12.5029 10.1297 11.3732 9 10 9ZM10 10.5C10.5628 10.5 11.0029 10.9401 11.0029 11.5029V17.5C11.0029 18.0628 10.5628 18.5029 10 18.5029C9.43722 18.5029 8.99707 18.0628 8.99707 17.5V11.5029C8.99707 10.9401 9.43722 10.5 10 10.5Z" fill="#4A4A4F"/>
            </svg>
        )
    }
}
