import { loadUserData } from '@tuqqi/common';
import { managerTableKeepGlobalData, openedGroupActions } from '@tuqqi/web';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { push } from 'react-router-redux';
import { showConfirm, showSnackbar } from '../../../../../actions/appActions';
import { selectDrawerNavigationOption } from '../../../../../actions/drawerNavigationActions';
import { COLLECTION_INIT, DATA_FEED_UPDATE, RESULT_CHANNEL_EMPTY, USER_DATA_LOAD } from '../../../../../actionTypes/actionTypes';
import { allCollectionMembers, joinToPublicGroup } from '../../../../views/groups/groupUtils';
import { markStarredGroup, unmarkStarredGroup } from '../../../../../actions/searchNavigationActions';

const getAdditionalOptions = (ctx) => {
    const { t, canUserLeaveTheGroup, leave, join, openSettings, openConnectors, isAdmin, isDisabledLeave, disabledTooltipText } = ctx

    

    const opts = [
        // {
        // id: 9,
        // icon: 'LeaveGroup',
        // key: 'LeaveGroup',
        // isDisabled: canUserLeaveTheGroup && isDisabledLeave,
        // disabledTooltipText: disabledTooltipText,
        // title: canUserLeaveTheGroup ? t("Leave") : t("Join group"),
        //  onClick: () => {
        //      canUserLeaveTheGroup ? leave() : join()
        //     }
        // },
    ]
    const settings =  {
        id: 7,
        icon: 'Settings',
        key: 'settings',
        title: t("Settings"),
        onClick: () => {
           openSettings() 
        }
    }

    const connectors =  {
        id: 8,
        icon: 'Connectors',
        key: 'connectors',
        title: t("Connectors"),
        onClick: () => {
            openConnectors() 
        }
    }

    isAdmin &&  opts.unshift(settings)
    isAdmin &&  opts.unshift(connectors)
    return opts
}

export const useGetGroupAdditionalOptions = (props) => {
    const { 
        t,
        groupInfo, 
        dispatch, 
        groupService, 
        userSettingsService, 
        profileData,
        starredGroups = [],
        setIsCanLeave,
        setIsCanJoin,
        pathname,
        searchQuery,
        queryService,
        teams,
     } = props;
     const [isAdmin, setIsAdmin] = useState(false)

     const groupName = groupInfo?.title ?? ''
     const collectionUid = groupInfo?.collectionUid ?? ''

     useEffect(() => {
        dispatch({
            type: "SET_COLLECTION_UID_UI",
            collectionUid
        })
     }, [collectionUid])

     const starredGroupsUids = useMemo(() => {
        return starredGroups.map(x => x.collectionUid)
     }, [starredGroups])

     const getIsGroupFavourite = useCallback(() => starredGroupsUids.includes(collectionUid), [starredGroupsUids, collectionUid])

     const isUserAdmin = useCallback((id) => {
        const adminIds = groupInfo?.collectionGroup?.adminIds ?? []

        return adminIds.includes(id)
    }, [groupInfo])

     useEffect(() => {
        const adminIds = groupInfo?.collectionGroup?.adminIds ?? []
        const memberId = profileData.id

        if(adminIds.includes(memberId)) {
            setIsAdmin(true)
            return
        }
        setIsAdmin(false)
    }, [groupInfo, profileData.id])

    const confirmedLeave = useCallback(() => {
        dispatch(showSnackbar('Leaving...'));
        groupService.leaveGroup(groupInfo.collectionUid)
            .then(() => {
                dispatch(showSnackbar('Leave!'));
                userSettingsService.getUserData().then((userData) => {
                    dispatch(loadUserData({ API_URL: process.env.API_SERVER }))
                    dispatch({ type: USER_DATA_LOAD, data: { ...userData } });
                    dispatch(push('/feed'));
                    dispatch({ type: DATA_FEED_UPDATE });
                    dispatch({ type: RESULT_CHANNEL_EMPTY });
                })
            })
    }, [groupInfo.collectionUid])

    const leave = useCallback(() => {
        const groupAdmins = groupInfo.collectionGroup.adminIds

        if (groupAdmins.length === 1 && groupAdmins[0] === profileData.id) {
            // dispatch(showConfirm(("You are the Admin of the group; therefore, you can't leave it. Please contact Tuqqi support"), () => { }, true, true))
            return
        }

        dispatch(showConfirm(("Are you sure you want to Leave this group?"), confirmedLeave))
    }, [groupInfo, confirmedLeave])

    const isDisabledLeaveAsSingleAdmin = useCallback(() => {
        const groupMembers = allCollectionMembers(groupInfo)
        const adminUsers = groupMembers.filter(x => isUserAdmin(x.id))
        if(adminUsers.length === 1 && adminUsers?.[0]?.id === profileData.id) return true
        return false
    }, [groupInfo, profileData.id, isUserAdmin])

    const isDisabledLeaveAsTeamMember = useCallback(() => {
        const groupMembers = allCollectionMembers(groupInfo)
        const membersPartIds = groupMembers.map(x => x.id)

        const teamIds = groupInfo.collectionTeamsDto?.groupUsers ?? []
        const possibleTeams = teams.filter(x => teamIds.includes(x.id))
        const newIds = possibleTeams.reduce((accum, team) => {
            const ids = team?.memberIds ?? []
            // const newIds = ids.filter(id => !membersPartIds.includes(id))
            const newIds = ids
            if(!newIds.length) return accum
            return Array.from(new Set(accum.concat(newIds)))
        }, [])
        
        return newIds.includes(profileData.id)
    }, [groupInfo, profileData.id, teams])
    
    const getTeamName = useCallback(() => {
        const teamIds = groupInfo.collectionTeamsDto?.groupUsers ?? []
        const possibleTeams = teams.filter(x => teamIds.includes(x.id) && (x?.memberIds ?? []).includes(profileData.id))
        return possibleTeams?.[0].name ?? 'Untitled'
    }, [groupInfo, profileData.id, teams])

    const isDisabledLeave = useMemo(() => {
        if(isDisabledLeaveAsSingleAdmin()) return true
        if(isDisabledLeaveAsTeamMember()) return true

        return false
    }, [isDisabledLeaveAsSingleAdmin, isDisabledLeaveAsTeamMember])

    const disabledTooltipText = useMemo(() => {
        if(isDisabledLeaveAsSingleAdmin()) return t("You cannot leave the '{{groupName}}' because you are the only admin", {groupName})
        if(isDisabledLeaveAsTeamMember()) return t("You cannot leave the '{{groupName}}' group because your team '{{teamName}}' belongs to it", {groupName, teamName: getTeamName()})
        return undefined
    }, [isDisabledLeaveAsSingleAdmin, isDisabledLeaveAsTeamMember, getTeamName])

    const join = useCallback(() => {
        const { groupUid } = groupInfo.collectionGroup

        joinToPublicGroup(groupUid, searchQuery, dispatch, queryService, groupService, userSettingsService)
    }, [groupInfo, searchQuery])

    const canUserLeaveTheGroup = useCallback(() => {
        if (!!groupInfo?.collectionGroup?.isClosed ||
            allCollectionMembers(groupInfo).some(u => u.auth0_id === profileData.auth0_id)) {
            return true
        }

        return false
    }, [groupInfo, profileData.auth0_id])

    const getChecked = useCallback(() => {
        const { collectionGroup, limitedGroup } = groupInfo


        const checked = new Map()
        collectionGroup.groupUsers.forEach(u => {
            checked.set(u.id, { isLimited: false, isAdmin: collectionGroup.adminIds.includes(u.id) })
        })
        limitedGroup.groupUsers.forEach(u => {
            checked.set(u.id, { isLimited: true })
        })

        return checked
    }, [groupInfo])

    const openSettings = useCallback(() => {
        const { backgroundUrl, settings, title, description, icon, collectionGroup, allInputsAllowed, defaultView, externalInputs, isLimitedGroupEnabled, mailAddressUid } = groupInfo
        // const checked = getChecked()

        dispatch(openedGroupActions.changeGroup(collectionGroup.groupUid))
        dispatch(selectDrawerNavigationOption(7))
        dispatch(push({ pathname: `${'/group/settings'}`, search: window.location.search }))

        // const collection = {
        //     isNew: false,
        //     id: groupInfo.id,
        //     allInputsAllowed,
        //     backgroundUrl,
        //     settings,
        //     title,
        //     mailAddressUid,
        //     externalInputs: externalInputs,
        //     description,
        //     defaultView,
        //     icon: icon || 'public',
        //     group: {
        //         isEnableMembersAsAuthors: collectionGroup.isEnableMembersAsAuthors,
        //         isLimitedGroupEnabled,
        //         disableChangeIsLimitedGroupEnabled: isLimitedGroupEnabled,
        //         checked,
        //         groupUsers: collectionGroup.groupUsers,
        //         adminIds: collectionGroup.adminIds,
        //         isClosed: collectionGroup.isClosed
        //     }
        // }

        // dispatch({ type: COLLECTION_INIT, collection })
    }, [groupInfo, getChecked])

    const openConnectors = useCallback(() => {
        const { collectionGroup } = groupInfo

        dispatch(openedGroupActions.changeGroup(collectionGroup.groupUid))
        dispatch(selectDrawerNavigationOption(8))
        dispatch(push({ pathname: `${'/group/connectors'}`, search: window.location.search }))

    }, [groupInfo])

    const userCanLeave = useMemo(() => canUserLeaveTheGroup(), [canUserLeaveTheGroup])

    const onAddGroupAsFavorite = useCallback(() => {
        groupService.AddCollectionToFavorites(collectionUid)
            .then((_) => {
                dispatch(showSnackbar('Added successfully!'));
                dispatch(markStarredGroup(collectionUid))
            });
    }, [collectionUid])
    
    const onRemoveGroupAsFavorite = useCallback(() => {
        groupService.RemoveCollectionFromFavorites(collectionUid)
            .then((_) => {
                dispatch(showSnackbar('Removed successfully!'));
                dispatch(unmarkStarredGroup(collectionUid))
            });
    }, [collectionUid])

    useEffect(() => {
        const allow = !!userCanLeave && !isDisabledLeave
        setIsCanLeave(allow)
    }, [userCanLeave, isDisabledLeave])

    useEffect(() => {
        setIsCanJoin(!userCanLeave)
    }, [userCanLeave])

    useEffect(() => {
        if(!pathname.includes('group')) return
        managerTableKeepGlobalData.setCallbacksPartial({
            openGroupSettings: openSettings,
            getIsGroupFavourite,
            onAddGroupAsFavorite,
            onRemoveGroupAsFavorite,
            onLeaveGroup: confirmedLeave,
        })
    }, [pathname, openSettings, getIsGroupFavourite, onAddGroupAsFavorite, onRemoveGroupAsFavorite, confirmedLeave])

    const options = useMemo(() => {
        return getAdditionalOptions({ 
            isAdmin,
            t, 
            canUserLeaveTheGroup: userCanLeave,
            isDisabledLeave,
            leave,
            join,
            disabledTooltipText,
            openSettings,
            openConnectors,
        })
    }, [userCanLeave, leave, join, openSettings, isAdmin])

    return [options, join]
}