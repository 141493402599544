import { Avatar, withStyles } from '@material-ui/core';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createCustomTheme } from "../../../../utils/theming";
import UserAvatar from '../../../components/Common/UserAvatar';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { DirectMessages, GroupMessages, Discussions, getFaces, getIcon, SavedMessages } from '../chatUtils';
import { managerTableKeepGlobalData } from '@tuqqi/web';
import groupPlaceholder from "../../../../assets/img/group_placeholder.png";
import { parseImageUrl } from '@tuqqi/common';
// import parseImageUrl from '../../../components/Common/parseImagUrl';

// Apply some reset
const styles = theme => ({
    groupAvatar: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    icon: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 2,
        boxShadow: '0 1px 6px 0 rgba(143, 100, 223, 0.31)',
        fontSize: 16,
        color: '#6200ee'
    },
    badge: {
        top: 45,
        right: -5,
    },
    avatarWrapper: {
        position: 'relative',
        width: 32,
        height: 32,
        top: -18,
        right: -19,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%' }
    },
    firstInGroup: {
        position: 'relative',
        width: 32,
        height: 32,
        top: 22,
        right: 0,
        zIndex: 2,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%', }
    },
    singleInGroup: {
        position: 'relative',
        width: 32,
        height: 32,
        top: 5,
        right: 0,
        zIndex: 2,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%', }
    },
    avatarGroup: {
        width: 32,
        height: 32,
    },
    firstAvatar: {
        width: 32,
        height: 32,
        border: 'solid 2px white'
    }
});
const defaultBackground = groupPlaceholder;
const parseLink = (link, cloudfront) => {
    if(!link || !cloudfront) return defaultBackground
    return parseImageUrl(cloudfront)(link) || defaultBackground
}
class ChatListItemLeftElement extends Component {
    state = {
        icon: ''
    }

    render() {
        const { chatType, document, cloudfront, users, inputMappings, chat, docId, classes, userProfile, userData } = this.props;
        const colors = createCustomTheme(userData.profileData.org.brandingColor);

        switch(chatType){
            case Discussions:
                const faces = getFaces(document, users, cloudfront);
                const icon = getIcon(inputMappings, document);
                const facesLen = faces.slice(0, 2).length
                const isSingleParticipant = facesLen === 1
                return (<div data-intrcm-id="ChatListItemLeftElement_nxxtcexh" style={{ marginLeft: 0 }} >
                    {faces.slice(0, 2).map((participant, i) => {
                        const isFirst = i === 0
                        return (
                        <div data-intrcm-id="ChatListItemLeftElement_84jkc7df" className={isFirst ? isSingleParticipant ? classes.singleInGroup : classes.firstInGroup : classes.avatarWrapper} key={participant.id}>
                            <Avatar className={isFirst ? classes.firstAvatar : classes.avatarGroup} alt={participant.tag} src={participant.imageUrl} />
                        </div>
                        )
                    })}
                </div>)
                // return (<div data-intrcm-id="ChatListItemLeftElement_5rgq045b" style={{ marginLeft: 0 }} >
                //     <Badge className={classes.margin} badgeContent={<Icon className={classes.icon}>{icon}</Icon>} classes={{ badge: classes.badge }}>
                //         <ImageGrid users={faces} key={getChatId(chatType, chat, docId)} />
                //     </Badge>
                // </div>)
            case DirectMessages: 
                if(userProfile){
                    return <div data-intrcm-id="ChatListItemLeftElement_fnc0jfhq" style={{ marginLeft: 0, marginRight: 0 }}  >
                        <UserAvatar variant="chatFeed" userUUID={userProfile.auth0_id} withShadow={false} />
                    </div>
                }
                else {
                    return null;
                }
            case GroupMessages: 
            const col = !chat?.chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chat?.chatUId)

            let url = defaultBackground

            if(col?.backgroundUrl && managerTableKeepGlobalData.getCloudfront()) {
                url = parseLink(col?.backgroundUrl, managerTableKeepGlobalData.getCloudfront())
            }
            return <div data-intrcm-id="ChatListItemLeftElement_fnc0jfhq" style={{ marginLeft: 0, marginRight: 0 }}  >
                <img alt={`group-image-${url}`} src={url} className={classes.groupAvatar} />
            </div>
            case SavedMessages:
                    return <div data-intrcm-id="ChatListItemLeftElement_vzvh97lc" style={{ height:48,width:48, backgroundColor: colors.primary.main, borderRadius: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                        <BookmarkBorder size={29} style={{color: '#fff'}} /> 
                    </div>
            default: return null;
        }
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    ...state.wrapper,
    channel: state.results.channel,
    searchQuery: state.search,
    users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withErrorCatcher('Chatlist element crashed'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatListItemLeftElement);