import { Grid, MenuItem, MenuList, TextField, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../withErrorCatcher';

const styles = theme => ({
    container: { 
        width: 'auto'
    },
    relativeContainer: {
        marginTop: 5,
        position: 'relative'
    },
    menu: {
        zIndex: 1, 
        top: 48,
        position: 'absolute',
        boxShadow: '0px 2px 6px 0 rgb(115 106 212 / 15%)',
        transition: 'background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: '#ffffff',
    }
});

class MenuWithSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputText: null,
            suggestOptions: [],
            suggestDeepOptions: [],
        }
    }

    static defaultProps = {
        options: [],
    };


    onTextChange = (e) => {
        const inputText = e.target.value
        const suggestOptions = inputText ? this.props.options.filter(option => option.includes(inputText)) : []
        this.setState({ inputText, suggestOptions })
        if(!!inputText) return
        this.props.withDeepOptions ? this.onSelectDeep(null)() : this.onSelect(null)()
    }
    onTextChangeDeep = (e) => {
        const inputText = e.target.value
        const suggestDeepOptions = inputText ? this.props.deepOptions.filter(option => option.title.includes(inputText)) : []
        this.setState({ inputText, suggestDeepOptions })    
    }

    onSelect = (option) => () => {
        this.setState({ inputText: null, suggestOptions: [] })
        this.props.onSelect(option)
    }
    onSelectDeep = (option) => () => {
        this.setState({ inputText: null, suggestDeepOptions: [] })
        this.props.onSelectOptionKey(option ? option.key : null)
    }

    render() {
        const { classes, options, label, onSelect, selectedValue, withAbsolute, withDeepOptions } = this.props
        const { inputText, suggestOptions, suggestDeepOptions } = this.state

        const valueInTextField = inputText === null ? selectedValue : inputText
        const opts = withDeepOptions ? suggestDeepOptions : suggestOptions
        return (
            <Grid container direction='column' className={`${classes.container} ${withAbsolute ? classes.relativeContainer : ''}`}>
                <TextField
                    value={valueInTextField}
                    label={label}
                    onChange={withDeepOptions ? this.onTextChangeDeep : this.onTextChange}
                />
                <MenuList className={`${withAbsolute ? classes.menu : ''}`} open={opts.length}>
                    {
                        opts.length && <MenuItem value='NONE' key='NONE' onClick={withDeepOptions ? this.onSelectDeep(null) : this.onSelect(null)}>NONE</MenuItem>
                    }
                    {
                        withDeepOptions ?
                            opts.map((option, idx) => <MenuItem value={option.title} key={option.key} onClick={this.onSelectDeep(option)}>{option.title}</MenuItem>) :
                            opts.map((option, idx) => <MenuItem value={option + idx} key={option + idx} onClick={this.onSelect(option, idx)}>{option}</MenuItem>)
                    }
                </MenuList>
            </Grid>)
    }
}


export default compose(withStyles(styles),
    withErrorCatcher())
    (MenuWithSearch);