import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BryntumGantt } from '@bryntum/gantt-react';
import { CalendarModel, DateHelper, DependencyStore, ProjectModel, TaskStore } from '@bryntum/gantt';
import { ganttColumns, taskStoreObserverFields, getBryntumFeatures } from './config';
import { dtoGanttSingleEventData, getMinimumStartDate, getPrevWeekProjectStartDate, runReportState } from './utils/dtoGanttData';
import { connect } from 'react-redux';
import './lib/assignUser/AssignColumn';
import './lib/relations/RelationsColumn';
import './lib/drag/DragColumn';
import './lib/statusTask/StatusTaskColumn';
import './lib/datePicker/DatePickerOverride';
import './lib/grid/GridOverride';
import './Gantt.scss'
import {useGanttReorder} from  './utils/useGanttReorder'
import {useConfirmChangeProjectStartDate} from  './utils/useConfirmChangeProjectStartDate'

import { managerGanttRef, useUpdateSpecificItemGroupGantt } from '@tuqqi/web';
import { useTranslation } from 'react-i18next';
import { useGanttGlobalListeners } from './utils/useGanttGlobalListeners';
import { GanttZoom } from './GanttZoom';
import { useFastCreation } from './utils/useFastCreation';
import { counterInstance } from './lib/title/DocTitle';
import { resolveDepsApi, resolvePercentDoneApi, resolveStartEndDateApi } from './utils/resolveStartEndDateApi';
import { runOnInitCols } from './lib/grid/GridOverride';

// import En from '@bryntum/gantt/locales/gantt.locale.En'
// import Ru from '@bryntum/gantt/locales/gantt.locale.Ru'
// import NL from '@bryntum/gantt/locales/gantt.locale.Nl'
// import SvSe from '@bryntum/gantt/locales/gantt.locale.SvSE'


const Gantt = props => {
    const {tasksFromKanban, collectionInfo, cloudfront, dispatch, groupService,Loader, queryService, fullviewIsOpen} = props
    const [t] = useTranslation("gantt")
    const [test] = useState({date:new Date(), block: 'center'})
    const ganttRef = useRef()

    const updateScheduleId = useRef("")
    const onChangeProjectStartDate = useConfirmChangeProjectStartDate({dispatch, queryService, fullviewIsOpen})
    const ganttListeners = useGanttGlobalListeners({dispatch})

    useUpdateSpecificItemGroupGantt()

    const [dtoGantt, setDtoGantt] = useState({
        fromInitial: false,
        resourcesData: [],
        eventsData: [],
        dependenciesData: [],
    })

    const [rowReorderFeatureNew, updateMove] = useGanttReorder({collectionInfo, groupService, dispatch})
    // useFastCreation({collectionInfo})
    // const {
    //     onDependencyValidationComplete,
    // } = useDepsEvents({})
    // const {
    //     onExpandNode,
    // } = useExpandNode()

    // useEffect(() => {
       
    //     // LocaleManager.locale = 'Ru'
    //     // console.log('LocaleManager.locales', LocaleManager.locales)
    // // LocaleManager.applyLocale(Ru)   
    // }, [ LocaleManager])

    useEffect(() => {
        return () => {
            managerGanttRef.gantt = null
        }
    }, [])

    useEffect(() => {
        managerGanttRef.gantt = ganttRef?.current ?? null
        if(!!updateMove.current) {
            updateMove.current = false
            return
        }
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)

        const formatTasksFromKanbanToGantt = tasksFromKanban.map(el => {
            return {
                ...el,
                startDate: DateHelper.parse(el.dateTimeFrom === "0001-01-01T00:00:00Z" ?  new Date().toISOString()  : el.dateTimeFrom),
                endDate: DateHelper.parse(el.dateTimeTo === "0001-01-01T00:00:00Z" ?  tomorrow.toISOString() : el.dateTimeTo),
            }
        })
    const minD = !!formatTasksFromKanbanToGantt.length ? getMinimumStartDate(formatTasksFromKanbanToGantt) : new Date()    
        const x = dtoGanttSingleEventData({
            isInitial: !Object.keys(refInitialProject.current).length,
            ganttRef,
            t,
            cloudfront,
            collectionInfo
        })(formatTasksFromKanbanToGantt, minD)
        setTimeout(() => {
            counterInstance.checkCurrent()
        }, 300)

        setDtoGantt(x)
    }, [collectionInfo, cloudfront, tasksFromKanban])

    const [isLoading, setIsLoading ] = useState(true)
    const [initialProject, setInitialPrject] = useState({})
    const refInitialProject = useRef(initialProject)

    useEffect(() => {
        refInitialProject.current = initialProject
    }, [initialProject])

    const addDependency = useCallback((data) => {
        const {records} = data
        if(!records.length) return
        if(!managerGanttRef.gantt) return
        const {originalData: { fromEvent, toEvent }} = records[0]
        const {originalData: {id: toDocId}} = toEvent

        const x = fromEvent.deps ?? []
        const deps = [...x, toDocId]

        fromEvent.set('deps', deps)
        const column = managerGanttRef.gantt.instance.columns.visibleColumns.find(x => x.type === 'successor')
        if(!column) return
        managerGanttRef.gantt.instance.refreshColumn(column)
    }, [])

    const updateEvent = useCallback(({changes, record}) => {
    resolveStartEndDateApi(record, changes)
    resolveDepsApi(record, changes)
    resolvePercentDoneApi(record, changes)

    const {originalData} = record
    if(!!originalData?.isRootRow) {
        const updateGantt = ganttRef.current && ganttRef.current.instance.project.eventStore
        if(!updateGantt) return
        const allRecords = updateGantt?.allRecords ?? []
        record.startDate = DateHelper.parse(getPrevWeekProjectStartDate(allRecords))
    }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        const {eventsData, dependenciesData, resourcesData} = dtoGantt
        const project = new ProjectModel({
            autoCalculatePercentDoneForParentTasks: true,
            // listeners : {
            //     load : {
            //         prio : 10000,
            //         fn(args) { 
            //             ganttRef.current = args.source
            //         }
            //     },
            // },

            eventStore: new TaskStore({
                wbsMode : 'auto',
                reapplySortersOnAdd: true,
                sorters: dtoGantt.fromInitial ? managerGanttRef.getInitialSorters() ?? [] : [],
                // manuallyScheduled: true,
                fields: taskStoreObserverFields,
                data: eventsData,
            }),
            calendar: new CalendarModel({
                    intervals : [
                          {
                            "recurrentStartDate" : "on Fri at 0:00",
                            "recurrentEndDate"   : "on Sun at 0:00",
                            "isWorking"          : false,
                          }
                        ],
                        "expanded" : false
            }),
            resourcesData,
            dependenciesData,
            dependencyStore: new DependencyStore({
                listeners: {
                    add: addDependency
                }
            })
        })
        project.eventStore.on({
            update: updateEvent,
        })
        project.on({
            'load': () => {
                if(dtoGantt.fromInitial) runReportState()
            },  
            'dataReady': () => {
                managerGanttRef.gantt = ganttRef?.current ?? null
                runOnInitCols()
            }
        })
        onChangeProjectStartDate(eventsData?.[0]?.startDate ?? '') 
        setInitialPrject(project)
        setIsLoading(false)
    }, [dtoGantt])

    const columns = useMemo(() => {
        return ganttColumns(t)
    }, [])

    const bryntumFeatures = useMemo(() => {
        return getBryntumFeatures(t)
    }, [])

    if(isLoading || !Object.keys(initialProject).length) return Loader
    return <div data-intrcm-id="GanttBryntum_bl7lcz43" style={wrapStyle}>
    <BryntumGantt 
        ref={ganttRef}
        visibleDate={test}
        listeners={ganttListeners}
        {...bryntumFeatures}
        rowReorderFeature={rowReorderFeatureNew}
        // onExpandNode={onExpandNode}
        // onDependencyValidationComplete={onDependencyValidationComplete}
        rowHeight={45}
        dependencyIdField="id"
        project={initialProject}
        data={dtoGantt.eventsData}
        columns={columns}
    />
    {/* {<GanttZoom ganttRef={ganttRef} />} */}
    </div>
};

const mapStateToProps = (state) => ({ 
    fullviewIsOpen: state.fullview.open,
 })
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Gantt)

const wrapStyle = {
    position: 'relative'
}