import React, { PureComponent } from 'react';

export const UserHelpCenterIcon = class UserHelpCenterIcon extends PureComponent {

    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.48603 0 0 4.48604 0 10C0 15.514 4.48603 20 10 20C15.514 20 20 15.514 20 10C20 4.48604 15.514 0 10 0ZM10 1.5C14.7033 1.5 18.5 5.2967 18.5 10C18.5 14.7033 14.7033 18.5 10 18.5C5.29669 18.5 1.5 14.7033 1.5 10C1.5 5.2967 5.29669 1.5 10 1.5ZM10 4.5C8.35207 4.5 7 5.85207 7 7.5V7.75C6.99859 7.84938 7.01696 7.94806 7.05402 8.04028C7.09108 8.13251 7.1461 8.21645 7.21588 8.28723C7.28566 8.358 7.36882 8.41421 7.46051 8.45257C7.5522 8.49093 7.65061 8.51068 7.75 8.51068C7.84939 8.51068 7.9478 8.49093 8.03949 8.45257C8.13118 8.41421 8.21434 8.358 8.28412 8.28723C8.3539 8.21645 8.40892 8.13251 8.44598 8.04028C8.48304 7.94806 8.50141 7.84938 8.5 7.75V7.5C8.5 6.66293 9.16293 6 10 6C10.8371 6 11.5 6.66293 11.5 7.5C11.5 8.67045 11.1957 8.81712 10.6621 9.23633C10.3953 9.44593 10.0459 9.6876 9.74707 10.0947C9.44826 10.5019 9.25 11.0694 9.25 11.75C9.24859 11.8494 9.26696 11.9481 9.30402 12.0403C9.34108 12.1325 9.3961 12.2164 9.46588 12.2872C9.53566 12.358 9.61882 12.4142 9.71051 12.4526C9.8022 12.4909 9.90061 12.5107 10 12.5107C10.0994 12.5107 10.1978 12.4909 10.2895 12.4526C10.3812 12.4142 10.4643 12.358 10.5341 12.2872C10.6039 12.2164 10.6589 12.1325 10.696 12.0403C10.733 11.9481 10.7514 11.8494 10.75 11.75C10.75 11.3234 10.833 11.1501 10.9561 10.9824C11.0791 10.8148 11.2922 10.6483 11.5879 10.416C12.1793 9.95135 13 9.07955 13 7.5C13 5.85207 11.6479 4.5 10 4.5ZM10 14C9.73478 14 9.48043 14.1054 9.29289 14.2929C9.10536 14.4804 9 14.7348 9 15C9 15.2652 9.10536 15.5196 9.29289 15.7071C9.48043 15.8946 9.73478 16 10 16C10.2652 16 10.5196 15.8946 10.7071 15.7071C10.8946 15.5196 11 15.2652 11 15C11 14.7348 10.8946 14.4804 10.7071 14.2929C10.5196 14.1054 10.2652 14 10 14Z" fill="#4A4A4F"/>
            </svg>
        )
    }
}
