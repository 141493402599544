import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import SearchNavigationBackIcon from '../../../Common/Icons/SearchNavigationBackIcon';
import ChannelsSection from '../Sections/ChannelsSection/ChannelsSection';

const styles = (theme) => ({
    container: {
        width: theme.constants.searchInputWidth,
        height: 'fit-content',
        borderRadius: 6,
        backgroundColor: '#f7f7fc',
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 45px)`,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    divider: {
        backgroundColor: '#dddee6',
        marginRight: 20,
        marginLeft: 20
    },
    back: {
        marginLeft: 20,
        marginTop: 24,
        marginBottom: -20,
        cursor: 'pointer',
        transform: 'scale(1.3)',
    },
})

function SearchChannels(props) {
    const {classes, dispatch, query} = props
    const [isFilterMode, setIsFilterMode] = useState(false)

    useEffect(() => {
        if(query.length > 1) {
            setIsFilterMode(true)
            return;
        }
        setIsFilterMode(false)
    }, [query])

    const goToLanding = () => {
        const query = {query: ''}
        dispatch({
            type: 'SEARCH_QUERY', query
        })
    }
    return (
        <div data-intrcm-id="SearchChannelsPage_plvbzdc2" className={classes.container} >
            <SearchNavigationBackIcon className={classes.back} onClick={goToLanding} />
            <ChannelsSection
                withKeysNavigation
                withSubscribe
                withSubscribed
                withTrending
                withSeeAll={false}
                withAddNew
                withSlice={false}
                filterMode="filter"
                withFilter={isFilterMode}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        query: state.search.query
    }
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})


export const SearchChannelsPage = compose(
    withStyles(styles, {withTheme: true}),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SearchChannels)

