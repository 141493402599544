import Excel from "exceljs";
import saveAs from 'file-saverjs';

function buildColumnsWithData(columnsWithoutData, data, renderCell){
    const columnsWithData = []
    columnsWithoutData.forEach((column, columnNum) => {
        const cells = []
        data.forEach((row, rowNum) => {
            const cell = renderCell(row, column)
            cells.push(cell)
        })
        columnsWithData.push({...column, cells })
    })

    return columnsWithData
}

const calcColsBase = (doc, renderCell, columnsWithoutData) => {
    return columnsWithoutData.map((column) => {
        const cell = renderCell(doc, column)
        const isEmpty = Object.keys(cell).length === 0
        return !isEmpty ? cell : {value: '', t: ''}
    })
}

function buildRelatedColumnsWithData(columnsWithoutData, itemsEntries, renderCell){
    const columnsWithDataByKey = {}
    itemsEntries.forEach(([originDocId, items]) => {
        columnsWithoutData.forEach((column, columnNum) => {
            const cells = []
            items.forEach((row) => {
                const cell = renderCell(row, column)
                const isEmpty = Object.keys(cell).length === 0
                cells.push(!isEmpty ? cell : {value: '', t: ''})
            })
            columnsWithDataByKey[originDocId] = [...(columnsWithDataByKey[originDocId] ?? []), {...column, cells }]
        })
    })

    return Object.entries(columnsWithDataByKey)
}

function getColumnWidth(column){
    const numFmt = (column.cells.find(c => c.numFmt) || {}).numFmt
    const columnTitleLength = column.fieldName.length
    if (numFmt) {
        return Math.max(columnTitleLength, numFmt.length) + 10
    }
    else {
        const cellsValuesLength = column.cells.map(c => (((c.excelValue && c.excelValue.length) || c.value || '').length || 0))

        const maxCellWidth = Math.max(columnTitleLength, ...cellsValuesLength) + 10

        return Math.min(maxCellWidth, 60)
    }
}

function buildColumnsHeader(columns){

    return columns.map(column => ({
        header: column.fieldName, key: column.fieldName,
        width: getColumnWidth(column),
        style: {
            alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
            numFmt: (column.cells.find(c => c.numFmt) || {}).numFmt
        }
    }))
}

function buildRelatedColumnsHeader(relatedColumnsWithData, dataType){
    if(!relatedColumnsWithData.length) return []

    const anyColumns = relatedColumnsWithData[0][1]
    return anyColumns.map(column => ({
        header: generateColumnKey(column.fieldName, dataType), key: generateColumnKey(column.fieldName, dataType),
        width: getColumnWidth(column),
        style: {
            alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
            numFmt: (column.cells.find(c => c.numFmt) || {}).numFmt
        }
    }))
}

export default function exportTableToExcel(data, columnsWithoutData, renderCell, fileName, seetTitle, withRelated, dataTypesEntries){

        const workbook = new Excel.Workbook();
        workbook.creator = 'Tuqqi';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        workbook.properties.date1904 = true;
        workbook.calcProperties.fullCalcOnLoad = true;
        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]
        const correctTitle = (seetTitle ?? '').replace(/[*?:\[\]\\\/]/gi, '').trim()
        const worksheet = workbook.addWorksheet(correctTitle);
        worksheet.pageSetup.margins = {
            left: 0.7, right: 0.7,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };

        //setting header
        const columnsWithData = buildColumnsWithData(columnsWithoutData, data, renderCell)
        worksheet.columns = buildColumnsHeader(columnsWithData)
        withRelated && dataTypesEntries.forEach((entry) => {
            const [dataType, additional] = entry
            const relatedColumnsWithData = buildRelatedColumnsWithData(additional.columns, additional.items, renderCell)
            const relatedHeader = buildRelatedColumnsHeader(relatedColumnsWithData, dataType)
            worksheet.columns = [...worksheet.columns, ...relatedHeader]
        })

        //generate data
        const finishItems = [] //[{columns: []}]
        data.forEach((doc) => {
            const {docId} = doc
            const columnsBase = calcColsBase(doc, renderCell, columnsWithoutData)
            if(!withRelated) {
                finishItems.push({columns: columnsBase})
                return
            }
            if(withRelated && dataTypesEntries.length === 1) {
                const [, additional1] = dataTypesEntries[0]
                const entry = additional1.items.find(([originalDocId]) => originalDocId === docId)
                if(!entry) {
                    finishItems.push({columns: columnsBase})
                    return
                }
                entry[1].forEach((relatedDoc) => {
                    const columnsRelated = calcColsBase(relatedDoc, renderCell, additional1.columns)
                    finishItems.push({columns: [...columnsBase, ...columnsRelated]})
                })
                return
            }
            if(withRelated && dataTypesEntries.length > 1) {
                const [, additional1] = dataTypesEntries[0]
                const [, additional2] = dataTypesEntries[1]
                const entry1 = additional1.items.find(([originalDocId]) => originalDocId === docId)
                const entry2 = additional2.items.find(([originalDocId]) => originalDocId === docId)

                if(!entry1 && !entry2) {
                    finishItems.push({columns: columnsBase})
                    return
                }
                if(!entry1 && !!entry2) {
                    const columnsDummy = new Array(additional1.columns.length).fill({value: '', t: ''})
                    entry2[1].forEach((relatedDoc) => {
                        const columnsRelated = calcColsBase(relatedDoc, renderCell, additional2.columns)
                        finishItems.push({columns: [...columnsBase, ...columnsDummy, ...columnsRelated]})
                    })
                    return
                }
                if(!!entry1 && !entry2) {
                    entry1[1].forEach((relatedDoc) => {
                        const columnsRelated = calcColsBase(relatedDoc, renderCell, additional1.columns)
                        finishItems.push({columns: [...columnsBase, ...columnsRelated]})
                    })
                    return
                }
                entry1[1].forEach((relatedDoc) => {
                    entry2[1].forEach((relatedDoc1) => {
                        const columnsRelated1 = calcColsBase(relatedDoc, renderCell, additional1.columns)
                        const columnsRelated2 = calcColsBase(relatedDoc1, renderCell, additional2.columns)
                        finishItems.push({columns: [...columnsBase, ...columnsRelated1, ...columnsRelated2]})
                    })
                })
            }
        })

        finishItems.forEach((row) => {
            const {columns} = row
            worksheet
                .addRow(columns.map(c => c.excelValue || c.value))
                .eachCell(c => {
                    const columnIndex = c.col - 1
                    const cellData = columns[columnIndex]

                    if (cellData.style) {
                        c.style = {
                            ...c.style,
                            ...cellData.style
                        }
                    }
                })
        })
        
        workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer]), fileName?`${fileName}.xlsx`: 'export.xlsx')
        })
}

const generateColumnKey = (fieldName, dataType) => `${fieldName}(${dataType})`