import React from 'react'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = theme => ({

    header: {
        margin: 0
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    },
    content: { "fontFamily": "\"Rubik\", Helvetica, Arial, serif", "fontWeight": "400", "fontStyle": "normal", "fontSize": "14.0px", "color": "rgba(33, 33, 45, 1.0)", "textAlign": "center", "letterSpacing": "0.25px", "lineHeight": "20.0px" },
    link: { "fontFamily": "\"Rubik\", Helvetica, Arial, serif", "fontWeight": "400", "fontStyle": "normal", "fontSize": "14.0px", "color": "rgba(33, 33, 45, 1.0)", "textAlign": "center", "letterSpacing": "0.25px", "lineHeight": "20.0px" }
});

const FavoriteKanaban = (props) => {
    return <div data-intrcm-id="GroupKanbanPlaceholder_npaunnfm" style={{
        position: 'absolute',
        top: '300px',
        left: '40%'
    }}>
        <img src={'/resources/placeholders/illustration_Kanban.svg'} ></img><br />
        <div data-intrcm-id="GroupKanbanPlaceholder_rn1wud81" style={{ textAlign: 'center', width: 400, marginLeft: 25, lineHeight: 1.4 }}>
            <span className={props.classes.content}>{props.t('Seems like there are no items yet. In Favorites you can hold all favorite items')},<b> <a href={"https://intercom.help/tuqqi/en/articles/3914988-introduction-to-your-favorite-page"} target="_blank" style={{ color: 'rgba(107, 19, 246, 1.0)' }} className={props.classes.link} >{props.t('Learn more')}</a> </b>on how to add and manage this view.</span>
        </div>
    </div>
}


const GroupKanban = (props) => {
    return <div data-intrcm-id="GroupKanbanPlaceholder_pnr51q6h" style={{
        position: 'absolute',
        top: '300px',
        left: '50%'
    }}>
        <img src={'/resources/placeholders/illustration_Kanban.svg'} ></img><br />
        <div data-intrcm-id="GroupKanbanPlaceholder_nnvxljfq" style={{ textAlign: 'center', width: 400, marginLeft: 25, lineHeight: 1.4 }}>
            <span className={props.classes.content}>{props.t('In the group kanban view you can manage projects, track progress and get a full overview of the team operations')}<br /> <b><a href={"https://intercom.help/tuqqi/en/articles/3868804-introduction-the-kanban-view-inside-a-group"} target="_blank" style={{ color: 'rgba(107, 19, 246, 1.0)' }} className={props.classes.link}>{props.t('Learn more')}</a></b> on how to use this view.</span>

        </div>
    </div>
}

export default withStyles(styles)((props) => {
    const { classes, isFavorites } = props;
    const { t, i18n } = useTranslation('feedWidgets');


    if (isFavorites) {
        return <FavoriteKanaban classes={classes} t={t} />;

    }

    return <GroupKanban classes={classes} t={t} />;



})