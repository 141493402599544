import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';

const styles = theme => ({
    container: {
        "padding": "0px 4px",
        "width": "auto",
        borderRadius: 4,
        backgroundColor: '#f3f6fb',
        margin:'0px 2px',
        
        '&:first-child':{
            marginLeft:0
        },
        '&:last-child':{
            marginRight:0
        }
    },

})


class FooterKanbanCardItemContainer extends PureComponent {
    render() {
        const { classes, children } = this.props

        return (
            <Grid container direction='row' wrap='nowrap' alignItems='center' className={classes.container}>
                {children}
            </Grid>

        )

    }
}

export default withStyles(styles)(FooterKanbanCardItemContainer);