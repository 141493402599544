
import { withStyles, Button, Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PollInputOption from './PollInputOption';
import { changeInputPoll } from '../../../../actions/inputActions';

const styles = theme => ({
});

const NUMBER_OF_CHARACTERS = 118

class PollPreview extends React.Component {
    getEmptyInput = () => {
        return { optionText: '', optionImg: '' }
    }

    componentDidMount() {
        const initOptions = [
            this.getEmptyInput(),
            this.getEmptyInput()
        ]

        this.props.changeInputPoll({ options: initOptions })
    }

    onChangeMultiple = () => {
        const { changeInputPoll, poll } = this.props;
        changeInputPoll({ isMultiple: !poll.isMultiple })
    }

    onChangeAddOwn = () => {
        const { changeInputPoll, poll } = this.props;
        changeInputPoll({ isAddOwn: !poll.isAddOwn })
    }

    onChange = (idx) => (e) => {
        const { changeInputPoll, poll } = this.props;
        let text = e.target.value;

        const options = [...poll.options];

        if (idx < options.length) {
            if( text.length > NUMBER_OF_CHARACTERS ){
                text = text.substr(0, NUMBER_OF_CHARACTERS)
            }

            options[idx].optionText = text;

            changeInputPoll({ options })
        }
    }

    onDeleteOption = (idx) => {
        const { changeInputPoll, poll } = this.props;
        const options = [...poll.options];

        options.splice(idx, 1);

        changeInputPoll({ options })
    }

    addImage = idx => file => {
        const { changeInputPoll, poll } = this.props;
        const options = [...poll.options];

        try{
            const parsedFile = JSON.parse(file);

            options[idx].optionImg = parsedFile.fileId;

            changeInputPoll({ options })
        }
        catch (e){
            
        }
    }

    addOption = () => {
        const { changeInputPoll, poll } = this.props;
        changeInputPoll({ options: [...poll.options, this.getEmptyInput()] })
    }

    render() {
        const { userData, poll, t, cloudfront } = this.props;
        const { options, isMultiple, isAddOwn } = poll;

        return <div data-intrcm-id="PollPreview_cpoevig4" style={{ padding: '0px 16px 20px' }} >
            {options.map((option, idx) => {
                return <PollInputOption 
                    idx={idx}
                    option={option}
                    onDeleteOption={this.onDeleteOption} 
                    onChange={this.onChange} 
                    addImage={this.addImage} 
                    cloudfront={cloudfront}
                />
            })}

            <Button onClick={this.addOption} color="primary" style={{ textTransform: 'none' }}>
                {t("Add option")}
            </Button>

            <div data-intrcm-id="PollPreview_fpe04uzw">
                <FormControlLabel label={t("Allow people to choose multiple options")}
                    control={ <Switch checked={isMultiple} onChange={this.onChangeMultiple} /> } 
                />
                <FormControlLabel label={t("Allow people to add their own options")}
                    control={ <Switch checked={isAddOwn} onChange={this.onChangeAddOwn} /> } 
                />
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    oldUiInput: state.oldUiInput.document,
    poll: state.oldUiInput.poll,
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => ({ 
    dispatch,
    changeInputPoll: (poll) => dispatch(changeInputPoll(poll))
})

export default compose(
    withStyles(styles),
    withTranslation('postInput'),
    connect(mapStateToProps, mapDispatchToProps)
)(PollPreview);