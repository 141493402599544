import i18n from "i18next";
import { UIResources, withOwnInternationalizationOptions } from '@tuqqi/common';
import { initReactI18next } from "react-i18next";
import Locize from 'i18next-locize-backend';

UIResources['en'] = UIResources['en-US']
// UIResources['he-IL'] = UIResources['he-il']
// UIResources['es-AR'] = UIResources['es-ar']
const isProd = process.env.NODE_ENV === 'production';

const internationalizationOptions = withOwnInternationalizationOptions({ resources: UIResources })

const locizeOptions = isProd ? {} : {
  backend: {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID_UI,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY_UI,
    referenceLng: 'en-US'
  },
  saveMissing: true,
  updateMissing: true
};


const i18nOptions = {
  ...internationalizationOptions,
  ...locizeOptions,
  debug: !isProd,
  react: {
    useSuspense: false
  }
}

i18n
  .use(Locize)
  .use(initReactI18next)// passes i18n down to react-i18next
  .init(i18nOptions);

export default i18n;