import { withStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getDefaultLaunchpadOptions } from '../../../../../utils/launchpadNavigation/getDefaultLaunchpadOptions';
import { onClickDefaultLaunchpadOption } from '../../../../../utils/launchpadNavigation/launchpadNavigation';
import LaunchpadOption from '../Common/LaunchpadOption';
import { useNewActionCenter } from '../../../../../utils/useNewTable';
import { shouldUseScheduler, useShouldUseScheduler } from '@tuqqi/web';

const styles = (theme) => ({
    container: {
        marginTop: 26,
        position: 'relative',
        border: 'solid 1px #D7D7DB',
        boxSizing: 'border-box',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        color: '#4A4A4F'
    },
    titleWrap: {
        position: 'absolute',
        top: -8,
        backgroundColor: '#fff',
        paddingRight: 12,
        paddingLeft: 12,
        left: 12
    },
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 0,
        color: '#4A4A4F'
    },
    optionsWrap: {
        width: '100%',
        marginLeft: 4,
        marginRight: 4,
    },
    options: {
        minHeight: 80,
        padding: 24,
        paddingLeft: 1,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 'auto',
        display: 'grid',
        'grid-row-gap': '24px',
        'grid-template-columns': '92px 92px 92px 92px',
    }
})

function _DefaultLaunchpadOptions(props) {
    const {classes, dispatch, orgUid, isFocused} = props
    const [t] = useTranslation("search")

    const onClickDefaultOption = useCallback((o) => {
        onClickDefaultLaunchpadOption(o.referenceUid, dispatch)
    }, [])
    const {value: useScheduler} = useShouldUseScheduler('launchpad-sch', true)


    const settings = useMemo(() => {
        return {
            withActionCenter: useNewActionCenter(orgUid),
            withScheduler: useScheduler,
        }
    }, [orgUid, isFocused, useScheduler])

    const options = useMemo(() => getDefaultLaunchpadOptions(t, settings), [settings, settings?.withActionCenter, settings?.withScheduler])

    return (
        <div data-intrcm-id="DefaultOptions_aoak0k4k" className={classes.container} >
            <div data-intrcm-id="DefaultOptions_iipi4zjo" className={classes.titleWrap}>
                <div data-intrcm-id="DefaultOptions_gi2bb3jm" className={classes.title}>{t("Important links")}</div>
            </div>
            <div data-intrcm-id="DefaultOptions_22dgpid9" className={classes.optionsWrap} >
                <div data-intrcm-id="DefaultOptions_qnovyo7m" className={classes.options} >
                    {options.map((opt, i) => (
                        <LaunchpadOption onClick={onClickDefaultOption} key={opt.id + '' + i } option={opt} />
                    ))}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatch
})
const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
})

export const DefaultLaunchpadOptions = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_DefaultLaunchpadOptions)

