import React, { useCallback } from 'react'

export const useOrgMenuResolvers = () => {

    const getFirstLayer = useCallback((orgMenuItems) => {
        return orgMenuItems.filter((x) => x.categoryId === 0 || x.type !== 0 && !orgMenuItems.find(_x => _x.id === x.categoryId))
    }, [])

    const getSubLayer = useCallback((orgMenuItems, item) => {
        if(item.type !== 0) return []
        return orgMenuItems.filter((it) => it.type !== 0 && it.categoryId === item.id)
    }, [])

    return {
        getFirstLayer,
        getSubLayer,
    }
}