import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { allCollectionMembers } from '../groupUtils';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { showConfirm, showSnackbar } from '../../../../actions/appActions';
import { DATA_FEED_UPDATE, RESULT_CHANNEL_EMPTY, USER_DATA_LOAD } from '../../../../actionTypes/actionTypes';
import { joinToPublicGroup } from '../groupUtils';
import SubscribeIcon from '../../../components/Common/Icons/UserIcons/SubscribeIcon';

const styles = theme => ({

    buttonText: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "color": theme.newUI.color.gray,
        "textTransform": "none"

    },
    button: {
        "width": "fit-content",
        "marginLeft": "8px",
        "minWidth": "unset",
        bottom: 8,
        position: 'fixed'
    }, 

    unSubscribeButton: {
        width: 149,
        height: 40,
        fontSize: 14,
        textTransform: 'uppercase',
        color: '#fff',
        boxShadow: 'none',
        backgroundColor: theme.customColors.primary.main
    },

    unsubButtonHolder: {
        cursor: 'pointer',
        margin: '0 auto',
        width: 46,
        height: 46,
        backgroundColor: '#f0f0fa',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%'
    },
    add: {
        transform: 'rotate(45deg)'
    }

});



class LeaveGroup extends Component {

    leave = () => {

        const { groupInfo, dispatch, groupService, userSettingsService, profileData, isFromNewDrawer = false, isDrawerOpen = true } = this.props;

        // Check if user is the last admin

        const groupAdmins = groupInfo.collectionGroup.adminIds

        if (groupAdmins.length === 1 && groupAdmins[0] === profileData.id) {
            dispatch(showConfirm(("You are the Admin of the group; therefore, you can't leave it. Please contact Tuqqi support"), () => { }, true, true))
        } else {



            const action = () => {


                dispatch(showSnackbar('Leaving...'));

                groupService.leaveGroup(groupInfo.collectionUid)
                    .then((currGroup) => {
                        dispatch(showSnackbar('Leave!'));
                        userSettingsService.getUserData().then((userData) => {
                            dispatch({ type: USER_DATA_LOAD, data: { ...userData } });
                            dispatch(push('/feed'));
                            dispatch({ type: DATA_FEED_UPDATE });
                            dispatch({ type: RESULT_CHANNEL_EMPTY });
                        })
                    })
            }

            dispatch(showConfirm(("Are you sure you want to Leave this group?"), action))
        }

    }

    join = () => {
        const { searchQuery, dispatch, queryService, groupService, userSettingsService } = this.props;

        const { groupUid } = this.props.groupInfo.collectionGroup

        joinToPublicGroup(groupUid, searchQuery, dispatch, queryService, groupService, userSettingsService)
    }

    canUserLeaveTheGroup() {
        const { profileData, groupInfo } = this.props;


        if (groupInfo.collectionGroup.isClosed ||
            allCollectionMembers(groupInfo).some(u => u.auth0_id === profileData.auth0_id)) {
            return true
        }

        return false
    }

    render() {
        const { classes, t, isFromNewDrawer, isDrawerOpen } = this.props;

        const canUserLeaveTheGroup = this.canUserLeaveTheGroup();

        if(isFromNewDrawer) {
            if(!isDrawerOpen) {
                return(
                    <div data-intrcm-id="LeaveGroup_4eglyx28" className={classes.unsubButtonHolder}  onClick={canUserLeaveTheGroup ? this.leave : this.join}>
                        <SubscribeIcon isSelected className={`${canUserLeaveTheGroup ? classes.add : ''}`} />
                    </div>
                )
            }
           return(
           <Button
                onClick={canUserLeaveTheGroup ? this.leave : this.join}
                className={classes.unSubscribeButton}
                variant="contained"
            >
                {t(canUserLeaveTheGroup ? 'Leave group' : 'Join group')}
            </Button>)
        }
        return (
            <Button
                onClick={canUserLeaveTheGroup ? this.leave : this.join}
                classes={{ label: classes.buttonText, root: classes.button }}
            >
                {t(canUserLeaveTheGroup ? 'Leave group' : 'Join group')}
            </Button>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    profileData: state.userData.profileData,
    groupInfo: state.results.channel,
    groupService: state.services.groupService,
    userSettingsService: state.services.userSettingsService,
    queryService: state.services.queryService,
    searchQuery: state.search

})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(withStyles(styles),
    withTranslation('groupDrawerTranslate'),
    withErrorCatcher("Failed loading Group Info, Please Contact Tuqqi Support"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LeaveGroup);