import { CircularProgress, Dialog, withStyles } from '@material-ui/core';
import filestack from 'filestack-js';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { handleFilestackPicker } from '../../../utils/filesUtils.js';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const styles = theme => ({
	root: {
		backgroundColor: 'transparent',
		boxShadow: 'none',
		overflowX: 'hidden',
		overflowY: 'hidden'
	},
	progress: {
		margin: 'auto'
	}
})

class DropZone extends Component {
	constructor(props) {
		super(props);

		this.state = { isLoading: false }
		this.filestackClient = this.initFileStackClient()
	}

	initFileStackClient = () => {
		if(!this.props.filestack.write.policy) return
		const apikey = 'AG1Zm0R1vQ3yoXm9IzMcxz';
		const filestackClient = filestack.init(apikey);
		filestackClient.options = { maxFiles: 100, storeTo: { location: 's3' } }
		filestackClient.setSecurity(this.props.filestack.write)

		return filestackClient;
	}

	onFinishUploading = () => {
		this.setState({ isLoading: false });
	}

	needSkeepSelectors = ['[id^="preview-comment-"]', '[id^="connectors-create-form-settings"]', '[id^="connectors-edit-form-settings"]', '[id^="main-chat-wrap"]']

	 isSkipDefaultDrop = (event) => {
		 const ret = this.needSkeepSelectors.some(selectors => {
		const ignoreElement = document.querySelectorAll(selectors);
		const isClickInsideElement = Array.from(ignoreElement ?? []).find(el => el.contains(event.target))
		if(isClickInsideElement) return true
		return false
		})

		return ret
	}


	onDragEnter = (e) => {
		if(this.isSkipDefaultDrop(e)) return
		 e.preventDefault()
		 e.stopPropagation()
		}

	drop = (event) => {
		if(this.isSkipDefaultDrop(event)) return
		event.preventDefault();
		event.stopPropagation();
		var uploades = [];
	
		if (event.target) {
			const { files } = event.dataTransfer;

			for (var i = 0; i < files.length; i++) {
				uploades.push(this.filestackClient.upload(files[i]));
			}

			this.setState({ isLoading: true })

			Promise.all(uploades).then(res => {
				this._handleFilestackPicker(res);
			})
		}
	}

	onDragLeave = (e) => {
		 e.preventDefault()
		 e.stopPropagation()

		}

	onDragOver = (e) => {
			 e.preventDefault()
			 e.stopPropagation()
			}
	
	_handleFilestackPicker = (res) => {
		this.onFinishUploading()
		const { dispatch, collectionResult, searchQuery, inputDocument, inputService, t, oldUiInput } = this.props

		handleFilestackPicker(res, dispatch, collectionResult, searchQuery, inputDocument, inputService, t, oldUiInput)
	}

	render() {
		const { children, classes, filestack } = this.props;
		if(!filestack.write.policy) return children

		return <div data-intrcm-id="DropZone_qdcnce99"
			onDragEnter={this.onDragEnter}
			onDragLeave={this.onDragLeave}
			onDrop={this.drop}
			onDragOver={this.onDragOver}
		>
			{children}

			<Dialog
				classes={{ paper: classes.root }}
				open={this.state.isLoading}
				transition={Transition}
			>
				<CircularProgress className={classes.progress} size={60} />
			</Dialog>
		</div>
	}
}

const mapStateToProps = (state) => ({
	filestack: state.globalData.filestack,
	inputDocument: state.oldUiInput.document,
	oldUiInput: state.oldUiInput,
	collectionResult: state.results.channel,
	searchQuery: state.search,
	...state.services
})

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch
	}
}

export default compose(
	withTranslation('postInput'),
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	))(DropZone);