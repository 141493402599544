import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { finishedUpdatingSpecificItem } from '../../../actions/dataActions';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer';
import { dispatchDataToNewUIStore } from '../../components/Common/Utils/newUIMigrationUtil';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import TableView from '../groups/table/TableView';
import {  filterData, loadMore, loadMoreQuery, withAggDataTypes } from '../search/searchUtils';
import { defaultDataTypes as getDefaultDataTypes } from '../../components/Common/Utils/defaultDataTypes';
import { fetchDocuments } from '@tuqqi/common';
import { SERVER_API } from '../../../utils/serverAPI';
import { themeConstants } from '../../../theme.js';
import { managerRemovedItemsLocal } from '@tuqqi/web';

const styles = theme => ({

    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    container: {
        height: '100%',
        overflow: 'hidden',
        marginLeft: themeConstants.navigationDrawerWidth,
    },
    containerSmall: {
        height: '100%',
        overflow: 'hidden',
        marginLeft: themeConstants.navigationDrawerSmallWidth
    },
    titleContainer: {
        height: 32,
        margin: '5px 0px 30px 0px',
    },
    title: {
        fontFamily: "'Montserrat'",
        fontSize: 16,
        fontWeight: 300,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.5,
        letterSpacing: '0.2px',
        textAlign: 'center',
        color: '#302f47'
    },


});

class AllTasksView extends Component {


    constructor(props) {
        super(props)

        this.state = {
            firstAllResultsLength: {
                init: false,
                value: 1000
            },
            isLoading: false,
            searchResultCount: 0,
            isLoadingMore: false,
            results: [],
        }

        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadMore = loadMore.bind(this);

    }

    isLocalFilterPresented = (props) => {
        const { localFilter = {} } = props
        return Object.keys(localFilter.filters).length > 0
    }

    loadData = (nextProps) => {

        const props = nextProps ? nextProps : this.props

        const { searchQuery, dispatch, queryService } = props;

        this.setState({ isLoading: true })
        const withLocalFilter = this.isLocalFilterPresented(nextProps)


        // Set the page = 0
        let _searchQuery = { ...searchQuery, page: 0 }
        
        const dateSchedule = {}
        if(!window.location.pathname.includes('calendar')) {
            dateSchedule.dateScheduleFrom = null
            dateSchedule.dateScheduleTo = null
            _searchQuery = { ..._searchQuery, ...dateSchedule }
        }

        dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { page: 0, ...dateSchedule, size: _searchQuery.size < 25 ? 25 : _searchQuery.size} });
        if(withLocalFilter) {
            _searchQuery.size = this.state?.firstAllResultsLength?.value ?? _searchQuery.size
        }

        return queryService.Search(_searchQuery, null, true)
            .then((result) => {
                let hasMore = true
                if (result.count <= (_searchQuery.page + 1) * _searchQuery.size) {
                    hasMore = false;
                }
                const data = result.data
                //TODO: test it
                const exractAggregationFromResult = (propertyName) => (result.aggregations[propertyName] && result.aggregations[propertyName].Items) || []

                const dataTypes = exractAggregationFromResult('dataTypes')
                this.loadItemsStatus().then(values => {
                    const allLabels = values[0];
                    const docStates = values[1];


                    const defaultStatus = {
                        status: 'New',
                        taskStatusColor: 0
                    }
                    const enrichedData = data
                    .filter(item => !managerRemovedItemsLocal.getItems().includes(item?.Value?.Source?.docId ?? ''))
                    .map(item => {

                        // Get labels
                        let labels = []
                        if (item.Value.Source.collectionLabels) {
                            labels = item.Value.Source.collectionLabels.map(
                                docLabel => {
                                    const [docCollectionUid, docLabelUid] = docLabel.split(':')
                                    // find label 
                                    const label = allLabels.find(x => x.labelUid === docLabelUid)
                                    if (label) {
                                        return label
                                    }
                                }
                            )

                        }

                        let status = docStates.find(x => x.docId === item.Key);

                        if (!status) {
                            status = defaultStatus
                        }
                        item.Value.Source = { ...item.Value.Source, ...status, labels }

                        return item
                    })

                    if(this?.state?.firstAllResultsLength && !this.state.firstAllResultsLength.init) {
                        this.setState({firstAllResultsLength: {
                                value: result.count === 0 ? this?.state?.firstAllResultsLength.value : result.count,
                                init: true,
                            }
                        })
                    }

                    const relatedDocs = exractAggregationFromResult('related')

                    this.setState({
                        results: enrichedData,
                        searchResultCount: result.count,
                        isLoading: false,
                        dataTypes,
                        experts: exractAggregationFromResult('experts'),
                        relatedTags: exractAggregationFromResult('hashtag'),
                        hasMore,
                        relatedDocs
                    });

                    dispatch({ type: 'DATA_UPDATED' });
                    dispatchDataToNewUIStore(enrichedData, dispatch)
                    
                    const correctDataTypes =  [{
                        Key: 'All',
                        DocCount: result.count
                    }, ...dataTypes]

                    // if exist filter of data-types in _searchQuery 
                    // the result.aggregations.dataTypes is not on all of the data
                    // so don't add it to the state
                    if (!withAggDataTypes(_searchQuery)) {
                        const relateDocsdIds = relatedDocs.map(rel => rel.Key)
                        dispatch(fetchDocuments(SERVER_API)(relateDocsdIds))

                        dispatch({
                            type: 'RESULT_FILTERS_UPDATE', results: {
                                dataTypes: correctDataTypes,
                                relatedDocs: relatedDocs
                            }
                        
                        });
                    }
                })



            })
    }

    calcDataTypes = (resultCount) => {
        const {customInputs} = this.props
        const defaultDataTypes = getDefaultDataTypes()
        const dataTypes = [...defaultDataTypes, ...customInputs].map((m) => ({
            Key: m.customDataMapping.dataTypeFilter,
            DocCount: 0
        }))
        return [{ Key: 'All', DocCount: resultCount }, ...dataTypes]
    }

    loadItemsStatus = () => {
        const { groupService } = this.props
        return Promise.all([
            groupService.getAllLabels(),
            groupService.getItemsStatus()])

        // return groupService.getItemsStatus();


    }

    loadReports = () => {
        const { userSettingsService } = this.props;
        userSettingsService.getUserSavedReportsForMyPage().then((result) => {
            if (!result) return
            const savedReports = result.filter(r => r.collectionId === 0)
            this.props.dispatch({
                type: 'SET_SAVED_REPORTS',
                savedReports
            })
        })
    }

    checkQueryStringParam = () => {
        var url = new URL(window.location);
        var searchParams = new URLSearchParams(url.search);
        
        return searchParams.has("reportId")
    }

    componentDidMount() {
        this.loadReports();
        if(!this.checkQueryStringParam){
            this.loadData();
        }
        

    }

    updateSpecificItemInState = (docId) => {
        const { queryService, dispatch } = this.props;

        const query = {
            itemsIds: [docId],
            page: 0,
            filter: {},
            tags: [],
            size: 1,
            AggType: 'FLAT',
        }

        queryService.SearchByIds(query).then((results) => {

            if (results.data[0] && results.data[0].Value) {
                var newResults = [...this.state.results]

                var result = newResults.find(item => item.Key === docId)
                if(result) {
                    result.Value = results.data[0].Value
                    this.setState({ results: newResults.filter(item => !managerRemovedItemsLocal.getItems().includes(item?.Value?.Source?.docId ?? '')) })
                }
                dispatch(finishedUpdatingSpecificItem(docId));
            }

        })
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            const withLocalFilter = this.isLocalFilterPresented(nextProps)

            this.filterData(nextProps.oldUiData.dataUpdate, nextProps, false, withLocalFilter);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }

        if (nextProps.oldUiData.needsUpdate) {
            if (this.state.isLoading === false) {
                this.loadData(nextProps);
            }
        }
    }

    saveReport = (reportName) => {
        const { userSettingsService, searchQuery, localFilter } = this.props;
        const {selectedFieldSources, filters = {}} = localFilter
        let resQuery = searchQuery
        resQuery.filters = filters
        if(!!selectedFieldSources && !!selectedFieldSources.length) {
            resQuery = {
                query: searchQuery,
                selectedFieldSources,
                filters,
            }
        }
        const report = {
            reportName: reportName,
            reportJSON: JSON.stringify(resQuery),

        }

        userSettingsService.saveUserReportForMyPage(report).then((result) => {

            this.loadReports();
        })

    }

    render() {
        const { classes, isDrawerOpen, savedReports } = this.props
        const { isLoadingMore, isLoading, results, searchResultCount, firstAllResultsLength } = this.state

        return (
            <CategoryViewOnlyLeftDrawer

            >
                <div data-intrcm-id="MyPageTasksx_aj97ezqr" className={isDrawerOpen ? classes.container : classes.containerSmall}>
                    <TableView isLoading={isLoading} results={results}
                        firstAllResultsLength={firstAllResultsLength}
                        savedReports={savedReports}
                        isDrawerOpen={isDrawerOpen}
                        searchResultCount={searchResultCount}
                        loadMore={this.loadMoreQuery}
                        isLoadingMore={isLoadingMore}
                        saveReport={this.saveReport} 
                        isFromMyPageTasks
                        />
                </div>
            </CategoryViewOnlyLeftDrawer>

        );
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
    searchQuery: state.search,
    oldUiData: state.oldUiData,
    localFilter: state.localFilter,
    customInputs: state.data.globalData.customInputMappings.data,
    savedReports: state.localFilter?.savedReports ?? [],

    ...state.services,
})



export default compose(withStyles(styles),
    withTranslation('myReportsView'),
    withErrorCatcher("Failed Loading User, Please Contact Tuqqi support"),
    connect(
        mapStateToProps
    ))(AllTasksView);
