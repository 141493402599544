import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";


const styles = theme => ({
    container:{ 
        height:126,
        // backgroundColor: '#f7f7f7', 
        // padding: '10px 12px',
        cursor:'pointer'
     },

    oneRow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    imageContainer:{ 
        height: 126, 
        flex: '0 0 126px' 
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    textContainer: {
        padding: '10px 16px',
        backgroundColor: '#f7f7f7',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden'
    },

    someRows:{
        maxHeight: 54,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
    },




    link: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "16px",
        "letterSpacing": "normal",
        "color": "#090909"
    },
    title: {
        "fontFamily": "Roboto",
        margin: '4px 0px 1px 0px',
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909"
    }

});

class BookmarkTemplateLeftImage extends PureComponent {

    render() {
        const { classes, result, openLink, link } = this.props;
        const { previewDescription, title, previewImgUrl } = result;

        return <Grid container wrap='nowrap' direction='row' className={classes.container} onClick={openLink}>
            <Grid item className={classes.imageContainer}>
                <img className={classes.image} src={previewImgUrl} />
            </Grid>
            <Grid wrap='nowrap' direction='column' item container className={classes.textContainer} >
                <Grid item className={classnames(classes.link, classes.oneRow)} dir="auto" >
                    {link}
                </Grid>
                <Grid item className={classnames(classes.title, classes.oneRow)} dir="auto" >{title}</Grid>
                <Grid item className={classnames(classes.previewDescription, classes.someRows)} dir="auto" >
                    {previewDescription}
                </Grid>

            </Grid>
        </Grid>

    }
}

export default compose(withStyles(styles))(BookmarkTemplateLeftImage);
