import { Icon } from '@iconify/react';
import { memo, useEffect, useState } from 'react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { withStyles } from '@material-ui/core/styles';
import { Divider, IconButton } from '@material-ui/core';
import ChatRoomAttachment from './ChatRoomAttachment';
import ChatRoomOneParticipant from './ChatRoomOneParticipant';
import ChatRoomGroupParticipant from './ChatRoomGroupParticipant';
import React from 'react'
import compose from 'recompose/compose';
import { isDiscussions, searchService } from '@tuqqi/common';
import { isEqualJson } from '../../../../utils/commonHelpers';
import { managerItemWatchers } from '@tuqqi/web';
import ChatRoomGroupParticipantNew from './ChatRoomGroupParticipantNew';
import { GroupMessages } from '../chatUtils';
import ChatRoomGroupMembers from './ChatRoomGroupMembers';

const styles = theme => ({
  root: {
    height: '100%',
    marginLeft: 'auto',
    marginRight: '0px',
    width: 320,
    flexShrink: 0,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    borderLeft: `solid 1px ${theme.palette.divider}`,
    borderTop: `solid 0px transparent`,
    transition: theme.transitions.create('width')
  },
  toggleButton: {
    borderRight: 0,
    display: 'flex',
    overflow: 'hidden',
    position: 'absolute',
    alignItems: 'center',
    top: theme.spacing.unit * 1,
    left: theme.spacing.unit * (-4) - 2,
    width: theme.spacing.unit *(4),
    height: theme.spacing.unit *(4),
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: theme.shape.borderRadius
  },
  btnToggle: {
    borderRadius: 'unset',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius
  }
});


const ChatRoom =  memo(function ChatRoom({ attachments, participants, classes, chatType, user, docId, selected }) {
  const [openSidebar, setOpenSidebar] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [showAttachment, setShowAttachment] = useState(true);
  const [showParticipants, setShowParticipants] = useState(true);
  const isGroup = isDiscussions(chatType)
  const isGroupMessages = chatType === GroupMessages
  const [document, setDocument] = useState(null)

  const oldWay = document === null

  useEffect(() => {
    if(!isGroup || !docId) {
      setDocument(null) 
      return
    }

    (async () => {
      try {
        const updatedItem = await searchService.searchItem(docId, {returnFullData: true})
        if(!updatedItem) {
          setDocument(null)
          return
        }
        setDocument(updatedItem)
        managerItemWatchers.fetch(updatedItem.docId)
      } catch (error) {
        setDocument(null)
      }
    })()
  }, [docId, isGroup])


  const GGG = oldWay ? 
    (
      <ChatRoomGroupParticipant
        openSidebar={openSidebar}
        participants={participants}
        isCollapse={showParticipants}
        onCollapse={() => setShowParticipants((prev) => !prev)}
      />
    ) : (
      <ChatRoomGroupParticipantNew 
        openSidebar={openSidebar}
        isCollapse={showParticipants}
        onCollapse={() => setShowParticipants((prev) => !prev)}
        document={document}
      />
    )

  const GroupM = !isGroupMessages || !selected ? null : <ChatRoomGroupMembers 
    openSidebar={openSidebar}
    isCollapse={showParticipants}
    onCollapse={() => setShowParticipants((prev) => !prev)}
    colUid={selected}
  />

  return (
    <div data-intrcm-id="ChatRoom_yiplosjp"
    className={classes.root}
      style={{
        ...(!openSidebar && {
          width: 0,
          '& > *': { overflow: 'hidden' }
        })
      }}
    >
      <div data-intrcm-id="ChatRoom_e49cmjoq" className={classes.toggleButton}>
        <IconButton className={classes.btnToggle} onClick={() => setOpenSidebar(!openSidebar)}>
          <Icon
            width={16}
            height={16}
            icon={openSidebar ? arrowIosForwardFill : arrowIosBackFill}
          />
        </IconButton>
      </div>

      {isGroupMessages ? GroupM :
       isGroup ? GGG : (
        <div data-intrcm-id="ChatRoom_ql9hw6qw">
          <ChatRoomOneParticipant
            user={user}
            isGroupMessages={isGroupMessages}
            chatType={chatType}
            openSidebar={openSidebar}
            participants={participants}
            isCollapse={showInfo}
            onCollapse={() => setShowInfo((prev) => !prev)}
          />
        </div>
      )}
      <Divider />

      <ChatRoomAttachment
        attachments={attachments}
        openSidebar={openSidebar}
        isCollapse={showAttachment}
        onCollapse={() => setShowAttachment((prev) => !prev)}
      />
    </div>
  );
}, (prev, next) => isEqualJson(prev, next))

export default compose(withStyles(styles, { withTheme: true }))(ChatRoom);