import React from 'react'
import { withStyles, Toolbar } from '@material-ui/core';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import UserAvatar from '../../components/Common/UserAvatar'
import { connect } from 'react-redux';
import { drawerToggle } from '../../../actions/appActions'
import UserDrawer from './UserDrawer'

const styles = theme => ({
    toolbar: {
        backgroundColor: theme.customColors.primary.b500,
        height: 64,
        padding: '0px 16px',
        justifyContent: 'space-between'
    }
})

class NewOrganizationToolbar extends React.Component {
    oponUserSettings = () => {
        const { dispatch, wrapper } = this.props;

        dispatch(drawerToggle({ userSettingsOpen: !wrapper.userSettingsOpen }))
    }

    render() {
        const { classes, profileData } = this.props

        return <div data-intrcm-id="NewOrganizationToolbar_pw5a79ab">
            <Toolbar className={classes.toolbar}>
                <img className={classes.img} src={"/resources/default-logo-toolbar.png"} />
                <UserAvatar hideLevel userUUID={profileData.auth0_id} onClick={this.oponUserSettings} />
            </Toolbar>

            <UserDrawer  />
        </div>
    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    wrapper: state.wrapper,
    ...state.services
})

export default compose(withStyles(styles),
    connect(mapStateToProps),
    withErrorCatcher("Failed loading user, Please Contact Tuqqi Support"))(NewOrganizationToolbar)