import React from 'react'

export const CancelTaskIcon = () => {
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_8580_262687" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="3" y="3" width="9" height="9">
    <path d="M11.1828 3.31406C10.9391 3.07031 10.5453 3.07031 10.3016 3.31406L7.24531 6.36406L4.18906 3.30781C3.94531 3.06406 3.55156 3.06406 3.30781 3.30781C3.06406 3.55156 3.06406 3.94531 3.30781 4.18906L6.36406 7.24531L3.30781 10.3016C3.06406 10.5453 3.06406 10.9391 3.30781 11.1828C3.55156 11.4266 3.94531 11.4266 4.18906 11.1828L7.24531 8.12656L10.3016 11.1828C10.5453 11.4266 10.9391 11.4266 11.1828 11.1828C11.4266 10.9391 11.4266 10.5453 11.1828 10.3016L8.12656 7.24531L11.1828 4.18906C11.4203 3.95156 11.4203 3.55156 11.1828 3.31406Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_8580_262687)">
    <rect width="15" height="15" fill="#4A4A4F"/>
    </g>
    </svg>    
}