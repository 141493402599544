import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import CardWrapper from "./CardWrapper";


const DragableCardContainer = React.memo(function DragableCardContainer(props) {
  const { data: cards, index, style, isDragging } = props;

  const card = cards[index]
  if (!cards[index]) {
    return null;
  }


  return (
    <Draggable
      key={card.id}

      draggableId={card.id}
      index={index} >
      {
        (provided, snapshot) => (
          <CardWrapper
            provided={provided}
            snapshot={snapshot}
            style={style}
            cards={cards}
            index={index}
          />
        )
      }
    </Draggable>
  );

});

export default DragableCardContainer;

