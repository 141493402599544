import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";
const styles = theme => ({
    questionMark: {
        "width": "118px",
        "height": "100%",
        "right": "0",
        "position": "absolute"
    }, 
});

class QuestionMark extends PureComponent {

    render() {
        const { classes } = this.props;
        return <img src="/system/questionMark.png" className={classes.questionMark} /> 
    }
}

export default compose(withStyles(styles))(QuestionMark);
