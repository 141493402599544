import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withErrorCatcher from "../../components/Common/withErrorCatcher";
import { TagPageLeftDrawerNew } from '../search/Components/TagPageLeftDrawerNew';

const TagPageView = (props) => {
  const { children, location } = props;

  const query = location.query.searchquery;
  const paramsQuery = location.pathname;

  return (
    <Fragment>
      <TagPageLeftDrawerNew query={query} paramsQuery={paramsQuery} />
      {children}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withErrorCatcher(),
  connect(mapStateToProps, mapDispatchToProps)
)(TagPageView);
