import React from "react";
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose';
import Project from "./ProjectsList";
import withErrorCatcher from "../../../../components/Common/withErrorCatcher";
import { GanttObjectsType } from "../helpers/helpers";

const styles = theme => ({
      tableBodyContainer: {   
        borderLeft: `#DADDEE 1px solid`,
    },
})

const GanttTable = ({ tableInfo ,classes,onExpanderClick}) => {

  const projects = tableInfo.tasks.filter( p => p.type === GanttObjectsType.project)
  const tasks = tableInfo.tasks.filter(t => t.type === GanttObjectsType.task)

  return (
    <div data-intrcm-id="Table_xm5c4vuq" className={classes.tableBodyContainer}>
     {tableInfo.tasks.map((object, idx) => 
        <Project 
            object={object} 
            index={idx + 1} 
            tasks={tasks}  
            projects={projects}  
            onExpanderClick={onExpanderClick} 
            key={object.id}
        />)}
      </div>
  );
};

export default compose(withStyles(styles),withErrorCatcher())(GanttTable);