import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes';
import AppContent from '../../components/App/appContent';
import SettingsDrawer from '../../components/App/Drawers/settingsDrawer';
import PageContentWrapper from '../../components/App/PageComponents/pageContentWrapper';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';

// Apply some reset
const styles = theme => ({
    container: {
        height: '100%',
        marginLeft: 16
    },
});

class UserDashboard extends Component {

    state = {
        loaded: false
    };


    componentDidMount() {
        const { userSettingsService, adminService } = this.props;


        // Load data
        userSettingsService.getUserDashboardToken().then((res) => {

            window.Cumulio.addDashboard({
                dashboardId: '411d1edf-355d-44eb-b50e-fc11c7c027e5',
                container: '#my-dashboard',
                key: res.id,
                token: res.token,
                language: 'en'
            });
        })

        // Load data
        // adminService.getDashboardToken().then((res) => {

        //     window.Cumulio.addDashboard({
        //         dashboardId: '7d943bf7-807f-4e41-ae03-70cc3357a9f2',
        //         container: '#my-dashboard',
        //         key: res.id,
        //         token: res.token,
        //         language: 'en-US'
        //     });
        // })


    }


    render() {
        const { classes, t } = this.props;

        return (
            <AppContent >
                <PageContentWrapper  isFull={true} drawer={<SettingsDrawer selected="user" />}>
                    <div data-intrcm-id="userDashboard_ra59u0s3" className={classes.container}>
                        <div data-intrcm-id="userDashboard_06jdm6by" id={"my-dashboard"}>

                        </div>
                    </div>
                </PageContentWrapper>
            </AppContent>)
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), withTranslation('userSettings'), withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps))(UserDashboard);
