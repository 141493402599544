import { searchDocumentsWithAbort } from '@tuqqi/common'
import React, { useEffect, useMemo, useState } from 'react'
import { SERVER_API } from '../../utils/serverAPI'

export const useOrgMenuAllowed = (opts) => {
    const {token, orgMenuItems, allowFetch} = opts
    const [allowedItemsUids, setAllowedItemsUids] = useState([])

    const allItemsIds = useMemo(() => orgMenuItems
        .filter((m) => m.type === 2)
        .map((m) => m.referenceUid)
    , [orgMenuItems])

    useEffect(() => {
        if(!allowFetch) return
        const itemsIds = [...new Set(allItemsIds)]
        if(!itemsIds.length) {
            setAllowedItemsUids([])
            return
        }
        searchDocumentsWithAbort(SERVER_API, {data: {auth: {token}}})({
            query: '',
            itemsIds,
            page: 0,
            filter: {},
            size: itemsIds.length,
            AggType: 'FLAT',
        }, '/SearchByIds').then((res) => {

            if(!res) return
            const filteredRes = res.filter(doc => itemsIds.includes(doc.docId))
            const ids = filteredRes.map(doc => doc.docId)
            setAllowedItemsUids(ids)
        })
    }, [allItemsIds, token, allowFetch])

    return [allowedItemsUids]
}