import React, { PureComponent } from "react";

export default class Knowledge extends PureComponent {
  render() {

    const { color } = this.props
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="4" y="5" width="16" height="14" rx="2" stroke={color} stroke-width="1.5"/>
      <rect x="12" y="5" width="1" height="14" fill={color}/>
      <rect x="14" y="8" width="4" height="1" fill={color}/>
      <rect x="6" y="8" width="5" height="1" fill={color}/>
      <rect x="14" y="10" width="3" height="1" fill={color}/>
      <rect x="6" y="10" width="2" height="1" fill={color}/>
      <rect x="6" y="12" width="4" height="1" fill={color}/>
      <rect x="6" y="14" width="3" height="1" fill={color}/>
      </svg>
    );
  }
}
