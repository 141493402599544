import { ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import React, { Component, PureComponent } from 'react';
import withErrorCatcher from './withErrorCatcher.js'
import UserAvatar from '../Common/UserAvatar';
import { connect } from 'react-redux';
import { compose } from 'recompose';

class UserListItemCustom extends PureComponent {
    render() {
        const { classes, user, key, onClick, rightElement, disabled, isButton, secondaryText, userData } = this.props;

        userData

        const secondary = <div data-intrcm-id="UserListItemCustom_sgyipks7">{user.title}<br />@{user.tag}</div>
        const userName = userData.profileData.auth0_id == user.auth0_id
            ? <div data-intrcm-id="UserListItemCustom_wyhp2p66" style={{ fontWeight: 500 }} >You</div>
            : `${user.firstname} ${user.lastname}`

        return (
            <ListItem
                disabled={disabled}
                key={key}
                dense
                button={isButton}
                role={undefined}
                className={classes.listItem}
                onClick={onClick}
            >
                <UserAvatar userUUID={user.auth0_id} withShadow={false} />
                <ListItemText
                    primary={userName}
                    secondary={secondaryText || secondary} />
                <ListItemSecondaryAction>{rightElement}</ListItemSecondaryAction>
            </ListItem>
        )
    }
}

// export default UserListItemCustom

const mapStateToProps = (state) => ({
    users: state.globalData.users,
    userData: state.userData,
})

export default compose(
    // withStyles(styles), 
    withErrorCatcher("User list could not be loaded"),
    connect(mapStateToProps))(UserListItemCustom)