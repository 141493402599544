import { FULL_RESET_STORE } from '../actionTypes/actionTypes';
import i18n from '../i18n';
import dataTypesDictionary from '../ui/components/Common/userCompatibleNames/dataTypesDictionary';
import { defaultDataTypes } from '../ui/components/Common/Utils/defaultDataTypes';
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const t = i18n.getFixedT(i18n.language, 'dataTypeSelectionTranslate')
export const MAX_DATATYPE_SELECTED = 2

const noneOpt = {
    selected: true,
    key: 'None',
    label: 'None',
    timestamp: 0
}

const initialState = {
    showMenu: false,
    anchorEl: null,
    dataTypesOptions: [noneOpt]
};

const translateDataType = (dataType, inputMappings) => {
    return t(`inputTypes:${dataTypesDictionary(dataType, inputMappings)}`)
}

const defaultDataTypesFilters = defaultDataTypes().map(d => d.customDataMapping.dataTypeFilter)
const dataTypesFilters = defaultDataTypesFilters.filter(d => d !== 'contact' && d !== 'company')

const exportTableDataTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
        case ON_GO_TO_ITEM_NOTIF:
                return initialState
        case 'SET_MENU_EXPORT_DATATYPE': {
            const {showMenu, anchorEl} = action
            const dataTypesOptions = state.dataTypesOptions.map(o => ({...o, selected: o.key === 'None'}))
            return {...state, showMenu, anchorEl, dataTypesOptions}
        }
        case 'CLEAR_MENU_EXPORT_DATATYPE_OPTIONS': {
            return {...state, dataTypesOptions: initialState.dataTypesOptions}
        }
        case 'UPDATE_MENU_EXPORT_DATATYPE_OPTIONS': {
            return {...state, dataTypesOptions: action.dataTypesOptions}
        }
        case 'SET_MENU_EXPORT_DATATYPE_OPTIONS': {
            const {inputMappings, dataTypes} = action

            const customDataTypes = dataTypes.filter(u => !dataTypesFilters.includes(u))
            const opts = customDataTypes.map(c => ({
                timestamp: 0,
                selected: false,
                key: c,
                label: translateDataType(c, inputMappings),
            }))
            return {...state, dataTypesOptions: [noneOpt, ...opts]}
        }

        default:
            return state
    }
}

export default exportTableDataTypeReducer