
import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFilestackLink } from '../../../utils/filesUtils';
import BookmarkTemplate from '../FeedViewComponents/ContentPreview/DataTypeTemplates/Bookmark/BookmarkTemplate';
import FileTemplate from '../FeedViewComponents/ContentPreview/DataTypeTemplates/FileTemplate';
import QuestionTemplateNoComment from '../FeedViewComponents/ContentPreview/DataTypeTemplates/Question/QuestionTemplateNoComment';
import MediaPreview from './MediaPreview';
import PollPreview from './Polls/PollPreview';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        cursor: 'pointer',
    },
    gridListTile: {
        width: '100%',
        height: 170,
    },
    image: {
        height: 170,
        width: '100%',
        objectFit: 'contain',
        objectFit: 'cover'
    },
    tileBarTitle: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.1,
        color: '#ffffff',
        whiteSpace: 'pre-wrap',
        paddingTop: 0,
        paddingBottom: 0,
    },
    tileBarSubtitle: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.1,
        color: '#ffffff',
        paddingTop: 0,
        paddingBottom: 0,
    },
    gridListTileBar: {
        margin: 16,
        padding: 6,
        height: '12px',
        backgroundColor: 'rgba(0,0,0,0.54)',
        position: 'absolute',
        maxWidth: '90%',
        bottom: 0,
        left: 0,
        borderRadius: 2,
        alignItems: 'center',
        width: 'fit-content'
    },
    placeHolder: {
        height: 108
    },
    alreadyExist: {
        color: 'red',
        width: '100%',
        paddingLeft: 12
    },
    image: {
        height: 360,
        width: '100%',
        objectFit: 'contain',
        objectFit: 'cover',
        cursor: 'pointer'
    },
});

class InputPreview extends React.Component {
    render() {
        const { classes, oldUiInput } = this.props;

        if (!oldUiInput.hasPreview && oldUiInput.dataType !== "question" && oldUiInput.dataType !== "poll") {
            return null
        } else {
            const properties = {
                docId: '',
                result: oldUiInput,
                relatedItems: [],
                openLink: () => { },
                openItem: () => { },
                openFile: () => { },
                dataTypeName: oldUiInput.dataType,
                isInput: true
            }
            switch (oldUiInput.dataType) {
                case 'question':
                    properties.result = { ...oldUiInput, taggedUsers: [1, 1, 1, 1, 1] }
                    return <QuestionTemplateNoComment {...properties} />
                case "bookmark"://shared_link_already_exists
                    return (
                        <Grid wrap='nowrap' container direction='column'>
                            {oldUiInput.alreadyExists ? <Typography className={classes.alreadyExist} >This link already exist</Typography> : null}
                            <BookmarkTemplate {...properties} />
                        </Grid>)
                case 'file':
                    return <Grid wrap='nowrap' container alignItems='center'><FileTemplate {...properties} /></Grid>
                case 'media': return <MediaPreview />
                case 'poll': return <PollPreview {...properties} />
                default:
                    return null
                    break;
            }
        }
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    oldUiInput: state.oldUiInput.document,
    dataType: state.oldUiInput.dataType,
    link: state.oldUiInput.link,
    previewDescription: state.oldUiInput.previewDescription,
    title: state.oldUiInput.title,
    filestack: state.globalData.filestack,
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(InputPreview);
