import { withStyles, Grid } from '@material-ui/core';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { themeConstants } from '../../../theme';

const styles = theme => ({
    feedAndWidgetContainer: {
        marginTop: 16,
        "margin": "0 auto",
        "width": "fit-content"
    },

    container: {
        [theme.breakpoints.down(1680)]: {
            marginRight: 0,
        },
        [theme.breakpoints.up(1680)]: {
            marginRight: themeConstants.navigationDrawerWidth
        },
        height: '100%',
        padding: '0px 32px',
        marginLeft: themeConstants.navigationDrawerWidth
    },
    
    feedContainer: {
        marginRight: 32,
        width: 512,
        flex: '0 0 auto'
    },
    rightWidgets: {
        width: 320,
    },
    rightWidgetsW: {
        width: 320,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 110px)',
        position: 'fixed',
    }
    

});


class CategoryFeedView extends Component {
    render() {
        const { classes, rightWidgets, children } = this.props;

        return (             
                <div data-intrcm-id="CategoryFeedView_7cjxjasp" className={classes.container} >
                    <Grid direction='row' container className={classes.feedAndWidgetContainer} wrap='nowrap'>
                        <Grid item className={classes.feedContainer}>
                            {children}
                        </Grid>
                        <Grid container item className={classes.rightWidgets} direction='column'>
                            <div data-intrcm-id="CategoryFeedView_7qwpq49f" className={classes.rightWidgetsW}>
                                {rightWidgets}
                            </div>
                        </Grid>
                    </Grid>
                </div>            
        )
    }
}


const mapStateToProps = (state) => ({
    ...state.wrapper,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CategoryFeedView);