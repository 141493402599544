import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { themeConstants } from '../../../../theme'
import withErrorCatcher from '../../../components/Common/withErrorCatcher'
import { getAllCollection } from '../../groups/groupUtils'
import OngoingHeader from './OngoingHeader'
import OngoingLanes from './ongoingLanes/OngoingLanes'
import {buildOngoingTasks} from './userOngoingUtils'
import ScrollContainer from 'react-indiana-drag-scroll'

const styles = (theme) => ({
    scroll: {
        "&::-webkit-scrollbar": {
            visibility: 'visible!important',
            width: 12,
            height: 12,
            display: "block",
            backgroundColor: '#D7D7DB',
        },
        "&::-webkit-scrollbar-thumb": {
            visibility: 'visible!important',
            opacity: 0.5,
            backgroundColor: '#B1B1B3',
            borderRadius: 16,
        },
    },
    container: {
        paddingTop: 11,
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 11px)`,
        marginLeft: themeConstants.navigationDrawerWidth,
        overflow: 'scroll',
    },
    containerSmall: {
        paddingTop: 11,
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 11px)`,
        marginLeft: themeConstants.navigationDrawerSmallWidth,
        overflow: 'scroll',
    },
    wrapper: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        display: "inline-flex",
        flexDirection: "column",
        userSelect: "none",
        "-webkit-user-select": "none",
        "-webkit-touch-callout": "none",
        paddingLeft: 30
    },
})
const UserOngoingView = (props) => {
    const {results, classes, isDrawerOpen, userCollections, openCollections} = props
    const [allCollections, setAllCollections] = useState([])
    const [tasks, setTasks] = useState([])
    const [sortBy, setSortBy] = useState('dueDate')

    useEffect(() => {
        const allCollections = getAllCollection(openCollections, userCollections);
        setAllCollections(allCollections)
    }, [userCollections, openCollections])

    useEffect(() => {
        const tasks = buildOngoingTasks(results, allCollections)
        setTasks(tasks)
    }, [results, allCollections])

    return (
        <ScrollContainer ignoreElements={".ongoing-btns, .ongoing-card"} horizontal vertical={false} hideScrollbars={false} className={`${classes.scroll} ${isDrawerOpen ? classes.container : classes.containerSmall} scroll-container`}>
            <div data-intrcm-id="UserOngoingView_j81lv7ht" className={`${classes.wrapper}`}>
                <OngoingHeader sortBy={sortBy} setSortBy={setSortBy} />
                <OngoingLanes sortBy={sortBy} tasks={tasks} />
            </div>
        </ScrollContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    openCollections: state.globalData.openCollections,
    userCollections: state.userData.collections,
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true}),
    withErrorCatcher()
)(UserOngoingView)