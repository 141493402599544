import React, { PureComponent } from 'react';

export class ProfileIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="7" r="3.25" stroke={color} stroke-width="1.5"/>
            <path d="M19.25 17C19.25 17.8647 19.0296 18.4402 18.707 18.8453C18.3778 19.2587 17.8842 19.5671 17.2149 19.7877C15.8371 20.2419 13.9733 20.25 12 20.25C10.0267 20.25 8.16293 20.2419 6.78506 19.7877C6.11578 19.5671 5.62225 19.2587 5.29303 18.8453C4.97041 18.4402 4.75 17.8647 4.75 17C4.75 16.1364 4.97068 15.4743 5.32578 14.9541C5.68485 14.4281 6.21088 14.0045 6.88269 13.6723C8.24568 12.9983 10.1085 12.75 12 12.75C13.8915 12.75 15.7543 12.9983 17.1173 13.6723C17.7891 14.0045 18.3152 14.4281 18.6742 14.9541C19.0293 15.4743 19.25 16.1364 19.25 17Z" stroke={color} stroke-width="1.5"/>
            </svg>
        )
    }
}