import React, { createRef } from 'react'
import DatePicker from '../../Header/SubheaderToolbar/Filters/DatePicker';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { Paper, Typography, InputLabel, withStyles, Checkbox } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { INPUT_CHANGE } from '../../../../actionTypes/actionTypes';
import { getDueDateStyling, getDueDate } from '../../../../utils/taskDetailsUtils';
import withErrorCatcher from '../withErrorCatcher';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 24
    },
    label: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "rgba(151, 154, 170, 0.8)"
    },
    statusText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d",
        borderRadius: 2,
        backgroundColor: '#e3e3e3',
        padding: 8,
        marginTop: 6,
        width: 'fit-content'
    },
    dueDateWrapper: {
        marginLeft: 24
    },
    dueDateText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#ffffff",
        textDecoration: 'underline',
        cursor: 'pointer',
        borderRadius: 2,
        backgroundColor: '#ffb632',
        paddingTop: 4,
        paddingRight: 12,
        paddingBottom: 4,
        paddingLeft: 8,
        marginTop: 6,
        width: 'fit-content'
    },
    addDueDateText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d",
        textDecoration: 'underline',
        cursor: 'pointer',
        borderRadius: 2,
        backgroundColor: '#e3e3e3',
        padding: 8,
        marginTop: 6,
        width: 'fit-content',
    },
    doneCheckbox: {
        padding: 0,
        paddingRight: 8
    }
})

class TaskDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            datetTimePickerOpen: false
        }

        this.pickerRef = createRef()
    }

    getStatus = () => {
        const { groupInfo, docId, t } = this.props

        if (!groupInfo || !groupInfo.collectionViews) {
            return null
        }

        const kanbanInfo = groupInfo.collectionViews.find(x => x.collectionViewType === 'Kanban')
        if (!kanbanInfo) {
            return null
        }

        const lanes = kanbanInfo.itemLists
        for (let i = 0; i < lanes.length; i++) {
            const lane = lanes[i]
            if (lane.items.find(x => x.documentId === docId)) {
                return lane.title
            }
        }

        return t('Unassigned')
    }

    openDateTimePicker = () => {
        this.pickerRef.current.open()
    }

    dateChanged = (newDate) => {
        this.props.updateDueDate(newDate)
    }

    doneChanged = (e) => {
        const checked = e.target.checked
        this.props.updateTaskDone(checked)
    }

    render() {
        const { item, classes, t } = this.props
        const status = this.getStatus()
        const dueDate = getDueDate(item)

        const styling = getDueDateStyling(item.dueDateUTC, item.taskDueDateDone)

        return <div data-intrcm-id="TaskDetailsEditMode_4l2j6i61" className={classes.wrapper}>
            {status && <div data-intrcm-id="TaskDetailsEditMode_y80onj4u">
                <Typography className={classes.label}>{t('Status')}</Typography>
                <Typography variant="body1" className={classes.statusText}>{status}</Typography>
            </div>}
            <div data-intrcm-id="TaskDetailsEditMode_b9ha05xa" className={status && classes.dueDateWrapper}>
                <Typography className={classes.label}>{t('Due Date')}</Typography>
                {dueDate ? <Typography variant="caption" className={classes.dueDateText} style={{ backgroundColor: styling.backgroundColor, color: styling.textColor }}>
                    <Checkbox checked={item.taskDueDateDone} className={classes.doneCheckbox} onChange={this.doneChanged} style={{ color: styling.checkboxColor }} />
                    <span onClick={this.openDateTimePicker}>{dueDate}</span>
                </Typography> :
                    <Typography variant="caption" className={classes.addDueDateText} onClick={this.openDateTimePicker}>
                        {t('Add a due date')}
                    </Typography>}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker clearable={true} ref={this.pickerRef} onChange={this.dateChanged} style={{ display: 'none' }} />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    groupInfo: state.results.channel
})

const mapDispatchToProps = dispatch => {
    return {
        updateDueDate: newDate => dispatch({ type: INPUT_CHANGE, model: { dueDateUTC: newDate } }),
        updateTaskDone: taskDueDateDone => dispatch({ type: INPUT_CHANGE, model: { taskDueDateDone } }),
    }
}

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    withTranslation('itemFullViewModal'),
    connect(mapStateToProps, mapDispatchToProps))(TaskDetails)