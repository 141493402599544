import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import WidgetSingleImgOption from '../../../Common/WidgetOptions/WidgetSingleImgOption';
import { statusColors } from '../../../Kanban/ListColorModal/ListColorModal';
import {applySearchQuery} from '../../../../views/search/searchUtils';
import { withMixpanel } from '../../../../components/Common/withMixpanel';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        width: 15,
        height: 15,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginLeft: 8,
        marginBottom: 8,
    }
});

const getOptions = (t) => statusColors.map(color => ({
    label: t(color.label),
    selected: false,
    key: color.id,
    icon: color.color,
}))

const LaneColorsWidget = (props) => {
    const { classes, t, searchQuery, dispatch, mixpanelTrack } = props;
    const [options, setOptions] = useState(getOptions(t))

    const onOptionClick = (key) => {
        mixpanelTrack(`Lane colors widget`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            o.selected = false
            return o
        })
        setOptions(newOptions)
        const selected = newOptions.filter(o => o.selected).map(o => o.key)
        const newQuery = {...searchQuery, colors: selected}
        applySearchQuery(newQuery, dispatch)
    }

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="LaneColorsWidget_spjk3wiy" className={classes.containerInner}>
                <div data-intrcm-id="LaneColorsWidget_q96jv9uj" className={classes.titleSection}>
                        <div data-intrcm-id="LaneColorsWidget_mmn2jud3" className={classes.title}>{t("Colors")}</div>
                </div>
                <div data-intrcm-id="LaneColorsWidget_btxvo6ai" className={classes.options}>
                    {options.map(o => (
                        <WidgetSingleImgOption optionStyle={classes.option} key={o.key} option={o} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    searchQuery: state.search
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps),
)(LaneColorsWidget);