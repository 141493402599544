import React, { PureComponent } from 'react';

export default class SharedPermissionIcon extends PureComponent {

    render() {
        const { className } = this.props
        return (
            <svg className={className} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <rect fill="#A62A2A" width="40" height="40" rx="20"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="20" cy="7" r="3"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="20" cy="33" r="3"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="9" cy="14" r="3"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="9" cy="26" r="3"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="31" cy="14" r="3"/>
                <circle stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="31" cy="26" r="3"/>
            </g>
        </svg>
        
        )
    }
}
