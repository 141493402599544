import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/goToActions'
import { initProcessStringConfig, processTextString } from '@tuqqi/web';


const styles = theme => ({
    tag: {
        color: 'blue',
        cursor: 'pointer'
    },
    text: {
        fontFamily: '"Roboto"',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.1px',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    link: {
        color: `${theme.palette.primary.main}!important`,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
})

class SplitedText extends Component {
    static defaultProps = {
        withParsingText: false
    }
    onOpenHashtag = (hashtag) => {
        const { dispatch } = this.props
        goTo(hashtag, dispatch)();
    }
    onOpenLink = (link) => {
        window.open(link, '_blank')
    }

    splitedText = (comment) => {
        const { classes, dispatch, text, withParsingText } = this.props;

        if(!text){
            return
        }
        if(withParsingText) {
            const processConfig = initProcessStringConfig({
                linkClassname: classes.link,
                tagClassname: classes.link,
                onLinkClick: this.onOpenLink,
                onTagClick: this.onOpenHashtag
            })
            return processTextString(text, processConfig)
        }
        const splitedText = text.split(' ').map((word, index) => {
            if (word.startsWith("#") || word.startsWith("@")) {
                return <a dir='auto' key={index} className={`${classes.tag} tag-text`} onClick={goTo(word, dispatch)}>{`${word} `}</a>
            } else {
                return `${word} `
            }
        })

        return splitedText
    }


    render() {
        const { classes, style, className } = this.props;
        const splitedText = this.splitedText()

        return (
            <div data-intrcm-id="splitedText_k5zsucka" dir='auto' className={className || classes.text} style={style}>
                {splitedText}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({ dispatch})

export default compose(withStyles(styles, {withTheme: true}), connect(mapDispatchToProps))(SplitedText);