import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Typography, Icon, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import parseImgeUrl from '../../../../Common/parseImagUrl';
import Faces from './Faces.js';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

//import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
//import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';

const styles = theme => ({
    container: {
        overflow: 'hidden',
        borderRadius: 4,
        border: 'solid 1px rgba(151, 154, 170, 0.3)',
        cursor: 'pointer',
        marginBottom: 16,
    },
    leftSideContainer: {
        width: 56,
        flexGrow: 0,
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex"
    },
    rightSideContainer: {
        justifyContent: 'center',
        display: "flex",
        alignItems: 'flex-end',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
    },
    centerContainer: {
        paddingTop: 10,
        paddingLeft: 16,
        paddingRight: 8,
        paddingBottom: 8,

        overflow: 'hidden',
        width: '100%'
    },
    optionContent: {
        flexDirection: 'row', 
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: 8, 
        alignItems: 'flex-end'
    },
    img: {
        width: 48,
        height: 48,
        objectFit: 'contain',
        borderRadius: 4,
    },
    text: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#21212d',
        paddingRight: 16,
    },
    checkbox: {
        borderRadius: '50%',
        fontSize: 24,
    },
    barColorPrimary: {
        backgroundColor: '#979aaa'
    },
    barColorPrimaryFirst: {
        backgroundColor: theme.customColors.primary.main,
    },
    colorPrimary: {
        backgroundColor: 'rgba(151, 154, 170, 0.2)'
    },
    root: {
        height: 16,
        borderRadius: 4
    },
    icon: {
        fontSize: 48,
        color: 'rgba(151, 154, 170, 0.08)',
    }
});

class PollOptionTemplate extends PureComponent {
    state = {
        usersFaces: []
    }

    updateOption = () => {
        const { users, option, votes, userData } = this.props;
        const { pollOptionUsers } = option;

        const levelValue = votes ? (pollOptionUsers.length / votes) * 100 : 0;
        const checked = pollOptionUsers.find( o => o.userProfile.auth0_id === userData.profileData.auth0_id)

        const usersFaces = pollOptionUsers.map(ou => {
            const user = users.find( u => u.auth0_id == ou.userProfile.auth0_id)

            return {
                fullName: `${user.firstname} ${user.lastname}`,
                profileImageUrl: user.profileImageUrl,
                userId: user.id
            }
        })

        const usersIds = usersFaces.map(u => u.userId);

        this.setState({ levelValue, usersFaces, usersIds, checked })
    }

    componentDidUpdate(prevProps){
        if(this.props.needUpdate){
            this.updateOption()
            this.props.pollUpdated()
        }
    }

    componentDidMount(){
        const { option, cloudfront } = this.props;
        const { optionImg } = option;

        this.updateOption()

        let imageUrl = '';
        if(optionImg){
            imageUrl = parseImgeUrl(cloudfront, optionImg, 965)
        }

        this.setState({ optionImg: imageUrl })
    } 

    render() {
        const { classes, option, vote, openUsersDialog, idx } = this.props;
        const { levelValue, usersFaces, usersIds, optionImg, checked, isOpen } = this.state;

        const { optionText } = option;
        const checkedStyle = checked ? { backgroundColor: 'rgba(151, 154, 170, 0.1)' } : {}
        
        return <div data-intrcm-id="PollOptionTemplate_wzbw8zwd">
            <Grid container wrap='nowrap' direction='row' onClick={vote} className={classes.container} style={checkedStyle} >
                <Grid item className={classes.leftSideContainer} style={{ borderRight: 'solid 1px rgba(151, 154, 170, 0.3)' }}>
                    {/* this is what should be by design:
                        {checked 
                            ? <CheckBoxTwoToneIcon className={classes.checkbox} /> 
                            : <CheckBoxOutlineBlankRoundedIcon className={classes.checkbox} />} 
                    */}
                    {checked 
                        ? <Icon className={classes.checkbox}>check_box</Icon> 
                        : <Icon className={classes.checkbox}>check_box_outline_blank</Icon>
                    }
                </Grid>

                <Grid item className={classes.centerContainer} dir="auto" xs='auto'>
                    <div data-intrcm-id="PollOptionTemplate_zoyfnlzs" className={classes.optionContent} >
                        <Typography className={classes.text}>{optionText}</Typography>
                        <Faces
                            key={idx}
                            usersFaces={usersFaces} 
                            numFacePile={3}
                            openUsersDialog={openUsersDialog(usersIds)} />
                    </div>
                    <LinearProgress
                        classes={{ 
                            barColorPrimary: idx === 0 ? classes.barColorPrimaryFirst : classes.barColorPrimary,
                            root: classes.root,
                            colorPrimary: classes.colorPrimary,
                        }}
                        variant='determinate'
                        value={levelValue} />
                </Grid>
                
                <Grid item className={classes.rightSideContainer} >
                    {optionImg
                        ? <img src={optionImg} className={classes.img} 
                            onClick={(e) => { 
                                this.setState({ isOpen: true }); 
                                e.stopPropagation(); 
                            }} 
                        />
                        : <Icon className={classes.icon} >poll</Icon>
                    }
                </Grid>
            </Grid>
            {isOpen && <Lightbox mainSrc={optionImg} onCloseRequest={() => this.setState({ isOpen: false }) } /> }
        </div>
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
    ...state.services,
})

export default compose(withStyles(styles, { withTheme: true }),
    connect(
        mapStateToProps,
    ))(PollOptionTemplate)
