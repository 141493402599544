import { Divider, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import React, { Component, PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { compose } from 'recompose';
import UserListItemCustom from '../../../Common/UserListItemCustom';
import SubTitle from './SubTitle';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "../../../Common/spinner";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    button: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSspacing: 1.1,
        textAlign: 'center',
        color: theme.customColors.primary.b600,
    },
    listItem: {
        paddingRight: 0,
        paddingLeft: 0
    }
});

class SelectedUsersList extends PureComponent {
    state = {
        page: 0
    }

    render() {
        const { askedUsers, classes, title, buttonAllTitle, buttonTitle, handleClick, handleAllClick } = this.props;
        const delta = 20
        let allButton = buttonAllTitle
            ? <Button onClick={handleAllClick} classes={{ root: classes.button }}>
                {buttonAllTitle}
            </Button> : null;

        return askedUsers && askedUsers.length
            ? <div data-intrcm-id="SelectedUsersList_nfomqkva" style={{ paddingBottom: 18 }}>
                {title ? <SubTitle subTitle={title} button={allButton} /> : <div data-intrcm-id="SelectedUsersList_0dh98i4t" />}
                <Divider />
                <List style={{ paddingLeft: 24, paddingRight: 24, maxHeight: 700, overflow: 'auto'   }}>


                    <InfiniteScroll
                        useWindow={false}
                        pageStart={0}
                        loadMore={(page) => { this.setState({ page: this.state.page + 1 }) }}
                        hasMore={(this.state.page * delta) < askedUsers.length}
                        loader={<Spinner classes={classes} />
                        }>


                        {askedUsers.slice(0, (this.state.page) * delta).map((user, idx) => (
                            <UserListItemCustom
                                disabled={false}
                                isButton={false}
                                user={user}
                                key={idx}
                                classes={classes}
                                rightElement={buttonTitle ? <Button classes={{ root: classes.button }}
                                    onClick={() => handleClick(user.auth0_id)} >
                                    {buttonTitle}
                                </Button> : null} />))}
                    </InfiniteScroll>
                </List>
            </div>
            : <div data-intrcm-id="SelectedUsersList_bk9v15es" />
    }
}

export default compose(withStyles(styles, { withTheme: true }), withTranslation('mustReadListModal'))(SelectedUsersList);