import { withStyles, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import CopyToClipboard from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { showSnackbar, showConfirm } from '../../../../actions/appActions';
import { PREVIEW_CLOSE_ANALYZE, PREVIEW_SHOW_ANALYZE, DELETE_ITEM, FULLVIEW_CHANGE, DATA_UPDATE } from "../../../../actionTypes/actionTypes";
import AddMembersToItem from './AddMembersToItem';
import ItemTagsModal from './itemTagsModal';
import LinkedItemsModal from './linkedItemsModal';
import NotifyMeModal from './NotifyMeModal';
import RelatedItemsModal from './RelatedItemsModal';
import MustReadListModal from './MustRead/MustReadListModal';
import Favourites from './Favourites'
import ItemLogsModal from './ItemLogsModal'
import OnErrorDialog from '../../Common/OnErrorDialog.js';
import ChangeLabelModal from './Menu/LabelMenu/ChangeLabelModal';
import ClassificationSelector from '../../FullView/ClassificationSelector.js';
import GroupSelector from '../../FullView/GroupSelector.js';
import { canUserSetClassification, canUserChangeGroup, isUserAdminOnGroupItem, isUserHasPermissionOnGroupItem, isUserOrgAdmin, isUserMemberOnItem, isUserItemAuthor, canUserChangeMembers, canUserDeleteItem, canUserChangeEveryoneCanEdit, canUserEditItem } from '../../Common/Utils/userPermissionsUtils';
import { showLabels } from './Menu/LabelMenu/labelUtil.js';
import { updateSpecificItem } from '../../../../actions/dataActions.js';
import { canDataTypePublishToFeed } from '../../Common/Utils/inputUtils.js';

// Apply some reset
const styles = theme => ({
    dialogRoot: {
        zIndex: 1601
    },
    defaultText: {
        color: 'inherit',
        'font-size': 'inherit',
        'font-weight': 'inherit',
        'line-height': 'inherit',
    },
    labelContainer: {
        marginLeft: 0
    }
});

const ITEM_HEIGHT = 48;
const editableDataTypes = ["wysiwyg", "code"];

class ItemPreviewCardHeaderMenu extends Component {
    state = {
        anchorEl: null,
        relatedOpen: false,
        linkedOpen: false,
        tagsOpen: false,
        notifyMeOpen: false,
        collectionOpen: false,
        membersOpen: false,
        mustReadOpen: false,
        itemLogsOpen: false,
        isChangeLabelOpen: false,
        classificationSelectorOpen: false,
        groupSelectorOpen: false,
        classificationAnchorEl: null,
        groupSelectorAnchorEl: null
    };

    closeMenu = (event) => {

        this.setState({
            anchorEl: null,
            relatedOpen: false,
            linkedOpen: false,
            tagsOpen: false,
            notifyMeOpen: false,
            collectionOpen: false,
            membersOpen: false,
            mustReadOpen: false,
            itemLogsOpen: false,
            isChangeLabelOpen: false,
            classificationSelectorOpen: false,
            groupSelectorOpen: false,
            classificationAnchorEl: null,
            groupSelectorAnchorEl: null
        })
        this.stopPropagation(event)
    }
    handleClick = event => {
        const { docId, collaborationService } = this.props;

        collaborationService.logClick(docId);

        this.setState({ anchorEl: event.currentTarget });
        this.stopPropagation(event)
    };

    componentWillReceiveProps(nextProps) {
        const { anchorEl, docId, dispatch, deleteDocId } = nextProps

        if (this.state.anchorEl != anchorEl) {
            this.setState({ anchorEl })
        }
        if (deleteDocId === docId) {
            dispatch({ type: DELETE_ITEM, data: { deleteDocId: '' } })
            this.deleteItem()
        }
    }

    handleRequestClose = (event) => {
        const { onClose } = this.props
        this.setState({ anchorEl: null });
        onClose && onClose()

        this.stopPropagation(event)
    };

    stopPropagation(event) {
        event && event.stopPropagation()
    }

    isEditableItem = (dataType) => {
        if (editableDataTypes.includes(dataType)) {
            return true;
        } else {
            return false;
        }
    }

    openEdit = () => {
        const { result, docId, dispatch } = this.props;
        const { dataType } = result;

        var inputType = null;

        switch (dataType) {
            case 'bookmark':
                inputType = { linkInputOpen: true };
                break;
            case 'attachment':
                inputType = { flieInputOpen: true };
                break;
            case 'wysiwyg':
                dispatch(push('edit'))
                inputType = { wysiwygInputOpen: true };
                break;
            case 'contact':
                inputType = { contactInputOpen: true }
                break;
            case 'code':
                dispatch(push('edit'))
                inputType = { codeInputOpen: true };
                break;
            default:
                inputType = null;
                break;

        }

        dispatch({ type: 'INPUT_EDIT_OPEN', model: Object.assign({}, result, { formTitle: 'Edit: ' + result.title, docId: docId }), input: inputType })
    }

    showRelated = () => {
        this.setState({ relatedOpen: true, anchorEl: null })
    }
    showLinked = () => {
        this.setState({ linkedOpen: true, anchorEl: null })
    }
    showTags = () => {
        this.setState({ tagsOpen: true, anchorEl: null })
    }

    runFlow = () => {
        const { dispatch, t, inputService, docId } = this.props
        this.setState({ anchorEl: null })
        dispatch(showSnackbar(t('Queueing Flow run')))
        inputService.runFlow(docId).then(x => {
            dispatch(showSnackbar(t('Flow run has been queued successfully')))
        }).catch(x => {
            dispatch(showSnackbar(t('Failed queueing Flow run')))
        })
    }

    notifyMe = () => {
        this.setState({ notifyMeOpen: true, anchorEl: null })
    }
    mustRead = () => {
        this.setState({ mustReadOpen: true, anchorEl: null })
    }
    addToCollection = () => {
        this.setState({ collectionOpen: true, anchorEl: null });
    }
    changeLabel = () => {
        this.setState({ isChangeLabelOpen: true, anchorEl: null });
    }
    showItemsLog = () => {
        this.setState({ itemLogsOpen: true, anchorEl: null })
    }

    openClassificationsMenu = (e) => {
        this.setState({ classificationSelectorOpen: true, classificationAnchorEl: e.target.children[0] })
    }

    openGroupSelectorMenu = e => {
        this.setState({ groupSelectorOpen: true, groupSelectorAnchorEl: e.target.children[0] })
    }

    

    addChecklist = () => {
        const { collaborationService, docId, dispatch, fullView, t } = this.props

        dispatch(showSnackbar(t('Please Wait')));
        collaborationService.addChecklist(docId).then(checklist => {
            dispatch({ type: 'FULLVIEW_CHANGE', item: { ...fullView.item, checklists: [...fullView.item.checklists, checklist] } })
            dispatch(showSnackbar(t('Add Checklist successfully')));
        })
    }

    showAnalyze = () => {
        const { docId, dispatch, previewItem } = this.props

        if (previewItem.showAnalyze && previewItem.docId == docId) {
            dispatch({ type: PREVIEW_CLOSE_ANALYZE })
        } else {
            dispatch({ type: PREVIEW_SHOW_ANALYZE, item: { docId } })
        }
    }

    getMenuItems = () => {
        const {
            collaborationService,
            dispatch,
            userData,
            docId,
            result,
            fullView,
            previewItem,
            t,
            collectionInfo,
            classes,
            inputMappings,
            defaultInputTemplates
        } = this.props;

        const { profileData, collections } = userData

        const items = []

        // if (relatedDocuments && relatedDocuments.length) {
        //     items.push(<MenuItem onClick={this.showLinked}>{t('Linked Items')}</MenuItem>)
        // }

        if (collectionInfo && collectionInfo.collectionUid && showLabels({ collectionUid: collectionInfo.collectionUid })
            && userData.collections.some(collection => collection.collectionUid == collectionInfo.collectionUid)) {
            items.push(<MenuItem onClick={this.changeLabel}>{t('Labels')} </MenuItem>)
        }

        // items.push(<MenuItem onClick={this.showRelated}>{t('Related')} </MenuItem>)

        if (canUserSetClassification(userData, result)) {
            items.push(<MenuItem onClick={this.openClassificationsMenu}>{t('Classifications')}<RightIcon /></MenuItem>)
        }

        if (canUserChangeGroup(userData, result)) {
            items.push(<MenuItem onClick={this.openGroupSelectorMenu}>{t('Groups')}<RightIcon /></MenuItem>)
        }

        if (canUserEditItem(profileData, collections, result)) {
            items.push(<MenuItem onClick={this.mustRead}>{t('Must Read Managment')}</MenuItem>)
        }

        items.push(
            <Favourites render={<MenuItem />} docId={docId} onClick={this.handleRequestClose} />,
            // <MenuItem onClick={this.mustRead}>{t('Must Read Managment')}</MenuItem>,
            // <MenuItem onClick={this.showTags}>{t('Tags')}</MenuItem>,
            <MenuItem onClick={this.runFlow}>{t('Run Flow')}</MenuItem>,
            <MenuItem onClick={this.notifyMe}>{t('Seen Notifications')}</MenuItem>,
            <MenuItem>
                <CopyToClipboard text={`${location.host}/feed?openItemId=${docId}`}
                    onCopy={() => {
                        this.props.dispatch(showSnackbar(t('Link copied to clipboard')));
                        this.handleRequestClose()
                    }}
                >
                    <div data-intrcm-id="itemPreviewCardHeaderMenu_nykhs738" style={{ width: '100%' }}>{t('Share')}</div>
                </CopyToClipboard>
            </MenuItem>,
            <MenuItem onClick={this.showItemsLog}>{t('Activity Log')}</MenuItem>);

        if (fullView.open) {
            items.push(<MenuItem onClick={this.addChecklist}>{t('Add Checklist')}</MenuItem>)
        }
        // else {
        //     items.push(<MenuItem onClick={this.showAnalyze}>{t('Show Analytics')}</MenuItem>)
        // }

        if (canUserChangeMembers(profileData, collections, result)) {
            items.push(<MenuItem onClick={() => { this.setState({ membersOpen: true, anchorEl: null }) }}>{t('Manage members')}</MenuItem>)
        }


        if (canUserDeleteItem(profileData, result)) {
            items.push(<MenuItem onClick={this.onDelete}>{t('Delete')}</MenuItem>);
        }

        if (canUserChangeEveryoneCanEdit(userData.profileData, result)) {
            items.push(
                <MenuItem >
                    <FormControlLabel
                        classes={{
                            label: classes.defaultText,
                            root: classes.labelContainer
                        }}
                        labelPlacement='start'
                        control={
                            <Checkbox
                                checked={result.isEveryoneCanEdit}
                                onChange={this.changeEveryoneCanEdit}
                                value={result.isEveryoneCanEdit}
                            />
                        }
                        label={t("Everyone Can Edit")}
                    />
                </MenuItem>)
        }
        return items;
    }

    changeEveryoneCanEdit = (event) => {
        const { inputService, docId, dispatch, fullView, t } = this.props
        const isEveryoneCanEdit = event.target.checked
        this.closeMenu(event)
        dispatch(showSnackbar(t('Update')));

        inputService.changeEveryoneCanEdit(docId, isEveryoneCanEdit).then((res) => {
            if (!res.ok) {
                dispatch(showSnackbar(t('Error occured')));
            } else {
                dispatch({ type: FULLVIEW_CHANGE, item: { ...fullView.item, isEveryoneCanEdit } })
                dispatch(showSnackbar(t('Item Updated successfully')));
                dispatch(updateSpecificItem(docId))
            }
        })
    }
    onDelete = () => {
        const { docId, t, dispatch } = this.props
        const action = { type: DELETE_ITEM, data: { deleteDocId: docId } }

        dispatch(showConfirm(t("Are you sure you want to delete this? this action can't be undone"), action))
    }
    deleteItem = () => {
        const { inputService, docId, dispatch, t } = this.props

        inputService.deleteItem(docId).then(() => {
            this.setState({ expanded: false })
            dispatch({ type: 'DATA_UPDATE' })
            dispatch(showSnackbar(t('Deleted successfully')));
        })
        dispatch(showSnackbar(t('Deleting')));
    }

    isAnyModalOpen = () => {

        const { relatedOpen,
            linkedOpen,
            tagsOpen,
            notifyMeOpen,
            collectionOpen,
            membersOpen,
            mustReadOpen,
            itemLogsOpen,
            isChangeLabelOpen,
            classificationSelectorOpen,
            groupSelectorOpen } = this.state;
        return relatedOpen ||
            linkedOpen ||
            tagsOpen ||
            notifyMeOpen ||
            collectionOpen ||
            membersOpen ||
            mustReadOpen ||
            itemLogsOpen ||
            isChangeLabelOpen ||
            classificationSelectorOpen ||
            groupSelectorOpen


    }
    isAdminGroup = () => {
        const { result, userData } = this.props;
        const {groupUid} = result
        const {collections, profileData} = userData
        const {auth0_id} = profileData
        const collection = collections.find(col => col.collectionGroup.groupUid === groupUid)
        if(!collection) return false

        return collection.collectionGroup.adminUIds.includes(auth0_id)

    }

    render() {
        const { docId, result, buttonClass, dialogClass, icon } = this.props;
        if (!result) {
            return null
        }

        const open = Boolean(this.state.anchorEl);
        return (
            <div data-intrcm-id="itemPreviewCardHeaderMenu_m1dq97id" style={{ height: 'fit-content' }} onClick={this.stopPropagation}>
                <IconButton
                    classes={{ root: buttonClass }}
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    {
                        icon || <MoreVertIcon style={{ opacity: 0.3 }} />
                    }
                </IconButton>
                {open ? <Menu
                    className={dialogClass}
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClick={this.stopPropagation}
                    onClose={this.handleRequestClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    {this.getMenuItems()}
                </Menu> : null}

                {this.isAnyModalOpen() ? <Fragment> <AddMembersToItem
                    open={this.state.membersOpen}
                    handleClose={this.closeMenu}
                    docId={docId}
                    document={result}
                />
                    <ChangeLabelModal
                        open={this.state.isChangeLabelOpen}
                        handleClose={this.closeMenu}
                        isAdmin={this.isAdminGroup()}
                        docId={docId}
                        docCollectionLabels={result.collectionLabels}
                    />
                    <RelatedItemsModal
                        open={this.state.relatedOpen}
                        handleClose={this.closeMenu}
                        docId={docId}
                        document={result}
                    />
                    {/* <LinkedItemsModal
                        open={this.state.linkedOpen}
                        handleClose={this.closeMenu}

                        docId={docId}
                        document={result}
                        relatedDocuments={relatedDocuments}
                    /> */}
                    {/* <ItemTagsModal
                        open={this.state.tagsOpen}
                        handleClose={this.closeMenu}
                        docId={docId}
                        document={result}
                    /> */}
                    <NotifyMeModal
                        open={this.state.notifyMeOpen}
                        handleClose={this.closeMenu}
                        docId={docId}
                    />
                    <ClassificationSelector open={this.state.classificationSelectorOpen}
                        anchorEl={this.state.classificationAnchorEl}
                        onClose={this.closeMenu}
                        closeParent={this.handleRequestClose}
                        dialogClass={dialogClass}
                        item={result}
                        docId={docId} />
                    <GroupSelector open={this.state.groupSelectorOpen}
                        anchorEl={this.state.groupSelectorAnchorEl}
                        onClose={this.closeMenu}
                        closeParent={this.handleRequestClose}
                        dialogClass={dialogClass}
                        item={result}
                        docId={docId} /></Fragment> : <div data-intrcm-id="itemPreviewCardHeaderMenu_ahn9b9s2" />}

                {this.state.itemLogsOpen ?
                    <ItemLogsModal docId={docId}
                        open={this.state.itemLogsOpen}
                        onClose={this.closeMenu}
                    /> : null}

                {this.state.mustReadOpen ? <MustReadListModal
                    open={this.state.mustReadOpen}
                    handleClose={this.closeMenu}
                    docId={docId}
                    document={result}
                /> : <div data-intrcm-id="itemPreviewCardHeaderMenu_ke7fnzav" />}
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    collectionInfo: state.results.channel,
    fullView: state.fullView,
    previewItem: state.previewItem,
    deleteDocId: state.confirmDialog.deleteDocId,
    classifications: state.globalData.classifications,
    defaultInputTemplates: state.globalData.defaultInputTemplates,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
    withTranslation('itemPreviewCardHeaderMenu'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemPreviewCardHeaderMenu);

