import React, { Component, PureComponent } from 'react';

import withErrorCatcher from './withErrorCatcher.js'

import { ListItem, ListItemText, Checkbox, withStyles } from '@material-ui/core';

import UserAvatar from './UserAvatar';


const styles = theme => ({

})
class UserListItem extends PureComponent {

    render() {
        const { classes, user, key, onClick, checked, disabled } = this.props;

        if(!user){
            return null
        }
        
        return (
            <ListItem
                disabled={disabled}
                key={key}
                dense
                button
                role={undefined}
                className={classes.listItem}
                onClick={onClick}
            >
                <UserAvatar userUUID={user.auth0_id} withShadow={false} />
                <ListItemText
                    primary={`${user.firstname} ${user.lastname}`}
                    secondary={<div data-intrcm-id="userListItem_6clz3j2e">{user.title}<br />@{user.tag}</div>} />
                <Checkbox
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItem>
        )
    }
}
export default withStyles(styles)(UserListItem);

