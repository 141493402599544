import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

const styles = (theme) => ({
});

const imgConfig = {
  'taskDoneCheck': "check-mark-button-emoji",
  'dudeStatic' : "dude",
  'commentStaticImgCloud' : "cloud",
  'workInProgressImg': 'work-in-progress',
  'groupCommentImg': 'in-discussions'
}
const StaticImg = (props) => {
  const { styles, eventType, option } = props;
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    import('../../../../../assets/img/stories/' + eventType + "/" + imgConfig[option] + ".png").then(image => {
      setImgUrl(image.default ?? '')
  });
  }, [eventType]);


  return (
    <img
      src={imgUrl}
      style={styles.container}
      alt="image"
    />
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StaticImg);
