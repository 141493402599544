import { FormControl, Typography, withStyles } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionGroupSettings } from '../../../../../actions/collectionActions.js';
import CollectionPermissionSettingsOption from './CollectionPermissionSettingsOption.js';
import EditWarning from './EditWarning.js';

const styles = theme => ({
    radioGroup: {
        paddingLeft: 20,
        paddingTop: 16
    },
    container: {
        marginBottom: 24
    }
})

class AccessLevel extends PureComponent {

    onChange = selectedValue => {
        const { dispatch, isClosed } = this.props;

        const modal = {
            isClosed: !isClosed
        }
        if(!modal.isClosed){
            modal.isLimitedGroupEnabled = false
        }
        dispatch(updateCollectionGroupSettings(modal))
    }


    render() {
        const { classes, isClosed, t, orgTitle, isEditMode } = this.props;

        return (
            <FormControl container direction='column' component="fieldset" className={classes.container}>
                <Typography variant='h6'>{t('Group Access Level')}</Typography>
                <RadioGroup
                    aria-label="Group Access Level"
                    value={isClosed}
                    onChange={this.onChange}>
                    <CollectionPermissionSettingsOption
                        disabled={isEditMode}
                        key='true'
                        value={true}
                        primary={t('Closed')}
                        secondry={t('closedDescription')}
                    />
                    <CollectionPermissionSettingsOption
                        disabled={isEditMode}
                        value={false}
                        key='false'
                        primary={t('Open')}
                        secondry={t('openDescription', { orgTitle })}
                    />
                </RadioGroup>
                <EditWarning text={t('privacyWarning')}/>
            </FormControl>)

    }
}
const mapStateToProps = (state) => ({
    orgTitle: state.globalData.profileData.org.title,
    isClosed: state.collection.group.isClosed,
    isEditMode: !state.collection.isNew
})

export default compose(withStyles(styles),
    withTranslation('newGroupDialog'),
    connect(mapStateToProps))(AccessLevel);