
import { browserHistory } from 'react-router'
import fileDownload from 'react-file-download'
import $ from 'jquery';


export default class LinkPreviewService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getLinkPreview = (url) => {

        

        if (!this.validateUrl(url)) {
            return;
        }

      
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        
        var bodyPromise = fetch(process.env.API_SERVER + '/bookmarks/splash?link=' + url, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            return res.text();
        }).catch((res)=>{
            return res;
        });

        return new Promise((resolve, reject) => {




            bodyPromise.then((res) => {
                resolve(this.getDataFromBody(res));
            }).catch((err) => {
                reject(err);
            })



           
        });
    }


    findTitleInDom = ($dom) => {
        return $dom.find("meta[property='og:title']").attr("content") ||
            $dom.find("title").text() ||
            $dom.find("meta[name=title]").attr("content");
    }

    findDescriptionInDom = ($dom) => {
        return $dom.find("meta[property='og:description']").attr("content") ||
            $dom.find("meta[name=description]").attr("content") ||
            $dom.find("div .description").text();
    }

    findImageInDom = ($dom) => {
        var imageSrc = $dom.find("meta[property='og:image']").attr("content") ||
            $dom.find("meta[itemprop=image]").attr("content") ||
            $dom.find("link[rel=image_src]").attr("content") ||
            this.findFirstImageInBody($dom.find("body"));

        // maybe the returned url is relative
        if (imageSrc && !this.validateUrl(imageSrc)) {

            var a = document.createElement("a");
            a.href = this.url;

            imageSrc = a.protocol + "//" + a.hostname + imageSrc;
        }

        return imageSrc;
    }

    findFirstImageInBody = ($body) => {
        var result;

        var $images = $body.find("img[src]");

        var $img;
        $images.each(function () {
            $img = $(this);
            if ($img.attr("height") && $img.attr("height") > 40 &&
                $img.attr("width") && $img.attr("width") > 40) {
                result = this.src;
                return false;
            }
        });

        return result;
    }

    validateUrl = (value) => {
        return (/^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i).test(value);
    }



    getDataFromBody(data) {

        // old request
        // if (that.url !== url) {
        //     return;
        // }

        // that.emptyPreviewContainer();

        // html to lower case
        data = data.replace(/<\/?[A-Z]+[\w\W]*?>/g, function (m) {
            return m.toLowerCase();
        });

        // parse data to jQuery DOM object
        var dom = document.implementation.createHTMLDocument('');
        dom.body.innerHTML = data;
        var $dom = $(dom);

        // get components
        var title = this.findTitleInDom($dom),
            description = this.findDescriptionInDom($dom),
            image = this.findImageInDom($dom);

        return { title: title, image: image, description: description };

        // // build dom elements
        // var $title = $("<a></a>").attr("href", url).text(title),
        //     $description = $("<p></p>").text(description);

        // var $spanRight;
        // if (image) {
        //     var $image = $("<img></img>").attr("src", image),
        //         $spanLeft = $("<div></div>").addClass("span4");
        //     $spanRight = $("<div></div>").addClass("span8");
        //     $spanLeft
        //         .append($image);
        //     that.$previewContainer
        //         .append($spanLeft);
        // } else {
        //     $spanRight = $("<div></div>");
        // }

        // $spanRight
        //     .append($title)
        //     .append($description);
        // that.$previewContainer
        //     .append($spanRight);
    }


}