import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import AutosuggestField from '../Common/autosuggestField/autosuggestField';
import withErrorCatcher from '../Common/withErrorCatcher.js';
import ImgComment from "./imgComment";
import { getTaskText, isTask } from "./taskUtils";

// Apply some reset
const styles = theme => ({
    inputContainer: {
        flex: '1 2 calc(100% - 110px)',
        display: 'flex',
        flexFlow: 'nowrap',
        alignItems: 'center',
    },
    ...theme.comment
});


class AddComment extends Component {

    state = {
        text: '',
        isLoading: false
    }

    // onKeyPress = (event) => {
    //     if (event.key.toLowerCase() === 'enter') {
    //         event.preventDefault();
    //         this.addComment();
    //     }
    // }

    isLink(text) {
        const currPageLink = `${location.host}/search\\?searchquery=%23`
        const reg = new RegExp(`^\\s*(http(s)?://)?${currPageLink}[^\\s]*\\s*$`, 'i')

        return reg.test(text)
    }
    getLinkText(text) {
        const splited = text.split('/search?searchquery=%23') || []

        return splited[1]
    }
    addComment = () => {
        const { collaborationService, docId, title, onCommentAdd, onTaskAdd, profileData, onLinkAdded, changeCounter } = this.props;
        const { firstname, lastname, profileImageUrl, auth0_id } = profileData;

        var comment = {
            "docId": docId,
            "docTitle": title,
            "text": this.state.text,
            "userDisplayName": firstname + ' ' + lastname,
            "userPicUrl": profileImageUrl,
            "dateTime": (new Date()).toISOString(),
            "userId": auth0_id
        }


        this.setState({ isLoading: true });
        if (isTask(this.state.text)) {
            comment.text = getTaskText(this.state.text)

            collaborationService.addTask(comment).then(newTask => {
                this.setState({ text: '', isLoading: false });
                onTaskAdd(newTask)
            })
        } else if (this.isLink(comment.text)) {
            this.setState({ text: '', isLoading: false });

            const link = {
                docId,
                link: this.getLinkText(comment.text)
            }

            collaborationService.addLink(link).then(newDocument => {
                const newLink = newDocument.relatedDocuments.find(x => x.relatedDocumentId === link.link)
                onLinkAdded && onLinkAdded(newLink)
            })
        } else {
            collaborationService.commentDocument(comment).then(newComment => {
                this.setState({ text: '', isLoading: false });
                onCommentAdd(newComment)
                changeCounter('commentsCounter', 1)
            })
        }
    }

    onChange = (event, { newValue, method }) => {
        this.setState({ text: newValue })
    }

    render() {
        const { classes } = this.props;
        const { profileImageUrl, auth0_id } = this.props.profileData;

        let content;

        if (this.state.isLoading) {
            content = <CircularProgress className={classes.progress} />
        } else {
            content = <AutosuggestField
                value={this.state.text}
                onChange={this.onChange}
                onSubmit={this.addComment} />
        }

        return (
            <div data-intrcm-id="AddComment_l5dwma6k" className={classes.container} style={{ height: 55 }}>
                <ImgComment userId={auth0_id} src={profileImageUrl} containerStyle={{ display: 'flex' }} imgStyle={{ margin: 'auto 16px auto 16px' }} />
                <div data-intrcm-id="AddComment_7hpoxm6e" className={classes.inputContainer}>
                    {content}
                </div>
            </div>)
    }
}

AddComment.propTypes = {
    docId: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AddComment);