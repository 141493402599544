
import { TextField, withStyles, Icon, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PickFileComponent from '../../Common/autosuggestField/PickFileComponent';
import parseImgeUrl from '../../Common/parseImagUrl';

const styles = theme => ({
    gridItemIcons: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 48
    },
    textField: {
        fontSize: theme.typography.title.fontSize,
        fontWeight: theme.typography.title.fontWeight,
        fontStyle: theme.typography.title.fontStyle,
        fontFamily: 'Roboto',
        marginBottom: 10,
        marginTop: 10,
        border: 'none',
        width: '100%',
        '&:focus': {
            outline: 'none'
        }
    },
    optionIcons: {
        cursor: 'pointer',
        fontSize: 22
    },
    img: {
        width: 48,
        height: 48,
        objectFit: 'contain',
        borderRadius: 4,
    }
});

const IconPickerStyle = "cursor: pointer; margin-bottom: 12px; font-size: 22px; padding-right: 26px;"
const ImagePickerStyle = "cursor: pointer; margin-top: -10px; margin-right: 26px; height: 48px; width: 48px; object-fit: contain; border-radius: 4px;"

class PollInputOption extends React.Component {
    render() {
        const { classes, t, option, idx, onDeleteOption, onChange, addImage, cloudfront } = this.props;
        const { optionText, optionImg } = option;

        let parsedImageUrl = optionImg;

        if(optionImg){
            parsedImageUrl = parseImgeUrl(cloudfront, encodeURIComponent(optionImg), 512)
        }

        return <Grid container>
            <Grid item xs={10} style={{ paddingRight: 16, }} >
                <TextField
                    classes={classes}
                    className={classes.textField}
                    inputProps={{ dir: 'auto' }}
                    id={"option_" + idx}
                    placeholder={t("Add new option")}
                    onChange={onChange(idx)}
                    value={optionText}
                    fullWidth />
            </Grid>
            <Grid item xs={1} className={classes.gridItemIcons} >
                <PickFileComponent 
                    className={'imagePickerPollWrapper'}
                    onLoad={addImage(idx)} 
                    iconName='add_a_photo' 
                    iconStyle={IconPickerStyle}
                    image={parsedImageUrl}
                    imagePickerStyle={ImagePickerStyle}
                    renderFilePond={true}
                    acceptedFileTypes={['image/*', 'video/*']}
                />
            </Grid>
            <Grid item xs={1} className={classes.gridItemIcons} >
                <Icon className={classes.optionIcons} onClick={() => onDeleteOption(idx)}>clear</Icon>
            </Grid>
        </Grid>
    }
}
export default compose(
    withStyles(styles),
    withTranslation('postInput'),
)(PollInputOption);