import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useActionsCenterView } from '../../../../../../../hooks/useActionCenter/useActionCenterView';
import ActionsCenterWidgetControl from './control/ActionCenterControl';
import ActionsCenterMain from './main/ActionCenterMain';
import { setGoToMustRead } from './control/gg';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../../../Common/withErrorCatcher';

const _styles = (theme) => ({
    container: {
        marginTop: 8,
        // minHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight + 16 + 16}px)`,
        width: 300 + 16,
        background: 'rgba(255, 255, 255, 1)',
        boxShadow: 'rgba(140, 140, 140, 0.2) 0px 0px 0px 1px',
        borderRadius: 12,
        // border: 'solid 1px rgba(0, 0, 0, 0.9)'
    },
});

const ActionCenterWidget = (props) => {
    const {classes: styles, dispatch} = props
    const [actionsCenterView, setActionsCenterView] = useActionsCenterView()

    useEffect(() => {
        setGoToMustRead(() => setActionsCenterView('mustRead'))
    }, [setActionsCenterView])

    return <div data-intrcm-id="ActionCenterWidget_qtkr8xck" className={styles.container}>
        <ActionsCenterWidgetControl dispatch={dispatch} actionsCenterView={actionsCenterView} setActionsCenterView={setActionsCenterView} />
        <ActionsCenterMain actionsCenterView={actionsCenterView} />
    </div>
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(
    withErrorCatcher(),
    withStyles(_styles, {withTheme: true}),
    connect(null, mapDispatchToProps),
)(ActionCenterWidget);