import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = (theme) => ({
    container: {
        cursor: 'pointer',
        position: 'relative',
        width: 92,
        maxWidth: 92,
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 28,
        height: 28,
        border: 'dashed 2px #D7D7DB',
        borderRadius: 16,
        marginBottom: 20,
        zIndex: 2,
    },
    titleHoverText: {
        color: `${theme.customColors.primary.main}!important`
    },
    title: {
        position: 'absolute',
        bottom: 0,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        lineHeight: 'normal',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: '#1B1E2A',
        maxWidth: 65,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        zIndex: 2,
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: `${theme.customColors.primary.main}!important`
        },
    },
    hoverItem: {
        backgroundColor: '#F9F9FA!important'
    },
    hover: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'transparent',
        borderRadius: 8,
        width: 88,
        height: 88,
        transform: 'translate(-50%, -50%)',
        top: '55%',
        left: '50%',
        zIndex: 1
    },
})

function LaunchpadOptionAdder(props) {
    const {classes, categoryId, dispatch} = props
    const [t] = useTranslation('search')
    const [isHover, setIshover] = useState(false)

    const onClick = useCallback(() => {
        dispatch({
            type: 'SET_LAUNCHPAD_PAGE_TYPE',
            pageType: 'add_item',
            selectedCategory: categoryId
        })
    }, [categoryId])

    const Icon = <div data-intrcm-id="LaunchpadOptionAdder_5k067gqf" onClick={onClick} onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={classes.icon}>
        <AddIcon style={{color: '#737373'}} />
    </div>

    const Title = <div data-intrcm-id="LaunchpadOptionAdder_8cihove1" onClick={onClick} onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={`${classes.title} ${isHover ? classes.titleHoverText : ''}`}>{t("Add")}</div>
    return (
        <>
        <div data-intrcm-id="LaunchpadOptionAdder_krgzwvq6" className={classes.container} >
            {Icon}
            {Title}
            <div data-intrcm-id="LaunchpadOptionAdder_chcovaab" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} onClick={onClick} className={`${classes.hover} ${isHover ? classes.hoverItem : ''}`} />
        </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    connect(null, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(LaunchpadOptionAdder)

