import React from 'react';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
import Paper from '@material-ui/core/Paper';
import UserSettingsService from '../../../services/userSettingsService'
import { browserHistory } from 'react-router'
import VerifyForm from './forms/verifyForm'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import indigo from '@material-ui/core/colors/indigo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const errorMessages = {
    mailError: "Please provide a valid email address",
    passwordError: "Password should be equal",
    wordsError: "Please only use letters",
    numericError: "Please provide a number",
    urlError: "Please provide a valid URL",
}

const styles = theme => ({
    divStyle: {
        width: 400,
        margin: 'auto',

        padding: 20,
    },
    paperStyle: {
        width: 400,
        margin: 'auto',
        marginTop: 100,
        padding: 20,
    },
    switchStyle: {
        marginBottom: 16,
    },
    submitStyle: {
        marginTop: 32,
    },
    paperForm: {
        marginTop: -65
    },
    formBack:{
        backgroundColor:indigo[500],
        height:150
    }
});

class Verify extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.userSettingsService = new UserSettingsService();
        this.state = this.getInitialState();

    }

    getInitialState = () => {
        return {
            canSubmit: false,
            open:false
        };
    }



    enableButton = () => {
        this.setState({
            canSubmit: true,
        });
    }

    disableButton = () => {
        this.setState({
            canSubmit: false,
        });
    }
    handleClose = () => {
        this.setState({ open: !this.state.open })
    }
    submitForm = (data) => {
        data.activation_token = this.props.location.query.userToken;
                this.userSettingsService.verify(data).then((result) => {
                    browserHistory.replace('/')
                });        
    }



    render() {
        const { classes } = this.props;
        const { isFinished } = this.state;

        let { paperStyle, divStyle, switchStyle, submitStyle } = styles;
        let { wordsError, numericError, urlError, mailError, passwordError } = errorMessages;
        return (<div data-intrcm-id="verify_cnr7pdt0">
        <div data-intrcm-id="verify_eu4egspj" className={classes.formBack}>
        </div>
        <Grid container spacing={16} >
            <Grid md={3} />
            <Grid md={6}>
                <Paper className={classes.paperForm}>
                <VerifyForm  onSubmit={this.submitForm} handleModal={this.handleClose}  />

                </Paper>
            </Grid>
            <Grid md={3} />
        </Grid>
        <Dialog
                open={this.state.open}
                transition={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Terms and Conditions"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div data-intrcm-id="verify_52sv83rp">
        <p style={{textAlign: 'center'}}><strong><u>Privacy Policy</u></strong></p>
        <p>Tuqqi Systems LTD. (“<strong>Tuqqi</strong>”, “<strong>we</strong>”) is committed to maintaining the privacy of its customers and website users (“<strong>user</strong>”, “<strong>you</strong>”). The following information describes how Tuqqi collects and processes information about you when you use our website (the “<strong>Website</strong>”).</p>
        <p>Our Privacy Policy explains:</p>
        <ul>
          <li>What information we collect and why we collect it.</li>
          <li>How we use that information.</li>
          <li>Your rights with regard to the collection of such information.</li>
        </ul>
        <p>Tuqqi provides a platform that connects to Tuqqi's customer's employee/organization's working tools &amp; personal computers, for processing and indexing data inside the tools based on configurations to provide search, updates and knowledge management services ("<strong>Services</strong>").</p>
        <p>By using our Website and Services, you agree to this Privacy Policy.</p>
        <p><strong><u>Collecting Information</u></strong></p>
        <p>Please note that the scope of this Privacy Policy is limited only to information collected by Tuqqi through your use of its Website and Services. Some information may be automatically collected, and some is collected when you interact with our Website and Services. The type of information that may be automatically collected<strong>&nbsp;</strong>is non-personal information, which includes your session durations, the content you accessed on the Website or Service, the frequency and scope of your use of the Website or the Service and information about your computer and internet connection including the operating system you use and browser type.</p>
        <p>Information from which you can be personally identified may also be collected, including but not limited to your name, email address, and personal preference with respect to the content provided to you by us (hereinafter: “<strong>Personal Information</strong>”). Furthermore, as part of your use of our Services we will collect your log information as well as your location Information. This includes your IP address, actual location and other sensors that may provide us with identifiable information.</p>
        <p>Personal Information shall be collected only if received voluntarily from you, including, without limitation, by registering to be provided with our Services, by interacting with other users via our blog available on our Website or by completing the contact forms available through the Website.</p>
        <p><strong><u>Access through Third Party Services</u>&nbsp;</strong></p>
        <p>By accessing the Services through another third party platform or service or by connecting to such a third party network, platform or service via the Service, you are authorizing us to collect, store, and use your Personal Information in accordance with this Privacy Policy and allow us to access to any and all information that you agreed the other third party platform could provide to us through the social network/third party platform Application Programming Interface (API), based on your settings on the third party social network or platform.&nbsp;</p>
        <p><strong><u>Use of Information</u></strong></p>
        <p>We use the Personal Information we collect from you for a range of different business purposes according to different legal bases of processing. We may use or process your Personal Information for the following purposes. One or more purposes may apply simultaneously.</p>
        <ol>
          <li><strong>Providing the Requested Services</strong></li>
        <p><strong>&nbsp;</strong></p>
        <ul>
          <li>We collect Personal Information to provide you with the Services you contracted to receive.</li>
          <li>Such collection of information will enable us to provide you with technical and professional assistance, with regard to the Services you use or intend to use.&nbsp;</li>
          <li>When you contact us, we keep a record of your communication to help solve any issues you might be facing. We may use your email address to inform you about our Services, and respond to your inquiries.</li>
        </ul>
        <p>
          <br />
        </p>
        <p>We process the Personal Information where it is necessary for the adequate performance of the contract regarding the requested Services.</p>
        <p>
          <br />
        </p>
        
          <li><strong>Improvement and Development of the Services</strong></li>
        
        <p>
          <br />
        </p>
        <ul>
          <li>We collect Personal Information to improve and develop our Services and understand feedback on Tuqqi's platform and to help provide more information on the use of Services more quickly and easily.</li>
          <li>We collect Personal Information for ongoing review and improvement of the information provided on our Website to ensure it is user friendly.&nbsp;</li>
          <li>We collect Personal Information to improve the management and administration of our business and maintain compliancy with our internal policies and procedures.</li>
          <li>We conduct surveys and research, test features in development, and analyze the information we have to evaluate and improve our Services, develop new features, and conduct audits and troubleshooting activities.&nbsp;</li>
        </ul>
        <p>
          <br />
        </p>
        <p>We process this information in light of our legitimate interest in improving the Services, to allow our users to have the best experience.</p>
        
          <li><strong>Maintain a Safe and Secure Environment</strong></li>
      
        <p>We may use your information to detect and prevent fraud, abuse and security incidents in the following ways;</p>
        <ul>
          <li>Verify and authenticate your identity and prevent unauthorized or illegal activity;</li>
          <li>Enhance the safety and security of our Website and Services;</li>
          <li>Conduct security investigations and risk assessments;</li>
          <li>Prevent or take action against activities that are, or may be, in breach of our terms of service or applicable law.&nbsp;</li>
        </ul>
        <p>We process this information in light of our legitimate interest in improving our Services and enabling our users to browse in a secure environment.</p>
        
          <li><strong>Personalize Content, Advertising and Marketing</strong></li>
      
        <p>
          <br />
        </p>
        <ul>
          <li>If you have used&nbsp;Tuqqi's services in the past, we have a legitimate business interest for matching the data we collect with other data we had already collected.&nbsp;</li>
          <li>This enables us to understand your needs and interests, optimize the content we send you and make it more suitable and relevant to your needs.</li>
          <li>This also enables us&nbsp;to improve your experience on the Website by providing you with personalized content, recommendations, and features.</li>
        </ul>
        
        <p>
          <br />
        </p>
        <p>We process this information in light of our legitimate interest to personalize your experience and customize our content.</p>
        </ol>

        <p><strong><u>Disclosure of Information and Transfer of Data</u></strong></p>
        <p>Except as otherwise provided in this Privacy Policy, we reasonably attempt to ensure that we never intentionally disclose any of your Personal Information, to any third party without having received your permission, except as provided for herein or otherwise as permitted or required under law.</p>
        <p>In order to perform our contractual and other legal responsibilities or purposes, we may, from time to time, need to share your Personal Information with third parties. We may as well share your Personal Information with our affiliates, subsidiaries or any third party service providers and individuals to facilitate our Services or any portion thereof, such as marketing, data management or maintenance services. We may also share your information with analytics service providers for analytics services. Such analytics service providers set their own cookies or other identifiers on your computer, through which they can collect information about your usage of our Website and Services. This helps us compile aggregated statistics about the effectiveness of our Website and Services.&nbsp;</p>
        <p>The above mentioned third parties may be located in countries other than your own, and we may send them information we receive. When such third party service providers process your Personal Information on our behalf, we will assure that they comply with obligations similar to those which are set forth in this Privacy Policy. We will also assure that they will abide by our data privacy and security requirements, and will be allowed to use the Personal Information solely for the purposes we set. We will transfer your Personal Information while using appropriate and suitable safeguards, while using a variety of legal mechanisms, including contracts, to ensure your rights and protections travel with your data.&nbsp;</p>
        <p>We may also transfer your information, including Personal Information, in connection with a corporate merger, consolidation, the sale of related assets or corporate division or other fundamental corporate changes. Furthermore, information about you may also be released in order to comply with any valid legal obligation or inquiry or process such as a search warrant, subpoena, statute or court order. We will also release specific information in special cases, such as if you use the Website or Services to perform an unlawful act or omission or take any act or omission that may damage Tuqqi, its property and goodwill, or if there is an attempted breach of the security of the Website or&nbsp;&nbsp;Service or a physical or property threat to you or others. The authority supervising such activities is the Israeli Privacy Protection Authority, and you have the right to file a complaint to it or any other relevant supervisory authority.</p>
        <p><strong><u>Your Rights</u></strong></p>
        <p>You have the right at any time to request to access or modify your information. To exercise these options, please contact us at <a href="mailto:i@tuqqi.com">i@tuqqi.com</a>.&nbsp;</p>
        <p>In some jurisdictions, in particular those located within the European Union (the "<strong>EU</strong>") or within the European Economic Area (the "<strong>EEA</strong>"), you may be afforded specific rights regarding your Personal Information. Subject to such eligibility, you may have the following rights to:</p>
        <ol>
          <li>Request a rectification of your Personal Information where the information we hold about you is incorrect or incomplete.</li>
          <li>Object to the processing of your Personal Information for direct marketing purposes.</li>
          <li>Object to the processing of your Personal Information where our legal basis for that processing is that such processing is necessary for our legitimate interests.</li>
          <li>Object to an automated decision-making (including profiling) in certain circumstances.</li>
          <li>Request the erasure of your Personal Information in certain circumstances, such as where processing is no longer necessary for the purpose it was originally collected for, and there is no compelling reason for us to continue to process or store it.</li>
          <li>Receive your Personal Information, or ask us to transfer it to another organization that you have provided to us, which we process by automated means, where our processing is either based on your consent or is necessary for the performance of a contract with you.</li>
        </ol>
        <p>
          <br />
        </p>
        <p>Generally, with regard to information collected on our Website, Tuqqi is a "Data Controller". Therefore, if you wish to exercise the above mentioned rights, please contact us, and we will make our best efforts to fulfill your request.&nbsp;</p>
        <p>With regard to information collected via the Services, Tuqqi is considered a "Data Processor", and Tuqqi's customer is the Data Controller of the information provided to Tuqqi by such customer. If you wish to exercise the above mentioned rights, you should contact our relevant customer, and they will have the duty to respond to your request. If you still wish to contact us, we assure you that we will make our best efforts to fulfill your request.&nbsp;</p>
        <p>If you wish to file a request regarding any of the above, you may contact us at: <a href="mailto:i@tuqqi.com">i@tuqqi.com</a>.&nbsp;</p>
        <p><strong><u>Cookies</u></strong></p>
        <p>We may use “cookies” and/or other technologies or files (collectively, “<strong>cookies</strong>”) to identify how visitors make use of this Website and our Services. This aggregated tracking information may be used to help us improve and enhance the Website and Service experience for all of our users. In addition, cookies are used for adjusting the Website and Services to your personal preferences. Cookies contain information such as the pages you visited, the length of time you stayed on the Website or Services, the location from which you accessed the Website and Services and more. If you would prefer not to have cookies stored on your computer, you may modify your browser settings to reject most cookies, or manually remove cookies that have been placed on your computer. However, by rejecting the cookies, you may be unable to fully access the offerings on this Website and our Services. To find out more about cookies, visit <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
        <p>
          <br /><strong><u>Opt In or Opt Out</u></strong></p>
        <p>You are always in control of your data, and if you choose to receive information from us, you can change your mind later. If, at any time, you would like to stop receiving such information or opt out of a feature, you may notify us by writing to <a href="mailto:i@tuqqi.com">i@tuqqi.com</a>. You should be aware, however, that it is not always possible to completely remove or modify information in our databases and servers, although we will always make reasonable efforts to do so upon your request.&nbsp;</p>
        <p><strong><u>Links to Other Websites</u></strong></p>
        <p>This Website may provide links to other websites. Please be aware that these other websites are not covered by our Privacy Policy. This Privacy Policy does not cover the information practices exercised by other providers of products or services, advertisers or other websites, companies or individuals, which are not owned or controlled by Tuqqi. We suggest that when linking to another website, you always read that website’s privacy policy before volunteering any personally identifiable information.<strong>&nbsp;</strong></p>
        <p><strong><u>Data Security</u></strong></p>
        <p>We deploy industry standard measures to ensure the security, confidentiality, integrity and availability of Personal Information we process. We maintain physical, technical and administrative safeguards, and test and update these periodically. We endeavor to restrict access to Personal Information on a ‘need to know’ basis for the provision of Services to you. No such measures are perfect or impenetrable. In the event of a security breach, we will take all reasonable action to minimize any harm. Although we will do our best to protect Personal Information, we cannot guarantee the security of data transmitted to our site or to group companies, and transmission is at the users own risk.</p>
        <p><strong><u>Data Retention</u>&nbsp;</strong></p>
        <p>Generally, Tuqqi does not retain information longer than necessary to provide its Services and for its reasonable business and lawful needs. If you withdraw your consent to us processing your data, we will erase your Personal Information from our systems, unless the data is required for Tuqqi to establish, exercise or defend against legal claims or it is necessary for the performance of the requested Services.</p>
        <p><strong><u>Children's Privacy</u></strong></p>
        <p>The Service is not intended for children under the age of 16. We do not, knowingly or intentionally, collect information about children who are under 16 years of age.&nbsp;</p>
        <p><strong>IF YOU ARE UNDER THE AGE OF 16 YOU MAY NOT USE THE SERVICE, UNLESS PARENTAL CONSENT IS PROVIDED ACCORDINGLY</strong></p>
        <p><strong><u>Questions Regarding Our Privacy Policy</u></strong></p>
        <p>If you have any questions regarding this Privacy Policy or the practices described above, you are always welcome to contact us at <a href="mailto:i@tuqqi.com">i@tuqqi.com</a>.</p>
        <p><strong><u>Revisions and Modifications to our Privacy Policy</u></strong></p>
        <p>We reserve the right to revise, amend, or modify this Privacy Policy at any time. When changing the policy, we will update this posting accordingly. Please review this Privacy Policy often so that you will remain updated regarding our current policies.</p>
        <p><strong><u>Governing Law and Jurisdiction</u></strong></p>
        <p>This Privacy Policy will be governed and interpreted pursuant to the laws of the State of Israel without giving effect to its choice of law rules. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to this Privacy Policy shall be to the competent courts in Tel Aviv, Israel, to the exclusion of any other jurisdiction.</p>
        <p>
          <br />
        </p>
        <p>This page was updated on 20 August 2019</p>
      </div>
            </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Ok
            </Button>

                </DialogActions>
            </Dialog>
        <br/><br/>
    </div >
   
        );
    }
}

const mapStateToProps = (state) => ({
    // userData: state.userData,
    // ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Verify);