import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import { emojisCategoryData } from "@tuqqi/common";
import React from 'react';

const styles = theme => ({
    formControl: {
        margin: '0px',
        display: 'flex'
    },
    description: {
        color: '#979AAA',
        margin: 0,
        fontSize: '0.75rem',
        textAlign: 'left',
        marginTop: 8,
        minHeight: '1em',
        fontFamily: 'Rubik',
        lineHeight: '1em',
    },
    selectMenu: { display: 'flex', alignItems: 'center' },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto',
        fontSize: 18,
    },
})




class _NewIconSelector extends React.Component {
    _onChange = (e) => {
        e.persist()
        this.props.onChange(e.target.value)
    }
    render() {
        const { classes, icon, label, disabled, description } = this.props;

        return <><FormControl disabled={disabled} className={classes.formControl}>
            <InputLabel htmlFor="icon">{`${label || 'Icon'}`}</InputLabel>
            <Select
                classes={{ selectMenu: classes.selectMenu }}
                value={icon}
                onChange={this._onChange}
                inputProps={{ name: 'icon', id: 'icon', }}
            >
                {emojisCategoryData.map((iconData, idx) => {
                    return (
                        <MenuItem key={idx} value={iconData.iconName}>
                            {iconData.iconName}
							<div className={classes.icon}>{iconData.icon}</div>
                        </MenuItem>
                    )})}
            </Select>
        </FormControl>
        {!!description && <InputLabel className={classes.description} htmlFor="icon">{description}</InputLabel>}
        </>
    }
}

export const NewIconSelector = withStyles(styles)(_NewIconSelector);