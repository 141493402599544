import { Avatar, Grid, withStyles } from '@material-ui/core';
import classnames from "classnames";
import moment from "moment";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview';
import parseImageUrl from '../../../components/Common/parseImagUrl';
import TableDocMembers from '../../../components/Common/TableDocMembers';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import SocialDataAttachment from '../../../components/Kanban/Card/FooterKanbanCard/SocialData/SocialDataAttachment';
import SocialDataComment from '../../../components/Kanban/Card/FooterKanbanCard/SocialData/SocialDataComment';
import SocialDataSeen from '../../../components/Kanban/Card/FooterKanbanCard/SocialData/SocialDataSeen';
import SocialDataTask from '../../../components/Kanban/Card/FooterKanbanCard/SocialData/SocialDataTask';
import KanbanLabelsWrapper from '../../../components/Kanban/Card/KanbanLabelsWrapper';
import { COLUMN_DATA_TYPE_ATTACHMENTS, COLUMN_DATA_TYPE_BUSINESS_ID, COLUMN_DATA_TYPE_COLLECTION_LABELS, COLUMN_DATA_TYPE_COMMENTS_COUNTER, COLUMN_DATA_TYPE_DOC_TIMESTAMP, COLUMN_DATA_TYPE_DUE_DATE_UTC, COLUMN_DATA_TYPE_GROUP, COLUMN_DATA_TYPE_LAST_MODIFED_TIME, COLUMN_DATA_TYPE_MEMBERS, COLUMN_DATA_TYPE_RATING, COLUMN_DATA_TYPE_READ_CONFIRM, COLUMN_DATA_TYPE_RELATED_DOCUMENTS, COLUMN_DATA_TYPE_SEEN_COUNTER, COLUMN_DATA_TYPE_STATUS, COLUMN_DATA_TYPE_TASK_COUNTER, COLUMN_DATA_TYPE_TITLE } from './tableUtil';

const styles = theme => ({
    container: {
        "height": "53px",
        boxSizing: 'border-box',
        "backgroundColor": '#ffffff',
        padding: '8px 0px 8px 32px',
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        "color": theme.newUI.color.commonBlack,
        '&:last-child': {
            paddingRight: 16
        }
    },
    title: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        "color": theme.newUI.color.commonBlack
    },
    avatar: {
        width: 24,
        height: 24
    },
    label: {
        marginBottom: -4
    },
    textContainer: {
        width: 'fit-content',
    },
    clickable: {
        cursor: 'pointer'
    }
})

class TableCell extends Component {

    openItem = () => {

        const { row, docId, openFullview } = this.props;
        openFullview(row, docId, false)
    }

    cutText(text = '') {
        if (text && typeof text === 'string') {
            const suffix = text.length > 60 ? '...' : ''

            return text.substr(0, 60) + suffix
        }

        return ''
    }

    buildData() {
        const { row, column, docId, classes, cloudfront, inputMappings } = this.props;

        const data = column.extractData(row)

        if (!data && column.type !== COLUMN_DATA_TYPE_ATTACHMENTS) {
            return { text: '' }
        }

        switch (column.type) {
            case 'text':
            case 'email':
            case 'paragraph':
            case 'select':
            case 'postText':
            case 'location':
            case 'checkbox':
            case 'mailFrom':
            case 'contactFullName':
            case 'contactCompany':
            case 'contactEmail':
            case 'contactPhoneNumber':
            case 'contactAddress':
            case 'contactBirthday':
            case 'contactJobTitle':
            case 'contactAdditionalInfo':
            case 'companyFullName':
            case 'companyID':
            case 'companyEmail':
            case 'companyPhoneNumber':
            case 'companyType':
            case 'companyAddress':
            case 'companyFoundingYear':
            case 'companyCEO':
            case 'companyNumberOfEmployees':
            case 'companyAdditionalInfo':
            case 'eventLocation':
            case 'eventDescription':
                return { className: classes.title, text: this.cutText(data) }
                break;
            case 'phone':
                return {className: classes.title, text: data}

            case COLUMN_DATA_TYPE_TITLE:
                return { className: classes.title, text: this.cutText(data), onClick: this.openItem }
                break;
            case 'multiSelect':
                const value = data && data.length ? data.join(", ") : ''
                return { className: classes.title, text: this.cutText(value) }
                break;


            case COLUMN_DATA_TYPE_DOC_TIMESTAMP:
            case COLUMN_DATA_TYPE_LAST_MODIFED_TIME:
            case 'eventDate&Time':
                return { text: moment(data).format('MMMM Do, YYYY, HH:mm') }
                break;

            case COLUMN_DATA_TYPE_COLLECTION_LABELS:
                return { text: <KanbanLabelsWrapper classes={{ container: classes.label }} docLabels={data} /> }
                break;

            case COLUMN_DATA_TYPE_DUE_DATE_UTC:
                return { text: data && moment(data).format('DD/MM/YYYY, HH:mm') }
                break;

            case COLUMN_DATA_TYPE_SEEN_COUNTER:
                return { text: <SocialDataSeen seenCounter={data} /> }
                break;

            case COLUMN_DATA_TYPE_COMMENTS_COUNTER:
                return { text: <SocialDataComment commentsCounter={data} /> }
                break;
            case COLUMN_DATA_TYPE_BUSINESS_ID:
                return { text: data}
                break;

            case COLUMN_DATA_TYPE_TASK_COUNTER:
                return { text: <SocialDataTask taskDoneCounter={data.taskDoneCounter} taskCounter={data.taskCounter} /> }
                break;
            case COLUMN_DATA_TYPE_READ_CONFIRM:
                return { text: <SocialDataTask taskDoneCounter={data.mustReadUsersApproved} taskCounter={data.mustReadUsers} /> }
                break;

            case COLUMN_DATA_TYPE_RELATED_DOCUMENTS: {
                if(!data?.length) return { text: '' }
                return { text: <SocialDataAttachment counter={data?.length ?? 0} /> }
            }
            case COLUMN_DATA_TYPE_ATTACHMENTS: {
                const mapping = inputMappings.find(m => m?.customDataMapping?.dataTypeFilter === row?.dataType)
                const data = column.extractData(row, mapping)

                return { text: data?.length || ''}
            }



            case 'date':
                return { text: moment(data).format('DD/MM/YYYY') }
            case COLUMN_DATA_TYPE_RATING:
                return { text: data }
            case 'dateTime':
                return { text: moment(data).format('DD/MM/YYYY, HH:mm') }

            // case 'avatar':
            //     return { text: <Avatar className={classes.avatar} src={parseImageUrl(cloudfront, data[column.sourceField], 300)} /> }
            case 'links':
                return { text: data && data.map(link => <a style={{ marginRight: 8 }} target='_blank' href={link.link}>{link.title}</a>) }
            case 'bookmark':
                return { text: <a target='_blank' href={data.link}>{data.previewTitle}</a> }

            case 'questionStatus':
            case 'questionAnswer':
            case 'questionWhoAnswered':
            case 'eventGuests':
            case COLUMN_DATA_TYPE_STATUS:
            case COLUMN_DATA_TYPE_GROUP:
                return { text: data }

            case COLUMN_DATA_TYPE_MEMBERS:
                return { text: <TableDocMembers maxSize={3} docMembers={data} />, minWidth: 140 }

            case 'contactAvatar':
            case 'companyAvatar':
                return { text: <Avatar className={classes.avatar} src={parseImageUrl(cloudfront, data, 300)} /> }

            case 'contactWebsite':
            case 'contactFacebook':
            case 'contactLinkedin':
            case 'companyWebsite':
            case 'companyFacebook':
            case 'companyLinkedin':
            case 'eventGoToEvent':
                return { text: <a href={data} target='_blank'>{data}</a> }
            case 'number':
                return { text: data.view }
            case 'calculation':
                return { text: data.view }
            default:
                break;
        }

    }

    render() {
        const { classes, column } = this.props;

        const { text, className, dataType, minWidth, onClick } = this.buildData();


        return (
            <td onClick={onClick} className={classnames(classes.container, className)} style={{ minWidth }}>
                <Grid container alignItems='center' item dir='auto' className={classnames(classes.textContainer, onClick && classes.clickable)}>
                    {text}
                </Grid>
            </td>
        )
    }

}


const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    inputMappings: state.globalData.inputMappings ?? [],
})

export default compose(withStyles(styles),
    withOpenFullview,
    withErrorCatcher(),
    connect(mapStateToProps, null))(TableCell);