import React from 'react'
import CellRenderStartEnd from './CellRenderStartEnd';
// import CellRenderDateParent from './CellRenderDateParent';

export const columnStartRendererCell = ({ record, value, ...rest }) => {
    const { id, originalData: {isItemRow }} = record;

    if(!isItemRow || !id) return ''
    const value1 = [value, record.endDate]

    return <CellRenderStartEnd isEnd={false} record={record} value={value1}  />
}

export const columnEndRendererCell = ({ record, value }) => {
    const { id, originalData: {isItemRow }} = record;

    if(!isItemRow || !id) return ''

    const value1 = [record.startDate, value]

    return <CellRenderStartEnd isEnd={true} record={record} value={value1}  />
}