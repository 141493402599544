import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import React, { Component, Fragment } from 'react';

import withErrorCatcher from './withErrorCatcher.js'

import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "./spinner";

const styles = theme => ({

    chip: {
        margin: 5
    },
    disScrollig: {
        overflow: 'hidden'
    },
    list:{ maxHeight: 700, overflow: 'auto' }

});


class LongList extends Component {

    state = {
        page: 0
    };




    render() {
        const { classes, list, useWindow } = this.props;


        const delta = 20


        return (

            <List className={classes.list}>
                <InfiniteScroll
                    useWindow={useWindow}
                    pageStart={0}
                    loadMore={(page) => { this.setState({ page: this.state.page + 1 }) }}
                    hasMore={(this.state.page * delta) < list.length}
                    loader={<Spinner classes={classes} />
                    }>

                    {list.slice(0, (this.state.page) * delta).map(this.props.render)}
                </InfiniteScroll>
            </List>
        );
    }
}

export default withStyles(styles)(LongList)
