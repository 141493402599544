import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import Linkify from 'react-linkify';
import classnames from "classnames";

const styles = theme => ({
    container:{
        padding:16,
        width:'100%'
    },
    postText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#21212d"
      }
});

class PostTemplate extends PureComponent {
    linkifyProperties = { target: '_blank', style: { "textOverflow": "ellipsis", wordBreak: 'break-all', color: 'blue' } }

    render() {
        const { classes, result } = this.props;

        return (
            <div data-intrcm-id="PostTemplate_kbzthq2k" container className={classnames(classes.postText, classes.container)} dir='auto'>
                <Linkify properties={this.linkifyProperties}>
                    {result.postText}
                </Linkify>
            </div >)

    }
}

export default compose(withStyles(styles))(PostTemplate);
