import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFileLinkFromCloundfront } from '../../../utils/filesUtils.js';
import withErrorCatcher from '../Common/withErrorCatcher.js';
import PollTemplate from '../FeedViewComponents/ContentPreview/DataTypeTemplates/Poll/PollTemplate.js';
import PostTemplate from '../FeedViewComponents/ContentPreview/DataTypeTemplates/PostTemplate';
import CodeView from './ItemFullViews/codeFull';
// import DataItemTemplateFull from './ItemFullViews/dataItemTemplateFull';
import DefaultFullView from './ItemFullViews/defaultFullView';
import EventFullView from './ItemFullViews/EventFullView.js';


// Apply some reset
const styles = theme => ({
    preview: {
        paddingRight: 30, paddingLeft: 30
    }
});

class ItemFullViewContent extends Component {

    openLink = () => {
        const { collaborationService, itemId, item } = this.props;

        collaborationService.logClick(itemId);
        window.open(item.link, '_blank')
    }
    openItem = () => { }
    openFile = () => {
        const { cloudfront, item } = this.props;
        const link = getFileLinkFromCloundfront(cloudfront, item.attachments[0].fileUid)
        window.open(link)
    }

    showFullViewContent = () => {
        const { item, itemId } = this.props;

        var properties = {
            docId: item.docId,
            result: item,
            relatedItems: item.relatedDocuments,
            openLink: this.openLink,
            openItem: this.openItem,
            openFile: this.openFile,

            dataTypeName: item.dataType
        }

        switch (item.dataType) {

            case 'code':
                return <CodeView />
            case 'question':
                return <PostTemplate {...properties} />

            case 'event':
                return <EventFullView item={item} />
            case 'poll':
                return <PollTemplate {...properties} />
            default:

                return <DefaultFullView />

        }
    }

    render() {
        const { dispatch, searchQuery, classes } = this.props;

        return <div data-intrcm-id="itemFullViewContent_5uhvhkmd" className={classes.preview}>
            {this.showFullViewContent()}
        </div>
    }
}

const mapStateToProps = (state) => ({
    searchQuery: state.search,
    item: state.fullView.item,
    itemId: state.fullView.itemId,
    cloudfront: state.globalData.cloudfront,
    collaborationService: state.services.collaborationService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemFullViewContent);