import { FULL_RESET_STORE } from "../actionTypes/actionTypes";

const initialState = [];

const userData = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case 'SERVICES_INIT':
      return Object.assign({}, state, {
       ...action.services

      });
   
    default:
      return state
  }
}

export default userData;