import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer';
import { NewUiWrapper, GroupConnectors, managerConnectorsView } from '@tuqqi/web';
import { themeConstants } from '../../../../theme';
import { store } from '../../../../index.js';
import { GroupLeftDrawerNew } from '../GroupLeftDrawer/GroupLeftDrawerNew.js';

const styles = theme => ({
    settings: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: '#f4f6fc',
    },
    settings1: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: '#f4f6fc',
    },
    settings0: {
        marginTop: -11,
        marginLeft: 0,
        width: 'fit-content',
        backgroundColor: '#f4f6fc',
    },
})

class GroupConnectorsNew extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isDrawerOpen: true,
            showLeft: true
        }
    }


    handleSwitchDrawerStatus = (status) => {
        this.setState({isDrawerOpen: status})
    }

    handleShowLeft = (showLeft) => {
        this.setState({showLeft})
    }

    componentDidMount(){
        managerConnectorsView.setShowLeft(this.handleShowLeft)
    }


    render() {
        const { classes, currentGroup, groupUid, isDrawerOpen } = this.props;
        const { showLeft } = this.state;

        const callbacks = {
        }

        return <CategoryViewOnlyLeftDrawer >
            {/* {showLeft && <GroupLeftDrawerNew handleSwitchDrawerStatus={this.handleSwitchDrawerStatus} isDrawerOpen={isDrawerOpen}/>} */}
            <div data-intrcm-id="groupConnectorsNew_86cldu0w" className={!showLeft ? classes.settings0 : isDrawerOpen ? classes.settings : classes.settings1}>
                <NewUiWrapper store={store} callbacks={callbacks}>
                    { !!currentGroup.isOpen && <GroupConnectors drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth  : themeConstants.navigationDrawerSmallPXWidth} groupUid={groupUid ?? ''} /> }
                </NewUiWrapper>
            </div>
        </CategoryViewOnlyLeftDrawer>
    }
}
const mapStateToProps = (state) => ({
    groupUid: state.group?.groupUid,
    currentGroup:  state.group,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    withErrorCatcher("Failed Loading Settings, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupConnectorsNew);