import { useState, useEffect } from "react";
import user_add_commentGroup from '../../../../assets/img/stories/user_add_comment/Group/mainImg.png';
import user_add_commentPublic from '../../../../assets/img/stories/user_add_comment/Public/mainImg.png';
import user_add_itemPublic from '../../../../assets/img/stories/user_add_item/Public/mainImg.png';
import user_add_itemGroup from '../../../../assets/img/stories/user_add_item/Group/mainImg.png';
import user_add_taskGroup from '../../../../assets/img/stories/user_add_task/Group/mainImg.png';
import user_add_taskPublic from '../../../../assets/img/stories/user_add_task/Public/mainImg.png';
import user_askedtoread_itemPublic from '../../../../assets/img/stories/user_askedtoread_item/Public/mainImg.png';
import user_done_taskPublic from '../../../../assets/img/stories/user_done_task/Public/mainImg.png';
import user_done_taskGroup from '../../../../assets/img/stories/user_done_task/Group/mainImg.png';
import user_link_itemPublic from '../../../../assets/img/stories/user_link_item/Public/mainImg.png';
import user_move_item_lanePublic from '../../../../assets/img/stories/user_move_item_lane/Public/mainImg.png';
import user_read_itemPublic from '../../../../assets/img/stories/user_read_item/Public/mainImg.png';

const useMainImg = (eventType, groupType) => {
  const [mainImgUrl, setMainImgUrl] = useState("");

  useEffect(() => {
    const imageData = `${eventType}${groupType}`
    switch(imageData) {
        case 'user_add_commentGroup':
          setMainImgUrl(user_add_commentGroup);
            break;
        case 'user_add_commentPublic':
          setMainImgUrl(user_add_commentPublic);
            break;
        case 'user_add_itemPublic':
          setMainImgUrl(user_add_itemPublic);
            break;
        case 'user_add_itemGroup':
          setMainImgUrl(user_add_itemGroup);
            break;
        case 'user_add_taskGroup':
          setMainImgUrl(user_add_taskGroup);
            break;
        case 'user_add_taskPublic':
          setMainImgUrl(user_add_taskPublic);
            break;
        case 'user_askedtoread_itemPublic':
          setMainImgUrl(user_askedtoread_itemPublic);
            break;
        case 'user_done_taskPublic':
          setMainImgUrl(user_done_taskPublic);
            break;
        case 'user_done_taskGroup':
          setMainImgUrl(user_done_taskGroup);
            break;
        case 'user_link_itemPublic':
          setMainImgUrl(user_link_itemPublic);
            break;
        case 'user_move_item_lanePublic':
          setMainImgUrl(user_move_item_lanePublic);
            break;
        case 'user_read_itemPublic':
          setMainImgUrl(user_read_itemPublic);
            break;
        default:
          setMainImgUrl(user_add_itemPublic);
    }

}, [eventType, groupType])

  return { mainImgUrl };
};

export default useMainImg;
