/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Grid, Avatar } from '@material-ui/core';


const styles = theme => ({
  root: {
    // width:400, 
    margin: '14px 32px',
    flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div data-intrcm-id="AutosuggestChip_88dvck2i" ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

const OptionItem = (props) => {
  const { label, avatar,backgroundColor, isColored } = props.data
 
  return (
    <Fragment>
      {avatar ? <Avatar style={{ height: 30, width: 30, marginRight: 10 }} src={avatar} /> : null}
     { isColored && <div data-intrcm-id="AutosuggestChip_90r90utx" style={{width: 10, height: 10, backgroundColor: backgroundColor ? backgroundColor: 'rgb(240, 243, 255)', marginRight: 10}} /> }
      <Typography>
        {label}
      </Typography>
    </Fragment>
  )
}
function Option(props) {
  const { data } = props
  return (
    <MenuItem
      key={data && data.value}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {
        data ? <OptionItem data={data} /> : props.children
      }
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div data-intrcm-id="AutosuggestChip_5ckbjrxd" className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  const { data } = props

  const style = { border: data.value === 'draft:draft' ? '1px dashed #7B4FF0' : 'none', backgroundColor: data.backgroundColor ? data.value === 'draft:draft' ? 'transparent' : data.backgroundColor : '' }
  const labelStyle = { color: data?.isLabel || data.value === 'draft:draft' ? '#1B1E2A' : '#ffffff', fontWeight: 500 }

  return (
    <Chip
      avatar={data.avatar ? <Avatar src={data.avatar} /> : null}
      label={<Typography style={data.backgroundColor ? labelStyle : null}>{props.children}</Typography >}

      style={data.backgroundColor ? style : null}
      tabIndex={-1}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function IndicatorSeparator(props) {
  return null
}
function DropdownIndicator(props) {
  return null
}

const components = (CustomOptionItem, CustomMultiValue) => ({
  Control,
  Menu,
  MultiValue: !!CustomMultiValue ? CustomMultiValue : MultiValue,
  NoOptionsMessage,
  Option: !!CustomOptionItem ? CustomOptionItem : Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator
});

class AutosuggestChip extends React.PureComponent {

  render() {
    const { withPreview = false, defaultSuggestions = [], CustomMultiValue, CustomOptionItem, classes, theme, selected, handleChange, isAsync, suggestions, label, convertValToLabel, placeholder } = this.props;


    let value;
    if (!selected) {
      value = []
    } else if (isAsync) {
      value = selected.map(s => ({ value: s, label: (convertValToLabel ? convertValToLabel(s) : s) }))
    } else {
      value = suggestions.filter(suggested => selected.some(s => s === suggested.value))
    }

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div data-intrcm-id="AutosuggestChip_yekoknxe" className={classes.root}>
        <NoSsr>
          {
            isAsync ? 
            <AsyncSelect
              value={ value}
              // cacheOptions 
              defaultOptions={withPreview ? defaultSuggestions : []}
              loadOptions={suggestions}
              onChange={handleChange}

              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              components={components(CustomOptionItem, CustomMultiValue)}

              placeholder={placeholder}
              isMulti
            /> :
              <Select
                value={value}
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                  label,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                options={suggestions}
                components={components(CustomOptionItem, CustomMultiValue)}
                onChange={handleChange}
                placeholder={placeholder}
                isMulti
              />
          }
        </NoSsr>
      </div>
    );
  }
}

AutosuggestChip.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.services
})


const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

export default compose(withStyles(styles, { withTheme: true }),
  withErrorCatcher("Failed Loading Autosuggest, Please Contact Tuqqi Support"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(AutosuggestChip);