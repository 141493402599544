import { Tooltip, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher.js';
import FooterKanbanCardItemContainer from './FooterKanbanCardItemContainer.js';
import getLastModifiedDay from "./daysToAlertOnUnusedItemUtil";

const styles = theme => ({
    tooltip: theme.newUI.tooltip.container,
    popper: theme.newUI.tooltip.popper,
})


const AlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="AlertIcon__a" d="M1.607 10.25h8.786c.898 0 1.458-.974 1.009-1.75L7.009.91C6.56.136 5.44.136 4.991.91L.598 8.5c-.449.776.111 1.75 1.01 1.75zM6 6.167c-.32 0-.583-.263-.583-.584V4.417c0-.321.262-.584.583-.584.32 0 .583.263.583.584v1.166c0 .321-.262.584-.583.584zM6.583 8.5H5.417V7.333h1.166V8.5z" />
                <path id="AlertIcon__d" d="M7.042 21L0 21 0 0.502 7.042 0.502 14.085 0.502 14.085 21 7.042 21z" />
                <filter id="AlertIcon__c" width="206.7%" height="169.6%" x="-53.3%" y="-34.8%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(-913 -1163) translate(909 1160)">
                    <rect width="22" height="20" fill="#F3F6FB" rx="4" />
                    <g transform="translate(5 5)">
                        <mask id="AlertIcon__b" fill="#fff">
                            <use href="#AlertIcon__a" />
                        </mask>
                        <g fill="#FF4848" mask="url(#AlertIcon__b)">
                            <path d="M0 0H14V14H0z" transform="translate(-1 -2)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
class DaysToAlertOnUnusedItem extends PureComponent {

    getLastModifiedDay() {
        const { daysToAlertOnUnusedItem, lastModifiedTime } = this.props

        let lastModifiedDay = 0
        if (daysToAlertOnUnusedItem) {
            const MSPassFromTheLastModifiedTime = Date.now() - new Date(lastModifiedTime)
            const numMSInDay = 1000 * 60 * 60 * 24

            const numDaysPass = MSPassFromTheLastModifiedTime / numMSInDay
            if (numDaysPass >= daysToAlertOnUnusedItem) {
                lastModifiedDay = parseInt(numDaysPass)
            }
        }

        return lastModifiedDay
    }


    render() {
        const { classes, t, daysToAlertOnUnusedItem, lastModifiedTime } = this.props

        const lastModifiedDay = getLastModifiedDay(daysToAlertOnUnusedItem, lastModifiedTime)
        if (lastModifiedDay === 0) {
            return null
        }

        return (
            <Tooltip
                placement='bottom'
                classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                title={<span dir='auto'>{t(`No action in the pass day${lastModifiedDay === 1 ? '' : 's'}`, { numDays: lastModifiedDay })}</span>}>
                <FooterKanbanCardItemContainer classes={{ container: classes.container }} >
                    <AlertIcon />
                </FooterKanbanCardItemContainer>
            </Tooltip>
        )

    }
}


const mapStateToProps = (state, ownProps) => ({
    daysToAlertOnUnusedItem: (state.results.channel &&
        state.results.channel.settings &&
        state.results.channel.settings.daysToAlertOnUnusedItem) || 0
})

export default compose(withStyles(styles),
    withTranslation('daysToAlertOnUnusedItemTranslate'),
    withErrorCatcher(),
    connect(mapStateToProps))(DaysToAlertOnUnusedItem);