import React, { Component } from 'react';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import AppContent from '../../components/App/appContent';
import PageContentWrapper from '../../components/App/PageComponents/pageContentWrapper';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { goTo } from "../../../actions/goToActions";
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "../../components/Common/spinner";
import UserAvatar from '../../components/Common/UserAvatar';

// Apply some reset
const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paper: {
        padding: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    listLabel: {
        marginBottom: 10
    },
    // avatar: {
    //     margin: 10,
    //     width: 35,
    //     height: 35,
    // },
    youAvatar: {
        margin: 10,
        width: 35,
        height: 35,
        backgroundColor: 'blue'
    },
    admin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 52,
        height: 22,
        borderRadius: 4,
        backgroundColor: '#4b32f2',
        border: 'solid 1px #4b32f2',
    },
    adminText: {
        display: 'inline-block',
        fontFamily: '"Roboto"',
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 0.5,
        textAlign: 'left',
        color: '#ffffff',
    },
});

class ScoreBoard extends Component {
    state = {
        tags: [],
        isLoading: true,
        usres: {}
    }

    componentDidMount() {
        this.loadData();

    }

    loadData = () => {
        const { scoreboardService } = this.props;

        scoreboardService.getAll().then(res => {
            this.setState({ users: res, isLoading: false, page: 0 })
        })
    }

    render() {
        const { classes, dispatch, userData, t } = this.props;
        const { users } = this.state;

        let content = null
        if (this.state.isLoading) {
            content = (
                <div data-intrcm-id="scoreboard_re3oy5zr" className={classes.spinner}>
                    <CircularProgress className={classes.progress} />
                </div>);
        }
        else if (this.state.users.length) {
            const delta = 20
            const usersToScroll = users.filter(user => user.isIncludeInChallenge && (user.firstname || user.lastname) && user.status === 0)
            content = <List className={classes.listStyle}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={(page) => { this.setState({ page: this.state.page + 1 }); }}
                    hasMore={(this.state.page * delta) < usersToScroll.length}
                    loader={<Spinner classes={classes} />}>
                    {
                        usersToScroll.sort((a, b) => b.points - a.points)
                            .slice(0, (this.state.page) * delta)
                            .map((user, idx) => {
                                const isCurrentUser = user.auth0_id === userData.profileData.auth0_id;
                                const fullName = `${user.firstname || ''} ${user.lastname || ''}`
                                return <ListItem
                                    button
                                    key={idx}
                                    onClick={goTo('@' + user.tag, dispatch)}
                                    className={classes.item}>
                                    <UserAvatar userUUID={user.auth0_id} withShadow={false} />
                                    <ListItemText
                                        primary={isCurrentUser ? <div data-intrcm-id="scoreboard_442c9lve" style={{ fontWeight: 'bold' }}>{t('You')}</div> : fullName}
                                        secondary={user.points + ' '+t('pts')} />
                                    <ListItemSecondaryAction style={{ paddingRight: 16 }}>
                                        #{idx + 1}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            })}
                </InfiniteScroll>
            </List>
        }

        return <div data-intrcm-id="scoreboard_t6588n5w">
            <AppContent >
                <PageContentWrapper>
                    <Grid spacing={16}>
                        <Typography variant="title" style={{ marginBottom: '15px' }}>
                            {t('Scoreboard')}
                            </Typography>
                        <Paper className={classes.paperStyle}>
                            {content}
                        </Paper>
                    </Grid>
                </PageContentWrapper>
            </AppContent>
        </div>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalUsers: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),    withTranslation('scoreboard'),
connect(mapStateToProps, mapDispatchToProps))(ScoreBoard);
