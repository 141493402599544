import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { VariableSizeList } from "react-window";
import { compose } from 'recompose';
import { getDueDate } from '../../../utils/taskDetailsUtils';
import KanbanCardTitle from './Card/KanbanCardTitle';
import KanbanLabels from './Card/KanbanLabels';
import CardWrapper from './CardWrapper';
import DragableCardContainer from './DragableCardContainer';
import classnames from "classnames";
import getLastModifiedDay from './Card/FooterKanbanCard/daysToAlertOnUnusedItemUtil';
import Spinner from '../Common/spinner';

import { KANBAN_CARD_MENU_HEIGHT } from './Card/KanbanCardMenu';
import { MEMBERS_ROW_HEIGHT, MEMBER_WIDTH } from "./Card/KanbanMembers";
import { DEFAULT_CARD_HEIGHT, KANBAN_CARD_COLLECTION_LABELS_CONTAINER_MARGIN_BOTTOM, KANBAN_CARD_WIDTH, AVAILABLE_CARD_WIDTH } from "./Card/KanbanCard";
import { KANBAN_CARD_PADDING_LEFT, KANBAN_CARD_PADDING_VERTICAL } from "./CardWrapper";
import { FooterKanbanCardHeight } from "./Card/FooterKanbanCard/FooterKanbanCard";
import theme from "../../../theme";
import { getPreviewImageFromAttachments } from '@tuqqi/common';

const scroolbarWidth = theme().newUI.scrollbar["&::-webkit-scrollbar"].width
const distanceBetweenScrollbarToCards = 8

const styles = theme => ({
    container: {

        // The height (window.screen.height) 
        // is important in order that the container 
        // will be the as biggest as it can be
        flex: `1 1 ${window.screen.height}px`,

        overflowY: 'hidden',
        overflowX: 'hidden',
        width: 'initial',

        '& > *': {
            marginTop: -8,
            marginBottom: -8,
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '&:hover': {
                ...theme.newUI.scrollbar
            },
        },
    },
    tempContainer: {
        position: 'absolute',
        visibility: 'hidden'
    },
    cardContainer: {
        flex: 1
    },
    spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        height: '100%'
    }
});


class Cards extends Component {
    state = {
        initiated: false,
        availHeight: undefined,
        itemNeedsUpdate: false,
        itemToUpdateId: '',
    }
    listRef = React.createRef()
    mountedRef = React.createRef()
    
    componentDidUpdate(prevProps, prevState) {        
  
        if (prevProps.list.cards !== this.props.list.cards) {
            
            if (this.props.oldUiData.specificItemUpdate) {
                const card = this.props.list.cards.find(c => c.id === this.props.oldUiData.itemId)
                if (!card) return
                this.setState({ itemNeedsUpdate: true, itemToUpdateId: this.props.oldUiData.itemId, prevHeight: card.height || 190 });
                card.height = card.height > 190 ? card.height : 190
                this.listRef.current && this.listRef.current.resetAfterIndex(0)
            }
            else {
                this.listRef.current && this.listRef.current.resetAfterIndex(0)
            }
        }

        if (!this.props.oldUiData.specificItemUpdate && this.state.itemNeedsUpdate) {           

            const card = this.props.list.cards.find(c => c.id === this.state.itemToUpdateId)
           
                if (card) {


                    card.height = undefined
                    const updatedCardHeight = this.getItemSize(this.props.list.cards.indexOf(card), true);              
                    card.height = updatedCardHeight  //> this.state.prevHeight ? updatedCardHeight : this.state.prevHeight;
                    
                    this.setState({ itemNeedsUpdate: false, itemToUpdateId: '', prevHeight: 0 });
                }
          
          
        }

        if (prevProps.applySort !== this.props.applySort) {
            this.setState({ initiated: false })
            this.resetList()
        }
    }
    getNode = () => {
        if (!this.mountedRef.current) return {
            firstChild: null,
            getBoundingClientRect: () => ({ height: 0 })
        }
        return ReactDOM.findDOMNode(this)
    }

    getTempContainer = () => {
        if (!this.tempContainer) {
            this.tempContainer = this.getNode().firstChild
        }

        return this.tempContainer
    }

    resetList = () => {

        setTimeout(() => {
            this.props.list.cards.forEach((card, index) => {
                if (card) {
                    card.height = this.getItemSize(index)
                }
            })
            const temp = this.getTempContainer()
            temp && ReactDOM.unmountComponentAtNode(temp)
            this.tempContainer = null
            this.setState({ initiated: true })
        })
    }
    componentDidMount() {
        this.mountedRef.current = true
        const { height } = this.getNode().getBoundingClientRect()
        this.setState({ availHeight: height })
        this.resetList()
    }

    componentWillUnmount() {
        this.mountedRef.current = false
    }


    labels = (doc) => {
        
        const {  collectionUid, userCollections, openCollections, orgLabels,orgUid } = this.props
      
        const {  collectionLabels } = doc;
        
        let filteredCollectionLabels = collectionLabels.filter(x => x.includes(collectionUid) || x.includes(orgUid))
        const filterOrgLabels = orgLabels.filter(label => filteredCollectionLabels.includes(`${orgUid}:${label.labelUid}`))
 
        let labels = []
        if (collectionUid) {
            // Take the label from the "state.userData.collections" not from the "state.results.channel" 
            //  because the channel doesn't hold the new labels (if exist)
            const cuuCollection = userCollections.concat(openCollections)
                .find(collection => collection.collectionUid === collectionUid)


                filteredCollectionLabels.forEach(docLabel => {
                const [docCollectionUid, docLabelUid] = docLabel.split(':')
                if (docCollectionUid === collectionUid) {
                    const collectionLabel = cuuCollection.collectionLabels.find(collectionLabel => collectionLabel.labelUid === docLabelUid)
                    collectionLabel && labels.push(collectionLabel)
                }
            })
        } else {

            if (filteredCollectionLabels) {
                labels = filteredCollectionLabels
            }
        }
    
        return  [...labels,...filterOrgLabels]
    }

    getLabelHeight = (doc, virtual) => {
        const { collectionUid,orgUid } = this.props

        const labelsTitleLength = this.labels(doc).reduce((total, label) => total + label.name? label.name.length:0,0)

        if (!doc.collectionLabels.length) {
            return 0
        }

        let collectionLabels = doc.collectionLabels.filter(x => x.includes(collectionUid)|| x.includes(orgUid))

        if (!collectionLabels.length) {
            return 0
        }
           
        if (labelsTitleLength <= 23 && virtual) {
            return 20
        }

        if (labelsTitleLength < 40 && virtual) {
            return 40
        }
        if (labelsTitleLength < 60 && virtual) {
            return 60
        }
        if (labelsTitleLength < 80 && virtual) {
            return 80
        }
    }

    getSocialDataHeight = (doc) => {

        const {
            commentsCounter,
            seenCounter,
            relatedDocumentsCounter,
            taskCounter,
            taskDoneCounter,
            lastModifiedTime
        } = doc;

        if (commentsCounter ||
            seenCounter ||
            taskCounter ||
            relatedDocumentsCounter ||
            getLastModifiedDay(this.props.daysToAlertOnUnusedItem, lastModifiedTime) ||
            getDueDate(doc)
        ) {
            return FooterKanbanCardHeight
        }
        return 0
    }
    getMembersHeight = (doc) => {
        const numMembersInRow = parseInt(AVAILABLE_CARD_WIDTH / MEMBER_WIDTH)

        const rows = Math.ceil(doc.members.length / numMembersInRow)

        return rows * MEMBERS_ROW_HEIGHT
    }

    getImageHeight = (doc) => {
        if (getPreviewImageFromAttachments(doc)) {
            return 200
        } else {
            return 0;
        }
    }

    getTitleHeight = (doc, labelHight, virtual) => {
        let menuHeight = 0
        if (!labelHight) {
            menuHeight = KANBAN_CARD_MENU_HEIGHT
        }
        if (doc.title.length <= 29) {
            return Math.max(42, menuHeight)

        } else {
            return Math.max(54, menuHeight)
        }
    }


    getItemSize = (index, isVirtual) => {
        const { list } = this.props
      
        const currCard = list.cards[index]
        if (!currCard) {
            return 200
        }
        else if (currCard.height) {
            return currCard.height
        }

        const virtual = isVirtual || this.state.initiated

        const { doc } = currCard
        const baseHeight = DEFAULT_CARD_HEIGHT
        const padding = KANBAN_CARD_PADDING_VERTICAL
        const labelsHeight = this.getLabelHeight(doc, virtual)
        const titleHeight = this.getTitleHeight(doc, labelsHeight, virtual)
        // const membersHeight = this.getMembersHeight(doc)
        // const socialDataHeight = this.getSocialDataHeight(doc)
        const imageHeight = this.getImageHeight(doc)
        return baseHeight + padding + labelsHeight + titleHeight + imageHeight
    }

    render() {
        const { list, classes, applySort, sort } = this.props;
        const { initiated, availHeight } = this.state;

        let cards = []
        if (list.cards.length) {

            cards = list.cards
            if (applySort) {
                cards.sort(sort)
            }


        }

        if (!initiated) {
            return <Grid
                container
                direction='column'
                className={classes.container} >
                <div data-intrcm-id="cards_9u36nimq" className={classes.tempContainer} />
                <Spinner classes={{ spinner: classes.spinner }} />
            </Grid>
        }
        return (
            <Grid container direction='column' className={classes.container}>
                <Droppable
                    droppableId={list.id + ''}
                    type="CARD"
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                        <CardWrapper
                            cards={cards}
                            index={rubric.source.index}
                            provided={provided}
                            snapshot={snapshot}
                        />
                    )}
                >

                    {(provided, snapshot) => {
                        const itemCount = snapshot.isUsingPlaceholder
                            ? cards.length + 1
                            : cards.length;
                        return (
                            <VariableSizeList
                                height={availHeight}
                                itemCount={itemCount}
                                itemSize={this.getItemSize}
                                width={KANBAN_CARD_WIDTH + KANBAN_CARD_PADDING_LEFT + scroolbarWidth + distanceBetweenScrollbarToCards}
                                outerRef={provided.innerRef}
                                itemData={cards}
                                ref={this.listRef}>
                                {DragableCardContainer}
                            </VariableSizeList>
                        );
                    }}

                </Droppable>
            </Grid>

        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    oldUiData: state.oldUiData,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections,
    collectionUid: state.results.channel.collectionUid,
    orgUid: state.data.userData.userDetails.data.organizationUid,
    documents: state.data.documents.data,
    orgLabels: state.globalData.orgLabels,
    daysToAlertOnUnusedItem: (state.results.channel &&
        state.results.channel.settings &&
        state.results.channel.settings.daysToAlertOnUnusedItem) || 0,

})

export default compose(withStyles(styles),
    connect(mapStateToProps, null))(Cards);