import React, { PureComponent } from 'react';

export default class SearchPostIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="4" y="5" width="16" height="14" rx="2" stroke={color} stroke-width="1.5"/>
            <rect x="7" y="8" width="10" height="1" fill={color}/>
            <rect x="7" y="10" width="6" height="1" fill={color}/>
            <rect x="7" y="12" width="8" height="1" fill={color}/>
            <rect x="7" y="14" width="4" height="1" fill={color}/>
            </svg>
        )
    }
}