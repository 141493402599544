import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputChangeTitle from "./inputChangeTitle";
import InputChangeDescription from "./inputChangeDescription";
import withErrorCatcher from "../../Common/withErrorCatcher";
import { compose } from "recompose";

const styles = (theme) => ({
  startedInfoHolder: {
    padding: "10px 20px 14px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "740px",
  },
  listTitle: {
    height: 24,
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#21212d",
    cursor: "pointer",
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    whiteSpace: 'pre-wrap',
  },
  listDescription: {
    height: 38,
    marginTop: 5,
    fontFamily: "RubikRoman, sans-serif",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#adadad",
    cursor: "pointer",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
});

const ListHeader = ({ title, description, editListTitle, editListDescription, list, classes }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const toEditTitle = () => {
    setIsEditingTitle(true);
  };

  const toEditDescription = () => {
    setIsEditingDescription(true);
  };

  const onBlurTitle = () => {
    setIsEditingTitle(false);
  };

  const onBlurDescription = () => {
    setIsEditingDescription(false);
  };

  return (
    <div data-intrcm-id="listHeader_qued4dlx" className={classes.startedInfoHolder}>
      {!isEditingTitle ? (
        <Typography className={classes.listTitle} onDoubleClick={toEditTitle}>
          {title}
        </Typography>
      ) : (
        <InputChangeTitle
          editListTitle={editListTitle}
          onBlur={onBlurTitle}
          list={list}
        />
      )}

      {!isEditingDescription ? (
        <Typography
          className={classes.listDescription}
          onDoubleClick={toEditDescription}
        >
          {description}
        </Typography>
      ) : (
        <InputChangeDescription
          editListDescription={editListDescription}
          onBlur={onBlurDescription}
          list={list}
        />
      )}
    </div>
  );
};

export default compose(withStyles(styles),  withErrorCatcher("Failed Loading, Please Contact Tuqqi Support"))(ListHeader);
