export function isDataTypeEnableInPermission(inputMappings, defaultInputTemplates, collections, dataType, permission) {
    let isEnable = false


    if (permission === 'public') {
        isEnable = canDataTypePublishToFeed(dataType, inputMappings, defaultInputTemplates)
    } else {
        isEnable = canDataTypePublishToCollection(dataType, permission, collections)
    }

    return isEnable
}

function canDataTypePublishToCollection(dataType, groupUid, collections) {
    let isEnable = false

    const currCollection = collections.find(c => c.collectionGroup.groupUid === groupUid)
    if (currCollection) {
        isEnable = currCollection.allInputsAllowed || currCollection.settings.allowedInputs.includes(dataType)
    }

    return isEnable
}

export function canDataTypePublishToFeed(dataType, inputMappings = [], defaultInputTemplates = []) {

    const defaultInputTemplate = defaultInputTemplates.find(template => template.dataType === dataType)
    const inputMappingTemplate = inputMappings.find(template => template.customDataMapping.dataTypeFilter === dataType)
    
    const inputTemplate = defaultInputTemplate || inputMappingTemplate

    return inputTemplate && inputTemplate.enablePublishingToPublicFeed
}