import { withStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/KeyboardArrowLeft';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const styles = (theme) => ({
    container: {
        height: 56,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        paddingLeft: 16,
        borderBottom: '#D7D7DB 1px solid'
    },
    title: {
        marginLeft: 16,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.2,
        color: '#0C0C0D'
    },
    back: {
        cursor: 'pointer',
        fontSize: 20,
        color: '#737373',
    },
})

function _OrgCategoryLaunchpadHeader(props) {
    const {classes, dispatch} = props
    const [t] = useTranslation("search")

    const onClickBack = useCallback(() => {
        dispatch({
            type: 'SET_LAUNCHPAD_PAGE_TYPE',
            pageType: 'landing',
            selectedCategory: 0
        })
    }, [])

    return (
        <div data-intrcm-id="OrgCategoryLaunchpadHeader_4zdejx5l" className={classes.container} >
            <ArrowBack onClick={onClickBack} className={classes.back} />
            <div data-intrcm-id="OrgCategoryLaunchpadHeader_ssmet6ay" className={classes.title}>{`${t("Back")}`}</div>
        </div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export const OrgCategoryLaunchpadHeader = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_OrgCategoryLaunchpadHeader)

