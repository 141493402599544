import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';


import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import LaunchpadIcon from "../../Common/Icons/launchpad/LaunchpadIcon";
import {getLaunchpadKeysLabel} from '../../../../utils/launchpadNavigation/launchpadUtils'
import { hexToRGB } from '../../../../utils/hexToRGB';
import withErrorCatcher from '../../Common/withErrorCatcher';
import SvgIcon from '@material-ui/core/SvgIcon';


  


const styles = theme => {
    const whiteOpacity016 = hexToRGB('#FFFFFF', 0.16)
return ({
    launchpadButton: { 
        margin: 0,
        padding:0,
        
        "&:hover": {
            backgroundColor: 'inherit',
          },
      
    },

    launchpadIconContainer: {
        height: 32,
        width:32,
        borderRadius: 16,
        "&:hover": {
            backgroundColor: whiteOpacity016,
          },
    },
    icon : {
        color:"rgba(255,255,255,0.87)",
        height:18,
        width:18
    }
})
}

const Icon = () => {
    return <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1999 10.175C14.1999 11.2934 13.2933 12.2 12.1749 12.2C11.0566 12.2 10.1499 11.2934 10.1499 10.175C10.1499 9.05661 11.0566 8.14999 12.1749 8.14999C13.2933 8.14999 14.1999 9.05661 14.1999 10.175ZM15.5499 10.175C15.5499 12.0389 14.0389 13.55 12.1749 13.55C10.311 13.55 8.79993 12.0389 8.79993 10.175C8.79993 8.31103 10.311 6.79999 12.1749 6.79999C14.0389 6.79999 15.5499 8.31103 15.5499 10.175ZM21.85 10.175C21.85 11.2934 20.9434 12.2 19.825 12.2C18.7066 12.2 17.8 11.2934 17.8 10.175C17.8 9.05661 18.7066 8.14999 19.825 8.14999C20.9434 8.14999 21.85 9.05661 21.85 10.175ZM23.2 10.175C23.2 12.0389 21.6889 13.55 19.825 13.55C17.961 13.55 16.45 12.0389 16.45 10.175C16.45 8.31103 17.961 6.79999 19.825 6.79999C21.6889 6.79999 23.2 8.31103 23.2 10.175ZM12.1749 19.85C13.2933 19.85 14.1999 18.9434 14.1999 17.825C14.1999 16.7067 13.2933 15.8 12.1749 15.8C11.0566 15.8 10.1499 16.7067 10.1499 17.825C10.1499 18.9434 11.0566 19.85 12.1749 19.85ZM12.1749 21.2C14.0389 21.2 15.5499 19.689 15.5499 17.825C15.5499 15.9611 14.0389 14.45 12.1749 14.45C10.311 14.45 8.79993 15.9611 8.79993 17.825C8.79993 19.689 10.311 21.2 12.1749 21.2ZM21.85 17.825C21.85 18.9434 20.9434 19.85 19.825 19.85C18.7066 19.85 17.8 18.9434 17.8 17.825C17.8 16.7067 18.7066 15.8 19.825 15.8C20.9434 15.8 21.85 16.7067 21.85 17.825ZM23.2 17.825C23.2 19.689 21.6889 21.2 19.825 21.2C17.961 21.2 16.45 19.689 16.45 17.825C16.45 15.9611 17.961 14.45 19.825 14.45C21.6889 14.45 23.2 15.9611 23.2 17.825Z" fill="white" fill-opacity="0.56"/>
    </svg>    
}

class LaunchpadToolbar extends PureComponent {
   

    onClick = (e) => {
        const { dispatch } = this.props;
        dispatch({type: 'TOGGLE_LAUNCHPAD'})
    }

    onKeyUp = (e) => {
        if(e.which === 13){ 
            e.preventDefault();
            return false
        }
    }

    render() {
        const { classes, t, isFocused } = this.props;
        const keyLabel = getLaunchpadKeysLabel()
        return (
            <Tooltip title={`${t('Launchpad')} ${!!keyLabel ? '(' + keyLabel + ')' : ""}`} placement="bottom">
                <IconButton data-intrcm-id="LaunchpadToolbar_0ee11k8h" onKeyPress={this.onKeyUp} classes={{ label: classes.launchpadIconContainer, root: classes.launchpadButton }} aria-label="Launchpad" onClick={this.onClick}>
                    <Icon />
                </IconButton>
            </Tooltip>

        )
    }
}

const mapStateToProps = (state) => ({
    isFocused: state.launchpadNavigation.isFocused,
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher('Launchpad crashed'),
    withStyles(styles),
    withTranslation('feedToolbar'),
    connect(mapStateToProps, mapDispatchToProps)
)(LaunchpadToolbar);