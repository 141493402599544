import {getOS} from '../OSutils'

const isCommonSearchListener = (e) => {
    if(!!e.ctrlKey && e.keyCode === 75) return true
    return false
}
const isCommonLaunchpadListener = (e) => {
    if(!!e.ctrlKey && e.keyCode === 66) return true
    return false
}
  
const isMacSearchListener = (e) => {
    if((!!e.metaKey && e.keyCode === 75)) return true
    return false
}
const isMacLaunchpadListener = (e) => {
    if((!!e.metaKey && e.keyCode === 66)) return true
    return false
}
  
export const isShouldLaunchpadOpen = (e) => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return isMacLaunchpadListener(e)
        case 'linux':
        case 'windows':
            return isCommonLaunchpadListener(e)
        default:
            return false
    }
}
export const isShouldSearchOpen = (e) => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return isMacSearchListener(e)
        case 'linux':
        case 'windows':
            return isCommonSearchListener(e)
        default:
            return false
    }
}
export const getLaunchpadKeysLabel = () => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return 'CMD + B'
        case 'linux':
        case 'windows':
            return "CTRL + B"
        default:
            return ""
    }
}

export const launchpadOptionsMode = {
    DEFAULT: 'default',
    GROUP: 'group',
    EXTERNAL_LINK: 'externallink',
    EXTERNAL_FORM: 'externalform',
}

export const applyOptions = (allOptions = [], allowedOptions = [], allOptionsAllowed) => {
    if(!!allOptionsAllowed) return allOptions

    const filteredOptions = allowedOptions
        .map(o => allOptions.find(op => op.key === o.key))
        .filter(o => !!o)
        .sort((a, b) => a.order - b.order)
    return filteredOptions
}

export const resetPrevSuggestStyle = (suggestIndex, container = document.getElementById('launchpad-navigation-dialog')) => {
    if (!container) return;

    if(suggestIndex === -1) return;

    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    if (!suggestionsList.length) return;
    
    const prevSelector = suggestionsList[suggestIndex];
  
    if(!prevSelector) return;
    prevSelector.style.backgroundColor = 'transparent';
    prevSelector.style.display = 'none';
}

export const resetAllSuggestStyle = (container = document.getElementById('launchpad-navigation-dialog')) => {
    if (!container) return;

    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    if (!suggestionsList.length) return;
    
    suggestionsList.forEach(sel => {
      if(!sel) return;
      sel.style.backgroundColor = 'transparent';
      sel.style.display = 'none';
    })
  }