import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import parseImgeUrl from "../../../Common/parseImagUrl";
import groupPlaceholder from "../../../../../assets/img/group_placeholder.png";
const width = 296

const styles = (theme) => ({
  container: {
    objectFit: "cover",
  },
});

const ImgGroup = (props) => {
  const { styles, classes, currentScene, cloudfront } = props;
  const [image, setImage] = useState("");
  const { collection } = currentScene;

  useEffect(() => {
    collection.backgroundUrl && setImage(collection.backgroundUrl);
  }, [collection]);

  return (
    <img
      src={!image.length ? groupPlaceholder : parseImgeUrl(cloudfront, image, width)}
      style={styles.container}
      className={`${classes.container}`}
    />
  );
};

const mapStateToProps = (state) => ({
  cloudfront: state.globalData.cloudfront,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ImgGroup);
