import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles, Collapse, Grid, Chip, Divider, Typography, Tooltip, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Pie } from 'react-chartjs-2';
import goTo from "../../../actions/goTo";
import { PREVIEW_SHOW_ANALYZE, PREVIEW_CLOSE_ANALYZE } from "../../../../actionTypes/actionTypes";
import { withTranslation } from 'react-i18next'

const styles = theme => ({

    chip: {
        margin: 10,
        cursor: 'pointer'
    }
});

class Analytics extends Component {
    state = {
        users: []
    }

    enter = () => {
        const users = this.props.users.filter(u => u.isIncludeInChallenge)
        this.setState({ users })
    }
    closeAnalistics = () => {
        this.props.dispatch({ type: PREVIEW_CLOSE_ANALYZE })
    }
    render() {
        const { classes, expanded, result, dispatch, t, docId, previewItem } = this.props
        const { seenCounter, seen, tags, commentsCounter } = result


        const data = {
            labels: [
                t('Seen'),
                t('Unseen'),
            ],
            datasets: [{
                data: [seen.length, this.state.users.length - seen.length],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ]
            }]
        };


        return (


            <Collapse in={previewItem.showAnalyze && previewItem.docId == docId} transitionDuration="auto" unmountOnExit onEnter={this.enter}  >
                <Grid container wrap='nowrap' className={classes.header} >

                    <Grid item xs={7} md={9} wrap='nowrap' className={[classes.spaceLeft, classes.padding].join(' ')}>
                        <Tooltip id="tooltip-bottom-end" title="Hide Analytics" placement="bottom" >
                            <IconButton disableRipple={true} disableFocusRipple={true} size="medium" className={classes.button} style={{ paddingLeft: 0 }}
                                aria-label="Comments" onClick={this.closeAnalistics}>
                                <ExpandLess className={[classes.subTitle, classes.icon].join(' ')} />
                                &nbsp;
    <Typography variant="caption" className={classes.subTitle}>
                                    {t('Hide Analytics')}
                                </Typography>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <div data-intrcm-id="analytics_7bbsx7a7" style={{ overflowY: 'auto' }}>

                    <Pie data={data} />
                    <div data-intrcm-id="analytics_l814v6ro" style={{ margin: 16 }}>
                        <Divider />
                        <h3>{t('Tags')}</h3>
                        {
                            result.tags.slice(0, 10).map(t => <Chip onClick={goTo('#' + t, dispatch)} label={t} className={classes.chip} />)
                        }
                        <Divider />
                        <h3>{t('Scores')}</h3>
                        <p>{t('Commnts')}: {commentsCounter * 3} {t('views')}: {seenCounter + (seen.length * 3)} {t('Tags')}: {result.tags.length} {t('Create')}: 15</p>
                    </div>
                </div>
            </Collapse>)
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
    previewItem: state.previewItem,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('analytics'),
    connect(mapStateToProps, mapDispatchToProps))(Analytics);