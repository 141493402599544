export default class ChatService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getDocumentsUserInComments() {
        return fetch(process.env.API_SERVER + '/comments/user', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getDirectMessages() {
        return fetch(process.env.API_SERVER + '/chat/getAllChats', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    updateLastUserEnterDiscussionChat(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (docId) {
            return fetch(process.env.API_SERVER + '/chat/updateLastSeenDiscussion', {
                method: 'POST',
                body: JSON.stringify(docId),
                headers: myHeaders
            }).then(function (res) {

            });
        }
    }

    getComments(docId, page) {
        if (docId !== '') {
            return fetch(process.env.API_SERVER + '/comments/chatComments?docId=' + docId + '&page=' + page, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    openNewChat(userId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (userId) {
            return fetch(process.env.API_SERVER + '/chat/newChat', {
                method: 'POST',
                body: JSON.stringify(userId),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }
    openNewGroupChat(collectionUid) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (collectionUid) {
            return fetch(process.env.API_SERVER + '/chat/newGroupChat', {
                method: 'POST',
                body: JSON.stringify(collectionUid),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    getMessages(chatUId, page) {
        return fetch(process.env.API_SERVER + '/chat/getChatMessages?chatUid=' + chatUId + '&page=' + page, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    messageUser(message) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (message) {
            return fetch(process.env.API_SERVER + '/chat/addmessage', {
                method: 'POST',
                body: JSON.stringify(message),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    updateLastUserEnterDMChat(chatUId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (chatUId) {
            return fetch(process.env.API_SERVER + '/chat/updateLastSeen', {
                method: 'POST',
                body: JSON.stringify(chatUId),
                headers: myHeaders
            }).then(function (res) {

            });
        }
    }

    getDiscussionsData(docId) {
        return fetch(process.env.API_SERVER + '/chat/getDiscussionsData?docId=' + docId, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    searchInComments(query = '') {
        return fetch(process.env.API_SERVER + '/chat/searchComments?query=' + query?.trim?.() ?? '', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getDirectMessagesData(chatId) {
        return fetch(process.env.API_SERVER + '/chat/getDirectMessagesData?chatId=' + chatId, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
}