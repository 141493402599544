import React, {useCallback, useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import LaunchpadOption from './LaunchpadOption';
import LaunchpadOptionAdder from './LaunchpadOptionAdder';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog, NewUiWrapper } from '@tuqqi/web';
import {store} from '../../../../../index'
import DeleteLaunchpadOptionIcon from '../../../Common/Icons/launchpad/DeleteLaunchpadOptionIcon';
import EditLaunchpadOptionIcon from '../../../Common/Icons/launchpad/EditLaunchpadOptionIcon';

const styles = (theme) => ({
    container: {
        marginTop: 26,
        position: 'relative',
        border: 'solid 1px #D7D7DB',
        boxSizing: 'border-box',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        color: '#4A4A4F'
    },
    titleWrap: {
        position: 'absolute',
        top: -8,
        backgroundColor: '#fff',
        paddingRight: 12,
        paddingLeft: 12,
        left: 12
    },
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: 6,
        top: 0,
    },
    editIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 30,
        top: 0,
    },
    icons: {
        position: 'absolute',
        top: -10,
        right: 12,
        width: 56,
        height: 20,
        backgroundColor: '#fff'
    },
    optionsWrap: {
        width: '100%',
        marginLeft: 4,
        marginRight: 4,
    },
    options: {
        minHeight: 80,
        padding: 24,
        paddingLeft: 1,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 'auto',
        display: 'grid',
        'grid-row-gap': '24px',
        'grid-template-columns': '92px 92px 92px 92px',
    }
})

function _LaunchpadCategoryItems(props) {
    const {
        editable,
        options, 
        onClick, 
    } = props

    return <>
        {options.map((opt) => (
            <LaunchpadOption editable={editable} onClick={onClick} key={opt.id} option={opt} />
        ))}
    </>
}

function _LaunchpadCategory(props) {
    const {
        adminService,
        editable,
        classes, 
        allowedOptions,
        item, 
        onClick,
        dispatch, 
    } = props
    const {customTitle} = item
    const [items, setItems] = useState([])
    const [t] = useTranslation("search")

    useEffect(() => {
        const subs = allowedOptions.filter((it) => it.type !== 0 && it.categoryId === item.id)
        setItems(subs)
    }, [item.id, allowedOptions])

    const onRemoveCategory = useCallback(() => {
        adminService.deleteLaunchpadMenuItem(item.id).then(res => {
            if(!res) return
            dispatch({
                type: 'DELETE_LAUNCHPAD_OPTION',
                id: item.id
            })
        })   
    }, [item.id])

    const onEdit = useCallback(() => {
        dispatch({type: 'IS_EDIT_NAME_CATEGORY', editCategory: item.id})
    }, [item])

    return ( 
        <div data-intrcm-id="LaunchpadCategory_6fsww4j6" className={classes.container} >
            {editable && <div data-intrcm-id="LaunchpadCategory_gvxeapn9" className={classes.icons}>
                <EditLaunchpadOptionIcon onClick={onEdit} className={classes.editIcon} />
                <NewUiWrapper store={store} callbacks={{}}><ConfirmDialog title={t('Delete category')}
                    message={t("Are you sure you want to delete this category? this action can’t be undone")}
                    actionButtonText={t("Delete")}
                    onConfirmed={onRemoveCategory}>
                        <DeleteLaunchpadOptionIcon className={classes.close} />
                </ConfirmDialog></NewUiWrapper>
            </div>}
            <div data-intrcm-id="LaunchpadCategory_fuqdhgxm" className={classes.titleWrap}>
                <div data-intrcm-id="LaunchpadCategory_s7d9xyuh" className={classes.title}>{customTitle}</div>
            </div>
            <div data-intrcm-id="LaunchpadCategory_ahuni7ex" className={classes.optionsWrap} >
                <div data-intrcm-id="LaunchpadCategory_1swqsi4u" className={classes.options} >
                    <_LaunchpadCategoryItems onClick={onClick} editable={editable} options={items} />
                    {!!editable && <LaunchpadOptionAdder categoryId={item.id} />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    allowedOptions: state.launchpadNavigation.allowedOptions,
    adminService: state.services.adminService,
})
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export const LaunchpadCategory = compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(_LaunchpadCategory)
