import i18n from '../../i18n';
import {store} from '../../index';
import { capitalize } from '../commonHelpers';

const GetData = (t, subscriptionType) => { 
    const reasonData = {
        groups: {
            title: t('You reached the maximum amount of groups'),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 5 groups If you wish to create a new group upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 5 groups. If you wish to create a new group, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features')
            ]
        },
        customInput: {
            title: t('You reached the maximum amount of custom inputs'),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 2 custom inputs If you wish to create a new custom input upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 2 custom inputs. If you wish to create a new custom input, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited storage')
            ]
        },
        incomingRules: {
            title: t('You reached the maximum amount of automatic rules'),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 2 automatic rules If you wish to create a new rule upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 2 automatic rules. If you wish to create a new rule, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited automatic rules'),
            ]
        },
        users: {
            title: t(`You reached the maximum amount of users in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 5 users If you wish to add more users upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can have up to 5 users. If you wish to add more users, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Users on demand')
            ]
        },
        library: {
            title: t(`The Library cant be used in the ${subscriptionWord(subscriptionType)} plan`, `The Library can't be used in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan cant use Library page If you wish to get usage upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can't use Library page. If you wish to get usage, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
            ]
        },
        timeTracking: {
            title: t(`The Time tracking cant be used in the ${subscriptionWord(subscriptionType)} plan`, `The Time tracking can't be used in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan cant use time tracking If you wish to get usage upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can't use time tracking. If you wish to get usage, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
            ]
        },
        contentManagement: {
            title: t(`The Content management cant be used in the ${subscriptionWord(subscriptionType)} plan`, `The Content management can't be used in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan cant use content management If you wish to get usage upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can't use content management. If you wish to get usage, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
            ]
        },
        classifiedGroup: {
            title: t(`The group cant be classified in the ${subscriptionWord(subscriptionType)} plan`, `The group can't be classified in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan cant use clasified groups If you wish to get usage upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can't use clasified groups. If you wish to get usage, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
            ]
        },
        brandingColors: {
            title: t(`The Branding colors cant be used in the ${subscriptionWord(subscriptionType)} plan`, `The Branding colors can't be used in the ${subscriptionWord(subscriptionType)} plan`),
            description: t(`Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan cant use branding colors If you wish to get usage upgrade your plan and get much more`, `Organizations in the ${capitalize(subscriptionWord(subscriptionType))} plan can't use branding colors. If you wish to get usage, upgrade your plan and get much more.`),
            features: [
                t('Unlimited custom inputs'), 
                t('Branding colors'), 
                t('Unlimited storage'), 
                t('Unlimited features'),
            ]
        },
        businessClass: {
            title: t("Its time to get Business class plan", "It's time to get Business class plan"),
            description: '',
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
            ]
        },
        default: {
            title: t('You reached the maximum in the current plan'),
            description: '',
            features: [
                t('Unlimited custom inputs'), 
                t('CRM features'), 
                t('Unlimited groups'), 
                t('Unlimited features'),
                t('Unlimited storage'),
                t('Users on demand')
            ]
        }
    }
    return reasonData
}
export const getReasonData = (reason) => {
    const t = i18n.getFixedT(i18n.language, 'upgradeSubscription')
    const subscriptionType = getSubscriptionType()
    const rd = GetData(t, subscriptionType)
    return rd[reason] || rd.default
}

export const getSubscriptionType = () => {
    const state = store?.getState?.()
    if(!state?.globalData?.currentSubscription) return 0
    const {globalData: {currentSubscription = {}}} = state
    return currentSubscription.subscriptionType ?? 0
}

const subscriptionWord = (subscriptionType) => {
    switch (subscriptionType) {
        case 0:
            return 'free'
        case 1:
            return 'economy'
        case 2:
            return 'business'
        default:
            return 'free';
    }
}