import React, { Component } from 'react'
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles, Icon, Avatar, Grid, Typography } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux';
import UserAvatar from '../../../Common/UserAvatar';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    header: {
        margin: 0
    }
})

class AuthorInEditMode extends Component {

    getAuthors = (item, userName) => {
        const { members } = item

        if (!members || !members.length) {
            return userName;
        }
        else {
            const membersNumToDesplay = 3

            let users = members.slice(0, membersNumToDesplay).map(member => member.userName).join(", ")

            if (members.length > membersNumToDesplay) {
                let more = members.length - membersNumToDesplay

                users += " & " + more + (more > 1 ? " others" : " other")
            }

            return users
        }
    }

    render() {
        const { item, classes } = this.props
        const { firstname, lastname, tag, profileImageUrl, title, level } = this.props.userData.profileData;
        const userName = `${firstname} ${lastname}`

        return (<Grid container spacing={16} className={classes.header}>
            <Grid item>
                <UserAvatar userUUID={this.props.userData.profileData.auth0_id} withShadow={true} />
            </Grid>
            <Grid item >
                <Typography variant="body2">{this.getAuthors(item, userName)}</Typography>
                <Typography variant="caption">{title}</Typography>
            </Grid>
            <Grid md={3}></Grid>
            <Grid item alignItems={'flex-end'}></Grid>
        </Grid>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('itemFullViewModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AuthorInEditMode);