import { Tooltip, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import FooterKanbanCardItemContainer from '../FooterKanbanCardItemContainer';

const styles = theme => ({
    socialDatatext: {
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.34",
        "letterSpacing": "0.4px",
        "color": theme.newUI.color.gray,
        marginRight: 2
    },
    tooltip: theme.newUI.tooltip.container,
    popper: theme.newUI.tooltip.popper,
})


class SocialDataContainer extends PureComponent {
    getplural = () => {
        const { total, t, tooltipText } = this.props

        const suffix = total === 1 ? '' : 's'
        const res = `${t(tooltipText + suffix)}`

        return res
    }

    render() {

        const { classes, text, icon, total } = this.props

        return (
            <Tooltip
                placement='bottom'
                classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                title={<span dir='auto'>{`${total} ${this.getplural()}`}</span>}
            >
                <FooterKanbanCardItemContainer>
                    <span className={classes.socialDatatext}>{text}</span>
                    {icon}
                </FooterKanbanCardItemContainer>
            </Tooltip>

        )

    }
}



export default compose(withStyles(styles), withTranslation('socialData'))(SocialDataContainer);