import React, { PureComponent } from "react";
import withErrorCatcher from '../withErrorCatcher.js'
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next'

import '../../Common/FilesUploader/FilesUploaderStyles.css';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import './FilePondOverride.scss'
import { Icon, withStyles } from "@material-ui/core";
import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = theme => ({
    hidden: {
        visibility: 'hidden'
    }
})

class PickFileComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            isLoading: false
        }
        this.filePondRef = React.createRef()
    }

    static defaultProps = {
        iconName: 'attachment',
        allowMultiple: true
    }

    getIcon() {
        const { classes, iconName } = this.props;

        return (
            <Icon className={classes.icon} onClick={this.openFilePicker}>
                {iconName}
            </Icon>)
    }

    openFilePicker = () => {
        if (this.filePondRef.current &&
            this.filePondRef.current.browse) {
            this.filePondRef.current.browse()
        }
    }

    getLabelIdle() {
        const {
            iconName,
            iconStyle,
            image,
            imagePickerStyle,
        } = this.props;

        const labelIdle = image
            ? `<img src="${image}" style="${imagePickerStyle}" />`
            : `<i class="material-icons filepond-icon-button" style="${iconStyle}">${iconName}</i>`

        return labelIdle
    }

    render() {
        const {
            userIdToken,
            onLoad,
            className,
            image,
            acceptedFileTypes,
            allowMultiple,
            onUpdateFiles,
            renderFilePond,
            classes,
        } = this.props;


        return (
            <div data-intrcm-id="PickFileComponent_q7p71aaj" className={className}>
                {!renderFilePond && this.getIcon()}
                <div data-intrcm-id="PickFileComponent_fq3ps1b2" className={!renderFilePond && classes.hidden}>
                    <FilePond
                        ref={this.filePondRef}
                        acceptedFileTypes={acceptedFileTypes}
                        files={this.state.files}
                        allowMultiple={allowMultiple}
                        labelIdle={this.getLabelIdle()}
                        dropOnElement={true}
                        server={{
                            url: process.env.API_SERVER + '/files/s3',
                            revert: null,
                            restore: null,
                            load: null,
                            fetch: null,
                            process: {
                                headers: { 'Authorization': 'Bearer ' + userIdToken },
                                onload: onLoad
                            }
                        }}
                        onupdatefiles={onUpdateFiles}
                    />
                </div>
            </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userIdToken: state.userData.idToken
    }
}

export default compose(withStyles(styles), withErrorCatcher(), connect(mapStateToProps))(PickFileComponent)
