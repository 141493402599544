import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import CustomInputTemplate from './DataTypeTemplates/CustomInput/CustomInputTemplate';
import PollTemplate from './DataTypeTemplates/Poll/PollTemplate.js';
import PostTemplate from './DataTypeTemplates/PostTemplate';

// Apply some reset
const styles = theme => ({

});

class ItemContentPreview extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        const { result, openItem, dataTypeName, openLink, openFile } = this.props;
        const { dataType, mimeType } = result;


        var properties = {
            docId: this.props.docId,
            result,
            relatedItems: this.props.relatedItems,
            openLink: openLink,
            openItem: openItem,
            openFile: openFile,
            dataTypeName
        }
        let item = <PostTemplate  {...properties} />;

        // if (mapping) {
        //     item = <CustomInputTemplate {...properties}  />
        // }

        switch (dataType) {

            case 'poll':
                item = <PollTemplate {...properties} />
                break;
            default: item = <CustomInputTemplate  {...properties} />
                break;
        }

        return <Grid container>
            {item}
        </Grid>
    }

}

export default withErrorCatcher()(ItemContentPreview)