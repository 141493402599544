import React, { PureComponent } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { withStyles, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next'


const styles = theme => ({
    text: {
        fontSize: 'initial',
        display: 'flex',
        marginLeft: 8,
        alignItems: 'center',
        userSelect: 'none',

        "fontFamily": "Rubik", "fontSize": "16px", "fontWeight": "normal", "fontStretch": "normal", "fontStyle": "normal", "lineHeight": "1.75", "letterSpacing": "1px", "color": "#979aaa"
    }
});


class PlaceHolder extends PureComponent {


    render() {
        const { classes, t } = this.props
        return (
            <Typography className={[classes.text, 'start-writing-here-placeholder'].join(' ')}>
                {t('Start writing here')}
            </Typography>
        )
    }
}

export default compose(withStyles(styles), withTranslation('postInput'))(PlaceHolder);

