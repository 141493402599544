import { Avatar, Grid, Icon, IconButton, Tooltip, Typography, withStyles, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore, TrendingUp } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Analytics from "./analytics";
import classNames from "classnames";
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => {
    return {
        text: {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.25,
            letterSpacing: 0.3,
            textTransform: 'none',
            boxShadow: 'none',
            backgroundColor: 'white',

            padding: 14,
        },
        button: {
            color: '#757575',
        },
        disableButton: {
            color: `${theme.customColors.primary.main} !important`,
        }
    }
};

class ItemPreviewCommentBar extends Component {
    state = {
        isDisabled: false
    }

    confirmReading = () => {
        const { inputService, docId } = this.props;

        this.setState({ isDisabled: true })

        inputService.approvedReading(docId).then(res => {
            this.setState({ confirmed: true })
        })
    }

    logClick = () => {
        this.setState({ isDisabled: true })
        this.props.logClick();
    }

    componentWillMount() {
        const { userData, result, users } = this.props;
        const userId = userData.profileData.auth0_id;

        let seenUsers = users.filter((user) => {
            const fullName = `${user.firstname} ${user.lastname}`

            return result.seen.includes(fullName)
        })

        let isDisabled = false;
        let confirmed = false;
        let isMustRead = false;

        if (result.mustReadUsers && result.mustReadUsers.includes(userId)) {
            if (result.mustReadUsersApproved && result.mustReadUsersApproved.includes(userId)) {
                confirmed = true;
                isDisabled = true;
            }

            isMustRead = true;
        }
        else if (seenUsers.find(seenUser => seenUser.auth0_id == userId)) {
            isDisabled = true;
        }

        this.setState({ isMustRead, confirmed, isDisabled })
    }

    renderButton = (title, icon, onClick, isDisabled) => {
        const { classes } = this.props;

        return isDisabled
            ? <Button variant="text" classes={{ root: [classes.disableButton, classes.text].join(" ") }} fullWidth disableRipple disableFocusRipple disabled disableTouchRipple >
                <Icon style={{ paddingRight: 4, fontSize: 20 }}>{icon}</Icon>
                {title}
            </Button>
            : <Button variant="text" className={[classes.button, classes.text].join(" ")} fullWidth disableRipple onClick={onClick}>
                <Icon style={{ paddingRight: 4, fontSize: 20 }}>{icon}</Icon>
                {title}
            </Button>
    }

    render() {
        const { classes, commentClickedHandle, logClick, t } = this.props;
        const { isMustRead, confirmed, isDisabled } = this.state;

        return <Fragment>
            <Grid container wrap='nowrap' className={classNames(classes.header, classes.subTitle)} justify='space-between' alignItems='center'>
                <Grid item wrap='nowrap' item xs={6} wrap='nowrap' alignItems='center'>
                    {this.renderButton(t("Comment"), "comment", commentClickedHandle)}
                </Grid>
                <Grid item xs={6} alignItems='center'>
                    {isMustRead
                        ? (confirmed
                            ? this.renderButton(t("Marked as read"), "done", null, true)
                            : this.renderButton(t("Must read"), "error_outline", this.confirmReading)
                        )
                        : this.renderButton(t("View"), "remove_red_eye", this.logClick, isDisabled)
                    }
                </Grid>
            </Grid>
        </Fragment>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    previewItem: state.previewItem,
    ...state.services,
    users: state.globalData.users,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
withTranslation('itemPreviewCommentBarTranslate'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemPreviewCommentBar);