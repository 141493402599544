import React, { useEffect, useState, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import { Gantt, ViewMode } from 'gantt-task-react';
import "./index.css";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateDocumentSchedule } from '@tuqqi/common'
import ViewSwitcher from "./components/ViewSwitcher";
import GanttTable from "./components/Table";
import TableHeader from "./components/TableHeader";
import TaskTooltip from './components/TaskTooltip';
import { getStartEndDateForProject, sortByGanttOrder } from './helpers/helpers'
import { openFullview } from '@tuqqi/web';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { withTranslation } from 'react-i18next';
import { addRecentItem } from '../../../../actions/searchNavigationActions';

const styles = theme => ({
  ganttContainer: {
    backgroundColor: 'white',

  },
  ganttCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRight: `solid 1px #DADDEE`,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
  },
  tooltip: {
    fontStyle: 'normal',
    fontfamily: 'Rubik',
    fontWeight: 400,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    fontSize: 14,
  },
});


const GanttChart = (({ classes, collectionInfo, dispatch, tasksFromKanban, profileData }) => {

  const [ganttIsReady, setGanttIsReady] = useState(false)
  const [isChecked, setIsChecked] = useState(true);
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([])
  const kanbanCollection = (collectionInfo.collectionViews || []).find(c => c.collectionViewType === "Kanban");

  let columnWidth = 64;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    let projectInfo = []
    const { itemLists } = kanbanCollection;

    const projectsForGantt = itemLists.map((project) => {
      projectInfo.push({
        taskIds: project.items.map(t => t.documentId),
        projectId: project.id
      })
      return {
        // required gantt lib fields
        id: String(project.id),
        name: project.title,
        start: new Date(),
        end: new Date(),
        progress: 25,
        type: "project",
        //
        hideChildren: false,
        styles: { progressColor: '#23C244', progressSelectedColor: '#23C244', backgroundSelectedColor: '#FEE3B2', backgroundColor: '#FEE3B2', fontSize: 12 },
      }
    })

    const tasksForGantt = tasksFromKanban.map((task, idx) => {
      let start = new Date()
      let end = new Date()
      end.setHours(end.getHours() + 24);

      if (task.dateTimeFrom && (new Date(task.dateTimeFrom)).getTime() > (new Date('01/01/2020')).getTime()) {
        start = new Date(task.dateTimeFrom);
      }

      if (task.dateTimeTo && (new Date(task.dateTimeTo)).getTime() > (new Date('01/01/2020')).getTime()) {
        end = new Date(task.dateTimeTo);
      }

      let currentProgress = 0
      if (task.taskCounter) {
        currentProgress = task.taskDoneCounter * 100 / task.taskCounter
      }
      const project = projectInfo.find(pro => pro.taskIds.includes(task.docId))

      return {
        // required gantt lib fields
        id: task.docId,
        name: task.title,
        start: start,
        end: end,
        progress: currentProgress,
        type: 'task',
        //
        project: String(project?.projectId),
        // dependencies: task.dependencies,
        styles: { progressColor: '#BC98FB', backgroundColor: '#DADDEE', fontSize: 12 },
        //  customs field
        assignedResource: task.assignedResource,
        taskCounter: task.taskCounter,
        taskDoneCounter: task.taskDoneCounter,
        taskDueDateDone: task.taskDueDateDone,
      }

    })

    const sortedTasksAndProjects = sortByGanttOrder(projectsForGantt, tasksForGantt)

    setTasks(sortedTasksAndProjects)
    setGanttIsReady(true)
  }, [kanbanCollection, tasksFromKanban])

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    dispatch(updateDocumentSchedule({ API_URL: process.env.API_SERVER })(task.id, { dateTimeTo: task.end, dateTimeFrom: task.start, taskDueDateDone: false, dueDateRecurring: 0 }))
    setTasks(newTasks);
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };


  return (
    <div data-intrcm-id="Gantt_nxccqaxb" className={classes.ganttContainer}>
      <ViewSwitcher
        onViewModeChange={setView}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {ganttIsReady && (
        <div data-intrcm-id="Gantt_nu0is222" className={classes.ganttCont}>
          <Gantt
            fontSize={12}
            columnWidth={columnWidth}
            headerHeight={32}
            fontFamily='Rubik'
            barCornerRadius={4}
            rowHeight={40}
            barFill={85}
            barProgressColor='#F0F3FF'
            barBackgroundColor='#F0F3FF'
            barProgressSelectedColor='#BC98FB'
            barBackgroundSelectedColor='#DADDEE'
            tasks={tasks}
            viewMode={view}
            handleWidth={2}
            locale={profileData.lang}
            listCellWidth={isChecked ? "155px" : ""}
            onDateChange={handleTaskChange}
            onExpanderClick={handleExpanderClick}
            onDoubleClick={(task) => { dispatch(openFullview(task.id, false)); dispatch(addRecentItem(docId)) }}
            TaskListHeader={(e) => {
              return <TableHeader e={e} />
            }}
            TooltipContent={(tooltipInfo) => {
              return <TaskTooltip {...tooltipInfo} />
            }}
            TaskListTable={(tableInfo) => {
              return <GanttTable tableInfo={tableInfo} {...tableInfo} />
            }}
          />
        </div>
      )}
    </div>
  )
})

const mapStateToProps = (state) => ({
  myTodoTasks: state.myTodo.todoTasks,
  documents: state.data.documents.data,
  collectionInfo: state.results.channel,
  searchQuery: state.search,
  collaborationService: state.services.collaborationService,
  fullview: state.fullview,
  profileData: state.userData.profileData,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
  withTranslation('gantt'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ), withErrorCatcher())(GanttChart);


