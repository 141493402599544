import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { NoNotifsIcon } from '../../../Common/Icons/Notifications/NoNotifications';

const styles = (theme) => ({
    container: {
        marginTop: 56,
        marginBottom: 56,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        marginTop: 16,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: 0.24606,
        color: '#979AAA',
    },
})

const NoNotifs = (props) => {
    const {classes} = props
    const [t] = useTranslation("notificationsDrawer")
    
    return (
        <>
            <div data-intrcm-id="NoNotifs_ezyoi7v9" className={classes.container}>
                <div data-intrcm-id="NoNotifs_yuz4f2oo" className={classes.icon}><NoNotifsIcon /></div>
                <div data-intrcm-id="NoNotifs_8nr1tkq0" className={classes.title}>{t("There are no notifications")}</div>
            </div>
        </>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(NoNotifs)