import { Grid, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher.js';
import KanbanMembers from '../KanbanMembers.js';
import DaysToAlertOnUnusedItem from './DaysToAlertOnUnusedItem.js';
import DueDate from './DueDate.js';
import SocialDataAttachment from './SocialData/SocialDataAttachment.js';
import SocialDataComment from './SocialData/SocialDataComment.js';
import SocialDataSeen from './SocialData/SocialDataSeen.js';
import SocialDataTask from './SocialData/SocialDataTask.js';

const FooterKanbanCardItemMarginTop = 8
const FooterKanbanCarItemdHeight = 20
export const FooterKanbanCardHeight = FooterKanbanCarItemdHeight + FooterKanbanCardItemMarginTop

const styles = theme => ({

    rightSideContainer: {
        marginLeft: "auto",
    },
    itemContainer: {
        width: 'initial',
        '& > *': {
            marginTop: FooterKanbanCardItemMarginTop,
            height: FooterKanbanCarItemdHeight,
        }
    }
})



class FooterKanbanCard extends PureComponent {

    render() {
        const { classes, doc,members } = this.props

        const { commentsCounter, seenCounter,
            relatedDocumentsCounter, taskCounter, taskDoneCounter } = doc;
        return (
            <Grid container direction='row' wrap='nowrap' className={classes.container}>
                <Grid container item alignItems='center' wrap='nowrap' className={classes.itemContainer}>
                    <SocialDataSeen seenCounter={seenCounter} />
                    <SocialDataComment commentsCounter={commentsCounter} />
                    <SocialDataTask taskDoneCounter={taskDoneCounter} taskCounter={taskCounter} />
                    <SocialDataAttachment counter={relatedDocumentsCounter} />
                    <DaysToAlertOnUnusedItem lastModifiedTime={doc.lastModifiedTime} />
                    <DueDate doc={doc} />
                </Grid>
                <Grid item container wrap='nowrap' className={classnames(classes.rightSideContainer, classes.itemContainer)}> 
                    <KanbanMembers docMembers={members} />
                </Grid> 
            </Grid>
        )
    }
}


export default compose(withStyles(styles),
    withErrorCatcher())(FooterKanbanCard);