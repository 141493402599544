import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import React from 'react'
import { hexToRGB } from '../../../utils/hexToRGB';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden'
    },
    simpleBar: {
        maxHeight: '100%',
        '& .simplebar-scrollbar': {
          '&:before': {
            backgroundColor: hexToRGB('#637381', 0.48)
          },
          '&.simplebar-visible:before': {
            opacity: 1
          }
        },
        '& .simplebar-track.simplebar-vertical': {
          width: 10
        },
        '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
          height: 6
        },
        '& .simplebar-mask': {
          zIndex: 'inherit'
        }
    }
  });

 function Scrollbar({ children, classes, maxHeight }) {

  return (
    <div data-intrcm-id="Scrollbar_lw6pnxfz" className={classes.root}>
      <SimpleBarReact timeout={500} clickOnTrack={false} style={{maxHeight: maxHeight || '100%'}}>
        {children}
      </SimpleBarReact>
    </div>
  );
}
export default compose(withStyles(styles, { withTheme: true }))(Scrollbar);