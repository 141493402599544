import { withStyles } from '@material-ui/core'
import React from 'react'
import { compose } from 'recompose'
import NoResultsView from '../../../../components/Common/NoResultsView'
import OngoingLane from './OngoingLane'

const styles = (theme) => ({
    container: {
        width: 'fit-content',
        marginTop: 25,
        marginBottom: 25,
        marginRight: 25,
        display: 'flex',
        flexDirection: 'row'
    },
})
const OngoingLanes = (props) => {
    const {classes, tasks, sortBy} = props

    if(!tasks.length) return <NoResultsView />
    
    return (
        <div data-intrcm-id="OngoingLanes_eb9u27li" className={classes.container}>
            {tasks.map((task, i) => (
                <OngoingLane
                    sortBy={sortBy}
                    task={task}
                    key={task.collectionUid + i}
                />
            ))}
        </div>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(OngoingLanes)