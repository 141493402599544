function cutText(text = '', maxLength = 60) {
    const suffix = text.length > maxLength ? '...' : ''

    return text.substr ? (text.substr(0, maxLength) + suffix) : text
}
function buildCellValue(row, column, renderCell){
    const data = renderCell(row, column)
    let cellValue
    if(!data || data.value == undefined){
        return undefined
    }
        const text = cutText(data.value)

        cellValue = text.replace ? text.replace(/\r|\n|;/gm, ', '): text

    return cellValue
} 

export default function exportTableToCsv (data, columns, renderCell, fileName){
        let commaSepereted = ''
        let rows = [[]]
        const header = []
        columns.forEach((column, cellNum) => {
            rows[0].push(columns[cellNum].fieldName)
        })
        data.forEach(row => {
            const tempRow = columns.map((column, cellNum) => (
                buildCellValue(row, column, renderCell)
            ))
            rows.push(tempRow)
        })

        rows.forEach(row => {
            commaSepereted += `${row.join(';')}\r\n`
        })
        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${commaSepereted}`);

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
}
