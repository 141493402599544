import { DATA_UPDATE_SPECIFIC_ITEM, FINISHED_UPDATING_ITEM, DATA_UPDATE } from '../actionTypes/actionTypes';


export function updateSpecificItem(itemId) {
    return { type: DATA_UPDATE_SPECIFIC_ITEM, itemId }
}

export function finishedUpdatingSpecificItem(itemId) {
    return { type: FINISHED_UPDATING_ITEM, itemId }
}

export function dataUpdate(){
    return { type: DATA_UPDATE }
}