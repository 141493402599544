import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';

const styles = (theme) => ({
    container: {
        width: 60,
        height: 92,
        boxShadow: 'none',
        position: 'relative',
        marginRight: 35,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        '&:last-child': {
            marginRight: 0
        },
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.23,
        borderRadius: 6,
        width: 60 + 20,
        height: 92 + 18,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    avatar: {
        borderRadius: '50%',
        display: 'flex',
        alignSelf: 'center',
        width: 60,
        height: 60,
        zIndex: 2,
        objectFit: 'cover'
    },
    name: {
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.17,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#21212d',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: 'rgba(0,0,0,1)'
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginRight: 5,
        marginLeft: 5,
        zIndex: 2
    }
})

function User(props) {
    const {user, classes, openUser, withKeysNavigation} = props

    const keysNavigationProps = !withKeysNavigation ? {} : {
        suggestCase: JSON.stringify({tag: user.tag, suggestType: "user"}),
        suggest: "suggest-find"
    }

    return (
        <>
        <div data-intrcm-id="User_kkzpw8lv" className={classes.container} >
            <img alt="user" className={classes.avatar} src={user.avatar} />
            <div data-intrcm-id="User_y6y5b0z8" onClick={(e) => openUser(e, user.tag)} style={{marginTop: 4}} className={classes.name}>{user.firstname} <br/> {user.lastname}</div>
        <div data-intrcm-id="User_v1ac49p9" {...keysNavigationProps} className={classes.suggest}/>
        </div>
        </>
    )
}

export default compose(
    withStyles(styles, {withTheme: true})
)(User)

