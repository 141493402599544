import React from 'react'
import { connect } from 'react-redux';
import { initPusher } from './pusherUtils';

const withPusher = (WrappedComponent) => {
    class TuqqiPusher extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                channelName: ''
            }

            this.pusher = initPusher(props.userData)
        }

        unbindChannel = (channelName) => {
            this.pusher.unsubscribe(channelName);
        }

        initPusherChannel = (channelName, eventName, callBack) => {
            const channel = this.pusher.subscribe(channelName);

            channel.bind(eventName, comment => callBack(comment))
        }

        render() {
            return <WrappedComponent 
                initChannel={this.initPusherChannel} 
                unbindChannel={this.unbindChannel} 
                {...this.props} 
            />
        }
    }

    const mapStateToProps = (state) => ({
        userData: state.userData
    })

    return connect(mapStateToProps)(TuqqiPusher)
}

export default withPusher