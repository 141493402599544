import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TOGGLE_COLLECTION_DIALOG } from '../../../actionTypes/actionTypes';
import { showSnackbar } from '../../../actions/appActions';
import { userDataLoad } from '../../../actions/globalDataAction';
import { goToCollection } from "../../../actions/goToActions";
import CollectionPermissionSettings from '../Common/Groups/CollectionPermissionSettings/CollectionPermissionSettings.js';
import CollectionSettingsStep from '../Common/Groups/CollectionSettings/CollectionSettingsStep';
import GroupUsers from '../Common/Groups/GroupUsers/GroupUsers';
import CollectionBasicDetailsStep from '../Common/Groups/CollectionBasicDetailsStep';
import { withMixpanel } from '../Common/withMixpanel';
import { loadUserData } from '@tuqqi/common';
import { SERVER_API } from '../../../utils/serverAPI';
import { emptyReq } from '../../views/groups/groupUtils';
import { mapCollectionToGroupNavigation } from '../../../utils/groupsNavigation/collectionToGroupNavigation';
import parseImagUrl from "../Common/parseImagUrl";
import groupPlaceholder from "../../../assets/img/group_placeholder.png"

// Apply some reset
const styles = theme => ({
    root: {
        width: '100%',
        height: '80%',
        flexGrow: 1,
    },
    stepper: {
        padding: 'initial',
        width: '100%',
        paddingTop: 30
    }
});

class GroupDialog extends Component {
    state = {
        fullKanbanLength: 0
    }
    setKanbanLength = () => {
        const { searchQuery, queryService } = this.props
        const _searchQuery = { ...searchQuery, ...emptyReq }
        queryService.GetKanbanData(_searchQuery).then(res => {
            this.setState({ fullKanbanLength: res.length })
        })
    }

    listenChangeKanbanLength = (prevCollection) => {
        const {collection} = this.props
        if(!!collection.open === !!prevCollection.open) return
        this.setKanbanLength()
    }

    listenChangeStep = () => {
        const {activeStep} = this.state
        if([0,1,2,3].includes(activeStep)) return
        this.setState({activeStep: 0})
    }
    listenClose = (prevCollection) => {
        const {activeStep} = this.state
        const {collection} = this.props
        const collectionWasClosed = !collection.open && !!prevCollection.open
        const needReset = collectionWasClosed && activeStep !== 0
        if(!needReset) return
        this.setState({activeStep: 0})
    }

    componentDidUpdate(prevProps, prevState) {
        this.listenChangeKanbanLength(prevProps.collection)
        this.listenChangeStep()
        this.listenClose(prevProps.collection)
    }

    handleClose = () => {
        const { dispatch } = this.props;

        this.setState({
            activeStep: 0
        })

        dispatch({ type: TOGGLE_COLLECTION_DIALOG });
        dispatch(loadUserData(SERVER_API))
    };

    createSavedCollection = () => {
        const { allInputsAllowed, backgroundUrl, settings, title, description, icon, group, id, defaultView } = this.props.collection

        if(!allInputsAllowed && !settings?.allowedInputs?.length) {
            settings.allowedInputs = ['post']
        }
        const newCollection = {
            isLimitedGroupEnabled: group.isLimitedGroupEnabled,
            allInputsAllowed,
            backgroundUrl,
            settings,
            id,
            title,
            description,
            icon: icon || 'public',
            collectionGroup: {
                groupUsers: [],
                isClosed: group.isClosed,
                isEnableMembersAsAuthors: group.isEnableMembersAsAuthors
            },
            limitedGroup: {
                groupUsers: []
            },
            defaultView
        }

        Array.from(group.checked.keys()).forEach(key => {
            let groupUser = { userId: key }

            if (group.checked.get(key).isLimited) {
                newCollection.limitedGroup.groupUsers.push(groupUser)
            } else {
                if (group.checked.get(key).isAdmin) {
                    groupUser.role = 1
                }
                newCollection.collectionGroup.groupUsers.push(groupUser)
            }
        })


        return newCollection
    }

    handleClickAdd = () => {
        const { userSettingsService, groupService, queryService, dispatch, collection, mixpanelTrack, t } = this.props;

        dispatch(showSnackbar(t(collection.isNew ? 'Creating' : 'Saving')));

        mixpanelTrack(collection.isNew ? 'Create group' : 'Update group', {})

        const newCollection = this.createSavedCollection()

        this.handleClose();

        const serverRequest = collection.isNew ? groupService.createCollection : groupService.updateCollection

        serverRequest(newCollection).then((updatedCollection) => {
            if(collection.isNew) {
                const backgroundUrl = parseImagUrl(this.props.cloudfront, updatedCollection.backgroundUrl, 100, groupPlaceholder);
                dispatch({
                    type: 'ADD_SEARCH_MANAGED_GROUP',
                    group: mapCollectionToGroupNavigation({ ...updatedCollection, backgroundUrl })
                })
            }
            queryService.getCollectionInfo(updatedCollection.collectionUid).then((result) => {
                if(!result) return
                dispatch({
                    type: 'RESULT_CHANNEL_UPDATE', results: {
                        channel: result,
                    }
                })
            })
            userSettingsService.getUserData().then((userData) => {
                dispatch(loadUserData(SERVER_API))
                dispatch(userDataLoad(userData))
                dispatch({ type: 'RESULT_FILTERS_UPDATE', results: { dataTypes: [],  relatedDocs: [], experts: [], groups: [], } });
                dispatch(showSnackbar(t(collection.isNew ? 'Created!' : 'Saved!')));

                goToCollection('/' + updatedCollection.title, dispatch, updatedCollection.collectionUid, updatedCollection.collectionGroup.groupUid, updatedCollection)();
            })
        })
    }

    handleNext = () => {
        this.moveStep(1)
    };

    handleBack = () => {
        this.moveStep(-1)
    };

    moveStep(step) {
        this.setState({ activeStep: this.state.activeStep + step });
    }

    stepsLabel = ['Basic Details', 'Privacy Level', 'Users', 'Group Settings']

    getStepContent = (stepIndex) => {

        switch (stepIndex) {
            case 0:
                return <CollectionBasicDetailsStep fullKanbanLength={this.state.fullKanbanLength} />;
            case 1:
                return <CollectionPermissionSettings />;
            case 2:
                return <GroupUsers />;
            case 3:
                return <CollectionSettingsStep />;
            default:
                return 'Uknown stepIndex';
        }
    }

    render() {
        const { classes, collection, t } = this.props;
        const { activeStep } = this.state;

        if(!collection.open){
            return null;
        }
        return <Dialog
            classes={{ paper: classes.root }}
            open={collection.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {collection.isNew ? t('Add new group') : `${t('Update')}  ${collection.title}`}
                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                    {this.stepsLabel.map(label => (
                        <Step key={label}>
                            <StepLabel>{t(label)}</StepLabel>
                        </Step>)
                    )}
                </Stepper>
            </DialogTitle>
            <DialogContent>

                {this.getStepContent(activeStep)}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} aria-label="delete" className={classes.button}>
                    {t('Cancel')}
                </Button>
                <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.backButton}
                >
                    {t('Back')}

                </Button>
                {activeStep < this.stepsLabel.length - 1
                    ?
                    <Button
                        className="new-group-next-btn"
                        variant="contained"
                        color="primary"
                        disabled={!(collection.title && collection.description)}
                        onClick={this.handleNext}>
                        {t('Next')}

                    </Button>
                    :
                    <Button
                        onClick={this.handleClickAdd}
                        disabled={!(collection.title && collection.description)}
                        color="primary"
                        aria-label="Add group"
                        className={`${classes.addButton} add-group-btn`}>
                        {t(collection.isNew ? t('Add group') : t('Update Group'))}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    ...state.wrapper,
    collection: state.collection,
    cloudfront: state.globalData.cloudfront,
    searchQuery: state.search,
    ...state.services
})

export default compose(withStyles(styles),
    withMixpanel,
    withTranslation('newGroupDialog'),
    connect(mapStateToProps))(GroupDialog);

