import React, { Component } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import { LeftDrawer } from '../../components/App/Drawers/LeftDrawer/leftDrawer.js';
import { themeConstants } from '../../../theme.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';


const styles = theme => ({
    resultsWrap: {
        position: 'relative',
        width: '100vw',
        
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('1441')]: {
            paddingLeft: 16,
            width: '100vw',
            justifyContent: 'center',
            display: 'flex',
            [theme.breakpoints.down('1009')]: {
                paddingLeft: 250,
                width: '100vw',
                justifyContent: 'center',
                display: 'block',
            },
        },
    },
    results: {
        [theme.breakpoints.up('1441')]: {
            position: 'relative',
        },
        width: 512,
        marginTop:16,
        flex: '0 0 auto'
    },
    left: {
        [theme.breakpoints.up('1441')]: {
            position: 'absolute',
            left: -themeConstants.leftDrawerWidth - 38,
        },
        position: 'absolute',
        left: 16
    },
    right1: {
        width: 300 + 16 + 16,
        paddingLeft: 4,
        marginTop: 8,
        // marginTop: 16,
        zIndex: 2,
        bottom: 0,
        position: 'fixed',
        overflowY: 'auto',
        overscrollBehavior: 'contain',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight + 16}px)`,
        top: theme.constants.defaultToolbarHeight
    },
    right: {
        [theme.breakpoints.up('1441')]: {
            right: -24,
            position: 'absolute',
        },
        [theme.breakpoints.down('1120')]: {
            visibility: 'hidden'
        },
        position: 'absolute',
        right: 300 + 16 + 16
    },

});


class NewFeedDiscovery extends Component {
    render() {
        const { classes, rightWidgets, children } = this.props;
        // console.log(window.innerWidth, window.outerWidth, 'fddcdcd');
        return (
            <Grid item className={classes.resultsWrap} >
            <Grid item className={classes.results} >
                <Grid item className={classes.left}>
                    <LeftDrawer />
                </Grid>
                {children}
                <Grid item className={classes.right}>
                    <div data-intrcm-id="NewFeedDiscovery_3ervrqhm" className={classes.right1}>
                        {rightWidgets}
                    </div>
                </Grid>
            </Grid>
            </Grid>
        )
    }
}

NewFeedDiscovery.propTypes = {
    leftWidgets: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(NewFeedDiscovery);