import React from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { Divider, MenuItem, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DataTypeButtonComponent } from './DataTypeListForCreate';
import { getGroupDocumentPermission } from '@tuqqi/common';
import { openInput as openNewUiInput } from '@tuqqi/web'


const styles = (theme) => ({
    title: {
        color: '#FFFFFF8F',
        fontWeight: '400', 
        padding: "6px 8px 6px 8px", 
        fontSize: 12,
        lineHeight: '12px',
        marginLeft:4, 
        marginRight:4
    },
    text: {
        fontFamily: theme.typography.fontFamily,
    }
})

const CreateNewInGroup = (props) => {

    const { t,groupInfo, dispatch, classes } = props;    
    if (!!!groupInfo || !!!groupInfo.settings || !!!groupInfo.settings.allowedInputs ) return null;



    const openNewInput = (dataType) => {
        const permissions = getGroupDocumentPermission(groupInfo.collectionGroup.groupUid)
        let newDoc = { html: '', title: '', permissions: permissions, dataType: dataType }
        dispatch(openNewUiInput(newDoc, dataType))
        dispatch({ type: 'BLUR_CREATENEW_MODAL' })
    }
    // Datatypes for collection    
    const dataTypesFilters = groupInfo.settings.allowedInputs;
    const dataTypes = props.inputMappings.filter(x => dataTypesFilters.includes(x.customDataMapping.dataTypeFilter)).sort((a, b) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    });

    const dataTypesButtons = dataTypes.map((x) => {
        return <DataTypeButtonComponent mapping={x} getCollectionsForDataType={() => { return [groupInfo] }} openNewInput={() => { openNewInput(x.customDataMapping.dataTypeFilter) }} disableMenu={true} />
    });


    return (
        <div>
            <div className={classes.title} >{groupInfo.title}</div>
            {dataTypesButtons}
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        isFocused: state.createNewModal.isFocused,
        groupInfo: state.results.channel,
        inputMappings: state.globalData.inputMappings,

    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, { withTheme: true }),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(CreateNewInGroup)
