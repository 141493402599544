import React, { Component } from 'react';
import { push } from 'react-router-redux';
import moment from 'moment'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Prize from './Prize'
import Status from './status'
import CircularProgress from '@material-ui/core/CircularProgress';
import { showSnackbar } from '../../../../../actions/appActions'
import { withMixpanel } from '../../../../components/Common/withMixpanel.js';
import { withTranslation } from 'react-i18next';
import { OldPagesWrap } from '../../newway/OldPagesWrap.js';
import { managerAdminDrawer } from '@tuqqi/web';

const maxNumPrizes = 3

const styles = theme => ({
    container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
    page: {
        width: '50%',
        margin: 'auto'
    },
    dateRangeSpace: {
        marginTop: 17
    },
    prizeButton: {
        // padding:'0 24px 24px'
        margin: '10px 24px'
        // margin: '0 24px 24px 10px'
    },
    title: {
        // margin: '10px 24px'
    },
    saveButton: {
        marginLeft: 'auto'
    },
    head: {
        padding: '6px 24px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paperStyle: {
        marginBottom: 40
    },
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
})

class newChallenge extends Component {
    state = {
        prizes: [],
        scores: [],
        isLoading: true,
        openNewChallenge: false,
        challenge: '',
        isExistOtherChallenge: false
    }
    addPrize = () => {
        this.setState({ prizes: [...this.state.prizes, {}] })
    }
    removePrize = (index) => (() => {
        let newPrizes = this.state.prizes.filter((p, i) => i != index)
        this.setState({ prizes: newPrizes })
    })
    handlePrizeChenged = (index) => ((key, value) => {
        this.state.prizes[index][key] = value
    })


    save = () => {
        const { params, challengeService, dispatch, mixpanelTrack } = this.props;
        const { type, id } = params

        let request;
        let challenge = {
            id: id,
            head: '',
            title: this.state.title,
            dateFrom: new Date(this.state.dateFrom),
            dateTo: new Date(this.state.dateTo),
            prizes: this.state.prizes,
            description: this.state.description

        };

        switch (type) {
            case "details":
                request = challengeService.updateChallenge(challenge)
                mixpanelTrack('Update challenge', {})
                break;
            case "new":
                request = challengeService.insertChallenge(challenge)
                mixpanelTrack('Create challenge', {})
                break;
            default:
                return
        }
        dispatch(showSnackbar('Saving...'));

        request.then((statusCode) => {
            // if(statusCode)
            dispatch(showSnackbar(statusCode.message/*'Saved...'*/));
            if (statusCode.isSuccess) {
                this.props.dispatch(push('/admin/scoreBoardSettings'))
            }
        })
    }

    disableSave = () => {
        const dateFrom = new Date(this.state.dateFrom)
        const dateTo = new Date(this.state.dateTo)
        let disable = false

        if (this.state.isExistOtherChallenge) {
            disable = true
        }
        else if (dateFrom > dateTo) {
            disable = true
        }
        else if (this.props.params.type == "details" && dateTo < Date.now) {
            disable = true
        }

        return disable
    }
    componentDidMount() {
        const { params, challengeService } = this.props;
        const { type, id } = params

        managerAdminDrawer.setOption({
			'prop': 'scoreboard',
			uid: 'item'
		})

        if (type == "details") {

            challengeService.getChallengeById(id)
                .then((details) => {
                    const { challenge, scores, isExistOtherChallenge } = details

                    this.setState({
                        isExistOtherChallenge: false,//isExistOtherChallenge,
                        challenge: challenge,
                        title: challenge.title,
                        dateFrom: new Date(challenge.dateFrom),
                        dateTo: new Date(challenge.dateTo),
                        prizes: challenge.prizes,
                        isLoading: false,
                        description: challenge.description,
                        scores: scores,
                        head: 'Edit'
                    })
                })
        } else {
            this.setState({
                dateFrom: new Date(),
                dateTo: new Date(),
                isLoading: false,
                head: 'New'
            })
        }
    }
    handleChange = (key) => (e) => {
        this.setState({ [[key]]: e.target.value })
    }
    render() {
        const { classes,t } = this.props;

        let content;

        if (this.state.isLoading) {
            content = (<div data-intrcm-id="newChallenge_1cu08psm" className={classes.spinner}>
                <CircularProgress className={classes.progress} />
            </div>)
        }
        else {
            content = (<div data-intrcm-id="newChallenge_4rmw1aa7"> <div data-intrcm-id="newChallenge_abl1ty3n" class={classes.head}>
                <Button className={classes.saveButton} disabled={this.disableSave()} color="primary" variant="raised" onClick={this.save}>
                    {t('Save')}
    </Button>
            </div>
                <List>
                    <ListItem>
                        <TextField autoFocus margin="dense" value={this.state.title} label={t("Title")} type="text" fullWidth onChange={this.handleChange("title")} />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label={t("Date from")} type="date"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={moment(this.state.dateFrom).format(moment.HTML5_FMT.DATE)}
                            value={moment(this.state.dateFrom).format(moment.HTML5_FMT.DATE)}
                            onChange={this.handleChange("dateFrom")} />
                        <div data-intrcm-id="newChallenge_7fi3gkgs" className={classes.dateRangeSpace}>&nbsp; - &nbsp;</div>
                        <TextField
                            label={t("Date to")} type="date"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={moment(this.state.dateTo).format(moment.HTML5_FMT.DATE)}
                            value={moment(this.state.dateTo).format(moment.HTML5_FMT.DATE)}
                            onChange={this.handleChange("dateTo")} />
                    </ListItem>
                    <ListItem>
                        <TextField autoFocus margin="dense" value={this.state.description} label={t("Description")} type="text" fullWidth onChange={this.handleChange("description")} />
                    </ListItem>
                </List>

                <Button className={classes.prizeButton} disabled={this.state.prizes.length >= maxNumPrizes} color="primary" variant="raised" onClick={this.addPrize}>
                    {t('Add Prize')}
    </Button>
            </div>)
        }

        return (
            <OldPagesWrap className={classes.container} classNameContent={classes.content} >
                        <Typography variant="title" style={{ marginBottom: '15px' }} className={classes.title}>
                            {this.state.head} {t('Challenge')}
                 </Typography>
                        <Typography variant="body2" gutterBottom>
                            {t('Details')}
                                </Typography>
                        <Paper className={classes.paperStyle}>
                            {content}
                        </Paper>

                        {
                            this.state.prizes.map((prize, index) =>
                                <Prize key={index} index={index} handlePrizeChenged={this.handlePrizeChenged(index)} data={prize} removePrize={this.removePrize(index)} />)
                        }
                        <Status scores={this.state.scores} />
        </OldPagesWrap>)
    }
}

const mapStateToProps = (state) => ({
    challengeService: state.services.challengeService
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),
    withTranslation('admin'),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(newChallenge);