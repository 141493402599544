import { Grid, Menu, MenuItem, withStyles, Checkbox, Divider, Typography, Button, Tooltip } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import classnames from "classnames";
import Popover from '@material-ui/core/Popover';
import { withTranslation } from 'react-i18next';

import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';

const styles = theme => ({
    container: {
        cursor: 'pointer',
        width: 'initial',
        "height": "44px",
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray400}`,
        "backgroundColor": theme.newUI.color.gray50
    },
    menuItemsContainer: {
        maxHeight: 395,
        overflowY: 'auto',
        ...theme.newUI.scrollbar
    },
    menuItem: {
        flex: '0 0 auto',
        height: 60,
        padding: '0px 16px 0px 4px',
        '&:hover': {
            backgroundColor: '#f0f3ff'
        },
        '&:focus': {
            backgroundColor: '#daddee'
        }
    },
    menuItemSelected: {
        backgroundColor: '#f0e6fe !important'
    },
    menuItemText: {
        marginLeft: 16,
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.75",
        "letterSpacing": "0.5px",
        "color": theme.newUI.color.commonBlack
    },
    checked: {
        color: `${theme.newUI.color.primary} !important`
    },
    checkbox: {
        marginRight: 4
    },
    divider: {
        backgroundColor: theme.newUI.color.gray400
    },
    footer: {
        height: 40,
        padding: '0px 8px'
    },
    menu: {
        overflow: 'hidden'
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    buttonText: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        // "textAlign": "center",
        "color": theme.newUI.color.primary
    },

    button: {
        textTransform: 'initial'
    },
    selectAll: {
        "color": theme.newUI.color.commonBlack
    },
    title: {
        fontFamily: 'Rubik',
        height: 40,
        padding: '0px 16px',
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    disabled: {
        color: `${theme.newUI.color.gray} !important`
    },
    disableFocus: {
        outline: 'none'
    }
});

class MenuTableToolbar extends Component {
    state = {
        anchorEl: null,
        openMenu: false,
        changed: false,
        options: []
    }

    isSomeOptionChanged = () => {
        return this.props.options.some(propsOption => {
            const stateOption = this.state.options.find(currOption => currOption.key === propsOption.key) || {}

            return propsOption.selected !== stateOption.selected
        })
    }

    initOptions = (propsOptions) => {
        this.setState({ options: [...this.props.options] })
    }

    togglrOpenMenu = (event) => {
        this.setState({
            openMenu: !this.state.openMenu,
            anchorEl: event && event.currentTarget
        })
    }

    save = () => {
        this.togglrOpenMenu(null)
        this.props.onChoose(this.state.options)
    }

    cancel = () => {
        this.initOptions()
    }
    onChoose = (selectedOption) => (e) => {
        const { onChoose, allowMultiple } = this.props;

        if (allowMultiple) {
            this.setState({
                options: this.state.options.map(option =>
                    option.key === selectedOption.key ? ({ ...option, selected: !selectedOption.selected }) : option)
            })

        } else {
            this.togglrOpenMenu(null)
            onChoose(selectedOption, e)
        }
    }

    toggleSelectAll = () => {
        const isAllSelected = this.isAllSelected()

        this.setState({
            options: this.state.options.map(option => ({ ...option, selected: !isAllSelected }))
        })
    }

    isAllSelected = () => this.state.options.every(option => option.selected)

    render() {
        const { classes, t, dataTypes, button, children, allowMultiple, title } = this.props;
        const { openMenu, anchorEl, options } = this.state;


        return (
            <Fragment>
                <Tooltip
                    classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                    title={title}
                    placement="bottom"
                >
                    <Grid item container alignItems='center' onClick={this.togglrOpenMenu} className={classes.container}>
                        {button}
                    </Grid>
                </Tooltip>
                <Menu
                    dir='auto'
                    onEnter={this.initOptions}
                    MenuListProps={{
                        className: classes.list
                    }}
                    classes={{
                        paper: classes.menu,
                    }}
                    id="customized-menuuiyoiuyiuy"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}

                    onClose={this.togglrOpenMenu}
                    open={openMenu}
                >
                    <Grid container alignItems='center' className={classnames(classes.title, classes.disableFocus)} >{title}</Grid>
                    <Divider className={classnames(classes.divider, classes.disableFocus)} />
                    <Grid container direction='column' className={classes.menuItemsContainer} wrap='nowrap'>
                        {
                            options.map((option, idx) => (
                                <MenuItem
                                    selected={option.selected}
                                    onClick={this.onChoose(option)}
                                    value={option.key}
                                    classes={{
                                        root: classes.menuItem,
                                        selected: classes.menuItemSelected,

                                    }}>
                                    <Grid container wrap='nowrap' alignItems='center'>
                                        {
                                            allowMultiple && (
                                                <Checkbox checked={option.selected}
                                                    classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                    }} />)
                                        }
                                        <span className={classes.menuItemText}>{t(option.label)}</span>
                                    </Grid>
                                </MenuItem>
                            ))
                        }
                    </Grid>

                    <Divider className={classnames(classes.divider, classes.disableFocus)} />
                    <Grid container wrap='nowrap' alignItems='center'
                        className={classnames(classes.footer, classes.disableFocus)}>
                        {
                            allowMultiple && (
                                <Button classes={{
                                    // root: classes.button,
                                    root: classnames(classes.button, classes.buttonText, classes.selectAll)
                                }}
                                    onClick={this.toggleSelectAll}>
                                    {t(this.isAllSelected() ? "Unselect all" : 'Select all')}
                                </Button>
                            )
                        }


                        <Button classes={{
                            root: classes.button,
                            label: classes.buttonText
                        }}
                            onClick={this.togglrOpenMenu}
                            style={{ marginLeft: 'auto' }}>
                            {t('Cancel')}
                        </Button>
                        {
                            allowMultiple && (
                                <Button disabled={!this.isSomeOptionChanged()} classes={{
                                    root: classnames(classes.button, classes.buttonText),
                                    disabled: classes.disabled
                                }}
                                    onClick={this.save}
                                    style={{ marginLeft: 8 }}>
                                    {t('Save')}
                                </Button>)
                        }
                    </Grid>
                </Menu>

            </Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    dataTypes: state.results.dataTypes
})


export default compose(withStyles(styles),
    withTranslation('menuTableToolbarTranslate'),
    withErrorCatcher())(MenuTableToolbar);