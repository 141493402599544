import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
	clearDrawerNavigation,
	selectDrawerNavigationOption,
	setDrawerNavigationMode,
} from "../../../../actions/drawerNavigationActions";
import { themeConstants } from '../../../../theme';
import { getMyDrawerFromPath } from "../../../../utils/drawerNavigation/myDrawerNavigationHelpers";
import { hexToRGB } from "../../../../utils/hexToRGB";
import { createTagPayload } from "../../../../utils/tagHelpers";
import { createCustomTheme } from "../../../../utils/theming";
import goTo from "../../../actions/goTo";
import DrawerContainer from "../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer";
import { useDrawerNavigation } from "../../../components/Common/Hooks/useDrawerNavigation/useDrawerNavigation";
import { useGetTagAdditionalOptions } from '../../../components/Common/Hooks/useDrawerNavigation/useGetTagAdditionalOptions';
import withErrorCatcher from "../../../components/Common/withErrorCatcher";
import { withMixpanel } from '../../../components/Common/withMixpanel';
import { TagsInfoDrawer } from "../../../components/TagsInfo/TagsInfoDrawer";

const styles = (theme) => {
  const primaryColor = theme.customColors.primary.b300
  const lighterColor = hexToRGB(primaryColor, 0.1)
  const primaryColor09Opacity = hexToRGB(primaryColor, 0.999)
  return ({
    container: {
      paddingTop: 24,
  },
  divider: {
	backgroundColor: "rgba(183, 184, 189, 0.15)",
	height: '1px',
	width: '100%',
  },
  navigation: {
    // margin: 8,
  },
  navigationSmall: {
    margin: 16,
  },
  drawerWrapper: {
        position: 'relative',
  },
  paper: {
    height: 'inherit!important',
    width: 240,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
  },
  drawerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 24,
  },
    settingsButton: {
       cursor: 'pointer',
        height: 28,
        width: 83,
        marginLeft: 10,
        border: `1px solid ${theme.customColors.primary.b500}`,
        textTransform: 'none',
        fontFamily: 'Rubik',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        color: theme.customColors.primary.b500,
        borderRadius: 4,
        "&:hover": {
          backgroundColor:  lighterColor,
      },
    },
    settingsButtonColored: {
      cursor: 'pointer',
      height: 28,
      width: 83,
      textTransform: 'none',
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      color: 'white',
      backgroundColor: theme.customColors.primary.b500,
      borderRadius: 4,
      "&:hover": {
        backgroundColor: primaryColor09Opacity,
    },
  },
  drawerSmallButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 22,
    marginBottom: 24,
  },
 
  smallButtonIcon: {
    width: 30,
    height: 28,
    border: `1px solid ${theme.customColors.primary.b500}`,
    textAlign: 'center',
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    marginTop: 8,
    "&:hover": {
        backgroundColor:  lighterColor,
    },
  },
  smallColoredButtonIcon: {
    width: 32,
    height: 28,
    textAlign: 'center',
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    backgroundColor: theme.customColors.primary.b500,
    "&:hover": {
        backgroundColor: primaryColor09Opacity,
    },
},
  tooltip: {
    fontSize: 12,
    backgroundColor: '#4A4A4F',
    padding: '4px 8px',
    borderRadius: 4,
    fontfamily: 'Rubik',
    lineHeight: '16px',
},
triangle: {
    position: 'absolute',
    top: '50%',
    left: -3,
    marginTop: -3.5,
    transform: 'rotate(-45deg)',
    border: `solid 3px #4A4A4F`,
    borderRight: `3px solid transparent`,
    borderBottom: `3px solid transparent`,
    zIndex: '9999'
},
})};

const __TagPageLeftDrawerNew = (props) => {
  const { 
    query, 
    classes, 
    dispatch, 
    drawerNavigation, 
    userData, 
    tagInfo, 
    relatedTagsQuery, 
    mixpanelTrack, 
    subscribedChannels,
    userSettingsService,
    profileData,
   } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [topicsTags, setTopicsTags] = useState([]);
  const [currentTagInfo, setCurrentTagInfo] = useState(tagInfo);
  const [t] = useTranslation("groupDrawerTranslate");
  const paperStyle = isDrawerOpen ? {} : { width: themeConstants.navigationDrawerSmallWidth, overflowX: 'hidden' };
  const isTopic = currentTagInfo?.tagType?.toLowerCase() === 'topic'
  const [descriptionText, setDescriptionText] = useState('')
  const themeOption = profileData.org?.brandingColor ?? 'default'
  const theme = createCustomTheme(themeOption)

  useEffect(() => {
    const { pathname } = window.location;
    const [mode, id] = getMyDrawerFromPath(pathname);

    dispatch(setDrawerNavigationMode(mode));
    dispatch(selectDrawerNavigationOption(id));

    return () => {
      dispatch(clearDrawerNavigation());
    };
  }, []);

  useEffect(() => {
    const ti = tagInfo.failed ? createTagPayload(query.slice(1), null, 'user_tag', 'MISC') : tagInfo
    setCurrentTagInfo(ti)
  }, [tagInfo, query])

  useEffect(() => {
    if(currentTagInfo?.tagType === 'topic') {
      setTopicsTags(currentTagInfo?.topicTags?.map(t => ({key: t})) ?? [])
      return
    }
    setTopicsTags([])
  }, [currentTagInfo])

  const selectChannelHandler = (channel) => {
    mixpanelTrack(`Channel page view - ${channel}`, {})
    goTo("#" + channel, dispatch)();
  };

  const [additionalOptions, EditTag] = useGetTagAdditionalOptions({
    t,
    query,
    isTopic,
    currentTagInfo,
    userData,
    dispatch,
    subscribedChannels,
    userSettingsService,
  })

  const [Navigation] = useDrawerNavigation({
    drawerNavigation,
    isChannel: true,
    currentTags: isTopic ? topicsTags : relatedTagsQuery,
    isDrawerOpen,
    profileData,
    query,
    selectChannelHandler,
    t
  });

  const NavigationSection = (
    <div data-intrcm-id="TagPageLeftDrawerNew_jocetm9f"
      className={isDrawerOpen ? classes.navigation : classes.navigationSmall}
    >
      {Navigation}
    </div>
  );

//   const handleDescriptionSave = () => {
// 	this.props.inputService.addTagDescription(this.tagPrefix() + tagInfo.tag, descriptionText).then(() => {
// 		dispatch({
// 			type: 'RESULT_CHANNEL_UPDATE', results: {
// 				channel: { ...tagInfo, description: descriptionText },
// 			}
// 		})
// 		// this.setState({ open: false })
// 		dispatch(showSnackbar('Saved!'));
// 	})
// 	dispatch(showSnackbar('Saving...'));
// }


  return (
    <div data-intrcm-id="TagPageLeftDrawerNew_p3lk45vk" className={classes.drawerWrapper}>
      <DrawerContainer containerStyle={`${classes.container} ${classes.paper}`} PaperProps={{ style: paperStyle }} open={isDrawerOpen}>
        <TagsInfoDrawer openEditModal={additionalOptions[0].onClick} currentTagInfo={currentTagInfo} descriptionText={descriptionText} setDescriptionText={setDescriptionText} query={query} isDrawerOpen={isDrawerOpen}/>
        <div className={classes.divider} />
        {NavigationSection}
      </DrawerContainer>
        {EditTag}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tagInfo: state.results.channel,
  profileData: state.userData.profileData,
  drawerNavigation: state.drawerNavigation,
  relatedTagsQuery: state.results.relatedTagsQuery,
  userData: state.userData,
  userSettingsService: state.services.userSettingsService,
  subscribedChannels: state.userData.subscribedTags,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export const TagPageLeftDrawerNew = compose(
  withStyles(styles),
  withTranslation('widgetLibrary'),
  withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
  withMixpanel,
  connect(mapStateToProps, mapDispatchToProps)
)(__TagPageLeftDrawerNew);
