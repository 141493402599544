import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, ListItemIcon, TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { globalDataLoad } from '../../../../actions/globalDataAction.js';
import HelpButtonTooltip from '../../../components/Common/HelpButton/HelpButtonTooltip.js';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';
import { defaultLabelColors, proccessLabelColor, setOrgLabels } from  "@tuqqi/common"

const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    button: {
        margin: 16
    }
})


const CreateNewModal = (props) => {
    const { handleClose, open, handleClickSave,t } = props

    const [labelName, setLabelName] = useState('');
    const [color, setColor] = useState('');

    return <Dialog open={open} onRequestClose={handleClose}>
        <DialogTitle>{t('Create new Label')}</DialogTitle>
        <DialogContent>
            <TextField fullWidth label={t("Name")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelName} margin="normal"
                onChange={(value) => { setLabelName(event.target.value) }} />
            <Grid container>
                {defaultLabelColors.map((_color, idx) => (
                    <Grid container alignContent='center' justify='center' item
                        onClick={() => { setColor(_color) }}
                        key={idx}

                        style={{ backgroundColor: proccessLabelColor(_color), width: 52, height: 52, margin: '0px 10px 10px 0', borderRadius: 2, cursor: 'pointer' }}>
                        {color === _color ? <Icon style={{ color: '#1B1E2A' }}>{t('check')}</Icon> : null}
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
            {t('Cancel')}
            </Button>
            <Button onClick={() => { handleClickSave({ name: labelName, color: color }) }} color="primary" >
            {t('Save')}
            </Button>
        </DialogActions>
    </Dialog>
}

const EditLabelModal = (props) => {
    const { handleClose, open, handleClickSave,t, label } = props

    const [labelName, setLabelName] = useState(label.name);
    const [color, setColor] = useState(label.color);

    return <Dialog open={open} onRequestClose={handleClose}>
        <DialogTitle>{t('Edit Label')}</DialogTitle>
        <DialogContent>
            <TextField fullWidth label={t("Name")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelName} margin="normal"
                onChange={(value) => { setLabelName(event.target.value) }} />
            <Grid container>
                {defaultLabelColors.map((_color, idx) => (
                    <Grid container alignContent='center' justify='center' item
                        onClick={() => { setColor(_color) }}
                        key={idx}

                        style={{ backgroundColor: proccessLabelColor(_color), width: 52, height: 52, margin: '0px 10px 10px 0', borderRadius: 2, cursor: 'pointer' }}>
                        {color === _color ? <Icon style={{ color: '#1B1E2A' }}>{t('check')}</Icon> : null}
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
            {t('Cancel')}
            </Button>
            <Button onClick={() => { handleClickSave({ ...label, name: labelName, color: color }) }} color="primary" >
            {t('Save')}
            </Button>
        </DialogActions>
    </Dialog>
}

export const newOrgLabelOnInit = {
    value: false
}

class _OrganizationLabels extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            labels: [],
            loaded: false,
            modalOpen: newOrgLabelOnInit.value,
            editModalOpen: false,
            editLabel: null,
        }
    }

    componentDidMount() {
        const { adminService } = this.props
        adminService.getAllOrgLabel().then(labels => this.setState({ labels, loaded: true }))
        newOrgLabelOnInit.value = false;
    }

    edit = (label) => {
        if(label.labelUid === 'draft:draft') return
        this.setState({editModalOpen: true, editLabel: label})
    }

    save = (label) => {
        const { adminService, dispatch } = this.props;

        adminService.createOrgLabel(label).then(() => {
            this.setState({ modalOpen: false })
            adminService.getAllOrgLabel().then(labels => {
                dispatch(globalDataLoad({ orgLabels: labels }));
                dispatch(setOrgLabels(labels))
                this.setState({ labels, loaded: true })
            })

        })
    }

    saveEdit = (label) => {
        const { adminService, dispatch } = this.props;

        adminService.editOrgLabel(label).then(() => {
            this.setState({ editModalOpen: false, editLabel: null })
            adminService.getAllOrgLabel().then(labels => {
                dispatch(globalDataLoad({ orgLabels: labels }));
                dispatch(setOrgLabels(labels))
                this.setState({ labels, loaded: true })
            })
        })
    }

    delete = (label) => {
        if(label.labelUid === 'draft:draft') return
        const { adminService, dispatch } = this.props;

        adminService.deleteOrgLabel(label.labelUid)
            .then(() => { adminService.getAllOrgLabel().then(labels => {
                dispatch(globalDataLoad({ orgLabels: labels }));
                this.setState({ labels, loaded: true })
            }) })

    }



    render() {
        const { classes , t, tDraft} = this.props;
        const { labels: _labels, loaded, modalOpen, editModalOpen, editLabel } = this.state;

        const spinner = <div data-intrcm-id="OrgLabelsSettings_fme5uuc3" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>
        let content = spinner;
        const labels = [{id: -2, color: '#7B4FF0', name: tDraft("Draft"), labelUid: 'draft:draft' }, ..._labels]

        if (loaded) {
            content = <List>
                {
                    labels.map((label, idx) => (
                        <ListItem
                            key={idx}
                            style={label.labelUid === 'draft:draft' ? {pointerEvents: 'none'} : {}}
                            button
                            onClick={() => { this.edit(label) }} >
                            <div data-intrcm-id="OrgLabelsSettings_wy6vs044" style={{width: 20, height: 20, border: label.labelUid === 'draft:draft' ? '1px dashed #7B4FF0' : 'none', background: label.labelUid === 'draft:draft'? 'transparent': proccessLabelColor(label.color), borderRadius: 10}} />
                            <ListItemText
                                primary={label.name}
                                secondary={label.labelUid === 'draft:draft' ? t("This label will be applied to items that are saved as Draft, it means that have empty mandatory fields") : label.color} />
                            {label.labelUid !== 'draft:draft' && <ListItemIcon >
                                <Icon onClick={() => { this.delete(label) }} >{t('clear')}</Icon>
                            </ListItemIcon>}
                        </ListItem>
                    ))
                }
            </List>
        }



        return <div data-intrcm-id="OrgLabelsSettings_ysr3h1hc">
            <HelpButtonTooltip link='articles/6075070-how-to-use-labels-in-the-system'>
                <AdminSettingsTitle text={t('Org labels')} />
            </HelpButtonTooltip>

            <Paper className={classes.paperStyle}>

                {content}
                <Button
                    className={classes.button}
                    color="primary"
                    variant="raised"
                    onClick={() => { this.setState({ modalOpen: !modalOpen }) }}>
                    {t('Create Organization Label')}
                </Button>

                <CreateNewModal t={t} open={modalOpen} handleClose={() => { this.setState({ modalOpen: false }) }} handleClickSave={this.save} />
                {!editLabel ? null : <EditLabelModal t={t} label={editLabel} open={editModalOpen} handleClose={() => { this.setState({ editModalOpen: false, editLabel: null }) }} handleClickSave={this.saveEdit} />}
            </Paper>
        </div>

    }
}

export const OrganizationLabels = (props) => {
    const [tDraft] = useTranslation('filterModal')
    return <_OrganizationLabels {...props} tDraft={tDraft} />
}
const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    deleteCertificationId: state.confirmDialog.deleteCertificationId,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('admin'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(OrganizationLabels);