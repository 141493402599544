import React from 'react';
import SocialDataContainer from './SocialDataContainer';

const SeenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="SeenIcon__a" d="M7 1.792c2.21 0 4.182 1.242 5.145 3.208C11.182 6.966 9.217 8.208 7 8.208 4.783 8.208 2.817 6.966 1.855 5 2.817 3.034 4.789 1.792 7 1.792M7 .625C4.083.625 1.593 2.439.583 5 1.593 7.56 4.083 9.375 7 9.375S12.408 7.561 13.417 5C12.407 2.44 9.917.625 7 .625zm0 2.917c.805 0 1.458.653 1.458 1.458 0 .805-.653 1.458-1.458 1.458-.805 0-1.458-.653-1.458-1.458 0-.805.653-1.458 1.458-1.458m0-1.167C5.553 2.375 4.375 3.553 4.375 5S5.553 7.625 7 7.625 9.625 6.447 9.625 5 8.447 2.375 7 2.375z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
                <mask id="SeenIcon__b" fill="#fff">
                    <use href="#SeenIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#SeenIcon__b)">
                    <path d="M0 0H14V14H0z" transform="translate(0 -2)" />
                </g>
            </g>
        </svg>
    )
}

class SocialDataSeen extends React.PureComponent {

    render() {
        const { seenCounter } = this.props

        return (
            <SocialDataContainer icon={<SeenIcon />} text={seenCounter} tooltipText='view' total={seenCounter} />
        )
    }
}

export default SocialDataSeen;