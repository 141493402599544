import { Component } from 'react';


class App extends Component {
  render() {
    return this.props.children
  }
}

// export default Sentry.withProfiler(App);
export default App;
