import { confirmReadingRequestActionTypes, updateDocumentMustReadActionTypes } from '@tuqqi/common'
import { LOCATION_CHANGE } from 'react-router-redux'
import { FULLVIEW_OPEN, FULLVIEW_OPEN_LOAD } from '../actionTypes/actionTypes'

export default function mixpanelMiddleware(mixpanel) {
    if (!mixpanel || !mixpanel.track) {
        throw new TypeError('You must provide a mixpanel client instance.')
    }

    return (store) => (next) => (action) => {

        if (window.location.hostname === 'app.tuqqi.com' || window.location.hostname === 'desktop.tuqqi.com') {
            try {
                const { type } = action
                let trackingData = {}                
                // Kanban
                if (type === LOCATION_CHANGE && action.payload.pathname === "/group/kanban") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("Kanban opened", trackingData)
                }
                //Calendar
                if (type === LOCATION_CHANGE && action.payload.pathname === "/group/calendar") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("Group calendar opened", trackingData)
                }
                if (type === LOCATION_CHANGE && action.payload.pathname === "/mypage/calendar") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("User calendar opened", trackingData)
                }
                //Map
                if (type === LOCATION_CHANGE && action.payload.pathname === "/group/map") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("Group map opened", trackingData)
                }
                if (type === LOCATION_CHANGE && action.payload.pathname === "/mypage/map") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("User map opened", trackingData)
                }
                // Chat
                if (type === LOCATION_CHANGE && action.payload.pathname === "/chat") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("Chat opened", trackingData)
                }
                // Search
                if (type === LOCATION_CHANGE && action.payload.pathname === "/search/all") {
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    trackingData.query = ""
                    mixpanel.track("User searched", trackingData)
                }
                // Open item
                if (type === FULLVIEW_OPEN || type === FULLVIEW_OPEN_LOAD) {
                    trackingData.action = type
                    trackingData.item = action.itemId
                    mixpanel.track("User openned an item", trackingData)
                }if (type === LOCATION_CHANGE){
                    trackingData.action = type
                    trackingData.pathname = action.payload.pathname
                    mixpanel.track("User openned - " + action.payload.pathname, trackingData)
                }
                // NEW UI
                if (type === 'ADD_CHECKLIST_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Add checklist", trackingData)
                }
                // Chat
                if (type === 'ADD_TASKS') {
                    trackingData.action = type

                    mixpanel.track("Web - Add tasks", trackingData)
                }
                // Search
                if (type === 'DELETE_DOCUMENT_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Delete document", trackingData)
                }
                if (type === 'UPDATE_DOCUMENT_MEMBERS_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Delete document", trackingData)
                }
                if (type === 'SAVE_ITEM_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Add document to favorites", trackingData)
                }
                if (type === 'CREATE_COMMENT_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Add comment", trackingData)
                }
                if (type === 'ADD_RELATED_DOCUMENT_REQUEST_SUCCESS') {
                    trackingData.action = type

                    mixpanel.track("Web - Add related document", trackingData)
                }
                if (type === 'UPDATE_DOCUMENT_SCHEDULE_REQUEST_SUCCESS') {


                    trackingData.action = type

                    mixpanel.track("Web - Due date changed", trackingData)
                }
                if(type === updateDocumentMustReadActionTypes.success){
                    trackingData.action = type
                    mixpanel.track("Web - Must read changed", trackingData)

                }
                if(type === updateDocumentMustReadActionTypes.success){
                    trackingData.action = type
                    mixpanel.track("Web - Must read changed", trackingData)

                }
                if(type === confirmReadingRequestActionTypes.success){
                    trackingData.action = type
                    mixpanel.track("Web - Confirmed reading (read and confirm)", trackingData)

                }else{
                    trackingData.action = type
                    mixpanel.track(type, trackingData)
                }



                
            }
            catch (error) {
            }
        }
        return next(action)
    }
}