import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Clear from '@material-ui/icons/Clear'
import { goTo } from '../../../../actions/goToActions'
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';

import { showSnackbar } from '../../../../actions/appActions'
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paper: {
        padding: 16
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paperStyle: {
        marginBottom: 40
    },
    newButton: {
        margin: '8px 22px 8px'
    },
});

class UserTagsSettings extends Component {

    state = {
        isLoading: true,
        open: false
    };

    componentDidMount() {
        this.getAll();
    }

    getAll = () => {
        this.props.adminService.getAllUserTags().then((userTags) => {
            this.setState({
                userTags: userTags,
                isLoading: false
            })
        })
    }

    handleClose = () => {
        this.setState({
            open: false, chipData: [], coll_description: '',
            coll_name: ''
        });
    }

    handleClickSave = () => {
        const { adminService, dispatch } = this.props;
        const { coll_name, coll_description } = this.state;

        var tag = {
            Tag: coll_name.replace(/[ ]/g, "_"),
            Description: coll_description
        }

        this.setState({ existingTagError: false })

        adminService.addUserTag(tag).then(() => {
            dispatch(showSnackbar('Saved!'));
            this.getAll();
        })

        dispatch(showSnackbar('Saving...'));

        this.handleClose();
    }

    onChange = name => event => {
        this.setState({ [name]: event.target.value });

        if (name === "coll_name") {
            if (event.target.value) {
                if (this.state.userTags.find(userTag => userTag.tag === event.target.value)) {
                    this.setState({ isTitle: false, existingTagError: true })
                }
                else {
                    this.setState({ isTitle: true, existingTagError: false });
                }
            }
            else {
                this.setState({ isTitle: false });
            }
        }
    }

    delete = userTag => {
        const { adminService, dispatch } = this.props;

        adminService.deleteUserTag(userTag.tag).then(() => {
            dispatch(showSnackbar('Deleted!'));
            this.getAll();
        })

        dispatch(showSnackbar('Deleting...'));
    }

    getModal = () => {
        const { t } = this.props
        const { coll_name, coll_description } = this.state;

        return <Dialog open={this.state.open} onRequestClose={this.handleClose}>
            <DialogTitle>{t('New User Tag')}</DialogTitle>
            {this.state.existingTagError
                ? <div data-intrcm-id="userTagsSettings_3flp8mcz" style={{ color: 'red', paddingLeft: 25 }}>
                    {t('The tag')} {coll_name} {t('is already exist')}
                    </div>
                : null}
            <DialogContent>
                <TextField fullWidth label={t("Name")} id="coll_name" InputLabelProps={{ shrink: true }} value={coll_name} margin="normal"
                    onChange={this.onChange("coll_name")} />
                <TextField fullWidth label={t("Description")} id="coll_description" InputLabelProps={{ shrink: true }} value={coll_description} margin="normal"
                    onChange={this.onChange("coll_description")} />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                {t('Cancel')}
                    </Button>
                <Button onClick={this.handleClickSave} color="primary" disabled={!this.state.isTitle}>
                {t('Save')}
                    </Button>
            </DialogActions>
        </Dialog>
    }

    render() {
        const { classes, dispatch,t } = this.props;
        const { userTags } = this.state;

        const spinner = <div data-intrcm-id="userTagsSettings_tapvqs1k" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>
        let content = spinner;

        if (!this.state.isLoading) {
            content = <List className={classes.listStyle}>
                {userTags.map((userTag, idx) => {
                    let secondary = <div data-intrcm-id="userTagsSettings_5d9jvy45"></div>

                    if (userTag.description && userTag.tag) {
                        secondary = <div data-intrcm-id="userTagsSettings_djn9iuo1">{userTag.description}<br />#{userTag.tag}</div>;
                    }
                    else {
                        if (userTag.description) secondary = <div data-intrcm-id="userTagsSettings_hwbegl4p">{userTag.description}</div>
                        else secondary = <div data-intrcm-id="userTagsSettings_uxr05hqz">#{userTag.tag}</div>
                    }

                    return <ListItem
                        divider
                        button
                        key={idx}
                        onClick={goTo('#' + userTag.tag, dispatch)}>
                        <Avatar>#</Avatar>
                        <ListItemText
                            primary={userTag.displayName + ' - ' + userTag.type + ' (' + userTag.count + ' items)'}
                            secondary={secondary} />
                        <ListItemSecondaryAction style={{ paddingRight: 16, cursor: 'pointer' }} onClick={() => this.delete(userTag)}>
                            <Clear />
                        </ListItemSecondaryAction>
                    </ListItem>
                })}
            </List>
        }

        return <div data-intrcm-id="userTagsSettings_4reib5e8" >
            <AdminSettingsTitle text={t('User Tags')} />
            <Paper className={classes.paperStyle}>
                {content}
                <Button color="primary" variant="raised" className={classes.newButton}
                    onClick={() => { this.setState({ open: true }) }} >
                    {t('New')}
                </Button>
            </Paper>
            {this.getModal()}
        </div>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), withTranslation('admin'),connect(mapStateToProps, mapDispatchToProps))(UserTagsSettings);
