export const ORG_ADMIN = "Admin"
export const ORG_CONTRIBUTOR = "Contributor"
export const ORG_VIEWER = "Viewer"

export function canUserAddItemToGroup(profileData, userCollections, collectionUid){
    if (isUserOrgViewer(profileData)) {
        return false
    } else if (userCollections.some(collection => collection.collectionUid === collectionUid)) {
        return true
    }

    return false
}

export function canUserDeleteItem(profileData, item) {
    if (!profileData || !item) {
        return false
    }

    if (isUserItemAuthor(profileData, item) ||
        isUserMemberOnItem(item, profileData) ||
        isUserOrgAdmin(profileData)) {
        return true
    }
}

export function canUserChangeMembers(profileData, collections, item) {
    if (!profileData || !collections || !item) {
        return false
    }

    if (isUserItemAuthor(profileData, item) ||
        isUserMemberOnItem(item, profileData) ||
        isUserOrgAdmin(profileData) ||
        isUserAdminOnGroupItem(item, collections, profileData) ||
        isUserHasPermissionOnGroupItem(item, collections)) {
        return true
    }
}

export function canUserEditItem(profileData, collections, item) {
    if (!profileData || !collections || !item) {
        return false
    }

    if (isUserItemAuthor(profileData, item) ||
        isUserMemberOnItem(item, profileData) ||
        isUserOrgAdmin(profileData) ||
        isUserAdminOnGroupItem(item, collections, profileData) ||
        isUserHasPermissionOnGroupItem(item, collections)) {
        return true
    }
}


export const canUserSetClassification = (userData, item) => {
    if (!userData || !userData.profileData || !item) {
        return false
    }

    const { profileData } = userData

    return (profileData.classification > 0) &&
        isUserItemAuthor(profileData, item)
}

export function isUserItemAuthor(profileData, item) {
    if (!profileData || !item) {
        return false
    }

    return profileData.auth0_id === item.userUUID
}

export const canUserChangeGroup = (userData, item) => {
    if (!userData || !userData.profileData || !userData.collections || !item) {
        return false
    }

    const { collections, profileData } = userData;

    return (isUserItemAuthor(profileData, item) ||
        isUserMemberOnItem(item, profileData) ||
        isUserHasPermissionOnGroupItem(item, collections) ||
        isUserAdminOnGroupItem(item, collections, profileData) ||
        isUserOrgAdmin(profileData))
}


export function isUserOrgAdmin(profileData) {
    if (!profileData) {
        return false
    }

    return profileData.role === ORG_ADMIN
}

export function isUserOrgViewer(profileData) {
    if (!profileData) {
        return false
    }

    return profileData.role === ORG_VIEWER
}

export function isUserMemberOnItem(document, profileData) {
    if (!document || !document.members || !profileData) {
        return false
    }

    return document.members && document.members.find(member => member.userUUID === profileData.auth0_id)
}


export function getGroupByItem(doc, userCollections) {
    if (!doc || !userCollections) {
        return null
    }

    let group = null

    if (doc.groupUid) {
        const itemCollection = userCollections.find(c => c.collectionGroup.groupUid === doc.groupUid)

        group = itemCollection && itemCollection.collectionGroup
    }

    return group
}

export function isUserHasPermissionOnGroupItem(doc, userCollections) {
    if (!doc || !userCollections) {
        return false
    }

    const group = getGroupByItem(doc, userCollections)
    const allowed = group && group.isEnableMembersAsAuthors

    return allowed
}

export function isUserAdminOnGroupItem(doc, userCollections, profileData) {
    if (!doc || !userCollections || !profileData) {
        return false
    }

    const group = getGroupByItem(doc, userCollections)
    const allowed = group && group.adminIds.includes(profileData.id)

    return allowed
}


export function canUserChangeEveryoneCanEdit(profileData, item) {
    if (!profileData || !item) {
        return false
    }

    if (isUserItemAuthor(profileData, item) ||
        isUserMemberOnItem(item, profileData)) {
        return true
    }
}