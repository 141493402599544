import React from 'react'
import CommentText from './CommentText'
import DataTypeTag from './DataTypeTag'
import DataTypeTagLinked from './DataTypeTagLinked'
import DataTypeTitle from './DataTypeTitle'
import DocTitle from './DocTitle'
import GroupTitle from './GroupTitle'
import ImgGroup from './ImgGroup'
import MainImg from './MainImg'
import GroupTitleCollection from './GroupTitleCollection'
import StaticImg from './StaticImg'
import StaticText from './StaticText'
import UserAvatar from './UserAvatar'
import UserSignature from './UserSignature'
import MoveItemStatusText from './MoveItemStatusText'
import DataTypeTitleLinked from './DataTypeTitleLinked'
import MainImgLinked from './MainImgLinked'
import DocTitleLinked from './DocTitleLinked'

export const useResolveRunTimeApplies = (currentScene) => {
    if(!currentScene || currentScene.id === -1) return [null]
    const RunTimeApplies = Object
        .entries(currentScene.sceneStyles)
        .map(([key, style]) => {
            switch (key) {
                case 'dataType':
                    return <DataTypeTag dataType={currentScene.dataType} styles={style} />
                case 'dataTypeTag':
                    return <DataTypeTag dataType={currentScene.dataType} styles={style} tag/>
                case 'dataTypeTagLinked':
                    return <DataTypeTagLinked currentScene={currentScene} styles={style}/>
                case 'dataTypeTitle':
                    return <DataTypeTitle dataType={currentScene.dataType} styles={style} />
                case 'dataTypeTitleLinked':
                    return <DataTypeTitleLinked  currentScene={currentScene} styles={style} />
                case 'mainImg':
                    return <MainImg docId={currentScene.docId} eventType={currentScene.systemActionTypeUid} groupType={currentScene.groupType} styles={style} />
                case 'mainImgLinked': 
                    return <MainImgLinked name={key} currentScene={currentScene} styles={style} />
                case 'docTitle':
                    return <DocTitle docId={currentScene.docId} styles={style}/>
                case 'docTitleLinked':
                    return <DocTitleLinked currentScene={currentScene} styles={style}/>
                case 'completedChecklistOption':
                    return <DocTitle docId={currentScene.docId} styles={style} currentScene={currentScene} completedChecklistOption={true}/>
                case 'avatar':
                    return <UserAvatar docId={currentScene.docId} styles={style}/>
                case 'secondaryImg':
                    return <UserSignature styles={style}/>
                case 'commentText':
                    return <CommentText styles={style}/>
                case 'taskDoneCheck':
                case 'dudeStatic':
                case 'commentStaticImgCloud':
                case 'workInProgressImg':
                case 'groupCommentImg':
                    return <StaticImg eventType={currentScene.systemActionTypeUid} styles={style} option={key}/>
                case 'commentStaticText':
                case 'addTaskStatic':
                case 'staticNeedToRead':
                case 'staticPleaseConfirm':
                case 'staticConfirmed':
                case 'staticConfirmedSecondary':
                case 'staticTextNewItemGroup':
                    return <StaticText styles={style} option={key}/>
                case 'groupTitle':
                    return <GroupTitle currentScene={currentScene} styles={style}/>
                case 'groupTitleCollection':
                    return <GroupTitleCollection currentScene={currentScene} styles={style}/>
                case 'groupImg':
                    return <ImgGroup name={key} currentScene={currentScene} styles={style} />
                case 'moveTextFromStatus':
                    return <MoveItemStatusText styles={style} from/>
                case 'moveTextToStatus':
                    return <MoveItemStatusText styles={style} to/>
                default:
                    return null
            }
        })
    return [RunTimeApplies]
}