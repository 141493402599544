import { Divider, Icon, ListItem, ListItemAvatar, ListItemIcon, ListItemText, withStyles, Avatar, Grid, IconButton } from '@material-ui/core';
import { VideoCall } from '@material-ui/icons/'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ChatUsersDialog from './ChatUsersDialog';
import { getFaces } from './chatUtils';
import goTo from '../../actions/goTo'
import UserAvatar from '../../components/Common/UserAvatar';
import ChatHeaderMenu from './ChatHeaderMenu';
import { showVideoChat } from '../../../actions/appActions';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import { withOpenFullview } from '../../../new-ui/withOpenFullview';
import { managerTableKeepGlobalData, openFullview } from '@tuqqi/web';
import groupPlaceholder from "../../../assets/img/group_placeholder.png";
import { parseImageUrl } from '@tuqqi/common';
import { addRecentItem } from '../../../actions/searchNavigationActions';
import goToCollection from '../../actions/goToCollection';

const styles = theme => ({
    groupAvatar: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    mainContainer: {
        height: 85,
        display: 'flex',
        alignItems: 'center'
    },
    listIcon: {
        marginRight: 0
    },
    icon: {
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 6,
        boxShadow: '0 1px 6px 0 rgba(143, 100, 223, 0.31)',
        fontSize: 25,
        color: '#6200ee'
    },
    chatTitle: {
        fontSize: 15,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1,
        letterSpacing: 0.1,
        color: 'rgba(0,0,0, 0.54)',
        paddingTop: 3,
        flexDirection: 'row',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    avatar: {
        width: 32,
        height: 32,
        marginLeft: -8
    },
    firstAvatar: {
        zIndex: 1,
        width: 32,
        height: 32,
        marginLeft: -8,
        border: 'solid 2px white',
        marginTop: -2
    },
    documentFaces: {
        marginTop: 8, marginLeft: 4, cursor: 'pointer', display: 'flex', flexDirection: 'row'
    },
    moreAvatars: {
        width: 32,
        height: 32,
        background: theme.customColors.primary.b100,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: -6,
        color: 'rgba(181, 0, 255,1)',
        fontSize: 12,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        lineHeight: 1.33,
        fontStretch: 'normal',
        letterSpacing: 0.4,
    },
    startVideoIcon: {
        color: theme.customColors.primary.main
    }
})

const defaultBackground = groupPlaceholder;
const parseLink = (link, cloudfront) => {
    if(!link || !cloudfront) return defaultBackground
    return parseImageUrl(cloudfront)(link) || defaultBackground
}

class ChatHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            docUsers: []
        }
    }

    renderDocumentFaces = (tagedUsers) => {
        const { classes } = this.props;

        return <div data-intrcm-id="ChatHeader_0dv1tfwd" style={{ display: 'flex' }}>
            {tagedUsers.slice(0, 2).map((tagedUser, idx) => {
                return <Avatar key={idx} alt={tagedUser.fullName} src={tagedUser.imageUrl} className={idx === 0 ? classes.firstAvatar : classes.avatar} />
            })}
            {tagedUsers.length - 2 > 0 && <div data-intrcm-id="ChatHeader_xhqqykou" className={classes.moreAvatars}>{`+${tagedUsers.length - 2}`}</div>}
        </div>
    }

    openUsers = () => this.setState({ docMembersDialogOpen: true })
    closeUsers = () => this.setState({ docMembersDialogOpen: false })

    openNewChat = (userUId) => {
        const { closeAddChatModal, openChat } = this.props;

        this.setState({ isLoading: true })

        this.props.chatService.openNewChat(userUId).then(res => {
            this.setState({ isLoading: false });
            closeAddChatModal(res)
            this.closeUsers();

            openChat(res)
        })
    }

    openItem = () => {
        const { docId, dispatch } = this.props
        dispatch(openFullview(docId, false))
        dispatch(addRecentItem(docId))

    }

    openVideoChat = () => {
        const { title, dispatch, chatId } = this.props;
        dispatch(showVideoChat(chatId, title));
    }
    onClickTitle = () => {
        const isGM = this.props.isGroupChat && this.props.chatId
        if(isGM) {
            const chatUId = this.props.chatId
            const group = !chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chatUId)
            goToCollection('/' + group.title, this.props.dispatch, group.collectionUid, group.collectionGroup.groupUid, group)();
            return
        }
        const {document} = this.props
        if(!document || Object.keys(document).length === 0) return
        this.openItem()
    }

    renderGroup = () => {
        const chatUId = this.props.chatId
        const col = !chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chatUId)
        let url = defaultBackground

        if(col?.backgroundUrl && managerTableKeepGlobalData.getCloudfront()) {
            url = parseLink(col?.backgroundUrl, managerTableKeepGlobalData.getCloudfront())
        }

        return <div data-intrcm-id="ChatListItemLeftElement_fnc0jfhq" style={{ marginLeft: 0, marginRight: 0 }}  >
            <img alt={`group-image-${url}`} src={url} className={this.props.classes.groupAvatar} />
        </div>
    }

    getGroupTitle = () => {
        const chatUId = this.props.chatId
        const col = !chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chatUId)
        return col?.title ?? ''   
    }

    render() {
        const { userProfile, title, classes, document, users, cloudfront, dispatch } = this.props;
        const tagedUsers = document && Object.keys(document).length > 0 ? getFaces(document, users, cloudfront) : []
        const isDiscussions = tagedUsers.length > 0
        const isGM = this.props.isGroupChat && this.props.chatId
        
        return <div data-intrcm-id="ChatHeader_xtjur20e" style={{ flex: '0 0 auto' }}>
            <Grid className={classes.mainContainer} container justify="space-between">
                <Grid item xs={8}>
                    <ListItem style={{flexDirection: 'row', alignItems: 'center', marginLeft: 12}}>
                        {isGM ? 
                        this.renderGroup() :
                        isDiscussions
                            ? <div data-intrcm-id="ChatHeader_wbwvirl3" className={classes.documentFaces} onClick={this.openUsers} >
                                {this.renderDocumentFaces(tagedUsers)}
                              </div>
                            : userProfile
                                ? <ListItemAvatar>
                                    <UserAvatar userUUID={userProfile.auth0_id} withShadow={false} />
                                </ListItemAvatar>
                                : null
                        }
                        <ListItemText style={{cursor: isDiscussions || isGM ? 'pointer' : 'unset'}} onClick={this.onClickTitle} dir='auto' classes={{ primary: classes.chatTitle }} primary={isGM ? this.getGroupTitle() : title} />
                    </ListItem>
                </Grid>
                <Grid item xs={4}>
                    <div data-intrcm-id="ChatHeader_823a9oru" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div data-intrcm-id="ChatHeader_4mv4phn0" style={{ marginTop: 4 }}>
                            <IconButton aria-label="Start video call"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className={classes.startVideoIcon}
                                onClick={this.openVideoChat}>
                                <VideoCall />
                            </IconButton>
                            <ChatHeaderMenu isGM={isGM} openItem={document ? this.openItem : goTo('@' + userProfile.tag, dispatch)} isUser={!document} />
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Divider />
            <ChatUsersDialog
                title={`Users in the disscusion (${tagedUsers.length})`}
                open={this.state.docMembersDialogOpen}
                onClose={this.closeUsers}
                usersToShow={tagedUsers}
                listItemOnClick={(user) => goTo('@' + user.tag, dispatch)()}
                rightElementOnClick={user => this.openNewChat(user.auth0_id)}
                getSecondaryText={user => user.title}
                disabled={false}
                isButton={true}
                isLoading={this.state.isLoading}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    inputMappings: state.globalData.inputMappings,
    users: state.globalData.activeUsers,
    cloudfront: state.globalData.cloudfront,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withErrorCatcher(),
    withOpenFullview,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatHeader);