import { withStyles } from '@material-ui/core';
import i18n from 'i18next';
import moment from "moment";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../actions/appActions';
import { updateUserProfile } from '../../../actions/userAction.js';
import { FirstLoginDialog, NewUiWrapper } from '@tuqqi/web';
import { store } from '../../..';
import { parseImageUrl } from '@tuqqi/common';

const styles = theme => ({
});

class UserProfileModal extends Component {
    fixImage(cloudfront, user) {
        var parse = parseImageUrl(cloudfront);

        if (user && user.profileImageUrl) {
            user.profileImageUrl = parse(user.profileImageUrl)
        }
    }

    handleClickSave = (userData) => {
        const { profileData, userSettingsService, dispatch, cloudfront, t } = this.props;

        const { firstname, lastname, userName, birthday, profileImageUrl, lang } = userData;
        if(!firstname || !lastname || !lang || !userName) return
        dispatch(showSnackbar(t('Saving')));

        const newData = {
            birthday,
            firstname,
            lastname,
            lang,
            tag: userName,
            title: profileData?.title ?? '',
            role: profileData?.role ?? 'Contributor',
            profileImageUrl
        }

        userSettingsService.editUserSetting(newData)
            .then((res) => {
                const updatedData = {...newData, status: newData?.status ?? 0}

                if (!res.ok) {
                    dispatch(showSnackbar(t('Error occured while try to saving your change')));
                } else {
                    i18n.changeLanguage(lang)
                    moment.locale(lang)

                    this.fixImage(cloudfront, updatedData)
                    dispatch(updateUserProfile(updatedData));

                    dispatch(showSnackbar(t('Saved!')));

                    this.props.onUserProfileDataUpdate();
                }
            })
    }

    render() {
        const { open, profileData } = this.props;

        const callbacks = {
            onSaveUserFirstLogin: this.handleClickSave,
        }

        if(!open){
            return null;
        }

        return <NewUiWrapper store={store} callbacks={callbacks}>
            <FirstLoginDialog initUser={profileData ?? {}} />
        </NewUiWrapper>
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    profileData: state.userData.profileData,
    userSettingsService: state.services.userSettingsService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withStyles(styles),
    withTranslation('userEditModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(UserProfileModal);