import * as React from 'react'
import { getRowLaneStatus } from '../../../../../../../../utils/actionsCenter/getItemLaneStatus';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getDueDate, getDueDateStyling, parseImageUrl, StatusColorMap } from '@tuqqi/common';
import DueDateIcon from '../../../../../../Common/Icons/ActionCenter/DueDateIcon';
import BirthdayIcon from '../../../../../../Common/Icons/ActionCenter/BirthdayIcon';
import { openFullview, withGetDataTypeName } from '@tuqqi/web';
import { useTranslation } from 'react-i18next';
import ItemActions from './ItemActions';
import { Tooltip, withStyles } from '@material-ui/core';
import moment from 'moment';
import goTo from '../../../../../../../actions/goTo';

function isTruncated(el) {
    if (!el) return false
    return el.scrollHeight > el.clientHeight
}

const _styles = (theme) => ({
    tooltip: {
        fontStyle: 'normal',
        fontfamily: 'Rubik',
        fontWeight: 400,
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 12,
    },
    container: {
        padding: '8px 12px',
        width: 250,
        marginRight: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#FBFBFC',
            borderRadius: 6
        },
        justifyContent: 'space-between',
    },
    indicator: {
        display: 'flex',
        flexDirection: 'column',
        width: 3,
        alignSelf: 'normal',
        height: 'inherit',
        marginRight: 8,
        marginLeft: 0,
        borderRadius: 4
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        width: '100%',
        marginLeft: 0,
    },
    titleText: {
        color: '#18181B',
        marginRight: 12,
        // letterSpacing: 0.4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: '20px',
        
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'break-spaces',
        cursor: 'pointer',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 3,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        marginTop:2
    },
    row3: {
        display: 'flex',
        flexDirection: 'row',
        // marginTop:4
    },
    status: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    groupNameText: {
        color: "#18181B",
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'break-spaces',
        cursor: 'pointer',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 3,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        lineHeight: '20px',
        // maxWidth: 140,
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: 'normal',
        padding:"2px 6px 2px 0px",
    },
    groupNameTextStatus: {
        color: "#18181B",
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'break-spaces',
        cursor: 'pointer',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 3,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        lineHeight: '20px',
        // maxWidth: 140,
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: 'normal',
        padding:"2px 6px 2px 0px",
    },
    statusText: {
        color: "rgba(0,0,0,0.67)",
        padding:"2px 6px 2px 6px",
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 13,
        lineHeight: '16px',
        
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        borderRadius: 5
    },
    mustReadText: {
        color: '#4A4A4F',
        
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 13,
        lineHeight: '16px',
    },
    infoContainer: {
        display: 'flex',
        
        flexDirection: 'row',
        alignItems: 'center',
    },
    dateText: {
        color: '#4A4A4F',
        whiteSpace: 'noWrap',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 13,
        lineHeight: '16px',
        marginRight:12
        
    },
    imgUser: {
        borderRadius: 8,
        width: 16,
        minWidth: 16,
        height: 16,
        marginRight: 4,
        marginTop: -1,
        background: '#EDEDF0',
    },
    mustRead: {
        
        // backgroundColor: 'rgba(123, 79, 240, 0.12)',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    titles: {
        display: 'flex',
    }

});

function _ItemTask(props) {
    const { view, item, usersCollections, openCollections, dispatch, classes: styles, cloudfront, getDataTypeName } = props
    const [t] = useTranslation("actionCenter")
    const [isHover, setIsHover] = React.useState(false)
    const [isOpenedMore, setIsOpenedMore] = React.useState(false)

    const subFooterRef = React.useRef(null)
    const [textEl, setTextEl] = React.useState()
    const elId = `task-item-id${item.docId}`
    const isUserBd = !!item.isUser
    const parseUrl = parseImageUrl(cloudfront.data);









    const datatypeName = getDataTypeName(item?.dataType ?? "");

    const onHoverTitle = () => {
        const x = document.getElementById(elId)
        setTextEl(x)
    }

    const onOpenedMore = () => {
        setIsOpenedMore(true)
    }

    const onClosedMore = () => {
        setIsOpenedMore(false)
        setIsHover(false)
    }

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        if (isOpenedMore) return
        setIsHover(false)
    }


    const onTitle = React.useCallback(() => {
        if (isUserBd) {
            if (!item.tag) return
            const userTag = '@' + item.tag

            goTo(userTag, dispatch)()
            return
        }
        if (!item.docId) return
        dispatch(openFullview(item.docId, true))
    }, [item.docId, isUserBd, item?.tag])

    let heightClient = 56
    if (!!subFooterRef.current) {
        const x = subFooterRef.current.clientHeight || 56
        heightClient = x
    }

    const getCollectionByGroupUid = React.useCallback((groupUid) => {
        if (isUserBd) return [false, {}, false]
        const userCollection = usersCollections.find(col => col.collectionGroup.groupUid === groupUid)
        if (!!userCollection) return [true, userCollection, true]
        const openCollection = openCollections.find(col => col.collectionGroup.groupUid === groupUid)
        if (!!openCollection) return [true, openCollection, false]

        return [false, {}, false]
    }, [usersCollections, openCollections, isUserBd])

    const [exists, coll] = React.useMemo(() => getCollectionByGroupUid(item.groupUid ?? ''), [item.groupUid])

    const laneStatus = React.useMemo(() => {
        return getRowLaneStatus(item, { withGroupName: true, openCollections: openCollections, usersCollections: usersCollections, view: view })
    }, [item, openCollections, usersCollections, view])

    const dueDate = React.useMemo(() => getDueDate(item, 'MMM DD'), [item])

    const dueDateStyle = React.useMemo(() => {
        const x = getDueDateStyling(item.dueDateUTC, item.taskDueDateDone)
        if (x.backgroundColor === '#e3e3e3') x.backgroundColor = '#1B1E2A'
        return x
    }, [item])

    const [mustReadUsersApproved, mustReadUsers] = React.useMemo(() => [item.mustReadUsersApproved?.length ?? 0, item.mustReadUsers?.length ?? 0], [item])

    const backgroundColor = React.useMemo(() => {
        if (isUserBd) return 'rgb(151, 154, 170)'

        return laneStatus.color === 0 ? '#979aaa' : StatusColorMap[laneStatus.color]
    }, [laneStatus.color, isUserBd])

    const statusMaxWidth = React.useMemo(() => {
        if (isUserBd) return 125

        if (view === 'mustRead') {
            if (!dueDate) return 125
            if (mustReadUsers < 10 && mustReadUsersApproved < 10) return 85
            if (mustReadUsers < 100 && mustReadUsersApproved < 100) return 75
            return 65
        }
        return 125
    }, [view, dueDate, mustReadUsersApproved, mustReadUsers, isUserBd])


    const Title = isUserBd ? <div style={{width: 4}} /> : <div data-intrcm-id="ItemTask_plmyrh0s" id={elId} onMouseMove={onHoverTitle} dir='auto' className={styles.titleText}>{datatypeName || "Task"}</div>

    const renderDateDivider = () => {
        if (!isUserBd && dueDate) return <div data-intrcm-id="ItemTask_7otnakuj" className={styles.infoContainer}>

            <div data-intrcm-id="ItemTask_fhm3azzc" className={styles.dateText} style={{ color: dueDateStyle.backgroundColor }}>{"⏱️" + " " +dueDate}</div>
        </div>
        if (!!isUserBd && item.birthday) return <div data-intrcm-id="ItemTask_t6audgi9" className={styles.infoContainer}>
            {/* <BirthdayIcon color={'#1B1E2A'} /> */}

            <div data-intrcm-id="ItemTask_ft5r4c7v" className={styles.dateText} style={{ color: '#1B1E2A' }}>{"🥳" + " "+ dueDate}</div>
        </div>
        return null
    }

    const renderImgUser = () => {
        if (!isUserBd) return null
        if (!item.profileImageUrl || !parseImageUrl(item.profileImageUrl)) return <div data-intrcm-id="ItemTask_1su9bizu" className={styles.imgUser} />
        return <img className={styles.imgUser} alt="user" src={parseUrl(item.profileImageUrl)} />
    }

    const tooltipText = !isUserBd ? item.title || 'Untitled' : moment(item.birthday).format("MMMM Do")


    const TitleT = !!isUserBd || isTruncated(textEl) ?
    <Tooltip classes={{ tooltip: styles.tooltip }} title={tooltipText} placement="top">
        {Title}
    </Tooltip> :
    Title


    const Row1 = <div data-intrcm-id="ItemTask_h38p9of8" className={styles.row1}>
        {renderImgUser()}
        <div className={styles.titles}>
            {TitleT}
            {renderDateDivider()}
            {!isUserBd && view === 'mustRead' && <div data-intrcm-id="ItemTask_bfdto2f1" className={styles.mustRead}>
            <div data-intrcm-id="ItemTask_ea0nyq39" className={styles.mustReadText}>{`✍️  ${mustReadUsersApproved}/${mustReadUsers}`}</div>
        </div>}
        </div>
    </div>

    const laneStr = isUserBd ? t("Birthday") : laneStatus.title


    const Row2 = <div data-intrcm-id="ItemTask_5f6p88wu" className={styles.row2}>
        <div data-intrcm-id="ItemTask_r5hpjo6k" className={styles.status}>
            {/* <div data-intrcm-id="ItemTask_8xeodorf" dir='auto' className={styles.groupNameText} >{laneStr}</div> */}
            <div data-intrcm-id="ItemTask_8xeodorf" dir='auto' className={styles.groupNameText} >{item.title || 'Untitled'}</div>
        </div>        
    </div>

    const Row3 = <div data-intrcm-id="ItemTask_d3ssp99of8" className={styles.row3}>
        <div data-intrcm-id="ItemTask_8xeodorf" dir='auto' style={{color: backgroundColor}} className={styles.groupNameTextStatus} >{`${laneStr}`}</div>

    </div>

    const Content = <div data-intrcm-id="ItemTask_vqb3ieh4" onClick={onTitle} className={styles.content}>
        {Row1}
        {Row2}
        {Row3}

    </div>

    return <div data-intrcm-id="ItemTask_kehv90y3" ref={subFooterRef} className={styles.container} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {/* {ColorLane} */}


        {Content}
        {!isUserBd && !!isHover && <ItemActions isPX onCloseMenu={onClosedMore} onOpenMenu={onOpenedMore} onTitle={onTitle} wrapHeight={heightClient} item={item} exists={exists} coll={coll} actionCenterView={view} />}
    </div>
}

const mapStateToProps = (state) => ({
    cloudfront: state.data.globalData.cloudfront,
    usersCollections: state.data.userData.userCollections?.data ?? [],
    openCollections: state.data.globalData.openCollections?.data ?? [],
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export const ItemTask = compose(
    withGetDataTypeName(),
    withStyles(_styles, { withTheme: true }),
    connect(mapStateToProps, mapDispatchToProps),
)(_ItemTask);