import { managerAggregationsForDataType } from "@tuqqi/web";
import { selectDrawerNavigationOption } from "../actions/drawerNavigationActions";
import { groupContext } from "./drawerNavigation/groupDrawerNavigationHelpers";
import { goToPath } from "./drawerNavigation/navigationHelpers";

let showOldTable = false

export const setShowOldTable = (val, dispatch) => {
    showOldTable = val
    dispatch(selectDrawerNavigationOption(groupContext.home.id))
    goToPath(groupContext.home.pathname, dispatch)
}
export const useNewTable = (orgUid) => {
    if(showOldTable) return false

    return orgUid === "companyx_2" || !managerAggregationsForDataType.isWrongVersion()
}

export const useNewTableOrig = (orgUid) => {
    return orgUid === "companyx_2" || !managerAggregationsForDataType.isWrongVersion()
}
export const useNewActionCenter = (orgUid) => {
    return useNewTable(orgUid)
}