import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { hexToRGB } from '../../../../utils/hexToRGB';
import ChatIcon from "../../Common/Icons/ChatIcon";
import withErrorCatcher from '../../Common/withErrorCatcher';
import MesagePoint from './MesagePoint';
import SvgIcon from '@material-ui/core/SvgIcon';



// Apply some reset
const styles = theme => {
    const whiteOpacity016 = hexToRGB('#FFFFFF', 0.16)

    return ({
        chatPoint: {
            bottom: 20,
            left: 23
        },

        chatButton: {
            margin: 0,
            padding:0,
            
            "&:hover": {
                backgroundColor: 'inherit',
            },
        },

        chatIconContainer: {
            height: 32,
        width:32,
            borderRadius: 16,
            "&:hover": {
                backgroundColor: whiteOpacity016,
            },
        },
        icon : {
            color:"rgba(255,255,255,0.87)",
            height:18,
        width:18
        }
    })
}

const Icon = () => {
    return <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.6C0 6.23969 0 4.55953 0.653961 3.27606C1.2292 2.14708 2.14708 1.2292 3.27606 0.653961C4.55953 0 6.23969 0 9.6 0H22.4C25.7603 0 27.4405 0 28.7239 0.653961C29.8529 1.2292 30.7708 2.14708 31.346 3.27606C32 4.55953 32 6.23969 32 9.6V18.4C32 21.7603 32 23.4405 31.346 24.7239C30.7708 25.8529 29.8529 26.7708 28.7239 27.346C27.4405 28 25.7603 28 22.4 28H9.6C6.23969 28 4.55953 28 3.27606 27.346C2.14708 26.7708 1.2292 25.8529 0.653961 24.7239C0 23.4405 0 21.7603 0 18.4V9.6Z" fill="#B7B8BD" fill-opacity="0.07"/>
    <path d="M13.1457 19.1764C13.33 18.9679 13.6233 18.894 13.8844 18.9901C14.6163 19.2595 15.4068 19.4 16.225 19.4C19.6201 19.4 22.3 16.9757 22.3 14C22.3 11.0243 19.6201 8.6 16.225 8.6C12.83 8.6 10.15 11.0243 10.15 14C10.15 15.0897 10.5094 16.1302 11.1755 17.0141C11.271 17.1408 11.3188 17.297 11.3105 17.4554C11.2752 18.1356 10.9483 19.0619 10.3347 20.2764C11.6696 20.1725 12.5974 19.7963 13.1457 19.1764ZM9.9179 21.6503C9.72998 21.656 9.54381 21.6127 9.37767 21.5248C8.84857 21.2446 8.64676 20.5886 8.92691 20.0595C9.50641 18.965 9.84134 18.1335 9.936 17.6031C9.19905 16.5369 8.80005 15.2959 8.80005 14C8.80005 10.2469 12.113 7.25 16.225 7.25C20.3371 7.25 23.65 10.2469 23.65 14C23.65 17.7531 20.3371 20.75 16.225 20.75C15.3988 20.75 14.5932 20.6295 13.8313 20.3962C12.9342 21.1895 11.621 21.5985 9.9179 21.6503Z" fill="white" fill-opacity="0.67"/>
    </svg>    
}

class ChatToolbar extends PureComponent {

    onChatClick = () => {
        const { dispatch, pathname } = this.props;
        if (pathname.includes('chat') && !pathname.includes('group/chat')) return
        dispatch({ type: 'TOGGLE_CHATS_MODAL' })
    }

    render() {
        const { classes, t } = this.props;

        return (
            <Tooltip title={t('Chat')} placement="bottom">
                <IconButton data-intrcm-id="ChatToolbar_ip44mnto" classes={{ label: classes.chatIconContainer, root: classes.chatButton }} aria-label="Chat" onClick={this.onChatClick}>
                    <MesagePoint classes={{ point: classes.chatPoint }} />
                    {/* <ChatIcon /> */}
                    <Icon />
                </IconButton>
            </Tooltip>

        )
    }
}

const mapStateToProps = (state) => ({
    lastEnterChat: state.userData.profileData.lastEnterChat,
    pathname: state.wrapper.pathname,
    profileData: state.userData.profileData
})

export default compose(withStyles(styles),
    withErrorCatcher(),
    withTranslation('feedToolbar'),
    connect(mapStateToProps))(ChatToolbar);