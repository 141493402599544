import { Grid, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import MenuButton from "../MenuButton";
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';

const styles = theme => ({
    container: {
        width: 'initial',
        marginRight: 32
    },
    menuTitle: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    menuButton: {
        "borderRadius": "4px",
        "backgroundColor": theme.newUI.color.gray50,
        "flex": "0 0 auto",
        "padding": "5px 1px 4px 12px",
        "width": "initial"
    },
    selectedValue: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "textAlign": "right",
        "color": theme.newUI.color.commonBlack
    },
    spaceBetweenItems: {
        width: 8
    }

})

class RowPerPage extends Component {
    state = {
        anchorEl: null,
        openMenu: false,
    }

    togglrOpenMenu = (event) => {
        this.setState({
            openMenu: !this.state.openMenu,
            anchorEl: event && event.target
        })
    }

    onChoose = (selected) => () => {
        this.togglrOpenMenu(null)
        this.props.onRowsPerPageChange(selected)
    }
    rowsPerPageOptions = [25, 50, 100, 1000]
    render() {
        const { classes, rowsPerPage, t } = this.props;
        const { anchorEl, openMenu } = this.state;

        return (
            <Fragment>
                <Grid dir='auto' item container className={classes.container} wrap='nowrap' alignItems='center'>
                    <span className={classes.menuTitle}>{`${t("Rows per page")}: `}</span>
                    <div data-intrcm-id="RowPerPage_umrlgcvu" className={classes.spaceBetweenItems}/>
                    <MenuButton
                        onClick={this.togglrOpenMenu}
                        classes={{ button: classes.menuButton, selectedValue: classes.selectedValue }}
                        selectedValue={rowsPerPage} />
                </Grid>

                <Menu
                    onClose={this.togglrOpenMenu}
                    open={openMenu}
                    anchorEl={anchorEl}
                >
                    {
                        this.rowsPerPageOptions.map(option => (
                            <MenuItem onClick={this.onChoose(option)} value={option}>
                                {option}
                            </MenuItem>
                        ))
                    }
                </Menu>

            </Fragment>)

    }

}


const mapStateToProps = (state, ownProps) => ({
    searchQuery: state.search

})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('rowPerPageTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(RowPerPage);