import { Button, DialogActions, DialogContent, Divider, TextField, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher';
import DisableScreen from '../../FeedViewComponents/ItemPreviewComponents/Menu/LabelMenu/DisableScreen';
import ChecklistRuleTemplate from './ChecklistRuleTemplate';
import MemberRuleTemplate from './MemberRuleTemplate';
import ListRulesSelect from './ListRulesSelect';
import { deleteRule, initRule, saveRule } from './ListRulesUtils';
import { DELETE_LANE_RULE } from '../../../../actionTypes/actionTypes';
import { showConfirm } from '../../../../actions/appActions';
import LabelRuleTemplate from './LabelRuleTemplate';
import { withMixpanel } from '../../Common/withMixpanel';
import IntegrationRuleTemplate from './IntegrationRuleTemplate';

const styles = theme => ({
    container: { backgroundColor: '#ffffff', width: 600 },
    titleText: {
        color: '#21212D',
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 500
    },
    progress: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        top: 'calc(50% - 40px)',
        left: 'calc(50% - 40px)',
    },
    formControl: {
        paddingTop: 16,
        paddingBottom: 16,
        flexDirection: 'row',
        alignItems: 'center',
    }
});

const incoming = "incoming";
const outgoing = 'outgoing';

class ListRuleModal extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    componentDidUpdate(prevProps) {
        const { collectionListRulesService, rule, list, deleteRuleid, dispatch } = this.props;
        const prevDeleteRuleid = prevProps.deleteRuleid;

        if (rule && rule.id) {
            if ((prevProps.rule && rule.id !== prevProps.rule.id) || !prevProps.rule) {
                this.setState({ isLoading: true })

                collectionListRulesService.getRuleData(rule.id, list.id).then(res => {
                    if (res.ok) {
                        res.json().then(rule => {
                            const updatedRule = initRule(rule);
                            this.setState({ data: updatedRule.data })
                        })
                    }

                    this.setState({ isLoading: false })
                })
            }

            if (prevDeleteRuleid !== deleteRuleid && deleteRuleid === rule.id) {
                dispatch({ type: DELETE_LANE_RULE, data: { deleteRuleid: '' } })
                deleteRule(this.props);
            }
        }
        // TODO: need to fix better!
        if (this.props.open) {
            document.body.style.overflow = 'auto';
        }
    }

    getRuleTitle = (triggerType) => {
        const { t } = this.props;

        switch (triggerType) {
            case incoming: return t("Rule for incoming items")
            // case outgoing: return t("Rule for outgoing items")
        }
    }

    onChange = (name) => (event) => {
        const value = event.target.value;
        if (value.length < 72) {
            this.setState({ [name]: value })
        }


    }

    updateData = (data) => {
        this.setState({ data: { ...data } })
    }

    renderContent = () => {
        const { ruleType, data } = this.state;

        switch (ruleType) {
            case 'checklist':
                const checklist = data ? data : { title: this.props.t('Checklist'), tasks: [] }
                return <ChecklistRuleTemplate checklist={checklist} updateData={this.updateData} />
            case 'member':
                const membersData = data ? data : { members: [] }
                return <MemberRuleTemplate data={membersData} updateData={this.updateData} />

            case 'setMembers':
                const memberSetData = data ? data : { members: [] }
                return <MemberRuleTemplate data={memberSetData} updateData={this.updateData} />

            case 'label':
                const labelsData = data ? data : { labels: [] }
                return <LabelRuleTemplate data={labelsData} updateData={this.updateData} />
            case 'mustread':
                const mustReadData = data ? data : { members: [] }
                return <MemberRuleTemplate data={mustReadData} updateData={this.updateData} />
            case 'integration':
                const integration = data ? data : { members: [] }
                return <IntegrationRuleTemplate data={integration} updateData={this.updateData} />
        }
    }

    initRule = () => {
        const rule = initRule(this.props.rule);
        this.setState({ ...rule })
    }

    save = () => {
        const { title, ruleType, data } = this.state;
        saveRule(title || this.props.t("New Rule"), ruleType, data, this.props);
        this.props.mixpanelTrack('Saved rule - ' + ruleType, {})

    }

    onDelete = () => {
        const { rule, dispatch, list } = this.props
        const action = { type: DELETE_LANE_RULE, data: { deleteRuleid: rule.id } }

        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), action))
    }

    render() {
        const { open, handleClose, classes, list, triggerType, t, rule } = this.props;
        const { ruleType, title } = this.state;

        const ruleTitle = this.getRuleTitle(triggerType);
        if (!open) {
            return null
        }

        return (
            <Dialog
                disableEnforceFocus
                onEnter={this.initRule}
                open={open}
                onClose={() => { handleClose() }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
                classes={{ paper: classes.container }}
            >
                <DialogTitle style={{ padding: '24px 16px' }}>
                    <Typography dir='auto' className={classes.titleText} style={{ paddingBottom: 8 }} >{ruleTitle}</Typography>
                    <TextField key={'title'}
                        inputProps={{ className: classes.titleText, dir: 'auto' }}
                        value={title}
                        classes={classes}
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        label={t('New Rule')}
                        // placeholder={t('New Rule')}
                        onChange={this.onChange('title')}
                        fullWidth />

                    <Typography dir='auto' style={{ paddingTop: 16 }}>{t(`If an item is ${triggerType} then do`)}:</Typography>
                    <ListRulesSelect onChange={this.onChange} ruleType={ruleType} triggerType={triggerType} />
                </DialogTitle>

                {ruleType ? <Divider /> : null}

                <DialogContent style={{ paddingTop: 16 }}>
                    <DisableScreen disable={this.state.isLoading} />
                    {ruleType === "member" && !this.props.groupInfo?.collectionGroup?.isEnableMembersAsAuthors ? <Typography color="error">
                        {t("Note: Only admin or an assignee can add another assignee. For correct functioning change to Co-working group")}
                    </Typography> : null}
                    {this.renderContent()}
                </DialogContent>

                <Divider />

                <DialogActions  >
                    {rule ? <Button onClick={this.onDelete} color="primary">
                        {t('Delete')}
                    </Button> : null}
                    <Button onClick={handleClose} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.save} color="primary" disabled={!ruleType}>
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    deleteRuleid: state.confirmDialog.deleteRuleid,
    groupInfo: state.results.channel,
    ...state.services
})

export default compose(withStyles(styles),
    withTranslation('listRules'),
    withMixpanel,
    withErrorCatcher("Failed Loading rules, Please Contact Tuqqi Support"),
    connect(mapStateToProps))(ListRuleModal);