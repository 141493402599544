import React from 'react';
import {withStyles} from '@material-ui/core';
import { compose } from 'recompose';

const styles = (theme) => ({
    icon: {
        position: 'absolute',
        zIndex:1,
        right: 48,
        top: -55
    },
})

const FirstClassPlaneView = (props) => {
    const {classes, planeClasses} = props

    return (
        <img className={`${classes.icon} ${planeClasses}`} alt="plain" src={"/resources/subscription/airplane-first-class.svg"}/>
    )
}

export const FirstClassPlane = compose(
    withStyles(styles, {withTheme: true}),
)(FirstClassPlaneView)