import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { getIsoDate } from './event-utils'
import Tooltip from '@material-ui/core/Tooltip';
import './calendar.css'
import { withStyles } from '@material-ui/core'
import { updateDocumentSchedule } from '@tuqqi/common'
import CalendarTooltip, { mapTime } from './CalendarTooltip'
import { useTranslation } from 'react-i18next';
import { mapLangToCalendar } from '../../../../utils/calendar/calendarHelpers';

const styles = theme => ({
  tooltip: {
    background: '#4A4A4F',
  },
  popper: {
    opacity: 1,
  },
  eventText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#1B1E2A'
  },
  eventTextSmall: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#1B1E2A'
  },
  timeText: {
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: '16px',
    fontStretch: 'normal',
    letterSpacing: '0.4px',
    fontSize: 11,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  titleMonthText: {
    fontStyle: 'normal',
    fontfamily: 'Rubik',
    fontWeight: 500,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  titleText: {
    marginTop: 2,
    fontStyle: 'normal',
    fontfamily: 'Rubik',
    fontWeight: 500,
    lineHeight: '16px',
    fontStretch: 'normal',
    letterSpacing: '0.4px',
    fontSize: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  titleTextSmall: {
    marginTop: 0,
    fontStyle: 'normal',
    fontfamily: 'Rubik',
    fontWeight: 500,
    lineHeight: '16px',
    fontStretch: 'normal',
    letterSpacing: '0.4px',
    fontSize: 11,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tooltipTitle: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.246063,
    color: '#FFFFFF',
  },
  tooltipSubTitle: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: 0.4,
    color: '#D7D7DB',
  },
  headerToolbarNotMonth: {
    '& .fc-event-main': {
      border: 'none!important',
      backgroundColor: '#fff!important',
      borderRadius: '3px!important'
    },
  },
  headerToolbar: {
    '& .fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active': {
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `white!important`,
      backgroundColor: `${theme.customColors.primary.main}!important`
    },
    '& .fc-dayGridMonth-button.fc-button.fc-button-primary': {
      width: 'auto',
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `${theme.customColors.primary.main}!important`
    },
    '& .fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active': {
      width: 'auto',
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `white!important`,
      backgroundColor: `${theme.customColors.primary.main}!important`
    },
    '& .fc-timeGridDay-button.fc-button.fc-button-primary': {
      width: 'auto',
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `${theme.customColors.primary.main}!important`
    },
    '& .fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active': {
      width: 'auto',
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `white!important`,
      backgroundColor: `${theme.customColors.primary.main}!important`
    },
    '& .fc-timeGridWeek-button.fc-button.fc-button-primary': {
      border: `1px solid ${theme.customColors.primary.main}!important`,
      color: `${theme.customColors.primary.main}!important`
    },
    '& .fc .fc-button-primary.fc-today-button': {
      backgroundImage: `linear-gradient(48deg, ${theme.customColors.primary.main} -27%, ${theme.customColors.primary.b300} 98%), linear-gradient(to bottom, #ffffff, #ffffff)!important`
    },
    '& .fc-daygrid-event.fc-event-start': {
      top: 5,
      marginLeft: 5,
      marginRight: 5,
      width: 'calc(100% - 10px)'
    },
    '& .fc .fc-highlight': {
      background: `${"#F3F6FB"}!important`
    },
    '& .fc-event-main': {
      border: 'none!important',
      maxWidth: '100%',
    },
    '& .fc-timegrid-event': {
      borderColor: 'transparent!important',
    },
    '& .fc-daygrid-event': {
      borderColor: 'transparent!important',
    },
    '& .fc-daygrid-event': {
      borderColor: 'transparent!important',
    },

  }
});

const Calendar = ({ events, onPress, classes, dispatch, onPressNew, profileData, initialView = 'timeGridWeek', onUpdateMonth = () => { } }) => {
  const [isMonth, setIsMonth] = useState(false)
  const [t] = useTranslation('calendarSettings')

  const handleEventClick = (clickInfo) => {
    onPress(clickInfo.event.id)
  }
  const handleSelectClick = (clickInfo) => {
    onPressNew(clickInfo.start, clickInfo.end)
  }

  const handleDropEvent = (eventDropInfo) => {
    const { event } = eventDropInfo

    const prevSchedule = event?._def?.extendedProps?.docSchedule
    if (!prevSchedule) return
    dispatch(updateDocumentSchedule({ API_URL: process.env.API_SERVER })(event.id, { ...prevSchedule, dateTimeFrom: event.startStr, dateTimeTo: event.endStr === '' ? event.startStr : event.endStr, dueDateUTC: event.startStr }))
  }

  const handleResize = (eventResizeInfo) => {
    const { event } = eventResizeInfo

    const prevSchedule = event?._def?.extendedProps?.docSchedule
    if (!prevSchedule) return
    dispatch(updateDocumentSchedule({ API_URL: process.env.API_SERVER })(event.id, { ...prevSchedule, dateTimeFrom: event.startStr, dateTimeTo: event.endStr, dueDateUTC: event.startStr }))
  }

  const handleEvents = (events) => {
    const lookup = events.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0;
      return a;
    }, {});

    const duplicatedEvents = events.filter(e => lookup[e.id])
    if (duplicatedEvents.length) {
      duplicatedEvents.forEach((evt, i) => {
        if (i === 0) return
        evt.remove()
      })
    }

  }


  return (
    <div data-intrcm-id="Calendarx_r0h04ttj" className='demo-app'>
      <div data-intrcm-id="Calendarx_a6xyrkv5" className={['demo-app-main', classes.headerToolbar, isMonth ? '' : classes.headerToolbarNotMonth].join(" ")}>
        <FullCalendar
          allDayText={t('all-day')}
          locale={mapLangToCalendar(profileData.lang)}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title',
            right: 'prev,today,next'
          }}
          buttonText={{
            today: t('Today'),
            day: t('Day'),
            week: t('Week'),
            month: t('Month'),
          }}
          initialView={initialView}
          droppable={true}
          editable={true}
          selectable={true}
          selectMirror={false}
          dayMaxEvents={false}
          weekends={true}
          events={events}
          eventsSet={handleEvents}
          eventContent={info => <RenderEventContent setIsMonth={setIsMonth} {...info} />}
          eventResize={handleResize}
          eventClick={handleEventClick}
          select={handleSelectClick}
          eventDrop={handleDropEvent}
          datesSet={onUpdateMonth}
          eventResize={handleResize}
        />
      </div>
    </div>
  )


}

const mapStateToProps = (state) => ({
  documents: state.data.documents.data,
  profileData: state.userData.profileData,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(Calendar);


const emptyBackground = {
  backgroundColor: 'transparent'
}
const dayGridMonthStyle = {
  top: 5,
  marginRight: 0,
  marginLeft: 0,
}
const dayGridMonthStyleManyDays = {
  ...dayGridMonthStyle,
  height: 28,
}
const timeGridWeekStyle = {
  marginRight: 0,
  marginLeft: 0,
  height: '100%'
}
function isTruncated(el) {
  if (!el) return false
  return el.scrollWidth > el.clientWidth
}

const getIsManyDays = (startStr, endStr) => {
  if (!!startStr && !endStr) {
    const start = new Date(startStr).toISOString()
    const tmp = new Date(start)
    tmp.setHours(tmp.getHours() + 1)
    const end = tmp.toISOString()

    const timeStart = `${tmp.getHours()}:${tmp.getMinutes()}`
    const diff = Math.floor(Math.abs(new Date(start).getTime() - new Date(end).getTime()) / 3600000);
    if (diff === 24) return new Date(startStr).getDate() !== new Date(endStr).getDate() && timeStart !== '0:0'
    return diff >= 24
  }

  const tmp = new Date(startStr)
  const timeStart = `${tmp.getHours()}:${tmp.getMinutes()}`

  const diff = Math.floor(Math.abs(new Date(startStr).getTime() - new Date(endStr).getTime()) / 3600000);
  if (diff === 24) return new Date(startStr).getDate() !== new Date(endStr).getDate() && timeStart !== '0:0'

  return diff >= 24
  // return new Date(startStr).getDate() !== new Date(endStr).getDate()
}


const RenderEventContentMonthType = withStyles(styles)(({ classes, ...eventInfo }) => {
  const { event } = eventInfo
  const { title, backgroundColor, _context, _def, startStr, endStr } = event
  const elId = `event-text-id${_def.defId}`

  const isManyDays = getIsManyDays(startStr, endStr)

  const dayGridMonthStyles = isManyDays ? dayGridMonthStyleManyDays : dayGridMonthStyle
  const { viewApi: { type } } = _context
  const mainStyle = dayGridMonthStyles

  const border = "none!important"
  const onHover = () => {
  }
  const Event = (
    <div data-intrcm-id="Calendarx_u0u1rca1" className='fc-event-main' style={{ width: '100%' }}>
      <div data-intrcm-id="Calendarx_0jquzjy1" style={{ ...mainStyle, backgroundColor, border, ...(isManyDays && type === 'dayGridMonth' ? emptyBackground : {}) }} className='fc-daygrid-event'>
        <div data-intrcm-id="Calendarx_ggtvompq" onMouseEnter={onHover} className={classes.eventText}>
          <div data-intrcm-id="Calendarx_tfx21kg1" id={elId} style={{ marginTop: 0 }} className={classes.titleMonthText}>{`${title}`}</div>
        </div>
      </div>
    </div>
  )

  return (
    <Tooltip classes={{ tooltip: classes.tooltip, popper: classes.popper }} interactive title={<CalendarTooltip event={event} />} placement="top-start">
      {Event}
    </Tooltip>
  )
})
const RenderEventContent = withStyles(styles)(({ classes, setIsMonth, ...eventInfo }) => {
  const { event } = eventInfo
  const { start, end, title, backgroundColor, _context, _def, startStr, endStr } = event

  const { viewApi: { type } } = _context

  useEffect(() => {
    setIsMonth(type === 'dayGridMonth')
  }, [type])

  if (type === 'dayGridMonth') return <RenderEventContentMonthType {...eventInfo} />
  const elId = `event-text-id${_def.defId}`
  const timeId = `event-text-time-id${_def.defId}`

  const mainStyle = type === 'timeGridWeek' ? timeGridWeekStyle : timeGridWeekStyle
  const time = getIsoDate(start)

  const border = "none!important"
  const onHover = () => {
  }

  const isShouldBeSmall = useMemo(() => {
    const difference = Math.abs(new Date(endStr).getTime() - new Date(startStr).getTime())
    const resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes <= 30
  }, [startStr, endStr])

  const textClassName = isShouldBeSmall ? classes.titleTextSmall : classes.titleText
  const textWrapClassName = isShouldBeSmall ? classes.eventTextSmall : classes.eventText
  const allDayEvent = !end
  const timeStr = useMemo(() => {
    const startTime = mapTime(start)
    const endTime = mapTime(end)
    return `${startTime}-${endTime}`
  }, [start, end])

  const correctTime = isShouldBeSmall ? time : timeStr

  const Event = (
    <div data-intrcm-id="Calendarx_sio678q1" style={{ ...mainStyle, backgroundColor: backgroundColor, border }} className={allDayEvent ? {} : 'fc-daygrid-event'} >
      <div data-intrcm-id="Calendarx_i4tpdh9g" onMouseEnter={onHover} className={textWrapClassName} >
        <div data-intrcm-id="Calendarx_kxxk7uwc" id={elId} className={textClassName}>{`${title}`}</div>
        {end ? <div data-intrcm-id="Calendarx_8ja26ver" id={timeId} className={classes.timeText} style={{ overflow: 'initial', marginLeft: !!time && isShouldBeSmall ? 4 : 0 }}>{`${correctTime}`}</div> : null}
      </div>
    </div>
  )  

  return (
    <Tooltip classes={{ tooltip: classes.tooltip, popper: classes.popper }} interactive title={<CalendarTooltip event={event} />} placement="top-start">
      {Event}
    </Tooltip>
  )
})