import { Grid, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import MenuButton from "../MenuButton";
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';

const styles = theme => ({
    container: {
        width: 'initial',
        marginRight: 32
    },
    menuTitle: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    menuButton: {
        "borderRadius": "4px",
        "backgroundColor": theme.newUI.color.gray50,
        "flex": "0 0 auto",
        "padding": "5px 1px 4px 12px",
        "paddingBottom": 5,
        "width": "initial"
    },
    selectedValue: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "textAlign": "right",
        "color": theme.newUI.color.commonBlack
    },
    spaceBetweenItems: {
        width: 8
    }

})

class AggregationType extends Component {
    state = {
        anchorEl: null,
        openMenu: false,
    }

    toggleOpenMenu = (event) => {
        this.setState({
            openMenu: !this.state.openMenu,
            anchorEl: event && event.target
        })
    }

    onChoose = (selected) => () => {
        this.toggleOpenMenu(null)
        this.props.onAggregationTypeChange(selected)    
    }

    aggregationTypeOptions = ['Summary', 'Average', 'Minimum', 'Maximum']

    render() {
        const { classes, aggregationType, t } = this.props;
        const { anchorEl, openMenu } = this.state;

        return (
            <Fragment>
                <Grid dir='auto' item container className={classes.container} wrap='nowrap' alignItems='center'>
                    <span className={classes.menuTitle}>{`${t("Aggregation value")}: `}</span>
                    <div data-intrcm-id="AggregationType_yxl7tq5f" className={classes.spaceBetweenItems}/>
                    <MenuButton
                        onClick={this.toggleOpenMenu}
                        classes={{ button: classes.menuButton, selectedValue: classes.selectedValue }}
                        selectedValue={aggregationType} />
                </Grid>

                <Menu
                    onClose={this.toggleOpenMenu}
                    open={openMenu}
                    anchorEl={anchorEl}
                >
                    {
                        this.aggregationTypeOptions.map(option => (
                            <MenuItem onClick={this.onChoose(option)} value={option}>
                                {option}
                            </MenuItem>
                        ))
                    }
                </Menu>

            </Fragment>)

    }

}

export default compose(withStyles(styles),
    withErrorCatcher(),
)(AggregationType);