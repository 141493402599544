import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import React, { Fragment, PureComponent } from 'react';
import { compose } from 'recompose';

const styles = theme => ({
    text: {
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#21212d',
    },
    bold: {
        fontWeight: 500
    }
});

class NotificationParsedText extends PureComponent {


    getMessageTemplateParam(match) {
        const place = (match).replace(/[^\d]/gi, '')

        return this.props.messageTemplateParams[place] || ''
    }


    getTemplate = (text, isBold) => {
        const { classes } = this.props;

        let res
        if (!text) {
            res = null
        }
        else if (!isBold) {
            res = text
        }
        else {
            res = <span dir='auto' className={classes.bold}>{text}</span>
        }

        return res
    }

    getRegExecution(text) {
        const reg = /\$\{\d\}/gi

        const res = reg.exec(text)
        if (!res) {
            return null
        }

        return {
            match: res[0],
            index: res.index,
            lastIndex: reg.lastIndex
        }
    }

    render() {
        const { text, classes, styles = null} = this.props;
        let tempText = text
        const splitedText = []

        let regExecution = this.getRegExecution(tempText)
    
        while (regExecution != null) {
            const simpleText = tempText.substring(0, regExecution.index)
            splitedText.push(this.getTemplate(simpleText, false))

            const messageTemplateParam = this.getMessageTemplateParam(regExecution.match)
            splitedText.push(this.getTemplate(messageTemplateParam, true))


            tempText = tempText.substring(regExecution.lastIndex)
            regExecution = this.getRegExecution(tempText)
        }

        if (!splitedText.length || tempText) {
            splitedText.push(this.getTemplate(tempText, false))
        }
        
        return <span className={classes.text} style={styles ? styles.text : null}>{splitedText}</span>
    }
}


export default compose(withStyles(styles))(NotificationParsedText);
