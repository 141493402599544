export const CHAT_GROUP_INFO  = {
    isGroup: false,
    groupUid: '',
    collectionUid: ''
}

export const putChatGroup = (groupUid, colUid) => {
    CHAT_GROUP_INFO.groupUid = groupUid
    CHAT_GROUP_INFO.collectionUid = colUid
    CHAT_GROUP_INFO.isGroup = true
}

export const clearChatGroup = () => {
    CHAT_GROUP_INFO.groupUid = ''
    CHAT_GROUP_INFO.collectionUid = ''
    CHAT_GROUP_INFO.isGroup = false
}