import React, { PureComponent } from 'react';

export default class EditLaunchpadOptionIcon extends PureComponent {
    render() {
        const { className, onClick } = this.props
        return (
            <svg onClick={onClick} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.6805 3.88562L11.9803 7.18543M8.6805 3.88562L10.5661 2L13.8659 5.29983L11.9803 7.18543L8.6805 3.88562ZM8.6805 3.88562L2.27607 10.29C2.15105 10.415 2.08081 10.5846 2.08081 10.7614V13.7851H5.1045C5.28131 13.7851 5.45088 13.7149 5.5759 13.5898L11.9803 7.18543L8.6805 3.88562Z" stroke="#737373" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>         
        )
    }
}