import React from 'react'
import withErrorCatcher from '../withErrorCatcher.js'
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Avatar from '@material-ui/core/Avatar';

export default function renderSuggestion(suggestion, { query, isHighlighted }) {

    var avatar = <div data-intrcm-id="suggestionComponent_r18sigpx" />
    if (typeof suggestion.avatar !== 'undefined') {
        if (suggestion.avatar !== '') {
            avatar = <Avatar src={suggestion.avatar} />
        }
    }

    
    var valueForSuggest = query;
    var vals = query.split(" ");
    
    if (vals.length > 1) {
        valueForSuggest = vals[vals.length - 1];
    }

    var suggestText = suggestion.tag; // if user
    if(!suggestText){
        suggestText = suggestion.value;
    }

    const matches = match(valueForSuggest[0] + suggestText, valueForSuggest);
    const parts = parse(valueForSuggest[0] + suggestText, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            {avatar}
            <div data-intrcm-id="suggestionComponent_xw36o7ch" style={{ paddingLeft: 10 }}>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    ) : (
                            <span key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </span>
                        );
                })}
            </div>
        </MenuItem>
    );
}