import { RESULT_FILTERS_UPDATE, RESULT_CHANNEL_UPDATE, RESULT_CHANNEL_FILTER_UPDATE, RESULT_CHANNEL_EMPTY, FULL_RESET_STORE } from "../actionTypes/actionTypes";
import {ON_GO_TO_CHAT} from '@tuqqi/web'
import {updateGroupWorkingTimeActionTypes, updateGroupLimitedUserRoleActionTypes, updateGroupClassicUserRoleActionTypes, removeTeamFromGroupActionTypes, addTeamToGroupActionTypes, addUserToGroupActionTypes, removeUserFromGroupActionTypes, updateGroupDefaultViewActionTypes, updateGroupDisplayedViewsActionTypes, updateGroupDescriptionActionTypes, updateGroupImageActionTypes, updateGroupTitleActionTypes, tempDTOForGanttGroup, updateGroupAllowedInputsActionTypes} from '@tuqqi/common'

const initialState = [];

const getUpdatedViewsAfterMove = (lists, docId, fromLaneId, toLaneId, newCardIndex) => {
    if(toLaneId === fromLaneId) return newLists

    if(toLaneId === -1) {
        const newLists = lists.map(l => {
            if(l.id === fromLaneId)  {
                l.items = l.items.filter(item => item.documentId !== docId)
                return {
                    ...l,
                    items: l.items.map((l, i) => ({...l, order: i}))
                }
            }
            return l
        })
        return newLists
    }
    let keepLists = [...lists]
    const newLists = lists
        .map(l => {
            if(l.id === toLaneId) {
                let oldId = 0
                
                keepLists.find(l => l.items?.find?.(x => {
                    if(x.documentId === docId) oldId = x.id
                    return x.documentId === docId
                }))

                l.items.splice(newCardIndex, 0, {order: newCardIndex, documentId: docId, collectionViewListId: l.id, id: oldId});
                return {
                    ...l,
                    items: l.items.map((l, i) => ({...l, order: i}))
                }
            }
            return l
        })
        .map(l => {
            if(l.id === fromLaneId) {
                l.items = l.items.filter(item => item.documentId !== docId)

                return {
                    ...l,
                    items: l.items.map((l, i) => ({...l, order: i}))
                }
            }
        })
        .map((l, i) => ({...l, order: i}))

    return newLists
}

const input = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState;
        case RESULT_FILTERS_UPDATE:
            return {
                ...state,
                dataTypes: action.results.dataTypes ? [...action.results.dataTypes] :[],
                relatedDocs: action.results.relatedDocs   ? [...action.results.relatedDocs] : [],
				groups: action.results.groups   ? [...action.results.groups] : [],
				experts: action.results.experts   ? [...action.results.experts] : [],
            }
        case RESULT_CHANNEL_EMPTY:
        case ON_GO_TO_CHAT:
            return {
                ...state,
                relatedTagsQuery: [],
                channel: {},
                dataTypes: [],
                relatedDocs: [],
				experts: [],
				groups: [],
            }
        case RESULT_CHANNEL_UPDATE:

            return {
                ...state,
                channel: tempDTOForGanttGroup({ ...action.results.channel }),
            }
        case 'RESULT_CHANNEL_VIEWS':

            return {
                ...state,
                channel: tempDTOForGanttGroup({ ...state.channel, collectionViews: action.collectionViews }),
            }
        case updateGroupTitleActionTypes.start: {
            const startAction = action
    
            if(!startAction?.additionalData?.collectionId) return state
            const {title, collectionId} = startAction?.additionalData
            if(!title || !collectionId) return state
            if(collectionId !== state.channel?.id) return state
    
            return {
                ...state,
                channel: {
                    ...state.channel,
                    title
                }
            }
        }
        case updateGroupWorkingTimeActionTypes.start: {
            const startAction = action
    
            if(!startAction?.additionalData?.collectionId) return state
            const {collectionAvailabilityTimes, collectionId} = startAction?.additionalData
            if(!collectionId) return state
            if(collectionId !== state.channel?.id) return state
    
            return {
                ...state,
                channel: {
                    ...state.channel,
                    collectionAvailabilityTimes: collectionAvailabilityTimes || ''
                }
            }
        }
        case updateGroupAllowedInputsActionTypes.start: {
            const startAction = action
      
            if (!startAction?.additionalData?.collectionId) return state
            const { allInputsAllowed, allowedInputs, collectionId } = startAction?.additionalData
            if (!collectionId) return state
            if(collectionId !== state.channel?.id) return state

            return {
                ...state,
                channel: {
                    ...state.channel,
                    allInputsAllowed,
                    settings: {
                        ...(state.channel?.settings ?? {}),
                        allowedInputs
                      },
                }
            }
          }
        case updateGroupDescriptionActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId) return state
            const {description, collectionId} = startAction?.additionalData
            if(!description || !collectionId) return state
            if(collectionId !== state.channel?.id) return state

            return {
                ...state,
                channel: {
                    ...state.channel,
                    description
                }
            }
        }
        case updateGroupDefaultViewActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId) return state
            const {defaultView, collectionId} = startAction?.additionalData
            if(!defaultView || !collectionId) return state
            if(collectionId !== state.channel?.id) return state

            return {
                ...state,
                channel: {
                    ...state.channel,
                    defaultView: defaultView
                }
            }
        }
        case updateGroupDisplayedViewsActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId) return state
            const {availableViews, collectionId} = startAction?.additionalData
            if(!availableViews || !collectionId) return state
            if(collectionId !== state.channel?.id) return state

            return {
                ...state,
                channel: {
                    ...state.channel,
                    availableViews: availableViews
                }
            }
        }
        case updateGroupImageActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId) return state
            const {image, collectionId} = startAction?.additionalData
            if(!image || !collectionId) return state
            if(collectionId !== state.channel?.id) return state

            return {
                ...state,
                channel: {
                    ...state.channel,
                    backgroundUrl: image
                }
            }
        }
        case removeUserFromGroupActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            const groupUsers = (state.channel?.collectionGroup?.groupUsers ?? [])
            const limitedUsers = (state.channel?.limitedGroup?.groupUsers ?? [])
            const userData = [...groupUsers, ...limitedUsers].find(x => x.id === bodyInfo.userId)

            if(!userData) return state
            if(!newState.channel?.collectionGroup) return state

            const prevLimitedUsers = limitedUsers.filter(u => u.id !== userData.id)
            const prevGroupUsers = groupUsers.filter(u => u.id !== userData.id)
            const prevAdminIds = (state?.channel?.collectionGroup?.adminIds ?? []).filter(id => id !== userData.id)
            const prevAdminUIds = (state?.channel?.collectionGroup?.adminUIds ?? []).filter(uid => uid !== userData.auth0_id)

            newState.channel.collectionGroup = {
                ...newState.channel.collectionGroup,
                groupUsers: prevGroupUsers,
                adminUIds: prevAdminUIds,
                adminIds: prevAdminIds,
            }
            newState.channel.limitedGroup = {
                ...newState.channel.limitedGroup,
                groupUsers: prevLimitedUsers
            }

            return {...state, channel: {
                ...newState.channel
            }}
        }
        case addUserToGroupActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {user: userData, bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            const groupUsers = (state.channel?.collectionGroup?.groupUsers ?? [])
            const limitedUsers = (state.channel?.limitedGroup?.groupUsers ?? [])

            if(!userData) return state
            if(!newState.channel?.collectionGroup) return state

            const prevLimitedUsers = limitedUsers.filter(u => u.id !== userData.id)
            const prevGroupUsers = groupUsers.filter(u => u.id !== userData.id)
            const prevAdminIds = (state?.channel?.collectionGroup?.adminIds ?? []).filter(id => id !== userData.id)
            const prevAdminUIds = (state?.channel?.collectionGroup?.adminUIds ?? []).filter(uid => uid !== userData.auth0_id)

            if(!!bodyInfo.isLimited) {
                prevLimitedUsers.push(userData)
            } else {
                prevGroupUsers.push(userData)
            }

            newState.channel.collectionGroup = {
                ...newState.channel.collectionGroup,
                groupUsers: prevGroupUsers,
                adminUIds: prevAdminUIds,
                adminIds: prevAdminIds,
            }
            newState.channel.limitedGroup = {
                ...newState.channel.limitedGroup,
                groupUsers: prevLimitedUsers
            }
            
            return {...state, channel: {
                ...newState.channel
            }}
        }
        case addTeamToGroupActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            if(!newState.channel?.collectionGroup) return state

            const prevUsers = newState.channel?.collectionTeamsDto?.groupUsers ?? []

            newState.channel.collectionTeamsDto = {
                ...newState.channel.collectionTeamsDto,
                groupUsers: [...prevUsers, bodyInfo.teamId]
            }
            
            return {...state, channel: {
                ...newState.channel
            }}
        }
        case removeTeamFromGroupActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            if(!newState.channel?.collectionGroup) return state

            const prevUsers = newState.channel?.collectionTeamsDto?.groupUsers ?? []

            newState.channel.collectionTeamsDto = {
                ...newState.channel.collectionTeamsDto,
                groupUsers: prevUsers.filter(x => x !== bodyInfo.teamId)
            }
            
            return {...state, channel: {
                ...newState.channel
            }}
        }
        case updateGroupClassicUserRoleActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            const groupUsers = (state.channel?.collectionGroup?.groupUsers ?? [])
            const limitedUsers = (state.channel?.limitedGroup?.groupUsers ?? [])
            const userData = [...groupUsers, ...limitedUsers].find(x => x.id === bodyInfo.userId)

            if(!userData) return state
            if(!newState.channel?.collectionGroup) return state
            const wasLimited = limitedUsers.find(x => x.id === bodyInfo.userId)
            
            //changing between classified | others
            if(!!wasLimited) {
                newState.channel.limitedGroup.groupUsers = limitedUsers.filter(x => x.id !== bodyInfo.userId)
                newState.channel.collectionGroup.groupUsers = [...groupUsers.filter(x => x.id !== bodyInfo.userId), userData]
            }

            const prevAdminIds = (state?.channel?.collectionGroup?.adminIds ?? []).filter(id => id !== userData.id)
            const prevAdminUIds = (state?.channel?.collectionGroup?.adminUIds ?? []).filter(uid => uid !== userData.auth0_id)

            if(bodyInfo.userRole === 1) {
                prevAdminIds.push(userData.id)
                prevAdminUIds.push(userData.auth0_id)
            }
            newState.channel.collectionGroup = {
                ...newState.channel.collectionGroup,
                adminUIds: prevAdminUIds,
                adminIds: prevAdminIds,
            }

            return {...state, channel: {
                ...newState.channel
            }}
        }
        case updateGroupLimitedUserRoleActionTypes.start: {
            const startAction = action

            if(!startAction?.additionalData?.collectionId || !startAction?.additionalData?.bodyInfo) return state
            const {bodyInfo, collectionId} = startAction?.additionalData

            if(collectionId !== state.channel?.id) return state

            const newState = {...state}
            
            const groupUsers = (state.channel?.collectionGroup?.groupUsers ?? [])
            const limitedUsers = (state.channel?.limitedGroup?.groupUsers ?? [])
            const userData = [...groupUsers, ...limitedUsers].find(x => x.id === bodyInfo.userId)

            if(!userData) return state
            if(!newState.channel?.collectionGroup) return state
            

            if(!!bodyInfo.isLimited) {
                newState.channel.limitedGroup.groupUsers = [...limitedUsers.filter(x => x.id !== bodyInfo.userId), userData]
                newState.channel.collectionGroup.groupUsers = groupUsers.filter(x => x.id !== bodyInfo.userId)
            } else {
                newState.channel.limitedGroup.groupUsers = limitedUsers.filter(x => x.id !== bodyInfo.userId)
                newState.channel.collectionGroup.groupUsers = [...groupUsers.filter(x => x.id !== bodyInfo.userId), userData]
            }

            const prevAdminIds = (state?.channel?.collectionGroup?.adminIds ?? []).filter(id => id !== userData.id)
            const prevAdminUIds = (state?.channel?.collectionGroup?.adminUIds ?? []).filter(uid => uid !== userData.auth0_id)

            newState.channel.collectionGroup = {
                ...newState.channel.collectionGroup,
                adminUIds: prevAdminUIds,
                adminIds: prevAdminIds,
            }
            return {...state, channel: {
                ...newState.channel
            }}
        }
        case 'RESULT_CHANNEL_VIEW_NEW_LANE': {
            const lane = action.lane
            const isGantt = !!action.isGantt
            const newViews = [...state.channel.collectionViews]
            const kanbanInd = state.channel.collectionViews.findIndex(x => isGantt ? x.collectionViewType === 'Gantt' : x.collectionViewType === 'Kanban')
            if(kanbanInd === -1) return state
            const kanban = state.channel.collectionViews[kanbanInd]
            kanban.itemLists.push(lane)
            newViews[kanbanInd] = kanban

            return {
                ...state,
                channel: { ...state.channel, collectionViews: newViews },
            }
        }
        case 'RESULT_CHANNEL_VIEW_UPDATE_LANE': {
            const lane = action.lane
            const isGantt = !!action.isGantt
            const newViews = [...state.channel.collectionViews]
            const kanbanInd = state.channel.collectionViews.findIndex(x => isGantt ? x.collectionViewType === 'Gantt' : x.collectionViewType === 'Kanban')
            if(kanbanInd === -1) return state
            const kanban = state.channel.collectionViews[kanbanInd]
            kanban.itemLists = kanban.itemLists.map((list) => list.id === lane.id ? lane : list)
        
            newViews[kanbanInd] = kanban
            
            return {
                ...state,
                channel: { ...state.channel, collectionViews: newViews },
            }
        }
        case 'RESULT_CHANNEL_VIEWS_GANTT': {
            const itemLists = action.itemLists

            const newViews = [...state.channel.collectionViews]
            const ganttInd = state.channel.collectionViews.findIndex(x => x.collectionViewType === 'Gantt')
            if(ganttInd === -1) return state

            const gantt = state.channel.collectionViews[ganttInd]
            gantt.itemLists = itemLists
        
            newViews[ganttInd] = gantt
            
            return {
                ...state,
                channel: { ...state.channel, collectionViews: newViews },
            }
        }
        case 'RESULT_CHANNEL_MOVE_CARD_BETWEEN_LANES_GANTT': {
            const {docId, fromLaneId, toLaneId, newCardIndex} = action

            const ganttView = state.channel.collectionViews.find(view => view.collectionViewType === 'Gantt')
            if(!ganttView) return state
            ganttView.itemLists = getUpdatedViewsAfterMove(ganttView.itemLists, docId, fromLaneId, toLaneId,newCardIndex)

            const collectionViews = state.channel.collectionViews.map((view) => view.collectionViewType === 'Gantt' ? ganttView : view)

            
            return {
                ...state,
                channel: { ...state.channel, collectionViews },
            }
        }
        case 'RESULT_CHANNEL_VIEW_DELETE_LANE': {
            const lane = action.lane
            const isGantt = !!action.isGantt
            const newViews = [...state.channel.collectionViews]
            const kanbanInd = state.channel.collectionViews.findIndex(x => isGantt ? x.collectionViewType === 'Gantt' : x.collectionViewType === 'Kanban')
            if(kanbanInd === -1) return state
            const kanban = state.channel.collectionViews[kanbanInd]
            const filtered = kanban.itemLists.filter(el => el.id !== lane.id)
            kanban.itemLists = filtered
            newViews[kanbanInd] = kanban

            return {
                ...state,
                channel: { ...state.channel, collectionViews: newViews },
            }
        }
        case 'RESULT_RELATED_UPDATE':
            return {
                ...state,
                relatedTagsQuery: action.relatedTagsQuery
            }
        case RESULT_CHANNEL_FILTER_UPDATE:
            return {
                ...state,
                channel: { ...action.results.channel },
                dataTypes: action.results.dataTypes ? [...action.results.dataTypes] :[],
                relatedDocs: action.results.relatedDocs   ? [...action.results.relatedDocs] : [],
				experts: action.results.experts   ? [...action.results.experts] : [],
				groups: action.results.groups ? [...action.results.groups] : [],
            }
        default:
            return state
    }
}

export default input