import React, { PureComponent } from 'react';

export const CreateIcon = class CreateIcon extends PureComponent {

    render() {
        const {  } = this.props
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00016 1.33325C4.32418 1.33325 1.3335 4.32394 1.3335 7.99992C1.3335 11.6759 4.32418 14.6666 8.00016 14.6666C11.6761 14.6666 14.6668 11.6759 14.6668 7.99992C14.6668 4.32394 11.6761 1.33325 8.00016 1.33325ZM8.00016 2.33325C11.1357 2.33325 13.6668 4.86438 13.6668 7.99992C13.6668 11.1355 11.1357 13.6666 8.00016 13.6666C4.86462 13.6666 2.3335 11.1355 2.3335 7.99992C2.3335 4.86438 4.86462 2.33325 8.00016 2.33325ZM7.99235 4.65942C7.85986 4.66149 7.7336 4.71607 7.64131 4.81116C7.54903 4.90625 7.49826 5.03409 7.50016 5.16658V7.49992H5.16683C5.10057 7.49898 5.03479 7.51122 4.97331 7.53593C4.91182 7.56064 4.85586 7.59732 4.80868 7.64384C4.76149 7.69036 4.72403 7.7458 4.69845 7.80693C4.67288 7.86805 4.65971 7.93366 4.65971 7.99992C4.65971 8.06618 4.67288 8.13178 4.69845 8.19291C4.72403 8.25404 4.76149 8.30948 4.80868 8.356C4.85586 8.40252 4.91182 8.4392 4.97331 8.46391C5.03479 8.48861 5.10057 8.50086 5.16683 8.49992H7.50016V10.8333C7.49923 10.8995 7.51147 10.9653 7.53617 11.0268C7.56088 11.0883 7.59756 11.1442 7.64408 11.1914C7.6906 11.2386 7.74604 11.2761 7.80717 11.3016C7.8683 11.3272 7.9339 11.3404 8.00016 11.3404C8.06642 11.3404 8.13203 11.3272 8.19316 11.3016C8.25428 11.2761 8.30972 11.2386 8.35624 11.1914C8.40276 11.1442 8.43944 11.0883 8.46415 11.0268C8.48886 10.9653 8.5011 10.8995 8.50016 10.8333V8.49992H10.8335C10.8998 8.50086 10.9655 8.48861 11.027 8.46391C11.0885 8.4392 11.1445 8.40252 11.1916 8.356C11.2388 8.30948 11.2763 8.25404 11.3019 8.19291C11.3274 8.13178 11.3406 8.06618 11.3406 7.99992C11.3406 7.93366 11.3274 7.86805 11.3019 7.80693C11.2763 7.7458 11.2388 7.69036 11.1916 7.64384C11.1445 7.59732 11.0885 7.56064 11.027 7.53593C10.9655 7.51122 10.8998 7.49898 10.8335 7.49992H8.50016V5.16658C8.50112 5.09966 8.48864 5.03323 8.46345 4.97122C8.43826 4.90921 8.40088 4.85289 8.35353 4.8056C8.30617 4.7583 8.2498 4.72099 8.18776 4.69589C8.12572 4.67078 8.05927 4.65838 7.99235 4.65942Z" fill="white" fill-opacity="0.7"/>
            </svg>            
        )
    }
}