import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Collapse,
  withStyles
} from '@material-ui/core';

import { pxToRem } from '../../../../utils/pxToRem';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Scrollbar from '../../../components/Common/Scrollbar';
import { withTranslation } from 'react-i18next';
import { managerItemWatchers, useItemWatchers, WatchersList, useDataTypeName } from '@tuqqi/web';


const HEIGHT = 55;
const styles = theme => ({
    collapsedButton: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        height: 40,
        borderRadius: 0,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
        justifyContent: 'space-between',
        color: theme.palette.text.disabled,
        flexDirection: 'column', paddingTop: 12
    },
    list: {
        transition: theme.transitions.create('max-height')
    },
    options: {
      width: 320,
      outline: 'none'
  },
    participant: {
        height: HEIGHT, padding: '8px 20px'
    },
    participantAvatar: {
        position: 'relative', width: 40, height: 40
    },
    collapsedContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    collapseIcon: {
        flexDirection: 'column', right: 10, position: 'absolute', color: 'rgba(0, 0, 0, 0.38)'
    },
    hint: {
      marginRight: 16,
      marginLeft: 16,
      height: 40,
      maxHeight: 40,
      overflow: 'hidden',
      color: '#4A4A4F',
      'font-feature-settings': "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.148
    }
  });

  const PART_UID = 'fullview_WATCHER_'
function ChatRoomGroupParticipantNew({
  document,
  isCollapse,
  onCollapse,
  classes,
  dispatch,
  userUid,
  openSidebar,
  t,
}) {
    const data = useItemWatchers((PART_UID) + document.docId, true)
    const getDataTypeName = useDataTypeName()

    const value = useMemo(() => data?.[document.docId] ?? [], [data, document.docId])
    const datatypeName = getDataTypeName(document?.dataType ?? '')

    const groupUid = document.groupUid
    const withTeams = true
    const [members, setMembers] = useState(managerItemWatchers.getPotentialForGroup({groupUid, withTeams: true, collection: undefined}))

    useEffect(() => {
        setMembers(managerItemWatchers.getPotentialForGroup({groupUid, withTeams: true, collection: undefined}))
    }, [groupUid])

    const onChange = useCallback((data) => {
        managerItemWatchers.setWatchersUI(document.docId, data)

        setTimeout(() => {
            managerItemWatchers.setWatchersApi(document.docId, ['mock'])
        }, 100)
    }, [document.docId])

    const popoverMaxHeight = 48 * 5
    const listStyle = !!popoverMaxHeight ? {
      maxHeight: popoverMaxHeight,
      marginTop: -8,
      marginBottom: 8,
      overflow: 'hidden'
  } : {}

  const getGreySelected = useCallback((uid) => {
    return uid !== userUid
  }, [userUid])

  const selectedMembersIdsStr = useMemo(() => {
    return members
        .filter(m => value.includes(m.uid))
        .map(m => m.id + '')
}, [value, members])

    const isChecked = useCallback((uid) => {
        return value.includes(uid)
    }, [value])

    const onClickMember = useCallback((uid) => {
        if(value.includes(uid)) {
            onChange(value.filter(x => x !== uid))
            return
        }
        onChange(value.concat(uid))
    }, [value])

  const getCheckedTeamType = useCallback((team) => {
    if(!team.isTeam || !withTeams) return 'uncheck'

    const [teamIdsStr = ''] = team.subTitle.split(':')?.slice?.(-1) ?? []
    const teamIds = teamIdsStr.split(';') ?? []
    if(teamIds.every(idStr => selectedMembersIdsStr.includes(idStr))) return 'check'
    if(teamIds.every(idStr => !selectedMembersIdsStr.includes(idStr))) return 'uncheck'
    return 'part'
}, [withTeams, selectedMembersIdsStr])

const onClickTeam = useCallback((team, checked, isPatlyChecked) => {
    if(!team.isTeam) return

    const [teamIdsStr = ''] = team.subTitle.split(':')?.slice?.(-1) ?? []
    const teamIds = (teamIdsStr.split(';') ?? []).map(x => Number(x))

    const teamUids = members
        .filter(x => x.id && teamIds.includes(x.id))
        .map(x => x.uid)
    
    if(!!checked && !isPatlyChecked) {
        onChange(value.filter(x => !teamUids.includes(x)))
        return
    }
    onChange(Array.from(new Set([...value, ...teamUids])))
}, [withTeams, members, value])


  const Content = <div data-intrcm-id="SelectMembersPopover_rre90bew" className={classes.options} style={listStyle}>
        <WatchersList fromChat getGreySelected={getGreySelected} onClickTeam={onClickTeam} getCheckedTeamType={getCheckedTeamType} isChecked={isChecked} onClickUser={onClickMember} sectionWidth={320} isEmptySearchValue={true} data={members} />
    </div>

  const Hint = <div className={classes.hint}>{t("Join or leave the watching list, and see who else is watching this {{datatypeName}}.", {datatypeName})}</div>

  return (
    <React.Fragment>
      <div data-intrcm-id="ChatRoomGroupParticipantNew_cavlrs8y" className={classes.collapsedContainer}>
        <Button
            className={classes.collapsedButton}
            fullWidth
            disableRipple
            color="inherit"
            onClick={onCollapse}>{t("Watchers in discussion ({{countOfUsers}})", {countOfUsers: value.length})}
        </Button>
        {openSidebar &&
            <Icon
                className={classes.collapseIcon}
                icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
                width={16}
                height={16}
            />
        }
      </div>
      
      <div data-intrcm-id="ChatRoomGroupParticipantNew_7wwwn6e4" className={classes.list} style={{ maxHeight: isCollapse ? popoverMaxHeight + 40 : 0}}>
        <Scrollbar maxHeight={popoverMaxHeight + 40}>
          <Collapse in timeout="auto">
            {Hint}
            {Content}
          </Collapse>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    userUid: state?.data?.userData?.userDetails?.data?.auth0_id ?? ''
})

const mapDispatchToProps = dispatch => ({
    dispatch
})
export default compose(
    withStyles(styles, { withTheme: true }), 
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChatRoomGroupParticipantNew);