import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../../actions/appActions';
import UserListItem from "../../Common/userListItem";
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "../../Common/spinner";
import LongList from "../../Common/LongList";

const styles = theme => ({

    chip: {
        margin: 5
    },
    disScrollig: {
        overflow: 'hidden'
    }

});


class NotifyMeModal extends Component {

    state = {
        open: false,
        eventType: 2,//'everyone',
        checked: [],
        allSelected: false,
        disabled: true,
        page: 0,
        users: []
    };

    selectAllLabel = () => {
        const { t } = this.props
        const { allSelected } = this.state

        return allSelected ? t("Clear") : t("Select All")
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleChange = (event) => { this.setState({ eventSetting: event.target.value }); };

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    };
    handleEventType = (event) => { this.setState({ eventType: +event.target.value }) }

    handleSelectAll = () => {
        const { allSelected, users } = this.state

        let userIds;

        if (allSelected) {
            userIds = [];
        } else {
            userIds = users.map(u => u.auth0_id);
        }

        this.setState({
            allSelected: !allSelected,
            checked: userIds,
        })
    }
    handleCancel = () => {
        this.setState({ open: false })
    }
    handleSave = () => {

        const { dispatch, notificationService, handleClose, docId, t } = this.props;
        const { users, checked, eventType } = this.state

        const allSelected = (users.length == checked.length)

        dispatch(showSnackbar(t('Saving')));

        notificationService.createItemIdSeenRule(docId, checked, eventType)
            .then((res) => {
                let message;
                if (res.ok) {
                    message = 'Saved!'
                } else {
                    message = 'Error occurred!'
                }
                dispatch(showSnackbar(t(message)));
            })
        handleClose()
    }

    loadData = () => {
        const { docId, notificationService } = this.props

        const { users, checked } = this.state
        notificationService.getDocNotificationRule(docId).then(res => {

            if (res.ok) {
                res.json().then(rule => {

                    const allSelected = (users.length == checked.length)

                    this.setState({
                        eventType: rule.eventType,
                        checked: rule.specify,
                        allSelected,
                        disabled: false
                    })
                })
            } else {
                this.setState({ disabled: false })
            }

        })

    }

    componentDidMount() {
        const { users, userUid } = this.props;

        const currUsers = users.filter(u => u.auth0_id != userUid);
        this.setState({ users: currUsers })
    }

    renderItem = (user, idx) => {
        const { classes } = this.props;

        const { disabled, checked } = this.state

        return (
        <UserListItem
            disabled={disabled}
            user={user}
            key={idx}
            onClick={this.handleToggle(user.auth0_id)}
            classes={classes}
            checked={checked.indexOf(user.auth0_id) !== -1} />)
    }

    render() {
        const { open, handleClose, classes, dispatch, t } = this.props;

        const { disabled, checked, selectLabale, users, eventType, allSelected } = this.state

        const delta = 20


        return (
            <Fragment>
                <Dialog
                    open={open}
                    onEnter={this.loadData}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{t('Seen notifications settings')}
                    </DialogTitle>
                    <Divider />
                    <DialogContent className={classes.disScrollig} style={{ width: 600, flexShrink: 0, overflowY: 'auto' }}>
                        <div data-intrcm-id="NotifyMeModal_apo2i443">
                            <FormControl disabled={disabled} component="fieldset" required className={classes.formControl}>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    className={classes.group}
                                    value={eventType}
                                    onChange={this.handleEventType}>
                                    <FormControlLabel disabled={disabled} value={2} control={<Radio />} label={<span>{t('Notify me when')} <em style={{ color: 'red' }}>{t('everyone')}</em> {t('sees the item')}</span>} />
                                    <FormControlLabel disabled={disabled} value={1} control={<Radio />} label={<span>{t('Notify me when')} <em style={{ color: 'red' }}>{t('each one')}</em> {t('of the selected see the item')}</span>} />
                                </RadioGroup>
                            </FormControl>

                        </div>
                        <br />
                    </DialogContent>
                    <Divider />
                    <DialogContent>
                        <LongList useWindow={false} list={users} render={this.renderItem}/>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleSelectAll} color="primary">
                            {this.selectAllLabel()}
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    userUid: state.userData.profileData.auth0_id,
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('notifyMeModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(NotifyMeModal);