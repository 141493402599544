import React, { Fragment } from "react";

import { compose } from "recompose";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import { withStyles, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Category from "./category";
import withErrorCatcher from "../Common/withErrorCatcher.js";
import CategoryAdder from "./categoryAdder";

const styles = (theme) => ({
  wrapper: {
    overflowX: "visible",
    height: "100%",
  },
  headerSection: {
    fontFamily: "Rubik",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "2px",
    color: theme.newUI.color.gray,
    marginBottom: 25,
    paddingLeft: 10,
  },
  container: {
    height: "100%",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 88px)",
    paddingRight: "340px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down(1680)]: {
      paddingRight: "0px",
    },
  },
  listsWrapper: {
    display: "inline-flex",
    flexDirection: "column",
    height: "100%",
    userSelect: "none",
    "-webkit-user-select": "none",
    "-webkit-touch-callout": "none",
  },
  LiveActivityHolder: {
    borderLeft: "1px solid #ced1e0",
  },
  drawerLiveActivity: {
    width: "20%",
  },

  item: {
    paddingLeft: "8px",
    paddingRight: "8px",
    marginBottom: "25px",

    [theme.breakpoints.down(1200)]: {
      flexBasis: "45%",
    },
    [theme.breakpoints.down(767)]: {
      flexBasis: "100%",
    },
  },

  itemInner: {
    padding: "20px",
    width: "264px",
    minHeight: "264px",
    borderRadius: "4px",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    border: "solid 1px #dcdde0",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
    cursor: "pointer",
    boxSizing: "border-box",

    [theme.breakpoints.down(1250)]: {
      width: "100%",
    },
  },
});

export class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startX: null,
      startScrollX: null,
    };
    this.ref = React.createRef();
  }
  componentDidMount() {
    const ref = document.getElementById("board-container");
    if (ref) {
      setTimeout(() => {
        ref.scrollTo(-ref.scrollLeft, 0);
      });
    }
  }
  isEmptyArea(target) {
    const { className } = target;
    return className && className.includes("list-wrapper");
  }

  handleDragEnd = ({ source, destination, type }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    const { moveList, moveCardBetweenLanes, moveCardInLane } = this.props.func;

    switch (type) {
      case "COLUMN":
        if (
          source.index !== 0 &&
          destination.index !== 0 &&
          source.index !== destination.index
        ) {
          moveList({
            oldListIndex: source.index,
            newListIndex: destination.index,
          });
        }
        break;
      case "CARD":
        // todo: check if this bug appear
        if (destination.index === -1) {
          destination.index = 0;
        }
        if (source.droppableId !== destination.droppableId) {
          moveCardBetweenLanes({
            sourceListId: source.droppableId - 0,
            destListId: destination.droppableId - 0,
            oldCardIndex: source.index,
            newCardIndex: destination.index,
          });
        } else if (source.index !== destination.index) {
          moveCardInLane({
            listId: source.droppableId - 0,
            oldCardIndex: source.index,
            newCardIndex: destination.index,
          });
        }
        break;
      default:
        break;
    }
  };

  // The following three methods implement dragging of the board by holding down the mouse
  handleMouseDown = ({ target, clientX }) => {
    if (this.isEmptyArea(target)) {
      window.addEventListener("mousemove", this.handleMouseMove);
      window.addEventListener("mouseup", this.handleMouseUp);
      this.setState({
        startX: clientX,
        startScrollX: window.scrollX,
      });
    }
  };

  // Go to new scroll position every time the mouse moves while dragging is activated
  handleMouseMove = ({ clientX }) => {
    const { startX, startScrollX } = this.state;
    const scrollX = startScrollX - clientX + startX;
    window.scrollTo(scrollX, 0);
    const windowScrollX = window.scrollX;
    if (scrollX !== windowScrollX) {
      this.setState({
        startX: clientX + windowScrollX - startScrollX,
      });
    }
  };

  // Remove drag event listeners
  handleMouseUp = () => {
    if (this.state.startX) {
      window.removeEventListener("mousemove", this.handleMouseMove);
      window.removeEventListener("mouseup", this.handleMouseUp);
      this.setState({ startX: null, startScrollX: null });
    }
  };

  handleWheel = ({ target, deltaY }) => {
    if (this.isEmptyArea(target)) {
      if (Math.sign(deltaY) === 1) {
        window.scrollTo(window.scrollX + 80, 0);
      } else if (Math.sign(deltaY) === -1) {
        window.scrollTo(window.scrollX - 80, 0);
      }
    }
  };

  render() {
    const { lists, func, classes, containerClassName } = this.props;

    return (
      <div data-intrcm-id="board_9x87x5yd" className={classnames(classes.wrapper, containerClassName)}>
        <Fragment>
          <Typography className={classes.headerSection}>ALL LISTS</Typography>
          <div data-intrcm-id="board_ewtmrpp4"
            className={classnames(
              "lists-wrapper",
              "kanban-wrapper",
              classes.listsWrapper
            )}
          >
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId={"1"} type="COLUMN" direction="horizontal">
                {(provided) => (
                  <div data-intrcm-id="board_dxbd8zm7"
                    id="board-container"
                    className={classes.container}
                    ref={provided.innerRef}
                  >
                    <Grid
                      container
                      wrap="wrap"
                      direction="row"
                      alignItems="flex-start"
                      className={classes.listContainer}
                    >
                      {lists.map((list, index) => (
                        <Category list={list} key={list.id} classes={classes}/>
                      ))}
                      <CategoryAdder addList={func.addList} classes={classes} />
                    </Grid>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withErrorCatcher("Failed Loading Knowledge Board, Please Contact Tuqqi Support")
)(Board);
