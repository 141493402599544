import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
    container: {
        padding: '6px 18px',
        borderRadius: 6,
        zIndex: 2,
        backgroundColor: '#0e0e14',
        wordBreak: "break-all",
        textAlign: 'left'
    },
    containerForLongText: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
    },
    text: {
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
        fontFamily: 'Rubik',
        fontSize: 44,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.32,
        letterSpacing: 0,
        color: '#fff',
    },
    textSmall: {
        fontSize: 20,
    }
})
const GroupTitle = (props) => {
    const {styles, classes, currentScene} = props
    const [isTextBig, setIsTextBig] = useState(false)
    const [groupTitle, setGroupTitle] = useState('')
    const [messageTemplateParamsArray, setMessageTemplateParamsArray] = useState([])

    useEffect(() => {
        setMessageTemplateParamsArray(currentScene.messageTemplateParams)
    }, [currentScene])

    useEffect(() => {
        if(messageTemplateParamsArray.length) {
            setGroupTitle(messageTemplateParamsArray[messageTemplateParamsArray.length - 1])
        }
    }, [messageTemplateParamsArray])

    useEffect(() => {
        if(groupTitle && groupTitle.length > 16) {
            setIsTextBig(true)
        } else {
            setIsTextBig(false)
        }
    }, [groupTitle])

    if(!groupTitle) return null

    return <div data-intrcm-id="GroupTitle_hp4zioc7" style={styles.container} className={isTextBig ? `${classes.container} ${classes.containerForLongText}` : classes.container}>
            <div data-intrcm-id="GroupTitle_5z4y1bau" style={styles.text} className={isTextBig ? `${classes.text} ${classes.textSmall}` : classes.text}>
                {groupTitle}
            </div>
        </div>
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(GroupTitle)