import React, { useEffect, useState } from 'react'
import {withStyles, CircularProgress} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { seenStoryScene } from '@tuqqi/common'
import StoryControls from '../StoryControls/StoryControls'
import { randomID } from '../../../../utils/randomID'
import StorySceneAnim from './StorySceneAnim'

const styles = (theme) => ({
    container: {
        borderRadius: 8,
        height: 'fit-content',
        margin: 'auto',
        width: 'fit-content',
        position: 'relative',
        display: 'flex',
        backgroundColor: 'transparent'
    },
    textZone: {
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        marginTop: '60%',
        width: '100%',
    },
    indicator: {
        zIndex: 2,
        position: 'absolute',
        width: 'inherit'
    },
    storyNext: {
        position: 'absolute',
        bottom: '50%',
        width: 'fit-content',
        height: 'fit-content',
        left: 'auto',
        right: -80,
        cursor: 'pointer'
    },
    storyPrev: {
        position: 'absolute',
        bottom: '50%',
        width: 'fit-content',
        height: 'fit-content',
        right: 'auto',
        left: -80,
        cursor: 'pointer'
    },
    header: {
        zIndex: 2,
        position: 'absolute',
        width: '100%',
        height: 'fit-content',
        top: 40  
    },
    loader: {
        color: theme.customColors.primary.b300,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -15,
        marginLeft: -15
    },
    hidden: {
        display: 'none'
    }
})

const defaultCurrentScene = {id: -1}
const StoryScene = (props) => {
    const {classes, storiesNavigation, dispatch} = props
    const [key, setKey] = useState(randomID())
    const [isLoading, setIsLoading] = useState(true)
    const [currentScene, setCurrentScene] = useState(null)
    const {currentStoryId} = storiesNavigation
    const {id: sceneId} = currentScene ?? defaultCurrentScene

    useEffect(() => {
        if(sceneId === -1 || currentStoryId === '') return;
        dispatch(seenStoryScene(currentStoryId, sceneId))
    }, [sceneId, currentStoryId])

    useEffect(() => {
        if(currentStoryId === '') return;
        setKey(randomID())
    }, [currentStoryId])


    const loader = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 800)
    }

    useEffect(() => {
        loader()

        const { currentScene } = storiesNavigation

        setTimeout(() => {
            setCurrentScene(currentScene)
        , 400})

    }, [storiesNavigation])

    useEffect(() => {
        loader()
    }, [])

    if(sceneId === -1) return null;

    const wrapClasses = {
        indicator: classes.indicator,
        header: classes.header,
        textZone: classes.textZone,
    }

    return (
        <div data-intrcm-id="StoryScene_93okpbax" className={classes.container}>
            {isLoading && <CircularProgress className={classes.loader} size={60}/>}
           {currentScene ?  <div data-intrcm-id="StoryScene_gehpg1qv" className={isLoading ? classes.hidden : ''}>
                <StorySceneAnim currentScene={currentScene} key={key} wrapClasses={wrapClasses} />
                <StoryControls prevClass={classes.storyPrev} nextClass={classes.storyNext} />
            </div>: null }
        </div>
    )
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoryScene)