import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
    container: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
        wordBreak: 'break-all',
        textAlign: 'center'
    },
    containerBig: {
        '-webkit-line-clamp': 4,
    },
    text: {
        fontSize: 33
    },
    textBig: {
        fontSize: 16
    }
})

const DocTitle = (props) => {
    const {styles, classes, storiesNavigation } = props
    const {currentScene} = storiesNavigation
    const [commentText, setCommentText] = useState('')

    useEffect(() => {
        const params = currentScene.messageTemplateParams
        if(params.length) {
            setCommentText(params[2])
        }
    },[currentScene])

    return <div data-intrcm-id="CommentText_bk96i62n" style={styles.container} className={commentText.length > 24 ? `${classes.container} ${classes.containerBig}` :classes.container}>
        <div data-intrcm-id="CommentText_znsucjfu" style={styles.text} className={commentText.length > 24 ? `${classes.text} ${classes.textBig}` : classes.text}>
            {commentText}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DocTitle)