import React, { PureComponent } from 'react';

export const NoNotifsIcon = class NoNotifsIcon extends PureComponent {

    render() {
        return (
            <svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7773 0.017578C9.69386 0.398317 3.5 7.34339 3.5 15.3809V22.6484L0.849606 27.9805C0.844943 27.9902 0.840386 28 0.835934 28.0098C-0.230323 30.2773 1.4957 33 4.00195 33H12.5C12.5 36.2959 15.2041 39 18.5 39C21.7959 39 24.5 36.2959 24.5 33H32.9961C35.5023 33 37.2306 30.2778 36.1641 28.0098C36.1596 28 36.155 27.9902 36.1504 27.9805L33.5 22.6484V15C33.5 6.4938 26.3633 -0.386636 17.7773 0.017578ZM17.918 3.01367C24.838 2.68789 30.5 8.1622 30.5 15V23C30.4998 23.2317 30.5533 23.4604 30.6562 23.668L33.4492 29.2891C33.6288 29.674 33.421 30 32.9961 30H4.00195C3.57702 30 3.3709 29.6746 3.55078 29.2891V29.2871L6.34375 23.668C6.44672 23.4604 6.5002 23.2317 6.5 23V15.3809C6.5 8.88033 11.4794 3.31693 17.918 3.01367ZM15.5 33H21.5C21.5 34.6741 20.1741 36 18.5 36C16.8259 36 15.5 34.6741 15.5 33Z" fill="#DADDEE"/>
            </svg>
        )
    }
}