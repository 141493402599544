import { Grid, Tooltip, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import HelpButtonIcon from './HelpButtonIcon';

// Apply some reset
const styles = theme => ({
    container:{
        width: 'initial',
        display: 'inline-flex'
    },
    tooltip: theme.newUI.tooltip.container,
    popper: theme.newUI.tooltip.popper,
    iconContainer: {
        width: 'initial',
        cursor: 'pointer',
        margin: '0 8px'
    }
});

class HelpButtonTooltip extends PureComponent {
    render() {
        const { classes, t, link, children, customLink } = this.props;

        const tooltip = (
            <Tooltip
                classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                title={t('Learn more')}
                placement="bottom"
            >
                <Grid container alignItems='center' className={classes.iconContainer}>
                    <HelpButtonIcon customLink={customLink} link={link} classes={{ icon: classes.icon }} />
                </Grid>
            </Tooltip>
        )

        if (!children) {
            return tooltip
        }

        return (
            <Grid container direction='row' alignItems='center' className={classes.container}>
                {children}
                {tooltip}
            </Grid>
        )
    }

}


export default compose(withStyles(styles), withTranslation('userSettings'))(HelpButtonTooltip);
