import React from 'react';
import {withStyles} from '@material-ui/core';
import { compose } from 'recompose';

const styles = (theme) => ({
    icon: {
        position: 'absolute',
        zIndex:1,
        right: 75,
        top: -23
    },
})

const EconomyClassPlaneView = (props) => {
    const {classes, planeClasses} = props

    return (
        <img className={`${classes.icon} ${planeClasses}`} alt="plain" src={"/resources/subscription/airplane-economy-class.svg"}/>
    )
}

export const EconomyClassPlane = compose(
    withStyles(styles, {withTheme: true}),
)(EconomyClassPlaneView)