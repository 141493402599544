import { StatusColorMap } from '@tuqqi/common';
import { DateHelper } from '@bryntum/gantt';
import { managerGanttGlobalHeader, managerGroupReportView, putRowsFromFetch, managerCanUserEditRow, managerGroupGantt, managerRowMembers } from '@tuqqi/web';
import { managerGanttRef } from '@tuqqi/web'

const wrongDate = '0001-01-01T00:00:00Z'
const wrongDatePart = '0001-01-01'

export const runReportState = () => {
    if(!managerGanttRef.gantt) return
    const report = managerGroupReportView.getCustomSelReport()
    if(!report) return
    const state = report?.gantt?.state
    if(!state) return

    managerGanttRef.applyState(state)
    managerGanttRef.gantt.instance.scrollToNow({block: 'center', animate: false})
}


const getDate = (date) => {
    if(!date) return undefined
    if(typeof date === 'string' && (date === wrongDate || date.includes(wrongDatePart))) return undefined
    const d = new Date(date)

    return d.toISOString()
}

const getCLSColor = (taskColor) => {
    if(!taskColor && taskColor !== 0) return ''
    return `task-${taskColor}-color`
}

export const getMinimumStartDate = (items) => {

    const times = items.map(i => {
        if(!i.startDate) return i.startDate
        return typeof i.startDate === 'string' ? new Date(i.startDate).getTime() : i.startDate.getTime()
    })
    const min = Math.min(...times)
    return DateHelper.parse(new Date(min).toISOString())
}

let duplicated = []
export const getPrevWeekProjectStartDate = (items) => {
    const minDate = getMinimumStartDate(items)
    minDate.setDate(minDate.getDate() - 21);
    return minDate.toISOString()
}

export const getPrevWeek = (minDate) => {
    minDate.setDate(minDate.getDate() - 21);
    return minDate.toISOString()
}

export const extractCurrentReportExpanded = () => {
    const report = managerGroupReportView.getCustomSelReport()
    if(!report) return {}
    return report?.gantt?.expanded ?? {}
}

export const dontShowLaneIfEmpty = () => {
    const filters = managerGanttGlobalHeader.getKeep().globalFilters
    if(!!filters?.users?.length || 
        !!filters?.dataTypes?.length || 
        !!filters?.colors?.length || 
        !!filters?.laneStatusTitle?.length ||
        !!filters?.groupOfTasks?.length ||
        !!filters?.relatedDocIds?.length) return true
    return false
}

const parseProgress = (taskManualProgressIndicator) => {
    if(!taskManualProgressIndicator) return 0
    if(typeof taskManualProgressIndicator === 'number') return taskManualProgressIndicator
    if(Number(taskManualProgressIndicator) + '' === taskManualProgressIndicator + '') return Number(taskManualProgressIndicator)
    try {
        return eval(taskManualProgressIndicator)
    } catch (err) {
        return 0
    }
}
const resolveList = (itemList, laneData, tasksFromKanban, dependenciesData, resourcesData, onFilterUnassignTasks) => {
    duplicated = []
    const children = (itemList?.items ?? [])
        .filter((item) => {
            if(duplicated.includes(item.documentId)) return false
            duplicated.push(item.documentId)
            return true
        })
        .map((item) => {
            const { documentId, order } = item
            const task = tasksFromKanban.find(t => t.docId === documentId)
            if(!task) return undefined
            onFilterUnassignTasks(task)
        
            const { members = [], title, startDate, endDate, dependencies, taskDueDateDone, taskManualProgressIndicator = 0, } = task
            const _assignedResource = members.map(x => x.userUUID)

            const _deps = Array.from(new Set(dependencies ?? [])) 
            if(!!_deps?.length) {
                _deps.forEach((toTask) => {
                    dependenciesData.push({
                        id: `${documentId}_${toTask}`,
                        toEvent: toTask,
                        fromEvent: documentId
                    })
                })
            }
            const laneStatus = putRowsFromFetch.getLaneForDoc(documentId, managerGroupGantt.getKeepGroup() ?? undefined)
            const taskChild = {
                order,
                // isNew: false,
                id: documentId,
                assignedResource: [],
                deps: _deps,
                isItemRow: true,
                // isMilestone: true,
                statusTask: putRowsFromFetch.getTableRowStatus(laneStatus),
                percentDone: taskManualProgressIndicator,
                editable: {
                    common: managerCanUserEditRow.canUserEdit(documentId),
                    status: managerCanUserEditRow.canUserEdit(documentId, {isStatus: true}),
                    groupOfTask: managerCanUserEditRow.canUserEdit(documentId, {isStatus: false, isGroupOfTask: true}),
                },
                cls: getCLSColor(laneStatus.listColor ?? 0),
                name: title || 'Untitled',
                showInTimeline: false,
                manuallyScheduled: false,
                startDate:   DateHelper.parse(getDate(startDate)),
                constraintDate: getDate(startDate),
                constraintType: "startnoearlierthan",
                endDate:   DateHelper.parse(getDate(endDate)),
                expanded: false,
                scheduleUpdate: {
                    dueDateUTC: new Date(),
                    startDate: getDate(startDate), 
                    endDate: getDate(endDate), 
                    taskDueDateDone,
                    dependencies: _deps, 
                }
            }
            // taskChild.style = 'background-color: #953db8; color: #953db8; border-color: #953db8';

            if(!!_assignedResource?.length) {
                const filtered = _assignedResource
                    .map(x => resourcesData.find(res => res.uid === x))
                    .filter(x => !!x)

                taskChild.assignedResource = filtered
            }
            return taskChild
        })
        .filter(item => !!item)
        .sort((a, b) => a.order - b.order)
    laneData.children = children
    // laneData.style = `background-color: ${laneData.laneColor}`;
    
    return laneData
}

export const dtoGanttSingleEventData = (context) => (tasksFromKanban = [], minStartDate) => {
    const {
        ganttRef,
        isInitial,
        t,
        collectionInfo,
        cloudfront,
    } = context
    if(!collectionInfo) return {
        resourcesData: [],
        eventsData: [],
        dependenciesData: [],
    }
    const {title, collectionUid, collectionGroup, newGroupTasksTitle, newColumnTitle, groupDefaultStatusColor} = collectionInfo

    const defaultGroupOfTasks = (!newGroupTasksTitle || newGroupTasksTitle === "Unassigned tasks group") ? t("Unassigned tasks group") : newGroupTasksTitle
    const groupUsers = collectionGroup?.groupUsers ?? []
    const ganttCollection = (collectionInfo.collectionViews || []).find(c => c.collectionViewType === "Gantt");
    const itemLists = ganttCollection?.itemLists ?? [];
    
    let unassignedGroupOfTasksItems = [...tasksFromKanban]
    const onFilterUnassignTasks = (task) => {
        unassignedGroupOfTasksItems = unassignedGroupOfTasksItems.filter(x => x.docId !== task.docId)
    }

    const resourcesData = managerRowMembers.collectAllMembers(collectionInfo ?? managerGroupGantt.getKeepGroup(), {withTeams: true, ignoreMe: true}).filter(x => !x.isTeam)
    const dependenciesData = []

    const exp = extractCurrentReportExpanded()

    const store = ganttRef?.current?.instance?.project?.eventStore


    const checkRootExp = () => {
        if(isInitial && !!(collectionUid in exp)) return exp[collectionUid] ?? true
        return true
    }

    const checkLaneExp = (rowKeep, id) => {
        if(isInitial && !!(id in exp)) return exp[id]

        return !!rowKeep ? rowKeep.isExpanded(store) : false
    }

    const eventsData = [{
        isRootRow: true,
        id : collectionUid,
        // isNew: false,
        name : title,
        expanded : checkRootExp(),
        cls: 'root-row',
        children : []
    }]

    itemLists.forEach((itemList) => {
        const rowKeep = store?.getById?.(itemList.id)

        const laneData = {
            order: itemList.order,
            id: itemList.id,
            // isNew: false,
            laneColor: StatusColorMap[itemList.listColor],
            name: itemList.title || 'Untitled',
            isLane: true,
            expanded: checkLaneExp(rowKeep, itemList.id),
        }
        const newLaneData = resolveList(itemList, laneData, tasksFromKanban, dependenciesData, resourcesData, onFilterUnassignTasks)
        const allowPush = newLaneData.children.length ? true : !dontShowLaneIfEmpty()
        if(allowPush) eventsData[0].children.push(newLaneData)
    })
    //default lane
    const rowKeepDefault = store?.getById?.(-1)

    const defaultLaneData = {
        order: -1,
        id: -1,
        laneColor: 0,
        // isNew: false,
        name: defaultGroupOfTasks,
        isLane: true,
        expanded: checkLaneExp(rowKeepDefault, -1),
    }

    const defaultItemList = {
        id: -1,
        title: defaultGroupOfTasks,
        order: -1,
        rules: [],
        listColor: 0,
        // isNew: false,
        items: unassignedGroupOfTasksItems.map((x, i) => ({collectionViewListId: -1, documentId: x.docId, order: i})),
    }

    const newDefaultLaneData = resolveList(defaultItemList, defaultLaneData, unassignedGroupOfTasksItems, dependenciesData, resourcesData, () => {})
    const allowUnshift = newDefaultLaneData.children.length ? true : !dontShowLaneIfEmpty()
    if(allowUnshift) eventsData[0].children.unshift(newDefaultLaneData)

    eventsData[0].children.sort((a, b) => a.order - b.order)
    eventsData[0].startDate = getPrevWeek(minStartDate)
    return {
        fromInitial: isInitial,
        resourcesData,
        eventsData,
        dependenciesData,
    }
}

export const getStartEndDateForParent = (items = []) => {
    if(!items.length) return [null, null]
    let startDate = items[0].startDate;
    let endDate = items[0].endDate;
      
    for (let i = 0; i < items.length; i++) {
        const task = items[i];
        if (!!task.startDate && (new Date(startDate)).getTime() > (new Date(task.startDate)).getTime()) {
            startDate = task.startDate;
        }
        if (task.endDate && (new Date(endDate)).getTime() < (new Date(task.endDate)).getTime()) {
            endDate = task.endDate;
        }
    }
    return [startDate, endDate];
}