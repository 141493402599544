import React, { PureComponent } from 'react';

export default class OpenGroupIcon extends PureComponent {

    render() {
        const { className } = this.props
        return (
            <svg className={className} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <rect fill="#A654C6" width="40" height="40" rx="20"/>
                    <path d="M18.516 10.214c2.44-.639 5 .475 6.221 2.707a.808.808 0 0 1-.013.8.788.788 0 0 1-.69.387.789.789 0 0 1-.678-.411 3.932 3.932 0 0 0-4.444-1.935c-1.744.456-2.963 2.048-2.962 3.871v.8h7.9c2.18.002 3.947 1.79 3.95 3.996v4.797c-.003 2.206-1.77 3.994-3.95 3.996h-7.9c-2.18-.002-3.947-1.79-3.95-3.996v-4.797a3.999 3.999 0 0 1 2.37-3.658v-1.138c0-2.552 1.704-4.78 4.146-5.42zm5.334 7.817h-7.9c-1.309 0-2.37 1.074-2.37 2.398v4.797c0 1.324 1.061 2.398 2.37 2.398h7.9c1.308 0 2.37-1.074 2.37-2.398v-4.797c0-1.324-1.062-2.398-2.37-2.398zM19.9 21.23c.436 0 .79.358.79.8v1.598c0 .441-.354.8-.79.8a.795.795 0 0 1-.79-.8v-1.599c0-.441.353-.8.79-.8z" fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".4"/>
                </g>
            </svg>
        )
    }
}
