import { withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { LaunchpadSelectedCategory } from './LaunchpadSelectedCategory';
import { OrgCategoryLaunchpadHeader } from './OrgCategoryLaunchpadHeader';

const styles = (theme) => ({
    container: {
        ...theme.launchpadPageWrap,
    },
})

function _LaunchpadOrgCategory(props) {
    const {classes} = props

    return (
        <div data-intrcm-id="LaunchpadOrgCategory_icnx3dd1" className={classes.container} >
            <OrgCategoryLaunchpadHeader />
            <LaunchpadSelectedCategory />
        </div>
    )
}

export const LaunchpadOrgCategory = compose(
    withStyles(styles, {withTheme: true})
)(_LaunchpadOrgCategory)

