import { Grid, Icon, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { SORT_ORDER } from '../../../../reducers/searchReducer';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';

const styles = theme => ({
    container: {
        backgroundColor: 'rgb(244,245,251)', 
        zIndex: 3,
        position: 'sticky',
        top: 0,
        padding:0,
        "height": "53px",
        paddingLeft: 32,
        cursor:'pointer',
        '&:last-child':{
            paddingRight: 16,
            width: '100%'
        },
        '& .sortIcon': {
            visibility: 'hidden'
        },
        '&:hover': {
            '& .sortIcon': {
                visibility: 'visible'
            }
        }
    },
    titleText: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.gray
    },
    icon: {
        "color": theme.newUI.color.gray,
        fontSize: 14,
        marginLeft: 4
    }
})

class HeaderTableCell extends Component {

    state = {
        sortOrder: '',
        isMouseHover: false,
    }

    onSort = () => {
        const { column, onSort  } = this.props;
        onSort(column)
    }


    getSortedIcon() {
        const { column, sortedColumn, sortOrder } = this.props;
        
        let sortedIcon
        if (sortedColumn !== column && !this.state.isMouseHover) {
            sortedIcon = null
        }
        else if (sortOrder === SORT_ORDER.Ascending) {
            sortedIcon = 'arrow_upward'
        }
        else {
            sortedIcon = 'arrow_downward'
        }

        return sortedIcon
    }


    cutText(text = '') {
        const suffix = text.length > 60 ? '...' : ''

        return text.substr(0, 60) + suffix
    }


    
    render() {
        const { classes, t, column, sortedColumn, sortOrder } = this.props;
        const { fieldName, dataType, className } = column;


        const isColumnSorted = sortedColumn === column
        const sortIcon = (isColumnSorted && (sortOrder === SORT_ORDER.Ascending) ? 'arrow_upward' : 'arrow_downward')

        return (
            <th className={classnames(classes.container, (dataType === 'dateTime' && classes.dateTime), className)}>
                <Grid onClick={this.onSort} container alignItems='center' item wrap='nowrap'>
                    <span className={classes.titleText} dir='auto'>{t(this.cutText(fieldName))}</span>
                    <Icon className={classnames(classes.icon, !isColumnSorted && 'sortIcon') }>{sortIcon}</Icon>
                </Grid>
            </th>
        )
    }

}



export default compose(withStyles(styles),
withErrorCatcher(),
    withTranslation('headerTableCellTranslate'))(HeaderTableCell);