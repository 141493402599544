import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import Search from './search'
import Avatar from '@material-ui/core/Avatar';
import AppsIcon from '@material-ui/icons/Apps';
import { NotificationsNone } from '@material-ui/icons'
import { push } from 'react-router-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';



//const animationName = 'rag-fadeIn'
const styles = theme => ({
    // root: {
    //     height: '40px',
    //     width: '100%',
    // },

});

class Header extends Component {

    render() {
        const { classes, userData, wrapper, dispatch, location } = this.props;

        return (
            <div data-intrcm-id="headerMobile_i5b2ukxs" className={classes.root}>
                <AppBar className={classes.appBar} color="inherit" elevation={2}>
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                            
                        <IconButton
                        onClick={this.openMenu}
                        style={{ padding: 6, marginRight:6 }}
                        aria-label="More"
                        aria-haspopup="true">

                        <MoreHorizIcon />
                    </IconButton> 

                        </IconButton>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Tuqqi
                        </Typography>
                    </Toolbar>
                </AppBar>


            </div>
        );
    }
    // }
}


const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    wrapper: state.wrapper,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Header);
