import { Grid, InputBase, withStyles, InputLabel } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import withErrorCatcher from "../Common/withErrorCatcher.js";
import ListHeaderContainer from "./listHeaderContainer.js";

const CustomInput = withStyles((theme) => ({
  input: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    letterSpacing: "0.1px",
    textAlign: "center",
    marginTop: "-10px",
    color: "#b9b9bd",
    "&::placeholder": {
      color: "#b9b9bd",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  adderHolder: {
    borderRadius: 2,
    border: "dashed 2px #e1e2e6",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  inputContainer: {
    marginLeft: 0,
    paddingLeft: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#fff",
  },
  actionContainer: {
    marginTop: 8,
    position: "absolute",
    bottom: 5,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 5px",
  },
  addButton: {
    borderRadius: "4px",
    border: `solid 1px ${theme.newUI.color.primary}`,
    padding: "4px 8px",
    marginRight: 13,
    color: theme.newUI.color.primary,
  },
  inputLabel: {
    display: "block",
    cursor: "pointer",
  },
  categoryAdderCancel: {
    color: '#a654c6',
  }
});

class ListAdder extends React.Component {
  state = {
    title: "",
  };

  inputRef = React.createRef();

  edit = (e) => {
      this.setState({ title: e.target.value });
  };

  addList = () => {
      this.props.addList(this.state.title, this.state.description);
      this.setState({ title: "" });
  };

  cancelAdd = () => {
    this.setState({ title: "" });
  };

  onKeyDown = (e) => {
    const enterKeyCode = 13;
    if (e.keyCode === enterKeyCode) {
      this.addList();
    }
  };

  render() {
    const { title, disabled } = this.state;
    const { classes, t } = this.props;

    const placeholder = "Add Category";

    return (
      <div data-intrcm-id="categoryAdder_91oxir1u" className={classes.item}>
        <div data-intrcm-id="categoryAdder_c72169be" className={classes.itemInner}>
          <div data-intrcm-id="categoryAdder_gldtosu1" className={classes.adderHolder}>
            <ListHeaderContainer
              classes={{ container: classes.inputContainer }}
            >
              <InputLabel
                shrink
                htmlFor="AdderInput"
                className={classes.inputLabel}
                onClick={() => this.inputRef.current.focus()}
              >
                <AddIcon style={{ color: "#b9b9bd", fontSize: "70px" }} />
              </InputLabel>
              <CustomInput
                disabled={disabled}
                autoFocus={false} //important
                inputRef={this.inputRef}
                className={classes.input}
                placeholder={t(placeholder)}
                onChange={this.edit}
                onKeyDown={this.onKeyDown}
                value={this.state.title}
                name="AdderInput"
              />
            </ListHeaderContainer>

            {Boolean(title) && (
              <Grid
                item
                container
                alignItems="center"
                className={classes.actionContainer}
              >
                <Grid onClick={this.addList} item className={classes.addButton}>
                  {t("Add")}
                </Grid>
                <CancelIcon
                  onClick={this.cancelAdd}
                  className={classes.categoryAdderCancel}
                />
              </Grid>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withErrorCatcher(),
  withTranslation("knowledgeTranslate"),
  withErrorCatcher("Failed Loading, Please Contact Tuqqi Support"),
  withStyles(styles)
)(ListAdder);
