import React, { useCallback, useMemo } from 'react'
import { push } from 'react-router-redux';
import { showSnackbar } from '../../../../../actions/appActions';
import { checkIfSubscribed } from '../../../../../utils/usersHelpers';
import { mixpanelTrack } from '../../Utils/mixpanelUtils';

const getAdditionalOptions = (ctx) => {
    const { t, isSubscribed, onClickGeneralSubscribe, openChat, isRemoved } = ctx

    const opts = [
        {
            id: 7,
            icon: 'Message',
            title: t("Message"),
            onClick: () => {
                openChat()
            }
        },
        {
            id: 8,
            icon: isSubscribed ? 'Unsubscribe' : 'Subscribe',
            title: isSubscribed ? t("Unsubscribe") : t("Subscribe"),
            onClick: () => {
                onClickGeneralSubscribe()
            }
        },
    ]

    if(isRemoved) return []

    return opts
}

export const useGetUserAdditionalOptions = (props) => {
    const { 
        t,
        user,
        userData,
        activeUsers,
        isRemoved,
        dispatch,
        userSettingsService,
        chatService,
     } = props;

    const openChat = useCallback(() => {
        if(!!isRemoved) return
        if (!user) return
        mixpanelTrack(`User page view - message`, {})
        const { auth0_id } = user
        chatService.openNewChat(auth0_id).then(res => {
          const { chat } = res
          if (!chat || !chat.chatUId) {
            dispatch(push({ pathname: "chat" }))
            return
          }
          dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: res
          })
          dispatch(push(`/chat?chatid=${chat.chatUId}`))
        })
    }, [user])

    const isSubscribed = useMemo(() => !!user ? checkIfSubscribed(user.tag, userData) : false, [user, userData])
    
    const subscribe = useCallback((auth0_id) => {
        if(!!isRemoved) return

        const user = activeUsers.find(user => user.auth0_id === auth0_id)
        if (!user) return;
    
        dispatch(showSnackbar('subscribing...'));
        userSettingsService
            .subscribe(user)
            .then((_) => {
                userSettingsService.getUserData().then((userData) => {
                    dispatch(showSnackbar('Subscribed successfully!'));
                    dispatch({
                        type: 'USER_DATA_LOAD', data: {
                        ...userData
                        }
                    });
                })
            });
    }, [activeUsers])

    const unsubscribe = useCallback((tagUser) => {
        if(!!isRemoved) return

        const subscribedTag = userData.subscribedTags.find(tag => tag.tag.toLowerCase() === tagUser.toLowerCase());
        dispatch(showSnackbar('unsubscribing...'));
        userSettingsService.unsubscribe(subscribedTag.id).then(_ => {
            userSettingsService.getUserData().then((userData) => {
                dispatch(showSnackbar('Unsubscribed successfully!'));
                dispatch({
                type: 'USER_DATA_LOAD', data: {
                    ...userData
                }
                });
            })
        })
    }, [userData])

    const onClickGeneralSubscribe = useCallback(() => {
        if(!!isRemoved) return

        mixpanelTrack(`User page view - subscribe/unsubscribe`, {})
        if (isSubscribed) {
          unsubscribe(user.tag)
          return
        }
        subscribe(user.auth0_id)
    }, [isSubscribed, subscribe, unsubscribe, user])

    const options = useMemo(() => {
        return getAdditionalOptions({ 
            t, 
            isSubscribed,
            isRemoved,
            onClickGeneralSubscribe,
            openChat,
        })
    }, [isSubscribed, onClickGeneralSubscribe, openChat, isRemoved])

    return [options]
}