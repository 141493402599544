import { Paper, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import Header from '../../../../components/App/PageComponents/pageContentHeader';
import {
	editContainer, editField, editSection, handleChangeAboveWorkflow, handleChangeWorkflow,
	onCloseEditAllFieldStyles, onCloseEditSection, onCloseNewContainer, onCloseNewField, onDeleteContainer,
	onDeleteField, onDeleteSection, saveMapping
} from '../customInputTemplateUtils';
import CreateCustomInputAllSettings from '../components/CreateCustomInputAllSettings';
import EditAllFieldStyles from '../components/editAllFieldStyles';
import EditSectionModal from '../components/editSectionModal';
import NewContainerModal from '../components/newContainerModal';
import NewFieldModal from '../components/newFieldModal';
import DisplayOrderCreateCustomInput from '../components/DisplayOrderCreateCustomInput';
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import AutomaticTitleOrderCreateCustomInput from '../components/AutomaticTitleOrderCreateCustomInput';
import DisplayDummyJsonCustomInput from '../components/DisplayDummyJsonCustomInput';
import DefaultChecklistsCustomInput from '../components/DefaultChecklistsCustomInput';
import { withTranslation } from 'react-i18next';
import { OldPagesWrap } from '../../newway/OldPagesWrap';
import { managerAdminDrawer } from '@tuqqi/web';

const styles = theme => ({
	container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
	paper: {
		padding: 30
	},
	section: {
		padding: 15,
		marginBottom: 5
	},
	divid: {
		marginTop: 20,
		marginBottom: 20,
		backgroundColor: 'rgba(0, 0, 0, 0.1)'
	},
	saveButton: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 15
	},
	buttonIc: {
		marginTop: 8,
		fontSize: 14,
		display: 'block',
		textTransform: 'none'
	},
	editButton: {
		marginLeft: 'auto',
		width: 'fit-content'
	},
})

export class CreateCustomInputTemplatePage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			isNewTemplate: true,
			customFieldsMappings: [],
			defaultCollectionUid: null,
			specificGroupsUids: null,
			title: '',
			dataTypeFilter: 'data_type_' + (new Date()).getTime(),
			description: '',
			icon: '',
			enableSettings: {
				isEnableFiles: false,
				isEnableTags: true,
				hasCoverPicture: true,
				isEnableLinks: true,
				isEnableContact: false,
				isEnableLocation: false,
				isEnableCompany: false,
				isEveryoneCanEdit: false,
				enablePublishingToPublicFeed: false,
				isEnableDueDate: false,
				isDueDateMandatory: false,
				isEnableWorkflow: false,
				isAboveWorkflow: false,
				isAutomaticTitle: false,
				isWithoutSeparateTitleByCommas: false,
				isRtl: false,
				isBusinessIdEnabled: false,
				isEnableRating: false,
			},
			openNewContainer: false,
			containers: [],
			openEditSection: false,
			backgroundImageUrl: '',

			quickActionText: '',
			triggeredCustomInput: '',
			checklists: []
		}

		this.onCloseNewContainer = onCloseNewContainer.bind(this);
		this.editContainer = editContainer.bind(this);
		this.onDeleteContainer = onDeleteContainer.bind(this);

		this.onCloseNewField = onCloseNewField.bind(this);
		this.editField = editField.bind(this);
		this.onDeleteField = onDeleteField.bind(this);

		this.onCloseEditSection = onCloseEditSection.bind(this);
		this.editSection = editSection.bind(this);
		this.onDeleteSection = onDeleteSection.bind(this);

		this.saveMapping = saveMapping.bind(this);
		this.onCloseEditAllFieldStyles = onCloseEditAllFieldStyles.bind(this);
	}

	handleChange = (name, idx) => (e) => {
		if (idx == undefined) {
			this.setState({ [name]: e.target.value })
		} else {
			this.state.customFieldsMappings[idx][name] = e.target.value
			this.setState({ customFieldsMappings: [...this.state.customFieldsMappings] })
		}
	}

	handleChangeChecklists = (checklists) => {
		this.setState({ checklists })
	}

	changeEnable = (name) => () => {
		const newVal = !this.state.enableSettings[name]
		this.setState({
			enableSettings: {
				...this.state.enableSettings,
				[name]: newVal,
				...(name === 'isEnableDueDate' && !newVal ? {isDueDateMandatory: false} : {})
			}
		})
	}

	componentWillMount() {
		const { mappings, goTo } = this.props;

		if (!mappings) {
			goTo({ pathname: '/admin/custominputmappings' })
		}
		else if (mappings.customDataMapping) {
			const { customDataMapping } = mappings;

			const { checklists } = mappings
			let parsedCheckLists = null
			if (checklists) {
				parsedCheckLists = JSON.parse(checklists)
			}


			this.setState({

				checklists: parsedCheckLists?.checklists ?? [],
				customFieldsMappings: [...customDataMapping.customFieldsMappings]
			})
		}
	}

	componentDidMount() {
		const { mappings, goTo } = this.props;

		managerAdminDrawer.setOption({
			'prop': 'input-settings',
			uid: 'item'
		})

		if (!mappings) {
			goTo({ pathname: '/admin/custominputmappings' })
		}
		else {
			const { title, description, icon, customDataMapping, id, triggeredCustomInput, quickActionText, defaultCollectionUid, specificGroupsUids } = mappings
			const { dataTypeFilter } = customDataMapping

			let isNewTemplate = true

			if (title != '') {
				isNewTemplate = false
			}

			this.setState({
				id, isNewTemplate, title, icon, description, dataTypeFilter,
				defaultCollectionUid,
				specificGroupsUids,
				backgroundUrl: mappings.backgroundUrl || this.state.backgroundUrl,

				enableSettings: {
					isEnableFiles: this.isEnable('isEnableFiles'),
					isEnableTags: this.isEnable('isEnableTags'),
					hasCoverPicture: this.isEnable('hasCoverPicture'),
					isEnableLinks: this.isEnable('isEnableLinks'),
					isEveryoneCanEdit: this.isEnable('isEveryoneCanEdit'),
					isEnableContact: this.isEnable('isEnableContact'),
					isEnableCompany: this.isEnable('isEnableCompany'),
					isEnableLocation: this.isEnable('isEnableLocation'),
					enablePublishingToPublicFeed: this.isEnable('enablePublishingToPublicFeed'),
					isEnableDueDate: this.isEnable('isEnableDueDate'),
					isDueDateMandatory: this.isEnable('isDueDateMandatory'),
					isEnableWorkflow: this.isEnable('isEnableWorkflow'),
					isAboveWorkflow: this.isEnable('isAboveWorkflow'),
					isAutomaticTitle: this.isEnable('isAutomaticTitle'),
					isWithoutSeparateTitleByCommas: this.isEnable('isWithoutSeparateTitleByCommas'),
					isRtl: this.isEnable('isRtl'),
					isBusinessIdEnabled: this.isEnable('isBusinessIdEnabled'),
					isEnableRating: this.isEnable('isEnableRating'),
				},

				triggeredCustomInput,
				quickActionText
			})
		}
	}

	isEnable(name) {
		let res;

		if (this.props.mappings[name] === undefined || this.props.mappings[name] === null) {
			res = this.state.enableSettings[name]
		} else {
			res = this.props.mappings[name]
		}

		return res
	}

	onLoadImg = (backgroundUrl) => {
		this.setState({ backgroundUrl })
	}

	changeAboveWorkflow = () => {
		this.setState({
			enableSettings: {
				...this.state.enableSettings,
				isAboveWorkflow: !this.state.enableSettings.isAboveWorkflow,
				...handleChangeAboveWorkflow()
			},
			quickActionText: '',
			triggeredCustomInput: '',
		})
	}

	changeWorkflow = () => {
		if (this.state.enableSettings.isEnableWorkflow) {
			this.setState({
				quickActionText: '',
				triggeredCustomInput: ''
			})
		}

		this.setState({
			enableSettings: {
				...this.state.enableSettings,
				isEnableWorkflow: !this.state.enableSettings.isEnableWorkflow,
				...handleChangeWorkflow()
			}
		})
	}

	renderFields = (fields, containerIdx, sectionIdx) => {
		const { classes } = this.props;

		return fields && fields.sort((a, b) => a.fieldOrder - b.fieldOrder).map(field => {
			let fullField = this.state.customFieldsMappings.find(f => f.sourceField === field.sourceField);

			if (fullField) {
				fullField.textStyleCustom = field.style;
				fullField.titleStyleCustom = field.titleStyle;

				return <Button variant="raised" className={classes.buttonIc}
					onClick={() => this.editField(fullField, containerIdx, sectionIdx)} >
					{`${fullField.fieldName} (${fullField.sourceField}) - ${fullField.type}`}
				</Button>
			}
			else {
				return <div data-intrcm-id="createCustomInputTemplate_6lm6kk7g" />
			}
		})
	}

	renderContainers = () => {
		const { classes,t } = this.props;

		return this.state.containers.map((container, containerIdx) => {
			return <Paper classes={{ root: classes.section }} elevation={1}>
				<div data-intrcm-id="createCustomInputTemplate_80n9n24j" className={classes.editButton}>
					<Button variant="raised" aria-label="Add" color="primary" className={classes.editButton}
						onClick={() => this.onDeleteContainer(containerIdx)} >
						{t('Delete')}
					</Button>
				</div>
				{container.title && <Typography {...container.titleStyle} >{container.title}</Typography >}

				<Grid container {...container.gridStyle} >
					{
						container.sections.map((section, sectionIdx) => {
							return <Grid item xs={section.gridSize} {...section.gridStyle} >
								<Paper classes={{ root: classes.section }} elevation={1} >
									<div data-intrcm-id="createCustomInputTemplate_97cklyvc" className={classes.editButton}>
										<Button disabled variant="raised" aria-label="Add" color="primary"
											onClick={() => this.editSection(sectionIdx, section, containerIdx)} >
											{t('Edit')}
										</Button>
									</div>
									{section.title && <Typography {...section.titleStyle} >{section.title}</Typography >}

									{this.renderFields(section.fields, containerIdx, sectionIdx)}
									{this.addButton(() => { this.setState({ openNewField: true, containerIdx, sectionIdx }) }, t('Field'))}
								</Paper>
							</Grid>
						})
					}
				</Grid>
			</Paper>
		})
	}

	addButton = (func, name) => {
		const { classes,t } = this.props;

		return <div data-intrcm-id="createCustomInputTemplate_qru40sav" style={{ width: 100, margin: '0px auto' }}>
			<Button variant="raised" aria-label={t("Add")} color="primary" className={classes.buttonIc} onClick={func} >
				{t('Add')} {name}
			</Button>
		</div>
	}

	addRow = () => {
		const newContainer = {
			sections: [{ gridSize: 12 }]
		}

		this.setState({
			openNewContainer: false,
			containers: [...this.state.containers, newContainer],
			container: null,
			containerIdx: null
		})
	}

	onDeleteContainer = (containerIdx) => {
		let containers = [...this.state.containers];

		containers.splice(containerIdx, 1);

		this.setState({ openNewContainer: false, containers: [...containers], container: null, containerIdx: null })
	}

	onDisplayOrderChange = (sourceField, previewDisplayOrder) => {
		const fieldToUpdateIndex = this.state.customFieldsMappings
			.findIndex(field => field.sourceField === sourceField)

		this.state.customFieldsMappings[fieldToUpdateIndex] = {
			...this.state.customFieldsMappings[fieldToUpdateIndex],
			previewDisplayOrder: previewDisplayOrder
		}

		this.setState({
			customFieldsMappings: [...this.state.customFieldsMappings]
		})
	}

	onAutomaticTitleOrderChange = (sourceField, previewDisplayOrder) => {
		const fieldToUpdateIndex = this.state.customFieldsMappings
			.findIndex(field => field.sourceField === sourceField)

		this.state.customFieldsMappings[fieldToUpdateIndex] = {
			...this.state.customFieldsMappings[fieldToUpdateIndex],
			automaticTitleOrder: previewDisplayOrder
		}

		this.setState({
			customFieldsMappings: [...this.state.customFieldsMappings]
		})
	}
	isBusinessIdFieldEnabled = () => {
		const { customFieldsMappings } = this.state
		return !!((customFieldsMappings || []).find(mapping => mapping.type === 'businessId'))
	}
	isBusinessIdFormEnabled = () => {
		const { enableSettings } = this.state
		return enableSettings.isBusinessIdEnabled
	}
	setDefaultCollectionUid = (defaultCollectionUid) => {
		this.setState({defaultCollectionUid})
	}
	setSpecificGroupsUids = (specificGroupsUids) => {
		this.setState({specificGroupsUids})
	}

	render() {
		const { classes, mappings,t } = this.props;
		const { title, isNewTemplate, containers, enableSettings, customFieldsMappings, checklists } = this.state

		let pageTitle = {
			title: isNewTemplate ? t('New input') : t('Edit ') + title,
			subtitle: isNewTemplate ? t('Create new input') : t('Edit'),
			avatar: <ListItemIcon><AddCircle style={{ height: 48, width: 48 }} /></ListItemIcon>,
			description: ''
		}

		return (<OldPagesWrap className={classes.container} classNameContent={classes.content} >
					<Paper className={classes.paper} elevation={1}>
						<Grid container spacing={16} justify="space-between">
							<Grid item md={8}>
								<Header {...pageTitle} classes={classes} />
							</Grid>
							<Grid md={4}>
								<Button variant="raised" onClick={() => { this.props.mixpanelTrack('Create custom input'); this.saveMapping() }} className={classes.saveButton}>
									{t('Save changes')}
								</Button>
							</Grid>
						</Grid>

						<Divider className={classes.divid} />

						<form className={classes.root} autoComplete="off">
							<CreateCustomInputAllSettings
								{...this.state}
								setDefaultCollectionUid={this.setDefaultCollectionUid}
								setSpecificGroupsUids={this.setSpecificGroupsUids}
								isBusinessIdFieldEnabled={this.isBusinessIdFieldEnabled()}
								handleChange={this.handleChange}
								onLoadImg={this.onLoadImg}
								changeEnable={this.changeEnable}
								changeAboveWorkflow={this.changeAboveWorkflow}
								changeWorkflow={this.changeWorkflow}
							/>

							<Divider className={classes.divid} />

							<Button disabled variant="raised" onClick={() => this.setState({ openEditAllFieldStyles: true })} className={classes.saveButton}>
								{t('Edit all fields style')}
							</Button>

							{/* {containers ? this.renderContainers() : this.renderFields(customFieldsMappings)} */}
							{this.renderFields(customFieldsMappings)}

							{/* {this.addButton(this.addRow, 'Row')} */}
							{this.addButton(() => { this.setState({ openNewField: true }) }, t('Field'))}
							<DisplayDummyJsonCustomInput
								customFieldsMappings={customFieldsMappings}
							/>
							<DefaultChecklistsCustomInput
								handleChangeChecklists={this.handleChangeChecklists}
								initialChecklists={checklists}
							/>
							<DisplayOrderCreateCustomInput
								customFieldsMappings={customFieldsMappings}
								onChange={this.onDisplayOrderChange}
							/>
							{enableSettings.isAutomaticTitle ? <AutomaticTitleOrderCreateCustomInput
								customFieldsMappings={customFieldsMappings}
								onChange={this.onAutomaticTitleOrderChange}
							/> : null}
						</form>
					</Paper>

			<NewContainerModal
				open={this.state.openNewContainer}
				onClose={this.onCloseNewContainer}
				containerIndex={this.state.containerIdx}
				container={this.state.container}
				onDelete={this.onDeleteContainer} />

			<EditSectionModal
				open={this.state.openEditSection}
				onClose={this.onCloseEditSection}
				sectionIndex={this.state.sectionIdx}
				containerIndex={this.state.containerIdx}
				section={this.state.section}
				onDelete={this.onDeleteSection} />

			<NewFieldModal
				customFieldsMappings={customFieldsMappings}
				isBusinessIdExists={this.isBusinessIdFormEnabled() || this.isBusinessIdFieldEnabled()}
				open={this.state.openNewField}
				field={this.state.field}
				containerIndex={this.state.containerIdx}
				sectionIndex={this.state.sectionIdx}
				onClose={this.onCloseNewField}
				onDelete={this.onDeleteField}
				fieldsCount={this.state.customFieldsMappings.length} />

			<EditAllFieldStyles
				open={this.state.openEditAllFieldStyles}
				onClose={this.onCloseEditAllFieldStyles} />
		</OldPagesWrap>);
	}
}

const mapStateToProps = (state) => ({
	customInputService: state.services.customInputService,
	globalData: state.globalData,
	mappings: state.customInputMapping.currentMapping,
	idToken: state.userData.idToken
})

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch,
		goTo: path => { dispatch(push(path)) }
	}
}

export default compose(
	withStyles(styles),
	withTranslation('admin'),
	withMixpanel,
	connect(mapStateToProps, mapDispatchToProps)
)(CreateCustomInputTemplatePage);