export function onTaskAdd(task) {
    this.props.changeCounter && this.props.changeCounter('taskCounter', 1)

    this.setState({ tasks: [...this.state.tasks, task] })
    this.props.dispatch({ type: 'DATA_WIDGET' })
}

export function onTaskUpdate(task) {
    // Remove the task from the social bar
    const newNum = task.isDone ? 1 : -1
    this.props.changeCounter && this.props.changeCounter('taskDoneCounter', newNum)

    const taskIndex = this.state.tasks.findIndex(t => t.key == task.key)
    this.state.tasks[taskIndex] = task
    this.setState({ tasks: [...this.state.tasks] });
    this.props.dispatch({ type: 'DATA_WIDGET' })
}

export function onTaskDelete(task) {
    // Remove the task from the social bar
    this.props.changeCounter && this.props.changeCounter('taskCounter', -1)

    const tasks = this.state.tasks.filter(t => t.key != task.key);
    this.setState({ tasks });
    this.props.dispatch({ type: 'DATA_WIDGET' })
}

const regTask = /^\s*todo\s*:\s*/i
export function isTask(text) {
    return regTask.test(text)
}

export function getTaskText(text) {
    return text.replace(regTask, '')
}