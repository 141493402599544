import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = theme => ({
    avatar: {
        width: 48,
        height: 48
    },
    header: {
        margin: 0
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    }
});

export default compose(withStyles(styles),withTranslation('admin'))((props) => {
    const { title, subtitle, avatar, description, classes,t } = props;

    return <Grid container spacing={16} className={classes.header} spacing={16}>
        <Grid item>
            <div data-intrcm-id="pageContentHeader_gigt5gb2" className={avatar}>
                {avatar}
            </div>
        </Grid>

        <Grid item >
            <Typography variant="body2" >
                {t(title)}
            </Typography>
            <Typography variant="caption" >
                {t(subtitle)}
            </Typography>
            <Typography variant="caption">
                {t(description)}
            </Typography>
        </Grid>

        <Grid md={3}></Grid>

        <Grid item alignItems={'flex-end'}></Grid>
    </Grid>
})