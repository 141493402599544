

import React, { PureComponent } from 'react';

export default class CancelIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4 4L8 8M12 12L8 8M8 8L12 4M8 8L4 12" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }
}