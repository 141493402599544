import React, { Component } from 'react';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js'
import { push } from 'react-router-redux';
import moment from 'moment'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminSettingsTitle from '../../Common/AdminSettingsTitle.js';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    activeIcon: {
        margin: '20px 30px 20px 0px',
        padding: 3,
        borderRadius: '9%'
    },
    activeColor: {
        backgroundColor: '#2196f3'
    },
    deactiveColor: {
        backgroundColor: 'rgb(225, 0, 80)'
    },
    newButton: {
        margin: '0 22px 8px'
    },
    paperStyle: {
        marginBottom: 40
    },
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
})

class ChallengeSettings extends Component {
    state = {
        isLoading: true,
        openNewChallenge: false,
        challenges: [],
        isLoading: true
    }
    openNewChallenge = () => {

    }
    componentDidMount() {
        this.getAll();
    }

    getAll = () => {
        this.props.challengeService.getAllChallenges().then((challenges) => {
            this.setState({
                challenges: challenges,
                isLoading: false
            })
        })
    }

    isAlerdyExistChallenge = () => {

        const isExist = this.state.challenges.some(challenge => new Date(challenge.dateTo) >= Date.now())

        return isExist
    }
    render() {
        const { classes, dispatch,t } = this.props
        const challengeList = this.state.challenges;

        let content;
        if (this.state.isLoading) {
            content = (<div data-intrcm-id="ChallengeSettings_91hdmi8d" className={classes.spinner}>
                <CircularProgress className={classes.progress} />
            </div>)
        }
        else {
            content = (<div data-intrcm-id="ChallengeSettings_ss972g94">
                <List className={classes.listStyle}>
                    {
                        challengeList.map((challenge, index) => {
                            let isActive = new Date(challenge.dateTo) >= Date.now()
                            return (
                                <div data-intrcm-id="ChallengeSettings_ob5lnxq5">
                                    <ListItem key={index} button onClick={() => { dispatch(push(`/admin/scoreBoardSettings/newChallenge/details/${challenge.id}`)) }}>
                                        <ListItemText primary={challenge.title}
                                            secondary={`${t('Running between')} ${moment(new Date(challenge.dateFrom)).format('DD.MM.YYYY')/*.toLocaleDateString().replace(/\//g, ".")*/} - ${moment(new Date(challenge.dateTo)).format('DD.MM.YYYY')}`} />
                                        <Chip label={isActive ? t('Active') : t('Not Active')}
                                            className={(isActive ? classes.activeColor : classes.deactiveColor)} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        })
                    }

                </List>

                <Button color="primary" variant="raised" className={classes.newButton} disabled={this.isAlerdyExistChallenge()}
                    onClick={() => { dispatch(push('/admin/scoreBoardSettings/newChallenge/new/0')) }} >
                    {t('New Challenge')}
                                </Button>
            </div>)
        }

        return (
            <div data-intrcm-id="ChallengeSettings_wq456vu4">
                        <AdminSettingsTitle text={t('Monthly Challenge')} />
                <Paper className={classes.paperStyle}>
                    {content}
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    challengeService: state.services.challengeService
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),
withTranslation('admin'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChallengeSettings);