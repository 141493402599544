import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class SearchNavigationBackIcon extends PureComponent {

    render() {
        return (
            <SvgIcon onClick={this.props.onClick} className={this.props.className} viewBox="0 0 28 28" >
                <path d="m7 14 1.41-1.41L3.83 8H22V6H3.83l4.59-4.59L7 0 0 7z" fill="#B1B2B8" fill-rule="nonzero"/>
            </SvgIcon>)
    }
}
