import React, { PureComponent } from 'react';

export const UserAdminSettingsIcon = class UserAdminSettingsIcon extends PureComponent {

    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.48603 0 0 4.48604 0 10C0 15.514 4.48603 20 10 20C15.514 20 20 15.514 20 10C20 4.48604 15.514 0 10 0ZM10 1.5C14.7033 1.5 18.5 5.2967 18.5 10C18.5 14.7033 14.7033 18.5 10 18.5C8.74959 18.5 7.56696 18.2246 6.49902 17.7422L8.95117 15.29C9.37402 15.3905 9.79523 15.5 10.25 15.5C13.4168 15.5 16 12.9168 16 9.75C16 9.00775 15.8546 8.30005 15.6006 7.65234C15.5551 7.5364 15.4815 7.43359 15.3864 7.3532C15.2913 7.27282 15.1776 7.21738 15.0557 7.19189C14.9338 7.16641 14.8075 7.17168 14.6882 7.20722C14.5688 7.24277 14.4602 7.30748 14.3721 7.39551L11.6338 10.1338C11.3875 10.3804 11.0704 10.5 10.75 10.5C10.4296 10.5 10.1125 10.3804 9.86621 10.1338C9.37188 9.63946 9.37215 8.85995 9.86621 8.36621L12.6045 5.62793C12.6925 5.53983 12.7572 5.43121 12.7928 5.31185C12.8283 5.19249 12.8336 5.06616 12.8081 4.94426C12.7826 4.82235 12.7272 4.70871 12.6468 4.61359C12.5664 4.51847 12.4636 4.44487 12.3477 4.39941C11.7 4.14537 10.9923 4 10.25 4C7.08319 4 4.5 6.58319 4.5 9.75C4.5 10.2048 4.60956 10.626 4.70996 11.0488L2.25781 13.501C1.77542 12.433 1.5 11.2504 1.5 10C1.5 5.2967 5.29669 1.5 10 1.5ZM10.25 5.5C10.3571 5.5 10.4488 5.54963 10.5537 5.55762L8.80566 7.30566C7.73772 8.37292 7.738 10.1267 8.80566 11.1943C9.33937 11.7287 10.0474 12 10.75 12C11.4526 12 12.1606 11.7287 12.6943 11.1943L14.4424 9.44629C14.4504 9.55123 14.5 9.64288 14.5 9.75C14.5 12.1062 12.6062 14 10.25 14C9.78618 14 9.34079 13.923 8.91992 13.7842C8.788 13.7406 8.64656 13.7345 8.51138 13.7665C8.37619 13.7986 8.25257 13.8676 8.1543 13.9658L5.14453 16.9766C4.31784 16.4008 3.59917 15.6822 3.02344 14.8555L6.03418 11.8457C6.1324 11.7474 6.20139 11.6238 6.23346 11.4886C6.26553 11.3534 6.25942 11.212 6.21582 11.0801C6.07682 10.6584 6 10.2138 6 9.75C6 7.39381 7.89381 5.5 10.25 5.5Z" fill="#4A4A4F"/>
            </svg>
        )
    }
}
