import React, { Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Icon, Typography } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { COLLECTION_LABELS_UPDATE, DELETE_LABEL } from '../../../../../../actionTypes/actionTypes.js';
import { showSnackbar, showConfirm } from '../../../../../../actions/appActions.js';

const styles = theme => ({
    container: { marginBottom: 8 },
    color: {
        width: 260,
        minHeight: 32,
        borderRadius: 2,
        position: 'relative',
    },
    name: {
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize',
        marginLeft: 14
    },
    icon: {
        color: '#ffffff',
        position: 'absolute',
        right: 4
    },
    edit: { width: 32, height: 32 }
});




class Label extends React.PureComponent {
    state = {
        searchText: ''
    };

    choose = () => {
        const { groupService, label, docId, changeLabels, load, disabled } = this.props

        if (disabled) {
            return
        }
        let promise;

        load(true)
        if (this.islabelExistOnItem()) {
            promise = groupService.removeLabelFromItem(label, docId)
        } else {
            promise = groupService.addLabelToItem(label, docId)
        }

        promise.then(res => {
            if (res) {
                changeLabels(res)
            }
            load(false)
        })

    }


    islabelExistOnItem = () => {
        const { label, docCollectionLabels, collectionUid } = this.props

        return docCollectionLabels.some(docLabel => {
            const [labelCollectionUid, labelUid] = docLabel.split(':')

            return labelCollectionUid === collectionUid && labelUid === label.labelUid
        })
    }

    edit = () => {
        const { label, edit } = this.props
        edit(label)
    }

    componentWillReceiveProps(nextProps) {
        const { deleteLabelId, label, dispatch } = nextProps
        if (deleteLabelId && deleteLabelId === label.id) {
            dispatch({ type: DELETE_LABEL, data: { deleteLabelId: '' } })
            this.delete()
        }
    }

    delete = () => {
        const { groupService, label, docId, load, collectionLabels, dispatch, collectionId, t } = this.props

        load(true)

        groupService.deleteLabel(label.id).then(r => {
            if(r.ok){
                const newLabels = collectionLabels.filter(l => l.id != label.id)
                dispatch({ type: COLLECTION_LABELS_UPDATE, collectionLabels: newLabels, collectionId })
            } else {
                dispatch(showSnackbar(("ERROR: Can't delete label")))
            }
            load(false)
        })
    }
    onDelete = () => {
        const { label, dispatch } = this.props

        const action = { type: DELETE_LABEL, data: { deleteLabelId: label.id } }
        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), action))
    }

    render() {
        const { label, key, classes, disabled, isAdmin } = this.props;

        return (
            <Grid container wrap='nowrap' className={classes.container} key={key} alignContent='center' alignItems='center' justify='space-between' wrap='nowrap'>
                <Grid container alignContent='center' alignItems='center' justify='left' wrap='nowrap' item onClick={this.choose}
                    className={classes.color}
                    style={{ width: isAdmin ? 260 : '100%', backgroundColor: label.color /* boxShadow: (key % 2 == 0 ? `-8px 0 ${label.color}` : '')*/ }}>
                    <Typography variant='caption' className={classes.name}>{label.name}</Typography>
                    {
                        this.islabelExistOnItem() ? <Icon className={classes.icon} >check</Icon> : null
                    }
                </Grid>
                {isAdmin && <Grid onClick={this.edit} container alignContent='center' justify='center' className={classes.edit} item><Icon>edit</Icon></Grid>}
                {isAdmin && <Grid onClick={this.onDelete} container alignContent='center' justify='center' className={classes.edit} item><Icon>delete</Icon></Grid>}

            </Grid>)
    }
}

const mapStateToProps = (state) => ({
    deleteLabelId: state.confirmDialog.deleteLabelId,

    collectionUid: state.results.channel.collectionUid,
    collectionId: state.results.channel.id,

    collectionLabels: state.userData.collections.find(collection => collection.collectionUid == state.results.channel.collectionUid).collectionLabels,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}


export default compose(withStyles(styles), withTranslation('label'), connect(mapStateToProps, mapDispatchToProps))(Label);