
import { browserHistory } from 'react-router'
import fileDownload from 'react-file-download'


export default class UserSettingsService {
    constructor(token, onUnauthorized) {
        if (token != null) {
            // this.token = auth.getToken();
            this.token = token;
        }
        this.onUnauthorized = onUnauthorized;


        // this.auth = auth;
    }

    getUserToken = () => {
        return this.token;
    }


    isLoggedIn = () => {
        return !!localStorage.getItem('id_token')
    }



    postView(view) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/views', {
            method: 'POST',
            body: JSON.stringify(view),
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }

    getViews() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/views', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }

    deleteView(id) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/views?id=' + id, {
            method: 'DELETE',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }

    subscribe(tag) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (tag !== '') {
            return fetch(process.env.API_SERVER + '/subscriptions',
                {
                    method: 'POST',
                    body: JSON.stringify(tag),
                    headers: myHeaders
                })
                .then(function (res) {
                    if (res.status === 401) {
                        // console.log(res);
                        this.onUnauthorized();
                    }

                    return res.json();
                });
        }
    }

    unsubscribe(tagId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (tagId !== '') {
            return fetch(process.env.API_SERVER + '/subscriptions?id=' + tagId,
                {
                    method: 'DELETE',
                    headers: myHeaders
                })
                .then(function (res) {
                    if (res.status === 401) {
                        // console.log(res);
                        this.onUnauthorized();
                    }

                    return res.json();
                });
        }
    }










    getUserDetails() {
        return fetch(process.env.API_SERVER + '/users/details', {
            method: 'GET',
            // body: form,
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        }).catch((error) => {
            console.error('This is error ' + error);
            // this.auth.refreshToken();
        });
    }

    getUser() {
        return fetch(process.env.API_SERVER + '/users/get-user', {
            method: 'GET',
            // body: form,
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
         

            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        })
    }

    getUserData() {
        return fetch(process.env.API_SERVER + '/users/data', {
            method: 'GET',
            // body: form,
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        }).catch((error) => {
            console.error('This is error ' + error);
            // this.auth.refreshToken();
        });
    }

    getUsers() {
        return fetch(process.env.API_SERVER + '/users', {
            method: 'GET',
            // body: form,
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        }).catch((error) => {
            console.error('This is error ' + error);
            // this.auth.refreshToken();
        });
    }


    updatePersonalizedFeed(state) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/usersettings/personalizedfeed?personalized=' + state, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }

    async confirmRequest(docId) {
        try {
            var headers = new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json'
            });
            const address = `${process.env.API_SERVER}/mustRead/toggleApprove?docId=${docId}`

            const res = await fetch(address, {
                method: 'PUT',
                body: JSON.stringify(docId),
                headers,
            })

            if (!res) return { isAborted: false, val: false }


            return {
                val: res.status === 200,
                isAborted: false
            }
        }
        catch (err) {
            return { isAborted: false, val: false }
        }
    }
    async removeMeFromMembersDocument(docId) {
        try {
            var headers = new Headers({
                'Authorization': 'Bearer ' + this.token
            });
            const address = `${process.env.API_SERVER}/content/removeMeFromMembers?docId=${docId}`

            const res = await fetch(address, {
                method: 'GET',
                headers
            })

            if (!res) return { isAborted: false, val: false }

            return {
                val: res.status === 200,
                isAborted: false
            }
        }
        catch (err) {
            return { isAborted: false, val: false }
        }
    }

    editUserSetting(newSetting) {
        var headers = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/usersettings/editusersetting', {
            method: 'POST',
            body: JSON.stringify(newSetting),
            headers: headers
        });
    }

    editAdminUserSetting(newSetting) {
        var headers = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/adminusersettings/editadminusersetting', {
            method: 'POST',
            body: JSON.stringify(newSetting),
            headers: headers
        });
    }






    // invite user
    invite(formData) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/users/add', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: myHeaders
        })
            .then(function (res) {
                if (res.status === 401) {
                    // console.log(res);
                    this.onUnauthorized();
                }

                return res.json();
            })
            .catch((error) => {
                return error;
            });
    }

    /// WITHOUT AUTHORIZATION
    // signup
    signUp(formData) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
        return fetch(process.env.API_SERVER + '/users/signup', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: myHeaders
        })
            .then(function (res) {
                if (res.status === 401) {
                    // console.log(res);
                    this.onUnauthorized();
                }

                return res.json();
            })
            .catch((error) => {
                return error;
            });
    }

    adminAddUser(newUser) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/adminusersettings/adminadduser', {
            method: 'POST',
            body: JSON.stringify(newUser),
            headers: myHeaders
        })
    }

    adminInviteUser(newUser) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/adminusersettings/invite', {
            method: 'POST',
            body: JSON.stringify(newUser),
            headers: myHeaders
        })
    }

    initUserDefaulNotificationSettings(tag) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/users/AddUserDefaulNotificationSettings', {
            method: 'POST',
            body: JSON.stringify(tag),
            headers: myHeaders
        })
    }

    verify(formData) {
        var myHeaders = new Headers({
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/users/verify', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: myHeaders
        })
            .then(function (res) {
                if (res.status === 401) {
                    // console.log(res);
                    this.onUnauthorized();
                }

                return res.json();
            })
            .catch((error) => {
                return error;
            });
    }
    IsUserTagExists(userTag) {
        var myHeaders = new Headers({
            'Content-Type': 'application/json'
        });

        return fetch(`${process.env.API_SERVER}'/users/IsUserTagExists/?usertag=${userTag}'`, {
            method: 'GET',
            headers: myHeaders
        })
            .then(function (res) {
                if (res.status === 401) {
                    // console.log(res);
                    this.onUnauthorized();
                }

                return res.json();
            })
            .catch((error) => {
                return false;
            });
    }

    getUserFullData() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/usersettings/getUserFullData/`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });

    }

    restoreUser(userId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/adminusersettings/restoreUser?userId=${userId}`, {
            method: 'PUT',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }

    deleteUser(userId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/adminusersettings?userId=${userId}`, {
            method: 'DELETE',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });
    }


    getUserDashboardToken() {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/usersettings/dashboard`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });


    }

    getUserSavedReportsForMyPage() {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/savedreports`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });


    }

    saveUserReportForMyPage(report) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(`${process.env.API_SERVER}/savedreports`, {
            method: 'POST',
            body: JSON.stringify(report),
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });


    }

    logOut() {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/users/logout`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if (res.status === 401) {
                // console.log(res);
                this.onUnauthorized();
            }

            return res.json();
        });


    }
}