import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import React from 'react'
import parseImagUrl from "../../../components/Common/parseImagUrl";
import {
  List,
  Avatar,
  Button,
  Collapse,
  ListItem,
  ListItemText,
  ListItemAvatar,
  withStyles
} from '@material-ui/core';
import goTo from '../../../actions/goTo';
import { pxToRem } from '../../../../utils/pxToRem';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Scrollbar from '../../../components/Common/Scrollbar';
import { withTranslation } from 'react-i18next';


const HEIGHT = 55;
const styles = theme => ({
    collapsedButton: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        height: 40,
        borderRadius: 0,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
        justifyContent: 'space-between',
        color: theme.palette.text.disabled,
        flexDirection: 'column', paddingTop: 12
    },
    list: {
        transition: theme.transitions.create('max-height')
    },
    participant: {
        height: HEIGHT, padding: '8px 20px'
    },
    participantAvatar: {
        position: 'relative', width: 40, height: 40
    },
    collapsedContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    collapseIcon: {
        flexDirection: 'column', right: 10, position: 'absolute', color: 'rgba(0, 0, 0, 0.38)'
    }
  });

const Participant = function Participant({ participant, onClickParticipant, classes }) {
  const { firstname, lastname, profileImageUrl, title, tag } = participant;
  const name = `${firstname} ${lastname}`
  return (
    <React.Fragment>
      <ListItem onClick={() => onClickParticipant(tag)} button disableGutters className={classes.participant}>
        <ListItemAvatar>
          <div data-intrcm-id="ChatRoomGroupParticipant_jvwbxwk7" className={classes.participantAvatar}>
            <Avatar alt={name} src={profileImageUrl} />
          </div>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={title}
          primaryTypographyProps={{ variant: 'subtitle2', noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    </React.Fragment>
  );
}

function ChatRoomGroupParticipant({
  participants,
  isCollapse,
  onCollapse,
  cloudfront,
  classes,
  dispatch,
  openSidebar,
  t,
}) {
  const onClickParticipant = (tag) => {
    goTo('@' + tag, dispatch)()
  } 
  return (
    <React.Fragment>
      <div data-intrcm-id="ChatRoomGroupParticipant_cavlrs8y" className={classes.collapsedContainer}>
        <Button
            className={classes.collapsedButton}
            fullWidth
            disableRipple
            color="inherit"
            onClick={onCollapse}>{t('In discussion')} ({participants.length})
        </Button>
        {openSidebar &&
            <Icon
                className={classes.collapseIcon}
                icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
                width={16}
                height={16}
            />
        }
      </div>
      <div data-intrcm-id="ChatRoomGroupParticipant_7wwwn6e4" className={classes.list} style={{ maxHeight: isCollapse ? HEIGHT * 4 : 0}}>
        <Scrollbar maxHeight={HEIGHT * 4}>
          <Collapse in timeout="auto">
            <List disablePadding>
              {participants.map((participant) => {
                  participant.profileImageUrl = parseImagUrl(cloudfront, participant.profileImageUrl);

                  return (
                    <Participant
                        classes={classes}
                        key={participant.auth0_id}
                        participant={participant}
                        onClickParticipant={onClickParticipant}
                    />
                )
              })}
            </List>
          </Collapse>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => ({
    dispatch
})
export default compose(
    withStyles(styles, { withTheme: true }), 
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChatRoomGroupParticipant);