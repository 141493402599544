import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import parseImgeUrl from '../../../Common/parseImagUrl';
import { compose } from 'recompose'

const styles = (theme) => ({
})

const UserSigniture = (props) => {
    const [signature, setSigniture] = useState(null)
    const {styles, cloudfront, storiesNavigation } = props
    const {currentStoryId, stories} = storiesNavigation

    useEffect(() => {
        const story = stories.find(s => s.user.auth0_id === currentStoryId)
        if(!story) return;
        setSigniture(story.user?.signature)
    }, [currentStoryId])

    if(!signature) return null

    return <img src={parseImgeUrl(cloudfront, signature)} style={styles.container} alt="signature"/>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(UserSigniture)