import { updateSpecificItem } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import ResultsList from '../../components/App/PageComponents/ResultsList';
import FeedDataItem from '../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem';
import { updateFeedCache } from '../../components/Header/HeaderToolbar/updateFeed';
import MustReadWidgets from '../../components/Widgets/MustReadWidget/MustReadWidgets';
import ActionCenterWidget from '../../components/Widgets/RightWidgets/Feed/UserPofileWidget/actionCenter/ActionCenterWidget';
import SponsoredContentWidget from '../../components/Widgets/RightWidgets/Feed/sponsoredContent/SponsoredContentWidget';
import { updateSpecificItemInState } from '../search/searchUtils';
import NewFeedDiscovery from './NewFeedDiscovery';
import { RenderUpToDateNew } from './RenderUpToDateNew';
import { FEED_NEW_ITEMS } from '../../../actionTypes/actionTypes';
import { loadData, loadMore } from './feedUtils';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { LAST_SCROLL_POS } from '../../components/App/wrapper';


const FeedNewItems = (props) => {

    return <div onClick={props.onClick}>New Items</div>
}

const styles = theme => ({
    childrenWrap: {
        [theme.breakpoints.down('1071')]: {
            marginLeft: 20
        }
    }
})

class FeedPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            feedPage: 0,
            results: [],
            upToDate: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            hadEmptyResult: false
        }

        // Bind the utils
        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.needsUpdate) {
            // this.loadData(nextProps.oldUiData.dataUpdate);
            this.props.dispatch({ type: 'DATA_UPDATED' });
            this.props.dispatch({ type: FEED_NEW_ITEMS, state: false});
        } else if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        } else if(!!nextProps.queryFiltersItemId && !!nextProps.queryFiltersUpdateType && nextProps.queryFiltersUpdateType === 'add') {
            this.loadData(0);
            this.props.dispatch(updateSpecificItem(null));
        }
    }

 
    shuffleWithOrder = (results) => {
        const { sponsoredContentItems } = this.state
        if(results && results.length !== 0 && sponsoredContentItems && sponsoredContentItems.length !== 0) {
            let randomShuffledSponsoredContentItems = sponsoredContentItems

            let indexOflenght = Math.trunc(results.length / 12)
            if(indexOflenght === 0 ){
                indexOflenght = 1
            }
            if(randomShuffledSponsoredContentItems.length < indexOflenght) {
                randomShuffledSponsoredContentItems = this.repeat(randomShuffledSponsoredContentItems,indexOflenght)
            }

            const randomShuffledSponsoredContentItemsForFeed = randomShuffledSponsoredContentItems.slice(0,indexOflenght)

            results = randomShuffledSponsoredContentItemsForFeed.map((el, index ) => {
               return this.insert(results,(index + 1)  * 12 - 1,el)
           })[0]
           return results
        }
      return  results ?? []
    }

    listenLoaded = (prevProps, prevState) => {
        if(this.state.isLoading === prevState.isLoading) return
        if(this.state.isLoading) return
        if(LAST_SCROLL_POS.value === 0) return

        const el = window.document.getElementById("main_feed_scroll")
        if(!el) return

        setTimeout(() => {
            el.scrollTo({top: LAST_SCROLL_POS.value})
            LAST_SCROLL_POS.value = 0
        }, 300)
    }
    componentDidUpdate(prevProps, prevState) {
        this.listenLoaded(prevProps, prevState)
    }
    
    componentDidMount() {
        updateFeedCache(() => this.loadData(0))
        this.loadData(this.state.dataReloadInidicator)

        window.Alvis("show_checklist");

        this.props.adminService.getSponsoredContent().then((docIds) => {
            const query = {
                // itemsIds: [],
                page: 0,
                // size: 0,
                AggType: 'FLAT',
            }
            query.itemsIds = docIds;
            query.size = docIds.length;
            this.props.queryService.SearchByIds(query).then((sponsoredContentResults) => {
                  const  sponsoredContentItems = this.randomShuffle(sponsoredContentResults.data)
                   this.setState({ sponsoredContentItems })
                })
            })

    }

    renderItem = (idx, result, key) => {
        const { sponsoredContentItems } = this.state
        let isSponsoredContent = false
        if(sponsoredContentItems) {
            const ids = sponsoredContentItems && sponsoredContentItems.map(el => el.Key)
             isSponsoredContent = ids.includes(key)
        }
        return <FeedDataItem 
            key={idx + key}
            docId={key}
            isSponsoredContent={isSponsoredContent}
            result={result.Value.Source}
        />

    }

    renderListHeader = () => {
        const { upToDate } = this.state;

        return <div data-intrcm-id="feedPage_y0nsrj07">
            {upToDate && upToDate.length > 0 ? <RenderUpToDateNew results={upToDate} renderItem={this.renderItem} /> : null}
        </div>
    }

    randomShuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
    
      insert = (arr, index,item) => {
         arr.splice( index, 0,item );
         return arr
        };

    repeat = (arr, n) => Array(n).fill(arr).flat();

    render() {
        const { results, isLoading, isLoadingMore, hasMore, upToDate} = this.state;
        const rightWidgets = [<ActionCenterWidget />,<SponsoredContentWidget />]

        let shuffledFilteredResults = this.shuffleWithOrder(results.filter(x => !upToDate.find(y => y.Key === x.Key)))

        return (
            <NewFeedDiscovery rightWidgets={rightWidgets}>
                <div className={this.props.classes.childrenWrap}>
                <MustReadWidgets />
                {/* {this.props.oldUiData.feedNewItems? <FeedNewItems onClick={()=>{this.loadData()}} /> : null} */}
                <ResultsList
                    results={shuffledFilteredResults ?? []}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    hasMore={hasMore}
                    loadMore={this.loadMore}
                    isSearch={false}
                    showInput
                    Header={this.renderListHeader()}
                    renderItem={this.renderItem}
                    Title='' />
                    </div>
            </NewFeedDiscovery>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    queryFiltersItemId: state.queryFilters.itemId,
    queryFiltersUpdateType: state.queryFilters.updateType,
    userData: state.userData,
    routerLocation: ownProps.location,
    profileData: state.userData.profileData,
    ...state.services,
    oldUiData: state.oldUiData,
    openCollections: state.globalData.openCollections,
    globalData: state.globalData,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, mapDispatchToProps),
)(FeedPage);