import React from 'react'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './css/chatPlaceholder.css'
import { useTranslation } from 'react-i18next';

const styles = theme => ({
    learnMore: {
        color: theme.customColors.primary.b700
    },
    header: {
        margin: 0
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    }
});



export default withStyles(styles, { withTheme: true })((props) => {
    const { classes } = props;
    const { t, i18n } = useTranslation('feedWidgets');

    return <Grid container spacing={16} justify={"center"} className={classes.header} spacing={16}>
        <Grid item  xs={6}>
            <div data-intrcm-id="ChatPlaceHolder_xp833ffq">
                <input id="anPageName" name="page" type="hidden" defaultValue="chatplaceholder" />
                <div data-intrcm-id="ChatPlaceHolder_xgs0z8m4" className="chatplaceholder anima-word-break ">
                    <div data-intrcm-id="ChatPlaceHolder_06x8blcj" style={{  height: '100%', position: 'relative', margin: 'auto' }}>
                        <div data-intrcm-id="ChatPlaceHolder_k97s7on1" className="chatplaceholder1">
                            <div data-intrcm-id="ChatPlaceHolder_shwd99zb" className="rectangle">
                            </div>
                            <img alt="Image" className="illustrationchat" src={"/resources/placeholders/Illustration_Chat.svg"} />
                            <div data-intrcm-id="ChatPlaceHolder_4exjrpp5" className="tuqqischatakatu anima-valign-text-middle">
                                <span>
                                    <span className="span1">{t(`AKA Tuqqi Talk is built to focus discussions only on the things that matter`)}.<br /></span><a href={"https://intercom.help/tuqqi/en/articles/3869099-introduction-to-the-chat"} target="_blank" className={classes.learnMore}>{t('Learn more')}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Scripts */}
                {/* End of Scripts */}
            </div>
        </Grid>
    </Grid>
})