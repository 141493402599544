import React, { PureComponent } from "react";
import { SvgIcon } from "@material-ui/core";

export default class IconCircleArrow extends PureComponent {
  render() {
    return (
      <svg
        width="34px"
        height="34px"
        viewBox="0 0 34 34"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Feed"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Stories---Open"
            transform="translate(-461.000000, -260.000000)"
          >
            <g id="Left-Side" transform="translate(120.000000, 198.000000)">
              <g
                id="icon---circle-arrow-(minimize)"
                transform="translate(342.000000, 63.000000)"
              >
                <circle
                  id="Oval"
                  stroke="#979AAA"
                  stroke-width="0.5"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  cx="16"
                  cy="16"
                  r="16.25"
                ></circle>
                <g id="icon---arrow" transform="translate(6.000000, 7.000000)">
                  <polygon
                    id="Path"
                    opacity="0.87"
                    points="0 0 18 0 18 18 0 18"
                  ></polygon>
                  <path
                    d="M12.465,2.2425 C12.0975,1.875 11.505,1.875 11.1375,2.2425 L4.905,8.475 C4.6125,8.7675 4.6125,9.24 4.905,9.5325 L11.1375,15.765 C11.505,16.1325 12.0975,16.1325 12.465,15.765 C12.8325,15.3975 12.8325,14.805 12.465,14.4375 L7.035,9 L12.4725,3.5625 C12.8325,3.2025 12.8325,2.6025 12.465,2.2425 Z"
                    id="Path"
                    fill="#979AAA"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
