import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import withPusher from '../../components/Common/Pusher/withPusher.js';
import { NewUiWrapper, managerUserCalendar, CalendarUser, shouldUseScheduler } from '@tuqqi/web';
import {store} from '../../../index'
import { themeConstants } from '../../../theme.js';
import { withOpenFullview } from '../../../new-ui/withOpenFullview.js';
import { MockForSetTableGlobalDataSch } from '../groups/scheduler/MockForSetTableGlobalDataSch.js';
import { FULLVIEW_POST, INPUT_OPEN } from '../../../actionTypes/actionTypes.js';
import OldCalendar from './MyPageCalendar'

const styles = theme => ({
    table: {
        marginLeft: themeConstants.navigationDrawerWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
    table1: {
        marginLeft: themeConstants.navigationDrawerSmallWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
})

export class MyPageCalendarNew extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {
        if(!shouldUseScheduler(this.props.orgUid)) return
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })

        managerUserCalendar.preFetch(this.props.userUid, true)
    }

    openOldInput = (memberUid, dataType, text, startDate, endDate) => {
        const { dispatch } = this.props
        const payload = { 
            postText: '', 
            dataType, 
            permissions: [], 
            dateTimeFrom: new Date(startDate), 
            dateTimeTo: new Date(endDate), 
        }

        if(!!memberUid) {
            payload.members = [{userUUID: memberUid}]
        }

        dispatch({
            type: FULLVIEW_POST,
            openPost: true,
            item: payload
        })
        dispatch({ type: INPUT_OPEN, model: payload });
    }

    render() {
        const { classes, isDrawerOpen,groupUid, collectionUid } = this.props;

        const callbacks = {
            onOpenOldDataType: this.openOldInput
        }

        if(!shouldUseScheduler(this.props.orgUid)) return <OldCalendar {...this.props} />
     
        return <CategoryViewOnlyLeftDrawer >
                <div className={isDrawerOpen ? classes.table : classes.table1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            <MockForSetTableGlobalDataSch />
                            <CalendarUser drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerWidth : themeConstants.navigationDrawerSmallWidth} />
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    search: state.search,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    userUid: state.userData?.profileData?.auth0_id ?? '',
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Table, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(MyPageCalendarNew);