import { Divider, Icon, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import channelsPlaceholder from '../../../../../../assets/img/channels_placeholder.png';
import parseImagUrl from "../../../../../components/Common/parseImagUrl";
import GeneralButton from '../../../../Common/AllButton/AllButton';

const styles = (theme) => ({
    container: {
        position: 'relative',
        width: theme.constants.searchInputWidth - 40,
        height: 56,
        marginRight: 12,
        borderRadius: 4,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginRight: 0
        },
        display: 'flex',
        flexDirection: 'row',
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.23,
        borderRadius: 6,
        width: theme.constants.searchInputWidth - 40 + 10,
        height: 56 + 20 - 1,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1,
    },
    picWrap: {
        width: 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    pic: {
        display: 'flex',
        alignSelf: 'center',
        borderRadius: '50%',
        width: 56,
        height: 56,
        color: '#f7f7f7',
        backgroundColor: '#bdbdbd',
        fontSize: 25,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    texts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    title: {
        minHeight: 20,
        maxWidth: 320,
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'start',
        color: '#21212d',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: 'rgba(0,0,0,1)'
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginLeft: 16,
        zIndex: 2
    },
    description: {
        minHeight: 20,
        maxWidth: 320,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        textAlign: 'start',
        color: '#979aaa',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginLeft: 16,
        zIndex: 2
    },
    divider: {
        marginTop: 8,
        marginBottom: 8,
        color: '#dddee6',
        '&:last-child': {
            marginTop: 0,
            display: 'none',
            marginBottom: 0
        },
    },
    subsribeButtomContainer: {
        width: 112,
        height: 32,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: theme.customColors.primary.main,
            color: '#fff',
        },
    },
    subsribeButtomText: {
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 1.23,
        letterSpacing: 0.5,
    },
    unsubsribeButtomContainer: {
        width: 112,
        height: 32,
        backgroundColor: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: '#fff',
        '&:hover': {
            background: 'transparent',
            color: theme.customColors.primary.main,
        },
    },
    subscribe: {
        alignSelf: 'center',
        marginRight: 0,
        marginLeft: 'auto',
        zIndex: 2
    },
    imgHashtag: {
        objectFit: 'cover',
        width: 80,
        overflow: 'hidden',
        borderRadius: '50%'
    }
})

const checkIfSubscribed = (tag, subscribedTags) => {    
    if(subscribedTags && subscribedTags.length && tag){
        return subscribedTags.some(t => (t.tag || "").toLowerCase() === tag.toLowerCase())
    }
    return false
}
const mapTitleTag = (tag = '') => tag.startsWith('#') ? tag.slice(1) : tag

function Channel(props) {
    const {type, channel, classes, openChannel, subscribe, unsubscribe, withSubscribe, subscribedTags, cloudfront, withKeysNavigation,t} = props
    const isSubscribed = checkIfSubscribed(channel.tag, subscribedTags)
    const isChannelWithImage = channel.icon && channel.icon.includes('fileId=')

    const keysNavigationProps = !withKeysNavigation ? {} : {
        suggestCase: JSON.stringify({tag: channel.tag, suggestType: "channel"}),
        suggest: "suggest-find"
    }

    const onClickGeneral = () => {
        if(isSubscribed) {
            unsubscribe(channel.tag)
            return
        }
        subscribe(channel)
    }

    const renderSubscribeButton = () => {
        if(!withSubscribe) return null;
        const title = isSubscribed ? t('UNSUBSCRIBE') : t('SUBSCRIBE')
        const containerStyle = isSubscribed ? classes.unsubsribeButtomContainer : classes.subsribeButtomContainer

        return <GeneralButton onClick={onClickGeneral} containerStyle={containerStyle} textStyle={classes.subsribeButtomText} title={title} />
    }
    const renderTagIcon = () => {
        if(isChannelWithImage) return null
        // if(type === 'trend') return <HasTagIcon isSelected={true}/>
        if(type === 'trend') return <img src={channelsPlaceholder} className={classes.imgHashtag}/>
        return <Icon> {channel.topicIcon || 'rss_feed'}</Icon>
    }
    const renderDescription = () => {
        if(type === 'trend') return channel.description || ''
        return channel.numNewItems === 0 ? 'You are up to date' : channel.numNewItems + ' unread'
    }

    const renderPic = () => {
        const styles = isChannelWithImage ? {
            backgroundImage: `url(${parseImagUrl(cloudfront, channel.icon, 70, channel.icon)})`
        } : {}
        return (
            <div data-intrcm-id="Channel_rhgsgvdx" className={classes.picWrap}>
                <div data-intrcm-id="Channel_twsjfinm" style={styles} alt="channel" className={classes.pic}>{renderTagIcon()}</div>
            </div>
        )
    }

    return (
        <>
            <div data-intrcm-id="Channel_rct8hst3" className={classes.container} >
                {renderPic()}
                <div data-intrcm-id="Channel_o9jntzpv" className={classes.texts}>
                    <div data-intrcm-id="Channel_ikl39uvp" onClick={(e) => openChannel(e, channel)} className={classes.title}>{mapTitleTag(channel.tag)}</div>
                    <div data-intrcm-id="Channel_jaq3fb9b" className={classes.description} >{renderDescription()}</div>
                </div>
                <div data-intrcm-id="Channel_pvjk0631" className={classes.subscribe}>{renderSubscribeButton()}</div>
                <div data-intrcm-id="Channel_iqfokypt" {...keysNavigationProps} className={classes.suggest} />
            </div>
            <Divider className={classes.divider} />
        </>
    )
}


const mapStateToProps = (state) => ({
    subscribedTags: state.userData.subscribedTags,
    cloudfront: state.globalData.cloudfront
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('groupDrawerTranslate'),
    connect(mapStateToProps)
)(Channel)

