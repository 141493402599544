import { Button, Card, CardActions, CardContent, CardMedia, Typography, withStyles, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ItemPreviewImgOrIcon from '../Common/ItemPreviewImgOrIcon';
import UserAvatar from '../Common/UserAvatar';
import dataTypesDictionary from '../Common/userCompatibleNames/dataTypesDictionary';
import { showSnackbar } from '../../../actions/appActions.js';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    wrapper: {
        "overflow": "visible",
        "marginRight": "16px",
        "marginBottom": "6px",
        "marginTop": "6px",
        "height": 220,
        'word-break': 'break-all'

    },
    firstItem: {
        marginLeft: "3px"
    },
    userIcon: {
        width: "30px",
        height: "30px"
    },
    userName: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "normal",
        "color": "rgba(0, 0, 0, 0.54)",


        marginTop: 8
    },
    timeAgo: {
        "font-family": "Roboto",
        "font-size": "10px",
        "font-weight": "normal",
        "font-style": "normal",
        "font-stretch": "normal",
        "line-height": 1.6,
        "letter-spacing": "normal",
        "color": "var(--black-54)",
        "margin-left": "13px"
    },
    view: {
        minWidth: 50,
        "fontFamily": "Roboto",
        "fontSize": "10px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.6",
        "letterSpacing": "0.3px",
        "textAlign": "right",
        "color": "#271eff"
    },
    title: {
        "width": "200px",
        "height": 70,
        "font-family": "Roboto",
        "font-size": "16px",
        "font-weight": 500,
        "font-style": "normal",
        "font-stretch": "normal",
        "line-height": "normal",
        "letter-spacing": "0.1px",
        "color": "#21212d",
    },
    actionsWrapper: {
        justifyContent: "space-between"
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    }
})

class RelatedItemCard extends Component {
    constructor(props) {
        super(props)

        const { data, users, userData, inputMappings, sourceItem, docId } = this.props
        const { userUUID, userName, userPicUrl, userTag, docTimestamp, dataType, groupUid, title } = data

        const user = users.find(x => x.auth0_id === userUUID) || {}
        const docTime = moment(docTimestamp).fromNow();
        const typeName = dataTypesDictionary(dataType, inputMappings)
        const vowel = ['a', 'e', 'i', 'o', 'u']
        const prefix = vowel.includes(typeName.toLowerCase()[0]) ? 'an' : 'a'

        let postedIn = ''
        const isPublic = groupUid === userData.profileData.organizationUid
        if (!isPublic) {
            const group = isPublic ? null : userData.collections.find(x => x.collectionGroup.groupUid === groupUid)
            if (group) {
                postedIn = 'in ' + group.title
            }
        }

        this.state = {
            userName,
            userPicUrl,
            userLevel: user.level,
            shared: `Shared ${prefix} ${typeName} ${postedIn}`,
            docTime,
            dataType,
            title,
            userTag,
            linked: sourceItem && sourceItem.relatedDocuments && sourceItem.relatedDocuments.some(r => r.relatedDocumentId == docId)
        }
    }

    logClick = () => {
        this.props.collaborationService.logClick(this.props.docId)
    }

    openItem = () => {
        const { dispatch, data, docId } = this.props
        dispatch({ type: 'FULLVIEW_OPEN_LOAD', item: { ...data }, itemId: docId })
        this.logClick()
    }

    formatTitle = (title, maxLength) => title.length > maxLength ? `${title.substr(0, maxLength)}...` : title


    linkItem = () => {
        const { collaborationService, docId, dispatch, sourceDocId, sourceItem } = this.props;

        this.setState({ linked: true });

        const link = {
            docId: sourceDocId,
            link: docId
        }

        collaborationService.addLink(link).then(newLink => {
            //todo message for user
            //todo: translate
            dispatch(showSnackbar((`Item Linked to ${sourceItem && sourceItem.title}`)));

        })
    }

    render() {
        const { classes, data, index, enableLinked, t } = this.props
        const { userName, userPicUrl, userLevel, userTag, docTime, title } = this.state

        return (<Card className={`${classes.wrapper} ${index === 0 ? classes.firstItem : ''}`} >
            <CardContent>
                <CardMedia className={classes.header}>
                    <UserAvatar userUUID={data.userUUID} className={classes.userIcon} />
                    <ItemPreviewImgOrIcon document={data} />
                </CardMedia>
                <Typography className={classes.userName}>{userName}</Typography>
                <Typography className={classes.title}>{this.formatTitle(title, 70)}</Typography>
            </CardContent>
            <CardActions className={classes.actionsWrapper}>
                <Grid item>
                    <Typography className={classes.timeAgo}>{docTime}</Typography>
                </Grid>
                <Grid item>
                    {
                        enableLinked ? <Button disabled={this.state.linked} className={classes.view} onClick={this.linkItem}>{t(this.state.linked ? 'linked' : 'link')}</Button> : null
                    }
                    <Button className={classes.view} onClick={this.openItem}>{t('View')}</Button>            </Grid>


            </CardActions>
        </Card>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    cloudfront: state.globalData.cloudfront,
    inputMappings: state.globalData.inputMappings,
    sourceItem: state.fullView.item,
    sourceDocId: state.fullView.itemId,

    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles), withTranslation('relatedModal'), connect(mapStateToProps))(RelatedItemCard)