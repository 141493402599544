import { Icon, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { element } from 'prop-types';



const styles = theme => ({

    listContainer: {
        overflow: 'auto',
        // overflow:'hidden',
        position: 'relative'
    },
    cardsContainer: {
        height: '100%',
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        flexWrap: 'nowrap',
        // '-webkit-overflow-scrolling':'touch',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },

})



class ScrollListWithArrow extends Component {

    state = {
        reachRightEnd: false,
        reachLeftEnd: false,
    }
    listRef = React.createRef();
    isIPad = navigator.userAgent.match(/iPad/i) != null;
    shouldHideLeftArrow() {
        const { scrollWidth, offsetWidth, scrollLeft } = this.listContainer

        const reachEnd = scrollWidth === (offsetWidth + scrollLeft)

        return reachEnd
    }

    shouldHideRightArrow() {
        const reachEnd = this.listContainer.scrollLeft === 0

        return reachEnd
    }

    scrollToRight = (event) => {
        if (!this.listContainer) {
            return
        }
        const rightContainerSide = this.listContainer.parentElement.getBoundingClientRect().right
        const arrElements = Array.from(this.listContainer.children)
        const revercedElement = arrElements.reverse()

        const nexItemToScroll = revercedElement.find((element) => {
            return element.getBoundingClientRect().right < rightContainerSide
        }) || arrElements[0]

        const sizeScroll = rightContainerSide - nexItemToScroll.getBoundingClientRect().right
        this.scrollTo(-sizeScroll, event)
    }


    getFirstListItemWidth = () => {
        return (this.listContainer && this.listContainer.firstElementChild && this.listContainer.firstElementChild.offsetWidth)

    }
    scrollTo(sizeScroll, event) {
        this.listContainer.scrollBy({
            top: 0,
            left: sizeScroll,
            behavior: 'smooth'
        })

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

    }
    scrollToLeft = (event) => {
        if (!this.listContainer) {
            return
        }
        const leftContainerSide = this.listContainer.parentElement.getBoundingClientRect().left
        const nexItemToScroll = Array.prototype.find.call(this.listContainer.children, (element) => {
            return element.getBoundingClientRect().left > leftContainerSide
        }) || this.listContainer.children.item(this.listContainer.childElementCount - 1)

        const sizeScroll = nexItemToScroll.getBoundingClientRect().left - leftContainerSide
        this.scrollTo(sizeScroll, event)
    }

    onScroll = () => {
        if (this.isIPad) {
            return true
        }

        const reachRightEnd = this.shouldHideRightArrow()
        const reachLeftEnd = this.shouldHideLeftArrow()

        if (this.state.reachLeftEnd != reachLeftEnd ||
            this.state.reachRightEnd != reachRightEnd) {
            this.setState({ reachLeftEnd, reachRightEnd })
        }

        return true
    }

    initReachEnd = () => {
        const { scrollWidth, offsetWidth } = this.listContainer

        if (scrollWidth == offsetWidth || this.isIPad) {
            this.setState({ reachLeftEnd: true, reachRightEnd: true })
        } else {
            this.setState({ reachLeftEnd: true, reachRightEnd: false })
        }
    }

    componentDidUpdate(preProps) {
        if (!this.listRef.current && preProps.children.length != this.props.children.length) {
            this.initReachEnd()
        }
    }

    componentDidMount() {
        this.listContainer = this.listRef.current
        this.initReachEnd()
    }


    render() {
        const { classes, children, rightArrow, leftArrow } = this.props;
        const { reachLeftEnd, reachRightEnd } = this.state;


        return (
            <Fragment>
                {!reachLeftEnd && React.cloneElement(leftArrow, { onClick: this.scrollToLeft })}
                {!reachRightEnd && React.cloneElement(rightArrow, { onClick: this.scrollToRight })}
                <div data-intrcm-id="ScrollListWithArrow_gg4abje2" className={classes.listContainer} >
                    <div data-intrcm-id="ScrollListWithArrow_6oarv8cb" className={classes.cardsContainer} ref={this.listRef} onScroll={this.onScroll}>
                        {children}
                    </div>
                </div>

            </Fragment>
        )




    }
}





export default compose(withStyles(styles))(ScrollListWithArrow);

