import React, { PureComponent } from "react";

export const LaunchpadSearchIcon = class LaunchpadSearchIcon extends PureComponent {
  render() {
    return (
        <svg className={this.props.className} width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 7.75L9.5 9.5" stroke="#737373" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 5.5C2.5 7.15685 3.84315 8.5 5.5 8.5C6.32985 8.5 7.08105 8.16305 7.62415 7.6185C8.1654 7.07585 8.5 6.327 8.5 5.5C8.5 3.84315 7.15685 2.5 5.5 2.5C3.84315 2.5 2.5 3.84315 2.5 5.5Z" stroke="#737373" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
  }
}