import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withStyles, TextField, Paper, Checkbox, List, ListItem, Typography, Button, Grid } from '@material-ui/core';

// import Task from "./task";
import Task from "../../Comments/task";
import { showSnackbar, showConfirm } from "../../../../actions/appActions";
import { withTranslation } from 'react-i18next'
import { DELETE_CHECKLIST } from '../../../../actionTypes/actionTypes';

// Apply some reset
const styles = theme => ({
    container: {
        height: 28,
        paddingBottom: 16
    },
    text: {
        color: '#17394d',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 500,
    },
    button:{
        textTransform:'capitalize'
    },
    titleContainer:{
        width:'initial',
        // flex:'1 1 auto'
    }
});

class TaskHeader extends Component {

    state = {
        isInEditMode: false,
        newTitle:''
    }


    componentWillReceiveProps(nextProps){
        const { deleteChecklistUid, checklist, dispatch } = nextProps
        if(deleteChecklistUid ===  checklist.uid){
            const { checklist, t, dispatch } = this.props    
            dispatch({ type: DELETE_CHECKLIST, data: { deleteChecklist: ''} }) 
            this.delete()
        }
    }
    
    changeTitle = (e) => {
        this.setState({ newTitle: e.target.value })
    }



    editTitle = () => {
        const { checklist, item, dispatch, collaborationService, index, t } = this.props
        const { isInEditMode } = this.state
        let  newTitle = '';

        if (isInEditMode && this.state.newTitle != checklist.title) {
            dispatch(showSnackbar(t('Please Wait')));
    
            const param = {
                ...checklist,
                title: this.state.newTitle,
                docUid: item.docId
            }
            item.checklists[index] = { ...item.checklists[index], title: this.state.newTitle  }
            dispatch({ type: 'FULLVIEW_CHANGE', item: { ...item, checklists: [...item.checklists] } })
            collaborationService.updateChecklist(param).then(res => {
                // item.checklists[index] = { ...res }
                // dispatch({ type: 'FULLVIEW_CHANGE', item: { ...item, checklists: [...item.checklists] } })
            })
        } else {
            newTitle = checklist.title
        }

        this.setState({ isInEditMode: !isInEditMode, newTitle })
    }

    onDelete = () => {
        const { checklist, t, dispatch } = this.props
        const action = { type: DELETE_CHECKLIST, data: { deleteChecklistUid: checklist.uid} }

        dispatch(showConfirm(t("Are you sure you want to delete this? this action can't be undone"), action))   
    }

    delete = () => {
        const { collaborationService, item, dispatch, index, checklist, t } = this.props

        dispatch(showSnackbar(t('Please Wait')));

        collaborationService.deleteChecklist({
            ...checklist,
            docUid: item.docId
        }).then(res => {
            if (res.ok) {
                const checklists = item.checklists.filter(c => c.uid != checklist.uid)
                item.checklists.splice(index, 1)
                dispatch({ type: 'FULLVIEW_CHANGE', item: { ...item, checklists: [...item.checklists] } })
                dispatch(showSnackbar('Deleted successfully'));
            }
        })
    }

    addTask =() =>{
        const { checklist, addTask } = this.props;
        addTask(checklist.uid)
    }
    render() {
        const { classes, dispatch, checklist, addTask, t } = this.props;


        return (
            <Grid container>
                <Grid className={classes.titleContainer} container alignItems='center' item xs={8} onDoubleClick={this.editTitle} >
                    {
                        this.state.isInEditMode ?
                            <TextField

                            InputProps={{fullWidth:true}}
                                onBlur={this.state.isInEditMode && this.editTitle}
                                fullWidth={true}
                                placeholder='Title'
                                value={this.state.newTitle}
                                onChange={this.changeTitle} /> :
                            <Typography component='h3' className={classes.text}>
                                {checklist.title || "Checklist"}
                            </Typography>
                    }
                </Grid>
                <Grid item className={classes.addTask} style={{marginLeft:'auto'}}>
                            <Button className={classes.button} onClick={this.onDelete}>
                                {t('Delete Checklist')}
                            </Button>
                </Grid>
                <Grid item className={classes.addTask}>
                            <Button className={classes.button} onClick={this.addTask}>
                            {t('Add Task')}
                            </Button>
                </Grid>
            </Grid>
        )



    }
}

const mapStateToProps = (state) => ({
    item: state.fullView.item,
    collaborationService: state.services.collaborationService,
    deleteChecklistUid: state.confirmDialog.deleteChecklistUid,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
withTranslation('taskList'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TaskHeader);