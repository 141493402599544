import React from 'react'

export const DragIcon = () => {
    return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="3" r="2" fill="#737373"/>
    <circle cx="11" cy="13" r="2" fill="#737373"/>
    <circle cx="6" cy="13" r="2" fill="#737373"/>
    <circle cx="11" cy="8" r="2" fill="#737373"/>
    <circle cx="6" cy="8" r="2" fill="#737373"/>
    <circle cx="11" cy="3" r="2" fill="#737373"/>
    </svg>    
}