import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { isEqualJson } from '@tuqqi/common'
import { TableFieldReadOnlyTooltip, putGantItem } from '@tuqqi/web'
import { DateHelper } from '@bryntum/gantt'
import { DateView } from './DateView'
import CellRenderStartEndEdit from './CellRenderStartEndEdit'
import { isValid } from 'date-fns'
import { CheckEditDate, allowChangeProjectDate } from '../../utils/checkChangeDate'

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    containerEdit: {
        outline: 'auto!important',
        outlineColor: '#7B4FF0!important',
    },
    container: {
        height: 40,
        position: 'relative',
        boxShadow: 'none',
        marginRight: -4,
        marginLeft: -4,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: 'initial',
        outline: 'none',
        width: '-webkit-fill-available',
    },
})

//[start, end]
const checkApi = (record, originalValue = [], newValue = []) => {
    if(isEqualJson(originalValue, newValue)) return
    const allowStart = !newValue?.[0] ? true : allowChangeProjectDate(newValue?.[0])
    const allowEnd = !newValue?.[1] ? true : allowChangeProjectDate(newValue?.[1])
    if(!allowStart || !allowEnd) {
        const scheduleInfo = {}
        const schedule = {}
        schedule.dateTimeFrom = newValue?.[0] ?? ''
        schedule.dateTimeTo = newValue?.[1] ?? ''
        scheduleInfo.scheduleUpdatePayload = schedule
        scheduleInfo.id = record.id
        CheckEditDate.scheduleInfo = scheduleInfo
        CheckEditDate.onShowConfirm()

        return
    }

    if(!record) return
    record.set({
        startDate: DateHelper.parse(putGantItem.getDateCorrectGanntt(newValue[0])),
        endDate: DateHelper.parse(putGantItem.getDateCorrectGanntt(newValue[1])),
    })
    //api - in listener because constraintDate
}

const getDatesAsString = (value = []) => {
    const start = isValid(value?.[0]) ? value?.[0].toISOString() : ""
    const end = isValid(value?.[1]) ? value?.[1].toISOString() : ""
    return [start, end]
}

const CellRenderStartEnd = memo((props) => {
    const [open, setOpen] = useState(false)
    const {classes} = props
    const [valueLocal, setValueLocal] = useState(getDatesAsString(props.value))
    const localRef = useRef(valueLocal)
    const containerRef = useRef()

    const valueInner = useMemo(() => {
        const lll = getDatesAsString(props.value)
        if(props.isEnd) return lll?.[1] ?? ''
        return lll?.[0] ?? ''
    }, [props.value, props.isEnd])

    const disabled = !props.record?.data?.editable?.common

    useEffect(() => {
        setValueLocal(getDatesAsString(props.value))
    }, [props.value])
    
    useEffect(() => {
        localRef.current = valueLocal
    }, [valueLocal])
    
    const handleOpen = (e) => {
        if(!!disabled) return
        if(open) return
        setOpen(true)
    }

    const onClose = () => {
        if(!open) return
        checkApi(props.record, getDatesAsString(props.value), localRef.current)
        setOpen(false)
    }
    

    const X = <div onClick={handleOpen} ref={containerRef} className={`${classes.container} ${open ? classes.containerEdit : ''} ${!disabled ? classes.cursor : ''}`}>
        <DateView disabled={disabled} value={valueInner} />
        {open && <CellRenderStartEndEdit value={valueLocal} containerRef={containerRef} onChange={setValueLocal} onClose={onClose} />}
    </div>

    if(disabled) return <TableFieldReadOnlyTooltip component={X} />

    return X
})

export default withStyles(styles, {withTheme: true})(CellRenderStartEnd)