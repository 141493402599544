import React, { PureComponent } from 'react';

export default class HashTagIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 3L13 20" stroke={color} stroke-width="1.5"/>
            <path d="M11 3L6 20" stroke={color} stroke-width="1.5"/>
            <path d="M2 15H21" stroke={color} stroke-width="1.5"/>
            <path d="M3 9H22" stroke={color} stroke-width="1.5"/>
            </svg>
        )
    }
}