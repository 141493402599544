import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import './messages.scss';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  }
};


class NoMobileSupport extends Component {
  getStoreLinks = () => {
    var url = '';
    var text = 'Download';

    if (/Android|Opera Mini/i.test(navigator.userAgent)) {
      url = 'https://play.google.com/store/apps/details?id=com.tuqqi.mobile';
      text += ' from google play';
    }
    else if (/|iPhone|iPod|Opera Mini/i.test(navigator.userAgent)) {
      url = 'https://itunes.apple.com/app/id1390367533';
      text += ' from app store'
    }

    return { url, text }
  }

  getDeepLinks = () => {
    let url = 'tuqqi://app/feed';


    if (window.location.href.includes('searchquery')) {
      const urlParams = new URLSearchParams(window.location.search);
      let query = urlParams.get('searchquery');
      query = query.replace('#', '')      
      const redirect = 'openItem'
      url += `?query=${query}&redirect=${redirect}`
    }

    return { url }


  }

  storeButton = () => {
    const { url, text } = this.getStoreLinks();

    var button = <Button onClick={() => window.open(url, '_blank')} color="primary" > {text} </Button>

    return button;
  }

  componentDidMount() {
    const { url } = this.getDeepLinks();
    window.location.replace(url)
  }

  render() {
    const { classes } = this.props;

    return (
      <div data-intrcm-id="noMobileSupport_99naa3rh" className={classes.root}>
        <div data-intrcm-id="noMobileSupport_q3s47ztb" style={{ textAlign: 'center', paddingTop: 20 }}>
          <img src={"/favicon/android-icon-144x144.png"} alt={'image'} />
          <div data-intrcm-id="noMobileSupport_u1c6uxiz" style={{ textAlign: 'center', paddingTop: 10 }}>
            {this.storeButton()}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(NoMobileSupport);