import React, { useState } from 'react';
import { Divider, withStyles, MenuItem } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import DataTypeListForCreate, { DataTypeButtonComponent } from './DataTypeListForCreate';
import { push } from 'react-router-redux';
import { getOS } from '../../../../../utils/OSutils';
import FeedLeftItemsDatatypes from './FeedLeftItemsDatatypes';
import { getGroupDocumentPermission } from '@tuqqi/common';
import { initInviteUser, openInput as openNewUiInput } from '@tuqqi/web'
import { defaultDataTypes } from '../../../Common/Utils/defaultDataTypes';

const styles = (theme) => {
    return ({
        container: {
            borderRadius: 8,
            // padding: 16,
            zIndex: '1202!important',
            position: 'absolute',
            width: 220,
            // maxWidth: theme.constants.notificationsNavigationWidth,
            maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 24px)`,
            height: 'fit-content',
            right: 208,
            left: 'auto',
            bottom: 0,
            top: -2,
            backgroundColor: 'rgba(35, 35, 38, 1)',
            paddingTop:4,
            paddingBottom:4,
            overflow:"auto"
        },
        containerDark: {
            zIndex: '1201!important',
            position: 'fixed',

            width: '100vw',
            height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
            left: 0,
            right: 0,
            bottom: 0,
            top: theme.constants.defaultToolbarHeight,
            'background-color': 'rgba(0, 0, 0, 0)'
        },
        hidden: {
            zIndex: -1,
            visibility: 'hidden',
        },
        menuItemGroupCreate: {
            padding: "6px 8px 6px 8px",
            marginLeft:4,
            marginRight:4,
            borderRadius:6,
      
            color: "rgba(255,255,255,0.9)",
            backgroundColor: `rgba(183, 184, 189, 0.2)`,
            "&:hover": {
                backgroundColor: theme.customColors.primary.b500
    
            }
        },
        newUser: {
            marginTop: 8,
        },
        divie:
            {
                marginTop:4,marginBottom:4,backgroundColor:"rgba(183, 184, 189, 0.07)"
            }
    });
}

const isCommonCreateListener = (e) => {
    if(!!e.ctrlKey && e.shiftKey && e.key === '=') return true
    return false
}
  
const isMacCreateListener = (e) => {
    if (e.metaKey && e.shiftKey && e.key === '=') return true
    return false
}
  

export const isShouldCreateOpen = (e) => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return isMacCreateListener(e)
        case 'linux':
        case 'windows':
            return isCommonCreateListener(e)
        default:
            return false
    }
}
export const getCreateKeysLabel = () => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return 'CMD + +'
        case 'linux':
        case 'windows':
            return "CTRL + +"
        default:
            return ""
    }
}

const CreateNewLibraryCategoryView = (props) => {
    const {t, isFocused, classes, dispatch, profileData } = props

    const isAdmin = profileData?.role === "Admin"
    const [allowArticle, setAllowArticle] = useState(false)

    const onNewGroup = () => {
        dispatch({
            type: 'TOGGLE_CREATENEW_MODAL',
            isFocused: false
        })
        dispatch(push({ pathname: '/creategroup' }))
    }

    const onInviteUser = () => {
        dispatch({
            type: 'TOGGLE_CREATENEW_MODAL',
            isFocused: false
        })
        initInviteUser.value = true;
        dispatch(push('/admin/users'))
    }


    const openNewInput = (dataType) => {
        const permissions = getGroupDocumentPermission('')
        let newDoc = { html: '', title: '', permissions: permissions, dataType: dataType }
        dispatch(openNewUiInput(newDoc, dataType))
        dispatch({ type: 'BLUR_CREATENEW_MODAL' })
    }

    const Inputs = !allowArticle ? null : defaultDataTypes(t).filter(x => ['htmlwysiwyg'].includes(x.customDataMapping.dataTypeFilter)).map((x) => {
        return <DataTypeButtonComponent mapping={x} getCollectionsForDataType={() => { return [undefined] }} openNewInput={() => { openNewInput(x.customDataMapping.dataTypeFilter) }} disableMenu={true} />
    });


    return <>
                {Inputs}
                <Divider className={classes.divie} />
                <DataTypeListForCreate setAllowArticle={setAllowArticle} ignoreDataTypes={[]}  noGroup isFocused={isFocused} />
                <Divider className={classes.divie} />
                <MenuItem className={classes.menuItemGroupCreate} onClick={onNewGroup}>{t("New Work Group")}</MenuItem>
                {isAdmin && <MenuItem className={`${classes.menuItemGroupCreate} ${classes.newUser}`} onClick={onInviteUser}>{t("Invite User")}</MenuItem>}

    </>
}

const mapStateToProps = (state) => {
    return {
        isFocused: state.createNewModal.isFocused,
        profileData: state.data?.userData?.userDetails?.data,
        subscription: state.globalData.currentSubscription,
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, { withTheme: true }),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(CreateNewLibraryCategoryView)