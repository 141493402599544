import React, { Component } from 'react'
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles, Icon, Avatar } from '@material-ui/core';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    rowStyle: {
        flexDirection: 'row',
        display: 'flex'
    },
    icon: {
        height: 18,
        fontFamily: 'Material Icons',
        color: '#868686',
        fontSize: 18,
        padding: 0,
        paddingRight: 5,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})

class TimeToRead extends Component {

    render() {
        const { item, seperator, classes, t } = this.props
        const { docText } = item
        const timeToRead = docText ? t('min to read', { time: Math.ceil(docText.length / 1350) }) : ''

        return (<div data-intrcm-id="TimeToRead_r0uynii9" className={classes.rowStyle}>
            <Icon classes={{ root: classes.icon }} className={classes.icon}>access_time</Icon>
            <div data-intrcm-id="TimeToRead_9bmoj9by">{timeToRead} {seperator}</div>
        </div>)
    }
}

export default compose(withStyles(styles),
    withTranslation('itemFullViewModal')
)(TimeToRead);