import React from 'react'
import withErrorCatcher from '../Common/withErrorCatcher.js'
import Comment from './Comment';
import Activity from './Activity';
import Task from './task';
import LinkedDocument from './linkedDocument';



export let loadData = function (docId) {
    const { collaborationService } = this.props;
    Promise.all(
        [collaborationService.getComments(docId),
        collaborationService.getActivities(docId),
        collaborationService.getTasks(docId)])
        .then((res) => {
            this.setState({ dataLoaded: true, comments: res[0], activities: res[1], tasks: res[2] })
        })
}


export let printComments = function ({ activities, comments, tasks, relatedDocuments }, onLinkDeleted, onCommentDelete, isChoose) {
    const { docId } = this.props
    const commentArr = comments.map(comment => {
        return {
            obj: comment,
            type: 'comment',
            date: new Date(comment.dateTime)
        }
    })
    const taskArr = tasks.map(task => {
        return {
            obj: task,
            type: 'task',
            date: new Date(task.created)
        }
    })
    let arr = commentArr.concat(taskArr)

    arr = arr.sort((a, b) => a.date - b.date)

    const component = [<Activity key='activity' activity={activities[activities.length - 1]} classes={this.props.classes} />]
    
    relatedDocuments && relatedDocuments.forEach((link, idx) => {
        component.push(<LinkedDocument key={`link-${idx}`} docId={docId} link={link} onDelete={onLinkDeleted} classes={this.props.classes} last={arr.length == 0 && idx == relatedDocuments.length - 1} />)
    })

    arr.forEach((x, idx) => {
        switch (x.type) {
            case 'comment':
                component.push(<Comment key={idx} isChoose={isChoose} onCommentDelete={onCommentDelete}  comment={x.obj} classes={this.props.classes} last={idx == arr.length - 1} />)
                break
            case 'task':
                component.push(<Task key={idx} onDelete={this.onTaskDelete} onUpdate={this.onTaskUpdate} task={x.obj} last={idx == arr.length - 1} />)
                break
        }
    })

    return component
}