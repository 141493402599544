import { isImage } from '../filesUtils'

export const isJsonValid = (json) => {
    try {
        JSON.parse(json);
        return true
    }
    catch (e) {
        return false
    }
}

export const getJsonData = (json) => {
    let jsonData = json;

    if (!jsonData) {
        jsonData = '{}';
    }
    let parsedJsonData = { 'Raw Data': jsonData }
    let parsedSuccessfully = false;
    let replacedJson = jsonData

    if (!isJsonValid(jsonData)) {
        replacedJson = replacedJson.replace(/`/g, '')
        if (isJsonValid(replacedJson)) {
            parsedJsonData = JSON.parse(replacedJson)
            parsedSuccessfully = true
        }
    } else {
    parsedJsonData = JSON.parse(replacedJson)
        parsedSuccessfully = true
    }
    return [parsedJsonData, parsedSuccessfully]
}

const getPhotoForSponsoredContentMedia = (document) => {
    const photoAttachment = document.attachments.find(el => isImage(el.fileType))

    const mediaUrl = document.link ? document.link : photoAttachment?.fileUid
    return mediaUrl ?? ''
}

const getPhotoForSponsoredContentFromAttachments = (document) => {
    const photoAttachment = (document?.attachments ?? []).find(el => isImage(el.fileType))

    return photoAttachment?.fileUid ?? ''
}
export const getPhotoForSponsoredContent = (doc) => {
    if(!doc) return ''
    switch (doc.dataType) {
        case 'media':
            return getPhotoForSponsoredContentMedia(doc)
        case 'htmlwysiwyg':
            return getPhotoForSponsoredContentFromAttachments(doc)
        default:
            return getPhotoForSponsoredContentFromAttachments(doc)
    }
}

const getTextForSponsoredContentPost = (document) => {
    if(!document.title && !document.postText && document?.attachments?.[0]) return document.attachments[0].fileName
    return document?.postText ?? ''
}
const getTextForSponsoredContentMedia = (document) => {
    return document.postText ?? ''
}
const getTextForSponsoredContentArticle = (document, tFunc) => {
    const t = document?.docText ?? ''
    const text = t.replace(/&nbsp;/g, ' ').replace(/&#?[a-z0-9]+/g, '')
    return text || tFunc("No description")
}

export const getCustomInputDescriptionForSponsored = (previewFields = []) => {
    if (!previewFields.length) {
        return []
    }

    return previewFields.sort((a, b) => a.previewDisplayOrder - b.previewDisplayOrder)
        .slice(0, 4)
}

export const getTextForSponsoredContent = (doc, customMappings, t) => {
    if(!doc) return [true, '', false]
    switch (doc.dataType) {
        case 'media':
            return [true, getTextForSponsoredContentMedia(doc), true]
        case 'post':
            return [true, getTextForSponsoredContentPost(doc), true]
        case 'htmlwysiwyg':
            return [true, getTextForSponsoredContentArticle(doc, t), false]
        case 'contact': {
            const [parsedJsonData, parsedSuccessfully] = getJsonData(doc.jsonData)
            const contactInfo = parsedSuccessfully ? parsedJsonData : {}
            const { company = "", email = "", jobtitle = "", phone = "" } = contactInfo
            const fields = []
            if(!!jobtitle) fields.push({fieldName: 'Job', value: jobtitle})
            if(!!company) fields.push({fieldName: 'Company', value: company})
            if(!!email) fields.push({fieldName: 'Email', value: email})
            if(!!phone) fields.push({fieldName: 'Phone', value: phone})
            return [false, fields, false]
        }
        case 'company': {
            const [parsedJsonData, parsedSuccessfully] = getJsonData(doc.jsonData)
            const companyInfo = parsedSuccessfully ? parsedJsonData : {}
            const { id = "", email = "", phone = "" } = companyInfo
            const fields = []
            if(!!id) fields.push({fieldName: 'Id', value: id})
            if(!!email) fields.push({fieldName: 'Email', value: email})
            if(!!phone) fields.push({fieldName: 'Phone', value: phone})
            return [false, fields, false]
        }
        default:
            const mapping = customMappings.find(m => m.customDataMapping.dataTypeFilter === doc.dataType)
            if(!mapping) return [true, '', false]
            const [parsedJsonData, parsedSuccessfully] = getJsonData(doc.previewDescriptionJson)
            const data = parsedSuccessfully ? parsedJsonData : null
            const fields = getCustomInputDescriptionForSponsored(data)
            return [false, fields, false]
    }
}
export const getTitleForSponsoredContent = (doc, t) => {
    if(!doc) return ''
    switch (doc.dataType) {
        case 'media':
            return ''
        case 'post':
            return ''
        default:
            return doc.title ?? t('Untitled')
    }
}