import { SHOW_NOTIFICATION_SNACKBAR, HIDE_NOTIFICATION_SNACKBAR, FULL_RESET_STORE } from '../actionTypes/actionTypes'

const initialState = [];
const notificationSnackBar = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;
    case SHOW_NOTIFICATION_SNACKBAR:
      return {
        ...state,
        notificationSnackBarOpen: true,
        notifications: action.notifications || [],
      }

    case HIDE_NOTIFICATION_SNACKBAR:
      return {
        ...state,
        notificationSnackBarOpen: false
      }

    default:
      return state
  }
}

export default notificationSnackBar