import { Icon } from '@iconify/react';
import pinFill from '@iconify/icons-eva/pin-fill';
import PointsIcon from '@material-ui/icons/Sort';
import phoneFill from '@iconify/icons-eva/phone-fill';
import emailFill from '@iconify/icons-eva/email-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Avatar, Button, Divider, Collapse, Typography, withStyles } from '@material-ui/core';
import React from 'react'
import { pxToRem } from '../../../../utils/pxToRem';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import parseImagUrl from "../../../components/Common/parseImagUrl";

const findUser = (auth0_id, users) => {
    const user = users.find(u => u.auth0_id === auth0_id)
    return user
}
const styles = theme => ({
    collapsedButton: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        height: 40,
        borderRadius: 0,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
        justifyContent: 'space-between',
        color: theme.palette.text.disabled,
        flexDirection: 'column', paddingTop: 12
    },
    row: {
        display: 'flex',
        margin: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 0}px`,
    },
    rowIcon: {
        width: 16,
        height: 16,
        marginTop: 4,
        marginRight: theme.spacing.unit * 1,
        color: theme.palette.text.secondary,
    },
    rowText: {
        flexGrow: 1,
        maxWidth: 160,
        wordWrap: 'break-word',
        lineHeight: 22 / 14,
        fontSize: pxToRem(14)
    },
    list: {
        transition: theme.transitions.create('max-height')
    },
    collapsedContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    collapseIcon: {
        flexDirection: 'column', right: 10, position: 'absolute', color: 'rgba(0, 0, 0, 0.38)'
    }
  });

const ChatRoomOneParticipant = function ChatRoomOneParticipant({
  participants,
  isCollapse,
  isGroupMessages,
  onCollapse,
  classes,
  openSidebar,
  cloudfront,
  users,
  user,
  chatType
}) {
  const participant = chatType === 'SavedMessages' ? user : findUser(participants[0].auth0_id, users);

  if (participant === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      <div data-intrcm-id="ChatRoomOneParticipant_k1h1kone"
        style={{
          paddingTop: 32,
          paddingBottom: 24,
          visibility: isGroupMessages ? 'hidden' : 'visible'
        }}
      >
        <Avatar alt={participant.firstname} src={parseImagUrl(cloudfront, participant.profileImageUrl)} style={{ width: 96, height: 96, marginRight: 'auto', marginLeft: 'auto' }} />
        <div data-intrcm-id="ChatRoomOneParticipant_cyhjv0mm" style={{ marginTop: 16, textAlign: 'center' }}>
          <Typography variant="subtitle1">{`${participant.firstname} ${participant.lastname}`}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {participant.title}
          </Typography>
        </div>
      </div>

      <Divider />
      <div data-intrcm-id="ChatRoomOneParticipant_2qq79icy" className={classes.collapsedContainer}>
      <Button
        className={classes.collapsedButton}
        fullWidth
        color="inherit"
        onClick={onCollapse}
      >
        information
      </Button>
      {openSidebar &&
            <Icon
                className={classes.collapseIcon}
                icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
                width={16}
                height={16}
            />
        }
      </div>
      <Collapse in={isCollapse} timeout="auto">
        <div data-intrcm-id="ChatRoomOneParticipant_sh82pbqs" style={{ paddingRight: 2.5 * 8,paddingLeft: 2.5 * 8, paddingBottom: 8, visibility: isGroupMessages ? 'hidden' : 'visible' }}>
          <div data-intrcm-id="ChatRoomOneParticipant_lt74sqas" className={classes.row}>
            <Icon className={classes.rowIcon} icon={emailFill} />
            <Typography className={classes.rowText}>{participant.email}</Typography>
          </div>
          <div data-intrcm-id="ChatRoomOneParticipant_fhtnmx3l" className={classes.row}>
            <PointsIcon className={classes.rowIcon} />
            <Typography className={classes.rowText}>{`${participant.points} points (Level ${participant.level})`}</Typography>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    users: state.globalData.users,
})


export default compose(
    withStyles(styles, { withTheme: true }), 
    connect(
        mapStateToProps,
        null
    )
)(ChatRoomOneParticipant);