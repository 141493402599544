import { Divider, withStyles } from '@material-ui/core';
import React, { Component, createRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import AppContent from '../../components/App/appContent';
import ChatContent from './chatContent';
import ChatDrawer from './ChatDrawer/chatDrawer';
import ChatHeader from './ChatHeader';
import {
    addComment, closeAddChatModal, getChatFunctions, loadComments, loadMessages, loadMoreComments, addMessage, updateResults, getChatId, loadMoreMessages, Discussions, DirectMessages, GroupMessages, mapSavedMessages, addSavedMessage, loadSavedMessages, SavedMessages, loadGroupMessages, loadMoreGroupMessages, addGroupMessage
} from './chatUtils';
import withPusher from '../../components/Common/Pusher/withPusher';
import ChatPlaceHolder from '../../components/Placeholders/ChatPlaceHolder';
import { getNotificationData } from './chatNotificationsUtils';
import { getChannelName } from '../../components/Common/Pusher/pusherUtils';
import { push } from 'react-router-redux';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import { findChatByChatId, getDirectMessages, getDiscussionsRooms, isDiscussions, updateLatestComment, updateLatestMessage, updateNewCommentsChat, uppendDataResult } from '@tuqqi/common';
import { deepMerge, isEqualJson } from '../../../utils/commonHelpers';
import { SERVER_API } from '../../../utils/serverAPI'
import ChatRoom from './ChatRoom/ChatRoom';
import ChatInput, { chatDrop } from './ChatInput/ChatInput';
import { NewUiWrapper, useDropzone } from '@tuqqi/web';
import {store} from '../../../index';
import { withTranslation } from 'react-i18next';
import { CHAT_GROUP_INFO } from './chatRecognize';


const top = 44;
const getActiveRoom = (rooms, selectedChatId) => {
    const room = rooms.find(room => {
        const id = isDiscussions(room.chatType) ? room.docId : room.chat.chatUId
        return id === selectedChatId
      })
      return room
}
const roomSelector = (rooms, selectedChatId, users, user, newChatRoom) => {
    if(selectedChatId === '' || selectedChatId === user.auth0_id) return {
        participants: [],
    }
  
    const room = getActiveRoom(rooms, selectedChatId) || newChatRoom
    if(!room) return {docId: '', participants: [user]}
    const participants = isDiscussions(room.chatType) ? users.filter(user => room.document.tags.includes(user.tag)) : [room.userProfile, user]
   
    return {participants, docId: isDiscussions(room.chatType) ? room.docId : ''};
  };

const getAttachments = (comments) => {
    const attachments = [...comments].reverse().reduce((accum, comment) => {
        if(comment && comment.attachments){
            const withCreatedAt = comment.attachments.map(a => ({...a, createdAt: comment.createdAt}))

            return [...accum, ...withCreatedAt]
        } 
        return []       
    }, [])
    return attachments
}
// Apply some reset
const styles = theme => ({
    drawer: {
        position: 'fixed',
        zIndex: 1,
        height: `calc(100% - ${top}px)`,
        overflowY: 'auto'
    },
    progress: {
        margin: 'auto'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 43px)',
        marginLeft: -30,
        marginTop: -16,
        marginRight: -30
    },
    headerContainerGroup: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 43px)',
        marginTop: -16,
    },
    contentContainer: {
        paddingTop: 45,
        height: '100%',
        flex: '0 1 auto',
        overflowY: 'auto'
    },
    groupContainer: {flexGrow: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', marginTop: 4},
    columnContainer: {
        flexGrow: 1, display: 'flex', flexDirection: 'column'
    },
    mainContainer: {
        display: 'flex', flexDirection: 'row', height: 'calc(100% - 85px)'
    },
    drawerContainer: {
        height: '100%',
        marginRight: 'auto',
        marginLeft: '0px',
        width: 385,
        flexShrink: 0,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        transition: theme.transitions.create('width')
    }
});

export const mapPushComment = (comment, chatType) => {
    if(!isDiscussions(chatType))return comment
    const {key, ...restComment} = comment
    return {...restComment, commentId: key}
}

class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            results: [],
            loadingDocuments: true,
            commentsLoading: false,
            selected: '',
            isLoadingMore: false,
            openSidebar: true
        }

        this.getChatFunctions = getChatFunctions.bind(this);

        this.loadMoreComments = loadMoreComments.bind(this);
        this.loadMoreMessages = loadMoreMessages.bind(this);
        this.loadMoreGroupMessages = loadMoreGroupMessages.bind(this);
        this.loadComments = loadComments.bind(this);
        this.addComment = addComment.bind(this);

        this.loadMessages = loadMessages.bind(this)
        this.loadGroupMessages = loadGroupMessages.bind(this)
        this.loadSavedMessages = loadSavedMessages.bind(this)
        this.closeAddChatModal = closeAddChatModal.bind(this)
        this.addMessage = addMessage.bind(this)
        this.addGroupMessage = addGroupMessage.bind(this)
        this.addSavedMessage = addSavedMessage.bind(this)
        this.newChatRef = createRef()
        this.newInstantMessageRef = createRef({needReplace: false, tempId: ''})
    }

    componentWillUnmount() {
        const { auth0_id, tag } = this.props.userData.profileData;

        this.props.unbindChannel(getChannelName(auth0_id))
        this.props.unbindChannel(getChannelName(tag))
    }
    setOpenSidebar = () => {
        this.setState({openSidebar: !this.state.openSidebar})
    }

    listenFetchGroupChat = (prevProps) => {
        if(this.props.isFocusedChatsModal) return
        const isFetching = this.props.chatFeedGroup?.directMessages?.isFetching
        const isFetchingPrev = prevProps.chatFeedGroup?.directMessages?.isFetching
        if(isFetching === isFetchingPrev) return
        if(!this.isCorrectFromGroup()) return
        if(!isFetching && !!isFetchingPrev) {
            this.props.chatService.openNewGroupChat(CHAT_GROUP_INFO.collectionUid).then(res => {
                const { chat } = res
                if (!chat || !chat.chatUId) {
                  return
                }
                this.props.uppendDataResult(res);
              })  
        }
    }

    componentDidMount() {
        this.initChatChannel();
        this.initNotificationChannel();

        this.loadDisscusions();
    }

    listenCommentPosting = (prevProps) => {
        if (!this.getStateWithChatType(this.props)) return

        const [newCommentPosting, prevCommentPosting] = [this._getPostingComment(this.props), this._getPostingComment(prevProps)];
        if (prevCommentPosting !== newCommentPosting) {
            this.setState({ isAddingComment: newCommentPosting })
            prevCommentPosting && !newCommentPosting && this.scrollToLatestMessage()
        }
    }
    getSavedItemInd = (items, tempId) => items.findIndex(el => {
            const {item} = el
            if(item.messageType === 2) return el.item.message.tempId === tempId
            return false
    })
    getCommentItemInd = (items, tempId) => items.findIndex(el => {
            const {item} = el
            return item.tempId === tempId
    })
    get allowInstantReplace() {
        return this.newInstantMessageRef.current && this.newInstantMessageRef.current.needReplace
    }
    listenItems = (prevProps) => {
        if(!this.getStateWithChatType(this.props)) return

        const {items} = this.state
        const newComments = this._getComments(this.props)
        if(isEqualJson(newComments, items)) return;
        if(items.length === newComments.length +1 && this.allowInstantReplace) {
            return
        }
        if(items.length === newComments.length && this.allowInstantReplace) {
            const tempId = this.newInstantMessageRef.current.tempId
            const isSavedMessage = this.state.chatType === SavedMessages
            const ind = isSavedMessage ? this.getSavedItemInd(items, tempId) : this.getCommentItemInd(items, tempId) 
            const indC = isSavedMessage ? this.getSavedItemInd(newComments, tempId) : this.getCommentItemInd(newComments, tempId)
            if(ind < 0 || indC < 0) return

            const copyItems = [...items]
            const prevItem = {...copyItems[ind]}
            const comment = {...newComments[indC]}

            copyItems[ind] = {isDeleting: comment.isDeleting, item: deepMerge(prevItem.item, comment.item)}
            this.newInstantMessageRef.current = {needReplace: false, tempId: ''}
            this.setState({items: copyItems})
            return
        }
        this.setState({items: newComments})
    }

    checkGroupChanged = (prevProps) => {
        if(!this.isCorrectFromGroup()) return
        if(this.props.groupUid === prevProps.groupUid) return
        this.setState({selected: ''})
    }

    componentDidUpdate(prevProps) {
        const { urlQuery } = this.props;
        const { results, selected } = this.state;

        if (urlQuery && selected !== urlQuery && results.length > 0) {
            this.openChatFromUrl()
        }
        this.checkGroupChanged(prevProps)
        this.listenResults(prevProps)
        this.listenCommentsLoading(prevProps)
        this.listenCommentPosting(prevProps)
        this.listenMoreCommentsLoading(prevProps)
        this.listenMessageData(prevProps)
        this.listenDocumentCommentsLoading(prevProps)
        this.listenItems(prevProps)
        this.listenFetchGroupChat(prevProps)
    }

    getChatFeed = (props) => {
        if(this?.isCorrectFromGroup?.()) return props.chatFeedGroup
        return props.chatFeed
    }

    listenDocumentCommentsLoading = (prevProps) => {
        if(!this.getChatFeed(this.props)) return
        
        const [newDocsLoading, prevDocsLoading] = [this._getFetchingDocumentComments(this.props, Discussions), this._getFetchingDocumentComments(prevProps, Discussions)];
        const [newDMLoading, prevDMLoading] = [this._getFetchingDocumentComments(this.props, DirectMessages), this._getFetchingDocumentComments(prevProps, DirectMessages)];
        if (prevDocsLoading !== newDocsLoading || prevDMLoading !== newDMLoading) {
          const loading = newDocsLoading && newDMLoading;
          this.setState({loadingDocuments: loading})
        }
      }
    listenCommentsLoading = (prevProps) => {
        if (!this.getStateWithChatType(this.props)) return

        const [newCommentsLoading, prevCommentsLoading] = [this._getFetchingComments(this.props), this._getFetchingComments(prevProps)];
        if (prevCommentsLoading !== newCommentsLoading) {
            this.setState({ commentsLoading: newCommentsLoading })
            prevCommentsLoading && !newCommentsLoading && this.scrollToLatestMessage()
        }
    }

    listenMoreCommentsLoading = (prevProps) => {
        if (!this.getStateWithChatType(this.props)) return

        const [newCommentsMoreLoading, prevCommentsMoreLoading] = [this._getFetchingMoreComments(this.props), this._getFetchingMoreComments(prevProps)];
        if (prevCommentsMoreLoading !== newCommentsMoreLoading) {
            this.setState({ isLoadingMore: newCommentsMoreLoading })
        }
    }

    openChatFromUrl = () => {
        const { urlQuery, chatRoom, dispatch } = this.props;
        const { results } = this.state;

        const result = results.find(res => {
            const { chatType, chat, docId } = res;

            const resChatId = getChatId(chatType, chat, docId);

            return resChatId === urlQuery
        })

        if (result) {
            this.openChat(result);
        } else if(!result && chatRoom.chatRoomFromUserPage && chatRoom?.room?.chat?.chatUId === urlQuery) {
            this.openChat(chatRoom.room);
            dispatch({
                type: 'SET_CHAT_ROOM',
                chatRoomFromUserPage: false,
            })
        }
    }

    listenMessageData = (prevProps) => {
        if(!this.getChatFeed(this.props)) return

        const prevFetching = this.getChatFeed(prevProps).fetchRoomData.isFetching;
        const nextFetching = this.getChatFeed(this.props).fetchRoomData.isFetching;
        if(!nextFetching && prevFetching) {
            const nextFetch = this.getChatFeed(this.props).fetchRoomData;
            if(!!(nextFetch && nextFetch.message && Object.keys(nextFetch.message).length > 0)){
                const res = nextFetch.message
                if(nextFetch.type === 'notification') {
                    res && this.props.uppendDataResult(res);
                    return
                }
                const chatId = getChatId(res.chatType, res.chat, res.docId)

                if (chatId === this.props.selected) {
                    res.newComments = 0;
                }
                this.props.uppendDataResult(res);
            }
        }
    }
    

    initNotificationChannel = () => {
        const channelName = getChannelName(this.props.userData.profileData.auth0_id);
        const eventName = 'notification';

        this.props.initChannel(channelName, eventName, this.handleNotificationPusher);
    }

    initChatChannel = () => {
        const channelName = getChannelName(this.props.userData.profileData.auth0_id);
        const eventName = 'chat';

        this.props.initChannel(channelName, eventName, this.handleChatPusher);
    }

    handleChatPusher = (comment) => {
        if(comment.isChatNotification) return

        const { results, selected } = this.state;

        this.updateFeedResults(comment, results, selected)
        const chatId = comment.chatUId || comment.docId

        if (chatId === selected) {
            this.updateComments(comment)
        }
    }
    updateComments = (comment) => {
        const { chatType, selected } = this.state;
        if(chatType === SavedMessages) {
            return
        }
        const commentUserId = isDiscussions(chatType) ? comment.userId : comment.sender.auth0_id;
        const newComment = mapPushComment(comment, chatType)
        if (this.state.isAddingComment && commentUserId === this.props.userData.profileData.auth0_id) return;
        isDiscussions(chatType) ?
            this.props.dispatch(updateLatestComment(selected, newComment)) :
            this.props.dispatch(updateLatestMessage(selected, newComment))
    }

    handleNotificationPusher = (notification) => {
        const { dispatch, userData: {profileData} } = this.props;
        if(notification.chatId && notification.chatId === profileData.auth0_id) return
        const { results } = this.state;
        const [, index] = findChatByChatId(results, notification.docId || notification.chatId);

        if (index < 0) {
            getNotificationData(notification.docId, notification.chatId, dispatch, 'notification')
        }
    }

    isCorrectFromGroup = () => {
        return !!this.props.fromGroup && !!this.props.groupUid
    }

    loadDisscusions = async () => {
        const { dispatch } = this.props;

        if(this.isCorrectFromGroup()) {
            return
        }
        dispatch(getDiscussionsRooms(SERVER_API)(0))
        dispatch(getDirectMessages(SERVER_API)(0, {includeGroups: 1}));
    }

    listenResults = (prevProps) => {
        if(!this.getChatFeed(this.props)) return

        const [prevResults, newResults] = [this.getResults(prevProps), this.getResults(this.props)]
        if(isEqualJson(prevResults, newResults) && isEqualJson(newResults, this.state.results)) return;
        this.setState({results: newResults})
    }

    updateFeedResults = (comment, oldResults, selected) => {
        const { dispatch } = this.props;
        updateResults(comment, oldResults, selected, dispatch);

        this.scrollToLatestMessage()
    }

    scrollToLatestMessage = () => {
        if (!this.messagesEnd) return
        setTimeout(()=>this.messagesEnd.scrollIntoView({ behavior: "auto" }), 100);
    }

    updateCommentsDifference = (existedResult) => {
        const {dispatch} = this.props

        const id = isDiscussions(existedResult.chatType) ? existedResult.document.docId : existedResult.chat.chatUId
        const commentsCounter = isDiscussions(existedResult.chatType) ? existedResult.document.commentsCounter + existedResult.newComments : 0
        dispatch(updateNewCommentsChat(id, 0, commentsCounter))//or result
    }

    openChat = (result) => {
        const {t} = this.props
        var results = [...this.state.results];
        this.newChatRef.current = result;
        let { loadData, loadMore, addComment, selected, title, icon, userProfile, mapData } = this.getChatFunctions(result, t);
        const chatType = result.chatType;
        this.setState({
            selected, document: result.document, docId: result.docId, 
            loadMore, addComment, title, icon, userProfile, mapData, chatType, items: [],
        })
        const [existedResult, index] = findChatByChatId(results, selected)
        const exists = index >= 0

        exists && this.updateCommentsDifference(existedResult)

        if(this.isCorrectFromGroup()) {
            const url = new URL(window.location.href);
            url.searchParams.set('chatid', encodeURIComponent(selected))
            this.props.dispatch(push({ pathname: url.pathname, search: url.search }))
        } else {
            this.props.dispatch(push({ pathname: "chat", search: '?chatid=' + encodeURIComponent(selected) }))
        }

        loadData();
    }

    renderHeader = () => {
        const {selected, icon, userProfile, title, docId, document, chatType} = this.state

        return selected && (
            <ChatHeader
                isCorrectFromGroup={this.isCorrectFromGroup}
                icon={icon}
                userProfile={userProfile}
                title={title}
                docId={docId}
                document={document}
                openChat={this.openChat}
                closeAddChatModal={this.closeAddChatModal}
                isGroupChat={chatType === GroupMessages}
                chatId={selected} />
        )
    }
    renderChatContent = ({chatComments}) => {
        const {classes} = this.props
        const {commentsLoading, loadMore} = this.state

        const comments = this.state.items
        const hasMore = this._getHasMore(this.props) && comments.length > 30;

        return (
            <div data-intrcm-id="chat_u49lw29a" className={classes.contentContainer}>
                <ChatContent
                    commentsLoading={commentsLoading}
                    hasMore={hasMore}
                    comments={chatComments}
                    loadMore={loadMore}
                />
                <div data-intrcm-id="chat_4ub0ysyq" style={{ float: "left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>
        )
    }
    renderInput = () => {
        const {selected, title, isAddingComment, document, docId} = this.state

        return (
            <ChatInput
                isAddingComment={isAddingComment}
                conversationId={selected}
                onSend={this.state.addComment}
                title={title}
                onLinkAdd={() => { }}
                currDoc={document}
                docId={docId}
                userData = {this.props.userData}
                disabled={!selected || isAddingComment}
                />
        )
    }
    renderChatRoom = ({chatComments}) => {
        const {selected, chatType, results} = this.state
        const {userData: {profileData: user}, activeUsers} = this.props
        const attachments = getAttachments(chatComments)
        const {participants, docId} = roomSelector(results, selected, activeUsers, user, this.newChatRef.current);

        return selected && <ChatRoom selected={selected} docId={docId} attachments={attachments} user={user} chatType={chatType} participants={participants} />
    }
    renderChatDrawer = () => {
        const {results, openSidebar, selected, loadingDocuments} = this.state
        const {classes} = this.props

        return (
            <ChatDrawer results={results}
                openSidebar={openSidebar}
                setOpenSidebar={this.setOpenSidebar}
                selected={selected}
                isCorrectFromGroup={this.isCorrectFromGroup}
                className={classes.drawer}
                onClick={this.openChat}
                loading={loadingDocuments}
                closeAddChatModal={this.closeAddChatModal} />
        )
    }

    getStateWithChatType = (props) => {
        const { chatType, selected } = this.state;
        if(!chatType || !selected) return undefined
        if(chatType === SavedMessages) return props.savedMessagesState[selected]
        return isDiscussions(chatType) ? props.commentsState[selected] : props.messagesState[selected]
    }
    getRoomWithChatType = (props, chatType) => {
        return this.getChatFeed(props)[isDiscussions(chatType) ? 'documentsComments' : 'directMessages']
    }
    _getFetchingDocumentComments = (props, chatType) => (this.getRoomWithChatType(props, chatType) && this.getRoomWithChatType(props, chatType).isFetching) || false;

    _getHasMore = props => {
        if(this.state.chatType === SavedMessages) return (this.getStateWithChatType(props) && this.getStateWithChatType(props)['hasMoreSavedMessages']) || false
        return (this.getStateWithChatType(props) && this.getStateWithChatType(props)[isDiscussions(this.state.chatType) ? 'hasMoreComments' : 'hasMoreMessages']) || false;
    }
    _getFetchingComments = props => (this.getStateWithChatType(props) && this.getStateWithChatType(props).isFetching) || false;
    _getFetchingMoreComments = props => (this.getStateWithChatType(props) && this.getStateWithChatType(props).isFetchingMore) || false;
    _getPostingComment = props => (this.getStateWithChatType(props) && this.getStateWithChatType(props).isPosting) || false;
    _getComments = props => {
        if(this.state.chatType === SavedMessages) return (this.getStateWithChatType(props) && this.getStateWithChatType(props).savedMessages) || []

        return (this.getStateWithChatType(props) && this.getStateWithChatType(props)[isDiscussions(this.state.chatType) ? 'comments' : 'messages']) || []
    }
    getResults = (props) => this.getChatFeed(props).results
    renderMain = () => {
        const { users, cloudfront, classes } = this.props;
        const { selected, commentsLoading, mapData, openSidebar } = this.state;
        const comments = this.state.items
        const chatComments = commentsLoading || !mapData ? [] : mapData(comments, users, cloudfront);

        return (
        <NewUiWrapper callbacks={{}} store={store}>
            <AppContent>
                <div data-intrcm-id="chat_ppy6qloj" className={classes.headerContainer}>
                    <div data-intrcm-id="chat_ficfjmhh" className={classes.groupContainer}>
                        <div data-intrcm-id="chat_bej7so9p" className={classes.drawerContainer} style={{width: openSidebar ? 385 : 80}}>
                            {this.renderChatDrawer()}
                        </div>
                        <div data-intrcm-id="chat_6sn37lg7" className={classes.columnContainer}>
                        {this.renderHeader()}
                        <div data-intrcm-id="chat_efaw32mw" className={classes.mainContainer}>
                            <div data-intrcm-id="chat_9sx6w33d" className={classes.columnContainer}>
                                {selected ? (
                                    <div id="main-chat-wrap" style={{display: 'contents'}} {...this.props.getRootProps()}>
                                        {this.renderChatContent({chatComments})}
                                        {this.renderInput()}
                                        <input {...this.props.getInputProps()} />
                                    </div>
                                ) : <ChatPlaceHolder />}
                            </div>
                            {this.renderChatRoom({chatComments})}
                        </div>
                        </div>
                    </div>
                </div>
            </AppContent>
        </NewUiWrapper>)
    }

    renderGroup = () => {
        const { users, cloudfront, classes } = this.props;
        const { selected, commentsLoading, mapData, openSidebar } = this.state;
        const comments = this.state.items
        const chatComments = commentsLoading || !mapData ? [] : mapData(comments, users, cloudfront);

        return (
        <NewUiWrapper callbacks={{}} store={store}>
            <div>
                <div data-intrcm-id="chat_ppy6qloj" className={classes.headerContainerGroup}>
                    <div data-intrcm-id="chat_ficfjmhh" className={classes.groupContainer}>
                        <div data-intrcm-id="chat_bej7so9p" className={classes.drawerContainer} style={{width: openSidebar ? 385 : 80}}>
                            {this.renderChatDrawer()}
                        </div>
                        <div data-intrcm-id="chat_6sn37lg7" className={classes.columnContainer}>
                        {this.renderHeader()}
                        <div data-intrcm-id="chat_efaw32mw" className={classes.mainContainer}>
                            <div data-intrcm-id="chat_9sx6w33d" className={classes.columnContainer}>
                                {selected ? (
                                    <div id="main-chat-wrap" style={{display: 'contents'}} {...this.props.getRootProps()}>
                                        {this.renderChatContent({chatComments})}
                                        {this.renderInput()}
                                        <input {...this.props.getInputProps()} />
                                    </div>
                                ) : <ChatPlaceHolder />}
                            </div>
                            {this.renderChatRoom({chatComments})}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </NewUiWrapper>)
    }

    render() {
        return this.isCorrectFromGroup() ? this.renderGroup() : this.renderMain()
    }
}


const ChatWrap = (props) => {
    const onDrop = useCallback((acceptedFiles) => {
        chatDrop?.cb?.(acceptedFiles)
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true});

    return <Chat {...props} getRootProps={getRootProps} getInputProps={getInputProps} />
}

const GroupChatWrap = (props) => {
    const onDrop = useCallback((acceptedFiles) => {
        chatDrop?.cb?.(acceptedFiles)
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true});

    return <Chat fromGroup {...props} getRootProps={getRootProps} getInputProps={getInputProps} />
}

const mapStateToProps = (state, ownProps) => ({
    chatRoom: state.chatRoom,
    userData: state.userData,
    inputMappings: state.globalData.inputMappings,
    users: state.globalData.users,
    activeUsers: state.globalData.activeUsers,
    cloudfront: state.globalData.cloudfront,
    urlQuery: ownProps.location.query.chatid,
    chatFeed: state.data.chatData.chatFeed,
    chatFeedGroup: state.data.chatData.chatFeedGroup,
    commentsState: state.data.documentsAdditionalData.comments.data,
    messagesState: state.data.chatData.messages.data,
    savedMessagesState: state.data.chatData.savedMessages.data,
    isFocusedChatsModal: state.chatsModal.isFocused,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        uppendDataResult: (result) => dispatch(uppendDataResult(result)),
    }
}

export default compose(
    withTranslation('chat'),
    withPusher,
    withErrorCatcher(),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatWrap);


export const FromGroupChat = compose(
    withTranslation('chat'),
    withPusher,
    withErrorCatcher(),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupChatWrap);