import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Group from './GroupSearchItem';
import NewGroup from './NewGroup';
import { connect } from 'react-redux';
import goToCollection from '../../../../../actions/goToCollection';
import NoItemsPlaceholder from '../../../../../views/knowledge/Library/NoItemsPlaceholder/NoItemsPlaceholder';
import { mapSearchGroups } from '../../../../../../utils/searchNavigation/searchHelpers';
import { markStarredGroup, unmarkStarredGroup } from '../../../../../../actions/searchNavigationActions';
import { showSnackbar } from '../../../../../../actions/appActions';
import { getGroupSuggestions } from '../../../../Common/autosuggestField/autosuggestUtil/levenshtein';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../../../actions/upgradeSubscriptionActions';
import { getAllCollection } from '../../../../../views/groups/groupUtils';
import parseImagUrl from "../../../../Common/parseImagUrl";
import { mapCollectionToGroupNavigation } from '../../../../../../utils/groupsNavigation/collectionToGroupNavigation';
import { getUniqueBy } from '../../../../../../utils/commonHelpers';
import groupPlaceholder from "../../../../../../assets/img/group_placeholder.png";
import { push } from 'react-router-redux';

const styles = (theme) => ({
    container: {
        marginTop: 28,
        width: theme.constants.searchInputWidth - 40,
        maxWidth: `calc(${theme.constants.searchInputWidth}px - 40px)`,
        height: 'fit-content',
        maxHeight: '80vh',
        marginRight: 20,
        marginLeft: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.8,
        letterSpacing: 0.31,
        color: '#21212d',
    },
    seeAll: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 13,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.23,
        letterSpacing: 0.5,
        textAlign: 'right',
        color: theme.customColors.primary.main,
        cursor: 'pointer'
    },
    myGroups: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    managedGroups: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    favouritesGroups: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        marginTop: 24,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 2,
        color: '#979aaa'
    },
    noGroups: {
    }
})

const SHOW_MY_GROUPS_LENGTH = 6;
const SHOW_MANAGED_GROUPS_LENGTH = 6;
const SHOW_FAVOURITES_GROUPS_LENGTH = 4;

const initFilterGroups = {
    openGroups: [],
    managedGroups: [],
    favouriresGroups: [],
    myGroups: []
}
const filterGroups = (filter = initFilterGroups, query) => {
    const { managedGroups, myGroups, favouriresGroups, openGroups} = filter
    const q = query.slice(1).toLowerCase();
    const grs = [...managedGroups, ...myGroups, ...favouriresGroups, ...openGroups]
    const unique = getUniqueBy('groupUid', grs)
    return getGroupSuggestions(unique, q, 10);
}


function GroupsSection(props) {
    const {
        classes,
        t,
        dispatch,
        query,
        searchNavigation,
        groupService,
        withManagedGroups,
        withSubscribedGroups,
        withStarredGroups,
        subscription,
        withSeeAll,
        withAddNew,
        withSlice,
        withFilter,
        filterMode,
        withKeysNavigation,
        openCollections,
        cloudfront,
    } = props
    const { managedGroups: managed, subscribedGroups: subscribed, starredGroups: starred } = searchNavigation

    const [myGroups, setMyGroups] = useState(withSlice ? subscribed.slice(0, SHOW_MY_GROUPS_LENGTH) : subscribed)
    const [favouritesGroups, setFavouriesGroups] = useState(withSlice ? starred.slice(0, SHOW_FAVOURITES_GROUPS_LENGTH) : starred)
    const [managedGroups, setManagedGroups] = useState(withSlice ? managed.slice(0, SHOW_MANAGED_GROUPS_LENGTH) : managed)
    const [filteredGroups, setFilteredGroups] = useState([])
    const filterRef = useRef(initFilterGroups)
    const [isLoading, setIsLoading] = useState(false)

    const favouritesUids = favouritesGroups.map(group => group.collectionUid)
    useEffect(() => {
        if (!withFilter) return;

        const { managedGroups, favouriresGroups, myGroups, openGroups } = filterRef.current
        const filtered = filterGroups({
            managedGroups,
            favouriresGroups,
            myGroups,
            openGroups
        }, query);

        if (filterMode === "suggestion") {
            setFilteredGroups(filtered.slice(0, SHOW_FAVOURITES_GROUPS_LENGTH))
            return
        }
        setFilteredGroups(filtered)
    }, [query, withFilter, filterMode])

    useEffect(() => {
        filterRef.current.myGroups = myGroups;
        filterRef.current.favouriresGroups = favouritesGroups;
        filterRef.current.managedGroups = managedGroups;
    }, [myGroups, favouritesGroups, managedGroups])

    useEffect(() => {
        const groups = openCollections.map((collection) => {
            const backgroundUrl = parseImagUrl(cloudfront, collection.backgroundUrl, 50, groupPlaceholder);
            return mapCollectionToGroupNavigation({...collection, backgroundUrl})
        })
        filterRef.current.openGroups = groups
    }, [openCollections, cloudfront])

    useEffect(() => {
        // if(!withSubscribedGroups) return;

        

        const myGroups = mapSearchGroups(subscribed, {
            maxLen: SHOW_MY_GROUPS_LENGTH,
            withPlaceholder: true,
            starred,
            withFilterStarred: true,
            withSlice: filterMode === "suggestion" ? false : withSlice
        });
        setMyGroups(myGroups)

    }, [subscribed, starred, withSlice, filterMode])

    useEffect(() => {
        // if(!withManagedGroups) return;
      
        const managedGroups = mapSearchGroups(managed, {
            maxLen: SHOW_MANAGED_GROUPS_LENGTH,
            withPlaceholder: false,
            starred,
            withFilterStarred: true,
            withSlice: filterMode === "suggestion" ? false : withSlice
        });
        setManagedGroups(managedGroups)
    }, [managed, starred, withSlice, filterMode])

    useEffect(() => {
        // if (!withStarredGroups) return;

        const starredGroups = mapSearchGroups(starred, {
            maxLen: SHOW_FAVOURITES_GROUPS_LENGTH,
            withPlaceholder: false,
            withSlice: filterMode === "suggestion" ? false : withSlice
        });
        setFavouriesGroups(starredGroups)
    }, [starred, withSlice, filterMode])

    const hideSearchNavigation = () => {
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
        })
    }

    const openGroup = (e, group) => {
        const {userCollections} = props
        const collection = getAllCollection(userCollections, openCollections).find(col => col.collectionUid === group.collectionUid)
        hideSearchNavigation()
        goToCollection('/' + group.title, dispatch, group.collectionUid, group.groupUid, collection)();
    }
    const onMarkStarredGroup = (collectionUid) => {
        if(isLoading) return
    
        setIsLoading(true)
        dispatch(showSnackbar('adding to favourites...'));
        groupService.AddCollectionToFavorites(collectionUid)
            .then((_) => {
                dispatch(showSnackbar('Added successfully!'));
                dispatch(markStarredGroup(collectionUid))
                setIsLoading(false)
            });
    }
    const onUnmarkStarredGroup = (collectionUid) => {
        if(isLoading) return
    
        setIsLoading(true)
        dispatch(showSnackbar('removing from favourites...'));
        groupService.RemoveCollectionFromFavorites(collectionUid)
            .then((_) => {
                dispatch(showSnackbar('Removed successfully!'));
                dispatch(unmarkStarredGroup(collectionUid))
                setIsLoading(false)
            });
    }
    const openSubscriptionDialog = () => {
        dispatch(initSubscriptionModal({reason: 'groups', mode: 'existed', onClose: hideSearchNavigation}))
        dispatch(openSubscriptionModal())
    }
    const canAddGroup = () => {
        if (subscription.subscriptionType === 0 && (subscribed.length + managed.length) >= 5) return false
        if (subscription.subscriptionType === 1 && (subscribed.length + managed.length) >= 5) return false
        return true

    }
    const onNewGroup = () => {
        if (!canAddGroup()) {
            openSubscriptionDialog()
            return false
        }
        hideSearchNavigation()
        dispatch(push({ pathname: '/creategroup'}))
    }

    const goToSearchGroups = () => {
        dispatch({
            type: 'SEARCH_QUERY', query: { query: '/' }
        })
    }

    const renderHeader = () => {
        return (
            <div data-intrcm-id="GroupsSection_7bgw86c3" className={classes.header}>
                <div data-intrcm-id="GroupsSection_gw3gbbpw" className={classes.title}>{t('Groups')}</div>
                {withSeeAll && <div data-intrcm-id="GroupsSection_99tqdg9m" onClick={goToSearchGroups} className={classes.seeAll}>{t('See all groups').toUpperCase()}</div>}
                {withAddNew && <div data-intrcm-id="GroupsSection_0ao91r9n" onClick={onNewGroup} className={classes.seeAll}>{t('+ Add New Group').toUpperCase()}</div>}
            </div>
        )
    }
    const renderMyGroups = () => {
        if (!withSubscribedGroups || !myGroups.length || withFilter) return
        return (
            <>
                <div data-intrcm-id="GroupsSection_i7j8d52n" className={classes.section}>{t("Member in groups").toUpperCase()}</div>
                <div data-intrcm-id="GroupsSection_7g4lh1c9" className={classes.myGroups}>
                    {myGroups.map(group => <Group withKeysNavigation={withKeysNavigation} isFavourite={false} markStarredGroup={onMarkStarredGroup} unmarkStarredGroup={onUnmarkStarredGroup} openGroup={openGroup} key={group.groupUid} group={group} />)}
                </div>
            </>
        )
    }
    const renderFavouritesGroups = () => {
        if (!withStarredGroups || withFilter) return

        const showAddGroup = (!favouritesGroups.length || favouritesGroups.length === 1) && !query.startsWith('/')
        return (
            <>
                <div data-intrcm-id="GroupsSection_44lb4sa0" className={classes.section}>{t("Favourites").toUpperCase()}</div>
                <div data-intrcm-id="GroupsSection_ljttiauv" className={classes.favouritesGroups}>
                    {(withSlice ? favouritesGroups.slice(0, SHOW_FAVOURITES_GROUPS_LENGTH) : favouritesGroups).map(group => <Group withKeysNavigation={withKeysNavigation} isFavourite openGroup={openGroup} markStarredGroup={onMarkStarredGroup} unmarkStarredGroup={onUnmarkStarredGroup} key={group.groupUid} group={group} />)}
                    {showAddGroup && <NewGroup onNewGroup={onNewGroup} translations={{ newGroup: t('+ Add New Group') }} />}
                </div>
            </>
        )
    }
    const renderManagedGroups = () => {
        if (!withManagedGroups || !managedGroups.length || withFilter) return
        return (
            <>
                <div data-intrcm-id="GroupsSection_44v9rjpp" className={classes.section}>{t("Managed").toUpperCase()}</div>
                <div data-intrcm-id="GroupsSection_fgnz9zf8" className={classes.managedGroups}>
                    {managedGroups.map(group => <Group withKeysNavigation={withKeysNavigation} isFavourite={false} openGroup={openGroup} markStarredGroup={onMarkStarredGroup} unmarkStarredGroup={onUnmarkStarredGroup} key={group.groupUid} group={group} />)}
                </div>
            </>
        )
    }
    const renderNoGroups = () => {
        if (!!favouritesGroups.length || !!myGroups.length || !!managedGroups.length || withFilter) return;

        return <NoItemsPlaceholder containerStyle={classes.noGroups} placeholder={t("No groups available")} />
    }
    const renderFilterGroups = () => {
        if (!withFilter) return
        if (!filteredGroups.length) return <NoItemsPlaceholder containerStyle={classes.noGroups} placeholder={t("No groups available")} />
        const showAddGroup = (filteredGroups.length === 1) && !query.startsWith('/')

        return (
            <>
                <div data-intrcm-id="GroupsSection_kg7ci923" className={classes.section}>{t("Found").toUpperCase()}</div>
                <div data-intrcm-id="GroupsSection_vr0zf297" className={classes.managedGroups}>
                    {filteredGroups.map(group => <Group withKeysNavigation={withKeysNavigation} isFavourite={favouritesUids.includes(group.collectionUid)} openGroup={openGroup} markStarredGroup={onMarkStarredGroup} unmarkStarredGroup={onUnmarkStarredGroup} key={group.groupUid} group={group} />)}
                    {showAddGroup && <NewGroup onNewGroup={onNewGroup} translations={{ newGroup: t('+ Add New Group') }} />}
                </div>
            </>
        )
    }

    if (filterMode === 'suggestion' && withFilter && !filteredGroups.length) return null;

    return (
        <div data-intrcm-id="GroupsSection_y9kruunu" className={classes.container} >
            {renderHeader()}
            {renderNoGroups()}
            {renderFavouritesGroups()}            
            {renderManagedGroups()}
            {renderMyGroups()}
            {renderFilterGroups()}
            <div data-intrcm-id="GroupsSection_60bg1j1z" style={{ height: 15 }} />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        cloudfront: state.globalData.cloudfront,
        openCollections: state.globalData.openCollections,
        userCollections: state.userData.collections,
        query: state.search.query,
        searchNavigation: state.searchNavigation,
        subscription: state.globalData.currentSubscription,
        groupService: state.services.groupService
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, { withTheme: true }),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(GroupsSection)

