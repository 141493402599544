import React from 'react';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@material-ui/core/Button';
import {
    withStyles, TextField, Dialog, DialogActions, FormControl, InputLabel, Select,
    MenuItem, DialogContent, FormControlLabel, Switch, FormHelperText, Typography,
    Tabs, Tab, SwipeableViews, AppBar, Slide
} from '@material-ui/core';

import { inputTypes, alignOptions, colorOptions, variantOptions, DataTypeFieldInputTypeValue, TitleFileInputTypeValue, DataTypeUniqueFieldInputTypeValue } from '../customInputTemplateUtils';
import IconSelecter from './IconSelecter.js';
import DataTypeSelector from './DataTypeSelector.js';
import { CalculationField } from './calculation/CalculationField.js';
import { setOuterChangeOperations, setOuterUnitsCalculation } from './calculation/outerChangeOperations.js';
import { validateFormula, prepareBeforeSave } from './calculation/helpers.js';
import { withTranslation } from 'react-i18next';
import { useNewTable } from '../../../../../utils/useNewTable.js';

const styles = theme => ({
    paper: {
        padding: 30
    },
    divid: {
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    buttonIc: {
        marginTop: '8px',
        fontSize: '14px'
    },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto'
    },
    fieldContainer: {
        margin: '24px 0'
    },
    formControl: {
        margin: '0 5px'
    },
    root: {
        width: 700,
        borderRadius: 16,
        // minHeight: 800
    },
    textField: {
        marginRight: 20
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        paddingBottom: 15,
        fontWeight: 'bold'
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class NewFieldModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            calculatedOperations: [],
            calculationUnits: '',
            fieldName: '',
            fieldDescription: '',
            fieldIcon: '',
            sourceField: '',
            fieldOrder: 0,
            type: '',
            newField: true,
            dataType: null,
            options: null,
            unit: null,
            toAggregate: false,
            required: false,
            conditionFieldFlag: false,
            conditionField: '',
            conditionValue: '',
            textStyleCustom: {
                align: '', color: '', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false
            },
            titleStyleCustom: {
                align: '', color: '', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false
            },
            tab: 'generl'
        }
    }

    componentDidMount() {
        const { field } = this.props;

        if (field) {
            const { fieldName, fieldIcon, dataType, fieldDescription, sourceField, type, options, unit, textStyleCustom, titleStyleCustom, toAggregate, required, conditionField, conditionValue } = field;

            this.setState({
                fieldName, fieldDescription, fieldIcon, sourceField, type, options, dataType, unit, newField: false, toAggregate, required: required,
                textStyleCustom: textStyleCustom ? textStyleCustom : this.state.textStyleCustom,
                titleStyleCustom: titleStyleCustom ? titleStyleCustom : this.state.titleStyleCustom,
                conditionField, conditionValue,
                conditionFieldFlag: !!conditionField
            })
        }
        else {
            this.setState({
                fieldName: '', fieldDescription: '', fieldIcon: '', sourceField: '', type: '', newField: true, dataType: null, options: null, unit: null, toAggregate: false,
                required: false,
                conditionField: '', conditionValue: '', conditionFieldFlag: false,
                textStyleCustom: {
                    align: '', color: '', variant: '', gutterBottom: false, inline: false,
                    internalDeprecatedVariant: false, noWrap: false, paragraph: false
                },
                titleStyleCustom: {
                    align: '', color: '', variant: '', gutterBottom: false, inline: false,
                    internalDeprecatedVariant: false, noWrap: false, paragraph: false
                }
            })
        }
        setOuterChangeOperations((ops) => {
            this.setState({calculatedOperations: ops})
        })
        setOuterUnitsCalculation((units) => {
            this.setState({calculationUnits: units})
        })
    }

    componentWillReceiveProps(nextProps) {
        const { field, fieldsCount } = nextProps;

        if (field) {
            const { fieldName, fieldDescription, fieldIcon, sourceField, fieldOrder, type, options, dataType, unit, textStyleCustom, titleStyleCustom, toAggregate, required, conditionField, conditionValue } = field;

            this.setState({
                fieldName, fieldDescription, fieldIcon, sourceField, fieldOrder, type, options, dataType, unit, newField: false, toAggregate, required, conditionField, conditionValue, conditionFieldFlag: !!conditionField,
                textStyleCustom: textStyleCustom ? textStyleCustom : this.state.textStyleCustom,
                titleStyleCustom: titleStyleCustom ? titleStyleCustom : this.state.titleStyleCustom,
            })
        }
        else {
            this.setState({
                fieldName: '', fieldIcon: '', fieldDescription: '', sourceField: 'field_' + new Date().getTime(), fieldOrder: (fieldsCount + 1) * 10, type: '', newField: true, options: null,
                conditionField: '', conditionValue: '', conditionFieldFlag: false,
                dataType: null, unit: null, toAggregate: false, required: false,
                textStyleCustom: {
                    align: '', color: '', variant: '', gutterBottom: false, inline: false,
                    internalDeprecatedVariant: false, noWrap: false, paragraph: false
                },
                titleStyleCustom: {
                    align: '', color: '', variant: '', gutterBottom: false, inline: false,
                    internalDeprecatedVariant: false, noWrap: false, paragraph: false
                }
            })
        }
    }
    handleRequiredStatus = (e) => {
        if(this.isBusinessIdType()) return
        this.handleChange('required')(e)
    }
    handleConditionStatus = (e) => {
        this.setState({ conditionFieldFlag: !this.state.conditionFieldFlag, conditionField: '', conditionValue: '' })
    }

    handleTitleCollapseStatus = (e) => {
        this.setState({ options: !!this.state.options ? null : 'true' })
    }

    handleChange = (name, idx) => (e) => {
        if (name === 'required') {
            this.setState({ [name]: e.target.checked })
        }
        else {
            if(name === 'options') e.target.value = e.target.value
            this.setState({ [name]: e.target.value })
        }
    }

    onAdd = () => {
        const { onClose, containerIndex, sectionIndex, field } = this.props;
        const { calculationUnits, calculatedOperations, fieldName, fieldIcon, fieldDescription, sourceField, fieldOrder, type, options, dataType, unit, newField, toAggregate, textStyleCustom, titleStyleCustom, required, conditionField, conditionValue, conditionFieldFlag, dataTypeUniqueFlag } = this.state;
        const requiredStatus = this.isBusinessIdType() ? true : required
        
        let opts = options
        if(type === 'calculation') {
            const x = prepareBeforeSave(calculatedOperations, calculationUnits)
            opts = x
        }

        onClose({
            field: { fieldName, fieldDescription, fieldIcon, sourceField, fieldOrder, type, options: opts, dataType, unit, toAggregate, required: requiredStatus, id: field ? field.id : 0, conditionField: conditionFieldFlag ? conditionField : '', conditionValue: conditionFieldFlag ? conditionValue : '' },
            style: textStyleCustom,
            titleStyle: titleStyleCustom,
            containerIndex,
            sectionIndex,
            isNew: newField
        })
    }

    onDelete = () => {
        const { onDelete, containerIndex, sectionIndex, field } = this.props;

        onDelete(containerIndex, sectionIndex, field.sourceField);
    }

    handleChangeStyle = (isTitle, name, isChecked) => (e) => {
        let value = isChecked ? e.target.checked : e.target.value;

        if (isTitle) {
            let titleStyleCustom = { ...this.state.titleStyleCustom };
            titleStyleCustom[name] = value

            this.setState({ titleStyleCustom })
        }
        else {
            let textStyleCustom = { ...this.state.textStyleCustom };
            textStyleCustom[name] = value

            this.setState({ textStyleCustom })
        }
    }

    renderStyleSelection = (inputLabel, value, fieldName, options, formHelperText, isTitle) => {
        const { classes,t } = this.props;

        return <FormControl className={classes.formControl} fullWidth >
            <InputLabel >{t(inputLabel)}</InputLabel>
            <Select
                value={value}
                onChange={this.handleChangeStyle(isTitle, fieldName)}
            >
                <MenuItem value=""><em>{t('None')}</em></MenuItem>
                {options.map((option, idx) => (
                    <MenuItem value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id="component-helper-text">{t(formHelperText)}</FormHelperText>
        </FormControl>
    }

    renderStyleCheckBox = (inputLabel, value, fieldName, formHelperText, isTitle) => {
        const { classes } = this.props;

        return <FormControl className={classes.formControl} fullWidth>
            <FormControlLabel
                control={
                    <Switch checked={value}
                        onChange={this.handleChangeStyle(isTitle, fieldName, true)}
                    />}
                label={inputLabel}
            />
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderStyle = (isTitle) => {
        const { classes,t } = this.props;
        const { textStyleCustom, titleStyleCustom } = this.state;
        const { align, color, variant, gutterBottom, inline, internalDeprecatedVariant, noWrap, paragraph } = isTitle ? titleStyleCustom : textStyleCustom;

        return <div data-intrcm-id="newFieldModal_2plkdgbe">
            <Typography classes={{ root: classes.title }}>{isTitle ? 'Title Style' : 'Content Style'}</Typography>
            {this.renderStyleSelection('Align', align, 'align', alignOptions, 'Set the text-align on the component.', isTitle)}
            {this.renderStyleSelection('Color', color, 'color', colorOptions, 'The color of the component. It supports those theme colors that make sense for this component.', isTitle)}
            {this.renderStyleSelection('Variant', variant, 'variant', variantOptions, 'Applies the theme typography styles. Use body1 as the default value with the legacy implementation and body2 with the new one.', isTitle)}

            {this.renderStyleCheckBox('Gutter Bottom', gutterBottom, 'gutterBottom', 'If true, the text will have a bottom margin.', isTitle)}
            {this.renderStyleCheckBox('Inline', inline, 'inline', 'Controls whether the Typography is inline or not.', isTitle)}
            {this.renderStyleCheckBox('Internal Deprecated Variant', internalDeprecatedVariant, 'internalDeprecatedVariant',
                <div data-intrcm-id="newFieldModal_tsug35da">
                    <div data-intrcm-id="newFieldModal_zjl99e6w">{t('A deprecated variant is used from an internal component')}.</div>
                    <div data-intrcm-id="newFieldModal_pg2orv9a">{t(`Users don't need a deprecation warning here if they switched to the v2 theme`)}.</div>
                    <div data-intrcm-id="newFieldModal_r0dzoa2j">{t('They already get the mapping that will be applied in the next major release')}.</div>
                </div>
                , isTitle)}
            {this.renderStyleCheckBox('No Wrap', noWrap, 'noWrap', 'If true, the text will not wrap, but instead will truncate with an ellipsis.')}
            {this.renderStyleCheckBox('Paragraph', paragraph, 'paragraph', 'If true, the text will have a bottom margin.', isTitle)}
        </div>
    }
    isBusinessIdType = () => {
        const { type } = this.state;
        const inputType = inputTypes.find(inputType => inputType.key === type)
        return inputType && inputType.key === 'businessId'
    }

    checkDataTypeAggregation = (inputTypes) => {
        let ts = inputTypes

        if(!!useNewTable(this.props.orgUid)) {
            ts = ts.map(inputType => inputType.key === 'datatype' ? ({...inputType, disabled: true}) : inputType)
        } else {
            ts = ts.filter(x => x.key !== 'datatypeUnique')
        }
        return ts
    }

    getInputTypes = () => {
        const { isBusinessIdExists } = this.props;
        if(!isBusinessIdExists) {
            return this.checkDataTypeAggregation(inputTypes)
        }

        let ts = inputTypes.map(inputType => inputType.key === 'businessId' ? ({...inputType, disabled: true}) : inputType)

        return this.checkDataTypeAggregation(ts)
    }
    isDisableAdd = () => {
        const { fieldName, type, dataType, calculatedOperations } = this.state;
        if(!fieldName) return true
        if(!type) return true
        if(type === 'datatype' && !dataType) return true
        if(type === 'datatypeUnique' && !dataType) return true
        if(type === 'calculation' && (!calculatedOperations.length || !validateFormula(calculatedOperations))) return true
    }

    ignoreCondition = ['datatype', 'datatypeUnique', 'file']

    getSourceFields = () => {
        const {customFieldsMappings} = this.props
        return customFieldsMappings
            .filter(f => !this.ignoreCondition.includes(f.type))
            .map((f) => ({disabled: false, key: f.sourceField, value: f.sourceField, name: f.fieldName}))
    }

    renderConditionField = () => {
        const {conditionField, conditionValue, conditionFieldFlag} = this.state
        const {classes,t} = this.props
        if(!conditionFieldFlag) return null
        return (
            <>
            <FormControl style={{marginTop: 5, marginBottom: 5}} fullWidth className={classes.formControl}>
                <InputLabel style={{marginTop: 2}} >{t('Condition source field')}</InputLabel>
                <Select value={conditionField} defaultValue="Select existed field" inputProps={{ name: 'conditionField', id: 'conditionField', }}
                    disabled={false} required error={!conditionField} onChange={this.handleChange('conditionField')}
                >
                    <MenuItem value={''}><em>{t('Select existed field')}</em></MenuItem>
                    {this.getSourceFields().map((type, idx) => {
                        return <MenuItem disabled={type.disabled} key={type.key} value={type.value} >{`${type.value} (${type.name})`}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    disabled={false}
                    label={t('Condition field value')}
                    helperText={t('Enter the values of the conditional field separated by ;')}
                    onChange={this.handleChange('conditionValue')}
                    value={conditionValue}
                    fullWidth />
            </FormControl>
            </>
        )
    }
    renderCalculationField = (inputType) => {
        if(!inputType?.key || inputType.key !== 'calculation') return null
        const { newField, options } = this.state;
        const { customFieldsMappings } = this.props;

        const numberFields = (customFieldsMappings ?? []).filter(x => x.type === 'number')
        return (
            <CalculationField numberFields={numberFields} isNewField={newField} options={options} />
        )
    }

    renderConditionFlag = () => {
        const {type, conditionFieldFlag} = this.state
        const {t} = this.props;
        return !!type ? <FormControlLabel
            control={<Switch
                checked={conditionFieldFlag}
                onChange={this.handleConditionStatus}
                color="primary"
            />}
            label={`${t("Condition field")}?`}
            
        /> : null
    }


    renderTitleCollapsedFlag = () => {
        const {t} = this.props;
        const {type, options} = this.state
        if(!type || type !== 'title') return null
        return <FormControlLabel
            control={<Switch
                checked={!!options && options === 'true'}
                onChange={this.handleTitleCollapseStatus}
                color="primary"
            />}
            label={`${t("Collapsed default")}?`}
        />
    }
    renderGeneral = () => {
        const { classes ,t} = this.props;
        const { fieldName, fieldIcon, fieldDescription, sourceField, fieldOrder, type, newField, options, dataType, unit, required, conditionFieldFlag } = this.state;

        const inputType = inputTypes.find(inputType => inputType.key === type)

        const requiredStatus = this.isBusinessIdType() ? true : required
        return <div data-intrcm-id="newFieldModal_xfi94bsw">
            <Typography classes={{ root: classes.title }}>{t('General')}</Typography>
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    error={!fieldName}
                    required={true}
                    label={t('Field name')}
                    helperText={t('Type the field name')}
                    onChange={this.handleChange('fieldName')}
                    value={fieldName}
                    fullWidth />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    label={t('Description (optional)')}
                    helperText={t('Type the field description')}
                    onChange={this.handleChange('fieldDescription')}
                    value={fieldDescription}
                    fullWidth />
            </FormControl>
            <IconSelecter icon={fieldIcon} onChange={this.handleChange('fieldIcon')} />
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="type">{t('Type')}</InputLabel>
                <Select fullWidth={true} value={type} inputProps={{ name: t('type'), id: 'type', }}
                    disabled={!newField} required={true} error={!type} onChange={this.handleChange('type')}
                >
                    <MenuItem value={''}><em>{t('Select field')}</em></MenuItem>

                    {this.getInputTypes().map((type, idx) => {
                        return <MenuItem disabled={type.disabled} key={idx} value={type.key} >{type.value}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    label={t('Source field')}
                    helperText={t('Source field - used for integrations')}
                    onChange={this.handleChange('sourceField')}
                    disabled={!newField}
                    value={sourceField}
                    disabled
                    fullWidth />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    label={t('Field order')}
                    helperText={t('Set the field order; the field position in the document')}
                    onChange={this.handleChange('fieldOrder')}
                    value={fieldOrder}
                    fullWidth />
            </FormControl>
            {inputType && inputType.value === DataTypeFieldInputTypeValue ? <DataTypeSelector value={dataType} onChange={this.handleChange('dataType')} /> : null}
            {inputType && inputType.value === DataTypeUniqueFieldInputTypeValue ? <DataTypeSelector value={dataType} onChange={this.handleChange('dataType')} /> : null}
            {inputType && inputType.optionalHelperText ?
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label={t('Options')}
                        helperText={t(inputType.optionalHelperText)}
                        onChange={this.handleChange('options')}
                        value={options}
                        multiline
                        rowsMax={5}
                        fullWidth />
                </FormControl>
                : null
            }
            {inputType && inputType.unitHelpText && inputType.key !== 'calculation' ?
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label={t('Unit')}
                        helperText={t(inputType.unitHelpText)}
                        onChange={this.handleChange('unit')}
                        value={unit}
                        fullWidth />
                </FormControl>
                : null
            }
            {this.renderCalculationField(inputType)}
            {this.renderConditionField()}
            {inputType && inputType.value !== TitleFileInputTypeValue && inputType.key !== 'calculation' ? <FormControlLabel
                control={<Switch
                    checked={requiredStatus}
                    onChange={this.handleRequiredStatus}
                    color="primary"
                />}
                label={`${t("Required")}?`}
            /> : null}
            {this.renderConditionFlag()}
            {this.renderTitleCollapsedFlag()}
        </div>
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ tab: newValue })
    }

    render() {
        const { open, onClose, classes, field,t } = this.props;
        const { newField, tab } = this.state;

        return <Dialog
            style={{ overflow: 'auto' }}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            transition={Transition}
        // classes={{ paper: classes.root }}
        >
            <DialogContent>
                <AppBar position="static" color="default">
                    <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={this.handleChangeTab} >
                        <Tab label={t("General")} value='generl' />
                        <Tab disabled label={t("Content Style")} value='content' />
                        <Tab disabled label={t("Title Style")} value='title' />
                    </Tabs>
                </AppBar>

                {tab === 'generl' && this.renderGeneral()}
                {tab === 'content' && this.renderStyle(false)}
                {tab === 'title' && this.renderStyle(true)}
            </DialogContent>



            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Cancel')}
                </Button>
                {field
                    ? <Button onClick={this.onDelete} color="primary">
                        {t('Delete')}
                    </Button>
                    : <div data-intrcm-id="newFieldModal_9anbunxi" />
                }
                <Button disabled={this.isDisableAdd()} onClick={this.onAdd} color="primary">
                    {newField ? t("Add") : t("Update")}
                </Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    customInputService: state.services.customInputService,
    globalData: state.globalData,
    mappings: state.customInputMapping.currentMapping
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        goTo: path => { dispatch(push(path)) }
    }
}

export default compose(withStyles(styles),
withTranslation('admin'),
    connect(mapStateToProps, mapDispatchToProps)
)(NewFieldModal);