import React from 'react'
import { Column, ColumnStore } from '@bryntum/gantt';
import AssignUsers from './AssignUsers';

export default class AssignColumn extends Column {
    static get $name() {
        return 'AssignColumn';
    }

    static get type() {
        return 'resourceassign';
    }

    static get isGanttColumn() {
        return true;
    }

    static get defaults() {
        return {
            field      : 'assignedResource',
            cellCls    : 'b-status-column-cell',
        };
    }

    renderer(xxx) {
        const { record, value } = xxx
        const { id, originalData: {isItemRow,  }} = record;
        if(!isItemRow || !id) return ''

        return <AssignUsers record={record} value={value} />
    }
}

ColumnStore.registerColumnType(AssignColumn);
