import { Divider, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import LeftDrawerCustomItemCategory from './LeftDrawerCustomItemCategory';
import {useOrgMenuAllowed} from '../../../../../hooks/useOrgMenu/useOrgMenuAllowed' 
import {useOrgMenuResolvers} from '../../../../../hooks/useOrgMenu/useOrgMenuResolvers' 

const styles = (theme) => ({
    customIcon: {

    },
    divider: {
        marginTop: 8,
        marginBottom: 8,
        backgroundColor: '#B7B8BD26',
        marginLeft: -8,
        marginRight: -8,
    },
    shortCutTitle:{
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#6B6F7A',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px'
    }
})

const _LeftDrawerCustomItems = (props) => {
    const {classes, orgMenuItems, token, t} = props
    // const [t] = useTranslation("Shortcuts")

    const [allowedItemsUids] = useOrgMenuAllowed({token, orgMenuItems, allowFetch: true})
    const {getFirstLayer} = useOrgMenuResolvers()

    const firstLayer = useMemo(() => {
        return getFirstLayer(orgMenuItems)
    }, [orgMenuItems])


    if(!firstLayer.length) return null

    const List = <>
    <Divider className={classes.divider} />
    <div className={classes.shortCutTitle}>{t("Shortcuts")}</div>
    {firstLayer.map((item) => <LeftDrawerCustomItemCategory item={item} allowedItemsUids={allowedItemsUids} />)}
    </>

    return <>
        {List}
    </>
}

const mapStateToProps = (state) => ({
    token: state.data.auth.token,
    orgMenuItems: state.feedNavigation.orgMenuItems,
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    withErrorCatcher(),
    withStyles(styles, {withTheme: true}),
    withTranslation('drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps)
)(_LeftDrawerCustomItems);
