import { withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SuggestedDocItem } from '../Common/SuggestedDocItem';

const styles = (theme) => ({
    container: {
        marginTop: 24
    },
    label: {
        marginBottom: 8,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        color: '#737373'
    }
})

function _LaunchpadItemsSection(props) {
    const {classes, searchText, selectedCategory, queryService} = props
    const [t] = useTranslation("search")
    const [filtered, setFiltered] = useState([])

    useEffect(() => {
        const q = searchText.toLowerCase().trim()
        queryService.autosuggestItems(q)
            .then(({isAborted, val}) => {
                if(isAborted || !val.length) {
                    setFiltered([])
                    return
                }
                const suggested = val.map(suggestion => ({
                    docId: suggestion.docId,
                    title: suggestion.title,
                }))
                setFiltered(suggested.slice(0, 8))
            })
    }, [searchText])

    if(!filtered.length) return null
    return (
        <div data-intrcm-id="LaunchpadItemsSection_ywmifjg2" className={classes.container} >
            <div data-intrcm-id="LaunchpadItemsSection_3j0gqi6w" className={classes.label}>{t("Items")}</div>
            {filtered.map((col) => <SuggestedDocItem selectedCategory={selectedCategory} key={col.docId} item={col} />)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedCategory: state.launchpadNavigation.selectedCategory,
    queryService: state.services.queryService
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export const LaunchpadItemsSection = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_LaunchpadItemsSection)

