import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer';
import FavoritesKanban from './FavoritesKanban';





const numFacePile = 3


const styles = theme => ({
    titleContainer: {
        height: 32,
        margin: '5px 0px 30px 0px',
    },
    title: {
        fontFamily: "'Montserrat'",
        fontSize: 16,
        fontWeight: 300,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.5,
        letterSpacing: '0.2px',
        textAlign: 'center',
        color: '#302f47'
    },

    collectionName: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
        paddingLeft: 9

    },
    dot: {
        color: 'rgba(48 ,47 ,71, 0.54)',
        margin: '0px 12px'
    },
    // container: {
    //     height: '100%',
    //     overflow: 'hidden',
    //     marginLeft: 328
    // },
})

class MyPageFavoritesView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '/favourites'
        }
    }

    sumResult() {
        const { filters } = this.props

        const filter = filters && filters.find(f => f.Key == 'All')

        if (filter) {
            return <span>({filter.DocCount}) </span>
        }

        return null
    }

    handleChange = (event, value) => {
        const { dispatch } = this.props
        this.setState({ value });
        dispatch(push({ pathname: value }))

        // todo check if it's work in all case
        dispatch({ type: 'SEARCH_QUERY', query: { page: 0 } })
    };

    render() {
        const { filters, classes, children, t, isDrawerOpen } = this.props
        const tabClasses = { labelContainer: classes.labelContainer, label: classes.tabLabel, root: classes.tabRoot, selected: classes.tabSelected, wrapper: classes.wrapperTab }

        return <CategoryViewOnlyLeftDrawer>
            <div data-intrcm-id="MyPageFavoritesx_7ose01z7" className={classes.container}>
                <FavoritesKanban isDrawerOpen={isDrawerOpen}/>
            </div>
        </CategoryViewOnlyLeftDrawer>
    }
}

// export default GroupView;
const mapStateToProps = (state, ownProps) => ({
    ...state.wrapper,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    userData: state.userData,
    router: state.router,
    ...state.services,
    viewOptions: state.viewOptions,
    oldUiData: state.oldUiData,
    filters: state.results.dataTypes,
    channel: state.results.channel,

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles), withTranslation('favouritesView'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MyPageFavoritesView);