import React, { PureComponent } from 'react'
import { withStyles,  Button } from '@material-ui/core'
import { compose } from 'recompose';
import { connect } from 'react-redux'
import { FULLVIEW_POST, INPUT_OPEN,  FULLVIEW_CLOSE } from '../../../actionTypes/actionTypes';
import { withTranslation } from 'react-i18next'
import { shouldAutoOpen } from '../../views/groups/groupUtils.js';
import { openInput, changeCollectionInputInfo } from '../../../actions/inputActions.js';
import { canUserAddItemToGroup } from '../Common/Utils/userPermissionsUtils.js';
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import { openInput as openNewUiInput } from '@tuqqi/web'

const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 18 18">
        <defs>
            <path id="AddIcon__a" d="M12.786 7.964H7.964v4.822c0 .53-.434.964-.964.964s-.964-.434-.964-.964V7.964H1.214C.684 7.964.25 7.53.25 7s.434-.964.964-.964h4.822V1.214c0-.53.434-.964.964-.964s.964.434.964.964v4.822h4.822c.53 0 .964.434.964.964s-.434.964-.964.964z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
            <mask id="AddIcon__b" fill="#fff">
                <use href="#AddIcon__a" />
            </mask>
            <g fill="#979AAA" mask="url(#AddIcon__b)">
                <path d="M0 0H18V18H0z" transform="translate(-2 -2)" />
            </g>
        </g>
    </svg>
)
const styles = theme => ({
    container: {
        margin: '16px 0px 6px 10px',
        flex: '0 0 auto',
        width: 264,
        "height": "34px",
        "borderRadius": "4px",
        "border": `solid 1px  #B6B9CA`,
        "backgroundColor": "#ffffff",
        '&:hover': {
            backgroundColor: 'rgba(33, 33, 45, 0.04)'
        }
    }
})


class CardAdder extends PureComponent {
    addCard = () => {
        const { dispatch, groupUid, list, groupInfo, inputMappings, oldUiInput, userData, searchQuery, changeCollectionInputInfo } = this.props
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = list.id === -1 ? "" : list.id + ''

        if (shouldAutoOpen(groupInfo)) {
            const dataType = groupInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }

        changeCollectionInputInfo({ kanbanListId })
    }

    hasPermissionToAdd = (groupUid) => {
        const { collectionUid, userData } = this.props

        return canUserAddItemToGroup(userData.profileData, userData.collections, collectionUid)
    }

    render() {
        const { classes, groupUid, t, list } = this.props

        if (!groupUid || !this.hasPermissionToAdd(groupUid)) {
            return null
        }

        return (
            <Button
                className={classes.container}
                onClick={this.addCard}>
                <AddIcon />
            </Button>
        )
    }
}


const mapStateToProps = (state) => ({
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
    groupInfo: state.results.channel,
    groups: state.userData.groups,
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    searchQuery: state.search,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
})

export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CardAdder);
