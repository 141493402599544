import { Grid, InputBase, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher.js';
import ListHeaderContainer from './ListHeader/ListHeaderContainer.js';
import GroupKanbanColumnsPlaceholder from '../Placeholders/GroupKanbanColumnsPlaceholder.js';

const CancelIcon = ({ onClick }) => {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="CancelIcon__a" d="M12.893.302c-.39-.39-1.02-.39-1.41 0l-4.89 4.88-4.89-4.89c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.89 4.89-4.89 4.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.89-4.89 4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41l-4.89-4.89 4.89-4.89c.38-.38.38-1.02 0-1.4z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(5 5)">
                <mask id="CancelIcon__b" fill="#fff">
                    <use href="#CancelIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#CancelIcon__b)">
                    <path d="M0 0H24V24H0z" transform="translate(-5 -5)" />
                </g>
            </g>
        </svg>
    )
}

const styles = theme => ({
    inputContainer: {
        marginLeft: 0
    },
    container: {
        marginLeft: 26,
        width: 246
    },
    actionContainer: {
        marginTop: 8
    },
    addButton: {
        "borderRadius": "4px",
        "border": `solid 1px ${theme.newUI.color.primary}`,
        padding: '4px 8px',
        marginRight: 13,
        color: theme.newUI.color.primary
    },

    input: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        color: theme.newUI.color.commonBlack,
        '&::placeholder': {
            color: theme.newUI.color.gray,
        }
    }
})


class ListAdder extends React.Component {
    state = {
        title: '',
    }

    edit = (e) => {
        this.setState({ title: e.target.value })
    }

    addList = () => {

        if (this.state.title.length) {
            this.props.addList(this.state.title)
            this.setState({ title: '' })
        }
    }

    cancelAdd = () => {
        this.setState({ title: '' })
    }

    onKeyDown = e => {
        const enterKeyCode = 13
        if (e.keyCode === enterKeyCode) {
            this.addList()
        }
    }

    render() {
        const { title, disabled } = this.state
        const { classes, t } = this.props

        return (
            <Grid container direction='column' className={classes.container}>
                <ListHeaderContainer classes={{ container: classes.inputContainer }}>
                    <InputBase
                        disabled={disabled}
                        autoFocus={false} //important  
                        className={classes.input}
                        placeholder={t('+Add lane')}
                        value={title}
                        onChange={this.edit}
                        onKeyDown={this.onKeyDown}
                        value={title} />
                </ListHeaderContainer>

                {
                    Boolean(title) &&
                    <Grid item container alignItems='center' className={classes.actionContainer}>
                        <Grid onClick={this.addList} item className={classes.addButton}>{t('Add')}</Grid>
                        <CancelIcon onClick={this.cancelAdd} />
                    </Grid>
                }                
            </Grid>
        )
    }
}


export default compose(withErrorCatcher(), withTranslation('kanbanTranslate'), withStyles(styles))(ListAdder);
