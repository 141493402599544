import { InputBase, withStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { LaunchpadSearchIcon } from '../../../Common/Icons/LaunchpadSearchIcon';
import {LaunchpadGroupsSection} from './LaunchpadGroupsSection'
import { LaunchpadItemsSection } from './LaunchpadItemsSection';
import { LaunchpadNoItemsPlaceholder } from './NoItemsPlaceholder';

const styles = (theme) => ({
    container: {
        paddingRight: 16,
        paddingLeft: 16,
        minHeight: 56,
    },
    searchIcon: {
        left: 8,
        position: 'absolute'
    },
    inputWrap: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: 28,
        width: theme.constants.launchpadWidth - 32,
        marginTop: 16
    }
})

const CustomInput = withStyles((theme) => ({
    input: {
        border: `transparent 1px solid`,
        'transition-property': 'border',
        transitionDuration: '.5s',
        '&:focus': {
            border: `${theme.customColors.primary.main} 1px solid`
        },
        paddingLeft: 32,
        paddingRight: 8,
        width: theme.constants.launchpadWidth - 32,
        height: 28,
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(123, 79, 240, 0.16)',
        borderRadius: 4,
        backgroundColor: '#EDEDF0',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#0C0C0D',
        "&::placeholder": {
            color: '#B1B1B3',
        },
    },
  }))(InputBase);

function _AddItemContent(props) {
    const {classes} = props
    const [t] = useTranslation("search")
    const [searchText, setSearchText] = useState("")

    const handleChange = useCallback((e) => {
        setSearchText(e.target.value)
    }, []);

    const Content = !searchText.trim() ?
        <LaunchpadNoItemsPlaceholder /> :
        <>
            <LaunchpadGroupsSection searchText={searchText}  />
            <LaunchpadItemsSection searchText={searchText}  />
        </>
    return (
        <div data-intrcm-id="AddItemContent_p667wmag" className={classes.container} >
            <div data-intrcm-id="AddItemContent_bp2l1c6c" className={classes.inputWrap}>
                <LaunchpadSearchIcon className={classes.searchIcon} />
                <CustomInput
                    type='text'
                    autoFocus={false}
                    onChange={handleChange}
                    placeholder={t("Search for group, item, link", "Search for group, item, link...")}
                    value={searchText}
                />
            </div>
            {Content}
        </div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export const AddItemContent = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_AddItemContent)

