import { withStyles } from '@material-ui/core';
import { Billing as BillingViewWeb, NewUiWrapper } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { store } from '../../../..';

const styles = theme => ({
    wrap: {

    }
});

const BillingView = (props) => {
    const callbacks = {
    }


    return <NewUiWrapper store={store} callbacks={callbacks}>
        <BillingViewWeb />
    </NewUiWrapper>}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(BillingView);
