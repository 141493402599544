import React, { memo, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { isEqualObjJson } from '@tuqqi/common'

import { TableFieldReadOnlyTooltip, managerGanttRef } from '@tuqqi/web'
import { StatusView } from './StatusView'
import StatusEdit from './StatusEdit'

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    containerEdit: {
        outline: 'auto!important',
        outlineColor: '#7B4FF0!important',
        height: '38px!important',
    },
    container: {
        height: 40,
        position: 'relative',
        boxShadow: 'none',
        marginRight: -4,
        marginLeft: -4,
        marginTop: 1,
        marginBottom: 1,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: 'initial',
        outline: 'none',
        width: '-webkit-fill-available',
    },
})

const checkApi = (record, originalValue, newValue) => {
    if(isEqualObjJson(originalValue, newValue)) return

    if(!record) return
    record.set('statusTask', newValue)
    managerGanttRef.updateStatusApi(record, originalValue, newValue)
}

const DocStatus = memo((props) => {
    const [open, setOpen] = useState(false)
    const {classes} = props
    const [valueLocal, setValueLocal] = useState(props.value)
    const valueLocalRef = useRef(valueLocal)
    const containerRef = useRef()

    const disabled = !props.record?.data?.editable?.status

    useEffect(() => {
        setValueLocal(props.value)
    }, [props.value])

    useEffect(() => {
        valueLocalRef.current = valueLocal
    }, [valueLocal])


    const handleOpen = (e) => {
        if(!!disabled) return
        if(open) return
        setOpen(true)
    }

    const onClose = () => {
        if(!open) return
        checkApi(props.record, props.value, valueLocalRef.current)
        setOpen(false)
    }

    const onChange = (newVal, needClose) => {
        setValueLocal(newVal)
        if(needClose) {
            valueLocalRef.current = newVal
            onClose()
        }
    }

    
    const X = <div onClick={handleOpen} ref={containerRef} className={`${classes.container} ${open ? classes.containerEdit : ''} ${!disabled ? classes.cursor : ''}`}>
        <StatusView value={valueLocal} />
        {open && <StatusEdit value={valueLocal} containerRef={containerRef} onChange={onChange} onClose={onClose} />}
    </div>

    if(disabled) return <TableFieldReadOnlyTooltip component={X} />

    return X
})

export default withStyles(styles, {withTheme: true})(DocStatus)