import React, { Fragment, createRef } from 'react'
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../actions/appActions'; //'../../actions/appActions'
import withErrorCatcher from '../Common/withErrorCatcher.js';
import Board from "./board";
import GroupKanbanPlaceholder from '../Placeholders/GroupKanbanPlaceholder';
import { withMixpanel } from '../Common/withMixpanel';
import {getGroupForDocument} from '@tuqqi/common'
import { SERVER_API } from '../../../utils/serverAPI.js';

const styles = theme => ({

})

export class KanbanView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }
    isFullviewOpennedRef = createRef(this.props.fullviewOpenned)

    componentDidUpdate(prevProps) {
        this.listenOpenFullview(prevProps)
    }
    listenOpenFullview = (prevProps) => {
        if(this.props.fullviewOpenned === prevProps.fullviewOpenned) return
        this.isFullviewOpennedRef.current = this.props.fullviewOpenned
    }
    
    moveList = ({ oldListIndex, newListIndex }) => {
        const { lists } = this.props
        const newLists = lists.map(list => ({ ...list }))
        const movedLane = newLists[oldListIndex]

        newLists.splice(oldListIndex, 1)

        newLists.splice(newListIndex, 0, movedLane)
        newLists.forEach((list, index) => {
            list.order = index - 1
        })
        this.props.moveList(newLists)
    }

    moveCardBetweenLanes = ({ sourceListId, destListId, oldCardIndex, newCardIndex, isError = false }) => {

        const { lists, updateDataMoveCard, dispatch, updateLists, t, mixpanelTrack, collectionUid } = this.props


        // send to mixpanel
        mixpanelTrack('Moved card from lane to lane', {});

        // let updatedLanes = lists;
        let updatedLanes = [...lists];

        const sourceListIndex = updatedLanes.findIndex(l => l.id === sourceListId)
        const sourceList = {
            ...updatedLanes[sourceListIndex],
            cards: [...updatedLanes[sourceListIndex].cards]
        }

        const destListIndex = updatedLanes.findIndex(l => l.id === destListId)

        const destList = {
            ...updatedLanes[destListIndex],
            cards: [...updatedLanes[destListIndex].cards]
        }

        const movedCard = sourceList.cards[oldCardIndex]


        // Remove the card from the source
        sourceList.cards.splice(oldCardIndex, 1)

        // Insert the card in the destination
        destList.cards.splice(newCardIndex, 0, movedCard)

        sourceList.cards = this.orderCards(sourceList.cards)
        destList.cards = this.orderCards(destList.cards)

        updatedLanes[sourceListIndex] = sourceList
        updatedLanes[destListIndex] = destList

        updateLists(updatedLanes)

        if (!isError) {
            let rules = [];

            if (destList.rules) {
                rules = rules.concat(destList.rules.filter(r => r.triggerType === 'incoming'));
            }

            if (sourceList.rules) {
                rules = rules.concat(sourceList.rules.filter(r => r.triggerType === 'outgoing'));
            }

            updateDataMoveCard({
                card: movedCard,
                sourceListId: sourceListId,
                targetLIstId: destListId,
                newPosition: newCardIndex,
                rules,
            }).then(res => {
                if(res.ok && this.isFullviewOpennedRef.current && !!collectionUid) {
                    dispatch(getGroupForDocument(SERVER_API)(collectionUid))
                }
                if (!res.ok) {
                    dispatch(showSnackbar(t('Error occured while moving', { item: movedCard.doc.title })));
                    this.moveCardBetweenLanes({
                        destListId: sourceListId,
                        sourceListId: destListId,
                        newCardIndex: oldCardIndex,
                        oldCardIndex: newCardIndex,
                        isError: true
                    })
                }
            })
        }

    }


    orderCards(cards) {
        return cards.map((card, index) => ({ ...card, order: index }))
    }

    moveCardInLane = ({ listId, oldCardIndex, newCardIndex, isError = false }) => {
        const { updateLists, updateDataMoveCard, dispatch, t, lists } = this.props
        let updatedLanes = [...lists]
        const lane = updatedLanes.find(l => l.id == listId)

        const currLaneIndex = updatedLanes.findIndex(l => l.id == listId)

        const currLane = {
            ...updatedLanes[currLaneIndex],
            cards: [...updatedLanes[currLaneIndex].cards]
        }

        const movedCard = currLane.cards[oldCardIndex]
        currLane.cards.splice(oldCardIndex, 1)

        currLane.cards.splice(newCardIndex, 0, movedCard)

        currLane.cards = this.orderCards(currLane.cards)
        updatedLanes[currLaneIndex] = currLane
        updateLists(updatedLanes)

        if (!isError) {
            updateDataMoveCard({
                card: movedCard,
                sourceListId: listId,
                targetLIstId: listId,
                newPosition: newCardIndex
            }).then(res => {
                if (!res.ok) {
                    dispatch(showSnackbar(t('Error occured while moving', { item: movedCard.doc.title })));
                    this.moveCardInLane({
                        listId,
                        newCardIndex: oldCardIndex,
                        oldCardIndex: newCardIndex,
                        isError: true
                    })
                }
            })
        }
    }




    render() {
        const { lists, editTitleList, editListColor, deleteList, addList, isLoadingMore, reloadDataWithoutLoader, className, placeholder } = this.props
        
        let numberOfCards = 0
        for (let index = 0; index < lists.length; index++) {
            const list = lists[index];
            if (list.cards) {
                numberOfCards += list.cards.length
            }
        }

        return <Fragment>
            <Board
                containerClassName={className}
                func={{
                    editTitleList,
                    editListColor,
                    deleteList,
                    isLoadingMore,
                    addList,
                    moveList: this.moveList,
                    moveCardBetweenLanes: this.moveCardBetweenLanes,
                    moveCardInLane: this.moveCardInLane,
                    reloadDataWithoutLoader: reloadDataWithoutLoader
                }}
                lists={lists}
            />
            {numberOfCards === 0 && lists.length === 1 ? placeholder : null}
        </Fragment>
    }
}

const mapStateToProps = (state) => ({
    ...state.services,
    fullviewOpenned: state.fullview.open,
    collectionUid: state?.results?.channel?.collectionUid ?? ""
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withMixpanel,
    withTranslation('kanbanTranslate'),
    withErrorCatcher("Failed Loading Kanban Board, Please Contact Tuqqi Support"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(KanbanView);