import { withStyles } from '@material-ui/core';
import "chartist/dist/chartist.css";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withMixpanel } from '../../components/Common/withMixpanel';
import { OldPagesWrap } from './newway/OldPagesWrap';

// Apply some reset
const styles = theme => ({
    content: {
        marginTop: '0px!important',
        marginRight: '32px!important',
        marginLeft: '32px!important',
    },
    container: {
        maxWidth: 'none!important'
    }
});

class Dashboard extends Component {
    componentDidMount() {
        this.props.mixpanelTrack('Go admin dashboard', {});

        this.props.adminService.getDashboardToken().then((res) => {
            window.Cumulio.addDashboard({
                loader: {
                    background: '#fff',
                },
                dashboardId: '7d943bf7-807f-4e41-ae03-70cc3357a9f2',
                container: '#my-dashboard',
                key: res.id,
                token: res.token,
                language: 'en'
            });
        })
    }
    render() {
        const { classes } = this.props;

        return <OldPagesWrap className={`admindashboardtuqqi ${classes.container}`} classNameContent={classes.content} >
            <div data-intrcm-id="dashboard_u1wxbky2" id={"my-dashboard"} />
        </OldPagesWrap>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withStyles(styles),
    withMixpanel,
    connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
