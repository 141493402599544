import { withStyles, Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import classnames from "classnames";

const styles = theme => ({
    editableTitle: {
        cursor: 'text'
    },
    uneditableTitle: {
        cursor: 'default'
    },
    container:{
        width: 'initial'
    },
    title: {
        'word-break': 'break-all',
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        "color": theme.newUI.color.commonBlack,
        "maxHeight": "47px",
        "overflow": "hidden",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": "2",
    },
    total: {
        marginLeft: 8,
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.gray,
    }
})

const ListTitle = (props) => {

    const { classes, list, t, onEdit, isChangedAble } = props;
    let total = list.id == -1 ? list.total : list.cards.length
    if(list.id == -1 && (total === undefined || isNaN(total))){
        total = list.cards.length
    }

    return (
        <Grid container wrap='nowrap' alignItems='center'
            onDoubleClick={isChangedAble && onEdit}
            className={classnames(classes.container, (isChangedAble ? classes.editableTitle : classes.uneditableTitle))}>
            <span className={classes.title}>{list.title ? list.title : t('Unassigned')}</span>
            <span className={classes.total} dir="auto">{`(${total})`}</span>
        </Grid>
    )

}





export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    withErrorCatcher())(ListTitle);