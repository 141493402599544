import React from 'react'
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Chip, Divider, Grid, withStyles } from '@material-ui/core';
import DiscoveryList from '../../views/knowledge/Library/Discovery/discoveryList';
import RelatedItemCard from './RelatedItemCard';
import MustReadItem from '../Widgets/MustReadWidget/MustReadItem';

const styles = theme => ({
    text: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
    },
    container:{
        overflow: 'hidden'
    }
})

class RelatedItemsSection extends React.Component {

    render() {

        const { title, classes, enableLinked } = this.props
        const items = this.props.items ? this.props.items : []

        return (<div data-intrcm-id="RelatedItemsSection_pwqup2l7" className={classes.container} >
            <span className={classes.text}>{title} ({items.length})</span>
            <div data-intrcm-id="RelatedItemsSection_x2wwea2h" style={{ marginTop: 16 }}>
                <DiscoveryList>
                    {items.map((x, i) => <RelatedItemCard enableLinked={enableLinked} key={x.docId} data={x} docId={x.docId} index={i} />)}
                </DiscoveryList>
            </div>
        </div>)
    }
}

export default compose(withStyles(styles))(RelatedItemsSection);