import {getSubscriptionType} from './subscription/reasonData';

let accent, primary, secondary;
const canChangeTheme = (subscriptionType) => {
  return !([0, 1].includes(subscriptionType))
}
export const themeOptions = [
  {placeholder: 'Default', option: 'default'}, 
  {placeholder: 'Theme1', option: 'theme1'}, 
  {placeholder: 'Theme2', option: 'theme2(label1)'}, 
  {placeholder: 'Theme3', option: 'theme3(label2)'}, 
  {placeholder: 'Theme4', option: 'theme4(label3)'}, 
  {placeholder: 'Theme5', option: 'theme5(label4)'}, 
  {placeholder: 'Theme6', option: 'theme6(label5)'}, 
  {placeholder: 'Theme7', option: 'theme7(label6)'}, 
  {placeholder: 'Theme8', option: 'theme8(label7)'}, 
  {placeholder: 'Theme9', option: 'theme9(label8)'}, 
  {placeholder: 'Theme10', option: 'theme10(label9)'}, 
  {placeholder: 'Theme11', option: 'theme11(label10)'}, 
  {placeholder: 'Theme12', option: 'theme12(label11)'}, 
  {placeholder: 'Theme13', option: 'theme13(label12)'}, 
  {placeholder: 'Theme14', option: 'theme14(label13)'}, 
  {placeholder: 'Theme15', option: 'theme15(label14)'},
  {placeholder: 'Theme16', option: 'theme16(label15)'}
]
export const createCustomTheme = (option, subscriptionType) => {
  if(!canChangeTheme(getSubscriptionType())) {
    option = 'default'
  }
  switch (option) {
    case "theme1":
      accent = "#795548";
      primary = {
        main: "#55B48B",
        b50: "#D4ECE2",
        b100: "#B8E0CF",
        b200: "#9CD3BB",
        b300: "#7FC7A8",
        b350: "#7FC7A9",
        b400: "#71C1DE",
        b500: "#449C76",
        b600: "#327156",
        b700: "#255540",
        b800: "#132B20",
        b900: "#060E08",
      };
      secondary = {
        main: "#CB48B7",
        b50: "#F66DFF3",
        b100: "#EDBFE6",
        b200: "#E090D4",
        b300: "#D770C7",
        b400: "#CD40B5",
        b500: "#9F2D8E",
        b600: "#7F2472",
        b700: "#5F1B55",
        b800: "#401239",
        b900: "#20091C",
      };
      break;

    case "theme2(label1)":
      accent = "#56abdc";
      primary = {
        main: "#a654c6",
        b50: "#e7d1f0",
        b100: "#dfce2b",
        b200: "#cfa3e0",
        b300: "#bf85d6",
        b350: "#b775d1",
        b400: "#af66cc",
        b500: "#953db8",
        b600: "#8938a8",
        b700: "#702e8a",
        b800: "#3e194d",
        b900: "#250f2e",
      };
      secondary = {
        main: "#e83f6f",
        b50: "#fdedf1",
        b100: "#fbdae4",
        b200: "#f6b6c8",
        b300: "#f4a4bb",
        b400: "#ee6d91",
        b500: "#e73668",
        b600: "#db1a50",
        b700: "#a4133c",
        b800: "#800f2f",
        b900: "#5b0b22",
      };
      break;

    case "theme3(label2)":
      accent = "#a654c6";
      primary = {
        main: "#ffb632",
        b50: "#fff8eb",
        b100: "#ffeac2",
        b200: "#ffdb99",
        b300: "#ffd485",
        b350: "#ffcd70",
        b400: "#ffbf47",
        b500: "#ffb01f",
        b600: "#ffa90a",
        b700: "#f59ff0",
        b800: "#cc8500",
        b900: "#a36a00",
      };
      secondary = {
        main: "#2a90cb",
        b50: "#ddeef8",
        b100: "#bbddf1",
        b200: "#99c9ea",
        b300: "#67b3e0",
        b400: "#45a3d9",
        b500: "#1f6c98",
        b600: "#1c6087",
        b700: "#185477",
        b800: "#154866",
        b900: "#0e3004",
      };
      break;

    case "theme4(label3)":
      accent = "#ff775c";
      primary = {
        main: "#f3d20b",
        b50: "#fefbec",
        b100: "#fcf4c5",
        b200: "#f7e164",
        b300: "#f9e577",
        b350: "#f8e163",
        b400: "#f5d629",
        b500: "#eac80b",
        b600: "#c3a709",
        b700: "#af9608",
        b800: "#887506",
        b900: "#756406",
      };
      secondary = {
        main: "#f0faf6",
        b50: "#d1efe4",
        b100: "#c2eadc",
        b200: "#ae40ca",
        b300: "#76d0af",
        b400: "#58c69d",
        b500: "#39a77f",
        b600: "#349873",
        b700: "#2f8968",
        b800: "#246a51",
        b900: "#1f5b45",
      };
      break;

    case "theme5(label4)":
      accent = "#ffb01f";
      primary = {
        main: "#ff5c3a",
        b50: "#ffddd6",
        b100: "#ffccc2",
        b200: "#ffaa99",
        b300: "#ff8870",
        b350: "#ff775c",
        b400: "#ff5533",
        b500: "#ff441f",
        b600: "#ff330a",
        b700: "#f52900",
        b800: "#cc2200",
        b900: "#a31b00",
      };
      secondary = {
        main: "#8f8f8f",
        b50: "#ebebeb",
        b100: "#e0e0e0",
        b200: "#d6d6d6",
        b300: "#b8b8b8",
        b400: "#999999",
        b500: "#858585",
        b600: "#7a7a7a",
        b700: "#666666",
        b800: "#525252",
        b900: "#3d3d3d",
      };
      break;

    case "theme6(label5)":
      accent = "#008000";
      primary = {
        main: "#3ece5a",
        b50: "#def7e3",
        b100: "#cef395",
        b200: "#adebba",
        b350: "#dce7ad",
        b300: "#9de7ac",
        b400: "#6cda82",
        b500: "#31c44e",
        b600: "#2db448",
        b700: "#25933b",
        b800: "#1d722e",
        b900: "#186227",
      };
      secondary = {
        main: "#f0faf6",
        b50: "#d1efe4",
        b100: "#c2eadc",
        b200: "#ae40ca",
        b300: "#76d0af",
        b400: "#58c69d",
        b500: "#39a77f",
        b600: "#349873",
        b700: "#2f8968",
        b800: "#246a51",
        b900: "#1f5b45",
      };
      break;

    case "theme7(label6)":
      accent = "#ff775c";
      primary = {
        main: "#85efff",
        b50: "#c2f7ff",
        b100: "#adf4ff",
        b200: "#85efff",
        b300: "#33e4ff",
        b350: "#1fe1ff",
        b400: "#1fe1ff",
        b500: "#00c2e0",
        b600: "#00b1cc",
        b700: "#009fb8",
        b800: "#008da3",
        b900: "#007c8f",
      };
      secondary = {
        main: "#3ece5a",
        b50: "#def7e3",
        b100: "#cef395",
        b200: "#adebba",
        b300: "#9de7ac",
        b400: "#6cda82",
        b500: "#31c44e",
        b600: "#2db448",
        b700: "#25933b",
        b800: "#1d722e",
        b900: "#186227",
      };
      break;

    case "theme8(label7)":
      accent = "#a3a300";
      primary = {
        main: "#f959da",
        b50: "#feebfa",
        b100: "#fed8f6",
        b200: "#fdc4f1",
        b300: "#fb99e8",
        b350: "#fa89e4",
        b400: "#fa75df",
        b500: "#fd4ed6",
        b600: "#f83ad2",
        b700: "#f613c9",
        b800: "#ec09bf",
        b900: "#b1068f",
      };
      secondary = {
        main: "#00caeb",
        b50: "#c2f7ff",
        b100: "#adf4ff",
        b200: "#85efff",
        b300: "#33e4ff",
        b400: "#1fe1ff",
        b500: "#00c2e0",
        b600: "#00b1cc",
        b700: "#009fb8",
        b800: "#008da3",
        b900: "#007c8f",
      };
      break;

    case "theme9(label8)":
      accent = "#00caeb";
      primary = {
        main: "#4145d3",
        b50: "#dedff7",
        b100: "#bdbfef",
        b200: "#acaeec",
        b300: "#8b8ee4",
        b350: "#7b7ee0",
        b400: "#5a5ed8",
        b500: "#393ed0",
        b600: "#2f34c6",
        b700: "#272ba5",
        b800: "#232795",
        b900: "#1f2284",
      };
      secondary = {
        main: "#f959da",
        b50: "#feebfa",
        b100: "#fed8f6",
        b200: "#fdc4f1",
        b300: "#fb99e8",
        b400: "#fa75df",
        b500: "#fd4ed6",
        b600: "#f83ad2",
        b700: "#f613c9",
        b800: "#ec09bf",
        b900: "#b1068f",
      };
      break;

    case "theme10(label9)":
      accent = "#299617";
      primary = {
        main: "#434849",
        b50: "#eaebeb",
        b100: "#cacdce",
        b200: "#b5baba",
        b300: "#959c9d",
        b350: "#8a9a93",
        b400: "#585e5f",
        b500: "#3b3f40",
        b600: "#313435",
        b700: "#272a2a",
        b800: "#1d1f20",
        b900: "#141515",
      };
      secondary = {
        main: "#4145d3",
        b50: "#dedff7",
        b100: "#bdbfef",
        b200: "#acaeec",
        b300: "#8b8ee4",
        b400: "#5a5ed8",
        b500: "#393ed0",
        b600: "#2f34c6",
        b700: "#272ba5",
        b800: "#232795",
        b900: "#1f2284",
      };
      break;

    case "theme11(label10)":
      accent = "#ff836b";
      primary = {
        main: "#955e1f",
        b50: "#f8ebdd",
        b100: "#f1d8bb",
        b200: "#eac499",
        b300: "#dc9d56",
        b350: "#d99445",
        b400: "#cb802a",
        b500: "#87551c",
        b600: "#774b18",
        b700: "#664015",
        b800: "#553511",
        b900: "#442b0e",
      };
      secondary = {
        main: "#434849",
        b50: "#eaebeb",
        b100: "#cacdce",
        b200: "#b5baba",
        b300: "#959c9d",
        b400: "#585e5f",
        b500: "#3b3f40",
        b600: "#313435",
        b700: "#272a2a",
        b800: "#1d1f20",
        b900: "#141515",
      };
      break;

    case "theme12(label11)":
      accent = "#449C76";
      primary = {
        main: "#ff0000",
        b50: "#ffd6d6",
        b100: "#ffc2c2",
        b200: "#ff8585",
        b300: "#ff5c5c",
        b350: "#ff7070",
        b400: "#ff4747",
        b500: "#f50000",
        b600: "#b80000",
        b700: "#a30000",
        b800: "#8f0000",
        b900: "#7a0000",
      };
      secondary = {
        main: "#955e1f",
        b50: "#f8ebdd",
        b100: "#f1d8bb",
        b200: "#eac499",
        b300: "#dc9d56",
        b400: "#cb802a",
        b500: "#87551c",
        b600: "#774b18",
        b700: "#664015",
        b800: "#553511",
        b900: "#442b0e",
      };
      break;

    case "theme13(label12)":
      accent = "#ff836b";
      primary = {
        main: "#008000",
        b50: "#c2ffc2",
        b100: "#70ff70",
        b200: "#00e000",
        b300: "#1fff1f",
        b350: "#0aff0a",
        b400: "#00f500",
        b500: "#00b800",
        b600: "#007a00",
        b700: "#006600",
        b800: "#005200",
        b900: "#003d00",
      };
      secondary = {
        main: "#ff0000",
        b50: "#ffd6d6",
        b100: "#ffc2c2",
        b200: "#ff8585",
        b300: "#ff5c5c",
        b400: "#ff4747",
        b500: "#f50000",
        b600: "#b80000",
        b700: "#a30000",
        b800: "#8f0000",
        b900: "#7a0000",
      };
      break;

    case "theme14(label13)":
      accent = "#585e5f";
      primary = {
        main: "#0000ff",
        b50: "#d6d6ff",
        b100: "#9999ff",
        b200: "#8585ff",
        b300: "#7070ff",
        b350: "#5c5cff",
        b400: "#5c5cff",
        b500: "#1f1fff",
        b600: "#0000f5",
        b700: "#0000e0",
        b800: "#0000b8",
        b900: "#00008f",
      };
      secondary = {
        main: "#008000",
        b50: "#c2ffc2",
        b100: "#70ff70",
        b200: "#47ff47",
        b300: "#1fff1f",
        b400: "#00f500",
        b500: "#00b800",
        b600: "#007a00",
        b700: "#006600",
        b800: "#005200",
        b900: "#003d00",
      };
      break;

    case "theme15(label14)":
      accent = "#005200";
      primary = {
        main: "#a52a2a",
        b50: "#f2cece",
        b100: "#e6dede",
        b200: "#de7d7d",
        b300: "#d55d5d",
        b350: "#d14d4d",
        b400: "#cd3c3c",
        b500: "#922626",
        b600: "#822121",
        b700: "#611919",
        b800: "#411111",
        b900: "#200808",
      };
      secondary = {
        main: "#0000ff",
        b50: "#d6d6ff",
        b100: "#9999ff",
        b200: "#8585ff",
        b300: "#7070ff",
        b400: "#5c5cff",
        b500: "#1f1fff",
        b600: "#0000f5",
        b700: "#0000e0",
        b800: "#0000b8",
        b900: "#00008f",
      };
      break;
      
      case "theme16(label15)":
        accent = "#DC143C";
        primary = {
          main: "#434849",
          b50: "#eaebeb",
          b100: "#cacdce",
          b200: "#494F55",
          b300: "#959c9d",
          b350: "#8a9a93",
          b400: "#585e5f",
          b500: "#000000",
          b600: "#313435",
          b700: "#272a2a",
          b800: "#1d1f20",
          b900: "#141515",
        };
        secondary = {
          main: "#FFB6C1",
          b50:  "#FFC0CB",
          b100: "#FFC0CB",
          b200: "#FFC0CB",
          b300: "#FFB6C1",
          b350: "#DB7093",
          b400: "#DB7093",
          b500: "#DB7093",
          b600: "#FF69B4",
          b700: "#FF1493",
          b800: "#DB7093",
          b900: "#C71585",
        };
        break;

    default:
      accent = "#ff5c3a";
      primary = {
        main: "#6b13f6",
        b50: "#D4DCFD",
        b100: "#CAABFC",
        b200: "#9f6bfa",
        b300: "#9556F9",
        b350: "#8d50fa",
        b400: "#7524F7",
        b500: "#8645f9",
        b600: "#4A07B7",
        b700: "#3B0592",
        b800: "#2C046E",
        b900: "#160237",
      };
      secondary = {
        main: "#ffbf4a",
        b50: "#FFE4B3",
        b100: "#FFDB99",
        b200: "#FFD280",
        b300: "#FFC966",
        b400: "#FFC599",
        b500: "#FFB835",
        b600: "#FFA805",
        b700: "#D58BOO",
        b800: "#8E5COO",
        b900: "#5F3E00",
      };
  }

  return {
    accent,
    primary,
    secondary,
  };
};
