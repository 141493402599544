import { NewUiWrapper, Library } from '@tuqqi/web';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { initSubscriptionModal, openSubscriptionModal } from '../../../actions/upgradeSubscriptionActions.js';
import { setLastCreatingGroup } from '@tuqqi/web';
import parseImagUrl from "../../components/Common/parseImagUrl";
import { mapCollectionToGroupNavigation } from '../../../utils/groupsNavigation/collectionToGroupNavigation';
import { loadUserData } from '@tuqqi/common';
import { userDataLoad } from '../../../actions/globalDataAction';
import groupPlaceholder from "../../../assets/img/group_placeholder.png"

import { store } from '../../../index.js';
import { showSnackbar } from '../../../actions/appActions';
import { compose } from 'recompose';
import { withMixpanel } from '../../components/Common/withMixpanel';
import { withTranslation } from 'react-i18next';
import { SERVER_API } from '../../../utils/serverAPI.js';
import { updateOrganizationData } from '../../../utils/loadOrganizationData.js';
import { push } from 'react-router-redux';
import goTo from '../../actions/goTo.js';
import { closeFullview } from '@tuqqi/web/dist/actions/fullview-actions/fullviewActions.js';
import { getAllCollection } from '../groups/groupUtils.js';
import goToCollection from '../../actions/goToCollection.js';

class NewLibrary extends Component {
    openSubscriptionDialog = () => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({reason: 'library', mode: 'existed', allowClose: false}))
        dispatch(openSubscriptionModal())
    }

    canUseLibrary = () => {
        const { subscription } = this.props;
        if([0, 1].includes(subscription.subscriptionType)) return false
        return true
    }

    componentDidMount() {
        if(!this.canUseLibrary()) {
            this.openSubscriptionDialog()
        }
    }


    onCreateCategory = (newCollection, cb) => {
        const { userSettingsService, groupService, dispatch, mixpanelTrack, t } = this.props;
        dispatch(showSnackbar(t('Creating')));

        mixpanelTrack('Create group', {})

        groupService.createCollection(newCollection).then((updatedCollection) => {
            setLastCreatingGroup(updatedCollection?.collectionGroup?.groupUid, updatedCollection?.collectionUid ?? '')
            const backgroundUrl = parseImagUrl(this.props.cloudfront, updatedCollection.backgroundUrl, 100, groupPlaceholder);
            dispatch({
                type: 'ADD_SEARCH_MANAGED_GROUP',
                group: mapCollectionToGroupNavigation({ ...updatedCollection, backgroundUrl })
            })

            dispatch(showSnackbar(t('Created!')));
            cb?.(updatedCollection?.collectionGroup?.groupUid ?? '')
            userSettingsService.getUserData().then((userData) => {
                dispatch(loadUserData(SERVER_API))
                dispatch(userDataLoad(userData))
                dispatch({ type: 'RESULT_FILTERS_UPDATE', results: { dataTypes: [],  relatedDocs: [], groups: [], experts: [] } });
            })
        })    
    }

    onDeleteGroup = (colUid) => {
        const { groupService, dispatch, services, globalData, userData } = this.props;
        if(!colUid) return

        groupService.deleteCollection(colUid).then(() => {
            updateOrganizationData(null, dispatch, services, globalData, userData).then(() => {
                persistor.persist()
            }).catch(err => {

            })
            dispatch(push('/feed'));

        });
    }

    _goToCollection = (groupUid) => {
        const { collections, openCollections, dispatch, fullview } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid === groupUid)
        if (!collection) {
            return
        }

        goToCollection('/' + collection.title, dispatch, collection.collectionUid, groupUid, collection)();

        fullview.open && dispatch(closeFullview())
    }

    goToUser = (userUid) => {
        const { users, dispatch, fullview } = this.props

        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }

        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
        fullview.open && dispatch(closeFullview())
    }


    render() {
        const callbacks = {
            onDeleteGroup: this.onDeleteGroup,
            onUserClick: this.goToUser,
            onGroupClick: this._goToCollection,
            onCreateCategory: this.onCreateCategory
        }

        return <NewUiWrapper store={store} callbacks={callbacks}>
            <Library />    
        </NewUiWrapper>
    }
}

const mapStateToProps = (state) => ({
    globalData: state.globalData,
    users: state.globalData.users,
    userData: state.userData,
    services: state.services,
    groupService: state.services.groupService,
    fullview: state.fullview,
    openCollections: state.globalData.openCollections,
    collections: state.userData.collections,
    userSettingsService: state.services.userSettingsService,
    subscription: state.globalData.currentSubscription,
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(
    withMixpanel,
    withTranslation('newGroupDialog'),
    connect(mapStateToProps, mapDispatchToProps),
)(NewLibrary);
