import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neo.css';
import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import './CodeMirrorCssOverrides.css';
import './i18n';
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/neo.css'
import { setApiUrl, setRefreshFeed, setItemUpdate, setDomainUrl, setExternalUrl } from '@tuqqi/web'
import 'froala-editor/css/froala_editor.min.css'
// import 'froala-editor/css/froala_style.css'
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/plugins/video.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css'
import getInitialStore from './initialStore';
import configureStore from './store/configureStore';
import getTheme from './theme';
import TuqqiRouting from './TuqqiRouting';
import { Auth0Provider } from '@auth0/auth0-react';

// import AuthService from './utils/auth/AuthService';
import { DATA_UPDATE } from './actionTypes/actionTypes';
import { updateSpecificItem } from './actions/dataActions';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { PersistGate } from 'redux-persist/integration/react';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';

setApiUrl(process.env.API_SERVER)
window.Alvis("boot")
const muiTheme = createMuiTheme(
    getTheme()
);

// const auth = new AuthService('GvlPc9GZiU3g4DeZsmVXaNDAf2CkqLC5', 'tuqqi.auth0.com');

const { store, persistor } = configureStore(browserHistory, getInitialStore());
export { store, persistor }

persistor.persist()

setDomainUrl("https://app.tuqqi.com")
setExternalUrl(process.env.TUQQI_FORM)
setRefreshFeed(() => store.dispatch({ type: DATA_UPDATE }))
setItemUpdate((docId) => store.dispatch(updateSpecificItem(docId)))

const generateClassName = createGenerateClassName({
    disableGlobal: true,
    productionPrefix: 'c',
    seed: 'app1'
});

Sentry.init({
    dsn: 'https://76c59171062148d6a2762ed252d57fd8@o193292.ingest.sentry.io/1296107',
    release: "tuqqi_web@6",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    // integrations: [new MyAwesomeIntegration()],
})

ReactDOM.render(
    <Auth0Provider
        domain="auth.tuqqi.com"
        clientId="GvlPc9GZiU3g4DeZsmVXaNDAf2CkqLC5"
        redirectUri={window.location.origin}
        useRefreshTokens={false}        
    >
        <StylesProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={muiTheme} >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <TuqqiRouting store={store} />
                    </PersistGate>
                </Provider>
            </MuiThemeProvider>
        </StylesProvider>
    </Auth0Provider>
    , document.getElementById('root'))