import { Avatar, Chip, Icon, MenuItem, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withErrorCatcher from '../../../../components/Common/withErrorCatcher'
import AutosuggestChip from '../../../../components/Header/SubheaderToolbar/Filters/AutosuggestChip'
import { getAllCollection } from '../../../groups/groupUtils'
import CancelIcon from '@material-ui/icons/Cancel';
import {isExistsTuqqiIcon, getDefaultTuqqiIconName} from '@tuqqi/common'
import {allFluencyIconsNames, allFluencyIconsData, getDefaultTuqqiIcon} from '@tuqqi/common'
import SVG from 'react-inlinesvg';

const styles = (theme) => ({
    title: {
        marginBottom: 8,
        marginTop: 24,
        fontFamily: "Rubik",
        fontSize: 18,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.25px"
    },
    suggestField: {
        marginRight: -28,
        marginLeft: -28,
    },
})

const OptionItem = (props) => {
    const { label, icon } = props.data
  
    return (
      <Fragment>
        <Avatar style={{width: 32, height: 32, marginRight: 16}}>
            <Icon>{icon}</Icon>
        </Avatar>
        <Typography>
          {label}
        </Typography>
      </Fragment>
    )
}
function Option(props) {
    const { data } = props
    return (
      <MenuItem
        key={data && data.value}
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {
          data ? <OptionItem data={data} /> : props.children
        }
      </MenuItem>
    );
}

function MultiValue(props) {
    const { data } = props
  
    const style = { backgroundColor: data.backgroundColor ? data.backgroundColor : '' }
    const labelStyle = { color: '#ffffff', fontWeight: 500 }
    const rightIcon = useMemo(() => isExistsTuqqiIcon(data.icon) ? data.icon : getDefaultTuqqiIconName(), [data.icon])
    const pathIcon = useMemo(() => {
      const ind = allFluencyIconsNames.findIndex(x => x === rightIcon)
      if(ind === -1) return getDefaultTuqqiIcon()
      return allFluencyIconsData[ind]
    }, [rightIcon])
  
    return (
      <Chip
        avatar={<SVG style={{alignSelf: 'center', width: 18, height: 18, marginLeft: 8, marginRight: -8, marginTop: -2}} src={pathIcon}/>}
        label={<Typography style={data.backgroundColor ? labelStyle : null}>{props.children}</Typography >}
  
        style={data.backgroundColor ? style : null}
        tabIndex={-1}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }

const __ItemGroupType = (props) => {
    const { classes, referenceUid, onChangeReferenceUid, openCollections, collections, onChangeIcon, onChangeTitle } = props
    
    const allGroups = useMemo(() => getAllCollection(openCollections, collections), [openCollections, collections])

    const suggestions = useMemo(() => {
        return allGroups.map(collection => {
            return {
                label: collection.title,
                uid: collection.collectionGroup.groupUid,
                value: collection.collectionGroup.groupUid,
                icon: collection.icon ?? 'public'
            }
        })
    }, [allGroups])

    const onChangeGroup = (newData) => {
        if(!newData?.length) {
            onChangeReferenceUid('')    
            return
        }
        const item = newData.slice(-1)[0]
        onChangeReferenceUid(item.uid)
        onChangeIcon(isExistsTuqqiIcon(item.icon) ? item.icon : getDefaultTuqqiIconName())
        onChangeTitle(item.label)
    }

    return <>
        <Typography variant="title" className={classes.title}>
            Choose group
        </Typography>
        <div data-intrcm-id="ItemGroupType_6ed2q6qk" className={classes.suggestField}>
            <AutosuggestChip CustomMultiValue={MultiValue} CustomOptionItem={Option} placeholder={''} handleChange={onChangeGroup} suggestions={suggestions} label={'Group'} selected={[referenceUid || '-1']} />
        </div>
    </>
}

const mapStateToProps = (state) => ({
    orgMenuItems: state.feedNavigation.orgMenuItems,
    openCollections: state.data.globalData.openCollections.data,
    collections: state.data.userData.userCollections.data,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const ItemGroupType = compose(
    withStyles(styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(__ItemGroupType)