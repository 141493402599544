import { Dialog, DialogContent, Button, DialogTitle, Grid, withStyles, ListItem, List, DialogActions, ListItemText, IconButton } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import OnErrorDialog from '../../../../components/Common/OnErrorDialog';
import TopicExpert from '../../../knowledge/Library/TopicView/topicExpert';

import { ChatBubble } from '@material-ui/icons';
import UserAvatar from '../../../../components/Common/UserAvatar';

const styles = theme => ({
    userItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    root: {
        minWidth: 475
    }
})

class MemebersDialog extends React.Component {
    render() {
        const { open, onClose, classes, usersIds, title,handleMemberSelect,ganttTranslation } = this.props
        const fullname = x => x.firstname + ' ' + x.lastName
        
        const users = usersIds.map(id => this.props.users.find(x => x.id === id)).filter(x => !!x).sort((a, b) => fullname(a) > fullname(b) ? 1 : -1)

        return <Dialog
            open={open}
            onClose={onClose}>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>{title}</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <List>
                    {users.map(user => {
                         const { firstname, lastname, auth0_id} = user
                        return <ListItem className={classes.userItem}>
                             <ListItem
                                button
                                key={user.auth0_id}
                                onClick={() => handleMemberSelect(auth0_id)}
                                className={classes.expertListItem}>
                                <UserAvatar userUUID={auth0_id} />
                                <ListItemText
                                    className={classes.expertText}
                                    classes={{ primary: classes.expertFont, secondary: classes.exportTitle }}
                                    primary={`${firstname} ${lastname}`}/>
                            </ListItem>
                        </ListItem>
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{ganttTranslation('Close')}</Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
})

export default compose(
    withStyles(styles), 
    withErrorCatcher(OnErrorDialog),
    connect(mapStateToProps))(MemebersDialog)