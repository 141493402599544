import { MenuItem, TextField, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import React from 'react';
import { withTranslation } from 'react-i18next';
import FormControlLabelSwitch from "./FormControlLabelSwitch";
import { compose } from 'recompose';

const styles = theme => ({
    switchWithSelect: {
        margin: '16px 0px'
    },
})

const disabledWhenWorkflow = ['isEnableTags', 'isEnableLinks',
    'enablePublishingToPublicFeed', 'isEnableContact', 'isEnableCompany', 'isEnableLocation']

class CreateCustomInputEnableSettings extends React.Component {

    enableLabels = () => {
        const { t } = this.props;
        return {
        isEnableFiles: t('Enable Files'),
        isEnableTags: t('Enable Tags'),
        isEnableLinks: t('Enable Links'),
        enablePublishingToPublicFeed: t('Enable Publishing To Public Feed'),
        isEveryoneCanEdit: t('Everyone Can Edit'),
        isEnableLocation: t('Enable Location'),
        isEnableContact: t('Enable Contact (As Public)'),
        isEnableCompany: t('Enable Company (As Public)'),
        isEnableDueDate: t('Enable Due Date'),
        isDueDateMandatory: t('Mandatory Due Date'),
        isAutomaticTitle: t('Automatically build title'),
        isWithoutSeparateTitleByCommas: t('Separate title by commas'),
        isRtl: t('Is RTL'),
        isEnableRating: t('Enable Rating'),
        isBusinessIdEnabled: t('Enable business Id (data type has unique buisness Id)')
        }}

    isDisabled = (name) => {
        const { isEnableWorkflow, isAboveWorkflow } = this.props.enableSettings;

        return disabledWhenWorkflow.includes(name) && (isEnableWorkflow || isAboveWorkflow)
    }

    getEnableSwitch(name, isDisabled = false) {
        const { changeEnable, enableSettings } = this.props;
        const disabled = this.isDisabled(name) || isDisabled;

        return <FormControlLabelSwitch
            checked={enableSettings[name]}
            onChange={changeEnable(name)}
            label={this.enableLabels()[name]}
            disabled={disabled} />
    }

    
    getEnableTitleSeparateSwitch() {
        const { changeEnable, enableSettings } = this.props;
        const name = 'isWithoutSeparateTitleByCommas'
        const disabled = this.isDisabled('isWithoutSeparateTitleByCommas');

        if(!enableSettings.isAutomaticTitle) return null
        return <FormControlLabelSwitch
            checked={!enableSettings[name]}
            onChange={changeEnable(name)}
            label={this.enableLabels()[name]}
            disabled={disabled} />
    }
    getBusinessIdEnableSwitch() {
        const { changeEnable, enableSettings, isBusinessIdFieldEnabled } = this.props;
        const disabled = this.isDisabled('isBusinessIdEnabled') || isBusinessIdFieldEnabled;
        const checked = enableSettings['isBusinessIdEnabled'] || isBusinessIdFieldEnabled
        return <FormControlLabelSwitch
            checked={checked}
            onChange={disabled ? () => {} : changeEnable('isBusinessIdEnabled')}
            label={this.enableLabels()['isBusinessIdEnabled']}
            disabled={disabled} />
    }

    getSwitchWithSelect = (name) => {
        return <Grid container className={this.props.classes.switchWithSelect} >
            {this.getEnableSwitch(name)}

            <TextField select disabled value='public' label={this.props.t('Save as')} >
                <MenuItem value='public' >{this.props.t('Public')}</MenuItem>
            </TextField>
        </Grid>
    }


    render() {
        return <div data-intrcm-id="CreateCustomInputEnableSettings_wraxje7v">
            {this.getEnableSwitch('isAutomaticTitle')}
            {this.getEnableTitleSeparateSwitch()}
            {this.getEnableSwitch('isEnableFiles')}
            {this.getEnableSwitch('isEnableTags')}
            {this.getEnableSwitch('isEnableLinks')}
            {this.getEnableSwitch('enablePublishingToPublicFeed')}
            {this.getEnableSwitch('isEveryoneCanEdit')}
            {this.getEnableSwitch('isEnableLocation')}
            {this.getEnableSwitch('isEnableDueDate')}
            {this.getEnableSwitch('isDueDateMandatory',this.props.enableSettings.isEnableDueDate ? false :true)}
            {this.getEnableSwitch('isRtl')}
            {this.getEnableSwitch('isEnableRating')}
            {this.getBusinessIdEnableSwitch()}
            {this.getSwitchWithSelect('isEnableContact')}
            {this.getSwitchWithSelect('isEnableCompany')}
        </div>
    }
}

export default compose(withStyles(styles),withTranslation('admin'))(CreateCustomInputEnableSettings);