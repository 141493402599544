import { usePrevious, managerRemovedItemsLocal } from '@tuqqi/web'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { date2359Time, dateZeroTime, getDateCorrect, infiniteEndRange, infiniteStartRange } from '../../utils/actionsCenter/buildAgenda'
import { addDays, endOfMonth, endOfYear, isWithinInterval, nextSaturday, nextSunday, startOfMonth, startOfYear } from 'date-fns'

const initialSearchQuery = {
    page: 0,
    size: 100,
    query: '',
    AggType: 'FLAT'
}

const AllowEmpty = (groupBy) => {
    if(groupBy === undefined) return false
    return [1, 5, 6].includes(groupBy)
}

const processRespFetchActionCenter = (x) => {
    if(AllowEmpty(x.groupBy)) {
        x.sections = x.sections
            .sort((a, b) => b.count - a.count)
            .map(x => {
                // if(x.count > AC_LIMIT_TABLE) return {...x, items: []}
                return x
            })
        return x
    }
    x.sections = x.sections
        .sort((a, b) => b.count - a.count)
        .filter(x => !!x.count)
        .map(x => {
            // if(x.count > AC_LIMIT_TABLE) return {...x, items: []}
            return x
        })
    return x
}

export const getRangeForDueDateUid = (uid) => {
    switch (uid) {
        case 'Past Due':
            return [dateZeroTime(new Date(infiniteStartRange)), date2359Time(addDays(new Date(), -1))]
        case 'Today':
            return [dateZeroTime(new Date()), date2359Time(new Date())]
        case 'Tomorrow':
            return [dateZeroTime(addDays(new Date(), 1)), date2359Time(addDays(new Date(), 1))]
        case 'This week': {
            const d = dateZeroTime(addDays(new Date(), 2))
            return [d, date2359Time(nextSaturday(d))]
        }
        case 'This month': {
            const d = dateZeroTime(nextSunday(new Date()))
            return [d, date2359Time(endOfMonth(new Date()))]
        }
        case 'This year': {
            const temp = new Date()
            temp.setMonth(temp.getMonth() + 1)

            return [dateZeroTime(startOfMonth(temp)), date2359Time(endOfYear(new Date()))]
        }
        case 'Future due dates': {
            const temp = new Date()
            temp.setFullYear(temp.getFullYear() + 1)
            return [dateZeroTime(startOfYear(temp)), date2359Time(new Date(infiniteEndRange))]
        }
        case 'Not Set':
            return []
    
        default:
            return []
    }
}


const updateLocalForDueDate = (sections, item) => {
    const newVal = getDateCorrect(item?.dueDateUTC ?? '')
    return sections.map(sec => {
        if(!newVal && sec.uid === 'Not Set') {
            return {...sec, items: [...sec.items.filter(x => x.docId !== item.docId), item]}
        }
        const range = getRangeForDueDateUid(sec.uid)
        if(!range.length) return sec
        try {
        if(new Date(range[1]).getTime() <= new Date(range[0]).getTime()) return sec
        const inInterval = isWithinInterval(new Date(newVal), {start: range[0], end: range[1]})
        if(!!inInterval) return {...sec, items: [...sec.items.filter(x => x.docId !== item.docId), item]}
        return {...sec, items: [...sec.items.filter(x => x.docId !== item.docId)]}
        } catch (error) {
            return sec                 
        }
    })

}

const updateLocal = (groupBy, sections, item) => {
    if(groupBy !== GroupByEnum.DueDate) return sections

    switch (groupBy) {
        case GroupByEnum.DueDate:
            return updateLocalForDueDate(sections, item)
    
        default:
            return sections
    }
}


export const useSearchACQueryLocal = (ctx) => {
    const {returnFullData, clearBeforeLoad, filterResponse, queryService, queryFiltersUpdateType, queryFiltersItemId} = ctx

    const abortControllerRef = useRef(new AbortController())
    const searchQueryRef = useRef(initialSearchQuery)
    const [itemsSections, setItemsSections] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const cancelRequest = useCallback(() => {
        if(!abortControllerRef.current) return
        abortControllerRef.current.abort()
    }, [])

    useEffect(() => {
        return () => {
            cancelRequest()
        }
    }, [])

    const prevQueryFiltersUpdateType = usePrevious(queryFiltersUpdateType)
    
    useEffect(() => {
        if(queryFiltersUpdateType === 'delete' && prevQueryFiltersUpdateType !== 'delete' && queryFiltersItemId) {
            setItemsSections(prev => prev.map(sec => ({...sec, items: sec.items.filter(x => !managerRemovedItemsLocal.getItems().includes(x.docId))})))
        }
    }, [queryFiltersItemId, queryFiltersUpdateType])

    const resolveArg = useCallback((arg) => {
        switch (arg) {
            case "isLoading":
                setIsLoading(false)
                return
        }
    }, [])

    const resolveFetch = useCallback(async (searchQuery, arg) => {
        const newQuery = {...searchQuery}

        queryService.fetchACInit(newQuery, null, returnFullData).then((result) => {
            if(!result) {
                setItemsSections([])
                resolveArg(arg)
                return
            }
            const proccesed = processRespFetchActionCenter(result)

            setItemsSections(proccesed?.sections ?? [])
            resolveArg(arg)
        })
    }, [abortControllerRef.current, returnFullData, resolveArg, filterResponse])

    const loadData = useCallback((_searchQuery) => {
        abortControllerRef.current = new AbortController()
        searchQueryRef.current = _searchQuery
        setIsLoading(true)
        !!clearBeforeLoad && setItemsSections([])
        
        resolveFetch(_searchQuery, 'isLoading')
    }, [abortControllerRef.current, resolveFetch, clearBeforeLoad])

    return {
        isLoading, 
        itemsSections, 
        loadData, 
        abortData: cancelRequest,
    }
}
