import { setToken } from '@tuqqi/common';
import React from 'react';
import { connect } from 'react-redux';
import { persistor, store } from '../..';
import UserSettingsService from '../../services/userSettingsService';
import { fullResetStore } from '@tuqqi/web'
import { withAuth0 } from '@auth0/auth0-react';
import { compose } from 'recompose';
import { initServices } from '../loadOrganizationData';

export function requireAuthentication(Component, paramProps) {

    class Callback extends React.Component {
        constructor(props, context) {
            super(props, context);
            this.userSettings = new UserSettingsService(this.props.authService)

        }
        componentWillMount() {
            this.checkAuth();
        }

        componentWillReceiveProps(nextProps) {
            this.checkAuth();
        }
        sameTuqqiVersion = () => {
            if (!('tuqqiVersion' in store.getState().globalData)) return false;
            return process.env.CURRENT_VERSION === store.getState().globalData.tuqqiVersion
        }

        async checkAuth() {
            const { isAuthenticated, loginWithRedirect } = this.props.auth0;

            if (isAuthenticated) {
                const { getIdTokenClaims } = this.props.auth0;


                // if (!claims) {
                //     loginWithRedirect()
                // }


                // init services
                // const services = initServices(id_token)
                // this.props.dispatch({ type: 'SERVICES_INIT', services });
                // this.props.dispatch(setToken(id_token))
                const claims = await getIdTokenClaims();
                if (claims && !!claims?.__raw) {
                    const id_token = claims.__raw;
                    if (this.props.services && this.props.services.userSettingsService) {
                        if (this.props.services.userSettingsService.getUserToken() !== id_token) {
                            const services = initServices(id_token)
                            this.props.dispatch({ type: 'SERVICES_INIT', services });
                            this.props.dispatch(setToken(id_token))

                        }
                    }

                }
            }

            // const {  isAuthenticated, idToken } = this.props.userData;
            // if(!this.props.globalData.rehydrated) return

            // if (authService.isAuthenticated() && this.sameTuqqiVersion()) {
            //     if (!isAuthenticated) {
            //         // this.props.dispatch({ type: 'USER' })
            //         this.props.dispatch({ type: 'USER_LOGIN', authResult: authService.getSession() });
            //     }
            //     const token = authService.getToken()
            //     this.props.dispatch(setToken(token))
            // } else {
            //     const s = encodeURIComponent(window.location.search)
            //     store.dispatch(fullResetStore())
            //     persistor.purge().then(_=> {
            //         window.location = 'https://tuqqi.auth0.com/authorize?response_type=token&scope=openid%20app_metadata&client_id=GvlPc9GZiU3g4DeZsmVXaNDAf2CkqLC5&redirect_uri=' + process.env.AUTH0_REDIRECT +  s
            //     })
            // }
        }

        render() {

            const { isAuthenticated } = this.props.auth0;

            return (
                <div>
                    {isAuthenticated && this.props.userData.isAuthenticated === true
                        ? <Component {...this.props} {...paramProps} />
                        : null
                    }
                </div>
            )
        }
    }

    const mapStateToProps = (state) => ({
        authService: state.authModule.authService,
        userData: state.userData,
        router: state.router,
        globalData: state.globalData
    })

    return compose(
        withAuth0,
        connect(
            mapStateToProps
        ))(Callback);
    // return connect(mapStateToProps)(Callback);
}