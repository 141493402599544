import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import withErrorCatcher from "../Common/withErrorCatcher.js";
import ItemPreviewCardHeaderMenu from "../FeedViewComponents/ItemPreviewComponents/itemPreviewCardHeaderMenu";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

export const KNOWLEDGE_CARD_MENU_HEIGHT = 28;

const styles = (theme) => ({
  container: {
    height: KNOWLEDGE_CARD_MENU_HEIGHT,
  },
  menuIcon: {
    margin: "-8px -8px 0px 0px",
    padding: 6,
  },
});

class KnowledgeCardMenu extends PureComponent {
  render() {
    const { classes, card } = this.props;
    const { doc, id } = card;

    return (
      <div data-intrcm-id="cardMenu_yu02t561" className={classes.container}>
        <ItemPreviewCardHeaderMenu
          buttonClass={classes.menuIcon}
          docId={id}
          result={doc}
          relatedDocuments={doc.relatedDocuments}
          icon={<MoreHorizIcon fill="#979AAA" />}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withErrorCatcher("Failed Loading Item, Please Contact Tuqqi support")
)(KnowledgeCardMenu);
