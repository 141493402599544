import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import Map from "../../../components/Common/Map/Map";
import MapPin from '../../../components/Common/Map/MapPin.js';
import { withStyles } from '@material-ui/core';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import { filterData, loadData } from '../../search/searchUtils';
import Spinner from '../../../components/Common/spinner';
import { DATA_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../actionTypes/actionTypes.js';


const styles = theme => ({
    container: {
        height: 'auto',
        width: 'auto',
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        left: 328
    },
});

class SearchPageMap extends Component {
    constructor(props) {
        super(props)

        this.state = {
            center: undefined,
            results: [],
            myResults: [],
            dataReloadInidicator: 0,
            isLoading: true,
            open: false
        }
        this.loadData = loadData.bind(this);
        this.filterData = filterData.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }
    }

    componentDidMount() {
        const {dispatch} = this.props
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { dataTypes: [], hasLocation: true, page: 0} })
        dispatch({ type: DATA_UPDATE });
        dispatch({ type: DATA_FILTER });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps);
        }
    }
    
    getCenter = () => {
        if (!(window.google && window.google.maps && window.google.maps.LatLngBounds)) {
            return undefined
        }

        this._bounds = new window.google.maps.LatLngBounds();
        this.state.results.forEach((item, index) => {
            const { geoLocation } = item.Value.Source
            if (geoLocation) {
                const position = new window.google.maps.LatLng(geoLocation.lat, geoLocation.lon);
                this._bounds.extend(position);
            }
        });

        const center = this.extractCenter(this._bounds)
        return center
    }

    extractCenter(_bounds) {
        const center = this._bounds.getCenter();
        const res = { lat: center.lat(), lng: center.lng() }
        if (!isNaN(res.lat) && !isNaN(res.lng)) {
            return res
        }
        return undefined
    }


    handleMapMounted = ({ map }) => {
        const center = this.getCenter()
        this.setState({ center })

        map && map.fitBounds && (map.fitBounds(this._bounds));
    }


    openItem = (itemId, item) => () => {
        const { collaborationService, openFullview } = this.props;

        collaborationService.logClick(itemId);
        openFullview(item, itemId, true);
    }

    render() {
        const { classes } = this.props
        const {isLoading, results, center} = this.state
        if (isLoading) {
            return <Spinner  />
        }
        return (
            <Map center={center} zoom={6} classes={{ container: classes.container }} onMapMounted={this.handleMapMounted} >
                {
                    results.map((item, idx) => {
                        const { title, postText } = item.Value.Source
                        const geoLocation = item.Value.Source.geoLocation
                        const textToShow = [title, `${postText.substring(0, 25)}${postText.length > 25 ? '...' : ''}`].filter(text => !!text).join(', ')
                        return  <MapPin
                                    key={idx}
                                    lat={geoLocation.lat}
                                    lng={geoLocation.lon}
                                    text={textToShow}
                                    onClick={this.openItem(item.Key, item.Value.Source)} />
                    })
                }
            </Map>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.services,
    oldUiData: state.oldUiData,
    profileData: state.userData.profileData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document
})

const mapDispatchToProps = dispatch => ({
        dispatch: dispatch
})

export default compose(withStyles(styles),
    withOpenFullview,
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(SearchPageMap);