import React, { PureComponent } from 'react';

export default class ClosedGroupIcon extends PureComponent {

    render() {
        const { className } = this.props
        return (
            <svg className={className} width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>7E9C33C0-2E6F-48FB-AF1A-0E6982409FE1</title>
                <g id="Feed" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="New-Groups---All-Badges" transform="translate(-310.000000, -376.000000)">
                        <g id="Group-15" transform="translate(310.000000, 376.000000)">
                            <g id="badge---close">
                                <rect id="Rectangle" fill="#FFB532" x="0" y="0" width="40" height="40" rx="20"></rect>
                                <g id="lock-(2)" transform="translate(12.000000, 10.000000)" fill="#FFFFFF" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="0.4">
                                    <path d="M13.5893833,6.73393911 L13.5893833,5.59562842 C13.5893832,2.50524821 11.0841351,8.33813603e-08 7.99375488,8.33813603e-08 C4.90337467,8.33813603e-08 2.39812651,2.50524821 2.39812646,5.59562842 L2.39812646,6.73393911 C0.943304622,7.36887378 0.00208817232,8.80454261 0,10.3918813 L0,15.1881343 C0.00264282836,17.3944531 1.79055861,19.1823689 3.99687744,19.1850117 L11.9906323,19.1850117 C14.1969512,19.1823689 15.9848669,17.3944531 15.9875098,15.1881343 L15.9875098,10.3918813 C15.9854216,8.80454261 15.0442051,7.36887378 13.5893833,6.73393911 Z M3.99687744,5.59562842 C3.99687744,3.38821396 5.78634042,1.59875098 7.99375488,1.59875098 C10.2011693,1.59875098 11.9906323,3.38821396 11.9906323,5.59562842 L11.9906323,6.3950039 L3.99687744,6.3950039 L3.99687744,5.59562842 Z M14.3887588,15.1881343 C14.3887588,16.5125829 13.315081,17.5862607 11.9906323,17.5862607 L3.99687744,17.5862607 C2.67242877,17.5862607 1.59875098,16.5125829 1.59875098,15.1881343 L1.59875098,10.3918813 C1.59875098,9.06743267 2.67242877,7.99375488 3.99687744,7.99375488 L11.9906323,7.99375488 C13.315081,7.99375488 14.3887588,9.06743267 14.3887588,10.3918813 L14.3887588,15.1881343 Z" id="Shape"></path>
                                    <path d="M7.99375488,11.1912568 C7.55227199,11.1912568 7.19437939,11.5491494 7.19437939,11.9906323 L7.19437939,13.5893833 C7.19437939,14.0308662 7.55227199,14.3887588 7.99375488,14.3887588 C8.43523777,14.3887588 8.79313037,14.0308662 8.79313037,13.5893833 L8.79313037,11.9906323 C8.79313037,11.5491494 8.43523777,11.1912568 7.99375488,11.1912568 Z" id="Path"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}