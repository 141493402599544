import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Typography } from '@material-ui/core';
import React from 'react';

export const statusColors = [
    // {
    //     id: 0,
    //     label: 'No color',
    //     color: 'grey',

    // },
    // {
    //     id: 10,
    //     label: 'Yellow',
    //     color: '#f6df51'
    // },
    {
        id: 20,
        label: 'Green',
        color: '#23c244'
    },
    {
        id: 30,
        label: 'Red',
        color: '#ff4848'
    },
    {
        id: 40,
        label: 'Orange',
        color: '#ffbf4a'
    },
    // {
    //     id: 50,
    //     label: 'Blue',
    //     color: '#1a8bee'
    // },
    // {
    //     id: 60,
    //     label: 'Turquoise',
    //     color: '#13d5f6'
    // },
    // {
    //     id: 70,
    //     label: 'Pink',
    //     color: '#ff64df'
    // },
    // {
    //     id: 80,
    //     label: 'Purple',
    //     color: '#6b13f6'
    // },
    // {
    //     id: 90,
    //     label: 'Purple 2',
    //     color: '#bc98fb'
    // },
    // {
    //     id: 100,
    //     label: 'Orange 2',
    //     color: '#ff785c'
    // },
]

const ColorComponent = (props) => {

    const { color, choose, isCurrent } = props

    return <Grid container wrap='nowrap' style={{ marginBottom: 8 }} alignContent='center' alignItems='center' justify='space-between' wrap='nowrap'>
        <Grid container alignContent='center' alignItems='center' justify='left' wrap='nowrap' item onClick={choose}
            style={{
                backgroundColor: color.color,
                width: 260,
                minHeight: 32,
                borderRadius: 2,
                position: 'relative', /* boxShadow: (key % 2 == 0 ? `-8px 0 ${label.color}` : '')*/
            }}>
            {isCurrent ? <Icon style={{
                color: '#ffffff',
                position: 'absolute',
                right: 4
            }} >check</Icon> : null}
        </Grid>
    </Grid>
}

export const getColorByStatusId = (statusId) => {
    switch (statusId) {
        case 20:
            return '#23c244'
        case 30:
            return '#ff4848'
        case 40:
            return '#ffbf4a'
        default:
            return '#46A9F0'
    }
}

export default function ListColorModal(props) {

    const { open, handleClose, choose, listTaskColor } = props;

    if(!open){
        return null;
    }

    return <Dialog
        // onEnter={this.initRule}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
    >
        <DialogTitle style={{ padding: '24px 16px' }}>
            <Typography dir='auto' style={{
                color: '#21212D',
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: 500
            }} >Choose status color</Typography>
        </DialogTitle>
        <DialogContent >

            <Grid container >
                <Grid container direction='column' wrap='nowrap'>
                    {statusColors.map((color, idx) => <ColorComponent key={idx} color={color} onClick={() => { }} isCurrent={color.id === listTaskColor} choose={() => { choose(color.id) }} />)}
                </Grid>
            </Grid>


        </DialogContent>
        <DialogActions >
            <Button onClick={() => { choose(0) }} aria-label="save"  >
                {'Remove color'}
            </Button>
        </DialogActions>

    </Dialog>

}

