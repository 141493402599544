import React from 'react';
import { withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { compose } from 'recompose';

const styles = (theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 8,
        '&:last-child': {
            marginBottom: 0
        }
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.23,
        borderRadius: 6,
        width: theme.constants.searchInputWidth - 40 + 10,
        height: 34 + 10,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    iconWrap: {
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 34,
        height: 34,
        backgroundColor: '#ebebf5',
        zIndex: 2
    },
    icon: {
        color: '#5a5b66',
    },
    resultText: {
        marginLeft: 12,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: '#21212d',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#21212d'
        },
        zIndex: 2
    }
})

function Suggest(props) {
    const {suggest, classes, openSuggest, withKeysNavigation} = props

    const keysNavigationProps = !withKeysNavigation ? {} : {
        suggestCase: JSON.stringify({suggest: suggest, suggestType: "suggest"}),
        suggest: "suggest-find"
    }
    if(!suggest.query) return null

    return (
        <div data-intrcm-id="Suggest_30k4n65u" className={classes.container} >
            <div data-intrcm-id="Suggest_mu6kj3r1" className={classes.iconWrap}><SearchIcon className={classes.icon} /></div>
            <div data-intrcm-id="Suggest_ubai1pjo" onClick={() => openSuggest(suggest)} className={classes.resultText}>{suggest.query}</div>
            <div data-intrcm-id="Suggest_nyha33rh" {...keysNavigationProps} className={classes.suggest} />
        </div>
    )
}

export default compose(
    withStyles(styles, {withTheme: true})
)(Suggest)

