import { Grid, withStyles } from '@material-ui/core';
import classnames from "classnames";
import moment from "moment";
import React, { Component, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import FooterKanbanCard from './FooterKanbanCard/FooterKanbanCard';
import KanbanCardMenu from './KanbanCardMenu.js';
import KanbanLabelsWrapper from './KanbanLabelsWrapper';
import KanbanMembers, { MembersContainerMarginTop } from './KanbanMembers.js';
import KanbanCardTitle from './KanbanCardTitle.js';
import KanbanCardImage from './KanbanCardImage'
import { parseImageUrl, getPreviewImageFromAttachments } from '@tuqqi/common'

const cardHoverStyle = {
    "boxShadow": "0 2px 4px -1px rgba(33, 33, 45, 0.2), 0 1px 10px 0 rgba(33, 33, 45, 0.12), 0 4px 5px 0 rgba(33, 33, 45, 0.14)",
    "border": "solid 0.5px #b6b9ca",
    "backgroundColor": "#f3f6fb"
}
const CardBorder = 1

const CardPaddingTop = 16
const CardPaddingBottom = 16
const CardPaddingLeft = 16
const CardPaddingRight = 16

const AuthorInfoMarginTop = 4
const AuthorInfoMarginBottom = 2
const AuthorInfoFontSize = 12
const AuthorInfoLineHeight = 1.34

export const KANBAN_CARD_COLLECTION_LABELS_CONTAINER_MARGIN_BOTTOM = -2
export const KANBAN_CARD_WIDTH = 264
export const AVAILABLE_CARD_WIDTH = KANBAN_CARD_WIDTH - CardPaddingRight - CardPaddingLeft - (2 * CardBorder)

export const DEFAULT_CARD_HEIGHT = CardBorder + CardPaddingTop + CardPaddingBottom +
    MembersContainerMarginTop + AuthorInfoMarginTop
    + AuthorInfoMarginBottom + (AuthorInfoFontSize * AuthorInfoLineHeight)

const styles = theme => ({
    cardContainer: {
        overflow: 'hidden',
        "width": KANBAN_CARD_WIDTH,
        "borderRadius": "4px",
        "border": `solid ${CardBorder}px #B6B9CA`,
        "backgroundColor": "#ffffff",
        '& .menu': {
            opacity: 0,
            // displ®ay: 'none'
        },
        '&:hover': {
            ...cardHoverStyle,
            '& .menu': {
                opacity: 1,
                display: 'initial'
            }
        }
    },
    containerBody: {
        padding: `${CardPaddingTop}px ${CardPaddingRight}px ${CardPaddingBottom}px ${CardPaddingLeft}px`,
    },
    dragging: {
        transform: 'rotate(11deg)',
        ...cardHoverStyle,
        '& .menu': {
            display: 'initial'
        }
    },
    authorInfo: {
        marginTop: AuthorInfoMarginTop,
        marginBottom: AuthorInfoMarginBottom,
        "fontFamily": "Rubik",
        "fontSize": AuthorInfoFontSize,
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": AuthorInfoLineHeight,
        "letterSpacing": "0.4px",
        "color": theme.newUI.color.gray
    },
    menuIconWithoutLabels: {
        marginTop: -10
    }
})



class KanbanCard extends Component {
    state = {
        isMouseHover: false,
    }
    static defaultProps = {
        withContainerClassName: false,
        containerClassName: ''
    }
    shouldOpenCard = (e) => {
        const {card: {id}} = this.props
        let shouldOpen = true
        const menuIconEl = document.getElementById(`item_kanban_preview_more_menu_${id}`)
        const cardEl = document.getElementById(`item_preview_card_kanban_${id}`)
        const menuIconOpenedEl = document.getElementById(`item_preview_more_menu_opened_${id}`)

        if(menuIconEl?.contains(e.target) && !menuIconOpenedEl) {
            shouldOpen = false
        }
        if(!!menuIconOpenedEl && !cardEl?.contains(e.target)) {
            shouldOpen = false
        }
        return shouldOpen
    }
    openCard = (e) => {
        if(!this.shouldOpenCard(e)) return
        const { openFullview } = this.props;
        const { doc, id } = this.props.card
        openFullview(doc, id, true)
    }

    shouldComponentUpdate(nextProps) {
        const { card } = this.props;

        const newCard = nextProps.card;

        if (newCard.doc && card.doc) {
            if ((newCard.doc.lastModifiedTime > card.doc.lastModifiedTime)
                || (newCard.doc.taskDoneCounter !== card.doc.taskDoneCounter)) {
                return true;
            }
        }

        return false;
    }



    render() {
        const { classes, cloudfront, card, isDraggingOver, collectionUid, withContainerClassName, containerClassName, users, orgUid } = this.props
        
        if (!card || !card.doc) {
            return null
        }
        const { doc, id } = card

        const { title, members, collectionLabels } = doc;

        const containerC = withContainerClassName ? containerClassName : classes.cardContainer
        let filteredCollectionLabels = collectionLabels.filter(x => x.includes(collectionUid) || x.includes(orgUid))

        // should component update does not allow to update state, temp solution
        let parsedImage = ''
        const imageAttachments = getPreviewImageFromAttachments(doc)
        if (imageAttachments) {
            const parse = parseImageUrl(cloudfront);
            parsedImage = parse(imageAttachments)
        }
        const user = users.find(u => u.auth0_id === doc.userUUID)
        const userName = user.firstname + ' ' + user.lastname;
        return (
            <Grid id={`item_preview_card_kanban_${id}`} container onClick={this.openCard} className={classnames(containerC, isDraggingOver && classes.dragging)} direction='column' >
                {imageAttachments ? <KanbanCardImage image={parsedImage} /> : null}
                <Grid container className={classes.containerBody} direction='column'>
                    {
                        filteredCollectionLabels.length ?
                            <Fragment>
                                <Grid container direction='row' wrap='nowrap' style={{ marginBottom: KANBAN_CARD_COLLECTION_LABELS_CONTAINER_MARGIN_BOTTOM }} justify='space-between'>
                                    <KanbanLabelsWrapper docLabels={filteredCollectionLabels} />
                                    <KanbanCardMenu card={card} classes={{ menuIcon: 'menu' }} />
                                </Grid>
                                <KanbanCardTitle title={title} />
                            </Fragment> :
                            <Grid container direction='row' wrap='nowrap' justify='space-between' >
                                <KanbanCardTitle title={title} />
                                <KanbanCardMenu
                                    card={card}
                                    classes={{ menuIcon: 'menu' }} />
                            </Grid>
                    }
                    <span className={classes.authorInfo}>{moment(doc.docTimestamp).fromNow()}</span >
                    <FooterKanbanCard members={members} doc={doc} />
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    collectionUid: state.results.channel.collectionUid,
    cloudfront: state.globalData.cloudfront,
    users: state.globalData.users,
    orgUid: state.data.userData.userDetails.data.organizationUid
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading Item, Please Contact Tuqqi support"),
    withOpenFullview,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(KanbanCard);