import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import withErrorCatcher from '../../../../../../components/Common/withErrorCatcher';
import { compose } from 'recompose';
import {SimpleTextDarkTooltip, TuqqiAutocompleteInput} from '@tuqqi/web'
import {useModalKeyboard} from '@tuqqi/web'
import calculateWidth from './calculateWidth';
import { CancelTaskIcon } from './CancelTaskIcon';
import { CreateTaskIcon } from './CreateTaskIcon';
import { OpenFullviewIcon } from './OpenFullviewIcon';
import { NewUiWrapper } from '@tuqqi/web';
import { store } from '../../../../../../..';
import { KeepFastCreation } from '../../../utils/keepFastCreation';

const KANBAN_CARD_WIDTH = 224
const styles = {
    icon: {
        transform: 'rotate(90deg)',
        marginLeft: 8,
        cursor: 'pointer',
    },
    menu: {
        marginTop: 8,
        padding: 12,
        width: KANBAN_CARD_WIDTH,
        maxWidth: KANBAN_CARD_WIDTH,
        backgroundColor: '#FFFFFF',
        marginBottom: 8,
        boxSizing: 'border-box',
    },
    card: {
    },
    icons: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cancelIcon: {
        marginRight: 'auto'
    },
    createIcon: {
        marginRight: 8
    },
    icon: {
        cursor: 'pointer'
    },
    input: {
        width: '100%',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.24,
        color: '#0C0C0D',
        padding: 0,
        outline: 0,
        minHeight: 20,
        border: 0,
        maxWidth: KANBAN_CARD_WIDTH - 24,
        resize: 'none',
    },
}

const useStyles = makeStyles(styles)

const inputStyles = (empty, initInputHeight) => ({
    '&multiLine': {
        control: {
        },
        input: {
            width: '100%',
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
            letterSpacing: 0.24,
            color: '#0C0C0D',
            padding: 0,
            outline: 0,
            minHeight: empty ? initInputHeight : 20,
            border: 0,
            maxWidth: KANBAN_CARD_WIDTH - 24,
            resize: 'none',
        },
    },
    suggestions: {
        display: 'none',
    },
})

export const FastCreationContent = (props) => {
    const classes = useStyles()
    const { onCancel, onOpenFullview, onCreate } = props
    const [t] = useTranslation("gantt")
    const [title, setTitle] = useState('')

    const initInputHeight = useMemo(() => {
        const size = calculateWidth(t('Type the {{datatypeName}} title and press Enter to submit', {datatypeName: KeepFastCreation.dataTypeName}), {lineHeight: '20px', font: 'Rubik', fontSize: '15px', width: `${KANBAN_CARD_WIDTH - 24}px`})
        return size.height
    }, [t])

    useEffect(() => {
        props.setPlaceholderHeight(initInputHeight)
    }, [initInputHeight])

    const _onOpenFullview = useCallback(() => {
        onOpenFullview(title)
    }, [onOpenFullview, title])
    
    const _onCreate = useCallback(() => {
        onCreate(title)
    }, [onCreate, title])

    const {updateAllowMainAction, onEnter} = useModalKeyboard({
        modalShown: true, 
        onMain: _onCreate, 
        onCancel: onCancel, 
        id: `fast-creation-input`,
        deniedKeyCode: ['shiftKey'], 
        manualEnter: true
    })

    const isDisabled = useMemo(() => {
        return !title?.trim?.()
    }, [title])

    const onChangeTitleHandler = (val) => {
        setTitle(val)
    }

    useEffect(() => {
        updateAllowMainAction(!isDisabled)
    }, [isDisabled])

    const renderWithWrapNewUI = (comp) => {
        return <NewUiWrapper store={store} callbacks={{}}>
            <>
            {comp}
            </>
        </NewUiWrapper>
    }

    const Input = <TuqqiAutocompleteInput style={inputStyles(!title?.trim?.(), initInputHeight)}
        autoFocus={true}
        denyPreventDefaultOnEnterShift
        onEnterKeyDown={e => {
            e.preventDefault()
            onEnter?.()
        }}
        shouldRenderGroupMentioning
        withoutMentions
        onChange={onChangeTitleHandler}
        placeholder={t('Type the {{datatypeName}} title and press Enter to submit', {datatypeName: KeepFastCreation.dataTypeName})}
        singleLine={false}
        value={title} />

    const Icons = !!isDisabled ? null :
        <div data-intrcm-id="FastCreationContent_weed2h4o" className={classes.icons}>
            <div data-intrcm-id="FastCreationContent_4d4gxfyl" onClick={onCancel} className={`${classes.icon} ${classes.cancelIcon}`}>
                <CancelTaskIcon />
            </div>
            <div data-intrcm-id="FastCreationContent_g1k6y27r" onClick={_onCreate} className={`${classes.icon} ${classes.createIcon}`}>
                {renderWithWrapNewUI(<SimpleTextDarkTooltip text={t('Quickly create a {{datatypeName}} in this group', {datatypeName: KeepFastCreation.dataTypeName})} component={<CreateTaskIcon />} />)}
            </div>
            <div data-intrcm-id="FastCreationContent_r5as2wp8" onClick={_onOpenFullview} className={`${classes.icon}`}>
                {renderWithWrapNewUI(<SimpleTextDarkTooltip text={t('Open the full view to create a new {{datatypeName}}', {datatypeName: KeepFastCreation.dataTypeName})} component={<OpenFullviewIcon />} />)}
            </div>
        </div>
    

    return <>
        {renderWithWrapNewUI(Input)}
        {Icons}
       </> 
}

export default compose(withErrorCatcher())(FastCreationContent);