import { randomID } from "@tuqqi/common"

export const mapChatCommentToNotification = (comment) => {
    if(comment?.chatUId) {
        return {
            _commentData: comment,
            activitiId: null,
            collection: null,
            created: comment.creationDateTime,
            dateTime: comment.creationDateTime,
            docId: randomID(),
            icon: null,
            id: randomID(),
            isChatNotification: true,
            type: comment.type === 'Group' ? 'group' : 'private',
            itemText: null,
            key: randomID(),
            link: randomID(),
            messageTemplateParams: [`${comment?.sender?.firstname ?? ''} ${comment?.sender?.lastname ?? ''}`, comment.type === 'Group' ? 'Group' : 'Private', comment.messageText],
            notificationDescription: null,
            notificationRuleId: null,
            ruleTitle: null,
            systemActionTypeUid: 'user_add_comment',
            dataType: '',
            title: "${0} @ ${1}",
            topic: "${2}",
            userThatMadeTheAction: {auth0_id: comment?.sender?.auth0_id, firstname: comment?.sender?.firstname ?? '', lastname: comment?.sender?.lastname ?? '', tag: comment?.sender?.tag ?? '', profileImageUrl: comment?.sender?.profileImageUrl ?? ''},
            whyDoISeeThisItem: ''
        }  
    }
    return {
        _commentData: comment,
        activitiId: null,
        collection: null,
        created: comment.dateTime,
        dateTime: comment.dateTime,
        docId: comment.docId,
        icon: null,
        id: randomID(),
        isChatNotification: true,
        type: 'item',
        itemText: null,
        key: comment.docId,
        link: comment.docId,
        messageTemplateParams: [comment.userDisplayName, comment.docTitle || 'Untitled', comment.text],
        notificationDescription: null,
        notificationRuleId: null,
        ruleTitle: null,
        systemActionTypeUid: 'user_add_comment',
        dataType: '',
        title: "${0} @ ${1}",
        topic: "${2}",
        userThatMadeTheAction: {auth0_id: comment.userId, firstname: comment.userDisplayName?.split(" ")?.[0] ?? '', lastname: comment.userDisplayName?.split(" ")?.[1] ?? '', tag: comment.userTag, profileImageUrl: comment.userPicUrl},
        whyDoISeeThisItem: ''
    }
}