import { withStyles, Grid, List, Divider } from '@material-ui/core';
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { push } from 'react-router-redux';
import DrawerListItem from "./drawerListItem";
import LeftDrawerDefaultItems from "./LeftDrawerDefaultItems";
import { connect } from 'react-redux';
import LeftDrawerCustomItems from './LeftDrawerCustomItems';
import { parseImageUrl } from '@tuqqi/common'
import { themeConstants } from '../../../../../theme';
import { managerTableKeepGlobalData } from '@tuqqi/web';
import { TuqqiSetup } from './TuqqiSetup';

// Apply some reset
const styles = theme => ({
    list: {
        paddingLeft: 14,
        paddingRight: 8,
        paddingTop:8,
        paddingBottom:16
     },
    container: {
        marginTop: 16,
        marginBottom: 16,
        zIndex: 2,
        bottom: 0,
        position: 'fixed',
        overscrollBehavior: 'contain',
        backgroundColor:'white',
        boxShadow:'rgba(140, 140, 140, 0.2) 0px 0px 0px 1px',
        borderRadius:'12px',
        overflowY: 'auto',
        height:'fit-content',
        

        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight + 16 + 16}px)`,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        top: theme.constants.defaultToolbarHeight
    },
    show: {
        width: themeConstants.leftDrawerPXWidth,
        [theme.breakpoints.down('1071')]: {
            width: themeConstants.leftDrawerPXWidth
        }
    },
    hidden: {
        width: 0
    },
    divider: {
        backgroundColor: '#B7B8BD26',
        marginLeft: -8,
        marginRight: -8,
        marginTop: 8,
        marginBottom: 8,
    },
    dividerBold: {
        height: 3,
        backgroundColor: '#B7B8BD26',
        marginLeft: -8,
        marginRight: -8,
        marginTop: 8,
        marginBottom: 8,
    },
    title: {
        width: 'fit-content',
        padding: 16,
        paddingBottom: 0,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: 0.246063,
        color: '#0C0C0D'

    },
    adminTitle:{
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#6B6F7A',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: 14,
        paddingRight: 8,
        paddingBottom: 8,
        alignItems: 'center',
    },
    headerTexts: {
        marginLeft: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    avatar: {
        width: 32,
        cursor: 'pointer',
        height: 32,
        borderRadius: 8,
        objectFit: 'cover'  
    },
    userTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        cursor: 'pointer',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '20px',
        color: '#18181B',
    },
    userSubTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '12px',
        color: '#6B6F7A',
    }
});

const fallbackImage = '/resources/default-image.svg'

const parseLink = (link, cloudfront) => {
    if(!link || !cloudfront) return fallbackImage
    return parseImageUrl(cloudfront)(link) || fallbackImage
}

const _LeftDrawer = (props) => {
    const {classes, t, dispatch, profileData, pathname, canOpenMenu} = props
    const cloudfront = managerTableKeepGlobalData.getCloudfront()

    const show = useMemo(() => pathname === '/' || pathname === '/feed' ,[pathname])

    const link = useMemo(() => {
        let l = profileData?.profileImageUrl ?? ''
        return parseLink(l, cloudfront)
    }, [cloudfront, profileData?.profileImageUrl])


    const onGoToAdmin = () => {
        dispatch(push('/admin'))
    }
    const onGoToNavAdmin = () => {
        dispatch(push('/admin/navigation'))
    }

    const onGoToAdminCustomForm = () => {
        dispatch(push('/admin/custominputmappings'))
    }

    const onGoToAdminManageUsers = () => {
        dispatch(push('/admin/users'))
    }
    const onGoToUser = () => {
        dispatch(push('/mypage'))
    }
    const onGoToHelpCenter = () => {
        const el = window.document.getElementsByClassName("intercom-launcher")?.[0]
        if(!el) return
        el.click()
    }

    const DefaultItems = <LeftDrawerDefaultItems />
    const CustomItems = <LeftDrawerCustomItems />

    const Head = <div className={classes.header}>
        <img onClick={onGoToUser} src={link} className={classes.avatar} />
        <div className={classes.headerTexts}>
            <div onClick={onGoToUser} className={classes.userTitle}>{`${profileData?.firstname?.trim?.() ?? ""} ${profileData?.lastname?.trim?.() ?? ""}`}</div>
            <div className={classes.userSubTitle}>{`${profileData?.title?.trim?.() ?? ""}`}</div>
        </div>
    </div>

    const AdminItem = profileData.role === "Admin" &&
            <DrawerListItem
                id="admin_item"
                selectedCondition={false}
                onClick={onGoToAdmin}
                // icon={<SVG src={adminIconPath} />}
                text={t('⚙️  Dashboard')} />
    
    const NavAdminItem = profileData.role === "Admin" &&
            <DrawerListItem
                id="nav_admin_item"
                selectedCondition={false}
                onClick={onGoToNavAdmin}
                // icon={<SVG src={adminIconPath} />}
                text={t('🧭  Customize Menu')} />
    
    const CustomFormAdminItem = profileData.role === "Admin" &&
            <DrawerListItem
                id="custom_form_admin_item"
                selectedCondition={false}
                onClick={onGoToAdminCustomForm}
                // icon={<SVG src={adminIconPath} />}
                text={t('📋  Form Builder')} />
    
    const ManageUsersAdminItem = profileData.role === "Admin" &&
            <DrawerListItem
                id="manage_users_admin_item"
                selectedCondition={false}
                onClick={onGoToAdminManageUsers}
                // icon={<SVG src={adminIconPath} />}
                text={t('💌  Invite Users')} />                

    const HelpCenterItem = <DrawerListItem
                id="help_center__item"
                selectedCondition={false}
                onClick={onGoToHelpCenter}
                text={t('❓ Help Center')} />                

    if(!show) return null

    const showSetup = false;

    return <Grid className={`${classes.container} ${!!canOpenMenu ? classes.show : classes.hidden}`}>
        {Head}
        {/* <Divider className={classes.divider} /> */}

        <List className={classes.list}>
            {DefaultItems}
            {CustomItems}
            {profileData.role === "Admin" && <Divider className={classes.divider} />}
            {profileData.role === "Admin" && <div className={classes.adminTitle}>{t("Administrator")}</div>}

            {AdminItem}
            {ManageUsersAdminItem}
            {CustomFormAdminItem}
            {NavAdminItem}

            {<>
                <Divider className={classes.divider} />
                {HelpCenterItem}
                {showSetup && <TuqqiSetup count={25} />}
            </>}
            {/* <div data-intrcm-id="leftDrawer_gsp9id77" className={classes.listItem}>
                <LeftDrawerFooter />
            </div> */}
        </List>
    </Grid>
}

const mapStateToProps = (state) => ({
    profileData: state.data?.userData?.userDetails?.data,
    pathname: state?.wrapper?.pathname ?? '',
    canOpenMenu: state?.wrapper?.canOpenMenu ?? false
})

const mapDispatchToProps = dispatch => ({dispatch})

export const LeftDrawer = compose(
    withStyles(styles, { withTheme: true }),
    withErrorCatcher(),
    withTranslation('drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps)
)(_LeftDrawer);