import React, { useCallback, useEffect, useMemo } from 'react';
import { Divider, Menu, MenuItem, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { definedSpecificGroups, getGroupDocumentPermission } from '@tuqqi/common';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';
import { isDataTypeEnableInPermission } from '../../../Common/Utils/inputUtils';


const styles = (theme) => ({
    title: {
        color: '#FFFFFF8F',
        fontWeight: '400', 
        padding: "6px 8px 6px 8px", 
        fontSize: 12,
        lineHeight: '12px',
        marginLeft:4, 
        marginRight:4
    },
    text: {
        // fontFamily: theme.typography.fontFamily,
    },
    menuItemFirstDataType: {
        padding: "6px 8px 6px 8px",
        color: "rgba(255,255,255,0.9)",
        marginLeft: 4,
        marginRight: 4,
        "&:hover": {
            backgroundColor: "rgba(183, 184, 189, 0.2)"

        }
    },
    menuItemFirstDataType2: {
        padding: "6px 8px 6px 8px",
        color: "rgba(255,255,255,0.9)",
        marginLeft: 4,
        marginRight: 4,
        "&:hover": {
            backgroundColor: "rgba(183, 184, 189, 0.2)"

        }
    }
})

const useStyles = makeStyles(styles);


export const DataTypeButtonComponent = (props) => {
    const { mapping, getCollectionsForDataType, openNewInput, disableMenu, passDataType } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [groups, setGroups] = React.useState([]);
    const classes = useStyles();


    const openInputMenu = (dataType, groupUid) => {
        setAnchorEl(null)
        openNewInput(dataType, groupUid)
    }

    const onDataTypeClick = (event) => {

        if (disableMenu) {
            openNewInput(passDataType ? mapping.customDataMapping.dataTypeFilter : undefined)
        } else {
            setAnchorEl(event.currentTarget);

            setGroups(getCollectionsForDataType(mapping.customDataMapping.dataTypeFilter));
        }
    }

    return <div>
        <MenuItem className={classes.menuItemFirstDataType} onClick={onDataTypeClick}>
            {/* <MenuItem style={{padding: "6px 8px 6px 8px",color: "rgba(255,255,255,0.9)",marginLeft:4,marginRight:4,"&:hover": {backgroundColor: "rgba(183, 184, 189, 0.2)"}}} onClick={onDataTypeClick}> */}
            {/* <MenuItem  onClick={onDataTypeClick}> */}
            <Icon style={{ color: "rgba(255,255,255,0.56", fontSize: 18, marginRight: 8 }}>{mapping.icon}</Icon>

            <div style={{textOverflow: "ellipsis", maxWidth: 150, overflow: 'hidden'}}>{mapping.title}</div>
        </MenuItem>
        {/* {console.log("this is ",mapping)} */}

        <Menu anchorEl={anchorEl}
            disableAutoFocusItem
            open={anchorEl}
            onClose={() => { setAnchorEl(null) }} >
            {
                groups.map((collection) => (
                    <MenuItem className={classes.menu} onClick={() => openInputMenu(mapping.customDataMapping.dataTypeFilter, collection.collectionGroup.groupUid)}>{collection.title}</MenuItem>
                ))
            }
        </Menu>
    </div>

}


const DataTypeListForCreate = (props) => {
    const { inputMappings, dispatch, groupInfo, t, isFocused, defaultInputTemplates, collections, noGroup = false, ignoreDataTypes = [], setAllowArticle = undefined } = props

    const [numberToDispaly, setNumberToDispaly] = React.useState(4);
    const [totalNumber, setTotalNumber] = React.useState(0);
    const classes = useStyles();

    
    useEffect(() => {
        
        if (!isFocused) {
            setNumberToDispaly(4)
        }
        
    }, [isFocused])
    
    const isSpecificPublicExists = useCallback((dataType) => {
        const mapping = inputMappings.find(m => m.customDataMapping.dataTypeFilter === dataType)
        if(!mapping) return true
        if(!mapping.specificGroupsUids || typeof mapping.specificGroupsUids !== 'string') return true
        const keys = mapping.specificGroupsUids.split(";")
        if(!keys.length) return true
        
        return keys.includes(definedSpecificGroups.public)
    }, [inputMappings])
    
    
    const _isDataTypeEnableInPermission = useCallback((dataType) => {
        const permission =  'public'
        let allowUsage = isDataTypeEnableInPermission(inputMappings, defaultInputTemplates, collections, dataType, permission)
        if(!isSpecificPublicExists(dataType)) {
            allowUsage = false
        }
        return allowUsage
    }, [isSpecificPublicExists, defaultInputTemplates, collections])

    useEffect(() => {
        if(!setAllowArticle) return
        setAllowArticle(_isDataTypeEnableInPermission('htmlwysiwyg'))
    }, [setAllowArticle, _isDataTypeEnableInPermission])
    
    const getCollectionsForDataType = (dataType) => {
        
        const allCollections = [...props.collections, ...props.openCollections];
        
        const collections = allCollections.filter(collection => {
            if (collection.allInputsAllowed) {
                return false
            }
            
            if (collection?.settings?.allowedInputs?.includes?.(dataType)) {
                return true
            }
            
            return false
        })
        
        return collections;
        
    }
    
    const openNewInput = (dataType, groupUid) => {
        const permissions = getGroupDocumentPermission(groupUid)
        let newDoc = { html: '', title: '', permissions: permissions, dataType: dataType }
        dispatch(openNewUiInput(newDoc, dataType))
        setNumberToDispaly(4);
        dispatch({ type: 'BLUR_CREATENEW_MODAL' })
    }
    
    
    
    const dataTypesList = useMemo(() => {
        const dataTypesFilters = noGroup && ignoreDataTypes.length ? ignoreDataTypes : groupInfo?.settings?.allowedInputs ?? [];
        // Sort inputMappings alphabetically by dataTypeName
        const maps = inputMappings
        .filter(inputMapping => _isDataTypeEnableInPermission(inputMapping.customDataMapping.dataTypeFilter) && !dataTypesFilters.includes(inputMapping.customDataMapping.dataTypeFilter))
        const sortedMappings = maps.sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
        
        return sortedMappings;    
    }, [inputMappings, numberToDispaly, groupInfo, noGroup, ignoreDataTypes]);

    useEffect(() => {
        setTotalNumber(dataTypesList.length)
    }, [dataTypesList.length])
    
    const showMore = totalNumber <=4 ? null : (<div>
        <MenuItem className={classes.menuItemFirstDataType} onClick={() => { setNumberToDispaly(numberToDispaly === 4 ? totalNumber : 4) }}>{numberToDispaly === 4 ? t('Show All') : t('Show Less') } {numberToDispaly === 4 ? " (" + totalNumber + ")" : ""}</MenuItem>

    </div>)

if (!inputMappings) {
    return null
}
if(!dataTypesList.length) return null


return (
    <div data-intrcm-id="CreateNewModal_1vahjoeksdad"  >
            <div className={classes.title}>{t("Other")}</div>
            <Divider />
            {dataTypesList.slice(0, numberToDispaly).map((mapping, idx) => {
            if(!mapping) return null;
            return (
                <DataTypeButtonComponent
                key={idx}
                mapping={mapping}          
                disableMenu          
                openNewInput={openNewInput}
                passDataType
                getCollectionsForDataType={getCollectionsForDataType}
                />
            )
        })}
            {showMore}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        inputMappings: state.globalData.inputMappings,
        openCollections: state.globalData.openCollections,
        defaultInputTemplates: state.globalData.defaultInputTemplates,
        collections: state.userData.collections,
        groupInfo: state.results.channel,

    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(DataTypeListForCreate)