import React from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Icon } from '@material-ui/core';


const styles = theme => ({

    container: { width: 24, justifyContent: 'left', cursor: 'pointer' }
});


class GoBackIcon extends React.PureComponent {

    render() {
        const { goBack, classes } = this.props
        return (
            <Grid onClick={goBack} container alignContent='center' className={classes.container}>
                <Icon >keyboard_arrow_left</Icon>
            </Grid>
        );
    }
}


export default withStyles(styles)(GoBackIcon);
