import React, { PureComponent } from 'react';

export default class LeaveGroupIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H14" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5547 9.03682C15.8901 8.59378 15 9.07017 15 9.86887V11.0004H10C9.44772 11.0004 9 11.4481 9 12.0004C9 12.5526 9.44772 13.0004 10 13.0004H15V14.1318C15 14.9305 15.8901 15.4069 16.5547 14.9639L19.7519 12.8324C20.3457 12.4366 20.3457 11.5641 19.7519 11.1683L16.5547 9.03682Z" fill={color}/>
            </svg>
        )
    }
}
