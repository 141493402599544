import { DRAWER_TOGGLE, FULL_RESET_STORE, DATA_UPDATE, DATA_BEING_FILTERED, DATA_LOADMORE, DATA_FEED_UPDATE, DATA_UPDATED, DATA_FILTER, DATA_FILTERED, DATA_WIDGET, DATA_WIDGET_LOADED, DATA_UPDATE_SPECIFIC_ITEM, FINISHED_UPDATING_ITEM, FEED_NEW_ITEMS } from '../actionTypes/actionTypes'

const initialState = [];

const input = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState

        case DATA_UPDATE:
            return {
                ...state,
                dataUpdate: state.dataUpdate + 1,
                needsUpdate: true
            }

        case DATA_LOADMORE:
            return {
                ...state,
                loadMore: true
            }

        case FEED_NEW_ITEMS:
            return {
                ...state,
                feedNewItems: action.state
            }


        case DATA_FEED_UPDATE:
        case 'UPDATE_DOCUMENT_SCHEDULE_REQUEST_SUCCESS':
            return {
                ...state,
                needsUpdate: true
            }

        case DATA_UPDATED:
            return {
                ...state,
                needsUpdate: false,
                widgetUpdate: false,
                loadMore: false,
                needsFilter: false
            }
        case DATA_FILTER:
            return {
                ...state,
                needsFilter: true,
            }
        case DATA_BEING_FILTERED:
            return {
                ...state,
                isFiltering: true
            }
        case DATA_FILTERED:
            return {
                ...state,
                needsFilter: false,
                isFiltering: false
            }
        case DATA_WIDGET:
            return {
                ...state,
                widgetUpdate: true
            }
        case DATA_WIDGET_LOADED:
            return {
                ...state,
                widgetUpdate: false
            }
        case DATA_UPDATE_SPECIFIC_ITEM:
            return {
                ...state,
                itemId: action.itemId,
                specificItemUpdate: true,
            }
        case 'UPDATE_DOCUMENT_LOCATION_REQUEST_SUCCESS':
            return {
                ...state,
                dataUpdate: state.dataUpdate + 1,
                needsUpdate: true
            }
        case FINISHED_UPDATING_ITEM:
            return {
                ...state,
                specificItemUpdate: false,
                itemId: null,
            }
        default:
            return state
    }
}

export default input