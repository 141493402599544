import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {NewUiWrapper, useSelectedAdminDrawer, TeamsCommonView} from '@tuqqi/web'
import { store } from '../../../..';
import { makeStyles } from '@material-ui/styles';
import goTo from '../../../actions/goTo';
import { push } from 'react-router-redux';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions';
import parseImagUrl from "../../../../ui/components/Common/parseImagUrl";
import { globalDataLoad } from '../../../../actions/globalDataAction';
import { goToEditProfile } from '../../../actions/goToEditProfile';

const styles = theme => ({
    wrap: {

    }
});

const useStyles = makeStyles(styles)

const UID = 'manage-users-main-new'

const fixImage = (cloudfront, user) => {
    if (user && user.profileImageUrl) {
        user.profileImageUrl = parseImagUrl(cloudfront, user.profileImageUrl)
    }
    if (user && user.org && user.org.logo && !user.org.logo.includes('app.tuqqi.com')) {
        user.org.logo = parseImagUrl(cloudfront, user.org.logo, 145)
    }
}
const ManageTeamsView = (props) => {
    const classes = useStyles()
    const drawerInfo = useSelectedAdminDrawer(UID)
    const {chatService, users, dispatch, userSettingsService, cloudfront} = props
    
    const openSubscriptionDialog = (reason) => {
        if(!reason) return
        dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }

    const onUserClick = (userUid) => {
        if(!userUid) return
        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }
        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
    }
    const onUserMessage = (auth0_id) => {
        if(!auth0_id) return

        chatService.openNewChat(auth0_id).then(res => {
            const { chat } = res
            if (!chat || !chat.chatUId) {
              dispatch(push({ pathname: "chat" }))
              return
            }
            dispatch({
              type: 'SET_CHAT_ROOM',
              chatRoomFromUserPage: true,
              room: res
            })
            dispatch(push(`/chat?chatid=${chat.chatUId}`))
          })
    }
    const onUserEditProfile = () => {
        goToEditProfile(dispatch)
    }

    const onAfterDeleteUser = () => {
        userSettingsService.getUsers().then(users => {
            dispatch(globalDataLoad({ users: users }))
        })
    }

    const onAfterEditUser = () => {
        userSettingsService.getUsers().then(users => {
            const activeUsers = users.filter(x => x.status === 0);
            users.forEach(_userDetails => fixImage(cloudfront, _userDetails))
            activeUsers.forEach(_userDetails => fixImage(cloudfront, _userDetails))

            dispatch(globalDataLoad({users, activeUsers}));
        });

    }

    const callbacks = {
        onShowSubscriptionModal: openSubscriptionDialog,
        onUserClick,
        onAfterDeleteUser,
        onUserMessage,
        onAfterEditUser,
        onUserEditProfile,
    }


    const Content = <TeamsCommonView prop='teams' uid={drawerInfo.uid} />

    return <NewUiWrapper store={store} callbacks={callbacks}>
        {Content}
    </NewUiWrapper>}

const mapStateToProps = (state) => ({
    users: state?.data?.globalData?.activeUsers?.data ?? [],
    chatService: state.services.chatService,
    cloudfront: state.globalData.cloudfront,
    userSettingsService: state.services.userSettingsService
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(ManageTeamsView);
