import React, { useCallback, useMemo, useState } from 'react';
import { Icon, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatDateTime, parseImageUrl } from '@tuqqi/common';
import moment from 'moment';
import goTo from '../../../actions/goTo';
import { hexToRGB } from '../../../views/groups/Calendar/event-utils';
import withErrorCatcher from '../../Common/withErrorCatcher';
import { withMixpanel } from '../withMixpanel';
import goToCollection from '../../../actions/goToCollection';
import NotificationParsedTextNew from '../../Header/HeaderToolbar/NotificationsModal/NotificationParsedTextNew';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview';
import { onGoToChatStore } from '@tuqqi/web';
import { push } from 'react-router-redux';

const styles = (theme) => ({
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: 4,
        top: 4,
        color: '#979aaa'
    },
    point: {
        right: 5,
        top: 16,
        position: 'absolute',
        width: 5,
        height: 5,
        backgroundColor: theme.customColors.primary.main,
        borderRadius: 3,
    },
    unread: {
        backgroundColor: hexToRGB(theme.customColors.primary.main, 0.04)
    },
    container: {
        position: 'relative',
        minHeight: 46,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 16,
        paddingLeft: 5,
        paddingTop: 26,
        paddingBottom: 8,
    },
    avatar: {
        objectFit: 'cover',
        backgroundColor: '#F3F6FB',
        alignSelf: 'flex-start',
        width: 50,
        height: 50,
        borderRadius: 25,

    },
    title: {
        cursor: 'pointer',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#0C0C0D',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#0C0C0D'
        },
    },
    time: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#737373',
    },
    firstRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    mainText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.5,
        color: '#0C0C0D',
        marginTop: 4,
    },
    textsWrap: {
        cursor: 'pointer',
        marginTop: 0,
        alignSelf: 'flex-start',
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 318,
        marginLeft: 16,
    }
})

const NotificationSnackbarItem = (props) => {
    const { dispatch, classes, notification, close, t, cloudfront, queryService, mixpanelTrack, openFullview, notificationService } = props
    const parseUrl = !!cloudfront?.data?.signedFiles ? parseImageUrl(cloudfront.data) : () => ''
    const [isLoading, setIsLoading] = useState(false)

    const url = useMemo(() => {
        const { userThatMadeTheAction } = notification
        const { profileImageUrl } = userThatMadeTheAction
        if (!profileImageUrl) return ''
        return parseUrl(profileImageUrl)
    }, [notification, parseUrl])

    const title = useMemo(() => {
        const { userThatMadeTheAction } = notification
        const { firstname, lastname } = userThatMadeTheAction
        if (!firstname && !lastname) return t('Unknown')
        return `${firstname} ${lastname}`
    }, [notification])

    const dateString = useMemo(() => {
        const a = moment(new Date(), 'M/D/YYYY');
        const b = moment(new Date(notification.dateTime), 'M/D/YYYY');
        const diffDays = b.diff(a, 'days');

        return diffDays >= 1 ? formatDateTime(notification.dateTime) : moment(new Date(notification.dateTime), 'ddd MMM DD YYYY HH:mm:ss GMT Z').fromNow();
    }, [notification])

    const goToUser = (e) => {
        const { userThatMadeTheAction } = notification
        if (!userThatMadeTheAction?.tag) return
        const userTag = userThatMadeTheAction.tag.startsWith("@") ? '' : '@' + userThatMadeTheAction.tag

        close()
        goTo(userTag, dispatch)()
    }

    const markAsReadNotification = useCallback((notification) => {
        const { id } = notification

        notificationService.sawNotification(id, 'web')

        dispatch({
            type: 'USER_SEEN_NOTIFICATION',
            id,
            seenOnDateTime: new Date().toISOString()
        })
    }, [])

    const onPressNotify = useCallback((notification) => {
        const { docId, collection } = notification
        if(isLoading) return
        markAsReadNotification(notification)

        if(notification?.isChatNotification && !!notification?._commentData) {
            const isPrivate = !!notification._commentData.chatUId && !!notification._commentData.sender
            const chatUid = isPrivate ? notification._commentData.chatUId : notification._commentData.docId
            close()
            dispatch(onGoToChatStore())
            if(!chatUid) {
                dispatch(push({ pathname: "chat" }))
                return
            }
        
            dispatch(push(`/chat?chatid=${chatUid}`))
            return
        }

        if (docId) {
            setIsLoading(true)
            queryService.searchById(docId).then((results) => {
                if (results.data.length) {
                    const doc = results.data[0].Value.Source;

                    close()
                    dispatch({type: "BLUR_NOTIFICATIONS_MODAL"})
                    openFullview(doc, docId, true)
                }
                setIsLoading(false)
            })
            mixpanelTrack('Go item from notification drawer', {});
        } else if (collection) {

            dispatch({type: "BLUR_NOTIFICATIONS_MODAL"})
            close()
            goToCollection('/' + collection.title, dispatch, collection.collectionUid, collection.collectionGroup.groupUid)();
            mixpanelTrack('Go group from notification drawer', {});
        }
    }, [openFullview])


    const _onPress = () => {
        onPressNotify(notification)
    }
    const _Close = (e) => {
        e.preventDefault()
        e.stopPropagation()
        close()
    }

    return <div data-intrcm-id="NotificationSnackBarItem_zivm9ht5" className={`${classes.container}`}>
        <div data-intrcm-id="NotificationSnackBarItem_ply4df0a" className={classes.close}>
            <Icon onClick={_Close}>close</Icon>
        </div>
        {!!url ? <div data-intrcm-id="NotificationSnackBarItem_p3ljlyn6" style={{ alignSelf: 'flex-start', cursor: 'pointer' }} onClick={goToUser}><img alt='notfdf' className={classes.avatar} src={url} /></div> : <div data-intrcm-id="NotificationSnackBarItem_7pozr45t" style={classes.avatar} />}
        <div data-intrcm-id="NotificationSnackBarItem_ff19w5f2" onClick={_onPress} className={classes.textsWrap}>
        <NotificationParsedTextNew type={notification?.type ?? 'item'} systemActionTypeUid={notification.systemActionTypeUid} icon={notification.icon} title={title} className={classes.mainText} text={notification.topic} messageTemplateParams={notification.messageTemplateParams} />
            <div data-intrcm-id="NotificationSnackBarItem_mugg7bel" className={classes.firstRow}>
                <div data-intrcm-id="NotificationSnackBarItem_3rje34y6" className={classes.time}>{dateString}</div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        notificationService: state.services.notificationService,
        queryService: state.services.queryService,
        cloudfront: state.data.globalData.cloudfront,
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withOpenFullview,
    withMixpanel,
    withStyles(styles, { withTheme: true }),
    withTranslation('notificationsDrawer'),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationSnackbarItem)