import React, { Component } from 'react'
import { unsupportedByFullviewDatatypes, openFullview } from '@tuqqi/web';
import { connect } from 'react-redux';
import { getAllCollection } from '../ui/views/groups/groupUtils';
import { addRecentItem } from '../actions/searchNavigationActions';

export const withOpenFullview = (WrappedComponent) => {
    class WithOpenFullView extends Component {

        openFullview = (document, docId, openSideMenu = false) => {
            const { dispatch, collaborationService, collections, openCollections } = this.props;

            collaborationService.logClick(docId);

            if (unsupportedByFullviewDatatypes.includes(document.dataType)) {
                dispatch({
                    type: 'FULLVIEW_OPEN_LOAD',
                    item: { ...document },
                    itemId: docId
                })
            } else {
                let collectionUid = ''
                if (document.groupUid) {                    
                    const collection = getAllCollection(collections, openCollections)
                        .find(coll => coll.collectionGroup.groupUid === document.groupUid)
                    if(collection){
                        collectionUid = collection.collectionUid
                    }
                }

                dispatch(openFullview(docId, openSideMenu, collectionUid))
                
            }            
            dispatch(addRecentItem(docId))
        }

        render() {
            return <WrappedComponent {...this.props} openFullview={this.openFullview} />
        }
    }

    const mapStateToProps = state => ({
        collaborationService: state.services.collaborationService,
        openCollections: state.globalData.openCollections,
        collections: state.userData.collections,
    })

    return connect(mapStateToProps)(WithOpenFullView)
}