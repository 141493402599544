import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AgendaSmallIcon } from '../../../../../../Common/Icons/ActionCenter/Widget/AgendaIcon';
import { ToDoSmallIcon } from '../../../../../../Common/Icons/ActionCenter/Widget/ToDoIcon';
import {ConfirmSmallIcon} from '../../../../../../Common/Icons/ActionCenter/Widget/ConfirmIcon';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createCustomTheme } from '../../../../../../../../utils/theming'

const _styles = theme => ({
    container: {
        // marginTop: 8,
        '&:last-child': {
            marginRight: 0
        },
        // marginRight: 8,
        borderRadius: 8,
        // background: '#fff',
        paddingRight: 4,
        paddingLeft: 8,
        cursor: 'pointer',
        height: 'inherit',
        // border: `1px solid ${theme.customColors.primary.main}`,
        display:'inline-grid',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: 90,
        height:30
    
    },
    selectedContainer: {
        background: 'white',
        border: '0.5px solid var(--border-basis-alt-subtle, rgba(183, 184, 189, 0.15))',
        boxShadow: `
        0px 12px 12px -6px rgba(107, 111, 122, 0.06),
        0px 6px 6px -3px rgba(107, 111, 122, 0.06),
        0px 3px 3px -1.5px rgba(107, 111, 122, 0.06),
        0px 1px 1px -0.5px rgba(107, 111, 122, 0.06),
        0px 4px 4px -4px rgba(107, 111, 122, 0.06),
        0px 0px 0px 1px rgba(255, 255, 255, 0.02) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset`,
        zIndex:1,
        display:'inline-grid'

    },
    titleText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: 1,
        whiteSpace: 'nowrap',
        color: 'rgba(0,0,0,0.87)',
        // letterSpacing: 0.4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        // backgroundColor:'rgba(183, 184, 189, 0.1)'
    },
    numText: {
        display: 'inline-table',
        marginLeft: 4,
        paddingLeft:3,
        paddingRight:3,
        paddingTop:0,
        paddingBottom:0,
        textAlign: 'center',
        minWidth: 15,
        whiteSpace: 'nowrap',
        color: 'rgba(0,0,0,0.87)',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '20px',
        backgroundColor:'rgba(183, 184, 189, 0.2)',
        borderRadius:12
    },
    numTextSign: {
        backgroundColor: '#EF4444!important',
        color: '#fff!important',
        boxShadow: "0px -1px 0px 0px #B7B8BD26 inset",
    },
    selectedTitleText: {
        color: 'rgba(0,0,0,0.87)',
        backgroundColor:'rgba(183, 184, 189, 0.2)',

    },
    
    row1: {
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
  
});

const titles = {
    'agenda': 'Agenda',
    'todo': 'To Do',
    'mustRead': 'Sign'
}
function __ControlOption(props) {
    const {selected, view, onPressView, count, classes: styles, brandingColor} = props
    const colors = createCustomTheme(brandingColor);

    const [t] = useTranslation("feedWidgets")
    const isSelected = React.useMemo(() => selected === view, [selected, view])

    const onPress = React.useCallback(() => {
        onPressView(view)
    }, [onPressView, view])

    const renderIcon = () => {
        switch (view) {
            case 'agenda':
                return <AgendaSmallIcon color={isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)'}  />
            case 'todo':
                return <ToDoSmallIcon color={isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)'} />
            case 'mustRead':
                return <ConfirmSmallIcon color={isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)'} />        
            default:
                return null
        }
    }

    return <div data-intrcm-id="ControlOption_elgd0aaw" onClick={onPress} className={`${styles.container} ${isSelected ? styles.selectedContainer : ''}`}>
        <div data-intrcm-id="ControlOption_mhwol48s" className={styles.row1}>
            {/* {renderIcon()} */}
            { <div data-intrcm-id="ControlOption_deeqokhg" className={`${styles.titleText} `} >{!!titles[view] ? t(titles[view]) : t("Untitled")}</div>}
            <div data-intrcm-id="ControlOption_ob49o4t3" className={`${styles.numText} ${view === 'mustRead' && !isSelected ? styles.numTextSign : ""} ${isSelected ? styles.selectedTitleText : ''}`} >{count}</div>
            
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        brandingColor: state.userData.profileData.org.brandingColor,
    }  
}

export const ControlOption = compose(
    withStyles(_styles, {withTheme: true}),
    connect(mapStateToProps),
)(__ControlOption)