import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import React from 'react'
import { Button, Divider, Collapse, Typography, withStyles } from '@material-ui/core';
import Scrollbar from '../../../components/Common/Scrollbar';
import { format } from 'date-fns';
import { hexToRGB } from '../../../../utils/hexToRGB';
import { pxToRem } from '../../../../utils/pxToRem';
import { getFileFullName, getFileThumb } from '../../../../utils/filesUtils';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import parseImagUrl from "../../../components/Common/parseImagUrl";
import { memo } from 'react';
import { isEqualJson } from '../../../../utils/commonHelpers';
import { getFileLinkFromCloundfront, downloadFile } from '../../../../utils/filesUtils.js';
import { useTranslation } from 'react-i18next';

function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        paddingBottom: theme.spacing.unit * 2
    },
    fileItem: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing.unit * 2,
        padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 2.5}px`,
    },
    fileThumb: {
        width: 40,
        height: 40,
        flexShrink: 0,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: hexToRGB('#919EAB', 0.16),
        '& img': { width: '100%', height: '100%' },
        '& svg': { width: 24, height: 24 }
    },
    collapsedButton: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        height: 40,
        borderRadius: 0,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
        justifyContent: 'space-between',
        color: theme.palette.text.disabled,
        flexDirection: 'column', paddingTop: 12
    },
    collapsedContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    collapseIcon: {
        flexDirection: 'column', right: 10, position: 'absolute', color: 'rgba(0, 0, 0, 0.38)'
    },
    list: {
        transition: theme.transitions.create('max-height')
    },
  });

const AttachmentItem = memo(function AttachmentItem({ file, parseImageUrl, classes, onClickAttachment }) {
    return (
      <div data-intrcm-id="ChatRoomAttachment_a20wtnim" onClick={()=>{onClickAttachment(file.fileUid, file.fileName)}} className={classes.fileItem}>
        <div data-intrcm-id="ChatRoomAttachment_7hn0564l" className={classes.fileThumb}>{getFileThumb(file, parseImageUrl)}</div>
        <div data-intrcm-id="ChatRoomAttachment_qnxvc1wh" style={{ marginLeft: 1.5 * 8, maxWidth: 228 }}>
          <Typography variant="body2" noWrap>
            {getFileFullName(file.fileName)}
          </Typography>
          <Typography noWrap variant="caption" style={{ color: 'text.secondary', display: 'block' }}>
            {fDateTime(file.createdAt)}
          </Typography>
        </div>
      </div>
    );
  }, (prev, next) => isEqualJson(prev, next))

const ChatRoomAttachment = memo(function ChatRoomAttachment({
    openSidebar,
    cloudfront,
    attachments,
    classes,
    isCollapse,
    onCollapse,
  }) {
    const [t] = useTranslation("chat")
    const parseImageUrl = (url) => {
      return parseImagUrl(cloudfront, url)
    }
    const onClickAttachment = (fileUid, fileName) => {
        const fullUrl = getFileLinkFromCloundfront(cloudfront, fileUid)
        downloadFile(fullUrl, fileName)
    }
  
    return (
      <div data-intrcm-id="ChatRoomAttachment_2gz9l0m0" className={classes.root}>
          <div data-intrcm-id="ChatRoomAttachment_5bxx2pb5" className={classes.collapsedContainer}>
              <Button
                  className={classes.collapsedButton}
                  fullWidth
                  color="inherit"
                  onClick={onCollapse}
              >
                  {t('attachment')}
              </Button>
              {openSidebar &&
                      <Icon
                          className={classes.collapseIcon}
                          icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
                          width={16}
                          height={16}
                      />
                  }
          </div>
  
        {!isCollapse && <Divider />}
        <div data-intrcm-id="ChatRoomAttachment_vh0tyf8z" className={classes.list} style={{maxHeight: isCollapse ? 'calc(100% - 30px)' : 0}}>
            <Scrollbar>
            <Collapse in={isCollapse} timeout="auto">
                {attachments.map((file, idx) => (
                    <AttachmentItem parseImageUrl={parseImageUrl} onClickAttachment={onClickAttachment} classes={classes} key={file.fileUid + idx} file={file} />
                ))}
            </Collapse>
            </Scrollbar>
        </div>
      </div>
    );
  }, (prev, next) => isEqualJson(prev, next))

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

export default compose(
    withStyles(styles, { withTheme: true }), 
    connect(
        mapStateToProps,
        null
    )
)(ChatRoomAttachment);