import React, { PureComponent } from 'react';

export const QuickReplyIcon = class QuickReplyIcon extends PureComponent {

    render() {
        const { color, className } = this.props
        return (
            <svg className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.2274 0.499846C8.03631 0.505697 7.85467 0.584292 7.71958 0.719572L0.719582 7.71957C0.578983 7.86023 0.5 8.05097 0.5 8.24985C0.5 8.44872 0.578983 8.63946 0.719582 8.78012L7.71958 15.7801C7.82449 15.8849 7.95811 15.9563 8.10357 15.9852C8.24903 16.0141 8.39979 15.9993 8.53681 15.9425C8.67382 15.8858 8.79094 15.7897 8.87336 15.6664C8.95578 15.5431 8.9998 15.3981 8.99986 15.2498V11.4276C10.0937 11.3698 11.7884 11.37 13.5194 11.9393C15.5703 12.6138 17.4208 13.9292 18.0145 16.8973C18.0513 17.0796 18.1544 17.2417 18.304 17.3523C18.4535 17.4628 18.6388 17.5138 18.8239 17.4955C19.0089 17.4771 19.1806 17.3906 19.3055 17.2528C19.4304 17.1151 19.4997 16.9358 19.4999 16.7498C19.4999 16.6954 19.4838 16.6563 19.4833 16.6024H19.4852C19.4845 16.5986 19.482 16.5964 19.4813 16.5926C19.4298 11.6852 17.2098 8.71338 14.7127 7.09653C12.5131 5.67225 10.3285 5.29076 8.99986 5.14242V1.24985C8.99989 1.14944 8.97977 1.05005 8.94067 0.957561C8.90158 0.865076 8.84432 0.781381 8.77228 0.711437C8.70024 0.641494 8.6149 0.586726 8.5213 0.550379C8.4277 0.514033 8.32776 0.496849 8.2274 0.499846ZM7.49986 3.06039V5.77328C7.49984 5.96257 7.5714 6.14487 7.70018 6.28361C7.82895 6.42235 8.00542 6.50726 8.19419 6.52133C9.19649 6.59534 11.7103 6.93925 13.8973 8.35531C15.2269 9.2162 16.3966 10.521 17.1561 12.3778C16.2071 11.497 15.1015 10.8807 13.9881 10.5145C11.605 9.73067 9.22152 9.8476 8.17954 9.94516C7.99357 9.96269 7.82083 10.049 7.69512 10.1872C7.56941 10.3253 7.49978 10.5054 7.49986 10.6922V13.4393L2.3104 8.24985L7.49986 3.06039Z" fill={color} />
            </svg>
        )
    }
}