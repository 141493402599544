import { Menu } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import withErrorCatcher from '../../../../../../components/Common/withErrorCatcher';
import { compose } from 'recompose';
import FastCreationContent from './FastCreationContent';

const KANBAN_CARD_WIDTH = 224
const styles = {
    icon: {
        transform: 'rotate(90deg)',
        marginLeft: 8,
        cursor: 'pointer',
    },
    menu: {
        marginTop: 8,
        padding: 12,
        width: KANBAN_CARD_WIDTH,
        maxWidth: KANBAN_CARD_WIDTH,
        backgroundColor: '#FFFFFF',
        marginBottom: 8,
        boxSizing: 'border-box',
    },
    menuTop18: {
        marginTop: 18,
    },
    menuTop38: {
        marginTop: 38,
    },
    icons: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cancelIcon: {
        marginRight: 'auto'
    },
    createIcon: {
        marginRight: 8
    },
    icon: {
        cursor: 'pointer'
    },
    input: {
        width: '100%',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.24,
        color: '#0C0C0D',
        padding: 0,
        outline: 0,
        minHeight: 20,
        border: 0,
        maxWidth: KANBAN_CARD_WIDTH - 24,
        resize: 'none',
    },
}

const useStyles = makeStyles(styles)

export const FastCreationMenu1 = (props) => {
    const classes = useStyles()
    const { onCancel, onOpenFullview, onCreate, id, show, onClose } = props
    const [anchor, setAnchor] = useState(null)
    const [placeholderHeight, setPlaceholderHeight] = useState(20)

    useEffect(() => {
        const el = window.document.getElementById(id)
        if(!el) return

        setAnchor(el)
    }, [id])


    const classNameTop = useMemo(() => {
        const count = placeholderHeight / 20
        if([1, 2].includes(count)) return ''
        if([3].includes(count)) return classes.menuTop18
        if([4].includes(count)) return classes.menuTop38
        return classes.menuTop28
    }, [placeholderHeight])

    if(!show) return null

    return <><div data-intrcm-id="FastCreationMenu1_ukumoozc">
        <Menu 
            classes={{ paper: `${classes.menu} ${classNameTop}` }}
            anchorEl={anchor}
            open={!!anchor}
            onClose={onClose}
            elevation={8} >
                {anchor ? 
                <FastCreationContent setPlaceholderHeight={setPlaceholderHeight} onCreate={onCreate} onOpenFullview={onOpenFullview} onCancel={onCancel} /> : null}
        </Menu>
    </div>
       </> 
}

export default compose(withErrorCatcher())(FastCreationMenu1);