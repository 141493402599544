import { withStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { sortItemsByDate, sortItemsByLaneColor, sortItemsByRating } from '../../../../../utils/actionsCenter/helpers'
import goToCollection from '../../../../actions/goToCollection'
import { getAllCollection } from '../../../groups/groupUtils'
import { sortOngoingLane } from '../userOngoingUtils'
import OngoingCard from './OngoingCard'

const styles = (theme) => ({
    container: {
        width: 264,
        minWidth: 264,
        height: 'fit-content',
        marginRight: 30,
        marginLeft: 5,
        '&:last-child': {
            marginRight: 0
        }
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#21212C'
        },
        fontFamily: 'Rubik',
        fontWeight: '500',
        fontSize: 16,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212C',
    },
    itemsCount: {
        padding: '4px 12px',
        fontFamily: 'Rubik',
        fontWeight: '500',
        fontSize: 11,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212C',
    },
    itemsCountForm: {
        borderRadius: 15,
        backgroundColor: '#EEEEF3',
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20
    },
   
})
const OngoingLane = (props) => {
    const {classes, task, userCollections, openCollections, dispatch, sortBy, mappings} = props
    const { collectionTitle, items, collectionUid, groupUid } = task
    
    const onClickTitle = () => {
        const collection = getAllCollection(openCollections, userCollections).find(col => col.collectionUid === collectionUid)
        goToCollection('/' + collectionTitle, dispatch, collectionUid, groupUid, collection)();
    }

    const Header = (
        <div data-intrcm-id="OngoingLane_x2aytak5" className={classes.header}>
            <div data-intrcm-id="OngoingLane_26bg87rk" onClick={onClickTitle} className={classes.title}>{collectionTitle}</div>
            <div data-intrcm-id="OngoingLane_absc6qho" className={classes.itemsCountForm}>
                <div data-intrcm-id="OngoingLane_d5wny18l" className={classes.itemsCount}>
                    {!!items.length ? items.length : ''}
                </div>
            </div>
        </div>
    )

    const isEnabledRating = useCallback((dataType) => {
        const mapping = mappings.find(x => x.customDataMapping.dataTypeFilter === dataType)
        if(!mapping || !Object.keys(mapping).length) return false
        return !!mapping.isEnableRating
    }, [mappings])

    const sorted = useMemo(() => {
        return sortOngoingLane(items, sortBy, { isEnabledRating })
    }, [items, sortBy, isEnabledRating])
    
    const Cards = sorted.map(doc => {
        return (
        <OngoingCard key={doc.docId + sortBy} card={{
            doc,
            id: doc.docId,
            order: 0,
            title: doc.title,
        }} />
        )}
    )
    return (
        <div data-intrcm-id="OngoingLane_2w41zga4" className={classes.container}>
            {Header}
            {Cards}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    mappings: state.globalData.inputMappings,
    openCollections: state.globalData.openCollections,
    userCollections: state.userData.collections,
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true}),
)(OngoingLane)