import React, { Fragment, PureComponent, Component } from 'react';

import withErrorCatcher from './withErrorCatcher.js'

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import { withTranslation } from 'react-i18next';
registerPlugin(FilePondPluginImagePreview)



const styles = theme => ({
    imageLabel: {
        "display": "block",
        "color": "rgba(0, 0, 0, 0.54)",
        "padding": "0",
        "fontSize": "1rem",
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "lineHeight": "1",
        "transform": "translate(0, 1.5px) scale(0.75)",
        "transformOrigin": "top left",
        "margin": "6px 0 3px"
    }
})

class UploadImag extends Component {

    onLoadImg = (res) => {
        const file = JSON.parse(res);
        this.props.onLoadImg(file.link)
    }

    render() {
        const { classes, withTitle, imageUrl, idToken,t } = this.props

        return (
            <Fragment>
                {
                    withTitle ? <p className={classes.imageLabel}>{t('Image')}</p> : null
                }
                <FilePond allowMultiple='false'
                    files={imageUrl ? [{ source: imageUrl.substring(imageUrl.indexOf("=") + 1), options: { type: 'local' } }] : []}
                    server={{
                        url: process.env.API_SERVER + '/files/s3',
                        revert: (uniqueFileId, load, error) => {
                        },
                        restore: null,
                        load: {
                            url: '/?fileId=',
                            headers: { 'Authorization': 'Bearer ' + idToken }
                        },
                        fetch: null,
                        process: {
                            headers: { 'Authorization': 'Bearer ' + idToken },
                            onload: this.onLoadImg
                        }
                    }}
                />  
                  </Fragment>)
    }
}




const mapStateToProps = (state) => ({
    idToken: state.userData.idToken
})



export default compose(withStyles(styles),
    connect(mapStateToProps),
    withTranslation('admin'),
)(UploadImag);
