import React  from 'react';

const JoinGroupIcon = (props) => {
        const { color } = props

        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8V12M4 8H8H4ZM12 8H8H12ZM8 8V4V8Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
}

export default JoinGroupIcon