import { DateHelper, DatePicker, Override } from '@bryntum/gantt';
import { CheckEditDate, allowChangeProjectDate, extractScheduleFromRecord } from "../../utils/checkChangeDate"

class DatePickerOverride {
    static get target() {
        return {
            class      : DatePicker,
            product    : 'grid',
            minVersion : '1.0',
            maxVersion : '5.0.7'
        };
    }

    onUIDateSelect(date, event) {
        const
        me                             = this,
        { lastClickedDate, selection } = me;

        me.lastClickedDate = date;
        if (!!me.isDisabledDate(date)) return
        if(!allowChangeProjectDate(date)) {
            if(!!me?.owner?.eventRecord) {
                const scheduleInfo = {}
                const schedule = extractScheduleFromRecord(me.owner.eventRecord)
                if(me.owner.name === 'startDate') {
                    schedule.dateTimeFrom = date
                } else {
                    schedule.dateTimeFrom = date
                    schedule.dateTimeTo = date
                }
                scheduleInfo.scheduleUpdatePayload = schedule
                scheduleInfo.id = me.owner.eventRecord.id
                CheckEditDate.scheduleInfo = scheduleInfo
            }
            if(me.floating) me.hide();
            me.activatingEvent = null;
            CheckEditDate.onShowConfirm()
            return
        }

        me.activatingEvent = event;

        // Handle multi selecting.
        // * single contiguous date range, eg: an event start and end
        // * multiple discontiguous ranges
        if (me.multiSelect) {
            if (me.multiRange) {
                // TODO: multiple date ranges
            }
            else if (!lastClickedDate || !DateHelper.isSameDate(date, lastClickedDate)) {
                if (lastClickedDate && event.shiftKey) {
                    selection[1] = date;
                    selection.sort();
                }
                else {
                    selection.length = 0;
                    selection[0] = date;
                }

                me.trigger('selectionChange', {
                    selection,
                    userAction : Boolean(event)
                });
            }
        }
        else {
            if (!me.value || me.value.getTime() !== date.getTime()) {
                me.value = date;
            }
            else if (me.floating) {
                me.hide();
            }
        }

        me.activatingEvent = null;
    }
}

Override.apply(DatePickerOverride);
