import Menu from '@material-ui/core/Menu';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { confirmLoading, hideConfirm, showConfirm, showSnackbar } from '../../../actions/appActions';
import { getGroupByItem } from '../Common/Utils/userPermissionsUtils';
import withErrorCatcher from '../Common/withErrorCatcher';
import GroupSelectorMenuItem from './GroupSelectorMenuItem';



class GroupSelector extends React.Component {

    close = () => {
        const { onClose, closeParent } = this.props
        onClose()
        if (closeParent) {
            closeParent()
        }
    }
    getMembersUid = () => {
        return this.props.item.members.map(x => x.userUUID)
    }

    handlePrivate = isPrivate => () => {
        const { dispatch, inputService, docId } = this.props
        const confirmMessage = `You Are About To Set The Item To Be "${isPrivate ? 'Private' : 'Public'}", Are you sure?`

        this.close()

        dispatch(showConfirm(confirmMessage, () => {
            dispatch(confirmLoading())
            dispatch(showSnackbar("Updating..."))
            inputService.updateItemPermission(docId, isPrivate, this.getMembersUid(), false, '')
                .then(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Updated Successfully"))
                }).catch(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Failed Updating"))
                })
        }, false))
    }

    handleLimitToMembers = () => {
        const { dispatch, inputService, docId, item } = this.props
        const confirmMessage = `You Are About To Set The Item To Be Limited To Members Only, Are you sure?`

        this.close()

        dispatch(showConfirm(confirmMessage, () => {
            dispatch(confirmLoading())
            dispatch(showSnackbar("Updating..."))
            inputService.updateItemPermission(docId, true, this.getMembersUid(), true, '')
                .then(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Updated Successfully"))
                }).catch(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Failed Updating"))
                })
        }, false))
    }

    handleCollection = (groupUid, groupDisplayName) => () => {
        const { dispatch, inputService, docId } = this.props

        this.close()

        dispatch(showConfirm(`You Are About To Move This Item To The Group "${groupDisplayName}", Are you sure?`, () => {
            dispatch(confirmLoading())
            dispatch(showSnackbar("Updating..."))
            inputService.updateItemPermission(docId, false, this.getMembersUid(), false, groupUid)
                .then(x => {
                    dispatch({ type: 'DATA_UPDATE' });

                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Updated Successfully"))
                }).catch(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Failed Updating"))
                })
        }, false))
    }

    render() {
        const {
            dialogClass,
            anchorEl,
            open,
            onClose,
            item,
            t,
            collections
        } = this.props

        const selectedGroup = getGroupByItem(item, collections)

        const handlePrivate = this.props.handlePrivate || this.handlePrivate
        const handleCollection = this.props.handleCollection || this.handleCollection
        const handleLimitToMembers = this.props.handleLimitToMembers || this.handleLimitToMembers

        const canBeSetToLimited = item && item.members && item.members.length >= 2
        const isPublic = !selectedGroup && !item.isPrivate && !item.isLimitedToMembers

        return <Menu anchorEl={anchorEl} open={open} onClose={onClose} className={dialogClass} disableAutoFocusItem>
            <GroupSelectorMenuItem
                key='public'
                dataType={item.dataType}
                permission='public'
                forceDisabled
                disableIfDataTypeIsNotPermitted
                onClick={handlePrivate(false)}
                isSelected={isPublic}
                title={t('Public')}
            />


            {canBeSetToLimited &&
                <GroupSelectorMenuItem
                    key='Limited To Members'
                    onClick={handleLimitToMembers}
                    isSelected={item.isLimitedToMembers}
                    title={t('Limited To Members')}
                />}

            <GroupSelectorMenuItem
                key='private'
                onClick={handlePrivate(true)}
                isSelected={item.isPrivate}
                title={t('Private')}
            />
            {
                collections.map((collection) => (
                    <GroupSelectorMenuItem
                        dataType={item.dataType}
                        permission={collection.collectionGroup.groupUid}
                        disableIfDataTypeIsNotPermitted
                        forceDisabled
                        key={collection.collectionUid}
                        value={collection.collectionUid}
                        onClick={handleCollection(collection.collectionGroup.groupUid, collection.title)}
                        isSelected={
                            selectedGroup &&
                            !item.isPrivate &&
                            collection.collectionGroup.groupUid === selectedGroup.groupUid}
                        title={collection.title}
                    />))
            }
        </Menu>

    }
}

const mapStateToProps = (state) => ({
    inputService: state.services.inputService,
    collections: state.userData.collections
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withTranslation('itemFullViewModal'),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(GroupSelector);