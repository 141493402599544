import { Grid, withStyles } from '@material-ui/core';
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import classnames from "classnames";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { changeCollectionInputInfo, openInput } from '../../../../actions/inputActions';
import { FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN } from '../../../../actionTypes/actionTypes';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { shouldAutoOpen } from '../groupUtils';
import { openInput as openNewUiInput } from '@tuqqi/web'

const styles = theme => ({
    container: {
        height: 53,
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
        padding: '8px 0px 8px 32px',
    },
    textContainer: {
        width: 'fit-content',
    },
    clickable: {
        cursor: 'pointer'
    },
    addNew: {
        fontFamily: "Rubik",
        fontSize: 14,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: 0.4,
        color: theme.customColors.primary.main,
    }
})

class TableCellAddNew extends Component {

    openNewItem = () => {
        const { dispatch, groupUid, groupInfo, inputMappings, oldUiInput, userData, searchQuery, changeCollectionInputInfo } = this.props
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = ""

        if (shouldAutoOpen(groupInfo)) {
            const dataType = groupInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }

        changeCollectionInputInfo({ kanbanListId })
    }

    render() {
        const { classes, t } = this.props;

        return (
            <td onClick={this.openNewItem} className={classnames(classes.container)} >
                <Grid container alignItems='center' item dir='auto' className={classnames(classes.textContainer, classes.clickable, classes.addNew)}>
                    {`+ ${t('Add new')}`}
                </Grid>
            </td>
        )
    }

}


const mapStateToProps = (state) => ({
    groupUid: state.results.channel.collectionGroup &&
        state.results.channel.collectionGroup.groupUid,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    searchQuery: state.search,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
})

export default compose(withStyles(styles),
    withOpenFullview,
    withErrorCatcher(),
    withTranslation("footerTableCellTranslate"),
    connect(mapStateToProps, mapDispatchToProps))(TableCellAddNew);