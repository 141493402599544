import React, { PureComponent } from 'react';

export default class SearchAllIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="4" y="7" width="13" height="13" rx="2" stroke={color} stroke-width="1.5"/>
                <path d="M17 18H18C19.1046 18 20 17.1046 20 16V6C20 4.89543 19.1046 4 18 4H9C7.89543 4 7 4.89543 7 6V7" stroke={color} stroke-width="1.5"/>
                <path d="M7 12L9.33333 10.6667L10.5 11.3333L12.8333 10L14 10.6667" stroke={color}/>
                <rect x="7" y="14" width="7" height="1" fill={color}/>
                <rect x="7" y="16" width="4" height="1" fill={color}/>
            </svg>
        )
    }
}