


import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment';


const styles = theme => ({
    grayDot: {
        width: '3.2px',
        height: '3.2px',
        backgroundColor: '#b2b2b2',
        borderRadius: '50%',
        position: 'relative',
        marginLeft: 5,
        marginRight: 3,
        display: 'inline-block',
        top: -3
    }
})

class DateFrom extends Component {

    render() {
        const { classes, dateTime } = this.props;

        return (
            <Fragment>
                {/* <span className={classes.grayDot}></span> */}
                <span>{moment(dateTime).fromNow()}</span>
                {/* <span className={classes.grayDot}></span> */}
            </Fragment>)
    }
}

export default compose(withStyles(styles))(DateFrom);