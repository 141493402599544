import React, { useState, useEffect } from "react";
import { withStyles, LinearProgress } from "@material-ui/core";
import { goToStory } from "@tuqqi/common";
import { compose } from "recompose";

const styles = (theme) => ({
  container: {
    flex: "1 0",
    marginRight: 5,
    width: "auto",
    height: 3,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, .4)",
    borderRadius: 4,
    "&:last-child": {
      marginRight: 0,
    },
  },
  barroot: {
    backgroundColor: "#fff",
  },
});

const SceneIndicator = (props) => {
  const { classes, scene, currentScene, dispatch } = props;
  const [progress, setProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const handlerStoriesClick = () => {
    dispatch(goToStory(scene.id));
  };

  const setValForSeen = () => {
    if (scene.id !== currentScene.id && progress > 0) {
      setProgress(100);
    }
  };

  const setValForCurrent = () => {
    if (scene.seen && scene.id === currentScene.id) {
      setProgress(0);
    }
  };

  useEffect(() => {
    if (scene.id === currentScene.id) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    setValForSeen();
    setValForCurrent();
  }, [scene, currentScene]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isActive) {
        setProgress((oldProgress) => {
          const diff = 1.15;
          if (oldProgress === 100) {
            return oldProgress;
          }
          return oldProgress + diff;
        });
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [isActive]);

  const getValue = (scene, currentScene, progress) => {
    if (scene.seen && scene.id !== currentScene.id) {
      return 100;
    } else {
      return progress;
    }
  };

  return (
    <LinearProgress
      variant="determinate"
      value={getValue(scene, currentScene, progress)}
      className={`${classes.container}`}
      onClick={handlerStoriesClick}
      classes={{ barColorPrimary: classes.barroot }}
    />
  );
};

export default compose(withStyles(styles))(SceneIndicator);
