import React from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { closeSubscriptionModal } from '../../../../../../actions/upgradeSubscriptionActions';
import YouNeedToUpdateSubscriptionModal from './YouNeedToUpdateSubscriptionModal'

function YouNeedToUpdateSubscriptionWrapper(props){
    const { upgradeSubscription, subscription, closeSubscriptionModal, dispatch } = props;
    const { open, title, description, features, onClose, allowClose } = upgradeSubscription;

    const close = () => {
        closeSubscriptionModal();
        onClose && onClose();
    }
    const home = () => {
        dispatch(push('/feed'))
        closeSubscriptionModal();
        onClose && onClose();
    }
    if(!open){
        return null;
    }
    return (
        <YouNeedToUpdateSubscriptionModal
            open={open}
            title={title}
            description={description}
            features={features}
            onClose={close}
            onHome={home}
            allowClose={allowClose}
            subscription={subscription}
        />
    )
}

const mapStateToProps = (state) => ({
    upgradeSubscription: state.upgradeSubscription,
    subscription: state.globalData.currentSubscription,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    closeSubscriptionModal: () => dispatch(closeSubscriptionModal())
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(YouNeedToUpdateSubscriptionWrapper); 