import { Paper, Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, PureComponent } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Spinner from '../../Common/spinner';
import withErrorCatcher from '../../Common/withErrorCatcher';
import NoResultsView from '../../Common/NoResultsView';

// Apply some reset
const styles = theme => ({
    paper: {
        // padding: 16,
        width: '100%',
        color: theme.palette.text.secondary,
    },
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center',
        position: 'initial'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
});

class ResultsList extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }


    showWebResults() {
        return !location.pathname.includes('group')
    }


    render() {
        const { classes, isLoading, loadMore, results, hasMore, isSearch, isUniqeSearch, showInput = false, Header, renderItem } = this.props;



        let content = null;
        if (isLoading) {
            content = <Fragment>{Header}
                <Spinner classes={classes} />
            </Fragment>
        } else if(!isLoading && !results &&  !results.length) {
            content = <Fragment>
                {Header}
                <NoResultsView/>
            </Fragment>
        }
        else {
            content = <Fragment>
                {Header}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    getScrollParent={()=> document.getElementById('main_feed_scroll')} 
                    useWindow={false}
                    hasMore={hasMore}
                    loader={<Spinner classes={classes} />}
                >

                    {results.map((result, idx) => renderItem(idx, result, result.Key))}

                </InfiniteScroll></Fragment>
        }

        return <div data-intrcm-id="ResultsList_ieqyfaoo" className={classes.paper}>
            <Grid
                style={{ flexWrap: 'nowrap' }}
                container
                direction="column"
            >

                {content}

            </Grid>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    router: state.router,
    ...state.services,
    oldUiData: state.oldUiData

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ResultsList);

