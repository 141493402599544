import levenshtein from "levenshtein-edit-distance";

/**
 * Initialize suggestions .
 * 
 * Params: arr:  Array<{input: Array<string>}>
 * input is thhe text that can user enter in order to get the suggest
 */
function initSuggestions(arr, valueForSuggest, size = 10) {

  const suggestions = arr.map(suggestion => ({
    ...suggestion,
    distance: calcMinDistance(valueForSuggest, suggestion.input)
  }))
    // .filter(suggestion => suggestion.distance < minDistance)
    .sort((a, b) => a.distance - b.distance)
    .slice(0, size)
  return suggestions
}

function getGroupSuggestions(arr, valueForSuggest, size = 10) {

  const suggestions = arr.map(suggestion => ({
    ...suggestion,
    distance: calcMinDistance(valueForSuggest, [  suggestion.title ])
  }))
    // .filter(suggestion => suggestion.distance < minDistance)
    .sort((a, b) => a.distance - b.distance)
    .slice(0, size)
  return suggestions
}


function calcMinDistance(valueForSuggest, input) {
  const distances = input.map(value => calcDistance(valueForSuggest, value))
  const min = Math.min(...distances)

  return min
}

function calcDistance(valueForSuggest, input) {
  const levenshteinDistance = levenshtein(input?.toLowerCase?.() ?? '', valueForSuggest?.toLowerCase?.() ?? '')

  // Normal the ditance by dividing on the length 
  // so if the word is match but have long distance
  // the distance will less because the long of thhe divider
  const normalDistance = levenshteinDistance / Math.max(input.length, valueForSuggest.length)

  return normalDistance
}

export { initSuggestions, calcDistance, getGroupSuggestions }