import { mapToCustomTemplate } from '../../components/Mappings/resultMappings.js';
import parseImgeUrl from '../../components/Common/parseImagUrl';
import { getDataType } from '../../components/Common/Utils/defaultDataTypes.js';
import { SERVER_API } from '../../../utils/serverAPI.js';
import { getChatCommentsForDocument, getChatMessages, getSavedMessages, isDiscussions, postChatMessage, postComment, postSavedMessage, updateDataResult, updateLastUserEnterChatDiscussions, updateLastUserEnterChatDM, updateSavedRoom } from '@tuqqi/common';
import { getNotificationData } from './chatNotificationsUtils.js';
import { randomID } from '../../../utils/randomID.js';
import { CHAT_GROUP_INFO } from './chatRecognize.js';

export const DirectMessages = "DirectMessages";
export const GroupMessages = "GroupMessages";
export const Discussions = "Discussions";
export const SavedMessages = "SavedMessages";


// Disscusions
export let loadComments = function (docId) {
    return function () {
        this.props.dispatch(getChatCommentsForDocument(SERVER_API)(docId, 0));
    }.bind(this)
}
export let loadSavedMessages = function (userId) {
    return function () {
        this.props.dispatch(getSavedMessages(SERVER_API)(userId, 0));
    }.bind(this)
}

export let loadMoreComments = function () {
    if (this.state.isLoadingMore) return;
    const {selected: docId} = this.state
    
    const commentsPage = this.props.commentsState[docId] ? this.props.commentsState[docId].commentsPage : 0;
    const nextPage = commentsPage + 1;

    this.props.dispatch(getChatCommentsForDocument(SERVER_API)(docId, nextPage));
}

export let mapComments = function (comments, users, cloudfront) {
    return comments.sort((resA, resB) => new Date(resA.item.dateTime) - new Date(resB.item.dateTime)).map((comment, idx) => {
        const { text, dateTime, userId } = comment.item;

        let commentData = chatCommentData(idx, text, userId, dateTime, cloudfront, comment.item, users, {messageType: 0, isSavedMessage: false})
        if (commentData) {
            return commentData
        }

    })
}

// DM
export let loadMessages = function (chatUId) {
    return function () {
        this.props.dispatch(getChatMessages(SERVER_API)(chatUId, 0));
    }.bind(this)
}

// GM
export let loadGroupMessages = function (chatUId) {
    return function () {
        this.props.dispatch(getChatMessages(SERVER_API)(chatUId, 0, {chatType: 2}));
    }.bind(this)
}

export const loadMoreMessages = function () {
    if (this.state.isLoadingMore) return;
    const {selected: chatUId} = this.state

    const messagesPage = this.props.messagesState[chatUId] ? this.props.messagesState[chatUId].messagesPage : 0;
    const nextPage = messagesPage + 1;

    this.props.dispatch(getChatMessages(SERVER_API)(this.state.selected, nextPage));
}

export const loadMoreGroupMessages = function () {
    if (this.state.isLoadingMore) return;
    const {selected: chatUId} = this.state

    const messagesPage = this.props.messagesState[chatUId] ? this.props.messagesState[chatUId].messagesPage : 0;
    const nextPage = messagesPage + 1;

    this.props.dispatch(getChatMessages(SERVER_API)(this.state.selected, nextPage, {chatType: 2}));
}

export let mapMessages = function (comments, users, cloudfront) {
    return comments.sort((resA, resB) => new Date(resA.item.creationDateTime) - new Date(resB.item.creationDateTime)).map((comment, idx) => {
        const { messageText, creationDateTime, sender } = comment.item;

        let commentData = chatCommentData(idx, messageText, sender.auth0_id, creationDateTime, cloudfront, comment.item, users, {messageType: 1, isSavedMessage: false})
        if (commentData) {
            return commentData
        }
    })
}

export let mapSavedMessages = function (comments, users, cloudfront) {
    return comments.sort((resA, resB) => new Date(resA.item.dateTime) - new Date(resB.item.dateTime)).map((el, idx) => {
        const { messageType, comment, message } = el.item;
        if(messageType === 0) {
            const { text, dateTime, userId } = comment;
            let commentData = chatCommentData(idx, text, userId, dateTime, cloudfront, comment, users, {messageType, isSavedMessage: true})
            if (commentData) {
                return commentData
            }
        }
        const { messageText, creationDateTime, sender} = message || {messageText: '', sender: {auth0_id: ''}, creationDateTime: ''};
        let commentData = chatCommentData(idx, messageText, sender.auth0_id, creationDateTime, cloudfront, message || {type: null, id: 0, attachments: []}, users, {messageType, isSavedMessage: true})
        if (commentData) {
            return commentData
        }
    })
}

///// ADD Comment

function isLink(text) {
    const currPageLink = `${location.host}/search\\?searchquery=%23`
    const reg = new RegExp(`^\\s*(http(s)?://)?${currPageLink}[^\\s]*\\s*$`, 'i')

    return reg.test(text)
}

function getLinkText(text) {
    const splited = text.split('/search?searchquery=%23') || []

    return splited[1]
}
const mapNewInstantSavedMessage = ({text, profileData, tempId, attachments}) => ({
        item: {
            comment: null,
            dateTime: new Date(),
            messageType: 2,
            message: {
                messageText: text,
                id: tempId,
                chatUId: profileData.auth0_id,
                tempId,
                attachments: attachments || [],
                creationDateTime: new Date(),
                lastModification: new Date(),
                sender: profileData
            }
        },
        isDeleting: false
})
const mapNewInstantMessage = ({text, profileData, tempId, attachments}) => ({
        item: {
            messageText: text,
            id: tempId,
            chatUId: profileData.auth0_id,
            tempId,
            attachments: attachments || [],
            creationDateTime: new Date(),
            lastModification: new Date(),
            sender: profileData
        },
        isDeleting: false
})
const mapNewInstantGroupMessage = ({text, colUid, profileData, tempId, attachments}) => ({
        item: {
            messageText: text,
            id: tempId,
            chatUId: colUid,
            tempId,
            attachments: attachments || [],
            creationDateTime: new Date(),
            lastModification: new Date(),
            sender: profileData
        },
        isDeleting: false
})
const mapNewInstantComment = ({text, profileData, tempId, attachments, document}) => ({
        item: {
            attachments: attachments || [],
            text: text,
            commentId: tempId,
            dateTime: new Date(),
            docId: document.docId,
            docTitle: document.docTitle,
            tags: [profileData.tag],
            userDisplayName: `${profileData.firstname.trim()} ${profileData.lastname.trim()}`,
            userId: profileData.auth0_id,
            userPicUrl: profileData.profileImageUrl,
            userProfile: null,
            userTag: profileData.tag,
            tempId,
        },
        isDeleting: false
})

export let addComment = function (docId, title, onLinkAdd, currDoc, text, attachments) {
    const { collaborationService, userData, dispatch, chatService } = this.props;
    const { firstname, lastname, profileImageUrl, auth0_id } = userData.profileData;
    const {items, document} = this.state;

    var comment = {
        docId,
        docTitle: title,
        text,
        userDisplayName: firstname + ' ' + lastname,
        userPicUrl: profileImageUrl,
        dateTime: (new Date()).toISOString(),
        userId: auth0_id,
        attachments
        // type: type
    }

    if (isLink(comment.text)) {
        const link = {
            docId,
            link: getLinkText(comment.text)
        }

        return collaborationService.addLink(link).then(newDocument => {
            const newDoc = { ...currDoc }
            const oldRelated = newDoc.relatedDocuments || []

            const newLink = newDocument.relatedDocuments.find(x => x.relatedDocumentId === link.link)
            newDoc.relatedDocuments = oldRelated.concat(newLink)
            dispatch({ type: 'FULLVIEW_CHANGE', item: newDoc })

            onLinkAdd()
        })
    } else {
        const tempId = randomID()
        const newMessage = mapNewInstantComment({document, text, profileData: userData.profileData, tempId, attachments})
    
        this.newInstantMessageRef.current = {needReplace: true, tempId}
        this.setState({items: [...items, newMessage]}, () => this.scrollToLatestMessage())
    
        setTimeout(() => this.props.dispatch(postComment(SERVER_API)(this.state.selected, text, attachments || [], tempId)), 100)
    }
}

export let addMessage = function (chatUId, title, onLinkAdd, currDoc, text, attachments) {
    const { userData, dispatch } = this.props;
    const {items} = this.state;
    const tempId = randomID()

    const newMessage = mapNewInstantMessage({text, profileData: userData.profileData, tempId, attachments})
    this.newInstantMessageRef.current = {needReplace: true, tempId}
    this.setState({items: [...items, newMessage]}, () => this.scrollToLatestMessage())

    setTimeout(() => dispatch(postChatMessage(SERVER_API)(this.state.selected, text, userData.profileData, attachments || [], tempId)), 100)
}

export let addGroupMessage = function (chatUId, title, onLinkAdd, currDoc, text, attachments) {
    const { userData, dispatch } = this.props;
    const {items} = this.state;
    const tempId = randomID()

    const newMessage = mapNewInstantGroupMessage({text, colUid: CHAT_GROUP_INFO.collectionUid, profileData: userData.profileData, tempId, attachments})
    this.newInstantMessageRef.current = {needReplace: true, tempId}
    this.setState({items: [...items, newMessage]}, () => this.scrollToLatestMessage())

    setTimeout(() => dispatch(postChatMessage(SERVER_API)(this.state.selected, text, userData.profileData, attachments || [], tempId, {isGroupChat: true})), 100)
}

export let addSavedMessage = function (chatUId, title, onLinkAdd, currDoc, text, attachments) {
    const {dispatch, userData} = this.props;
    const {items} = this.state;
    const tempId = randomID()

    const newMessage = mapNewInstantSavedMessage({text, profileData: userData.profileData, tempId, attachments})
    this.newInstantMessageRef.current = {needReplace: true, tempId}
    this.setState({items: [...items, newMessage]}, () => this.scrollToLatestMessage())

    setTimeout(() => dispatch(postSavedMessage(SERVER_API)(this.state.selected, text, userData.profileData, attachments || [], tempId)), 100)

    const room = {
        commentText: text,
        commentTime: new Date().toDateString(),
    }
    this.props.dispatch(updateSavedRoom(room))
}

// GENERAL
export let getChatFunctions = function (result,t) {
    switch (result.chatType) {
        case DirectMessages:
            return {
                selected: result.chat.chatUId,
                loadData: this.loadMessages(result.chat.chatUId),
                loadMore: this.loadMoreMessages,
                mapData: mapMessages,
                addComment: this.addMessage,
                title: result.userProfile.firstname + ' ' + result.userProfile.lastname,
                icon: '',
                userProfile: result.userProfile
            }
        case GroupMessages:
            return {
                selected: result.chat.chatUId,
                loadData: this.loadGroupMessages(result.chat.chatUId),
                loadMore: this.loadMoreGroupMessages,
                mapData: mapMessages,
                addComment: this.addGroupMessage,
                title: result.userProfile.firstname + ' ' + result.userProfile.lastname,
                icon: '',
                userProfile: result.userProfile
            }
        case Discussions:
            return {
                selected: result.docId,
                loadMore: this.loadMoreComments,
                loadData: this.loadComments(result.docId),
                mapData: mapComments,
                addComment: this.addComment,
                title: result.document.title,
                icon: getIcon(this.props.inputMappings, result.document),
                userProfile: null
            }
        case SavedMessages:
            return {
                selected: result.userProfile.auth0_id,
                loadData: this.loadSavedMessages(result.userProfile.auth0_id),
                loadMore: () => {},
                mapData: mapSavedMessages,
                addComment: this.addSavedMessage,
                title: t('Saved Messages'),
                icon: '',
                userProfile: result.userProfile
            }
    }
}

export const findChatIndex = (result, commentChatId) => {
    switch (result.chatType) {
        case DirectMessages: return result.chat.chatUId == commentChatId;
        case GroupMessages: return result.chat.chatUId == commentChatId;
        case Discussions: return result.docId == commentChatId;
        default: return "";
    }
}

export const getChatId = (chatType, chat, docId) => {
    switch (chatType) {
        case DirectMessages: return chat ? chat.chatUId : "";
        case GroupMessages: return chat ? chat.chatUId : "";
        case Discussions: return docId;
        default: return "";
    }
}

export const getChatCommentData = (comment, dispatch) => {
    if (comment.chatUId) {
        return {
            text: comment.messageText,
            commentChatId: comment.chatUId,
            commentTime: comment.creationDateTime,
            userUid: comment.sender.auth0_id,
            chatType: comment.type === 'Group'? GroupMessages : DirectMessages,
            updateTime: () => dispatch(updateLastUserEnterChatDM(SERVER_API)(comment.chatUId))
        }
    }
    else if (comment && comment.docId) {
        return {
            text: comment.text,
            commentChatId: comment.docId,
            commentTime: comment.dateTime,
            userUid: comment.userId,
            chatType: Discussions,
            chatTitle: comment.docTitle,
            updateTime: () => dispatch(updateLastUserEnterChatDiscussions(SERVER_API)(comment.docId))
        }
    }
}

export let updateResults = function (comment, results, selected, dispatch) {
    if(comment.sender && comment.receiver) return
    const chatCommentData = getChatCommentData(comment, dispatch)
    const oldResults = [...results]
    const resIndex = results.findIndex(res => findChatIndex(res, chatCommentData.commentChatId))

    if (resIndex >= 0) {
        let { newComments } = oldResults[resIndex];
        let counts = isDiscussions(chatCommentData.chatType) ? oldResults[resIndex].document.commentsCounter : 0

        if (chatCommentData.commentChatId === selected) {
            counts = counts + 1
            chatCommentData.updateTime()
        } else {
            newComments = newComments + 1;
        }
        const newResult = {
            ...oldResults[resIndex],
            commentTime: chatCommentData.commentTime,
            commentText: chatCommentData.text,
            commentType: chatCommentData.chatType,
            lastUserId: chatCommentData.userUid,
            newComments,
            ...(isDiscussions(chatCommentData.chatType) ? { document: { ...oldResults[resIndex].document, commentsCounter: counts } } : {})
        }
        dispatch(updateDataResult(chatCommentData.commentChatId, newResult))
        return
    }

    getNotificationData(comment && comment.docId, comment.chatUId, dispatch, 'push')
}

export let getIcon = function (inputMappings, document) {
    let icon = 'chat';
    const dataType = getDataType(document.dataType) || mapToCustomTemplate(document, inputMappings)

    if (dataType) {
        icon = dataType.icon;
    }

    switch (document.dataType) {
        case "media": icon = document.mimeType.includes("video") ? "videocam" : "photo"; break;
    }

    return icon;
}

export let getFaces = function (document, users, cloudfront) {
    var tagedUsers = []

    if (document) {
        document.tags.map(tag => {
            let user = users.find(user => user.tag == tag);

            if (user && !tagedUsers.find(tagedUser => tagedUser.id === user.id)) {
                tagedUsers.push({
                    id: user.id,
                    uid: user.auth0_id,
                    imageUrl: parseImgeUrl(cloudfront, user.profileImageUrl),
                    tag: user.tag,
                    fullName: user.firstname + " " + user.lastname
                })
            }
        })
    }

    return tagedUsers
}

export let closeAddChatModal = function (result) {
    if (result) {
        let results = [...this.state.results];
        if (!result.commentText) {
            result.commentTime = new Date()
        }

        let userChat = results.find(res => {
            if (res.userProfile) {
                return res.userProfile.auth0_id === result.userProfile.auth0_id
            }
        })

        if (!userChat) {
            this.setState({ results: [result, ...results] })
        }
    }
}

function chatCommentData(idx, messageText, auth0_id, creationDateTime, cloudfront, comment, users, {messageType, isSavedMessage}) {
    const currUser = users.find(user => user.auth0_id === auth0_id)
    if (!currUser) {
        return null
    }
    return {
        id: comment.commentId ? comment.commentId : comment.id,
        _id: idx,
        text: messageText,
        createdAt: new Date(creationDateTime),
        type: comment.type,
        originalItem: comment,
        typeItem: {messageType, isSavedMessage},
        attachments: comment.attachments,
        user: {
            _id: auth0_id,
            name: currUser.firstname + " " + currUser.lastname,
            tag: currUser.tag,
            avatar: parseImgeUrl(cloudfront, currUser.profileImageUrl),
        },
    }
}