import { Dialog, DialogContent, Button, DialogTitle, Grid, withStyles, ListItem, List, DialogActions } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import withErrorCatcher from './withErrorCatcher';
import OnErrorDialog from './OnErrorDialog';
import { connect } from 'react-redux';
import TopicExpert from '../../views/knowledge/Library/TopicView/topicExpert';

const styles = theme => ({
    userItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    root: {
        minWidth: 475
    }
})

class GroupMemebersDialog extends React.Component {
    render() {
        const { open, onClose, classes, usersIds, title } = this.props
        const fullname = x => x.firstname + ' ' + x.lastName
        
        const users = usersIds.map(id => this.props.users.find(x => x.id === id)).filter(x => !!x).sort((a, b) => fullname(a) > fullname(b) ? 1 : -1)
        return <Dialog
            open={open}
            onClose={onClose}>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>{title}</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <List>
                    {users.map(user => {
                        return <ListItem className={classes.userItem}>
                            <TopicExpert key={user.tag} user={user} />
                        </ListItem>
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
})

export default compose(
    withStyles(styles), 
    withErrorCatcher(OnErrorDialog),
    connect(mapStateToProps))(GroupMemebersDialog)