import React from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import StoryNavigation from './StoryNavigation'

const styles = (theme) => ({
    allStoriesTitle: {
        marginLeft: 21,
        marginTop: 20,
        marginBottom: 16,
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
    },
    storiesListHolder: {
        paddingTop: 18,
    }
})

const StoriesNavigation = (props) => {
    const {classes, storiesNavigation, isDrawerOpen} = props

    const {stories, currentStoryId} = storiesNavigation

    const AllStories = (
        <>
            {isDrawerOpen && <div data-intrcm-id="StoriesNavigation_8d47lzaw" className={classes.allStoriesTitle}>All Activities</div>}
            <div data-intrcm-id="StoriesNavigation_cttjalxr" className={!isDrawerOpen ? classes.storiesListHolder : ''}>
                {stories.map(story => (
                    <StoryNavigation currentStoryId={currentStoryId} story={story} isDrawerOpen={isDrawerOpen}/>
                ))}
            </div>
        </>
    )

    return AllStories
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoriesNavigation)