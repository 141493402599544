
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalDataLoad, userDataLoad } from '../../../actions/globalDataAction';
import InputService from '../../../services/inputService';
import SignupService from '../../../services/signupService';
import AdminService from '../../../services/adminService'
import parseImagUrl from "../Common/parseImagUrl";
import Spinner from '../Common/spinner/spinner';

class NewOrganization extends Component {
    constructor(props, context) {
        super(props, context);
    };

    componentDidMount() {
        const { userDataLoaded } = this.props.userData;

        this.loadServices()
        if (!userDataLoaded) {
            // Get user data
            this.loadAllData();
        }
    }

    loadServices = () => {
        const { dispatch, user, userData, authService } = this.props;

        const services = {
            loaded: true,
            inputService: new InputService(userData.idToken),
            signupService: new SignupService(userData.idToken),
            adminService: new AdminService(userData.idToken)
        }

        dispatch({ type: 'SERVICES_INIT', services: services });
        services.inputService.getCloudFrontSignature().then(cloudfront => {
            this.fixImage(cloudfront, user)
        })
    }

    fixImage(cloudfront, user) {
        if (user && user.profileImageUrl) {
            user.profileImageUrl = parseImagUrl(cloudfront, user.profileImageUrl)
        }
    }

    loadAllData = () => {
        const { dispatch, user, userData, authService } = this.props;
        dispatch(globalDataLoad({ profileData: user, users: [user] }));

        dispatch(userDataLoad({ profileData: user }));
    }


    render() {
        const { userData, children, services } = this.props;

        if (!userData.userDataLoaded || !services || !services.signupService ) {
            return <Spinner />
        }

        return children
    }
}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    authService: state.authModule.authService,
    services: state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewOrganization);
