import React, { useEffect, useState, useRef } from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { nextStory, nextStoryScene, getStoryControlNextAction, STORY_CONTROL_TYPES } from '@tuqqi/common'
import ArrowRightIcon from '../../../components/Common/Icons/StoriesIcons/ArrowRightIcon'

const styles = (theme) => ({
})

const defaultCurrentScene = {id: -1}

const StoryNext = (props) => {
    const {classes, storiesNavigation, dispatch, wrapClass} = props
    const storiesRef = useRef([])
    const currentStoryIdRef = useRef('')
    const {currentScene, currentStoryId, stories} = storiesNavigation
    const [actionType, setActionType] = useState(STORY_CONTROL_TYPES.NEXT_HIDE)
    const {id: sceneId}  = currentScene ?? defaultCurrentScene
    
    useEffect(() => {
        storiesRef.current = stories
    }, [stories])

    useEffect(() => {
        currentStoryIdRef.current = currentStoryId
    }, [currentStoryId])

    useEffect(() => {
        const actionType = getStoryControlNextAction({
            sceneId, 
            currentStoryId: currentStoryIdRef.current,
            stories: storiesRef.current
        })
        setActionType(actionType)
    }, [sceneId])

    const onClick = () => {
        switch (actionType) {
            case STORY_CONTROL_TYPES.NEXT_SCENE:
                return dispatch(nextStoryScene())
            case STORY_CONTROL_TYPES.NEXT_STORY:
                return dispatch(nextStory())
            default:
                return
        }
    }
    if(actionType === STORY_CONTROL_TYPES.NEXT_HIDE) return null

    return <div data-intrcm-id="StoryNext_m07yisre" onClick={onClick} className={wrapClass}>
            <ArrowRightIcon/>
        </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoryNext)