import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import AddComment from '../Comments/AddComment'
import { loadData, printComments } from '../Comments/CommentsUtils'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import red from '@material-ui/core/colors/red';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCommentFullView from '../Comments/AddCommentFullView';
// import { onTaskAdd, onTaskDelete, onTaskUpdate } from '../Comments/taskUtils'

// Apply some reset
const styles = theme => ({
    avatar: {
        backgroundColor: red[500],
    },
    comments: {
        margin: 0
    }
});

class FullViewComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            comments: [],
            tasks: []
        } 

        this.loadData = loadData.bind(this);
        this.printComments = printComments.bind(this);
        // [onTaskAdd, onTaskDelete, onTaskUpdate].forEach(f => this[f.name] = f.bind(this))

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.itemId !== this.props.itemId) {
            this.loadData(this.props.itemId);
        }
    }

    componentWillMount() {
        this.loadData(this.props.itemId);
    }

    changeCounter = (name, numToAdd) => {
        const { item, dispatch, updateSpecificItem } = this.props
        const newDoc = { ...item }
        newDoc[name] += numToAdd
        
        updateSpecificItem && updateSpecificItem();

        dispatch({type: 'FULLVIEW_CHANGE', item: newDoc})
    }

    onCommentDelete = (comment) => {
        let comments = this.state.comments.filter(com => com.key != comment.key);
        this.setState({ comments: comments });

        this.changeCounter('commentsCounter', -1)
    }

    onCommentAdd = (comment) => {
        this.setState({ comments: [...this.state.comments, comment] })

        this.changeCounter('commentsCounter', 1)
    }

    onTaskAdd = (task) => {
        this.setState({ tasks: [...this.state.tasks, task] })

        this.changeCounter('taskCounter', 1)
    }

    onLinkAdd = () => {
        this.props.updateSpecificItem && this.props.updateSpecificItem();
    }
    
    onTaskUpdate = (task) => {
        // Remove the task from the social bar
        const newNum = task.isDone ? 1 : -1
        this.changeCounter('taskDoneCounter', newNum)
    
        const taskIndex = this.state.tasks.findIndex(t => t.key == task.key)
        this.state.tasks[taskIndex] = task
        this.setState({ tasks: [...this.state.tasks] });
    }
    
    onTaskDelete = (task) => {
        // Remove the task from the social bar
        this.changeCounter('taskCounter', -1)
    
        const tasks = this.state.tasks.filter(t => t.key != task.key);
        this.setState({ tasks });
    }

    onLinkDeleted = (linkId) => {
        const { item, dispatch } = this.props
        const newDoc = { ...item }
        newDoc.relatedDocuments = newDoc.relatedDocuments.filter(r => r.relatedDocumentId != linkId);

        dispatch({type: 'FULLVIEW_CHANGE', item: newDoc})
    }

    render() {
        const { classes, item, edit, itemId, isFullView, userData } = this.props;
        const { dataLoaded, comments, activities, tasks } = this.state;

        var content = <CircularProgress className={classes.progress} />

        let isChoose = isFullView && (userData.profileData.auth0_id === item.userUUID) && !item.selectedComment && item.dataType === "question";

        if (dataLoaded) {
            content = this.printComments({ activities, comments, tasks:[], relatedDocuments: item.relatedDocuments }, this.onLinkDeleted, (comment) => this.onCommentDelete(comment), isChoose);
        }

        let properties = {
            docId: itemId ,
            title: item.title, 
            onCommentAdd: this.onCommentAdd,
            onTaskAdd: this.onTaskAdd,
            onLinkAdd: this.onLinkAdd
        }

        return <div data-intrcm-id="FullViewComments_8iqh1mwt" className={classes.comments}>
            {
                isFullView ? 
                <AddCommentFullView {...properties} />
                : <AddComment {...properties} />
            }
            <div data-intrcm-id="FullViewComments_wxso4wib" style={{ marginLeft: dataLoaded ? -16 : 0 }}>
                {content}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.fullView,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FullViewComments);