import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { defaultDataTypes } from '../../../Common/Utils/defaultDataTypes'

const styles = (theme) => ({
    container: {
    },
    containerForLongText: {
        width: 180,
    },
    text: {
        opacity: 0.95,
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
        fontFamily: 'Roboto Slab',
        fontSize: 36,
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.56,
        letterSpacing: 0,
        color: '#fff'
    },
    textSmall: {
        fontSize: '18px !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
    }
})
const DataTypeTitle = (props) => {
    const {dataType, styles, classes, inputMappings} = props
    const [dataTypeTitle, setDataTypeTitle] = useState('')

    useEffect(() => {
        const allMappings = [...inputMappings, ...defaultDataTypes()]
        const mapping = allMappings.find(i => i.customDataMapping.dataTypeFilter === dataType)

        if(!mapping) return
        setDataTypeTitle(mapping.title)
    }, [inputMappings, dataType])

    if(!dataTypeTitle) return null
    
    return <div data-intrcm-id="DataTypeTitle_v4hp8b4l" style={styles.container} className={dataTypeTitle.length > 12 ? classes.containerForLongText : ''}>
        <div data-intrcm-id="DataTypeTitle_4b7d8jvb" style={styles.text} className={dataTypeTitle.length > 12 ? `${classes.text} ${classes.textSmall}` : classes.text}>
            {dataTypeTitle}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DataTypeTitle)