

import { withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { isNotificationDateSeen } from '../../../../../utils/notifications';
import withErrorCatcher from '../../../Common/withErrorCatcher';


// Apply some reset
const styles = theme => ({
    point: {
        boxSizing: 'border-box',
        minWidth: 6,
        height: 6,
        padding: '0px 2px',
        background: '#EF4444',        
        borderRadius: 8,
        position: 'absolute',
        border: "0.5px solid #18181B",
        boxShadow: "0px 6px 6px -3px #6B6F7A0F",
    },
    pointText: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '14px',
        color: '#FFFFFF',
    },
});


class NotifPoint extends PureComponent {


    isDisplay = () => {
        if(Math.sign(this.notificationsToDisplay()) === -1 || this.notificationsToDisplay() === 0) return false
        return true
    }


    notificationsToDisplay = () => {
        const { notifications, lastSawNotification } = this.props;
        const filteredIds = notifications
            .filter(n => !n.isChatNotification && !isNotificationDateSeen(n.created, n.seenOnDateTime, lastSawNotification))

        return filteredIds.length
    }

    render() {
        if (!this.isDisplay()) {
            return null
        }
      
        return <div data-intrcm-id="NotifPoint_x6a19y1e" className={this.props.classes.point}>
                {/* <div data-intrcm-id="NotifPoint_8vwpp0v0" className={this.props.classes.pointText}>{this.notificationsToDisplay()}</div> */}
            </div>
    }
}


const mapStateToProps = (state) => ({
    notifications: state.userData.notifications,
    lastSawNotification: state.userData.profileData?.lastSawNotification ?? '',
})

export default compose(withStyles(styles, { withTheme: true }),
    withErrorCatcher("Notification point crashed"),
    connect(mapStateToProps))(NotifPoint);