import { Button, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import { FilePond } from 'react-filepond';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ADD_EXTERNAL_INPUT, REMOVE_EXTERNAL_INPUT } from '../../../../../actionTypes/actionTypes';
import { EditDescription } from './EditDescription';
import DOMPurify from 'dompurify'
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import EditSelectedEI from './EditSelectedEI';

const noteText = 'User who create external input, will be the member of all the items getting inside the group'

const styles = theme => ({
    controls: {
        paddingLeft: 20,
        paddingTop: 30
    },
    externalnputHolder: {
        display: 'flex',
        flexDirection: 'row',
    },
    externalInputText: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '90%'
    },
    externalInputDelete: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '5%',
        alignSelf: 'center',
        alignItems: 'flex-end'
    },
    link: {
        color: theme.customColors.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    formNote: {
        fontSize: 12,
        fontWeight: 400,
        color: '#8645f9',
        opacity: 0.7,
    },
    iconInfo: {
        color: '#8645f9',
        opacity: 0.7,
        fontSize: 16,
        marginRight: 8,
        marginBottom: 2
    },
})


class ExtarnalInputSettings extends Component {

    state = {
        editingEI: [],
        selectedAll: false,
        selectedDataMapping: -1,
        selectedFieldsMapping: [],
        allInputs: [],
        allowedInputs: [],
        title: '',
        finishMessage: '',
        description: '',
        fileId:'',
    }


    uploadImg = () => {


        return <FilePond
            labelIdle={this.props.t("filesUploader:d&dLabel")}
            acceptedFileTypes={['image/*']}
            allowMultiple='false'
            server={
                {
                    url: process.env.API_SERVER + '/files/s3',
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                    process: {
                        headers: {
                            'Authorization': 'Bearer ' + this.props.userData.idToken,
                        },
                        onload: this.onLoadImg
                    }
                }
            }
        >
        </FilePond>
    }


    handleChange = event => {
        const val = event.target.value
        this.setState({selectedFieldsMapping: []})
        this.setState({ selectedDataMapping: event.target.value });
    };
    handleChangeMappingFields = event => {
        const value = event.target.value
        if(value.some((v) => JSON.stringify(v) === '[]')) {
            this.setState({ selectedFieldsMapping: [] });    
            return
        }
        this.setState({ selectedFieldsMapping: event.target.value.filter(v => JSON.stringify(v) !== '[]') });
    };


    returnItem = (item, allowedInputsList) => {
        const res = allowedInputsList.filter((input) => input === item.customDataMapping.dataTypeFilter)

        if (res.length) {
            return item
        }
    }

    returnFilteredData = (allowedInputsList, inputMappings) => {
        return inputMappings.filter((item) => this.returnItem(item, allowedInputsList))
    }

    componentDidMount() {
        const { inputMappings, settings } = this.props
        const filteredData = this.returnFilteredData(settings.allowedInputs, inputMappings)

        this.setState({ allInputs: inputMappings, allowedInputs: filteredData })
    }

    componentDidUpdate(prevProps) {
        const { settings, inputMappings } = this.props

        if (prevProps.settings.allowedInputs.length !== settings.allowedInputs.length) {
            const filteredData = this.returnFilteredData(settings.allowedInputs, inputMappings)
            this.setState({ allowedInputs: filteredData })
        }
    }

    createExternalInput = () => {
        const { selectedDataMapping, allowedInputs, allInputs, title, description, fileId, selectedFieldsMapping,finishMessage } = this.state;
        const { groupService, collection, dispatch } = this.props;
        const { allInputsAllowed } = collection;
        const inputs = allInputsAllowed ? allInputs : allowedInputs;

        const mapping = inputs.find(input => input.customDataMapping && input.customDataMapping.id === selectedDataMapping)
        if (!mapping) return;
        // const { title = '', description = '' } = mapping

        groupService.createExternalInput(collection.id, selectedDataMapping, title, DOMPurify.sanitize(description), fileId, selectedFieldsMapping,DOMPurify.sanitize(finishMessage)).then(res => {
            dispatch({
                type: ADD_EXTERNAL_INPUT,
                input: { type: null, uid: res.result, title, description,finishMessage }
            })

            this.setState({
                title: '',
                description: '',
                fileId:'',
                finishMessage: '',
            })
        })
    }

    deleteExternalInput = (uid) => {
        const { groupService, collection, dispatch } = this.props;

        groupService.deleteExternalInput(collection.id, uid).then(() => {
            dispatch({
                type: REMOVE_EXTERNAL_INPUT,
                uid
            })
        });
    }

    
    onLinkClick = (uid) => {
        window.open(`${process.env.TUQQI_FORM}/?uid=${uid}`, '_blank')
    }

    onLoadImg = (res) => {
        const file = JSON.parse(res);
        // this.props.dispatch(updateCollection({ backgroundUrl: file.link }))
        this.setState({fileId: file.fileId})
    }
    getMappingFields = (inputs) => {
        if(!this.showMappingFields(inputs)) return null
        const mapping = inputs.find(i => i.customDataMapping.id === this.state.selectedDataMapping)
        return mapping.customDataMapping.customFieldsMappings
    }
    showMappingFields = (inputs) => {
        if(!inputs.length || this.state.selectedDataMapping === -1) return false
        const mapping = inputs.find(i => i.customDataMapping.id === this.state.selectedDataMapping)
        return !!mapping
    }

    removeFromEditing = (uid) => {
        this.setState({editingEI: this.state.editingEI.filter(x => x !== uid)})
    }
    startEditingExternalInput = (uid) => {
        const isExists = this.state.editingEI.includes(uid)
        if(!!isExists) {
            this.removeFromEditing(uid)
            return
        }
        this.setState({editingEI: [...this.state.editingEI, uid]})
    }

    render() {
        const { t, classes, collection } = this.props;
        const { allInputs, allowedInputs, title, description, fileId,finishMessage } = this.state;
        const { allInputsAllowed } = collection;
        const inputs = allInputsAllowed ? allInputs : allowedInputs;
        return (
            <div data-intrcm-id="ExternalInputSettings_yuk1rfe0">
                <br />
                <br />
                <Typography variant='h6' >{t('External Inputs')}</Typography>
                <div data-intrcm-id="ExternalInputSettings_a2fjdyh3" className={classes.controls}>
                    <div data-intrcm-id="ExternalInputSettings_hqifoxis">
                        <Select
                            value={this.state.selectedDataMapping}
                            onChange={this.handleChange}
                            displayEmpty
                            name="dataMapping"
                            placeholder={t("Choose mapping")}
                        >
                            <MenuItem value={-1}>{t("Choose mapping")}</MenuItem>
                            {inputs.length && inputs.map((input) => {
                                return <MenuItem value={input.customDataMapping.id}>{input.title}</MenuItem>
                            })}
                        </Select>
                    </div>
                    {this.showMappingFields(inputs) && <div data-intrcm-id="ExternalInputSettings_uk8n0zxr" style={{marginTop: 10}}>
                        <Select
                            multiple
                            displayEmpty
                            value={this.state.selectedFieldsMapping}
                            label={this.state.selectedFieldsMapping.length ? t("Choose mapping fields") : `${this.state.selectedFieldsMapping.length} ${t("selected")}`}
                            onChange={this.handleChangeMappingFields}
                            name="dataMappingFields"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return t("Choose mapping fields")
                                }
                                return `${selected.length} ${t("selected")}`
                              }}
                        >
                            <MenuItem value={[]}>{t("Choose mapping fields")}</MenuItem>
                            {this.getMappingFields(inputs).map((field) => {
                                return <MenuItem value={field.sourceField}>{field.fieldName} ({field.type}) {field.required ? '*' : ''}</MenuItem>
                            })}
                        </Select>
                    </div>}
                    <TextField className='new-group-title' label={t("Form Title *")} helperText={t("This will be the title of your Form")} id="gr_title" placeholder={t("Enter form title")}
                        onChange={(event) => { this.setState({ title: event.target.value }) }} value={title} InputLabelProps={{ shrink: true }} margin="normal" fullWidth />
                    <div data-intrcm-id="ExternalInputSettings_hco4gx31" style={{display: 'flex', alignItems: 'center'}}>
                        <InfoIcon className={classes.iconInfo} />
                        <Typography variant="body1" className={classes.formNote}>{t(noteText)}</Typography>
                    </div>
                    <EditDescription t={t} value={description} onChange={(newValue) => {this.setState({description: newValue})}} />
                    <br />
                    {this.uploadImg()}
                    <br />
                    <EditDescription t={t} isFinishMessage value={finishMessage} onChange={(newValue) => {this.setState({finishMessage: newValue})}} />
                    <Button variant="contained" disabled={this.state.selectedDataMapping === -1} onClick={this.createExternalInput}>
                        {t("Create external input")}
                    </Button>
                    <br />
                    <br />
                    <div data-intrcm-id="ExternalInputSettings_lxcjnp6k" id="external-inputs-list">
                        {collection.externalInputs ? collection.externalInputs.map(externalInput => (
                            <div data-intrcm-id="ExternalInputSettings_yiwkt0fp" >
                                <br />
                                <div data-intrcm-id="ExternalInputSettings_2lz72ffx" className={classes.externalnputHolder}>
                                    <div data-intrcm-id="ExternalInputSettings_j57y522d" className={classes.externalInputText}>
                                        <span>{`${externalInput.title || ''}`}</span>
                                        <Typography className={classes.link} variant='body2' component='span' onClick={() => this.onLinkClick(externalInput.uid)}>{t('Open form')}</Typography>
                                    </div>
                                    <div data-intrcm-id="ExternalInputSettings_z2799v27" className={classes.externalInputDelete}>
                                        <EditIcon style={{ cursor: 'pointer' }} onClick={() => this.startEditingExternalInput(externalInput.uid)} />
                                    </div>
                                    <div data-intrcm-id="ExternalInputSettings_jljdp8cf" className={classes.externalInputDelete}>
                                        <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => this.deleteExternalInput(externalInput.uid)} />
                                    </div>
                                </div>
                                <EditSelectedEI show={!!this.state.editingEI.includes(externalInput.uid)} selected={externalInput} inputs={inputs} onHide={this.removeFromEditing} />
                            </div>
                        )) : null}
                    </div>
                </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    collection: state.collection,
    settings: state.collection.settings,
    allInputsAllowed: state.collection.allInputsAllowed,
    inputMappings: state.globalData.inputMappings,
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
    withStyles(styles),
    withTranslation('newGroupDialog'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ExtarnalInputSettings);