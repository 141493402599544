import { Grid, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../../actions/appActions';
import SelectIcon from '../../../components/Common/Icons/SelectIcon';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import AdminEditContainer from '../AdminEditContainer';
import { OldPagesWrap } from '../newway/OldPagesWrap';

const styles = theme => ({
  container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
  paper: {
    padding: 30
  },
  divid: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  buttonIc: {
    marginTop: '8px',
    fontSize: '14px'
  }
})



export class CreateIntegration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      icon: '',
      title: '',
      description: '',
      color: ''
    }
  }

  save = () => {

    const { adminService, dispatch } = this.props;
    dispatch(showSnackbar('Saving...'));

    const { title } = this.state

    const integration = { title }
    adminService.addIntegration(integration).then(res => {
      if (res.ok) {
        dispatch(showSnackbar('Save'));
        dispatch(push('/admin/integrations'))
      } else {
        dispatch(showSnackbar('Error occured while saving the integration!'));
      }
    })
  }



  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }
  changeColor = (color) => {
    this.setState({ color })
  }
  render() {
    const { classes } = this.props;

    const { icon, title, description, color } = this.state
    return <OldPagesWrap className={classes.container} classNameContent={classes.content} >
    <AdminEditContainer isNew save={this.save} title='New integration' subtitle='Create Integration' description='' >

      <Grid container direction='column'>

        <TextField
          className={classes.item}
          fullWidth
          value={title}
          label="Title"
          onChange={this.handleChange("title")} />
      </Grid>
    </AdminEditContainer>
    </OldPagesWrap>
  }

}

const mapStateToProps = (state) => ({
  adminService: state.services.adminService,
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default compose(withStyles(styles),
  withErrorCatcher(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(CreateIntegration);