import { withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { AddItemContent } from './AddItemContent';
import { AddItemHeader } from './AddItemHeader';

const styles = (theme) => ({
    container: {
        ...theme.launchpadPageWrap,
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 16px)`,
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 16px)`,
    },
})

function LaunchpadAddItem(props) {
    const {classes} = props

    return (
        <div data-intrcm-id="LaunchpadAddItem_cx7anvc8" className={classes.container} >
            <AddItemHeader />
            <AddItemContent />
        </div>
    )
}

export const LaunchpadAddItemPage = compose(
    withStyles(styles, {withTheme: true})
)(LaunchpadAddItem)

