import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { StatusColorMap, hexToRGB } from '@tuqqi/common';
import { whiteStatusesText, blackStatusesText } from '@tuqqi/web';
import React, { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    valueWrapOuterRTL: {
        display: 'flex',
        marginLeft: '0px!important',
        width: 'inherit',
        justifyContent: 'flex-end',
        textAlign: 'right'
    },
    text: {
        whiteSpace: 'inherit',
        overflow: 'hidden',
        'font-feature-settings': "'clig' off, 'liga' off",
        textOverflow: 'ellipsis',
        fontWeight: 400,
        fontSize: 12,
    },
    valueWrap: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
    },
    valueWrapOuter: {
        width: '100.5%',
        marginBottom: -3,
        marginTop: -3,
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
        margin: '-1px',
    },
    inputWrap: {
    },
    singleLine: {
        alignItems: 'center',
    },
}))


export const StatusView = (props) => {
    const classes = useStyles()
    const value = props.value

    const noStatus = false

    // const isRTl = useMemo(() => isRTLValue(value?.title ?? ''), [value])
    const isRTl = useMemo(() => false, [value])
    const backgroundColor = noStatus ? '#fff' : hexToRGB(StatusColorMap[value?.listColor ?? 0] ?? '#fff', 0.75)
    
    const textColor = useMemo(() => {
        const color = StatusColorMap[value?.listColor ?? 0]
        if(whiteStatusesText.includes(color)) return '#fff'
        if(blackStatusesText.includes(color)) return '#0C0C0D'
        return '#0C0C0D'
    }, [value?.listColor])

    const wrapStyleValue = useMemo(() => {
        return {
            color: textColor
        }
    }, [textColor])

    const NoValue = <div data-intrcm-id="StatusFromInput_jqtcqo5d" style={{backgroundColor}} className={`${classes.inputWrap} ${classes.singleLine}`}>
    </div>

    const Value = <div data-intrcm-id="StatusFromInput_igteqpgl" style={{backgroundColor}} className={`${classes.valueWrapOuter} ${isRTl ? classes.valueWrapOuterRTL : ''}`}>
        <div data-intrcm-id="StatusFromInput_w30jwhjj" className={`${classes.valueWrap}`} >
            <Typography style={wrapStyleValue} dir="auto" className={`${classes.text}`} component='span' variant='body2' >{value?.title ?? ''}</Typography>
        </div>
    </div>
  

    const X = !!value ? Value : NoValue

    return X
}