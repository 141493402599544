import { Icon, ListItemIcon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';


export default class AdminSettingsRow extends React.Component {

    render() {

        const { primary, secondary, icon, onClick, onDelete, disabled,t } = this.props

        return (
            <ListItem button onClick={onClick} disabled={disabled}>
                <Avatar>
                    <Icon>{icon || 'folder'}</Icon>
                </Avatar>
                <ListItemText primary={primary} secondary={secondary} />
                {
                    onDelete ?
                        <ListItemIcon onClick={onDelete}><Icon>{'delete'}</Icon></ListItemIcon>
                        : null
                }

            </ListItem>
        )
    }
}
