import React, { PureComponent } from 'react';

export default class SearchFileIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V8M15 4L19 8M15 4V7C15 7.55228 15.4477 8 16 8H19" stroke={color} stroke-width="1.5"/>
                <rect x="8" y="12" width="8" height="1" fill={color}/>
                <rect x="8" y="14" width="8" height="1" fill={color}/>
                <rect x="8" y="16" width="4" height="1" fill={color}/>
            </svg>
        )
    }
}