import { makeStyles } from '@material-ui/styles';
import { NewUiWrapper, updateSpecificItem } from '@tuqqi/web';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useActionsCenter } from '../../../../../../../../hooks/useActionCenter/useActionCenter';
import { buildAgendaTasks } from '../../../../../../../../utils/actionsCenter/buildAgenda';
import { buildToDoTasks } from '../../../../../../../../utils/actionsCenter/buildToDo';
import { Placeholder } from './Placeholder';
import { SectionList } from './SectionList';
import withErrorCatcher from '../../../../../../Common/withErrorCatcher';
import { store } from '../../../../../../../..';

const emptyObj = {}
const _styles = theme => ({
    wrap: {
        paddingBottom: 4,
    },
    buttonMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        marginRight: 16,
        marginLeft: 16,
        cursor: 'pointer',
        background: 'rgba(123, 79, 240, 0.08)',
        borderRadius: 4
    },
    buttonMoreText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: '#7B4FF0',
    }
});

const useStyles = makeStyles(_styles)

function ActionsCenterMain(props) {
    const {users, actionsCenterView, queryService, dispatch, favourites, userCollections, openCollections, userUid, queryFiltersItemId, queryFiltersUpdateType, customStatuses} = props
    const {itemsAgenda, itemsReadConfirm, itemsToDo, isLoading, onReload, agendaBirthdayUsers} = useActionsCenter({queryFiltersUpdateType, queryFiltersItemId, dispatch, queryService, userUid, users, customStatuses})
    const styles = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const [t] = useTranslation("actionCenter")

    const toggleExpanded = React.useCallback(() => {
        setExpanded(prev => !prev)
    }, [])

    React.useEffect(() => {
        setExpanded(false)
    }, [actionsCenterView])

    const getAllCollections = React.useCallback(() => {
        const userColIds = userCollections.map(col => col.collectionUid)
        const filteredOpenCollections = openCollections.filter(col => !userColIds.includes(col.collectionUid))

        return [...userCollections, ...filteredOpenCollections]
    }, [userCollections, openCollections])

    const allCollections = React.useMemo(() => getAllCollections(), [getAllCollections])

    const [todoLanes, setTodoLanes] = React.useState([])
    const [mustReadLanes, setMustReadLanes] = React.useState([])
    const [agendaLanes, setAgendaLanes] = React.useState([])

    React.useEffect(() => {
        setTodoLanes(buildToDoTasks(itemsToDo, allCollections, favourites ?? [], false))
    }, [itemsToDo, allCollections, favourites])

    React.useEffect(() => {
        setMustReadLanes(buildToDoTasks(itemsReadConfirm, allCollections, favourites ?? [], true))
    }, [itemsReadConfirm, allCollections, favourites])

    React.useEffect(() => {
        setAgendaLanes(buildAgendaTasks(itemsAgenda, agendaBirthdayUsers, t))
    }, [itemsAgenda, agendaBirthdayUsers, t])

    const lanes = React.useMemo(() => {
        switch (actionsCenterView) {
            case 'agenda':
                return agendaLanes
            case 'todo':
                return todoLanes
            case 'mustRead':
                return mustReadLanes
            default:
                return []
        }
    }, [actionsCenterView, todoLanes, agendaLanes, mustReadLanes])

    React.useEffect(() => {
        if(!queryFiltersItemId) return
        onReload()
        dispatch(updateSpecificItem(null))
    }, [queryFiltersItemId, onReload, lanes])

    const getCountByUid = React.useCallback((uid) => {
        return lanes.find(x => x.collectionUid === uid)?.data?.length ?? 0
    }, [lanes])

    const [needsExpand, updatedLanes, leftItemsCount] = React.useMemo(() => {
        const first3 = lanes.slice(0, 3)
        const fullLenItems = lanes.reduce((accum, item) => accum + item.data.length, 0)
        const lenItems = first3.reduce((accum, item) => accum + item.data.length, 0)

        return [false, lanes, 0]
        // if(lenItems <= 3) return [false, lanes, 0]

        // if(!!expanded) return [true, lanes, 0]
        // const res = []
        // let left = 3
    
        // if(!!first3[0]) {
        //     const data = first3[0].data.slice(0, 3)
        //     res.push({...first3[0], data})
        //     left = left - data.length
        // }
        // if(!!first3[1]) {
        //     left < 0 ? 0 : left
        //     const data = first3[1].data.slice(0, left)
        //     res.push({...first3[1], data})
        //     left = left - data.length
        // }
        // if(!!first3[2]) {
        //     left < 0 ? 0 : left
        //     const data = first3[2].data.slice(0, left)
        //     res.push({...first3[2], data})
        //     left = left - data.length
        // }
        // const res1 = res.filter(x => !!x.data.length)

        // const resLenItems = res1.reduce((accum, item) => accum + item.data.length, 0)
        // return [true, res1, fullLenItems - resLenItems]
    }, [lanes, actionsCenterView, expanded])

    const More = !!needsExpand && <div data-intrcm-id="ActionCenterMain_j0m5kybk" onClick={toggleExpanded} className={styles.buttonMore}>
        <div data-intrcm-id="ActionCenterMain_dczdy35v" className={styles.buttonMoreText}>{`${!expanded ? t('View more') : t('View less')} ${!!leftItemsCount ? '(' + leftItemsCount + ')' : ''}`}</div>
    </div>

    const Content = !updatedLanes.length ? <Placeholder /> :
        <>
            {updatedLanes.map(list => <SectionList getCountByUid={getCountByUid} key={list.collectionUid} view={actionsCenterView} list={list} />)}
            {More}
        </>
    return (
        <NewUiWrapper store={store} callbacks={emptyObj}>
        <div data-intrcm-id="ActionCenterMain_lr33plwu" className={styles.wrap}>
            {Content}
        </div>
        </NewUiWrapper>
    );
}

const mapStateToProps = (state) => ({
    queryFiltersItemId: state.queryFilters.itemId,
    queryFiltersUpdateType: state.queryFilters.updateType,
    customStatuses: state.data?.globalData?.statuses?.statuses ?? [],
    queryService: state.services.queryService,
    favourites: state.userData.favoriteCollections ?? [],
    userCollections: state.data.userData.userCollections.data,
    userUid: state.userData.profileData.auth0_id,
    openCollections: state.data.globalData.openCollections.data,
    users: state.data.globalData.activeUsers?.data ?? [],

})
const mapDispatchToProps = (dispatch) => ({
    dispatch,
})

export default compose(
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(ActionsCenterMain);
