import { withStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getAllCollection } from '../../../../views/groups/groupUtils';
import LaunchpadOption from '../Common/LaunchpadOption';

const styles = (theme) => ({
    container: {
        marginTop: 26,
        position: 'relative',
        border: 'solid 1px #D7D7DB',
        boxSizing: 'border-box',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        color: '#4A4A4F'
    },
    titleWrap: {
        position: 'absolute',
        top: -8,
        backgroundColor: '#fff',
        paddingRight: 12,
        paddingLeft: 12,
        left: 12
    },
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 0,
        color: '#4A4A4F'
    },
    optionsWrap: {
        width: '100%',
        marginLeft: 4,
        marginRight: 4,
    },
    options: {
        minHeight: 80,
        padding: 24,
        paddingLeft: 1,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 'auto',
        display: 'grid',
        'grid-row-gap': '24px',
        'grid-template-columns': '92px 92px 92px 92px',
    }
})

function _OrgMenuOptions(props) {
    const {classes, openCollections, collections, firstLayerOrgMenu, allowedOrgMenuItemsUids, orgMenuItems, goToItem, title} = props
    const allGroups = useMemo(() => getAllCollection(openCollections, collections), [openCollections, collections])

    const isGroupExists = useCallback((groupUid) => {
        const group = allGroups.find(col => col.collectionGroup.groupUid === groupUid)
        return !!group
    }, [allGroups])

    const isItemExists = useCallback((uid) => {
        return allowedOrgMenuItemsUids.includes(uid)
    }, [allowedOrgMenuItemsUids])


    const getisDisabled = useCallback((item) => {
        switch(item.type){
            case 1:
                return !isGroupExists(item.referenceUid)
            case 2:
                return !isItemExists(item.referenceUid)
            case 10:
                return false
            default:
                return false
        }
    }, [isGroupExists, isItemExists])


    const onClick = useCallback((o) => {
        goToItem(o, getisDisabled(o))
    }, [orgMenuItems, allowedOrgMenuItemsUids])

    return (
        <div data-intrcm-id="OrgMenuOptions_brlgw6fy" className={classes.container} >
            <div data-intrcm-id="OrgMenuOptions_5uthkwng" className={classes.titleWrap}>
                <div data-intrcm-id="OrgMenuOptions_2ay1q6mk" className={classes.title}>{title}</div>
            </div>
            <div data-intrcm-id="OrgMenuOptions_day4delr" className={classes.optionsWrap} >
                <div data-intrcm-id="OrgMenuOptions_x8a4yrth" className={classes.options} >
                    {firstLayerOrgMenu.map((opt) => (
                        <LaunchpadOption isOrgMenu disabled={getisDisabled(opt)}  onClick={onClick} key={opt.id} option={opt} />
                    ))}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

const mapStateToProps = (state) => ({
    orgMenuItems: state.feedNavigation.orgMenuItems,
    openCollections: state.data.globalData.openCollections.data,
    collections: state.data.userData.userCollections.data,
})

export const OrgMenuOptions = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_OrgMenuOptions)

