import { Collapse, List, withStyles, Divider, Tooltip } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ItemTask } from './ItemTask';
import { connect } from 'react-redux';
import goToCollection from '../../../../../../../actions/goToCollection';
import { KeyboardArrowDown } from '@material-ui/icons';
import { GroupTooltipPos } from '@tuqqi/web';

const _styles = theme => ({
    title: {
        cursor: 'pointer',
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        color: '#18181B',
        maxWidth: 248,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#737373',
            
        },
    }, 
    agendaTitle: {
        cursor: 'default',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    container: {
        marginTop: 0,
        '&:first-child': {
            marginTop: 4,
        },
    },
    titleContainer: {
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop:8,
        paddingBottom:8,
        
        minHeight: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#737373',
            backgroundColor:"#E8EBFD",
            cursor:'pointer'
         },
         marginRight: 8,
         borderRadius: 6,
         marginLeft: 8,
    }, 
    titleContainerExpanded: {
        backgroundColor: "#B7B8BD12",
        borderRadius: 6,
    },
    separator: {
        backgroundColor: '#efefef',
        width: 1,
        height: 'auto'
    },
    arrowDown: {
        cursor: 'pointer',
        transition: 'transform .2s ease-in-out',
        transform: 'rotate(180deg)',
        color: "#6B6F7A",
        width:20,
        
    },
    arrowUp: {
        cursor: 'pointer',
        transition: 'transform .2s ease-in-out',
        color: "#6B6F7A",
        width:20,
        
    },
    divider: {
        backgroundColor: '#e0e0e0',
        marginTop: 8,
        marginBottom: 8,
        
    },
    countText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        color: '#4338CA',
        whiteSpace: 'nowrap',
    },
    count: {
        borderRadius: 4,
        backgroundColor: '#818CF81A',
        height: 20,
        minWidth: 26,
        alignItems: 'center',
        justifyContent: "center",
        display: 'flex',
        marginRight: 12,
        marginLeft: "auto",
        '&:hover': {
            textDecoration: 'none!important',
            backgroundColor:"#818CF81A!important",
         },
    },
    wrap: {
        display: 'flex'   
    }
});


const collapseAgendaStatus = (title) => {
  
    switch (title) {
        case 'Past Due':
            return false
        case 'Today':
            return false
        case 'Tomorrow':
            return false
        case 'Later This week':
            return true
        case 'Later This month':
            return true
        case 'Later This year':
            return true
        case 'Not Set':
            return true
        case 'Future due dates':
            return true

        default:
            return true
    }
   
}


const darkStyleWrap = {width: '100%', display: 'flex', justifyContent: 'center'}
const darkStyle = {}
const __SectionList = (props) => {
    const {list, view, getCountByUid, openCollections, usersCollections, dispatch, classes: styles} = props
    const isAgenda = useMemo(() => view === 'agenda', [view])
    const [collapsed, setCollapsed] = useState(isAgenda? collapseAgendaStatus(list.sectionTitle): false)
    const count = useMemo(() => getCountByUid(list.collectionUid), [getCountByUid, list.collectionUid])

    

    const toggleCollapsed = () => {
        setCollapsed(prev => !prev)
    }

    const collection = useMemo(() => {
        if(isAgenda) return undefined
    
        const allCollections = [...usersCollections, ...openCollections]
        const collection = allCollections.find(col => col.collectionUid === list.collectionUid)
        return collection
    }, [list, usersCollections, openCollections, isAgenda])

    const openCollection = useCallback(() => {
        if(isAgenda) return

        goToCollection('/' + collection.collectionTitle, dispatch, collection.collectionUid, collection.collectionGroup.groupUid, collection)();
    }, [collection, isAgenda])

    const _Title = <div data-intrcm-id="SectionList_uliuq3vj" onClick={openCollection} className={`${styles.title} ${isAgenda ? styles.agendaTitle : ''}`}>{`${list.title}`}</div>
    const Title = isAgenda ? _Title : !!collection ? 
        <GroupTooltipPos style={darkStyle} styleWrap={darkStyleWrap} collection={collection} component={_Title} /> :
        <Tooltip title={list.title} placement="top">{_Title}</Tooltip>

    const Count = <div className={styles.count}>
        <div className={styles.countText}>{count}</div>
    </div>

    const Header = (
        <div data-intrcm-id="SectionList_xz1qow17" dir='auto' className={`${styles.titleContainer} ${!collapsed ? styles.titleContainerExpanded : ''}`} onClick={toggleCollapsed}>
            {Title}
            {Count}
            {!collapsed ?
                    <KeyboardArrowDown className={styles.arrowDown} /> :
                    <KeyboardArrowDown className={styles.arrowUp} />}
        </div>
    )
    if(!list.data.length) return null

   
   
    return (
        <div data-intrcm-id="SectionList_bdschq4x" className={styles.container} >
            {Header}
            <Collapse  in={!collapsed} timeout="auto" unmountOnExit={false} style={{marginTop: 4, marginBottom: 4}}>
            <List component="div" disablePadding>
                {list.data.map((item, i) => (
                    <div className={styles.wrap} key={item.docId}>
                        {<div data-intrcm-id="SectionList_czrrco5j" style={{marginRight: 2, marginLeft: 24}} className={styles.separator} />}
                        <ItemTask view={view} item={item} />
                    </div>
                ))}
            </List>
            {/* <Divider className={styles.divider} /> */}
            </Collapse>
        </div>
    )
}

const mapStateToProps = (state) => ({
    usersCollections: state.data.userData.userCollections?.data ?? [],
    openCollections: state.data.globalData.openCollections?.data ?? [],
})
const mapDispatchToProps = (dispatch) => ({
    dispatch
})


export const SectionList = compose(
    withStyles(_styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(__SectionList)