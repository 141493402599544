import React from 'react';
import SocialDataContainer from './SocialDataContainer';

const TaskIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="TaskIcon__a" d="M10.092.675c-.228-.228-.595-.228-.823 0l-3.29 3.29.823.822 3.29-3.295c.221-.222.221-.595 0-.817zm2.473-.006L6.802 6.433l-2.03-2.025c-.228-.227-.595-.227-.823 0-.227.228-.227.595 0 .823l2.439 2.438c.227.228.595.228.822 0l6.177-6.171c.228-.228.228-.596 0-.823h-.005c-.222-.233-.59-.233-.817-.006zM.653 5.237l2.439 2.438c.227.228.595.228.822 0l.408-.408-2.846-2.859c-.228-.227-.595-.227-.823 0-.227.228-.227.601 0 .829z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(0 3)">
                <mask id="TaskIcon__b" fill="#fff">
                    <use href="#TaskIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#TaskIcon__b)">
                    <path d="M0 0H14V14H0z" transform="translate(0 -3)" />
                </g>
            </g>
        </svg>

    )
}



class SocialDataTask extends React.PureComponent {

    render() {
        const { taskCounter, taskDoneCounter } = this.props;

        if (taskCounter === 0) {
            return null
        }

        let taskDoneText = taskDoneCounter || 0;

        return (
            <SocialDataContainer
                icon={<TaskIcon />}
                text={taskDoneText + "/" + taskCounter}
                tooltipText='task' total={taskCounter} />
        )

    }
}

export default SocialDataTask