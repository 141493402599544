import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class SaveIcon extends PureComponent {

    static defaultProps = {
        fill: "#FFF",
        isTowTone: true
    }

    render() {
        const { fill, className, isTowTone } = this.props
        return (
            <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <title>save-24px</title>
                <g id="Mockups" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Report" transform="translate(-1799.000000, -423.000000)">
                        <g transform="translate(359.000000, 299.000000)" id="BG-&amp;-Screenshot">
                            <g transform="translate(0.000000, 1.000000)">
                                <g id="Groups" transform="translate(0.000000, -1.000000)">
                                    <g id="Group-4" transform="translate(1430.000000, 114.000000)">
                                        <g id="save-24px" transform="translate(10.000000, 10.000000)">
                                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M17.5555556,2 L4.22222222,2 C2.98888889,2 2,3 2,4.22222222 L2,19.7777778 C2,21 2.98888889,22 4.22222222,22 L19.7777778,22 C21,22 22,21 22,19.7777778 L22,6.44444444 L17.5555556,2 Z M19.7777778,19.7777778 L4.22222222,19.7777778 L4.22222222,4.22222222 L16.6333333,4.22222222 L19.7777778,7.36666667 L19.7777778,19.7777778 Z M12,12 C10.1555556,12 8.66666667,13.4888889 8.66666667,15.3333333 C8.66666667,17.1777778 10.1555556,18.6666667 12,18.6666667 C13.8444444,18.6666667 15.3333333,17.1777778 15.3333333,15.3333333 C15.3333333,13.4888889 13.8444444,12 12,12 Z M5.33333333,5.33333333 L15.3333333,5.33333333 L15.3333333,9.77777778 L5.33333333,9.77777778 L5.33333333,5.33333333 Z" id="Shape" fill="#979AAA" fill-rule="nonzero"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>)
    }
}

