
import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFilestackLink } from '../../../utils/filesUtils';

const styles = () => ({
    image: {
        height: 360,
        width: '100%',
        objectFit: 'contain'
    },
});

class MediaPreview extends React.Component {
    render() {
        const { classes, oldUiInput, filestack } = this.props;

        const src = getFilestackLink(filestack, oldUiInput.attachments[0].fileLink)

        if (oldUiInput.mimeType.includes('video')) {
            return (<video
                style={{ width: '100%' }}
                controls="controls"
                controlsList="nodownload">
                <source src={src} type={oldUiInput.mimeType} />
            </video>)
        } else {
            return <img className={classes.image} src={src} alt={oldUiInput.title} />
        }
    }
}

const mapStateToProps = (state) => ({
    oldUiInput: state.oldUiInput.document,
    filestack: state.globalData.filestack,
})

export default compose(
    withStyles(styles), 
    connect(mapStateToProps)
)(MediaPreview);