import moment from 'moment'

export const getDueDateStatus = (dueDate, done) => {
    if (done) {
        return 'done'
    }

    const today = moment(Date.now())
    const dueDateMoment = moment(dueDate)

    if (today.isAfter(dueDateMoment)) {
        return 'dueDatePassed'
    }

    const result = dueDateMoment.diff(today, 'hours')
    if (result < 24) {
        return 'dueDateSoon'
    }

    return 'regular'
}

export const getDueDateStyling = (dueDate, done) => {

    const defaultCheckboxColor = 'white'
    const defaultTextColor = 'white'

    switch (getDueDateStatus(dueDate, done)) {
        case 'done':
            const green = '#3ece5a'
            return { backgroundColor: green, checkboxColor: defaultCheckboxColor, textColor: defaultTextColor }
        case 'dueDatePassed':
            return { backgroundColor: 'red', checkboxColor: defaultCheckboxColor, textColor: defaultTextColor }
        case 'dueDateSoon':
            return { backgroundColor: 'orange', checkboxColor: defaultCheckboxColor, textColor: defaultTextColor }
        default:
            const gray = '#e3e3e3'
            return { backgroundColor: gray, checkboxColor: 'rgba(48, 47, 71, 0.54)', textColor: '#21212d' }
    }
}

export const getDueDate = (item, format = 'DD/MM/YYYY, HH:mm') => {
    const dueDateUTC = item.dueDateUTC

    if (!dueDateUTC) {
        return null
    }

    const zeroDateTime = '0001-01-01T00:00:00Z'
    if (dueDateUTC === zeroDateTime) {
        return null
    }

    return  moment(dueDateUTC).format(format)
}