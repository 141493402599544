import React, { useState } from "react";
import { Collapse, withStyles, Tooltip } from '@material-ui/core'
import { compose } from 'recompose';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { connect } from 'react-redux';
import withErrorCatcher from "../../../../components/Common/withErrorCatcher";
import { allCollectionMembers, shouldAutoOpen } from "../../groupUtils";
import { getGroupDocumentPermission, getPublicDocumentPermission, updateDocumentSchedule } from "@tuqqi/common";
import { openFullview, openInput as openNewUiInput } from '@tuqqi/web'
import { changeCollectionInputInfo } from "../../../../../actions/inputActions";
import { FULLVIEW_POST, INPUT_OPEN, FULLVIEW_CLOSE } from "../../../../../actionTypes/actionTypes";
import { GanttObjectsType } from "../helpers/helpers";
import MembersDialog from "./MembersDialog";
import { useTranslation } from "react-i18next";
import { SERVER_API } from "../../../../../utils/serverAPI";
import { updateSpecificItem } from '../../../../../actions/dataActions';
import { getChannelName } from '../../../../components/Common/Pusher/pusherUtils';
import { showSnackbar } from "../../../../../actions/appActions";
import { userDataLoad } from "../../../../../actions/globalDataAction";
import { addRecentItem } from "../../../../../actions/searchNavigationActions";
// import { getChannelName, initPusher } from '../../components/Common/Pusher/pusherUtils';
const styles = theme => ({
  taskListTableRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainerProject: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowContainerProjectOdd: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F0F3FF',
  },
  rowContainerTask: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowContainerTaskOdd: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F0F3FF',
  },

  rowItemProject: {
    marginLeft: 16,
    width: 220,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowItemTask: {
    cursor: 'pointer',
    marginLeft: 27,
    width: 190,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Rubik',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.24606284499168396,
  },
  rowItemAssignedProject: {
    marginLeft: -8,
    width: 165,
  },
  rowItemAssigned: {
    marginLeft: 10,
    width: 165,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  projectName: {
    fontFamily: 'Rubik',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.09842514246702194,
  },
  resourceName: {
    marginLeft: 8,
    fontFamily: 'Rubik',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4000000059604645,
  },
  arrowContainer: {
    marginLeft: 6,
  },
  arrowUp: {
    marginTop: 5,
    width: 20,
    height: 20,
    cursor: 'pointer',
    transition: 'transform .2s ease-in-out',
    transform: 'rotate(360deg)',
    color: '#ABADBA',
    fontSize: 25,
    marginRight: -4
  },
  arrowDown: {
    marginTop: 5,
    width: 20,
    height: 20,
    cursor: 'pointer',
    transition: 'transform .2s ease-in-out',
    transform: 'rotate(180deg)',
    color: theme.customColors.primary.main,
    fontSize: 25,
    marginRight: -4
  },
  tooltip: {
    fontStyle: 'normal',
    fontfamily: 'Rubik',
    fontWeight: 400,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    fontSize: 14,
  },
  addNewTask: {
    cursor: 'pointer',
    fontFamily: 'Rubik',
    color: '#8645F9',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 27,
    width: 190,
    letterSpacing: 0.09842514246702194,
  },
  collectionFaces: {
    marginLeft: 12,
    display: 'flex'
  },
  avatar: {
    width: 30,
    height: 30,
    marginTop: 4,
    borderRadius: '50%',
    border: `solid 1px #ffffff`,
    boxSizing: "border-box"
  },
  coveringAvatar: {
    width: 30,
    height: 30,
    marginTop: 4,
    borderRadius: '50%',
    border: `solid 1px #ffffff`,
    boxSizing: "border-box",
    marginLeft: -12,
    zIndex: 1
  },
  assignButton: {
    cursor: 'pointer',
    fontStyle: 'italic',
    fontFamily: 'Rubik',
    fontSize: 12,
    fontWeight: 400,
  },
  moreAvatars: {
    cursor: 'pointer',
    zIndex: 1,
    width: 28,
    height: 28,
    background: '#daddee',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: -12,
    marginTop: 3,
    color: theme.customColors.primary.main,
    border: 'solid 2px #fff',
    fontWeight: '500',
    fontSize: 11,
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
  },
})

const ProjectsList = ({ classes, object, tasks, index, users, groupUid, groupInfo, inputMappings, oldUiInput, userData, searchQuery,
  changeCollectionInputInfo, onExpanderClick, dispatch, inputService }) => {

  const [showUsersDialog, setShowUsersDialog] = useState(false)
  const [groupTranslation] = useTranslation('groupDrawerTranslate');
  const [ganttTranslation] = useTranslation('gantt');
  const showAvatars = 7

  const tasksForCurrentProject = tasks.filter(t => t.project === object.id)
  const allUsersImagesWithDuplicates = tasksForCurrentProject.map(t => users.filter(u => u.auth0_id === t.assignedResource)).flat()
  const allUsersImages = [...new Set(allUsersImagesWithDuplicates)]
  const user = users.find(u => u.auth0_id === object.assignedResource)

  const toggleGroupMembersDialog = () => {
    setShowUsersDialog(prev => !prev)
  }
  const { collectionGroup } = groupInfo

  const adminIds = collectionGroup.adminIds || []
  const members = allCollectionMembers(groupInfo)
    .map(member => ({ ...member, isAdmin: adminIds.includes(member.id) }))
    .sort((a, b) => b.isAdmin - a.isAdmin)

  const addCard = (projectId) => {
    const payload = { postText: '', dataType: 'post', permissions: [groupUid] }
    const kanbanListId = projectId === -1 ? "" : projectId

    if (shouldAutoOpen(groupInfo)) {
      const dataType = groupInfo.settings.allowedInputs[0]
      let isCustomInput = false
      inputMappings.map((x) => {
        if (x.customDataMapping.dataTypeFilter === dataType) {
          isCustomInput = true
        }
      })
      if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
        dispatch({ type: FULLVIEW_CLOSE })

        const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
        let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
        dispatch(openNewUiInput(newDoc, dataType))
      } else {
        openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
      }
    } else {
      dispatch({
        type: FULLVIEW_POST,
        openPost: true,
        item: payload
      })
      dispatch({ type: INPUT_OPEN, model: payload });
    }
    changeCollectionInputInfo({ kanbanListId })
  }


  const handleMemberSelect = async (userId) => {
    inputService.addMembers(object.id, [userId]).then((userData) => {
      dispatch(userDataLoad(userData));
      dispatch(updateSpecificItem(object.id));
      dispatch(showSnackbar(ganttTranslation('Members Added!')));
    });
    dispatch(updateDocumentSchedule(SERVER_API)(object.id, { dateTimeFrom: object.start, dateTimeTo: object.end, taskDueDateDone: object.taskDueDateDone, assignedResource: userId }))
  }

  return (
    <div data-intrcm-id="ProjectsList_1o2irzht" className={classes.taskListTableRow}>
      {object.type === GanttObjectsType.project ?
        (
          <div data-intrcm-id="ProjectsList_7t93uo98" className={index % 2 ? classes.rowContainerProject : classes.rowContainerProjectOdd}>
            <div data-intrcm-id="ProjectsList_ws32bwfd" className={classes.rowItemProject}>
              <div data-intrcm-id="ProjectsList_i2dbqlbm" className={classes.projectName}>{object.name}</div>
              <div data-intrcm-id="ProjectsList_42foc9hj"
                className={classes.arrowContainer}
                onClick={() => onExpanderClick(object)}>
                {!object.hideChildren ? <KeyboardArrowDown className={classes.arrowUp} />
                  : <KeyboardArrowDown className={classes.arrowDown} />}
              </div>
            </div>
            <div data-intrcm-id="ProjectsList_v06f005h">
            </div>
            <div data-intrcm-id="ProjectsList_a3kwrfpd" className={classes.rowItemAssignedProject}>
              <div data-intrcm-id="ProjectsList_ak25b1xq" className={classes.collectionFaces}>
                {allUsersImages?.slice(0, showAvatars).map(u => <img key={u.id} className={classes.coveringAvatar} src={u?.profileImageUrl} style={{}} />)}
                {allUsersImages.length - showAvatars > 0 && <div data-intrcm-id="ProjectsList_kryunffs" onClick={toggleGroupMembersDialog} className={classes.moreAvatars}>{`+${allUsersImages.length - showAvatars}`}</div>}
              </div>
            </div>
          </div>
        ) : (
          <Collapse in={!object.hideChildren} timeout="auto" unmountOnExit={false}>
            <div data-intrcm-id="ProjectsList_sp92jsoy" className={index % 2 ? classes.rowContainerTask : classes.rowContainerTaskOdd}>
              {object.id.includes(GanttObjectsType.addingTask) ?
                (<div data-intrcm-id="ProjectsList_dooz17he" className={classes.addNewTask}
                  onClick={() => addCard(object.project)}>
                  {ganttTranslation('+ Add new task')}
                </div>) : (
                  <>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={object.name} placement="bottom">
                      <div data-intrcm-id="ProjectsList_39zoteef" className={classes.rowItemTask} onClick={() => { dispatch(openFullview(object.id, false)); dispatch(addRecentItem(object.id)) }}>
                        {object.name}
                      </div>
                    </Tooltip>
                    <div data-intrcm-id="ProjectsList_i3geue8e" className={classes.rowItemAssigned}>
                      {object.assignedResource ? (
                        <>
                          <img key={object.assignedResource} className={classes.avatar} src={user?.profileImageUrl} />
                          <div data-intrcm-id="ProjectsList_8o04liax" className={classes.resourceName}>{user?.firstname}</div>
                        </>
                      ) : <div data-intrcm-id="ProjectsList_n4m7volg" className={classes.assignButton} onClick={toggleGroupMembersDialog}>{ganttTranslation('assign')}</div>}
                    </div>
                  </>
                )}
            </div>
          </Collapse>
        )}
      <MembersDialog
        open={showUsersDialog}
        usersIds={members.map(x => x.id)}
        title={groupTranslation('GroupMembersTotal', { total: members.length })}
        onClose={toggleGroupMembersDialog}
        handleMemberSelect={handleMemberSelect}
        ganttTranslation={ganttTranslation}
      />
    </div>
  )
};

const mapStateToProps = (state) => ({
  users: state.globalData.users,
  inputService: state.services.inputService,
  groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
  groupInfo: state.results.channel,
  inputMappings: state.globalData.inputMappings,
  oldUiInput: state.oldUiInput.document,
  userData: state.userData,
  searchQuery: state.search,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
})

export default compose(withStyles(styles),
  withErrorCatcher(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(ProjectsList);
