import React, { memo, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import Edit from './Edit';
import View from './View';
import { managerGanttRef } from '@tuqqi/web';
import { isEqualJson } from '@tuqqi/common';
import { setAllowArrows } from '../grid/GridOverride';

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    containerItem: {
        marginLeft: '-16px!important',
    },
    container: {
        height: 40,
        position: 'relative',
        boxShadow: 'none',
        marginRight: -4,
        marginLeft: -8,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: 'initial',
        outline: 'none',
        width: '-webkit-fill-available',
    },
})


const checkApi = (record, originalValue = '', newValue = '') => {
    if(record?.data?.isRootRow) return

    if(isEqualJson(originalValue, newValue)) return

    if(!record) return
    record.set('name', newValue)
    if(record?.data?.isItemRow) managerGanttRef.updateTitleApi(record, newValue)
    if(record?.data?.isLane) managerGanttRef.updateGroupOfTaskNameApi(record, newValue)
}


const CellRenderTitle = memo((props) => {
    const [open, setOpen] = useState(false)
    const {classes, record, title} = props
    const [valueLocal, setValueLocal] = useState(title)
    const localRef = useRef(valueLocal)

    const disabled = !props.record?.data?.editable?.common

    useEffect(() => {
        setValueLocal(title)
    }, [title])

    useEffect(() => {
        localRef.current = valueLocal
    }, [valueLocal])
    

    const onClose = () => {
        if(!open) return
        setAllowArrows()
        checkApi(props.record, props.title, localRef.current)
        setOpen(false)
    }
    

    const X = <div className={`${classes.container} ${record.data.isItemRow ? classes.containerItem : ''} ${!disabled ? classes.cursor : ''}`}>
        {open ? 
            <Edit value={valueLocal} setValue={setValueLocal} record={record} onClose={onClose} /> :
            <View setOpen={setOpen} title={valueLocal} record={record} />}
    </div>

    return X
})

export default withStyles(styles, {withTheme: true})(CellRenderTitle)