import { Button, Icon, Menu, MenuItem } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionGroupSettings } from '../../../../../actions/collectionActions.js';
import withErrorCatcher from '../../withErrorCatcher.js';

class ClassifiedGroupMenu extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null
        }
        this.unlimitedUserText = props.t('Unclassified User')
        this.groupMemberText = props.t('Group member')
        this.limitedUserText = props.t('Classified member')
        this.adminText = props.t('Group Admin')
    }

    toggleOpenMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
    }
    onChangeClassified = (isLimited) => (event) => {
        this.toggleOpenMenu(event)
        const { dispatch, checked, user, isNew, groupService, groupUid } = this.props;

        if (isNew) {
            checked.set(user.id, { isLimited })
            dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))
        } else {
            groupService.updateUserClassification(groupUid, user.id, isLimited).then(() => {
                checked.set(user.id, { isLimited })
                dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))
            })
        }
    }

    onChangeAdmin = (isAdmin) => (event) => {
        this.toggleOpenMenu(event)
        const { dispatch, checked, user, isNew, groupService, groupUid, isLimitedGroupEnabled } = this.props;

        if (isNew) {
            checked.set(user.id, { isAdmin })
            dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))
        } else {

            if (isLimitedGroupEnabled && isAdmin) {
                this.onChangeClassified(false)
            }

            groupService.updateUserRole(groupUid, user.id, isAdmin ? 1 : 0).then(() => {
                checked.set(user.id, { isAdmin })
                dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))
            })



        }


    }

    getUserCurrentState = () => {
        const { checked, user, isLimitedGroupEnabled } = this.props;

        if (checked.has(user.id)) {
            if (checked.get(user.id).isLimited) {
                return this.limitedUserText
            } else if (checked.get(user.id).isAdmin) {
                return this.adminText
            }

        }
        return isLimitedGroupEnabled ? this.unlimitedUserText : this.groupMemberText
    }




    render = () => {
        const { user, checked, isLimitedGroupEnabled,t } = this.props;
        const { anchorEl } = this.state;
        return (
            <Fragment>
                <Button
                    disabled={!checked.has(user.id)}
                    color="primary"
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.toggleOpenMenu}
                >
                    {this.getUserCurrentState()}
                    <Icon>arrow_drop_down</Icon>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.toggleOpenMenu}
                >
                    <MenuItem onClick={isLimitedGroupEnabled ? this.onChangeClassified(false) : this.onChangeAdmin(false)} key={1}>{isLimitedGroupEnabled ? this.unlimitedUserText : this.groupMemberText}</MenuItem>
                    {isLimitedGroupEnabled ? <MenuItem onClick={this.onChangeClassified(true)} key={2}>{this.limitedUserText}</MenuItem> : null}
                    <MenuItem onClick={this.onChangeAdmin(true)} key={3}>{t('Admin')}</MenuItem>
                </Menu>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    checked: state.collection.group.checked,
    isNew: state.collection.isNew,
    groupUid: state.search.groupUid,
    ...state.services
})


export default compose(
    withTranslation('newGroupDialog'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
    ))(ClassifiedGroupMenu);
