import { Avatar, Chip, Icon, MenuItem, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withErrorCatcher from '../../../../components/Common/withErrorCatcher'
import AutosuggestChip from '../../../../components/Header/SubheaderToolbar/Filters/AutosuggestChip'
import CancelIcon from '@material-ui/icons/Cancel';

const styles = (theme) => ({
    title: {
        marginBottom: 8,
        marginTop: 24,
        fontFamily: "Rubik",
        fontSize: 18,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.25px"
    },
    suggestField: {
        marginRight: -28,
        marginLeft: -28,
    },
})

function MultiValue(props) {
    const { data } = props
  
    const style = { backgroundColor: data.backgroundColor ? data.backgroundColor : '' }
    const labelStyle = { color: '#ffffff', fontWeight: 500 }
  
    return (
      <Chip
        avatar={null}
        label={<Typography style={data.backgroundColor ? labelStyle : null}>{props.children}</Typography >}
        style={data.backgroundColor ? style : null}
        tabIndex={-1}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }
  

const __ItemDocumentType = (props) => {
    const { classes, referenceUid, onChangeReferenceUid, queryService, onChangeTitle } = props
    const [selectedDoc, setSelectedDoc] = useState(null)

    const convertValToLabel = (value) => {
        if(!value || !selectedDoc || selectedDoc.value !== value) return ''
        return selectedDoc.label
    }
    useEffect(() => {
        if(!referenceUid) {
            setSelectedDoc(null)
            return
        }
        queryService.searchItem(referenceUid)
            .then((val) => {
                if(!val) {
                    setSelectedDoc(null)
                    return
                }
                const suggested = {
                    value: val.docId,
                    label: val.title,
                }

                setSelectedDoc(suggested)
            })
    }, [referenceUid])

    const onChangeGroup = (newData) => {
        if(!newData?.length) {
            onChangeReferenceUid('')    
            return
        }
        onChangeReferenceUid(newData.slice(-1)[0].value)
        onChangeTitle(newData.slice(-1)[0].label)
    }

    const promiseOptions = inputValue => {
        return queryService.autosuggestItems(inputValue)
            .then(({isAborted, val}) => {
                if(isAborted || !val.length) return []
                const suggested = val.map(suggestion => ({
                    value: suggestion.docId,
                    label: suggestion.title,
                }))
                return suggested
            })
    }

    return <>
        <Typography variant="title" className={classes.title}>
            Choose document
        </Typography>
        <div data-intrcm-id="ItemDocumentType_nke80x4z" className={classes.suggestField}>
            <AutosuggestChip convertValToLabel={convertValToLabel} CustomMultiValue={MultiValue} isAsync placeholder={''} handleChange={onChangeGroup} suggestions={promiseOptions} label={'Item'} selected={!!selectedDoc ? [selectedDoc.value] : []} />
        </div>
    </>
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const ItemDocumentType = compose(
    withStyles(styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(__ItemDocumentType)