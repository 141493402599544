import { Avatar, Grid, Icon, IconButton, Tooltip, Typography, withStyles, Divider } from '@material-ui/core';
import { ExpandLess, ExpandMore, TrendingUp } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Analytics from "./analytics";
import classNames from "classnames";
import ItemPreviewCommentBar from './ItemPreviewCommentBar';
import { withTranslation } from 'react-i18next'

// Apply some reset
const styles = theme => {
    return {
        seenCounter: {
            "marginLeft": "4px",
            "marginRight": "11px",
            "fontFamily": "Roboto",
            "fontSize": "14px",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "fontStretch": "normal",
            "lineHeight": "1.43",
            "letterSpacing": "0.3px",
            "color": "rgba(0, 0, 0, 0.38)"
        },
        button: {
            width: 'auto',
            fontSize: theme.typography.body1.fontSize,
            color: theme.typography.display1.color,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        icon: {
            width: '1.0rem',
            height: '1.0rem',
        },
        avatar: {
            width: 19,
            height: 19,
            marginLeft: -5,
        },
        padding: {
            padding: '12px 0'
        },
        header: {
            height: 44,
            padding: '12px 16px'

        },

        seenContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
        },
        plus: {
            margin: '0 2px 0 9px'
        },
        subTitle: {
            "fontFamily": "Roboto",
            "fontSize": "14px",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "fontStretch": "normal",
            "lineHeight": "normal",
            "letterSpacing": "normal",
            "color": "rgba(151, 154, 170, 0.8)"
        },
        seen: {
            alignItems: 'center',
            display: 'flex'
        },
        spaceLeft: {
            marginLeft: 16,
        },
        spaceRight: {
            marginRight: 16,
        },
        socialDetails: {
            display: 'flex'
        },
        trendingUp: {
            fontSize: 16,
            paddingRight: 6,
            color: theme.customColors.primary.main
        }
    }
};

const numFacePile = 5

const Counter = (props) => {
    const { icon, text, show } = props
    if (!show) {
        return null
    }
    return (
        <Fragment>
            <Icon style={{ fontSize: 15 }}>{icon}</Icon>
            <span style={{ marginRight: 15, marginLeft: 3 }}>{text}</span>
        </Fragment>
    )
}

class ItemPreviewCardFooter extends Component {
    state = {
        commentsOpen: false,
        commentsCounter: 0,
        seenCounter: 0,
        taskCounter: 0,
        taskDoneCounter: 0
    }

    changeCounter = (name, numToAdd) => {
        this.setState({ [name]: (this.state[name] + numToAdd) })
    }

    logClick = () => {
        const { collaborationService, docId } = this.props;

        if (!this.state.commentsOpen) {
            collaborationService.logClick(docId).then(res => {
                this.changeCounter("seenCounter", 1)
            });
        }
    }

    checkIfTrending = () => {
        const { result, classes } = this.props;

        if (result.seen.length > 3 && result.seenCounter > 20) {
            return <TrendingUp className={classes.trendingUp} />
        }
    }

    facePile() {
        const { result, users, classes } = this.props;
        const seen = result.seen.slice(0, numFacePile)

        const avatars = users.map((user, idx) => {
            const fullName = `${user.firstname} ${user.lastname}`
            if (seen.includes(fullName)) {
                return <Avatar key={idx} alt={fullName} src={user.profileImageUrl} className={classes.avatar} />
            }
        })

        return avatars
    }

    seenTitle() {
        const { result, t } = this.props;

        let seen = <div data-intrcm-id="itemPreviewCardFooter_0k00od1l">{t('No views')}</div>
        if (result.seenCounter > 0) {
            seen = <div data-intrcm-id="itemPreviewCardFooter_dcqp8jub">
                {result.seen.map((user) => {
                    return <div data-intrcm-id="itemPreviewCardFooter_yc23w7xx">
                        {user}
                        <br />
                    </div>
                })}
            </div>
        }

        return seen
    }

    componentDidMount() {
        const { commentsCounter, seenCounter, taskCounter, taskDoneCounter } = this.props.result;

        this.setState({
            commentsCounter,
            seenCounter,
            taskCounter,
            taskDoneCounter,
            commentsOpen: false
        })
    }

    getplural = (text, number) => {
        const { t } = this.props

        const res = number > 1 ? `${text + "s"}` : `${text}`;

        return t(res)

    }

    socialDetail = (name, number, toPlural, show) => {
        return show
            ? <span class={`item-preview-card-footer-${name}`} style={{ marginRight: 12 }}>
                {number} {toPlural ? this.getplural(name, number) : name}
            </span>
            : <span />
    }

    socialDetails = () => {
        const relatedDocuments = this.props.result.relatedDocuments || [];
        const { commentsCounter, taskCounter, taskDoneCounter } = this.state

        const taskDoneText = taskDoneCounter ? taskDoneCounter : 0;

        return (
            <Fragment>
                {this.socialDetail("comment", commentsCounter, true, true)}
                {this.socialDetail("tasks", taskDoneText + "/" + taskCounter, false, taskCounter > 0)}
                {this.socialDetail("link", relatedDocuments.length, relatedDocuments.length, relatedDocuments.length)}
            </Fragment>)
    }

    render() {
        const { docId, classes, result, commentClickedHandle, hideComment } = this.props;
        const seen = this.seenTitle()
        const facePile = this.facePile()

        return (
            <Grid item container direction='row' wrap='nowrap' alignItems='center' justify='space-between' style={{ backgroundColor: '#f8f9fc', height: 57, padding: '0 16px' }}>
                <Grid style={{ width: 'auto', cursor: 'pointer' }} item container wrap='nowrap' item wrap='nowrap' alignItems='center' onClick={commentClickedHandle}>
                    {this.socialDetails()}
                </Grid>
                <Grid item container style={{ width: 'auto' }} alignItems='center'>
                    {this.checkIfTrending()}
                    <span style={{ marginRight: 2, marginTop: -2 }}>{this.state.seenCounter}</span>
                    <Icon style={{ marginRight: 13, fontSize: 18 }}>remove_red_eye</Icon>
                    <Tooltip id="tooltip-bottom-end" title={seen} placement="bottom">
                        <IconButton style={{ padding: 0, backgroundColor: 'transparent' }} size="small" aria-label="Share" >
                            {facePile}
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    previewItem: state.previewItem,
    ...state.services,
    users: state.globalData.users,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('socialData'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemPreviewCardFooter);