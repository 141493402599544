export default function getLastModifiedDay(daysToAlertOnUnusedItem, lastModifiedTime) {

    let lastModifiedDay = 0
    if (daysToAlertOnUnusedItem) {
        const MSPassFromTheLastModifiedTime = Date.now() - new Date(lastModifiedTime)
        const numMSInDay = 1000 * 60 * 60 * 24

        const numDaysPass = MSPassFromTheLastModifiedTime / numMSInDay
        if (numDaysPass >= daysToAlertOnUnusedItem) {
            lastModifiedDay = parseInt(numDaysPass)
        }
    }

    return lastModifiedDay
}