import { openFullview } from '@tuqqi/web'
import React, { useMemo } from 'react'
import { addRecentItem } from '../../../../../actions/searchNavigationActions'

export const useGanttGlobalListeners = (opts) => {
    const {dispatch} = opts

    
    const moreListeners = useMemo(() => ({
        taskClick(ev) {
            const record = ev?.taskRecord
            if(!record) return
            console.log(record?.originalData?.isItemRow, record?.id);
            if(!record?.originalData?.isItemRow || !record?.id) return

            dispatch(openFullview(record.id, false))
            dispatch(addRecentItem(record.id))
        }
    }), [])

    return moreListeners
}