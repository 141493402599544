import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';

const maxSize = 4
const MemberImageSize = 22

const styles = theme => ({
    container: {
        display: 'inline-flex',
    },
    avatarContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: MemberImageSize,
        height: MemberImageSize,
        border: `solid 1px #ffffff`,
        borderRadius: '50%',
        boxSizing: "border-box",
        marginLeft: -8,
        '&:first-child': {
            marginLeft: 0,
        },
    },
    avatar: {
        width: '100%',
        height: '100%',
        display: 'block',
    },
    plusAvatarsContainer: {
            minWidth: MemberImageSize,
            height: MemberImageSize,
            border: 'solid 1px #fff',
            marginLeft: -8,
            zIndex: 2,
            borderRadius: '50%',
            background: '#daddee',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
    },
    plusAvatarsText: {
            paddingLeft: 4,
            paddingRight: 4,
            color: theme.customColors.primary.main,
            fontWeight: 500,
            fontSize: 9,
            fontFamily: 'Rubik',
    },
})

class Members extends PureComponent {
    render() {
        const { classes, users, } = this.props

        let members = users.slice(0, maxSize).map(member => {
            return (
                <span key={member.userUUID} className={classes.avatarContainer}>
                    <img className={classes.avatar} src={member.profileImageUrl} alt={member.userName} />
                </span>
            )
        })

        return (
            <Grid className={classes.container}>
                {members}
                {maxSize && users.length - maxSize > 0 ?
                    <span className={classes.plusAvatarsContainer}>
                        <div data-intrcm-id="Members_4a2cdat6" className={classes.plusAvatarsText}>{'+'+(users.length - maxSize)}</div>
                    </span>
                    : null}
            </Grid>
        )
    }
}


const mapStateToProps = (state) => ({
})

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        null
    ))(Members);