import React from 'react';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import {withStyles, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import ChecklistRuleTemplate from '../../../../components/Kanban/ListRules/ChecklistRuleTemplate';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    dialog: {
        overflow: 'auto',
        width: 500
    }
})

class DefaultChecklistModal extends React.Component {
    state = {
        checklistData: {}
    }

    listenChecklist = (prevChecklistId) => {
        const {checklist, checklistId,t} = this.props
        if(checklistId === prevChecklistId) return

        const checklistData = !!Object.keys(checklist).length ? checklist : { title: t('Checklist'), tasks: [] }
        this.updateChecklistData(checklistData)
    }

    componentDidMount() {
        this.listenChecklist(this.props)
    }

    componentDidUpdate(prevProps) {
        this.listenChecklist(prevProps.checklistId)
    }

    updateChecklistData = (data) => {
        this.setState({ checklistData: { ...data } })
    }

    onSave = () => {
        const {onSave, checklistId} = this.props
        const {checklistData} = this.state

        onSave(checklistData, checklistId)
    }

    onDelete = () => {
        const {onDelete, checklistId} = this.props

        onDelete(checklistId)
    }    

    render() {
        const { open, onClose, isEdit, classes,t } = this.props;
        const { checklistData } = this.state;

        return <Dialog
            classes={{paper: classes.dialog}}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
                <DialogContent>
                    <ChecklistRuleTemplate checklist={checklistData} updateData={this.updateChecklistData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('Cancel')}
                    </Button>
                    {isEdit && <Button onClick={this.onDelete} color="primary">
                       {t('Delete')}
                    </Button>}
                    <Button onClick={this.onSave} color="primary">
                        {isEdit ? t('Update') : t('Save')}
                    </Button>
                </DialogActions>
        </Dialog>
    }
}

export default compose(
    withTranslation('admin'),
    withStyles(styles, {withTheme: true}),
)(DefaultChecklistModal);