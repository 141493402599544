import React, { useCallback } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { getOS } from '../../../../../utils/OSutils';
import CreateNewGroupView from './CreateNewGroupView';
import CreateNewOthersView from './CreateNewOthersView';
import { managerLibraryView } from '@tuqqi/web';
import CreateNewLibraryContactsView from './CreateNewLibraryContactsView';
import CreateNewLibraryCategoryView from './CreateNewLibraryCategoryView';
import CreateNewEventView from './CreateNewEventView';
import CreateNewAdminView from './CreateNewAdminView';

const styles = (theme) => {
    return ({
        container: {
            borderRadius: 8,
            // padding: 16,
            zIndex: '1202!important',
            position: 'absolute',
            width: 220,
            // maxWidth: theme.constants.notificationsNavigationWidth,
            maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 24px)`,
            height: 'fit-content',
            right: 208,
            left: 'auto',
            bottom: 0,
            top: -2,
            backgroundColor: 'rgba(35, 35, 38, 1)',
            paddingTop:4,
            paddingBottom:4,
            overflow:"auto"
        },
        containerDark: {
            zIndex: '1201!important',
            position: 'fixed',

            width: '100vw',
            height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
            left: 0,
            right: 0,
            bottom: 0,
            top: theme.constants.defaultToolbarHeight,
            'background-color': 'rgba(0, 0, 0, 0)'
        },
        hidden: {
            zIndex: -1,
            visibility: 'hidden',
        },
        menuItemGroupCreate: {
            padding: "6px 8px 6px 8px",
            marginLeft:4,
            marginRight:4,
            borderRadius:6,
      
            color: "rgba(255,255,255,0.9)",
            backgroundColor: `rgba(183, 184, 189, 0.2)`,
            "&:hover": {
                backgroundColor: theme.customColors.primary.b500
    
            }
        },
        divie:
            {
                marginTop:4,marginBottom:4,backgroundColor:"rgba(183, 184, 189, 0.07)"
            }
    });
}

const isCommonCreateListener = (e) => {
    if(!!e.ctrlKey && e.shiftKey && e.key === '=') return true
    return false
}
  
const isMacCreateListener = (e) => {
    if (e.metaKey && e.shiftKey && e.key === '=') return true
    return false
}
  

export const isShouldCreateOpen = (e) => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return isMacCreateListener(e)
        case 'linux':
        case 'windows':
            return isCommonCreateListener(e)
        default:
            return false
    }
}
export const getCreateKeysLabel = () => {
    const os = getOS()
    switch (os) {
        case 'macos':
            return 'CMD + +'
        case 'linux':
        case 'windows':
            return "CTRL + +"
        default:
            return ""
    }
}

const CreateNewModal = (props) => {
    const {isFocused, classes, pathname } = props


    const getContent = () => {
        if(pathname.includes("admin")) return <CreateNewAdminView />
        if(pathname.includes("library") && managerLibraryView.getLibraryView() === 'contacts') return <CreateNewLibraryContactsView />
        if(pathname.includes("library") && managerLibraryView.getLibraryView() === 'category' && managerLibraryView.getCatUid()) return <CreateNewLibraryCategoryView categoryUid={managerLibraryView.getCatUid()} />
        if((pathname.includes("library") && managerLibraryView.getLibraryView() === 'calendar') || pathname.includes("orgscheduler")) return <CreateNewEventView />

        return pathname.includes("group") ? <CreateNewGroupView /> : <CreateNewOthersView />
    }

    const wrapperClassname = [classes.containerDark, !isFocused ? classes.hidden : ''].join(" ");
    return (<div data-intrcm-id="CreateNewModal_1vadsssdad" id="createNew-navigation-wrapper" className={wrapperClassname}>
            <Paper id="createNew-navigation-dialog" className={classes.container}>
                {getContent()}
            </Paper>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFocused: state.createNewModal.isFocused,
        subscription: state.globalData.currentSubscription,
        pathname: state.wrapper.pathname,
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, { withTheme: true }),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(CreateNewModal)