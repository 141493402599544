import React, { Component } from 'react';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LabelIcon from '@material-ui/icons/Label';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsDrawer from '../../components/App/Drawers/settingsDrawer';
import AppContent from '../../components/App/appContent';
import PageContentWrapper from '../../components/App/PageComponents/pageContentWrapper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { showSnackbar } from '../../../actions/appActions'
import { withTranslation } from 'react-i18next';


const styles = theme => ({
    textChannel: {
        color: '#212121',
        fontSize:theme.typography.subheading.fontSize,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
      },

      listLabel: {
        fontSize: '18px',
        marginBottom: 16,
    },

    paperStyle: {
        marginBottom: 40,
    },

    paperInner: {
        padding: 16,
    },

    addButton:{
        marginTop: 16
    },


    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },

    menu: {
    width: 200,
    },  
});

class QueryViews extends Component {

    state = {
        isTitle: false,
    }

    onChange = name => event => {
        this.setState({ [name]: event.target.value });

        if(name === "coll_name"){
            if(event.target.value){
                this.setState({ isTitle: true });
            }
            else{
                this.setState({ isTitle: false });
            }
        }
    }

    handleDelete = (queryId)  => () => {
        const {userSettingsService, dispatch, t } = this.props;

        userSettingsService.deleteView(queryId).then(() => {
            userSettingsService.getUserData().then((userData) => {
                dispatch({
                    type: 'USER_DATA_LOAD', data: {
                        ...userData
                    }
                });

                this.props.dispatch(showSnackbar(t('Alert Deleted!') ));
            })

            this.props.dispatch(showSnackbar(t('Alert Deleting') ));
        })
    }

    handleClickAdd = () => {
        const { userSettingsService, dispatch, t } = this.props;
        const { coll_query, coll_name } = this.state

        this.props.dispatch(showSnackbar(t('Saving') ));
        
        const newQuery = {
            "Query": coll_query,
            "Name": coll_name
        }

        this.setState({
            isTitle: false,
            coll_query: "",
            coll_name: ""
        })

        userSettingsService.postView(newQuery)
            .then((query) => {
                userSettingsService.getUserData().then((userData) => {
                    dispatch({
                        type: 'USER_DATA_LOAD', data: {
                            ...userData
                        }
                    });

                    this.props.dispatch(showSnackbar(t('Added!') ));
                })
            })
    }

    openQuery = (query) => {
        this.props.dispatch({ type: 'SEARCH_QUERY', query: { query: query.query, page: 0 } })
        this.props.dispatch({ type: 'DATA_UPDATE' });
        this.props.dispatch(push({ pathname: 'search', search: '?searchquery=' + encodeURIComponent(query.query) }))
    }

    render() {
        const { classes, userData, dispatch, t } = this.props;

        return <div data-intrcm-id="queryViews_8jjcedze"> 
        <AppContent>
            <PageContentWrapper drawer={<SettingsDrawer selected="query"/>}>
                <Grid spacing={16}>
                        <Typography variant="title" style={{ marginBottom: '15px' }}>
                            {t('Manage alerts')}
                        </Typography>
                        <Paper className={classes.paperStyle}>
                            <div data-intrcm-id="queryViews_8w0wy9ew" className={classes.paperInner}>
                                <Typography variant="body2" className={classes.listLabel} gutterBottom>
                                {t('Add new alerts')}
                                </Typography>
                                <TextField fullWidth label={t("Name")} id="coll_name" InputLabelProps={{shrink: true}} placeholder={t("Enter alert name")} margin="normal" 
                                            onChange={this.onChange("coll_name")} value={this.state.coll_name}/>
                                <TextField fullWidth label={t("Query")} id="coll_query" InputLabelProps={{shrink: true}} placeholder={t("Create an alert about")} margin="normal" 
                                            onChange={this.onChange("coll_query")} value={this.state.coll_query}/>
                                <Button onClick={this.handleClickAdd} 
                                    disabled={!this.state.isTitle}
                                    color="primary"
                                    variant={'raised'} 
                                    aria-label="Add alert" 
                                    className={classes.addButton}>
                                    {t('Add alerts')}
                                </Button>
                            </div>
                        </Paper>
                        <Paper>
                            <div data-intrcm-id="queryViews_6j6o0gld" className={classes.paperInner}> 
                                <Typography variant="body2" className={classes.listLabel} gutterBottom>
                                {t('Alerts')}
                                </Typography>
                                <List>
                                    {userData.queryViews.map((query, idx) => {
                                        return <ListItem 
                                        button
                                        onClick={() => this.openQuery(query)}
                                        key={idx} >
                                            <ListItemIcon>
                                                <LabelIcon />
                                            </ListItemIcon>
                                        <ListItemText
                                            primary={<span className={classes.textChannel}>{query.name}:</span>}
                                            secondary={query.query}
                                        />
                                        <ListItemSecondaryAction onClick={this.handleDelete(query.id)}>
                                            <IconButton aria-label="Delete alert">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    })}
                                </List>
                            </div>
                        </Paper>
                </Grid>
            </PageContentWrapper>
        </AppContent>
    </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services,
    globalData: state.globalData,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),withTranslation('queryViews')
)(QueryViews);