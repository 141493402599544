import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { EditFieldPencilIcon, TableFieldReadOnlyTooltip, managerGanttRef, managerTableKeepGlobalData } from '@tuqqi/web'
import { isRTLValue } from '@tuqqi/web'
import { useTranslation } from 'react-i18next'
import { setDenyArrows } from '../grid/GridOverride'
import { getStringAsNum, hexToRGB } from '@tuqqi/common'
import { compose } from 'recompose'

const styles = theme => ({
    inputWrapPointer: {
        cursor: 'pointer'
    },
    hoverValue: {
        background: hexToRGB(theme.palette.primary.main, 0.04),
        borderRadius: 4
    },
    valueWrapOuterRTL: {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right'
    },
    pencil: {
        marginLeft: 4,
        display: 'flex'
    },
    text: {
        whiteSpace: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 12,
    },
    valueWrap: {
        borderRadius: 4,
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        minHeight: 28,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 8,
        paddingRight: 8,
    },
    valueWrapOuter: {
        paddingLeft: 12,
        paddingRight: 12,
        marginLeft: -8,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    input: {
        padding: 0,
        paddingRight: 8,
        paddingLeft: 8,
        outline: 0,
        border: 0,
        backgroundColor: '#EDEDF0',
        color: '#a9a9a9',
        width: '100%',
        resize: 'none',
        ...theme.typography.body2,
        fontWeight: 400,
        fontSize: 12, 
    },
    inputWrap: {
        marginLeft: 12,
        marginRight: 12,
        width: 'fit-content',
        background: '#EDEDF0',
        marginTop: 'auto',
        marginBottom: 'auto',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        border: `1px solid transparent`,
    },
    singleLine: {
        overflow: 'hidden',
        height: 28,
        alignItems: 'center',
    },
})


const getActualWidth = (record) => {
    if(!managerGanttRef.gantt) return 10
    const column = managerGanttRef.gantt.instance.columns.visibleColumns.find(x => x.type === 'percentdone')
    if(!column) return 10
    if(typeof column.width !== 'number') return 10

    let gap = 8

    return column.width - gap
}

const getValue = (percent) => {
    const val = percent
    if(val === 0 || val === '0') return 0
    if (!val) return ''
    if(typeof val === 'number') return val
    const x = getStringAsNum(val)
    return x
}
const View = memo((props) => {
    const {classes, percentDone, record} = props

    const value = getValue(percentDone)
    const formated = !((value ?? '') + '') ? '' : `${Math.round(value)}%`

    const [t] = useTranslation("gantt")
    const actualWidth = getActualWidth(record)
    
    const disabled = record.data?.isItemRow && !props.record?.data?.editable?.common

    const label = managerTableKeepGlobalData.tTable('Progress')
    const [isHoverValue, setIsHoverValue] = useState(false)
    const allowHover = !disabled && !!isHoverValue && record.data.isItemRow
    const isRTl = useMemo(() => isRTLValue(value), [value])

    const onMouseEnter = useCallback(() => {
        if(!!allowHover) return
        setIsHoverValue(true)
    }, [allowHover])

    const onMouseLeave = useCallback(() => {
        setIsHoverValue(false)
    }, [])


    const Pensil = !allowHover ? null : <span className={classes.pencil}>
        <EditFieldPencilIcon />
    </span>


    const wrapStyle = useMemo(() => {
        return {
            width: actualWidth - 24
        }
    }, [actualWidth])

    const wrapStyleValue = useMemo(() => {
        return {
            maxWidth: actualWidth - 40
        }
    }, [actualWidth])


    const onClickEdit = useCallback(() => {
        if(!record.data.isItemRow) return
        if(disabled) return

        props.setOpen(true)
        setDenyArrows()
    }, [disabled])


        const NoValue = <div data-intrcm-id="CellRenderSimpleText_avduzczo" dir="auto" onClick={onClickEdit} style={wrapStyle} className={`${classes.inputWrap} ${classes.singleLine}`}>
        <div data-intrcm-id="CellRenderSimpleText_dp9qj9l2" className={classes.input}>{`${t("Add")} ${label.toLowerCase()}...`}</div>
        </div>

        const Ttt = <div data-intrcm-id="CellRenderNumberText_qry68pa0" style={wrapStyleValue} dir="auto" className={`${classes.text}`} >{formated}</div>

        const Value = <div data-intrcm-id="CellRenderSimpleText_797gfsi2" onClick={onClickEdit} className={`${classes.valueWrapOuter} ${isRTl ? classes.valueWrapOuterRTL : ''}`}>
        <div data-intrcm-id="CellRenderSimpleText_ayivpmn4" className={`${classes.valueWrap} ${allowHover ? classes.hoverValue : ''} ${allowHover ? classes.inputWrapPointer : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
            {Ttt}
            {Pensil}
        </div>
        </div>


    const noVal = value === '0' ? false : (!value && value !== 0)

    const X = !noVal ? Value : NoValue
    if(disabled) return <TableFieldReadOnlyTooltip component={X} />


      return X
})

export default compose(
    withStyles(styles, {withTheme: true}),
)(View)