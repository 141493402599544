import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createCustomTheme } from '../../../../../utils/theming';
import { resetAllSuggestStyle, resetPrevSuggestStyle } from '../../../../../utils/launchpadNavigation/launchpadUtils';
import { getAllCollection } from '../../../../views/groups/groupUtils';
import goToCollection from '../../../../actions/goToCollection';
import { openFullview } from '@tuqqi/web';
import { onClickDefaultLaunchpadOption } from '../../../../../utils/launchpadNavigation/launchpadNavigation';
import { useOrgMenuResolvers } from '../../../../../hooks/useOrgMenu/useOrgMenuResolvers';
import { OrgMenuOptions } from '../LaunchpadLanding/OrgMenuOptions';
import { addRecentItem } from '../../../../../actions/searchNavigationActions';

const styles = (theme) => ({
    container: {
        padding: '24px 16px',
        paddingTop: 0,
    },
})

function _LaunchpadSelectedCategory(props) {
    const {
        allowedOptions,
        classes, 
        isFocused,
        dispatch, 
        openCollections, 
        userCollections, 
        brandingColor,
        orgMenuItems,
        selectedCategory,
        allowedOrgMenuItemsUids,
    } = props
    const theme = createCustomTheme(brandingColor)
    const [suggestIndex, setSuggestIndex] = useState(-1)
    const [isSuggestNavigationMode, setIsSuggestNavigationMode] = useState(false)
    
    const {getSubLayer} = useOrgMenuResolvers()

    const item = useMemo(() => {
        const item = orgMenuItems.find(x => x.id === selectedCategory)
        return item
    }, [orgMenuItems, selectedCategory])

    const layers = useMemo(() => {
        if(!item) return []
        return getSubLayer(orgMenuItems, item)
    }, [orgMenuItems, selectedCategory, item])

    const allGroups = useMemo(() => getAllCollection(openCollections, userCollections), [openCollections, userCollections])

    const goToGroup = useCallback((groupUid) => {
        const group = allGroups.find(col => col.collectionGroup.groupUid === groupUid)
        if(!group) return
        dispatch({type: 'BLUR_LAUNCHPAD'})
        goToCollection('/' + group.title, dispatch, group.collectionUid, group.collectionGroup.groupUid, group)();
    }, [allGroups])

    const openItem = useCallback((docId) => {
        dispatch(openFullview(docId, true))
        dispatch(addRecentItem(docId))
    }, [])

    const openLink = useCallback((link) => {
        window.open(link, '_blank')
    }, [])

    const goToItem = useCallback((item, isDisabled) => {
        if(!!isDisabled) return

        switch(item.type){
            case 0: {
                dispatch({
                    type: 'FOCUS_LAUNCHPAD_ORG_CATEGORY',
                    selectedCategory: item.id,
                    title: item.customTitle,
                    allowedOrgMenuItemsUids,
                })
                return
            }
            case 1:
                return goToGroup(item.referenceUid)
            case 2:
                return openItem(item.referenceUid)
            case 10:
                return openLink(item.referenceUid)
            default:
                return
        }
    }, [goToGroup, openItem, openLink, allowedOrgMenuItemsUids])

    useEffect(() => {
        setIsSuggestNavigationMode(false)
        setSuggestIndex(-1)
        resetAllSuggestStyle()
    }, [selectedCategory])

    const listenArrows = useCallback(({key}) => {
        const container = document.getElementById('launchpad-navigation-dialog');
        if (!container) return;
    
        if (!isSuggestNavigationMode) setIsSuggestNavigationMode(true)
    
        const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
        if (!suggestionsList.length) return;
        if ((suggestIndex === -1 || suggestIndex === 0) && key === 'ArrowUp') return;
        if (suggestIndex === suggestionsList.length - 1 && key === 'ArrowDown') return;
    
        const newInd = key === 'ArrowDown' ? suggestIndex + 1 : suggestIndex - 1;
        const selector = suggestionsList[newInd];
    
        selector && (selector.style.backgroundColor = theme.primary.main);
        selector && (selector.style.display = 'flex');
    
        resetPrevSuggestStyle(suggestIndex, container)
        setSuggestIndex(newInd)
    }, [isSuggestNavigationMode, suggestIndex])

    const onClickDefaultOption = useCallback((referenceUid) => {
        onClickDefaultLaunchpadOption(referenceUid, dispatch)
    }, [])

    const getOptionForSuggested = useCallback((isOrgMenu, suggestCaseStr, isCategory) => {
        if(isOrgMenu === 'true') {
            const option = orgMenuItems.find(o => isCategory === 'true' ? o.id == suggestCaseStr : o.referenceUid === suggestCaseStr)
            return option
        }
        const option = allowedOptions.find(o => isCategory === 'true' ? o.id === suggestCaseStr : o.referenceUid === suggestCaseStr)
        return option
    }, [allowedOptions, orgMenuItems])


    const goToSelectedSuggest = useCallback(() => {
        const container = document.getElementById('launchpad-navigation-dialog');
        if (!container) return;
    
        const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
        const selector = suggestionsList[suggestIndex];
    
        const suggestCaseStr1 = selector.getAttribute('suggestCase');
        const [isOrgMenu, suggestCaseStr, isCategory] = suggestCaseStr1?.split('||');
        if(!suggestCaseStr) return

        const option = getOptionForSuggested(isOrgMenu, suggestCaseStr, isCategory)
        if(!option && !!suggestCaseStr.includes('default:')) {
            onClickDefaultOption(suggestCaseStr)
            resetAllSuggestStyle()
            setIsSuggestNavigationMode(false)
            setSuggestIndex(-1)
        }
        if(!option) return

        goToItem(option)
        resetAllSuggestStyle()
        setIsSuggestNavigationMode(false)
        setSuggestIndex(-1)
    }, [suggestIndex, goToItem, onClickDefaultOption, getOptionForSuggested])

    const listenSuggests = useCallback((e) => {
        if(!isFocused) return
        if((e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
            e.preventDefault()
            listenArrows(e)
            return
        }
        if (e.key !== 'Enter' || !isSuggestNavigationMode) return;
        goToSelectedSuggest(e)

    }, [theme, isSuggestNavigationMode, isFocused])

    useEffect(() => {
        window.addEventListener("keydown", listenSuggests)
        return () => {
            window.removeEventListener("keydown", listenSuggests)
        }
    })

    const OrgMenuOptionsInner = !layers?.length ? null :
        <OrgMenuOptions goToItem={goToItem} title={item?.customTitle ?? 'Untitled'} firstLayerOrgMenu={layers} allowedOrgMenuItemsUids={allowedOrgMenuItemsUids} />

    return (
        <div data-intrcm-id="LaunchpadSelectedCategory_bb31vqfk" className={classes.container} >
            {OrgMenuOptionsInner}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      selectedCategory: state.launchpadNavigationOrgMenu.selectedCategory,
      allowedOrgMenuItemsUids: state.launchpadNavigationOrgMenu.allowedOrgMenuItemsUids,
      token: state.data.auth.token,
      orgMenuItems: state.feedNavigation.orgMenuItems,
      isFocused: state.launchpadNavigation.isFocused,
      allowedOptions: state.launchpadNavigation.allowedOptions,
      userCollections: state.data.userData.userCollections.data, 
      openCollections: state.data.globalData.openCollections.data,
      brandingColor: state.userData?.profileData?.org?.brandingColor ?? 'default'
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export const LaunchpadSelectedCategory = compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(_LaunchpadSelectedCategory)
