import React from 'react';

 const MessageIcon = (props) => {
        const { color } = props

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.68044 3.88562L11.9802 7.18543M8.68044 3.88562L10.566 2L13.8658 5.29983L11.9802 7.18543L8.68044 3.88562ZM8.68044 3.88562L2.27601 10.29C2.15099 10.415 2.08075 10.5846 2.08075 10.7614V13.7851H5.10444C5.28125 13.7851 5.45082 13.7149 5.57584 13.5898L11.9802 7.18543L8.68044 3.88562Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
}

export default  MessageIcon
