import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ChatsContent from './ChatsContent';
import withErrorCatcher from '../../../Common/withErrorCatcher';

const styles = (theme) => ({
    container: {
        borderRadius: 12,
        zIndex: '1202!important',
        position: 'absolute',
        width: theme.constants.notificationsNavigationWidth,
        maxWidth: theme.constants.notificationsNavigationWidth,
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 24px)`,
        height: 'fit-content',
        right: 16,
        left: 'auto',
        bottom: 0,
        top: -2,
        backgroundColor: '#FFF'
    },
    containerDark: {
        zIndex: '1201!important',
        position: 'fixed',
        width: '100vw',
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
        left: 0,
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        'background-color': 'rgba(0, 0, 0, 0.45)'
    },
    hidden: {
        zIndex: -1,
        visibility: 'hidden',
    }
})

const NotificationsModal = (props) => {
    const {isFocused, classes} = props

    const wrapperClassname = [classes.containerDark, !isFocused ? classes.hidden : ''].join(" ");
    return (
        <div data-intrcm-id="ChatsModal_1vahjoek" id="chats-navigation-wrapper" className={wrapperClassname}>
            <Paper id="chats-navigation-dialog" className={classes.container} >
                <ChatsContent />
            </Paper>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFocused: state.chatsModal.isFocused,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationsModal)