import React, { Component, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {AdminDrawer, NewUiWrapper, loadLocale, managerAdminDrawer, useSelectedAdminDrawer, managerAdminSubscriptionType, useSubscriptionType} from '@tuqqi/web'
import CircularProgress from '@material-ui/core/CircularProgress';
import AppContent from '../../../components/App/appContent';
import PageContentWrapper from '../../../components/App/PageComponents/pageContentWrapper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { store } from '../../../../index.js';
import { push } from 'react-router-redux';
import { getOptionFromPath } from './getOptionFromPath';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions';


const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 48px)'
    }
});

const UID = 'wrap-wrap-admin-main'

const AdminInner = (props) => {
    const {lang, children, classes, dispatch, subscriptionType} = props
    const drawerInfo = useSelectedAdminDrawer(UID)

    useEffect(() => {
        managerAdminSubscriptionType.setSubscriptionType(subscriptionType)
        const x = getOptionFromPath(window.location.pathname)

        if(!x) return
        if(!managerAdminSubscriptionType.getIsDisabledView(subscriptionType, x)) return

        managerAdminDrawer.setOption({prop: 'dashboard', uid: 'dashboard'})
        dispatch(push('/admin'))
    }, [subscriptionType])

    useEffect(() => {
        loadLocale(lang)
    }, [lang])

    useEffect(() => {
        managerAdminDrawer.init()
        const x = getOptionFromPath(window.location.pathname)
        if(!x) return
        if(managerAdminSubscriptionType.getIsDisabledView(subscriptionType, x)) {
            managerAdminDrawer.setOption({prop: 'dashboard', uid: 'dashboard'})
            dispatch(push('/admin'))
            return
        }

        managerAdminDrawer.setOption({prop: x, uid: x})
        return () => {
            managerAdminDrawer.setOption({prop: 'dashboard', uid: 'dashboard'})
            managerAdminDrawer.destroy()
        }
    }, [])

    const openSubscriptionDialog = (reason) => {
        if(!reason) return
        dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }

	useEffect(() => {
		switch (drawerInfo.prop) {
		  case "home":
			dispatch(push("/admin"));
			break;
		  //Account
		  case "org-preferences":
			dispatch(push("/admin/organizationsettings"));
			break;
		  case "manage-users":
			if (drawerInfo.uid !== "manage-users") break;
			dispatch(push("/admin/users"));
			break;
		  case "billing":
			dispatch(push("/admin/billing"));
			break;
		  // Analytics
		  case "time-tracking":
			dispatch(push("/admin/timetracking"));
			break;
		  case "dashboards":
			if (drawerInfo.uid !== "dashboards") break;
			dispatch(push("/admin/analytics"));
			break;
		  // Customization
		  case "menu":
			if (drawerInfo.uid !== "menu") break;
			dispatch(push("/admin/navigation"));
			break;
		  case "forms":
			if (drawerInfo.uid !== "forms") break;
			dispatch(push("/admin/custominputmappings"));
			break;
		  //   case "templates":
		  //     dispatch(push("/admin/analytics"));
		  //     break;
		  //   case "status-colors":
		  //      dispatch(push("/admin/analytics"));
		  //     break;
		  case "important-content":
			dispatch(push("/admin/important-content"));
			break;
		  case "teams":
			dispatch(push("/admin/teams"));
			break;
			case "dashboards":
				dispatch(push("/admin/analytics"));
				break;
		  // NOT used
		  case "integrations":
			dispatch(push("/admin/integrations"));
			break;
		  // System
		  case "logs":
			dispatch(push("/admin/logs"));
			break;
		  case "recycle-bin":
			dispatch(push("/admin/archive"));
			break;
		  default:
		}
	  }, [drawerInfo.prop, drawerInfo.uid]);

    const callbacks = {
        onShowSubscriptionModal: openSubscriptionDialog
    }

    const Drawer = <NewUiWrapper store={store} callbacks={callbacks}>
        <AdminDrawer />
    </NewUiWrapper>

const bgColor = useMemo(() => {
	switch (drawerInfo.prop) {
	  case "home":
		return "#F9F9F9"
	  //Account
	  case "org-preferences":
		return "#F9F9F9"
	  case "billing":
		return "#F9F9F9"
	  // Customization
	  case "menu":
		return "#F9F9F9"
	  case "important-content":
		return "#F9F9F9"
	  default:
		return "white"
	}
}, [drawerInfo.prop])

    return <div data-intrcm-id="AdminNew_j05j983p" className={classes.container} style={{background: bgColor }}>
        {Drawer}
        {children}
    </div>
}

class AdminNew extends Component {
    state = {
        isAdmin: false,
        isLoading: true
    }

    componentDidMount(){
        const { adminService, userData } = this.props;

        adminService.isAdmin(userData.profileData.auth0_id).then( res => {
            this.setState({ isAdmin: res, isLoading: false })
        })
    }

    renderNotAuthorized = () => {
        return <AppContent >
            <PageContentWrapper >
                <Grid spacing={16}>
                    <Typography variant="title" style={{ marginBottom: '15px' }}>
                        Not authorized
                    </Typography>
                </Grid>
            </PageContentWrapper>
        </AppContent>
    }

    renderAuthorized = () => {
        const { lang, children, classes, dispatch, subscriptionType } = this.props;

        return <AdminInner subscriptionType={subscriptionType} dispatch={dispatch} classes={classes} lang={lang}>
            {children}
        </AdminInner>
    }

    allowRender = () => {
        return !this.state.isLoading && this.state.isAdmin
    }

    render() {
        const { classes } = this.props;

        const spinner = <div data-intrcm-id="AdminNew_zxwr9kwz" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>

        if(!!this.state.isLoading) return spinner
        if(!this.allowRender()) return this.renderNotAuthorized()
        
        return this.renderAuthorized()
    }
}

const mapStateToProps = (state) => ({
    ...state.services,
    userData: state.userData,
    subscriptionType: state?.globalData?.currentSubscription?.subscriptionType ?? 0,
    lang: state?.userData?.profileData?.lang ?? 'en-US',
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AdminNew);
