import { withStyles } from '@material-ui/core';
import { getAllCollections, getGroupSuggestions } from '@tuqqi/common';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SuggestedGroupItem } from '../Common/SuggestedGroupItem';

const styles = (theme) => ({
    container: {
        marginTop: 24
    },
    label: {
        marginBottom: 8,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        color: '#737373'
    }
})

function _LaunchpadGroupsSection(props) {
    const {classes, searchText, userCollections, openCollections, selectedCategory} = props
    const [t] = useTranslation("search")
    const [filtered, setFiltered] = useState([])

    const allCollections = useMemo(() => {
        return getAllCollections(openCollections, userCollections)
    }, openCollections, userCollections)

    useEffect(() => {
        const colls = getGroupSuggestions(allCollections, searchText.toLowerCase(), 8);
        setFiltered(colls)
    }, [searchText, allCollections])

    if(!filtered.length) return null
    return (
        <div data-intrcm-id="LaunchpadGroupsSection_ykntp01h" className={classes.container} >
            <div data-intrcm-id="LaunchpadGroupsSection_926n0woi" className={classes.label}>{t("Groups")}</div>
            {filtered.map((col) => <SuggestedGroupItem selectedCategory={selectedCategory} key={col.collectionUid} collection={col} />)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedCategory: state.launchpadNavigation.selectedCategory,
    userCollections: state.data.userData.userCollections.data, 
    openCollections: state.data.globalData.openCollections.data,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export const LaunchpadGroupsSection = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_LaunchpadGroupsSection)

