import React from 'react'

const  NoTasksIcon  = () =>  {
return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.35" d="M32 38H12C8.686 38 6 35.314 6 32V12C6 8.686 8.686 6 12 6H32C35.314 6 38 8.686 38 12V32C38 35.314 35.314 38 32 38Z" fill="#B1B1B3"/>
<path d="M19.9995 30C19.4875 30 18.9755 29.804 18.5855 29.414L12.5855 23.414C11.8035 22.632 11.8035 21.368 12.5855 20.586C13.3675 19.804 14.6315 19.804 15.4135 20.586L19.9995 25.172L28.5855 16.586C29.3675 15.804 30.6315 15.804 31.4135 16.586C32.1955 17.368 32.1955 18.632 31.4135 19.414L21.4135 29.414C21.0235 29.804 20.5115 30 19.9995 30Z" fill="#B1B1B3"/>
<path d="M37.7203 10.2798C37.8843 10.8278 38.0003 11.3978 38.0003 11.9998V31.9998C38.0003 35.3138 35.3143 37.9998 32.0003 37.9998H12.0003C11.3983 37.9998 10.8283 37.8838 10.2803 37.7198C11.0243 40.1898 13.2883 41.9998 16.0003 41.9998H36.0003C39.3143 41.9998 42.0003 39.3138 42.0003 35.9998V15.9998C42.0003 13.2878 40.1903 11.0238 37.7203 10.2798Z" fill="#B1B1B3"/>
</svg>
}

export default NoTasksIcon


