import { FormControl, TextField, withStyles, FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import React from 'react';
import { registerPlugin } from 'react-filepond';
import { withTranslation } from 'react-i18next';
import UploadImag from '../../../../components/Common/uploadImag';
import IconSelecter from '../components/IconSelecter.js';
import PickDefaultGroup from './PickDefaultGroup';
import PickSpecificGroups from './PickSpecificGroups';
import { compose } from 'recompose';
registerPlugin(FilePondPluginImagePreview)

const styles = theme => ({
    formControl: {
        margin: '0 5px',
        display: 'flex'
    },
})

class CreateCustomInputSettings extends React.Component {
    render() {
        const { classes, handleChange, title, dataTypeFilter, description, icon, backgroundUrl, onLoadImg, defaultCollectionUid, setDefaultCollectionUid, specificGroupsUids, setSpecificGroupsUids,t} = this.props;

		return <div data-intrcm-id="CreateCustomInputSettings_nmkio6gn">
			<Grid style={{ display: 'inline-flex' }} direction='column'>
				<IconSelecter icon={icon} onChange={handleChange('icon')} />

				<FormControl className={classes.formControl}>
					<TextField label={t('Input title')} required={true}  error={title?false:true} onChange={handleChange('title')} value={title} margin='dense' />
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField label={t('Data type')} disabled onChange={handleChange('dataTypeFilter')} value={dataTypeFilter} margin='dense' />
					 <FormHelperText id="my-helper-text">{t('Note that the data type name is not the same as the data type that exists in the system')}.</FormHelperText>

				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField label={t('Description')} onChange={handleChange('description')} value={description} margin='dense' />
				</FormControl>
				<FormControl className={classes.formControl}>
					<PickDefaultGroup setDefaultCollectionUid={setDefaultCollectionUid} defaultCollectionUid={defaultCollectionUid} />
				</FormControl>
				<FormControl className={classes.formControl}>
					<PickSpecificGroups defaultGroupUid={defaultCollectionUid} dataTypeFilter={dataTypeFilter} setSpecificGroupsUids={setSpecificGroupsUids} specificGroupsUids={specificGroupsUids} />
				</FormControl>
			</Grid>
            
			<UploadImag withTitle={true} imageUrl={backgroundUrl} onLoadImg={onLoadImg} />
		</div>
    }
}

export default compose(withStyles(styles),withTranslation('admin'))(CreateCustomInputSettings);