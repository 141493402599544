
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { USER_NOTIFICATION_REFRESH } from '../../../../actionTypes/actionTypes';
import { drawerToggle } from '../../../../actions/appActions';
import NotificationIcon from "../../Common/Icons/NotificationIcon";
import NotifPoint from './NotificationsModal/NotifPoint';
import { withMixpanel } from '../../Common/withMixpanel';
import { hexToRGB } from '../../../../utils/hexToRGB';
import withErrorCatcher from '../../Common/withErrorCatcher';
import SvgIcon from '@material-ui/core/SvgIcon';


// Apply some reset
const styles = theme => { 
    const whiteOpacity016 = hexToRGB('#FFFFFF', 0.16)
    return ({
   
    notificationPoint: {
        bottom: 20,
        left: 23
    },
   
    notificationButton: {
        margin: 0,
        padding:0,
         
         "&:hover": {
            backgroundColor: 'inherit',
          },
          
    },
  
      notificatioIconContainer: {
        height: 32,
        width:32,
        borderRadius: 16,
        "&:hover": {
            backgroundColor: whiteOpacity016,
          },
      },
      icon : {
        color:"rgba(255,255,255,0.87)",
        height:18,
        width:18
    }
})}

const Icon = () => {
    return <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0001 6.34998C14.5703 6.34998 13.2137 6.97502 12.2242 8.06303C11.2365 9.14892 10.6913 10.6091 10.6913 12.1198V13.913L10.6913 13.9222L10.6877 13.9307L9.40072 16.9376C9.33708 17.0863 9.27264 17.2368 9.23033 17.3672C9.18564 17.505 9.1334 17.711 9.17803 17.9454C9.23418 18.2403 9.40586 18.5005 9.65488 18.6682C9.8528 18.8014 10.0628 18.8345 10.207 18.8476C10.3436 18.86 10.5073 18.86 10.669 18.86H21.3312C21.4929 18.86 21.6566 18.86 21.7932 18.8476C21.9374 18.8345 22.1474 18.8014 22.3453 18.6682C22.5943 18.5005 22.766 18.2403 22.8221 17.9454C22.8668 17.711 22.8145 17.505 22.7698 17.3672C22.7275 17.2368 22.6631 17.0863 22.5995 16.9377L21.3125 13.9307L21.3089 13.9222L21.3088 13.913V12.1198C21.3088 10.6091 20.7636 9.14892 19.776 8.06303C18.7864 6.97502 17.4298 6.34998 16.0001 6.34998ZM13.2229 8.97137C13.9713 8.14847 14.9719 7.69998 16.0001 7.69998C17.0282 7.69998 18.0289 8.14847 18.7773 8.97137C19.5277 9.7964 19.9588 10.928 19.9588 12.1198V13.913L19.9588 13.9246C19.9587 13.9661 19.9586 14.0344 19.9671 14.1045C19.9745 14.1648 19.9867 14.2243 20.0036 14.2826C20.0234 14.3505 20.0504 14.4132 20.0668 14.4513L20.0714 14.4619L21.3759 17.51H10.6243L11.9288 14.4619L11.9334 14.4513C11.9498 14.4132 11.9768 14.3505 11.9966 14.2826C12.0135 14.2243 12.0257 14.1648 12.0331 14.1045C12.0416 14.0344 12.0414 13.9661 12.0413 13.9246L12.0413 13.913V12.1198C12.0413 10.928 12.4725 9.7964 13.2229 8.97137Z" fill="white"/>
    <path d="M15.3309 19.9324C15.1394 19.6126 14.7249 19.5086 14.4051 19.7001C14.0852 19.8916 13.9812 20.3062 14.1727 20.626C14.3613 20.9409 14.6305 21.1995 14.951 21.3782C15.2715 21.5568 15.6332 21.65 16.0001 21.65C16.3671 21.65 16.7288 21.5568 17.0492 21.3782C17.3698 21.1995 17.639 20.9409 17.8275 20.626C18.019 20.3062 17.915 19.8916 17.5952 19.7001C17.2754 19.5086 16.8608 19.6126 16.6693 19.9324C16.6044 20.0407 16.5095 20.1335 16.3919 20.199C16.2742 20.2646 16.139 20.3 16.0001 20.3C15.8613 20.3 15.7261 20.2646 15.6083 20.199C15.4907 20.1335 15.3958 20.0407 15.3309 19.9324Z" fill="white"/>
    </g>
    </svg>    
}


class NotificationToolbar extends PureComponent {

    showNotifications = () => {
        const { dispatch, wrapper, mixpanelTrack } = this.props;

        // if (!wrapper.notificationsOpen) this.refreshNotifications()

        // dispatch(drawerToggle({ notificationsOpen: !wrapper.notificationsOpen }))
        dispatch({type: 'TOGGLE_NOTIFICATIONS_MODAL'})
        mixpanelTrack('Opened Notification Drawer (Web)', {});
    }
    refreshNotifications = () => {
        const { notificationService, notifications, dispatch  } = this.props
  
        notificationService.getNotifications().then(newNotifications => {             
            if (newNotifications && newNotifications.length &&
                (newNotifications.length !== notifications.length ||
                    newNotifications.some(newNot => notifications.every(oldNot => oldNot.id !== newNot.id)))) {
                    dispatch({ type: USER_NOTIFICATION_REFRESH, notifications: newNotifications });
                }
        })
    }

    isFeed = (props) => {
        const { wrapper: {isFeed} } = props;
        return isFeed
    }

    componentDidUpdate(prevProps) {
        const {dispatch, wrapper} = this.props
        const isPrevFeed = this.isFeed(prevProps)
        const isNextFeed = this.isFeed(this.props)

        if(isPrevFeed === isNextFeed) return
        if(!isPrevFeed && isNextFeed && window.innerWidth >= 1680) {
            if(!wrapper.notificationsOpen) dispatch(drawerToggle({ notificationsOpen: true }))
            this.refreshNotifications()
            return
        }
    }
    componentWillReceiveProps(nextProps) {
        const {dispatch, wrapper} = this.props
        const isPrevFeed = this.isFeed(this.props)
        const isNextFeed = this.isFeed(nextProps)

        if(isPrevFeed && !isNextFeed && wrapper.notificationsOpen) {
            dispatch(drawerToggle({ notificationsOpen: false }))
            return
        }
    }

    componentDidMount() {
        const {dispatch, wrapper} = this.props

        const isNextFeed = this.isFeed(this.props)
        if(!isNextFeed) return

        if(wrapper.notificationsOpen && window.innerWidth < 1680) {
            dispatch(drawerToggle({ notificationsOpen: false }))
            return
        }
        if(!wrapper.notificationsOpen && window.innerWidth >= 1680) dispatch(drawerToggle({ notificationsOpen: true }))
        this.refreshNotifications()
    }

    render() {
        const { classes, t, lastSawNotification } = this.props;

        return (
            <Tooltip title={t('Notifications')} placement="bottom">
                <IconButton data-intrcm-id="NotificationToolbar_55kydula"  classes={{ label: classes.notificatioIconContainer, root: classes.notificationButton }} aria-label="Notifications" onClick={this.showNotifications} >
                    <NotifPoint classes={{ point: classes.notificationPoint }} lastSaw={lastSawNotification} isChatNotification={false} />
                    {/* <NotificationIcon /> */}
                    <Icon />
                </IconButton>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => ({
    lastSawNotification: state.userData.profileData.lastSawNotification,
    notifications: state.userData.notifications,
    wrapper: state.wrapper,
    ...state.services
})

export default compose(withStyles(styles),
    withErrorCatcher("Notification toolbar crashed"),
    withTranslation('feedToolbar'),
    withMixpanel,
    connect(mapStateToProps))(NotificationToolbar);