import { Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from "recompose";
import ReportIcon from '@material-ui/icons/Report'

const styles = theme => ({
    noResultsView: {
        padding: 20,
        display: 'flex',
        alignItems: 'center'
    },
    noResultsText: {
        fontSize: 18,
        paddingLeft: 16,
        color: theme.customColors.primary.b300,
    },
    noResultsIcon: {
        color: theme.customColors.primary.b300,
        fontSize: 35
    }
});

const NoResultsView = ({ classes, t }) => {
    return (<div data-intrcm-id="indexx_ydujc19c" variant="outlined" square elevation={3} className={classes.noResultsView}>
        <ReportIcon className={classes.noResultsIcon} /> <Typography variant="h4" component="h4" className={classes.noResultsText}>{t(`Seems like there are no items found, please adjust the search terms`)}</Typography>
    </div>)
}

export default compose(
    withStyles(styles),
    withTranslation("feedWidgets"),
)(NoResultsView);
