import React, { Fragment } from 'react'
import { withStyles, TextField, Grid, Icon, Typography } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withErrorCatcher from '../../Common/withErrorCatcher';
import ContactFullViewField from './ContactFullViewField';
import moment from 'moment';

const styles = theme => ({
    icon: {
        marginRight: 16,
        color: 'rgba(0, 0, 0, 0.62)',
        width: 'auto',
        marginBottom: 'auto',

    }
})

const Field = withStyles(styles)(({ icon, value, classes }) => {
    return <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: 8 }}>
        <Grid container item className={classes.icon}>
            <Icon >{icon}</Icon>
        </Grid>
        <Grid item>
            <Typography variant="body1" style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>{value}</Typography>
        </Grid>
    </Grid>
})

class EventFullView extends React.Component {

    getEventTime = (from, to) => {
        const momentFrom = moment(from);
        const momentTo = moment(to);

        if (momentFrom.isSame(momentTo, 'date')) {
            return `${momentFrom.format("dddd, MMM D, YYYY · HH:mm")} - ${momentTo.format("HH:mm")}`
        } else {
            return `${momentFrom.format("ddd, MMM D, YY, HH:mm")} - ${momentTo.format("ddd, MMM D, YY, HH:mm")}`
        }
    }

    render() {
        const { classes, item } = this.props;
        const { dateTimeFrom, dateTimeTo, link } = item
        const { location, attendees, description } = item.parsedJsonData
        
        return <Grid>
            <Field icon='access_time' value={this.getEventTime(dateTimeFrom, dateTimeTo)} />
            {attendees && <Field icon='people'
                value={<Fragment>
                    {attendees.map(x => <div data-intrcm-id="EventFullView_7x16zl7x">
                        {`${x.firstName} ${x.lastName}`} {'<'}<a href={`mailto:${x.email}`}>{x.email}</a>{'>'} {x.userUUID === item.userUUID ? '(Creator)' : ''}
                    </div>)}
                </Fragment>} />}
            {location && <Field icon='room' value={location} />}
            {link && <Field icon='link' value={<a target="_blank" href={link}>Go To Event</a>} />}
            {description && <Field icon='notes' value={description} />}
        </Grid>
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    ...state.fullView,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(EventFullView)