export default class signupService {
    constructor(token) {
        this.token = token;
        // this.auth = auth;
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    getHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token
        });
    }

    // Create organization
    createOrganization(templateName) {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/new/', {
            method: 'POST',
            body: JSON.stringify(templateName),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            }).catch(x => window.location.reload());
    }


    // Create organization
    createOrganizationStep1() {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/step1', {
            method: 'POST',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    createOrganizationStep2() {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/step2', {
            method: 'POST',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    createOrganizationStep3() {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/step3', {
            method: 'POST',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    createOrganizationStep4() {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/step4', {
            method: 'POST',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    createOrganizationStep5(templateName) {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/organization/step5/', {
            method: 'POST',
            body: JSON.stringify(templateName),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }




    updateOrganizationTemplate(templateName) {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + `/organization/update-template?templateName=${templateName}`, {
            method: 'PUT',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    getOrganizationTitle() {
        var myHeaders = this.getHeaders()

        return fetch(process.env.API_SERVER + '/organization/org-title', {
            method: 'GET',
            headers: myHeaders
        })
            .then(function (res) {
                return res.text();
            });
    }

    checkOrganizationExist(orgName) {
        var myHeaders = this.getHeaders()

        if (orgName) {
            return fetch(process.env.API_SERVER + `/organization/isExist?orgName=${orgName}`, {
                method: 'GET',
                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }
}