import { Divider, Typography, withStyles } from '@material-ui/core';
import React from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher';
import SearchMinimizeIcon from '../Common/Icons/SearchIcons/SearchMinimizeIcon';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 16,
        marginTop: 0,
        marginBottom: 0
    },
    containerMinimize: {
        textAlign: 'center',
        paddingTop: 12,
    },
    title: {
        maxWidth: 150,
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: 'normal',
        color: '#0C0C0D',
        textAlign: 'center',
        display: 'block',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    subtitle: {
        marginTop: 8,
        display: 'block',
        fontWeight: 400,
        fontFamily: 'Rubik',
        fontSize: 11,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: theme.newUI.color.gray,
    },
    iconSearchDrawer: {
        width: 40,
        height: 40,
        position: 'relative',
        top: 7,
        left: 7,
    }
})
const __SearchInfoDrawer = (props) => {
    const {classes, query, isDrawerOpen} = props
    const [t] = useTranslation("groupDrawerTranslate");

    return (
        <div data-intrcm-id="SearchInfoDrawer_c42emqpt" className={isDrawerOpen ? classes.container : classes.containerMinimize}>
            {isDrawerOpen ? <Typography className={classes.title}>{query}</Typography> : <SearchMinimizeIcon/>}
            {isDrawerOpen && <Typography dir='auto' className={classes.subtitle}>{t('Search Data Files Media and etc')}</Typography>}
        </div>
    )
}

const mapStateToProps = (state) => ({
})

export const SearchInfoDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, null)
)(__SearchInfoDrawer);