import {
    GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE,
    GLOBAL_LOAD, USER_COLLECTION_LOAD,
    USER_DATA_LOAD, USER_SUBSCRIBED_LOAD, GLOBAL_SUBSCRIPTION_LOAD
} from '../actionTypes/actionTypes';

export function globalDataLoad(data) {
    return { type: GLOBAL_LOAD, data }
}

export function userDataLoad(data) {
    return { type: USER_DATA_LOAD, data }
}

export function userSubscribedTagsLoad(data) {
    return { type: USER_SUBSCRIBED_LOAD, subscribedTags: data }
}

export function userCollectionLoad(data) {
    return { type: USER_COLLECTION_LOAD, collections: [...data] }
}

export function updateDefaultInputTemplate(updatedTemplate) {
    return { type: GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE, updatedTemplate }
}

export function updateSubscription(currentSubscription) {
    return { type: GLOBAL_SUBSCRIPTION_LOAD, currentSubscription }
} 