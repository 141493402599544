

import React from 'react';

const ExpandLefrDrawerMenuRigthIcon = (props) => {
    const { color } = props
    
    return <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
    <path d="M1 1L5 5L1 9" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export default ExpandLefrDrawerMenuRigthIcon
