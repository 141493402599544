import { Avatar, withStyles, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';

// Apply some reset
const styles = theme => {
    return {
        container: {
            flexDirection: 'row', 
            display: 'flex',
            alignItems: 'center',
        },
        facesNumber: {
            color: '#979aaa', 
            marginRight: 8,
            paddingRight: 8,
        },

        smallAvatar: {
            width: 24,
            height: 24,
            marginLeft: -8,
            border: 'solid 1px #ffffff',
        },
        mediumAvatar: {
            width: 32,
            height: 32,
            marginLeft: -8,
        },
    }
};

const defaultProps = {
    numFacePile: 10,
    size: 'small',
    variant: 'caption',
}

class Faces extends Component {
    state = {
        styles: {}
    }

    componentDidMount(){
        const { classes, size } = this.props;
        const styles = {};

        if ( size === 'small'){
            styles.avatar = classes.smallAvatar
        }

        this.setState({ styles })
    }

    render() {
        const { usersFaces, numFacePile, variant, openUsersDialog, classes, key } = this.props;
        const { styles } = this.state;
        const users = usersFaces.slice(0, numFacePile);

        const avatars = <div data-intrcm-id="Faces_h4sp8tyg" className={classes.container} onClick={openUsersDialog} key={key} >
            <Typography variant={variant} className={classes.facesNumber}>{usersFaces.length}</Typography>

            {users.map((user, idx) => {
                return <Avatar key={idx} alt={user.fullName} src={user.profileImageUrl} className={styles.avatar} />
            })}
        </div>

        return avatars
    }
}

Faces.defaultProps = defaultProps;

export default compose(withStyles(styles))(Faces);