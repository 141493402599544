
export const getIsoDate = (d) => d.toLocaleTimeString().split(':').slice(0,2).join(":")

export const getEvent = (id, title, date) => ({id, title, start: date}) 

export const formatTitle = (title) => title.length < 17 ? title : title.substring(0,17) + '...' 

const colors = {
  "red": 'rgb(255,0,0)',
  "blue": 'rgb(0,0,255)', 
  "yellow": 'rgb(255,255,0)', 
  "navy": 'rgb(0,0,128)', 
  "orange": 'rgb(255,165,0)', 
  "green": 'rgb(0,128,0)', 
  "aqua": 'rgb(0,255,255)', 
  "black": 'rgb(0,0,0)', 
  'white': 'rgb(255,255,255)'
}
export function shadeColor(somecolor, percent) {

   const isHex = somecolor.includes('#')
   let color = somecolor
   if (!isHex) {
      const isRGB = somecolor.includes("rgb")
      if(isRGB) {
        color = rgbToHex(somecolor)
      } else {
        const isDefaultColor = Object.keys(colors).includes(somecolor.toLowerCase())
        if(isDefaultColor) {
          color = rgbToHex(colors[somecolor.toLowerCase()])
        }
      }
   }

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}
export function hexToRGB(hex, alpha) {

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  export function rgbToHex(color) {
    color = ""+ color;
    if (!color || color.indexOf("rgb") < 0) {
        return;
    }

    if (color.charAt(0) == "#") {
        return color;
    }

    var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
        r = parseInt(nums[2], 10).toString(16),
        g = parseInt(nums[3], 10).toString(16),
        b = parseInt(nums[4], 10).toString(16);

    return "#"+ (
        (r.length == 1 ? "0"+ r : r) +
        (g.length == 1 ? "0"+ g : g) +
        (b.length == 1 ? "0"+ b : b)
    );
}

const defaultHexColors = {
  red: '#ff0000',
  blue: '#0000ff', 
  yellow: '#ffff00', 
  navy: '#000080', 
  orange: '#ffa500', 
  green: '#008000', 
  aqua: '#00ffff', 
  black: '#000000', 
  white: '#ffffff',
  brown: "#a52a2a",
  grey: '#808080'
}

const defaultColorToHex = (color) => {
  if(!color || !(color in defaultHexColors)) return defaultHexColors.grey
  return defaultHexColors[color]
}

export const proccessLabelColorCalendar = (color, alp) => {
  const correctColor = color && color.includes('#') ? color : defaultColorToHex(color)
  const cc = hexToRG1(correctColor, alp ?? 0.24)
  return cc
}

function hexToRG1(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}
