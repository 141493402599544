import { MenuItem, Select, InputLabel } from '@material-ui/core'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { defaultDataTypes } from '../../../../components/Common/Utils/defaultDataTypes'

class DataTypeSelector extends Component {

    getAllDataTypes = () => {
        const { globalData } = this.props
        const { inputMappings } = globalData

        const dataTypes = [...inputMappings, ...defaultDataTypes()]
            .filter(x => x.customDataMapping.dataTypeFilter)
            .map(x => ({
                dataType: x.customDataMapping.dataTypeFilter,
                title: x.title
            }))
        return dataTypes
    }

    render() {
        const { onChange, value,t } = this.props
        const dataTypes = this.getAllDataTypes()

        return <div data-intrcm-id="DataTypeSelector_015unyvp" style={{ margin: '0 5px', marginTop: 5, width: '100%'}}>
            <Select inputProps={{id: 'specific_datatype'}} required error={!value} fullWidth onChange={onChange} value={value}>
                {dataTypes.map(x => {
                    return <MenuItem key={x.dataType} value={x.dataType}>{x.title}</MenuItem>
                })}
            </Select>
            <InputLabel style={{fontSize: 13}} htmlFor="specific_datatype">{t('Select specific datatype')}</InputLabel>
        </div>
    }
}

const mapStateToProps = (state) => ({
    globalData: state.globalData
})

export default  compose( withTranslation('admin'),connect(mapStateToProps))(DataTypeSelector)
