import React, { useCallback, useMemo, useState } from 'react'
import { showSnackbar } from '../../../../../actions/appActions';
import { checkIfSubscribed } from '../../../../../utils/usersHelpers';
import { mixpanelTrack } from '../../Utils/mixpanelUtils';
import EditChannel from "../../../../components/Widgets/RightWidgets/Search/editChannel";
import { createTagPayload } from "../../../../../utils/tagHelpers";

const getAdditionalOptions = (ctx) => {
    const { t, isSubscribed, onClickGeneralSubscribe, openEditModal, isTopic } = ctx

    const opts = [
        {
            id: 8,
            icon: isSubscribed ? 'Unsubscribe' : 'Subscribe',
            title: isSubscribed ? t("Unsubscribe") : t("Subscribe"),
            onClick: () => {
                onClickGeneralSubscribe()
            }
        },
    ]

    if(!isTopic) opts.unshift({
        id: 7,
        icon: 'EditTag',
        title: t("Edit"),
        onClick: () => {
            openEditModal()
        }
    })

    return opts
}

export const useGetTagAdditionalOptions = (props) => {
    const { 
        t,
        isTopic,
        currentTagInfo,
        userData,
        dispatch,
        userSettingsService,
        subscribedChannels, 
        query,
     } = props;

    const [showEditTag, setShowEditTag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const closeEditTag = useCallback(() => {
        mixpanelTrack(`Channel page view - close edit tag`, {})
        setShowEditTag(false)
    }, [])
    
      const openEditModal = useCallback(() => {
        mixpanelTrack(`Channel page view - open edit tag`, {})
        setShowEditTag(true)
    }, [])

    const isSubscribed = useMemo(() => checkIfSubscribed(query.slice(1), userData), [query, userData])
    
    const subscribe = useCallback(() => {
        if (isLoading) return;
        mixpanelTrack(`Channel page view - subscribe`, {})
        const tagInfo = createTagPayload(query.slice(1), null, 'user_tag')
        setIsLoading(true);
        dispatch(showSnackbar("subscribing..."));
    
        userSettingsService.subscribe(tagInfo).then((_) => {
          userSettingsService.getUserData().then((userData) => {
            dispatch(showSnackbar("Subscribed successfully!"));
            dispatch({
              type: "USER_DATA_LOAD",
              data: {
                ...userData,
              },
            });
            setIsLoading(false);
          });
        });
    }, [isLoading]);

    const unsubscribe = useCallback(() => {
        if (isLoading) return;
        mixpanelTrack(`Channel page view - unsubscribe`, {})
        const subscribedTag = subscribedChannels.find(
            (tag) => tag.tag.toLowerCase() === query.slice(1).toLowerCase()
        );
        setIsLoading(true);
        dispatch(showSnackbar("unsubscribing..."));
        userSettingsService.unsubscribe(subscribedTag.id).then((_) => {
            userSettingsService.getUserData().then((userData) => {
                dispatch(showSnackbar("Unsubscribed successfully!"));
                dispatch({
                    type: "USER_DATA_LOAD",
                    data: {
                        ...userData,
                    },
                });
                setIsLoading(false);
            });
        });
    }, [isLoading, subscribedChannels]);

    const onClickGeneralSubscribe = useCallback(() => {
        mixpanelTrack(`tag page view - subscribe/unsubscribe`, {})
        if (isSubscribed) {
          unsubscribe()
          return
        }
        subscribe()
    }, [isSubscribed, subscribe, unsubscribe])

    const options = useMemo(() => {
        return getAdditionalOptions({ 
            t, 
            isTopic,
            isSubscribed,
            onClickGeneralSubscribe,
            openEditModal,
        })
    }, [isSubscribed, onClickGeneralSubscribe, openEditModal, isTopic])

    const EditTag = <EditChannel tagInfo={currentTagInfo} open={showEditTag} onClose={closeEditTag} />

    return [options, EditTag]
}