import { Grid, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChatBubbleIcon from '@material-ui/icons/Chat';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { goTo } from "../../../../../actions/goToActions";
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import UserAvatar from '../../../../components/Common/UserAvatar';


const styles = theme => ({

    expertListItem: {
        padding: '4px 0',
        marginBottom: 11
    },
    expertAvatar: {
        width: 28,
        height: 28,
    },
    expertText: {
        paddingRight: 14
    },
    expertFont: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    exportTitle: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "rgba(151, 154, 170, 0.8)",
        marginTop: 2
    },
    chatExpertIcon: {
        fontSize: 16,
        color: 'darkgrey',
        cursor: 'default'
    },
    expertChatWrapper: {
        backgroundColor: "white",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
        padding: 5
    },
    percent: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#31aa39",
        marginRight: 7
    }
});



class TopicExpert extends PureComponent {

    gotoUser = () => {
        const { dispatch, user } = this.props;
        goTo('@' + user.tag, dispatch)()
    }

    render() {
        const { classes, dispatch, user, percent } = this.props;

        const { profileImageUrl, firstname, lastname, title, level, tag } = user

        return (<ListItem
            button
            key={tag}
            onClick={this.gotoUser}
            className={classes.expertListItem}>
            <UserAvatar userUUID={user.auth0_id} />
            <ListItemText
                className={classes.expertText}
                classes={{ primary: classes.expertFont, secondary: classes.exportTitle }}
                primary={`${firstname} ${lastname}`}
                secondary={title} />
            {typeof percent == "number" ? <Grid item className={classes.percent}>{(percent).toFixed()}%</Grid> : null}
            <IconButton disabled>
                <ChatBubbleIcon fontSize="small" />
            </IconButton>
        </ListItem>)
    }
}



const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading User Info, Please Contact Tuqqi Support"),
    connect(mapDispatchToProps))(TopicExpert);