import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { parseImageUrl } from '@tuqqi/common';
import { SmallStarIcon } from '../../../Common/Icons/UserIcons/SmallStarIcon';
import { UserScoreboardIcon } from '../../../Common/Icons/UserIcons/UserScoreboardIcon';
import { UserSettingsIcon } from '../../../Common/Icons/UserIcons/UserSettingsIcon';
import { UserHelpCenterIcon } from '../../../Common/Icons/UserIcons/UserHelpCenterIcon';
import { UserPrivacyPolicyIcon } from '../../../Common/Icons/UserIcons/UserPrivacyPolicyIcon';
import { UserAdminSettingsIcon } from '../../../Common/Icons/UserIcons/UserAdminSettingsIcon';
import { UserLogoutIcon } from '../../../Common/Icons/UserIcons/UserLogoutIcon';
import goTo from '../../../../actions/goTo';
import UserNavigationOption from './UserNavigationOption';
import PrivacyPolicy from '../../../Common/privacyPolicy';
import { withAuth0 } from '@auth0/auth0-react';
import { push } from 'react-router-redux';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { goToEditProfile } from '../../../../actions/goToEditProfile';

const styles = (theme) => ({
    content: {
        width: 'inherit',
    },
    userInfo: {
        margin: 16,
        marginLeft: 0,
        marginBottom: 0,
        marginRight: 0,
        paddingRight: 16,
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderBottomColor: '#EDEDF0',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    logoutBorder: {
        borderTopColor: '#EDEDF0',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
    },
    avatar: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 16,
        width: 64,
        height: 64,
        borderRadius: 32,
        background: '#EDEDF0'
    },
    titles: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#0C0C0D'
        },
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0.14763,
        color: '#0C0C0D',
    },
    subTitle: {
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#737373',
    },
    points: {
        width: 'fit-content',
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 16,
        display: 'inline-flex',
        background: '#F9D006',
        borderRadius: 16,
        paddingRight: 6,
        paddingLeft: 6,
    },
    pointsText: {
        marginLeft: 2,
        marginTop: 2,
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#0C0C0D',
    },
    options: {
        marginLeft: 0,
        marginRight: 0,
    },
})

const getMainOptions = (t, isAdmin) => {
    const options = []
    options.push({ type: 'scoreboard', icon: <UserScoreboardIcon />, title: t("Scoreboard"), subtitle: t("Organization scoreboard") })
    options.push({ type: 'settings', icon: <UserSettingsIcon />, title: t("User preferences"), subtitle: t("Edit/view your settings") })
    if (!!isAdmin) options.push({ type: 'admin', icon: <UserAdminSettingsIcon />, title: t("Admin settings"), subtitle: t("See your account activitiy data") })
    options.push({ type: 'helpCenter', icon: <UserHelpCenterIcon />, title: t("Help center"), subtitle: t("Tuqqi help center") })
    // options.push({ type: 'privacyPolicy', icon: <UserPrivacyPolicyIcon />, title: t("Privacy policy"), subtitle: t("Tuqqi privacy policy") })
    return options
}

const getLogoutOption = (t) => {
    return { type: 'logout', icon: <UserLogoutIcon />, title: t("Sign out"), subtitle: t("Click to sign out") }
}

const UserInfoContent = (props) => {
    const { classes, profileData, cloudfront, t, dispatch, userSettingsService, auth0 } = props

    const [openDialogPrivacy, setOpenDialogPrivacy] = useState(false)
    const parseUrl = useMemo(() => {
        if (cloudfront && cloudfront.data && cloudfront.data.signedFiles) {
            return parseImageUrl(cloudfront.data);
        } else {
            return () => ''
        }
    }, [cloudfront])
    const userImg = useMemo(() => !profileData?.profileImageUrl ? '' : parseUrl(profileData.profileImageUrl), [profileData, parseUrl])
    const userTitle = useMemo(() => !profileData ? t("Unknown") : `${profileData.firstname} ${profileData.lastname}`, [profileData])
    const userTag = useMemo(() => !profileData?.tag ? '' : `${profileData.tag.startsWith("@") ? '' : '@'}${profileData.tag}`, [profileData])
    const userJob = useMemo(() => !profileData?.title ? '' : `${profileData.title}`, [profileData])
    const userPoints = useMemo(() => !profileData?.points ? 0 : `${profileData.points}`, [profileData])
    const isAdmin = useMemo(() => !profileData?.role ? false : profileData.role === 'Admin', [profileData])

    const goToUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!profileData.tag) return
        const userTag = profileData.tag.startsWith("@") ? '' : '@' + profileData.tag

        dispatch({ type: 'BLUR_USER_MODAL' })
        goTo(userTag, dispatch)()
    }

    const mainOptions = useMemo(() => getMainOptions(t, isAdmin), [isAdmin, t])
    const logoutOption = useMemo(() => getLogoutOption(t), [t])

    const UserInfo = <div data-intrcm-id="UserInfoContent_xnm9qlb8" className={classes.userInfo}>
        {!!userImg ?
            <img onClick={goToUser} alt="user-img" src={userImg} className={classes.avatar} /> :
            <div data-intrcm-id="UserInfoContent_bc1zu6g5" className={classes.avatar} />}
        <div data-intrcm-id="UserInfoContent_ii1j7xug" className={classes.titles}>
            <div data-intrcm-id="UserInfoContent_cohxs7mi" onClick={goToUser} className={classes.title}>{userTitle}</div>
            <div data-intrcm-id="UserInfoContent_4c3jxgwj" className={classes.subTitle}>{`${userTag}${!userJob ? '' : ' · ' + userJob}`}</div>
            <div data-intrcm-id="UserInfoContent_8u8o2rue" className={classes.points}>
                <SmallStarIcon />
                <div data-intrcm-id="UserInfoContent_z4kd7zge" className={classes.pointsText}>{`${userPoints} ${t("pts")}.`}</div>
            </div>
        </div>
    </div>

    const onClickOption = (type) => {
        switch (type) {
            case 'scoreboard':
                dispatch({ type: 'BLUR_USER_MODAL' })
                dispatch(push('/scoreboard'));
                break;
            case 'settings':
                dispatch({ type: 'BLUR_USER_MODAL' })
                goToEditProfile(dispatch)
                break;
            case 'admin':
                dispatch({ type: 'BLUR_USER_MODAL' })
                dispatch(push('/admin'));
                break;
            case 'helpCenter':
                const helpSenterLink = t('drawerListItemTerms:helpLink')
                window.open(helpSenterLink, '_blank');
                break;
            case 'privacyPolicy':
                // setOpenDialogPrivacy(true)
                break;
            case 'logout':
                dispatch({ type: 'BLUR_USER_MODAL' })
                userSettingsService.logOut().then(() => {
                    dispatch({ type: 'USER_LOGOUT' });
                    auth0.logout({ returnTo: process.env.AUTH0_REDIRECT })
                })
                break;
            default:
        }
    }

    const Options = <div data-intrcm-id="UserInfoContent_gpiolgr4" className={classes.options}>
        {mainOptions.map((o) => <UserNavigationOption onClick={onClickOption} key={o.type} className="" o={o} />)}
        <UserNavigationOption isLast onClick={onClickOption} className={classes.logoutBorder} o={logoutOption} />
    </div>

    return (
        <>
            <div data-intrcm-id="UserInfoContent_d5761wmb" className={classes.content}>
                {UserInfo}
                {Options}
            </div>
            <PrivacyPolicy
                open={openDialogPrivacy}
                close={() => { setOpenDialogPrivacy(false) }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cloudfront: state.data.globalData.cloudfront,
        profileData: state.userData?.profileData,
        userSettingsService: state.services.userSettingsService
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withAuth0,
    withErrorCatcher('User info content crashed'),
    withStyles(styles, { withTheme: true }),
    withTranslation('userSettingsDrawer', 'drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps),
)(UserInfoContent)