export const OPEN_SUBSCRIPTION_MODAL = 'OPEN_SUBSCRIPTION_MODAL'
export const CLOSE_SUBSCRIPTION_MODAL = 'CLOSE_SUBSCRIPTION_MODAL'
export const INIT_SUBSCRIPTION_MODAL = 'INIT_SUBSCRIPTION_MODAL'

export const openSubscriptionModal = () => ({
    type: OPEN_SUBSCRIPTION_MODAL
})

export const closeSubscriptionModal = () => ({
    type: CLOSE_SUBSCRIPTION_MODAL
})

export const initSubscriptionModal = ({reason, mode = 'existed', title, description, features, onClose, allowClose}) => ({
    type: INIT_SUBSCRIPTION_MODAL,
    reason,
    mode,
    title,
    description,
    features,
    onClose,
    allowClose
})