import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';


const styles = theme => ({

});

class UserPageStatistics extends Component {

    render() {


        return (<p>Statistics</p>)

    }
}

const mapStateToProps = (state) => ({
    collaborationService: state.services.collaborationService,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(UserPageStatistics);