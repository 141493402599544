import React, { Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withErrorCatcher from "../Common/withErrorCatcher.js";

const styles = (theme) => ({
  categoryTitle: {
    marginTop: 35,
    height: 48,
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "23px",
    letterSpacing: "normal",
    color: "#21212d",
    marginBottom: 5,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  categoryDescription: {
    height: 36,
    fontFamily: "RubikRoman, sans-serif",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: '17px',
    letterSpacing: "normal",
    color: "#adadad",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  assignedTo: {
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#adadad",
  },
  authorsHolder: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: 'absolute',
    bottom: 20
  },
  authors: {
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#565662",
  },
  usersImages: {
    marginRight: "7px",
  },
  imgAvatar: {
    borderRadius: "50%",
    top: "2px",
    position: "relative",
    border: "2px solid white",
  },
});

const CustomButton = withStyles(theme => ({
  root: {
    borderRadius: "16px",
    backgroundColor: '#a654c6',
    padding: "2px 8px",
    fontFamily: "Rubik",
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    color: "#ffffff",
    maxWidth: 118,
    textTransform: "lowercase",

    "&:hover": {
      backgroundColor: "#B834A4",
    },
  },
}))(Button);

class Category extends React.Component {
  state = {
    authors: "",
    authorsPhotos: [],
  };

  componentDidMount() {
    const { list, users } = this.props;

    const uuids = list.cards.map((item) => item.doc.userUUID);
    const uniqueUuids = [...new Set(uuids)];

    let usersList = [];

    for (let i = 0; i < uniqueUuids.length; i++) {
      const user = users.find((item) => item.auth0_id === uniqueUuids[i]);
      !!user && usersList.push(user);
    }

    const usersData = usersList.map(
      (item) => `${item.firstname} ${item.lastname}`
    );

    let userPhotos = usersList.map((item) => item.profileImageUrl);
    let currentArticleAuthors = usersData?.join?.(", ") || "No Authors Yet";

    if(usersData.length > 3 && !!usersData.length) {
      userPhotos = userPhotos.slice(0, 1);
      currentArticleAuthors = `${usersData[0]} ${usersData.length - 1}+`;
    }

    this.setState({
      authors: currentArticleAuthors,
      authorsPhotos: userPhotos,
    });
  }

  calcAvatarPisition = (index) => {
    if (!index) {
      return "0px";
    }
    return `-${index * 10}px`;
  };

  calcBlockMargin = (length) => {
    if (!length && length === 1) {
      return "0px";
    }
    return `-${(length - 1) * 10}px`;
  };

  render() {
    const { classes, dispatch, list } = this.props;
    const id = list.id;

    const description =
      list.description ||
      "Category has no description";

    const renderCategory = (idCategory) => {
      if (idCategory !== -1) {
        return (
          <div data-intrcm-id="category_09scpp02"
            className={classes.item}
            onClick={() => {
              dispatch(
                push({
                  pathname: `/group/category/${idCategory}`,
                  state: list,
                })
              );
            }}
          >
            <div data-intrcm-id="category_eszhaevp" className={classes.itemInner}>
              <CustomButton>{`${list.cards.length} articles inside`}</CustomButton>
              <div data-intrcm-id="category_qzqa693j" className={classes.categoryInfo}>
                <Typography variant="h3" className={classes.categoryTitle}>
                  {list.title}
                </Typography>
                <Typography className={classes.categoryDescription}>
                  {description}
                </Typography>
              </div>
              <div data-intrcm-id="category_oxdhss69" className={classes.authorsHolder}>
                {this.state.authorsPhotos.length ? (
                  <div data-intrcm-id="category_ltva8s2s" className={classes.usersImages}>
                    {this.state.authorsPhotos.map((url, index) => (
                      <img
                        src={url}
                        alt="avatar"
                        width={20}
                        height={20}
                        className={classes.imgAvatar}
                        key={index}
                        style={{ left: this.calcAvatarPisition(index) }}
                      />
                    ))}
                  </div>
                ): null}
                <div data-intrcm-id="category_vkz1i690"
                  style={{
                    marginLeft: this.calcBlockMargin(
                      this.state.authorsPhotos.length
                    ),
                  }}
                >
                  <Typography className={classes.assignedTo}>Authors</Typography>
                  <Typography className={classes.authors}>
                    {this.state.authors}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    return <Fragment>{renderCategory(id)}</Fragment>;
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = (state) => ({
  users: state.globalData.users,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withErrorCatcher("Failed Loading Knowledge Category, Please Contact Tuqqi Support")
)(Category);
