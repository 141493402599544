import { withStyles, Dialog, DialogContent } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DropRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import SelectedIcon from '@material-ui/icons/Done'
import { showConfirm, hideConfirm, confirmLoading, showSnackbar } from '../../../actions/appActions';
import withErrorCatcher from '../Common/withErrorCatcher';

const styles = theme => ({
    selectedIcon: {
        marginBottom: 5
    }
})

class ClassificationSelector extends React.Component {

    handleClassification = (level, displayName) => () => {
        const { dispatch, inputService, docId, onClose, closeParent } = this.props

        onClose()
        if (closeParent) {
            closeParent()
        }

        dispatch(showConfirm(`You are about to change item classification to "${displayName}", Are you sure?`, () => {
            dispatch(confirmLoading())
            dispatch(showSnackbar("Updating Classification..."))
            inputService.updateItemClassification(docId, level)
                .then(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Classification Updated Successfully"))
                }).catch(x => {
                    dispatch(hideConfirm())
                    dispatch(showSnackbar("Failed Updating Classification"))
                })
        }, false))
    }

    render() {

        const { classes, dialogClass, anchorEl, item, open, onClose, userData, classifications, t } = this.props
        const notClassified = { level: 0, displayName: t("Not Classified") }

        const handleClassification = this.props.handleClassification || this.handleClassification

        return <Menu anchorEl={anchorEl} open={open} onClose={onClose} className={dialogClass} >
            <MenuItem key={'first-classification-option'} value={notClassified} onClick={handleClassification(notClassified.level, notClassified.displayName)}>
                {t("Not Classified")}
                {item.classification === notClassified.level && <SelectedIcon className={classes.selectedIcon} />}
            </MenuItem>
            {classifications.map(option => {
                if (option.level <= userData.profileData.classification) {
                    return <MenuItem key={option.uId} value={option.level} onClick={handleClassification(option.level, option.displayName)}>
                        {option.displayName + " - level: " + option.level}
                        {item.classification === option.level && <SelectedIcon className={classes.selectedIcon} />}
                    </MenuItem>
                }
            })}
        </Menu>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    classifications: state.globalData.classifications,
    inputService: state.services.inputService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('itemFullViewModal'),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(ClassificationSelector);