import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import TaskHeader from "./taskHeader";
import LiniarDivider from '../../Common/Task/liniarDivider'
import { withStyles, TextField, Paper, Checkbox, List, ListItem, Typography, Button, Grid } from '@material-ui/core';

import Task from "../../Comments/task";
import { showSnackbar } from "../../../../actions/appActions";
import TaskListItem from './taskListItem';
import AutosuggestField from '../../Common/autosuggestField/autosuggestField';
// Apply some reset
const styles = theme => ({
    checklist:{
        margin:32
    }
});

class TaskList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            newTasks: [],
            isEditTitle: false,
            tasks: [],
            existNewTask: false,
            newTask: null,
            newTaskText: ''
        };
    };



    componentDidMount() {
        const { collaborationService, item } = this.props;
        this.addEmptyTask(this.props.item.checklists)

        collaborationService.getTasks(item.docId)
            .then((tasks) => {
                this.setState({ tasks })
            })
    }

    onTaskUpdate = (isNewTask, task) => {
        const { tasks, newTasks } = this.state
        const listName = (isNewTask ? 'newTasks' : 'tasks')

        const taskIndex = this.state[listName].findIndex(t => t == task)
        this.state[listName][taskIndex] = { ...task }

        this.setState({ [listName]: [...this.state[listName]] })
    };


    
    onTaskDeleted = (isNewTask, task) => {
        const { tasks, newTasks } = this.state

        const listName = (isNewTask ? 'newTasks' : 'tasks')

        const taskIndex = this.state[listName].findIndex(t => t == task)
        this.state[listName].splice(taskIndex, 1)

        this.setState({ [listName]: [...this.state[listName]] })
    }



    handleNewTaskChanged = (checklistUid, newValue) => {
        const { newTasks } = this.state
        // find the new task in the checklist
        const newTaskIndex = newTasks.findIndex(t => t.checklistUid == checklistUid && !t.sent)

        // change the value
        newTasks[newTaskIndex] = { ...newTasks[newTaskIndex], text: newValue }

        // init the state
        this.setState({ newTasks: [...newTasks] })
    }



    newTaskIndexSent = 0
    addTask = (checklistUid) => {
        if (this.state.newTasks.some(t => t.checklistUid == checklistUid && !t.sent)) {
            this.saveTask(checklistUid)
        }

        const { collaborationService, profileData, item } = this.props;
        const { firstname, lastname, auth0_id } = profileData;

        const newTask = {
            docId: item.docId,
            docTitle: item.title,
            text: '',
            userDisplayName: `${firstname} ${lastname}`,
            dateTime: (new Date()).toISOString(),
            userId: auth0_id,
            checklistUid,
            isNewTask: true,
            sent: false,
        }

        this.setState((state) =>({
            newTasks: [...state.newTasks, newTask]
        }))
        
    }


    saveTask = (checklistUid) => {
        const { collaborationService } = this.props

        // find the new task in the checklist
        const newTaskIndex = this.state.newTasks.findIndex(t => t.checklistUid == checklistUid && !t.sent)

        // if the text is empty
        if (!this.isNotEmptyTask(this.state.newTasks[newTaskIndex])) {
            
            // Remove the task 
            this.state.newTasks.splice(newTaskIndex, 1)
            this.setState({ newTasks: [...this.state.newTasks] })
        } else  {
            const index = this.newTaskIndexSent++

            // change the value
            this.state.newTasks[newTaskIndex] = { ...this.state.newTasks[newTaskIndex], sent: true, index }

            // init the state
            this.setState({ newTasks: [...this.state.newTasks] })

            // add the task in server
            collaborationService.addTask(this.state.newTasks[newTaskIndex]).then(resTask => {

                // Move task from "state.newTasks" to "state.tasks"
                const newTaskSentIndex = this.state.newTasks.findIndex(t => t.sent && t.index == index)
                if (newTaskSentIndex != -1) {
                    this.state.newTasks.splice(newTaskIndex, 1)
                }
                this.setState({ tasks: [...this.state.tasks, resTask], newTasks: [...this.state.newTasks] })
            })
        } 
    }


    componentWillUnmount() {
        const { collaborationService } = this.props

        this.state.newTasks
            .filter(unSavedTask => !unSavedTask.sent && this.isNotEmptyTask(unSavedTask))
            .forEach(unSavedTask => {
                collaborationService.addTask(unSavedTask)
            })
    }

    componentWillReceiveProps(nextProps){
        const oldChecklists = this.props.item.checklists;
        const newChecklists  = nextProps.item.checklists;

        if(newChecklists.length != oldChecklists.length){
            this.addEmptyTask(newChecklists)
        }
    }

    addEmptyTask(checklists){
            checklists.forEach(checklist => {
                const isExistsNewTask = this.state.newTasks.some(t => t.checklistUid == checklist.uid && !t.sent)
                if(!isExistsNewTask){
                    this.addTask(checklist.uid)
                }
            })
    }

    isNotEmptyTask(task){
        return (task.text || '').trim().length != 0
    }

    render() {
        const { classes, dispatch, item, index } = this.props;

        const { checklists } = item

        return (

            checklists.map((checklist, idx) => {
                const tasks = this.state.tasks.filter(t => t.checklistUid == checklist.uid)
                const newTasks = this.state.newTasks.filter(t => t.checklistUid == checklist.uid)

                // For the task-counter
                const allTasks = tasks.concat(newTasks).filter(task => this.isNotEmptyTask(task))
                
                return (
                    <div data-intrcm-id="taskList_rqwtljia"  key={idx} className={classes.checklist}>
                        <TaskHeader index={idx} checklist={checklist} addTask={this.addTask} />
                        <LiniarDivider done={allTasks.filter(task => task.isDone).length}  allTasks={allTasks.length}/>
                        <List>
                            {
                                tasks.map(((task, taskIdx) => <TaskListItem key={taskIdx} task={task} isNewTask={false} onTaskDeleted={this.onTaskDeleted} onTaskUpdate={this.onTaskUpdate} />))
                            }
                            {
                                newTasks.map(((task, taskIdx) => (
                                    <TaskListItem
                                        isChangeable={taskIdx == newTasks.length -1}
                                        onTaskDeleted={this.onTaskDeleted}
                                        saveNewTask={this.addTask}
                                        handleNewTaskChanged={this.handleNewTaskChanged}
                                        isNewTask={true}
                                        key={taskIdx} 
                                        task={task}
                                        onTaskUpdate={this.onTaskUpdate} />)))
                            }
                           
                        </List>

                    </div>)
            })
        )

    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    item: state.fullView.item,
    collaborationService: state.services.collaborationService,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TaskList);