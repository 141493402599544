import { Typography, withStyles, Tooltip, Button } from '@material-ui/core';
import React from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher';
import Star from '@material-ui/icons/Star';
import { connect } from 'react-redux';
import EmailIcon from '../Common/Icons/ProfileIcons/EmailIcon';
import LanguageIcon from '../Common/Icons/ProfileIcons/LanguageIcon';
import NicknameIcon from '../Common/Icons/ProfileIcons/NicknameIcon';
// import PhoneIcon from '@material-ui/icons/Phone'
import PhoneIcon from '../Common/Icons/UserIcons/PhoneIcon';
import MessageIcon from '../Common/Icons/UserIcons/MessageIcon';
import SubscribeIcon from '../Common/Icons/UserIcons/SubscribeIcon';
import UnsubscribeIcon from '../Common/Icons/UserIcons/UnsubscribeIcon';
import { hexToRGB } from '../../views/groups/Calendar/event-utils';
import parseImagUrl from "../../../ui/components/Common/parseImagUrl";

const styles = theme => {
    const primaryColor = theme.customColors.primary.b300
    const lighterColor = hexToRGB(primaryColor, 0.1)
    const primaryColor09Opacity = hexToRGB(primaryColor, 0.999)

return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 16,
        marginBottom: 0,
        marginTop: 0,
        padding: '0 16px',
    },
    containerSmall: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        width: 88,
        height: 88,
        borderRadius: '50%',
        marginBottom: 16
    },
    imgSmall: {
        width: 40,
        height: 40,
        borderRadius: '100%',
        marginTop: 12,
    },
    title: {
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.commonBlack,
        display: 'block',
        textAlign: 'center',
    },
    subtitle: {
        margin: '8px 0',
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 11,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.4px',
        color: theme.newUI.color.gray,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
    pointsContainer: {
        marginTop: -5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 14,
    },
    starIcon: {
        display: 'flex',
        color: '#efc03e',
        width: 19,
        height: 19,
        fontSize: 19,
        marginRight: 6,
        marginTop: -1
    },
    points: {
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: '0.4px',
        color: 'rgb(240, 204, 48)',
    },
    infoContainer: {
        border: '1px solid #D7D7DB',
        borderRadius: 8,
        display: 'flex',
        width: '100%',
        height: 'fit-content',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    infoContainerTooltipe: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: -10
    },
    infoTitle: {
        width: '90%',
        fontFamily: 'Rubik',
        fontSize: 11,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: '0.4px',
        color: theme.newUI.color.commonBlack,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,

        '&:last-child': {
            marginBottom: 0
        },
    },
    infoTitleTooltipe: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'light',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: '0.4px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
        position: 'relative',

        '&:last-child': {
            marginBottom: 0
        },
    },
    emailHolder: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    emailHolderBig: {
        width: '195px',
    },
    infoIconHolder: {
        marginRight: 10,
        color: '#979aaa',
    },
    infoIconHolderToolTipe: {
        color: '#fff',
        marginRight: 5,
        fontSize: 15
    },
    tooltip: {
        fontSize: 12,
        backgroundColor: '#4A4A4F',
        padding: '4px 8px',
        borderRadius: 4,
        fontfamily: 'Rubik',
        lineHeight: '16px',
    },
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -3,
        marginTop: -3.5,
        transform: 'rotate(-45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
    avatarImgHolderSmall: {
        width: '100%',
        textAlign: 'center'
    },
    drawerSmallButtons: {
        marginTop: 12,
        marginBottom: 24,
      },

      drawerButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 24,
      },
      settingsButton: {
        height: 28,
        width: 83,
        marginLeft: 10,
        border: `1px solid ${theme.customColors.primary.b500}`,
        textTransform: 'none',
        fontFamily: 'Rubik',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        color: theme.customColors.primary.b500,
        borderRadius: 4,
        "&:hover": {
            backgroundColor:  lighterColor,
           
        },
    },
    settingsButtonColored: {
      height: 28,
      width: 83,
      textTransform: 'none',
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      color: 'white',
      backgroundColor: theme.customColors.primary.b500,
      borderRadius: 4,
      "&:hover": {
        backgroundColor: primaryColor09Opacity,
    },
  },

  smallButtonIcon: {
    width: 30,
    height: 28,
    border: `1px solid ${theme.customColors.primary.b500}`,
    textAlign: 'center',
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    marginTop: 8,
    "&:hover": {
        backgroundColor:  lighterColor,
    },
  },
  smallColoredButtonIcon: {
    width: 32,
    height: 28,
    textAlign: 'center',
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    marginTop: 8,
    backgroundColor: theme.customColors.primary.b500,
    "&:hover": {
        backgroundColor: primaryColor09Opacity,
    },
  },
  notAvailable: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: 0.4,
    color: '#B1B1B3',
  },
})
}
const __SelectedUserInfoDrawer = (props) => {
    const {selectedUser = [], classes, isDrawerOpen,additionalOptions,theme, isRemoved} = props
    const {points, profileImageUrl, auth0_id, firstname,lastname, tag, title, email, lang, phone } = selectedUser[0]
    const Points = (
        <div data-intrcm-id="UserInfoDrawer_umsrjksc" className={classes.pointsContainer}>
            <Star className={classes.starIcon}/>
            <Typography className={classes.points}>{`${points} pts.`}</Typography>
        </div>
    )


    const Info = ({content = false}) =>  {
        const titleStyles = content ? classes.infoTitleTooltipe : classes.infoTitle
        const iconHolderStyles = content ? classes.infoIconHolderToolTipe : classes.infoIconHolder
        const iconColor =  content ? '#fff': '#979AAA'
        
        return (<div data-intrcm-id="UserInfoDrawer_b4c5w9ui" className={content ? classes.infoContainerTooltipe : classes.infoContainer}>
                    <Typography className={titleStyles} noWrap={true}>
                        <span className={iconHolderStyles}>
                            <PhoneIcon iconColor={iconColor}/>
                        </span>
                        { phone ? <span  className={`${classes.emailHolder} ${classes.emailHolderBig}`}>{phone}</span>: <div data-intrcm-id="UserInfoDrawer_nj8b25rf" className={classes.notAvailable}>N/A</div>}
                    </Typography>
                    <Typography className={titleStyles} noWrap={true}>
                        <span className={iconHolderStyles}>
                            <EmailIcon iconColor={iconColor}/>
                        </span>
                        {email ? <span className={`${classes.emailHolder} ${classes.emailHolderBig}`}>{email}</span> : <div data-intrcm-id="UserInfoDrawer_ce1pqks5" className={classes.notAvailable}>N/A</div>}
                    </Typography>
                    <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <NicknameIcon iconColor={iconColor}/>
                        </span>
                    {tag ?? <div data-intrcm-id="UserInfoDrawer_ukpj61fh" className={classes.notAvailable}>N/A</div>}
                    </Typography>
                    <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <LanguageIcon iconColor={iconColor}/>
                        </span>
                        {lang ?? <div data-intrcm-id="UserInfoDrawer_w19e8byq" className={classes.notAvailable}>N/A</div>}
                    </Typography>
                    {content && <div data-intrcm-id="UserInfoDrawer_w9wcjyl9" className={classes.triangle}></div>}
                </div>)
    }

    const content = <Info content/>

    let photo = profileImageUrl ?? ''

    if(!photo.startsWith('https://')) {
        photo = parseImagUrl(props.cloudfront, photo, 120)
    }
    if(photo.includes("fit-in/50x0")) {
        photo = photo.replace("fit-in/50x0", "fit-in/120x0")
    }

    return (
        <div data-intrcm-id="UserInfoDrawer_ak0dbyml" className={isDrawerOpen ? classes.container : classes.containerSmall}>
            <Tooltip placement="right" 
                 title={<>
                    {content}
                    <div data-intrcm-id="UserInfoDrawer_0ffcvv5p" className={classes.triangle}></div>
                </>  }
                 interactive
                 disableHoverListener={isDrawerOpen ? true : false} 
                 classes={{tooltip: classes.tooltip}}
            >
                <div data-intrcm-id="UserInfoDrawer_ulckzjxg" className={!isDrawerOpen && classes.avatarImgHolderSmall}><img alt={`user-image-${auth0_id}`} src={photo} className={isDrawerOpen ? classes.img : classes.imgSmall} /></div>
            </Tooltip>
            {isDrawerOpen && 
            <>
                <Typography className={classes.title}>{firstname} {lastname} {isRemoved ? '(Removed)' : ''}</Typography>
                <Typography dir='auto' className={classes.subtitle}>
                        @{tag} · {title}
                </Typography>
                {Points}
            </>
            }
            {isDrawerOpen ? <Info/> : null}
            { isDrawerOpen ? <div data-intrcm-id="UserInfoDrawer_ju5ek89c" className={classes.drawerButtons}>
          {additionalOptions.map(el => {
            return (
              <Button onClick={el.onClick} className={el.title === "Message" ? classes.settingsButtonColored  : classes.settingsButton } >
                {el.title}
              </Button> 
            )
            })}
          </div> : <div data-intrcm-id="UserInfoDrawer_4694vy27" className={classes.drawerSmallButtons}> 
              {additionalOptions.map(el => (
                  <Tooltip
                            placement="right" 
                            title={<>
                                {el.title}
                                <div data-intrcm-id="UserInfoDrawer_725r1f7k" className={classes.triangle}></div>
                            </>  }
                            disableHoverListener={false} 
                            classes={{tooltip: classes.tooltip}}
                            onClick={el.onClick}
                            >
                            <div data-intrcm-id="UserInfoDrawer_vv9qrap3" className={el.title === "Message" ? classes.smallColoredButtonIcon : classes.smallButtonIcon}>
                             {el.title === "Message" && <MessageIcon color='white' /> }
                              {el.title === "Subscribe" && <SubscribeIcon color={theme.primary.b500} /> }
                              {el.title === "Unsubscribe" && <UnsubscribeIcon color={theme.primary.b500} /> }
                            </div>
                      </Tooltip>
                ))}
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    myAuth0_id: state.userData.profileData.auth0_id,
    cloudfront: state.globalData.cloudfront,
})

export const SelectedUserInfoDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, null)
)(__SelectedUserInfoDrawer);