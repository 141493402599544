import { Button, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { isUserOrgAdmin } from '../../Common/Utils/userPermissionsUtils';
import { push } from 'react-router-redux';
import { SHOW_UPGRADE_FROM_FREE } from '../../../../actionTypes/actionTypes';
import moment from 'moment'


const styles = theme => ({
    container: {
        padding: 8,
        marginRight: 43,
        height: 34,
        borderRadius: 4,
        backgroundColor: `${theme.customColors.accent} !important`,
        "textTransform": "none",
    },
    icon: {
        marginRight: 8,
    },
    text: {
        // marginRight: 8,
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "textAlign": "center",
        "color": "#ffffff"
    }
});

const UpgradeIcon = (props) => (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 18 18">
        <defs>
            <path id="UpgradeIcon__a" d="M6 .5S2.25 2 2.25 8c0 2.325.57 4.313 1.252 5.873.248.54.78.877 1.373.877h2.25c.593 0 1.125-.338 1.373-.877.69-1.56 1.252-3.548 1.252-5.873C9.75 2 6 .5 6 .5m1.125 12.75h-2.25C4.125 11.57 3.75 9.807 3.75 8c0-3.48 1.425-5.1 2.25-5.752C6.825 2.9 8.25 4.52 8.25 8c0 1.807-.375 3.57-1.125 5.25M12 15.5l-2.895-1.162c.525-1.148.9-2.333 1.133-3.54m-7.343 3.54L0 15.5l1.762-4.703c.233 1.208.608 2.393 1.133 3.54M6 8c-.825 0-1.5-.675-1.5-1.5S5.175 5 6 5s1.5.675 1.5 1.5S6.825 8 6 8z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(3 1)">
            <mask id="UpgradeIcon__b" fill="#fff">
                <use href="#UpgradeIcon__a" />
            </mask>
            <g fill="#FFF" mask="url(#UpgradeIcon__b)">
                <path d="M0 0H18V18H0z" transform="translate(-3 -1)" />
            </g>
        </g>
    </svg>
)

class UpgradeButton extends PureComponent {

    upgrade = () => {
        // this.props.goTo('/admin/billing')
        // this.props.dispatch({ type: SHOW_UPGRADE_FROM_FREE })
        window.open("https://tuqqi.com/contact-sales", '_blank').focus()
    }


    render() {
        const { classes, profileData, subscription, t } = this.props;

        if (isUserOrgAdmin(profileData) &&  (subscription.subscriptionState === 3 || subscription.subscriptionState === 0)) {

         
            return (
                <Button onClick={this.upgrade}
                    classes={{
                        root: classes.container,
                    }}>
                    {/* <UpgradeIcon className={classes.icon} /> */}
                    <span className={classes.text} >{t('Upgrade')}</span>

                </Button>
            )
        }else if(isUserOrgAdmin(profileData) && subscription.subscriptionType === 0){
            return (
                <Button onClick={this.upgrade}
                    classes={{
                        root: classes.container,
                    }}>
                    <UpgradeIcon className={classes.icon} />
                    <span className={classes.text} style={{marginRight:8}}>{t('Upgrade')}</span>
    
                </Button>
            )
        }

        return null


    }
}

const mapStateToProps = (state) => ({
    subscription: state.globalData.currentSubscription,
    profileData: state.userData.profileData
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        goTo: path => { dispatch(push(path)) }
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('billing'),
    connect(mapStateToProps, mapDispatchToProps))(UpgradeButton);