import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddComment from '../../Comments/AddComment'
import { loadData, printComments } from '../../Comments/CommentsUtils'
import { onTaskAdd, onTaskDelete, onTaskUpdate } from '../../Comments/taskUtils'

// Apply some reset
const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    comments: {        
        overflow: 'visible'
    },
    viewedLabel: {
        margin: '16px 0 0 16px',
        marginBottom: '10px'
    }
});

class ItemPreviewCardComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            comments: [],
            tasks: []
        }

        this.loadData = loadData.bind(this);
        this.printComments = printComments.bind(this);
        [onTaskAdd, onTaskDelete, onTaskUpdate].forEach(f => this[f.name] = f.bind(this))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.expanded === true) {
            if (!this.state.dataLoaded && this.props.docId) {
                this.loadData(this.props.docId);
            }
        }
    }

    onCommentDelete = (comment) => {
        // Remove the comment from the social bar
        this.props.changeCounter && this.props.changeCounter('commentsCounter', -1)

        let comments = this.state.comments.filter(com => com.key != comment.key);
        this.setState({ comments: comments });
    }

    render() {
        const { classes, expanded, docId, result, linkEvents, relatedDocuments, changeCounter } = this.props;
        const { dataLoaded, comments, tasks, activities } = this.state;
        const { onLinkAdded, onLinkDeleted } = linkEvents

        let content;
        if (dataLoaded) {
            content = this.printComments({ activities, comments, tasks, relatedDocuments }, onLinkDeleted, (comment) => this.onCommentDelete(comment));
        } else {
            content = <div data-intrcm-id="itemPreviewCardComments_v77j3s3r" style={{ textAlign: 'center' }}><CircularProgress /></div>
        }

        return (
            <Collapse in={expanded} transitionDuration="auto" unmountOnExit className={classes.comments}>
                <div data-intrcm-id="itemPreviewCardComments_ydef3w5a" className={classes.comments}>
                    <div data-intrcm-id="itemPreviewCardComments_rh4hexpc" style={{ borderTop: '1px solid rgba(79, 78, 97, 0.2)', borderBottom: '1px solid rgba(79, 78, 97, 0.2)' }}>
                        {content}
                    </div>
                    <AddComment
                        changeCounter={changeCounter}
                        docId={docId}
                        title={result.title}
                        onLinkAdded={onLinkAdded} onTaskAdd={this.onTaskAdd} onCommentAdd={(comment) => { this.setState({ comments: [...this.state.comments, comment] }) }} />
                </div>
            </Collapse>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemPreviewCardComments);