import React, { useCallback, useEffect, useMemo, useState }  from 'react'
import { withStyles } from '@material-ui/core'
import {managerAggregationsForDataType, managerDraftInput, managerGanttRef, openFullview } from '@tuqqi/web'
import { compose } from 'redux'
import { connect } from 'react-redux'
import CreateLaneModal from './CreateLaneModal'
import { changeCollectionInputInfo, openInput } from '../../../../../../actions/inputActions'
import { shouldAutoOpen } from '../../../groupUtils'
import { FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN } from '../../../../../../actionTypes/actionTypes'
import { createCustomInputSilent, getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common'
import { openInput as openNewUiInput } from '@tuqqi/web'
import { LaneMenu } from './LaneMenu'
import withErrorCatcher from '../../../../../components/Common/withErrorCatcher'
import { dataUpdate } from '../../../../../../actions/dataActions'
import FastCreationMenu1 from './fastCreation/FastCreationMenu1'
import { DateHelper } from '@bryntum/gantt'
import { KeepFastCreation } from '../../utils/keepFastCreation'
import { addRecentItem } from '../../../../../../actions/searchNavigationActions'

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    container: {
        minWidth: 80,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    title: {
        color: '#21212D',
        fontSize: 14,
        alignSelf: 'center',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: '500',
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
    },
    titleItem: {
        fontWeight: '300',
    },
    hovered: {
        opacity: '1!important',
    },
    addNewTask: {
        opacity: 0.5,
        transition: 'opacity 0.5s!important',
        alignSelf: 'center',
        cursor: 'pointer',
        fontFamily: 'Rubik',
        color: '#8645F9',
        fontSize: 20,
        fontWeight: 500,
        marginLeft: 12,
        border: 'solid 2px #8645F9',
        borderRadius: 12,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: 0.09842514246702194,
      },
})

class Counter {
    callbacks = {}

    checkCurrent = () => {
        const keys = Object.entries(this.callbacks)
        if(!keys.length) return

        keys.forEach(([key, setter]) => {
            if(!key || !setter) return
            const fromParentRec = managerGanttRef.gantt?.instance?.project?.eventStore?.getById?.(key)
            if(!fromParentRec) return
            setter?.(fromParentRec?.children?.length ?? 0)

        })
    }

    getInitital = (isLane, record) => {
        if(!isLane) return 0
        return record?.children?.length ?? 0
    }
    shouldShow = (isLane) => {
        return !!isLane
    }
    clear = () => {
        this.callbacks = {}
    }

    addCallback = (isLane, id, setter) => {
        if(!isLane || !id) return
        this.callbacks = {
            ...this.callbacks,
            [id]: setter
        }
    }
}

export const counterInstance = new Counter()

const DocTitle = (props) => {
    const {changeCollectionInputInfo, inputMappings, groupUid, docId, title, classes, isItemRow, dispatch, isLane, isRootRow, groupService, collectionInfo,originalData,collectionInfo: { collectionGroup },userData} = props
    const [shown, setShown] = useState(false)
    const [counter, setCounter] = useState(counterInstance.getInitital(!!originalData.isLane, props.record))

    useEffect(() => {
        counterInstance.addCallback(originalData.isLane, originalData.id, setCounter)
    }, [])

    const [showCreateLane, setShowCreateLane] = useState(false)
    const [showFastCreation, setShowFastCreation] = useState(false)

    const isAdmin = useMemo(() => {
        if(!userData.profileData || !collectionGroup || !collectionGroup.adminIds) return false
        return collectionGroup.adminIds.includes(userData.profileData.id)
    }, [collectionGroup, userData])

    const ganttViewId = useMemo(() => {
        if(!collectionInfo || !Object.keys(collectionInfo).length) return -1
        const gantt = collectionInfo?.collectionViews?.find?.(x => x.collectionViewType === 'Gantt')
        return gantt?.id ?? -1
    }, [collectionInfo])

    // const collectionUid = useMemo(() => collectionInfo?.collectionUid ?? "", [collectionInfo])
    // const collectionID = useMemo(() => collectionInfo?.id ?? 0, [collectionInfo])

    const addFastCard = useCallback(() => {
        setShowFastCreation(true)
        // return
        // const store = managerGanttRef.gantt?.instance?.project?.eventStore
        // const isExpaned = props.record.isExpanded(store)

        // if(!isExpaned) {
        //     ExpandedForCreating.fromCreating = true
        //     ExpandedForCreating.id = props.record.id

        //     managerGanttRef.gantt?.instance?.expand(props.record.id)
        //     await managerGanttRef.gantt?.instance?.project?.commitAsync()
        //     return
        // }

        // insertNewNode(props.record)
    }, [])

    const updateAfterCreating = useCallback((payload) => {
        return (itemId) => {   
            dispatch(dataUpdate()) 
        }
    }, [])

    const addCard = () => {
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const ganttListId = originalData.id === -1 ? '' : originalData.id + ''

        if (shouldAutoOpen(collectionInfo)) {
            const dataType = collectionInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: '', ganttListId}
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }
        changeCollectionInputInfo({ kanbanListId: '', ganttListId })
    }

    const handleClick = useCallback((e) => {
        // if(isNew) return
        if(!isItemRow) return
        dispatch(openFullview(docId, false))
        dispatch(addRecentItem(docId))
    }, [docId, isItemRow])

    const onDoubleClick = useCallback(() => {
        setShowEditLane(true)
    }, [docId, isItemRow])

    const onMouseEnter = useCallback(() => {
        if(!originalData.isLane && !isRootRow) return
        setShown(true)
    }, [originalData.isLane, isRootRow])

    const onMouseLeave = useCallback(() => {
        if(!originalData.isLane && !isRootRow) return
        setShown(false)
    }, [originalData.isLane, isRootRow])

    const onSaveCreateLane = useCallback((name) => {
        if(ganttViewId === -1) return
        const lane = {
            title: name, 
            collectionViewId: ganttViewId,
            listColor: 0
        }
        setShowCreateLane(false)
        groupService.addGanttLane(lane).then(newLane => {
            dispatch({
                type: 'RESULT_CHANNEL_VIEW_NEW_LANE',
                isGantt: true,
                lane: {...newLane, items: []}
            })
        })
    }, [ganttViewId])

    // const onSaveEditLane = useCallback((name) => {
    //     const viewListItems = originalData.children.map(item => {
    //         return {
    //             collectionViewListId: originalData.id,
    //             documentId: item.id,
    //             order: item.order,
    //         }
    //     })
    //     const lane = {
    //         id: originalData.id,
    //         title: name,
    //         order: originalData.order,
    //         listColor: 0,
    //         items: viewListItems,
    //     }
    //     groupService.updateLane(lane).then(() => {
    //         setShowEditLane(false)
    //         dispatch({
    //             type: 'RESULT_CHANNEL_VIEW_UPDATE_LANE',
    //             lane: {...lane, items: []}
    //         })
    //     })
    // }, [ganttViewId])

    const deleteLane = () => {
        if(isRootRow) return
        const viewListItems = originalData.children.map(item => {
            return {
                collectionViewListId: originalData.id,
                documentId: item.id,
                order: item.order,
            }
        })
        const lane = {
            id: originalData.id,
            title: originalData.name,
            order: originalData.order,
            listColor: 0,
            items: viewListItems,
        }

        // const collection = {
        //     id: collectionID,
        //     collectionUid: collectionUid,
        //     collectionViews: [
        //         {
        //             id: ganttViewId,
        //             itemLists: [lane]
        //         }
        //     ]
        // }
        groupService.deleteGanttLane(originalData.id, {}).then((res) => {
              dispatch({
                type: 'RESULT_CHANNEL_VIEW_DELETE_LANE',
                isGantt: true,
                lane: {...lane, items: []}
            })
        })
    }

    const onCloseCreateLane = useCallback((name) => {
        setShowCreateLane(false)
    }, [])

    // const onCloseEditLane = useCallback((name) => {
    //     setShowEditLane(false)
    // }, [])

    const onClickAdd = useCallback(() => {
        if(!originalData.isLane && !isRootRow) return
        if(isRootRow) {
            setShowCreateLane(true)
            return
        }
        // KeepFastCreation.allow ? 
            // addFastCard() :
            addCard()
    }, [originalData.isLane, isRootRow, addCard, addFastCard])

    const onCreate = (title) => {
        setShowFastCreation(false)

        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)

        const dataType = collectionInfo.settings.allowedInputs[0] ?? ''
        if(!dataType) return
        const mappings = managerAggregationsForDataType.getMappings()
        const mapping = mappings.find(x => x.customDataMapping.dataTypeFilter === dataType)
        if(!mapping) return

        const ganttListId = props.record.originalData.id === -1 ? '' : props.record.originalData.id + ''
        const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
        const isDraft = !mapping ? false : managerDraftInput.getIsDraft({
            mapping,
            relatedDocuments: [],
            dueDatePresented: false,
            jsonData: {},
            businessId: ''
        })


        let newDoc = { data: {}, html: '', title, permissions, labels: [], dataType: dataType, kanbanListId: '', ganttListId, dateTimeFrom: DateHelper.parse(new Date().toISOString()), dateTimeTo: DateHelper.parse(tomorrow.toISOString())}
        
        if(managerAggregationsForDataType.isUseAggregationsForDataType(newDoc.dataType)) {
            newDoc.aggregations = managerAggregationsForDataType.mapJsonToAggregations(newDoc.dataType, newDoc.data)   
            newDoc.data = {}
        }

        newDoc.isDraft = isDraft

        dispatch(createCustomInputSilent({ API_URL: process.env.API_SERVER, updateItem: updateAfterCreating(newDoc)})(newDoc, true))
    }
    const onOpenFullview = (title) => {
        setShowFastCreation(false)

        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)

        const dataType = 'contact'
        const ganttListId = props.record.originalData.id === -1 ? '' : props.record.originalData.id + ''
        const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()

        let newDoc = { html: '', title, permissions, dataType: dataType, kanbanListId: '', ganttListId, dateTimeFrom: DateHelper.parse(new Date().toISOString()), dateTimeTo: DateHelper.parse(tomorrow.toISOString())}
        dispatch(openNewUiInput(newDoc, dataType))
    }
    
    const onCancel = () => {
        setShowFastCreation(false)
    }
    
    const onCloseFastCreation = () => {
        setShowFastCreation(false)
    }

    const showCreation = !!originalData.isLane && showFastCreation
    const namee = counterInstance.shouldShow(originalData.isLane) ? `${title} (${counter})` : title
    return <>
    <div data-intrcm-id="DocTitle_ujn80pdb" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`${classes.container} ${isItemRow ? classes.pointer : ''}`} onClick={handleClick} >
        <div data-intrcm-id="DocTitle_c4cav16t" className={`${classes.title} ${isItemRow ? classes.titleItem : ''}`}>{namee}</div>
        {<FastCreationMenu1 show={showCreation} onClose={onCloseFastCreation} id={`lane-menu-${docId}`} onCreate={onCreate} onOpenFullview={onOpenFullview} onCancel={onCancel} /> }
        {!!originalData.isLane || !!isRootRow ? <LaneMenu id={`lane-menu-${docId}`} isDefaultLane={!!originalData.isLane && originalData.id === -1} isLane={!!originalData.isLane} isRootRow={!!isRootRow} deleteLane={deleteLane } dispatch={ dispatch} isAdmin={isAdmin} onClickAddCard={onClickAdd}/> : null}
    </div>
    {isRootRow && <CreateLaneModal onSave={onSaveCreateLane} onClose={onCloseCreateLane} open={showCreateLane} />}
    {/* <EditLaneNameModal onSave={onSaveEditLane} onClose={onCloseEditLane} open={showEditLane} /> */}
    </>
}

const mapStateToProps = (state) => ({ 
    groupService: state.services.groupService,
    collectionInfo: state.results.channel,
    groupUid: state.results.channel.collectionGroup && state.results.channel.collectionGroup.groupUid,
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    searchQuery: state.search,

 })
const mapDispatchToProps = (dispatch) => ({ 
    dispatch,
    changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
 })

export default compose(
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(DocTitle)