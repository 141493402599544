import React, { useEffect, useMemo, useRef } from 'react'
import { setCountAgendaItems, setCountMustReadItems, setCountToDoItems } from '../../actions/actionsCenterActions'
import {useSearchItemsQueryLocal} from '../../hooks/useSearchItems/useSearchItemsQueryLocal'
import { getUsersForAgenda } from '../../utils/actionsCenter/buildAgenda'
import { useSearchACQueryLocal } from '../useSearchItems/useSearchACQueryLocal'

const wrongDate = '0001-01-01T00:00:00Z'
export const filterDocsDueDate = (items) => {
    const filtered = items.filter((item) => {
        return !!item.dueDateUTC?.toString() && wrongDate !== item.dueDateUTC.toString()
    })
    return filtered
}

const initialSearchQuery = {
    page: 0,
    size: 100,
    query: '',
    AggType: 'FLAT'
}
export const useActionsCenter = (ctx) => {
    const {queryService, userUid, dispatch, users, customStatuses} = ctx

    const customColors = useMemo(() => customStatuses?.map?.(x => x.taskStatusColor) ?? [], [customStatuses])

    const { items: itemsToDo, loadData: loadToDo, abortData: abortToDo, isLoading: isLoadingToDo } = useSearchItemsQueryLocal({queryFiltersUpdateType: ctx.queryFiltersUpdateType, queryFiltersItemId: ctx.queryFiltersItemId, returnFullData: true, acView: 'todo', queryService})
    const { items: itemsReadConfirm, loadData: loadReadConfirm, abortData: abortReadConfirm, isLoading: isLoadingReadConfirm } = useSearchItemsQueryLocal({queryFiltersUpdateType: ctx.queryFiltersUpdateType, queryFiltersItemId: ctx.queryFiltersItemId,returnFullData: true, acView: 'mustRead', queryService})
    const { itemsSections: itemsAgenda, loadData: loadAgenda, abortData: abortAgenda, isLoading: isLoadingAgenda } = useSearchACQueryLocal({queryFiltersUpdateType: ctx.queryFiltersUpdateType, queryFiltersItemId: ctx.queryFiltersItemId, returnFullData: true, filterResponse: filterDocsDueDate, acView: 'agenda', queryService})
    const mounted = useRef(false)
    
    const agendaBirthdayUsers = useMemo(() => getUsersForAgenda(users), [users])

    useEffect(() => {
        if(!!isLoadingToDo) return
        dispatch(setCountToDoItems(itemsToDo.length))
    }, [itemsToDo, isLoadingToDo])

    useEffect(() => {
        if(!!isLoadingAgenda) return
        const count = itemsAgenda.reduce((accum, cur) => accum + (cur?.items?.length ?? 0), 0)

        dispatch(setCountAgendaItems(count + agendaBirthdayUsers.length))
    }, [itemsAgenda, isLoadingAgenda, agendaBirthdayUsers])

    useEffect(() => {
        if(!!isLoadingReadConfirm) return
        dispatch(setCountMustReadItems(itemsReadConfirm.length))
    }, [itemsReadConfirm, isLoadingReadConfirm])

    const onReload = React.useCallback(() => {
        if(!userUid || !mounted.current) return
        const queryTodo = {...initialSearchQuery, size: 100, users: [userUid ?? ''], colors: [30, 40, ...customColors]}
        const queryMustRead = {...initialSearchQuery, size: 100, isMyItem: false, isMyPage: true, mustread: true, sortOrder: "asc"}
        const queryAgenda = {
            ...initialSearchQuery, 
            groupBy: 1,
            relatedDocIds: [], 
            dataTypes: [], 
            filterBoolean: {
                'taskDueDateDone': [false]
            }, 
            size: 100, 
            users: [userUid ?? ''], 
        }
        
        loadToDo(queryTodo)
        loadReadConfirm(queryMustRead)
        loadAgenda(queryAgenda)
    }, [userUid, customColors, loadReadConfirm, loadToDo,loadAgenda])

    const onAbort = React.useCallback(() => {
        abortReadConfirm()
        abortToDo()
        abortAgenda()
    }, [abortToDo, abortReadConfirm, abortAgenda])

    useEffect(() => {
        mounted.current = true
        onReload()
        return () => {
            mounted.current = false
            onAbort()
        };
    }, []);

    return {
        onReload,
        itemsAgenda,
        itemsReadConfirm,
        itemsToDo,
        agendaBirthdayUsers,
        isLoading: isLoadingReadConfirm || isLoadingToDo || isLoadingAgenda
    }
}