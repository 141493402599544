import { withStyles } from '@material-ui/core';
import React from 'react';

const styles = theme => ({
    container: {
        width: '100%',
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    innerContainer: {
        width: '100%',
        padding: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    placeholder: {
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.75,
        letterSpacing: 'normal',
        color: '#a3a3a3',
    }
});

export default withStyles(styles)((props) => {
    const { classes, placeholder, containerStyle, textStyle } = props;

    return <div data-intrcm-id="NoItemsPlaceholder_gkqhhnnq" className={[classes.container, containerStyle].join(" ")}>
                <div data-intrcm-id="NoItemsPlaceholder_d2z9o0yk" className={classes.innerContainer}>
                    <span className={[classes.placeholder, textStyle].join(" ")}>{placeholder || 'No items available'}</span>
                </div>
            </div>
})