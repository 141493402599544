export default class AdminService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    // size
    getStorageUse() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/docSize', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }
    // users
    getUsers() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/users', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Sponsored content
    getSponsoredContent() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/sponsored', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Sponsored content
    addSponsoredContent(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/sponsored?docId=' + encodeURIComponent(docId), {
            method: 'PUT',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Sponsored content
    deleteSponsoredContent(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/sponsored?docId=' + encodeURIComponent(docId), {
            method: 'DELETE',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }


    getUserFullData(auth0_id) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/adminusersettings/getUserFullData/${auth0_id}`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    editCertification(certification) {
        var myHeaders = this.postHeaders();


        return fetch(`${process.env.API_SERVER}/certification/editCertification`, {
            method: 'POST',
            body: JSON.stringify(certification),
            headers: myHeaders
        })

    }

    createMenuItem(item) {
        var myHeaders = this.postHeaders();


        return fetch(`${process.env.API_SERVER}/menu/new`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return null })
    }
    createLaunchpadMenuItem(item) {
        var myHeaders = this.postHeaders();

        return fetch(`${process.env.API_SERVER}/launchpad/new`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return null })
    }

    updateMenuItem(item) {
        var myHeaders = this.postHeaders();

        return fetch(`${process.env.API_SERVER}/menu?id=${item.id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return null })
    }

    updateLaunchpadMenuItem(item) {
        var myHeaders = this.postHeaders();

        return fetch(`${process.env.API_SERVER}/launchpad?id=${item.id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return null })
    }

    getMenuItems() {
        var myHeaders = this.getHeader();


        return fetch(`${process.env.API_SERVER}/menu/getAll`, {
            method: 'GET',
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return [] })
    }

    getLaunchpadMenuItems() {
        var myHeaders = this.getHeader();


        return fetch(`${process.env.API_SERVER}/launchpad/getAll`, {
            method: 'GET',
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return [] })
    }

    deleteMenuItem(id) {
        var myHeaders = this.postHeaders();

        return fetch(`${process.env.API_SERVER}/menu?id=${id}`, {
            method: 'DELETE',
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return false })
    }

    deleteLaunchpadMenuItem(id) {
        var myHeaders = this.postHeaders();

        return fetch(`${process.env.API_SERVER}/launchpad?id=${id}`, {
            method: 'DELETE',
            headers: myHeaders
        }).then((res) => res.json())
        .catch((err) => {return false })
    }
    addCertification(certification) {
        var myHeaders = this.postHeaders();


        return fetch(`${process.env.API_SERVER}/certification/addCertification`, {
            method: 'PUT',
            body: JSON.stringify(certification),
            headers: myHeaders
        })

    }

    deleteCertification(certificationId) {
        var myHeaders = this.postHeaders();
        return fetch(`${process.env.API_SERVER}/certification/deleteCertification/${certificationId}`, {
            method: 'DELETE',
            headers: myHeaders
        })

    }

    getAllCertifications() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/certification/getAllCertifications/`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getAllIntegrations() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/integrations`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    addIntegration(integration) {
        var myHeaders = this.postHeaders();


        return fetch(`${process.env.API_SERVER}/integrations`, {
            method: 'POST',
            body: JSON.stringify(integration),
            headers: myHeaders
        })

    }

    getUserslogindata() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/userslogindata', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Scores
    getDocsCount() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/docsNumber', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Scores
    getActivity() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/activity', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }



    isAdmin(id) {
        var myHeaders = this.postHeaders();

        return fetch(process.env.API_SERVER + '/users/isAdmin', {
            method: 'POST',
            body: JSON.stringify(id),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getKnowledgelevels() {
        return fetch(process.env.API_SERVER + '/knowledgeLevels/getAll', {
            method: 'GET',
            headers: this.getHeader()
        }).then(res => res.json());
    }

    getAllRewards() {
        return fetch(process.env.API_SERVER + '/rewards/getAll', {
            method: 'GET',
            headers: this.getHeader()
        }).then(res => res.json());
    }

    editReward(reward) {
        return fetch(process.env.API_SERVER + '/rewards/reward/' + reward.id, {
            method: 'PUT',
            body: JSON.stringify(reward.points),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getAllTopics() {
        return fetch(process.env.API_SERVER + '/topics/getAll', {
            method: 'GET',
            headers: this.getHeader()
        }).then(res => res.json());
    }

    getAllUserTags() {
        return fetch(process.env.API_SERVER + '/userTags/getAll', {
            method: 'GET',
            headers: this.getHeader()
        })
        .then(res => res?.json?.() ?? [])
        .catch(err => []);
    }

    addTopic(topic) {
        return fetch(process.env.API_SERVER + '/topics/new/', {
            method: 'POST',
            body: JSON.stringify(topic),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    addUserTag(tag) {
        return fetch(process.env.API_SERVER + '/userTags/new/', {
            method: 'POST',
            body: JSON.stringify(tag),
            headers: this.postHeaders()
        }).then(res => res);
    }

    deleteUserTag(tag) {
        var myHeaders = this.getHeader();

        if (tag) {
            return fetch(process.env.API_SERVER + '/userTags?tag=' + tag,
                {
                    method: 'DELETE',
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }
    getAllWidgetSettings() {
        return fetch(process.env.API_SERVER + '/widgetsettings', {
            method: 'GET',
            headers: this.getHeader()
        }).then(res => res.json());
    }
    changeSettings(widgetSettings) {
        return fetch(process.env.API_SERVER + '/widgetsettings', {
            method: 'POST',
            body: JSON.stringify(widgetSettings),
            headers: this.postHeaders()
        }).then(res => res.json());
    }

    getComments() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/comments', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }


    // Knowledge graph
    getAllNodes() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/graph', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getAllLinks() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/linkednodes', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

      // Templates
      applyPatch(uid) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/organization/apply-patch?patchUid='+ uid, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Templates
    generateTemplate() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/templates/generate', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }


    applyTemplate(template) {

        return fetch(process.env.API_SERVER + '/templates', {
            method: 'POST',
            body: JSON.stringify(template),
            headers: this.postHeaders()
        }).then(res => res.json());


    }

    getTemplate(templateUID) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/templates?templateUID=' + encodeURI(templateUID), {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getAllTemplates() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/templates/all', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getDashboardToken() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/analytics/dashboard-token', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    updateOrganizationLogo(logoUrl) {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + `/organization/update-logo?logoUrl=${logoUrl}`, {
            method: 'PUT',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    updateOrganizationBrandColor(color) {
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + `/organization/update-color?color=${color}`, {
            method: 'PUT',
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    getAllOrgLabel(){
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/orglabels/getAll', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getAllOrgStatus(){
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/customStatus/getAll', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    createOrgLabel(label){
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/orglabels/new', {
            method: 'POST',
            body: JSON.stringify(label),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
    createOrgStatus(label){
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/customStatus/new', {
            method: 'POST',
            body: JSON.stringify(label),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
    editOrgLabel(label){
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/orglabels/update', {
            method: 'PUT',
            body: JSON.stringify(label),
            headers: myHeaders
        }).then(function (res) {
            return !!res.ok;
        });
    }
    editOrgStatus(label){
        var myHeaders = this.postHeaders()

        return fetch(process.env.API_SERVER + '/customStatus/update?id=' + label.id, {
            method: 'PUT',
            body: JSON.stringify(label),
            headers: myHeaders
        }).then(function (res) {
            return !!res.ok;
        });
    }
    deleteOrgLabel(labelUid){
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });
        return fetch(process.env.API_SERVER + '/orglabels?uid=' + labelUid, {
            method: 'DELETE',

            headers: myHeaders
        }).then(function (res) {
            return !!res.ok;
        });
    }
    deleteOrgStatus(labelId){
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });
        return fetch(process.env.API_SERVER + '/customStatus?id=' + labelId, {
            method: 'DELETE',

            headers: myHeaders
        }).then(function (res) {
            return !!res.ok;
        });
    }


}