import React, { useCallback, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next'
import GeneralButton from '../../../Common/AllButton/AllButton';
import EditIcon from '../../../Common/Icons/launchpad/EditIcon';
import { createCustomTheme } from '../../../../../utils/theming';
import { connect } from 'react-redux';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils.js';
import HelpOutline from '@material-ui/icons/HelpOutline';

const styles = (theme) => ({
    container: {
        padding: 16,
        paddingTop: 0,
        display: 'flex'
    },
    edit: {
        marginLeft: 0,
        marginRight: 'auto',
    },
    icon: {
        marginRight: 8
    },
    buttonText: {
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0.4,
    },
    buttonContainer: {
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main
        },
    },
    pressedButtonContainer: {
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        background: hexToRGB(theme.customColors.primary.main, 0.16),
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: 'transparent',
            color: theme.customColors.primary.main,
        },
    },
    info: {
        width: 23,
        height: 23,
        alignSelf: 'center',
        fill: "#979AAA",
        cursor: 'pointer'
    }
})

function BottomToolbar(props) {
    const {classes, dispatch, brandingColor, pageType} = props
    const [t] = useTranslation('search')
    const [isEnterEdit, setIsEnterEdit] = useState(false)

    const isEdit = useMemo(() => pageType === 'edit', [pageType])
    const colors = createCustomTheme(brandingColor);

    const onEdit = useCallback(() => {
        dispatch({
            type: 'SET_LAUNCHPAD_PAGE_TYPE',
            pageType: isEdit ? 'landing' : 'edit',
            selectedCategory: 0
        })
    }, [isEdit])

    const openInfoLink = useCallback(() => {
        const link = `https://help.tuqqi.com/en/articles/6200014-launchpad`
        window.open(link)
    }, [])


    const EditButton = <GeneralButton setIsEnter={setIsEnterEdit} onClick={onEdit} icon={isEdit ? null : <EditIcon className={classes.icon} color={!isEnterEdit ? (!isEdit ? colors.primary.main : colors.primary.main) : (!isEdit ? colors.primary.main : colors.primary.main)} />} containerStyle={isEdit ? classes.pressedButtonContainer : classes.buttonContainer} textStyle={classes.buttonText} title={isEdit ? t("Back") : t("Edit")} />
    const Info = <HelpOutline className={classes.info} onClick={openInfoLink} />

    return (
        <div data-intrcm-id="BottomToolbarSection_wt9g5moa" className={classes.container} >
            <div data-intrcm-id="BottomToolbarSection_fmiepz9k" className={classes.edit}>{EditButton}</div>
            {Info}
        </div>
    )
}

const mapStateToProps = (state) => ({
    brandingColor: state.userData.profileData.org.brandingColor,
    pageType: state.launchpadNavigation.pageType
})

const mapDispatchToProps = dispatch => ({dispatch})

export const BottomToolbarSection = compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(BottomToolbar)
