import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    alignContentOptions, alignItemsOptions, alignOptions, colorOptions, directionOptions, justifyOptions, spacingOptions, variantOptions, wrapOptions
} from '../customInputTemplateUtils';


const styles = theme => ({
    root: {
        width: 700,
        borderRadius: 16,
        minHeight: 800
    },
    textField: {
        marginRight: 20
    },
    formControl: {
        marginBottom: 10
    },
    divider: {
        marginTop: 10,
        marginBottom: 10
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        paddingBottom: 15,
        fontWeight: 'bold'
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class NewContainerModal extends React.Component {
  	constructor(props, context) {
		super(props, context);
		this.state = {
            title: '',
            sectionsNumber: 1,
            sections: [],
            containerIndex: null,
            titleStyle: { align: 'inherit', color: 'default', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false
            },
            gridStyle: {
                alignContent: 'stretch', alignItems: 'stretch', direction: 'row', justify: 'flex-start', spacing: 0, wrap: 'wrap'
            },
            tab: 'title'
    	}
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.container){
            const { title, titleStyle, sections, gridStyle } = nextProps.container;

            this.setState({ 
                title, 
                //titleStyle: JSON.stringify(titleStyle), 
                sectionsNumber: sections.length, 
                sections: sections.map(section => section.gridSize),
                containerIndex: nextProps.containerIndex,
                titleStyle: titleStyle ? titleStyle : this.state.titleStyle,
                gridStyle: gridStyle ? gridStyle : this.state.gridStyle
            })
        }
        else{
            this.setState({ 
                title: '',
                sectionsNumber: 1,
                sections: [],
                containerIndex: null,
                titleStyle: { align: '', color: '', variant: '', gutterBottom: false, inline: false,
                    internalDeprecatedVariant: false, noWrap: false, paragraph: false
                },
                gridStyle: {
                    alignContent: 'stretch', alignItems: 'stretch', direction: 'row', justify: 'flex-start', spacing: 0, wrap: 'wrap'
                }
            })
        }
    }

  	handleChange = (name, idx) => (e) => {
        let value = e.target.value;

        let sections = [];

        if( name === 'sectionsNumber'){
            let gridSizes
            let sectionsNumberValue = value <= 12 ? value : this.state.sectionsNumber;

            if(!(12 % sectionsNumberValue)){
                gridSizes = 12/sectionsNumberValue
            }
            else{
                gridSizes = sectionsNumberValue / 12 > 0.5 ? 1 : 2
            }

            for(var i = 0; i < sectionsNumberValue; i++) {
                if(i == sectionsNumberValue -1){
                    sections.push(12- (sectionsNumberValue-1) * gridSizes);
                }
                else{
                    sections.push(gridSizes);
                }
            }
            

            this.setState({ sections, sectionsNumber: sectionsNumberValue })
        } 
        else if(idx != undefined){
            sections = [...this.state.sections];
            sections[idx] = value;

            this.setState({ sections: [...sections] })
        } 
        else {
            this.setState({ [name]: value })
        }
    }
    
    handleChangeStyle = (isTitle, name, isChecked) => (e) => {
        let value = isChecked ? e.target.checked : e.target.value;
        
        if(isTitle){
            let titleStyle = {...this.state.titleStyle};
            titleStyle[name] = value

            this.setState({ titleStyle })
        }
        else{
            let gridStyle = {...this.state.gridStyle};
            gridStyle[name] = value

            this.setState({ gridStyle })
        }
    }

    onAdd = () => {
        const { onClose, container } = this.props;
        const { title, sections, sectionsNumber, containerIndex, titleStyle, gridStyle } = this.state;

        let newSections;
        
        if(container){
            newSections = container.sections;

            sections.map((sectionSize, idx) => {
                newSections[idx].gridSize = sectionSize;
            })
        }
        else{
            newSections = sectionsNumber == 1 
                ? [{ gridSize: 12 }]
                : [ ...sections.map(sectionSize => {
                        return { gridSize: sectionSize }
                    })
                ]
        }

        onClose({
            title,
            //titleStyle: titleStyle ? JSON.parse("{" + titleStyle + "}") : {},
            sections: newSections,
            containerIdx: containerIndex,
            titleStyle,
            gridStyle
        })
    }

    onDelete = () => {
        const { onDelete, containerIndex } = this.props;

        onDelete(containerIndex)
    }

    renderStyleSelection = (inputLabel, value, fieldName, options, formHelperText, isTitle) => {
        const { classes,t } = this.props;

        return <FormControl className={classes.formControl} fullWidth >
            <InputLabel >{inputLabel}</InputLabel>
            <Select
                value={value}
                onChange={this.handleChangeStyle(isTitle, fieldName)}
            >
                <MenuItem value=""><em>{t('None')}</em></MenuItem>
                { options.map((option, idx) => (
                    <MenuItem value={option}>
                        {option}
                    </MenuItem>
                )) }
            </Select>
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderStyleCheckBox = (inputLabel, value, fieldName, formHelperText, isTitle) => {
        const { classes,t } = this.props;

        return <FormControl className={classes.formControl} fullWidth>
            <FormControlLabel
                control={
                    <Switch checked={value} 
                        onChange={this.handleChangeStyle(isTitle, fieldName, true)} 
                    />}
                label={t(inputLabel)}
            />
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderTitleSettings = () => {
        const { classes,t } = this.props;
        const { title, titleStyle } = this.state;
        const { align, color, variant, gutterBottom, inline, internalDeprecatedVariant, noWrap, paragraph } = titleStyle;

        return <div data-intrcm-id="newContainerModal_i9kp3f9q">
            <Typography classes={{root: classes.title}}>{t('Title')}</Typography>

            <TextField label={t('Title')} value={title} fullWidth style={{ marginBottom: 10 }}
                helperText={t('Title of the container')} onChange={this.handleChange('title')} />

            {this.renderStyleSelection('Align', align, 'align', alignOptions, 'Set the text-align on the component.', true)}
            {this.renderStyleSelection('Color', color, 'color', colorOptions, 'The color of the component. It supports those theme colors that make sense for this component.', true)}
            {this.renderStyleSelection('Variant', variant, 'variant', variantOptions, 'Applies the theme typography styles. Use body1 as the default value with the legacy implementation and body2 with the new one.', true)}
            
            {this.renderStyleCheckBox('Gutter Bottom', gutterBottom, 'gutterBottom', 'If true, the text will have a bottom margin.', true)}
            {this.renderStyleCheckBox('Inline', inline, 'inline', 'Controls whether the Typography is inline or not.', true)}
            {this.renderStyleCheckBox('Internal Deprecated Variant', internalDeprecatedVariant, 'internalDeprecatedVariant', 
                <div data-intrcm-id="newContainerModal_xson83at">
                    <div data-intrcm-id="newContainerModal_5qzama55">{t('A deprecated variant is used from an internal component')}.</div> 
                    <div data-intrcm-id="newContainerModal_x34qjuqt">{t(`Users don't need a deprecation warning here if they switched to the v2 theme`)}.</div> 
                    <div data-intrcm-id="newContainerModal_dnzzp3b1">{t('They already get the mapping that will be applied in the next major release')}.</div>
                </div>
            , true)}
            {this.renderStyleCheckBox('No Wrap', noWrap, 'noWrap', 'If true, the text will not wrap, but instead will truncate with an ellipsis.', true)}
            {this.renderStyleCheckBox('Paragraph', paragraph, 'paragraph', 'If true, the text will have a bottom margin.', true)}
        </div>
    }

    renderGridSettings = () => {
        const { classes, container,t } = this.props;
        const { sectionsNumber, sections, gridStyle } = this.state;
        const { alignContent, alignItems, direction, justify, spacing, wrap } = gridStyle;

        return <div data-intrcm-id="newContainerModal_d42utij6">
            <Typography classes={{root: classes.title}}>{t('Row')}</Typography>

            <TextField 
                label={t("Number of sections")}
                value={sectionsNumber} 
                type="number" 
                fullWidth 
                margin="normal"
                onChange={this.handleChange('sectionsNumber')} 
                InputProps={{ inputProps: { min: 0, max: 12 } }}
                InputLabelProps={{ shrink: true }}
                disabled={container}
            />

            { sections.map( (size, idx) => {
                return <TextField 
                    id={"standard-number" + idx} 
                    label={`${t("Section")} + ${(idx + 1)}`}
                    value={size}
                    type="number"
                    className={classes.textField} 
                    InputLabelProps={{ shrink: true, }} 
                    margin="normal"
                    onChange={this.handleChange('sectionsSizes', idx)} 
                />
            }) }

            <div data-intrcm-id="newContainerModal_8afnu3o2" />
            <br />

            {this.renderStyleSelection('Align Content', alignContent, 'alignContent', alignContentOptions, 'Defines the align-content style property. It\'s applied for all screen sizes.', false)}
            {this.renderStyleSelection('Align Items', alignItems, 'alignItems', alignItemsOptions, 'Defines the align-items style property. It\'s applied for all screen sizes.', false)}
            {this.renderStyleSelection('Direction', direction, 'direction', directionOptions, 'Defines the flex-direction style property. It is applied for all screen sizes.', false)}
            {this.renderStyleSelection('Justify', justify, 'justify', justifyOptions, 'Defines the justify-content style property. It is applied for all screen sizes.', false)}
            {this.renderStyleSelection('spacing', spacing, 'spacing', spacingOptions, 'Defines the space between the type item component. It can only be used on a type container component.', false)}
            {this.renderStyleSelection('wrap', wrap, 'wrap', wrapOptions, 'Defines the flex-wrap style property. It\'s applied for all screen sizes.', false)}
        </div>
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ tab: newValue })
    }

 	render() {
        const { open, onClose, classes, container,t } = this.props;
        const { tab } = this.state;

		return <Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            transition={Transition}
			classes={{ paper: classes.root }}
		>
            <DialogContent>
                <AppBar position="static" color="default">
                    <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={this.handleChangeTab} >
                        <Tab label={t("Title")} value='title'/>
                        <Tab disabled label={t("Row")} value='row'/>
                    </Tabs>
                </AppBar>
                {tab === 'title' && this.renderTitleSettings()}
                {tab === 'row' && this.renderGridSettings()}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Cancel')}
                </Button>
                { container 
                    ? <Button onClick={this.onDelete} color="primary">
                        {t('Delete')}
                    </Button> 
                    : <div data-intrcm-id="newContainerModal_n76p61gc" /> 
                }
                <Button onClick={this.onAdd} color="primary">
                    {container ? t('Update') : t('Add')}
                </Button>
            </DialogActions>
		</Dialog>
  	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch,
  	}
}

export default compose(withStyles(styles),
    withTranslation('admin'),
  	connect(mapStateToProps, mapDispatchToProps)
)(NewContainerModal);