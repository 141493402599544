import React, { PureComponent } from 'react';

export default class SearchLocationIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5 10C5 13.2229 9.5443 18.3933 11.31 20.2807C11.6873 20.684 12.3127 20.684 12.69 20.2807C14.4557 18.3933 19 13.2229 19 10C19 6 16 3 12 3C8 3 5 6 5 10Z" stroke={color} stroke-width="1.5"/>
            <circle cx="12" cy="10" r="2.5" stroke={color}/>
            </svg>
        )
    }
}
export const MapPXIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.67539 5.25C9.78873 5.25 9.89555 5.27794 9.98933 5.3273L14.3251 7.45147L18.6781 5.31884C18.8873 5.21634 19.1346 5.22897 19.3323 5.35225C19.53 5.47553 19.6501 5.69203 19.6501 5.925V15.7969C19.6501 16.0545 19.5034 16.2897 19.2721 16.403L14.6221 18.6812L14.6194 18.6825C14.553 18.7147 14.4805 18.7364 14.4041 18.7454C14.3668 18.7498 14.3292 18.751 14.2917 18.7492C14.191 18.7443 14.0961 18.7174 14.0117 18.6731L9.6751 16.5485L5.32207 18.6812C5.11286 18.7837 4.86558 18.771 4.66791 18.6477C4.47023 18.5245 4.3501 18.308 4.3501 18.075V8.20313C4.3501 7.94548 4.49676 7.71031 4.72813 7.59696L9.37372 5.321C9.4645 5.27557 9.56696 5.25 9.67539 5.25ZM14.9998 16.9928L18.3001 15.3759V7.00735L14.9998 8.62423V16.9928ZM13.6498 8.62394V16.9925L10.3504 15.3761V7.00749L13.6498 8.62394ZM5.7001 8.62408L9.00039 7.00721V15.3758L5.7001 16.9927V8.62408Z" fill="#3B83F7"/>
    </svg>    
}