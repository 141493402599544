export default class ChallengeService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    
        this.controllerAPI = `${process.env.API_SERVER}/challenge/`
        this.headers = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    // headers = new Headers({
    //     'Authorization': 'Bearer ' + this.token,
    //     'Content-Type': 'application/json'
    // });


    // Get all challenges
    getAllChallenges = () => {

        return fetch(`${this.controllerAPI}All`, {
            method: 'GET',
            headers: this.headers
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Get all challenges
    getChallengeById = (challengeId) => {

        return fetch(`${this.controllerAPI}GetById/${challengeId}`, {
            method: 'GET',
            headers: this.headers
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Insert New Challenge
    insertChallenge = (challenge) => {

        return fetch(`${this.controllerAPI}Insert`, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(challenge),
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Edit exist Challenge
    updateChallenge = (challenge) => {

        return fetch(`${this.controllerAPI}Edit`, {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(challenge),
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
}