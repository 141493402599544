import React, { PropTypes as T } from 'react'
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
// import Board from 'react-trello';
// import Board from '@components/KanbanBoard'
import { push } from 'react-router-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { loadKanbanData, loadFavouritesInfo } from './favouritesUtils';
import { updateSpecificItemInState } from '../groups/groupUtils';


import { showSnackbar } from '../../../actions/appActions'

import KanbanView from "../../components/Kanban/kanbanView";
import GroupKanbanPlaceholder from '../../components/Placeholders/GroupKanbanPlaceholder.js';
import { themeConstants } from '../../../theme.js';
const styles = theme => ({
    kanban: {
        marginLeft: themeConstants.navigationDrawerWidth + 5,
        
    },
    kanbanSmall: {
        marginLeft: themeConstants.navigationDrawerSmallWidth + 5
    }
})

export class FavouritesKanbanView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            results: [],
            assigned: [],
            unassigned: [],
            isLoading: true,
            lanes: [],
            groupInfoLoaded: false,
            isLoadingGroupAndData: false,
            total: 0,
        }

        // Bind the utils
        this.loadKanbanData = loadKanbanData.bind(this);
        this.loadFavouritesInfo = loadFavouritesInfo.bind(this)

        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    buildUnassignedList = () => {
        const unassignedItems = this.state.unassigned.map((unassigned, idx) => {
            return {
                id: unassigned.id,
                title: unassigned.document.title,
                order: idx,
                doc: unassigned.document
            }
        })

        const list = {
            id: -1,
            title: 'Unassigned',
            cards: unassignedItems,
            order: -1,
            isUnassigned: true,
        }

        return list
    }

    buildKanban = () => {
        const { searchQuery, dispatch, queryService, userData, collectionInfo } = this.props;


        let columns = [this.buildUnassignedList()]

        this.view = collectionInfo.collectionViews.find(c => c.collectionViewType === "Kanban");

        this.view.itemLists.forEach(list => {
            const column = {
                id: list.id,
                title: list.title,
                order: list.order,
                isUnassigned: false
            }

            column.cards = []

            list.items.forEach(item => {
                const doc = this.state.assigned.find(data => data.id === item.documentId);
                if (doc) {
                    const card = {
                        id: item.documentId,
                        title: doc.document.title,
                        order: item.order,
                        doc: doc.document
                    }
                    column.cards.push(card)
                }
            })
            column.cards = column.cards.sort((card1, card2) => {
                return card1.order - card2.order
            })

            columns.push(column)
        })
        columns = columns.sort((a, b) => a.order - b.order)

        this.setState({ lanes: columns });
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isLoadingGroupAndData && this.props.oldUiData.needsUpdate) {

            this.setState({ isLoadingGroupAndData: true })
            Promise.all([this.loadKanbanData(this.props), this.loadFavouritesInfo(this.props)])
                .then(() => {
                    this.buildKanban()
                    this.setState({ isLoadingGroupAndData: false })
                })
        }
        else if (this.props.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(this.props, this.props.oldUiData.itemId)
        }
        else if (!this.props.oldUiData.isFiltering && this.props.oldUiData.needsFilter) {

            this.setState({ isLoadingGroupAndData: true })
            Promise.all([this.loadKanbanData(this.props), this.loadFavouritesInfo(this.props)])
                .then(() => {
                    this.buildKanban()
                    this.setState({ isLoadingGroupAndData: false })
                    this.props.dispatch({ type: 'DATA_FILTERED' });

                })
        }
    }


    updateLists = (newList) => {
        const lanes = (newList || this.state.lanes).map(lane => ({ ...lane }))

        lanes.forEach(lane => {
            lane.cards = lane.cards.map(card => ({ ...card }))

        })

        this.setState({ lanes: [...lanes] })
    }


    moveList = (newList) => {
        this.updateLists(newList)

        const { favouritesService, collectionInfo } = this.props
        const collection = {
            id: collectionInfo.id,
            collectionViews: [
                {
                    id: this.view.id,
                    itemLists: (newList || this.state.lanes).filter(l => l.id != -1).map(l => ({
                        id: l.id, order: l.order
                    }))
                }
            ]
        }
        favouritesService.moveLane(collection)
    }

    componentDidMount() {
        const { urlQuery, dispatch } = this.props;

        if (urlQuery !== '') {
            dispatch({ type: 'DATA_UPDATE' })
        } else {
            dispatch(push('feed'));
        }
    }

    updateDataMoveCard = ({ card, sourceListId, targetLIstId, newPosition, moveBack }) => {


        const { favouritesService, collectionInfo, } = this.props


        return favouritesService.moveCard({
            CollectionViewId: this.view.id,
            CardId: card.id,
            SourceLaneId: sourceListId,
            TargetLaneId: targetLIstId,
            Position: newPosition
        }).then((res) => {

            if (res.ok && sourceListId != targetLIstId) {
                if (sourceListId == -1) {
                    this.setState({ total: this.state.total - 1 })
                }
                else if (targetLIstId == -1) {
                    this.setState({ total: this.state.total + 1 })
                }
            }

            return res
        })
    }








    editTitleList = (listId, newTitle) => {

        const { favouritesService, collectionInfo } = this.props;
        const { lanes } = this.state;
        const currList = lanes.find(l => l.id == listId)

        currList.title = newTitle
        const collection = {
            id: collectionInfo.id,
            collectionViews: [
                {
                    id: this.view.id,
                    itemLists: [currList]
                }
            ]
        }

        favouritesService.updateLane(collection);
        this.updateLists(lanes)
    }

    deleteList = (laneId) => {
        const { favouritesService, collectionInfo, dispatch } = this.props
        const { lanes } = this.state


        dispatch(showSnackbar('Please wait while deleting the lane!'));

        favouritesService.deleteLane(laneId, this.view.id).then(res => {
            if (res.ok) {
                // move the cards from the deleted lane to unassigned lane

                // the index of the deleted lane
                const deletedLaneIndex = lanes.findIndex(l => l.id == laneId)

                const unassignedLane = lanes.find(l => l.order == -1)
                lanes[deletedLaneIndex].cards.forEach(c => {
                    if (!c.isEmptyCard) {
                        c.order = unassignedLane.cards.length
                        unassignedLane.cards.push(c)
                    }
                })

                this.setState({ total: this.state.total + lanes[deletedLaneIndex].cards.length })

                // remove the lane
                lanes.splice(deletedLaneIndex, 1)


                this.updateLists(lanes)
            } else {
                dispatch(showSnackbar('Error occured while deleting the lane!'));

            }
        })

    }



    addList = (title) => {

        this.props.dispatch(showSnackbar('Please wait while the lane added!'));

        let laneDTO = { title, collectionViewId: this.view.id }


        this.props.favouritesService.addLane(laneDTO).then(newLane => {
            const newList = {
                cards: [],
                id: newLane.id,
                order: newLane.order,
                title: newLane.title
            }
            this.state.lanes.push(newList)
            this.updateLists()
        })
    }



    render() {
        const { groupInfoLoaded, openCardDialog, docCardClicked, lanes, total } = this.state;
        const { oldUiData, classes, isDrawerOpen } = this.props;

        if (!groupInfoLoaded || !lanes || !lanes.length || oldUiData.needsUpdate) {
            return <CircularProgress style={{ position: 'fixed', left: '50%', right: '50%', top: '27%' }} />
        } else {

            return <KanbanView
                className={isDrawerOpen ? classes.kanban : classes.kanbanSmall}
                editTitleList={this.editTitleList}
                deleteList={this.deleteList}
                loadMore={this.loadMoreQuery}
                addList={this.addList}
                moveList={this.moveList}
                updateDataMoveCard={this.updateDataMoveCard}
                updateLists={this.updateLists}
                lists={lanes}
                placeholder={ <GroupKanbanPlaceholder isFavorites={true} />}
            />
        }
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    router: state.router,
    ...state.services,
    viewOptions: state.viewOptions,
    wrapper: state.wrapper,
    classifications: state.globalData.classifications,
    knowledgelevels: state.globalData?.knowledgelevels ?? [],
    globalData: state.globalData,
    collectionInfo: state.results.channel,
    oldUiData: state.oldUiData,
    searchQuery: state.search,
    inputMappings: state.globalData.inputMappings
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading Kanban, Please Contact Tuqqi Support"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FavouritesKanbanView);