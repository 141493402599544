import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import {proccessLabelColor} from '@tuqqi/common'

const styles = theme => ({
    container: {
        width: 188
        // marginTop:8
    },
    chip: {
        margin: '0px 4px 4px 0px',
        height: 20,
        borderRadius: 4,
        width: 'auto',
        padding: '2px 4px'
    },
    text: {
        lineHeight: 1.34,
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "letterSpacing": "0.4px",
        "color": "#1B1E2A"
    }
})


export class KanbanLabel extends PureComponent {

    render() {

        const { text, color } = this.props;

        return <Grid
            key={text}
            justify='center' alignItems='center'
            container
            style={{
                backgroundColor: proccessLabelColor(color), margin: '0px 4px 4px 0px',
                height: 20,
                borderRadius: 4,
                width: 'auto',
                padding: '2px 4px'
            }} >
            <span style={{
                lineHeight: 1.34,
                "fontFamily": "Rubik",
                "fontSize": "12px",
                "fontWeight": "normal",
                "fontStretch": "normal",
                "fontStyle": "normal",
                "letterSpacing": "0.4px",
                "color": "#1B1E2A"
            }}>
                {text}
            </span>
        </Grid>
    }


}





class KanbanLabels extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    labels = () => {
            const { docLabels, collectionUid, userCollections, openCollections,orgUid,orgLabels} = this.props
            let labels = []
            if (collectionUid) {
            // Take the label from the "state.userData.collections" not from the "state.results.channel" 
            //  because the channel doesn't hold the new labels (if exist)
            const cuuCollection = userCollections.concat(openCollections)
                .find(collection => collection.collectionUid === collectionUid)
            if(!docLabels) return []
            docLabels.forEach(docLabel => {
                const [docCollectionUid, docLabelUid] = docLabel.split(':')
                if (docCollectionUid === collectionUid) {
                    const collectionLabel = cuuCollection.collectionLabels? cuuCollection.collectionLabels.find(collectionLabel => collectionLabel.labelUid === docLabelUid): {}
                    collectionLabel && labels.push(collectionLabel)
                }
            })
            } else {
    
                if (docLabels) {
                    labels = docLabels
                }
            }
           
           const filterOrgLabels = orgLabels.filter(label => docLabels.includes(`${orgUid}:${label.labelUid}`))
            return  [...labels,...filterOrgLabels]
        }
    
    render() {
        const { classes } = this.props

        return (
            <Grid container className={classes.container}>
                {
                    this.labels().map(label => label && label.name ? <KanbanLabel text={label.name} color={label.color} /> : null)
                }
            </Grid>
        )
    }
}
export default compose(withStyles(styles),
    withErrorCatcher())(KanbanLabels);
