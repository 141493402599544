import React, { Component } from 'react';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import AppContent from '../../components/App/appContent';
import PageContentWrapper from '../../components/App/PageComponents/pageContentWrapper';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SettingsDrawer from '../../components/App/Drawers/settingsDrawer';


/// *********** ///
//
//
//   HTML ONLY  //
//
//
/// *********** ///


// Apply some reset
const styles = theme => ({
    paper: {
        padding: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    switchStyle: {
        marginBottom: 16
    },
    submitStyle: {
        marginTop: 32
    },
    userTitle: {
        fontSize: '18px',
        fontWeight: '100'
    },
    useSettingsTitle: {
        fontSize: '36px',
        fontWeight: '100'
    },
    textFiledStyle: {
        fontWeight: '100'
    },
    avatar: {
        width: 80,
        height: 80,
        border: '1px solid #dbdbdb'
    },
    row: {
        display: 'flex',
        justifyContent: 'center'
    },
    listLabel: {
        marginBottom: 10
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class UserSettings extends Component {

    state = {
        personalFeedSwitchDisable: false,
    };

    render() {
        const { classes } = this.props;

        return <div data-intrcm-id="classificationsgroups_mmvbjxzt">
            <AppContent >
                <PageContentWrapper drawer={<SettingsDrawer selected="classificationsgroups"/>}>
                    <Grid  spacing={16}>
                            <Typography variant="title" style={{ marginBottom: '15px' }}>
                                Classifications
                            </Typography>
                            <Paper className={classes.paperStyle}>
                                <List className={classes.listStyle}>
                                    <ListItem>
                                        <ListItemText primary={'Public (100)'} secondary={'Public classification'} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemText primary={'Employee (5)'} secondary={'The default classification to every employee'} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemText primary={'Executive (3)'} secondary={'Managers and executives in the company'} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemText primary={'Stakeholder (4)'} secondary={'Share holders in the company'} />
                                    </ListItem>
                                    <Divider />
                                </List>

                            <Button style={{ margin: '16px' }}
                                onClick={() => this.handleClickOpen()}
                                aria-label="add"
                                color="primary"
                                variant={'raised'}>
                                Add new classification
                                </Button>
                            </Paper>

                            <Typography variant="title" style={{ marginBottom: '15px' }}>
                                Groups
                            </Typography>
                            <Paper className={classes.paperStyle}>
                                <List className={classes.listStyle}>
                                    <ListItem>
                                        <ListItemText primary={'Project X (2)'} secondary={'All the employees that works on project X'} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemText primary={'HR Group (3)'} secondary={'All the employees that works on recruiting processes'} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemText primary={'Accouting (2)'} secondary={'Employess that works on finance & salaries'} />
                                    </ListItem>
                                    <Divider />
                                </List>

                            <Button style={{ margin: '16px' }}
                                onClick={() => this.handleClickOpen()}
                                aria-label="add"
                                color="primary"
                                variant={'raised'}>
                                Add new group
                                </Button>
                            </Paper>
                        </Grid>
                </PageContentWrapper>
            </AppContent>
        </div>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(UserSettings);
