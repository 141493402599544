import React from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResultsFeed from '../../App/PageComponents/resultsFeed';

const styles = theme => ({


});

class LinkedItemsModal extends React.Component {
    state = {
        results: [],
        isLoading: true
    };


    loadData = (dataReloadInidicator) => {

        const { relatedDocuments, queryService } = this.props

        const ids = relatedDocuments.map(related => related.relatedDocumentId)
        const query = {
            itemsIds: ids,
            page: 0,
            filter: {},
            tags: [],
            size: ids.length,
            AggType: 'FLAT',
        }


        if(query.itemsIds.length){
            this.setState({ isLoading: true})

            queryService.SearchByIds(query)
                .then((results) => {
                    this.setState({ results: results.data, isLoading: false})
                })
        }

    }

    close = (e) =>{
        this.setState({results:[], isLoading: true})
        this.props.handleClose(e)
    }


    render() {
        const { open, document, relatedDocuments } = this.props;
        const { results, isLoading, tagInfo } = this.state;
        const resultsProps = {
            results, isLoading, hasMore: false, isSearch: true, Title: '', isUniqeSearch:true
        }

        if(!relatedDocuments || !relatedDocuments.length){
            return null
        }
        return (
            <div data-intrcm-id="linkedItemsModal_65r5nrik">
                <Dialog
                    open={open}
                    onEnter={this.loadData}
                    onClose={this.close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={false}

                >
                    <DialogTitle id="alert-dialog-title">{((document.title == null || typeof document.title === 'undefined' ? "" : document.title).length > 65 ? document.title.substring(0, 65) + '...' : document.title) + " : linked items"}</DialogTitle>
                    <DialogContent style={{ backgroundColor: '#f7f7f7' }}>
                        <div data-intrcm-id="linkedItemsModal_yq3w8cn6" style={{ width: 750, paddingTop: '20px' }}>
                            <ResultsFeed  {...resultsProps} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LinkedItemsModal);


// export default AlertDialog;