import React from 'react';
import SocialDataContainer from './SocialDataContainer';


const AttachmentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="AttachmentIcon__a" d="M6.178 7.822c.239.228.239.601 0 .829-.228.227-.601.227-.829 0-1.137-1.138-1.137-2.987 0-4.124l2.065-2.065c1.138-1.138 2.987-1.138 4.124 0 1.138 1.137 1.138 2.986 0 4.124l-.869.869c.006-.478-.07-.957-.233-1.412l.274-.28c.688-.682.688-1.79 0-2.473-.683-.688-1.79-.688-2.473 0l-2.06 2.06c-.688.682-.688 1.79 0 2.473m1.646-2.474c.227-.227.6-.227.828 0 1.137 1.138 1.137 2.987 0 4.124l-2.065 2.065c-1.138 1.138-2.987 1.138-4.124 0-1.138-1.137-1.138-2.986 0-4.124l.869-.869c-.006.478.07.957.233 1.418l-.274.274c-.688.682-.688 1.79 0 2.473.683.688 1.79.688 2.473 0l2.06-2.06c.688-.682.688-1.79 0-2.473-.24-.227-.24-.6 0-.828z" />
            </defs>
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0H14V14H0z" />
                <mask id="AttachmentIcon__b" fill="#fff">
                    <use href="#AttachmentIcon__a" />
                </mask>
                <use fill="#979AAA" fill-rule="nonzero" href="#AttachmentIcon__a" />
                <g fill="#979AAA" mask="url(#AttachmentIcon__b)">
                    <path d="M0 0H14V14H0z" />
                </g>
            </g>
        </svg>

    )
}


class SocialDataAttachment extends React.PureComponent {
   
    render() {
        const { counter } = this.props
        

        if (!counter) {
            return null
        }

        return (
            <SocialDataContainer 
            icon={<AttachmentIcon />} 
            text={counter} 
            tooltipText='attachment' 
            total={counter} />
        )

    }
}

export default SocialDataAttachment