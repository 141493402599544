import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';

// Apply some reset
const styles = theme => ({
    container: { 
        maxWidth:theme.mainContent.width,
        marginLeft:'auto',
        marginRight:'auto',
        fontFamily: 'Rubik',
    },
});


class AppContent extends Component {
    render() {
        const { classes, children, propStyle } = this.props;
        return <div data-intrcm-id="appContent_yoc716xe" className={classes.container} style={{...propStyle}}> 
            {children}
        </div>
    }
}
export default withStyles(styles)(AppContent);