import React, { Component, Fragment, PureComponent } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles, Grid } from '@material-ui/core';
import Spinner from '../Common/spinner.js';
import RelatedItemsSection from '../RelatedReport/RelatedItemsSection.js';
import { withTranslation } from 'react-i18next';

const styles = () => ({

    title: {
        color: '#271eff'
    }
})

class BestRelated extends PureComponent {

    state = {
        isLoading: true,
        relatedItems: [],
        linkedItems:[],
    }

    componentDidMount() {
        this.loadData()
    }
    loadData = () => {


        const { itemId, dispatch, queryService, linkedDocuments } = this.props;

        this.setState({ isLoading: true })
  
        queryService.getRelated(itemId).then((results) => {
            
            this.setState({
                relatedItems: results.bestResult,
                linkedItems: results.linkedItems,
                isLoading: false
            });
        })
    }

    render() {
        const { classes, itemId, t } = this.props
        const { relatedItems, isLoading, linkedItems } = this.state

        if (isLoading) {
            return null
        }

        return (
            <Grid container style={{ padding: 32 }}>
                <RelatedItemsSection classes={{ text: classes.title }} items={[...linkedItems, ...relatedItems]} title={t("Related items")} enableLinked={true} sourceDocId={this.props.itemId} />
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    itemId: state.fullView.itemId,
    linkedDocuments: state.fullView.item.relatedDocuments,
    queryService: state.services.queryService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles),
    withTranslation('relatedItems'),
    withErrorCatcher("Related Items could not be loaded"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(BestRelated);