import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CLEAN_LOCAL_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../../actionTypes/actionTypes';
import dataTypesDictionary from '../../../../components/Common/userCompatibleNames/dataTypesDictionary';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import MenuButton from '../MenuButton';
import MenuTableToolbar from './MenuTableToolbar';

const DataTypeSelectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="DataTypeSelectionIcon__a" d="M.71 1.71L3.3 4.3c.39.39 1.02.39 1.41 0L7.3 1.71C7.93 1.08 7.48 0 6.59 0H1.41C.52 0 .08 1.08.71 1.71z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(8 10)">
            <mask id="DataTypeSelectionIcon__b" fill="#fff">
                <use href="#DataTypeSelectionIcon__a" />
            </mask>
            <g fill="#21212D" mask="url(#DataTypeSelectionIcon__b)">
                <path d="M0 0H24V24H0z" transform="translate(-8 -10)" />
            </g>
        </g>
    </svg>
)
const styles = theme => ({
    menuTitle: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        marginRight: 8,
        "color": theme.newUI.color.gray
    },
    selectedDataType: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        "color": theme.newUI.color.commonBlack
    },
    button: {
        width: 301

    },
    menu: {
        width: 301
    }
});

class DataTypeSelection extends Component {
    defaultDataType = 'All'


    onChoose = (dataTypeSelected) => {
        const dataType = dataTypeSelected.key
        const { dispatch, firstAllResultsLength } = this.props

        const dataTypes = []
        if (dataType !== this.defaultDataType) {
            dataTypes.push(dataType)
        }
        const size = dataTypes.length > 0 ? !!firstAllResultsLength?.init ? firstAllResultsLength.value : 1000 : this.props.search.size

        dispatch({type: CLEAN_LOCAL_FILTER})
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { dataTypes, page: 0, size } })

        dispatch({ type: DATA_UPDATE });
    }

    buildOptions() {
        const { t, optionalDataTypes } = this.props;

        const selectedDataType = this.getSelectedDataType()

        const options = optionalDataTypes.map(dataType => ({
            selected: dataType.Key === selectedDataType,
            key: dataType.Key,
            label: this.translateDataType(dataType.Key),
        }))

        return options
    }

    translateDataType = (dataType) => {
        const { t, inputMappings } = this.props;

        return t(`inputTypes:${dataTypesDictionary(dataType, inputMappings)}`)
    }
    getSelectedDataType() {
        const { selectedDataTypes } = this.props;

        const dataTypeSelected = selectedDataTypes.length === 1 ? selectedDataTypes[0] : this.defaultDataType

        return dataTypeSelected
    }

    render() {
        const { classes, t } = this.props;

        const options = this.buildOptions()
        const dataTypeSelected = this.getSelectedDataType()

        return (
            <MenuTableToolbar
                title={t('Select data type')}
                classes={{ menu: classes.menu }}
                button={<MenuButton
                    classes={{ button: classes.button }}
                    menuTitle={`${t('Data type')}: `}
                    selectedValue={this.translateDataType(dataTypeSelected)}
                />}
                options={options}
                onChoose={this.onChoose}
            />
        )
    }

}

const mapStateToProps = (state, ownProps) => ({
    search: state.search,
    selectedDataTypes: state.search.dataTypes,
    optionalDataTypes: state.results.dataTypes,
    inputMappings: state.globalData.inputMappings
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('dataTypeSelectionTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(DataTypeSelection);