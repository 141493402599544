import React, { Component } from 'react';
import { connect } from 'react-redux'
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import './PhotoMessage.css';
import FaError from 'react-icons/lib/fa/exclamation-triangle';
import parseImgeUrl from '../../../components/Common/parseImagUrl.js';
import { Typography } from '@material-ui/core';

class PhotoMessage extends Component {
    onOpen = () => {
        const { attachment, onOpenLightbox } = this.props
        const { fileUid } = attachment
        onOpenLightbox(fileUid)
    }

    render() {
        const { cloudfront, attachment } = this.props
        const { fileUid, fileName } = attachment

        const fullImageUrl = parseImgeUrl(cloudfront, fileUid, 500)

        const error = this.props.data.status && this.props.data.status.error === true;

        return (
            <div data-intrcm-id="PhotoMessage_xag7wacs" className="rce-mbox-photo">
                <div data-intrcm-id="PhotoMessage_ijg9u6f9"
                    className="rce-mbox-photo--img"
                    style={this.props.data.width && this.props.data.height && {
                        width: this.props.data.width,
                        height: this.props.data.height,
                    }}>
                    <img
                        style={{cursor: 'pointer'}}
                        src={fullImageUrl}
                        alt={this.props.data.alt}
                        onClick={this.onOpen}
                        onLoad={this.props.onLoad} />

                    {
                        error &&
                        <div data-intrcm-id="PhotoMessage_09a0ncvo" className="rce-mbox-photo--img__block">
                            <span
                                className="rce-mbox-photo--img__block-item rce-mbox-photo--error">
                                <FaError />
                            </span>
                        </div>
                    }
                </div>
                <Typography style={{fontSize: 14, maxWidth: 500, textAlign: 'center', marginTop: 8}} >{fileName}</Typography>
            </div>
        );
    }
}

PhotoMessage.defaultProps = {
    text: '',
    data: {
        status: {
            click: false,
            loading: 0,
            download: true
        }
    },
    onDownload: null,
    onOpen: null,
    onLoad: null,
};


const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorCatcher()(PhotoMessage));

