import { List, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';
import TaskListItem from '../../FullView/Checklist/taskListItem';
import ChecklistHeader from './ChecklistHeader.js';
import { updateChecklistTitle, updateTasks } from './ListRulesUtils.js';
import withErrorCatcher from '../../Common/withErrorCatcher.js'

// Apply some reset
const styles = theme => ({
    checklist: {

    }
});

class ChecklistRuleTemplate extends Component {
    componentDidMount() {
        this.addEmptyTask()
    }

    onTaskDeleted = (isNewTask, task) => {
        const { checklist, updateData } = this.props;

        const taskIndex = checklist.tasks.findIndex(t => t === task)
        checklist.tasks.splice(taskIndex, 1)

        const newChecklist = updateTasks(checklist, checklist.tasks)
        updateData(newChecklist);
    }

    handleNewTaskChanged = (taskIdx) => (cheklistId, newValue) => {
        const { checklist, updateData } = this.props;
        const { tasks } = checklist;

        // change the value
        tasks[taskIdx] = { ...tasks[taskIdx], text: newValue }

        // init the state
        const newChecklist = updateTasks(checklist, tasks)
        updateData(newChecklist);
    }

    newTaskIndexSent = 0

    addTask = () => {
        const { checklist, updateData } = this.props;

        const newTask = {
            text: '',
            isNewTask: true,
        }

        const newChecklist = updateTasks(checklist, checklist.tasks ? [...checklist.tasks, newTask] : [newTask])
        updateData(newChecklist);
    }

    resetTasks = () => {
        const { checklist, updateData } = this.props;

        const newChecklist = updateTasks(checklist, [])
        updateData(newChecklist);
    }

    componentDidUpdate(prevProps) {
        const { checklist } = this.props;

        if (checklist && checklist.tasks && !checklist.tasks.length) {
            this.addEmptyTask()
        }
    }

    addEmptyTask() {
        const isExistsNewTask = this.props.checklist.tasks ? this.props.checklist.tasks.length : 0

        if (!isExistsNewTask) {
            this.addTask()
        }
    }

    isNotEmptyTask(task) {
        return (task.text || '').trim().length != 0
    }

    changeTitle = (e) => {
        const newChecklist = updateChecklistTitle(this.props.checklist, e.target.value);
        this.props.updateData(newChecklist);
    }

    render() {
        const { classes, checklist: { title, tasks } } = this.props;

        return (
            <div data-intrcm-id="ChecklistRuleTemplate_yd0p0w7c" key={"checklist"} className={classes.checklist}>
                <ChecklistHeader
                    index={'checklist-header'}
                    title={title}
                    addTask={this.addTask}
                    changeTitle={this.changeTitle}
                    resetTasks={this.resetTasks}
                />

                <List>
                    {
                      tasks &&  tasks.map(((task, taskIdx) => <TaskListItem
                            key={taskIdx}
                            task={task}
                            isNewTask={true}
                            onTaskDeleted={this.onTaskDeleted}
                            onTaskUpdate={() => { }}
                            handleNewTaskChanged={this.handleNewTaskChanged(taskIdx)}
                            saveNewTask={this.addTask}
                            isChangeable={true}
                            isTemplate={true}
                        />))
                    }
                </List>
            </div>
        )
    }
}

export default compose(withStyles(styles))(ChecklistRuleTemplate);