import React, { PureComponent } from 'react';
import withErrorCatcher from './withErrorCatcher.js'
import { CircularProgress, withStyles } from '@material-ui/core';
import classnames from "classnames";
const styles = theme => ({
    progress:{
        // position:'absolute',
        top:'calc(50% - 20px)',
        left:'calc(50% - 20px)'
    },
    absolutePosition:{
        position:'absolute',
    },
    relativePosition:{
        position:'relative',
    }
})

class Spinner extends PureComponent{
    render(){
        const { classes, isAbsolutePosition, isSpinnerRelativePosition } = this.props
        return (
            <div data-intrcm-id="spinner_pymypf1j" className={classnames(classes.spinner, isSpinnerRelativePosition ? classes.relativePosition :'')}>
                <CircularProgress className={classnames(classes.progress, isAbsolutePosition ? classes.absolutePosition :'')} />
            </div>)
    }
}

export default withStyles(styles)(Spinner);