import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Avatar } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classnames from "classnames";
import QuestionMark from './QuestionMark';
const styles = theme => ({
    avatar: {
marginLeft:-15,
width:30,
height:30
    },
});

class QuestionTemplate extends PureComponent {



    render() {



        const { classes, taggedUsers, isInput, users } = this.props;
if(isInput){
    return (
        <Grid item container style={{width:'auto', margin: '30px 0 8px 0'}}>{taggedUsers.map(u => <Avatar  className={classes.avatar} >?</Avatar>)}</Grid>
    )
}

return (
            <Grid item container style={{width:'auto', margin: '30px 0 8px 0'}}>{taggedUsers.slice(0, 5).map((tag, idx) => {
                    const user = users.find(u => u.tag == tag)
                    if(user){
                        const userName = `${user.firstname} ${user.lastname}`
                        return <Avatar key={idx} className={classes.avatar} src={user.profileImageUrl} alt={userName} title={userName} />
                    } else{
                        return null
                    }
            })}</Grid>
        )
    }
}


const mapStateToProps = (state) => ({
    users: state.globalData.users,
})


export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        null
    ))(QuestionTemplate);
// export default compose(withStyles(styles))(QuestionTemplate);
