import React from 'react'

export const ArrowLeftIcon = (props) => (
    <svg onClick={props.onClick} className={props.className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <rect id="ArrowLeftIcon__a" width="1528" height="896" x="360" y="152" rx="4"/>
        <path id="ArrowLeftIcon__c" d="M6.71 9.88L2.83 6l3.88-3.88c.39-.39.39-1.02 0-1.41C6.32.32 5.69.32 5.3.71L.71 5.3c-.39.39-.39 1.02 0 1.41L5.3 11.3c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(-1802 -1010)">
        <path fill={props.backgroundColor || '#FFF'} d="M0 0H1920V1080H0z"/>
        <mask id="ArrowLeftIcon__b" fill="#fff">
            <use href="#ArrowLeftIcon__a"/>
        </mask>
        <rect width="1528.5" height="896.5" x="359.75" y="151.75" stroke="#979AAA" stroke-width=".5" rx="4"/>
        <g mask="url(#ArrowLeftIcon__b)">
            <g transform="translate(360 996)">
                <rect width="1528.5" height="52.5" x="-.25" y="-.25" fill={props.backgroundColor || '#FFF'} fill-rule="nonzero" stroke="#979AAA" stroke-width=".5" rx="4"/>
                <g>
                    <path d="M0 0H48V48H0z" transform="translate(1430 2)"/>
                    <g transform="translate(1430 2) translate(20 18)">
                        <mask id="ArrowLeftIcon__d" fill="#fff">
                            <use href="#ArrowLeftIcon__c"/>
                        </mask>
                        <g mask="url(#ArrowLeftIcon__d)" className={props.className}>
                            <path d="M0 0H24V24H0z" transform="translate(-8 -6)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export const ArrowRightIcon = (props) => (
    <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <rect id="ArrowRightIcon__a" width="1528" height="896" x="360" y="152" rx="4"/>
        <path id="ArrowRightIcon__c" d="M1 .71C.61 1.1.61 1.73 1 2.12L4.88 6 1 9.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L7 6.7c.39-.39.39-1.02 0-1.41L2.41.7C2.03.32 1.39.32 1 .71z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(-1850 -1010)">
        <path fill={props.backgroundColor || '#FFF'} d="M0 0H1920V1080H0z"/>
        <mask id="ArrowRightIcon__b" fill="#fff">
            <use href="#ArrowRightIcon__a"/>
        </mask>
        <rect width="1528.5" height="896.5" x="359.75" y="151.75" stroke="#979AAA" stroke-width=".5" rx="4"/>
        <g mask="url(#ArrowRightIcon__b)">
            <g transform="translate(360 996)">
                <rect width="1528.5" height="52.5" x="-.25" y="-.25" fill={props.backgroundColor || '#FFF'} fill-rule="nonzero" stroke="#979AAA" stroke-width=".5" rx="4"/>
                <g>
                    <path d="M0 0H48V48H0z" transform="translate(1478 2)"/>
                    <g transform="translate(1478 2) translate(20 18)">
                        <mask id="ArrowRightIcon__d" fill="#fff">
                            <use href="#ArrowRightIcon__c"/>
                        </mask>
                        <g mask="url(#ArrowRightIcon__d)" className={props.className}>
                            <path d="M0 0H24V24H0z" transform="translate(-8 -6)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)