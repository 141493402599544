import { push } from "react-router-redux"
import { SEARCH_QUERY } from "../../actionTypes/actionTypes"
import {reduxState} from '../reduxHelpers'

export const navOptions = {
    MY_PAGE: 'mypage',
    GROUP: 'group',
    GROUP_CATEGORY: 'groupcategory',
    SEARCH: 'search',
    GANTT: 'gantt',
    GROUP_SCHEDULER: 'scheduler',
    CHANNEL: 'channel',
    USER_PROFILE: 'userprofile',
    USER_FOUND: 'userfound'
}

export const goToPath = (goToPath, dispatch) => {
    dispatch(push({ pathname: `${goToPath}`, search: window.location.search }))
    dispatch({ type: SEARCH_QUERY, query: { page: 0 } })
}

export const getDatatypesState = () => {
    const getState = reduxState()
    return getState?.()?.results?.dataTypes ?? []
}

export const getInputMappingsState = () => {
    const getState = reduxState()
    return getState?.()?.globalData?.inputMappings ?? []
}