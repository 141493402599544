import React, { PureComponent } from 'react';

export default class SearchLinkIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14 11.5C14 9.25639 11.8761 7 9.28571 7C8.97888 7 7.96745 7.00001 7.71429 7.00001C5.11066 7.00001 3 9.01473 3 11.5C3 13.6416 4.56725 15.4338 6.66667 15.8885C7.0036 15.9616 7.35422 16 7.71429 16" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 11.5C10 13.7436 12.1239 16 14.7143 16C15.0211 16 16.0326 16 16.2857 16C18.8894 16 21 13.9853 21 11.5C21 9.35842 19.4328 7.56624 17.3333 7.11151C16.9964 7.03853 16.6457 7 16.2857 7" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }
}