import { FormControl, TextField, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withErrorCatcher from '../../../../components/Common/withErrorCatcher'

const styles = (theme) => ({
    formControl: {
        margin: '0 5px',
        display: 'flex'
    },
    title: {
        marginBottom: 8,
        marginTop: 24,
        fontFamily: "Rubik",
        fontSize: 18,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.25px"
    },
})

const __ItemExternalLinkType = (props) => {
    const { classes, referenceUid, onChangeReferenceUid} = props
    const [t] = useTranslation('admin')

    const onChangeLink = (e) => {
        e.persist()
        onChangeReferenceUid(e.target.value)
    }

    return <>
        <Typography variant="title" className={classes.title}>
            {t('Add link')}
        </Typography>
        <FormControl className={classes.formControl}>
			<TextField label={t('Link')} required error={!!referenceUid ? false : true} onChange={onChangeLink} value={referenceUid} margin='dense' />
		</FormControl>
    </>
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const ItemExternalLinkType = compose(
    withStyles(styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(__ItemExternalLinkType)