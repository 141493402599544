import React from 'react'
import withErrorCatcher from '../withErrorCatcher.js'
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next'
import PickFileComponent from './PickFileComponent'
import '../../Common/FilesUploader/FilesUploaderStyles.css';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateType)

var defaultTranslate = a => a
const styles = {
    input: {
        fontSize: 14,
        letterSpacing: '0.1px',
        color: 'color: rgba(0, 0, 0, 0.54)',
        overflowY: 'scroll'
    },
    pickImageElementWrapper: {
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        // top: -10,
        height: 27,
        overflow: 'hidden',
        marginTop: 10
    }
}

export default function renderInput(inputProps) {
    const {
        classes,
        autoFocus,
        value,
        multiline,
        allowRows,
        onSubmit,
        loading,
        placeholder,
        t,
        onLoad,
        onUpdateFiles,
        ...other
    } = inputProps;
    const translate = t || defaultTranslate

    return (
        <div data-intrcm-id="inputComponent_rczdrn16" >
            <Input style={{ ...styles.input }}
                disableUnderline={true}
                placeholder={placeholder || translate('Add new comment')}
                className={classes.textField}
                value={value}
                autoFocus
                multiline={allowRows || multiline}
                inputProps={{
                    dir: "auto",
                    autoFocus,
                    'aria-label': 'Description',
                    ...other,
                }}
            />
            {multiline
                ?
                <div data-intrcm-id="inputComponent_w97b4q48" style={styles.pickImageElementWrapper}>
                    <PickFileComponent 
                        onLoad={onLoad}
                        allowMultiple={false}
                        onUpdateFiles={onUpdateFiles}
                    />
                    <div data-intrcm-id="inputComponent_yo7yq26j" >
                        <Button size="small" disabled={loading} variant="raised" onClick={onSubmit} className={classes.buttonIc}>
                            {translate('Send')}
                        </Button>
                        {loading ? <CircularProgress size={25} className={classes.buttonProgress} /> : null}
                    </div>
                </div>

                : null
            }
        </div>
    );
}