import React from 'react'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({

    header: {
        margin: 0
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    }
});


const NonProfit = (props) => {
    return <div data-intrcm-id="GroupFeedPlaceholder_r4aft5n0">
        Non profit
    </div>
}


const Startup = (props) => {
    return <div data-intrcm-id="GroupFeedPlaceholder_3fb8l6rk">
        Startup
    </div>
}

const NoTemplate = (props) => {
    return <Grid>
        <div data-intrcm-id="GroupFeedPlaceholder_6eobdjpb" style={{ top: 100, left: 120, position: 'relative' }} >
            <img alt="Image" src={"/resources/placeholders/Illustration_feedgroup.svg"} />
            <br />
            <div data-intrcm-id="GroupFeedPlaceholder_fk4j0u2m" style={{ "backgroundColor": "rgba(255,255,255,0.0)", "height": "40px", "width": "320px", marginTop: 24, marginLeft: -46, "transform": "rotate(0deg)", "opacity": "1.0", "fontFamily": "\"Rubik\", Helvetica, Arial, serif", "fontWeight": "400", "fontStyle": "normal", "fontSize": "14.0px", "color": "rgba(33, 33, 45, 1.0)", "textAlign": "center", "letterSpacing": "0.25px", "lineHeight": "20.0px" }}>
                Seems like there are no items yet,<br />
                add new items in the "Start writing here..." box.
            </div>
        </div>
    </Grid>

}

export default withStyles(styles)((props) => {
    const { classes, orgType } = props;
    return <NoTemplate />

    const templates = [
        { name: "", title: "Blank" },
        { name: "nonProfit", title: "Non-profit" },
        { name: "startup", title: "Startup" },
        { name: "marketingAgency", title: "Marketing agency" },
        { name: "corporateTeam", title: "Corporate team" },
        { name: "municipality", title: "Municipality" },
        { name: "consultingGroup", title: "Consulting group" },
        { name: "familyOffice", title: "Family office" },
        { name: "lawFirm", title: "Law firm" },
        { name: "itGroup", title: "IT group" },
    ]

    switch (orgType) {
        case "nonProfit":
            return <NonProfit />
            break;
        case "startup":
            return <Startup />
            break;
        case "marketingAgency":
            return <Startup />
            break;
        case "municipality":
            return <Startup />
            break;
        case "consultingGroup":
            return <Startup />
            break;
        case "familyOffice":
            return <Startup />
            break;
        case "lawFirm":
            return <Startup />
            break;
        case "itGroup":
            return <Startup />
            break;

        default:
            return <NoTemplate />

    }
})