import React, { Component } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import renderInput from './inputComponent'
import renderSuggestionsContainer from './suggestionContainerComponent'
import renderSuggestion from './suggestionComponent'
import { withTranslation } from 'react-i18next'

const styles = theme => ({

    containerClose: {
        boxShadow: 'none'
    },
    container: {
        flex: 'auto',
        border: 'solid 1px #d1d1d1',
        borderRadius: 8,
        maxHeight: 158,
        paddingLeft: 10,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 16,
    },
    suggestionsContainerOpen: {
        zIndex: 3,
        position: 'relative',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 3,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04);',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    textField: {
        width: '100%',
        height: '100%',
        alignItems: 'normal'
    },
    buttonIc: {
        backgroundColor: '#ff863a',
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 0.4,
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none',
    },
    buttonProgress: {
        color: '#ff863a',
        position: 'absolute',
        right: 20
    },
});

class AutosuggestFieldFullView extends Component {
    state = {
        value: '',
        suggestionSelected: false,
        suggestions: [],
    };

    getSuggestions = value => {
        this.query = value;
        return this.props.queryService.autosuggest(value.toString());
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        var valueForSuggest = value;

        // split the space and the line break from text
        const vals = value.split(/\s/gm)
        if (vals.length > 1) {
            valueForSuggest = vals[vals.length - 1];
        }

        if (valueForSuggest.charAt(0) === '#') {
            this.getSuggestions(valueForSuggest)
                .then((values) => {
                    var suggestions = [];
                    this.setState({
                        suggestions: values
                    });
                })
        } else if (valueForSuggest.charAt(0) === '@' && valueForSuggest.length > 1) {
            const slicedValueForSuggest = valueForSuggest.slice(1)
            this.setState({
                suggestions: this.state.userMentions.filter(x => x.displayName.includes(slicedValueForSuggest))
            });
        }
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getSuggestionValue = suggestion => {
        const suggestionText = suggestion.tag || suggestion.value;
        if (suggestionText == null) {
            return suggestion.query || this.props.searchQuery.query || "";
        } else {
            const splitedQuery = this.props.value.split(" ")

            // Split the last word from the other words
            const otherWords = splitedQuery.length > 1 ? (splitedQuery.slice(0, -1).join(' ') + ' ') : ''
            // const lastWord = splitedQuery[splitedQuery.length - 1]

            // The prefix of the last word
            const prefix = suggestion.prefix || ""

            const res = otherWords + prefix + suggestionText
            return res;
        }
    };

    onKeyPress = (event) => {


        // enter key pressed
        if (event.key.toLowerCase() === 'enter') {
            //event.preventDefault();
            
            if (this.state.suggestionSelected) {
                this.setState({ suggestionSelected: false });
                event.preventDefault();
            } else if(!event.shiftKey){
                this.props.onSubmit()
            }
        }
    }

    componentDidMount() {
        var users = [];
        this.props.global.activeUsers.map((user) => {

            // Check if user name is initiated
            if (typeof user.firstname !== 'undefined' && typeof user.lastname !== 'undefined') {
                let tempUser = {
                    prefix: '@',
                    displayName: (user.firstname + user.lastname).toLowerCase(),
                    description: user.firstname + ' ' + user.lastname,
                    tag: user.tag.toLowerCase(),
                    type: 'Person',
                    avatar: user.profileImageUrl
                }
                users.push(tempUser);
            }
        })
        this.setState({ userMentions: users })
    }

    render() {
        const { classes, value, onChange, t, onUpdateFiles, onLoadFile } = this.props;

        return (
            <Autosuggest
                theme={{
                    container: classes.container,
                    suggestionsContainer: classes.containerClose,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={this.state.suggestions}

                onSuggestionSelected={(event) => {
                    this.setState({ suggestionSelected: true });

                }}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    t,
                    classes,
                    autoFocus: false,
                    value: value,
                    multiline: true,
                    onSubmit: this.props.onSubmit,
                    loading: this.props.loading,
                    numberOfLines: 5,
                    onChange: onChange,
                    onKeyPress: this.onKeyPress,
                    onLoad: onLoadFile,
                    onUpdateFiles
                }}
            />
        );
    }
}

AutosuggestFieldFullView.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.userData,
        searchQuery: state.search,
        global: state.globalData,
        ...state.services,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('renderInput'),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AutosuggestFieldFullView);