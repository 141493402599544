import { Slide, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { updateSubscription } from '../../../actions/globalDataAction';
import { HIDE_UPGRADE_FROM_FREE } from '../../../actionTypes/actionTypes';











function Transition(props) {
    return <Slide direction="up" {...props} />;
}

// Apply some reset
const styles = theme => ({
    root: {
        minWidth: 768,//1024,
        borderRadius: 16,
        minHeight: 700,
        overflowX: 'hidden'
    },
    paperFullWidth: {
        maxWidth: 840
    }
});


class YouNeedMoreUsersModal extends PureComponent {

    state = {
        usersNumber: 1,
        isSaving: false,
    }

    onChange = (event) => {
        this.setState({ usersNumber: event.target.value })
    }




    onClose = () => {
        const { dispatch, billingService } = this.props
        billingService.getCurrentSubscription().then((result) => {
            // Update current subscription before changing anything
            dispatch(updateSubscription(result))
        })
        dispatch({ type: HIDE_UPGRADE_FROM_FREE })
    }

    render() {
        const { upgradeFromFree, profileData, classes } = this.props;
        const { isOpen } = upgradeFromFree


        if (isOpen) {
            return (<Dialog
                open={true}
                classes={{ paperFullWidth: classes.paperFullWidth, paperWidthSm: classes.paperFullWidth }}
                scroll={'body'}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <iframe
                    style={{
                        borderWidth: 0,
                        borderRadius: 8
                    }}
                    width={840}
                    height={1100}
                    src={"https://store.payproglobal.com/checkout?products[1][id]=58249&products[1][qty]=5&page-template=13635&plan-products[]=58249,58250&exfo=742&billing-email=" + profileData.email + "&x-orguid=" + profileData.org.orgUid} ></iframe>
            </Dialog>)
        } else { return null }
    }




}


const mapStateToProps = (state) => ({
    upgradeFromFree: state.upgradeFromFree,
    profileData: state.userData.profileData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        goTo: path => { dispatch(push(path)) }

    }
}
export default compose(withStyles(styles),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(YouNeedMoreUsersModal);

