import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class NewGroupIcon extends PureComponent {

    render() {
        return (
            <SvgIcon className={this.props.className} viewBox="0 0 28 28" style={{ height: 23, width: 33 }}>
               <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                    <stop stop-color="#8645F9" offset="0%"/>
                    <stop stop-color="#BB7BFD" offset="100%"/>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
                    <stop stop-color="#8645F9" offset="0%"/>
                    <stop stop-color="#BB7BFD" offset="100%"/>
                </linearGradient>
                </defs>
                <g fill-rule="nonzero" fill="none">
                <path d="m19.67 14.452-4.018.718V5.862l4.218-.753a.523.523 0 0 0 .416-.607.514.514 0 0 0-.593-.426l-4.04.723V.524c0-.29-.23-.524-.514-.524a.518.518 0 0 0-.513.524v4.458l-8.958 1.6V2.517c0-.29-.23-.524-.513-.524a.518.518 0 0 0-.513.524v4.25l-4.194.75a.523.523 0 0 0-.417.606.516.516 0 0 0 .594.426l4.017-.718v9.308l-4.217.753a.523.523 0 0 0-.417.607.516.516 0 0 0 .593.426l4.041-.723v4.275c0 .29.23.524.513.524a.518.518 0 0 0 .513-.524v-4.458l8.958-1.6v4.066c0 .29.23.524.513.524a.518.518 0 0 0 .513-.524v-4.25l4.194-.75a.523.523 0 0 0 .417-.606.514.514 0 0 0-.594-.426zM5.667 16.954V7.647l8.958-1.601v9.307l-8.958 1.601z" stroke="url(#a)" stroke-width="2" fill="url(#a)" transform="translate(1 1)"/>
                <path fill="url(#b)" d="M30 6.5v-3h-3v3h-3v3h3v3h3v-3h3v-3z" transform="translate(1 1)"/>
                </g>
            </SvgIcon>)
    }
}
