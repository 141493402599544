import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, withStyles, Grid, Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Spinner from '../../Common/spinner';
import withErrorCatcher from '../../Common/withErrorCatcher';
import OnErrorDialog from '../../Common/OnErrorDialog'
import moment from 'moment'

const styles = theme => ({
    spinner: {
        textAlign: 'center',
        paddingTop: 16,
        width: '100%'
    },
    root: {
        width: '100%',
        marginTop: 10,
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
})

class ItemLogsModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            logs: [],
            isLoading: true,
            error: false
        }
    }

    componentDidMount() {
        const { collaborationService, docId } = this.props

        collaborationService.getDocumentActivityLogs(docId).then(logs => {
            this.setState({ logs, isLoading: false })
        }).catch(x => {
            console.error(x)
            this.setState({ isLoading: false, error: true })
        })
    }

    getContent() {
        const { logs, isLoading, error } = this.state
        const { classes } = this.props

        if (isLoading) {
            return <Spinner classes={classes} />
        }

        if (error) {
            return <span>Error Loading Logs. Please Contact Tuqqi Support</span>
        }

        return <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Time</TableCell>
                        <TableCell align="center">Activity</TableCell>
                        <TableCell align="left">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map(log => (
                        <TableRow key={log.date}>
                            <TableCell align="center" component="th" scope="row">
                                {moment(log.dateTime).format('MMMM Do YYYY, HH:mm:ss')}
                            </TableCell>
                            <TableCell align="center">action of type {log.actionType} by {log.performer}</TableCell>
                            <TableCell align="left">{log.userName ? log.userName : ''} performed {log.action}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    }

    render() {
        const { open, onClose } = this.props

        return <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>Activity Log{this.state.logs.length > 0 ? '(' + this.state.logs.length + ')' : null}</DialogTitle>
            <DialogContent>
                <Grid container>
                    {this.getContent()}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    collaborationService: state.services.collaborationService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(OnErrorDialog),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemLogsModal);