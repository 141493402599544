import React, { PureComponent } from 'react';

export default class SearchMediaIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="4" y="5" width="16" height="14" rx="2" stroke={color} stroke-width="1.5"/>
                <path d="M6 17L10 13L12 15L16 11L18 13" stroke={color}/>
                <circle cx="8" cy="9" r="1.5" stroke={color}/>
            </svg>
        )
    }
}