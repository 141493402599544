import {sortItemsByLaneColor} from '../../../../utils/actionsCenter/helpers'
const distantFuture = new Date(8640000000000000)

const getCollectionByGroupUid = (groupUid, allCollections) => {
    return allCollections.find(coll => coll.collectionGroup.groupUid === groupUid)
}

const sortTasksByItemsLen = (tasks = []) => {
    return tasks.sort((a, b) => {
        return b.items.length - a.items.length
    })
}

const sortByCreation = (items, sortOrder) => {
    return items.sort((a, b) => {
        let dateA = a.docTimestamp ? new Date(a.docTimestamp) : distantFuture
        let dateB = b.docTimestamp ? new Date(b.docTimestamp) : distantFuture
        return sortOrder === 'asc' ? 
            dateA.getTime() - dateB.getTime() :
            dateB.getTime() - dateA.getTime()
    })
}


const sortByDueDate = (items, sortOrder) => {
    const itemsWithoutDueDate = items.filter(x => !x.dueDateUTC)
    const itemsWithDueDate = items.filter(x => !!x.dueDateUTC)

    const sortedByDueDate = itemsWithDueDate.sort((a, b) => {
        let dateA = a.dueDateUTC ? new Date(a.dueDateUTC) : distantFuture
        let dateB = b.dueDateUTC ? new Date(b.dueDateUTC) : distantFuture
        return sortOrder === 'asc' ? 
            dateA.getTime() - dateB.getTime() :
            dateB.getTime() - dateA.getTime()
    })

    const sortedByCreation = sortByCreation(itemsWithoutDueDate, 'asc')
    return [...sortedByDueDate, ...sortedByCreation]
}

const sortByRating = (items, sortOrder, additionalData) => {
    const itemsWithoutRating = items.filter(x => (!x.rating && x.rating !== 0) || !additionalData.isEnabledRating(x.dataType))
    const itemsWithRating = items.filter(x => (!!x.rating || x.rating === 0) && !!additionalData.isEnabledRating(x.dataType))
    
    const sortedByRating = itemsWithRating.sort((a, b) => {
        if(a.rating === b.rating) {
            let dateA = a.docTimestamp ? new Date(a.docTimestamp) : distantFuture
            let dateB = b.docTimestamp ? new Date(b.docTimestamp) : distantFuture
            return dateA.getTime() - dateB.getTime()
        }
        return sortOrder === 'desc' ? 
            a.rating > b.rating ? -1 : 1 : 
            a.rating > b.rating ? 1 : -1
    })

    const sortedByCreation = sortByCreation(itemsWithoutRating, 'asc')
    return [...sortedByRating, ...sortedByCreation]
}


export const buildOngoingTasks = (results, allCollections) => {
    //{collectionTitle, items: [], collectionUid, groupUid}
    const lanes = {}
    results.forEach(res => {
        if(!res?.Value?.Source) return
        const { groupUid } = res.Value.Source
        const collection = getCollectionByGroupUid(groupUid, allCollections)
        if(!collection) return

        if(!lanes[collection.collectionUid]) {
            lanes[collection.collectionUid] = {
                collectionTitle: collection.title,
                collectionUid: collection.collectionUid,
                groupUid: collection.collectionGroup.groupUid,
                items: [res.Value.Source]
            }
            return
        }
        const items = [...(lanes[collection.collectionUid].items ?? []), res.Value.Source]
        lanes[collection.collectionUid] = {
            ...lanes[collection.collectionUid],
            items: items
        } 
    });
    const values = Object.values(lanes)
    const sortedValues = sortTasksByItemsLen(values)
    return sortedValues
}

export const sortOngoingLane = (items, sortOption, additionalData) => {
    switch (sortOption) {
        case 'dueDate':
            return sortByDueDate(items, 'asc')
        case 'status':
            return items.sort(sortItemsByLaneColor)
        case 'rating':
            return sortByRating(items, 'desc', additionalData)
        default:
            return items
    }
}