import React from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import DateFrom from "./dateFrom";

const styles = theme => ({
    ...theme.comment,
    svg: {
        fill: 'white',
    },
    svgContainer: {
        flex: '0 1 0px',
    },
    svgBorder:{
        backgroundColor:'#3154ef',
        width: 34,
        height: 34,
        border: 'solid 2px #3154ef',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '18px 0 auto 16px',
        marginRight:'16px'
    },
    commentTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle : {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle2 : {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.38)'
    }
})

export default withStyles(styles)((props) => {

    const { classes, activity, key, first, last  } = props;

    if(activity){

        const { userPicUrl, dateTime, userName, text, type, description } = activity;

        return (
            <div data-intrcm-id="Activity_kdzasur1" className={classes.container}>
                <div data-intrcm-id="Activity_1es0nyt0" className={classes.svgContainer}>
                    <div data-intrcm-id="Activity_fy9ald7d" className={classes.svgBorder}>
                        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
                        </svg>
                    </div>
    
                </div>
                <div data-intrcm-id="Activity_3fcxsmx6" className={classes.containerContentTime} style={{ borderBottomStyle: 'solid'}}>
                    <div data-intrcm-id="Activity_nnna0ytc" className={classes.content}>
                        <div data-intrcm-id="Activity_hngaf41n" className={classes.commentTitle}>{userName}</div>
                        <div data-intrcm-id="Activity_8pse971i" className={classes.commentSubTitle} >{description}</div>
                        <div data-intrcm-id="Activity_t2io8jzm" className={classes.commentSubTitle2}><DateFrom dateTime={dateTime}/></div>
                    </div>
                </div>
            </div>)
    }

    return null

})