import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import parseImgeUrl from '../../../Common/parseImagUrl';

const styles = (theme) => ({
    container: {
        objectFit: 'cover'
    },
})

const MainImgLinked = (props) => {
    const {styles, classes, name, queryService, documents, cloudfront, currentScene} = props
    const [backgroundLink, setBackgroundLink] = useState('')
    const [docId, setDocId] = useState(null)
    const [eventType, setEventType] = useState(null)
    const [groupType, setGroupType] = useState(null)
    const [previewImage, setPreviewImage] = useState('')
    const width = 296

    const preloadImages = () => {
        if(eventType && groupType && name) {
            import('../../../../../assets/img/stories/' + eventType + "/" + groupType + "/" + name + ".png").then(image => {
                setBackgroundLink(image.default ?? '')
            });
        }
    }

    useEffect(() => {
        setEventType(currentScene.systemActionTypeUid)
        setGroupType(currentScene.groupType)
        setDocId(currentScene.messageTemplateParams[3])
    }, [currentScene])

    useEffect(() => {
        preloadImages()
    }, [eventType, groupType, name])

    useEffect(() => {
        window.addEventListener('DOMContentLoaded', preloadImages);

        return () => {
            window.removeEventListener('DOMContentLoaded', preloadImages)
        }
    }, [])

    useEffect(() => {
        if(!docId) return
        
        const doc = documents[docId]
        if(!!doc?.document?.previewImgUrl) {
            setPreviewImage(doc.document.previewImgUrl)
            return
        }
        queryService.searchById(docId).then((results) => {
            if (!results?.data?.length) return
            const doc = results.data[0].Value.Source;

            if(doc?.document?.previewImgUrl) {
                setPreviewImage(doc.document.previewImgUrl)
            }
        })
    }, [documents, docId, queryService])

    return (<img src={!previewImage.length ? backgroundLink : parseImgeUrl(cloudfront, previewImage, width)} style={styles.container} className={`${classes.container}`} />)
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService,
    documents: state.data.documents.data ?? {},
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(MainImgLinked)