import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import React from 'react';

class OnErrorDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = { open: true }
    }

    closeDialog = () => this.setState({ open: false })

    render() {
        const { open } = this.state
        const { content } = this.props

        const defualtContent = <span>"Something Went Wrong, Please Contact Tuqqi support"</span>

        return <Dialog open={open} onClose={this.closeDialog}>
            <DialogContent>
                {content ? content : defualtContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    }
}

export default OnErrorDialog