import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Tooltip, withStyles } from '@material-ui/core'
import withErrorCatcher from '../../../components/Common/withErrorCatcher'
import parseImagUrl from "../../../components/Common/parseImagUrl";
import GeneralButton from '../../../components/Common/AllButton/AllButton';
import groupPlaceholder from "../../../../assets/img/group_placeholder.png";
import Members from './Members'
import { useTranslation } from 'react-i18next'
import { hexToRGB } from '../../groups/Calendar/event-utils'
import goToCollection from '../../../actions/goToCollection'
import { useState } from 'react'

function isTruncated(el) {
    if(!el) return false
    return el.scrollWidth > el.clientWidth
}

const _styles = (theme) => ({
    container: {
        marginLeft: 8,
        '&:first-child': {
            marginLeft: 0
        },
        boxSizing: 'border-box',
        display: 'flex',
        width: 252,
        maxWidth: 252,
        height: 228,
        maxHeight: 228,
        background: '#FFFFFF',
        border: '1px solid #D7D7DB',
        borderRadius: 8,
        padding: 16,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        marginTop: 16,
        overflowWrap: 'break-word',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'pre',
        maxWidth: 215,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.09,
        color: '#0C0C0D',
    },
    subTitle: {
        marginTop: 4,
        overflowWrap: 'break-word',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'break-spaces',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        color: '#0C0C0D',
    },
    img: {
        objectFit: 'cover',
        alignSelf: 'flex-start',
        display: 'flex',
        width: 64,
        height: 64,
        borderRadius: '50%'
    },
    texts: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 0,
        marginBottom: 'auto'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonText: {
        fontSize: 11,
        lineHeight: '16px',
        fontWeight: '400',
        letterSpacing: 0.4,
    },
    buttonContainer: {
        paddingRight: 8,
        paddingLeft: 8,
        marginRight: 0,
        marginLeft: 'auto',
        width: 'fit-content',
        height: 26,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.08),
        },
    },
})

const _SuggestedGroup = (props) => {
    const {classes, option, cloudfront, dispatch} = props
    const [t] = useTranslation('feedWidgets')
    const titleId = `event-title-id${option.collectionUid}`
    const [titleEl, setTitleEl] = useState()

    const onHoverTitle = ()=> {
        setTitleEl(document.getElementById(titleId))
    }
    const colTitle = option.title || t("Untitled")

    const backgroundUrl = parseImagUrl(cloudfront, option.backgroundUrl, 200, groupPlaceholder);
    const users = useMemo(() => {
        const users = option?.collectionGroup?.groupUsers ?? []
        return users.map((u) => {
            const profileImageUrl = parseImagUrl(cloudfront, u.profileImageUrl, 50, '');
            return {...u, profileImageUrl}
        })
    }, [option, cloudfront])

    const openCollectionView = () => {
        goToCollection('/' + option.title, dispatch, option.collectionUid, option.collectionGroup.groupUid)();
    }

    const Title = <div data-intrcm-id="GroupItem_64ob7rg8" id={titleId} onMouseEnter={onHoverTitle} className={classes.title}>{colTitle}</div>

    const TitleWrap = !isTruncated(titleEl) ?
        Title :
        <Tooltip classes={{tooltip: classes.tooltip}} title={colTitle} placement="top">
        {Title}  
      </Tooltip>

    const Img = <img alt='vf' className={classes.img} src={backgroundUrl} />
    const Texts = <div data-intrcm-id="GroupItem_5b1s35qm" className={classes.texts}>
        {TitleWrap}
        <div data-intrcm-id="GroupItem_pvzc1nv5" className={classes.subTitle}>{option.description || t('No description')}</div>
    </div>

    const ViewGroup = <GeneralButton onClick={openCollectionView} containerStyle={classes.buttonContainer} textStyle={classes.buttonText} title={t("View group")} />

    const Footer = <div data-intrcm-id="GroupItem_oot1u0d2" className={classes.footer}>
        <Members users={users} />
        {ViewGroup}
    </div>

    return <div data-intrcm-id="GroupItem_tvrati8c" className={classes.container}>
        {Img}    
        {Texts}
        {Footer}
    </div>
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})
export default compose(
    withStyles(_styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(_SuggestedGroup);
