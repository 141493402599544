import { withStyles, Avatar } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFaces, Discussions, DirectMessages, GroupMessages } from '../../../../../views/chat/chatUtils';
import parseImgeUrl from '../../../../Common/parseImagUrl';
import { managerTableKeepGlobalData } from '@tuqqi/web';
import groupPlaceholder from "../../../../../../assets/img/group_placeholder.png";
import { parseImageUrl } from '@tuqqi/common';

const styles = theme => ({
    avatarWrapper: {
        position: 'relative',
        width: 28,
        height: 28,
        top: -20,
        right: -12,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%' }
    },
    firstInGroup: {
        position: 'relative',
        width: 28,
        height: 28,
        top: 18,
        right: 0,
        zIndex: 2,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%', }
    },
    avatarGroup: {
        width: 28,
        height: 28,
    },
    firstAvatar: {
        width: 28,
        height: 28,
        border: 'solid 2px white'
    },
    signleWrap: {
        marginLeft: 0, 
        marginRight: 0, 
        width: 40, 
        display: 'flex', 
        alignItems: 'center', 
        height: 56
    },
    signleAvatar: {
        width: 36,
        height: 36,
        '& .MuiAvatar-img': { borderRadius: '50%' },
        '& .MuiAvatar-root': { width: '100%', height: '100%', }
    },
    groupAvatar: {
        width: 36,
        height: 36,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    manyWrap: {
        marginLeft: 0, 
        width: 40
    }
});

const defaultBackground = groupPlaceholder;
const parseLink = (link, cloudfront) => {
    if(!link || !cloudfront) return defaultBackground
    return parseImageUrl(cloudfront)(link) || defaultBackground
}

class OptionLeft extends Component {

    renderSingle = (uid) => {
        const {classes, users, cloudfront} = this.props
        const user = users.find(u => u.auth0_id === uid)
        const link = !user ? '' : parseImgeUrl(cloudfront, user.profileImageUrl)
        return <div data-intrcm-id="OptionLeft_vsh7ebn3" className={classes.signleWrap}>
            {!!link ?
                <Avatar className={classes.signleAvatar} alt={uid} src={link} /> :
                <div data-intrcm-id="OptionLeft_lzvgd2ew" className={classes.signleAvatar} />
            }
        </div> 
    }
    renderSingleGroup = () => {
        const {classes, cloudfront, chat} = this.props
        const col = !chat?.chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chat?.chatUId)
        let url = defaultBackground

        if(col?.backgroundUrl && cloudfront) {
            url = parseLink(col?.backgroundUrl, cloudfront)
        }
        return <div data-intrcm-id="OptionLeft_vsh7ebn3" className={classes.signleWrap}>
            {!!url ?
                <img alt={`group-image-${url}`} src={url} className={classes.groupAvatar} /> :
                <div data-intrcm-id="OptionLeft_lzvgd2ew" className={classes.signleAvatar} />
            }
        </div> 
    }
    renderTwo = (faces) => {
        const {classes} = this.props

        return <div data-intrcm-id="OptionLeft_t6soinss" className={classes.manyWrap} >
            {faces.slice(0, 2).map((participant, i) => {
                const isFirst = i === 0
                return <div data-intrcm-id="OptionLeft_5x957q8j" className={isFirst ? classes.firstInGroup : classes.avatarWrapper} key={participant.id}>
                    <Avatar className={isFirst ? classes.firstAvatar : classes.avatarGroup} alt={participant.tag} src={participant.imageUrl} />
                </div>
            })}
        </div>
    }

    render() {
        const { chatType, document, cloudfront, users, userProfile } = this.props;

        switch(chatType){
            case Discussions:
                const faces = getFaces(document, users, cloudfront);
                const isSingleParticipant = faces.length === 1

                if(!!isSingleParticipant) return this.renderSingle(faces[0].uid)
                return this.renderTwo(faces)
            case DirectMessages: 
                if(!!userProfile){
                    return this.renderSingle(userProfile.auth0_id)
                }
                return null
            case GroupMessages: 
                if(!!userProfile){
                    return this.renderSingleGroup()
                }
                return null
            default: return null;
        }
    }
}

const mapStateToProps = (state) => ({
    ...state.wrapper,
    cloudfront: state.data.globalData.cloudfront.data,
    users: state.globalData.users,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, mapDispatchToProps)
)(OptionLeft);