import { Grid, withStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import WidgetContainer from './WidgetContainer.js';
import parseImageUrl from '../../../../components/Common/parseImagUrl';
import { FULLVIEW_OPEN_LOAD } from '../../../../../actionTypes/actionTypes.js';
import { getSearchQueryItemsByDataType } from '../../groupUtils.js';
import AddItem from './AddItem.js';
import ScrollListWithArrow from '../../../../components/Common/ScrollListWithArrow.js';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
    container: {
        "borderRadius": "4px",
        "backgroundColor": "#f3f6fb",
        marginTop: 26
    },
    mediaItem: {
        "width": "70px",
        "height": "70px",
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray}`,
        "marginRight": "16px",
        flex: '0 0 auto'
    },
    mediaContainer: {
        // "overflowX": "hidden",
        "padding": "24px 16px"
        // "padding": "24px 0 0 16px"
    },
    scrollableMediaContainer:{
        padding:'24px 16px 38px 16px',
        "position": "relative"
    },
    addFileContainer: {
        "padding": "24px 16px"
    },
    addFileIcon: {
        padding: 26
    },
    tooltip: {
        "padding": "8px",
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        "textAlign": "center",
        "color": "#ffffff",
        "borderRadius": "4px",
        "backgroundColor": "#5b5e6c"
    },
    rightArrowIcon: {
        "bottom": "9px",
        "right": "8px",
        "position": "absolute"
    },
    leftArrowIcon: {
        "bottom": "9px",
        "left": "8px",
        "position": "absolute"
    }
});

const MediaItem = withStyles(styles)((props) => {
    const { classes, item, cloudfront, onClick } = props
    const { title, link } = item.Value.Source

    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={title}
            placement="bottom">
            <Grid
                onClick={onClick}
                title={title}
                style={{
                    backgroundImage: `url(${parseImageUrl(cloudfront, link, 140)})`
                }}
                item
                className={classes.mediaItem} />
        </Tooltip>)
})

const LeftArrowIcon = (props) => {
    return (
        <svg onClick={props.onClick} className={props.className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="22" viewBox="0 0 24 22">
            <defs>
                <path id="LeftArrowIcon__a" d="M6.71 9.557L2.83 6l3.88-3.557c.39-.357.39-.935 0-1.292-.39-.358-1.02-.358-1.41 0L.71 5.358c-.39.358-.39.935 0 1.293l4.59 4.207c.39.358 1.02.358 1.41 0 .38-.357.39-.944 0-1.301z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(8 5)">
                <mask id="LeftArrowIcon__b" fill="#fff">
                    <use href="#LeftArrowIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#LeftArrowIcon__b)">
                    <path d="M0 0H24V22H0z" transform="translate(-8 -5)" />
                </g>
            </g>
        </svg>
    )
}

const RightArrowIcon = (props) => {
    return (
        <svg onClick={props.onClick} className={props.className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="22" viewBox="0 0 24 22">
            <defs>
                <path id="RightArrowIcon__a" d="M1 1.151c-.39.358-.39.935 0 1.293L4.88 6 1 9.557c-.39.358-.39.935 0 1.293.39.357 1.02.357 1.41 0L7 6.642c.39-.357.39-.935 0-1.292L2.41 1.142C2.03.794 1.39.794 1 1.152z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(8 5)">
                <mask id="RightArrowIcon__b" fill="#fff">
                    <use href="#RightArrowIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#RightArrowIcon__b)">
                    <path d="M0 0H24V22H0z" transform="translate(-8 -5)" />
                </g>
            </g>
        </svg>
    )
}

class MediaWidget extends React.Component {
    state = {
        isLoading: true,
        mediaData: []
    }


    componentDidMount() {
        const { queryService, search } = this.props
        getSearchQueryItemsByDataType(search, queryService, 'media').then(result => {
            this.setState({ mediaData: result.data, isLoading: false })
        })
    }

    canAddMedia() {
        const { userCollections, groupInfo } = this.props;

        if (!groupInfo.collectionUid) {
            return false
        }
        const isUserMemberInCollection = userCollections.some(collection => collection.collectionUid === groupInfo.collectionUid)
        const isFileAllowedInGroup = groupInfo.allInputsAllowed || groupInfo.settings.allowedInputs.includes('media')

        return isUserMemberInCollection && isFileAllowedInGroup
    }

    openItem = (docId) => () => {
        const { dispatch, collaborationService } = this.props;

        collaborationService.logClick(docId);

        dispatch({ type: FULLVIEW_OPEN_LOAD, itemId: docId })
    }

    render() {
        const { classes, cloudfront, t } = this.props;

        const { mediaData, isLoading } = this.state;

        if (!isLoading && !mediaData.length && !this.canAddMedia()) {
            return null
        }

        return (
            <WidgetContainer title={t('Media')} isLoading={isLoading} dataType='media'>
                <Grid className={(mediaData.length > 3 ? classes.scrollableMediaContainer : classes.mediaContainer)} container item direction='row' wrap='nowrap'>
                    {
                        !mediaData.length ? <AddItem dataType='media' /> :
                            (
                                <ScrollListWithArrow
                                    leftArrow={<LeftArrowIcon className={classes.leftArrowIcon}/>}
                                    rightArrow={<RightArrowIcon className={classes.rightArrowIcon}/>}>
                                    {
                                        mediaData.map(item => (
                                            <MediaItem cloudfront={cloudfront} item={item} key={item.Key} onClick={this.openItem(item.Key)} />
                                        ))
                                    }

                                </ScrollListWithArrow>
                            )
                    }
                </Grid>

            </WidgetContainer>)
    }

}

const mapStateToProps = (state, ownProps) => ({
    cloudfront: state.globalData.cloudfront,
    collaborationService: state.services.collaborationService,
    groupInfo: state.results.channel,
    userCollections: state.userData.collections,
    queryService: state.services.queryService,
    search: state.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
withTranslation('groupWidgetTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MediaWidget);