import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles, TextField } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Apply some reset
const styles = theme => ({
    header: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: '#000000'
    },
    editor: {
        margin: '0 40 40 40',
        color: theme.typography.body1.color
    },
    textField: {
        margin: '16px 0'
    }
});

class DefaultFullView extends Component {
    constructor(props, context) {
        super(props, context);
    };
    state = {
        data: {},
        isHtml: false
    }
    componentDidMount() {
        const { docText, previewDescription, jsonData } = this.props.item || {};

        let data = '';
        let isHtml = false


        if (jsonData && jsonData.length) {
            if (typeof jsonData == 'object') {
                data = jsonData
            }
            else if (typeof jsonData == 'string') {
                try {
                    data = JSON.parse(jsonData)
                } catch (errorParse) {
                    try {
                        const html = jsonData.replace(new RegExp('&nbsp;', 'g'), ' ');
                        if (html.length) {
                            isHtml = true
                            data = html
                        }
                    } catch (errorReplase) { }
                }
            }
        } else {
            data = {}
            if (docText) {
                data.Description = docText;
            }

            if (previewDescription) {
                data.Description = previewDescription;
            }
        }

        // data = //data || { "": (docText || previewDescription || '') }


        this.setState({ data, isHtml })
    }
    render() {
        const { classes, item, isLoaded } = this.props;
        const { title } = item;
        const { data, isHtml } = this.state
        if (!isLoaded) {
            return <CircularProgress className={classes.progress} />
        }

        return (<div data-intrcm-id="defaultFullView_5wbbi6lz" className={classes.preview}>
            <Typography dir='auto' variant="title" className={classes.header} gutterBottom>{title}</Typography>
            {
                isHtml ?
                    <div data-intrcm-id="defaultFullView_wdnfvrdz" dir='auto' className={classes.editor}>
                        <div data-intrcm-id="defaultFullView_0360e2r9" className={"fr-view"} dangerouslySetInnerHTML={{ __html: data }} ></div>
                    </div>
                    : Object.keys(data).map((key, idx) => (
                        // <TextField label={key} value={data[key]} inputProps={{ disabled: true }} className={classes.textField} fullWidth />
                        <Typography>
                            <b>{key}</b>: {data[key].toString()}
                        </Typography>

                    ))

            }
        </div>);
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    searchQuery: state.search,
    ...state.fullView,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(DefaultFullView);