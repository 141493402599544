import React from 'react'
import { withStyles, Grid, Typography, TextField, Button } from '@material-ui/core';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import { connect } from 'react-redux';
import NewOrganizationToolbar from './NewOrganizationToolbar';
import Spinner from '../../components/Common/spinner';
import { loadOrganizationData } from '../../../utils/loadOrganizationData';
import { persistor } from '../../..';
const sleep = ms => new Promise(r => setTimeout(r, ms));


const styles = theme => ({
    root: {
        backgroundColor: '#F4F6F8',
        height: '100vh',
        overflow: 'hidden'
    },
    grid: {
        height: '100%'
    },
    spinner: {
        padding: 8
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: '#FFFFFF',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(https://app.tuqqi.com/system/newOrgPageImage.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        fontFamily: 'Rubik',
        fontSize: 49,
        color: '#ffffff',
        letterSpacing: 0.41,
    },
    title: {
        fontFamily: 'Rubik',
        fontSize: 30,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.25,
        color: '#21212d',
        marginBottom: 24
    },
    subTitle: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#21212d',

    },
    name: {
        marginTop: 8 * 3,
        color: 'white'
    },
    bio: {
        color: 'white'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 100,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        marginTop: 26,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8 * 2,
            paddingRight: 8 * 2
        }
    },
    textField: {
        marginTop: 24
    },
    nextButton: {
        margin: '16px 0px',
        backgroundColor: theme.customColors.primary.main,
        padding: 8,
        color: 'white',
        fontFamily: 'Rubik',
        fontSize: 13,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.23,
        letterSpacing: 1.16,
        textAlign: 'center',
        marginTop: 60,
        borderRadius: 4
    },
    '@global': {
        html: {
            background: "#ffffff",//theme.palette.background.default,
            fontFamily: theme.typography.fontFamily,

            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
        },
        body: {
            margin: 0,
        },
    },
})

// const templates = [
//     { name: "", title: "Blank" },
//     { name: "nonProfit", title: "Non-profit" },
//     { name: "startup", title: "Startup" },
//     { name: "marketingAgency", title: "Marketing agency" },
//     { name: "corporateTeam", title: "Corporate team" },
//     { name: "municipality", title: "Municipality" },
//     { name: "consultingGroup", title: "Consulting group" },
//     { name: "familyOffice", title: "Family office" },
//     { name: "lawFirm", title: "Law firm" },
//     { name: "itGroup", title: "IT group" },
// ]

class NewOrganizationPage extends React.Component {
    state = {
        templateName: "",
        orgTitle: "",
        saving: false,
        templates: [],
        setupStage: 'We are setting up your account; it might take up to 30 seconds'
    }

    componentDidMount() {
        this.props.signupService.getOrganizationTitle().then(res => this.setState({ orgTitle: res }))
        let templateUID = this.props.routerLocation.query.template
        this.setState({ templateName: templateUID })
        // this.props.adminService.getAllTemplates().then((res) => {

        //     this.setState({ templates: res })
        //     // If there is parameter in url, set template accordingly
            

        // })

    }

    runnerSuccess = async () => {
        const { dispatch, idToken } = this.props;

        try {
            await loadOrganizationData(idToken, dispatch)
            await sleep(1000)
            await persistor.persist()
            await sleep(1000)
            await window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    onClickNext = async () => {
        const { signupService } = this.props;



        window.hj('trigger', 'newUser');

        this.setState({ saving: true })

        signupService.createOrganizationStep1().then(res => {
            this.setState({ setupStage: '20% finished. Preparing data structure' })
            signupService.createOrganizationStep2().then(two => {
                this.setState({ setupStage: '40% finished. Preparing organization settings' })
                signupService.createOrganizationStep3().then(three => {
                    this.setState({ setupStage: '60% finished. Preparing scoring infrastructure' })
                    signupService.createOrganizationStep4().then(four => {
                        this.setState({ setupStage: '80% finished. Applying the template' })
                        signupService.createOrganizationStep5(this.state.templateName).then(five => {
                            this.setState({ setupStage: '99% finished. Final touches' })
                            this.runnerSuccess()
                        })
                    })
                })
            }).catch(x => x)
        });
    }

    onChangeTemplateName = (event) => {
        const value = event.target.value;

        this.setState({ templateName: value })
    }

    leftSide = () => {
        const { classes } = this.props;

        return <Grid className={classes.quoteContainer} item lg={5} >
            <div data-intrcm-id="NewOrganizationPage_k11lixeu" className={classes.quote}>
                <div data-intrcm-id="NewOrganizationPage_nkosabmp" className={classes.quoteInner}>
                    <Typography className={classes.quoteText} >
                        Your wiki, people, projects, social, customers and chat all synced together
                    </Typography>
                </div>
            </div>
        </Grid>
    }

    organizationForm = () => {
        const { classes } = this.props;

        return <div data-intrcm-id="NewOrganizationPage_hnhwl96a" className={classes.form} >
            <TextField
                disabled
                className={classes.textField}
                fullWidth
                label="Organization name"
                name="orgName"
                type="text"
                value={this.state.orgTitle}
                variant="outlined"
            />

            {/* <TextField
                className={classes.textField}
                fullWidth
                label="Organization template"
                name="orgTemplate"
                select
                value={this.state.templateName}
                variant="outlined"
                onChange={this.onChangeTemplateName}
            >
                {this.state.templates.map(template => <MenuItem key={template.name} value={template.templateUID}>
                    {template.name}
                </MenuItem>)
                }
            </TextField> */}

            <Button
                className={classes.nextButton}
                type="submit"
                variant="contained"
                onClick={this.onClickNext}
                disabled={this.state.saving}
            >
                Next
            </Button>
            {this.state.saving ? <div data-intrcm-id="NewOrganizationPage_1f195tmt">
                <Spinner classes={classes.spinner} />
                <Typography>
                    {this.state.setupStage}
                </Typography>
            </div> : <div data-intrcm-id="NewOrganizationPage_05ihya8t" />}

        </div>
    }

    render() {
        const { classes } = this.props;

        return <div data-intrcm-id="NewOrganizationPage_h0s6t7n1" className={classes.root}>
            <NewOrganizationToolbar />

            <Grid className={classes.grid} container >
                {this.leftSide()}

                <Grid className={classes.content} item lg={7} xs={12} >
                    <div data-intrcm-id="NewOrganizationPage_6gwwbny3" className={classes.content}>
                        <div data-intrcm-id="NewOrganizationPage_ahff4cj6" className={classes.contentBody}>
                            <div data-intrcm-id="NewOrganizationPage_xrj8wb9x" style={{ width: 500 }}>
                                <Typography className={classes.title} >Let’s set you up together</Typography>
                                <Typography className={classes.subTitle} >If your organization already has Tuqqi set up,</Typography>
                                <Typography className={classes.subTitle} >ask an administrator to send you an invite.</Typography>
                                {this.organizationForm()}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    routerLocation: ownProps.location,
    authService: state.authModule.authService,
    idToken: state.userData.idToken,
    ...state.services
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default compose(withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, mapDispatchToProps),
    withErrorCatcher("Failed loading user, Please Contact Tuqqi Support"))(NewOrganizationPage)