import { withStyles } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import KanbanCard from "./Card/KanbanCard";

export const KANBAN_CARD_PADDING_LEFT = 10
const KANBAN_CARD_PADDING_TOP = 8
const KANBAN_CARD_PADDING_BOTTOM = 8

export const KANBAN_CARD_PADDING_VERTICAL = KANBAN_CARD_PADDING_TOP + KANBAN_CARD_PADDING_BOTTOM

const styles = theme => ({
  firstCardContainer: {
    // paddingTop: '0px !important',
  },
  lastCardContainer: {
    // paddingBottom: '0px !important',
  },
  cardContainer: {
    // The padding-left isimportant for shadow of the card
    padding: `${KANBAN_CARD_PADDING_TOP}px 0px ${KANBAN_CARD_PADDING_BOTTOM}px ${KANBAN_CARD_PADDING_LEFT}px`,
    boxSizing: 'border-box'
  }
});

const CardWrapper = React.memo(function CardWrapper(props) {

  const { cards, index, style, classes, provided, snapshot } = props;

  const card = cards[index]
  if (!cards[index]) {
    return null;
  }


  return (
    <div data-intrcm-id="CardWrapper_h9t9bz6r"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={{
        ...provided.draggableProps.style,
        ...style
      }}
      className={classnames(classes.cardContainer, {
        [classes.firstCardContainer]: index === 0,
        [classes.lastCardContainer]: index === cards.length
      })}
    >
      <KanbanCard card={card} isDraggingOver={snapshot.isDragging} />
    </div>

  );

});


export default withStyles(styles)(CardWrapper);