import { ACTIVATE_TIMER, DISABLED_TIMER, SET_CURRENT_TIME, SET_TIME_TRACKING_TASK,SET_ACTIVE_TASK_ID } from '../actionTypes/actionTypes';

const initialState = {
    timerTrackingTask: null,
    currentTime: null,
    isTimerOn: false,
    wasStopped: false,
    id: null,
};

const timeTracker = (state = initialState, action) => {
    switch (action.type) {
        case  SET_TIME_TRACKING_TASK: 
            return {
                ...state,
                timerTrackingTask: action.payload,
            }
            case  SET_CURRENT_TIME: 
            return {
                ...state,
                currentTime: action.payload,
            }
        case  ACTIVATE_TIMER: 
            return {
                ...state, 
                isTimerOn: true,
            }
        case  DISABLED_TIMER: 
            return {
                ...state, 
                isTimerOn: false,
            }
        case SET_ACTIVE_TASK_ID:
            return {
                ...state,
                id: action.payload
            }
        default:
            return state
    }
}

export default timeTracker;