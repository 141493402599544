import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";
import QuestionMark from './QuestionMark';
import ImgFab from './ImgFab';
const styles = theme => ({
    status:{
            "fontFamily": "Roboto",
            "fontSize": "16px",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "fontStretch": "normal",
            "lineHeight": "normal",
            "letterSpacing": "normal",
            "color": "#ffffff"
      }
});

class QuestionStatus extends PureComponent {



    render() {



        const { classes, status } = this.props;
        return <Grid item className={`${classes.status} question-status`}>{status}</Grid>
    }
}

export default compose(withStyles(styles))(QuestionStatus);
