import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';


const styles = theme => ({
    container: {
        margin: '0px 16px',
        borderRadius: '2px',
        backgroundColor: '#f7f7f7',
        overflow: 'hidden'
    }
    
});


class Container extends PureComponent {


    render() {
        const { classes, pointer, children, onClick } = this.props;

        return (
            <Grid wrap='nowrap' container className={classes.container} alignItems='center' style={{cursor: (onClick? 'pointer': 'default')}} onClick={onClick}>
                {children}
            </Grid>)

    }
}

export default withStyles(styles)(Container);
