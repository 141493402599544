import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";

const styles = theme => ({
    oneRow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    container:{
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    textContainer: {
        backgroundColor: '#f7f7f7',
        padding: '10px 16px',
    },
    image: {
        width:'100%',
        maxHeight: 240,
        objectFit: 'cover',
    },
    link: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "16px",
        "letterSpacing": "normal",
        "color": "#090909"
    },
    title: {
        "fontFamily": "Roboto",
        margin: '4px 0px 1px 0px',
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909"
    }

});

class BookmarkTemplateFull extends PureComponent {

    render() {

        const { classes, result, link, openLink } = this.props;
        const { previewDescription, title, previewImgUrl } = result;

        return (
            <div data-intrcm-id="BookmarkTemplateFull_5tuzi3gz" onClick={openLink} className={classes.container}>
                <img className={classes.image} src={previewImgUrl} alt={result.title}  />
                <div data-intrcm-id="BookmarkTemplateFull_3rc0ad1q" container wrap='nowrap' direction='column' className={classes.textContainer}>
                    <Grid item className={classnames(classes.link, classes.oneRow)} dir="auto" >
                        {link}
                    </Grid>
                    <Grid item className={classnames(classes.title, classes.oneRow)} dir="auto" >{title}</Grid>
                    <Grid item className={classnames(classes.previewDescription, classes.oneRow)} dir="auto" >
                        {previewDescription}
                    </Grid>
                </div>
            </div>)
    }
}

export default compose(withStyles(styles))(BookmarkTemplateFull);