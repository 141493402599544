import { withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import {store} from '../../../../index'
import { ItemPreviewMoreMenu, NewUiWrapper } from '@tuqqi/web';

export const KANBAN_CARD_MENU_HEIGHT = 28

const styles = theme => ({
    container:{
        height: KANBAN_CARD_MENU_HEIGHT,
        width: KANBAN_CARD_MENU_HEIGHT,
        margin: '-16px -16px 0px 0px',
        transform: 'rotate(90deg)'
    },
})



class KanbanCardMenu extends PureComponent {


    render() {
        const { classes, card } = this.props
        const { doc, id } = card

        return (
            <div data-intrcm-id="KanbanCardMenu_a1avz7bf" id={`item_kanban_preview_more_menu_${id}`} className={`${classes.container} ${classes.menuIcon}`}>
                <NewUiWrapper callbacks={{}} store={store}>
                    <ItemPreviewMoreMenu document={{...doc, docId: id}} />
                </NewUiWrapper>
           </div>
        )
    }
}




export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading Item, Please Contact Tuqqi support"))(KanbanCardMenu);