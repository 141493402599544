import React from 'react'
import { Column, ColumnStore } from '@bryntum/gantt';
import DocRelations from './DocRelations';

export default class RelationsColumn extends Column {
    static get $name() {
        return 'Successors';
    }

    static get type() {
        return 'successor';
    }

    static get isGanttColumn() {
        return true;
    }

    static get defaults() {
        return {
            field      : 'deps',
            cellCls    : 'b-status-column-cell',
        };
    }

    renderer(xxx) {
        const { record, value } = xxx
        const {originalData: {isItemRow, id }} = record;
        
        if(!id || !isItemRow) return ''

        return <DocRelations deps={value} record={record}/>
    }
}

ColumnStore.registerColumnType(RelationsColumn);
