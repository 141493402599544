import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Typography } from '@material-ui/core';

export const statusColors = [
    {
        id: 20,
        label: 'Green',
        color: '#23c244'
    },
    {
        id: 30,
        label: 'Red',
        color: '#ff4848'
    },
    {
        id: 40,
        label: 'Orange',
        color: '#ffbf4a'
    },
]

const ColorComponent = (props) => {

    const { color, choose, isCurrent } = props

    return <Grid container wrap='nowrap' style={{ marginBottom: 8 }} alignContent='center' alignItems='center' justify='space-between' wrap='nowrap'>
        <Grid container alignContent='center' alignItems='center' justify='left' wrap='nowrap' item onClick={choose}
            style={{
                backgroundColor: color.color,
                width: 260,
                minHeight: 32,
                borderRadius: 2,
                position: 'relative', 
            }}>
            {isCurrent ? <Icon style={{
                color: '#ffffff',
                position: 'absolute',
                right: 4
            }} >check</Icon> : null}
        </Grid>
    </Grid>
}

export default function ListColorModal(props) {

    const { open, handleClose, choose, listTaskColor } = props;

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
    >
        <DialogTitle style={{ padding: '24px 16px' }}>
            <Typography dir='auto' style={{
                color: '#21212D',
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: 500
            }} >Choose status color</Typography>
        </DialogTitle>
        <DialogContent >

            <Grid container >
                <Grid container direction='column' wrap='nowrap'>
                    {statusColors.map((color, idx) => <ColorComponent key={idx} color={color} onClick={() => { }} isCurrent={color.id === listTaskColor} choose={() => { choose(color.id) }} />)}
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions >
            <Button onClick={() => { choose(0) }} aria-label="save"  >
                {'Remove color'}
            </Button>
        </DialogActions>

    </Dialog>

}

