import React, {useEffect, useRef, useState} from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Suggest from './Suggest';
import { connect } from 'react-redux';
import { checkIfChannel } from '../../../../../views/search/searchUtils';
import { push } from 'react-router-redux';
import { addRecentRequest, updateRecentRequestTimestamp } from '../../../../../../actions/searchNavigationActions';

const styles = (theme) => ({
    container: {
        marginTop: 28,
        paddingBottom: 18,
        width: theme.constants.searchInputWidth - 40,
        marginRight: 20,
        marginLeft: 20,
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.8,
        letterSpacing: 0.31,
        color: '#21212d',
    },
    suggests: {
        marginTop: 20,
    }
})

const SHOW_SUGGESTS_LENGTH = 3;

const filterSuggestions = (suggestions = []) => {
    return suggestions.map(suggest => ({timestamp: 0, query: suggest.value}))
}

function SuggestSection(props) {
    const {classes, t, recentRequests, dispatch, filterMode, withFilter, query, withKeysNavigation, withSlice, queryService, originText, searchQuery} = props
    const [suggestedRequests, setSuggestedRequests] = useState([])
    const originTextRef = useRef('')

    useEffect(() => {
        originTextRef.current = originText
    }, [originText])

    const abortControllerRef = useRef(new window.AbortController())

    useEffect(() => {
        if(!withFilter || query === '') return;

        abortControllerRef.current.abort()
        abortControllerRef.current = new window.AbortController()
        
        let valueForSuggest = query;
        const vals = query.split(" ");
        let originText = vals.slice(0, -1).join(' ')

        if (vals.length > 1) {
            valueForSuggest = vals[vals.length - 1];
            originText += ' '
        }
        dispatch({
            type: 'SEARCH_QUERY_ORIGIN_TEXT',
            originText
        })
        if(!valueForSuggest) return;

        queryService.autosuggest(valueForSuggest, abortControllerRef.current.signal).then(res => {
            setSuggestedRequests(filterSuggestions(res || []))
        }).catch(err => {
            console.log(err);
        })
    }, [query, withFilter])


    const openSuggest = (suggest) => {
        const searchText = `${originText ? originText + " " : ''}` + suggest.query
        let pathname = '/search'

        if (!checkIfChannel({...searchQuery, query: searchText})) {
          pathname += '/all'
        }
        dispatch({
            type: 'SEARCH_QUERY', query: {query: searchText, isFocused: false}
        })
        dispatch(push({ pathname, search: '?searchquery=' + encodeURIComponent(searchText) }))
        const reqs = recentRequests.map(req => req.query)

        reqs.includes(searchText) ?
            dispatch(updateRecentRequestTimestamp(searchText)) :
            dispatch(addRecentRequest(searchText))
    }

    const renderSuggests = () => {
        if(filterMode === 'filter') return
        return (
            <>
                <div data-intrcm-id="SuggestSection_op6yjsve" className={classes.title}>{t("Suggested")}</div>
                <div data-intrcm-id="SuggestSection_il68jzi7" className={classes.suggests}>
                    {(withSlice ? suggestedRequests.slice(0, SHOW_SUGGESTS_LENGTH) : suggestedRequests).map(suggest => <Suggest withKeysNavigation={withKeysNavigation} openSuggest={openSuggest} key={suggest.query} suggest={suggest} />)}
                </div>
            </>
        )
    }
    const renderRecents = () => {
        if(filterMode === 'suggestion') return
        return (
            <>
                <div data-intrcm-id="SuggestSection_wqd6046x" className={classes.title}>{t("Recent")}</div>
                <div data-intrcm-id="SuggestSection_ax8i13eh" className={classes.suggests}>
                    {(withSlice ? recentRequests.slice(0, SHOW_SUGGESTS_LENGTH) : recentRequests).map(suggest => <Suggest withKeysNavigation={withKeysNavigation} openSuggest={openSuggest} key={suggest.query} suggest={suggest} />)}
                </div>
            </>
        )
    }

    if(filterMode === 'filter' && !recentRequests.length) return null;
    if(filterMode === 'suggestion' && !suggestedRequests.length) return null;

    return (
        <div data-intrcm-id="SuggestSection_4gd5cttd" className={classes.container} >
            {renderSuggests()}
            {renderRecents()}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      query: state.search.query,
      searchQuery: state.search,
      originText: state.search.originText,
      recentRequests: state.searchNavigation.recentRequests,
      queryService: state.services.queryService,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SuggestSection)

