import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutosuggestFieldFullView from '../Common/autosuggestField/autosuggestFieldFullView';
import ImgComment from "./imgComment";

import { isTask, getTaskText } from "./taskUtils";

// Apply some reset
const styles = theme => ({
    inputContainer: {
        flex: '1 2 calc(100% - 110px)',
        display: 'flex',
        flexFlow: 'nowrap',
        alignItems: 'center',
    },
    ...theme.comment
});


class AddCommentFullView extends Component {

    state = {
        text: '',
        isLoading: false,
        attachments: []
    }

    onKeyPress = (event) => {
        if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            this.addComment();
        }
    }
    isLink(text) {
        const currPageLink = `${location.host}/search\\?searchquery=%23`
        const reg = new RegExp(`^\\s*(http(s)?://)?${currPageLink}[^\\s]*\\s*$`, 'i')

        return reg.test(text)
    }
    getLinkText(text) {
        const splited = text.split('/search?searchquery=%23') || []

        return splited[1]
    }

    onUpdateFiles = () => {
        this.setState({ isLoading: true });
    }

    addAttachment = (jsonResponse) => {
        const file = JSON.parse(jsonResponse);

        const attachment = {
            fileName: file.originalFileName,
            fileLink: file.fileId,
            fileType: file.type,
            fileSize: file.length,
            fileUid: file.fileId,
        }

        this.setState({ attachments: [ ...this.state.attachments,  attachment ] }, () => this.addComment())

    }
    addComment = () => {
        const { collaborationService, docId, title, onCommentAdd, onLinkAdd, onTaskAdd, userData, dispatch, currDoc } = this.props;
        const { firstname, lastname, profileImageUrl, auth0_id } = userData.profileData;
        let type, text;

        const comment = {
            "docId": docId,
            "docTitle": title,
            "text": text,
            "userDisplayName": firstname + ' ' + lastname,
            "userPicUrl": profileImageUrl,
            "dateTime": (new Date()).toISOString(),
            "userId": auth0_id,
            // type: type,
            text: this.state.text,
            attachments: this.state.attachments
        }

        this.setState({ isLoading: true, attachments: [] });

        if (isTask(this.state.text)) {
            comment.text = getTaskText(this.state.text)
            collaborationService.addTask(comment).then(newTask => {
                this.onAdded()
                onTaskAdd(newTask)
            })
        } else if (this.isLink(comment.text)) {

            const link = {
                docId,
                link: this.getLinkText(comment.text)
            }

            collaborationService.addLink(link).then(newDocument => {
                this.onAdded()

                const newDoc = { ...currDoc }
                const oldRelated = newDoc.relatedDocuments || []
                const newLink = newDocument.relatedDocuments.find(x => x.relatedDocumentId === link.link)
                newDoc.relatedDocuments = oldRelated.concat(newLink)
                dispatch({ type: 'FULLVIEW_CHANGE', item: newDoc })

                onLinkAdd()
            })
        } else {
            collaborationService.commentDocument(comment).then(newComment => {
                this.onAdded()
                onCommentAdd(newComment)
            })
        }
    }

    onAdded = () => {
        this.setState({ text: '', isLoading: false });
    }

    onChange = (event, { newValue, method }) => {
        this.setState({ text: newValue })
    }

    render() {
        const { classes } = this.props;
        const { auth0_id, profileImageUrl } = this.props.userData.profileData;

        let content = <AutosuggestFieldFullView
            onLoadFile={this.addAttachment}
            onUpdateFiles={this.onUpdateFiles}
            loading={this.state.isLoading}
            value={this.state.text}
            onChange={this.onChange}
            onSubmit={() => this.addComment(null)}/>

        return (
            <div data-intrcm-id="AddCommentFullView_n46ksywg" className={classes.container} style={{ flex: 1, flexDirection: 'row', marginLeft: -30, paddingBottom: 26 }} >
                <ImgComment userId={auth0_id} src={profileImageUrl} containerStyle={{ flex: 1, marginRight: 25 }} imgStyle={{ margin: '0px 0px 0px 33px' }} />
                <div data-intrcm-id="AddCommentFullView_op6jdqmc" className={classes.inputContainer}>                    
                    {content}
                </div>
            </div>)
    }
}

AddCommentFullView.propTypes = {
    docId: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services,
    currDoc: state.fullView.item,

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AddCommentFullView);