import { DialogContent, DialogContentText, withStyles, Chip } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import urlRegex from 'url-regex';
import Spinner from "../Common/spinner";
import InputPreview from './InputPreview';
import TextInput from "./textInput";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    inputContainer: {
        outline: 'none',
        userSelect: 'text',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    text: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    container: {
        // width: 145,
        height: 24,
        borderRadius: 16,
        backgroundColor: 'rgba(107, 19, 246, 0.2)',
        marginBottom: 12
      },
      icon:{
        color: 'black',
        fontSize: 16,
      }
});


class QuestionChip extends PureComponent {

    cancelQuestion = () => {
        const { dispatch, setQuestionCanceled } = this.props
        dispatch({
            type: 'INPUT_CHANGE', model: {
                dataType: 'post'
            }
        })
        setQuestionCanceled()
    }

    render() {
        const { classes, dataType, t } = this.props
        if(dataType == 'question'){
            return <Chip clickable={false}
            classes={{label:classes.text, root:classes.container, deleteIcon:classes.icon}}
            label={t("Sharing as a question")}
            onDelete={this.cancelQuestion}
            className={classes.chip}
            color="primary"
        />
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    dataType: state.oldUiInput.document.dataType

})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(QuestionChip);

