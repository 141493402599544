import { Tooltip, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getDueDate, getDueDateStatus as getDueDateStatusOld } from '../../../../../utils/taskDetailsUtils.js';
import withErrorCatcher from '../../../Common/withErrorCatcher.js';
import FooterKanbanCardItemContainer from './FooterKanbanCardItemContainer.js';
import {createCustomTheme} from "../../../../../utils/theming";
import { getDueDateStatus, getDueDateStyling } from '@tuqqi/common';

const styles = theme => ({
    tooltip: theme.newUI.tooltip.container,
    popper: theme.newUI.tooltip.popper
})


const DueDateIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="DueDateIcon__a" d="M8.258 7.896L6.292 6.729V4.087c0-.234-.187-.42-.42-.42h-.035c-.234 0-.42.186-.42.42V6.84c0 .204.105.397.285.502l2.13 1.277c.198.117.455.059.571-.14.123-.204.059-.466-.146-.583zm3.097-5.973L9.558.429c-.245-.204-.612-.175-.822.076-.204.245-.17.612.076.822l1.79 1.494c.245.204.613.175.823-.076.21-.245.175-.613-.07-.823zm-9.963.898l1.79-1.494c.251-.21.286-.577.076-.822-.204-.25-.571-.28-.816-.076L.645 1.923c-.245.21-.28.577-.07.822.204.25.572.28.817.076zM6 1.333c-2.9 0-5.25 2.351-5.25 5.25 0 2.9 2.35 5.25 5.25 5.25s5.25-2.35 5.25-5.25c0-2.899-2.35-5.25-5.25-5.25zm0 9.334c-2.252 0-4.083-1.832-4.083-4.084C1.917 4.332 3.748 2.5 6 2.5c2.252 0 4.083 1.832 4.083 4.083 0 2.252-1.831 4.084-4.083 4.084z" />
                <path id="DueDateIcon__d" d="M7.042 21L0 21 0 0.502 7.042 0.502 14.085 0.502 14.085 21 7.042 21z" />
                <filter id="DueDateIcon__c" width="206.7%" height="169.6%" x="-53.3%" y="-34.8%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(-810 -974) translate(806 971)">
                    <rect width="22" height="20" fill="#F3F6FB" rx="4" />
                    <g transform="translate(5 4)">
                        <mask id="DueDateIcon__b" fill="#fff">
                            <use href="#DueDateIcon__a" />
                        </mask>
                        <g fill={props.color} mask="url(#DueDateIcon__b)">
                            <path d="M0 0H14V14H0z" transform="translate(-1 -1)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}

class DueDate extends PureComponent {



    getDueDateColor = () => {
        const { dueDateUTC, taskDueDateDone } = this.props.doc
        const colors = createCustomTheme(this.props.userData.profileData.org.brandingColor);
        let color;
        let tooltipPrefixText = ''

        switch (getDueDateStatusOld(dueDateUTC, taskDueDateDone)) {
            case 'done':
                {
                    color = '#23c244'
                    tooltipPrefixText = 'Done'
                    break
                }
            case 'dueDatePassed':
                {
                    color = '#ff4848'
                    tooltipPrefixText = 'Pass'
                    break
                }
            case 'dueDateSoon':
                {
                    color = colors.secondary.main
                    break
                }
            default:
                {
                    color = '#979aaa'
                    break
                }
        }

        return { color, tooltipPrefixText }
    }

    render() {
        const { classes, doc, t } = this.props

        const strDueDate = getDueDate(doc, 'MMM DD')
        if (!strDueDate) {
            return null
        }

        const { color, tooltipPrefixText } = this.getDueDateColor(doc.dueDateUTC, doc.taskDueDateDone)
        const styling = getDueDateStyling(doc.dueDateUTC, doc.taskDueDateDone)
        const dueDateStatus = getDueDateStatus(doc.dueDateUTC, doc.taskDueDateDone)
        return (
            <Tooltip
                placement='bottom'
                classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                title={`${t(tooltipPrefixText)} ${strDueDate}`}>
                <FooterKanbanCardItemContainer>
                    <DueDateIcon color={dueDateStatus === "regular"  ? '#0C0C0D' : styling.backgroundColor} />
                </FooterKanbanCardItemContainer>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => ({
    state,
    userData: state.userData,
})

export default compose(withStyles(styles, { withTheme: true }),
    connect(
        mapStateToProps,
    ),
    withTranslation('kanbanTranslate'),
    withErrorCatcher())(DueDate);