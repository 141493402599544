import { Typography, withStyles, Avatar, Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher';
import { connect } from 'react-redux';
import parseImageUrl from '../Common/parseImagUrl';
import { useTranslation } from 'react-i18next';
import { allCollectionMembers } from '../../views/groups/groupUtils';
import ClosedGroupIcon from '../Common/Icons/GroupIcons/ClosedGroupIcon';
import OpenGroupIcon from '../Common/Icons/GroupIcons/OpenGroupIcon';
import DefaultPermissionIcon from '../Common/Icons/GroupIcons/DefaultPermissionIcon';
import ClassifiedPermissionIcon from '../Common/Icons/GroupIcons/ClassifiedPermissionIcon';
import SharedPermissionIcon from '../Common/Icons/GroupIcons/SharedPermissionIcon';
import goTo from '../../actions/goTo';
import UsersDialog from '../Common/UsersDialog';
import groupPlaceholder from "../../../assets/img/group_placeholder.png";
import SettingsIcon from '../Common/Icons/UserIcons/SettingsIcon';
import EditCollection from '../Widgets/RightWidgets/Search/editCollection';
import LeaveGroup from '../../views/groups/GroupLeftDrawer/LeaveGroup';
import { showConfirm } from '../../../actions/appActions';
import { createCustomTheme } from '../../../utils/theming';
import { hexToRGB } from '../../../utils/hexToRGB';
import JoinGroupIcon from '../Common/Icons/GroupIcons/JoinGroupIcon'
import { NewUiWrapper, GroupInfoTeams, ItemPreviewMoreMenuNew } from '@tuqqi/web';
import { store } from '../../..';
import { JoinIcon } from './Icons/JoinIcon';

const styles = theme => {
    const primaryColor = theme.customColors.primary.b300
    const lighterColor = hexToRGB(primaryColor, 0.1)
    const primaryColor09Opacity = hexToRGB(primaryColor, 0.999)

    return ({

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '86%',
        marginLeft: 24,
        marginTop: 12,
        marginRight: 'auto',
    },
    headerSmall: {
        width: 'auto!important',
        marginLeft: 'auto!important',
        marginRight: 'auto!important',
    },
    titles: {
        marginLeft: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    containerSmall: {
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        width: 32,
        height: 32,
        borderRadius: 160,
        marginBottom: 16,
        objectFit: 'cover'
    },
    imgSmall: {
        width: 32,
        height: 32,
        borderRadius: 8,
        objectFit: 'cover'
    },
    title: {
        maxWidth: 140,
        textOverflow: "ellipsis",
        overflow: "hidden",
        textWrap: "nowrap",
        fontFamily: 'Rubik',
        fontSize: 12,
        textAlign: 'center',
        paddingRight: 5,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '16px',
        letterSpacing: 'normal',
        color: "#18181B",
        display: 'block'
    },
    subtitle: {
        maxWidth: 140,
        textOverflow: "ellipsis",
        overflow: "hidden",
        textWrap: "nowrap",
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 10,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '12px',
        letterSpacing: '0.4px',
        letterSpacing: 0.4,
        color: '#6B6F7A',

    },
    description: {
        maxWidth: 335,
        marginTop: 2,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.4px',
        color: theme.newUI.color.commonBlack,
        // 'overflow-wrap': 'break-word',
        transition: 'opacity .1s ease-out',
    },
    collectionFaces: {
        marginTop: 12,
        marginLeft: 32,
        marginRight: 'auto',
        marginBottom: 4,
        display: 'flex'
    },
    collectionFacesWhenTeams: {
    },
    teamFaces: {
        marginTop: 0,
        marginLeft: 24,
        marginRight: 'auto',
        marginBottom: 4,
    },
    avatar: {
        cursor: 'pointer',
        border: 'solid 1px #fff',
        width: 22,
        height: 22,
        marginLeft: -9,
        zIndex: 1
    },
    moreAvatars: {
        cursor: 'pointer',
        border: 'solid 1px #fff',
        marginLeft: -9,
        zIndex: 2,
        borderRadius: '100%',
        height: 22,
        width: 22,
        background: '#daddee',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: theme.customColors.primary.main,
        fontWeight: 500,
        fontSize: 10,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        lineHeight: 1.25,
        fontStretch: 'normal',
        letterSpacing: 'normal',
    },
    menu: {
        marginRight: 0,
        marginLeft: 'auto'
    },
    join: {
        cursor: 'pointer',
        paddingRight: 8,
        paddingLeft: 10,
        paddingBottom: 0,
        height: 32,
        width: '82%',
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#6366F1F2',
        marginRight: 6,
        marginTop: 8,
        marginLeft: 6,
        borderRadius: 8
    },
    joinIcon: {
        minWidth: 22,
        width: 22,
        height: 22,
        display: 'flex',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 4,
    },
    joinText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: '#FFF',
    },
    moreAvatarsSmall: {
        marginTop: 10,
        border: 'solid 2px #fff',
        borderRadius: '100%',
        height: 22,
        width: 22,
        background: '#daddee',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: theme.customColors.primary.main,
        fontWeight: 500,
        fontSize: 10,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        lineHeight: 1.25,
        fontStretch: 'normal',
        letterSpacing: 'normal',
    },
    tooltip: {
        fontSize: 12,
        backgroundColor: '#4A4A4F',
        padding: '4px 8px',
        borderRadius: 4,
        fontfamily: 'Rubik',
        lineHeight: '16px',
    },
    settingsContainer: {
        // marginTop: 0,
    },
    settingSmallContainer: {
        // marginTop: 0,
    },
    settingsButton: {
        height: 27,
        border: `1px solid ${theme.customColors.primary.b500}`,
        borderRadius: 4,
        textTransform: 'none',
        padding: '6px 12px',
        fontFamily: 'Rubik',
        fontSize: 11,
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.4,
        color: theme.customColors.primary.b500,
        "&:hover": {
            backgroundColor:  lighterColor,
        },
    },
    settingsIcon: {
        border: `1px solid ${theme.customColors.primary.b500}`,
        padding: '8px 8px',
        borderRadius: 4,
        textAlign: 'center',
        marginRight: 8,
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'none',
        cursor: 'pointer',
         "&:hover": {
            backgroundColor:  lighterColor,
        },
        // marginBottom: 8,
    },
   
 
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -3,
        marginTop: -3.5,
        transform: 'rotate(-45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
    smallButtonIcon: {
            cursor: 'pointer',
        height: 30,
      marginTop: 14,
    //   "&:hover": {
    //       backgroundColor:  lighterColor,
    //   },
    },
    joinGroupButton: {
        cursor: 'pointer',
        height: 27,
        width: 89,
        borderRadius: 4,
        textTransform: 'none',
        padding: '6px 12px',
        fontFamily: 'Rubik',
        fontSize: 12,
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.4,
        lineHeight: '16px',
        backgroundColor: theme.customColors.primary.b500,
        color: '#FFFFFF',
        "&:hover": {
            backgroundColor:  primaryColor09Opacity,
        },
    },
    smallJoinGroupButton: {
        width: 32,
        height: 28,
        textAlign: 'center',
        marginRight: 8,
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 4,
        // marginTop: 8,
        backgroundColor: theme.customColors.primary.b500,
        "&:hover": {
            backgroundColor: primaryColor09Opacity,
        },
      },
}
)}


const defaultBackground = groupPlaceholder;
const showAvatars = 6

const __GroupInfoDrawer = (props) => {
    const [show, setShow] = useState(false)
    const [showUsersDialog, setShowUsersDialog] = useState(false)
    const { groupInfo, classes, cloudfront, isDrawerOpen, dispatch,isAdmin, profileData,additionalOptions, ignoreMenuKeys, isCanJoin, joinGroup } = props
    const { backgroundUrl, title, collectionGroup, description } = groupInfo;
    const [imgSrc, setImgSrc] = useState(defaultBackground);
    const [t] = useTranslation('groupDrawerTranslate');

    const themeOption = profileData.org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)
  
    const toggleGroupMembersDialog = () => {
        setShowUsersDialog(prev => !prev)
    }
    useEffect(() => {
        if (!groupInfo || !Object.keys(groupInfo).length) {
            setShow(false)
            return
        }
        setShow(true)
    }, [groupInfo])

    useEffect(() => {
        if (!backgroundUrl) {
            setImgSrc(defaultBackground)
            return
        }
        setImgSrc(parseImageUrl(cloudfront, backgroundUrl, 300))
    }, [backgroundUrl])

    if (!show) return null

    const adminIds = collectionGroup.adminIds || []
    const teamIds = groupInfo?.collectionTeamsDto?.groupUsers ?? []
    const members = allCollectionMembers(groupInfo)
        .map(member => ({ ...member, isAdmin: adminIds.includes(member.id) }))
        .sort((a, b) => b.isAdmin - a.isAdmin)

    const openUser = (tag) => {
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()
        goTo(userTagFormat, dispatch)()
    }

    const renderCollectionFaces = (members) => {
        if(!isDrawerOpen) return null;
        return <div data-intrcm-id="GroupInfoDrawer_6vb9r4k0" className={`${isDrawerOpen && classes.collectionFaces} ${!teamIds.length ? '' : classes.collectionFacesWhenTeams}`}>
            {isDrawerOpen && members.slice(0, showAvatars).map((tagedUser, idx) => {
                return <Avatar
                    onClick={() => openUser(tagedUser.tag)}
                    key={idx}
                    alt={tagedUser.fullName}
                    src={parseImageUrl(cloudfront, tagedUser.profileImageUrl)}
                    style={tagedUser.isAdmin ? { border: '2px #ffbf4a solid' } : {}}
                    className={classes.avatar} />
            })}
            {members.length - showAvatars > 0 && <div data-intrcm-id="GroupInfoDrawer_mjj29dx8" onClick={toggleGroupMembersDialog} className={isDrawerOpen ? classes.moreAvatars : classes.moreAvatarsSmall}>{isDrawerOpen ? `+${members.length - showAvatars}` : members.length}</div>}
        </div>
    }

    const renderTeamFaces = () => {
        if(!isDrawerOpen) return null
        if(!teamIds.length) return null
        return <div className={classes.teamFaces}>
            <NewUiWrapper store={store} callbacks={{}}>
                <GroupInfoTeams teamIds={teamIds} />
            </NewUiWrapper>
        </div>
    }

    const renderJoinGroup = () => {
        if(!isCanJoin) return null
        if(!isDrawerOpen) return null

        return <div onClick={joinGroup} className={`${classes.join}`}>
            <div className={classes.joinIcon} >
                <JoinIcon />
            </div>
            <div >
                <div className={classes.joinText} >{t("Join Group")}</div>
            </div>
        </div>

    }

    return (
        <div data-intrcm-id="GroupInfoDrawer_mw5e4equ" className={isDrawerOpen ? classes.container : classes.containerSmall}>
            <div className={`${classes.header} ${!isDrawerOpen ? classes.headerSmall : ""}`}>
                <img alt={`group-image-${backgroundUrl}`} src={imgSrc} className={classes.imgSmall} />
                {isDrawerOpen && <div className={classes.titles}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography dir='auto' className={classes.subtitle}>
                    {collectionGroup.isClosed ? t('Close group') : t('Open group')} · {t('numMembers', { num: members.length })}
                </Typography>
                </div>}
                {isDrawerOpen && <div className={classes.menu}><ItemPreviewMoreMenuNew ignoreKeys={ignoreMenuKeys} view="groupPX" isPX document={{docId: "mockDocId"}} /></div>}
            </div>
            
            {renderCollectionFaces(members)}
            {renderTeamFaces()}
            {renderJoinGroup()}
            <UsersDialog
                open={showUsersDialog}
                onClose={toggleGroupMembersDialog}
                usersIds={members.map(x => x.id)}
                title={t('GroupMembersTotal', { total: members.length })}
            />
               <div data-intrcm-id="GroupInfoDrawer_5pt7vg7m" style={{marginTop: !isAdmin && additionalOptions?.[0]?.title !== "Join group" ? 0 : 0 }} className={ isDrawerOpen ? classes.settingsContainer : classes.settingSmallContainer}> 
                {isAdmin ?  null  :  <>
                    {isDrawerOpen && !isAdmin && additionalOptions?.[0]?.title === "Join group" && additionalOptions.map(el => (
                            <Button
                            onClick={el.onClick}
                            className={classes.joinGroupButton}>  
                            {t(el.title)}
                        </Button>
                        )) }
                        {!isDrawerOpen && !isAdmin && additionalOptions?.[0]?.title === "Join group" && additionalOptions.map(el => (
                         <Tooltip
                        placement="right" 
                        title={<>
                            {el.title}
                            <div data-intrcm-id="GroupInfoDrawer_eqypi30k" className={classes.triangle}></div>
                        </>  }
                        disableHoverListener={false} 
                        classes={{tooltip: classes.tooltip}}
                        onClick={el.onClick}>
                         <div data-intrcm-id="GroupInfoDrawer_lyitwh8v" className={classes.smallJoinGroupButton}><JoinGroupIcon color={theme.primary.b500} /></div>
                    </Tooltip>
                    )) }
                         </>
                    }
                   
                </div>
                   
        </div>
    )
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    profileData: state.userData.profileData,
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export const GroupInfoDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading Group Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__GroupInfoDrawer);