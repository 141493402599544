import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Hidden } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";


const styles = theme => ({
    oneRow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },

  
    container:{ 
        height:95,
        backgroundColor: '#f7f7f7', 
        padding: '10px 12px',
        cursor:'pointer'
     },

     link: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "16px",
        "letterSpacing": "normal",
        "color": "#090909"
    },
    title: {
        "fontFamily": "Roboto",
        margin: '4px 0px 1px 0px',
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909"
    }
});

class BookmarkTemplateNoImage extends PureComponent {

    render() {

    

        const { classes, result, openLink, link } = this.props;
        const { previewDescription, title, previewImgUrl } = result;

        return <Grid container wrap='nowrap' direction='column' className={classes.container} onClick={openLink}>
        <Grid item className={classnames(classes.link, classes.oneRow)} dir="auto" >
               {link}
            </Grid>
            <Grid item className={classnames(classes.title, classes.oneRow)} dir="auto" >{title}</Grid>
            <Grid item className={classnames(classes.previewDescription, classes.oneRow)} dir="auto" >
                {previewDescription}
            </Grid>

        </Grid>

    }
}

export default compose(withStyles(styles))(BookmarkTemplateNoImage);
