import React, { PureComponent } from 'react';

export default class DefaultPermissionIcon extends PureComponent {

    render() {
        const { className } = this.props
        return (
            <svg className={className} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <rect fill="#008001" width="40" height="40" rx="20"/>
                    <path d="M13 23a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm14 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-8a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" stroke="#FFF" strokeWidth=".2" fill="#FFF" fillRule="nonzero"/>
                </g>
            </svg>
        )
    }
}
