import { FULL_RESET_STORE } from "../actionTypes/actionTypes";
import { CLOSE_GROUPS_NAVIGATION, OPEN_GROUPS_NAVIGATION, PUSH_MANAGED_GROUPS, PUSH_SUBSCRIBED_GROUPS, SET_MANAGED_GROUPS, SET_SUBSCRIBED_GROUPS, UPDATE_LATEST_GROUP_TIMESTAMP, UPDATE_MANAGED_GROUPS_BY_IDS, UPDATE_SUBSCRIBED_GROUPS_BY_IDS } from "../actionTypes/groupsNavigationTypes";
import { sortGroupsByTimestamp, sortGroupsByTitle } from "../utils/groupsNavigation/collectionToGroupNavigation";

//group: {groupUid, collectionUid, title, backgroundUrl, numNewItems, timestamp, description}
const initialState = {
    open: false,
    starred: [],
    latest: [],
    managed: [],
    subscribed: []
}

const groupsNavigation = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState
        case SET_MANAGED_GROUPS:
            return {...state, managed: sortGroupsByTitle(action.managed)}
        case PUSH_MANAGED_GROUPS:
            return {...state, managed: sortGroupsByTitle([...state.managed, ...action.managed])}
        case PUSH_SUBSCRIBED_GROUPS:
            return {...state, subscribed: sortGroupsByTitle([...state.subscribed, ...action.subscribed])}
        case OPEN_GROUPS_NAVIGATION:
            return {...state, open: true}
        case CLOSE_GROUPS_NAVIGATION:
            return {...state, open: false}
        case SET_SUBSCRIBED_GROUPS:
            return {...state, subscribed: sortGroupsByTitle(action.subscribed)}

        case UPDATE_MANAGED_GROUPS_BY_IDS: {
            const {managed} = action
            const newManaged = [...state.managed]
            newManaged.forEach(gr => {
                const m = managed.find(group => group.groupUid === gr.groupUid)
                if(!m) return
                gr.title = m.title
                gr.description = m.description
                gr.backgroudUrl = m.backgroudUrl
                gr.numNewItems = m.numNewItems
            })
            return {...state, managed: sortGroupsByTitle(newManaged)}
        }
        case UPDATE_SUBSCRIBED_GROUPS_BY_IDS: {
            const {subscribed} = action
            const newSubscribed = [...state.subscribed]
            newSubscribed.forEach(gr => {
                const m = subscribed.find(group =>  group.groupUid === gr.groupUid)
                if(!m) return
                gr.title = m.title
                gr.description = m.description
                gr.backgroudUrl = m.backgroudUrl
                gr.numNewItems = m.numNewItems
            })
            return {...state, subscribed: sortGroupsByTitle(newSubscribed)}
        }
        case UPDATE_LATEST_GROUP_TIMESTAMP: {
            const {groupUid} = action

            const newLatest = [...state.latest]
            const ind = newLatest.findIndex((gr) => gr.groupUid === groupUid)
            if(ind >= 0) {
                const prev = { ...newLatest[ind] }
                newLatest[ind] = { ...prev, timestamp: new Date().getTime() }
            } else {
                const itGroups = (state.managed.findIndex(g => g.groupUid === groupUid) >= 0 ) ? [...state.managed] : [...state.subscribed]
                const gr = itGroups.find(group => group.groupUid === groupUid)
                !!gr && newLatest.push({...gr, timestamp: new Date().getTime()})
            }
            return {...state, latest: sortGroupsByTimestamp(newLatest)}
        }
            
        default:
        return state
    }
}

export default groupsNavigation