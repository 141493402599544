
import React from 'react';
import {  withStyles } from '@material-ui/core';


const styles = theme => ({
        imageGroup: {
                marginRight: 12,
                width: "100%",
                height: 200,
                objectFit: 'cover'
            },
})

const KanbanCardImage = ({classes,image}) => {
        return  <img src={image} className={classes.imageGroup} /> 

    
}

export default withStyles(styles)(KanbanCardImage);


