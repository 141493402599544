import React from 'react'
import {withStyles, Tooltip} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { openStory } from '@tuqqi/common'
import parseImgeUrl from '../../Common/parseImagUrl'
import { formatDistanceToNowStrict } from 'date-fns'

const styles = (theme) => ({
    story: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        marginLeft: 21,
        borderRadius: 8,
        marginBottom: 14,
        '&:hover': {
            backgroundColor: '#daddee'
        }
    },
    storyClose: {
        justifyContent: 'center',
    },
    selectedStory: {
        backgroundColor: '#daddee'
    },
    avatarHolder: {
        width: 60,
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    avatar: {
        borderRadius: '50%',
        width: 56,
        height: 56,
        objectFit: 'cover'
    },
    avatarActive: {
        border: `solid 3px ${theme.customColors.primary.main}`,
        width: 62,
        height: 62,
        boxSizing: 'border-box',
    },
    avatarActiveClose: {
        border: `solid 2.5px ${theme.customColors.primary.main}`,
        width: 52,
        height: 52,
        boxSizing: 'border-box',
    },
    texts: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.5,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
    },
    subTitle: {
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    tooltipInfoHolder: {
        position: 'relative'
    },
    tooltip: {
        backgroundColor: theme.customColors.primary.b300,
        padding: '12px 10px',
        borderRadius: 6,
        cursor: 'pointer',
    },
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -16,
        marginTop: -6,
        transform: 'rotate(-45deg)',
        border: `solid 6px ${theme.customColors.primary.b300}`,
        zIndex: '9999'
    },
})

const InfoUser = ({classes, story, isDrawerOpen}) => {
    return(
        <div data-intrcm-id="StoryNavigation_mfe7h59b" className={classes.texts}>
            <div data-intrcm-id="StoryNavigation_xezz0s3b" className={classes.title}>{story.user.firstname} {story.user.lastname}</div>
            <div data-intrcm-id="StoryNavigation_otqn5nlk" className={classes.subTitle} style={{color : isDrawerOpen ? "#8d8d8d" : '#fff'}}>{formatDistanceToNowStrict(new Date(story.timestamp), {addSuffix: false})}</div>
        </div>
    )
}

const StorySmall = ({classes, story, onClickStory, cloudfront, currentStoryId, activeAvatarStyles}) => {
    return(
        <Tooltip placement="right" 
            interactive
            title={<div data-intrcm-id="StoryNavigation_gn8pyoj6" className={classes.tooltipInfoHolder} onClick={() => onClickStory(story)}>
                    <InfoUser/>
                    <div data-intrcm-id="StoryNavigation_bjvztz9v" className={classes.triangle}></div>
                  </div>} 
            classes={{tooltip: classes.tooltip}}
            >
            <div data-intrcm-id="StoryNavigation_4p335t9s" className={classes.avatarHolder} style={{margin: '0 auto'}} onClick={() => onClickStory(story)}>
                <img alt="story_avatar" src={parseImgeUrl(cloudfront, story.user.profileImageUrl)} 
                    className={`${classes.avatar} ${story.user.auth0_id === currentStoryId ? activeAvatarStyles : ''}`}   
                />      
            </div>
        </Tooltip>
    )
}

const StoryNavigation = (props) => {
    const {classes, dispatch, cloudfront, story, currentStoryId, isDrawerOpen} = props

    const onClickStory = (story) => {
        dispatch(openStory(story.user.auth0_id))
    }
    const activeAvatarStyles = isDrawerOpen ? classes.avatarActive : classes.avatarActiveClose
    const storyStyles = isDrawerOpen ? classes.story :`${classes.story} ${classes.storyClose}`




    const Story = (
        isDrawerOpen ? <div data-intrcm-id="StoryNavigation_4qkkldq6" onClick={() => onClickStory(story)} className={storyStyles}>
            <div data-intrcm-id="StoryNavigation_bf8a656v" className={classes.avatarHolder}>
                <img alt="story_avatar" src={parseImgeUrl(cloudfront, story.user.profileImageUrl)} 
                    className={`${classes.avatar} ${story.user.auth0_id === currentStoryId ? activeAvatarStyles : ''}`}   
                />      
            </div>
            <InfoUser classes={classes} story={story} isDrawerOpen={isDrawerOpen}/>
        </div> : <StorySmall classes={classes} story={story} onClickStory={onClickStory} currentStoryId={currentStoryId} cloudfront={cloudfront} activeAvatarStyles={activeAvatarStyles}/>
    )

    return Story
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoryNavigation)