import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import Spinner from "../../../../Common/spinner";

const styles = theme => ({

    container: { backgroundColor: '#ffffff' },

    disabled:{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, background: 'white', zIndex: 2, opacity:0.6 },

    progress: {
        zIndex: 3,
        position: 'absolute',
        width: '40px',
        height: '40px',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
    }
});


class DisableScreen extends React.PureComponent {
   
    render() {
        const { classes, disable } = this.props;
        if(!disable){
            return null
        }

        return (
            <Fragment>
                <Spinner classes={{ progress: classes.progress }} />
                <div data-intrcm-id="DisableScreen_616tbq8h" className={classes.disabled}/>
            </Fragment>)
    }
}


export default withStyles(styles)(DisableScreen);