import { Grid, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateSpecificItem } from '../../../../../../actions/dataActions';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js';
import ChooseLabel from './ChooseLabel.js';
import CreateLabel from './CreateLabel.js';
import DisableScreen from './DisableScreen.js';
import GoBackIcon from './GoBackIcon.js';

const styles = theme => ({

    container: { backgroundColor: '#ffffff' },
    title: {
        color: '#21212D',
        fontSize: 16
    },
    progress: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        top: 'calc(50% - 40px)',
        left: 'calc(50% - 40px)',

    }
});


class ChangeLabelModal extends React.PureComponent {
    createNewMode = "createNewMode"
    editMode = 'editMode'
    chooseMode = 'chooseMode'

    state = {
        searchText: '',
        mode: this.chooseMode,
        editedLabel: null,
        docCollectionLabels: [],
        isLoading: false
    };

    search = () => { }

    createNew = () => {
        this.setState({ mode: this.createNewMode })
    }
    edit = (label) => {
        this.setState({ mode: this.editMode, editedLabel: label })
    }
    save = () => {
        this.closeInput()
    }

    closeInput = () => {
        this.setState({ mode: this.chooseMode, editedLabel: null })
    }

    title = () => {
        let text = ''
        switch (this.state.mode) {
            case this.chooseMode:
                text = 'Labels'
                break;
            case this.createNewMode:
                text = 'Create Label'
                break;
            case this.editMode:
                text = 'Edit Label'
                break;
            default:
                break;
        }
        return text
    }

    onClose = (e) => {
        const { dispatch, docId, handleClose } = this.props;

        dispatch(updateSpecificItem(docId));
        handleClose(e)
    }

    initLabels = () => {
        this.changeLabels(this.props.docCollectionLabels)
    }

    changeLabels = (newLabels) => {
        this.setState({ docCollectionLabels: newLabels })
    }
    load = (isLoading) => {
        this.setState({ isLoading })
    }

    render() {
        const { open, handleClose, classes, docId, t, isAdmin } = this.props;

        const { docCollectionLabels, mode } = this.state
        return (
            <Dialog
                onEnter={this.initLabels}
                open={open}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
                classes={{ paper: classes.container }}
            >
                <DisableScreen disable={this.state.isLoading} />
                <DialogTitle style={{ padding: '18px 24px 0px 24px' }}>
                    <Grid container alignContent='center' justify='flex-start'>
                        {
                            mode != this.chooseMode ? <GoBackIcon goBack={this.closeInput} /> : null
                        }
                        <Typography variant='h6' className={classes.title}>{t(this.title())}</Typography>
                    </Grid>
                </DialogTitle>


                {
                    this.state.mode === this.chooseMode ?
                        <ChooseLabel isAdmin={isAdmin} docCollectionLabels={docCollectionLabels} docId={docId} createNewLabel={this.createNew} edit={this.edit} load={this.load} changeLabels={this.changeLabels} /> :
                        <CreateLabel editedLabel={this.state.editedLabel} goBack={this.closeInput} docId={docId} load={this.load} changeLabels={this.changeLabels} />
                }
            </Dialog>
        );
    }
}


const mapStateToProps = (state) => ({
    ...state.services
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}
export default compose(withStyles(styles),
withTranslation('labelMenu'),
    withErrorCatcher("Failed Loading Labels, Please Contact Tuqqi Support"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChangeLabelModal);