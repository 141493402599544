import React, { Component } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Codemirror from 'react-codemirror';
import './code.scss'
import "../../../../../../node_modules/codemirror/lib/codemirror.css";
import { withTranslation } from 'react-i18next'

require('codemirror/mode/javascript/javascript');
require('codemirror/mode/xml/xml');
require('codemirror/addon/selection/active-line');
require('codemirror/mode/clike/clike');
require('codemirror/mode/markdown/markdown');
require('codemirror/mode/sql/sql');
require('codemirror/mode/css/css');

// Apply some reset
const styles = theme => ({
    separator: {
        marginTop: 32,
        marginBottom: 16,
        display: 'block',
        border: 0,
        textAlign: 'center',
        overflow: 'visible',
        '&:before': {
            content: '"..."',
            display: ' inline-block',
            marginLeft: '.6em',
            color: 'rgba(0, 0, 0, .68)',
            position: 'relative',
            top: -30,
            fontWeight: 400,
            fontStyle: 'italic',
            fontSize: 30,
            letterSpacing: '.6em'
        }

    },
    textField: {
        fontSize: theme.typography.title.fontSize,
        fontWeight: theme.typography.title.fontWeight,
        fontStyle: theme.typography.title.fontStyle,
        marginBottom: 10,
        border:'none',
        width: '100%',
        paddingLeft: 8,
        '&:focus':{
            outline:'none'
        }
    },
    formControl: {
        fontSize: theme.typography.title.fontSize,
        fontStyle: theme.typography.title.fontStyle,
        marginBottom: 10,

        paddingLeft: 8
    },
    input: {
        textAlign: 'left',

        width: '100%',


    },
});



class CodeInput extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: 'javascript',
        };
    };
    changeMode = (event, index, value) => {
        var mode = event.target.value;
        this.props.dispatch({ type: "INPUT_CHANGE", model: { mimeType: mode } })

    }

    onChange = (value) => {

        let link = {};
        this.props.dispatch({ type: "INPUT_CHANGE", model: { docText: value, dataType: 'code', canSubmit: this.canSubmit() } })

    }

    handleChange = name => event => {
        this.props.dispatch({
            type: 'INPUT_CHANGE', model: {
                [name]: event.target.value,
                dataType: 'code',
                canSubmit: this.canSubmit()
            }
        })
    };

    canSubmit = () => {
        const { oldUiInput } = this.props;
        return oldUiInput.title !== '' && oldUiInput.docText !== '';
    }

    render() {
        const { classes, dispatch, oldUiInput, open, t } = this.props;
        var options = {
            lineNumbers: true,
            lineWrapping: true,
            readOnly: false,
            styleActiveLine: true,
            mode: this.props.oldUiInput.mimeType,
            viewportMargin: Infinity
        };
        return (
            <div data-intrcm-id="codeInput_ak0vwz60">

                <input value={oldUiInput.title}
                    dir="auto"
                    type="text"
                    classes={classes}
                    className={classes.textField}
                    placeholder={t("Title")}
                    onChange={this.handleChange('title')}
                />
              
                <FormControl className={classes.formControl}>
                    <Select
                        value={oldUiInput.mimeType}
                        onChange={this.changeMode}
                        input={<Input id="age-helper" />}

                    >
                        <MenuItem value="">
                            <em>{t('None')}</em>
                        </MenuItem>

                        <MenuItem value={"markdown"} >Markdown</MenuItem>
                        <MenuItem value={"javascript"} >JavaScript</MenuItem>
                        <MenuItem value={"text/x-sql"} >SQL</MenuItem>
                        <MenuItem value={"xml"} >XML</MenuItem>
                        <MenuItem value={"text/x-csharp"} >C#</MenuItem>
                        <MenuItem value={"text/css"} >CSS</MenuItem>


                    </Select>
                    <FormHelperText>{t('Choose snippet Language')}</FormHelperText>
                </FormControl>
                <br />
                <br />
                <Codemirror ref="editor"
                    className={'codeInput'}

                    value={oldUiInput.docText}
                    onChange={this.onChange}
                    options={options}
                    autoFocus={true} />
                <br />
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
withTranslation('codeInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CodeInput);
// export default withStyles(styles)(InputFAB);

