import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class NotificationIcon extends PureComponent {

    render() {
        const { isFocused } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="14" fill="white" fill-opacity={ isFocused ? "0.16" : "0"}/>
            <path d="M18.8327 18.0004H9.16738C8.76572 18.0004 8.39738 17.798 8.18238 17.4587C7.96738 17.1194 7.94072 16.7004 8.11172 16.337L9.00005 14.5497V12.4607C9.00005 9.71436 11.0904 7.46503 13.759 7.33936C15.1427 7.2767 16.4524 7.76303 17.4507 8.71536C18.45 9.66837 19 10.9534 19 12.3337V14.5497L19.8834 16.3274C20.059 16.7004 20.0327 17.1197 19.8177 17.459C19.6027 17.7984 19.2344 18.0004 18.8327 18.0004ZM13.7827 7.83903H13.786H13.7827ZM12.03 18.667C12.1894 19.6114 13.0107 20.3337 14 20.3337C14.9894 20.3337 15.8104 19.6114 15.97 18.667H12.03Z" fill="white"/>
            </svg>
            )
    }
}

