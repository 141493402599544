import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';


const styles = theme => ({
    seeAll: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "color": theme.newUI.color.primary,
        cursor: 'pointer'
    }
});

class SeeAll extends React.PureComponent {
    render() {
        const { classes, t, onClick} = this.props;

        return <Typography onClick={onClick}  className={classes.seeAll}>{t('See all')}</Typography>
    }

}



export default compose(withStyles(styles),
    withTranslation('groupWidgetTranslate'))(SeeAll);