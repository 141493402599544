import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {NewUiWrapper, LogsView as LogsViewWeb} from '@tuqqi/web'
import { store } from '../../../..';
import { makeStyles } from '@material-ui/styles';
import goTo from '../../../actions/goTo';
import { push } from 'react-router-redux';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions';
import { useExportLogs } from './useExportLogs';
import { goToEditProfile } from '../../../actions/goToEditProfile';

const styles = theme => ({
    wrap: {

    }
});

const useStyles = makeStyles(styles)

const UID = 'logs-main-new'

const LogsView = (props) => {
    const classes = useStyles()
    const {chatService, users, dispatch} = props
    const [exportAdminExcelTable, exportAdminCSVTable] = useExportLogs()
    
    const openSubscriptionDialog = (reason) => {
        if(!reason) return
        dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }

    const onUserClick = (userUid) => {
        if(!userUid) return
        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }
        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
    }
    const onUserMessage = (auth0_id) => {
        if(!auth0_id) return

        chatService.openNewChat(auth0_id).then(res => {
            const { chat } = res
            if (!chat || !chat.chatUId) {
              dispatch(push({ pathname: "chat" }))
              return
            }
            dispatch({
              type: 'SET_CHAT_ROOM',
              chatRoomFromUserPage: true,
              room: res
            })
            dispatch(push(`/chat?chatid=${chat.chatUId}`))
          })
    }

    const onUserEditProfile = () => {
        goToEditProfile(dispatch)
    }

    const callbacks = {
        exportAdminCSVTable,
        exportAdminExcelTable,
        onShowSubscriptionModal: openSubscriptionDialog,
        onUserClick,
        onUserEditProfile,
        onUserMessage,
    }


    return <NewUiWrapper store={store} callbacks={callbacks}>
        <LogsViewWeb />
    </NewUiWrapper>}

const mapStateToProps = (state) => ({
    users: state?.data?.globalData?.activeUsers?.data ?? [],
    chatService: state.services.chatService
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(LogsView);
