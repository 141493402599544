import React, { useEffect, useRef, useState } from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import SceneIndicator from './SceneIndicator'

const styles = (theme) => ({
    container: {
        marginTop: 10,
        marginRight: 10,
        marginLeft: 10,
        height: 30,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
})

const defaultScene = {id: -1}

const ScenesIndicator = (props) => {
    const {classes, storiesNavigation, wrapClass, dispatch} = props
    const [scenes, setScenes] = useState([])
    const {stories, currentStoryId, currentScene} = storiesNavigation
    const storiesRef = useRef([])

    useEffect(() => {
        storiesRef.current = stories
    }, [stories])

    useEffect(() => {
        const story = storiesRef.current.find(s => s.user.auth0_id === currentStoryId)
        if(!story) return
        setScenes(story.scenes)
    }, [currentStoryId])

    const {id}  = currentScene ?? defaultScene

    if(id === -1) return null
    
    return <div data-intrcm-id="ScenesIndicator_jlit5htu" className={wrapClass}>
        <div data-intrcm-id="ScenesIndicator_lx0z2v1m" className={classes.container}>
            {scenes.map((scene) => (
                <SceneIndicator key={scene.id} selected={scene.id === id} scene={scene} currentScene={currentScene} dispatch={dispatch}/>
            ))}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ScenesIndicator)