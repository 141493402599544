import { openFullview } from "@tuqqi/web"
import { push } from "react-router-redux"
import { addRecentItem } from "../../actions/searchNavigationActions"

const allowedHosts = ['app.tuqqi.com', 'newui.tuqqi.com', 'test.tuqqi.com']

export const processNavigationLink = (link, dispatch, isLaunchpad = false) => {
    try {
        const url = new URL(link)
        if(!allowedHosts.includes(url.host)) {
            window.open(link, '_blank')
            return
        }
        //check open item
        if(url.search?.includes?.('openItemId')) {
            const docId = url.searchParams.get("openItemId");
            dispatch(openFullview(docId, false))
            dispatch(addRecentItem(docId))
            if(isLaunchpad) dispatch({type: 'BLUR_LAUNCHPAD'})
            return
        }
        const pathname = url.pathname || '/'
        const search = url.search || ''
        dispatch(push({ pathname, search }))
        if(isLaunchpad) dispatch({type: 'BLUR_LAUNCHPAD'})
    } catch (error) {
        console.log(error);
        window.open(link, '_blank')    
    }
}