import { TaskStatusColor } from "@tuqqi/common"

export const getRowLaneStatus = (doc, context) => {
    const { CurrentStatus, currentStatus, TaskStatusColor: taskStatusColor, taskStatusColor: taskStatusColor1 } = doc
    const all = [...context?.usersCollections ?? [], ...context?.openCollections ?? []]
    const col = all.find(c => c.collectionGroup.groupUid === doc.groupUid)
    const { view, withGroupName } = context


    const status = CurrentStatus ?? currentStatus ?? ''
    const color = (taskStatusColor !== null && taskStatusColor !== undefined) ? taskStatusColor : (taskStatusColor1 !== null && taskStatusColor1 !== undefined) ? taskStatusColor1 : undefined

    if (!status && !col) return {
        title: 'Unassigned',
        color: TaskStatusColor.Grey
    }
    const collectionTitle = col?.title ?? ""
    let title = collectionTitle + ' · ' + (status ?? 'Unassigned')
    if (view !== 'agenda' && !withGroupName) {
        title = (status ?? 'Unassigned')
    }
    const showDefault = !!col && (!status || status === 'New')

    if (showDefault) return {
        title: col?.newColumnTitle ?? 'New',
        color: col?.groupDefaultStatusColor ?? TaskStatusColor.Grey
    }

    return {
        title: title,
        groupName: collectionTitle,
        statusLane: status,
        color: color ?? TaskStatusColor.Grey
    }
}