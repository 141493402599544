import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = (theme) => ({
    hover: {
        cursor: 'pointer',
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'transparent',
        width: theme.constants.userNavigationWidth,
        height: 64,
        zIndex: 1
    },
    hoverLast: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    hoverItem: {
        backgroundColor: '#F9F9FA'
    },
    option: {
        position: 'relative',
        width: theme.constants.userNavigationWidth,
        height: 64,
        display: 'flex',
        alignItems: 'center'
    },
    optionTitles: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 16,
        marginRight: 16,
    },
    optionTitle: {
        zIndex: 2,
        cursor: 'pointer',
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.24,
        color: '#0C0C0D',
    },
    hoverTitle: {
        color: theme.customColors.primary.main
    },
    optionSubTitle: {
        zIndex: 2,
        cursor: 'pointer',
        display: 'block',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#737373',
    },
    optionIcon: {
        cursor: 'pointer',
        zIndex: 2,
        display: 'flex',
        marginLeft: 16,
    },
})

const UserNavigationOption = (props) => {
    const {classes, o, className, onClick, isLast} = props
    const [isHover, setIshover] = useState(false)

    const _onClick = () => {
        onClick(o.type)
    }

    return (
        <div data-intrcm-id="UserNavigationOption_lzdjnfz4" onClick={_onClick} className={`${classes.option} ${className}`}>
            <div data-intrcm-id="UserNavigationOption_martur2n" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={classes.optionIcon}>{o.icon}</div>
            <div data-intrcm-id="UserNavigationOption_opj9e6s3" className={classes.optionTitles}>
                <div data-intrcm-id="UserNavigationOption_ede55m0i" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={`${classes.optionTitle} ${!!isHover ? classes.hoverTitle : ''}`}>{o.title}</div>
                <div data-intrcm-id="UserNavigationOption_w65gqleq" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={classes.optionSubTitle}>{o.subtitle}</div>
            </div>
            <div data-intrcm-id="UserNavigationOption_dzud23lz" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={`${classes.hover} ${isHover ? classes.hoverItem : ''} ${!!isLast ? classes.hoverLast : ''}`} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(UserNavigationOption)