import { withStyles, Badge } from '@material-ui/core';
import { formatDistanceToNowStrict } from 'date-fns';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Apply some reset
const styles = theme => ({
    dateTime : {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: '12px',
        lineHeight: 1.43,
        whiteSpace: 'normal',
        letterSpacing: 'normal'
        
    },
    badgeStyle: {
        marginTop: 5,
        width: 20,
        height: 20,
        backgroundColor: '#52c41a',
        fontWeight: '600', 
        fontSize: 11, 
        
        color: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    badge: {
        top: 30,
        right: 10
    }
});

class ChatListItemRightElement extends Component {
    state = { }

    dateFormat() {
        const { commentTime } = this.props;

        if(!commentTime) return ''
        return moment(commentTime).fromNow(false)
    }
    getIsSameUser = () => {
        const userId = this.props?.userData?.profileData?.auth0_id
        const lastUserId = this.props?.lastUserId
    
        if(!userId || !lastUserId || userId !== lastUserId) return false
        return true    
    }

    render() {
        const { newComments, classes,t } = this.props;
        const isSameUser = this.getIsSameUser()

        let badgeContent = !isSameUser && newComments
            ? <div data-intrcm-id="ChatListItemRightElement_7h5nam4n" className={classes.badgeStyle} >{newComments}</div> 
            : <div data-intrcm-id="ChatListItemRightElement_pij7qvqr" />

        return <div data-intrcm-id="ChatListItemRightElement_wkzjgx1p" style={{marginLeft: 'auto', width: 'auto'}} >
            <Badge badgeContent={badgeContent} classes={{ badge: classes.badge }}>
                <div data-intrcm-id="ChatListItemRightElement_pbruquod" className={classes.dateTime}>{this.dateFormat()}</div>
            </Badge>
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    ...state.wrapper,
    channel: state.results.channel,
    searchQuery: state.search,
    users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatListItemRightElement);