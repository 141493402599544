import { setToken } from '@tuqqi/common';
import React, { Component, createRef, memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Route, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Routing from './routing';
import UserSettingsService from './services/userSettingsService';
import Spinner from './ui/components/Common/spinner/spinner';
import NotFound from './ui/views/messages/pages/notFound';
import AuthHandler from './utils/auth/authHandler';
import { push } from 'react-router-redux';
import { persistor, store as globalStore } from '.';
import { withAuth0 } from "@auth0/auth0-react";
import { compose } from 'recompose';
import { initServices, loadOrganizationData } from './utils/loadOrganizationData';
import moment from 'moment';
import i18n from 'i18next';
import {registerServices, groupService} from '@tuqqi/common'
import { SERVER_API } from './utils/serverAPI';
import { detectIncognito } from "detectincognitojs";
import { managerTableKeepGlobalData } from '@tuqqi/web';

class TuqqiRouting extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            ignoreRehydration: false,
            isLoadingUser: false
        }
        this.allowPush = createRef(false)
    };

    resolveIncognito = () => {
        detectIncognito().then((result) => {
            this.setState({ignoreRehydration: !!result?.isPrivate})
        }).catch((err) => {
            this.setState({ignoreRehydration: false})
        });
    }

    componentDidMount() {
        this.resolveIncognito()
        this.handleAuthentication()
    }

    componentDidUpdate() {
        this.handleAuthentication()
    }


    isClientMobile = () => {
        let mql = window.matchMedia('(max-width: 750px)');
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches) {
            return true;
        }
        return false;
    }
    sameTuqqiVersion = () => {
        if (!('tuqqiVersion' in store.getState().globalData)) return false;
        return process.env.CURRENT_VERSION === store.getState().globalData.tuqqiVersion
    }

    checkLang = () => {
        const {userData} = this.props
        if(!userData.isAuthenticated) return
        const lang = userData?.profileData?.lang
        if(lang === i18n.language) return;
        i18n.changeLanguage(lang)
        moment.locale(lang)
    }

    handleAuthentication = async () => {
        const { auth, userData, globalData, dispatch } = this.props;
        const { isLoading,
            isAuthenticated,
            error,

            // user,
            loginWithRedirect,
            logout } = this.props.auth0;

        const { user, isLoadingUser, ignoreRehydration } = this.state

        if (!globalData.rehydrated && !ignoreRehydration) {
            return
        }

        if (isLoading) {
            return
        }
        // First layer
        if (isAuthenticated) {
            const { getIdTokenClaims } = this.props.auth0;

            const claims = await getIdTokenClaims();
            let id_token = "Wrong"

            try {
                if(!claims || !claims?.__raw){
                    loginWithRedirect()
                    return
                }
                id_token = claims.__raw;
            }  catch (error) {
                id_token = "Wrong"
            }

            // second layer
            this.checkLang()
            if ((userData && userData.isAuthenticated && userData.idToken === id_token)) {

                if(!userData?.profileData?.org && !!user?.org) {
                    dispatch({
                        type: 'USER_PROFILE_UPDATE',
                        profileData: user
                    })
                }                
                groupService.getToken() !== id_token && registerServices(id_token, SERVER_API.API_URL)

                // init services
                if (this.props.services && this.props.services.userSettingsService) {
                    if (this.props.services.userSettingsService.getUserToken() !== id_token) {
                        const services = initServices(id_token, loginWithRedirect);
                        dispatch({ type: 'SERVICES_INIT', services });
                        this.props.dispatch(setToken(id_token))
                        !this.sameTuqqiVersion() && loadOrganizationData(id_token, this.props.dispatch).then(() => {
                            persistor.persist()
                        })

                    }
                } else {
                    const services = initServices(id_token, loginWithRedirect)
                    dispatch({ type: 'SERVICES_INIT', services });
                    this.props.dispatch(setToken(id_token))
                    !this.sameTuqqiVersion() && loadOrganizationData(id_token, this.props.dispatch).then(() => {
                        persistor.persist()
                    })
                }


                if (user) {                    
                    if (!this.allowPush.current) return
                    if (user.org) {

                        this.props.dispatch(push('/feed' + window.location.search))
                    } else {
                        this.props.dispatch(push('/'))
                    }


                } else {
                    // Get user data
                    if (!isLoadingUser) {
                        this.loadUser(id_token);
                    }

                }

            } else {                            
                // // needs to login and load user
                const services = initServices(id_token, loginWithRedirect)
                dispatch({ type: 'SERVICES_INIT', services });
                this.props.dispatch({ type: 'USER_LOGIN', authResult: { idToken: id_token } });
                this.props.dispatch(setToken(id_token))
                // loginWithRedirect()
            }
        } else {            
            loginWithRedirect()
        }
    }

    loadUser = (id_token) => {
        const userSettingsService = new UserSettingsService(id_token)



        this.setState({ isLoadingUser: true })
        userSettingsService.getUser().then(user => {
            this.setState({ user, isLoadingUser: false })
            managerTableKeepGlobalData.setUserDetails(user)
        }).catch((err) => {                           
            if(err.message.includes('suspended')) {
                this.props.dispatch({type: "SET_SUSPENDED_ERROR"})
                return
            }
            // this.handleAuthentication()
            this.props.auth0.logout({ returnTo: process.env.AUTH0_REDIRECT })

        })
    }

    render() {
        const { userData } = this.props;
        const { user } = this.state
        const { isLoading,
            isAuthenticated,
            error,

            // user,
            loginWithRedirect,
            logout } = this.props.auth0;


        if (isLoading) {
            return <Spinner />        }

        return <TR
            authIsAuthenticated={isAuthenticated}
            userIsAuthenticated={userData && userData.isAuthenticated}
            user={user}
            isClientMobile={this.isClientMobile()}
        />

    }
}

const mapStateToProps = (state, ownProps) => ({
    authService: state.authModule.authService,
    userData: state.userData,
    pathname: state.wrapper.pathname,
    globalData: state.globalData,
    services: state.services,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}


export default compose(
    withAuth0,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TuqqiRouting);
const shouldUpdate = (prevProps, nextProps) => {
    if (prevProps.authIsAuthenticated !== nextProps.authIsAuthenticated) return false
    else if (prevProps.userIsAuthenticated !== nextProps.userIsAuthenticated) return false
    else if (prevProps.isClientMobile !== nextProps.isClientMobile) return false
    else if (!prevProps.user || !nextProps.user) return false
    return true
}

const TR = memo(function TR(props) {
    const { authIsAuthenticated, userIsAuthenticated, user, isClientMobile } = props;
    const history = useMemo(() => {
        return syncHistoryWithStore(browserHistory, globalStore)
    }, [])

    if (authIsAuthenticated) {
        if (userIsAuthenticated) {
            if (user) {
                const routing = Routing({ isMobile: isClientMobile, user });
                return <Router history={history}>
                    {routing}
                    {/* <Route path='callback' component={AuthHandler} /> */}
                    <Route path="*" component={NotFound} />
                </Router>
            }

            return <Spinner />
        }
    }

    return <Router history={history}>
        {/* <Route path='callback' component={AuthHandler} /> */}
        <Route path="*" component={() => {
            return <Spinner />
        }} />
    </Router>
}, shouldUpdate)