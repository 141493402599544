import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideVideoChat } from '../../../actions/appActions';
import withErrorCatcher from '../Common/withErrorCatcher.js'


class VideoChatWindow extends Component {

    componentDidMount() {
        window.addEventListener('message', this.handleIframeTask);

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);

    }

    handleIframeTask = (e) => {
        if (e.origin !== process.env.VIDEOCHAT_URL) {
            return;
        }
        if (e.data === 'close' || e.data === 'leave') {
            this.props.dispatch(hideVideoChat());
        }
        if (e.data === 'minimize') {
            // Change screen size
        }
    };

    render() {
        const { videoChatWindow, userData } = this.props;
        const { isOpen, docId, title } = videoChatWindow;


        if (isOpen) {
            return <div data-intrcm-id="videoChatWindow_hl6830ee" style={{
                width: 800,
                height: 640,
                position: "fixed",
                bottom: 50,
                right: 50,
                zIndex: 2300,
                backgroundColor:'#424242'
            }}>
                <iframe src={process.env.VIDEOCHAT_URL + `/room/${docId}?idToken=${userData.idToken}&title=${title}`} width="800px" height="640px" scrolling="auto" allow="microphone; camera" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>

            </div>
        } else {
            return null
        }


    }
}

const mapStateToProps = (state) => ({
    videoChatWindow: state.videoChatWindow,
    userData: state.userData,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(VideoChatWindow);