import { FULL_RESET_STORE } from "../actionTypes/actionTypes";
import { ADD_FEED_MENU_ITEM, DELETE_FEED_MENU_ITEM, SET_FEED_MENU_ITEMS, SET_OPENED_MENU_ITEM, UPDATE_FEED_MENU_ITEM } from "../actionTypes/feedNavigationTypes";
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

// OrgMenuItem: { id: number, referenceUid: string, customTitle: string, customIcon: string, categoryId: number, type: 'group' | 'item' | 'external_link' | 'category', order: number }
const initialState = {
    orgMenuItems: [],
    isNewOpened: false,
    openedItem: null
}


const feedNavigation = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
        case ON_GO_TO_ITEM_NOTIF:
            return initialState
        case SET_OPENED_MENU_ITEM:
            return {
                ...state,
                isNewOpened: action.isNewOpened,
                openedItem: action.item
            }
        case SET_FEED_MENU_ITEMS: {
            const {items} = action
            const newItems = items.sort((a, b) => a.order - b.order)
            return {
                ...state,
                orgMenuItems: newItems
            }
        }
        case ADD_FEED_MENU_ITEM: {
            const {item} = action
            const newItems = [...state.orgMenuItems, item].sort((a, b) => a.order - b.order)
            return {
                ...state,
                orgMenuItems: newItems
            }
        }
        case UPDATE_FEED_MENU_ITEM: {
            const {item} = action
            const newItems = [
                ...state.orgMenuItems.filter((it) => it.id !== item.id), 
                item
            ].sort((a, b) => a.order - b.order)
            return {
                ...state,
                orgMenuItems: newItems
            }
        }
        case DELETE_FEED_MENU_ITEM: {
            const {id} = action
            const newItems = state.orgMenuItems.filter((item) => item.id !== id)
            return {
                ...state,
                orgMenuItems: newItems
            }
        }
        default:
        return state
    }
}

export default feedNavigation