import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser'

export const withMixpanel = (WrappedComponent) => {
    class WithMixpanel extends Component {


        mixpanelTrack = (actionTitle, actionData) => {
            if (window.location.hostname === 'app.tuqqi.com'
                || window.location.hostname === 'desktop.tuqqi.com') {

                mixpanel.track("Web - " + actionTitle, actionData)
            }
        }

        render() {



            return <WrappedComponent {...this.props} mixpanelTrack={this.mixpanelTrack} />
        }
    }



    return WithMixpanel
}