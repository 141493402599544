import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import ItemPreviewCard from '@components/FeedViewComponents/itemPreviewCard';
import defaultPostInput from '../../Common/defaultPostInput';
import Spinner from '../../Common/spinner';
import FeedDataItem from '../../FeedViewComponents/PreviewCardTypes/FeedDataItem';
import NoResults from './NoResults';


// Apply some reset
const styles = theme => ({
    paper: {
        // padding: 16,
        width: '100%',
        color: theme.palette.text.secondary,
    },
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    title: {
        fontFamily: 'Montserrat',
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        textAlign: 'left',
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: '#302f47',
    },
    askQuestion: {
        fontFamily: '"Roboto"',
        fontSize: 12,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 1.1,
        textAlign: 'center',
        color: '#ffffff',
        backgroundColor: theme.customColors.primary.b600,
        marginBottom: 36,
        padding: '10px 16px 10px 16px'
    }
});

class ResultsFeed extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    catchError = (a, b, c) => {
        window.event.cancelBubble = true
        window.event.returnValue = false
        window.event.preventDefault()
        return false
    }

    addQuestion = () => {
        this.props.dispatch({
            type: 'FULLVIEW_NEW',
            item: { postText: defaultPostInput(this.props.searchQuery), dataType: 'question', classification: 0 },
        })
    }



    render() {
        const { classes, isLoading, loadMore, results, hasMore, isSearch, isUniqeSearch, showInput = false } = this.props;



        let content = null;

        if (isLoading) {
            content = <Spinner classes={classes} />

        } else if (!results.length) {
            content = <NoResults isSearch={isSearch} isUniqeSearch={isUniqeSearch} />

        } else {
            let panels = results.map((result, idx) => {
                let item = <FeedDataItem key={result.Key}
                    docId={result.Key}
                    isSearchResult={isSearch}
                    result={result.Value.Source}
                    whyDoISeeThisItem={result.Value.WhyDoISeeThisItem}
                    log={!isSearch ? result.Value.log : {}}
                    related={result.Value.related} />


                return item;
            })

            content = <Fragment>
                {this.props.Header}
                {/* {showInput ? <InputPreview /> : <div data-intrcm-id="resultsFeed_p2jcdkgz" />} */}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={<Spinner classes={classes} />}>
                    {/* <Grid
                        style={{ flexWrap: 'nowrap' }}
                        container
                        direction="column"
                        spacing={16}
                    > */}
                    {panels}

                    {/* </Grid> */}
                </InfiniteScroll></Fragment>

        }




        return <div data-intrcm-id="resultsFeed_giv7vagy" className={classes.paper}>
            <Grid
                style={{ flexWrap: 'nowrap' }}
                container
                direction="column"
                spacing={16}
            >
                {/* {!isLoading && results.length ? this.props.Header : null}
                {!isLoading ? <InputPreview />: null} */}
                {content}

            </Grid>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    router: state.router,
    ...state.services,
    searchQuery: state.search,
    oldUiData: state.oldUiData

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles, { withTheme: true }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ResultsFeed);

