import React, { PureComponent } from 'react';

export const ReadIcon = class ReadIcon extends PureComponent {

    render() {
        const { color, className } = this.props
        return (
            <svg className={className} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9719 0.327478C11.7989 0.336379 11.6361 0.412265 11.5181 0.539067L6.25507 6.04102L4.66849 4.22787C4.6111 4.16114 4.54107 4.10643 4.46243 4.0669C4.3838 4.02737 4.29812 4.0038 4.21033 3.99754C4.12254 3.99129 4.03438 4.00248 3.95093 4.03047C3.86749 4.05846 3.79041 4.1027 3.72415 4.16062C3.65789 4.21855 3.60375 4.28902 3.56486 4.36797C3.52597 4.44693 3.50309 4.5328 3.49756 4.62064C3.49202 4.70848 3.50393 4.79654 3.53259 4.87976C3.56126 4.96297 3.60612 5.03968 3.66458 5.10547L5.32994 7.00847L4.67695 7.69141L1.4914 4.21615C1.43224 4.15158 1.36095 4.0993 1.28158 4.06228C1.20221 4.02527 1.11633 4.00426 1.02884 4.00044C0.941355 3.99662 0.853971 4.01007 0.771682 4.04003C0.689393 4.06999 0.613811 4.11586 0.549253 4.17503C0.484695 4.2342 0.432426 4.30551 0.39543 4.38489C0.358434 4.46426 0.337436 4.55015 0.333636 4.63764C0.329836 4.72513 0.343307 4.81251 0.373282 4.89479C0.403256 4.97707 0.449145 5.05265 0.508329 5.11719L4.175 9.11719C4.23665 9.18446 4.31144 9.23836 4.39477 9.27555C4.47809 9.31274 4.56815 9.33243 4.65939 9.33341C4.75063 9.33438 4.8411 9.31662 4.9252 9.28122C5.00929 9.24582 5.08523 9.19354 5.1483 9.12761L6.21145 8.01628L7.16458 9.10547C7.2255 9.17516 7.30026 9.2314 7.3841 9.27062C7.46794 9.30983 7.55903 9.33115 7.65157 9.33324C7.7441 9.33532 7.83606 9.31811 7.92158 9.28271C8.0071 9.24731 8.08431 9.19448 8.1483 9.12761L15.4816 1.46094C15.5759 1.36518 15.639 1.24318 15.6627 1.11093C15.6864 0.978692 15.6696 0.842373 15.6145 0.719845C15.5593 0.597316 15.4685 0.494293 15.3538 0.424278C15.2392 0.354264 15.106 0.320523 14.9719 0.327478C14.7989 0.336379 14.6361 0.412265 14.5181 0.539067L7.68867 7.67904L7.13724 7.04883L12.4816 1.46094C12.5759 1.36518 12.639 1.24318 12.6627 1.11093C12.6864 0.978692 12.6696 0.842373 12.6145 0.719845C12.5593 0.597316 12.4685 0.494293 12.3538 0.424278C12.2392 0.354264 12.106 0.320523 11.9719 0.327478Z" fill={color}/>
            </svg>
        )
    }
}