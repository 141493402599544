import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
    container: {
        position: 'absolute',
        background: '#fff',
        padding: '10px 16px 12px',
        borderRadius: 4,
        zIndex: '4 !important',
        maxHeight: 67,
        maxWidth: 225,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        wordBreak: 'break-all',
    },
    containerForBigText: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
    },
    text: {
        fontFamily: 'Roboto Slab',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
    },
    textSmall: {
        fontSize: 15,
    }
})
const GroupTitleCollection = (props) => {
    const {styles, classes, currentScene} = props
    const [isTextBig, setIsTextBig] = useState(false)
    const [groupTitle, setGroupTitle] = useState('')

    useEffect(() => {
        setGroupTitle(currentScene.collection.title)
    }, [currentScene])

    useEffect(() => {
        if(groupTitle.length > 15) {
            setIsTextBig(true)
        } else {
            setIsTextBig(false)
        }
    }, [groupTitle])

    if(!groupTitle) return null

    return <div data-intrcm-id="GroupTitleCollection_r8yf7iuf" style={styles.container} className={isTextBig ? `${classes.container} ${classes.containerForBigText}` : classes.container}>
            <div data-intrcm-id="GroupTitleCollection_c9xyy65d" style={styles.text} className={isTextBig ? `${classes.text} ${classes.textSmall}` : classes.text}>
                {groupTitle}
            </div>
        </div>
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(GroupTitleCollection)