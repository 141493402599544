import React, { useEffect, useMemo, useState } from "react";
import { Icon, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import goTo from "../../../../actions/goTo";
import Grid from "@material-ui/core/Grid";
import { useTranslation, withTranslation } from "react-i18next";
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import { LineWeight } from "@material-ui/icons";
import { getDefaultDataTypes } from "@tuqqi/common";
import { getAllCollection } from "../../../../views/groups/groupUtils";

const styles = (theme) => ({
  container: {
    border: '1px solid #EBEBED',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  title: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    color: "#18181B",
	lineHeight: "20px", 
    margin: "12px",
  },
  section: {
	display: 'flex',
    flexDirection: 'column',
    padding: '16px 12px',
  },
  sectionTitle: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    color: "#6B6F7A",
	lineHeight: "16px", 
    marginBottom: "8px",
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    borderRadius: '16px',
    backgroundColor: 'rgba(183, 184, 189, 0.07)',
	border: "1px solid rgba(183, 184, 189, 0.2)",
    color: '#21212d',
    fontFamily: "Rubik",
    fontSize: "13px",
    fontWeight: "500",
    cursor: 'pointer',
	boxSizing: 'border-box',
	lineHeight: "16px", 
  },
  icon: {
	width: 24,
	height: 24,
	borderRadius: "50%",
	marginRight: 3,
  },
  tagItem: {
	display: 'flex',
	flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
	backgroundColor: "rgba(183, 184, 189, 0.15)",
	height: '1px',
	width: '100%',
},
iconTag: {
	width: 24,
	height: 24,
	color: "#6B6F7A",
	marginRight: 3,
}
});

const MAX_LENGTH = 10;

const TrendingTagsWidget = (props) => {
  const { trends, dispatch, classes, query, mixpanelTrack, experts, documents,groups,dataTypes,inputMappings, openCollections,collections, usersCollections } = props;
  const [currentTrends, setCurrentTrends] = useState([]);
  const { t } = useTranslation('feedWidgets');

  const renderTag = (tag) => {
    if (!tag) return '';
    return tag.startsWith('#') ? tag : '#' + tag;
  };

  useEffect(() => {
    const q = renderTag(query);
    const maxTrends = trends
      .sort((a, b) => b.value - a.value)
      .slice(0, MAX_LENGTH)
      .map(t => ({ ...t, selected: q === renderTag(t.key) }));

    setCurrentTrends(maxTrends);
  }, [trends, query]);

  const handleHashTagClick = (tag) => {
    mixpanelTrack(`Trending tags widget - go to ${renderTag(tag)}`, {});
    goTo(renderTag(tag), dispatch)();
  };

  const allGroups = useMemo(() => getAllCollection(openCollections, collections), [openCollections, collections])

  const groupsIds = groups.filter(el => !el.Key.includes("auth0|") && el.Key.length >= 20).map(el => el.Key)
  const groupsCollections = allGroups.filter(el => groupsIds.includes(el.collectionUid))

  console.log({groupsCollections})

  const getAllDataTypes = () => {
	return getDefaultDataTypes().concat(inputMappings)
}


  return (
    <div className={classes.container}>
      <div className={classes.title}>Mentioned</div>
	  <div className={classes.divider} />
	  	{!!groupsCollections.length  && <div className={classes.section} >
			<div className={classes.sectionTitle}>Groups</div>
				<div className={classes.tagContainer}>
				{groupsCollections.map(el => {
						return (
								<div className={classes.tag}>
									<div>
										{`${el?.title}`}
									</div>
								</div>
					)}
				)}
			</div>
		</div>}
	  <div className={classes.divider} />
		{dataTypes.length && <div className={classes.section} >
			<div className={classes.sectionTitle}>Forms</div>
			<div className={classes.tagContainer}>
				{dataTypes.filter(el => el.Key !== "All" ).slice(0, 5).map(el => {
						const inputMapping = getAllDataTypes().find(mapping => mapping.customDataMapping.dataTypeFilter === el.Key)
						const icon = inputMapping ? inputMapping.icon : ''

					return (
							<div className={classes.tag}>
								<Icon className={classes.iconTag}>{icon}</Icon>
								<div>
									{`${inputMapping?.title.replace('inputTypes:', '')} (${el.DocCount})`}
								</div>
								</div>
				)}
			)}
			</div>
		</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  trends: state.globalData.trends,
  query: state.search.query,
  search: state.search,
  relatedDocs: state.results.relatedDocs,
  experts: state.results.experts,
  groups: state.results.groups,
  dataTypes: state.results.dataTypes,
  documents: state.data.documents.data,
  inputMappings: state.globalData.inputMappings,
  openCollections: state.globalData.openCollections ?? [],
  usersCollections: state.data.userData.userCollections?.data ?? [],
  collections: state.data.userData.userCollections.data,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch: dispatch };
};

export default compose(
  withStyles(styles),
  withMixpanel,
  connect(mapStateToProps, mapDispatchToProps)
)(TrendingTagsWidget);
