import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import UsersDialog from '../../../../Common/UsersDialog.js';
import PollOptionTemplate from './PollOptionTemplate.js';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PollMoreOptions from './PollMoreOptions.js';
import Spinner from '../../../../Common/spinner.js';
import { withTranslation } from 'react-i18next';

const NUMBER_OF_OPTIONS_TO_SHOW = 5

const styles = theme => ({
    moreOptions: {
        border: 'solid 1px rgba(151, 154, 170, 0.3)',
        width: '100%',
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#979aaa',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#21212d',
        }
    },
    spinner: {
        margin: '32px auto'
    },
})

class PollTemplate extends PureComponent {
    state = {
        poll: {
            options: []
        },
        votedUsersDialogOpen: false,
        usersIds: [],
        loading: false
    }

    getPollFromApi = (res) => {
        const { docId, collaborationService } = this.props;

        if (!res) {
            console.warn("vote wasnt save")
        }
        else {
            const updatedPoll = this.updatePoll(res);
            this.setState({ poll: { ...updatedPoll, needUpdate: true } })
        }

        collaborationService.logClick(docId)
    }

    vote = (option) => (event) => {
        const { docId, pollService } = this.props;

        pollService.vote(option, docId).then(res => {
            this.getPollFromApi(res)
        })

        event.stopPropagation();
    }

    addOption = (text, img) => {
        const { docId, pollService } = this.props;

        if (text) {
            const pollReq = {
                docId: docId,
                options: [{ optionText: text, optionImg: img }]
            }

            pollService.addOption(pollReq).then(res => {
                this.getPollFromApi(res)
            })
        }
    }

    updatePoll = (poll) => {
        if(!poll){
            return { options: [] }
        }

        const sortedOptions = poll.options.map(o => { return { ...o, votes: o.pollOptionUsers.length } }).sort((o1, o2) => {
            return o2.votes - o1.votes
        })

        const votes = sortedOptions.map(o => o.votes).reduce((v1, v2) => v1 + v2, 0)

        return {
            ...poll,
            votes,
            options: sortedOptions
        }
    }

    componentDidMount() {
        const { result, pollService, docId } = this.props;

        if(!result.poll){
            this.setState({ loading: true })

            pollService.getPoll(docId).then(res => {
                const loadedPoll = this.updatePoll(res);
                this.setState({ poll: loadedPoll, loading: false })
            })
        }
        else{
            const updatedPoll = this.updatePoll(result.poll);
            this.setState({ poll: updatedPoll })
        }
    }

    openUsersDialog = (usersIds) => (event) => {
        this.setState({ votedUsersDialogOpen: true, usersIds })
        event.stopPropagation();
    }

    closeUsersDialog = () => this.setState({ votedUsersDialogOpen: false })

    pollUpdated = () => {
        this.setState({ poll: { ...this.state.poll, needUpdate: false } })
    }

    renderOption = (idx, option) => {
        const { votes, needUpdate } = this.state.poll;

        return <PollOptionTemplate
            idx={idx}
            option={option}
            votes={votes}
            vote={this.vote(option)}
            openUsersDialog={this.openUsersDialog}
            needUpdate={needUpdate}
            pollUpdated={this.pollUpdated}
        />
    }

    render() {
        const { classes, t } = this.props;
        const { moreOpen, poll, loading } = this.state;
        const { options, isAddOwn } = poll;

        const firstOptionsGroup = options.slice(0, NUMBER_OF_OPTIONS_TO_SHOW);
        const secondOptionsGroup = options.slice(NUMBER_OF_OPTIONS_TO_SHOW);

        return loading 
            ? <Spinner classes={classes} />
            : <div data-intrcm-id="PollTemplate_kjoyqgn8" style={{ paddingLeft: 16, paddingRight: 16, width: '100%' }}>
                {firstOptionsGroup.map((option, idx) => {
                    return this.renderOption(idx, option);
                })}
                <PollMoreOptions
                    secondOptionsGroup={secondOptionsGroup}
                    showMore={(event) => { this.setState({ moreOpen: !moreOpen });  event.stopPropagation(); }}
                    moreOpen={moreOpen}
                    optionsLength={options.length}
                    isAddOwn={isAddOwn}
                    renderOption={this.renderOption}
                    numberToShow={NUMBER_OF_OPTIONS_TO_SHOW}
                    addOption={this.addOption}
                />
                <UsersDialog
                    open={this.state.votedUsersDialogOpen}
                    onClose={this.closeUsersDialog}
                    usersIds={this.state.usersIds}
                    title={t("Voters")}
                />
            </div>
    }
}
const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    cloudfront: state.globalData.cloudfront,
    mappings: state.globalData.inputMappings,
    ...state.services,
})

export default compose(withStyles(styles), withTranslation('pollTemplate'), connect(mapStateToProps))(PollTemplate)