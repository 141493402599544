import { browserHistory } from 'react-router'
import fileDownload from 'react-file-download'
// import fetchJsonp from 'fetch-jsonp'


export default class QueryService {
    constructor(token, onUnauthorized) {

        this.token = token;
        this.onUnauthorized = onUnauthorized;
        

    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    autosuggest(query, signal) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (query !== '') {
            return fetch(process.env.API_SERVER + '/autosuggest?query=' + encodeURIComponent(query), {
                method: 'GET',
                signal: signal,
                // body: form,
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            }).catch(err => {
                if(err.message === 'The user aborted a request.') return;//manualy abort previos request
            });
        }
    }

    async autosuggestItems(input = "", signal) {
        try {
            const address = `${process.env.API_SERVER}/search`
            var headers = new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json'
            });
            const query = {
                query: input,
                dataTypes: [],
                page: 0,
                size: 25,
            }

            const res = await fetch(address, {
                method: 'POST',
                headers,
                body: JSON.stringify(query),
                signal: signal
            })
            
            if(!res || !res.ok) return { val: [], isAborted: false }
            
            const suggested = await res.json()
            const items = suggested.data.map(suggest => suggest.Value.Source)

            return { val: items, isAborted: false }
        }
        catch(err) {
            if (err.name === 'AbortError') {
                return { val: [], isAborted: true }
            } 
            return { val: [], isAborted: false }
        }
    }

    async searchItem(itemId, signal) {
        try {
            const searchQuery = {
                query: '',
                itemsIds: [itemId],
                page: 0,
                size: 1,
                AggType: 'FLAT',
            }
            var headers = new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json'
            });
            const address = `${process.env.API_SERVER}/search/SearchByIds?returnFullData=${false}`
            const res = await fetch(address, {
                method: 'POST',
                body: JSON.stringify(searchQuery),
                headers,
                signal: signal
            })
            
            if(!res) return null
            const result = await res.json()
            if(!result || !result.data?.length || result.data[0].Value.Source.docId !== itemId) return null
            return result.data[0].Value.Source
        }
        catch(err) {
            if (err.name == 'AbortError') {
                return null
            }
            return null
        }
    }

    getSearchResultsFromBing(q) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (q !== '') {
            return fetch('https://api.cognitive.microsoft.com/bing/v7.0/search?q=' + encodeURIComponent(q) + '&count=5&offset=0&mkt=en-us&safesearch=Moderate', {
                method: 'GET',
                // body: form,
                headers: {
                    'Ocp-Apim-Subscription-Key': '3f54a1bb0e9d44e0a3fd68a6e77399b9'
                }
            }).then(function (res) {

                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        } else {
            return null;
        }
    }

    getSuggestedNewsFromBing(q) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (!q) {
            return new Promise()
        }

        return fetch('https://api.cognitive.microsoft.com/bing/v7.0/news/search?q=' + encodeURIComponent(q) + '&count=10&offset=0&mkt=en-us&safesearch=Moderate', {
            method: 'GET',
            // body: form,
            headers: {
                'Ocp-Apim-Subscription-Key': '3f54a1bb0e9d44e0a3fd68a6e77399b9'
            }
        }).then(function (res) {

            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }




    LoadMore(searchQuery) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/search/groupmore';
        if (searchQuery.AggType === 'TIMELINE') {
            address = process.env.API_SERVER + '/search/eventmore';
        }

        if (searchQuery.query !== '' || searchQuery.isCollection) {
            return fetch(address, {
                method: 'POST',
                body: JSON.stringify(searchQuery),
                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    Search(searchQuery, signal = null, returnFullData = false) {
        let myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        let address = process.env.API_SERVER + '/search';
        if (searchQuery.AggType === 'FLAT') {
            address = process.env.API_SERVER + '/search?returnFullData=' + returnFullData;
        } else if (searchQuery.AggType === 'TIMELINE') {
            address = process.env.API_SERVER + '/search/timeline';
        } else {
            address = process.env.API_SERVER + '/search/grouped';
        }

        return fetch(address, {
            method: 'POST',
            signal: signal,
            body: JSON.stringify(searchQuery),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }
    fetchACInit(searchQuery, signal = null, returnFullData = false) {
        let myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        const address = process.env.API_SERVER + '/action-center?returnFullData=' + returnFullData;

        return fetch(address, {
            method: 'POST',
            signal: signal,
            body: JSON.stringify(searchQuery),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            }).catch(() => null);

    }

    SearchByIds(searchQuery) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });


        return fetch(process.env.API_SERVER + '/search/SearchByIds', {
            method: 'POST',
            body: JSON.stringify(searchQuery),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }

    updateDocumentSchedule(docId, schedule) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });


        return fetch(process.env.API_SERVER + `/content/update-schedule?id=${encodeURIComponent(docId)}`, {
            method: 'POST',
            body: JSON.stringify(schedule),
            headers: myHeaders
        })
        .then((res) => res.json())
        .catch(err => null);

    }

    SearchDueDateItems(showAll = false) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });


        return fetch(process.env.API_SERVER + '/search/upcomingTasks?showAll=' + showAll, {
            method: 'POST',
            body: JSON.stringify(),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }

    searchById(itemId) {

        const query = {
            itemsIds: [itemId],
            page: 0,
            filter: {},
            tags: [],
            size: 1,
            AggType: 'FLAT',
        }

        return this.SearchByIds(query);
    }
    GetKanbanData(searchQuery) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/search/kanban-web';



        return fetch(address, {
            method: 'POST',
            body: JSON.stringify(searchQuery),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }



    GetGroup(groupUid) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            //'Content-Type': 'application/json'
        });

        if (groupUid) {
            return fetch(process.env.API_SERVER + '/search/getGroup/?groupUid=' + groupUid, {
                method: 'GET',
                //body: JSON.stringify(searchQuery),
                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    getCollection(groupUid) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            //'Content-Type': 'application/json'
        });

        if (groupUid) {
            return fetch(process.env.API_SERVER + '/search/getCollection/?groupUid=' + groupUid, {
                method: 'GET',
                //body: JSON.stringify(searchQuery),
                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    getFeed(page) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/feed?page=' + page, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getUpToDate() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/feed/uptodate', {
            method: 'GET',
            headers: myHeaders
        }).then((res)=> {
            if(res.status === 401){                                                              
                this.onUnauthorized();
            }
                        
            return res.json();
        }).catch(err => {
            
        });

    }

    getTask(page) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/tasks/taskEvents?page=' + page, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                            
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getFeedTasks(size = 3) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(`${process.env.API_SERVER}/tasks/feedTasks?size=${size}`, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getRelated(docId, tags) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/search/related?docId=' + encodeURIComponent(docId),
            {
                body: JSON.stringify(tags && tags.length > 0 ? tags : null),
                method: 'POST',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    getLibrary() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/tags/library', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getTrends() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        try {

        return fetch(process.env.API_SERVER + '/tags/trend', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }).catch(err => []);
        } catch (error) {
            return []
        }
    }

    getTagInfo(tag) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/tags/info?hashtag=' + encodeURIComponent(tag);
        if (tag !== '') {
            return fetch(address, {
                method: 'GET',

                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    getCollectionInfo(collectionId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
        });

        if (collectionId) {
            return fetch(process.env.API_SERVER + '/collections/info?collectionUid=' + encodeURIComponent(collectionId),
                {
                    method: 'GET',
                    headers: myHeaders
                })
                .then(res => res.json())
                .catch(() => undefined);
        }
    }

    getSubscribedUsesForChannel(query) {
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            // 'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/search/SubscribedUsers?query=' + encodeURIComponent(query),
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(res => res.json()).catch((e) => {

            });
    }

    searchShortcuts(query) {
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
        });

        return fetch(process.env.API_SERVER + '/search/searchShortcuts?query=' + encodeURIComponent(query),
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(res => res.json()).catch((e) => {

            });
    }

    getRelatedTags(searchQuery) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/tags/related';
        if (searchQuery.query !== '') {
            return fetch(address, {
                method: 'POST',
                body: JSON.stringify(searchQuery),
                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }

    }

    downloadFile(file) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token

        });

        if (file.fileLink !== '') {
            return fetch(process.env.API_SERVER + '/files/s3?fileId=' + encodeURIComponent(file.fileLink) + '&filename=' + encodeURIComponent(file.fileName) + '&type=' + encodeURIComponent(file.fileType), {
                method: 'GET',
                // body: form,
                headers: myHeaders
            }).then(function (res) {
                return res.blob();
            }).then(res => {
                fileDownload(res, file.fileName);
            });
        }
    }

    getSources() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/tags/source', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }


    checkLink(link) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (link !== '') {
            return fetch(process.env.API_SERVER + '/bookmarks/check',
                {
                    method: 'POST',
                    body: JSON.stringify({

                        Url: link

                    }),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }


    }

    // Scores
    getScores() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + '/scoreboard/all', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    // Archive
    getArchivedItems() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/archive/archive', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getLogItems() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/archive/logs', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getDeletedItems() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/archive/deleted', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    restoreItem(id) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(process.env.API_SERVER + '/archive/restore/'+ id, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }
    getArchivedItemsById(id) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/archive/history?id=' + encodeURIComponent(id);
        if (id !== '') {
            return fetch(address, {
                method: 'GET',

                headers: myHeaders
            })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }


    // OUTER SERVICES
    getBingNews(q) {

        if (q !== '') {
            return fetch('https://api.cognitive.microsoft.com/bing/v5.0/news/search?q=' + q + '&count=5&offset=0&mkt=en-us&safesearch=Moderate', {
                method: 'GET',
                // body: form,
                headers: {
                    'Ocp-Apim-Subscription-Key': 'f2684acc8060498093e66e6b9e7699f4'
                }
            }).then(function (res) {

                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }

    }

    // testSearchCollection(searchQuery) {
    //     var myHeaders = new Headers({
    //             'Authorization': 'Bearer ' + this.token,
    //             'Content-Type': 'application/json'
    //         });

    //         return fetch(process.env.API_SERVER + '/search/kanbanUnassigned', {
    //         method: 'POST',
    //         body: JSON.stringify(searchQuery),
    //         headers: myHeaders
    //     })
    //     .then(function (res) {
    //     if(res.status === 401){                                
                // console.log(res);
                // this.onUnauthorized();
            // }
                        
            // return res.json();
    //     });
    //     }


}