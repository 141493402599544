const mapToHeader = (result, log, mappings) => {

    // get relevant mapping
    let mapping = getRelevantMapping(result, mappings);
    if (mapping != null) {
        // Create mapped fields
        let mappedFields = createMappedFieldPreview(result, mapping);
        // Create header props
        let headerProps = {
            userName: mappedFields.UserName,
            userPicUrl: mappedFields.UserPicUrl,
            userTag: mappedFields.UserTag,
            actionType: 'Shared a ' + mappedFields.DataTypeTitle + ' ',
            actionDate: result.docTimestamp
        }

        return headerProps


    }
    return null

}

const mapToTemplate = (result, mappings) => {
    let mapping = getRelevantMapping(result, mappings);
    if (mapping != null) {
        // Create mapped fields
        // let mappedFields = createMappedFieldPreview(result, mapping);

        // Create header props
        let template = {
            ...result,
            // ...mappedFields,
            // Template Id
            // TemplateUID:mapping.previewDisplayTemplate.uid,                        
            // custom fields array
            customFieldsMappings: mapping.customFieldsMappings,
            structureJson: mapping.structureJson,
        }

        return template;
    }

    return null
}

const mapToCustomTemplate = (result, customMapping) => {
    let mapping = getRelevantCustomMapping(result, customMapping);
    if (mapping != null) {
        let template = {
            ...result,
            customFieldsMappings: mapping.customDataMapping.customFieldsMappings,
            checklists: mapping.checklists,
            icon: mapping.icon
        }

        return template;
    }

    return null
}

// const checkIfResultIsMapped = (result, mappings) => {

//     const { source, dataType } = result;
//     // Check for relevant mapping
//     for (var index = 0; index < mappings.length; index++) {
//         var mapping = mappings[index];

//         // Check if dataType and source have specific mapping
//         if (mapping.dataTypeFilter === dataType && mapping.sourceFilter === this.props.result.source) {
//             // return <DynamicItemDisplay item={properties} mapping={mapping} />;
//             return true;
//         }
//     }
// }

const getRelevantCustomMapping = (result, customMapping) => {
    const { source, dataType } = result;
    let returnMapping = null;

    // Check for relevant mapping
    for (var index = 0; index < customMapping.length; index++) {
        var mapping = customMapping[index].customDataMapping;

        // Check if dataType and source have specific mapping
        if (mapping.dataTypeFilter === dataType && mapping.sourceFilter === source) {
            returnMapping = customMapping[index];
        }
    }

    return returnMapping
}

const getRelevantMapping = (result, mappings) => {
    const { source, dataType } = result;
    let returnMapping = null;

    // Check for relevant mapping
    for (var index = 0; index < mappings.length; index++) {
        var mapping = mappings[index];

        // Check if dataType and source have specific mapping
        if (mapping.dataTypeFilter === dataType && mapping.sourceFilter === source) {
            returnMapping = mapping;
        }
    }

    return returnMapping
}

const _isFieldInPreviewTemplate = (mapping, fieldId) => {
    // mapping.previewDisplayTemplate.fields

    for (var index = 0; index < mapping.previewDisplayTemplate.fields.length; index++) {
        var _field = mapping.previewDisplayTemplate.fields[index];
        if (_field.id === fieldId) {
            return true;
        }

    }
    return false;
}

const createMappedFieldPreview = (doc, mapping) => {

    var s = {};
    var mappedFields = {};
    // get display template fields
    mapping.displayMappings.map((fieldMapping) => {
        if (_isFieldInPreviewTemplate(mapping, fieldMapping.templateFieldId)) {


            if (fieldMapping.sourceFieldId === 15) {
                mappedFields[fieldMapping.templateField] = fieldMapping.propertiesValue
            } else if (fieldMapping.sourceFieldId === 14) {
                if (JSON.parse(doc.jsonData)) {
                    mappedFields[fieldMapping.templateField] = JSON.parse(doc.jsonData)[fieldMapping.propertiesValue];
                } else {
                    mappedFields[fieldMapping.templateField] = '';
                }

            } else {

                if (doc[fieldMapping.sourceField] === '') {
                    mappedFields[fieldMapping.templateField] = fieldMapping.propertiesValue;
                } else {

                    mappedFields[fieldMapping.templateField] = doc[fieldMapping.sourceField];
                }

            }
        }

    })


    return mappedFields;

}

export { mapToHeader, mapToTemplate, mapToCustomTemplate, getRelevantCustomMapping };