
export const getGroupViewFromPath = (pathname = '') => {
    if(!pathname) return ''
    if(pathname.includes('/group/feed')) return 'feed'
    if(pathname.includes('/group/home')) return 'home'
    if(pathname.includes('/group/table')) return 'table'
    if(pathname.includes('/group/scheduler')) return 'scheduler'
    if(pathname.includes('/group/chat')) return 'chat'
    if(pathname.includes('/group/kanban')) return 'kanban'
    if(pathname.includes('/group/gantt')) return 'gantt'
    if(pathname.includes('/group/calendarnew')) return 'calendar'
    if(pathname.includes('/group/calendar')) return 'calendar'
    if(pathname.includes('/group/map')) return 'map'
    if(pathname.includes('/group/dashboard')) return 'home'
}