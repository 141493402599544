import React, { useEffect, useRef, useState } from 'react'
import {withStyles} from '@material-ui/core'
import Close from '@material-ui/icons/Close';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import parseImgeUrl from '../../Common/parseImagUrl';
import { formatDistanceToNowStrict } from 'date-fns';
import { closeStory } from '@tuqqi/common'

const styles = (theme) => ({
    container: {
        width: 'inherit',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    avatar: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        border: `solid 3px ${theme.customColors.secondary.main}`,
        objectFit: 'cover',
    },
    texts: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'row'
    },
    title: {
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.7,
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        letterSpacing: 'normal',
        color: '#fff',
        marginRight: 12,
    },
    time: {
        opacity: .75,
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: .7,
        letterSpacing: 'normal',
        color:'#fff',
    },
    more: {
        marginLeft: 'auto',
        marginRight: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    moreIcon: {
        transform: 'scale(1.15)'
    },
    closeIcon: {
        color: '#fff',
        fontSize: 44,
        cursor: 'pointer'
    }
})

const defaultCurrentScene = {timestamp: 0, docId: ''}

const SceneHeader = (props) => {
    const {classes, storiesNavigation, wrapClass, cloudfront, dispatch} = props
    const {currentScene, currentStoryId, stories} = storiesNavigation
    const storiesRef = useRef([])
    const [profile, setProfile] = useState(null)
    const [time, setTime] = useState('')
    const {timestamp} = currentScene || defaultCurrentScene

    useEffect(() => {
        const time = formatDistanceToNowStrict(new Date(timestamp), {addSuffix: false})
        setTime(time)
    }, [timestamp])

    useEffect(() => {
        storiesRef.current = stories
    }, [stories])

    useEffect(() => {
        const story = storiesRef.current.find(s => s.user.auth0_id === currentStoryId)
        if(!story) return;
        setProfile(story.user)
    }, [currentStoryId])

    const onClose = () => {
        dispatch(closeStory())
    }

    if(!currentStoryId || !profile || !currentScene) return null

    return <div data-intrcm-id="SceneHeader_6yg60cq2" className={wrapClass}>
        <div data-intrcm-id="SceneHeader_e0sd5su3" className={classes.container}>
            <img alt="story_avatar" src={parseImgeUrl(cloudfront, profile.profileImageUrl)} className={classes.avatar}/>
            <div data-intrcm-id="SceneHeader_v4z12j6e" className={classes.texts}>
                <div data-intrcm-id="SceneHeader_sn8bgz0q" className={classes.title}>{profile.tag}</div>
                <div data-intrcm-id="SceneHeader_pxr8u2kk" className={classes.time}>{time}</div>
            </div>
            <div data-intrcm-id="SceneHeader_acof77fx" className={classes.more}>
                <Close className={classes.closeIcon} onClick={onClose}/>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
    cloudfront: state.globalData.cloudfront
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(SceneHeader)