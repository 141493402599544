import React from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { hideConfirm } from '../../../actions/appActions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import Spinner from '../Common/spinner'
import { Fragment } from 'react';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}



class ConfirmCloseModal extends React.Component {
    state = {
        // open: false,
    };

    handleConfirm = () => {
        const { dispatch, confirmDialog } = this.props;

        if (typeof confirmDialog.confirmAction === "function") {
            confirmDialog.confirmAction()
        } else {
            dispatch(confirmDialog.confirmAction);
        }
        if (confirmDialog.closeImmediately) {
            dispatch(hideConfirm());
        }
    };

    handleClose = () => {
        if (typeof this.props.confirmDialog.declineAction === "function") this.props.confirmDialog.declineAction()
        this.props.dispatch(hideConfirm());
    };

    render() {
        const { confirmDialog, t } = this.props;

        if(!this.props.confirmDialog.open){
            return null;
        }


        return (

            <Dialog
                open={this.props.confirmDialog.open}
                TransitionComponent={Transition}
                keepMounted
                dir="auto"
                style={{ zIndex: 1301 }}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {confirmDialog?.isGanttReload ? t("Refresh required") : t("Are you sure?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: '#21212d' }} id="alert-dialog-slide-description">
                        {t(confirmDialog.message)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {confirmDialog.onlyClose ?
                        <Button onClick={this.handleClose} color="primary" disabled={confirmDialog.confirmLoading}>
                            {t("Ok")}
                        </Button> : <Fragment>
                            <Button onClick={this.handleClose} color="primary" disabled={confirmDialog.confirmLoading}>
                                {t("Cancel")}
                            </Button>
                            <Button onClick={this.handleConfirm} color="primary" disabled={confirmDialog.confirmLoading}>
                                {confirmDialog?.isGanttReload ? t("Refresh") : t("Ok")}
                            </Button>
                        </Fragment>
                    }

                </DialogActions>
            </Dialog>
        );
    }
}




const mapStateToProps = (state) => ({
    confirmDialog: state.confirmDialog,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(
    withTranslation('confirmCloseDialog'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ConfirmCloseModal);