import min from 'date-fns/min'
import max from 'date-fns/max'
import isValid from 'date-fns/isValid'

export const getStartEndDateForProject = (tasks, projectId) => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
  
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  };


  export const sortByGanttOrder  = (projects,tasks) => {
   const result = projects.map((p, idx) => {
      // custom object for adding feature
      const addTaskObject = {
         // required gantt lib fields
          id: `addingTask${idx}`,
          name: '',
          start: new Date(),
          end: new Date(),
          progress: 0,
          type: 'task',
          //
        project: String(p.id),
        styles: {backgroundColor: 'transparent', backgroundSelectedColor: 'transparent', progressColor: 'transparent', progressSelectedColor: 'transparent'},
        isDisabled: true,
      }

      const tasksRelatedToProject = tasks.filter(t => t.project === p.id)

      // set project progress 
      
      const projectTotalTaskCounter = tasksRelatedToProject.reduce((total, amount) => total + amount.taskCounter,0)
      const projectTotalTaskCounterDone = tasksRelatedToProject.reduce((total, amount) => total + amount.taskDoneCounter,0)
      const currentProjectProgress =  projectTotalTaskCounterDone * 100 / projectTotalTaskCounter
      p.progress =  currentProjectProgress 
      
      //
      tasksRelatedToProject.push(addTaskObject)

      const startDates = tasksRelatedToProject.map(f => f.start)
      const minDate = min(startDates)
      const endDates = tasksRelatedToProject.map(f => f.end)
      const maxDate = max(endDates)

      if (isValid(minDate)) {
        p.start = minDate;
      }
      if (isValid(maxDate)) {
        p.end = maxDate
      }
   
      return [].concat(p).concat(tasksRelatedToProject)
    }).flat()
    return result
  }

  export const GanttObjectsType = Object.freeze(
    // gantt lib types
    {
    "project": 'project', 
    "task": 'task', 
    "milestone": 'milestone',
    // adding task is custom type
    'addingTask': 'addingTask'
  })