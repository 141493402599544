import { FULL_RESET_STORE } from "../actionTypes/actionTypes";
import { ADD_RECENT_REQUEST, INIT_LANDING_USERS, INIT_SEARCH_FAVOURITES_GROUPS, INIT_SEARCH_MANAGED_GROUPS, INIT_SEARCH_SUBSCRIBED_GROUPS, INIT_SEARCH_SUBSCRIBED_USERS, MARK_STARRED_GROUP, UNMARK_STARRED_GROUP, UPDATE_RECENT_REQUEST_TIMESTAMP } from "../actionTypes/searchNavigationTypes";
import { sortGroupsByTitle } from "../utils/groupsNavigation/collectionToGroupNavigation";
import { mapLandingUsers, mapSearchGroups, mapSubscribedUsers, sortRecentsRequestsByTimestamp } from "../utils/searchNavigation/searchHelpers";

const initialState = {
    recentRequests: [],//{query, timestamp}
    landingUsers: [],
    subscribedUsers: [],
    starredGroups: [],
    managedGroups: [],
    subscribedGroups: []
}


const searchsNavigation = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState
        case INIT_LANDING_USERS: {
            const {users = []} = action;
            const landingUsers = mapLandingUsers(users, users.length);
            return {
                ...state,
                landingUsers
            }
        }
        case INIT_SEARCH_SUBSCRIBED_USERS: {
            const {users = [], tags = []} = action;
            const subscribedUsers = mapSubscribedUsers(tags, users);
            return {
                ...state,
                subscribedUsers
            }
        }
        case INIT_SEARCH_MANAGED_GROUPS:
            return {...state, managedGroups: mapSearchGroups(action.groups, {
                starred: state.starredGroups,
                withSlice: false,       
            })}
        case 'ADD_SEARCH_MANAGED_GROUP':
            return {...state, managedGroups: mapSearchGroups([...state.managedGroups, action.group], {
                starred: state.starredGroups,
                withSlice: false,       
            })}
        case INIT_SEARCH_FAVOURITES_GROUPS:
            return {...state, starredGroups: mapSearchGroups(action.groups, {
                withSlice: false,       
            })}
        case INIT_SEARCH_SUBSCRIBED_GROUPS:
            return {...state, subscribedGroups: mapSearchGroups(action.groups, {
                starred: state.starredGroups,
                withSlice: false
            })}
        case MARK_STARRED_GROUP: {
                const {collectionUid} = action
                const allGroups = [...state.managedGroups, ...state.subscribedGroups]
                const group = allGroups.find(group => group.collectionUid === collectionUid)
                if(!group) return state
    
                return {...state, starredGroups: sortGroupsByTitle([...state.starredGroups, group])}
            }
            case UNMARK_STARRED_GROUP: {
                const {collectionUid} = action
                const starredGroups = [...state.starredGroups]
                const filteredGroups = starredGroups.filter(group => group.collectionUid !== collectionUid)
                
                return {...state, starredGroups: sortGroupsByTitle([...filteredGroups])}
            }
        case ADD_RECENT_REQUEST: {
            const {query} = action
            const prevRecents = [...state.recentRequests]
            return {...state, recentRequests: sortRecentsRequestsByTimestamp([...prevRecents, {query, timestamp: new Date().getTime()}])}
        }
        case UPDATE_RECENT_REQUEST_TIMESTAMP: {
            const {query} = action
            const prevRecents = [...state.recentRequests].map(recent => {
                if(recent.query !== query) return recent
                return {...recent, timestamp: new Date().getTime()}
            })
            
            return {...state, recentRequests: sortRecentsRequestsByTimestamp(prevRecents)}
        }
        default:
        return state
    }
}

export default searchsNavigation