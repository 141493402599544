import React, { PureComponent } from 'react';

export default class LaunchpadIcon extends PureComponent {

    render() {
        const { isFocused } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="14" fill="white" fill-opacity={ isFocused ? "0.16" : "0"}/>
            <path d="M12.1667 13.6667H9.5C8.673 13.6667 8 12.9937 8 12.1667V9.5C8 8.673 8.673 8 9.5 8H12.1667C12.9937 8 13.6667 8.673 13.6667 9.5V12.1667C13.6667 12.9937 12.9937 13.6667 12.1667 13.6667ZM18.5 13.6667H15.8333C15.0063 13.6667 14.3333 12.9937 14.3333 12.1667V9.5C14.3333 8.673 15.0063 8 15.8333 8H18.5C19.327 8 20 8.673 20 9.5V12.1667C20 12.9937 19.327 13.6667 18.5 13.6667ZM12.1667 20H9.5C8.673 20 8 19.327 8 18.5V15.8333C8 15.0063 8.673 14.3333 9.5 14.3333H12.1667C12.9937 14.3333 13.6667 15.0063 13.6667 15.8333V18.5C13.6667 19.327 12.9937 20 12.1667 20ZM18.5 20H15.8333C15.0063 20 14.3333 19.327 14.3333 18.5V15.8333C14.3333 15.0063 15.0063 14.3333 15.8333 14.3333H18.5C19.327 14.3333 20 15.0063 20 15.8333V18.5C20 19.327 19.327 20 18.5 20Z" fill="white"/>
            </svg>
        )
    }
}

