import React from 'react'
import {Button, withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import StoryScene from './StoryScene'
import { openFullview } from '@tuqqi/web'
import tuqqi_logo_horizontal from '../../../../assets/img/tuqqi_logo_horizontal.png'
import { addRecentItem } from '../../../../actions/searchNavigationActions'

const styles = (theme) => ({
    container: {
        marginRight: 0,
        marginLeft: 'auto',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    seeItemButton: {
        backgroundColor: 'red',
        width: 253,
        height: 52,
        display: 'flex',
        alignItems: 'center',
        padding: 18,
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        borderRadius: 4,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.25)',
        backgroundImage: 'linear-gradient(72deg, #bb7bfd -5%, #8645f9 100%)',
        position: 'absolute',
        left: '50%',
        marginLeft: -125,
        bottom: 10,
        textTransform: 'capitalize'
    },
    logo : {
        marginRight: 12
    },
})

const StoriesContentView = (props) => {
    const {classes, width, dispatch, storiesNavigation} = props

    const onClickHandler = () => {
        dispatch(openFullview(storiesNavigation.currentScene.docId, false))
        dispatch(addRecentItem(storiesNavigation.currentScene.docId))
    }
    
    return <div data-intrcm-id="StoriesContentView_12ygdoiu" style={{width, transition: 'width .2s ease-in-out'}} className={classes.container}>
        <StoryScene/>
        <Button className={classes.seeItemButton} onClick={onClickHandler}><img src={tuqqi_logo_horizontal} className={classes.logo}/>See the Item</Button>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoriesContentView)