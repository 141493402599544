import { Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';


const styles = theme => ({
    container: {
        bottom: 0,
        overflowY: 'auto',
        width: theme.constants.navigationDrawerWidth,
        top: theme.constants.defaultToolbarHeight - 1,
        backgroundColor: '#ffffff',
        border: '0.5px solid #daddee',
        zIndex: 1099,
        transition: theme.transitions.create('width'),
    },
});



class DrawerContainer extends PureComponent {

    render() {
        const { classes, children, containerStyle, PaperProps = null } = this.props;
        const paperStyles = [classes.container, containerStyle]
        
        return (
            <>  
                <Drawer
                    variant="persistent"
                    open={true}
                    PaperProps={PaperProps}
                    classes={{
                        paper: paperStyles.join(" ")
                    }}>
                    {children}

                </Drawer>
            </>
        )
    }
}



export default compose(withStyles(styles))(DrawerContainer);