import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PersonIcon from '@material-ui/icons/Person'
import WidgetSingleImgOption from '../../../Common/WidgetOptions/WidgetSingleImgOption';
import { mapLandingUsers } from '../../../../../utils/searchNavigation/searchHelpers';
import {applySearchQuery} from '../../../../views/search/searchUtils';
import { withMixpanel } from '../../../../components/Common/withMixpanel';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto',
        display: 'flex',
        flexWrap: 'wrap',
    },
    option: {
        marginLeft: 8,
        marginBottom: 8,
    }
});

const MAX_USERS = 5

const filterShortcuts = (shortcuts = []) => shortcuts.filter(s => s.type === 'user')
const getUsersByTags = (users = [], tags = []) => users.filter(u => tags.includes(u.tag))
const usersToOptions = (users = []) => users.map(u => ({
    selected: false,
    key: u.auth0_id,
    label: u.firstname,
    icon: u.avatar,
    isImage: true
}))
const RelatedUsersWidget = (props) => {
    const { classes, t, queryService, query, experts, activeUsers, searchQuery, dispatch, mixpanelTrack } = props;
    const [options, setOptions] = useState([])
    const disableUpdateRef = useRef(false)

    const onOptionClick = (key) => {
        mixpanelTrack(`Related user widget`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            o.selected = false
            return o
        })
        setOptions(newOptions)
        const selected = newOptions.filter(o => o.selected).map(o => o.key)
        const newQuery = {...searchQuery, users: selected}

        disableUpdateRef.current = selected.length > 0
        applySearchQuery(newQuery, dispatch)

    }

    useEffect(() => {
        if(disableUpdateRef.current) return

        queryService.searchShortcuts(query).then(relatedItems => {
            const filtered = filterShortcuts(relatedItems)
            const tags = [...new Set([...filtered.map(s => s.tag), ...(experts?.map(e => e.Key) ?? []) ])]
            const users = mapLandingUsers(getUsersByTags(activeUsers, tags) ?? [], MAX_USERS)
            const options = usersToOptions(users)
            setOptions(options)
        })
    }, [query, experts, activeUsers])

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="RelatedUsersWidget_rdj2er4z" className={classes.containerInner}>
                <div data-intrcm-id="RelatedUsersWidget_nfxt14nj" className={classes.titleSection}>
                        <PersonIcon className={classes.titleIcon} />
                        <div data-intrcm-id="RelatedUsersWidget_96uafgfn" className={classes.title}>{t("Users")}</div>
                </div>
                <div data-intrcm-id="RelatedUsersWidget_kyzpol5z" className={classes.options}>
                    {options.map(o => (
                        <WidgetSingleImgOption optionStyle={classes.option} key={o.key} option={o} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService,
    query: state.search.query,
    searchQuery: state.search,
    activeUsers: state.globalData.activeUsers,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})


export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps),
)(RelatedUsersWidget);