import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "recompose";
import withErrorCatcher from "../../components/Common/withErrorCatcher";
import { UserPageLeftDrawerNew } from '../search/Components/UserPageLeftDrawerNew';

const UserPageView = (props) => {
  const { children, location, dispatch, myProfile } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)

  const {tag} = myProfile
  const query = location.query.searchquery;
  const paramsQuery = location.pathname;
  const u = new URL(window.location.href ?? window.location.pathname)
  const userTagParam = u.searchParams.get("searchquery")
  const handleSwitchDrawerStatus = (status) => {
    setIsDrawerOpen(status)
  }

  useEffect(() => {
      const userTag = (userTagParam ?? '').startsWith('@') ? userTagParam.slice(1) : userTagParam
      if(userTag !== tag) return

      dispatch(push({ pathname: '/mypage' }))
  }, [userTagParam])
  return (
    <Fragment>
      <UserPageLeftDrawerNew handleSwitchDrawerStatus={handleSwitchDrawerStatus} isDrawerOpen={isDrawerOpen} query={query} paramsQuery={paramsQuery} />
        {React.cloneElement(children, {isDrawerOpen})}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myProfile: state.userData.profileData
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withErrorCatcher(),  
  connect(mapStateToProps, mapDispatchToProps)
)(UserPageView);
