import { parseImageUrl, randomID } from '@tuqqi/common'
import { onGoToChatStore, openFullview, pushActiveFullview, pushActiveFullviewWhenSingleInput, onGoToItemNotifStore, openMultipleFullview } from '@tuqqi/web';

import React, { useEffect, useMemo, useRef } from 'react'
import { push } from 'react-router-redux'
import { getNotificationAsParsedText } from '../../utils/notificationsHelpers';
import { isSupportedNotifications } from '../../utils/OSutils';
import { mapChatCommentToNotification } from './mapChatCommentToNotification';
import { addRecentItem } from '../../actions/searchNavigationActions';

const getMessageText = (messageText, t, attachments) => {
    if(!messageText && !attachments?.length) return t('No description')
    if(!messageText && !!attachments?.length && attachments.length === 1) return `1 ${t('file attached')}`
    if(!messageText && !!attachments?.length && attachments.length > 1) return `${attachments.length} ${t('files attached')}`
    if(messageText.length <= 60) return messageText
    return `${messageText.slice(0, 60)}...`
}

const showDesktopChatNotification = async (data, {t, parseUrl, isPrivate, type, onClick, addInAppNotifications}) => {
    try {
        if(!isSupportedNotifications()) {
            addInAppNotifications?.(data)
            return
        }
        if(!Notification) {
            addInAppNotifications?.(data)
            return
        }

        const permission = await Notification.requestPermission()
        if(!permission || permission !== 'granted') {
            addInAppNotifications?.(data)
            return
        }

        const title = isPrivate ? `${data.sender.firstname} ${data.sender.lastname}` : data.docTitle || t('Untitled')
        const body = isPrivate ? getMessageText(data.messageText, t, data?.attachments ?? []) : getMessageText(data.text, t, data?.attachments ?? [])
        let icon = isPrivate ? parseUrl(data?.sender?.profileImageUrl ?? '') : '/Tuqqi_logo_white_margin.png'

        if(!isPrivate && data.userPicUrl) {
            icon = parseUrl(data.userPicUrl)
        }

        var n = new Notification(title, {
            body,
            silent: true,
            icon,
        })

        n.addEventListener('click', () => {
            window.focus()
            n.close()
            onClick(data, type)
        })
        let sound = new Audio();
        sound.src = '/sounds/soundChat.mp3';
        sound.load();
        sound.play().catch(err => {});
    } catch (err) {
        addInAppNotifications?.(data)
    }
}

const showDesktopNotification = async (data, {t, type, onClick, markAsReadNotification, addInAppNotifications, addDesktopNotification}) => {
    try {
        if(!isSupportedNotifications()) {
            addInAppNotifications?.(data)
            return
        }
        if(!Notification) {
            addInAppNotifications?.(data)
            return
        }

        const permission = await Notification.requestPermission()
        if(!permission || permission !== 'granted') {
            addInAppNotifications?.(data)
            return
        }

        const title = !!data.userThatMadeTheAction ? `${data.userThatMadeTheAction.firstname} ${data.userThatMadeTheAction.lastname}` : t('Unknown')
        const body = getNotificationAsParsedText(data.messageTemplateParams, data.topic, data.icon, title, t)
        const icon = '/Tuqqi_logo_white_margin.png'

        var n = new Notification(title, {
            body,
            silent: true,
            icon,
        })

        addDesktopNotification()

        n.addEventListener('click', () => {
            window.focus()
            n.close()
            markAsReadNotification(data.id)
            onClick(data.docId, type)
        })
        let sound = new Audio();
        sound.src = '/sounds/soundItem.mp3';
        sound.load();
        sound.play().catch(err => {});
    } catch (err) {
        addInAppNotifications?.(data)
    }
}


export const useDesktopNotifications = (additionalData) => {
    const {t, cloudfront, type, userId, receivedRef, dispatch, fullviewState, inputState, addInAppNotifications, notificationService} = additionalData

    const cloudfrontRef = useRef(cloudfront)
    const fullviewStateRef = useRef(fullviewState)
    const inputStateRef = useRef(inputState)

    useEffect(() => {
        cloudfrontRef.current = cloudfront
    }, [cloudfront])

    useEffect(() => {
        fullviewStateRef.current = fullviewState
    }, [fullviewState])

    useEffect(() => {
        inputStateRef.current = inputState
    }, [inputState])

    const requestNotification = () => {
        try {
            if(!isSupportedNotifications()) return
            if(!Notification) return
            Notification.requestPermission(permission => {
                if(permission === 'denied') {
                    return
                }
            }).catch(_ => {})
        } catch (err) { }
    }

    const parseUrl = useMemo(() => {
        if (cloudfrontRef.current?.data?.signedFiles) {
            return parseImageUrl(cloudfrontRef.current.data);
        } else {
            return () => ''
        }
    }, [])

    const markAsReadNotification = (notificationId) => {
        if(!notificationService) return

        notificationService.sawNotification(notificationId, 'web')

        dispatch({
            type: 'USER_SEEN_NOTIFICATION',
            id: notificationId,
            seenOnDateTime: new Date().toISOString()
        })
    }


    const onClickItem = (docId) => {
        if(!inputStateRef.current || !fullviewStateRef.current) return

        dispatch(onGoToItemNotifStore())
        if(inputStateRef.current.isOpen && !inputStateRef.current.fullviewMode) {
            const inputData = inputStateRef.current?.data
            dispatch(pushActiveFullviewWhenSingleInput(inputData, randomID(), docId))
            return
        }
        if(fullviewStateRef.current.mode === 'multiple') {
            dispatch(pushActiveFullview({
                active: true,
                docId,
                isSideMenuOpen: false,
                isEditing: false,
                isCommenting: false
            }))
            return
        }
        if(!!fullviewStateRef.current.open) {
            dispatch(openMultipleFullview({main: {
                active: false,
                mainDocId: fullviewStateRef.current.mainDocId,
                isSideMenuOpen: false
            }, fullviewList: [
                {
                active: true,
                docId,
                isSideMenuOpen: false,
                isEditing: false,
                isCommenting: false
            }]}))
            return
        }
        dispatch(openFullview(docId, false))
        dispatch(addRecentItem(docId))
    }

    const onClickChatMessage = (comment) => {
        const isPrivate = !!comment.chatUId && !!comment.sender
        const chatUid = isPrivate ? comment.chatUId : comment.docId

        dispatch(onGoToChatStore())
        if(!chatUid) {
            dispatch(push({ pathname: "chat" }))
            return
        }
    
        dispatch(push(`/chat?chatid=${chatUid}`))
    }

    const onClick = (data, type) => {
        if(type === 'notification') {
            onClickItem(data)
            return
        }

        onClickChatMessage(data)
    }

    const addDesktopNotification = (notif) => {
        dispatch({
            type: 'PUSH_DESKTOP_NOTIFICATION',
            notif
        })
    }

    const executeChatNotifications = (comment) => {
        const isPrivate = !!comment.chatUId && !!comment.sender
        const id = isPrivate ? comment.id : comment.key

        if(!isPrivate && comment.userId === userId ) return false
        if(!isPrivate && comment.key === comment.docId) return false
        if(!!isPrivate && comment?.sender?.auth0_id === userId ) return false
        if(receivedRef.current.includes(id)) return false

        showDesktopChatNotification(comment, {t, parseUrl, isPrivate, type, onClick, addInAppNotifications: () => addInAppNotifications?.(mapChatCommentToNotification(comment))})
        receivedRef.current.push(id)

        return true
    }

    const executeNotification = (notif) => {
        const userIdThatMade = notif?.userThatMadeTheAction?.auth0_id
        const id = notif.id

        if(userIdThatMade === userId ) return false
        if(!notif?.docId) return false
        if(!!notif?.isChatNotification) return false
        if(receivedRef.current.includes(id)) return false

        showDesktopNotification(notif, {t, type, onClick, markAsReadNotification, addDesktopNotification: () => addDesktopNotification(notif), addInAppNotifications: () => addInAppNotifications?.(notif)})
        receivedRef.current.push(id)

        return true
    }

    const executer = (data) => {
        if(type === 'chat') {
            return executeChatNotifications(data)
        }
        if(type === 'notification') {
            return executeNotification(data)
        }
    }

    return [requestNotification, executer]
}