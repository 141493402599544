import React, { useEffect, useRef, useState } from 'react'
import { getNavigationOptions } from '../../../../../utils/drawerNavigation/drawerNavigationHelpers';
import { DrawerNavigation } from '../../DrawerNavigation/DrawerNavigation';

export const useDrawerNavigation = (options) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSubmenu, setShowSubmenu] = useState(false);
    const submenuOptRef = useRef(-1)
    const { pxSwitcher = false, settings, isChannel, drawerNavigation, paramsQuery, isDrawerOpen, currentTags = [], query, selectChannelHandler,t, additionalOptions = [] ,profileData} = options;
    const { mode, selectedId, submenu } = drawerNavigation;
    const { submenu: { listOptions, option: submenuOption } } = drawerNavigation

    useEffect(() => {
        setShowSubmenu(!!(anchorEl && submenuOptRef.current === submenuOption))
    }, [listOptions, submenuOption, anchorEl])

    const handleOptionClick = (option) => {
        submenuOptRef.current = option.id
        option.onClick()
    }

    const handleClick = (event, option) => {
        handleOptionClick(option)
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    };

    const handleClose = () => {
        submenuOptRef.current = -1
        setAnchorEl(null);
    };



    const navOptions = isChannel ? additionalOptions : getNavigationOptions(mode, t, additionalOptions, settings)

    if(pxSwitcher && mode === 'group') {
        const navs = navOptions.reduce((accum, cur) => {
            if(cur.key === 'home' || cur.key === 'chat') return [[...accum[0], cur], accum[1], accum[2]]
            if(cur.key === 'settings' || cur.key === 'connectors') return [accum[0], accum[1], [...accum[2], cur]]
            return [accum[0], [...accum[1], cur], accum[2]]
        }, [[], [], []])


        return navs.map((nav, i) => (
        <DrawerNavigation
            key={i}
            topLabel={i === 1 ? t("Views") : i === 2 ? t("Admin") : undefined}
            submenu={submenu}
            query={query}
            pxSwitcher={pxSwitcher}
            isDrawerOpen={isDrawerOpen}
            paramsQuery={paramsQuery}
            selectedOptionId={selectedId}
            isChannel={isChannel}
            options={nav}
            anchorEl={anchorEl}
            currentTags={currentTags}
            showSubmenu={showSubmenu}
            profileData={profileData}
            handleClick={handleClick}
            handleClose={handleClose}
            handleOptionClick={handleOptionClick}
            selectChannelHandler={selectChannelHandler}
        />
        ))
    }
    const Navigation = <DrawerNavigation
        submenu={submenu}
        query={query}
        isDrawerOpen={isDrawerOpen}
        paramsQuery={paramsQuery}
        selectedOptionId={selectedId}
        isChannel={isChannel}
        options={navOptions}
        anchorEl={anchorEl}
        currentTags={currentTags}
        showSubmenu={showSubmenu}
        profileData={profileData}
        handleClick={handleClick}
        handleClose={handleClose}
        handleOptionClick={handleOptionClick}
        selectChannelHandler={selectChannelHandler}
    />
    return [Navigation]
}