import { SHOW_UPGRADE_FROM_FREE, HIDE_UPGRADE_FROM_FREE, FULL_RESET_STORE } from '../actionTypes/actionTypes'
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = []
const upgradeFromFreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case SHOW_UPGRADE_FROM_FREE:
      return {
        ...state,
        isOpen: true,       
      }
    case HIDE_UPGRADE_FROM_FREE:
    case ON_GO_TO_CHAT:
    case ON_GO_TO_ITEM_NOTIF:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}

export default upgradeFromFreeReducer