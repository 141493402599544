import { fetchCustomInputMappings } from '@tuqqi/common'
import { CONFIG } from '@tuqqi/web/dist/config/config'
import { showSnackbar } from '../../../../actions/appActions'
import { globalDataLoad } from '../../../../actions/globalDataAction'

// WORKFLOW //
export const handleChangeWorkflow = () => {
    return {
        isEnableFiles: false,
        isEnableTags: false,
        hasCoverPicture: true,
        isEnableLinks: false,
        isEnableContact: true,
        isEnableCompany: true,
        isEnableLocation: true,
        enablePublishingToPublicFeed: true,
        isEveryoneCanEdit: true,
    }
}

export const handleChangeAboveWorkflow = () => {
    return {
        isEnableFiles: false,
        isEnableTags: false,
        hasCoverPicture: false,
        isEnableLinks: false,
        isEnableContact: false,
        isEnableCompany: false,
        isEnableLocation: false,
        enablePublishingToPublicFeed: false,
        isEveryoneCanEdit: true,
    }
}

////////// Container //////////

export let onCloseNewContainer = function (newContainer) {
    if (newContainer.sections) {
        let index = newContainer.containerIdx
        let containers = [...this.state.containers];

        if (index != null) {
            delete newContainer["containerIndex"];
            containers[index] = newContainer
        }
        else {
            containers.push(newContainer);
        }

        this.setState({ openNewContainer: false, containers: [...containers], container: null, containerIdx: null })
    }
    else {
        this.setState({ openNewContainer: false, container: null, containerIdx: null })
    }
}

export let editContainer = function (containerIdx, container) {
    this.setState({ containerIdx, container, openNewContainer: true })
}

export let onDeleteContainer = function (containerIdx) {
    if (containerIdx != null) {
        let containers = [...this.state.containers];

        containers.splice(containerIdx, 1);

        this.setState({ openNewContainer: false, containers: [...containers], container: null, containerIdx: null })
    }
    else {
        this.setState({ openNewContainer: false, container: null, containerIdx: null })
    }
}

////////// Field //////////

export let onCloseNewField = function (newField) {
    if (newField.field) {
        const { containerIndex, sectionIndex, field, isNew, style, titleStyle } = newField;

        let containers = [...this.state.containers];
        let customFieldsMappings = [...this.state.customFieldsMappings];



        if (isNew) {
            customFieldsMappings.push(field);
        }
        else {
            let oldFieldIndex = customFieldsMappings.findIndex(oldField => oldField.sourceField === field.sourceField);
            customFieldsMappings[oldFieldIndex] = newField.field;
        }

        this.setState({ customFieldsMappings: [...customFieldsMappings], openNewField: false, field: null })
    }
    else {
        this.setState({ openNewField: false, field: null })
    }
}

export let editField = function (field, containerIdx, sectionIdx) {
    this.setState({ field, openNewField: true, containerIdx, sectionIdx })
}

export let onDeleteField = function (containerIndex, sectionIndex, sourceField) {
    if (sourceField) {
        // let containers = [...this.state.containers];
        let customFieldsMappings = [...this.state.customFieldsMappings];

        // let fieldIndex = containers[containerIndex].sections[sectionIndex].fields.findIndex(field => field.sourceField === sourceField)
        // containers[containerIndex].sections[sectionIndex].fields.splice(fieldIndex, 1);

        let oldFieldIndex = customFieldsMappings.findIndex(oldField => oldField.sourceField === sourceField);
        customFieldsMappings.splice(oldFieldIndex, 1);

        this.setState({ customFieldsMappings: [...customFieldsMappings], openNewField: false, field: null })
    }
    else {
        this.setState({ openNewField: false, field: null })
    }
}

////////// Section //////////

export let editSection = function (sectionIdx, section, containerIdx) {
    this.setState({ sectionIdx, section, openEditSection: true, containerIdx })
}

export let onCloseEditSection = function (newSection) {
    if (newSection.containerIdx != null && newSection.sectionIdx != null) {
        let containers = [...this.state.containers];
        let containerIdx = newSection.containerIdx;
        let sectionIdx = newSection.sectionIdx;

        containers[containerIdx].sections[sectionIdx] = newSection.section;

        this.setState({ sectionIdx: null, section: null, openEditSection: false, containerIdx: null, containers: [...containers] })
    }

    this.setState({ sectionIdx: null, section: null, openEditSection: false, containerIdx: null })
}

export let onDeleteSection = function (containerIdx, sectionIdx) {
    if (containerIdx != null && sectionIdx != null) {
        let containers = [...this.state.containers];

        let gridSize = containers[containerIdx].sections[sectionIdx].gridSize;

        containers[containerIdx].sections.splice(sectionIdx, 1);

        let lastSection = containers[containerIdx].sections.length - 1;

        if (lastSection >= 0) {
            containers[containerIdx].sections[lastSection].gridSize += gridSize;
        }

        this.setState({ sectionIdx: null, section: null, openEditSection: false, containerIdx: null, containers: [...containers] })
    }

    this.setState({ sectionIdx: null, section: null, openEditSection: false, containerIdx: null })
}

////////// General //////////

export let saveMapping = function () {
    const { globalData, customInputService, goTo, dispatch, mappings } = this.props;

    const { updateCustomInputMapping, createCustomInputMapping } = customInputService

    const { title, dataTypeFilter, description, icon, backgroundUrl,
        enableSettings, quickActionText, triggeredCustomInput,
        customFieldsMappings, containers, checklists, defaultCollectionUid, specificGroupsUids } = this.state

    let { numbersAggsCounter, datesAggsCounter, locationsAggsCounter, stringsAggsCounter, checksAggsCounter } = mappings.customDataMapping    




    customFieldsMappings.map(field => {
        if (!field.aggregateField && field.toAggregate) {
            let aggregateField;

            switch (field.type) {
                case 'date':
                case 'dateTime': aggregateField = 'dateTime' + datesAggsCounter; datesAggsCounter++; break;
                case 'number': aggregateField = 'number' + numbersAggsCounter; numbersAggsCounter++; break;
                case 'location': aggregateField = 'location' + locationsAggsCounter; locationsAggsCounter++; break;
                case 'checkbox': aggregateField = 'check' + checksAggsCounter; checksAggsCounter++; break;
                default: aggregateField = 'string' + stringsAggsCounter; stringsAggsCounter++; break;
            }

            field.aggregateField = aggregateField;

            return field;
        }
    })
    // const structureJson = !!Object.keys(checklists).length ? JSON.stringify({checklists}) : JSON.stringify(containers)

    const customInputTemplate = {
        defaultCollectionUid,
        specificGroupsUids,
        title,
        description,
        icon,
        backgroundUrl,
        ...enableSettings,
        quickActionText,
        triggeredCustomInput,
        checklists : JSON.stringify({checklists}),
        customDataMapping: {
            id: mappings.customDataMapping.id,
            dataTypeFilter,
            customFieldsMappings: customFieldsMappings.filter(field => field.fieldName && field.sourceField && field.type),
            // structureJson,
            
            datesAggsCounter,
            numbersAggsCounter,
            locationsAggsCounter,
            stringsAggsCounter,
            checksAggsCounter
        },
    }

    let saveFunction;
    if (this.state.isNewTemplate) {
        customInputTemplate.customDataMapping.dataTypeFilter = dataTypeFilter ? dataTypeFilter : 'data_type_' + (new Date()).getTime();
        saveFunction = createCustomInputMapping
    } else {
        customInputTemplate.id = this.state.id
        saveFunction = updateCustomInputMapping
    }


    // Save mapping
    dispatch(showSnackbar('Saving...'));

    saveFunction(customInputTemplate).then((res) => {
        if (res.ok) {
            res.json().then(template => {
                let newMappings = globalData.inputMappings.filter(mapping => mapping.id != template.id)
                newMappings.push(template)
                newMappings = newMappings.map(mapping => ({ ...mapping }))
                dispatch({ type: 'CUSTOM_INPUT_EDIT_MAPPING', currentMapping: customInputTemplate })
                dispatch(globalDataLoad({
                    inputMappings: newMappings,
                }));
                dispatch(fetchCustomInputMappings(CONFIG))
                dispatch(showSnackbar('Saved!'));
                goTo({ pathname: '/admin/custominputmappings' })
            })
        }

        // goTo({ pathname: '/admin/custominputmappings' })
    })
}

export let onCloseEditAllFieldStyles = function (style) {
    let containers = this.state.containers;

    if (style.contentStyle && style.titleStyle) {
        containers = containers.map(container => {
            if (container.sections) {
                container.sections.map(section => {
                    if (section.fields) {
                        section.fields.map(field => {
                            field.style = style.contentStyle;
                            field.titleStyle = style.titleStyle;

                            return field;
                        })
                    }

                    return section;
                })
            }

            return container;
        })

        this.setState({ containers, openEditAllFieldStyles: false })
    }
    else {
        this.setState({ openEditAllFieldStyles: false })
    }
}

export const DataTypeFieldInputTypeValue = 'Datatype Field'
export const DataTypeUniqueFieldInputTypeValue = 'Linked datatype (unique)'

export const TitleFileInputTypeValue = 'Title'

export let inputTypes = [
    { key: 'paragraph', value: 'Paragraph text' },
    { key: 'text', value: 'One line text' },
    { key: 'datatype', disabled: false, value: DataTypeFieldInputTypeValue },
    { key: 'datatypeUnique', value: DataTypeUniqueFieldInputTypeValue },
    { key: 'file', value: 'File' },
    { key: 'select', value: 'Simple select', optionalHelperText: 'Insert text splited by ";" to be the option in the select' },
    { key: 'multiSelect', value: 'Multichoice select', optionalHelperText: 'Insert text splited by ";" to be the option in the select' },
    { key: 'number', value: 'Number', unitHelpText: 'Insert the unit of the value' },
    { key: 'title', value: TitleFileInputTypeValue },
    { key: 'date', value: 'Date' },
    { key: 'dateTime', value: 'Date & time' },
    { key: 'barcode', value: 'Barcode' },
    { key: 'barChart', value: 'Bar chart', disabled: true },
    { key: 'lineChart', value: 'Line chart', disabled: true },
    { key: 'pieChart', value: 'Pie chart', disabled: true },
    { key: 'table', value: 'Table', disabled: true },
    { key: 'businessId', value: 'Business Id' },
    { key: 'signature', value: 'Signature'},
    { key: 'calculation', value: 'Calculation'},
    { key: 'article', value: 'Article'},
    { key: 'phone', value: 'Phone'},
    { key: 'email', value: 'Email'}
]

export const defaultValuesInputTypes = {
    'paragraph': '',
    'text': '',
    // 'datatype': '',
    'select': '',
    'multiSelect': [],
    'number': 0,
    'title': '',
    'date': '',
    'dateTime': '',
    'barcode': '',
    // 'barChart': '',
    // 'lineChart': '',
    // 'pieChart': '',
    // 'table': '',
    'businessId': '',
    'article': '',
    'signature': '',
    'calculation': '',
    'phone': '',
    'email': ''
}

export let icons = ['headset_mic', 'help_outline', 'home',
    'group_add', 'perm_phone_msg', 'perm_contact_calendar', 'contact_mail',
    'mail',
    'radio', 'multiline_chart',
    'account_circle', 'card_travel',
    'account_balance', 'gavel',
    'bug_report', 'code', 'devices',
    'important_devices', 'desktop_mac',
    'android', 'phonelink_setup',
    'done', 'description', 'list_alt',
    'face', 'supervisor_account',
    'vpn_key', 'monetization_on',
    'touch_app', 'compare_arrows',
    'format_paint', 'wallpaper', 'color_lens',
    'vpn_lock', 'screen_lock_portrait',
    'public', 'star_border', 'textsms'
];

export let alignOptions = ['center', 'inherit', 'left', 'right', 'justify'];

export let colorOptions = ['default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'];

export let variantOptions = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit', "display4", 'display3', 'display2', 'display1', 'headline', 'title', 'subheading'];


export let alignContentOptions = ['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around'];

export let alignItemsOptions = ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'];

export let directionOptions = ['row', 'row-reverse', 'column', 'column-reverse'];

export let justifyOptions = ['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly'];

export let spacingOptions = [0, 8, 16, 24, 32, 40];

export let wrapOptions = ['nowrap', 'wrap', 'wrap-reverse'];