export default class CollectionListRulesService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    
    
        this.backendUrl = process.env.API_SERVER
        this.collectionListRulesUrl = process.env.API_SERVER + "/collectionListRules"
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    createRule(list, rule, collectionId) {
        if (rule.ruleDataType) {
            return fetch(this.collectionListRulesUrl + '/newLaneRule?collectionId=' + collectionId,
            {
                method: 'POST',
                body: JSON.stringify({
                    list,
                    rule
                }),
                headers: this.postHeaders()
            })
            .then(res => res.json());
        }
    }

    getRuleData(ruleId, listId) {
        if (ruleId && listId) {
            const params = `ruleId=${encodeURIComponent(ruleId)}&listId=${encodeURIComponent(listId)}`;

            return fetch(`${this.collectionListRulesUrl}/getRuleData?${params}`,
            {
                method: 'GET',
                headers: this.getHeader()
            });
        }
    }

    deleteRule(listId, ruleId, collectionId){
        if (listId && ruleId) {
            return fetch(`${this.backendUrl}/collectionListRules/delete?listId=${listId}&ruleId=${ruleId}&collectionId=${collectionId}`, {
                method: 'DELETE',
                headers: this.postHeaders()
            });
        }
    }
}