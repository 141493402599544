export const getMenuItemType = (type) => ({
    0: 'Category',
    1: 'Group',
    2: 'Item',
    3: 'Internal Link',
    10: 'External Link',
}[type])

export const getMenuItemTypeValByLabel = (label) => {
    switch (label) {
        case 'Category':
            return 0
        case 'Group':
            return 1
        case 'Item':
            return 2
        case 'Internal Link':
            return 3
        case 'External Link':
            return 10
    
        default:
            return 1
    }
}