import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = theme => ({
    title: {
        margin: '16px 0px',
        "fontFamily": "Rubik",
        "fontSize": "20px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "normal",
        "letterSpacing": "0.25px"
    }
})

class AdminSettingsTitle extends React.PureComponent {
    
    render() {
        const { classes, text,t } = this.props;

        return (
        <Typography variant="title" className={classes.title}>
            {t(text)}
        </Typography>)
    }
}

export default  compose(withStyles(styles),withTranslation('admin'))(AdminSettingsTitle);

