import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withErrorCatcher from '../withErrorCatcher.js'

import { hideNotificationSnackbar } from '../../../../actions/appActions';
import NotificationSnackBarItem from './NotificationSnackBarItem';

// Apply some reset
const styles = theme => ({
    root: {
        padding: 0,
        backgroundColor: 'white',
        top: 24,
        borderRadius: 8,
        left: 'auto',
        right: 24,
    },
    message: {
        padding: 0,
        width: '100%'
    }
});

class NotificationsSnackBar extends Component {
    handleClick = state => () => {
        this.setState({ open: true, ...state });
    };

    handleRequestClose = (event) => {
        event && event.preventDefault();
        event && event.stopPropagation();
        this.props.hideNotificationSnackbar()
    };


    render() {
        const { notificationSnackBar, classes } = this.props;
        const { notifications, notificationSnackBarOpen } = notificationSnackBar;

        return notifications.map(notification => (
            <Snackbar
                key={notification.key}
                dir='auto'
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={notificationSnackBarOpen}
                onClose={this.handleRequestClose}
                autoHideDuration={5000}
                SnackbarContentProps={{ 'aria-describedby': 'message-id', }}
                classes={{ root: classes.root }}
            >
                <SnackbarContent
                    message={<NotificationSnackBarItem notification={notification} close={this.handleRequestClose} />}
                    classes={{ root: classes.root, message: classes.message }}
                />
            </Snackbar>
        ))

    }
}

const mapStateToProps = (state) => ({
    notificationSnackBar: state.notificationSnackBar
})

const mapDispatchToProps = dispatch => {
    return {
        hideNotificationSnackbar: () => dispatch(hideNotificationSnackbar())
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(NotificationsSnackBar);