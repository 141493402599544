import { withStyles } from '@material-ui/core';
import { ImportantContent as ImportantContentWeb, NewUiWrapper } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { store } from '../../../..';

const styles = theme => ({
    wrap: {

    }
});

const ImportantContentView = (props) => {
    const callbacks = {
    }


    return <NewUiWrapper store={store} callbacks={callbacks}>
        <ImportantContentWeb />
    </NewUiWrapper>}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(ImportantContentView);
