import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, IconButton, Checkbox, Button, Icon, Grid } from '@material-ui/core';
import { StarBorder, Delete } from '@material-ui/icons';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/goToActions'

import moment from 'moment';
import urlRegex from 'url-regex';
import { isNullOrUndefined } from 'util';
import { showSnackbar } from '../../../actions/appActions'

import SplitedText from "./splitedText";
import DeleteButton from "./deleteButton";
import DateFrom from "./dateFrom";
import ImgComment from "./imgComment";

const styles = theme => ({
    timeContainer: {
        overflow: 'hidden',
        height: 'auto',
        lineHeight: 1,
        paddingTop: 2
    },
    commentTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle : {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle2 : {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.38)',
        display:'flex'
    },
    
    ...theme.comment,
})

class Task extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    delete = () => {
        const { task, collaborationService, dispatch, onDelete } = this.props;

        dispatch(showSnackbar( 'Deleting task...' ));

        collaborationService.deleteTask(task).then(() => {
            onDelete(task);
            dispatch(showSnackbar( 'Task deleted!' ));
        })
    }

    update = (e) => {
        const { task, userData, collaborationService, dispatch, onUpdate } = this.props;

        const newTask = { ...task }

        dispatch(showSnackbar( 'Update task...' ));

        newTask.isDone = !newTask.isDone
        newTask.modifierId = userData.profileData.auth0_id

        collaborationService.updateTask(newTask).then(() => {
            onUpdate(newTask);
            dispatch(showSnackbar( 'Task updated!' ));
        })
    }


    render() {
        const { classes, task, last, onTaskDelete } = this.props;
        const { created, creatorId, dueOn, isDone, modifierId, text, userDisplayName } = task;

        return (
            <Grid container item xs='12'>

                <div data-intrcm-id="task_u6bx3rif" className={classes.imgContainer}>
                <Checkbox
                  checkedIcon ={<Icon style={{ color:'white', fontSize:21 }} >check</Icon>}
                  style={{ backgroundColor: '#3154ef',color:'white', padding: 0 }}
                  className={classes.img}
                  checked={isDone}
                  
                  onChange={this.update}
                  icon ={<Icon style={{ color:'white',fontSize:21 }} >radio_button_unchecked</Icon>}
                />
                </div>
                <div data-intrcm-id="task_y2xa0wef" className={classes.containerContentTime} style={{ borderBottomStyle: last ? 'none' : 'solid' }}>
                    <div data-intrcm-id="task_aqpfqpcn" className={classes.content}>
                        <div data-intrcm-id="task_ddz3aig7" className={classes.commentTitle}>{userDisplayName}</div>
                        <div data-intrcm-id="task_4i048tdu" className={classes.commentSubTitle}>
                            <SplitedText text={'Todo: ' + text} style={{ whiteSpace: 'pre-line' }} />
                        </div>
                        <div data-intrcm-id="task_i5kjca2l" className={[classes.commentSubTitle2].join(' ')}>
                            <DateFrom dateTime={created} />
                            <DeleteButton creatorId={creatorId} onDelete={this.delete} />
                        </div>
                    </div>
                </div>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    // view: state.view,
    collaborationService: state.services.collaborationService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Task);