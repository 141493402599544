import {
    INPUT_AGGREGATIONS_CHANGE, INPUT_LINKS_CHANGE, FULLVIEW_NEW, INPUT_OPEN,
    INPUT_ADD_LINKED, INPUT_EDIT_LINK_UNIQE_DATA_TYPE, INPUT_COLLECTION_INFO_CHANGE, INPUT_CHANGE_POLL, INPUT_CHANGE
} from '../actionTypes/actionTypes'
import { mimeTypesMapping } from '../ui/components/Input/inputFullViewModal';
import { addInputRelatedDocs } from './inputRelatedDocsActions';

export function changeAggs(aggregations) {
    return { type: INPUT_AGGREGATIONS_CHANGE, aggregations }
}

export function changeLinks(links) {
    return { type: INPUT_LINKS_CHANGE, links }
}

export function changeCollectionInputInfo(collectionInfo) {
    return { type: INPUT_COLLECTION_INFO_CHANGE, collectionInputInfo: collectionInfo }
}

export function changeInputPoll(poll) {
    return { type: INPUT_CHANGE_POLL, poll }
}

const getIsPrivateForCustomInput = (input, inputMapping) => {
    const wasAlreadySetAsPrivate = input.isPrivate
    if (wasAlreadySetAsPrivate) {
        return true
    }

    const hasPermissionsSetAlready = input.permissions && input.permissions.length !== 0
    if (hasPermissionsSetAlready) {
        return input.isPrivate
    }

    if (inputMapping && !inputMapping.enablePublishingToPublicFeed) {
        return true
    }

    return input.isPrivate
}

export function openInput(dataType, userData, searchQuery, input, inputMappings) {
    const postDefaultInput = searchQuery.query || `@${userData.profileData.tag}`

    const { isPrivate, permissions } = input
    const isCustomInput = inputMappings.find(x => x.customDataMapping.dataTypeFilter === dataType)
    const mapId = isCustomInput ? isCustomInput.id : null

    const payload = {
        postText: postDefaultInput,
        dataType,
        isCustomInput: isCustomInput || false,
        isPrivate: isCustomInput ? getIsPrivateForCustomInput(input, isCustomInput) : isPrivate,
        permissions,
    }

    if (mapId) {
        payload.mapId = mapId
    }

    const mimeType = mimeTypesMapping[dataType]
    if (mimeType) {
        payload.mimeType = mimeType
    }

    return [{
        type: FULLVIEW_NEW,
        item: payload
    }, {
        type: INPUT_OPEN,
        model: payload
    }]
}

export const editLocation = newData => ({
    type: INPUT_CHANGE,
    model: {
        ...newData       
    }
})


export const addLinkedToInput = (item, addToInput = true) => (dispatch, getState) => {
    if (addToInput) {
        dispatch({
            type: INPUT_ADD_LINKED,
            model: item
        })
    }

    const { relatedDocuments } = item
    const ids = relatedDocuments.map(x => x.relatedDocumentId)
    const query = {
        itemsIds: ids,
        page: 0,
        filter: {},
        tags: [],
        size: ids.length,
        AggType: 'FLAT',
    }
    getState().services.queryService.SearchByIds(query).then(results => {
        dispatch(addInputRelatedDocs(item, results.data.map(x => x.Value.Source)))
    }).catch(e => {
        console.error("Failed to fetch related documents of linked input", e)
    })
}