import {
    FULL_RESET_STORE,
    CONFIRM_DIALOG_CLOSE, CONFIRM_IS_LOADING, CONFIRM_DIALOG_OPEN, DELETE_CHECKLIST, DELETE_COMMENT, DELETE_CUSTOM_INPUT_MAPPING, DELETE_ITEM, INIT_CONFIRM_DIALOG, DELETE_LABEL, DELETE_LANE, DELETE_LANE_RULE,
    DELETE_CERTIFICATION
} from '../actionTypes/actionTypes'
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialData = {
    deleteChecklistUid: '',
    deleteCommentUid: '',
    deleteDocId: '',
    deleteMappingUid: '',
    deleteLabelId: '',
    deleteLaneid: '',
    deleteCertificationId: '',
    confirmAction: {},
    declineAction: {}
}
const initialState = [];

const confirmDialog = (state = initialState, action) => {
    switch (action.type) {
        case  FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
        case ON_GO_TO_ITEM_NOTIF:
            return {
                ...initialState
            }
        case CONFIRM_DIALOG_CLOSE:
            return {
                ...state,
                open: false,
                message: '',
                // confirmAction: null

            }
        case CONFIRM_DIALOG_OPEN:
            return {
                ...initialData,
                ...state,
                open: true,
                confirmLoading: false,
                message: action.message,
                isGanttReload: action.isGanttReload,
                closeImmediately: action.closeImmediately,
                confirmAction: action.confirmAction,
                declineAction: action.declineAction,
                onlyClose: action.onlyClose,
            }

        case INIT_CONFIRM_DIALOG:
            return {
                ...initialData,
                ...state,
            }

        case CONFIRM_IS_LOADING:
            return {
                ...state,
                confirmLoading: true
            }

        case DELETE_CHECKLIST:
        case DELETE_COMMENT:
        case DELETE_CUSTOM_INPUT_MAPPING:
        case DELETE_ITEM:
        case DELETE_LABEL:
        case DELETE_LANE:
        case DELETE_CERTIFICATION:

        case DELETE_LANE_RULE:
            return {
                ...state,
                ...initialData,
                ...action.data
            }
        default:
            return state
    }
}

export default confirmDialog