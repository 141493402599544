import React, { PureComponent } from "react";

export default class ArrowLeftIcon extends PureComponent {
  render() {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="#FFF" fill-rule="nonzero">
          <circle fill-opacity=".2" cx="28" cy="28" r="28" />
          <path d="M32.52 15.49a1.604 1.604 0 0 0-2.308 0l-10.83 11.07a1.348 1.348 0 0 0 0 1.88l10.83 11.07a1.604 1.604 0 0 0 2.307 0 1.69 1.69 0 0 0 0-2.358l-9.436-9.659 9.45-9.659a1.687 1.687 0 0 0-.014-2.344z" />
        </g>
      </svg>
    );
  }
}
