import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { statusColors } from '../ListColorModal/ListColorModal';


const styles = theme => ({
    container: {
        marginLeft: 10,
        width: 264,
        paddingLeft: 16,
        height: 56,
        borderRadius: 4,
        backgroundColor: theme.newUI.color.gray50,
        flex: '0 0 auto'
    }
})

const ListHeaderContainer = ({ classes, children, otherProps, color }) => {

    const statusColor = statusColors.find(x => x.id === color);

    let styles = {}
    if (statusColor && statusColor.color) {
        styles = {
            borderLeftColor: statusColor.color,
            borderLeftStyle: 'solid',
            borderWidth: 5
        }


    }

    return (
        <Grid {...otherProps} container wrap='nowrap' style={styles} className={classes.container} justify='space-between' alignItems='center'>
            {children}
        </Grid>
    )
}

export default withStyles(styles)(ListHeaderContainer);