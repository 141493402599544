import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import User from '../AllUsersSection/User';
import { connect } from 'react-redux';
import goTo from '../../../../../actions/goTo';
import { showSnackbar } from '../../../../../../actions/appActions';

const styles = (theme) => ({
    container: {
        marginTop: 24,
        width: theme.constants.searchInputWidth - 40,
        height: 'fit-content',
        marginRight: 20,
        marginLeft: 20,
        marginBottom: 30,
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 2,
        color: '#979aaa',
    },
    users: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
})

const mapUsers = (subscribedTags, users) => {
    const tags = subscribedTags.map(tag => tag.tag);
    const filtered = users.filter(user => tags.includes(user.tag));
    const sorted = filtered.sort((a, b) => b.points - a.points);
    return sorted.map((user) => ({
        firstname: user.firstname,
        lastname: user.lastname,
        tag: user.tag,
        auth0_id: user.auth0_id,
        avatar: user.profileImageUrl.replace('fit-in/50x0', 'fit-in/70x0')
    }))
}

function SubscribedUsersSection(props) {
    const {classes, t, activeUsers, dispatch, userData, userSettingsService, withFilter, initialSubscribedUsers, withKeysNavigation} = props
    const {subscribedTags} = userData;
    const [users, setUsers] = useState(initialSubscribedUsers)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(withFilter) return;

        const users = mapUsers(subscribedTags, activeUsers);
        setUsers(users)
    }, [withFilter, subscribedTags, activeUsers])

    const openUser = (e, tag) => {
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
          })
        goTo(userTagFormat, dispatch)()
    }

    const unsubscribe = (tagUser) => {
        if(isLoading) return
        const subscribedTag = subscribedTags.find(tag => tag.tag.toLowerCase() === tagUser.toLowerCase());
        setIsLoading(true)
        dispatch(showSnackbar('unsubscribing...'));
        userSettingsService.unsubscribe(subscribedTag.id).then(_ => {
            userSettingsService.getUserData().then((userData) => {
                dispatch(showSnackbar('Unsubscribed successfully!'));
                dispatch({
                    type: 'USER_DATA_LOAD', data: {
                        ...userData
                    }
                });
                setIsLoading(false)
            })
        })
    }

    const renderUsers = () => {

        return (
            <>
            <div data-intrcm-id="SubscribedUsersSection_lmc2ajq3" className={classes.title}>{t('Subscribed').toUpperCase()}</div>
            <div data-intrcm-id="SubscribedUsersSection_su4l24zf" className={classes.users}>
                {users.map(user => <User withKeysNavigation={withKeysNavigation} isSubscribed subscribe={() => {}} unsubscribe={unsubscribe} openUser={openUser} key={user.auth0_id} user={user} />)}
            </div>
            </>
        )
    }
    if(!users.length || withFilter) return null;

    return (
        <div data-intrcm-id="SubscribedUsersSection_16y920cf" className={classes.container} >
            {renderUsers()}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      initialSubscribedUsers: state.searchNavigation.subscribedUsers,
      userData: state.userData,
      activeUsers: state.globalData.activeUsers,
      userSettingsService: state.services.userSettingsService
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SubscribedUsersSection)

