import { Icon } from '@iconify/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import roundSend from '@iconify/icons-ic/round-send';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import { Divider, IconButton, InputAdornment, withStyles, withTheme } from '@material-ui/core';
import EmojiPicker from './EmojiPicker';
import React from 'react'
import compose from 'recompose/compose';
import { withMixpanel } from '../../../components/Common/withMixpanel';
import { connect } from 'react-redux';
import { uploadFilesFromWeb } from '@tuqqi/web/dist/common/uploadFileFromWeb/uploadFileFromWeb'
import TuqqiAutocompleteInput from '@tuqqi/web/dist/components/common-components/TuqqiAutocompleteInput/TuqqiAutocompleteInput'
import {withCanUserEdit} from '@tuqqi/web/dist/common/withCanUserEdit/withCanUserEdit'
import {AttachmentOption, NewUiWrapper, usePrevious} from '@tuqqi/web'
import { withTranslation } from 'react-i18next';
import { randomID } from '@tuqqi/common';
import { store } from '../../../..';

const styles = theme => ({
    send: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginBottom: 4,
    },
    attachments: {
      marginBottom: 16,
      marginRight: 20,
      marginLeft: 20,
    },
    root: {
        height: 'fit-content',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit * 2
    },
    hintContainer: {
      marginTop: -4,
      marginBottom: 4,
      marginRight: 8,
      alignSelf: 'flex-end',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignContent: 'center'
    },
    hintText: {
      fontFamily: 'Rubik',
      fontSize: 11,
      lineHeight: '16px',
      letterSpacing: 0.4,
      color: '#0C0C0D',
    },
    hintSendText: {
      fontFamily: 'Rubik',
      fontSize: 11,
      lineHeight: '16px',
      letterSpacing: 0.4,
      color: '#B1B1B3',
      marginLeft: 3,
    },
    attachmentsIndicator: {
      position: 'absolute',
      width: 8,
      height: 8,
      right: 8,
      bottom: 8,
      borderRadius: '50%',
      background: '#ffbf4a',
    },
    row: {
      display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', minHeight: 56,
    },
    emodji: {
      display: 'flex', flexDirection: 'column', marginBottom: 28, alignSelf: 'flex-end', zIndex: 2
    },
    file: {
      display: 'flex', flexDirection: 'column', alignSelf: 'flex-end', marginBottom: 4,
    },
    input: {
      display: 'flex', flexDirection: 'column', width: '100%', zIndex: 1,
    },
    send: {
      alignSelf: 'flex-end', marginBottom: 4
    }
  });
const mentionsStyle = theme => ({
  '&singleLine': {
    input: {
      outline: 'none',
      width: '100%',
      border: 'none',
      color: '#21212d',
      fontSize: '1rem',
      fontFamily: 'Rubik',
      lineHeight: '1.1875em',
      display: 'block'
    },
  },
  '&multiLine': {
    input: {
      outline: 'none',
      width: '100%',
      border: 'none',
      color: '#21212d',
      fontSize: '1rem',
      fontFamily: 'Rubik',
      lineHeight: '1.1875em',
      display: 'block',
      paddingTop: 8,
      paddingBottom: 8,
      overflowY: 'auto'
    },
    control: {
      maxHeight: 112,
    }
  },
  suggestions: {
    list: {
       backgroundColor: 'white',
       position: 'absolute',
       bottom: 31,
       paddingTop: theme.spacing.unit * 2,
       paddingBottom: theme.spacing.unit * 2,
       width: theme.spacing.unit * 50,
       maxHeight: theme.spacing.unit * 36.5,
       overflowY: 'auto',
       borderRadius: 10,
       border: `solid 1px #d9d9d9`
   },

   item: {
       padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
       '&focused': {
           backgroundColor: theme.palette.grey[100],
       },
   },
 }
})

export const chatDrop = {
  cb: () => {}
}

function ChatInput({
  disabled,
  conversationId,
  onSend,
  classes,
  title,
  onLinkAdd,
  currDoc,
  mixpanelTrack,
  state,
  canUserEdit,
  docId,
  documentsState,
  theme,
  t
}) {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');
  const [doc, setDoc] = useState(currDoc)
  const [allowAddMember, setAllowAddMember] = useState(false)
  const [attachments, setAttachments] = useState([])
  const attachmentsRef = useRef([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const prevAttchemnts = usePrevious(attachments)

  useEffect(() => {
    attachmentsRef.current = attachments
  }, [attachments])

  const disabledInput = useMemo(() => {
    if(isUploading) return true
  
    return !uploadedFiles.length && !message.trim()
  }, [uploadedFiles, message, isUploading])

  const uploadFilesHandler = useCallback(() =>  {
    const nonUploadedAttachements = attachments.filter(el => {
        return !prevAttchemnts.includes( el );
      });
    if(!nonUploadedAttachements.length) return

    setIsUploading(true)

    const promises = nonUploadedAttachements
        .map(x => ({ fileName: x.fileName, uri: x.uri }))
        .map((x) => {
            return uploadFilesFromWeb(state)([x]).then((result) => {
                const resultFiltered = result.filter(x => attachmentsRef.current.find(x1 => x1.fileName === x.fileName) )
                
                setUploadedFiles((prev) => [...prev, ...resultFiltered])
            }).catch((error) => {})
        })

    Promise.all(promises)
        .then()
        .catch()
        .finally(() => {
            setIsUploading(false)
        })
},[attachments, state])

  useEffect(() => {
    uploadFilesHandler()
}, [uploadFilesHandler])

  const addComment = () => {
    setMessage('')
    onSend(conversationId, title, onLinkAdd, doc, message, uploadedFiles)
    setAttachments([])
    setUploadedFiles([])
    mixpanelTrack('New comment from chat', {});
}

  const handleKeyUp = () => {
      handleSend()
  };
  const getDocument = () => {
    const documentState = documentsState.data[docId]
    return (documentState && !documentState.isFetching) ? documentState.document : undefined
}
  useEffect(() => {
    setDoc(getDocument)
  }, [conversationId])

  useEffect(() => {
    if(!doc) {
      setAllowAddMember(false)
      return
    } 

    setAllowAddMember(canUserEdit(doc))
  }, [doc, canUserEdit, conversationId])

  useEffect(() => {
      setMessage('')
      setAttachments([])
      setUploadedFiles([])
  }, [conversationId])

  const handleSend = () => {
    if (disabledInput) return
    addComment();
  };

  const addAttachment = async (fileList) => {
    if (fileList) {
        const selectedFiles = Array.from(fileList).map(x => {
            const selectedFile = {
                fileName: x.name,
                fileSize: x.size,
                fileType: x.type,
                uid: randomID(),
                uri: URL.createObjectURL(x)
            }
            return selectedFile
        })
        setAttachments(prev => [...prev, ...selectedFiles])
    }
}
  const addAttachmentsOutter = useCallback((fileList) => {
    if (fileList) {
        const selectedFiles = Array.from(fileList).map(x => {
            const selectedFile = {
                fileName: x.name,
                fileSize: x.size,
                fileType: x.type,
                uid: randomID(),
                uri: URL.createObjectURL(x)
            }
            return selectedFile
        })
        setAttachments(prev => [...prev, ...selectedFiles])
    }
}, []) 

useEffect(() => {
  chatDrop.cb = addAttachmentsOutter
}, [addAttachmentsOutter])

  const removeAttachment = useCallback((uid) => {
    const removedItem = attachments.find(a => a.uid === uid)
    setAttachments(prev => prev.filter(a => a.uid !== uid))
    setUploadedFiles(prev => prev.filter(a => a.fileName !== removedItem?.fileName ))
  },[attachments])


  const onFilesSelected = (e) => {
    const fileList = e.target.files

    if (fileList) {
        const selectedFiles = Array.from(fileList).map(x => {
            const selectedFile = {
                fileName: x.name,
                fileSize: x.size,
                fileType: x.type,
                uid: randomID(),
                uri: URL.createObjectURL(x)
            }
            return selectedFile
        })
        setAttachments(prev => [...prev, ...selectedFiles])
        e.target.value = ''
    }
  }

  const onClickFile = (e) => {
    e.preventDefault()
    if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.click()
    }
  }

  const Attachments = !attachments.length ? null : 
    <NewUiWrapper store={store} callbacks={{}}>
      <div data-intrcm-id="ChatInput_bl9e32sa" className={classes.attachments}>
        {attachments.map((a, i) =>  <AttachmentOption key={a.uid} file={a} removeAttachment={removeAttachment} isPreview={false}/>)}
      </div>
    </NewUiWrapper>

  const Emodji = <div data-intrcm-id="ChatInput_4reqp9lk" className={classes.emodji}>
    <InputAdornment position="start">
      <EmojiPicker disabled={disabled} value={message} setValue={setMessage} />
    </InputAdornment>
  </div>

  const Input = <div data-intrcm-id="ChatInput_wmd3bear" className={classes.input}>
    <TuqqiAutocompleteInput style={mentionsStyle(theme)}
      onClipboardFiles={addAttachment}
      onEnterShiftKeyDown={handleKeyUp}
      autoFocus={true}
      docFromPreview={doc}
      onChange={(e) => setMessage(e)}
      canAddMember={allowAddMember}
      placeholder={t('Type a new message')}
      singleLine={false}
      value={message} />
  </div>

  const FileBtn = <div data-intrcm-id="ChatInput_e3fduihy" className={classes.file}>
    <div data-intrcm-id="ChatInput_agd13gzr" style={{ flexShrink: 0, position: 'relative' }}>
      <IconButton size="small" onClick={onClickFile}>
        <Icon icon={attach2Fill} width={24} height={24} />
      </IconButton>
    </div>
  </div>

  const SendBtn = <IconButton className={classes.send} color="primary" style={disabledInput ? {} : {color: 'rgb(98, 0, 238)'}} disabled={disabledInput} onClick={handleSend}>
    <Icon icon={roundSend} width={24} height={24} />
  </IconButton>

  const SendHintText = <div data-intrcm-id="ChatInput_wjh0qcsv" className={classes.hintContainer}>
    <div data-intrcm-id="ChatInput_hm4kvugz" className={classes.hintSendText}>{t('to send')}</div>
    <div data-intrcm-id="ChatInput_ypvy2wth" className={classes.hintText}>Shift + Enter</div>
  </div>

  return (
    <>
    {Attachments}
    <Divider />
    <div data-intrcm-id="ChatInput_n4nd56sl" className={classes.root}>
      <div data-intrcm-id="ChatInput_09ot87r7" className={classes.row}>
        {Emodji}
        {Input}
        {FileBtn}
      </div>
      {SendBtn}
      <input multiple type="file" onError={e=> console.log(e)} onChange={onFilesSelected} ref={fileInputRef} style={{ display: 'none' }} />
    </div>
    {SendHintText}
    </>
  );
}
const mapStateToProps = (state) => ({
    state,
    documentsState: state.data.documents,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default compose(
    withTheme(),
    withCanUserEdit,
    withStyles(styles, { withTheme: true }), 
    withMixpanel,
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChatInput);