import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer';
import withPusher from '../../../components/Common/Pusher/withPusher';
import { NewUiWrapper, TableGroup, managerGroupTable } from '@tuqqi/web';
import {store} from '../../../../index'
import { themeConstants } from '../../../../theme';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import { MockForSetTableGlobalData } from './MockForSetTableGlobalData.js';
import GroupTableView from '../groupTableView.js';
import { setShowOldTable, useNewTable, useNewTableOrig } from '../../../../utils/useNewTable.js';
import { shouldAutoOpen } from '../groupUtils.js';
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { openInput } from '../../../../actions/inputActions.js';
import { FULLVIEW_POST, INPUT_OPEN } from '../../../../actionTypes/actionTypes.js';

const styles = theme => ({
    table: {
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
    table1: {
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
})

export class GroupTableNewView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {
        if(!useNewTable(this.props.orgUid)) return
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerGroupTable.preFetch(this.props.groupUid, this.props.collectionUid)
        managerGroupTable.fetch(this.props.groupUid, this.props.collectionUid)
    }

    componentDidUpdate(prevProps) {
        if(!useNewTable(this.props.orgUid)) return
        if(this.props.collectionUid === prevProps.collectionUid && this.props.groupUid === prevProps.groupUid) return

        if(!this.props.collectionUid || !this.props.groupUid) return

        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerGroupTable.preFetch(this.props.groupUid, this.props.collectionUid)
        managerGroupTable.fetch(this.props.groupUid, this.props.collectionUid)
    }

    addCardNewGroupTable = () => {
        const { dispatch, inputMappings, oldUiInput, userData, search } = this.props

        const item = managerGroupTable.getKeepGroup()
        if(!item) return
        const groupUid = item.collectionGroup.groupUid

        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = ""

        if (shouldAutoOpen(item)) {
            const dataType = item.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, search, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }
    }


    showOldTable = () => {
        setShowOldTable(true, this.props.dispatch)
    }

    render() {
        const { classes, isDrawerOpen,groupUid, collectionUid } = this.props;

        const callbacks = {
            onClickAddItemFromTable: this.addCardNewGroupTable
        }

        const show = groupUid && collectionUid

        if(!useNewTable(this.props.orgUid)) return <GroupTableView />
     
        return <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="GroupTableNewView_3b644a2o" className={isDrawerOpen ? classes.table : classes.table1}>
                {/* {useNewTableOrig(this.props.orgUid) && <button style={{marginLeft: 100}} onClick={this.showOldTable}>Show old table</button>} */}
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            <MockForSetTableGlobalData />
                            {show && <TableGroup drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth : themeConstants.navigationDrawerSmallPXWidth} groupUid={groupUid} />}
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    search: state.search,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Table, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupTableNewView);