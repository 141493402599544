import classnames from 'classnames';
import React, { PureComponent } from 'react';
import SplitedText from '../../../components/Comments/splitedText.js';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import './SystemMessage.css';


export class SystemMessage extends PureComponent {
    render() {
        const { text, className } = this.props
        return (
            <div data-intrcm-id="SystemMessage_yj8vmf3z" className={classnames("rce-container-smsg", className)}>
                <div data-intrcm-id="SystemMessage_etrux0ff" className='rce-smsg'>
                    <SplitedText className="rce-smsg-text" text={text}/>
                </div>
            </div>
        );
    }
}

export default withErrorCatcher()(SystemMessage);
