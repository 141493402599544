import React from 'react';

 const PhoneIcon = (props) => {
        const { iconColor } = props

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12.0787 9.8015L9.33322 10.3335C7.47875 9.4027 6.33322 8.3335 5.66655 6.66683L6.17985 3.91343L5.20957 1.3335H2.70895C1.95725 1.3335 1.36531 1.95468 1.47758 2.69795C1.75785 4.5535 2.58424 7.91783 4.99989 10.3335C7.53669 12.8703 11.1904 13.9711 13.2012 14.4086C13.9778 14.5776 14.6666 13.9718 14.6666 13.1772V10.7876L12.0787 9.8015Z" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
}

export default  PhoneIcon
