import React, { useCallback, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NotificationOption from './NotificationOption'
import { withOpenFullview } from '../../../../../new-ui/withOpenFullview';
import { withMixpanel } from '../../../Common/withMixpanel';
import goToCollection from '../../../../actions/goToCollection';
import Spinner from '../../../Common/spinner';
import NoNotifs from './NoNotifs';
import { isNotificationDateSeen } from '../../../../../utils/notifications';

const styles = (theme) => ({
    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 24px - 52px)`,
    },
    spinner: {
        position: 'fixed',
        right: '50vw',
        top: '50vh',
        zIndex: 1301,
    }
})

const NotificationsList = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const {lastSawNotification, classes, notifications, mixpanelTrack, openFullview, dispatch, queryService, notificationService} = props

    const filtered = useMemo(() => {
        return notifications
            .filter(n => !n.isChatNotification)
            .sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime())
    }, [notifications, notifications.length])

    const getIsRead = useCallback((created, seenOnDateTime) => {
        return isNotificationDateSeen(created, seenOnDateTime, lastSawNotification)
    }, [lastSawNotification])

    const markAsReadNotification = useCallback((notification) => {
        const { id } = notification

        notificationService.sawNotification(id, 'web')

        dispatch({
            type: 'USER_SEEN_NOTIFICATION',
            id,
            seenOnDateTime: new Date().toISOString()
        })
    }, [])


    const onPressNotify = useCallback((notification) => {
        const { docId, collection } = notification

        markAsReadNotification(notification)
        if (docId) {
            setIsLoading(true)
            queryService.searchById(docId).then((results) => {
                if (results.data.length) {
                    const doc = results.data[0].Value.Source;
                    dispatch({type: "BLUR_NOTIFICATIONS_MODAL"})
                    openFullview(doc, docId, true)
                }
                setIsLoading(false)
            })
            mixpanelTrack('Go item from notification drawer', {});
        } else if (collection) {
            dispatch({type: "BLUR_NOTIFICATIONS_MODAL"})
            goToCollection('/' + collection.title, dispatch, collection.collectionUid, collection.collectionGroup.groupUid)();
            mixpanelTrack('Go group from notification drawer', {});
        }
    }, [openFullview])

    if(!filtered.length) return <NoNotifs />

    return (
        <>
            {isLoading && <Spinner classes={{ spinner: classes.spinner }} />}
            <div data-intrcm-id="NotificationsList_uao9ffhx" className={classes.content}>
                {filtered.map((item, i) => <NotificationOption markAsReadNotification={markAsReadNotification} onGoToSettings={props.onGoToSettings} key={item.id + new Date(item.dateTime).getTime()} isRead={getIsRead(item.created, item.seenOnDateTime)} onPress={onPressNotify} notification={item}/>)}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        notificationService: state.services.notificationService,
        notifications: state.userData.notifications,
        lastSawNotification: state.userData.profileData?.lastSawNotification ?? '',
        queryService: state.services.queryService
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withOpenFullview,
    withMixpanel,
    withStyles(styles, {withTheme: true}),
    withTranslation('notificationsDrawer'),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationsList)