import React from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import HeaderNavigation from './HeaderNavigation'
import StoriesNavigation from './StoriesNavigation'

const styles = (theme) => ({
    container: {
        marginLeft: 0,
        marginRight: 'auto',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column'
    }
})

const MenuNavigation = (props) => {
    const {classes, width, isDrawerOpen} = props

    return  <div data-intrcm-id="MenuNavigation_n03jvv6s" style={{width, transition: 'width .2s ease-in-out'}} className={classes.container}>
                <HeaderNavigation isDrawerOpen={isDrawerOpen}/>
                <StoriesNavigation isDrawerOpen={isDrawerOpen}/>
            </div>
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(MenuNavigation)