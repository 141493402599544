import { withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { LaunchpadAddItemEmptyIcon } from '../../../../components/Common/Icons/LaunchpadAddItemEmptyIcon'
const styles = (theme) => ({
    container: {
        marginTop: '75%'
    },
    iconWrap: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        marginTop: 16,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: 0.24,
        color: '#4A4A4F',
    },
    subTitle: {
        marginTop: 4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: '#737373',
    },
})

function _LaunchpadNoItemsPlaceholder(props) {
    const {classes} = props
    const [t] = useTranslation("search")

    return (
        <div data-intrcm-id="NoItemsPlaceholder_lsrejzek" className={classes.container} >
            <div data-intrcm-id="NoItemsPlaceholder_rrgk68bj" className={classes.iconWrap}><LaunchpadAddItemEmptyIcon /></div>
            <div data-intrcm-id="NoItemsPlaceholder_zydqre6k" className={classes.title}>{t("No elements to select")}</div>
            <div data-intrcm-id="NoItemsPlaceholder_l23nvj8z" className={classes.subTitle}>{t("Please enter group, item or link name in the search field above")}</div>
        </div>
    )
}

export const LaunchpadNoItemsPlaceholder = compose(
    withStyles(styles, {withTheme: true})
)(_LaunchpadNoItemsPlaceholder)

