import AppBar from '@material-ui/core/AppBar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderToolbar from './HeaderToolbar/HeaderToolbar.js';


class Header extends Component {

    render() {
        const { location, searchQuery } = this.props;

        return (
            <AppBar color="inherit" elevation={0}>
                <HeaderToolbar location={location} />
            </AppBar>
        );
    }
}

const mapStateToProps = (state) => ({
    searchQuery: state.search,
})


export default connect(mapStateToProps)(Header);

