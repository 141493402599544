import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { COLLECTION_INIT } from '../../../../../actionTypes/actionTypes';

// Apply some reset
const styles = theme => ({

});


class EditCollection extends Component {
    state = {
        editOpen: false,
        activeStep: 0
    }

    getChecked() {
        const { collectionGroup, limitedGroup } = this.props.collectionInfo


        const checked = new Map()
        collectionGroup.groupUsers.forEach(u => {
            checked.set(u.id, { isLimited: false, isAdmin: collectionGroup.adminIds.includes(u.id) })
        })
        limitedGroup.groupUsers.forEach(u => {
            checked.set(u.id, { isLimited: true })
        })

        return checked
    }

    openDialog = () => {
        const { collectionInfo, dispatch } = this.props
        dispatch(push({ pathname: `${"/group/settings"}`, search: window.location.search }))
        // const { backgroundUrl, settings, title, description, icon, collectionGroup, allInputsAllowed, defaultView, limitedGroup, externalInputs, isLimitedGroupEnabled, mailAddressUid } = collectionInfo

        // const checked = this.getChecked()

        // const collection = {
        //     isNew: false,
        //     id: collectionInfo.id,
        //     allInputsAllowed,
        //     backgroundUrl,
        //     settings,
        //     title,
        //     mailAddressUid,
        //     externalInputs: externalInputs,
        //     description,
        //     defaultView,
        //     icon: icon || 'public',
        //     group: {
        //         isEnableMembersAsAuthors: collectionGroup.isEnableMembersAsAuthors,
        //         isLimitedGroupEnabled,
        //         disableChangeIsLimitedGroupEnabled: isLimitedGroupEnabled,
        //         checked,
        //         groupUsers: collectionGroup.groupUsers,
        //         adminIds: collectionGroup.adminIds,
        //         isClosed: collectionGroup.isClosed
        //     }
        // }

        // dispatch({ type: COLLECTION_INIT, collection })
    }

    render() {
        const { classes, t, button } = this.props;
        return React.cloneElement(button, { onClick: this.openDialog })

    }

}

const mapStateToProps = (state) => ({
    collectionInfo: state.results.channel,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('editCollection'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(EditCollection);