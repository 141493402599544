import { ADD_FEED_MENU_ITEM, DELETE_FEED_MENU_ITEM, SET_FEED_MENU_ITEMS, SET_OPENED_MENU_ITEM, UPDATE_FEED_MENU_ITEM } from "../actionTypes/feedNavigationTypes";

export const addFeedMenuItem = (item) => ({
    type: ADD_FEED_MENU_ITEM,
    item
})

export const updateFeedMenuItem = (item) => ({
    type: UPDATE_FEED_MENU_ITEM,
    item
})

export const deleteFeedMenuItem = (id) => ({
    type: DELETE_FEED_MENU_ITEM,
    id
})

export const setFeedMenuItems = (items) => ({
    type: SET_FEED_MENU_ITEMS,
    items
})

export const setOpenedFeedMenuItem = (item, isNewOpened = true) => ({
    type: SET_OPENED_MENU_ITEM,
    item,
    isNewOpened
})