import React from 'react';
import { makeStyles } from '@material-ui/styles';


const UIdefaultToolbarHeight = 48
const top = 4
export const ADMIN_PANEL_WIDTH = 320
const OFFSET_MARGIN = 32
const styles = theme => ({
    wrap: {
        height: `calc(100vh - ${UIdefaultToolbarHeight + top}px)`,
        overflowX: 'hidden',
        maxWidth: `calc(100vw - ${ADMIN_PANEL_WIDTH}px)`,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    content: {
        width: `calc(100vw - ${ADMIN_PANEL_WIDTH}px - ${OFFSET_MARGIN*2}px)`,
        minWidth: `calc(100vw - ${ADMIN_PANEL_WIDTH}px - ${OFFSET_MARGIN*2}px)`,
        marginTop: 24,
        marginRight: OFFSET_MARGIN,
        marginLeft: OFFSET_MARGIN,
    }
});

const useStyles = makeStyles(styles)

export const OldPagesWrap = (props) => {
    const classes = useStyles()

    return <div data-intrcm-id="OldPagesWrap_j7kykpwd" className={`${classes.wrap} ${props.className ?? ''}`}>
        <div data-intrcm-id="OldPagesWrap_hly3qyi7" className={`${classes.content} ${props.classNameContent ?? ''}`}>
            {props.children}
        </div>
    </div>
}