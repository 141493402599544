import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js'
import Map from "../../components/Common/Map/Map";
import MapPin from '../../components/Common/Map/MapPin.js';
import { withStyles } from '@material-ui/core';
import { withOpenFullview } from '../../../new-ui/withOpenFullview.js';
import { loadData } from '../search/searchUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { themeConstants } from '../../../theme.js';


const styles = theme => ({
    container: {
        height: 'auto',
        width: 'auto',
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        left: themeConstants.navigationDrawerWidth,
    },
    containerSmall: {
        height: 'auto',
        width: 'auto',
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        left: themeConstants.navigationDrawerSmallWidth,
    }
});

class MyPageMap extends Component {
    constructor(props) {
        super(props)

        this.state = {
            center: undefined,
            results: [],
            myResults: [],
            dataReloadInidicator: 0,
            isLoading: true,
            open: false
        }
        this.loadData = loadData.bind(this);
    }


    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'DATA_UPDATE' })
    }
    componentDidUpdate(prevProps, prevState){
        this.listenResults(prevState.results)
    }

    listenResults = (prevResults) => {
        const {results} = this.state
        const {profileData: {auth0_id}} = this.props
        if(results.length === prevResults.length) return

        const myResults = results.filter((item) => {
            const {members, geoLocation} = item.Value.Source
            const userUUIDs = members.map(UUID => UUID.userUUID)
            return !!geoLocation && userUUIDs.includes(auth0_id)
        })
        this.setState({myResults})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps);
        }
    }
    
    getCenter = () => {
        if (!(window.google && window.google.maps && window.google.maps.LatLngBounds)) {
            return undefined
        }

        this._bounds = new window.google.maps.LatLngBounds();
        this.state.results.forEach((item, index) => {
            const { geoLocation } = item.Value.Source
            if (geoLocation) {
                const position = new window.google.maps.LatLng(geoLocation.lat, geoLocation.lon);
                this._bounds.extend(position);
            }
        });

        const center = this.extractCenter(this._bounds)
        return center
    }

    extractCenter(_bounds) {
        const center = this._bounds.getCenter();
        const res = { lat: center.lat(), lng: center.lng() }
        if (!isNaN(res.lat) && !isNaN(res.lng)) {
            return res
        }
        return undefined
    }


    handleMapMounted = ({ map }) => {
        const center = this.getCenter()
        this.setState({ center })

        map && map.fitBounds && (map.fitBounds(this._bounds));
    }


    openItem = (itemId, item) => () => {
        const { collaborationService, openFullview } = this.props;

        collaborationService.logClick(itemId);
        openFullview(item, itemId, true);
    }

    render() {
        const { classes, isDrawerOpen } = this.props
        const {isLoading, myResults, center} = this.state
        if (isLoading) {
            return <CircularProgress style={{ position: 'fixed', left: '50%', right: '50%', top: '27%' }} />
        }
        return (
            <Map center={center} zoom={6} classes={{ container: isDrawerOpen ? classes.container : classes.containerSmall}} onMapMounted={this.handleMapMounted}>
                {
                    myResults.map((item, idx) => {
                        const { title, postText } = item.Value.Source
                        const geoLocation = item.Value.Source.geoLocation
                        const textToShow = [title, `${postText.substring(0, 25)}${postText.length > 25 ? '...' : ''}`].filter(text => !!text).join(', ')
                        return  <MapPin
                                    key={idx}
                                    lat={geoLocation.lat}
                                    lng={geoLocation.lon}
                                    text={textToShow}
                                    onClick={this.openItem(item.Key, item.Value.Source)} />
                    })
                }
            </Map>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.services,
    oldUiData: state.oldUiData,
    profileData: state.userData.profileData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document
})

const mapDispatchToProps = dispatch => ({
        dispatch: dispatch
})

export default compose(withStyles(styles),
    withOpenFullview,
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MyPageMap);