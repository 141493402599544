import { withStyles } from '@material-ui/core';
import { deleteComment, saveComment, saveMessage } from '@tuqqi/common';
import { openFullview } from '@tuqqi/web';
import React, { Component } from 'react';
// RCE CSS
import 'react-chat-elements/dist/main.css';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SERVER_API } from '../../../utils/serverAPI.js';
import goTo from '../../actions/goTo';
// MessageBox component
import MessageBox from './MessageBox/MessageBox';


const styles = theme => ({
    commentTitle: {
     
        fontSize: 14,
        fontWeight: 500,
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle : {
       
        fontSize: 14,
        fontWeight: 'normal',
        
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle2 : {
       
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.38)',
        display:'flex'
    },
    iconButton: {
        root: {
            fontSize: 14
        }
    },
    message:{
        whiteSpace: 'pre-wrap',
    },
    ...theme.comment,
})

class ChatComment extends Component {
    state = {
        open: false,
        choosen: false
    }

    onSaveComment = () => {
        const {dispatch, comment, userData: {profileData}} = this.props
        dispatch(saveComment(SERVER_API)(profileData.auth0_id, comment.originalItem.docId, comment.id))
    }
    onClickDelete = () => {
        const {dispatch, comment} = this.props
        dispatch(deleteComment(SERVER_API)(comment.originalItem.docId, comment.id))
    }
    onSaveMessage = () => {
        const {dispatch, comment, userData: {profileData}} = this.props
        dispatch(saveMessage(SERVER_API)(profileData.auth0_id, comment.originalItem.chatUId, comment.id))
    }
    onClickOpenDocument = () => {
        const {dispatch, comment} = this.props
        dispatch(openFullview(comment.originalItem.docId, false))

    }


    render() {
        const { classes, comment, userData, dispatch, cloudfront } = this.props;

        if(!comment){
            return null
        }

        const { _id, text, createdAt, user, type } = comment
        const {typeItem} = comment

        let userComment = typeItem.isSavedMessage ? typeItem.messageType === 2 : user._id === userData.profileData.auth0_id;
        let showDeleteComment = user._id === userData.profileData.auth0_id && typeItem.messageType === 0 && !typeItem.isSavedMessage
        let parsedMessageText;
        let fullImageUrl;

       

        return (
            <MessageBox
                comment={comment}
                onOpenLightbox={this.props.onOpenLightbox}
                key={_id}
                onTitleClick={goTo('@' + user.tag, dispatch)}
                onClickSave={comment.originalItem.commentId ? this.onSaveComment : this.onSaveMessage}
                onClickOpenDocument={this.onClickOpenDocument}
                onClickDelete={this.onClickDelete}
                className={classes.message}
                title={user.name}
                avatar={user.avatar}
                showDeleteComment={showDeleteComment}
                position={userComment ? 'right' : 'left'}
                date={createdAt}
            />)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
    view: state.view,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatComment);