import React, {useEffect, useRef, useState} from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import User from './UserSearchItem';
import { connect } from 'react-redux';
import goTo from '../../../../../actions/goTo';
import { mapLandingUsers } from '../../../../../../utils/searchNavigation/searchHelpers';

const styles = (theme) => ({
    container: {
        marginTop: 24,
        width: theme.constants.searchInputWidth - 40,
        marginRight: 20,
        marginLeft: 20,
        marginBottom: 30
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.8,
        letterSpacing: 0.31,
        color: '#21212d',
    },
    seeAll: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 13,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.23,
        letterSpacing: 0.5,
        textAlign: 'right',
        color: theme.customColors.primary.main,
        cursor: 'pointer'
    },
    users: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        marginRight: 8,
        marginLeft: 8,
        justifyContent: 'space-between'
    }
})

const SHOW_USERS_LENGTH = 6;
const initFilterUsers = {
    users: []
}
const filterUsers = (filter = initFilterUsers, query) => {
    const {users} = filter;
    const q = query.slice(1).toLowerCase();
    const items = users.filter(user => `${user.firstname} ${user.lastname}`.toLowerCase().includes(q))
    return mapLandingUsers(items, SHOW_USERS_LENGTH)
}

function UsersSection(props) {
    const {classes, t, activeUsers, dispatch, initialLandingUsers, filterMode, withFilter, query, withKeysNavigation} = props
    const [users, setUsers] = useState(initialLandingUsers.slice(0, SHOW_USERS_LENGTH))
    const filterRef = useRef(initFilterUsers)

    useEffect(() => {
        if(filterMode !== 'suggestion' || !withFilter) return;

        const {users} = filterRef.current
        const filtered = filterUsers({users}, query);
        setUsers(filtered)

    }, [query, filterMode, withFilter])

    useEffect(() => {
        const users = mapLandingUsers(activeUsers, SHOW_USERS_LENGTH);
        setUsers(users)
        filterRef.current.users = activeUsers
    }, [activeUsers])

    const openUser = (e, tag) => {
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
          })
        goTo(userTagFormat, dispatch)()
    }

    const goToSearchUsers = () => {
        const query = {query: '@'}
        dispatch({
            type: 'SEARCH_QUERY', query
        })
    }

    const renderHeader = () => {
        return (
            <div data-intrcm-id="UsersSection_38ztsiac" className={classes.header}>
                <div data-intrcm-id="UsersSection_7rbf3cmd" className={classes.title}>{t('Users')}</div>
                <div data-intrcm-id="UsersSection_fzmyhmn1" onClick={goToSearchUsers} className={classes.seeAll}>{t('See all users').toUpperCase()}</div>
            </div>
        )
    }
    const renderUsers = () => {

        return (
            <div data-intrcm-id="UsersSection_bh56bl93" style={{justifyContent: users.length >= SHOW_USERS_LENGTH ? 'space-between' : 'flex-start'}} className={classes.users}>
                {users.map(user => <User withKeysNavigation={withKeysNavigation} openUser={openUser} key={user.auth0_id} user={user} />)}
            </div>
        )
    }
    if(!users.length) return null;

    return (
        <div data-intrcm-id="UsersSection_xqwmmilz" className={classes.container} >
            {renderHeader()}
            {renderUsers()}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      query: state.search.query,
      activeUsers: state.globalData.activeUsers,
      initialLandingUsers: state.searchNavigation.landingUsers
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UsersSection)

