import { push } from 'react-router-redux';

export default (groupTitle, dispatch, groupUid) => {
    
    return () => {
        dispatch({ type: 'SEARCH_QUERY', query: {
            query: groupTitle, 
            page: 0,
            groupUid: groupUid ? groupUid : null 
        } })
        dispatch({ type: 'DATA_UPDATE' });
        dispatch({ type: 'RESULT_CHANNEL_EMPTY' })

        dispatch(push({ pathname: '/group', search: '?searchquery=' + encodeURIComponent("/" + groupUid) }))
    }
}
