import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionGroupSettings } from '../../../../../actions/collectionActions.js';
import UserAvatar from '../../UserAvatar';
import InfinityUsersList from '../../Users/InfinityUsersList';
import ClassifiedGroupMenu from './ClassifiedGroupMenu.js';



class GroupUsers extends PureComponent {

    state = {
        isChanging: false
    }

    handleToggleNew = userId => {
        const { dispatch, checked } = this.props;
        if (checked.has(userId)) {
            checked.delete(userId)
        } else {
            // if the limited group is enabled, by default set new user as limited
            checked.set(userId, { isLimited: this.props.isLimitedGroupEnabled })
        }

        dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))
    };


    handleToggle = userId => {
        const { dispatch, checked, groupService, groupUid, limitedGroup } = this.props;
        this.setState({ isChanging: true })
        if (checked.has(userId)) {
            // update db
            groupService.removeUserFromGroup(groupUid, userId).then((res) => {

                this.setState({ isChanging: false })
                checked.delete(userId)
                dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))

            })

        } else {

            if (this.props.isLimitedGroupEnabled && limitedGroup && limitedGroup.groupUid) {
                // update db
                
                groupService.addUserToGroup(limitedGroup.groupUid, userId).then(() => {

                    this.setState({ isChanging: false })
                    checked.set(userId, { isLimited: true })
                    dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))

                })
            } else {
                // update db
                groupService.addUserToGroup(groupUid, userId).then(() => {

                    this.setState({ isChanging: false })
                    checked.set(userId, { isLimited: false })
                    dispatch(updateCollectionGroupSettings({ checked: new Map(checked) }))

                })
            }



        }

    };


    renderUser = (user, onChange) => {
        const { checked, isLimitedGroupEnabled, collection } = this.props;


        const handleToggleFunc = collection.isNew ? this.handleToggleNew : this.handleToggle;

        return (
            <ListItem
                key={user.id}
                dense
                button
                onClick={() => {
                    handleToggleFunc(user.id)
                    onChange()
                }}
            >
                <UserAvatar userUUID={user.auth0_id} withShadow={false} />
                <ListItemText
                    primary={`${user.firstname} ${user.lastname}`}
                    secondary={<div data-intrcm-id="GroupUsers_b6hh2hdp">{user.title}<br />@{user.tag}</div>} />
                <ClassifiedGroupMenu user={user} isLimitedGroupEnabled={isLimitedGroupEnabled} />
                <Checkbox
                    disabled={this.state.isChanging}
                    className='group-user-checkbox'
                    checked={checked.has(user.id)}
                    tabIndex={-1}
                    disableRipple
                />

            </ListItem>
        )
    }

    sortUsers = (users) => {

        const sortByName = (a, b) => {
            if (a.firstname < b.firstname) { return -1; }
            if (a.firstname > b.firstname) { return 1; }
            return 0;
        }

        const admins = users.filter(x => this.props.checked.has(x.id) && this.props.checked.get(x.id).isAdmin).sort(sortByName)

        const members = users.filter(x => this.props.checked.has(x.id) && (this.props.checked.get(x.id).isAdmin === false && this.props.checked.get(x.id).isLimited === false)).sort(sortByName)

        const limitedMembers = users.filter(x => this.props.checked.has(x.id) && (this.props.checked.get(x.id).isLimited === true)).sort(sortByName)

        const unchecked = users.filter(x => !this.props.checked.has(x.id)).sort(sortByName)

        const allUsers = [...admins, ...members, ...limitedMembers, ...unchecked];
        return allUsers;

    }

    render() {
        const { profileData, checked, users } = this.props

        // Sort users
        const sortedUsers = this.sortUsers(users)

        return (
            <InfinityUsersList
                checked={checked}
                users={sortedUsers.filter(user => user.id !== profileData.id)}
                renderUser={this.renderUser}
            />
        )
    }


}
const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    isLimitedGroupEnabled: state.collection.group.isLimitedGroupEnabled,
    // limitedGroupUid: state.search.collection.limitedGroup.groupUid,
    limitedGroup: state.search.collection? state.search.collection.limitedGroup: null,
    groupUid: state.search.groupUid,
    checked: state.collection.group.checked,
    users: state.globalData.activeUsers,
    collection: state.collection,
    ...state.services
})


export default compose(connect(mapStateToProps))(GroupUsers);