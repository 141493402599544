export const SET_COUNT_AGENDA_ITEMS = 'SET_COUNT_AGENDA_ITEMS'
export const SET_COUNT_TODO_ITEMS = 'SET_COUNT_TODO_ITEMS'
export const SET_COUNT_MUSTREAD_ITEMS = 'SET_COUNT_MUSTREAD_ITEMS'
export const DECREASE_ACTIONS_CENTER_COUNT = 'DECREASE_ACTIONS_CENTER_COUNT'

export const setCountAgendaItems = (count) => ({
    type: SET_COUNT_AGENDA_ITEMS,
    count
})

export const decreaseActionCenterCount = (view) => ({
    type: DECREASE_ACTIONS_CENTER_COUNT,
    view
})

export const setCountToDoItems = (count) => ({
    type: SET_COUNT_TODO_ITEMS,
    count
})

export const setCountMustReadItems = (count) => ({
    type: SET_COUNT_MUSTREAD_ITEMS,
    count
})
