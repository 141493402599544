import { ListItem, ListItemIcon, Tooltip, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { themeConstants } from '../../../../../theme';


// Apply some reset
const styles = theme => ({
    tooltip: {
        fontStyle: 'normal',
        fontfamily: 'Rubik',
        fontWeight: 400,
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 12,
    },
    subWidth: {
        width: '182px!important',
        marginLeft: 8,
        paddingLeft:8,
        paddingRight:8,
        [theme.breakpoints.down('1071')]: {
            width: '174px!important',
        },
    },
    item: {
        // marginTop: 4,
        // marginBottom: 4,
        paddingLeft:8,
        paddingRight:16,
        height: 32,
        // filter: 'grayscale(1)',
        width: themeConstants.leftDrawerWidth - 16,
        [theme.breakpoints.down('1071')]: {
            width: themeConstants.leftDrawerSmallWidth - 16
        },
        cursor: 'default!important'
    },
    itemHover: {
        // marginTop: 4,
        // marginBottom: 4,
        // filter: 'grayscale(1)',
        height: 32,
        paddingLeft:8,
        paddingRight:16,
        width: themeConstants.leftDrawerWidth - 16,
        [theme.breakpoints.down('1071')]: {
            width: themeConstants.leftDrawerSmallWidth - 16
        },
        '&:hover': {
            // filter: 'unset',
            cursor: 'pointer',
            color: "#1B1E2A !important",
            backgroundColor: "#E8EBFD !important",
            borderRadius: 8,
         }
         
    },
    itemManualHover: {
            // filter: 'unset',
            cursor: 'pointer',
            color: "#1B1E2A !important",
            backgroundColor: "#E8EBFD !important",
            borderRadius: 8,
    },
    selectedItem: {
        // marginTop: 4,
        // marginBottom: 4,
        // filter: 'unset!important',
        paddingLeft:8,
        paddingRight:16,
        height: 32,
        width: themeConstants.leftDrawerWidth - 16,
        [theme.breakpoints.down('1071')]: {
            width: themeConstants.leftDrawerSmallWidth - 16
        },
        // backgroundColor: "#E8EBFD !important",
        background: 'var(--bg-menu-vibrant, rgba(183, 184, 189, 0.2)) !important',

        borderRadius: 8,
        boxShadow: '0px -1px 0px 0px rgba(183, 184, 189, 0.15) inset'

    },
    selectedText: {
        color: "#1B1E2A !important",
    },
    listItemText: {
        maxWidth: 179,
        [theme.breakpoints.down('1071')]: {
            // maxWidth: 124,
            maxWidth: 118,
        },
        fontFamily: 'Rubik',
        color: 'rgba(0, 0, 0, 0.87)',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        // letterSpacing: 0.4,
        whiteSpace: 'nowrap',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    textDisabled: {
        display: 'block',
        fontFamily: 'Rubik',
        color: 'rgba(0, 0, 0, 0.62)',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 10,
        lineHeight: '10px',
        letterSpacing: 0.1,
    },
    textSoon: {
        display: 'block',
        fontFamily: 'Rubik',
        color: theme.customColors.primary.main,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 10,
        lineHeight: '10px',
        letterSpacing: 0.1,
    },
    subText: {
        color: '#6B6F7A'
    },
    listItemIcon: {
        width:20,
        marginRight:8
    }
});

function isTruncated(el) {
    if(!el) return false
    return el.scrollWidth > el.clientWidth
}  

class DrawerListItem extends Component {
    state = {
        onHover: false,
        textEl: null
    }
    onHover = () => {
        const {id} = this.props
        this.setState({textEl: document.getElementById(`feed-item-text-id${id}`)})
    }
    onMouseEnter = () => {
        this.setState({onHover: true})
    }
    onMouseLeave = () => {
        this.setState({onHover: false})
    }
    _onClick = (e) => {
        const {onClick, disabled} = this.props
        if(disabled) return
        onClick(e)
    }

    renderOption = () => {
        const { id, disabled, isSub, disableHover, classes, selectedCondition, icon, text, t, isSoon, iconHover } = this.props

       return <ListItem
                onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                disabled={disabled}
                selected={selectedCondition}
                classes={{ root: !disableHover && !disabled ? `${classes.itemHover} ${!disableHover && !disabled && iconHover ? classes.itemManualHover : ""} ${isSub ? classes.subWidth : ''}` : `${classes.item} ${isSub ? classes.subWidth : ''}`, selected: `${classes.selectedItem} ${isSub ? classes.subWidth : ''}` }}
                onClick={this._onClick}>
                {/* <ListItemIcon className={`${classes.listItemIcon} ${(selectedCondition ? classes.listItemIconSelected : '')} `} >
                    {icon}  
                </ListItemIcon> */}
                <div data-intrcm-id="drawerListItem_zmquw56t" onMouseEnter={this.onHover} style={{flexDirection: 'column'}}>
                <div data-intrcm-id="drawerListItem_yuy86j39" id={`feed-item-text-id${id}`} className={`${classes.listItemText} ${selectedCondition ? classes.selectedText : ''} ${!disableHover && !disabled && this.state.onHover ? classes.selectedText : ''} ${isSub ? classes.subText : ''}`}>{`${text}`}</div>
                {!!disabled && !isSoon && <div data-intrcm-id="drawerListItem_dzw6frop" className={classes.textDisabled}>{t("Disabled")}</div>}
                {!!disabled && !!isSoon && <div data-intrcm-id="drawerListItem_2vix85hr" className={classes.textSoon}>{t("Comming soon")}</div>}
                </div>
        </ListItem>
    }

    render() {
        const { classes, text, t } = this.props
        if(!isTruncated(this.state.textEl)) return this.renderOption()

        return <Tooltip classes={{tooltip: classes.tooltip}} title={`${text}`} placement="top">
            {this.renderOption()}
      </Tooltip> 

    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withTranslation('drawerListItem')
)(DrawerListItem);