import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {NewUiWrapper, useSelectedAdminDrawer, DashboardView} from '@tuqqi/web'
import { store } from '../../../..';
import { makeStyles } from '@material-ui/styles';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions';
import { push } from 'react-router-redux';

const styles = theme => ({
    wrap: {

    }
});

const useStyles = makeStyles(styles)

const UID = 'admin-main-new'

const AdminMainNew = (props) => {
    const classes = useStyles()
    const drawerInfo = useSelectedAdminDrawer(UID)
    const {dispatch} = props
    
    const openSubscriptionDialog = (reason) => {
        if(!reason) return
        dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }

	   const navToAccount = () => {
		dispatch(push("/admin/organizationsettings"));
	  };
	
	  const navToAnalytics = () => {
		dispatch(push('/admin/analytics'))
	  };
	
	  const navToCustomization = () => {
		dispatch(push('/admin/navigation'))
	  };
	
	  const  navToSystem = () => {
		dispatch(push('/admin/logs'))
	  };


    const callbacks = {
        onShowSubscriptionModal: openSubscriptionDialog,
		navToSystem: navToSystem,
		navToAnalytics:  navToAnalytics,
		navToCustomization: navToCustomization,
		navToAccount: navToAccount,
    }
    const renderMain = () => {
        switch (drawerInfo.prop) {
            case 'home':
                return <NewUiWrapper store={store} callbacks={callbacks}>
                    <DashboardView />
                </NewUiWrapper>
            default:
                return null
        }
    }


    return renderMain()
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(AdminMainNew);
