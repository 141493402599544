import React, { PureComponent } from 'react';

export default class DeleteLaunchpadOptionIcon extends PureComponent {
    render() {
        const { className } = this.props
        return (
            <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6666 7.33337V13.6C12.6666 13.821 12.4875 14 12.2666 14H3.73325C3.51234 14 3.33325 13.821 3.33325 13.6V7.33337" stroke="#C4423B" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.66675 11.3334V7.33337" stroke="#C4423B" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.33325 11.3334V7.33337" stroke="#C4423B" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.33333 4.66667H10.6667M14 4.66667H10.6667H14ZM2 4.66667H5.33333H2ZM5.33333 4.66667V2.4C5.33333 2.17909 5.51242 2 5.73333 2H10.2667C10.4876 2 10.6667 2.17909 10.6667 2.4V4.66667H5.33333Z" stroke="#C4423B" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>            
        )
    }
}