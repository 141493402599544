const proccessSimpleText = (text = '', t) => {
    const strWithoutSpaces = text.trim()
    if(!strWithoutSpaces) return text
    const temp = t(strWithoutSpaces)
    const res = text.replace(strWithoutSpaces, temp)
    return res
}

const getMessageTemplateParam = (match, messageTemplateParams, title) => {
    const place = (match).replace(/[^\d]/gi, '')

    let res = messageTemplateParams[place] || ''
    if(res === title) res = ''
    return res
}

const getTemplate = (text, isBold) => {
    let res
    if (!text) {
        res = ''
    }
    else if (!isBold) {
        res = text
    }
    else {
        res = text
    }

    return res
}

const getRegExecution = (text) => {
    const reg = /\$\{\d\}/gi

    const res = reg.exec(text)
    if (!res) {
        return null
    }

    return {
        match: res[0],
        index: res.index,
        lastIndex: reg.lastIndex
    }
}

export const getNotificationAsParsedText = (messageTemplateParams, text, icon, title, t) => {
    const getSplitedText = () => {
        let tempText = text
        const splitedText = []

        let regExecution = getRegExecution(tempText)
    
        while (regExecution != null) {
            const _simpleText = tempText.substring(0, regExecution.index)
            const simpleText = proccessSimpleText(_simpleText, t)
            
            splitedText.push(getTemplate(simpleText, false))

            const messageTemplateParam = getMessageTemplateParam(regExecution.match, messageTemplateParams, title)
            splitedText.push(getTemplate(messageTemplateParam, true))

            tempText = tempText.substring(regExecution.lastIndex)
            regExecution = getRegExecution(tempText)
        }

        if (!splitedText.length || tempText) {
            splitedText.push(getTemplate(tempText, false))
        }    
        splitedText.unshift(icon)
        return splitedText
    }
    const splitedText = getSplitedText()
    return splitedText.join("")
}