import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import goTo from "../../../../actions/goTo";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { withMixpanel } from '../../../../components/Common/withMixpanel';

const styles = (theme) => ({
  title: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "#21212d",
    marginBottom: 0,
    marginTop: 0,
  },
  container: {
    paddingTop: 18,
    paddingBottom: 0,
  },
  follwoingTagsWrapper: {
    padding: 18,
    borderRadius: 8,
    backgroundColor: '#f3f6fb',
  },
  listTags: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: '100%'
  },
  tag: {
      backgroundColor: '#e6eaf0',
      padding: '10px 17px',
      borderRadius: 4,
      color: '#73778f',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 1.14,
      marginBottom: 8,
      cursor: 'pointer',
      transition: 'all .3s ease',
      border: 'solid 2px #e6eaf0',
      marginRight: 6,
      '&:hover': {
        color: theme.customColors.primary.b400,
        borderColor: theme.customColors.primary.b400,
    },
  },
  ...theme.rightDrawerClasses,
});

class FollowingInterestWidget extends Component {
  state = {
    followingTags: [],
  };

  componentDidMount() {
    this.setState({ followingTags: this.props.userData.subscribedTags });
  }

  handleHashTagClick = (tag) => {
    this.props.mixpanelTrack(`Following interests widget - go to ${this.renderTag(tag)}`, {})
    goTo(this.renderTag(tag), this.props.dispatch)()
  }

  renderTag = (tag) => {
    if(!tag?.tag) return ''
    return tag.tag.startsWith('#') ? tag.tag : '#'+tag.tag
  }

  render() {
    const { classes, t } = this.props;

    return (
      <>
          <Grid container className={classes.follwoingTagsWrapper}>
            <h3 className={classes.title}>{t("Following")}</h3>
            <Grid container classes={{ container: classes.container }}>
              <ul className={classes.listTags}>
                {this.state.followingTags && this.state.followingTags.map((tag) => {
                  return <li className={classes.tag} key={tag.id} onClick={() => this.handleHashTagClick(tag)}>{this.renderTag(tag)}</li>
                })}
              </ul>
            </Grid>
          </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  globalData: state.globalData,
  ...state.services,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch: dispatch };
};

export default compose(
  withStyles(styles),
  withTranslation("userFollowingWidget"),
  withMixpanel,
  connect(mapStateToProps, mapDispatchToProps)
)(FollowingInterestWidget);
