
import React from "react";
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";

const classes = theme => ({
    tableHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        border: `solid 1px #DADDEE`,  
        borderRight: `solid 0px #DADDEE`, 
        height: 30,  
    },
    columnNameContainer: {
        width: 190,
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRight: `solid 1px #DADDEE`,  
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4000000059604645,
    },
    columnAssignContainer: {
      width: 155,
      marginLeft: 25,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontFamily: 'Rubik',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4000000059604645,
    },
 })

const TableHeader = ({ classes,t }) => {
  return (
    <div data-intrcm-id="TableHeader_lan303w5" className={classes.tableHeaderContainer}>
        <div data-intrcm-id="TableHeader_djlbhcch" className={classes.columnNameContainer}>
        {t('Name')}
        </div>
        <div data-intrcm-id="TableHeader_4t407hpm" className={classes.columnAssignContainer}>
        {t('Assigned')} 
        </div>
    </div>
  );
};

export default compose(withStyles(classes), withTranslation('gantt'))(TableHeader);