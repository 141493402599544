import { MenuItem, TextField, withStyles } from '@material-ui/core';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import React from 'react';
import FormControlLabelSwitch from "./FormControlLabelSwitch";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    formControl: {
        margin: 5,
        display: 'flex'
    },
    selectMenu: { display: 'flex', alignItems: 'center' },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto'
    },
})

class CreateCustomInputWorkflow extends React.Component {
    render() {
        const { inputMappings, handleChange, classes, enableSettings, 
            changeAboveWorkflow, changeWorkflow, 
            triggeredCustomInput, quickActionText,t } = this.props;

        const { isEnableWorkflow, isAboveWorkflow } = enableSettings;

		return <div data-intrcm-id="CreateCustomInputWorkflow_hlpy9ac3">
            <FormControlLabelSwitch checked={isAboveWorkflow} onChange={changeAboveWorkflow} label={t("Enable above workflow")} disabled={isEnableWorkflow} />
            <TextField 
                disabled={!isAboveWorkflow} 
                className={classes.formControl} 
                select 
                value={triggeredCustomInput} 
                label={t('Triggered custom input')}
                onChange={handleChange('triggeredCustomInput')} 
            >
                {inputMappings
                    .filter(inputMapping => inputMapping.isEnableWorkflow)
                    .map(inputMapping => {
                        return <MenuItem 
                            value={inputMapping.customDataMapping.dataTypeFilter} 
                        >
                            {inputMapping.title}
                        </MenuItem>
                    })
                }
            </TextField>

            <FormControlLabelSwitch checked={isEnableWorkflow} onChange={changeWorkflow} label={t("Enable workflow")} disabled={isAboveWorkflow} />

            <TextField 
                className={classes.formControl} 
                disabled={!isEnableWorkflow} 
                label={t('Quick action')}
                onChange={handleChange('quickActionText')} 
                value={quickActionText} 
                margin='dense' 
            />
		</div>
    }
}

const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
})

export default compose(withStyles(styles),withTranslation('admin'), connect(mapStateToProps))(CreateCustomInputWorkflow);