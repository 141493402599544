import { Avatar, Icon, Tab, Tabs, Typography, withMobileDialog, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import DropDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DropRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showConfirm, showSnackbar } from '../../../actions/appActions';
import { updateSpecificItem } from '../../../actions/dataActions';
import { goToGroup } from "../../../actions/goToActions";
import parseImagUrl from "../Common/parseImagUrl";
import ItemPreviewCardHeaderMenu from "../FeedViewComponents/ItemPreviewComponents/itemPreviewCardHeaderMenu";
import { mapToTemplate, getRelevantCustomMapping } from '../Mappings/resultMappings';
import ItemAuthor from './ModalComponents/Author/ItemAuthor'
import TaskList from "./Checklist/taskList";
import FullViewComments from './FullViewComments';
import ItemFullViewContent from './itemFullViewContent';
import ItemFullViewEdit from './itemFullViewEdit';
import ItemFullViewSocial from './itemFullViewSocial';
import PostText from '../FeedViewComponents/ItemPreviewComponents/PostText';
import ConfirmReading from './ConfirmReading'
import AuthorInEditMode from './ModalComponents/Author/AuthorInEditMode';
import ClassificationSelector from './ClassificationSelector'
import GroupSelector from './GroupSelector.js';
import { getClassificationDisplayName, getItemGroupDisplayName } from './classificationsUtils.js';
import { canUserChangeGroup, isUserMemberOnItem, isUserAdminOnGroupItem, isUserHasPermissionOnGroupItem, isUserOrgAdmin, isUserOrgViewer, isUserItemAuthor } from '../Common/Utils/userPermissionsUtils.js';
import BestRelated from './BestRelated.js';
import TaskDetails from '../Common/Task/TaskDetails.js';
import TaskDetailsEditMode from '../Common/Task/TaskDetailsEditMode.js';
import { getDataType } from '../Common/Utils/defaultDataTypes.js';
import { FULLVIEW_CLOSE, INPUT_CLOSE } from '../../../actionTypes/actionTypes.js';
import { withMixpanel } from '../Common/withMixpanel.js';
import { updateSpecificItem as updateSpecificItemNewUI } from '@tuqqi/web';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const mediaMimeTypes = ["image/gif", "image/jpeg", "image/jpeg", "image/png", "video/mp4"];

// Apply some reset
const styles = theme => ({
    paper: {
        padding: '0px 30px 54px',
    },
    menuDialogRoot: {
        zIndex: 1601,
    },
    root: {
        minWidth: 768,//1024,
        borderRadius: 16,
        minHeight: 700,
        overflowX: 'hidden'
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 24,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#302f47',
        marginBottom: 13,
        textAlign: 'left'
    },
    titleRoot: {
        padding: '22px 0px 0px 24px',
    },
    font: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontStretch: 'normal',
        fontWeight: 'normal',
    },
    buttonIc: {
        marginTop: 8,
        backgroundColor: theme.customColors.primary.b600,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none',
    },
    buttonClass: {
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        marginTop: 8
    },
    correctAnswer: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1.2,
        letterSpacing: 0.2,
        color: '#4cbc52'
    },
    selectedComment: {
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.2,
        color: '#000000',
        paddingTop: 10,
        paddingBottom: 40
    },
    progress: {
        margin: 'auto'
    },
    rightMenu: {
        margin: '12px 12px 12px 4px',
        padding: '0px 12px 0px 12px',
        width: 6
    },
    cover: {
        width: '100%',
        height: 220,
        objectFit: 'cover'
    },
    divider: {
        marginRight: -30,
        marginLeft: -30,
    },
    scroller: {
        overflowX: 'hidden'
    },
    avatar: {
        backgroundColor: 'white'
    },
    labelContainer: {
        paddingTop: 0
    },
    flexContainer: {
        justifyContent: 'center'
    },
    tabRoot: {
        textTransform: 'initial',
        color: theme.customColors.primary.b600,
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.2
    },
    linkIcon: {
        color: theme.customColors.primary.b600,
        fontSize: 25,
    }

});

class ItemFullViewModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            groupSelectionOpen: false,
            groupSelectionAnchorEl: null,
            classificationSelectionOpen: false,
            classificationSelectionAnchorEl: null,
            hasBeenEdited: false,
            hasError: false,
            isSaving: false
        }

    }

    handlePrivate = isPrivate => () => {
        this.props.dispatch({ type: "INPUT_CHANGE", model: { isPrivate: isPrivate, permissions: [], groupUid: '' } })
        this.setState({ groupSelectionOpen: false })
    }

    handleClassification = (value) => () => {
        this.props.dispatch({ type: "INPUT_CHANGE", model: { classification: value } })
        this.setState({ classificationSelectionOpen: false })
    }

    handleCollection = (value) => () => {
        this.props.dispatch({ type: "INPUT_CHANGE", model: { isPrivate: false, permissions: [value], groupUid: value } })
        this.setState({
            groupSelectionOpen: false
        })
    }

    handleClose = () => {
        const { isNew, edit, dispatch } = this.props;

        if (this.state.hasBeenEdited) {
            // Update the item in the feed
            this.props.dispatch(updateSpecificItem(this.props.itemId));

            this.setState({ hasBeenEdited: false });
        }

        if (edit) {
            this.props.dispatch(showConfirm("Changes you have made will be lost Are you sure you want to close?", { type: 'FULLVIEW_CLOSE' }))
        } else {
            this.props.dispatch({ type: 'FULLVIEW_CLOSE' })
        }
    };

    setAccessToken = (html) => {
        const { dataType } = this.props.item;
        if (dataType === 'htmlwysiwyg' || dataType === 'question') {

            html = html.replace(new RegExp('access_token=___(.*?)___', 'g'), 'access_token=___' + this.props.userData.idToken + '___');
        }
        return html;
    }

    addMappingToItem(doc, docId) {
        // Set regex
        let model = { ...doc, docId }
        model.jsonData = this.setAccessToken(model.jsonData);


        var mapping = mapToTemplate(model, this.props.inputMappings.map(mapping => mapping.customDataMapping))

        let item = { ...model };

        try {
            var json = model.jsonData && model.jsonData.replace(/\r?\n|\r/g, '')
            json = json.replace(/↵/g, '')

            let _jsonData = json && JSON.parse(json);
            if (_jsonData) {
                model.parsedJsonData = _jsonData;
            }
        }
        catch (e) {

        }

        if (mapping) {
            item = { ...model, mapping: mapping, customFieldsMappings: mapping.customFieldsMappings, isCustomInput: true }
        }
        else {
            item = { ...model, isCustomInput: false }
        }

        return { item, model }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    addLinkedDocsToItem = (oldItem, oldModel) => {

        const { queryService, inputMappings } = this.props
        const item = { ...oldItem, linkedContacts: [], linkedCompanies: [] }
        let promise = new Promise(resolve => resolve({ item, model: oldModel }))

        const customMapping = getRelevantCustomMapping(item, inputMappings)

        if (customMapping) {
            const allowedDataTypes = []
            if (customMapping.isEnableContact) {
                allowedDataTypes.push('contact')
            }
            if (customMapping.isEnableCompany) {
                allowedDataTypes.push('company')
            }

            const relatedDocumentIds = item.relatedDocuments
                .filter(related => allowedDataTypes.includes(related.dataType))
                .map(r => r.relatedDocumentId)

            if (relatedDocumentIds.length) {
                const relatedQuery = {
                    size: relatedDocumentIds.length,
                    page: 0,
                    itemsIds: relatedDocumentIds
                }
                promise = queryService.SearchByIds(relatedQuery).then(res => {
                    const items = res.data.map(data => data.Value.Source)
                    item.linkedContacts = items.filter(data => data.dataType === 'contact')
                    item.linkedCompanies = items.filter(data => data.dataType === 'company')
                    return { item, model: oldModel }
                })
            }
        }

        return promise
    }



    componentWillReceiveProps(nextProps) {
        const { item, dispatch, queryService, searchQuery, itemId, classifications, inputDocument, collectionResult, t } = nextProps;

        if (nextProps.open) {
            // Load data if needs to be openned
            if (!nextProps.isLoaded) {

                queryService.searchById(itemId)
                    .then(result => {
                        if (!result.data || !result.data[0] || !result.data[0].Value) {
                            throw "unable to load data"
                        } else {
                            const { item, model } = this.addMappingToItem(result.data[0].Value.Source, itemId)

                            return this.addLinkedDocsToItem(item, model)
                        }

                    }).then(({ item, model }) => {
                        if (nextProps.edit) {
                            dispatch({ type: 'INPUT_OPEN', model: { ...model, permissions: [model.groupUid] } })
                        }

                        dispatch({ type: 'FULLVIEW_LOADED', item: item })
                    }).catch(e => {
                        console.error(e)
                        dispatch({ type: FULLVIEW_CLOSE })
                        dispatch({ type: INPUT_CLOSE })
                        console.error(e)
                        dispatch(showSnackbar(t('An error occurred while Loading the item')));
                        return
                    })
            }
        }

        if (!this.props.open && nextProps.open && nextProps.isNew) {
            const currClass = classifications.find(c => c.level === inputDocument.classification);

            // If the user is in a collection but no classification
            // make the collection as a default choice with its group uid
            if (collectionResult && collectionResult.collectionGroup && !currClass) {
                this.handleCollection(collectionResult.collectionGroup.groupUid)()
            }
        }
    }

    openEdit = () => {
        const { dispatch, item, itemId } = this.props;

        dispatch({ type: 'INPUT_OPEN', model: item })
        dispatch({ type: 'FULLVIEW_EDIT_LOAD', item: item, itemId: itemId })
    }


    createPreviewDescriptionForContact = (parsedJsonData) => {

        // We need to compute the description here because - docText is cut
        const arrProperties = ['jobtitle', 'company', 'email', 'phone']
        const data = this.createPreviewDescriptionFromParsedJsonData(parsedJsonData, arrProperties)

        return data
    }
    createPreviewDescriptionForCompany = (parsedJsonData) => {
        // We need to compute the description here because - docText is cut
        const arrProperties = ['id', 'type', 'email', 'phone']
        const data = this.createPreviewDescriptionFromParsedJsonData(parsedJsonData, arrProperties)

        return data
    }
    createPreviewDescriptionFromParsedJsonData = (parsedJsonData = {}, arrProperties = []) => {
        const previewDescription = arrProperties
            .filter(property => parsedJsonData[property] !== undefined &&
                parsedJsonData[property] !== null &&
                parsedJsonData[property] !== '')
            .map(property => parsedJsonData[property])
            .join(", ")

        return previewDescription
    }


    saveLinkedDocs = (toBeSavedItem, sourceId) => {
        const { inputService, inputDocument } = this.props;

        return this.createItemsToLink(toBeSavedItem, inputDocument.linkedDocs).then(ids => {
            ids.push(sourceId)
            const links = new Map()
            ids.forEach(id => {
                links.set(id, ids.filter(x => x !== id))
            })

            return Promise.all(Array.from(links.keys()).map(x => inputService.linkMultipleItems(x, links.get(x))))
        })
    }


    createItemsToLink = (savedItem, linkedDocs) => {
        const { inputService, inputDocument, oldUiInput } = this.props
        const docsToCreate = linkedDocs.filter(x => !x.docId)
        const existingDocIds = linkedDocs.filter(x => x.docId).map(x => x.docId)

        const promises = Promise.all(docsToCreate.map(linkedDoc => {
            const toBeSavedLinkedDoc = this.createSavedItem(linkedDoc)
            const finalDoc = {
                ...oldUiInput,
                ...toBeSavedLinkedDoc,
            }
            if (finalDoc.dataType == 'contact') {
                finalDoc.permissions = []
            }
            delete finalDoc.parsedJsonData
            return inputService.postItem({ ...oldUiInput, document: finalDoc })
        }))

        return promises.then(results => results.map(x => x.key).concat(existingDocIds))
    }

    save = () => {
        const { inputService, dispatch, itemId, isNew, t, inputDocument, oldUiInput, mixpanelTrack } = this.props;

        let toBeSavedItem = this.createSavedItem(inputDocument)

        let promise = isNew ? inputService.postItem({ ...oldUiInput, document: toBeSavedItem }) : inputService.updateItem(itemId, toBeSavedItem);
        this.setState({ isSaving: true })
        const hasLinkedItemsOnCreate = inputDocument.linkedDocs && inputDocument.linkedDocs.length

        promise.then(res => {
            const data = {
                item: isNew ? res.value : res.source,
                itemId: isNew ? res.key : res.id
            }
            if (hasLinkedItemsOnCreate) {
                return this.saveLinkedDocs(toBeSavedItem, data.itemId).then(docs => {
                    if (!isNew) {
                        const relatedDocs = docs.filter(related => related.id != data.itemId).map(related => {
                            related.source.docId = related.id
                            return related.source
                        })
                        data.item = docs.find(res => res.id == data.itemId).source
                        data.item.linkedContacts = relatedDocs.filter(doc => doc.dataType == 'contact')
                        data.item.linkedCompanies = relatedDocs.filter(doc => doc.dataType == 'company')
                    }
                    return data
                })
            }

            return data
        }).then((data) => {
            if (isNew) {
                dispatch({ type: 'FULLVIEW_CLOSE' })
                dispatch(updateSpecificItemNewUI(data.itemId ?? null,'add'));
                dispatch({ type: 'DATA_UPDATE' })
            } else {

                const { item, model } = this.addMappingToItem(data.item, data.itemId)
                dispatch({ type: 'FULLVIEW_OPEN', item, itemId: data.itemId });
                this.setState({ hasBeenEdited: true });
            }

            dispatch({ type: 'INPUT_CLOSE' })
            dispatch({ type: "INPUT_CHANGE", model: { classification: 0 } })
            mixpanelTrack('Created item', { dataType: inputDocument.dataType })
            dispatch(showSnackbar('Saved!'));
        }).catch(x => {
            console.error(x)

            dispatch(showSnackbar(t('An error occurred while saving')));
        }).finally(() => {
            this.setState({ isSaving: false })
        })

        dispatch(showSnackbar('Saving'));
    }

    createSavedItem = (inputDocument) => {
        const { inputMappings } = this.props;

        let toBeSavedItem = { ...inputDocument };
        // TODO: add Templates specific logics
        if (toBeSavedItem.parsedJsonData && typeof toBeSavedItem.parsedJsonData == 'object') {
            const jsonData = JSON.stringify(toBeSavedItem.parsedJsonData)

            const inputMapping = inputMappings.find(mapping => mapping.customDataMapping.dataTypeFilter == toBeSavedItem.dataType)
            if (inputMapping) {

                const fieldsToShow = inputMapping.customDataMapping.customFieldsMappings
                    .filter(field => field.previewDisplayOrder !== null)
                    .sort((field1, field2) => field1.previewDisplayOrder - field2.previewDisplayOrder)

                const previewDescriptionArray = []
                const previewDescriptionJsonObject = {}

                fieldsToShow.forEach(field => {
                    const value = toBeSavedItem.parsedJsonData[field.sourceField]
                    if (value !== '' && value !== null && value !== undefined && !(Array.isArray(value) && !value.length)) {
                        previewDescriptionJsonObject[field.sourceField] = {
                            fieldName: field.fieldName,
                            value,
                            previewDisplayOrder: field.previewDisplayOrder
                        }
                        previewDescriptionArray.push(field.fieldName + ": " + value)
                    }
                })

                const previewDescription = previewDescriptionArray.join(" · ")

                toBeSavedItem.docText = previewDescription;
                toBeSavedItem.previewDescription = previewDescription;

                toBeSavedItem.previewDescriptionJson = JSON.stringify(previewDescriptionJsonObject);
                toBeSavedItem.jsonData = jsonData;
            }
            else if (toBeSavedItem.dataType == "contact") {
                toBeSavedItem.docText = jsonData;
                toBeSavedItem.previewDescription = this.createPreviewDescriptionForContact(toBeSavedItem.parsedJsonData)

                toBeSavedItem.jsonData = jsonData;
            }
            else if (toBeSavedItem.dataType == "company") {
                toBeSavedItem.docText = jsonData;
                toBeSavedItem.previewDescription = this.createPreviewDescriptionForCompany(toBeSavedItem.parsedJsonData)

                toBeSavedItem.jsonData = jsonData;
            }
        }

        return toBeSavedItem

    }


    openGroup = (e, group) => {
        this.handleClose();
        goToGroup('/' + group.title, this.props.dispatch, group.collectionUid)();
        e.stopPropagation()
    }

    isUserHasPermissionsToEditItem = () => {
        const { userData, item, inputMappings } = this.props;
        const { profileData, collections } = userData;
        const customMapping = getRelevantCustomMapping(item, inputMappings)

        const editableTypesForEveryone = ["contact", "company"]
        const userUid = profileData.auth0_id;

        let allowed = false
        if (isUserOrgViewer(profileData)) {
            allowed = false
        } else if (customMapping && customMapping.isEveryoneCanEdit) {
            allowed = true
        } else if (editableTypesForEveryone.includes(item.dataType)) {
            allowed = true
        } else if (item.isEveryoneCanEdit) {
            allowed = true
        } else if (isUserOrgAdmin(profileData)) {
            allowed = true
        } else if (isUserItemAuthor(profileData, item)) {
            allowed = true
        } else if (isUserMemberOnItem(item, profileData)) {
            allowed = true
        } else if (isUserAdminOnGroupItem(item, collections, profileData) ||
            isUserHasPermissionOnGroupItem(item, collections)) {
            allowed = true
        }

        return allowed
    }

    isDataTypeEditable = () => {
        const { item, inputMappings } = this.props;

        const allowedStaticDataTypes = ["htmlwysiwyg", "contact", "code", "company"]

        let allowed = false
        if (allowedStaticDataTypes.includes(item.dataType)) {
            allowed = true
        } else if (inputMappings.some(mapping => mapping.customDataMapping.dataTypeFilter === item.dataType)) {
            allowed = true
        }

        return allowed
    }

    canEdit = () => {
        return this.isDataTypeEditable() && this.isUserHasPermissionsToEditItem()
    }

    saveButtons = () => {
        const { classes, inputDocument, openCollections, userData, classifications, isNew, t } = this.props;

        const changeGroup = <Fragment>
            <Button size="small" color="primary" disabled={!isNew}
                onClick={(event) => { this.setState({ groupSelectionOpen: !this.state.groupSelectionOpen, groupSelectionAnchorEl: event.currentTarget }) }}
                className={classes.buttonClass} >
                {getItemGroupDisplayName(t, openCollections, userData, inputDocument)}
                {isNew && <DropDownIcon />}
            </Button>
            <Button size="small" color="primary" disabled={!isNew}
                onClick={(event) => { this.setState({ classificationSelectionOpen: !this.state.classificationSelectionOpen, classificationSelectionAnchorEl: event.currentTarget }) }}
                className={classes.buttonClass} >
                {getClassificationDisplayName(classifications, inputDocument.classification)}
                {isNew && <DropDownIcon />}
            </Button>
            <GroupSelector open={this.state.groupSelectionOpen}
                onClose={x => this.setState({ groupSelectionOpen: false })}
                anchorEl={this.state.groupSelectionAnchorEl}
                item={inputDocument}
                handlePrivate={this.handlePrivate}
                handleCollection={this.handleCollection} />
            <ClassificationSelector open={this.state.classificationSelectionOpen}
                onClose={x => this.setState({ classificationSelectionOpen: false })}
                anchorEl={this.state.classificationSelectionAnchorEl}
                item={inputDocument}
                handleClassification={this.handleClassification} />
        </Fragment>

        const saveButton = (
            < div >
                {canUserChangeGroup(userData, inputDocument) || isNew ? changeGroup : null}
                <Button id='save-item-button' disabled={inputDocument.error || this.state.isSaving} size="small" variant="raised" onClick={this.save} className={[classes.font, classes.buttonIc].join(" ")}>
                    {t('Save')}
                </Button>
            </div >)

        if (isNew || this.canEdit()) {
            return saveButton;
        }
        else {
            return null;
        }
    }

    editButton = () => {
        const { classes, t } = this.props;

        let editButton = <div data-intrcm-id="itemFullViewModal_d74opzzf">
            <Button size="small" variant="raised" onClick={this.openEdit} className={[classes.font, classes.buttonIc].join(" ")}>
                {t('Edit')}
            </Button>
        </div>

        if (this.canEdit()) {
            return editButton;
        }
        else {
            return null;
        }
    }

    author = () => {
        const { edit, isNew, item } = this.props

        if (isNew || edit) {
            return <AuthorInEditMode item={item} />
        } else {
            return <ItemAuthor item={item} showTimeToRead={true} />
        }
    }

    itemMetaData = () => {
        const { item, itemId, classes, t } = this.props;

        return <div data-intrcm-id="itemFullViewModal_l9xqnx58" style={{ padding: 0 }}>
            {item.selectedComment
                ? <div data-intrcm-id="itemFullViewModal_3dd9s45v" style={{ padding: 30 }}>
                    <div data-intrcm-id="itemFullViewModal_l6qdiwq9" className={[classes.font, classes.correctAnswer].join(" ")}>
                        {t('The correct answer')}
                    </div>
                    <div data-intrcm-id="itemFullViewModal_acbzemrs" className={[classes.font, classes.selectedComment].join(" ")}>
                        {item.selectedComment}
                    </div>
                </div>
                : null
            }

            <Divider style={{ backgroundColor: '#e1e2e3', marginLeft: 30, marginRight: 30, marginBottom: 20, marginTop: 20 }} />

            <ItemFullViewSocial />

            <div data-intrcm-id="itemFullViewModal_vsp533dn" style={{ backgroundColor: '#f5f5f5', borderTop: 'solid 1px #e1e2e3', padding: 30, borderBottom: 'solid 1px #e1e2e3' }}>
                <FullViewComments docId={itemId} isFullView={true} updateSpecificItem={() => { this.setState({ hasBeenEdited: true }) }}
                // onTaskAdd={this.onTaskAdd}
                // onCommentAdd={(comment) => { this.setState({ comments: [...this.state.comments, comment] }) }}
                />
            </div>
        </div>
    }

    defaultArr = []

    removeFromFavorites = () => {
        this.handleClose();
        this.props.dispatch({ type: 'DATA_UPDATE' })
    }

    showCover = () => {
        const { classes, item, cloudfront } = this.props;
        let previewImgUrl = item.previewImgUrl;

        let S3Url = 'https://app.tuqqi.com/covers/'

        const dataType = getDataType(item.dataType)
        if (dataType) {
            previewImgUrl = dataType.backgroundUrl;
        }

        switch (item.dataType) {
            case 'question':
                if (item.selectedComment) {
                    previewImgUrl = S3Url + 'queation_answerd.png';
                }
                else {
                    previewImgUrl = S3Url + 'queation_unanswerd.png';
                }

                break;
            case 'htmlwysiwyg':
                if (item.previewImgUrl) {
                    previewImgUrl = parseImagUrl(cloudfront, item.previewImgUrl, 965)
                }
                break;
            default:
                if (item.mapping)
                    previewImgUrl = item.previewImgUrl ? parseImagUrl(cloudfront, item.previewImgUrl, 965) : '';
                break;
        }

        if (!previewImgUrl) {
            previewImgUrl = S3Url + 'costum.png';
        }

        return <img src={previewImgUrl} className={classes.cover} onError={() => { this.setState({ error: true }) }} />
    }

    linkTabs = () => {
        const { classes, item } = this.props;

        return item.links && item.links.length
            ? <div data-intrcm-id="itemFullViewModal_9psah7uk" >
                <Tabs centered={true} classes={{ scroller: classes.scroller, flexContainer: classes.flexContainer }} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" style={{ paddingBottom: 10 }}>
                    {item.links.map((docLink, idx) => {
                        return <Tab label={docLink.title} value={idx} classes={{ root: classes.tabRoot, labelContainer: classes.labelContainer }}
                            icon={<Avatar className={classes.avatar}>
                                <Icon classes={{ root: classes.linkIcon }} >{docLink.icon}</Icon>
                            </Avatar>}
                            onClick={() => { window.open(docLink.link, '_blank') }} />
                    })}
                </Tabs>
                <Divider classes={{ root: classes.divider }} />
            </div>
            : <div data-intrcm-id="itemFullViewModal_xu73jiao" />
    }

    getConfirmReadingButton = item => {
        const { mustReadUsers, mustReadUsersApproved, docId } = item
        const { userData } = this.props

        if (!mustReadUsers) {
            return;
        }

        if (!mustReadUsers.find(x => x === userData.profileData.auth0_id)) {
            return;
        }

        const buttonProps = {
            mustReadUsersApproved: mustReadUsersApproved || [],
            docId
        }

        return <ConfirmReading {...buttonProps} />
    }

    render() {
        const { open, edit, classes, isNew, inputDocument, isLoaded, item, itemId, fullScreen, inFavorites, t } = this.props;

        if(!open){
            return null;
        }

        if (this.state.hasError) {
            return <Dialog
                fullScreen={fullScreen}
                scroll={'body'}
                classes={{ paper: classes.root }}
                open={open}
                onClose={this.handleClose}
                transition={Transition}
            >
                <div data-intrcm-id="itemFullViewModal_psmx0lmu" style={{ padding: 16 }}>
                    Error: please contact Tuqqi support
                </div>
            </Dialog>
        }

        if (isLoaded || isNew || edit) {
            return (
                <div data-intrcm-id="itemFullViewModal_8nv2ydvb">
                    <Dialog
                        fullScreen={fullScreen}
                        scroll={'body'}
                        classes={{ paper: classes.root }}
                        open={open}
                        onClose={this.handleClose}
                        transition={Transition}
                    >
                        {!edit ? this.showCover() : <div data-intrcm-id="itemFullViewModal_q0vuwtth" />}
                        <DialogTitle classes={{ root: classes.titleRoot }} >
                            <Grid wrap='nowrap' container spacing={16} justify="space-between">
                                <Grid item>
                                    {!edit ? <Typography dir='auto' variant="title" className={classes.title} gutterBottom >
                                        {item.dataType === 'event' && item.isEventCancelled ? `Cancelled: ${item.title}` : item.title}
                                    </Typography>

                                        : <div data-intrcm-id="itemFullViewModal_58rdn5bs" />}

                                    {this.author()}
                                    {edit ? <TaskDetailsEditMode docId={itemId} item={inputDocument} /> : <TaskDetails docId={itemId} item={item} />}
                                </Grid>
                                <Grid item>
                                    <div data-intrcm-id="itemFullViewModal_kwpn2fy4" style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>

                                        {this.getConfirmReadingButton(item)}

                                        {edit || isNew ? this.saveButtons() : this.editButton()}
                                        {isNew
                                            ? <div data-intrcm-id="itemFullViewModal_wnwnq656" style={{ width: 30 }} />
                                            : <div data-intrcm-id="itemFullViewModal_0tt5o2hk" style={{ marginTop: -4 }}>
                                                <ItemPreviewCardHeaderMenu
                                                    dialogClass={classes.menuDialogRoot}
                                                    relatedDocuments={item.relatedDocuments || this.defaultArr}
                                                    docId={itemId}
                                                    result={item}
                                                    buttonClass={classes.rightMenu}
                                                    onFavourites={() => { inFavorites ? this.removeFromFavorites() : null }} />
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider classes={{ root: classes.divider }} style={{ marginTop: 24 }} />
                            {!edit ? this.linkTabs() : <div data-intrcm-id="itemFullViewModal_q56omga9" />}

                        </DialogTitle>

                        <DialogContent style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 0, paddingTop: 24, overflowY: 'visible' }} classes={{ root: classes.paper }} elevation={1}>
                            <div data-intrcm-id="itemFullViewModal_6bzv6js8" >
                                {edit ? <ItemFullViewEdit /> :
                                    <Fragment>
                                        <PostText postText={item.postText} dataType={item.dataType} isFullView={true} />
                                        <ItemFullViewContent />
                                    </Fragment>}
                                {isNew ? null : (<Fragment>
                                    <Divider style={{ backgroundColor: '#e1e2e3', margin: '50px 30px 0px 30px' }} />
                                    <BestRelated />
                                    <TaskList itemId={itemId} />
                                </Fragment>)}
                            </div>
                            {isNew ? <div data-intrcm-id="itemFullViewModal_d6u6ubjv" /> : this.itemMetaData()}

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                {t('Close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            );
        }
        else {
            return <Dialog scroll={'body'} classes={{ paper: classes.root }} open={open} onClose={this.handleClose} transition={Transition} >
                <CircularProgress className={classes.progress} />
            </Dialog>
        }
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    searchQuery: state.search,
    // users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    filestack: state.globalData.filestack,
    classifications: state.globalData.classifications,
    openCollections: state.globalData.openCollections,
    collectionResult: (state.results && state.results.channel && state.results.channel.collectionGroup && state.results.channel),
    ...state.services,
    ...state.fullView,
    inputDocument: state.oldUiInput.document,
    oldUiInput: state.oldUiInput,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withTranslation('itemFullViewModal'),
    withStyles(styles, { withTheme: true }),
    withMixpanel,
    withErrorCatcher(),
    withMobileDialog({ breakpoint: 'xs' }),
    connect(mapStateToProps, mapDispatchToProps),
)(ItemFullViewModal);