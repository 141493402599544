import React from 'react';
import SocialDataContainer from './SocialDataContainer';

const CommentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="CommentIcon__a" d="M10.667.167H1.333C.692.167.167.692.167 1.333v10.5L2.5 9.5h8.167c.641 0 1.166-.525 1.166-1.167v-7c0-.641-.525-1.166-1.166-1.166zm0 8.166H2.5L1.333 9.5V1.333h9.334v7z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                <mask id="CommentIcon__b" fill="#fff">
                    <use href="#CommentIcon__a" />
                </mask>
                <g fill="#979AAA" mask="url(#CommentIcon__b)">
                    <path d="M0 0H14V14H0z" transform="translate(-1 -1)" />
                </g>
            </g>
        </svg>
    )
}

class SocialDataComment extends React.PureComponent {
    
    render() {
        const { commentsCounter } = this.props

        if (commentsCounter === 0) {
            return null
        }

        return (
         <SocialDataContainer 
         icon={<CommentIcon />} 
         text={commentsCounter} 
         tooltipText='comment' 
         total={commentsCounter} />

        )

    }
}

export default SocialDataComment