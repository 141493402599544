import React, { PureComponent } from 'react';
import { withStyles, ListItem, ListItemText, Paper } from '@material-ui/core';
import UserAvatar from '../../../components/Common/UserAvatar';
import goTo from '../../../actions/goTo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';

const styles = theme => ({
    nodeContainer: {
        'min-width': '250px',
        display: 'inline-block',
        cursor: 'pointer'
    }
});

class NodeItem extends PureComponent {

    openUser = (e) => {
        const { node:{ tag }, dispatch } = this.props

        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()

        goTo(userTagFormat, dispatch)()
        e.stopPropagation()
    }


    render() {
        const { node, onClick, classes } = this.props
  
        const sumChildren  = node.sumChildren > 0 ? `(${node.sumChildren + 1})` : ''

        return (
            <Paper key={node.id} className={classes.nodeContainer} onClick={this.openUser} >
            
            <ListItem >
                <UserAvatar onClick={this.openUser} userUUID={node.auth0_id}  />
                <ListItemText primary={ `${node.firstname} ${node.lastname}  ${sumChildren}`}  secondary={node.title} />
            </ListItem></Paper>
        );
    };


}


const mapStateToProps = (state) => ({
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading User, Please Contact Tuqqi support"),
    connect(mapStateToProps, mapDispatchToProps))(NodeItem);
