import { Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';

const styles = theme => ({
    privacyWarning: {
        marginLeft: 20,
        marginTop: 16,
        fontSize: 14
    },
})

class EditWarning extends PureComponent {
    render() {
        const { classes, text } = this.props;

        return (
            <Typography className={classes.privacyWarning}>
                {text}
            </Typography>)
    }
}

export default compose(withStyles(styles))(EditWarning);