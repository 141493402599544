import React, { useState, useEffect } from "react";
import { withStyles, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { parseImageUrl, getPreviewImageFromAttachments } from "@tuqqi/common";
import { withOpenFullview } from "../../../new-ui/withOpenFullview.js";
import imgPlaceholder from "../../../assets/img/knowledge_item_placeholder.png";
import { ErrorBoundary } from "react-error-boundary";
import { compose } from "recompose";
import CardMenu from "./cardMenu";

const styles = (theme) => ({
  article: {
    borderRadius: "4px",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    border: "solid 1px #dcdde0",
    backgroundColor: "#ffffff",
    marginBottom: "15px",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
  },
  articleImg: {
    overflow: "hidden",
    borderRadius: "4px",
  },
  textHolder: {
    width: 605,
    padding: "5px 15px",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "flex-start",
    height: 155,
    position: "relative",
  },
  articleTopText: {
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "2px",
    color: "#b8b8b8",
    marginBottom: "5px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  articleTitle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: "#21212d",
    marginBottom: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    width: "calc(100% - 50px)",
  },
  articleDescription: {
    height: 44,
    fontFamily: "RubikRoman, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#7a7a7a",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    textAlign: "left",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "100%",
  },
  articleBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.17",
    letterSpacing: "normal",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 15,
  },
  membersHolder: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  membersList: {
    color: "#21212d",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: 1.17,
  },
  smallText: {
    color: "#b8b8b8",
    display: "block",
    fontSize: "12px",
    lineHeight: 1.17,
  },
  cardMenuWrapper: {
    position: "absolute",
    top: "15px",
    right: "15px",
  },
  usersImages: {
    marginRight: "7px",
    display: "flex",
    flexDirection: "row",
  },
  imgAvatar: {
    borderRadius: "50%",
    top: "2px",
    position: "relative",
    border: "2px solid white",
  },
  amountMembers: {
    width: "28px",
    height: "28px",
    borderRadius: "100%",
    border: "solid 2px #fff",
    backgroundColor: "#f0f0f0",
    textAlign: "center",
    lineHeight: "28px",
    position: "relative",
  },
});

const CustomButton = withStyles({
  root: {
    borderRadius: "4px",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    border: "solid 1px #dcdde0",
    backgroundColor: "#ffffff",
    marginBottom: "15px",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: "100%",
    textTransform: "initial",
  },
})(Button);

const ErrorFallback = () => {
  return <p>Failed Loading Knowledge Article, Please Contact Tuqqi Support</p>;
};

const Article = ({
  dataArticle,
  classes,
  card,
  cloudfront,
  openFullview,
  users,
}) => {
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [membersNames, setMembersNames] = useState([]);
  const [seenCount, setSeenCount] = useState(0);
  const [currentCard, setCurrentCard] = useState(null);
  const [memberPhotoUrl, setMemberPhotoUrl] = useState([]);
  const [amountMembers, setAmountMembers] = useState(0);
  const [articleImageUrl, setArticleImageUrl] = useState("");

  const openCard = () => {
    const { doc, id } = card;
    openFullview(doc, id, true);
  };

  const calcAvatarPisition = (index) => {
    if (!index) {
      return "0px";
    }
    return `-${index * 10}px`;
  };

  const calcBlockMargin = (length) => {
    if (!length && length === 1) {
      return "0px";
    }
    return `-${(length - 1) * 10}px`;
  };

  const calcLeftOffset = (length) => {
    return `-${length * 10}px`;
  };

  useEffect(() => {
    const members = dataArticle.Value.Source.members;
    const currentMemberName = members.map((item) => item.userName).join();

    const articleMemberImages = members.map((member) => {
      const user = users.find((u) => u.auth0_id === member.userUUID);
      return user.profileImageUrl;
    });

    if (members.length > 3) {
      const names = `${members
        .map((item) => item.userName)
        .slice(0, 2)
        .join()} & ${members.length - 2} others`;

      const images = articleMemberImages.slice(0, 3);
      setMembersNames(names);
      setMemberPhotoUrl(images);
    } else {
      setMembersNames(currentMemberName);
      setMemberPhotoUrl(articleMemberImages);
    }
  }, [dataArticle, users]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    dataArticle.Value.Source.title &&
      setTitleText(dataArticle.Value.Source.title);
    // eslint-disable-next-line no-unused-expressions
    dataArticle.Value.Source.docText &&
      setDescriptionText(dataArticle.Value.Source.docText);
    setSeenCount(dataArticle.Value.Source.seen.length);
    setAmountMembers(dataArticle.Value.Source.members.length);
  }, [dataArticle]);

  useEffect(() => {
    const parseUrl = parseImageUrl(cloudfront.data);
    const imageUrl = getPreviewImageFromAttachments(dataArticle.Value.Source);

    try {
      const parsedUrl = parseUrl(imageUrl);
      setArticleImageUrl(parsedUrl);
    } catch (e) {
      setArticleImageUrl(imgPlaceholder);
    }
  }, [cloudfront]);

  useEffect(() => {
    setCurrentCard(card);
  }, [card]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CustomButton variant="contained" onClick={openCard}>
          <img
            alt="Article"
            src={articleImageUrl}
            width={165}
            height={165}
            className={classes.articleImg}
          />
        <div data-intrcm-id="article_a9i8llcp" className={classes.textHolder}>
          <Typography className={classes.articleTopText}>Article</Typography>
          <Typography className={classes.articleTitle}>{titleText}</Typography>
          <Typography className={classes.articleDescription}>
            {descriptionText}
          </Typography>
          <div data-intrcm-id="article_7nnsy5ah" className={classes.articleBottom}>
            <div data-intrcm-id="article_cy2qs9td" className={classes.membersHolder}>
              {memberPhotoUrl.length ? (
                <div data-intrcm-id="article_epnveeb3" className={classes.usersImages}>
                  {memberPhotoUrl.map((url, index) => (
                    <img
                      src={url}
                      alt="avatar"
                      width={28}
                      height={28}
                      className={classes.imgAvatar}
                      key={index}
                      style={{ left: calcAvatarPisition(index) }}
                    />
                  ))}
                  {amountMembers > 3 && (
                    <div data-intrcm-id="article_tqzthfkm"
                      className={classes.amountMembers}
                      style={{
                        left: calcLeftOffset(memberPhotoUrl.length),
                      }}
                    >
                      {amountMembers - 2}+
                    </div>
                  )}
                </div>
              ) : null}
              <div data-intrcm-id="article_rzi6snjv"
                style={{ marginLeft: calcBlockMargin(memberPhotoUrl.length) }}
              >
                <Typography className={classes.smallText}>
                  Written by
                </Typography>
                <Typography className={classes.membersList}>
                  {membersNames}
                </Typography>
              </div>
            </div>
            <Typography className={classes.smallText}>
              {`${seenCount} views`}
            </Typography>
          </div>
        </div>
        {currentCard && (
          <div data-intrcm-id="article_eof1atq6" className={classes.cardMenuWrapper}>
            <CardMenu card={currentCard} classes={{ menuIcon: "menu" }} />
          </div>
        )}
      </CustomButton>
    </ErrorBoundary>
  );
};

export default compose(withStyles(styles), withOpenFullview)(Article);
