import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { drawerToggle } from '../../../../actions/appActions';
import { withTranslation } from 'react-i18next';
import { goToEditProfile } from '../../../actions/goToEditProfile.js';

const drawerWidth = '280px';
const drawerHeight = 'auto';

// Apply some reset
const styles = theme => ({
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        height: drawerHeight,
        // backgroundColor: theme.palette.background.default,
        border: 0
    },
    text: {
        ...theme.newLeftDrawerClasses.text,
        fontSize: 14,
        cursor: "pointer"


    },
    ...theme.newLeftDrawerClasses
});


class settingsDrawer extends Component {
    state = {
        isTitle: false,
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(drawerToggle({
            leftWidgetDrawer: false,
        }))
    }

    render() {
        const { classes, dispatch, selected, t } = this.props;

        return (
            <Drawer className={this.props.className}
                variant="persistent"
                classes={{ paper: classes.drawerPaper }}
                open={true}
            >
                <List>
                    <ListItem>
                        <ListItemText secondary={t("SETTINGS")} />
                    </ListItem>


                    <ListItem
                        selected={selected === "user"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { goToEditProfile(dispatch) }}>
                        <ListItemText
                            disableTypography
                            secondary={<Typography>{t('User settings')}</Typography>}
                        />
                    </ListItem>

                    <ListItem
                        selected={selected === "dashboard"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/userdashboard')) }}>
                        <ListItemText
                            disableTypography
                            secondary={<Typography>{t('User dashboard')}</Typography>}
                        />
                    </ListItem>
                    <ListItem
                        selected={selected === "query"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/queryviews')) }}>
                        <ListItemText
                            disableTypography
                            secondary={<Typography>{t('Alerts (comming soon)')}</Typography>}
                        />
                    </ListItem>                    
                </List>
            </Drawer>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,

    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('settingsDrawer'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(settingsDrawer);