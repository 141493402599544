import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { counterInstance } from '../lib/title/DocTitle';
import { managerGanttRef, managerGroupGantt, managerTableKeepGlobalData } from '@tuqqi/web';
import { allowDragWhenSort } from '../lib/grid/GridOverride';

const getUpdatedLanesViews = (lists, oldLaneIndex, newLaneIndex) => {
    const newLists = [...lists].sort((a, b) => a.order - b.order)
    const [removed] = newLists.splice(oldLaneIndex, 1);
    newLists.splice(newLaneIndex, 0, removed);

    return newLists.map((l, i) => ({...l, order: i}))
}

const getUpdatedLaneItems = (items, oldCardIndex, newCardIndex) => {
    const newLists = [...items].sort((a, b) => a.order - b.order)
    const [removed] = newLists.splice(oldCardIndex, 1);
    newLists.splice(newCardIndex, 0, removed);

    return newLists.map((l, i) => ({...l, order: i}))
}
//item, lane, defaultLane, root
const getCase = (startRecord) => {
    if(!!startRecord?.originalData?.isRootRow) return 'root'
    if(!!startRecord?.originalData?.isLane && startRecord?.originalData?.id === -1) return 'defaultLane'
    if(!!startRecord?.originalData?.isLane && startRecord?.originalData?.id !== -1) return 'lane'
    if(!!startRecord?.originalData?.isItemRow) return 'item'
    return 'item'
}

//'itemEnd'
const getCaseForDragEnd = (context) => {
    if(!context.insertBefore && !!context?.startRecord?.originalData?.isItemRow && !!context?.parent?.originalData?.isLane) return 'itemEnd'

    return ''
}

const deniedDropTypes = ['root', 'defaultLane']
const denyDropType = (type) => {
    return deniedDropTypes.includes(type)
}

const resolveLaneDrag = (context) => {
    let v = true
    if(!context.insertBefore) v = false
    if(!context.parent?.originalData?.isRootRow) v = false
    if(!context.insertBefore?.originalData?.isLane) v = false
    if(context.insertBefore?.originalData?.id === -1) v = false

    return v
}

const resolveItemDrag = (context) => {
    let v = true
    if(!context.insertBefore) v = false
    if(!context.parent?.originalData?.isLane) v = false
    if(context?.insertBefore?.parent?.originalData?.id === -1 && context?.parent?.originalData?.id === -1) v = false
    if(context?.startRecord?.parent?.originalData?.id !== -1 && context?.parent?.originalData?.id === -1) v = true
    // if(context?.startRecord.isNew) v = false

    return v
}

const resolveItemDragForEnd = (context) => {
    let v = true
    if(!!context.insertBefore) v = false
    if(!context.parent?.originalData?.isLane) v = false
    if(context?.parent?.originalData?.id === -1) v = false
    if(context?.startRecord?.parent?.originalData?.id !== -1 && context?.parent?.originalData?.id === -1) v = true
    if(!context.startRecord?.originalData?.isItemRow) v = false
    // if(context?.startRecord.isNew) v = false


    return v
}

const getDataForMoveLane = (context) => {
    let oldInd = context?.oldPositionContext?.[0]?.parentIndex ?? -1
    let newInd = context?.startRecord?.parentIndex ?? -1

    if(oldInd !== -1) oldInd = oldInd - 1
    if(newInd !== -1) newInd = newInd - 1

    return {
        oldInd,
        newInd,
        valid: oldInd !== -1 && newInd !== -1
    }
}

const getDataForMoveTask = (context) => {
    const parentId = context?.parent?.id ?? -1
    const fromParentId = context?.oldPositionContext?.[0]?.parentId ?? -1
    const isSameLane = parentId === fromParentId
    let oldInd = context?.oldPositionContext?.[0]?.parentIndex ?? -1
    let newInd = context?.startRecord?.parentIndex ?? -1
    const id = context?.startRecord?.id ?? -1
    

    const valid = [oldInd, newInd, id].every(x => x !== -1)

    return {
        isSameLane,
        valid,
        oldInd,
        newInd,
        id,
        parentId,
        fromParentId
    }
}


export const useGanttReorder = (opts) => {
    const {collectionInfo, groupService, dispatch} = opts
    const ganttViewRef = useRef(undefined)
    const colUidRef = useRef(undefined)
    const colIdRef = useRef(undefined)
    const updateMove = useRef(false)

    const ganttView = useMemo(() => {
        if(!collectionInfo || !Object.keys(collectionInfo).length) return undefined
        const gantt = collectionInfo?.collectionViews?.find?.(x => x.collectionViewType === 'Gantt')
        return gantt
    }, [collectionInfo])

    useEffect(() => {
        ganttViewRef.current = ganttView
    }, [ganttView])

    useEffect(() => {
        if(!collectionInfo) {
            colUidRef.current = undefined
            colIdRef.current = undefined
            return
        }
        colUidRef.current = collectionInfo?.collectionUid ?? undefined
        colIdRef.current = collectionInfo?.id ?? undefined
    }, [collectionInfo])

    const moveLane = useCallback((oldInd, newInd) => {
        if(!ganttViewRef.current) return
        if(!colIdRef.current || !colUidRef.current) return

        ganttViewRef.current.itemLists = getUpdatedLanesViews(ganttViewRef.current.itemLists, oldInd, newInd)
        const updatedItemLists = ganttViewRef.current.itemLists.map((el, i) => ({ ...el, order: i}))
        ganttViewRef.current.itemLists = updatedItemLists

        const collection = {
            id: colIdRef.current,
            collectionUid: colUidRef.current,
            collectionViews: [
                {
                    id: ganttViewRef.current.id,
                    itemLists: updatedItemLists
                }
            ]
        }

        groupService.moveGanttLane(collection)
        updateMove.current = true
        setTimeout(() => {
            dispatch({
                type: 'RESULT_CHANNEL_VIEWS_GANTT',
                itemLists: updatedItemLists
            })
        }, 100)

    }, [])

    const moveCardInLane = useCallback((laneId, id, oldInd, newInd) => {
        if(!laneId) return
        if(!ganttViewRef.current) return
        if(!colUidRef.current) return
        const list = ganttViewRef.current?.itemLists?.find(x => x.id === laneId)
        if(!list || !list?.items?.length) return

        list.items = getUpdatedLaneItems(list.items, oldInd, newInd)
        const updatedItemLists = ganttViewRef.current.itemLists.map((el, i) => el.id === laneId ? list : el)

        const cardsIdsInOrder = list.items.map(x => x.id)
        const moveInfo = {
            collectionUid: colUidRef.current,
            laneId,
            cardsIdsInOrder

        }

        groupService.moveGanttCardInLane(moveInfo)
        updateMove.current = true
        setTimeout(() => {
            dispatch({
                type: 'RESULT_CHANNEL_VIEWS_GANTT',
                itemLists: updatedItemLists
            })
        }, 100)
    }, [])

    const onMoveItemBetweenLanes = useCallback((itemId, fromLaneId, toLaneId, newCardIndex) => {
        if(fromLaneId === toLaneId) return
        if(!ganttViewRef.current) return
        if(!colUidRef.current) return

        const moveLane = {
            collectionId: colUidRef.current,
            docTitle: '',
            sourceLaneId: fromLaneId,
            targetLaneId: toLaneId,
            cardId: itemId,
            position: newCardIndex,
            collectionViewType: 'Gantt'
        }

        groupService.moveGanttCard(moveLane).then((res) => {})
        updateMove.current = true
        setTimeout(() => {
            dispatch({
                type: 'RESULT_CHANNEL_MOVE_CARD_BETWEEN_LANES_GANTT',
                docId: itemId, 
                fromLaneId, 
                toLaneId, 
                newCardIndex
            })
        }, 100)
    }, [])


    const gridRowDrag = useCallback(({context}) => {
        if(!context.startRecord) {
            context.valid = false
            return false
        }

        const typeEndDrag = getCaseForDragEnd(context)

        if(typeEndDrag === 'itemEnd') {
            const valid = resolveItemDragForEnd(context)
            context.valid = valid
            return valid
        }

        if(!context.insertBefore) {
            context.valid = false
            return false
        }

        const type = getCase(context.startRecord)
        if(!!denyDropType(type)) {
            context.valid = false
            return false
        }
        switch (type) {
            case 'lane': {
                const valid = resolveLaneDrag(context)
                context.valid = valid
                return valid
            }
            case 'item': {
                const valid = resolveItemDrag(context)
                context.valid = valid
                return valid
            }
            default: {
                context.valid = false
                return false
            }
        }
    }, [])

    const gridRowBeforeDropFinalize = useCallback(({context}) => {
        if(getCase(context.startRecord) === 'item' && !context?.startRecord?.data?.editable?.groupOfTask) {
            const itemName = context.startRecord.name ?? 'Untitled'
            const groupName = managerGroupGantt.getKeepGroupTitle()
            managerTableKeepGlobalData.showSnackbar("You don't have the permission to move the {{itemName}} between group of tasks. Please contact the {{groupName}}'s admin", {itemName, groupName})
            return false
        }
        if(!allowDragWhenSort()) {
            managerTableKeepGlobalData.showSnackbar("To rearrange items manually, please disable prior sorting")
            return false
        }
        const typeEndDrag = getCaseForDragEnd(context)

        if(typeEndDrag === 'itemEnd') {
            return resolveItemDragForEnd(context)
        }


        const type = getCase(context.startRecord)
        if(!!denyDropType(type)) return false

        switch (type) {
            case 'lane': {
                return resolveLaneDrag(context)
            }
            case 'item': {
                return resolveItemDrag(context)
            }
            default:
                return false
        }
    }, [])

    const checkCounts = useCallback((fromParentId, toParentId) => {
        const fromParentRec = managerGanttRef.gantt?.instance?.project?.eventStore?.getById?.(fromParentId)
        const toParentRec = managerGanttRef.gantt?.instance?.project?.eventStore?.getById?.(toParentId)
        const fromParent = counterInstance.callbacks?.[fromParentId]
        const toParent = counterInstance.callbacks?.[toParentId]

        if(!!fromParent && fromParentRec) {
            fromParent?.(fromParentRec?.children?.length ?? 0)

        }
        if(!!toParent && toParentRec) {
            toParent?.(toParentRec?.children?.length ?? 0)

        }
    }, [])

    const gridRowDropTask = useCallback((context) => {
        const data = getDataForMoveTask(context)
        if(!data.valid) return false

        if(data.isSameLane) {
            moveCardInLane(data.parentId, data.id, data.oldInd, data.newInd)
            return true
        }
        if(!data.isSameLane) {
            checkCounts(data.fromParentId, data.parentId)
            onMoveItemBetweenLanes(data.id, data.fromParentId, data.parentId, data.newInd)
            return true
        }
        return false
    }, [])

    const gridRowDrop = useCallback(({context}) => {
        if(!context.valid) return false
        const typeEndDrag = getCaseForDragEnd(context)

        if(typeEndDrag === 'itemEnd') {
            return gridRowDropTask(context)
        }

        const type = getCase(context.startRecord)
        if(!!denyDropType(type)) return false

        switch (type) {
            case 'lane': {
                const {oldInd, newInd, valid} = getDataForMoveLane(context)
                if(!valid) return false
                moveLane(oldInd, newInd)
                return true
            }
            case 'item': {
                return gridRowDropTask(context)
            }
            default:
                return false
        }

    }, [])

    const rowReorderFeature = useMemo(() => ({
        disabled: false,
        hoverExpandTimeout: 1000000,
        listeners: {
            gridRowDrag,
            gridRowDrop,
            gridRowBeforeDropFinalize,
        },
    }), [])

    return [rowReorderFeature, updateMove]
}