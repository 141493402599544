import { withStyles } from '@material-ui/core';
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import { openFullview, openInput as openNewUiInput, updateSpecificItem, managerRemovedItemsLocal } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { finishedUpdatingSpecificItem } from '../../../actions/dataActions.js';
import { openInput } from '../../../actions/inputActions.js';
import { DATA_FILTERED, DATA_UPDATE, DATA_UPDATED, FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN } from '../../../actionTypes/actionTypes.js';
import { themeConstants } from '../../../theme.js';
import { getRowLaneStatus } from '../../../utils/actionsCenter/getItemLaneStatus.js';
import { getAfter4days, getBefore4days, getLastSunday, getMyUserCalendarQuery, getUpcomingSunday, INITIAL_VIEW_MY_CALENDAR } from '../../../utils/calendar/calendarHelpers.js';
import { createCustomTheme } from '../../../utils/theming';
import { dispatchDataToNewUIStore } from '../../components/Common/Utils/newUIMigrationUtil.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import Calendar from '../groups/Calendar/Calendar.jsx';
import { proccessLabelColorCalendar, shadeColor } from '../groups/Calendar/event-utils.js';
import { getOptionsForInputMapping, shouldAutoOpen } from '../groups/groupUtils.js';
import { checkIfCollectionOrGroup, withAggDataTypes } from '../search/searchUtils.js';

const styles = theme => ({
    container: {
        height: 'auto',
        width: 'auto',
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        overflow: 'hidden',
        marginTop: -30,
    },
});

class MyPageCalendar extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            dataReloadInidicator: 0,
            events: [],
            results: [],
            isLoading: true,
            isLoadingChanges: false
        }
    }

    dateScheduleFrom = getLastSunday()
    dateScheduleTo = getUpcomingSunday()
    wasFirst = false

    componentDidMount() {
        this.loadData(0, this.props, 'isLoading')
    }

    updateSpecificItemInState(props, docId) {
        const { dispatch, queryService } = props;

        queryService.searchById(docId).then((results) => {
            if (results.data.length) {

                const event = this.convertDocumentToEvent(docId, results.data[0].Value.Source)
                const events = [...this.state.events].filter(event => event.id !== docId)
                events.push(event)
                this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) })

            }
            this.setState({ isLoadingGroupAndData: false })
            dispatch(finishedUpdatingSpecificItem(docId));
        })
    }

    onUpdateMonth = (data) => {
        const {startStr, endStr, view} = data

        if(view.type === "timeGridDay") {
            this.dateScheduleFrom = getBefore4days(startStr)
            this.dateScheduleTo = getAfter4days(endStr)
        } else {
            this.dateScheduleFrom = startStr
            this.dateScheduleTo = endStr
        }

        if(!this.wasFirst) return
        this.props.dispatch({type: DATA_UPDATE})
    }

    checkLocalRemove = (prevProps) => {
        if(this.props.queryFiltersUpdateType === 'delete' && prevProps.queryFiltersUpdateType !== 'delete' &&
            !!this.props.queryFiltersItemId) {
                this.setState({ events: this.state.events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) })
        }
    }



    async componentDidUpdate(prevProps, prevState) {
        const { oldUiData, dispatch, queryFiltersItemId } = this.props

        if((prevState.isLoading && !this.state.isLoading) || (prevState.isLoadingChanges && !this.state.isLoadingChanges)) {
            this.updateEvents()
            return
        }
        if (this.state.isLoadingChanges || this.state.isLoading) {
            return
        }

        this.checkLocalRemove(prevProps)

        const [prevOpenned, nextOpenned] = [this.getFullviewStatus(prevProps), this.getFullviewStatus(this.props)];
        const prevDocument = this.getDocument(prevProps)

        if (oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(oldUiData.dataUpdate, this.props, 'isLoadingChanges')
        }
        else if(!!queryFiltersItemId && !prevProps.queryFiltersItemId) {
            this.loadData(oldUiData.dataUpdate, this.props, 'isLoadingChanges')
            dispatch(updateSpecificItem(null));
        }
        else if (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate) {
            if (prevOpenned && !nextOpenned && prevDocument && prevDocument.taskDueDateDone) {
                const id = prevDocument.docId;
                const events = [...this.state.events].filter(event => event.id !== id)
                this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) })
                return
            }
            this.loadData(oldUiData.dataUpdate, this.props, 'isLoadingChanges')
            dispatch(finishedUpdatingSpecificItem(oldUiData.itemId));
        }
    }

    render() {
        const { classes, isDrawerOpen } = this.props;
        const { isLoading, events } = this.state
        return (
                <div data-intrcm-id="MyPageCalendarx_em0c0vnx" className={classes.container} style={{marginLeft: isDrawerOpen ? themeConstants.navigationDrawerWidth  : themeConstants.navigationDrawerSmallWidth}}>
                    {isLoading ? null : <Calendar onUpdateMonth={this.onUpdateMonth} initialView={INITIAL_VIEW_MY_CALENDAR} onPressNew={this.onPressNew} onPress={this.onPress} events={events} />}
                </div>
        )
    }
    getFullviewStatus = (props) => {
        return props.fullviewState.open
    }
    getDocument = (props) => {
        const { documentsState, fullviewState } = props;
        if (!fullviewState.mainDocId) return undefined;

        const documentState = documentsState.data[fullviewState.mainDocId]
        const document = documentState && !documentState.isFetching && documentState.document

        if (!document) return undefined
        return document
    }

    onPress = (docId) => {
        if (!docId) return
        this.props.dispatch(openFullview(docId, false))

    }

        onPressNew = (startDate, endDate) => {
        const { dispatch, groupUid, groupInfo, inputMappings, oldUiInput, userData, searchQuery } = this.props
        const payload = { postText: '', dataType: 'post', permissions: [groupUid], dueDateUTC: startDate, dateTimeFrom: startDate, dateTimeTo: endDate }

        const kanbanListId = ""

        if (shouldAutoOpen(groupInfo)) {
            const dataType = groupInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId, dueDateUTC: startDate, dateTimeFrom: startDate, dateTimeTo: endDate }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }
    }

    getLabelColor = (doc, defaultColor) => {
        const {collectionLabels, groupUid} = doc
        const { userCollections, openCollections, orgLabels } = this.props

        const cuuCollection = userCollections
            .concat(openCollections)
            .find(collection => collection.collectionGroup.groupUid === groupUid)

        if (collectionLabels.length === 0) return defaultColor
        const [label] = collectionLabels
        const [, docLabelUid] = label.split(':')

        const collectionLabel = cuuCollection && [...(cuuCollection.collectionLabels ?? []), ...orgLabels].find(collectionLabel => collectionLabel.labelUid === docLabelUid)

        return collectionLabel ? collectionLabel.color : defaultColor
    }

    localFilter = (results) => {
        const { localFilter, searchQuery: {dataTypes}, inputMappings } = this.props;
        if(!dataTypes.length) return results

        const selectedFields = getOptionsForInputMapping(inputMappings, dataTypes[0])
        let filteredContent = results
        const filteredColumns = selectedFields.filter(x => !!localFilter.filters[x.sourceField])
        if (filteredColumns && filteredColumns.length > 0) {
            filteredColumns.forEach(column => {
                filteredContent = filteredContent.filter(res => {
                    
                    const fieldValue = column.extractData(res.Value.Source)

                        if(Array.isArray(fieldValue)){
                            for (let index = 0; index < fieldValue.length; index++) {
                                const element = fieldValue[index];
                                if(localFilter.filters[column.sourceField].data.indexOf(element) > -1){
                                    return true
                                }
                            }
                        }

                        if (Array.isArray(localFilter.filters[column.sourceField].data) && localFilter.filters[column.sourceField].data.indexOf(fieldValue) > -1 ) {
                            return true
                        }
                
                        if(localFilter.filters[column.sourceField].type === 'date') {
                            const fromToAgeValues = localFilter.filters[column.sourceField].data
                            if(fieldValue && fromToAgeValues.fromAge && fromToAgeValues.toAge) {
                               return new Date(fieldValue) > new Date(fromToAgeValues.fromAge) && new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
    
                            if(fieldValue && fromToAgeValues.fromAge) {
                                return new Date(fieldValue) > new Date(fromToAgeValues.fromAge)
                            }
    
                            if(fieldValue && fromToAgeValues.toAge) {
                                return new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
                        }
                    return false
                })
            })
        } 
        return filteredContent
    }



    updateEvents = () => {
        const {results} = this.state;
        const {dispatch} = this.props;
        this.wasFirst = true
        const filteredData = results
            .filter(item => {
                const { dateTimeFrom, dueDateUTC } = item.Value.Source
                const isEvent = (!!dateTimeFrom && document.dateTimeFrom !== '0001-01-01T00:00:00Z') || !!dueDateUTC

                return isEvent
            })
            .map(item => ({document: item.Value.Source, id:item.Value.Source.docId}))

        const events = filteredData.map(item => {
            const { id, document } = item
            return this.convertDocumentToEvent(id, document);

        })
        this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) }, () => {
            dispatch(finishedUpdatingSpecificItem());
            dispatch({type: DATA_UPDATED});
            dispatch({type: DATA_FILTERED});
        })
    }

    convertDocumentToEvent = (id, document) => {
        const colors = createCustomTheme(this.props.profileData.org.brandingColor);
        const backgroundColor = this.getLabelColor(document, colors.primary.b500)

        const darkerColor = shadeColor(backgroundColor, -30)
        const lighterColor = proccessLabelColorCalendar(backgroundColor, 0.38) 

        const start = !!document.dateTimeFrom && document.dateTimeFrom !== '0001-01-01T00:00:00Z' ? new Date(document.dateTimeFrom).toISOString() : new Date(document.dueDateUTC).toISOString()
        const tmp = new Date(start)
        tmp.setHours(tmp.getHours() + 1)

        const end = !!document.dateTimeTo && document.dateTimeTo !== '0001-01-01T00:00:00Z' ? new Date(document.dateTimeTo).toISOString() : tmp.toISOString()
        const docSchedule = {
            dueDateUTC: document.dueDateUTC,
            taskDueDateDone: document.taskDueDateDone,
            dueDateRecurring: document.dueDateRecurring,
            dateTimeFrom: start,
            dateTimeTo: end,
        }
        const dataType = document.dataType
        const members = document.members ?? []
        const laneStatus = getRowLaneStatus(document, { openCollections: this.props.openCollections, usersCollections: this.props.userCollections  })
        const isAllDayEvent = start === end && start === document.dueDateUTC
        return { id, title: document.title, start, end:  end, backgroundColor: lighterColor, borderColor: darkerColor, docSchedule, dataType, laneStatus, members,allDay: isAllDayEvent }
    }
    isLocalFilterPresented = () => {
        const { localFilter = {} } = this.props
        return Object.keys(localFilter.filters).length > 0
    }

    loadData = function (dataReloadInidicator, props, loadingName) {
        const { searchQuery, dispatch, queryService, profileData: {auth0_id} } = props;
    
        if (searchQuery.query !== '' || checkIfCollectionOrGroup(searchQuery) || searchQuery.isMyPage) {
            this.setState({ [loadingName]: true, dataReloadInidicator: dataReloadInidicator })
            const userDueDateQuery = getMyUserCalendarQuery(auth0_id, this.dateScheduleFrom, this.dateScheduleTo)
            if(dataReloadInidicator !==0) {
                userDueDateQuery.users = searchQuery?.users ?? []
                userDueDateQuery.labels = searchQuery?.labels ?? []
            }
            const _searchQuery = { ...searchQuery, ...userDueDateQuery}
            dispatch({ type: 'SEARCH_QUERY_UPDATE', query: userDueDateQuery});
    
            return queryService.Search(_searchQuery, null, true)
                .then((result) => {
                    let data = result.data
                    const exractAggregationFromResult = (propertyName) => (result.aggregations[propertyName] && result.aggregations[propertyName].Items) || []
    
                    const dataTypes = exractAggregationFromResult('dataTypes')

                    const withLocalFilter = this.isLocalFilterPresented()
                    if(withLocalFilter) {
                        data = this.localFilter(data)
                    }

                    this.setState({
                        results: data,
                        [loadingName]: false,
                    });
    
                    dispatchDataToNewUIStore(data, dispatch)
    
    
                    dispatch({ type: 'DATA_UPDATED' });
    
                    if (!withAggDataTypes(_searchQuery)) {
                        dispatch({
                            type: 'RESULT_FILTERS_UPDATE', results: {
                                dataTypes: [{
                                    Key: 'All',
                                    DocCount: result.count
                                }, ...dataTypes],
                            }
                        });
                    }
                })
        }
    }
}



const mapStateToProps = (state) => ({
    queryFiltersItemId: state.queryFilters.itemId,
    queryFiltersUpdateType: state.queryFilters.updateType,
    router: state.router,
    oldUiData: state.oldUiData,
    profileData: state.userData.profileData,
    ...state.services,
    searchQuery: state.search,
    fullviewState: state.fullview,
    documentsState: state.data.documents,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections,
    localFilter: state.localFilter,
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    groupUid: state?.results?.channel?.collectionGroup?.groupUid ?? '',
    groupInfo: state.results.channel,
    userData: state.userData,
    orgLabels: state.data.globalData.labels.labels,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}


export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MyPageCalendar);

