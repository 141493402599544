import React, { useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatDateTime, parseImageUrl } from '@tuqqi/common';
import moment from 'moment';
import goTo from '../../../../actions/goTo';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { NotificationActions } from './NotificationActions';
import NotificationParsedTextNew from './NotificationParsedTextNew';

const styles = (theme) => ({
    point: {
        right: 5,
        top: 16,
        position: 'absolute',
        width: 5,
        height: 5,
        backgroundColor: theme.customColors.primary.main,
        borderRadius: 3,
    },
    unread: {
        backgroundColor: hexToRGB(theme.customColors.primary.main, 0.04)
    },
    container: {
        position: 'relative',
        minHeight: 46,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 16,
        paddingLeft: 5,
        paddingTop: 8,
        paddingBottom: 8,
        borderBottomColor: '#D7D7DB',
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.08)
        },
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        '&:last-child': {
            borderBottomWidth: 0
        },
        '&:first-child': {
            borderTopColor: '#D7D7DB',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
        },
    },
    avatar: {
        objectFit: 'cover',
        backgroundColor: '#F3F6FB',
        alignSelf: 'flex-start',
        width: 50,
        height: 50,
        borderRadius: 25,

    },
    title: {
        cursor: 'pointer',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#0C0C0D',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#0C0C0D'
        },
    },
    time: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#737373',
    },
    firstRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    mainText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.5,
        color: '#0C0C0D',
        marginTop: 4,
    },
    textsWrap: {
        cursor: 'pointer',
        marginTop: 0,
        alignSelf: 'flex-start',
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 318,
        marginLeft: 16,
    }
})

const NotificationOption = (props) => {
    const { dispatch, classes, notification, isRead, onPress, t, cloudfront } = props
    // if(cloudfront == null || cloudfront.data == null || cloudfront.data.signedFiles){
    //     return null;
    // }
    const parseUrl = !!cloudfront?.data?.signedFiles ? parseImageUrl(cloudfront.data) : () => ''
    const itemRef = useRef(null)
    const [isHover, setIsHover] = useState(false)

    const onMouseLeaveItem = () => {
        setIsHover(false)
    }

    const onMouseEnterItem = () => {
        setIsHover(true)
    }


    const url = useMemo(() => {
        const { userThatMadeTheAction } = notification
        const { profileImageUrl } = userThatMadeTheAction
        if (!profileImageUrl) return ''
        return parseUrl(profileImageUrl)
    }, [notification, parseUrl])


    let heightItem = 56
    if(!!itemRef.current) {
        const x = itemRef.current.clientHeight || 56
        heightItem = x
    }

    const title = useMemo(() => {
        const { userThatMadeTheAction } = notification
        const { firstname, lastname } = userThatMadeTheAction
        if (!firstname && !lastname) return t('Unknown')
        return `${firstname} ${lastname}`
    }, [notification])

    const dateString = useMemo(() => {
        const a = moment(new Date(), 'M/D/YYYY');
        const b = moment(new Date(notification.dateTime), 'M/D/YYYY');
        const diffDays = b.diff(a, 'days');

        return diffDays >= 1 ? formatDateTime(notification.dateTime) : moment(new Date(notification.dateTime), 'ddd MMM DD YYYY HH:mm:ss GMT Z').fromNow();
    }, [notification])

    const goToUser = (e) => {
        const { userThatMadeTheAction } = notification
        if (!userThatMadeTheAction?.tag) return
        const userTag = userThatMadeTheAction.tag.startsWith("@") ? '' : '@' + userThatMadeTheAction.tag

        dispatch({ type: 'BLUR_NOTIFICATIONS_MODAL' })
        goTo(userTag, dispatch)()
    }

    const _onPress = () => {
        onPress(notification)
    }

    return <div data-intrcm-id="NotificationOption_gl23vdk0" onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseLeaveItem} ref={itemRef} className={`${classes.container} ${isRead ? {} : classes.unread}`}>
        {isRead ? null : <div data-intrcm-id="NotificationOption_43kh7pkh" className={classes.point} />}
        {!!url ? <div data-intrcm-id="NotificationOption_ykn5gax3" style={{ alignSelf: 'flex-start', cursor: 'pointer' }} onClick={goToUser}><img alt='notfdf' className={classes.avatar} src={url} /></div> : <div data-intrcm-id="NotificationOption_xqjaiz6e" style={classes.avatar} />}
        <div data-intrcm-id="NotificationOption_ycwiswra" onClick={_onPress} className={classes.textsWrap}>
        <NotificationParsedTextNew systemActionTypeUid={notification.systemActionTypeUid} icon={notification.icon} title={title} className={classes.mainText} text={notification.topic} messageTemplateParams={notification.messageTemplateParams} />
            <div data-intrcm-id="NotificationOption_56h5tsx6" className={classes.firstRow}>
                <div data-intrcm-id="NotificationOption_5r8wv6v7" className={classes.time}>{dateString}</div>
            </div>
        </div>
        {isHover && <NotificationActions markAsReadNotification={props.markAsReadNotification} onGoToSettings={props.onGoToSettings} onCloseMenu={onMouseLeaveItem} notification={notification} wrapHeight={heightItem} />}
    </div>
}

const mapStateToProps = (state) => {
    return {
        cloudfront: state.data.globalData.cloudfront,
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, { withTheme: true }),
    withTranslation('notificationsDrawer'),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationOption)