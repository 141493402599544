import { withStyles } from '@material-ui/core';
import React, { PureComponent, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'recompose';
import Spinner from '../../../components/Common/spinner';
import HeaderTableCell from './HeaderTableCell';
import TableCell from './TableCell';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import FooterTableCell from './FooterTableCell';
import { calculateAggregation, COLUMN_DATA_TYPE_ATTACHMENTS } from './tableUtil';
import TableCellAddNew from './TableCellAddNew';
import { connect } from 'react-redux';

const styles = theme => ({
    table: {
        borderCollapse: 'collapse',
        "whiteSpace": "nowrap",
        minWidth: '100%',
    },
    headerBackground: {
        position: 'fixed',
        height: '53px',
        'border-top-left-radius': '4px',
        'border-top-right-radius': '4px',
        backgroundColor: '#f3f6fb',
        "border-bottom": `solid 1px ${theme.newUI.color.gray400}`,

    },
    row: {
        "border-top": `solid 0.5px ${theme.newUI.color.gray400}`,
        "border-bottom": `solid 0.5px ${theme.newUI.color.gray400}`,

        '&:first-child': {
            border: 'none'
        },
        '&:last-child': {
            "border-bottom": `solid 1px ${theme.newUI.color.gray400}`,
        }
    }

})

class Table extends PureComponent {
    state = {
        aggregationType: 'Summary'
    }
    aggregateResults = () => {
        const { aggregationType } = this.state;
        const { columns, results, inputMappings } = this.props;

        const aggregated = columns.map(column => {
            const {type, fieldName} = column
            const value = calculateAggregation(aggregationType, results, column, inputMappings)
            return {type, fieldName, value}
        })
        return aggregated
    }
    onAggregationTypeChange = (selected) => {
        this.setState({aggregationType: selected})
    }
    render() {
        const { classes, isLoading, page, rowsPerPage, columns, results, sortedColumn, sortOrder, onSort, showTableFooter, isDrawerOpen } = this.props;
        const { aggregationType } = this.state;

        if (isLoading ) {
            return <CircularProgress style={{ position: 'fixed', left: '50%', right: '50%', top: '27%' }} />
        }
        const aggregatedColumns = this.aggregateResults()
        const currPageResult = results.slice(page * rowsPerPage, (page + 1) * rowsPerPage)        
        const headerStyle = {
            width: isDrawerOpen ? `calc(100% - 426px)` : `calc(100% - 186px)`
        }
        return (
            <Fragment>
                <div data-intrcm-id="Table_nd6x4q2w" style={headerStyle} className={classes.headerBackground} />
                <table  className={classes.table}>
                    <thead>
                        <tr>
                            {
                                columns.map(column => <HeaderTableCell onSort={onSort} column={column} sortedColumn={sortedColumn} sortOrder={sortOrder} />)
                            }
                        </tr>
                    </thead>
                    <tbody>
                            <>
                        {
                            currPageResult.map((res, idx) => (
                                <tr className={classes.row}>
                                    {columns.map(column => <TableCell docId={res.Key} row={res.Value.Source} column={column} />)}
                                </tr> 
                            ))
                        }
                            <tr className={classes.row}>
                                <TableCellAddNew />
                            </tr>
                            </>
                        
                    </tbody>
                    {showTableFooter && <tfoot>
                        <tr>
                            {
                                aggregatedColumns.map((column, idx) => <FooterTableCell aggregationType={aggregationType} onAggregationTypeChange={this.onAggregationTypeChange} isFirst={idx === 0} column={column} />)
                            }
                        </tr>
                    </tfoot>
                    }
                </table>
            </Fragment>


        )
    }

}

const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings ?? [],
})

export default compose(
    withStyles(styles), 
    connect(mapStateToProps, null),
    withErrorCatcher()
)(Table);