import { CircularProgress, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { filterData, IsQueryExistInCollections, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../search/searchUtils';
import { loadGroupInfo } from '../groups/groupUtils.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import { DATA_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import UserOngoingView from '../user/ongoing/UserOngoingView.js';

const styles = theme => ({

});

const initialQuery = { page: 0, size: 100}

class MyPageOngoing extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.urlQuery !== this.props.urlQuery) {
            this.updateUser(nextProps)
        }
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    getUserByTag = () => {
        const {profileData} = this.props
        return profileData
    }

    updateUser = (props) => {
        const {dispatch, customStatuses} = props
        const customColors = customStatuses?.map?.(x => x.taskStatusColor) ?? []
        const colors = [30, 40, ...customColors]
        const user = this.getUserByTag()
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...initialQuery, colors, users: [user?.auth0_id ?? ''], query: ''} })
        dispatch({ type: DATA_UPDATE });
        dispatch({ type: DATA_FILTER });
    }

    componentDidMount() {
        this.updateUser(this.props)
    }

    render() {
        const { results, isLoading } = this.state;
        const { isDrawerOpen } = this.props;

        if (isLoading) {
            return <CircularProgress style={{ position: 'fixed', left: '50%', right: '50%', top: '27%' }} />
        }

        return <CategoryViewOnlyLeftDrawer>
            <UserOngoingView isDrawerOpen={isDrawerOpen} results={results} />
        </CategoryViewOnlyLeftDrawer>

    }
}

const mapStateToProps = (state, ownProps) => ({
    customStatuses: state.data?.globalData?.statuses?.statuses ?? [],
    queryService: state.services.queryService,
    profileData: state.userData.profileData,
    oldUiData: state.oldUiData,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MyPageOngoing);