import React, { PureComponent } from 'react';

export default class BirthdayIcon extends PureComponent {

    render() {
        const { iconColor } = this.props
        return (
            <svg width="16" height="21" viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5.727c.987 0 1.778-.859 1.778-1.909 0-.363-.09-.697-.258-.983L8 0 6.48 2.835c-.169.286-.258.62-.258.983 0 1.05.8 1.91 1.778 1.91zm5.333 2.864H8.89V6.68H7.11v1.91H2.667C1.19 8.59 0 9.87 0 11.455v8.59c0 .525.4.955.889.955H15.11c.489 0 .889-.43.889-.955v-8.59c0-1.585-1.191-2.864-2.667-2.864zM1.067 19.568v-3.436c.8-.01 2.275-.353 2.844-.964l.969-1.022.951 1.022c1.165 1.25 3.191 1.24 4.347 0l.96-1.022.95 1.022c.57.61 2.045.954 2.845.964v3.436H1.067zm13.866-4.486c-.453-.01-1.59-.191-1.91-.544l-1.894-2.033-1.902 2.033a1.695 1.695 0 0 1-2.463 0l-1.893-2.033-1.902 2.033c-.311.343-1.449.534-1.902.544v-4.2c0-.525.4-.955.889-.955h12.088c.49 0 .89.43.89.955v4.2z" fill={iconColor} fill-rule="nonzero"/>
            </svg>
        )
    }
}