import React from 'react'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({

    header: {
        margin: 0
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    }
});

export default withStyles(styles)((props) => {
    const { classes } = props;

    return <Grid container spacing={16} className={classes.header} spacing={16}>


        <Grid item >
            <Typography variant="body2" >
                Group Add Column
            </Typography>
        </Grid>



    </Grid>
})