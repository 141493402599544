
import { ListItemText, MenuItem, Select } from '@material-ui/core';
import { definedSpecificGroups, dtoDocPermissionsToSpecificOptions, getPermissionOptionLabel, getPermissionsMoveOptions } from '@tuqqi/common';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';


const styles = {
    selectThemeHolder: {
        maxWidth: 500,
        width: 500,
        marginTop: 12,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    itemInfo: {
    },
    option: {
        color: '#21212d',
    },
    formText: {
        padding: 0,
        minHeight: 20
    },
    select: {
        width: 150,
        height: 32
    },
    errorText: {
        padding: 0,
        minHeight: 20, 
        color: '#f44336!important',
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Rubik',
        lineHeight: '1.5em',
    },
}

const PickSpecificGroups = ({ defaultGroupUid, userCollections, customInputMappings, dataTypeFilter, specificGroupsUids, setSpecificGroupsUids, openCollections }) => {
    const getOptions = useCallback(getPermissionsMoveOptions(userCollections, customInputMappings, { openCollections }), [userCollections, customInputMappings, openCollections])
    const getPermissionLabel = useCallback(getPermissionOptionLabel(definedSpecificGroups)(userCollections, { openCollections }), [userCollections, openCollections])
    const [t] = useTranslation('admin')

    const options = useMemo(() => getOptions(dataTypeFilter), [dataTypeFilter, getOptions])
    const mappedOptions = useMemo(() => dtoDocPermissionsToSpecificOptions(options, getPermissionLabel), [options, getPermissionLabel])

    const selectedOptions = useMemo(() => {
        if(!specificGroupsUids || typeof specificGroupsUids !== 'string') return []
        const selected = specificGroupsUids
            .split(';')
            .map((key) => mappedOptions.find((o) => o.key === key))
            .filter((o) => !!o)
        return selected
    }, [specificGroupsUids, mappedOptions])

    const selectedOptionsLabel = useMemo(() => {
        if(!selectedOptions || !selectedOptions.length) return t('None specific')
        
        return selectedOptions
            .map((o) => o.title)
            .join(";")
    }, [selectedOptions])

    const selectedOptionsKeys = useMemo(() => {
        if(!selectedOptions || !selectedOptions.length) return []
        
        return selectedOptions.map((o) => o.key)
    }, [selectedOptions])

    const handleSelectOptions = (event) => {
        const options = event.target.value

        if(!options || !options.length || !!options.find(o => o.key === 'None')) {
            setSpecificGroupsUids(null)
            return
        }
        const keys = options
            .map(o => o.key)
            .join(";")
        setSpecificGroupsUids(keys)
    }

    const errorText = useMemo(() => {
        if(!defaultGroupUid || !selectedOptions || !selectedOptions.length) return ''
        const o = selectedOptions.find((o) => o.key === defaultGroupUid)
        return !!o ? '' : '* Notice default group not included in specific list. First specific group will be used as default.'
    }, [defaultGroupUid, selectedOptions])

    return <div data-intrcm-id="PickSpecificGroups_nlgnjmrw" style={styles.selectThemeHolder}>
        <div data-intrcm-id="PickSpecificGroups_gn2sqezx" style={styles.itemInfo}>
            <ListItemText style={styles.formText} secondary={t("Select specific groups")} />
            <ListItemText style={styles.formText} primary={selectedOptionsLabel} />
            {!!errorText && <div data-intrcm-id="PickSpecificGroups_ixm809q5" style={styles.errorText} >{errorText}</div>}
        </div>
            <Select
                multiple
                labelId="simple-select-specific-groups"
                id="simple-select-specific-groupd"
                value={selectedOptions}
                onChange={handleSelectOptions}
                style={styles.select}
            >
                <MenuItem selected={false} key={'None'} value={{title: 'None', key: 'None'}} style={styles.option}>
                    {t('None')}
                </MenuItem>
                {mappedOptions.map((option) => (
                    <MenuItem selected={selectedOptionsKeys.includes(option.key)} key={option.key} value={option} style={styles.option}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
    </div>
}


const mapStateToProps = (state) => ({
    searchNavigation: state.searchNavigation,
    userCollections: state.data.userData.userCollections,
    openCollections: state.data.globalData.openCollections,
    customInputMappings: state.data.globalData.customInputMappings
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(PickSpecificGroups);
