import React from "react";

import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "react-router-redux";
import { withStyles, Grid, Link } from "@material-ui/core";
import { People } from '@material-ui/icons';
import Typography from "@material-ui/core/Typography";
import { DATA_FILTERED, DATA_UPDATED } from "../../../actionTypes/actionTypes";
import { finishedUpdatingSpecificItem } from "../../../actions/dataActions";
import { loadGroupInfo } from "../../views/groups/groupUtils";
import ArticleAdder from "./articleAdder";
import Article from "./article";
import ListHeader from "./ListHeader/listHeader";
import withErrorCatcher from "../Common/withErrorCatcher.js";


const styles = (theme) => ({
  headerSection: {
    height: 16,
    fontFamily: "Rubik",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "2px",
    color: theme.newUI.color.gray,
    marginBottom: 25,
    cursor: "pointer",
    paddingLeft: "30px",
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    overflow: 'hidden'
  },
  mainWrapper: {
    marginLeft: "369px",
    paddingRight: "30px",
  },
  container: {
    paddingRight: "340px",
    width: "1240px",
    margin: "0 auto",
    [theme.breakpoints.down(1680)]: {
      paddingRight: "0px",
      width: "940px",
    },
    [theme.breakpoints.down(1200)]: {
      width: "600px",
    },
  },
  listHolder: {
    width: 840,
    padding: "30px",
    borderRadius: "4px",
    backgroundColor: "#f7f7fc",
  },
  startedItem: {
    padding: "0 30px 30px",
    display: "flex",
    width: "100%",
  },

  listTitle: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#21212d",
  },
  listDescription: {
    fontFamily: "RubikRoman, sans-serif",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#adadad",
  },
  article: {
    minHeight: "188px",
    borderRadius: "4px",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    border: "solid 1px #dcdde0",
    backgroundColor: "#ffffff",
    marginBottom: "15px",
    padding: "15px",
  },
});

class CategoriesList extends React.Component {
  state = {
    items: [],
    listTitle: "",
    description: "",
    listId: 0,
    cardsList: [],
    isLoadingGroupAndData: false,
    lanes: [],
    actionCounter: 0,
  };

  editListTitle = (listId, title) => {
    const { groupService } = this.props;
    const { lanes } = this.state;
    const laneIndex = lanes.findIndex((lane) => lane.id === listId);

    const currList = { ...lanes[laneIndex], title };
    lanes[laneIndex] = currList;

    this.setState({
      listTitle: title,
    });

    groupService.updateLane(currList);
  };

  editListDescription = (listId, description) => {
    const { groupService } = this.props;
    const { lanes } = this.state;
    const laneIndex = lanes.findIndex((lane) => lane.id === listId);

    const currList = { ...lanes[laneIndex], description };
    lanes[laneIndex] = currList;

    this.setState({
      description: description,
    });

    groupService.updateLane(currList);
  };

  getView = () => {
    const { collectionInfo } = this.props;

    return (collectionInfo.collectionViews || []).find(
      (c) => c.collectionViewType === "Kanban"
    );
  };

  buildKnowledge = (allGroupItems) => {
    let view = this.getView();
    if (!view) return;

    const columns = view.itemLists
      .map((list) => ({
        id: list.id,
        title: list.title,
        order: list.order,
        listColor: list.listColor,
        isUnassigned: false,
        rules: list.rules,
        cards: [],
      }))
      .sort(this.sortOrder);

    const unassignedList = {
      id: -1,
      title: "Unassigned",
      cards: [],
      order: -1,
      isUnassigned: true,
    };
    allGroupItems.forEach((groupItem) => {
      let card = null;
      for (let column of columns) {
        const listItems = view.itemLists.find(
          (list) => list.id === column.id
        ).items;
        const listItem = listItems.find(
          (listItem) => listItem.documentId === groupItem.id
        );

        if (listItem) {
          card = this.buildCard(groupItem, listItem.order);
          column.cards.push(card);
          break;
        }
      }
      if (!card) {
        const time = new Date(groupItem.document.docTimestamp).getTime();
        unassignedList.cards.push(this.buildCard(groupItem, -time));
      }
    });
    columns.unshift(unassignedList);
    columns.forEach(
      (column) => (column.cards = column.cards.sort(this.sortOrder))
    );

    const updatedCards = columns.find((item) => item.id === this.state.listId);
    this.setState({ cardsList: updatedCards.cards, lanes: columns });
  };

  buildCard(item, order) {
    return {
      doc: item.document,
      id: item.id,
      order,
      title: item.document.title,
    };
  }

  getAllData = () => {
    const { searchQuery, queryService } = this.props;
    const _searchQuery = { ...searchQuery, page: 0 };

    return queryService.GetKanbanData(_searchQuery);
  };

  getCardsData = (items = null, id = null) => {
    let cards, idCards;

    const isUpdated = id && items;

    if (isUpdated) {
      cards = items.collectionViews[1].itemLists.find((item) => item.id === id);
      idCards = cards.items.map((item) => item.documentId);
    } else {
      cards = this.props.location?.state?.cards || this.props.categoryListState?.cards || [];
      idCards = cards.map((item) => item.id);
    }

    const query = {
      itemsIds: idCards,
      page: 0,
      size: idCards.length,
    };

    this.props.queryService.SearchByIds(query).then((results) => {
      this.setState({
        items: results.data,
      });
    });
  };

  updateLists = (updatedLanes) => {
    if (updatedLanes) {
      this.setState({
        lanes: [...updatedLanes],
        actionCounter: this.state.actionCounter + 1,
      });
    }
  };

  updateSpecificItemInState(props, docId) {
    const { dispatch, queryService } = props;
    const { lanes } = this.state;

    const laneIndex = lanes.findIndex((lane) =>
      lane.cards.some((card) => card.id === docId)
    );

    if (laneIndex > -1) {
      queryService.searchById(docId).then((results) => {
        if (results.data.length) {
          const lane = {
            ...lanes[laneIndex],
            cards: [...lanes[laneIndex].cards],
          };

          const cardIndex = lane.cards.findIndex((card) => card.id === docId);

          lane.cards[cardIndex] = {
            ...lane.cards[cardIndex],
            doc: results.data[0].Value.Source,
          };
          lanes[laneIndex] = lane;

            this.updateLists(lanes)
        }
        this.setState({ isLoadingGroupAndData: false });
        dispatch(finishedUpdatingSpecificItem(docId));
      });
    } else {
      dispatch(finishedUpdatingSpecificItem(docId));
    }
  }

  setupList = () => {
    const {location, dispatch} = this.props
    if(!location.state) return
    dispatch({
      type: "SET_CATEGORYLIST_STATE",
      categoryListState: location.state
    })
  }
  componentDidMount() {
    this.setupList()
    this.getCardsData();
    const list = this.props.location?.state ?? this.props.categoryListState;

    this.setState({
      listTitle: list.title,
      listId: list.id,
      cardsList: list.cards,
      description: list.description,
    });

    Promise.all([loadGroupInfo(this.props), this.getAllData()]).then(
      (results) => {
        this.buildKnowledge(results[1]);
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { oldUiData, dispatch } = this.props;

    if (this.state.isLoadingGroupAndData) {
      return;
    }

    if (
      oldUiData.needsUpdate ||
      (!oldUiData.isFiltering && oldUiData.needsFilter)
    ) {
      this.setState({ isLoadingGroupAndData: true });

      Promise.all([loadGroupInfo(this.props), this.getAllData()]).then(
        (results) => {
          this.buildKnowledge(results[1]);
          dispatch({ type: DATA_UPDATED });
          dispatch({ type: DATA_FILTERED });
          this.getCardsData(this.props.results, this.state.listId);
          this.setState({ isLoadingGroupAndData: false });
        }
      );
    } else if (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate) {
      this.setState({ isLoadingGroupAndData: true });
      this.updateSpecificItemInState(this.props, oldUiData.itemId);
    }
  }
  getCardById = (cardId) => {
    const {cardsList} = this.state
    return cardsList.find(card => card.id === cardId)
  }

  render() {
    const { classes, dispatch, categoryListState } = this.props;
    const categoryTitle = this.state.listTitle || "Getting Started with Tuqqi";
    const categoryDescription =
      this.state.description ||
      "Category has no description";

    return (
      <div data-intrcm-id="listArticles_rkb23iap" className={classes.mainWrapper}>
        <Grid container direction="column" className={classes.container}>
          <Typography className={classes.headerSection}>
            <Link
              color="inherit"
              onClick={() => {
                dispatch(
                  push({
                    pathname: `/group/knowledge`,
                  })
                );
              }}
            >
              ALL LISTS {" "}
            </Link>
            / {this.state.listTitle}
          </Typography>
          <div data-intrcm-id="listArticles_wimueafh" className={classes.listHolder}>
            <div data-intrcm-id="listArticles_7rg9p58f" className={classes.startedItem}>
              <div data-intrcm-id="listArticles_60ykj6qg" className={classes.startedImgHolder}>
                <People style={{ color: "#4c3ff3", fontSize: '88px' }}/>
              </div>
              <ListHeader
                title={categoryTitle}
                description={categoryDescription}
                editListTitle={this.editListTitle}
                editListDescription={this.editListDescription}
                list={this.props.location?.state ?? categoryListState}
              />
            </div>
            {this.state.items.map((item, index) => (
              <Article
                dataArticle={item}
                key={item.Key}
                card={this.getCardById(item.Key)}
                cloudfront={this.props.cloudfront}
                users={this.props.users}
              />
            ))}
            <ArticleAdder list={this.props.location?.state ?? categoryListState} />
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  queryService: state.queryService,
  results: state.results.channel,
  cloudfront: state.data.globalData.cloudfront,
  collectionInfo: state.results.channel,
  oldUiData: state.oldUiData,
  searchQuery: state.search,
  users: state.globalData.users,
  userData: state.userData,
  categoryListState: state.wrapper.categoryListState,
  ...state.services,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  withErrorCatcher("Failed Loading Articles List, Please Contact Tuqqi Support"),
  connect(mapStateToProps, mapDispatchToProps)
)(CategoriesList);
