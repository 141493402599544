import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class ChatIcon extends PureComponent {

    render() {
        const { isFocused } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="14" fill="white" fill-opacity={ isFocused ? "0.16" : "0"}/>
            <path d="M9.49992 7.66699C8.30525 7.66699 7.33325 8.63899 7.33325 9.83366V17.1507C7.33325 17.4714 7.51079 17.7612 7.79679 17.9066C7.91879 17.9689 8.05088 17.999 8.18221 17.999C8.35754 17.999 8.53161 17.9446 8.67961 17.8369L11.662 15.667H15.8333C17.0279 15.667 17.9999 14.695 17.9999 13.5003V9.83366C17.9999 8.63899 17.0279 7.66699 15.8333 7.66699H9.49992ZM10.4999 10.0003H14.8333C15.1093 10.0003 15.3333 10.224 15.3333 10.5003C15.3333 10.7767 15.1093 11.0003 14.8333 11.0003H10.4999C10.2239 11.0003 9.99992 10.7767 9.99992 10.5003C9.99992 10.224 10.2239 10.0003 10.4999 10.0003ZM18.6666 10.6755V13.5003C18.6666 15.0627 17.3956 16.3337 15.8333 16.3337H11.8795L10.2538 17.516C10.6185 18.2003 11.3389 18.667 12.1666 18.667H16.3378L19.3202 20.8363C19.4682 20.9443 19.6423 20.999 19.8176 20.999C19.949 20.999 20.081 20.9683 20.203 20.9059C20.4887 20.7606 20.6666 20.4707 20.6666 20.1501V12.8337C20.6666 11.6953 19.7836 10.7611 18.6666 10.6755ZM10.4999 12.3337H13.8333C14.1093 12.3337 14.3333 12.5573 14.3333 12.8337C14.3333 13.11 14.1093 13.3337 13.8333 13.3337H10.4999C10.2239 13.3337 9.99992 13.11 9.99992 12.8337C9.99992 12.5573 10.2239 12.3337 10.4999 12.3337Z" fill="white"/>
            </svg>
        )
    }
}

