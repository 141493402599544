import React from 'react'
import { makeStyles } from '@material-ui/styles';
import NoTasksIcon from '../../../../../../Common/Icons/ActionCenter/NoTasksIcon';
import { useTranslation } from 'react-i18next';
import { themeConstants } from '../../../../../../../../theme';

const _styles = {
    container: {
        // marginTop: `calc(33vh - ${themeConstants.defaultToolbarHeight + 16 + 16}px)`,
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 16,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        marginTop: 12,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: 0.147638,
        color: '#0C0C0D',
    },
    subTitle: {
        marginTop: 4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: '#737373',
    }
};

const useStyles = makeStyles(_styles)
const subTitleKey = "It seems everything is done You can check other categories items"
export const Placeholder = (props) => {
    const styles = useStyles()
    const [t] = useTranslation("actionCenter")
    
    return (
        <div data-intrcm-id="Placeholder_tf055uoe" className={styles.container}>
            <NoTasksIcon />
            <div data-intrcm-id="Placeholder_nn1x7djv" className={styles.title}>{t("There are no items")}</div>
            <div data-intrcm-id="Placeholder_jghvq50h" className={styles.subTitle}>{t(subTitleKey)}</div>
        </div>
    )
}