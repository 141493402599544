import { Divider, withStyles } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clearDrawerNavigation, selectDrawerNavigationOption, setDrawerNavigationMode } from '../../../../actions/drawerNavigationActions';
import { getMyDrawerFromPath } from '../../../../utils/drawerNavigation/myDrawerNavigationHelpers';
import DrawerContainer from '../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer';
import { useDrawerNavigation } from '../../../components/Common/Hooks/useDrawerNavigation/useDrawerNavigation';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { UserInfoDrawer } from '../../../components/UserInfo/UserInfoDrawer';
import {DrawerSwitcherNew} from './DrawerSwitcher';
import { withMixpanel } from '../../../components/Common/withMixpanel';
import { push } from "react-router-redux"
import {themeConstants} from '../../../../theme';
import { useNewActionCenter } from '../../../../utils/useNewTable';

const styles = theme => ({
    drawerWrapper: {
        position: 'relative',
    },
    container: {
        paddingTop: 24,
    },
    divider: {
        backgroundColor: '#daddee',
        margin: 16,
        marginTop: 24,
        marginBottom: 0,
    },
    dividerSmall: {
        marginTop: 24,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 16,
    },
    navigation: {
        margin: 16
    },
    navigationSmall: {
    },
    navigationTitle: {
        fontFamily: 'Rubik',
        fontSize: 18,
        marginBottom: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.commonBlack,
        display: 'block'
    },
  
    drawerHeader: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 10px',
        textAlign: 'right',
    },
    drawerHeaderLeft: {
        textAlign: 'right',
    },
    drawerHeaderRight: {
        textAlign: 'center',
    },
    icon: {
        color: '#979aaa',
        fontSize: 35,
        cursor: 'pointer'
    },
    memberHandle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
    },
    editButton: {
        width: 149,
        height: 40,
        boxShadow: 'none',
        fontSize: 14,
        textTransform: "uppercase",
        color: "#fff",
        backgroundColor: theme.customColors.primary.main,
        '&:hover': {
            backgroundColor: `${theme.customColors.primary.b300}!important`,
        },
    },
    editButtonHolder: {
        backgroundColor: '#eeeef3',
        width: 46,
        height: 46,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%'
    },
    editButtonSmall: {
        fontSize: 11,
        textTransform: 'capitalize',
        padding: '4px 7px',
    },
    paper: {
        height: 'inherit!important',
        width: themeConstants.navigationDrawerWidth,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    editIcon: {
        color: '#555563',
        cursor: 'pointer',
    },
})

const __MyPageLeftDrawerNew = (props) => {
    const {profileData, classes, dispatch, drawerNavigation, handleSwitchDrawerStatus, isDrawerOpen, orgUid, mixpanelTrack} = props
    const [t] = useTranslation('groupDrawerTranslate')
    const [tDrawerListItemTerms] = useTranslation('drawerListItemTerms')
    const paperStyle = isDrawerOpen ? {} : { width: themeConstants.navigationDrawerSmallWidth, overflowX: 'hidden' }

    const withActionCenter = useNewActionCenter(orgUid)

    const settings = useMemo(() => {
        return {
            tDrawerListItemTerms,
            withActionCenter
        }
    }, [withActionCenter])

    useEffect(() => {
        const {pathname} = window.location
        const [mode, id] = getMyDrawerFromPath(pathname)

        dispatch(setDrawerNavigationMode(mode))
        dispatch(selectDrawerNavigationOption(id))

        return () => {
            dispatch(clearDrawerNavigation())
        }
    }, [])

    const [Navigation] = useDrawerNavigation({drawerNavigation, isDrawerOpen, t,profileData, settings})
    const NavigationSection = (
        <div data-intrcm-id="MyPageLeftDrawerNew_o32h00l0" className={isDrawerOpen ? classes.navigation : classes.navigationSmall}>
            {Navigation}
        </div>
    )

    return (
        <div data-intrcm-id="MyPageLeftDrawerNew_tz62418y" className={classes.drawerWrapper}>
            <DrawerContainer  containerStyle={`${classes.container} ${classes.paper}`}  PaperProps={{style: paperStyle}} open={isDrawerOpen}>
                <DrawerSwitcherNew isDrawerOpen={isDrawerOpen} onClick={handleSwitchDrawerStatus}/>
                <UserInfoDrawer profile={profileData} isDrawerOpen={isDrawerOpen}/>
                <Divider className={ isDrawerOpen ? classes.divider : classes.dividerSmall } />
                {NavigationSection}
            </DrawerContainer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    profileData: state.userData.profileData,
    drawerNavigation: state.drawerNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const MyPageLeftDrawerNew = compose(
    withStyles(styles),
    withMixpanel,
    withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__MyPageLeftDrawerNew);