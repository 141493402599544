export let loadFavouritesInfo = function (props) {
    const { searchQuery, dispatch, favouritesService } = props;

    return favouritesService.getFavouritesInfo(searchQuery).then((result) => {
        dispatch({
            type: 'RESULT_CHANNEL_UPDATE', results: {
                channel: result,
            }
        })

        this.setState({ groupInfoLoaded: true })
    })
}

export let loadData = function (props, dataReloadInidicator) {
    const { searchQuery, favouritesService, dispatch } = props;

    let newSearchQuery = { ...searchQuery };

    return favouritesService.loadData(newSearchQuery).then((result) => {
        this.setState({
            isLoading: false,
            results: result.data,
            searchResultCount: result.count,
            searchResultTime: result.took,
            // dataTypes: result.count > 0 ? result.aggregations.dataTypes.Items : [],
            experts: result.count > 0 ? result.aggregations.experts.Items : [],
            relatedTags: result.count > 0 ? result.aggregations.hashtag.Items : [],
            dataReloadInidicator: dataReloadInidicator || 0
        })

        dispatch({ type: 'DATA_UPDATED' });
        // dispatch({
        //     type: 'RESULT_FILTERS_UPDATE', results: {
        //         dataTypes: [{
        //             Key: 'All',
        //             DocCount: result.count
        //         }, ...result.aggregations.dataTypes.Items],
        //     }
        // });
    })
}

export let filterData = function (props, dataReloadInidicator) {
    const { searchQuery, dispatch, favouritesService } = props;

    return favouritesService.loadData(searchQuery)
        .then((result) => {
            var hasMore = result.count > 10
            this.setState({
                dataReloadInidicator: dataReloadInidicator,
                results: result.data,
                searchResultCount: result.count,
                searchResultTime: result.took,
                isLoading: false,
                isRefreshing: false,
                hasMore,
            });
            
            dispatch({ type: 'DATA_UPDATED' });
        })
}

export let loadKanbanData = function (props) {
    const { searchQuery, dispatch, favouritesService } = props;

    return favouritesService.getKanbanData(searchQuery).then((kanbanResult) => {
        this.setState({
            assigned: kanbanResult.assigned || [],
            unassigned: kanbanResult.unassigned,
            total: kanbanResult.total,
        })

        dispatch({ type: 'DATA_UPDATED' });
        // dispatch({
        //     type: 'RESULT_FILTERS_UPDATE', results: {
        //         dataTypes: [{
        //             Key: 'All',
        //             DocCount: kanbanResult.assigned.length + kanbanResult.unassigned.length
        //         }],
        //     }
        // });
    })
}

export let buildKanban = function () {
    let collectionViews = [...this.props.collectionInfo.collectionViews];

    // Load assigne items
    if (collectionViews) {
        collectionViews.map(collectionView => collectionView.itemLists.map(itemList =>
            itemList.items.map(item => {
                var doc = this.state.assigned.find(data => data.id === item.documentId);
                if (doc) {
                    item.title = doc.document.title
                    item.doc = doc.document
                }
            })))
    }

    // Generate unassigned items
    var unassignedItems = this.state.unassigned.map((unassigned, idx) => {
        return {
            documentId: unassigned.id,
            title: unassigned.document.title,
            order: idx,
            doc: unassigned.document
        }
    })

    // Load kanban data - cards and lanes placement
    var columns = [];

    if (collectionViews) {
        this.view = collectionViews.find(c => c.collectionViewType === "Kanban");
        this.view.itemLists.unshift({ title: 'Unassigned', description: 'Unassigned items', order: -1, items: unassignedItems, id: -1 });
        columns = this.view.itemLists;
    }

    var data = { lanes: [] }

    columns.forEach(column => {
        var cards = column.items.map(item => {
            return { id: item.documentId, title: item.title, order: item.order, doc: item.doc, style: cardStyle }
        }).sort((card1, card2) => {
            return card1.order - card2.order
        })

        data.lanes.push({
            id: column.id,
            title: column.title,
            cards: [...cards/*, emptyCard(column.id)*/],
            order: (column.collectionViewId == this.view.id) ? column.order : -1,
            style: laneStyle,
            isUnassigned: (column.collectionViewId != this.view.id),

            // this function run before the nature scroll of kanban-trello and able to ovrride it
            onScroll: (e) => {
                const { unassigned, total } = this.state

                // If the lane is assigned
                if (column.collectionViewId == this.view.id ||
                    // If the lane is unassigned but all card loaded
                    unassigned.length >= total) {
                    e.stopPropagation();
                }
            }
        })
    })
    data.lanes.sort((a, b) => a.order - b.order)
    // return data;
    this.setState({ data: data });
}

export let addLane = function (newData) {

    // update the lane in view
    const createdLane = newData.lanes[newData.lanes.length - 1]
    createdLane.order = newData.lanes.length - 2
    createdLane.style = laneStyle
    createdLane.isTempLane = true
    // createdLane.cards.push(emptyCard(createdLane.id))
    this.updateLanes(newData.lanes)

    const oldLaneId = createdLane.id
    let laneDTO = { title: createdLane.title, collectionViewId: this.view.id }

    this.props.favouritesService.addLane(laneDTO).then(newLane => {

        // chnged the lane to be like the lane in DB
        const oldLane = newData.lanes.find(l => l.id == oldLaneId && l.isTempLane)
        oldLane.id = newLane.id
        oldLane.order = newLane.order
        oldLane.title = newLane.title
        oldLane.isTempLane = false

        this.updateLanes(newData.lanes)
    })
}

export let moveCard = function (props, viewType, cardId, sourceLaneId, targetLaneId, position) {
    const { data, assigned, unassigned, from } = this.state

    const sourceLane = data.lanes.find(l => l.id == sourceLaneId)
    const targetLane = data.lanes.find(l => l.id == targetLaneId)
    const card = targetLane && targetLane.cards.find(c => c.id == cardId)

    // if card.isEmptyCard => the card is fake card and exist only for ux reason
    if (!sourceLane || !targetLane || !card) {
        return
    }
    else if (sourceLane.isUnassigned && targetLane.isUnassigned) {
        return
    }
    // Deprecate condition
    else if (card.isEmptyCard) {
        // move the empty card to the last plase 
        this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: targetLane.cards.length })
    }

    // if targetLaneId.isTempLane => the lane isn't sync with server side and have not the correct id 
    else if (targetLane.isTempLane) {
        // move back the card 
        this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: position })
    } else {

        // update the total if the target or the source is unassigned
        // both of them can be unassigned if the user move the card in the same unassigned-Lane
        if (sourceLane.isUnassigned) {
            const oldCard = unassigned.find(card => card.id == cardId)

            const index = unassigned.indexOf(oldCard)
            unassigned.splice(index, 1)

            assigned.push({ ...oldCard })
            this.setState({ total: this.state.total - 1, assigned: [...assigned], unassigned: [...unassigned], from: (from - 1) })

        } else if (targetLane.isUnassigned) {
            const oldCard = assigned.find(card => card.id == cardId)
            const index = assigned.indexOf(oldCard)
            assigned.splice(index, 1)
            unassigned.push({ ...oldCard })
            this.setState({ total: this.state.total + 1, assigned: [...assigned], unassigned: [...unassigned]/*, from: (form + 1)*/ })
        }

        card.order = position

        this.updateLanes(this.state.data.lanes)

        props.favouritesService.moveCard({
            CollectionViewId: this.view.id,
            CardId: cardId,
            SourceLaneId: sourceLaneId,
            TargetLaneId: targetLaneId,
            Position: position
        }).then((res) => {
            if (!res.ok) {
                this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: targetLane.cards.length })
            }
        })
    }
}

export function moveLane(laneId, newPosition, laneObj) {
    const { favouritesService, collectionInfo } = this.props

    // if it's the "Unassigned" lane
    if (laneObj.order == -1 || newPosition == 0) {
        return
    }

    const { lanes } = this.state.data
    const oldPosition = laneObj.order + 1

    // if the lane move to the left side
    if (newPosition < oldPosition) {
        var t = lanes.filter(l => (l.order + 1) >= newPosition && l.order < laneObj.order)
        t.forEach(l => l.order++)
    } else { // if the lane move to the right side
        var t = lanes.filter(l => l.order > laneObj.order && (l.order + 1) <= newPosition)
        t.forEach(l => l.order--)
    }

    // change the position of the moved lane
    laneObj.order = newPosition - 1

    // sort the lanes and init the data in order that 
    // the board component know that the order changed
    lanes.sort((a, b) => a.order - b.order)

    this.updateLanes(lanes)

    // change the order in server
    const collection = {
        id: collectionInfo.id,
        collectionViews: [
            {
                id: getKanbanCollection(collectionInfo).id,
                itemLists: lanes.filter(l => l.order != -1).map(l => ({
                    id: l.id, order: l.order
                }))
            }
        ]
    }

    favouritesService.moveLane(collection)
}

export function deleteLane(laneId) {
    const { favouritesService, collectionInfo } = this.props
    const { lanes } = this.state.data

    favouritesService.deleteLane(laneId, getKanbanCollection(collectionInfo).id)

    // move the cards from the deleted lane to unassigned lane

    // the index of the deleted lane
    const deletedLaneIndex = lanes.findIndex(l => l.id == laneId)

    if (deletedLaneIndex != -1) {
        const unassignedLane = lanes.find(l => l.order == -1)
        lanes[deletedLaneIndex].cards.forEach(c => {
            if (!c.isEmptyCard) {
                c.order = unassignedLane.cards.length
                unassignedLane.cards.push(c)
            }
        })

        // remove the lane
        lanes.splice(deletedLaneIndex, 1)
    }

    // update the data in the board 
    this.updateLanes(lanes)
}

export function updateLane(laneId, newObj) {
    const { favouritesService, collectionInfo } = this.props;
    const { lanes } = this.state.data;

    const collection = {
        id: collectionInfo.id,
        collectionViews: [
            {
                id: getKanbanCollection(collectionInfo).id,
                itemLists: lanes
                    .filter(l => l.id == laneId)
                    .map(l => ({
                        id: l.id,
                        title: newObj.title,
                        description: newObj.description
                    }))
            }
        ]
    }

    favouritesService.updateLane(collection);
}

function getKanbanCollection(collectionInfo) {
    const kanban = collectionInfo.collectionViews.find(c => c.collectionViewType == "Kanban")

    return kanban
}

const cardStyle = {
    padding: 0, marginBottom: 12, border: 'none', width: 244, backgroundColor: 'transparent', marginRight: 'auto', marginLeft: 'auto'
}
const laneStyle = { backgroundColor: '#f8f9fc', margin: '0px 5px' }