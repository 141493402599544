import { withStyles } from '@material-ui/core';
import { randomID } from '@tuqqi/common';
import React, {useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const proccessSimpleText = (text = '', t) => {
    const strWithoutSpaces = text.trim()
    if(!strWithoutSpaces) return text
    const temp = t(strWithoutSpaces)
    const res = text.replace(strWithoutSpaces, temp)
    return res
}

const styles = (global => ({
    text: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.25,
        color: '#0C0C0D',
    },
    bold: {
        fontWeight: '500',
        color: '#0C0C0D',
    }
}))

const mapText = (text = '', type = '', type1 = 'item') => {
    if(type1 === 'private' && type === "user_add_comment") return "${0} sent a messsage ${2}"
    if(type1 === 'group' && type === "user_add_comment") return "${0} comented ${2} on your group"
    if(type === "user_add_comment") return "${0} comented ${2} on an item ${1}"

    return text
}

const _NotificationParsedTextNew = (props) => {
    const {messageTemplateParams, text, systemActionTypeUid, className, title, icon, classes, users, type} = props
    const [t] = useTranslation("notificationsDrawer")

    const usersNames = useMemo(() => {
        return users.map(x => `${x.firstname?.trim?.()?.toLowerCase?.() || ''}${x.lastname?.trim?.()?.toLowerCase?.() || ''}`)
    }, [users])

    const mappedText = useMemo(() => mapText(text, systemActionTypeUid, type), [systemActionTypeUid, text, type])

    const getMessageTemplateParam = useCallback((match) => {
        const place = (match).replace(/[^\d]/gi, '')

        let res = messageTemplateParams[place] || ''
        // if(res === title) res = ''
        return res
    }, [messageTemplateParams, title])

    const getTemplate = useCallback((text, isBold) => {
        let res
        if (!text) {
            res = null
        }
        else if (!isBold) {
            res = text
        }
        else {
            const val = text.split("").map(x => x?.trim?.() || '').join('')?.toLowerCase?.()

            if(usersNames.includes(val)) {
                res = <span key={randomID()} className={classes.bold}>{text}</span>
            } else {
                res = <span key={randomID()} className={classes.bold}>"{text}"</span>
            }
        }

        return res
    }, [usersNames])

    const getRegExecution = useCallback((text) => {
        const reg = /\$\{\d\}/gi

        const res = reg.exec(text)
        if (!res) {
            return null
        }

        return {
            match: res[0],
            index: res.index,
            lastIndex: reg.lastIndex
        }
    }, [])

    const splitedText = useMemo(() => {
        let tempText = mappedText
        const splitedText = []

        let regExecution = getRegExecution(tempText)
    
        while (regExecution != null) {
            const _simpleText = tempText.substring(0, regExecution.index)
            const simpleText = proccessSimpleText(_simpleText, t)
            
            splitedText.push(getTemplate(simpleText, false))

            const messageTemplateParam = getMessageTemplateParam(regExecution.match)
            splitedText.push(getTemplate(messageTemplateParam, true))

            tempText = tempText.substring(regExecution.lastIndex)
            regExecution = getRegExecution(tempText)
        }

        if (!splitedText.length || tempText) {
            splitedText.push(getTemplate(tempText, false))
        }    
        return splitedText
    }, [text, getRegExecution, getTemplate, getMessageTemplateParam])

    return <span className={`${classes.text} ${className}`}>{splitedText}</span>
}

const mapStateToProps = (state) => {
    return {
        users: state.data.globalData.users?.data ?? []
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(_NotificationParsedTextNew)