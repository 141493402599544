import { FormControlLabel, Grid, Switch, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Add } from '@material-ui/icons';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../../actions/appActions';
import { dataUpdate, updateSpecificItem } from '../../../../actions/dataActions.js';
import { userDataLoad } from '../../../../actions/globalDataAction.js';
import { allCollectionMembers } from '../../../views/groups/groupUtils.js';
import InfinityUsersList from '../../Common/Users/InfinityUsersList.js';
import UserListItem from "../../Common/userListItem";

// Apply some reset
const styles = theme => ({
    avatar: {
        height: 38,
        width: 38,
        margin: '27px auto 10px auto',
    },
    root: {
        width: '100%',
        height: '65%',
        flexGrow: 1,
    },
    text: {
        margin: 'auto',
        textAlign: 'center'
    },
    ...theme.rightDrawerClasses.headerWidget,
    description: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.64)',
        display: 'block',
        fontStyle: 'normal',
        fontStretch: 'normal',

    },
    descriptionPadding: {
        paddingTop: 13,
        paddingBottom: 14,
    },
    button: {
        textTransform: 'none',
        color: theme.customColors.primary.b600,
        backgroundColor: '#ffffff',
        margin: '14px 10px 14px 10px'
    },
    users: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.2,
        color: 'rgba(0, 0, 0, 0.38)',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleWidget: theme.rightDrawerClasses.titleWidget,
});


class AddMembersToItem extends Component {

    state = {
        shareOpen: false,
        checked: [],
        selectedAll: false,
    }

    handleSelectAll = () => {
        let userIds;

        if (this.state.selectedAll) {
            userIds = [];
        } else {
            userIds = this.getOptionalUsersToAdd().map(u => u.id);
        }

        this.setState({
            selectedAll: !this.state.selectedAll,
            checked: userIds,
        })
    }

    handleToggle = value => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    };


    cancel = () => {
        this.setState({ checked: [] })

        this.props.handleClose();
    }

    save = () => {
        const { inputService, docId, dispatch, t, activeUsers } = this.props;

        dispatch(showSnackbar(t('Adding members')));

        const updatedMembers = this.state.checked.map((userId) => (activeUsers.find(user => user.id === userId).auth0_id ))
        inputService.addMembers(docId, updatedMembers).then((userData) => {
            dispatch(userDataLoad(userData));
            dispatch(updateSpecificItem(docId));
            dispatch(showSnackbar(t('Members Added!')));
            this.cancel()
        });
    }

    initMembers = () =>  {
        const { document, activeUsers } = this.props;

        var membersIds = document.members ? document.members.map(member => {
            if (member.userId) {
                return member.userId
            } else {
                return (activeUsers.find(user => user.auth0_id === member.userUUID) || {}).id
            }
        }) : [];

        this.setState({ checked: membersIds })
    }

    renderInviteUser() {
        const { classes, t } = this.props;

        return (<ListItem
            dense
            button
            disabled
            className={classes.listItem}
        >
            <ListItemAvatar>
                <Add />
            </ListItemAvatar>

            <ListItemText
                primary={t('Invite unlisted user')}
                secondary={t('Coming soon')}
            />

        </ListItem>)
    }

    renderUser = (user, onChange) => {
        const { classes, document } = this.props;

        if (user.id === this.renderInviteUserId) {
            return this.renderInviteUser()
        }

        return (
            <UserListItem
                disabled={user.auth0_id === document.userUUID}
                user={user}
                key={user.tag}
                onClick={() => {
                    this.handleToggle(user.id)
                    onChange()
                }}
                classes={classes}
                checked={this.state.checked.indexOf(user.id) !== -1} />)


    }

    renderInviteUserId = -1
    getOptionalUsersToAdd(){
        const { activeUsers, document, collections, profileData } = this.props;

        let optionalUsersToAdd;
        const currCollection = document.groupUid && collections.find(c => c.collectionGroup.groupUid === document.groupUid);

        if (currCollection) {
            optionalUsersToAdd = allCollectionMembers(currCollection).filter(user => user.status === 0)
        } else {
            optionalUsersToAdd = activeUsers;
        }

        return optionalUsersToAdd
    }

    getUsersToAdd(){
        const optionalUsersToAdd = this.getOptionalUsersToAdd();

        const creator = optionalUsersToAdd.find(user => user.auth0_id === this.props.document.userUUID)
        const usersToAdd = []
        if(creator){
            usersToAdd.push(creator)
        }
        usersToAdd.push(...optionalUsersToAdd.filter(user => user.auth0_id !== this.props.document.userUUID), {
            id: this.renderInviteUserId
        })

        return usersToAdd
            .filter(user => user.status === 0)
            .sort((a,b) => {
                const aa = a?.firstname ? a.firstname.trim() : '' + " " + a?.lastname ? a.lastname.trim() : '',
                bb = b?.firstname ? b.firstname.trim() : '' + " " + b?.lastname ? b.lastname.trim() : '';
                if (aa > bb) return 1;
                else if (aa < bb) return -1;
                return 0;
            })
    }

    render() {
        const { classes, open, handleClose, t } = this.props;
        const { checked, selectedAll } = this.state;

        if(!open){
            return null
        }
        const usersToAdd = this.getUsersToAdd()

        return (
        <Dialog onEnter={this.initMembers} fullWidth open={open} onClose={handleClose}>
            <DialogTitle>
                <Grid container direction='row' justify='space-between' alignItems='center'>
                    <Grid item>{t('Add members to document')}</Grid>
                    <Grid item>
                        <FormControlLabel
                            labelPlacement='start'
                            control={
                                <Switch
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label={t("Suggested members")}
                        /></Grid>
                </Grid></DialogTitle>
            <Divider />
            <DialogContent>
                <InfinityUsersList
                    users={usersToAdd}
                    renderUser={this.renderUser}
                    checked={checked}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={this.handleSelectAll} color="primary">
                    {t(selectedAll ? 'Clear' : 'Select All')}
                </Button>
                <Button onClick={this.cancel} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={this.save} color="primary">
                    {t('Add')}
                </Button>
            </DialogActions>
        </Dialog>)
    }

}

const mapStateToProps = (state) => ({
    collections: state.userData.collections,
    profileData: state.userData.profileData,
    inputService: state.services.inputService,
    userSettingsService: state.services.userSettingsService,
    queryService: state.queryService,
    users: state.globalData.users,
    activeUsers: state.globalData.activeUsers,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('addMembersToItem'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AddMembersToItem);