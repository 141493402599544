import { initPusher as initPusherWeb } from '@tuqqi/web';

export let initPusher = (userData) => {
    const token = userData?.idToken
    const userUid = userData?.profileData?.auth0_id

    if(!token || !userUid) return undefined

    return initPusherWeb(userUid, token)
}

export let getChannelName = (value) => {
    return `private-user_${value.replace('|', '_')}`;
}

export let getTimeTrackingChannel = (value) => {
    return `timetracking_${value.replace('|', '_')}`;
}