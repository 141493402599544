import { Icon, withStyles, Typography, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import classnames from "classnames";
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import compose from 'recompose/compose';
import { FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN, RESULT_CHANNEL_EMPTY } from '../../../../actionTypes/actionTypes';
import { getGroupDocumentPermission, getPublicDocumentPermission, hexToRGB, parseImageUrl } from '@tuqqi/common';
import withErrorCatcher from '../../Common/withErrorCatcher';
import GeneralButton from '../../Common/AllButton/AllButton';
import { CreateIcon } from '../../Common/Icons/CreateIcon';
import { getDefaultPostText } from '../../Input/inputUtils';
import { UpdateFeedConfig } from './updateFeed';
import { managerTableKeepGlobalData } from '@tuqqi/web';
import { changeCollectionInputInfo, openInput } from '../../../../actions/inputActions';
import { shouldAutoOpen } from '../../../views/groups/groupUtils';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { ItemOpenerGroup } from '../../Input/otherInput';


// Apply some reset
const styles = theme => ({
    backIconContainer: {
        overflow: 'hidden',
        transition: 'width 0.2s linear 0s',
        boxSizing: 'border-box',
        width: 36,
        maxHeight: 28,
        paddingLeft: 12
    },
    backIcon: {
        color: '#ffffff',
        cursor: 'pointer'
    },
    divider: {
        position: 'absolute',
        marginTop: -2,
        marginLeft: -20,
        height: 30,
        borderLeft: `1px solid ${theme.customColors.primary.b200}`
    },
    img: { marginLeft: 16,  maxHeight: 28, cursor: 'pointer', objectFit: 'contain' },
    withoutWidth: { width: 0 },
    title: {
        color: 'rgba(255,255,255,0.77)',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: '500',
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 18,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    buttonContainer: {
        paddingRight: 9,
        paddingLeft: 9,
        marginRight: 8,
        marginLeft: 24,
        borderRadius: 8,
        boxShadow: 'none',
        width: 'fit-content',
        height: 26,
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: '#fff',
        border: `solid 1px ${"rgba(255, 255, 255, 0.16)"}`,
        '&:hover': {
            boxShadow: 'none',
            color: '#fff',
            background: hexToRGB(theme.customColors.primary.b500, 0.16),
        },
    },
    buttonText: {
        marginLeft: 7,
        fontSize: 14,
        fontWeight: '400',
        color: '#fff',
        letterSpacing: 0.4,
    },
});

const DEFAULT_LOGO = '/resources/default-logo-toolbar.png'
class ToolbarLeftSide extends PureComponent {
    state = {
        logo: DEFAULT_LOGO
    }
    _goBack = () => {
        this.props.dispatch(goBack())
    }
    componentDidMount() {
        const { cloudfront, orgLogo } = this.props;
        const parse = parseImageUrl(cloudfront);
        if (!orgLogo) return
        this.setState({ logo: parse(orgLogo) })

        managerTableKeepGlobalData.setOpenGlobalInput(this.openInput)
        managerTableKeepGlobalData.setOpenGroupInput((id, groupUid, group) => {
            this.addCardOnGroup(id, groupUid, group)
        })
    }
    listenLogoUpdate = (prevProps) => {
        const { orgLogo: prevOrgLogo } = prevProps
        const { cloudfront, orgLogo } = this.props;
        if (orgLogo === prevOrgLogo) return

        const parse = parseImageUrl(cloudfront);
        this.setState({ logo: !orgLogo ? DEFAULT_LOGO : parse(orgLogo) })
    }
    componentDidUpdate(prevProps) {
        this.listenLogoUpdate(prevProps)
        managerTableKeepGlobalData.setOpenGroupInput((id, groupUid, group) => {
            this.addCardOnGroup(id, groupUid, group)
        })
    }

    goHome = () => {
        const { dispatch } = this.props;

        dispatch(push('/feed'));
        UpdateFeedConfig.update()
        // dispatch({ type: DATA_FEED_UPDATE });
        dispatch({ type: RESULT_CHANNEL_EMPTY });
    }
    renderSpecificTitle = (title) => (
        <div data-intrcm-id="ToolbarLeftSide_f688evid" style={{ marginLeft: 50 }}>
            <Divider className={this.props.classes.divider} />
            <Typography className={this.props.classes.title}>{title}</Typography>
        </div>
    )
    renderTitle = () => {
        if (window.location.pathname.includes('library')) return this.renderSpecificTitle(this.props.t('Library'))
    }

    hasPermissionToAdd = (groupUid) => {
        const { groups } = this.props
        if (groups.some(g => g.groupUid == groupUid)) {
            return true
        }

        return false
    }

    addCardOnGroup = (id, groupUid, currentGroup) => {
        const { dispatch, inputMappings, oldUiInput, userData, search } = this.props
        const item = currentGroup
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = id === -1 ? "" : id + ''

        if (shouldAutoOpen(item)) {
            const dataType = item.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, search, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            ItemOpenerGroup.groupUid = groupUid
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }

        dispatch(changeCollectionInputInfo({kanbanListId}))
    }

    openForGroup = (groupUid) => {
        const { dispatch, inputMappings, oldUiInput, userData, search } = this.props

        const item = [...managerTableKeepGlobalData.getUserCollections(), ...managerTableKeepGlobalData.getOpenCollections()].find(x => x.collectionGroup.groupUid === groupUid)
        if(!item) return

        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = ""

        if (shouldAutoOpen(item)) {
            const dataType = item.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, search, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }
    }


    openInput = () => {
        const { dispatch, search } = this.props
        const { query, groupUid } = search

        if(!!groupUid) {
            this.openForGroup(groupUid)
            return
        }

        const payload = { postText: getDefaultPostText(query), dataType: 'post' }
        if (groupUid && this.hasPermissionToAdd(groupUid)) {
            payload.permissions = [groupUid]
        }
        dispatch({
            type: 'FULLVIEW_POST',
            openPost: true,
            item: payload
        })
        dispatch({ type: 'INPUT_OPEN', model: payload });

    }

    allowedCreate = ['/', '/feed', '/mypage/actioncenter', '/orgscheduler', '/group/table', '/group/chat']

    renderCreate = () => {
        return null
        if (this.allowedCreate.includes(window.location.pathname)) return <GeneralButton onClick={this.openInput} icon={<CreateIcon  />} containerStyle={this.props.classes.buttonContainer} textStyle={this.props.classes.buttonText} title={this.props.t("Create")} />
        return null
    }

    render() {
        const { classes, isFeed, location } = this.props;
        const { logo } = this.state

        return (
            <Grid item container className={classes.container} alignItems='center'>
                <div data-intrcm-id="ToolbarLeftSide_care88yt" className={classnames(classes.backIconContainer, isFeed && classes.withoutWidth)}>
                    <Icon aria-label="Back" className={classes.backIcon} onClick={this._goBack}>chevron_left</Icon>
                </div>
                <img data-intrcm-id="ToolbarLeftSide_p3x42ewi" onClick={this.goHome} className={classes.img} src={logo} />
                {this.renderTitle()}
                {this.renderCreate()}
            </Grid>

        )
    }
}
const existOrgLogo = (state) => !!(state && state.userData && state.userData.profileData && state.userData.profileData.org && state.userData.profileData.org.logo)

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    cloudfront: state.globalData.cloudfront,
    search: state.search,
    groups: state.userData.groups,
    userData: state.userData,
    orgLogo: existOrgLogo(state) && state.userData.profileData.org.logo,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    isFeed: state.wrapper.isFeed,
})

export default compose(withStyles(styles),
    withErrorCatcher(),
    withTranslation('feedToolbar'),
    connect(mapStateToProps, mapDispatchToProps))(ToolbarLeftSide);