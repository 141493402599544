import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { withMixpanel } from '../../../../../components/Common/withMixpanel';
import goTo from '../../../../../actions/goTo';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom: 16,
        wordBreak: 'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        listStyle: 'none',
        marginLeft: 0,
        paddingLeft: 0,
    },
    work: {
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'flex-start',
    },
    groupInfoHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleWork: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0,
        color: '#21212d',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 204,
        whiteSpace: 'nowrap',
        paddingTop: 3,
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline'
        }
    },
    description: {
        marginTop: -3,
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: ' normal',
        color: '#979aaa',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 204,
        whiteSpace: 'nowrap',
    },
    imageUser: {
        marginRight: 12,
        width: 40,
        height: 40,
        borderRadius: '100%',
        // border: '3px solid transparent',
        // transition: 'border-color .4s ease-out',
        // cursor: 'pointer',
        // '&:hover': {
        //     borderColor: theme.customColors.primary.main,
        // }
    },
    teamHeading: {
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 2,
        color: '#979aaa',
        marginBottom: 12,
        textTransform: 'uppercase'
    },
    seeOrgChat: {
        textAlign: 'center',
        color: theme.customColors.primary.main,
        fontSize: 13,
        textTransform: 'uppercase',
        fontWeight: 500,
        cursor: 'pointer'
    },
    teamItem: {
        height: 40,
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'flex-start',
    },

});

const TeammateComponent = ({ userData, classes, onTitleClick }) => {
    return (<div data-intrcm-id="UserTeamWidget_58n43m4k" className={classes.teamItem}>
        <img alt={userData.title} src={userData.profileImageUrl} className={classes.imageUser} />
        <div data-intrcm-id="UserTeamWidget_up2t6kky" className={classes.groupInfoHolder}>
            <span onClick={() => onTitleClick(userData.tag)} className={classes.titleWork}>{userData.firstname + ' ' + userData.lastname}</span>
            <span className={classes.description}>{userData.title}</span>
        </div>
    </div>)
}


const UserTeamWidget = (props) => {
    const { classes, dispatch, isMyUser, userProfile, activeUsers, mixpanelTrack, withoutContainer } = props;
    const [supervisor, setSupervisor] = useState({ profile: null, exists: false })
    const [teammates, setTeammates] = useState({ data: [], exists: false })
    const { t } = useTranslation('userTeamWidget')
    const { firstname, id, superiorId } = userProfile

    useEffect(() => {
        const supervisor = activeUsers.find(u => u.id === superiorId)
        const childs = activeUsers.filter(u => u.superiorId === superiorId && u.id !== id)

        if (!!supervisor) setSupervisor({ exists: true, profile: supervisor })
        if (!!childs) setTeammates({ exists: true, data: childs.sort((a, b) => b.points - a.points) })
    }, [superiorId.id, activeUsers])

    const openUser = (tag) => {
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        mixpanelTrack('User team widget - open user', {})
        userTagFormat += tag.replace(' ', '').toLowerCase()
        goTo(userTagFormat, dispatch)()
    }

    const goToChart = () => {
        mixpanelTrack('User team widget - go to org chart', {})
        dispatch(push('/orgchart'))
    }
    const Content = (
            <>
                <div data-intrcm-id="UserTeamWidget_mxjqed97" className={classes.titleSection}>
                    <div data-intrcm-id="UserTeamWidget_wlk3qwui" className={classes.title}>{isMyUser ? t("My Team") : firstname + "'s Team"}</div>
                </div>
                <div data-intrcm-id="UserTeamWidget_wtk8gi8d" className={classes.teamHolder}>
                    {supervisor.exists && <>
                        <h4 className={classes.teamHeading}>{t("Supervisor")}</h4>
                        <TeammateComponent onTitleClick={openUser} classes={classes} userData={supervisor.profile} />
                    </>
                    }
                    <h4 className={classes.teamHeading}>{t("Teammates")}</h4>
                    {teammates.exists && teammates.data.slice(0, 3).map(item => {
                        return <TeammateComponent key={item.id} onTitleClick={openUser} classes={classes} userData={item} />
                    })}
                    <div data-intrcm-id="UserTeamWidget_0wpyfp5z" onClick={goToChart} className={classes.seeOrgChat}>{t("See Org Chart")}</div>
                </div>
            </>
    )
    if(!!withoutContainer) return Content
    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="UserTeamWidget_6yrbbcbv" className={classes.containerInner}>
                {Content}
            </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    activeUsers: state.globalData.activeUsers
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withTranslation('userTeamWidget'),
    withMixpanel,
    connect(mapStateToProps, mapDispatchToProps),
)(UserTeamWidget);