import { Badge, Icon, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { CLEAN_FILTER, REMOVE_LOCAL_FILTER, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import { checkIfChannel, checkIfCollectionOrGroup } from '../../views/search/searchUtils';
import FilterModal from './SubheaderToolbar/Filters/FilterModal.js';
import SearchInput from './searchInput';
import './styles/themeAutoSuggest.scss';
import SearchNavigation from './SearchNavigation/SearchNavigation.js';
import { goTo } from '../../../actions/goToActions';
import goToCollection from '../../actions/goToCollection';
import { addRecentRequest, updateRecentRequestTimestamp } from '../../../actions/searchNavigationActions';
import { createCustomTheme } from '../../../utils/theming.js';
import { getAllCollection } from '../../views/groups/groupUtils.js';
import { debounce } from '../../../utils/debounce.js';
import { queryFiltersActions, searchQueryActions, initialSearchQuery, isCommonFilterPresented, isSomeMappingFilterPresented, managerTableRef, shouldUseScheduler, managerGroupGantt, managerGanttGlobalHeader, managerGroupScheduler, managerSchGlobalHeader, managerCalGlobalHeader, managerOrgScheduler, managerGroupCalendar, managerUserCalendar } from '@tuqqi/web';
import LaunchpadNavigation from './LaunchpadNavigation/LaunchpadNavigation.js';
import { isShouldLaunchpadOpen, isShouldSearchOpen } from '../../../utils/launchpadNavigation/launchpadUtils.js';
import { hexToRGB } from '../../../utils/hexToRGB.js';
import { isGanttNew } from '../../../utils/ganttSwitcher.js';
import UserNavigationModal from './HeaderToolbar/UserNavigation/UserNavigationModal.js';
import NotificationsModal from './HeaderToolbar/NotificationsModal/NotificationsModal.js';
import ChatsModal from './HeaderToolbar/Chat/ChatsModal.js';
import { useNewActionCenter, useNewTable } from '../../../utils/useNewTable.js';
import withErrorCatcher from '../Common/withErrorCatcher.js';
import CreateNewModal, { isShouldCreateOpen } from './HeaderToolbar/CreateNew/CreateNewModal.js';


const styles = theme => {
  const whiteOpacity015 = hexToRGB('#FFFFFF', 0.15)
  const whiteOpacity016 = hexToRGB('#FFFFFF', 0.16)
  const whiteOpacity03 = hexToRGB('#FFFFFF', 0.3)

  return ({
    searchBar: { ...theme.searchContainer },
    searchContainer: {
      marginRight: 0,
      marginLeft: 'auto',
      position: 'relative',
      // flex: `0 1 ${theme.constants.searchInputWidth}px`,
      height: 32,
      width: "14vw",
      boxShadow: "0px -1px 0px 0px #B7B8BD26 inset",
      borderRadius: 8,
      backgroundColor: '#B7B8BD1A',
      cursor: 'pointer',
      "&:hover": {
        // backgroundColor: whiteOpacity016,
      },
      '&:focus-within': {
        border: `1px solid ${whiteOpacity03}`,
      }
    },
    searchContainerExpanded: {
      width: "24vw!important",
      minWidth: 317,
    },
    filterIcon: {
      backgroundColor: theme.customColors.secondary.main,
    },
    autoSuggestContainer: { width: '100%', padding: 0, margin: 0, color: 'black', borderRadius: 4, position: 'relative' }
  })
}

class Search extends Component {
  state = {
    searchQueryString: this.props.searchQuery.query,
    isSuggestNavigationMode: false,
    suggestIndex: -1,
    openFilter: false
  };


  _debouncedSearch = debounce(
    (query) => this.onChangeHandler(query),
    400
  );

  setIsFocused = (isFocused) => {
    const { dispatch } = this.props;

    dispatch({
      type: 'SEARCH_INPUT_FOCUSED',
      isFocused
    })
  }

  searchGlobalListener = () => {
    const { dispatch, searchQuery: { searchLeftOffset } } = this.props
    const Element = document.getElementById('search-global-container');
    const offset = window.scrollX + Element.getBoundingClientRect().left

    if (searchLeftOffset === offset) return;
    dispatch({
      type: 'SEARCH_LEFT_OFFSET_CHANGE',
	  // NOTE: changed to  offset * 0.45 because searchInput in header was moved right
      searchLeftOffset: offset * 0.45
    })
  }

  launchpadKeysListener = (e) => {
    const { dispatch } = this.props

    let allow = false;

    if (isShouldLaunchpadOpen(e)) {
      allow = true
      dispatch({ type: 'TOGGLE_LAUNCHPAD' })
    }
    if (isShouldCreateOpen(e)) {
      allow = true
      dispatch({ type: 'TOGGLE_CREATENEW_MODAL' })
    }
    if (isShouldSearchOpen(e)) {
      allow = true
      dispatch({ type: 'TOGGLE_INPUT_FOCUSED' })
    }
    if (isShouldCreateOpen(e)) {
      allow = true
      dispatch({ type: 'TOGGLE_CREATENEW_MODAL' })
    }

    if (!allow) return
    e.preventDefault();

    
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.searchGlobalListener)
    window.removeEventListener("keydown", this.launchpadKeysListener)
  }


  getTheme = () => {
    const { userData: { profileData: { org } } } = this.props
    const themeOption = org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)
    return theme
  }

  componentDidMount() {
    window.addEventListener("resize", this.searchGlobalListener)
    window.addEventListener("keydown", this.launchpadKeysListener)
    this.searchGlobalListener()
  }

  listenSearchNavigationPageChange = (prevQuery) => {
    const { searchQuery: { query } } = this.props
    const { isSuggestNavigationMode } = this.state
    if (query === prevQuery || !isSuggestNavigationMode) return;

    this.resetPrevSuggestStyle()
    this.setState({ isSuggestNavigationMode: false, suggestIndex: -1 })
  }

  listenSearchNavigationFocusChange = (prevFocus) => {
    const { searchQuery: { isFocused } } = this.props
    const { isSuggestNavigationMode } = this.state
    if (isFocused === prevFocus || !isSuggestNavigationMode) return;

    this.resetPrevSuggestStyle()
    this.setState({ isSuggestNavigationMode: false, suggestIndex: -1 })

  }

  listenIsSuggestNavigatioMode = prev => {
    const { isSuggestNavigationMode } = this.state
    if (isSuggestNavigationMode === prev) return
    this.resetAllSuggestStyle()
  }

  syncReduxQueryWithLocal = (prevQuery) => {
    if (prevQuery !== this.props.searchQuery.query && this.props.searchQuery.query !== this.state.searchQueryString) {
      this.setState({ searchQueryString: this.props.searchQuery.query })
    }
  }

  listenCloseAfterChat = (prevPathname, nextPathname) => {
    const wasOpenedChat = nextPathname.includes("chat") && !prevPathname.includes('chat')
    if (!wasOpenedChat) return

    this.setState({ openFilter: false })
  }

  componentDidUpdate(prevProps, prevState) {
    this.listenPathnameChange(prevProps.pathname)
    this.syncReduxQueryWithLocal(prevProps.searchQuery.query)
    this.listenSearchNavigationPageChange(prevProps.searchQuery.query)
    this.listenSearchNavigationFocusChange(prevProps.searchQuery.isFocused)
    this.listenIsSuggestNavigatioMode(prevState.isSuggestNavigationMode)
  }

  listenPathnameChange = (prevPathname) => {
    const { pathname: nextPathname } = this.props;

    this.listenCloseAfterChat(prevPathname, nextPathname)
    if (prevPathname !== nextPathname) setTimeout(this.searchGlobalListener, 100)
  }

  onChangeHandler = (newValue) => {
    const { searchQuery: { query: queryValue }, dispatch } = this.props;

    dispatch({
      type: 'SEARCH_QUERY_INPUT',
      query: newValue
    })

    if (this.state.openFilter && !queryValue.length && newValue.length) {
      this.setState({ openFilter: false })
    }

    const query = {
      page: 0, isChannel: checkIfChannel(this.props.searchQuery),
    }

    if (!window.location.href.includes('group')) {
      query.isCollection = false
      query.collectionUid = null;
      query.groupUid = null;
    }
    dispatch({
      type: 'SEARCH_QUERY', query: query
    })
  };

  onChange = (event) => {
    let newValue = event.target.value;
    // replace hebrew double quotes
    const replacedValue = newValue.replaceAll('״', '"')

    this.setState({ searchQueryString: replacedValue })
    this._debouncedSearch(replacedValue)
  };

  search = () => {
    const { dispatch } = this.props
    const { searchQueryString } = this.state

    dispatch({ type: CLEAN_FILTER })
    dispatch({ type: REMOVE_LOCAL_FILTER })
    dispatch({ type: SEARCH_QUERY_UPDATE, query: { size: 50, query: searchQueryString } })
    dispatch({ type: 'DATA_UPDATE' });
    this.goToSearch([])
  }

  goToSearch = (dataTypes) => {
    const { dispatch, searchQuery, recentRequests, searchQueryNew } = this.props
    const { searchQueryString } = this.state
    this.setIsFocused(false)

    if (this.isKanban()) {
      dispatch(searchQueryActions.setSearchQuery({ ...searchQueryNew, query: searchQueryString, page: 0 }))
      dispatch(queryFiltersActions.setApplyingCommonFilters(true))
      return
    }
    if (!searchQueryString) {
      this.setState({ openFilter: false });
      return
    }

    if (searchQuery.isOrgCalendar || searchQuery.isCollection || searchQuery.isMyPage || searchQuery.isUserPage) return;

    let pathname = '/search'

    if (!checkIfChannel(searchQuery)) {
      const reqs = recentRequests.map(req => req.query)

      reqs.includes(searchQueryString) ?
        dispatch(updateRecentRequestTimestamp(searchQueryString)) :
        dispatch(addRecentRequest(searchQueryString));

      if (dataTypes.length === 0) pathname += '/all'
      else if (dataTypes.length === 1) pathname += `/${dataTypes[0]}`
    }

    if (this.checkIfUserTag()) {
      dispatch(push({ pathname: '/mypage' }))
      return
    }

    this.setState({ openFilter: false });
    dispatch(push({ pathname, search: '?searchquery=' + encodeURIComponent(searchQueryString) }))
  }

  checkIfUserTag = () => {
    const { searchQuery: { query }, userData: { profileData } } = this.props;
    const { tag, firstname, lastname } = profileData;

    return query.includes(tag) || query.includes(firstname.toLowerCase() + lastname.toLowerCase())
  }

  openUser = (tag) => {
    const { dispatch } = this.props
    let userTagFormat = ''
    if (!tag.startsWith('@')) {
      userTagFormat = `@`
    }
    userTagFormat += tag.replace(' ', '').toLowerCase()
    this.setIsFocused(false)
    goTo(userTagFormat, dispatch)()
    dispatch({
      type: 'SEARCH_QUERY', query: { query: userTagFormat }
    })
  }

  openGroup = (group) => {
    const { dispatch, openCollections, userData: { collections } } = this.props
    const collection = getAllCollection(collections, openCollections).find(col => col.collectionUid === group.collectionUid)

    this.setIsFocused(false)
    goToCollection('/' + group.title, dispatch, group.collectionUid, group.groupUid, collection)();
  }
  openChannel = (tag) => {
    const { dispatch } = this.props
    this.setIsFocused(false)
    const title = tag.startsWith('#') ? tag : `#${tag}`
    goTo(title, dispatch)()
  }

  openSuggest = ({ suggest }) => {
    const { dispatch, searchQuery, recentRequests } = this.props
    const { originText } = searchQuery
    const searchText = `${originText ? originText + " " : ''}` + suggest.query
    let pathname = '/search'

    if (!checkIfChannel({ ...searchQuery, query: searchText })) {
      pathname += '/all'
    }
    dispatch({
      type: 'SEARCH_QUERY', query: { query: searchText, isFocused: false }
    })
    dispatch(push({ pathname, search: '?searchquery=' + encodeURIComponent(searchText) }))
    const reqs = recentRequests.map(req => req.query)

    reqs.includes(searchText) ?
      dispatch(updateRecentRequestTimestamp(searchText)) :
      dispatch(addRecentRequest(searchText));
    this.search()
  }


  listener = ({ key }) => {
    const theme = this.getTheme()
    const container = document.getElementById('search-navigation-dialog');
    if (!container) return;

    const { isSuggestNavigationMode, suggestIndex } = this.state;
    if (!isSuggestNavigationMode) this.setState({ isSuggestNavigationMode: true });

    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    if (!suggestionsList.length) return;
    if ((suggestIndex === -1 || suggestIndex === 0) && key === 'ArrowUp') return;
    if (suggestIndex === suggestionsList.length - 1 && key === 'ArrowDown') return;

    const newInd = key === 'ArrowDown' ? suggestIndex + 1 : suggestIndex - 1;
    const selector = suggestionsList[newInd];

    selector && (selector.style.backgroundColor = theme.primary.main);
    selector && (selector.style.display = 'flex');

    this.resetPrevSuggestStyle(container)
    this.setState({ suggestIndex: newInd });
  }

  isSuggestMode = () => {
    const { searchQuery: { isFocused } } = this.props;
    return !!isFocused
  }

  resetPrevSuggestStyle = (container = document.getElementById('search-navigation-dialog')) => {
    if (!container) return;

    const { suggestIndex } = this.state;
    if (suggestIndex === -1) return;

    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    if (!suggestionsList.length) return;

    const prevSelector = suggestionsList[suggestIndex];

    if (!prevSelector) return;
    prevSelector.style.backgroundColor = 'transparent';
    prevSelector.style.display = 'none';
  }

  resetAllSuggestStyle = (container = document.getElementById('search-navigation-dialog')) => {
    if (!container) return;

    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    if (!suggestionsList.length) return;

    suggestionsList.forEach(sel => {
      if (!sel) return;
      sel.style.backgroundColor = 'transparent';
      sel.style.display = 'none';
    })
  }

  resolveUserSuggestType = (data) => {
    this.openUser(data.tag)
    this.setState({ suggestIndex: -1, isSuggestNavigationMode: false })
  }

  resolveGroupSuggestType = (data) => {
    this.openGroup(data.group)
    this.setState({ suggestIndex: -1, isSuggestNavigationMode: false })
  }
  resolveChannelSuggestType = (data) => {
    this.openChannel(data.tag)
    this.setState({ suggestIndex: -1, isSuggestNavigationMode: false })
  }
  resolveSearchSuggestType = (data) => {
    this.openSuggest(data)
    this.setState({ suggestIndex: -1, isSuggestNavigationMode: false })
  }

  resolveSuggestType = (data) => {
    const { suggestType } = data;
    switch (suggestType) {
      case 'user':
        return this.resolveUserSuggestType(data)
      case 'group':
        return this.resolveGroupSuggestType(data)
      case 'channel':
        return this.resolveChannelSuggestType(data)
      case 'suggest':
        return this.resolveSearchSuggestType(data)
      default:
        return;
    }
  }

  goToSelectedSuggest = () => {
    const container = document.getElementById('search-navigation-dialog');
    if (!container) return;

    const { suggestIndex } = this.state;
    const suggestionsList = container.querySelectorAll('div[suggest="suggest-find"]');
    const selector = suggestionsList[suggestIndex];

    const suggestCaseStr = selector.getAttribute('suggestCase');
    const data = JSON.parse(suggestCaseStr);

    this.resolveSuggestType(data)
  }

  onKeyDown = (event) => {
    const { searchQuery, dispatch } = this.props;
    const { isSuggestNavigationMode } = this.state;

    if (this.isSuggestMode() && (event.key === 'ArrowDown') || event.key === 'ArrowUp') {
      this.listener(event)
      return
    }

    if (event.key !== 'Enter') return;

    if (this.isActionCenter()) {

      return
    }

    if (this.isGantt()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerGanttGlobalHeader.updateGlobalFiltersPartial({ query: x })
      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (this.isGroupScheduler()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerSchGlobalHeader.updateGlobalFiltersPartial({ query: x })

      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (this.isOrgScheduler()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerSchGlobalHeader.updateGlobalFiltersPartial({ query: x })
      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (this.isNewCalendarGroup()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerCalGlobalHeader.updateGlobalFiltersPartial({ query: x })
      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (this.isNewCalendarUser()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerCalGlobalHeader.updateGlobalFiltersPartial({ query: x })
      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (this.isGroupTable()) {
      const { searchQueryString } = this.state
      const x = searchQueryString?.trim?.() ?? ''
      managerTableRef.searchOnQuery(x)
      this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { query: x, isFocused: false } })
      this.setState({ openFilter: false })
      return
    }
    if (isSuggestNavigationMode) {
      this.goToSelectedSuggest()
      return
    }
    const { query, isMyPage, isOrgCalendar } = searchQuery;
    const { searchQueryString } = this.state
    const x = searchQueryString?.trim?.() ?? ''

    if (x === '' && !checkIfCollectionOrGroup({ ...searchQuery, query: x }) && !isMyPage && !isOrgCalendar) {
      dispatch(push('/feed'));
      dispatch({
        type: 'SEARCH_REPORT_NAME',
        reportName: ''
      })
      dispatch({
        type: 'RESULT_FILTERS_UPDATE', results: {
          dataTypes: [],
          relatedDocs: [],
		  groups: [],
		  experts: [],
        }
      });
      this.setIsFocused(false)
      this.setState({ openFilter: false });
      return;
    }

    this.search()
  }

  toggleOpenFilter = () => {
    this.setState({ openFilter: !this.state.openFilter })
  }

  showAdvancedFilter = () => {
    const { searchQuery } = this.props;
    const { query = '' } = searchQuery

    if (this.isActionCenter()) return false
    if (this.isGroupScheduler()) return false
    if (this.isNewCalendar()) return false
    if (this.isOrgScheduler()) return false
    if (this.isGroupTable()) return false
    if (isGanttNew) {
      if (!!this.isGantt()) return false
    }

    // if query exists or the path is favourites and then there are no query  
    return query.length || searchQuery.isMyPage || searchQuery.isOrgCalendar || checkIfCollectionOrGroup(searchQuery)
  }

  renderSearchNavigation() {
    const { searchQuery } = this.props;
    const { query } = searchQuery;

    return (
      <SearchNavigation query={query} />
    );
  }

  renderLaunchpadNavigation() {
    return <LaunchpadNavigation />
  }

  renderUserNavigation() {
    return <UserNavigationModal />
  }

  renderChatsNavigation() {
    return <ChatsModal />
  }

  renderCreateNewModal() {
    return <CreateNewModal />
  }

  renderNotificationsNavigation() {
    return <NotificationsModal />
  }


  isKanban = () => {
    const { pathname } = this.props
    return !!pathname.includes("group/kanban")
  }

  isGantt = () => {
    const { pathname } = this.props
    return !!pathname.includes("group/gantt")
  }

  isGroupTable = () => {
    const { pathname } = this.props
    return (!!pathname.includes("group/table") && useNewTable(this.props.orgUid))
  }
  isGroupScheduler = () => {
    const { pathname } = this.props
    return (!!pathname.includes("group/scheduler") && useNewTable(this.props.orgUid))
  }
  isNewCalendar = () => {
    const { pathname } = this.props
    return shouldUseScheduler(this.props.orgUid) && (!!pathname.includes("group/calendar") || !!pathname.includes("mypage/calendar") || !!pathname.includes("user/calendar"))
  }
  isNewCalendarGroup = () => {
    const { pathname } = this.props
    return this.isNewCalendar() && pathname.includes("group/calendar")
  }
  isNewCalendarUser = () => {
    const { pathname } = this.props
    return this.isNewCalendar() && (!!pathname.includes("mypage/calendar") || !!pathname.includes("user/calendar"))
  }
  isOrgScheduler = () => {
    const { pathname } = this.props
    return (!!pathname.includes("orgscheduler") && useNewTable(this.props.orgUid))
  }

  isActionCenter = () => {
    const { pathname } = this.props
    return (!!pathname.includes("mypage/actioncenter") && useNewActionCenter(this.props.orgUid))
  }


  isFilterApplied = () => {
    const { searchQuery, mappingFilters, searchQueryNew } = this.props;

    if (this.isKanban()) {
      return isSomeMappingFilterPresented(mappingFilters) || isCommonFilterPresented(searchQueryNew)
    }

    return searchQuery.dataTypes.length || searchQuery.colors.length ||
      searchQuery.groups.length ||
      searchQuery.labels.length ||
      searchQuery.tags.length ||
      searchQuery.users.length ||
      searchQuery.groups.length ||
      searchQuery.dateTo ||
      searchQuery.dateFrom ||
      searchQuery.isMyItem ||
      searchQuery.mustread ||
      searchQuery.confirmed ||
      searchQuery.relatedDocIds.length ||
      searchQuery.laneStatusTitle.length
  }


  getAdvancedFilterIcon = () => {
    const { classes } = this.props;

    if (this.isFilterApplied()) {
      return <Badge color="secondary" classes={{ badge: classes.filterIcon }} style={{ position: 'absolute', right: 12, top: 6, color: '#ffffff' }} variant="dot">
        <img onClick={this.toggleOpenFilter} alt="filter" src={"/resources/filter.svg"} />
      </Badge>
    }
    return <img onClick={this.toggleOpenFilter} alt="filter" src={"/resources/filter.svg"} style={{ position: 'absolute', right: 12, top: 6, color: '#ffffff' }} />
  }

  onRemoveMyChip = () => {
    this.props.dispatch({
      type: 'SEARCH_QUERY_REMOVE_MY_PAGE',
    })
  }

  onRemoveGroupChip = () => {
    this.props.dispatch({
      type: 'SEARCH_QUERY_REMOVE_GROUP'
    })
  }

  useExpandedSearch = () => {
    return !!this?.props?.searchQuery?.isCollection
  }

  setInputProps = () => {
    const { t, searchQuery } = this.props;
    const { searchQueryString } = this.state;
    const { isFocused } = searchQuery;

    const inputProps = {
      // value: query,
      value: searchQueryString,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      placeholder: `${t('Search')}`,
      isFocused
    };

    const label = searchQuery.collectionTitle ?
      searchQuery.collectionTitle.replace('/', '') :
      searchQuery.query.replace('/', '');

    if (searchQuery.isCollection) {
      inputProps.group = {
        label,
        collectionUid: searchQuery.collectionUid,
        groupUid: searchQuery.groupUid
      }
      inputProps.onRemoveChip = this.onRemoveGroupChip
    }

    if (searchQuery.isMyPage) {
      inputProps.isMyPage = true;
      inputProps.onRemoveChip = this.onRemoveMyChip
    }
    return inputProps;
  }

  render() {
    const { classes, profileData } = this.props;
    const inputProps = this.setInputProps()


    const themeOption = profileData.org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)

    return (
      <div data-intrcm-id="search_lju3mrb0" id="search-global-container" className={`${classes.searchContainer} ${this.useExpandedSearch() ? classes.searchContainerExpanded : ''}`} >
        <div data-intrcm-id="search_bks763ff" className={classes.autoSuggestContainer}>
          <SearchInput {...inputProps} showFilters={this.showAdvancedFilter()} useExpandedSearch={this.useExpandedSearch()} setIsFocused={this.setIsFocused} onSearch={this.search} theme={theme} />
          {this.showAdvancedFilter() && this.getAdvancedFilterIcon()}
          {this.renderSearchNavigation()}
          {this.renderLaunchpadNavigation()}
          {this.renderUserNavigation()}
          {this.renderChatsNavigation()}
          {this.renderNotificationsNavigation()}
          {this.renderCreateNewModal()}
        </div>
        {this.state.openFilter && this.showAdvancedFilter() ? <FilterModal goToSearch={this.goToSearch} onClose={this.toggleOpenFilter} /> : null}
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  orgUid: state.userData?.profileData?.org?.orgUid ?? '',
  pathname: state.wrapper.pathname,
  openCollections: state.globalData.openCollections,
  userData: state.userData,
  searchQuery: state.search,
  searchQueryNew: state.searchQuery,
  mappingFilters: state.queryFilters.mappingFilters,
  recentRequests: state.searchNavigation.recentRequests,
  profileData: state.userData.profileData,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
  withTranslation('search'),
  withErrorCatcher(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(Search);