import { Divider, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clearDrawerNavigation, selectDrawerNavigationOption, setDrawerNavigationMode } from '../../../../actions/drawerNavigationActions';
import { getSearchDrawerFromPath } from '../../../../utils/drawerNavigation/searchDrawerNavigationHelpers';
import DrawerContainer from '../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer';
import { useDrawerNavigation } from '../../../components/Common/Hooks/useDrawerNavigation/useDrawerNavigation';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { SearchInfoDrawer } from '../../../components/SearchInfo/SearchInfoDrawer';
import {DrawerSwitcherNew} from './DrawerSwitcher';
import {themeConstants} from '../../../../theme';

const styles = theme => ({
    container: {
        paddingTop: 24,
    },
    divider: {
        backgroundColor: '#daddee',
        margin: 16,
        marginBottom: 0,
        marginTop: 24,
    },
    navigation: {
        margin: 16,
    },
    navigationSmall: {
        marginTop: 16,
    },
    navigationTitle: {
        marginBottom: 12,
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.commonBlack,
        display: 'block'
    },
    drawerWrapper: {
        position: 'relative',
    },
    paper: {
        height: 'inherit!important',
        overflowY: 'auto',
        width: themeConstants.navigationDrawerWidth,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    }
})

const __SearchLeftDrawerNew = (props) => {
    const {classes, dispatch, drawerNavigation, query, paramsQuery, profileData} = props
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)
    const [t] = useTranslation('searchPage')

    useEffect(() => {
        const {pathname} = window.location
        const [mode, id] = getSearchDrawerFromPath(pathname)

        dispatch(setDrawerNavigationMode(mode))
        dispatch(selectDrawerNavigationOption(id))

        return () => {
            dispatch(clearDrawerNavigation())
        }
    }, [])

    const paperStyle = isDrawerOpen ? {} : { width: themeConstants.navigationDrawerSmallWidth, overflowX: 'hidden' }

    const handleSwitchDrawerStatus = (status) => {
        setIsDrawerOpen(status)
    }

    const [Navigation] = useDrawerNavigation({drawerNavigation, paramsQuery, isDrawerOpen, t,profileData})


    const NavigationSection = (
        <div data-intrcm-id="SearchLeftDrawerNew_v9qu35n8" className={isDrawerOpen ? classes.navigation : classes.navigationSmall}>
            {Navigation}
        </div>
    )
    return (
        <div data-intrcm-id="SearchLeftDrawerNew_9zdlglks" className={classes.drawerWrapper}>
            <DrawerContainer containerStyle={`${classes.container} ${classes.paper}`}  PaperProps={{style: paperStyle}} open={isDrawerOpen}>
                <DrawerSwitcherNew isDrawerOpen={isDrawerOpen} onClick={handleSwitchDrawerStatus}/>
                <SearchInfoDrawer query={query} isDrawerOpen={isDrawerOpen}/>
                <Divider className={classes.divider} />
                {NavigationSection}
            </DrawerContainer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    drawerNavigation: state.drawerNavigation,
    profileData: state.userData.profileData,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const SearchLeftDrawerNew = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading Search Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__SearchLeftDrawerNew);