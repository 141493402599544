import { Paper, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { managerAdminDrawer } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { compose } from 'recompose';
import { updateDefaultInputTemplate } from '../../../../../actions/globalDataAction';
import { getDataType } from '../../../../components/Common/Utils/defaultDataTypes';
import PageHeader from '../../Common/PageHeader.js';
import { OldPagesWrap } from '../../newway/OldPagesWrap';
import FormControlLabelSwitch from "../components/FormControlLabelSwitch";

const styles = theme => ({
	container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
	paper: {
		padding: 30
	},
	divid: {
		marginTop: 20,
		marginBottom: 20,
		backgroundColor: 'rgba(0, 0, 0, 0.1)'
	},
})

class EditDefaultInputTemplate extends React.Component {

	constructor(props) {
		super(props)
		const currentMapSettings = this.getCurrentMapSettings()

		this.state = {
			enablePublishingToPublicFeed: currentMapSettings.enablePublishingToPublicFeed
		}
	}

	onToggleEnablePublishingChange = () => {

		this.setState({
			enablePublishingToPublicFeed: !this.state.enablePublishingToPublicFeed
		})
	}

	getCurrentMapSettings = () => {
		const { defaultInputTemplates, params } = this.props;

		return this.props.defaultInputTemplates.find(defaultInputTemplate => defaultInputTemplate.dataType === params.dataType);
	}

	componentDidMount() {
		managerAdminDrawer.setOption({
			'prop': 'input-settings',
			uid: 'default-item'
		})
	}

	saveMapping = () => {
		const { defaultInputService, dispatch } = this.props;
		const currentMapSettings = this.getCurrentMapSettings()

		const newData = {
			...currentMapSettings,
			enablePublishingToPublicFeed: this.state.enablePublishingToPublicFeed
		}

		defaultInputService.updateTemplate(newData)
			.then((updatedTemplate) => {
				dispatch(goBack())
				dispatch(updateDefaultInputTemplate(updatedTemplate))
			})
	}

	render() {
		const { classes, params } = this.props;

		return (<OldPagesWrap className={classes.container} classNameContent={classes.content} >
					<Paper className={classes.paper} elevation={1}>
						<PageHeader
							title={`Edit ${(getDataType(params.dataType) || {}).title}`}
							btnSaveText='Save changes'
							onSave={this.saveMapping}
						/>
						<Divider className={classes.divid} />
						<FormControlLabelSwitch
							checked={this.state.enablePublishingToPublicFeed}
							onChange={this.onToggleEnablePublishingChange}
							label="Enable Publishing To Public Feed" />
					</Paper>
		</OldPagesWrap>
		);
	}
}

const mapStateToProps = (state) => ({
	defaultInputService: state.services.defaultInputService,
	defaultInputTemplates: state.globalData.defaultInputTemplates,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(EditDefaultInputTemplate);