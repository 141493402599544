import { CircularProgress, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { loadGroupInfo } from './groupUtils.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../actions/upgradeSubscriptionActions.js';
import GanttBryntum from './GanttBryntum/GanttBryntum.js';
import { finishedUpdatingSpecificItem } from '../../../actions/dataActions.js';
import { themeConstants } from '../../../theme.js';
import { DateHelper } from '@bryntum/gantt';
import { CheckEditDate } from './GanttBryntum/utils/checkChangeDate.js';
import { GanttGroup, NewUiWrapper, managerGanttRef, managerGroupGantt, managerMoveGroupOfTask } from '@tuqqi/web';
import { store } from '../../../index.js';
import { MockForSetTableGlobalData } from './tableNew/MockForSetTableGlobalData.js';

const styles = theme => ({
    container: { 
        backgroundColor: '#EDEDF0',
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
    },
    containerWithDrawer: {
        backgroundColor: '#EDEDF0',
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
    },
});
class GroupGanttViewNew extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isInitialLoading: true,
            dataReloadInidicator: 0,
            tasksFromKanban: [],
        }
        this.loadGroupInfo = loadGroupInfo.bind(this)
    }

    onLoading = () => {
        this.setState({isInitialLoading: true, tasksFromKanban: [],})
    }

    onInitialLoaded = (items, groupInfoLoaded = undefined, oldDataUpdated = undefined) => {
        this.setState({isInitialLoading: false,  tasksFromKanban: items, groupInfoLoaded: groupInfoLoaded ?? prev.groupInfoLoaded})
        if(oldDataUpdated) this.props.dispatch({ type: 'DATA_UPDATED' });
    }


    emptyReq = {
        query: '',
        isUnseen: false,
        tags: [],
        users: [],
        dataTypes: [],
        labels: [],
        colors: [],
        groups: [],
        dateFrom: null,
        dateTo: null,
        isAuthor: false,
        page: 0,
        isMyItem: false,
        isTrending: false,
        hasLocation: false,
        sortField: '',
        sortOrder: null,
        sortType: null,
        confirmed: false,
        mustread: false,
        mustReadMyRequests: false,
        isCollection: true
      }

      getCollectionUid = () => {
        const { searchQuery, urlQuery, userCollections, openCollections} = this.props

        if(searchQuery?.collectionUid) return searchQuery.collectionUid
        const query = urlQuery?.slice?.(1);
        const collection = userCollections.concat(openCollections).find(coll => coll.title === query || coll.collectionUid === query)
        if(!collection?.collectionUid) return ''
        return collection.collectionUid
      }

      geGroupUid = () => {
        const { searchQuery, urlQuery, userCollections, openCollections} = this.props

        if(searchQuery?.groupUid) return searchQuery.groupUid
        const query = urlQuery?.slice?.(1);
        const collection = userCollections.concat(openCollections).find(coll => coll.title === query || coll.collectionUid === query)
        if(!collection?.collectionGroup?.groupUid) return ''
        return collection.collectionGroup.groupUid
      }

    componentDidMount() {
        if(!this.canUseGantt()) {
            this.openSubscriptionDialog()
            return
        }
        managerGroupGantt.initGantt(this.onInitialLoaded, this.onLoading)
        managerMoveGroupOfTask.mountGantt(this.onInitial)
        const groupUid = this.geGroupUid()
        const colUid = this.getCollectionUid()

        managerGroupGantt.preFetch(groupUid, colUid)
        managerGroupGantt.fetch(groupUid, colUid)

        this.registerReload()
    }

    componentWillUnmount() {
        managerMoveGroupOfTask.unmountGantt()
    }

    registerReload = () => {
        CheckEditDate.preRegisteredReload = () => {
            this.setState({  isInitialLoading: true })
        }
        CheckEditDate.registeredReload = () => {
            this.onInitial()
        }
    }

    onInitial = () => {
        const { searchQuery,collaborationService, urlQuery, userCollections, openCollections} = this.props
        const _searchQuery = { ...searchQuery, ...this.emptyReq }
        this.loadGroupInfo()
        managerGroupGantt.forOldRunner()
    }

    allowLoad = () => !this.state.isInitialLoading

    // listenCloseFullview = (prevProps) => {
    //     const {fullview: {open}} = this.props
    //     const {fullview: {open: prevOpen, mainDocId}} = prevProps
    //     if(open === prevOpen || (open && !prevOpen)) return {updateRequired: false, docId: ''}

    //     return {updateRequired: true, docId: mainDocId}
    // }

    // updateSpecificItemInState(props, docId) {
    //     const { dispatch, documents } = props;
    //     const { tasksFromKanban } = this.state;

    //     const index = tasksFromKanban.findIndex(item => item.docId === docId)
    //     const item = documents?.[docId]?.document
    //     if (index > -1 && !!item && !!Object.keys(item).length) {
    //         tasksFromKanban[index] = item
    //         const {fullview: {open}} = this.props
    //         if(!open) {
    //             const updateGanttItem = managerGanttRef.gantt?.instance?.project?.eventStore?.getById?.(docId)

    //             if(updateGanttItem){
    //                 const today = new Date()
    //                 const tomorrow = new Date(today)
    //                 tomorrow.setDate(tomorrow.getDate() + 1)
    //                 updateGanttItem.name = item.title
    //                 updateGanttItem.startDate = DateHelper.parse(item.dateTimeFrom === "0001-01-01T00:00:00Z" ?  new Date().toISOString()  : item.dateTimeFrom)
    //                 // updateGanttItem.constraintDate = DateHelper.parse(item.dateTimeFrom === "0001-01-01T00:00:00Z" ?  new Date().toISOString()  : item.dateTimeFrom)
    //                 updateGanttItem.endDate  = DateHelper.parse(item.dateTimeTo === "0001-01-01T00:00:00Z" ?  tomorrow.toISOString() : item.dateTimeTo)
             
    //             }
    //         }
    //         dispatch(finishedUpdatingSpecificItem(docId));
    //     } else {
    //         this.setState({ isLoadingGroupAndData: false })
    //         dispatch(finishedUpdatingSpecificItem(docId));
    //     }
    // }

    componentDidUpdate(prevProps) {
        // const { oldUiData } = this.props
        // const closedUpdate = this.listenCloseFullview(prevProps)
        // this.checkLocalRemove(prevProps)

        // if(this.state.isInitialLoading) return
        // if (closedUpdate.updateRequired || (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate)) {
        //     this.updateSpecificItemInState(this.props, closedUpdate.updateRequired ? closedUpdate.docId : oldUiData.itemId)
        // }
    }

    // checkLocalRemove = (prevProps) => {
    //     if(this.props.queryFilters.updateType === 'delete' && prevProps.queryFilters.updateType !== 'delete' &&
    //         !!this.props.queryFilters.itemId) {
    //             managerGanttRef.gantt?.instance?.project?.eventStore?.remove?.(this.props.queryFilters.itemId)
 
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if(nextProps.searchQuery.groupUid !== this.props.searchQuery.groupUid && nextProps.searchQuery?.groupUid) {
            const { searchQuery, collaborationService } = nextProps
            const _searchQuery = { ...searchQuery, ...this.emptyReq }


            this.allowLoad() && managerGroupGantt.forOldRunner()
            return
        }

        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadGroupInfo(nextProps)
            const { searchQuery, collaborationService } = this.props
            const _searchQuery = { ...searchQuery, ...this.emptyReq }
            this.setState({ dataReloadInidicator: nextProps.oldUiData.dataUpdate })
            if(searchQuery.groupUid) {
                this.allowLoad() &&  managerGroupGantt.forOldRunner(true)
            }
            return
        }
    }

     openSubscriptionDialog = () => {
        const { dispatch} = this.props
        dispatch(initSubscriptionModal({reason: 'gantt', mode: 'existed', allowClose: false}))
        dispatch(openSubscriptionModal())
    } 

    canUseGantt = () => {
        const { subscription} = this.props
        if([0, 1, 2, 6].includes(subscription.subscriptionType)) return false
        return true
    }

    renderLoader = () => {
        return <div data-intrcm-id="groupGanttViewNew_oxy2lsdg" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh'}}>
            <CircularProgress />
        </div>
    }

    renderGanttActual = () => {
        const { cloudfront, collectionInfo, groupService, queryService } = this.props;
        const { tasksFromKanban, isInitialLoading, groupInfoLoaded } = this.state

        return isInitialLoading || !groupInfoLoaded ? null : <GanttBryntum 
            Loader={this.renderLoader()}
            groupService={groupService}
            queryService={queryService}
            cloudfront={cloudfront} 
            collectionInfo={collectionInfo} 
            tasksFromKanban={tasksFromKanban} />
    }

    render() {
        const { classes, isDrawerOpen } = this.props;
        const { isInitialLoading, groupInfoLoaded } = this.state


        return (
            <NewUiWrapper store={store} callbacks={{}}>
            <div data-intrcm-id="groupGanttViewNew_xdl1tbf7" className={ isDrawerOpen ? classes.containerWithDrawer : classes.container}>
                <MockForSetTableGlobalData />
                <GanttGroup drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth : themeConstants.navigationDrawerSmallPXWidth} isLoading={isInitialLoading || !groupInfoLoaded} renderChildren={this.renderGanttActual} />
            </div>
            </NewUiWrapper>
        )
    }
}



const mapStateToProps = (state, ownProps) => ({
    ...state.services,
    searchQuery: state.search,
    queryFilters: state.queryFilters,
    subscription: state.globalData.currentSubscription,
    collectionInfo: state.results.channel,
    documents: state.data.documents.data,
    oldUiData: state.oldUiData,
    fullview: state.fullview,
    cloudfront: state.globalData.cloudfront,
    urlQuery: ownProps.location.query.searchquery,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupGanttViewNew);

