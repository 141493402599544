export const getDefaultLaunchpadOptions = (t, settings = undefined) => {

    const getActionCenter = () => {
        return {
            customIcon: 'to_do',
            customTitle: t('Action center'),
            referenceUid: 'default:actioncenter',
            id: 0,
        }
    }

    let x = [
    {
        customIcon: 'news',
        customTitle: t('Feed'),
        referenceUid: 'default:feed',
        id: 0,
    },
    {
        customIcon: 'customer',
        customTitle: t('My Page'),
        referenceUid: 'default:mypage',
        id: 1,
    },
    // {
    //     customIcon: 'report_file',
    //     customTitle: t('Reports'),
    //     referenceUid: 'default:mypage_tasks',
    //     id: 1,
    // },
    // {
    //     customIcon: 'favorite_folder',
    //     customTitle: t('Favorites'),
    //     referenceUid: 'default:favorites',
    //     id: 1,
    // },
    {
        customIcon: 'library',
        customTitle: t('Library'),
        referenceUid: 'default:library',
        id: 2,
    },
    {
        customIcon: 'orgscheduler',
        customTitle: t('Org Scheduler'),
        referenceUid: 'default:orgscheduler',
        id: 5,
    },
    {
        customIcon: 'stacked_organizational_chart',
        customTitle: t('Org Chart'),
        referenceUid: 'default:orgchart',
        id: 3,
    },
    {
        customIcon: 'brainstorm',
        customTitle: t('Groups'),
        referenceUid: 'default:groups',
        id: 4,
    },
    ]

    if(!settings?.withScheduler) x = x.filter(y => y.referenceUid !== 'default:orgscheduler')

    if(settings?.withActionCenter) {
        const res = [...x.slice(0, 1), getActionCenter(), ...x.slice(1)]
        return res
    }

    return x
}