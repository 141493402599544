import { fetchDocuments } from "@tuqqi/common";
import { setRelatedDocIds } from "../../../actions/appActions";
import { finishedUpdatingSpecificItem } from "../../../actions/dataActions";
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from "../../../actionTypes/actionTypes";
import { SERVER_API } from "../../../utils/serverAPI";
import { dispatchDataToNewUIStore } from "../../components/Common/Utils/newUIMigrationUtil";
import { managerRemovedItemsLocal } from "@tuqqi/web";

export let checkIfCollectionOrGroup = function (searchQuery) {

    if (searchQuery.isCollection) {
        return true
    }

    const query = searchQuery.query || '';


    return query.trim().startsWith('/');
}

export let getTagInfo = function (props, prevUrlQuery) {
    props.queryService.getTagInfo(props.searchQuery.query ? props.searchQuery.query : prevUrlQuery).then((result) => {
        this.setState({
            tagInfo: result
        });
        props.dispatch({
            type: 'RESULT_CHANNEL_UPDATE', results: {
                channel: result,
            }
        })
    })
}

// if exist filter of data-types in searchQuery 
// the result.aggregations.dataTypes is not on all of the data
// so sent  anothe query without filter of data-types
export let loadAggData = function (searchQuery, dispatch, queryService) {
    let _searchQuery = { ...searchQuery, page: 0, size: 1, dataTypes: [], relatedDocs: [] }

    return queryService.Search(_searchQuery).then(result => {
        const dataTypes = (result.aggregations.dataTypes && result.aggregations.dataTypes.Items) || []
        const relatedDocs = (result.aggregations.related && result.aggregations.related.Items) || []
		const groups = (result.aggregations.related && result.aggregations.groups.Items) || []
		const experts = (result.aggregations.experts && result.aggregations.experts.Items) || []

        const relateDocsdIds = relatedDocs.map(rel => rel.Key)

		dispatch(fetchDocuments(SERVER_API)(relateDocsdIds))

        dispatch({
            type: 'RESULT_FILTERS_UPDATE', results: { dataTypes,relatedDocs,groups, experts }
        });
    })

}

export let loadData = function (dataReloadInidicator, props, callbackFilterData, returnFullData = false, withFullLength = false) {
    const { searchQuery, dispatch, queryService } = props;

    if (searchQuery.query !== '' || checkIfCollectionOrGroup(searchQuery) || searchQuery.isMyPage) {
        this.setState({ isLoading: true, dataReloadInidicator: dataReloadInidicator })
        var reqs = null;

        // Set the page = 0
        let _searchQuery = { ...searchQuery, page: 0 }
        if(withFullLength) {
            _searchQuery.size = this.state?.firstAllResultsLength?.value ?? _searchQuery.size
        }

        const dateSchedule = {}
        if(!window.location.pathname.includes('calendar')) {
            dateSchedule.dateScheduleFrom = null
            dateSchedule.dateScheduleTo = null
            _searchQuery = { ..._searchQuery, ...dateSchedule }
        }

        dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { page: 0, ...dateSchedule } });

        return queryService.Search(_searchQuery, null, returnFullData)
            .then((result) => {
                let hasMore = true
                if (result.count <= (_searchQuery.page + 1) * _searchQuery.size) {
                    hasMore = false;
                }
                const data = callbackFilterData ? callbackFilterData(result.data) : result.data
	
                //TODO: test it
                const exractAggregationFromResult = (propertyName) => (result.aggregations[propertyName] && result.aggregations[propertyName].Items) || []
                const relatedDocs = exractAggregationFromResult('related')
				const groups = exractAggregationFromResult('groups')
                const dataTypes = exractAggregationFromResult('dataTypes')
				const experts = exractAggregationFromResult('experts')

                if(this?.state?.firstAllResultsLength && !this.state.firstAllResultsLength.init) {
                    this.setState({firstAllResultsLength: {
                            value: result.count,
                            init: true,
                        }
                    })
                }
                this.setState({
                    results: data?.filter?.(item => !managerRemovedItemsLocal.getItems().includes(item?.Value?.Source?.docId ?? '')) ?? [],
                    searchResultCount: result.count,
                    isLoading: false,
                    dataTypes,
                    experts,
                    relatedTags: exractAggregationFromResult('hashtag'),
					groups: groups,
                    hasMore,
                    relatedDocs
                });
             
                dispatchDataToNewUIStore(data, dispatch)
                dispatch({ type: 'DATA_UPDATED' });

                const correctDataTypes = (checkIfCollectionOrGroup(searchQuery) && this?.calcDataTypes) ? this.calcDataTypes(result.count) : [{
                    Key: 'All',
                    DocCount: result.count
                }, ...dataTypes]
                

                // if exist filter of data-types in _searchQuery 
                // the result.aggregations.dataTypes is not on all of the data
                // so don't add it to the state
                
                if (!withAggDataTypes(_searchQuery)) {
                    // setup related docs aggregations
                    const relateDocsdIds = relatedDocs.map(rel => rel.Key)

					dispatch(fetchDocuments(SERVER_API)(relateDocsdIds))

                    dispatch({
                        type: 'RESULT_FILTERS_UPDATE', results: {
                            dataTypes: correctDataTypes,
                            relatedDocs,
							groups,
							experts
                        }
                        
                    });
                }
            })
    }
}

export function withAggDataTypes(searchQuery) {

    let withAgg
    if (searchQuery.dataTypes.length == 0) {
        withAgg = false
    } else if (checkIfChannel(searchQuery) || checkIfCollectionOrGroup(searchQuery)) {
        withAgg = false
    } else {
        withAgg = true
    }

    return withAgg
}

export let filterData = function (dataReloadInidicator, props, returnFullData = false, withFullLength = false) {
    const emptyResult = {
        count: 0,
        data: {},
        took: {},
        aggregations: {
            dataTypes: {
                Items: []
            },
            experts: {
                Items: []
            },
            hashtag: {
                Items: []
            },
            relatedDocs: {
                Items: {},
            },
			groups: {
				Items: [],
			}
        }
    }

    const { searchQuery, dispatch, queryService, userData } = props;

    if (searchQuery.query !== '' || checkIfCollectionOrGroup(searchQuery) || searchQuery.isMyPage) {
        if(withFullLength) {
            searchQuery.size = this.state?.firstAllResultsLength?.value ?? searchQuery.size
        }
        dispatch({ type: 'DATA_BEING_FILTERED' })
        return queryService.Search(searchQuery, null, returnFullData)
            .then((result) => {
                var hasMore = result.count > 10
                this.setState({
                    dataReloadInidicator: dataReloadInidicator,
                    results: result.data?.filter?.(item => !managerRemovedItemsLocal.getItems().includes(item?.Value?.Source?.docId ?? '')) ?? [],
                    searchResultCount: result.count,
                    searchResultTime: result.took,
                    isLoading: false,
                    isRefreshing: false,
                    hasMore,
                });
                dispatchDataToNewUIStore(result.data, dispatch)

                dispatch({ type: 'DATA_FILTERED' });
                dispatch({ type: 'DATA_UPDATED' });
            })
    }
}

export const checkIfChannel = function (searchQuery) {
    const { query = '' } = searchQuery;
    var words = query.trim().split(" ");

    if (words.length === 1) {
        // check if it's a tag
        if (words[0][0] === '@' || words[0][0] === '#') {
            return true;
        }
    }

    return false;
}





export const IsQueryExistInCollections = function (searchQuery, collections) {
    const query = searchQuery.query.slice(1)
    const collection = collections.find(coll => coll.title == query || coll.collectionUid == query)

    if (collection) {
        searchQuery.collectionUid = query;
        searchQuery.query = `/${collection.title}`;
    }

    return collection
}

export const IsQueryExistInGroups = function (searchQuery, groups) {
    const query = searchQuery.groupUid || searchQuery.query.slice(1)
    const group = groups.find(g => g.groupUid == query || g.title == query)

    if (group) {
        searchQuery.groupUid = query;
        searchQuery.query = `/${group.title}`;
    }

    return group
}

export const checkIfQuestion = function () {
    const { query } = this.props.searchQuery;

    return query.includes('?');
}

export const loadMore = function (props, callbackFilterData, returnFullData = false) {

    const { queryService, searchQuery, dispatch } = props || this.props

    return queryService
        .Search(searchQuery, null, returnFullData)
        .then((result) => {
            var hasMore = this.state.hasMore;
            if (result.count < (searchQuery.page + 1) * searchQuery.size) {
                hasMore = false;
            }
            const oldRes = [...this.state.results]
            const newRes = result.data.filter(newDoc => oldRes.every(oldDoc => oldDoc.Key != newDoc.Key))

            const newData = callbackFilterData ? callbackFilterData(newRes) : newRes

            const results = [...oldRes, ...newData]
            dispatchDataToNewUIStore(results, dispatch)

            this.setState({ hasMore: hasMore, searchResultCount: result.count, results, isLoadingMore: false, isLoading: false })
            this.props.dispatch({ type: 'DATA_UPDATED' })
        })
}
export let loadMoreQuery = function (page) {
    const { searchQuery, dispatch } = this.props;

    if (searchQuery.query !== '' || checkIfCollectionOrGroup(searchQuery) || searchQuery.isMyPage) {
        // if (this.state.isLoading) {

        if (!this.state.isLoadingMore) {
            this.setState({ isLoadingMore: true });
            dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { page: searchQuery.page + 1 } });
            dispatch({ type: 'DATA_LOADMORE' })
        }
        // }
    }
}


export let updateSpecificItemInState = function (itemId) {
    const { dispatch, queryService } = this.props;

    const query = {
        itemsIds: [itemId],
        page: 0,
        filter: {},
        tags: [],
        size: 1,
        AggType: 'FLAT',
    }

    queryService.SearchByIds(query).then((results) => {
        let _oldResults = [...this.state.results];

        // Getting the index of the item in state in order to replace him with the new one
        let itemIndex = _oldResults.findIndex(item => item.Key === itemId)
        if (results && results.data[0] && results.data[0].Value && results.data[0].Value.Source && _oldResults[itemIndex] ) {
            if(_oldResults[itemIndex]?.Value?.Source) {
                _oldResults[itemIndex].Value.Source = results.data[0].Value.Source; 
            }
            
  
            let _newResults = _oldResults

            //TODO: This is wrong, needs to be checked
            // this.setState({ results: [] });
            this.setState({ results: _newResults.filter(item => !managerRemovedItemsLocal.getItems().includes(item?.Value?.Source?.docId ?? '')) });
            dispatch(finishedUpdatingSpecificItem(itemId));
        }

    })
}

export const applySearchQuery = (newSearchQuery, dispatch, ) => {

    dispatch({ type: SEARCH_QUERY_UPDATE, query: newSearchQuery })
    dispatch({ type: DATA_UPDATE });
}
