import React, { PureComponent, Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import {createCustomTheme} from '../../../utils/theming';

class ChatHeaderMenu extends PureComponent {

    state = {
        anchorEl: null,

    }

    handleClick = (event) => {

        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }



    render() {
        const { anchorEl } = this.state
        const { t, openItem, isGM } = this.props
        const open = Boolean(anchorEl);
        const colors = createCustomTheme(this.props.userData.profileData.org.brandingColor);

        return (
            <Fragment>
                <IconButton
                    aria-label="More"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    color="primary"
                    style={{color: colors.primary.b700}}
                    onClick={this.handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 220,
                            width: 200,
                        },
                    }}
                >
                    {isGM ? null : <MenuItem onClick={() => { openItem(); this.handleClose();  mixpanel.track('Go to item from chat', {});}}>
                        {this.props.isUser ? t('viewUser') : t('viewItem')}
                    </MenuItem>}
                    <MenuItem disabled onClick={this.handleClose}>
                        {t('Mute (Soon)')}
                    </MenuItem>

                </Menu>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    state,
    userData: state.userData,
})

export default compose(
    connect(
        mapStateToProps,
    ),
    withTranslation('chat'),
    )(ChatHeaderMenu);