import { COLLECTION_INIT, COLLECTION_UPDATE, TOGGLE_COLLECTION_DIALOG, COLLECTION_SETTINGS_UPDATE, COLLECTION_GROUP_UPDATE, FULL_RESET_STORE, ADD_EXTERNAL_INPUT, REMOVE_EXTERNAL_INPUT, EDIT_EXTERNAL_INPUT } from "../actionTypes/actionTypes";
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = [];

const colletion = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState
        case COLLECTION_INIT:
            return {
                ...action.collection,
                open:true
            }
        case TOGGLE_COLLECTION_DIALOG:
            return {
                ...state,
                open: !state.open
            }
        case ON_GO_TO_ITEM_NOTIF:
        case ON_GO_TO_CHAT: {
            if(!state.open) return state
            return {
                ...state,
                open: false
            }
        }
        case COLLECTION_UPDATE:
            return {
                ...state,
                ...action.collection
            }
        case COLLECTION_SETTINGS_UPDATE: 
        return {
            ...state,
            settings: { 
                ...state.settings, 
                ...action.settings 
            }
        }
        case ADD_EXTERNAL_INPUT: {
            const {input} = action
            const prevExternalInputs = Array.isArray(state.externalInputs) ? [...state.externalInputs] : []
            return {
                ...state,
                externalInputs: [...prevExternalInputs, input]
            }
        }
        case EDIT_EXTERNAL_INPUT: {
            const {input} = action
            const prevExternalInputs = Array.isArray(state.externalInputs) ? [...state.externalInputs] : []
            const newInputs = prevExternalInputs.map(x => {
                if(x.uid !== input.uid) return x
                return input
            })
            return {
                ...state,
                externalInputs: newInputs
            }
        }
        case REMOVE_EXTERNAL_INPUT: {
            const {uid} = action
            const prevExternalInputs = Array.isArray(state.externalInputs) ? [...state.externalInputs] : []
            const filtered = prevExternalInputs.filter(input => input.uid !== uid)
            return {
                ...state,
                externalInputs: [...filtered]
            }
        }

        case COLLECTION_GROUP_UPDATE: 
        return {
            ...state,
            group: { 
                ...state.group, 
                ...action.group 
            }
        }
        default:
            return state
    }
}

export default colletion