import React, { Component, PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';



import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import { showSnackbar } from '../../../../../actions/appActions'
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => ({
});


class EditChannel extends PureComponent {

    state = {
        text: ''
    }

    tagPrefix = () => {
        if (this.props.tagInfo.type &&
            this.props.tagInfo.type.toLowerCase() === 'person') {
            return '@'
        } else {
            return '#'
        }
    }

    onChange = (event) => {
        this.setState({ text: event.target.value })
    }

    handleDescriptionSave = () => {
        this.props.onClose()

        const { dispatch, inputService, tagInfo } = this.props;
        this.props.inputService.addTagDescription(this.tagPrefix() + tagInfo.tag, this.state.text).then(() => {
            dispatch({
                type: 'RESULT_CHANNEL_UPDATE', results: {
                    channel: { ...tagInfo, description: this.state.text },
                }
            })
            this.setState({ open: false })
            dispatch(showSnackbar('Saved!'));
        })
        dispatch(showSnackbar('Saving...'));

    }

    initText = () => {
        this.setState({
            text: this.props.tagInfo.description
        })
    }
    render() {
        const { tagInfo, dispatch, open, onClose, t } = this.props;

        return (
            <Dialog
                fullWidth
                onEnter={this.initText}
                open={open}
                onRequestClose={onClose}>
                <DialogTitle>#{tagInfo.tag}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1">{t('Please enter channel description:')}</Typography>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        multiline={true}
                        id="name"
                        label={t("Channel description")}
                        value={this.state.text}
                        onChange={this.onChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.handleDescriptionSave} color="primary">
                        {t('Save description')}
                    </Button>
                </DialogActions>
            </Dialog>)
    }




}


const mapStateToProps = (state) => ({
    userData: state.userData,
    userProfile: state.userData,
    tagInfo: state.results.channel,
    globalData: state.globalData,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('widgetLibrary'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(EditChannel);

