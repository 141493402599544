import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import UsersSection from '../Sections/UsersSection/UsersSection';
import GroupsSection from '../Sections/GroupsSection/GroupsSection';
import ChannelsSection from '../Sections/ChannelsSection/ChannelsSection';
import SuggestSection from '../Sections/SuggestSection/SuggestSection';

const styles = (theme) => ({
    container: {
        width: theme.constants.searchInputWidth,
		// position: 'absolute',
		// left: "-80%",
        height: 'fit-content',
        borderRadius: 6,
        backgroundColor: '#f7f7fc',
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 45px)`,
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    divider: {
        backgroundColor: '#dddee6',
        marginRight: 20,
        marginLeft: 20
    }
})

function SearchLanding(props) {
    const {classes, withSuggestion} = props

    return (
        <div data-intrcm-id="SearchLandingPage_e60v9bov" className={classes.container} >
            <SuggestSection
                withKeysNavigation
                withSlice
                filterMode={withSuggestion ? "suggestion" : "filter"}
                withFilter={withSuggestion}
            />
            <UsersSection
                withKeysNavigation
                filterMode="suggestion"
                withFilter={withSuggestion}
            />
            <GroupsSection
                withKeysNavigation
                withSubscribedGroups={false}
                withStarredGroups 
                withManagedGroups={false} 
                withSeeAll 
                withAddNew={false} 
                withSlice
                filterMode={withSuggestion ? "suggestion" : "filter"}
                withFilter={withSuggestion}
            />
            <ChannelsSection
                withKeysNavigation
                withSubscribe={false}
                withTrending
                withSeeAll 
                withAddNew={false}
                withSlice
                filterMode={withSuggestion ? "suggestion" : "filter"}
                withFilter={withSuggestion}
            />
        </div>
    )
}

export const SearchLandingPage = compose(
    withStyles(styles, {withTheme: true})
)(SearchLanding)

