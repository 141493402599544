import { withStyles } from '@material-ui/core';
import { ItemPreviewForOldUi, NewUiWrapper } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { store } from '../../../../index';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview';
import goTo from '../../../actions/goTo';
import goToCollection from '../../../actions/goToCollection';
import { goToEditProfile } from '../../../actions/goToEditProfile';
import { getAllCollection } from '../../../views/groups/groupUtils';
import { mixpanelTrack } from '../../Common/Utils/mixpanelUtils';
import withErrorCatcher from '../../Common/withErrorCatcher';
import ItemPreviewCardComments from '../ItemPreviewComponents/itemPreviewCardComments';
import ItemCardTemplateContainer from './ItemCardTemplateContainer';
import ResultDataItemTemplate from './resultDataItemTemplate';
import { LAST_SCROLL_POS } from '../../App/wrapper';



const styles = theme => ({
    newUiContainer: {
        marginBottom: 24,
        backgroundColor: '#ffffff',
        borderRadius: 8
    }
});

class FeedDataItem extends React.PureComponent {
    state = {
        commentsOpen: false
    }

    _goToCollection = (groupUid) => {
        const { collections, openCollections, dispatch } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid == groupUid)
        if (!collection) {
            return
        }

        if(window.location.pathname === '' || window.location.pathname === '/' || window.location.pathname === '/feed') {
            const el = window.document.getElementById("main_feed_scroll")
            if(el) {   
                LAST_SCROLL_POS.value = el.scrollTop
            }
        } else {
            LAST_SCROLL_POS.value = 0
        }

        goToCollection('/' + collection.title, dispatch, collection.collectionUid, groupUid, collection)();
    }

    goToUser = (userUid) => {
        const { users, dispatch } = this.props

        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }

        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
    }

    openItem = (docId) => {
        const { openFullview, result } = this.props;

        openFullview(result, docId, false)
    }

    
    commentClickedHandle = () => {
        this.setState({ commentsOpen: !this.state.commentsOpen })
    }
    linkEvents = {
        onLinkAdded: () => { },
        onLinkDeleted: () => { },
    }
    onOpenHashtag = (hashtag) => {
        const { dispatch } = this.props
        goTo(hashtag, dispatch)();
    }
    onOpenLink = (link) => {
        window.open(link, '_blank')
    }

    onUserMessage = (auth0_id) => {
        if(!auth0_id) return

        this.props.chatService.openNewChat(auth0_id).then(res => {
          const { chat } = res
          if (!chat || !chat.chatUId) {
            this.props.dispatch(push({ pathname: "chat" }))
            return
          }
          this.props.dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: res
          })
          mixpanelTrack(`User page view - message`, {})
          this.props.dispatch(push(`/chat?chatid=${chat.chatUId}`))
        })
    }

    onUserEditProfile = () => {
        mixpanelTrack('My page view edit profile', {})
        goToEditProfile(this.props.dispatch)
    }

    render() {
        const { docId, classes, result,isSponsoredContent } = this.props;

        const callbacks = {
            onDocumentClick: this.openItem,
            onUserClick: this.goToUser,
            onGroupClick: this._goToCollection,
            onOpenHashtag: this.onOpenHashtag,
            onOpenLink: this.onOpenLink,
            onUserEditProfile: this.onUserEditProfile,
            onUserMessage: this.onUserMessage,
        }

        if (result.dataType === 'poll') {
            let headerProps = {
                docId: docId,
                result: result,
                whyDoISeeThisItem: '',
                relatedDocuments: [],
            }

            return <ItemCardTemplateContainer>
                <ResultDataItemTemplate {...headerProps} result={result} commentsOpen={this.state.commentsOpen} commentClickedHandle={this.commentClickedHandle} />
                <ItemPreviewCardComments
                    {...headerProps}
                    changeCounter={this.changeCounter}
                    linkEvents={this.linkEvents}
                    expanded={this.state.commentsOpen}
                    docId={docId}
                    result={result}
                    relatedDocuments={[]} />
            </ItemCardTemplateContainer>
        }
        return (
            <div data-intrcm-id="FeedDataItem_2ulowask" className={classes.newUiContainer}>
                <NewUiWrapper callbacks={callbacks} store={store}>
                    <ItemPreviewForOldUi isSponsoredContent={isSponsoredContent} docId={docId} openItem={this.openItem} />
                </NewUiWrapper>
            </div>)

    }
}


const mapStateToProps = (state) => ({
    collaborationService: state.services.collaborationService,
    users: state.globalData.users,
    openCollections: state.globalData.openCollections,
    chatService: state.services.chatService,
    collections: state.userData.collections,
})
const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    withOpenFullview,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FeedDataItem);
