import { withStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
    container: {
    },
    containerForLongText: {
        width: 150,
    },
    text: {
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
        fontFamily: 'Rubik',
        fontSize: 36,
        fontWeight: '800',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.56,
        letterSpacing: 0,
        color: '#ffd288'
    },
    textSmall: {
        fontSize: '18px !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
    }
})
const DataTypeTag = (props) => {
    const {dataType, styles, classes, tag} = props

    return <div data-intrcm-id="DataTypeTag_cww672vl" style={styles.container} className={dataType.length > 12 ? classes.containerForLongText : classes.container}>
        <div data-intrcm-id="DataTypeTag_agbe7vug" style={styles.text} className={dataType.length > 12 ? `${classes.text} ${classes.textSmall}` : classes.text}>
            {tag ? `#${dataType}` : dataType}
        </div>
    </div>
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DataTypeTag)