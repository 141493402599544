export default class InputService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = ()=>{};
        this.backendUrl = process.env.API_SERVER
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    getHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token
        });
    }

    bookmarkLink(link) {
        var myHeaders = this.postHeaders();

        if (link.url !== '') {
            return fetch(this.backendUrl + '/bookmarks', {
                method: 'POST',
                body: JSON.stringify(link),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    postItem(input) {
        var myHeaders = this.postHeaders();

        if (input.document) {
            const document = input.document;

            if (document.title === '') {
                document.title = 'Untitled'
            }

            if (document.postText !== '' || document.docText !== '' || document.hasPreview) {
                return fetch(this.backendUrl + '/content',
                    {
                        method: 'POST',
                        body: JSON.stringify(input),
                        headers: myHeaders
                    })
                    .then(function (res) {
                        if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                    });
            }
        } else {
            return false
        }

    }

    updateItem(docId, updatedItem) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            if (updatedItem.title === '') {
                updatedItem.title = 'Untitled'
            }
            return fetch(this.backendUrl + '/content/update/?id=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    body: JSON.stringify(updatedItem),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    updateItemPermission(docId, isPrivate, members = [], isLimitedToMembers, groupUid) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/content/permissions?docId=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    body: JSON.stringify({ isPrivate, members, isLimitedToMembers, groupUid }),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    updateItemClassification(docId, classfication) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/content/classifications?docId=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    body: classfication,
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    addMembers(docId, updatedMembersUUIDs) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/content/updateMembers?id=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    body: JSON.stringify(updatedMembersUUIDs),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

  
    checkLink(link) {
        var myHeaders = this.postHeaders();

        if (link !== '') {
            return fetch(this.backendUrl + '/bookmarks/check',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        Url: link
                    }),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    // Archive item
    addTagDescription(tag, description) {
        var myHeaders = this.getHeaders();

        if (tag !== '') {
            return fetch(this.backendUrl + '/tags/describe?hashtag=' + encodeURIComponent(tag) + '&description=' + encodeURIComponent(description),
                {
                    method: 'GET',
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }
    async uploadMediaAsync(uri, fileName) {
        const apiUrl = process.env.API_SERVER + '/files/s3';

        const formData = new FormData();
        const attachment = await fetch(uri)
        const blob = await attachment.blob()

        formData.append('attachment', blob, fileName)

        let options = {
            method: 'POST',
            body: formData,
            headers: this.getHeaders(),
            mode: "cors"
        };
        return fetch(apiUrl, options).then(res => res.json());
    }


    // Archive item
    deleteItem(docId) {
        var myHeaders = this.getHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/archive?id=' + docId,
                {
                    method: 'DELETE',
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    shareCollection(userIds, collectionId) {
        var myHeaders = this.postHeaders();

        if (collectionId) {
            return fetch(this.backendUrl + '/collections/share',
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                        userIds: userIds,
                        collectionId
                    }),
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    runFlow(docId) {
        const headers = this.getHeaders()
        return fetch(this.backendUrl + `/content/runFlow?docId=${encodeURIComponent(docId)}`,
            {
                method: 'POST',
                headers
            }).then(x => {
                if (!x.ok) {
                    return Promise.reject()
                }
                return Promise.resolve()
            })
    }

    // Archive item
    getFilestackUploadPolicy() {
        var myHeaders = this.getHeaders();

        return fetch(this.backendUrl + '/files/fskeyupload',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    getFilestackViewPolicy() {
        var myHeaders = this.getHeaders();

        return fetch(this.backendUrl + '/files/fskeyview',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    loadS3Hash() {
        return fetch(this.backendUrl + '/files/hashs3', {
            method: 'GET',
            headers: this.getHeaders()
        }).then(res => res.json());
    }

    getCloudFrontSignature() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        let url = this.backendUrl + '/files/cfsignedurl';
        return fetch(url,
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            }).catch((e) => {
             
            });

    }

    addMustReadUsers(docId, updatedItem) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/mustRead/updateMustReadUsers?id=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    body: JSON.stringify(updatedItem),
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    approvedReading(docId) {
        var myHeaders = this.postHeaders();

        if (docId !== '') {
            return fetch(this.backendUrl + '/mustRead/toggleApprove?docId=' + encodeURIComponent(docId),
                {
                    method: 'PUT',
                    headers: myHeaders
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    linkMultipleItems(docId, relatedDocIds) {
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/links/multiple?docId=' + docId,
            {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(relatedDocIds)
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    changeEveryoneCanEdit(docId, isEveryoneCanEdit) {
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(`${this.backendUrl}/content/change-everyone-can-edit/${docId}`,
            {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({ isEveryoneCanEdit })
            })
        }
}
