import { withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../../../../actions/appActions';
import { isQueryInString, mapLandingUsers } from '../../../../../../utils/searchNavigation/searchHelpers';
import { checkIfSubscribed } from '../../../../../../utils/usersHelpers';
import goTo from '../../../../../actions/goTo';
import NoItemsPlaceholder from '../../../../../views/knowledge/Library/NoItemsPlaceholder/NoItemsPlaceholder';
import User from './User';

const styles = (theme) => ({
    container: {
        marginTop: 24,
        width: theme.constants.searchInputWidth - 40,
        height: 'fit-content',
        marginRight: 20,
        marginLeft: 20,
        marginBottom: 30,
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 2,
        color: '#979aaa',
    },
    users: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    noUsers: {
    }
})

const initFilterUsers = {
    users: []
}
const filterUsers = (filter = initFilterUsers, query) => {
    const {users} = filter;
    const q = query.slice(1).toLowerCase();
    return users.filter(user => isQueryInString(q, `${user.firstname} ${user.lastname}`))
}

function AllUsersSection(props) {
    const {classes, t, activeUsers, dispatch, userData, userSettingsService, query, withFilter, initialLandingUsers, withKeysNavigation} = props
    const [users, setUsers] = useState(initialLandingUsers)
    const [filteredUsers, setFilteredUsers] = useState(initialLandingUsers)
    const [isLoading, setIsLoading] = useState(false)

    const filterRef = useRef(initFilterUsers)
    useEffect(() => {
        if(!withFilter) return;

        const {users} = filterRef.current
        const filtered = filterUsers({users}, query);
        setFilteredUsers(filtered)
    }, [query, withFilter])

    useEffect(() => {
        if(withFilter) return;

        const users = mapLandingUsers(activeUsers);
        setUsers(users)
    }, [withFilter, activeUsers])

    useEffect(() => {
        filterRef.current.users = users;
    }, [users])


    const openUser = (e, tag) => {
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
          })
        goTo(userTagFormat, dispatch)()
    }

    const subscribe = (auth0_id) => {
        if(isLoading) return
        const user = activeUsers.find(user => user.auth0_id === auth0_id)
        if(!user) return;
    
        setIsLoading(true)
        dispatch(showSnackbar('subscribing...'));
        userSettingsService
            .subscribe(user)
            .then((_) => {
                userSettingsService.getUserData().then((userData) => {
                    dispatch(showSnackbar('Subscribed successfully!'));
                    dispatch({
                        type: 'USER_DATA_LOAD', data: {
                            ...userData
                        }
                    });
                    setIsLoading(false)
                })
            });
    }
    const unsubscribe = (tagUser) => {
        if(isLoading) return
        const subscribedTag = userData.subscribedTags.find(tag => tag.tag.toLowerCase() === tagUser.toLowerCase());
        setIsLoading(true)
        dispatch(showSnackbar('unsubscribing...'));
        userSettingsService.unsubscribe(subscribedTag.id).then(_ => {
            userSettingsService.getUserData().then((userData) => {
                dispatch(showSnackbar('Unsubscribed successfully!'));
                dispatch({
                    type: 'USER_DATA_LOAD', data: {
                        ...userData
                    }
                });
                setIsLoading(false)
            })
        })
    }

    const renderUsers = () => {
        if(withFilter) return;
        if(!users.length) return <NoItemsPlaceholder containerStyle={classes.noUsers} placeholder={t("No users available")} />

        return (
            <>
                <div data-intrcm-id="AllUsersSection_ufl8s11q" className={classes.title}>{t('All Users').toUpperCase()}</div>
                <div data-intrcm-id="AllUsersSection_91z6hxo5" className={classes.users}>
                    {users.map(user => <User withKeysNavigation={withKeysNavigation} isSubscribed={checkIfSubscribed(user.tag, userData)} subscribe={subscribe} unsubscribe={unsubscribe} openUser={openUser} key={user.auth0_id} user={user} />)}
                </div>
            </>
        )
    }
    const renderFilteredUsers = () => {
        if(!withFilter) return;
        if(!filteredUsers.length) return <NoItemsPlaceholder containerStyle={classes.noUsers} placeholder={t("No users available")} />

        return (
            <>
                <div data-intrcm-id="AllUsersSection_be6oc4gu" className={classes.title}>{t('Found').toUpperCase()}</div>
                <div data-intrcm-id="AllUsersSection_kwa7jei5" className={classes.users}>
                    {filteredUsers.map(user => <User withKeysNavigation={withKeysNavigation} isSubscribed={checkIfSubscribed(user.tag, userData)} subscribe={subscribe} unsubscribe={unsubscribe} openUser={openUser} key={user.auth0_id} user={user} />)}
                </div>
            </>
        )
    }
    return (
        <div data-intrcm-id="AllUsersSection_nfi11o66" className={classes.container} >
            {renderUsers()}
            {renderFilteredUsers()}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      initialLandingUsers: state.searchNavigation.landingUsers,
      userData: state.userData,
      activeUsers: state.globalData.activeUsers,
      query: state.search.query,
      userSettingsService: state.services.userSettingsService
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AllUsersSection)

