import React, { Fragment } from 'react'
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import ItemPreviewCardHeader from '../ItemPreviewComponents/itemPreviewCardHeader';
import ItemPreviewCardFooter from '../ItemPreviewComponents/itemPreviewCardFooter';
import PostText from '../ItemPreviewComponents/PostText';
import ItemContentPreview from '../ContentPreview/ItemContentPreview';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { getFileLinkFromCloundfront, downloadFile } from '../../../../utils/filesUtils.js';
import { Grid } from '@material-ui/core';

class ResultDataItemTemplate extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    openLink = () => {
        const { dispatch, collaborationService, docId, result } = this.props;

        collaborationService.logClick(docId);
        window.open(result.link, '_blank')
    }

    openFile = () => {
        const { result, cloudfront } = this.props;
        if (result.attachments && result.attachments.length) {
            const fileLink = getFileLinkFromCloundfront(cloudfront, result.attachments[0].fileUid);
            const filename = result.attachments[0].fileName;

            downloadFile(fileLink, filename)
        }
    }

    openFileFromClodfront = () => {

    }


    openItem = () => {
        const { dispatch, collaborationService, result, docId } = this.props;

        docId && collaborationService.logClick(docId);

        dispatch({ type: 'FULLVIEW_OPEN_LOAD', item: { ...result }, itemId: docId })
    }
    linkEvents = {
        onLinkAdded: () => { },
        onLinkDeleted: () => { },
    }
    render() {
        const { result, commentsOpen, commentClickedHandle, docId, withoutOpenComment, hideComment } = this.props;

        return <Fragment>
            <ItemPreviewCardHeader result={result} docId={docId} />
            <PostText postText={result.postText} dataType={result.dataType} />
            <ItemContentPreview
                result={result}
                docId={docId}
                openItem={this.openItem}
                openLink={this.openLink}
                openFile={this.openFile}
            />
            <ItemPreviewCardFooter
                hideComment={hideComment}
                docId={docId}
                commentsOpen={commentsOpen}
                result={result}
                commentClickedHandle={withoutOpenComment ? this.openItem : commentClickedHandle}
                linkEvents={this.linkEvents}
            />

        </Fragment>
    }
}



const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    // mappings: state.globalData.inputMappings,
    ...state.services,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ResultDataItemTemplate);
