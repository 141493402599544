export default class QueryService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    // Create custom mapping
    createSource(source) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/sources/new/', {
            method: 'POST',
            body: JSON.stringify(source),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Create custom mapping
    getSource(sourceId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/sources/source/' + sourceId, {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Custom input mappings
    getSources() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
        });

        return fetch(process.env.API_SERVER + '/sources/all',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    // Create custom mapping
    editSource(sourceId, userMappings) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/sources/source/' + sourceId, {
            method: 'PUT',
            body: JSON.stringify(userMappings),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

}