import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, Icon, Paper, TextField, Select, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getCollectionByGroupUid, proccessLabelColor, StatusColorMap } from '@tuqqi/common';
import React, { PureComponent, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { APPLY_LOCAL_FILTER, CLEAN_FILTER, CLEAN_LOCAL_FILTER, DATA_UPDATE, REMOVE_LOCAL_FILTER, SEARCH_QUERY_UPDATE } from '../../../../../actionTypes/actionTypes.js';
import { initFilter } from '../../../../../reducers/searchReducer.js';
import { allCollectionMembers, getAllCollection } from '../../../../views/groups/groupUtils.js';
import SavedReportSelection from '../../../../views/groups/table/EnhancedTableToolbar/SavedReportSelection.js';
import SaveIcon from '../../../Common/Icons/AppInUse/SaveIcon.js';
import { defaultDataTypes } from '../../../Common/Utils/defaultDataTypes.js';
import withErrorCatcher from '../../../Common/withErrorCatcher.js';
import { withMixpanel } from '../../../Common/withMixpanel.js';
import { showLabels } from '../../../FeedViewComponents/ItemPreviewComponents/Menu/LabelMenu/labelUtil.js';
import { statusColors } from '../../../Kanban/ListColorModal/ListColorModal.jsx';
import AutosuggestChip from './AutosuggestChip';
import DatePicker from "./DatePicker";
import { queryFiltersActions, searchQueryActions, initialSearchQuery, isCommonFilterPresented, isSomeMappingFilterPresented } from '@tuqqi/web';
import parseImagUrl from "../../../../components/Common/parseImagUrl";

const styles = theme => ({
    isAuthorContainer: {
        marginLeft: 32
    },
    unseenContainer: {
        marginLeft: 20
    },
    checkboxAutor: {
        padding: 0
    },
    container: {
        zIndex: 1203,
        width: 728,
        position: 'absolute',
        left: -70,
        right: 0,
        top: 64,
        boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
        maxHeight: 700,
        overflowY: 'auto'
    },
    closeIcon: {
        position: 'absolute',
        right: 16,
        top: 10,
    },
    button: {
        marginRight: 16,
        textTransform: 'capitalize',
    },
    buttonHolder: {
        justifyContent: 'space-between',
    },
    datePicker: {
        margin: '0px 0px 0px 32px'
    },
    datePickerAgeFrom: {
        margin: '0px 32px 0px 0px',
        width: 280,
    },
    datePickerAgeTo: {
        margin: '0px 0px 0px 32px',
        width: 280,
    },
    localFilters: {
        maxHeight: 500,
    },
    savedReports: {
        width: 319
    },
    savedReportsWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 30,
        marginTop: 30,
        marginRight: 30,
    },
    settingsIcon: {
        margin: 'auto'
    },
    saveButtonContainer: {
        cursor: 'pointer',
        width: 44,
        height: 44,
        borderRadius: 4,
        border: `solid 0.5px ${theme.newUI.color.gray400}`,
        backgroundColor: theme.newUI.color.gray50
    },
    ageAllFiltersHolder: {
        marginBottom: 10
    },
    datePickerWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 32px'
    },
    ageFilteringHolder: {
        padding: '25px 30px',
        width: 280
    },
    fieldAmount: {
        marginTop: 0,
        width: 175,
    },
    ageFieldTitle: {
        margin: '0 32px 5px',
        fontSize: 17
    },
    selectPeriod: {
        color: '#979AAA'
    }
});

const FromToAgeItem = ({
    classes,
    fieldName,
    t,
    handleDateFromToChangeAgeInput,
    maxDate,
    minDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateFromChange,
    handleDateToChange,
    sourceField,
}) => {
    const [fromValue, setFromValue] = useState('Days')
    const [toValue, setToValue] = useState('Days')
    const [fromAmount, setFromAmount] = useState(0)
    const [toAmount, setToAmount] = useState(0)
    const [flag, setFlag] = useState('')

    const handleChangeFrom = (event) => {
        setFromValue(event.target.value)
        setFlag('from')
    }

    const handleChangeTo = (event) => {
        setToValue(event.target.value)
        setFlag('to')
    }

    useEffect(() => {
        let value, amount
        if (flag === 'from') {
            value = fromValue
            amount = fromAmount
        } else {
            value = toValue
            amount = toAmount
        }
        handleDateFromToChangeAgeInput(value, sourceField, flag, amount)
    }, [flag, fromValue, toValue, fromAmount, toAmount, sourceField])

    return (
        <>
            <p className={classes.ageFieldTitle}>{fieldName}</p>
            <Grid container className={classes.datePickerWrapper}>
                <DatePicker classes={{ datePicker: classes.datePickerAgeFrom }} selectedDate={selectedDateFrom} onChange={handleDateFromChange} label={t('From')} maxDate={maxDate} />
                <DatePicker classes={{ datePicker: classes.datePickerAgeTo }} selectedDate={selectedDateTo} onChange={handleDateToChange} label={t('To')} minDate={minDate} />
            </Grid>
            <Grid container className={classes.ageAllFiltersHolder}>
                <Grid container className={classes.ageFilteringHolder} style={{ marginRight: 88 }}>
                    <Grid container item xs={6} alignItems="flex-end">
                        <Select
                            value={fromValue}
                            onChange={handleChangeFrom}
                            className={classes.selectPeriod}
                        >
                            <MenuItem value={'Days'}>{t('Days')}</MenuItem>
                            <MenuItem value={'Months'}>{t('Months')}</MenuItem>
                            <MenuItem value={'Years'}>{t('Years')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="number" label={fromValue + ' ago'} variant="standard" className={classes.fieldAmount} onChange={(e) => {
                            setFlag('from')
                            setFromAmount(e.target.value)
                        }} />
                    </Grid>
                </Grid>
                <Grid container className={classes.ageFilteringHolder}>
                    <Grid container item xs={6} alignItems="flex-end">
                        <Select
                            value={toValue}
                            onChange={handleChangeTo}
                            className={classes.selectPeriod}
                        >
                            <MenuItem value={'Days'}>{t('Days')}</MenuItem>
                            <MenuItem value={'Months'}>{t('Months')}</MenuItem>
                            <MenuItem value={'Years'}>{t('Years')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="number" label={toValue + ' ago'} variant="standard" className={classes.fieldAmount} onChange={(e) => {
                            setFlag('to')
                            setToAmount(e.target.value)
                        }} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


class FilterModal extends PureComponent {
    get emptyState() {
        return {
            ...initFilter(),
            usersSuggestions: [],
            dataTypesSuggestions: [],
            dataTypeFields: [],
            dateTypeFields: [],
            labelsSuggestions: [],
            statusesSuggestions: [],
            colorSuggestions: [],
            groupSuggestions: [],
            relatedDocsSuggestions: [],
            savedReportsDialog: false,
            savedReportName: '',
            groups: [],
            colors: [],
            laneStatusTitle: [],
            isLabelsLoaded: false,
        }
    }

    state = this.emptyState;
    docsTitleRef = {}


    acceptFiltersReports = (data, isEmpty = false) => {
        if (isEmpty) {
            this.setState(this.emptyState)
            return;
        }
        this.setState({ ...data })
    }

    loadReports = () => {
        const { userSettingsService, collectionInfo, pathname } = this.props;
        userSettingsService.getUserSavedReportsForMyPage().then((result) => {
            if (!result) return;
            const savedReports = pathname.includes('group/table') ?
                result.filter(r => r.collectionId === collectionInfo.id) :
                result.filter(r => r.collectionId === 0)

            this.props.dispatch({
                type: 'SET_SAVED_REPORTS',
                savedReports
            })
        })
    }

    saveReport = () => {
        const { savedReportName } = this.state;
        const { userSettingsService, pathname, collectionInfo } = this.props;
        const searchQuery = this.pullNewSearchQuery()
        if (!savedReportName) return;
        const report = {
            reportName: savedReportName,
            reportJSON: JSON.stringify(searchQuery),
            ...(pathname.includes("group/table") ? { collectionId: collectionInfo.id } : {})
        }
        userSettingsService.saveUserReportForMyPage(report).then((_) => {
            this.loadReports();
        })
    }

    getAllUserSuggestions = () => {
        const {users} = this.props
        return users.map(user => ({
            value: user.auth0_id,
            label: `${user.firstname} ${user.lastname}`,
            avatar: user.profileImageUrl
        }))
    }

    getUserSuggestions = () => {
        const {collectionInfo, userUid, cloudfront} = this.props
        if(!this.isGroup()) {
            return this.getAllUserSuggestions()
        }

        const groupExists = !!collectionInfo?.collectionGroup
        const members = !!groupExists ? allCollectionMembers(collectionInfo) : []
        if(!members.length) return this.getAllUserSuggestions()

        const userisMember = members.findIndex(x => x.auth0_id === userUid)
        if(userisMember === -1) this.getAllUserSuggestions()

        return members.map(user => ({
            value: user.auth0_id,
            label: `${user.firstname} ${user.lastname}`,
            avatar: !user?.profileImageUrl ? '' : parseImagUrl(cloudfront, user.profileImageUrl)
        }))
    }


    buildNewFilters = () => {
        const { inputMappings, collections, openCollections, searchQueryNew, localFilter, documents, relatedDocs, orgLabels, orgUid, userCollections, collectionViews, t } = this.props
        this.loadReports();
        const allCollections = getAllCollection(openCollections, collections);
        const groupsData = allCollections.map(collection => {
            const groupSuggestion = {
                label: collection.title,
                value: collection.collectionGroup.groupUid,
            }
            return groupSuggestion;
        })

        const currCcollection = allCollections.find(c => c.collectionGroup.groupUid === searchQueryNew.groupUid)

        const kanbanViewLists = [...collectionViews.find(view => view.collectionViewType === 'Kanban')?.itemLists ?? []]

        kanbanViewLists.unshift({ title: currCcollection && currCcollection.newColumnTitle ? currCcollection.newColumnTitle : 'New', listColor: currCcollection && currCcollection.groupDefaultStatusColor ? currCcollection.groupDefaultStatusColor : 0 })

        const statusesSuggestions = kanbanViewLists.map(el => el).map(el => ({
            value: el.title,
            label: el.title,
            backgroundColor: StatusColorMap[el.listColor],
            isColored: true,
        }))

        const customColors = this.props.customStatuses.map(x => ({id: x.taskStatusColor, label: x.title, color: StatusColorMap[x.taskStatusColor]}))
        const colorsData = [...statusColors, ...customColors].map(color => {
            const groupSuggestion = {
                label: color.label,
                value: color.id,
                backgroundColor: color.color,
                isColored: true,
            }
            return groupSuggestion;
        })
        colorsData.push({
            label: 'No color',
            value: 0,
            backgroundColor: 'gray',
            isColored: true,
        })
        const usersSuggestions = this.getUserSuggestions()
        const dataTypesSuggestions = defaultDataTypes().concat(inputMappings).map(dataType => ({
            value: dataType.customDataMapping.dataTypeFilter,
            label: dataType.title
        }))
        const relatedDocsSuggestions = relatedDocs.map((el) => {
            if (documents[el.Key]) {
                return {
                    value: el.Key,
                    label: documents[el.Key] && documents[el.Key].document ? `${documents[el.Key].document.title} ${documents[el.Key].document.dataType ? '( ' + this.getDataTypeTitle(documents[el.Key].document.dataType) + ' )' : ''}` : el.Key,
                }
            }
        })
        let labelsSuggestions = []
        // const currCcollection = allCollections.find(c => c.collectionGroup.groupUid === searchQueryNew.groupUid)
        if (currCcollection && currCcollection.collectionLabels) {
            const labelsCommonSuggestions = currCcollection.collectionLabels.map(label => ({
                backgroundColor: label.color,
                label: label.name,
                value: `${currCcollection.collectionUid}:${label.labelUid}`
            }))
            labelsSuggestions = [...labelsCommonSuggestions]
        }
        const _orgLabels = [{color: '#7B4FF0', name: t("Draft"), labelUid: 'draft:draft' }, ...orgLabels]

        const labelsOrgSuggestions = _orgLabels.map(label => ({
            backgroundColor: label.color,
            label: label.name,
            value: label.labelUid === 'draft:draft' ? label.labelUid : `${orgUid}:${label.labelUid}`
        }))
        labelsSuggestions = [...labelsSuggestions, ...labelsOrgSuggestions]
        // Set up custom input filters    
        if (!!searchQueryNew?.dataTypes && searchQueryNew.dataTypes.length === 1) {
            this.customInputFilters(searchQueryNew.dataTypes[0])
        }
        this.setState({ usersSuggestions, dataTypesSuggestions, labelsSuggestions, groupSuggestions: groupsData, colorSuggestions: colorsData, ...initFilter(), ...searchQueryNew, ...localFilter.filters, relatedDocsSuggestions, statusesSuggestions })
    }

    getAllDataTypes = () => {
        const { inputMappings } = this.props
        return defaultDataTypes().concat(inputMappings)
    }

    getDataTypeTitle = (dataTypeFilter) => {
        const all = this.getAllDataTypes()
        return all.find(m => m.customDataMapping.dataTypeFilter === dataTypeFilter)?.title ?? dataTypeFilter
    }



    componentDidMount(nextProps) {
        const { collections, openCollections, search, localFilter, documents, relatedDocs, orgLabels, orgUid, collectionViews, t } = this.props

        if (this.isKanban()) {
            this.buildNewFilters()
            return
        }
        this.loadReports();

        const allCollections = getAllCollection(openCollections, collections);
        const currCcollection = allCollections.find(c => c.collectionUid === search.collectionUid)

        const groupsData = allCollections.map(collection => {
            const groupSuggestion = {
                label: collection.title,
                value: collection.collectionGroup.groupUid,
            }

            return groupSuggestion;
        })
        const kanbanViewLists = [...collectionViews.find(view => view.collectionViewType === 'Kanban')?.itemLists ?? []]

        // kanban new lane        
        kanbanViewLists.unshift({ title: currCcollection && currCcollection.newColumnTitle ? currCcollection.newColumnTitle : 'New', listColor: currCcollection && currCcollection.groupDefaultStatusColor ? currCcollection.groupDefaultStatusColor : 0 })

        const statusesSuggestions = kanbanViewLists.map(el => el).map(el => ({
            value: el.title,
            label: el.title,
            backgroundColor: StatusColorMap[el.listColor],
            isColored: true,
        }))


        const customColors = this.props.customStatuses.map(x => ({id: x.taskStatusColor, label: x.title, color: StatusColorMap[x.taskStatusColor]}))
        const colorsData = [...statusColors, ...customColors].map(color => {
            const groupSuggestion = {
                label: color.label,
                value: color.id,
                backgroundColor: color.color,
                isColored: true,
            }

            return groupSuggestion;
        })
        colorsData.push({
            label: 'No color',
            value: 0,
            backgroundColor: 'gray',
            isColored: true,
        })

        const usersSuggestions = this.getUserSuggestions()

        const dataTypesSuggestions = this.getAllDataTypes().map(dataType => ({
            value: dataType.customDataMapping.dataTypeFilter,
            label: dataType.title
        }))

        const relatedDocsSuggestions = relatedDocs.map((el) => {
            if (documents[el.Key]) {
                return {
                    value: el.Key,
                    label: documents[el.Key] && documents[el.Key].document ? `${documents[el.Key].document.title} ${documents[el.Key].document.dataType ? '( ' + this.getDataTypeTitle(documents[el.Key].document.dataType) + ' )' : ''}` : el.Key,
                }
            }
        })

        let labelsSuggestions = []
        if (showLabels(search)) {
            if (currCcollection && currCcollection.collectionLabels) {
                const labelsCommonSuggestions = currCcollection.collectionLabels.map(label => ({
                    isLabel: true,
                    backgroundColor: proccessLabelColor(label.color),
                    label: label.name,
                    value: `${search.collectionUid}:${label.labelUid}`
                }))
                labelsSuggestions = [...labelsCommonSuggestions]
            }

            const _orgLabels = [{color: '#7B4FF0', name: t("Draft"), labelUid: 'draft:draft' }, ...orgLabels]
            const labelsOrgSuggestions = _orgLabels.map(label => ({
                isLabel: true,
                backgroundColor: proccessLabelColor(label.color),
                label: label.name,
                value: label.labelUid === 'draft:draft' ? label.labelUid : `${orgUid}:${label.labelUid}`
            }))

            labelsSuggestions = [...labelsSuggestions, ...labelsOrgSuggestions]
        }

        // Set up custom input filters    
        if (search.dataTypes.length === 1) {
            this.customInputFilters(search.dataTypes[0])
        }

        this.setState({ usersSuggestions, dataTypesSuggestions, labelsSuggestions, groupSuggestions: groupsData, colorSuggestions: colorsData, ...initFilter(), ...search, ...localFilter.filters, relatedDocsSuggestions, statusesSuggestions })

    }

    customInputFilters = (dataTypeFilter) => {
        //needs support customFieldsMappings for default mappings
        const customInput = this.props.inputMappings.filter(x => x.customDataMapping.dataTypeFilter === dataTypeFilter)[0]

        if (!customInput) return

        const dataTypeFields = customInput.customDataMapping.customFieldsMappings.filter(x => x.type === 'select' || x.type === 'multiSelect')
        const dateTypeFields = customInput.customDataMapping.customFieldsMappings.filter(x => x.type === 'date')

        dataTypeFields.map((field) => {
            if (field.options) {
                field.autosuggestOptions = field.options.split(';').map(option => ({
                    label: option,
                    value: option
                }))
            } else {
                field.autosuggestOptions = []
            }

        })

        this.setState({ dataTypeFields: dataTypeFields, dateTypeFields: dateTypeFields })

    }

    handleOpenSavedReports = () => {
        this.setState({ savedReportsDialog: true });
    };

    handleCloseSavedReports = () => {
        this.setState({ savedReportsDialog: false });
    };

    handleSaveReport = () => {
        this.saveReport()
        this.setState({ savedReportsDialog: false, savedReportName: '' });
    }

    onSelectReport = (report) => {
        const { dispatch, onClose } = this.props
        onClose()
        dispatch({
            type: 'SEARCH_REPORT_NAME',
            reportName: report.label
        })
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
        })
    }

    handleDateFromChange = (newDate) => {
        const dateWithTime = new Date(newDate)
        dateWithTime.setHours(0, 0, 0)
        this.setState({ 'dateFrom': dateWithTime })
    }

    getCurrentDate = () => {
        const today = new Date()
        today.setHours(0, 0, 0)
        return today
    }

    getConfigToState = (val, date) => {
        let dataConfig
        if (val) {
            if (val.data.fromAge) {
                dataConfig = { toAge: date, fromAge: val.data.fromAge }
            } else if (val.data.fromAge) {
                dataConfig = { toAge: date, fromAge: val.data.fromAge }
            } else if (val.data.fromAge && val.data.toAge) {
                dataConfig = { toAge: date, fromAge: val.data.fromAge }
            } else {
                dataConfig = { toAge: date }
            }
        } else {
            dataConfig = { toAge: date }
        }

        return dataConfig
    }

    getConfigFromState = (val, date) => {
        let dataConfig
        if (val) {
            if (val.data.toAge) {
                dataConfig = { toAge: val.data.toAge, fromAge: date }
            } else if (val.data.fromAge) {
                dataConfig = { fromAge: date }
            } else if (val.data.fromAge && val.data.toAge) {
                dataConfig = { toAge: val.data.toAge, fromAge: date }
            } else {
                dataConfig = { fromAge: date }
            }
        } else {
            dataConfig = { fromAge: date }
        }

        return dataConfig
    }

    setUTCDataToState = (date, sourceField) => {
        this.setState({ [`toChangeAge${sourceField}`]: date.toUTCString() })

        const val = this.props.localFilter.filters[sourceField]
        const data = this.getConfigToState(val, date)
        this.props.dispatch({ type: APPLY_LOCAL_FILTER, filter: { [sourceField]: { key: sourceField, data: data, type: 'date' } } })
    }

    setUTCDataFromState = (date, sourceField) => {
        this.setState({ [`fromChangeAge${sourceField}`]: date.toUTCString() })

        const val = this.props.localFilter.filters[sourceField]

        const data = this.getConfigFromState(val, date)
        this.props.dispatch({ type: APPLY_LOCAL_FILTER, filter: { [sourceField]: { key: sourceField, data: data, type: 'date' } } })
    }

    handleDateFromToChangeAgeInput = (period, sourceField, flag, value) => {
        const today = this.getCurrentDate()
        let year = today.getFullYear()
        let month = today.getMonth() + 1
        let day = today.getDate()

        switch (period) {
            case 'Days':
                day -= value;
                today.setDate(day);
                break;
            case 'Months':
                month -= +value + 1;
                today.setMonth(month);
                break;
            case 'Years':
                year -= value;
                today.setYear(year);
                break;
            default:
                console.log(period);
        }

        if (flag === 'from') {
            this.setUTCDataFromState(today, sourceField)
        } else if (flag === 'to') {
            this.setUTCDataToState(today, sourceField)
        }
    }

    handleDateToChange = (newDate) => {
        const dateWithTime = new Date(newDate)
        dateWithTime.setHours(23, 59, 59)
        this.setState({ 'dateTo': dateWithTime })
    }

    handleDateToChangeAge = (sourceField) => (newDate) => {
        const dateWithTime = new Date(newDate)
        dateWithTime.setHours(0, 0, 0)
        this.setState({ [`toChangeAge${sourceField}`]: dateWithTime })

        const val = this.props.localFilter.filters[sourceField]
        const data = this.getConfigToState(val, newDate)
        this.props.dispatch({ type: APPLY_LOCAL_FILTER, filter: { [sourceField]: { key: sourceField, data: data, type: 'date' } } })
    }

    handleDateFromChangeAge = (sourceField) => (newDate) => {
        const dateWithTime = new Date(newDate)
        dateWithTime.setHours(0, 0, 0)
        this.setState({ [`fromChangeAge${sourceField}`]: dateWithTime })

        const val = this.props.localFilter.filters[sourceField]
        const data = this.getConfigFromState(val, newDate)
        this.props.dispatch({ type: APPLY_LOCAL_FILTER, filter: { [sourceField]: { key: sourceField, data: data, type: 'date' } } })
    }

    handleChangeAutoSuggest = (name) => (newValue) => {
        const data = (newValue || []).map(val => val.value)

        this.setState({ [name]: data })
        if (name === 'relatedDocIds' && !data?.length) {
            setTimeout(() => this.search(false), 100)
        }
        if (name === 'dataTypes' && data.length === 1) {
            this.customInputFilters(data[0])
        }
        if (name === 'dataTypes') this.applySelectedFieldsSources()
    }


    applySelectedFieldsSources = () => {
        const { pathname, dispatch } = this.props
        if (pathname.includes("group/table") || pathname.includes("mypage/tasks")) {
            dispatch({
                type: 'SET_IGNORE_SELECTED_FIELD',
                ignoreSelectedFields: true
            })
        }
    }

    handleChangeAutoSuggestLocalFilter = (name) => (newValue) => {
        const { localFilter: { filters = {} } } = this.props
        const data = (newValue || []).map(val => val.value)

        if (newValue === null && name in filters) this.props.dispatch({ type: REMOVE_LOCAL_FILTER, name })
        else this.props.dispatch({ type: APPLY_LOCAL_FILTER, filter: { [name]: { key: name, data: data, type: 'select' } } })

        this.setState({ [name]: { key: name, data: data } })

        this.props.mixpanelTrack('Advanced search local filters applied', {})
    }

    handleCheckbox = (name) => (event) => {
        this.setState({ [name]: event.target.checked })
    }

    promiseOptions = inputValue => {
        return this.props.queryService.autosuggest('#' + inputValue).then(res => {
            const suggested = res.map(suggestion => ({
                value: suggestion.value,
                label: '#' + suggestion.value,
            }))
            suggested.unshift({
                value: inputValue,
                label: '#' + inputValue,
            })

            return suggested
        })
    }
    promiseReated = inputValue => {
        if (!inputValue?.trim?.()) return this.state.relatedDocsSuggestions

        return this.props.queryService.autosuggestItems(inputValue).then(({ val }) => {
            const suggested = val.map((el) => {
                this.docsTitleRef[el.docId] = el.title
                return {
                    value: el.docId,
                    label: `${el.title} ${el.dataType ? '( ' + this.getDataTypeTitle(el.dataType) + ' )' : ''}`,
                }
            })

            return suggested
        })
    }

    clear = () => {
        this.setState(this.emptyState)
        this.props.dispatch({ type: CLEAN_LOCAL_FILTER });
        this.props.dispatch({ type: CLEAN_FILTER });

    }
    pullNewSearchQuery = () => {
        const { tags, users, dataTypes, labels, dateFrom, dateTo, groups, colors, isAuthor, isUnseen, isMyItem, confirmed, mustread, isTrending, relatedDocIds, laneStatusTitle } = this.state

        const newSearchQuery = {
            tags, users, dataTypes, labels, dateFrom, groups, colors, dateTo, isAuthor, page: 0, isUnseen, isMyItem, isTrending, confirmed, mustread, relatedDocIds, laneStatusTitle
        }

        return newSearchQuery
    }

    applyGroupKanbanFilters = (withClose = true) => {
        const { dispatch, onClose, searchQueryNew, localFilter } = this.props
        const mappingFilterPresented = isSomeMappingFilterPresented(localFilter.filters)

        const newSearchQuery = this.pullNewSearchQuery()

        if (!mappingFilterPresented && !isCommonFilterPresented(newSearchQuery)) {

            dispatch(queryFiltersActions.resetAllQueryFilters())
            dispatch(searchQueryActions.setSearchQuery({ ...initialSearchQuery, groupUid: searchQueryNew.groupUid, sortOrder: "asc", isCollection: true }))
            !!withClose && onClose()
            return
        }

        dispatch(searchQueryActions.setSearchQuery({ ...searchQueryNew, ...newSearchQuery, page: 0 }))
        dispatch(queryFiltersActions.setApplyingCommonFilters(true))
        dispatch(queryFiltersActions.setApplyingMappingFilters(mappingFilterPresented, mappingFilterPresented ? localFilter.filters : {}))
        !!withClose && onClose()
    }

    search = (withClose = true) => {

        const { dispatch, onClose, goToSearch, mixpanelTrack } = this.props

        const { dataTypes, relatedDocIds } = this.state

        const newSearchQuery = this.pullNewSearchQuery()

        if (this.isKanban()) {
            this.applyGroupKanbanFilters(withClose)
            return
        }
        goToSearch(dataTypes)
        goToSearch(relatedDocIds)
        mixpanelTrack('Advance search applied (web)', {})

        dispatch({ type: SEARCH_QUERY_UPDATE, query: newSearchQuery })


        dispatch({ type: DATA_UPDATE });
        !!withClose && onClose()
    }

    convertTagToLabel = tag => `${tag}`
    convertDocValToLabel = value => {
        const { documents } = this.props
        const sTitle = this.docsTitleRef[value]
        if (!!(!value || !documents[value]) && !!sTitle) return sTitle
        if (!value || !documents[value]) return value
        const x = documents[value]
        return !!x.document ? `${x.document?.title || 'Untitled'}` : value
    }

    listenUpdateRelated = (prevRelated = []) => {
        const { relatedDocs, documents } = this.props
        if (JSON.stringify(relatedDocs) === JSON.stringify(prevRelated)) return

        const relatedDocsSuggestions = relatedDocs.map((el) => {
            if (documents[el.Key]) {
                return {
                    value: el.Key,
                    label: documents[el.Key] && documents[el.Key].document ? `${documents[el.Key].document.title} ${documents[el.Key].document.dataType ? '( ' + this.getDataTypeTitle(documents[el.Key].document.dataType) + ' )' : ''}` : el.Key,
                }
            }
        })
        this.setState({ relatedDocsSuggestions })
    }

    listenUpdateColor = (prevColors = []) => {
        const { collectionViews, search, openCollections, collections } = this.props
        if (JSON.stringify(this.state.colors) === JSON.stringify(prevColors)) return
        const kanbanView = collectionViews.find(view => view.collectionViewType === 'Kanban') ?? []
        const kanbanViewLists = [...kanbanView.itemLists];

        const allCollections = getAllCollection(openCollections, collections);
        const currCcollection = allCollections.find(c => c.collectionUid === search.collectionUid)

        kanbanViewLists.unshift({ title: currCcollection && currCcollection.newColumnTitle ? currCcollection.newColumnTitle : 'New', listColor: currCcollection && currCcollection.groupDefaultStatusColor ? currCcollection.groupDefaultStatusColor : 0 })

        if (this.state.colors.length !== 0) {
            const statusesSuggestions = kanbanViewLists.map(el => el).filter(el => this.state.colors.includes(el.listColor)).map(el => ({
                value: el.title,
                label: el.title,
                backgroundColor: StatusColorMap[el.listColor],
                isColored: true,
            }))

            this.setState({ statusesSuggestions })
        } else {
            const statusesSuggestions = kanbanViewLists.map(el => el).map(el => ({
                value: el.title,
                label: el.title,
                backgroundColor: StatusColorMap[el.listColor],
                isColored: true,
            }))

            this.setState({ statusesSuggestions })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        this.listenUpdateRelated(prevProps.relatedDocs)
        if (this.showLanesFilter()) {
            this.listenUpdateColor(prevState.colors)
        }

        // this.setState({ isMyItem: this.props.search.isMyItem })

    }
    isAllowSaveReport = () => {
        const { savedReportName } = this.state
        const { savedReports } = this.props
        const names = savedReports.map(r => r.reportName)
        if (!savedReportName || names.includes(savedReportName)) return false
        return true
    }

    needsRenderSavedReports = () => {
        const { pathname } = this.props
        return (pathname.includes("search") || pathname.includes("mypage") || pathname.includes("group/table"))
    }

    isKanban = () => {
        const { pathname } = this.props
        return !!pathname.includes("group/kanban")
    }

    isGroup = () => {
        const { pathname } = this.props
        return !!pathname.includes("group")
    }


    renderSavedReports = () => {
        const { classes, t, savedReports } = this.props
        if (!savedReports) return null;
        if (!this.needsRenderSavedReports()) return null;

        return (
            <div data-intrcm-id="FilterModal_cllweqwq" className={classes.savedReportsWrap}>
                <div data-intrcm-id="FilterModal_3an9276g" className={classes.savedReports} >
                    <SavedReportSelection mode="navigation" onSelect={this.onSelectReport} onInit={this.acceptFiltersReports} savedReports={savedReports} />
                </div>
                <Tooltip
                    title={t("Save new Report")}
                    placement="bottom" >
                    <Grid item container alignItems='center' onClick={this.handleOpenSavedReports} className={classes.saveButtonContainer}>
                        <SaveIcon fill='#979aaa' className={classes.settingsIcon} isTowTone={false} />
                    </Grid>
                </Tooltip>
            </div>

        )
    }

    renderDialog = () => {
        const { savedReportsDialog, savedReportName } = this.state
        const { t } = this.props
        if (!this.needsRenderSavedReports()) return null;

        return (<Dialog
            open={savedReportsDialog}
            onClose={this.handleCloseSavedReports}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{t("Save report")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("Type the report name")}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("Report name")}
                    fullWidth
                    value={savedReportName}
                    onChange={(event) => { this.setState({ savedReportName: event.target.value }) }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseSavedReports} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={!this.isAllowSaveReport()} onClick={this.handleSaveReport} color="primary">
                    {t("Save")}
                </Button>
            </DialogActions>
        </Dialog>
        )
    }

    showDataTypesFields = () => {
        const { dataTypes } = this.state
        const { pathname } = location
        return dataTypes.length === 1 && (pathname.includes(`/table`) || pathname.includes(`/tasks`) || pathname.includes(`/kanban`) || pathname.includes(`/mypage/calendar`) || pathname.includes(`/group/calendar`))
    }

    showLanesFilter = () => {
        const { pathname } = location
        return pathname.includes(`/group`)
    }

    render() {
        const { handleClose, classes, docId, t, search, onClose } = this.props;
        const { query, tags } = this.state

        return (
            <Paper className={classes.container}>
                <div data-intrcm-id="FilterModal_7nb8bpwg">
                    <Icon size={24} onClick={onClose} className={classes.closeIcon}>close</Icon>
                    <FormControlLabel
                        classes={{ root: classes.unseenContainer }}
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.isUnseen}
                                onChange={this.handleCheckbox('isUnseen')} />
                        }
                        label={t("Unviewed items")}
                    />

                    <FormControlLabel
                        classes={{ root: classes.unseenContainer }}
                        control={
                            <Checkbox
                                disabled={window.location.pathname === '/mypage'}
                                color="primary"
                                checked={this.state.isMyItem}
                                onChange={this.handleCheckbox('isMyItem')} />
                        }
                        label={t("My items")}
                    />

                    <FormControlLabel
                        classes={{ root: classes.unseenContainer }}
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.isTrending}
                                onChange={this.handleCheckbox('isTrending')} />
                        }
                        label={t("Is Trending")}
                    />
                    <FormControlLabel
                        classes={{ root: classes.unseenContainer }}
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.mustread}
                                onChange={this.handleCheckbox('mustread')} />
                        }
                        label={t("Must confirm")}
                    />

                    <FormControlLabel
                        classes={{ root: classes.unseenContainer }}
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.confirmed}
                                onChange={this.handleCheckbox('confirmed')} />
                        }
                        label={t("Confirmed")}
                    />

                    {
                        this.state.labelsSuggestions.length ?
                            <AutosuggestChip placeholder={t('Select multiple labels')} handleChange={this.handleChangeAutoSuggest('labels')} suggestions={this.state.labelsSuggestions} label={t('Labels')} selected={this.state.labels} />
                            : null
                    }
                    <AutosuggestChip
                        placeholder={t('Select multiple tags')}
                        isAsync
                        handleChange={this.handleChangeAutoSuggest('tags')}
                        suggestions={this.promiseOptions}
                        label={t('Tags')}
                        selected={this.state.tags}
                        convertValToLabel={this.convertTagToLabel} />

                    <AutosuggestChip
                        placeholder={t('Select multiple users')}
                        handleChange={this.handleChangeAutoSuggest('users')}
                        suggestions={this.state.usersSuggestions} l
                        label={t('Users')}
                        selected={this.state.users} />

                    {this.state.users.length === 1 ?
                        <FormControlLabel
                            classes={{ root: classes.isAuthorContainer }}
                            control={
                                <Checkbox
                                    classes={{ root: classes.checkboxAutor }}
                                    color="primary"
                                    checked={this.state.isAuthor}
                                    onChange={this.handleCheckbox('isAuthor')} />
                            }
                            label={t("Is Author")}
                        /> : null}


                    <AutosuggestChip placeholder={t('Select multiple data types')} handleChange={this.handleChangeAutoSuggest('dataTypes')} suggestions={this.state.dataTypesSuggestions} label={t('Data Type')} selected={this.state.dataTypes} />
                    {!search.isCollection ? <AutosuggestChip placeholder={t('Select multiple groups')} handleChange={this.handleChangeAutoSuggest('groups')} suggestions={this.state.groupSuggestions} label={t('Group')} selected={this.state.groups} /> : null}
                    <AutosuggestChip
                        isAsync
                        placeholder={t('Select related items')}
                        handleChange={this.handleChangeAutoSuggest('relatedDocIds')}
                        suggestions={this.promiseReated}
                        defaultSuggestions={this.state.relatedDocsSuggestions}
                        withPreview
                        convertValToLabel={this.convertDocValToLabel}
                        label={t('Items')}
                        selected={this.state.relatedDocIds}
                    />
                    {this.showLanesFilter() ?
                        <AutosuggestChip placeholder={t('Select status')} handleChange={this.handleChangeAutoSuggest('laneStatusTitle')} suggestions={this.state.statusesSuggestions} label={t('Status')} selected={this.state.laneStatusTitle} />
                        : null}
                    <AutosuggestChip placeholder={t('Select multiple colors')} handleChange={this.handleChangeAutoSuggest('colors')} suggestions={this.state.colorSuggestions} label={t('Status color')} selected={this.state.colors} />
                    <Grid container>
                        <DatePicker classes={{ datePicker: classes.datePicker }} selectedDate={this.state.dateFrom} onChange={this.handleDateFromChange} label={t('From')} maxDate={this.state.dateTo} />
                        <DatePicker classes={{ datePicker: classes.datePicker }} selectedDate={this.state.dateTo} onChange={this.handleDateToChange} label={t('To')} minDate={this.state.dateFrom} />
                    </Grid>
                    {this.showDataTypesFields() ? <div data-intrcm-id="FilterModal_si6864rf" >
                        <Divider style={{ marginBottom: 16, marginTop: 16, marginLeft: 32, marginRight: 32 }} />
                        {this.state.dataTypeFields.map(f =>
                            <AutosuggestChip placeholder={f.fieldName} handleChange={this.handleChangeAutoSuggestLocalFilter(f.sourceField)} suggestions={f.autosuggestOptions} label={f.fieldName} selected={this.state[f.sourceField] ? this.state[f.sourceField].data : []} />
                        )}

                    </div> : null}
                    {this.state.dateTypeFields.length ? this.state.dateTypeFields.map((item) => {
                        return <FromToAgeItem
                            t={t}
                            classes={classes}
                            fieldName={item.fieldName}
                            key={item.creationDateTime}
                            maxDate={this.state.dateTo}
                            minDate={this.state.dateFrom}
                            sourceField={item.sourceField}
                            handleDateFromToChangeAgeInput={this.handleDateFromToChangeAgeInput}
                            handleDateToChange={this.handleDateToChangeAge(item.sourceField)}
                            handleDateFromChange={this.handleDateFromChangeAge(item.sourceField)}
                            selectedDateTo={this.state[`toChangeAge${item.sourceField}`] || null}
                            selectedDateFrom={this.state[`fromChangeAge${item.sourceField}`] || null}
                        />
                    }) : null}
                    {this.renderSavedReports()}
                    <Grid container style={{ padding: '16px 0px' }} justify='flex-end'>
                        <Button color="primary" onClick={this.clear} className={classes.button}>
                            {t('clear')}
                        </Button>
                        <Button id='publish-new-post' color='primary' variant="raised" onClick={this.search} style={{ marginRight: 30 }} className={classes.button}>
                            {t('search')}
                        </Button>
                    </Grid>

                </div>
                {this.renderDialog()}
            </Paper>
        )
    }
}


const mapStateToProps = (state) => ({
    ...state.services,
    customStatuses: state.data?.globalData?.statuses?.statuses ?? [],
    search: state.search,
    users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    openCollections: state.globalData.openCollections,
    orgLabels: state.globalData.orgLabels,
    collections: state.userData.collections,
    localFilter: state.localFilter,
    pathname: state.wrapper.pathname,
    documents: state.data.documents.data,
    searchQuery: state.search,
    cloudfront: state.globalData.cloudfront,
    relatedDocs: state.results.relatedDocs,
    orgUid: state.data.userData.userDetails.data.organizationUid,
    userUid: state.data.userData.userDetails.data.auth0_id,
    savedReports: state.localFilter?.savedReports ?? [],
    searchQueryNew: state.searchQuery,
    collectionInfo: state.results.channel,
    collectionViews: state?.results?.channel?.collectionViews ?? [],
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}
export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('filterModal'),
    withMixpanel,
    withErrorCatcher("Failed Loading filter, Please Contact Tuqqi Support"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FilterModal);