export const getItemGroupDisplayName = (t, openCollections, userData, item) => {

    const privateText = t('Private')
    const publicText = t('Public')

    if (item.isPrivate) {
        return privateText
    } else if (item.isLimitedToMembers) {
        return 'Limited To Members'
    }

    const orgUid = userData.profileData.org.orgUid;

    if (item.groupUid && item.groupUid !== orgUid) {
        const allCollections = openCollections.concat(userData.collections)
        const collection = allCollections.find(c => c.collectionGroup.groupUid === item.groupUid)
        return collection ? collection.title : publicText
    }

    return publicText
}

export const getClassificationDisplayName = (classifications, classificationLevel) => {
    if (classificationLevel === 0) {
        return "Not Classified"
    }
    const classification = classifications.find(c => c.level === classificationLevel);
    return `${classification.displayName} - level: ${classification.level}`;
}