import { InputBase, withStyles } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import withErrorCatcher from "../../Common/withErrorCatcher.js";

const styles = (theme) => ({
  input: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    letterSpacing: "0.1px",
    color: theme.newUI.color.commonBlack,
  },
});

class InputTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: props.list.description,
    };
  }

  edit = (e) => {
    this.setState({ description: e.target.value });
  };

  onBlur = () => {
    const { list, editListDescription, onBlur } = this.props;

    if (list.description !== this.state.description) {
      editListDescription(list.id, this.state.description);
    }
    onBlur();
  };
  onClick = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      this.onBlur();
      return false;
    }
    return true;
  };

  render() {
    const { classes } = this.props;

    return (
      <InputBase
        onKeyDown={this.onClick}
        autoFocus
        value={this.state.description}
        onChange={this.edit}
        onBlur={this.onBlur}
        className={classes.input}
      />
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation("knowledgeTranslate"),
  withErrorCatcher()
)(InputTitle);
