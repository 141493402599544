import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, IconButton, Checkbox, Button, Typography, Icon } from '@material-ui/core';
import { StarBorder, Delete } from '@material-ui/icons';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/goToActions'

import moment from 'moment';
import urlRegex from 'url-regex';
import { showSnackbar } from '../../../actions/appActions'

import DeleteButton from "./deleteButton";
import ImgComment from "./imgComment";
import DateFrom from "./dateFrom";

const styles = theme => ({
    iconButton: {
        root: {
            fontSize: 14,

        }
    },
    svg: {
        fill: '#fff',
    },
    svgContainer: {
        flex: '0 1 0px',
    },
    svgBorder: {
        width: 34,
        backgroundColor: '#3154ef',

        height: 34,
        border: 'solid 2px #3154ef',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '18px 0 auto 16px',
        marginRight:'16px'
    },
    linkedTitle: {
        color: 'blue'
    },
    icon: {
        fill: 'white',
        fontSize: 18
    },
    commentTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,

        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',

        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle2: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.38)',
        display: 'flex'
    },
    ...theme.comment,
})

class LinkedDocument extends Component {
    state = {
        creator: {}
    }
    openItem = () => {
        const { dispatch, link: { relatedDocumentId } } = this.props
        dispatch({
            type: 'FULLVIEW_OPEN_LOAD',
            itemId: relatedDocumentId
        })
    }

    onDelete = () => {

        const { link, collaborationService, onDelete, dispatch, docId } = this.props;

        dispatch(showSnackbar('Deleting link...'));

        collaborationService.deleteLinked({ docId, link: link.relatedDocumentId }).then((res) => {
            res && onDelete(link.relatedDocumentId);
            dispatch(showSnackbar('Link deleted!'));
        })
    }

    componentDidMount() {

        const { orgUsers, link: { creatorUid } } = this.props

        const creator = orgUsers.find(user => user.auth0_id == creatorUid) || {}
        this.setState({ creator })
    }

    render() {
        const { classes, link, last } = this.props;
        const { relatedDocumentId, title, dataType, creatorUid, linkedOn } = link

        return (
            <div data-intrcm-id="linkedDocument_geabwz03" className={classes.container} >
                <div data-intrcm-id="linkedDocument_vt0wflc4" className={classes.svgContainer}>
                    <div data-intrcm-id="linkedDocument_wp2tv8ox" className={classes.svgBorder}>
                        <Icon className={[classes.svg, classes.icon].join()}><span style={{ color: 'white' }}>insert_link</span></Icon>
                    </div>
                </div>

                <div data-intrcm-id="linkedDocument_pikmnjtm" className={classes.containerContentTime} style={{ borderBottomStyle: last ? 'none' : 'solid' }}>
                    <div data-intrcm-id="linkedDocument_1ff6msrt" className={classes.content}>
                        <div data-intrcm-id="linkedDocument_ra9m3sue" className={classes.commentTitle}>{`${this.state.creator.firstname} ${this.state.creator.lastname} `}</div>
                        <div data-intrcm-id="linkedDocument_vrlzvq80" className={classes.commentSubTitle}><Typography >Linked this item to <a className={classes.linkedTitle} onClick={this.openItem}> {title}</a></Typography></div>
                        <div data-intrcm-id="linkedDocument_v5ex7a0b" className={classes.commentSubTitle2} style={{ overflow: 'hidden', height: 'auto', lineHeight: 1, paddingTop: 2 }}>
                            <DateFrom dateTime={linkedOn} />

                            <DeleteButton creatorId={creatorUid} onDelete={this.onDelete} />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    orgUsers: state.globalData.users,
    collaborationService: state.services.collaborationService
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LinkedDocument);