
const invalidISOStringDates = ['0001-01-01T00:00:00Z','0001-01-01T00:00:00.000Z','0001-01-01T01:00:00Z']

export const checkSpecificSeen = (seenOnDateTime) => {
    if(!seenOnDateTime) return false
    if(invalidISOStringDates.includes(seenOnDateTime)) return false

    return new Date(seenOnDateTime).getTime() <= new Date().getTime()
}

export const checkSpecificSeenWithGeneral = (createdDate, seenOnDateTime, lastSawNotification) => {
    if(!seenOnDateTime) return new Date(createdDate).getTime() <= new Date(lastSawNotification).getTime()
    if(invalidISOStringDates.includes(seenOnDateTime)) return new Date(createdDate).getTime() <= new Date(lastSawNotification).getTime()

    if(new Date(seenOnDateTime).getTime() > new Date(lastSawNotification).getTime()) return true
    return new Date(seenOnDateTime).getTime() <= new Date(lastSawNotification).getTime()
}

export const isNotificationDateSeen = (createdDate, seenOnDateTime, lastSawNotification) => {
    if(!lastSawNotification) return checkSpecificSeen(seenOnDateTime)
    if(invalidISOStringDates.includes(lastSawNotification)) return checkSpecificSeen(seenOnDateTime)

    return checkSpecificSeenWithGeneral(createdDate, seenOnDateTime, lastSawNotification)
}