import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addNotifications } from '../../../actions/notificationsActions'
import { useDesktopNotifications } from '../../../hooks/useDesktopNotifications/useDesktopNotifications'
import { getChannelName, initPusher } from '../Common/Pusher/pusherUtils'
import { feedHasNewItems } from '../../../actions/feedUpdateNewItemsActions'

import Wrapper from './wrapper'

const __Wrapper = (props) => {
    const {notificationService, children, routerLocation, userData, addNotifications,feedHasNewItems, cloudfront, dispatch, fullviewState, inputState} = props
    const receivedRef = useRef([])
    const [t] = useTranslation("notificationsDrawer")

    const pusher = useRef(initPusher(userData))
    const channelName = useRef(getChannelName(userData?.profileData?.auth0_id))

    const userId = useMemo(() => {
        return !userData ? '' : userData?.auth0_id ?? ''
    }, [userData])

    const addInAppNotifications = (notif) => {
        addNotifications([notif])
        if(notif?.systemActionTypeUid === 'user_add_item') {
            feedHasNewItems(true)
        }
    }


    const [requestNotification, executeNotif] = useDesktopNotifications({t, cloudfront, type: 'notification', userId, receivedRef, dispatch, fullviewState, inputState, addInAppNotifications, notificationService})

    useEffect(() => {
        if(!pusher.current) return

        const channel = pusher.current.subscribe(channelName.current);

        requestNotification()

        channel.bind('notification', data => {            
            const allowPush = executeNotif(data)
            if(!allowPush) return
        });
    }, [])

    return <Wrapper location={routerLocation}>
        {children}
    </Wrapper>
}

const mapStateToProps = (state) => ({
    notificationService: state.services.notificationService,
    userData: state.userData,
    fullviewState: state.fullview,
    inputState: state.input,
    cloudfront: state.data.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => ({ 
    dispatch,
    addNotifications: (notifications) => addNotifications(notifications).map(dispatch),
    feedHasNewItems: (state) => feedHasNewItems(state).map(dispatch)
 })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(__Wrapper);
