
import { Grid, withStyles, Typography, Divider } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import React, { PureComponent, Fragment } from 'react';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import { defaultValuesInputTypes } from '../customInputTemplateUtils';
import FormControlLabelSwitch from './FormControlLabelSwitch';


const styles = theme => ({
    divider: {
        margin: '24px 0px'
    },
    menuContainer: {
        padding: 10,
        marginTop: 16,
        height: 'fit-content',
        backgroundColor: lighten('#e0e0e0', 0.7)
    },
    toggle: {

    }
});

class DisplayDummyJsonCustomInput extends PureComponent {
    state = {
        showAnotherView: false
    }

    toggleShowAnotherView = () => {
        this.setState({showAnotherView: !this.state.showAnotherView})
    }

    renderJson = () => {
        const { customFieldsMappings } = this.props
        const { showAnotherView } = this.state

        const data = customFieldsMappings.reduce((accum, field) => {
            if(defaultValuesInputTypes[field.type] === undefined || field.sourceField in accum) return accum
            return {...accum, [field.sourceField]: defaultValuesInputTypes[field.type]}
        }, {})

        const json = JSON.stringify(data, null, 2)
        if(!showAnotherView) return json

        const searchRegExp = /"/gi;
        const replaceWith = '`';
        return json.replace(searchRegExp, replaceWith)
    }
    render() {
        const { classes, customFieldsMappings } = this.props
        const { showAnotherView } = this.state
        if(!customFieldsMappings || !customFieldsMappings.length) return null

        return (
            <Fragment>
                <Divider className={classes.divider} />
                <Typography variant='h4'>Json Example</Typography>
                <Grid container className={classes.toggle}>
                <FormControlLabelSwitch
                    checked={showAnotherView}
                    onChange={this.toggleShowAnotherView}
                    label={'Replace " with `'}
                    disabled={false} />
                </Grid>
                <Grid container className={classes.menuContainer}>
                    <Grid item>
                        <pre>{this.renderJson()}</pre>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
)(DisplayDummyJsonCustomInput);
