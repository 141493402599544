import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { NoChatsIcon } from '../../../Common/Icons/Chat/NoChatsIcon';

const styles = (theme) => ({
    container: {
        marginTop: 56,
        marginBottom: 56,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        marginTop: 16,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: 0.24606,
        color: '#979AAA',
    },
})

const NoChats = (props) => {
    const {classes} = props
    const [t] = useTranslation("chat")
    
    return (
        <>
            <div data-intrcm-id="NotChats_wx6274jn" className={classes.container}>
                <div data-intrcm-id="NotChats_jtexaw2b" className={classes.icon}><NoChatsIcon /></div>
                <div data-intrcm-id="NotChats_pcrj6fsx" className={classes.title}>{t("There are no chats")}</div>
            </div>
        </>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(NoChats)