import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import Calendar from './Calendar/Calendar'
import { openFullview, updateSpecificItem } from '@tuqqi/web';
import { hexToRGB, proccessLabelColorCalendar, shadeColor } from './Calendar/event-utils.js';
import { DATA_FILTERED, DATA_UPDATED } from '../../../actionTypes/actionTypes.js';
import { finishedUpdatingSpecificItem } from '../../../actions/dataActions.js';
import { createCustomTheme } from '../../../utils/theming.js';
import { emptyReq, getOptionsForInputMapping } from './groupUtils.js';
import { shouldAutoOpen } from '../../views/groups/groupUtils.js';
import { FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN } from '../../../actionTypes/actionTypes';
import { getGroupDocumentPermission, getPublicDocumentPermission, proccessLabelColor } from '@tuqqi/common';
import { openInput as openNewUiInput, managerRemovedItemsLocal } from '@tuqqi/web'
import { openInput } from '../../../actions/inputActions.js';
import { themeConstants } from '../../../theme.js';
import { getRowLaneStatus } from '../../../utils/actionsCenter/getItemLaneStatus.js';

const styles = theme => ({
    container: {
        overflow: 'hidden',
        marginTop: -30,
        width: `calc(100% - ${themeConstants.navigationDrawerWidth}px)`,
        height: 'calc(100vh - 80px)',
        marginLeft: themeConstants.navigationDrawerWidth,
    },
    container1: {
        overflow: 'hidden',
        marginTop: -30,
        width: `calc(100% - ${themeConstants.navigationDrawerSmallWidth}px)`,
        height: 'calc(100vh - 80px)',
        marginLeft: themeConstants.navigationDrawerSmallWidth,
    },
});
class GroupCalendarView extends React.Component {
    state = {
        fullKanbanLength: 0,
        events: [],
        isResize: false,
        loading: true,
        isLoadingChanges: false
    }

    async componentDidMount() {
        await this.setKanbanLength()
        const kanbanData = await this.getKanbanData()
        this.updateEvents(kanbanData)
    }

    updateSpecificItemInState(props, docId) {
        const { dispatch, queryService } = props;
        // const { lanes } = this.state;
        // let updatedLanes = [...lanes]

        queryService.searchById(docId).then((results) => {
            if (results.data.length) {

                const event = this.convertDocumentToEvent(docId, results.data[0].Value.Source)
                const events = [...this.state.events].filter(event => event.id !== docId)
                events.push(event)
                this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) })

            }
            this.setState({ isLoadingGroupAndData: false })
            dispatch(finishedUpdatingSpecificItem(docId));
        })
    }

    checkLocalRemove = (prevProps) => {
        if(this.props.queryFiltersUpdateType === 'delete' && prevProps.queryFiltersUpdateType !== 'delete' &&
            !!this.props.queryFiltersItemId) {
                this.setState({ events: this.state.events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)) })
        }
    }

    async componentDidUpdate(prevProps) {
        const { oldUiData, dispatch, queryFiltersItemId } = this.props

        if (this.state.isLoadingChanges) {
            return
        }

        this.checkLocalRemove(prevProps)
        if ((oldUiData.needsUpdate) ||
            (!oldUiData.isFiltering && oldUiData.needsFilter)) {
            this.setState({ isLoadingChanges: true })

            const kanbanData = await this.getKanbanData()
            this.updateEvents(kanbanData)

        }
        else if (!!queryFiltersItemId && !prevProps.queryFiltersItemId) {
            const kanbanData = await this.getKanbanData()
            this.updateEventsFrom(kanbanData)
            return
        }
        else if (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate) {
            // this.setState({ isLoadingChanges: true })
            // this.updateSpecificItemInState(this.props, oldUiData.itemId)
            // update all instead of one - meanwhile
            // TODO: update specific item
            const kanbanData = await this.getKanbanData()
            this.updateEvents(kanbanData)
        }
    }

    getFullviewStatus = (props) => {
        return props.fullviewState.open
    }
    getDocument = (props) => {
        const { documentsState, fullviewState } = props;
        if (!fullviewState.mainDocId) return undefined;

        const documentState = documentsState.data[fullviewState.mainDocId]
        const document = documentState && !documentState.isFetching && documentState.document

        if (!document) return undefined
        return document
    }

    onPress = (docId) => {
        if (!docId) return
        this.props.dispatch(openFullview(docId, false))

    }

    onPressNew = (startDate, endDate) => {
        const { dispatch, groupUid, groupInfo, inputMappings, oldUiInput, userData, searchQuery } = this.props
        const payload = { postText: '', dataType: 'post', permissions: [groupUid], dueDateUTC: startDate, dateTimeFrom: startDate, dateTimeTo: endDate }

        const kanbanListId = ""

        if (shouldAutoOpen(groupInfo)) {
            const dataType = groupInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId, dueDateUTC: startDate, dateTimeFrom: startDate, dateTimeTo: endDate }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }
    }
    getKanbanData = async () => {
        const withLocalFilter = this.isLocalFilterPresented()
        const getAllData = withLocalFilter ? this.getAllDataWithLocalFilter : this.getAllData
        const data = await getAllData()
        const items = withLocalFilter ? this.localFilter((data ?? []).map(el => ({ id: el.Key, document: el.Value.Source }))) : data

        return items
    }
    getAllData = () => {
        const { searchQuery, queryService } = this.props
        const _searchQuery = { ...searchQuery, page: 0 }

        return queryService.GetKanbanData(_searchQuery)
    }

    setKanbanLength = () => {
        const { searchQuery, queryService } = this.props
        const _searchQuery = { ...searchQuery, ...emptyReq }
        queryService.GetKanbanData(_searchQuery).then(res => {
            this.setState({ fullKanbanLength: res.length })
        })
    }

    getAllDataWithLocalFilter = () => {
        const { searchQuery, queryService } = this.props
        const { fullKanbanLength } = this.state
        const _searchQuery = { ...searchQuery, page: 0, size: fullKanbanLength }

        return queryService.Search(_searchQuery, null, true).then(res => {
            const { data = [] } = res
            return data
        })
    }
    getLabelColor = (collectionLabels, defaultColor) => {
        const { collectionUid, userCollections, openCollections, orgLabels } = this.props

        const cuuCollection = userCollections
            .concat(openCollections)
            .find(collection => collection.collectionUid === collectionUid)

        if (collectionLabels.length === 0) return defaultColor

        const [label] = collectionLabels
        const [, docLabelUid] = label.split(':')

        const collectionLabel = [...(cuuCollection?.collectionLabels ?? []), ...orgLabels].find(collectionLabel => collectionLabel.labelUid === docLabelUid)
        return collectionLabel ? collectionLabel.color : defaultColor
    }
    getTheme = () => {
        const { org } = this.props
        const themeOption = org?.brandingColor ?? 'default'
        const theme = createCustomTheme(themeOption)
        return theme
    }
    isLocalFilterPresented = () => {
        const { localFilter = {} } = this.props
        return Object.keys(localFilter.filters).length > 0
    }

    localFilter = (results) => {
        const { localFilter, searchQuery: { dataTypes }, inputMappings } = this.props;
        if (!dataTypes.length) return results

        const selectedFields = getOptionsForInputMapping(inputMappings, dataTypes[0])

        let filteredContent = results
        const filteredColumns = selectedFields.filter(x => !!localFilter.filters[x.sourceField])
        if (filteredColumns && filteredColumns.length > 0) {
            filteredColumns.forEach(column => {
                filteredContent = filteredContent.filter(res => {

                    const fieldValue = column.extractData(res.document)

                    if (Array.isArray(fieldValue)) {
                        for (let index = 0; index < fieldValue.length; index++) {
                            const element = fieldValue[index];
                            if (localFilter.filters[column.sourceField].data.indexOf(element) > -1) {
                                return true
                            }
                        }
                    }

                    if (Array.isArray(localFilter.filters[column.sourceField].data) && localFilter.filters[column.sourceField].data.indexOf(fieldValue) > -1) {
                        return true
                    }

                    if (localFilter.filters[column.sourceField].type === 'date') {
                        const fromToAgeValues = localFilter.filters[column.sourceField].data
                        if (fieldValue && fromToAgeValues.fromAge && fromToAgeValues.toAge) {
                            return new Date(fieldValue) > new Date(fromToAgeValues.fromAge) && new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                        }

                        if (fieldValue && fromToAgeValues.fromAge) {
                            return new Date(fieldValue) > new Date(fromToAgeValues.fromAge)
                        }

                        if (fieldValue && fromToAgeValues.toAge) {
                            return new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                        }
                    }
                    return false
                })
            })
        }
        return filteredContent
    }

    updateEvents = (kanbanData) => {
        const { dispatch } = this.props
        const filteredData = kanbanData.filter(item => {
            const { document } = item
            const isEvent = (!!document.dateTimeFrom && document.dateTimeFrom !== '0001-01-01T00:00:00Z') || !!document.dueDateUTC
            return isEvent
        })
        const events = filteredData.map(item => {
            const { id, document } = item
            return this.convertDocumentToEvent(id, document);

        })

        this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)), loading: false, isLoadingChanges: false }, () => {
            dispatch(finishedUpdatingSpecificItem());
            dispatch({ type: DATA_UPDATED });
            dispatch({ type: DATA_FILTERED });
        })
    }
    updateEventsFrom = (kanbanData) => {
        const { dispatch } = this.props
        const filteredData = kanbanData.filter(item => {
            const { document } = item
            const isEvent = (!!document.dateTimeFrom && document.dateTimeFrom !== '0001-01-01T00:00:00Z') || !!document.dueDateUTC
            return isEvent
        })
        const events = filteredData.map(item => {
            const { id, document } = item
            return this.convertDocumentToEvent(id, document);

        })
        this.setState({ events: events.filter(x => !managerRemovedItemsLocal.getItems().includes(x.id)), loading: false, isLoadingChanges: false }, () => {
            dispatch(updateSpecificItem(null))
        })
    }

    convertDocumentToEvent = (id, document) => {
        const backgroundColor = this.getLabelColor(document.collectionLabels, this.getTheme().primary.main)
        const darkerColor = shadeColor(backgroundColor, -30)
        const lighterColor = proccessLabelColorCalendar(backgroundColor, 0.38)
        const start = !!document.dateTimeFrom && document.dateTimeFrom !== '0001-01-01T00:00:00Z' ? new Date(document.dateTimeFrom).toISOString() : new Date(document.dueDateUTC).toISOString()
        const tmp = new Date(start)
        tmp.setHours(tmp.getHours() + 1)

        const end = !!document.dateTimeTo && document.dateTimeTo !== '0001-01-01T00:00:00Z' ? new Date(document.dateTimeTo).toISOString() : tmp.toISOString()
        const docSchedule = {
            dueDateUTC: document.dueDateUTC,
            taskDueDateDone: document.taskDueDateDone,
            dueDateRecurring: document.dueDateRecurring,
            dateTimeFrom: start,
            dateTimeTo: end,
        }
        const dataType = document.dataType
        const members = document.members ?? []
        const isAllDayEvent = start === end && start === document.dueDateUTC
        const laneStatus = getRowLaneStatus(document, { openCollections: this.props.openCollections, usersCollections: this.props.userCollections })
        return { id, title: document.title, start, end, backgroundColor: lighterColor, borderColor: darkerColor, docSchedule, dataType, laneStatus, members, allDay: isAllDayEvent }

    }

    render() {
        const { classes, isDrawerOpen } = this.props;
        const { loading, events } = this.state
        return (
            <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="groupCalendarView_7jyu92nm" className={isDrawerOpen ? classes.container : classes.container1}>
                    {loading ? null : <Calendar onPressNew={this.onPressNew} onPress={this.onPress} events={events} />}
                </div>
            </CategoryViewOnlyLeftDrawer>)
    }
}



const mapStateToProps = (state) => ({
    queryFiltersItemId: state.queryFilters.itemId,
    queryFiltersUpdateType: state.queryFilters.updateType,
    router: state.router,
    oldUiData: state.oldUiData,
    org: state.userData.profileData.org,
    ...state.services,
    orgLabels: state.data.globalData.labels.labels,
    searchQuery: state.search,
    fullviewState: state.fullview,
    documentsState: state.data.documents,
    userCollections: state.userData.collections,
    groupUid: state?.results?.channel?.collectionGroup?.groupUid ?? '',
    groupInfo: state.results.channel,
    oldUiInput: state.oldUiInput.document,
    inputMappings: state.globalData.inputMappings,
    userData: state.userData,
    openCollections: state.globalData.openCollections,
    localFilter: state.localFilter,
    inputMappings: state.globalData.inputMappings,
    collectionUid: state.results.channel.collectionUid,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}


export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupCalendarView);

