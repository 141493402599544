import React, {Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import { SearchLeftDrawerNew } from '../Components/SearchLeftDrawerNew.js';
import goTo from '../../../actions/goTo.js';

const BaseSearchPage = (props) => {
    const { children, location, dispatch } = props;

    const query = location.query.searchquery
    const paramsQuery = location.pathname

    if(query.startsWith('#') || query.startsWith('@')) {
        goTo(query, dispatch)()
    }

    return (
            <Fragment>
                <SearchLeftDrawerNew query={query} paramsQuery={paramsQuery}/>
                {children}
            </Fragment>
        )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    withErrorCatcher(),
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps)
)(BaseSearchPage);