import { Icon, ListItemIcon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useMemo } from 'react';
import {isExistsTuqqiIcon, getDefaultTuqqiIconName} from '@tuqqi/common'
import {allFluencyIconsNames, allFluencyIconsData, getDefaultTuqqiIcon} from '@tuqqi/common'
import SVG from 'react-inlinesvg';

export const FeedNavigationItemRow = (props) => {
    const { isSub, primary, secondary, icon, onClick, disabled, onDelete } = props

    const rightIcon = useMemo(() => isExistsTuqqiIcon(icon) ? icon : getDefaultTuqqiIconName(), [icon])
    const pathIcon = useMemo(() => {
      const ind = allFluencyIconsNames.findIndex(x => x === rightIcon)
      if(ind === -1) return getDefaultTuqqiIcon()
      return allFluencyIconsData[ind]
    }, [rightIcon])

    return (
        <ListItem style={isSub ? {paddingLeft: 54} : {}} button onClick={onClick} disabled={disabled}>
            <SVG width={32} height={32} src={pathIcon} />
            <ListItemText primary={primary} secondary={secondary} />
            {onDelete ?
                <ListItemIcon onClick={onDelete}><Icon>delete</Icon></ListItemIcon>
                : null}
        </ListItem>
    )
}
