import React, { Component } from 'react';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import { withTranslation } from 'react-i18next';

const scoreColors = ['rgb(75, 50, 242)', 'rgb(255, 150, 0)', 'rgb(241, 53, 119)']

const styles = theme => ({
    page: {
        margin: '50px 0'
    },
    score: {
        minWidth: 20,
        maxWidth: 20,

        height: 20,
        borderRadius: '50%',
        fontFamily: '"Roboto"',
        fontSize: 10,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 4,
        letterSpacing: 0.2,
        textAlign: 'left',
        color: '#ffffff',


        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifySelf: 'end',
    },
})

class Status extends Component {
    state = {
        title: '',
        link: '',
        image: '',
        description: '',
        plaseIndex: ''
    }

    removePrize = () => {
        const { removePrize, index } = this.props;
        removePrize(index)
    }
    previewPrize() {

    }
    componentDidMount() {
        const { data = {}, index } = this.props;
        let plaseIndex = '';
        switch (index) {
            case 0:
                plaseIndex = 'First'
                break;
            case 1:
                plaseIndex = 'Second'
                break;
            case 2:
                plaseIndex = 'Third'
                break;
            default:
                break;
        }

        this.setState({
            plaseIndex: plaseIndex,
            title: data.title,
            link: data.link,
            image: data.image,
            description: data.description,
        })

    }
    handleChange = (key) => (event) => {
        let value = event.target.value
        this.setState({ [key]: value })
        this.props.handlePrizeChenged(key, value)
    }
    render() {
        const { classes, removePrize, index, scores ,t} = this.props;

        if (scores.length == 0) {
            return <div data-intrcm-id="status_361kqkm0" />
        }
        else {
            return (
                <div data-intrcm-id="status_k20kq59k" className={classes.page}>
                    <Typography variant="body2" gutterBottom>
                        {t('Challenge Scoreboard')}
                                </Typography>

                    <Paper className={classes.paperStyle}>
                        <List className={classes.listStyle}>
                            {
                                scores.filter(user => user.isIncludeInChallenge)
                                   .map((user, idx) => {
                                        return <ListItem button key={idx}>
                                            <Avatar src={user.profileImageUrl} />
                                            <ListItemText
                                                primary={user.firstname + " " + user.lastname}
                                                secondary={user.challengeScore + ' pts.'} />
                                            <ListItemSecondaryAction style={{ paddingRight: 16 }}>
                                                <div data-intrcm-id="status_32svo92s" className={classes.score} style={{ backgroundColor: (scoreColors[idx] || '#c7c7c9') }}>{idx + 1}</div>
                                           </ListItemSecondaryAction>
                                        </ListItem>
                                    })
                            }
                        </List>
                    </Paper>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),
withTranslation('admin'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Status);