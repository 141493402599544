import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import BookmarkTemplateNoImage from './BookmarkTemplateNoImage';
import BookmarkTemplateLeftImage from './BookmarkTemplateLeftImage';
import BookmarkTemplateFull from './BookmarkTemplateFull';





export default class BookmarkTemplate extends PureComponent {
    state = {
        imageLoaded: false,
        imageWidth: 0,
        imageHeight: 0,
        link:''
    }

    getMeta(url) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    }

    onImageLoaded = (image) => {
        
    }

    // source code from https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
    extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
    }

    componentDidMount() {

        const link = this.extractHostname(this.props.result.link)
        this.setState({ link })
        this.getMeta(this.props.result.previewImgUrl).then((img) => {
            this.setState({ imageLoaded: true, imageWidth: img.width, imageHeight: img.height })
        }, (reject) => { });
    }

    render() {
        const { imageLoaded, link } = this.state;
        const { classes, result, openLink } = this.props;



        if (!imageLoaded) {
            return <BookmarkTemplateNoImage result={result} openLink={openLink} link={link}/>
            // check image size
        } else if (this.state.imageWidth >= 618 && this.state.imageHeight >= 240) {
            return <BookmarkTemplateFull result={result} openLink={openLink} link={link}/>
        } else {
            return <BookmarkTemplateLeftImage result={result} openLink={openLink} link={link}/>
        }
    }
}
