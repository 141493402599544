export default class BillingService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getCurrentSubscription() {
        return fetch(process.env.API_SERVER + '/billing/subscription', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        }).catch(() => null);

    }

    updateUsersCount(usersCount) {
        return fetch(process.env.API_SERVER + '/billing/updateUsers?addedUsersQuantity=' + usersCount, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        });

    }

    updateSubscriptionType(newSubsriptionType, billingInterval) {
        return fetch(process.env.API_SERVER + '/billing/updatePlan?subscriptionType=' + newSubsriptionType, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        });

    }


}