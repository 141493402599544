import { SELECT_DRAWER_NAVIGATION_OPTION, SET_DRAWER_NAVIGATION_MODE, CLEAR_DRAWER_NAVIGATION, SET_DRAWER_NAVIGATION_SUBMENU, CLEAR_DRAWER_NAVIGATION_SUBMENU } from "../actionTypes/drawerNavigationTypes";

export const selectDrawerNavigationOption = (selectedId) => ({
    type: SELECT_DRAWER_NAVIGATION_OPTION,
    selectedId
})

export const setDrawerNavigationMode = (mode) => ({
    type: SET_DRAWER_NAVIGATION_MODE,
    mode
})

export const clearDrawerNavigation = () => ({
    type: CLEAR_DRAWER_NAVIGATION
})

export const clearDrawerNavigationSubMenu = () => ({
    type: CLEAR_DRAWER_NAVIGATION_SUBMENU
})

export const setDrawerNavigationSubMenu = (listOptions, option) => ({
    type: SET_DRAWER_NAVIGATION_SUBMENU,
    listOptions,
    option
})