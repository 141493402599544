import React from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { Grid, withStyles, Card } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";
// Apply some reset
const styles = theme => ({
    paper: {
        color: theme.palette.text.secondary,
        position: 'relative',
        width: '100%',
        overflow: 'visible'
    },
    paper2: {
        // marginTop: 24,
        color: theme.palette.text.secondary,
        position: 'relative',
        //width: '100%',
        //margin: 'auto',
    },
    content: {
        marginBottom: 0,
        marginTop: 0,
    },
    rightHeader: {
        position: 'relative',
        right: 20,
        'padding-bottom': '0px !important',
        'padding-top': '0px !important',
        'padding-left': '9px !important',

    },
    rightHeaderAvatar: {
        position: 'relative',
        right: 20,
    },
    error: {
        margin: '50px 0'
    },
    itemPreview: {
        margin: 'auto',
        width: 'calc(100% - 32px)',
        marginBottom: 24,
        maxWidth: 512

    },
    item: {
        margin: 'auto',
        width: 'calc(100% - 32px)'
    },
    cardNewUI:{
        ...theme.feed.cardNewUI,
        ...theme.feed.previewNewUI,
        overflow: 'hidden'

    }
});

class ItemCardTemplateContainer extends React.PureComponent {

    render() {


        const { classes } = this.props;

        return <Grid container direction='column' wrap='nowrap' className={classnames(classes.paper, classes.cardNewUI)} >
                {this.props.children}
            </Grid>
    }
}

export default compose(withStyles(styles), withErrorCatcher())(ItemCardTemplateContainer)