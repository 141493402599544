import React, { Component, PureComponent, Fragment } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PlaceHolder from "./placeHolder";
import AutosuggestField from '../Common/autosuggestField/autosuggestField';
import urlRegex from 'url-regex';


const styles = theme => ({
    suggestionsContainerOpen: {
        overflow: 'scroll',
        height: 80,
    },
    inputContainer: {
        outline: 'none',
        userSelect: 'text',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    text: {
        fontSize: 14,
        color: '#1d2129',
        lineHeight: 1.34,
        unicodeBidi: 'embed',
    },
    link: {
        color: '#365899',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    placeHolder: {
        fontSize: 'initial',
        display: 'flex',
        color: '#bdbdbd',
        marginLeft: 8,
        alignItems: 'center',
        userSelect: 'none'
    },
});


class TextInput extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            hidePlaceHolder: false,
        }
        this.ref = React.createRef()
    };

    componentDidMount() {
        window.ref = this.ref.current
        const { postText, classes } = this.props
        if (this.ref.current) {
            this.ref.current.innerHTML = postText
        }
        this.el = this.ref.current
    }

    // move the cursor to the end of the text
    placeCaretAtEnd() {

        this.el.focus();
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(this.el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(this.el);
            textRange.collapse(false);
            textRange.select();
        }
    }

    // Mark as link the word in the text
    // words that start with # || @ || http
    // beCarefull because this function will move the cursor to the start of the text 
    markText = () => {

        let s = this.el.innerText.replace(/(?:^|\W)[#|@](\w+)(?!\w)|(https?:\/\/[^\s]+)/g, (a) => {
            return `<span class=${this.props.classes.link}>${a}</span>`
        })

        if (this.el.innerHTML != s) {
            this.el.innerHTML = s
        }
    }


    // Mark words in text and move the cursor to the end of the text
    markTextWithFocus(preValue) {

        // Check if the input is in the end of the text 
        // because if the input is in the middle of the text 
        // the markText() will move the cursor to the start of the text 
        // and placeCaretAtEnd() will move the cursor to the end of the text
        if (this.el.innerText.substr(0, preValue.length) == preValue) {
            this.markText(this.el)
            this.placeCaretAtEnd(this.el)
        }

    }

    onInput = () => {

        const { postText, onChange } = this.props
        const preValue = postText
        const newValue = this.el.innerText

        onChange(newValue)

        this.markTextWithFocus(preValue)
    }


    onBlur = () => {
        this.markText()
    }

    removePlaceHolder = () => {
        if (!this.state.hidePlaceHolder) {
            this.setState({ hidePlaceHolder: true })
            this.el.focus()
        }
    }


    render() {
        const { classes } = this.props
        return (
            <div data-intrcm-id="textInput_spr1ic9y" onClick={this.removePlaceHolder} style={{ height: '100%' }}>

                {((this.el && this.el.innerText) || this.state.hidePlaceHolder) ? null : <PlaceHolder />}

                <div data-intrcm-id="textInput_afoo1dzr" ref={this.ref} onBlur={this.markText} className={[classes.inputContainer, classes.text].join(' ')} onInput={this.onInput} contentEditable='true' style={{ height: '100%' }} />

            </div>)
    }
}

const mapStateToProps = (state) => ({
    postText: state.oldUiInput.document.postText,
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TextInput);

