import { Grid, Input, InputAdornment, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { compose } from 'recompose';
import UserListItem from '../userListItem.js';
import { connect } from 'react-redux'
import withErrorCatcher from '../withErrorCatcher.js';

const styles = (theme) => ({
    container: {
        height: '100%',
        overflow: 'hidden'
    },
    textField: {
        margin: 16
    }
})

class InfinityUsersList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: '',
            searchedUsers: props.users || props.globalUsers,
        }
        this.inputRef = React.createRef()
    }

    searchFocus = () => {
        this.inputRef.current && this.inputRef.current.focus && this.inputRef.current.focus()
    }

    handleToggle = value => () => {
        const { dispatch, checked, onChange, multiSelect } = this.props;

        let newChecked = []

        if (!multiSelect) {
            if (!checked.includes(value)) {
                newChecked.push(value);
            }
        } else {
            const currentIndex = checked.indexOf(value);
            newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        }

        this.searchFocus()
        onChange && onChange(newChecked)
    };

    getFilteredUsers = (search) => {
        const { users, globalUsers } = this.props;

        let filteredUsers = users || globalUsers;


        if (search.length) {
            filteredUsers = filteredUsers.filter(u => {
                const text = `${u.firstname}${u.lastname}${u.title}`

                return this.searchableText(`${u.firstname || ''} ${u.lastname || ''} ${u.title || ''}`, search)

            })
        }

        return filteredUsers
    }

    searchableText(text, search) {
        const parsedText = (text || '').toLowerCase();
        const parsedSearch = (search || '').toLowerCase();

        const include = (parsedSearch.includes(parsedText) || parsedText.includes(parsedSearch))

        return include
    }

    search = (e) => {
        const searchedUsers = this.getFilteredUsers(e.target.value)
        this.setState({ search: e.target.value, searchedUsers })

    }

    Row = (props) => {
        const { index, style } = props;
        const user = this.state.searchedUsers[index];

        return (

            <div data-intrcm-id="InfinityUsersList_uggqtwxv" style={style} key={user ? user.id : index} >
                {this._renderUser(user)}
            </div>
        );
    }

    _renderUser(user) {
        const { checked, renderUser } = this.props;

        let content = null
        if (user) {
            if (renderUser) {
                content = renderUser(user, this.searchFocus)
            } else {
                content = (
                    <UserListItem user={user}
                        onClick={this.handleToggle(user.id)}
                        checked={checked[user.id] || (checked.has && checked.has(user.id))} />
                )
            }
        }

        return content
    }

    static defaultProps = {
        numRowInView: 5,
        rowHeight: 73
    }

    render() {
        const { classes, checked, t, numRowInView, rowHeight } = this.props;
        const { searchedUsers, search } = this.state;


        return (
            <Grid container direction='column' wrap='nowrap' className={classes.container}>
                <Input
                    inputRef={this.inputRef}
                    autoFocus
                    placeholder={t('Search user')}
                    onChange={this.search}
                    value={search}
                    className={classes.textField}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }

                />
                <FixedSizeList
                    extraData={checked}
                    height={numRowInView * rowHeight}
                    itemSize={rowHeight}
                    itemCount={searchedUsers.length}
                    className={classes.cardsContainer}>
                    {this.Row}
                </FixedSizeList>
            </Grid>
        )
    }
}


const mapStateToProps = (state) => ({
    globalUsers: state.globalData.users,
})



export default compose(withStyles(styles),
    withErrorCatcher(),
    withTranslation('usersListTranslate'),
    connect(mapStateToProps))
    (InfinityUsersList);
