import React, { Fragment, PropTypes as T } from 'react'
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles, Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import classnames from "classnames";

import List from "./list";
import ListAdder from "./listAdder";
import { addConfetti } from './confetti.js';

const styles = theme => ({
    wrapper: {
        marginTop: -30,
        paddingTop: 30,
        overflowX: 'visible',
        height: '100%'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 88px)',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    listsWrapper: {
        "display": "inline-flex",
        "flexDirection": "column",
        "height": "100%",
        "userSelect": "none",
        "-webkit-user-select": "none",
        "-webkit-touch-callout": "none"
    },
    confettiZone: {
        width: 'inherit', 
        height: 'inherit', 
        position: 'absolute', 
        left: 0, 
        top: 0, 
        display: 'none'
    }
});
const GREEN_COLOR = 20

export class Board extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startX: null,
            startScrollX: null
        }
        this.ref = React.createRef()
    }
    componentDidMount() {
        const ref = document.getElementById('board-container')
        if (ref) {
            setTimeout(() => {
                ref.scrollTo(-ref.scrollLeft, 0)
            })

        }
    }
    isEmptyArea(target) {
        const { className } = target
        return className === "" || className?.includes?.("ListAdder") || className?.includes?.("ErrorCatcher-container") || className?.includes?.("ErrorCatcher-wrapper")
    }

    handleDragEnd = ({ source, destination, type }) => {
        const { collectionViews} = this.props;
        const kanbanView = collectionViews.find(view => view.collectionViewType === 'Kanban')
        const lanes = !kanbanView ? [] : (kanbanView?.itemLists ?? [])

        // dropped outside the list
        if (!destination) {
            return;
        }
        const { moveList, moveCardBetweenLanes, moveCardInLane } = this.props.func;

        switch (type) {
            case "COLUMN":
                if (source.index != 0 &&
                    destination.index != 0 &&
                    source.index !== destination.index) {
                    moveList({
                        oldListIndex: source.index,
                        newListIndex: destination.index,
                    })
                }
                break;
            case "CARD":
                // todo: check if this bug appear
                if (destination.index == -1) {
                    destination.index = 0
                }
                if (source.droppableId !== destination.droppableId) {
                    moveCardBetweenLanes({
                        sourceListId: source.droppableId - 0,
                        destListId: destination.droppableId - 0,
                        oldCardIndex: source.index,
                        newCardIndex: destination.index,
                    })
                } else if (source.index !== destination.index) {

                    moveCardInLane({
                        listId: source.droppableId - 0,
                        oldCardIndex: source.index,
                        newCardIndex: destination.index,
                    })
                }
                const lane = lanes.find(l => l.id+'' === destination.droppableId)
                if(source.droppableId !== destination.droppableId && !!lane && lane.listColor === GREEN_COLOR) {
                    const element = window.document.getElementById(`list_header_green_id_${destination.droppableId}`)
                    if(!element) return
                    setTimeout(() => addConfetti(element), 0)
                }
                break;
            default:
                break;
        }
    };

    // The following three methods implement dragging of the board by holding down the mouse
    handleMouseDown = ({ target, clientX }) => {
        if (this.isEmptyArea(target)) {
            window.addEventListener("mousemove", this.handleMouseMove);
            window.addEventListener("mouseup", this.handleMouseUp);
            this.setState({
                startX: clientX,
                startScrollX: window.scrollX
            });
        }

    };

    // Go to new scroll position every time the mouse moves while dragging is activated
    handleMouseMove = ({ clientX }) => {
        const { startX, startScrollX } = this.state;
        const scrollX = startScrollX - clientX + startX;
        window.scrollTo(scrollX, 0);
        const windowScrollX = window.scrollX;
        if (scrollX !== windowScrollX) {
            this.setState({
                startX: clientX + windowScrollX - startScrollX
            });

        }
    };

    // Remove drag event listeners
    handleMouseUp = () => {
        if (this.state.startX) {
            window.removeEventListener("mousemove", this.handleMouseMove);
            window.removeEventListener("mouseup", this.handleMouseUp);
            this.setState({ startX: null, startScrollX: null });
        }
    };

    handleWheel = ({ target, deltaY }) => {


        if (this.isEmptyArea(target)) {
            if (Math.sign(deltaY) === 1) {
                window.scrollTo(window.scrollX + 80, 0);
            } else if (Math.sign(deltaY) === -1) {
                window.scrollTo(window.scrollX - 80, 0);
            }
        }


    };

    render() {
        const { lists, func, classes, containerClassName } = this.props;

        return (
            <div data-intrcm-id="board_tgyt6hk8" onMouseDown={this.handleMouseDown} className={classnames(classes.wrapper, containerClassName)}>
                <div data-intrcm-id="board_o7dygol0" className={classnames("lists-wrapper", "kanban-wrapper", classes.listsWrapper)}>
                    <DragDropContext onDragEnd={this.handleDragEnd}  >
                        <Droppable
                            droppableId={'1'}
                            type="COLUMN"
                            direction="horizontal">

                            {provided => (
                                <div data-intrcm-id="board_ufulkbmj" id='board-container' className={classes.container}
                                    ref={provided.innerRef} >

                                    {
                                        lists.map((list, index) => (
                                            <List
                                                index={index}
                                                editTitleList={func.editTitleList}   
                                                editListColor={func.editListColor}                                             
                                                deleteList={func.deleteList}
                                                list={list}
                                                boardId={1}
                                                key={list.id}
                                                loadMore={func.loadMore}
                                                isLoadingMore={func.isLoadingMore}
                                                reloadDataWithoutLoader={func.reloadDataWithoutLoader} />
                                        ))}
                                    {provided.placeholder}
                                    <ListAdder addList={func.addList} />
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <canvas className={classes.confettiZone} id="kanban_green_lane_confetti"></canvas>
                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => ({
    collectionViews: state?.results?.channel?.collectionViews ?? [],
})


export default compose(
    withStyles(styles),
    withErrorCatcher("Failed Loading Kanban Board, Please Contact Tuqqi Support"),
    connect(mapStateToProps),
)(Board);