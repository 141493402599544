import { Grid, Icon, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { allCollectionMembers } from '../../../views/groups/groupUtils';
import Spinner from '../../Common/spinner';
import UserListItem from '../../Common/userListItem';
import InfinityUsersList from '../../Common/Users/InfinityUsersList';
import Label from '../../FeedViewComponents/ItemPreviewComponents/Menu/LabelMenu/Label';

// Apply some reset
const styles = theme => ({
    container: { marginBottom: 8 },
    color: {
        width: 260,
        minHeight: 32,
        borderRadius: 2,
        position: 'relative',
    },
    name: {
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize',
        marginLeft: 14
    },
    icon: {
        color: '#ffffff',
        position: 'absolute',
        right: 4
    },
    edit: { width: 32, height: 32 }
});

class IntegrationRuleTemplate extends Component {
    state = {
        integrations: [],
        integrationUid: '',
        loaded: false
    }
    componentDidMount() {

        const { adminService } = this.props
        adminService.getAllIntegrations().then(integrations => this.setState({ integrations, loaded: true }))


    }

    labels = () => {
        const { collectionLabels } = this.props;
        const { search } = this.state

        let res = []
        if (!search || search.length === 0) {
            res = collectionLabels
        } else {
            res = collectionLabels.filter(label => label.name.toLowerCase().includes(search.toLowerCase()))
        }

        return res
    }

    islabelExistOnItem = (label) => {
        const { labels } = this.state;

        return labels.indexOf(label.labelUid) > -1
    }

    choose = (event) => {
        // this.props.updateData({ labels: [...this.state.labels, label.labelUid] })
        this.props.updateData({ integrationUid: event.target.value })
        this.setState({ integrationUid: event.target.value })
        // this.setState({ labels: [...this.state.labels, label.labelUid] })

    }

  

    render() {
        const { classes, t } = this.props;
        const { integrations, loaded, integrationUid } = this.state;

        if (!loaded) {
            return <Spinner />
        }

        return (
            <div data-intrcm-id="IntegrationRuleTemplate_2m949m0a" >
                <Grid container >
                    <Grid container direction='column' wrap='nowrap'  >
                        <Select
                            value={integrationUid}
                            onChange={this.choose}
                            className={classes.selectPeriod}
                        >
                            {integrations.map((integration) => {
                                return <MenuItem value={integration.uid}>{integration.title}</MenuItem>
                            })}
                            {/* 
                            <MenuItem value={'Months'}>Months</MenuItem>
                            <MenuItem value={'Years'}>Years</MenuItem> */}
                        </Select>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

    collectionUid: state.results.channel.collectionUid,
    collectionLabels: state.userData.collections.find(collection => collection.collectionUid === state.results.channel.collectionUid).collectionLabels,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('labelMenu'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(IntegrationRuleTemplate);
// export default compose(withStyles(styles))(MemberRuleTemplate);
// {this.labels().map((label, idx) => <Grid container alignContent='center' alignItems='center' justify='left' wrap='nowrap' item onClick={() => { !this.islabelExistOnItem(label) ? this.choose(label) : this.removeLabel(label) }}
//                             className={classes.color}
//                             style={{ backgroundColor: label.color /* boxShadow: (key % 2 == 0 ? `-8px 0 ${label.color}` : '')*/ }}>
//                             <Typography variant='caption' className={classes.name}>{label.name}</Typography>
//                             {
//                                 this.islabelExistOnItem(label) ? <Icon className={classes.icon} >check</Icon> : null
//                             }
//                         </Grid>)}