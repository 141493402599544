import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CategoryFeedView from '../../components/App/CategoryFeedView.js';
import ResultsList from '../../components/App/PageComponents/ResultsList.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeedDataItem from '../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem.js';
import FeedInput from '../../components/Input/FeedInput.js';
import FollowingInterestWidget from '../../components/Widgets/RightWidgets/Feed/followingInterestWidget.jsx';
import { filterData, IsQueryExistInCollections, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../search/searchUtils';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { loadGroupInfo } from '../groups/groupUtils.js';
import UserGroupsWidget from '../../components/Widgets/RightWidgets/Feed/UserPofileWidget/UserGroupsWidget.js';
import UserTeamWidget from '../../components/Widgets/RightWidgets/Feed/UserPofileWidget/UserTeamWidget'
import { themeConstants } from '../../../theme.js';



const styles = theme => ({
    feedAndWidgetContainer: {

        marginTop: 16,
        "margin": "0 auto",
        "width": "fit-content"
    },
    container: {
        [theme.breakpoints.down(1680)]: {

            marginRight: 0,
        },
        [theme.breakpoints.up(1680)]: {
            marginRight: themeConstants.navigationDrawerWidth,
        },
        height: '100%',
        padding: '0px 32px',
        marginLeft: themeConstants.navigationDrawerWidth,
    },
    feedContainer: {
        marginRight: 32,
        width: 512,
        flex: '0 0 auto'
    }
});

class GroupFeed extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    renderFeedInput() {
        const { userData, groupInfo } = this.props;

        const isUserMemberInCollection = userData.collections.some(collection => collection.collectionUid === groupInfo.collectionUid)

        return isUserMemberInCollection ? <FeedInput key={-1} onClick={this.onOpenInput} /> : null
    }


    renderItem = (idx, result) => {
        return (
            <FeedDataItem
                key={result.Key}
                docId={result.Key}
                result={result.Value.Source}
            />
        )
    }

    componentDidMount() {
        this.loadData(this.state.dataReloadInidicator, this.props)
    }


    render() {
        // const { classes, searchQuery, filters, userData, groupInfo } = this.props;
        // const { results, isLoading, isLoadingMore, hasMore, relatedTags } = this.state;

        // if (isLoading) {
        //     return <Spinner isAbsolutePosition />
        // }
        const { //classes, searchQuery, filters, userData, channel, inputMappings, t, searchResultCount, experts, relatedTags, tagInfo,
            results, isLoading, isLoadingMore, hasMore } = this.state;
        
        const resultsProps = {
            results,
            isLoading,
            isLoadingMore,
            hasMore,
            loadMore: this.loadMoreQuery,
            isSearch: true,
            Header: <FeedInput key={-1} />,
            isUniqeSearch: true,
            showInput: true,
            renderItem: this.renderItem,
        }

        return <CategoryFeedView
            rightWidgets={[ <UserTeamWidget isMyUser userProfile={this.props.profileData}/>, <UserGroupsWidget isMyUser userProfile={this.props.profileData}/>, <FollowingInterestWidget/>]}
        >
           {!isLoading ? 
                <ResultsList {...resultsProps} /> :
                <CircularProgress style={{ position: 'relative', left: '50%', right: '50%', top: '27%' }} />
            }

        </CategoryFeedView>
    }

}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    profileData: state.userData.profileData,
    ...state.services,
    oldUiData: state.oldUiData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupFeed);