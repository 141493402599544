import React, { useEffect, useMemo, useRef, useState } from 'react' 
import { Menu, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { getCloseBracket, getNextView, getOpenBracket, KEYS, mapNumberFields, operators, prepareBeforeStartOperations, validateFormula, VIEWS } from './helpers'
import GeneralButton from '../../../../../components/Common/AllButton/AllButton';
import { hexToRGB } from '@tuqqi/common';
import { ChipOp } from './Chip';
import { OuterOperations } from './outerChangeOperations';

const styles = (theme) => ({
    label: {
        marginTop: 16,
        color: '#979AAA',
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Rubik',
        lineHeight: 1,
    },
    redLabel: {
        color: '#F44336'
    },
    row: {
        maxWidth: 630,
        marginTop: -4,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    selectorContainer: {
        marginTop: 8,
        paddingRight: 10,
        paddingLeft: 10,
        marginRight: 0,
        marginLeft: 0,
        borderRadius: 4,
        boxShadow: 'none',
        width: 'fit-content',
        height: 26,
        backgroundColor: '#fff',
        color: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        '&:hover': {
            boxShadow: 'none',
            color: theme.customColors.primary.main,
            background: hexToRGB(theme.customColors.primary.main, 0.16),
        },
    },
    errorSelector: {
        color: `#F44336!important`,
        border: `solid 1px #F44336!important`,
        '&:hover': {
            background: `${hexToRGB('#F44336', 0.16)}!important`,
            color: `#F44336!important`,
        },
    },
    selectorText: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: '400',
        letterSpacing: 0.4,
    },
    menu: {
        minWidth: 137,
        background: '#FFFFFF!important',
        border: '1px solid #D7D7DB',
        boxShadow: '0px 6px 8px rgba(11, 12, 12, 0.32)',
        borderRadius: 4,
        outline: 'none!important',
    },
    contentMenu: {
        outline: 'none!important',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 16,
    },
    menuContentLabel: {
        fontSize: 11,
        marginBottom: 8,
        lineHeight: '16px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.4,
        color: '#737373',
    },
    rowField: {
        cursor: 'pointer',
        marginBottom: 8,
        display: 'block'
    },
    rowFieldText: {
        fontSize: 14,
        height: 20,
        lineHeight: '20px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.24,
        color: '#0C0C0D',
    },
    input: {
        height: 28,
    },
    list: {
        outline: 'none!important',
    },
    inputText: {
        height: 28,
        paddingRight: 8,
        paddingLeft: 8,
        border: 'none',
        background: '#EDEDF0',
        borderRadius: 4,
        outline: 'none',
        '&::placeholder': {
            color: '#B1B1B3',
        }
    }
})

const __BuildFormula = (props) => {
    const {classes, options, isNewField = false, numberFields = []} = props
    const [anchor, setAnchor] = useState(null)
    const [constValue, setConstValue] = useState('')
    const selectRef = useRef(null)

    const isEditable = useMemo(() => !!isNewField || !isNewField, [isNewField])
    const otherFields = useMemo(() => mapNumberFields(numberFields), [numberFields])

    const [operations, setOperations] = useState(prepareBeforeStartOperations(options))
    const [currentStep, setCurrentStep] = useState(!!operations.length ? operations.length - 1 : -1)

    useEffect(() => {
        OuterOperations.onChange(operations)
        if(!operations.length || !selectRef.current) return
        const valid = validateFormula(operations)
        if(!!valid) return

        selectRef.current.click()
    }, [operations])

    const [nextView] = useMemo(() => {
        const sorted = operations.sort((a, b) => a.order - b.order)
        const key = sorted.slice(-1)?.[0]?.key ?? KEYS.operator
        const value = sorted.slice(-1)?.[0]?.value ?? ''

        return getNextView(currentStep, key, value)
    }, [operations, currentStep])

    const isError = useMemo(() => {
        return !validateFormula(operations)
    }, [operations])

    const openSelector = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setAnchor(e.currentTarget)
    }

    const closeSelector = () => {
        setAnchor(null)
        if(!constValue) return
        onSelectParam({fieldName: constValue, sourceField: constValue}, true, true)
        setConstValue('')
    }

    const onClearOperation = (order) => {
        setOperations(prev => prev.filter(x => x.order !== order))
        setCurrentStep(prev => prev - 1)
    }

    const onSelectParam = (f, denyClose = false, isConst = false) => {
        const sorted = (operations ?? []).sort((a, b) => a.order - b.order)
        const prevOrder = !sorted.length ? -1 : sorted.slice(-1)[0].order ?? 0
        const newOp = {
            label: f.fieldName,
            order: prevOrder + 1,
            key: isConst ? KEYS.parametr_constant : KEYS.parametr,
            value: f.sourceField
        }
        const x = [...operations, newOp].sort((a, b) => a.order - b.order)
        setOperations(x)
        setCurrentStep(prev => prev + 1)
        {!denyClose && closeSelector()}
    }

    const onSelectSymbol = (label, key) => {
        const sorted = (operations ?? []).sort((a, b) => a.order - b.order)
        const prevOrder = !sorted.length ? -1 : sorted.slice(-1)[0].order ?? 0
        const newOp = {
            label,
            order: prevOrder + 1,
            key,
            value: label
        }
        const x = [...operations, newOp].sort((a, b) => a.order - b.order)
        setOperations(x)
        setCurrentStep(prev => prev + 1)
        closeSelector()
    }

    const renderRow = (label, onClick) => {
        return <div data-intrcm-id="BuildFormula_9zhuzp3j" className={classes.rowField} onClick={onClick}>
            <div data-intrcm-id="BuildFormula_a01mkdm4" className={classes.rowFieldText}>{label}</div>
        </div>
    }

    const onChangeConst = (e) => {
        const val = e?.target?.value ?? ''
        setConstValue(val.replace(/[^\d.]/ig, ""));
    }

    const onKeyDown = (e) => {    
        if (e.key !== 'Enter') return;
        e.preventDefault()
        e.stopPropagation()
        if(!constValue) return
        onSelectParam({fieldName: constValue, sourceField: constValue}, true, true)
        setAnchor(null)
        setConstValue('')
    }

    const renderConstInput = () => {
        return <div data-intrcm-id="BuildFormula_b5qkw6zm" className={`${classes.rowField} ${classes.input}`}>
            <input onKeyDown={onKeyDown} onChange={onChangeConst} type="text" className={`${classes.rowFieldText} ${classes.inputText}`} value={constValue} placeholder={"Type a const value"} />
        </div>
    }

    const renderSelectorOperators = () => {
        const closeBracket = getCloseBracket(operations, currentStep)

        return <div data-intrcm-id="BuildFormula_qak2y7bt" className={classes.contentMenu}>
            <div data-intrcm-id="BuildFormula_940o8dtk" className={classes.menuContentLabel}>Operator</div>
            {operators.map((op, i) => <React.Fragment key={i}>{renderRow(op, () => onSelectSymbol(op, KEYS.operator))}</React.Fragment>)}
            {!!closeBracket && <>
                <div data-intrcm-id="BuildFormula_u1i7exrv" className={classes.menuContentLabel}>Bracket</div>
                {renderRow(closeBracket, () => onSelectSymbol(closeBracket, KEYS.bracket))}
            </>}
        </div>
    }
    const renderSelectorParamsBracket = () => {
        const openBracket = getOpenBracket(operations)
        const closeBracket = getCloseBracket(operations, currentStep)

        return <div data-intrcm-id="BuildFormula_70lydiqf" className={classes.contentMenu}>
            <div data-intrcm-id="BuildFormula_zft93z58" className={classes.menuContentLabel}>Parameter</div>
            {otherFields.map((f, i) => <React.Fragment key={i}>{renderRow(f.fieldName, () => onSelectParam(f))}</React.Fragment>)}
            {renderConstInput()}
            <div data-intrcm-id="BuildFormula_jco901fi" className={classes.menuContentLabel}>Bracket</div>
            {!!openBracket && renderRow(openBracket, () => onSelectSymbol(openBracket, KEYS.bracket))}
            {!!closeBracket && renderRow(closeBracket, () => onSelectSymbol(closeBracket, KEYS.bracket))}
        </div>
    }
    const renderSelectorContent = () => {
        if(nextView === VIEWS.view_operators) return renderSelectorOperators() 
        return renderSelectorParamsBracket()
    }

    const Chips = operations.map((o, i) => <ChipOp key={o.order + i} isLast={isEditable && o.order === operations.length - 1} index={o.order} label={o.label} onClear={onClearOperation} />)

    const MenuInner = !!anchor && <Menu
        anchorOrigin={{ vertical: "center", horizontal: 'left' }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
        classes={{ paper: classes.menu }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={closeSelector}
        MenuListProps={{classes: {padding: classes.list, root: classes.list}}}
        elevation={0} >
        {renderSelectorContent()}
    </Menu>

    const Selector =  <>
        <GeneralButton refBtn={selectRef} disabled={!isEditable} onClick={openSelector} containerStyle={`${classes.selectorContainer} ${isError ? classes.errorSelector : ''}`} textStyle={classes.selectorText} title={`Select`} />
        {MenuInner}
    </>
    return <>
        <div data-intrcm-id="BuildFormula_clcgi47o" className={`${classes.label} ${isError ? classes.redLabel : ''}`}>Build your formula *</div>
        <div data-intrcm-id="BuildFormula_rflvffv3" className={classes.row}>
            {Chips}
            {Selector}
        </div>
    </>
}

export const BuildFormula = compose(
    withStyles(styles, {withTheme: true})
)(__BuildFormula)