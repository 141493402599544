import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import CustomInputTemplateNoImage from './CustomInputTemplateNoImage';
import CustomInputTemplateLeftImage from './CustomInputTemplateLeftImage';
import CustomInputTemplateTopImage from './CustomInputTemplateTopImage';

import { mapToTemplate } from '../../../../Mappings/resultMappings';
import { connect } from 'react-redux';
import { compose } from 'recompose'

import parseImgeUrl from '../../../../Common/parseImagUrl';

const styles = theme => ({
    
});

class CustomInputTemplate extends PureComponent {
    state = {
        imageLoaded: false,
        imageWidth: 0,
        imageHeight: 0,
        link:'',
        img: null,
        imageUrl:''
    }

    parseImgeUrl() {
        const { cloudfront, result } = this.props
        const { previewImgUrl } = result;
        
    
        let processedImageUrl = previewImgUrl
        if (previewImgUrl.startsWith("fileId=")) {
    
            const replacedImageUrl = previewImgUrl.replace("fileId=", '')
    
            processedImageUrl = cloudfront.signed.replace('*', replacedImageUrl);
        }
    
        return processedImageUrl
    }

    getMeta = (src) => (
        new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src
        }))

        componentDidMount() {
            const { cloudfront, result } = this.props
            const { previewImgUrl } = result;
    
            if(previewImgUrl){
                const imageUrl = parseImgeUrl(cloudfront, previewImgUrl, 965)
    
                this.setState({ imageLoaded: false, imageUrl })
                this.getMeta(imageUrl).then((img) => {
                    this.setState({ imageLoaded: true, imageWidth: img.width, imageHeight: img.height })
                }, (reject) => { 
                    console.error('error')
                });
            }
            
        }
        
        render() {
            const { classes, result, mappings, openItem } = this.props;
            var mapping = mapToTemplate(result, mappings)

            const { imageWidth, imageHeight, imageLoaded, imageUrl } = this.state
    
            if (!result.previewImgUrl || !imageLoaded) {
                return <CustomInputTemplateNoImage result={result} mappings={mappings} openItem={openItem}/>
            } else if(imageWidth >= 618 && imageHeight >= 240){
                return <CustomInputTemplateTopImage result={result} mappings={mappings} openItem={openItem} imageUrl={imageUrl} />
            } else{
                return <CustomInputTemplateLeftImage result={result} mappings={mappings} openItem={openItem} imageUrl={imageUrl} />
            }
        }
}
const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    mappings: state.globalData.inputMappings,
})

export default 
    connect(
        mapStateToProps,
        null
    )(CustomInputTemplate)
