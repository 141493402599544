import { withStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
})

const textsConfig = {
    'commentStaticText': "New Comment!",
    'addTaskStatic' : "!New Task!",
    'staticNeedToRead' : "You need to read smt",
    'staticPleaseConfirm' : "Please confirm you're read",
    'staticConfirmed' : "I've confirmed",
    'staticConfirmedSecondary' : "!!!Confirmed!!!",
    'moveTextOngoingStatic': 'Ongoing',
    'moveTextDoneStatic':  'Done',
    'staticTextNewItemGroup': 'New Item!'
}

const StaticText = (props) => {
    const {styles, option} = props

    return <div data-intrcm-id="StaticText_ec7me2x6" style={styles.container}>
        <div data-intrcm-id="StaticText_8c2phtwe" style={styles.text}>
            {textsConfig[option]}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StaticText)