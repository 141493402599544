import { Button, FormControlLabel, Icon, ListItemIcon, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionSettings, updateCollection } from '../../../../../actions/collectionActions';
import { defaultDataTypes } from '../../Utils/defaultDataTypes';

const styles = theme => ({
    listLabel: {
        fontSize: '18px',
        marginBottom: 16,
    },
    paperStyle: {
        marginBottom: 40,
    },

    paperInner: {
        padding: 16,
    },

    addButton: {
        marginTop: 16
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    menu: {
        width: 200,
    },
    admin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 52,
        height: 22,
        borderRadius: 4,
        backgroundColor: '#4b32f2',
        border: 'solid 1px #4b32f2',
    },
    adminText: {
        display: 'inline-block',
        fontFamily: '"Roboto"',
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 0.5,
        textAlign: 'left',
        color: '#ffffff',
    },




    radioGroup: {
        paddingLeft: 20,
        paddingTop: 30
    },
    privacyWarning: {
        paddingLeft: 20, 
        paddingTop: 60,
        fontSize: 14
    }
})


class AllowedInputsSettings extends Component {
    state = {
        selectedAll: false,
        allInputs:[]
    }
    
    resolveIfwasDeleted = (inputs) => {
        const allInputs = defaultDataTypes()
            .concat(this.props.inputMappings)
            .map((m) => m.customDataMapping.dataTypeFilter)
        const res = inputs.filter(m => allInputs.includes(m))
        return res
    }
    handleToggle = value => () => {
        const { dispatch, settings } = this.props;
        const { allowedInputs } = settings

        const currentIndex = allowedInputs.indexOf(value);
        const newAllowedInputs= [...allowedInputs];

        if (currentIndex === -1) {
            newAllowedInputs.push(value);
        } else {
            newAllowedInputs.splice(currentIndex, 1);
        }
        const filtered = this.resolveIfwasDeleted(newAllowedInputs)

        dispatch(updateCollectionSettings({ allowedInputs: [...filtered] }))
    };

    selectAll = () => {
        const { dispatch, settings } = this.props;
        const { allowedInputs } = settings

        let newAllowedInputs;

        if (this.state.selectedAll) {
            newAllowedInputs = [];
        } else {
            newAllowedInputs = this.state.allInputs
            .map(mapping => mapping.customDataMapping.dataTypeFilter)
        }

        dispatch(updateCollectionSettings({ allowedInputs: [...newAllowedInputs] }))

        this.setState({
            selectedAll: !this.state.selectedAll,
        })
    }

    componentDidMount(){
        const allInputs = defaultDataTypes().concat(this.props.inputMappings)
        this.setState({ allInputs })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.inputMappings != this.props.inputMappings){
            const allInputs = defaultDataTypes().concat(nextProps.inputMappings)
            this.setState({ allInputs })
        }
    }

    onChange = (event, value) => {
        if(value) {
            this.props.dispatch(updateCollectionSettings({ allowedInputs: [] }))
        }
        // convert the value from string to boolean
        const bValue = (value == (true).toString() || false)
        
        this.props.dispatch(updateCollection({ allInputsAllowed: bValue }))
    }

    render() {
        const { classes, settings, allInputsAllowed, t } = this.props;

        return (
            <Fragment>
                <Typography variant='h6' >{t('Allowed Inputs')}</Typography>
                <RadioGroup
                    className={classes.radioGroup}
                    value={allInputsAllowed}
                    onChange={this.onChange}
                >
                    <FormControlLabel value={true} control={<Radio color="default"/>} label={t('All Inputs Allowed')} />
                    <FormControlLabel value={false} control={<Radio color="default"/>} label={t('Only Specify Inputs Allowed')} />
                </RadioGroup>

                {
                    allInputsAllowed 
                    ? null
                    : <Fragment>
                        <List>
                            {this.state.allInputs.map((input, idx) => (
                                <ListItem
                                    key={idx}
                                    button
                                    className={classes.listItÍem}
                                    onClick={this.handleToggle(input.customDataMapping.dataTypeFilter)}
                                >
                                    <ListItemIcon>
                                        <Icon>
                                            {input.icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={input.title} />
                                    <Checkbox
                                        checked={settings.allowedInputs.indexOf(input.customDataMapping.dataTypeFilter) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItem>
                            ))}
                        </List>

                        <Button onClick={this.selectAll} color="primary">
                            {this.state.selectedAll ? t("Clear All"): t("Select All")}
                        </Button>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    settings: state.collection.settings,
    allInputsAllowed: state.collection.allInputsAllowed,
    inputMappings: state.globalData.inputMappings
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
    withStyles(styles), 
    withTranslation('newGroupDialog'), 
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AllowedInputsSettings);