

import { withStyles } from '@material-ui/core';
import {  getDirectMessages, getDiscussionsRooms } from '@tuqqi/common';
import React, {  useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect} from 'react-redux';
import compose from 'recompose/compose';
import { SERVER_API } from '../../../../utils/serverAPI';
import { getChannelName } from '../../Common/Pusher/pusherUtils';
import withPusher from '../../Common/Pusher/withPusher';
import {useDesktopNotifications} from '../../../../hooks/useDesktopNotifications/useDesktopNotifications';
import { addNotifications } from '../../../../actions/notificationsActions';

// Apply some reset
const styles = theme => ({
    point: {
        boxSizing: 'border-box',
        minWidth: 6,
        height: 6,
        padding: '0px 2px',
        background: '#EF4444',
        borderRadius: 8,
        position: 'absolute',
        border: "0.5px solid #18181B",
        boxShadow: "0px 6px 6px -3px #6B6F7A0F",
    },
    pointText: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '14px',
        color: '#FFFFFF',
    },
});


const MesagePoint = (props) =>  {
    const { 
        dispatch, 
        classes,
        userDataState, 
        directMessages,
        discussionsMessages,
        userData, 
        cloudfront,
        pathname,
        addNotifications,
        isFocusedChatModal,
     } = props;
   
    // const [commentNumToDisplay, setCommentNumToDisplay] = useState(0)
    const receivedRef = useRef([])
    const [t] = useTranslation("chat")
    
    const userId = useMemo(() => {
        return !userData ? '' : userData?.auth0_id ?? ''
    }, [userData])

    const addInAppNotifications = (notif) => {
        addNotifications([notif])
    }

    const [requestNotification, executeNotif] = useDesktopNotifications({t, cloudfront, type: 'chat', userId, receivedRef, dispatch, addInAppNotifications})

    const newDirectMessages = useMemo(() => {
        return directMessages
            .reduce((accum, m) => {
                let cc = m?.item?.newComments ?? 0
                if(!!m?.item?.lastUserId && !!userId && m.item.lastUserId === userId) cc = 0

                return cc + accum
            }, 0)
    }, [directMessages, userId])

    const newDiscussionsMessages = useMemo(() => {
        return discussionsMessages
            .reduce((accum, m) => {
                let cc = m?.item?.newComments ?? 0
                if(!!m?.item?.lastUserId && !!userId && m.item.lastUserId === userId) cc = 0

                return cc + accum
            }, 0)
    }, [discussionsMessages, userId])

    const newComments = useMemo(() => {
        const sum = newDirectMessages + newDiscussionsMessages
        if(!sum) return 0
        return sum
    }, [newDirectMessages, newDiscussionsMessages])

    const    handleChatPusher =  useCallback((comment) => {
        const allowPush = executeNotif(comment)
        if(!allowPush) return

        dispatch(getDiscussionsRooms(SERVER_API)(0))
        dispatch(getDirectMessages(SERVER_API)(0, {includeGroups: 1}))
    },[userId])

   const  initChatChannel = () => {
        const channelName = getChannelName(userDataState.profileData.auth0_id);
        const eventName = 'chat';

        requestNotification()
        props.initChannel(channelName, eventName, handleChatPusher);
    }

    useEffect(() => {
        if(!!isFocusedChatModal || !!pathname?.includes?.('/chat')) return
        initChatChannel()
    }, [pathname, isFocusedChatModal])

   const destroyChatChannel = () => {
        const channelName = getChannelName(userDataState.profileData.auth0_id);

        props.unbindChannel(channelName);
    }

    useEffect(() => {
        initChatChannel()
        return () => {
            destroyChatChannel()
        }
    }, [])

    useEffect(() => {
        dispatch(getDiscussionsRooms(SERVER_API)(0))
        dispatch(getDirectMessages(SERVER_API)(0))
    }, [])
    

    // useEffect(() => {
    //     setCommentNumToDisplay(newComments)
    // }, [newComments])

    
        return <>
        {!!newComments ? <div data-intrcm-id="MesagePoint_m460owb3" className={classes.point}>
            {/* <div data-intrcm-id="MesagePoint_36f6cn3n" className={classes.pointText}>{commentNumToDisplay}</div> */}
        </div> : null }
        </>
}



const mapStateToProps = (state) => ({
    pathname: state.wrapper.pathname,
    isFocusedChatModal: state.chatsModal.isFocused,
    cloudfront: state.data.globalData.cloudfront,
    notifications: state.userData.notifications,
    directMessages: state.data.chatData.chatFeed?.directMessages?.messages ?? [],
    discussionsMessages: state.data.chatData.chatFeed?.documentsComments?.messages ?? [],
    isLoadingDM: state.data.chatData.chatFeed?.directMessages?.isFetching ?? false,
    isLoadingDiscussions: state.data.chatData.chatFeed?.documentsComments?.isFetching ?? false,
    userData: state.data.userData.userDetails.data,
    userDataState: state.userData,
})
const mapDispatchToProps = (dispatch) => ({ 
    dispatch,
    addNotifications: (notifications) => addNotifications(notifications).map(dispatch)
 })

export default compose(withStyles(styles, { withTheme: true }), withPusher,
    connect(mapStateToProps, mapDispatchToProps))(MesagePoint);