import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import classnames from "classnames";
import QuestionMark from './QuestionMark';
import ImgFab from './ImgFab';

const styles = theme => ({
    fullViewMode: {

    },
    preViewMode: {
        maxHeight: 56,
    },

    question: {
        maxHeight: 56,

        minHeight: 56,
        overflowY: 'hidden',
        margin: '25px 0 36px 0',
        "width": "430px",
        "fontFamily": "Roboto",
        "fontSize": "24px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "28px",
        "letterSpacing": "0.2px",
        "textAlign": "center",
        "color": "#ffffff"
    }
});

class QuestionText extends PureComponent {

    render() {

        const { classes, text, isFullView } = this.props;
        return <Grid item dir="auto" className={`question-text ${classnames([classes.question, !isFullView ? classes.fullViewMode : classes.preViewMode])}`}>{text}</Grid>
    }
}

const mapStateToProps = (state) => ({
    isFullView: state.fullView.open
})

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        null
    ))(QuestionText);