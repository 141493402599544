import React from 'react';
import { withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    formControl: {
        margin: '0 5px',
        marginBottom: 5,
        display: 'flex'
    },
    selectMenu: { display: 'flex', alignItems: 'center' },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto'
    },
})

class _SelectMenuItemCategory extends React.Component {
    _onChange = (e) => {
        e.persist()
        const depvalue = e.nativeEvent.target.getAttribute("data-depcode");
        this.props.onChange(depvalue)
    }

    getOptions = () => {
        const {categoriesItems,t} = this.props
        return [
            {key: 0, title: t('Main')},
            ...categoriesItems.map(i => ({key: i.id, title: i.customTitle}))
        ]
    }

    getOption = (categoryId) => {
        return this.getOptions().find(val => val.key == categoryId)
    }

    getOptionValue = () => {
        const {categoryId,t} = this.props
        return this.getOption(categoryId)?.title ?? t('Main')
    }

    render() {
        const { classes,t } = this.props;
        
        return <FormControl className={classes.formControl}>
            <InputLabel htmlFor="icon">{t('Category')}</InputLabel>
            <Select
                classes={{ selectMenu: classes.selectMenu }}
                value={this.getOptionValue()}
                onChange={this._onChange}
                inputProps={{ name: t('category'), id: 'category', }}
            >
                {this.getOptions().map((option) => (
                    <MenuItem data-depcode={option.key} key={option.key} value={option.title}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }
}

const mapStateToProps = (state) => ({
    categoriesItems: state.feedNavigation.orgMenuItems.filter(i => i.type == 0),
})

export const SelectMenuItemCategory = compose(
    withStyles(styles),
    withTranslation('admin'),
    connect(mapStateToProps),
)(_SelectMenuItemCategory);