import { FEED_NEW_ITEMS } from '../../../actionTypes/actionTypes';
import { dispatchDataToNewUIStore } from '../../components/Common/Utils/newUIMigrationUtil';
import mixpanel from 'mixpanel-browser'

export let loadData = function (dataReloadInidicator) {
    const { dispatch, queryService } = this.props;
    if (!this.state.isLoading) {
        this.setState({ isLoading: true });
    }

    Promise.all([
        queryService.getUpToDate(),
        queryService.getFeed(0)
    ]).then((allResults) => {
        const upToDate = allResults[0]
        const results = allResults[1];

        let filteredResultsForDispatch = []
        const openItemId = this.props?.routerLocation?.query?.openItemId

        if(!!openItemId) {
            filteredResultsForDispatch = !!openItemId && results?.length ? results.filter(documentResponse => documentResponse.Key !== openItemId) : results
        } else filteredResultsForDispatch = results

        setFirstOfDataType.call(this, results, ['contact', 'company'], 'phonebookFirstItem')

        dispatchDataToNewUIStore(filteredResultsForDispatch, dispatch)

        this.setState({ dataReloadInidicator: dataReloadInidicator, results: results, upToDate: upToDate.data, isLoading: false });

        dispatch({ type: 'DATA_UPDATED' });
        dispatch({ type: FEED_NEW_ITEMS, state: false});

    })
}

function setFirstOfDataType(results, dataTypes, widgetNameInState) {
    // if the widgetNameInState was assigned to the state don't check
    // because we need only the first item from the dataType
    if (this.state[widgetNameInState]) {
        return
    }

    const firstItemOfDataType = results.find(result => dataTypes.includes(result.Value.Source.dataType))

    if (firstItemOfDataType) {
        this.setState({ [widgetNameInState]: firstItemOfDataType.Key })
    }
}

export let loadMore = function (param) {

    const { dispatch, queryService } = this.props;
    const { feedPage } = this.state;

    if (!this.state.isLoadingMore) {

        let nextPage = feedPage + 1;
        this.setState({ isLoadingMore: true });
        if (window.location.hostname === 'app.tuqqi.com'
            || window.location.hostname === 'desktop.tuqqi.com') {

            mixpanel.track('Loading More in Feed (Web)', {})
        }

        queryService
            .getFeed(nextPage)
            .then((result) => {
                var { hasMore, hadEmptyResult } = this.state;


                if (result.length === 0 && hadEmptyResult) {
                    hasMore = false;
                }
                if (result.length === 0) {
                    hadEmptyResult = true
                } else {
                    hadEmptyResult = false
                }
                const contactData = this.state.results.concat(result)
                dispatchDataToNewUIStore(contactData, dispatch)

                setFirstOfDataType.call(this, contactData, ['contact', 'company'], 'phonebookFirstItem')
                this.setState({ feedPage: nextPage, results: contactData, isLoadingMore: false, hasMore: hasMore, hadEmptyResult: hadEmptyResult });
                dispatch({ type: 'DATA_UPDATED' });

            })
    }

}