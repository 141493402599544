import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer.js';
import withPusher from '../../../components/Common/Pusher/withPusher.js';
import { GroupHome, NewUiWrapper, managerGroupHome, managerGroupTable } from '@tuqqi/web';
import {store} from '../../../../index'
import { themeConstants } from '../../../../theme.js';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import goTo from '../../../actions/goTo.js';
import { getAllCollection } from '../groupUtils.js';
import goToCollection from '../../../actions/goToCollection.js';
import { closeFullview } from '@tuqqi/web/dist/actions/fullview-actions/fullviewActions.js';
import { goToEditProfile } from '../../../actions/goToEditProfile.js';
import { push } from 'react-router-redux';

const styles = theme => ({
    home: {
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
    home1: {
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
})

export class GroupHomeView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerGroupHome.fetch(this.props.groupUid, this.props.collectionUid)

    }

    componentDidUpdate(prevProps) {
        if(this.props.collectionUid === prevProps.collectionUid && this.props.groupUid === prevProps.groupUid) return

        if(!this.props.collectionUid || !this.props.groupUid) return

        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerGroupHome.fetch(this.props.groupUid, this.props.collectionUid)
    }

    onUserTagClick = (userTag) => {
        const { dispatch } = this.props

        goTo(userTag, dispatch)()
    }

    markAsReadNotification = (notification) => {
        const { id } = notification

        this.props.notificationService.sawNotification(id, 'web')

        this.props.dispatch({
            type: 'USER_SEEN_NOTIFICATION',
            id,
            seenOnDateTime: new Date().toISOString()
        })   
    }

    onUserClick = (userUid) => {
        const { users, dispatch, fullview } = this.props

        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }

        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
        fullview.open && dispatch(closeFullview()) 
    }
    _goToCollection = (groupUid) => {
        const { collections, openCollections, dispatch } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid === groupUid)
        if (!collection) {
            return
        }

        goToCollection('/' + collection.title, dispatch, collection.collectionUid, groupUid, collection)();

        dispatch(closeFullview())
    }

    onUserEditProfile = () => {
        const { dispatch, fullview } = this.props
        fullview.open && dispatch(closeFullview())

        goToEditProfile(dispatch)
    }

    onUserMessage = (auth0_id) => {
        if(!auth0_id) return
        const { dispatch, fullview, chatService } = this.props
        fullview.open && dispatch(closeFullview())

        chatService.openNewChat(auth0_id).then(res => {
          const { chat } = res
          if (!chat || !chat.chatUId) {
            dispatch(push({ pathname: "chat" }))
            return
          }
          dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: res
          })
          dispatch(push(`/chat?chatid=${chat.chatUId}`))
        })
    }

    render() {
        const { classes, isDrawerOpen,groupUid, collectionUid } = this.props;

        const callbacks = {
            onUserTagClick: this.onUserTagClick,
            markAsReadNotification: this.markAsReadNotification,
            onUserClick: this.onUserClick,
            onGroupClick: this._goToCollection,
            onUserEditProfile: this.onUserEditProfile,
            onUserMessage: this.onUserMessage,
        }

        const show = groupUid && collectionUid
     
        return <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="groupHomeView_sm1btwxy" className={isDrawerOpen ? classes.home : classes.home1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            {show && <GroupHome drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth : themeConstants.navigationDrawerSmallWidth} groupUid={groupUid} />}
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    notificationService: state.services.notificationService,
    chatService: state.services.chatService,
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    search: state.search,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    fullview: state.fullview,
    users: state.globalData.users,
    openCollections: state.globalData.openCollections,
    collections: state.userData.collections,
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Home, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupHomeView);