export default class QueryService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    // Custom input mappings
    getAll() {
        return fetch(process.env.API_SERVER + '/scoreboard/all',
            {
                method: 'GET',
                headers: this.getHeader()
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }
    // Custom input mappings
    getCurrntChallengeStatus() {
        return fetch(process.env.API_SERVER + '/scoreboard/CurrentChallengeStatus',
            {
                method: 'GET',
                headers: this.getHeader()
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }
}