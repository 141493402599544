import React, { useRef, useEffect, useState } from "react";
import {  withStyles  } from '@material-ui/core'
import { compose } from "recompose";
import { TooltipContent } from "./TooltipContent";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    tooltipDetailsContainer: {
        position: 'absolute',
        display: 'flex',
        flexShrink: '0',
        pointerEvents: 'none',
        width: 234,
        borderRadius: 4,
        backgroundColor: '#5B5E6C',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
        userSelect: 'none',
      },
    tooltipContainer: { 
        padding: 8,
        marginBottom: 6,
    },
    tooltipTitle: {
        fontSize: 14,
        color: '#F3F6FB',
    },
    tooltiParagraph: {
        fontSize: 12,
        color: '#F3F6FB',
    },
})


const Tooltip = ({
  task,
  rowHeight,
  rtl,
  svgContainerHeight,
  svgContainerWidth,
  scrollX,
  scrollY,
  arrowIndent,
  fontSize,
  fontFamily,
  headerHeight,
  taskListWidth,
  classes,
  t,
}) => {
  const tooltipRef = useRef(null);
  const [relatedY, setRelatedY] = useState(0);
  const [relatedX, setRelatedX] = useState(0);
  
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
      const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

      let newRelatedY = task.index * rowHeight - scrollY + headerHeight;
      let newRelatedX;
      if (rtl) {
        newRelatedX = task.x1 - arrowIndent * 1.5 - tooltipWidth - scrollX;
        if (newRelatedX < 0) {
          newRelatedX = task.x2 + arrowIndent * 1.5 - scrollX;
        }
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        if (tooltipLeftmostPoint > svgContainerWidth) {
          newRelatedX = svgContainerWidth - tooltipWidth;
          newRelatedY += rowHeight;
        }
      } else {
        newRelatedX = task.x2 + arrowIndent * 1.5 + taskListWidth - scrollX;
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        const fullChartWidth = taskListWidth + svgContainerWidth;
        if (tooltipLeftmostPoint > fullChartWidth) {
          newRelatedX =
            task.x1 +
            taskListWidth -
            arrowIndent * 1.5 -
            scrollX -
            tooltipWidth;
        }
        if (newRelatedX < taskListWidth) {
          newRelatedX = svgContainerWidth + taskListWidth - tooltipWidth;
          newRelatedY += rowHeight;
        }
      }

      const tooltipLowerPoint = tooltipHeight + newRelatedY - scrollY;
      if (tooltipLowerPoint > svgContainerHeight - scrollY) {
        newRelatedY = svgContainerHeight - tooltipHeight;
      }
      setRelatedY(newRelatedY);
      setRelatedX(newRelatedX);
    }
  }, [
    tooltipRef.current,
    task,
    arrowIndent,
    scrollX,
    scrollY,
    headerHeight,
    taskListWidth,
    rowHeight,
    svgContainerHeight,
    svgContainerWidth,
  ]);

  return (
    <div data-intrcm-id="TaskTooltip_klqvvoom"
      ref={tooltipRef}
      className={
           classes.tooltipDetailsContainer
      }
      style={{ left: relatedX, top: relatedY }}
    >
      <TooltipContent task={task} fontSize={fontSize} classes={classes} fontFamily={fontFamily} t={t} />
    </div>
  );
};

export default compose(withStyles(styles), withTranslation('gantt'))(Tooltip);

