import React, { Component } from 'react'
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles, Button, Icon } from '@material-ui/core'

const styles = (theme) => ({
    confirm: {
        "borderRadius": "4px",
        "boxShadow": "0 2px 4px 0 rgba(148, 148, 148, 0.51)",
        "backgroundColor": theme.customColors.secondary.main,
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.3px",
        "color": "#ffffff",
        marginRight: 15,
        marginTop: 5,
        height: 30,
        padding: 0,
        paddingRight: 16,
        textTransform: "none"
    },
    icon: {
        paddingRight: 8,
        paddingLeft: 16,
        fontSize: 18
    },
    read: {
        "borderRadius": "4px",
        "backgroundColor": "rgba(206, 206, 206, 0.8)",
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.3px",
        "color": "#767676",
        marginRight: 16,
        marginTop: 5,
        height: 30,
        padding: 0,
        paddingRight: 16,
        textTransform: "none"
    }
})

class ConfirmReading extends Component {

    constructor(props) {
        super(props)
        const { mustReadUsersApproved, userData } = this.props;

        if (mustReadUsersApproved.find(x => x === userData.profileData.auth0_id)) {
            this.state = { userRead: true }
        }
        else {
            this.state = { userRead: false, disabled: false }
        }
    }

    confirmReading = () => {
        const { docId, inputService } = this.props
        this.setState({ disabled: true })
        inputService.approvedReading(docId).then(res => {
            this.setState({ userRead: true })
        }).catch(x => {
            this.setState({ disabled: false })
        })
    }

    render() {
        const { classes } = this.props
        const { userRead, disabled } = this.state

        if (userRead) {
            return <Button className={classes.read} disabled>
                <Icon className={classes.icon}>done</Icon>
                Item marked as read
            </Button>
        }
        else {
            return <Button className={classes.confirm} onClick={this.confirmReading} variant="text" disabled={disabled}>
                <Icon className={classes.icon}>error_outline</Icon>
                Confirm Reading
            </Button>
        }
    }
}

const mapStateToProps = state => ({
    ...state.services,
    userData: state.userData
})

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(ConfirmReading)