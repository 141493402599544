import { push } from "react-router-redux"
import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions"
import { navOptions } from "./navigationHelpers"
import {mixpanelTrack} from '../../ui/components/Common/Utils/mixpanelUtils'
import { goToNotifications } from "../../ui/actions/goToNotifications"
import { goToEditProfile, goToMyFavourites } from "../../ui/actions/goToEditProfile"

export const myPageContext = {
    mode: navOptions.MY_PAGE,
    home: {
        id: 0,
        pathname: '/mypage'
    },
    actionCenter: {
        id: 1,
        pathname: '/mypage/actioncenter'
    },
    favourites: {
        id: 2,
        pathname: '/mypage/favorites'
    },
    tasks: {
        id: 3,
        pathname: '/mypage/tasks'
    },
    ongoing: {
        id: 4,
        pathname: '/mypage/ongoing'
    },
    calendar: {
        id: 5,
        pathname: '/mypage/calendar'
    },
    map: {
        id: 6,
        pathname: '/mypage/map'
    },
    dashboard: {
        id: 7,
        pathname: '/mypage/dashboard'
    },
    notifications: {
        id: 8,
        pathname: '/mypage/notifications'
    },
    profile: {
        id: 9,
        pathname: '/mypage/profile'
    },
}
export const getMyPageNavigationOpts = (t, dispatch, settings = undefined) => {
    const getActionCenter = () => {
        return {
            id: myPageContext.actionCenter.id,
            icon: 'Ongoing',
            title: t("Action Center"),
            onClick: () => {
                mixpanelTrack(`My page view ${myPageContext.actionCenter.pathname}`, {})
                dispatch(selectDrawerNavigationOption(myPageContext.actionCenter.id))
                dispatch(push({ pathname: myPageContext.actionCenter.pathname })) 
            }
        }
    }

    let x = [
    {
        id: myPageContext.home.id,
        icon: 'Feed',
        title: t("My Feed"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.home.pathname}`, {})
            dispatch(selectDrawerNavigationOption(myPageContext.home.id))
            dispatch(push({ pathname: myPageContext.home.pathname })) 
        }
    },
    {
        id: myPageContext.calendar.id,
        icon: 'DateRange',
        title: t("My Calendar"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.calendar.pathname}`, {})
            dispatch(selectDrawerNavigationOption(myPageContext.calendar.id))
            dispatch(push({ pathname: myPageContext.calendar.pathname })) 
        }
    },
    {
        id: myPageContext.tasks.id,
        icon: 'List',
        title: t("My Reports"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.tasks.pathname}`, {})
            dispatch(selectDrawerNavigationOption(myPageContext.tasks.id))
            dispatch(push({ pathname: myPageContext.tasks.pathname })) 
        }
    },
    {
        id: myPageContext.ongoing.id,
        icon: 'Ongoing',
        title: t("My Tasks"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.ongoing.pathname}`, {})
            dispatch(selectDrawerNavigationOption(myPageContext.ongoing.id))
            dispatch(push({ pathname: myPageContext.ongoing.pathname })) 
        }
    },
    {
        id: myPageContext.dashboard.id,
        icon: 'Statistics',
        title: t("Statistics"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.dashboard.pathname}`, {})
            dispatch(selectDrawerNavigationOption(myPageContext.dashboard.id))
            dispatch(push({ pathname: myPageContext.dashboard.pathname })) 
        }
    },
    {
        id: myPageContext.notifications.id,
        icon: 'Notifications',
        title: t("Notifications"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.notifications.pathname}`, {})
            goToNotifications(dispatch)
        }
    },
    {
        id: myPageContext.profile.id,
        icon: 'Profile',
        title: t("Profile"),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.profile.pathname}`, {})
            goToEditProfile(dispatch)
        }
    },
    {
        id: myPageContext.favourites.id,
        icon: 'Favorite',
        title: settings?.tDrawerListItemTerms?.("Favorites") ?? t('Favorites'),
        onClick: () => {
            mixpanelTrack(`My page view ${myPageContext.favourites.pathname}`, {})
            goToMyFavourites(dispatch)
        }
    },
]

    if(!!settings?.withActionCenter) {
        const res = [...x.slice(0, 3) , getActionCenter(), ...x.slice(3)]
        return res.filter(y => y.id !== myPageContext.ongoing.id && y.id !== myPageContext.tasks.id)
    }
    return x
}

export const getMyDrawerFromPath = (pathname = '') => {
    const mode = myPageContext.mode

    if(pathname.includes(myPageContext.actionCenter.pathname)) return [mode, myPageContext.actionCenter.id]
    if(pathname.includes(myPageContext.favourites.pathname)) return [mode, myPageContext.favourites.id]
    if(pathname.includes(myPageContext.tasks.pathname)) return [mode, myPageContext.tasks.id]
    if(pathname.includes(myPageContext.calendar.pathname)) return [mode, myPageContext.calendar.id]
    if(pathname.includes(myPageContext.map.pathname)) return [mode, myPageContext.map.id]
    if(pathname.includes(myPageContext.ongoing.pathname)) return [mode, myPageContext.ongoing.id]
    if(pathname.includes(myPageContext.dashboard.pathname)) return [mode, myPageContext.dashboard.id]
    if(pathname.includes(myPageContext.notifications.pathname)) return [mode, myPageContext.notifications.id]
    if(pathname.includes(myPageContext.profile.pathname)) return [mode, myPageContext.profile.id]

    return [mode, myPageContext.home.id]
}