import { Tooltip, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import SelectedIcon from '@material-ui/icons/Done';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isDataTypeEnableInPermission } from '../Common/Utils/inputUtils';
import dataTypesDictionary from '../Common/userCompatibleNames/dataTypesDictionary';
import withErrorCatcher from '../Common/withErrorCatcher';

const styles = theme => ({
    selectedIcon: {
        marginBottom: 5
    },
    disable: {
        opacity: 0.5
    }
})

class GroupSelectorMenuItem extends React.Component {

    isDataTypeDisableInPermission = () => {
        const {
            dataType,
            permission,
            disableIfDataTypeIsNotPermitted,
            inputMappings,
            defaultInputTemplates,
            collections
        } = this.props

        if (!disableIfDataTypeIsNotPermitted) {
            return false
        }
        
        const isDataTypeDisable = !isDataTypeEnableInPermission(inputMappings, defaultInputTemplates, collections, dataType, permission)

        return isDataTypeDisable
    }


    render() {
        const {
            classes,
            title,
            isSelected,
            value,
            onClick,
            t,
            forceDisabled,
            dataType,
            inputMappings
        } = this.props

        const isDataTypeDisable = this.isDataTypeDisableInPermission()


        const selectedIcon = isSelected ? <SelectedIcon className={classes.selectedIcon} /> : null

        if (isDataTypeDisable) {
            return (
                <Tooltip
                    title={<span dir='auto'>
                        {t('thisDataTypeIsDisableInPermission',
                            { dataType: dataTypesDictionary(dataType, inputMappings) })}
                    </span>}>
                    <MenuItem
                        value={value}
                        onClick={forceDisabled ? null : onClick}
                        classes={{ root: classes.disable }} >
                        {title}
                        {selectedIcon}
                    </MenuItem>
                </Tooltip>)
        }

        return (
            <MenuItem
                value={value}
                onClick={onClick} >
                {title}
                {selectedIcon}
            </MenuItem>)

    }
}


const mapStateToProps = (state) => ({
    collections: state.userData.collections,
    inputMappings: state.globalData.inputMappings,
    defaultInputTemplates: state.globalData.defaultInputTemplates
})


export default compose(withStyles(styles),
    withTranslation('itemFullViewModal'),
    connect(mapStateToProps),
    withErrorCatcher(),
)(GroupSelectorMenuItem);