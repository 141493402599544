import { Typography, withStyles } from '@material-ui/core';
import React from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';
import { hexToRGB } from '@tuqqi/common';

const styles = theme => {
    return ({

    container: {
        width: 216,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    containerSmall: {
        width: 64,
        marginTop: 12,
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        color: hexToRGB(theme.customColors.primary.main, 0.45),
        width: 88,
        height: 88,
        borderRadius: 160,
        marginBottom: 16,
        objectFit: 'cover'
    },
    imgSmall: {
        color: hexToRGB(theme.customColors.primary.main, 0.45),
        width: 40,
        height: 40,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    title: {
        maxWidth: 200,
        fontFamily: 'Rubik',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.commonBlack,
        display: 'block'
    },
}
)}

const __CreateGroupInfoDrawer = (props) => {
    const { classes, isDrawerOpen } = props
    const [t] = useTranslation('groupDrawerTranslate');
  
    return (
        <div data-intrcm-id="CreateGroupInfoDrawer_sbh6gx1t" className={isDrawerOpen ? classes.container : classes.containerSmall}>
            <AddCircle className={isDrawerOpen ? classes.img : classes.imgSmall} />
            {isDrawerOpen && <>
                <Typography className={classes.title}>{t("New Group")}</Typography>
            </>
            }       
        </div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export const CreateGroupInfoDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading creating Group, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__CreateGroupInfoDrawer);