import React, { Component } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import TopicsSettings from "./topicsSettings"
import UserTagsSettings from './userTagsSettings';
import SponsoredContent from './sponsoredContentSettings';
import OrgLabelsSettings from './OrgLabelsSettings.js';
import { OldPagesWrap } from '../newway/OldPagesWrap.js';
import OrgStatusColorsSettings from './OrgStatusColorsSettings';

// Apply some reset
const styles = theme => ({
    content: {
    },
    paper: {
        padding: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    switchStyle: {
        marginBottom: 16
    },
    submitStyle: {
        marginTop: 32
    },
    userTitle: {
        fontSize: '18px',
        fontWeight: '100'
    },
    useSettingsTitle: {
        fontSize: '36px',
        fontWeight: '100'
    },
    textFiledStyle: {
        fontWeight: '100'
    },
    avatar: {
        width: 80,
        height: 80,
        border: '1px solid #dbdbdb'
    },
    row: {
        display: 'flex',
        justifyContent: 'center'
    },
    listLabel: {
        marginBottom: 10
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class AdminUserTags extends Component {
    render() {

        const { classes } = this.props;
  
        return <OldPagesWrap classNameContent={classes.content} >
                    <SponsoredContent />
                    <OrgStatusColorsSettings />
                    <OrgLabelsSettings />
                    <TopicsSettings />
                    <UserTagsSettings />
        </OldPagesWrap>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AdminUserTags);
