import React, { useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import {managerRowMembers, managerGroupGantt, SelectMembersPopover} from '@tuqqi/web'

const styles = theme => ({
})


const AssignEdit = (props) => {
    const {value} = props
    const [users] = useState(managerRowMembers.getListUsers(managerGroupGantt.getCurrentColUid(), { withTeams: true, ignoreMe: true, groupMembersIfAC: true }))

    const uids = useMemo(() => value.map(x => x.uid), [value])

    const [teams, correctUsers] = useMemo(() => {
        const teams = users
            .filter(x => x.isTeam)
        const members = users
            .filter(x => !x.isTeam)
        return [teams, members]
    }, [users])

    const _onChange = (value) => {
        const list = (value
            .map(x => correctUsers.find(y => y.uid === x))
            .filter(x => !!x))
        props.onChange(list)
    }
  
    const onClose = () => {
        props.onClose()
    }

    return <SelectMembersPopover
        popoverMaxHeight={40 * 8}
        focused={true}
        denyPos={false}
        onClose={onClose}
        refContainer={props.containerRef}
        itemHeight={51}
        value={uids} 
        members={correctUsers}
        teams={teams}
        withTeams
        onChange={_onChange} />
}

export default withStyles(styles, {withTheme: true})(AssignEdit)