import React, { PureComponent } from 'react';

export default class EditIcon extends PureComponent {

    render() {
        const { color, className } = this.props
        return (
            <svg className={className} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="16" width="10" height="1" transform="rotate(-90 2 16)" fill={color}/>
                <rect x="2" y="3" width="3" height="1" transform="rotate(-90 2 3)" fill={color}/>
                <rect x="7" y="16" width="3" height="1" transform="rotate(-90 7 16)" fill={color}/>
                <rect x="7" y="10" width="10" height="1" transform="rotate(-90 7 10)" fill={color}/>
                <rect x="12" y="16" width="8" height="1" transform="rotate(-90 12 16)" fill={color}/>
                <rect x="12" y="5" width="5" height="1" transform="rotate(-90 12 5)" fill={color}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 11.5C10 10.1193 8.88071 9 7.5 9C6.11929 9 5 10.1193 5 11.5C5 12.8807 6.11929 14 7.5 14C8.88071 14 10 12.8807 10 11.5ZM8.75 11.5C8.75 10.8096 8.19036 10.25 7.5 10.25C6.80964 10.25 6.25 10.8096 6.25 11.5C6.25 12.1904 6.80964 12.75 7.5 12.75C8.19036 12.75 8.75 12.1904 8.75 11.5Z" fill={color}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.5C5 3.11929 3.88071 2 2.5 2C1.11929 2 -1.69631e-07 3.11929 -1.09278e-07 4.5C-4.89256e-08 5.88071 1.11929 7 2.5 7C3.88071 7 5 5.88071 5 4.5ZM3.75 4.5C3.75 3.80964 3.19036 3.25 2.5 3.25C1.80964 3.25 1.25 3.80964 1.25 4.5C1.25 5.19036 1.80964 5.75 2.5 5.75C3.19036 5.75 3.75 5.19036 3.75 4.5Z" fill={color}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 6.5C15 5.11929 13.8807 4 12.5 4C11.1193 4 10 5.11929 10 6.5C10 7.88071 11.1193 9 12.5 9C13.8807 9 15 7.88071 15 6.5ZM13.75 6.5C13.75 5.80964 13.1904 5.25 12.5 5.25C11.8096 5.25 11.25 5.80964 11.25 6.5C11.25 7.19036 11.8096 7.75 12.5 7.75C13.1904 7.75 13.75 7.19036 13.75 6.5Z" fill={color}/>
            </svg>
        )
    }
}