import { withStyles } from '@material-ui/core';
import { Menu as MenuWeb, NewUiWrapper } from '@tuqqi/web';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { store } from '../../../..';

const styles = theme => ({
    wrap: {

    }
});

const MenuView = (props) => {
    const callbacks = {
    }


    return <NewUiWrapper store={store} callbacks={callbacks}>
        <MenuWeb />
    </NewUiWrapper>}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return { dispatch }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
))(MenuView);
