import React, { PureComponent } from 'react';

export default class SearchMinimizeIcon extends PureComponent {

    render() {
        return (
            <svg width="32" height="31" viewBox="0 0 32 31" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.005 23.095c.624-1.085 1.093-2.325 1.093-3.72 0-3.875-3.122-6.975-7.025-6.975-3.902 0-7.024 3.1-7.024 6.975 0 3.875 3.122 6.975 7.024 6.975 1.405 0 2.654-.465 3.747-1.085l4.995 4.96L32 28.055l-4.995-4.96zm-5.932.155c-2.185 0-3.902-1.705-3.902-3.875s1.717-3.875 3.902-3.875c2.186 0 3.903 1.705 3.903 3.875s-1.717 3.875-3.903 3.875zM15.61 27.9V31C6.993 31 0 24.056 0 15.5 0 6.944 6.993 0 15.61 0c7.555 0 13.846 5.332 15.297 12.4h-3.23a12.428 12.428 0 0 0-7.384-8.386v.636c0 1.705-1.405 3.1-3.122 3.1h-3.122v3.1a1.56 1.56 0 0 1-1.561 1.55H9.366v3.1h3.122v4.65h-1.561L3.45 12.726a12.498 12.498 0 0 0-.328 2.774c0 6.835 5.604 12.4 12.488 12.4z" fill="#B8BBCC" fill-rule="nonzero"/>
            </svg>
        )
    }
}