import { FULL_RESET_STORE, GROUP_UPDATE } from "../actionTypes/actionTypes";
import {ON_GO_TO_CHAT} from '@tuqqi/web'

const initialState = [];

const group = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
            return initialState
        case GROUP_UPDATE:
            return {
                ...state,
                ...action.group
            }
        default:
        return state
    }
}

export default group