import React from 'react';
import {createCustomTheme} from "./theming";

export default ((props) => {

    const { level, themeOption  } = props;
    const colors = createCustomTheme(themeOption);
    let svg = <div/>;

    switch (level){
        case 1: svg = <g fill="none" fill-rule="nonzero">
                    <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                    <circle cx="12" cy="12" r="9.5" fill="#FFF" stroke="#000"/>
                    <circle cx="12.5" cy="12.5" r="1.5" fill="#000"/>
                </g>
            break;
        case 2: svg = 
                <g fill="none" fill-rule="nonzero">
                    <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                    <circle cx="12" cy="12" r="10" fill={colors.secondary.b100}/>
                    <circle cx="14.5" cy="12.5" r="1.5" fill="#FFF"/>
                    <circle cx="9.5" cy="12.5" r="1.5" fill="#FFF"/>
                </g>
            break;
        case 3: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <circle cx="12" cy="12" r="10" fill={colors.accent}/>
                <circle cx="12" cy="9.143" r="1.429" fill="#FFF"/>
                <circle cx="9.143" cy="14.857" r="1.429" fill="#FFF"/>
                <circle cx="14.857" cy="14.857" r="1.429" fill="#FFF"/>
            </g>
            break;
        case 4: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill="#4CAA51"/>
                    <circle cx="13.031" cy="7.126" r="1.476" fill="#FFF"/>
                    <circle cx="13.031" cy="13.031" r="1.476" fill="#FFF"/>
                    <circle cx="7.126" cy="13.031" r="1.476" fill="#FFF"/>
                    <circle cx="7.126" cy="7.126" r="1.476" fill="#FFF"/>
                </g>
            </g>
            break;
        case 5: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill="#1E88E5"/>
                    <circle cx="12.857" cy="7.143" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="10" r="1.429" fill="#FFF"/>
                    <circle cx="7.143" cy="7.143" r="1.429" fill="#FFF"/>
                    <circle cx="7.143" cy="12.857" r="1.429" fill="#FFF"/>
                    <circle cx="12.857" cy="12.857" r="1.429" fill="#FFF"/>
                </g>
            </g>
            break;
        case 6: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill={colors.primary.b600}/>
                    <circle cx="12.857" cy="10" r="1.429" fill="#FFF"/>
                    <circle cx="12.857" cy="6.19" r="1.429" fill="#FFF"/>
                    <circle cx="7.143" cy="6.19" r="1.429" fill="#FFF"/>
                    <circle cx="7.143" cy="10" r="1.429" fill="#FFF"/>
                    <circle cx="7.143" cy="13.81" r="1.429" fill="#FFF"/>
                    <circle cx="12.857" cy="13.81" r="1.429" fill="#FFF"/>
                </g>
            </g>
            break;
        case 7: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill="#6D4C41"/>
                    <circle cx="11.905" cy="6.19" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="10" r="1.429" fill="#FFF"/>
                    <circle cx="13.81" cy="10" r="1.429" fill="#FFF"/>
                    <circle cx="8.095" cy="13.81" r="1.429" fill="#FFF"/>
                    <circle cx="11.905" cy="13.81" r="1.429" fill="#FFF"/>
                    <circle cx="8.095" cy="6.19" r="1.429" fill="#FFF"/>
                    <circle cx="6.19" cy="10" r="1.429" fill="#FFF"/>
                </g>
            </g>
            break;
        case 8: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill="#E53935"/>
                    <circle cx="13.81" cy="8.095" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="4.286" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="15.714" r="1.429" fill="#FFF"/>
                    <circle cx="6.19" cy="8.095" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="8.095" r="1.429" fill="#FFF"/>
                    <circle cx="6.19" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="13.81" cy="11.905" r="1.429" fill="#FFF"/>
                </g>
            </g>
            break;
        case 9: svg = <g fill="none" fill-rule="nonzero">
                <circle cx="12" cy="12" r="12" fill="url(#a)"/>
                <g transform="translate(2 2)">
                    <circle cx="10" cy="10" r="10" fill="#000"/>
                    <circle cx="11.905" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="8.095" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="10" cy="8.095" r="1.429" fill="#FFF"/>
                    <circle cx="11.905" cy="4.286" r="1.429" fill="#FFF"/>
                    <circle cx="8.095" cy="4.286" r="1.429" fill="#FFF"/>
                    <circle cx="15.714" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="13.81" cy="15.714" r="1.429" fill="#FFF"/>
                    <circle cx="4.286" cy="11.905" r="1.429" fill="#FFF"/>
                    <circle cx="6.19" cy="15.714" r="1.429" fill="#FFF"/>
                </g>
            </g>
            break;
    }


    return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <defs>
        <linearGradient x1="28.5656157%" y1="31.7103795%" x2="67.7687872%" y2="63.852074%" id="a">
            <stop stop-color="#FFFFFF" offset="0%"></stop>
            <stop stop-color="#EDEDED" stop-opacity="0.531874691" offset="20.7389199%"></stop>
            <stop stop-color="#D8D8D8" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        </defs>
        {svg}
    </svg>)
})