import { hideSnackbar, showSnackbar } from "../actions/appActions";
import { getDefaultPostText } from "../ui/components/Input/inputUtils";
import { INPUT_CHANGE, FULLVIEW_POST, FULLVIEW_CLOSE, DATA_UPDATE, INPUT_CLOSE } from "../actionTypes/actionTypes";
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';
import fileTypePdf from '@iconify/icons-vscode-icons/file-type-pdf2';
import fileTypeAi2 from '@iconify/icons-vscode-icons/file-type-ai2';
import fileTypeWord from '@iconify/icons-vscode-icons/file-type-word';
import fileTypeExcel from '@iconify/icons-vscode-icons/file-type-excel';
import fileTypeVideo from '@iconify/icons-vscode-icons/file-type-video';
import fileTypePowerpoint from '@iconify/icons-vscode-icons/file-type-powerpoint';
import fileTypePhotoshop2 from '@iconify/icons-vscode-icons/file-type-photoshop2';
import React from 'react'

export const getFilestackLink = (filestack, link) => `${link}?policy=${filestack.view.policy}&signature=${filestack.view.signature}`

export function getFileLinkFromCloundfront(cloudfront, filename) {
    if (cloudfront && cloudfront.signedFiles && filename) {
        const replacedUrl = filename.replace("fileId=", '')
        return cloudfront.signedFiles.replace('*', encodeURIComponent(replacedUrl))
    }
}

export const downloadFile = (fileLink, filename) => {
    fetch(fileLink).then(res => {
        res.blob().then( blob => {
            const url = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = url;
            tempLink.download = filename;

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking 
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        })
    })
}

const sizeDescription = (fileSize) => {
	if (fileSize > 1048576) {
		return `Size: ${(fileSize / 1048576).toFixed(1)} MB`
	} else if (fileSize > 1024) {
		return `Size: ${(fileSize / 1024).toFixed(1)} KB`
	} else {
		return `Size: ${fileSize} Bytes`
	}
}

const getFileDetails = (file, removeTitleForMedia = true, defaultPostText = '') => {
	const attachment = {
		fileUid: file.key,
		fileName: file.filename,
		fileLink: file.url,
		fileType: file.mimetype,
		fileSize: file.size,
		thumbnailLink: 'https://drive-thirdparty.googleusercontent.com/64/type/' + file.mimetype
	}

  	const isMedia = (file.mimetype.includes('image/') || file.mimetype.includes('video/'))

	const link = {
		url: isMedia ? file.key : 'https://www.filestackapi.com/api/file/' + file.handle,
		title: file.filename,
		description: sizeDescription(file.size),
		image: 'https://drive-thirdparty.googleusercontent.com/64/type/' + file.mimetype
	}

	const item = {
		dataType: isMedia ? 'media' : 'file',
		mimeType: file.mimetype,
        title: isMedia && removeTitleForMedia ? null : link.title,
        
		previewTitle: link.title,
		previewDescription: link.description,
		previewImgUrl: link.image,
		link: file.key,
		hasPreview: true,
		attachments: [attachment],
        hasAttachments: true,
    }
    
    if(defaultPostText){
        item.postText = defaultPostText
    }

    return item
}

const getFilePermission = (collectionResult) => {
  	if(collectionResult && collectionResult.collectionGroup){
		const { groupUid } = collectionResult.collectionGroup;
        
        return {
			isPrivate: false,
			permissions: [groupUid],
			groupUid
		}
    }
      
    return {
        isPrivate: false,
        permissions: [],
        groupUid: ''
    }
}

export const handleFilestackPicker = (fileData, dispatch, collectionResult, searchQuery, inputDocument, inputService, t, input) => {
    
    const defaultPostText = searchQuery ? getDefaultPostText(searchQuery.query) : ''
  
    if (fileData.length === 1) {
        handleFilestackPickerOneFile(fileData[0], collectionResult, dispatch, defaultPostText);
    } else {
        handleFilestackPickerManyFiles(fileData, inputDocument, dispatch, inputService, t, input, collectionResult, defaultPostText);
    }
}

const handleFilestackPickerOneFile = (fileData, collectionResult, dispatch, defaultPostText) => {
    const payload = {
        ...getFileDetails(fileData, true, defaultPostText), 
        ...getFilePermission(collectionResult)}

    dispatch({ type: INPUT_CHANGE, model: payload });
    dispatch({ type: FULLVIEW_POST, openPost: true, item: payload })
}

const handleFilestackPickerManyFiles = (fileData, inputDocument, dispatch, inputService, t, input, collectionResult, defaultPostText) => {
    

    dispatch({ type: FULLVIEW_CLOSE })
    dispatch(showSnackbar(t('Saving'), 15 * 1000));

    const filesDetails = fileData.map(x => {
        return { 
            ...inputDocument, 
            ...getFileDetails(x, false, defaultPostText), 
            ...getFilePermission(collectionResult) 
        }
    })

    Promise.all(filesDetails.map(x => inputService.postItem({ ...input, document: x}))).then(x => {
        dispatch(hideSnackbar())
        dispatch({ type: DATA_UPDATE })
        dispatch({ type: INPUT_CLOSE })
        dispatch(showSnackbar(t('Saved!')));

    }).catch(x => {
        dispatch(hideSnackbar())
        dispatch(showSnackbar(t('Something went wrong')))
    })
}

export const filesFromSources = [
    'local_file_system',
    'url',
    'instagram',
    'facebook',
    'googledrive',
    'dropbox',
    'evernote',
    'flickr',
    'box',
    'github',
    'gmail',
    'picasa',
    'onedrive',
    'onedriveforbusiness',
    'clouddrive'
]

// const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
const FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];
const FORMAT_WORD = ['doc', 'docx'];
const FORMAT_EXCEL = ['xls', 'xlsx'];
const FORMAT_POWERPOINT = ['ppt', 'pptx'];
// const FORMAT_PDF = ['pdf'];
const FORMAT_PHOTOSHOP = ['psd'];
const FORMAT_ILLUSTRATOR = ['ai', 'esp'];
const ICON_SIZE = { width: 28, height: 28 };

export function getFileFullName(fileUrl) {
    return fileUrl.split('/').pop();
  }
export function getFileFormat(file) {
    let format;
  
    switch (file.fileType.includes(file.fileType)) {
      case file.fileType.includes('image'):
        format = 'image';
        break;
      case FORMAT_VIDEO.includes(file.fileType):
        format = 'video';
        break;
      case FORMAT_WORD.includes(file.fileType):
        format = 'word';
        break;
      case FORMAT_EXCEL.includes(file.fileType):
        format = 'excel';
        break;
      case FORMAT_POWERPOINT.includes(file.fileType):
        format = 'powerpoint';
        break;
      case file.fileType.includes('pdf'):
        format = 'pdf';
        break;
      case FORMAT_PHOTOSHOP.includes(file.fileType):
        format = 'photoshop';
        break;
      case FORMAT_ILLUSTRATOR.includes(file.fileType):
        format = 'illustrator';
        break;
      default:
        format = file.fileType;
    }
  
    return format;
  }
  export function getFileThumb(file, parseImageUrl) {
    let thumb;
    switch (getFileFormat(file)) {
      case 'image':
        thumb = <img src={parseImageUrl(file.fileUid)} alt={file.fileUid} />;
        break;
      case 'video':
        thumb = <Icon icon={fileTypeVideo} {...ICON_SIZE} />;
        break;
      case 'word':
        thumb = <Icon icon={fileTypeWord} {...ICON_SIZE} />;
        break;
      case 'excel':
        thumb = <Icon icon={fileTypeExcel} {...ICON_SIZE} />;
        break;
      case 'powerpoint':
        thumb = <Icon icon={fileTypePowerpoint} {...ICON_SIZE} />;
        break;
      case 'pdf':
        thumb = <Icon icon={fileTypePdf} {...ICON_SIZE} />;
        break;
      case 'photoshop':
        thumb = <Icon icon={fileTypePhotoshop2} {...ICON_SIZE} />;
        break;
      case 'illustrator':
        thumb = <Icon icon={fileTypeAi2} {...ICON_SIZE} />;
        break;
      default:
        thumb = <Icon icon={fileFill} {...ICON_SIZE} />;
    }
    return thumb;
  }
  

  export const parseFileType = (fileType) => {
    if(!fileType) return ''
    if(fileType.includes('pdf')) return 'pdf'
    else if(fileType.includes('document')) return 'docx'
    else if(fileType.includes('png')) return 'png'
    else if(fileType.includes('jpg')) return 'jpg'
    else if(fileType.includes('webp')) return 'webp'
    else if(fileType.includes('jpeg')) return 'jpeg'
    else if(fileType.includes('csv')) return 'csv'
    else if(fileType.includes('xslx')) return 'xslx'
    else if(fileType.includes('mp4')) return 'mp4'
    else if(fileType.includes('m4a')) return 'm4a'
    else if(fileType.includes('mp3')) return 'mp3'
    else if(fileType.includes('mp4')) return 'mp4'
    else if(fileType.includes('mov')) return 'mov'
    else if(fileType.includes('webm')) return 'webm'
    else if(fileType.includes('gif')) return 'gif'
    else if(fileType.includes('bmp')) return 'bmp'
    else return fileType
}

export const isPdf = (fileType) => fileType.includes('pdf')

const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp']
const videoTypes = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv']

export const validateUrl = (value) => {
    return (/^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i).test(value);
}
export const isImage = (fileType) => fileType === 'image' || imageTypes.includes(parseFileType(fileType))
export const isVideo = (fileType = '') => fileType?.startsWith?.('video') || videoTypes.includes(parseFileType(fileType))
export const isValidFile = (file) => Object.keys(file).length > 0