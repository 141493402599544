import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    alignContentOptions, alignItemsOptions, alignOptions, colorOptions, directionOptions, justifyOptions, spacingOptions, variantOptions, wrapOptions
} from '../customInputTemplateUtils';


const styles = theme => ({
    root: {
        width: 700,
        borderRadius: 16,
        minHeight: 800
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        paddingBottom: 15,
        fontWeight: 'bold'
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class EditSectionModal extends React.Component {
  	constructor(props, context) {
		super(props, context);
		this.state = {
            title: '',
            sectionIndex: null,
            containerIndex: null,
            titleStyle: { align: 'inherit', color: 'default', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false
            },
            gridStyle: {
                alignContent: 'stretch', alignItems: 'stretch', direction: 'row', justify: 'flex-start', spacing: 0, wrap: 'wrap'
            },
            tab: 'title'
    	}
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.section){
            const { title, titleStyle, gridStyle } = nextProps.section;

            this.setState({ 
                title, 
                titleStyle: titleStyle ? titleStyle : this.state.titleStyle, 
                containerIndex: nextProps.containerIndex,
                sectionIndex: nextProps.sectionIndex,
                gridStyle: gridStyle ? gridStyle : this.state.gridStyle, 
            })
        }
    }

  	handleChange = (name) => (e) => {
        let value = e.target.value;
        this.setState({ [name]: value })
	}

    onAdd = () => {
        const { onClose, section, sectionIndex, containerIndex } = this.props;
        const { title, titleStyle, gridStyle } = this.state;

        onClose({
            section: {
                ...section,
                title,
                titleStyle,
                gridStyle
            },
            containerIdx: containerIndex,
            sectionIdx: sectionIndex
        })
    }

    onDelete = () => {
        const { onDelete, containerIndex, sectionIndex } = this.props;

        onDelete(containerIndex, sectionIndex);
    }

    handleChangeStyle = (isTitle, name, isChecked) => (e) => {
        let value = isChecked ? e.target.checked : e.target.value;
        
        if(isTitle){
            let titleStyle = {...this.state.titleStyle};
            titleStyle[name] = value

            this.setState({ titleStyle })
        }
        else{
            let gridStyle = {...this.state.gridStyle};
            gridStyle[name] = value

            this.setState({ gridStyle })
        }
    }

    renderStyleSelection = (inputLabel, value, fieldName, options, formHelperText, isTitle) => {
        const { classes } = this.props;

        return <FormControl className={classes.formControl} fullWidth >
            <InputLabel >{inputLabel}</InputLabel>
            <Select
                value={value}
                onChange={this.handleChangeStyle(isTitle, fieldName)}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                { options.map((option, idx) => (
                    <MenuItem value={option}>
                        {option}
                    </MenuItem>
                )) }
            </Select>
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderStyleCheckBox = (inputLabel, value, fieldName, formHelperText, isTitle) => {
        const { classes } = this.props;

        return <FormControl className={classes.formControl} fullWidth>
            <FormControlLabel
                control={
                    <Switch checked={value} 
                        onChange={this.handleChangeStyle(isTitle, fieldName, true)} 
                    />}
                label={inputLabel}
            />
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderTitleSettings = () => {
        const { classes } = this.props;
        const { titleStyle, title } = this.state;
        const { align, color, variant, gutterBottom, inline, internalDeprecatedVariant, noWrap, paragraph } = titleStyle;

        return <div data-intrcm-id="editSectionModal_ttm4fqv0">
            <Typography classes={{root: classes.title }}>Title</Typography>

            <TextField label='Title' value={title} fullWidth style={{ marginBottom: 10 }}
                helperText='Title of the container' onChange={this.handleChange('title')} />

            {this.renderStyleSelection('Align', align, 'align', alignOptions, 'Set the text-align on the component.', true)}
            {this.renderStyleSelection('Color', color, 'color', colorOptions, 'The color of the component. It supports those theme colors that make sense for this component.', true)}
            {this.renderStyleSelection('Variant', variant, 'variant', variantOptions, 'Applies the theme typography styles. Use body1 as the default value with the legacy implementation and body2 with the new one.', true)}
            
            {this.renderStyleCheckBox('Gutter Bottom', gutterBottom, 'gutterBottom', 'If true, the text will have a bottom margin.', true)}
            {this.renderStyleCheckBox('Inline', inline, 'inline', 'Controls whether the Typography is inline or not.', true)}
            {this.renderStyleCheckBox('Internal Deprecated Variant', internalDeprecatedVariant, 'internalDeprecatedVariant', 
                <div data-intrcm-id="editSectionModal_hjh4hpaw">
                    <div data-intrcm-id="editSectionModal_c27jw7mf">A deprecated variant is used from an internal component.</div> 
                    <div data-intrcm-id="editSectionModal_3o48k683">Users don't need a deprecation warning here if they switched to the v2 theme.</div> 
                    <div data-intrcm-id="editSectionModal_lp6tgbtu">They already get the mapping that will be applied in the next major release.</div>
                </div>
            , true)}
            {this.renderStyleCheckBox('No Wrap', noWrap, 'noWrap', 'If true, the text will not wrap, but instead will truncate with an ellipsis.', true)}
            {this.renderStyleCheckBox('Paragraph', paragraph, 'paragraph', 'If true, the text will have a bottom margin.', true)}
        </div>
    }

    renderGridSettings = () => {
        const { classes } = this.props;
        const { gridStyle } = this.state;
        const { alignContent, alignItems, direction, justify, spacing, wrap } = gridStyle;

        return <div data-intrcm-id="editSectionModal_i4hdcp7n">
            <Typography classes={{root: classes.title}}>Section Style</Typography>
            {this.renderStyleSelection('Align Content', alignContent, 'alignContent', alignContentOptions, 'Defines the align-content style property. It\'s applied for all screen sizes.', false)}
            {this.renderStyleSelection('Align Items', alignItems, 'alignItems', alignItemsOptions, 'Defines the align-items style property. It\'s applied for all screen sizes.', false)}
            {this.renderStyleSelection('Direction', direction, 'direction', directionOptions, 'Defines the flex-direction style property. It is applied for all screen sizes.', false)}
            {this.renderStyleSelection('Justify', justify, 'justify', justifyOptions, 'Defines the justify-content style property. It is applied for all screen sizes.', false)}
            {this.renderStyleSelection('spacing', spacing, 'spacing', spacingOptions, 'Defines the space between the type item component. It can only be used on a type container component.', false)}
            {this.renderStyleSelection('wrap', wrap, 'wrap', wrapOptions, 'Defines the flex-wrap style property. It\'s applied for all screen sizes.', false)}
        </div>
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ tab: newValue })
    }

 	render() {
        const { open, onClose, classes,t } = this.props;
        const { title, tab } = this.state;

		return <Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            transition={Transition}
			classes={{ paper: classes.root }}
		>
            <DialogContent>
                <AppBar position="static" color="default">
                    <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={this.handleChangeTab} >
                        <Tab label={t("Title")} value='title'/>
                        <Tab label={t("Section")} value='section'/>
                    </Tabs>
                </AppBar>
                {tab === 'title' && this.renderTitleSettings()}
                {tab === 'section' && this.renderGridSettings()}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={this.onDelete} color="primary">
                    {t('Delete')}
                </Button> 
                <Button onClick={this.onAdd} color="primary">
                    {t('Update')}
                </Button>
            </DialogActions>
		</Dialog>
  	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch,
  	}
}

export default compose(withStyles(styles),
    withTranslation("admin"),
  	connect(mapStateToProps, mapDispatchToProps)
)(EditSectionModal);