import { Divider, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component, Fragment, PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Spinner from '../../../Common/spinner';
import NotSelectedUsersList from './NotSelectedUsersList';
import SelectedUsersList from './SelectedUsersList';
import ConfirmedUsersList from './ConfirmedUsersList';
import { loadData, handleToggle, handleSelectAll, handleSave, remindUser, remindAllUsers } from './mustReadUtils';
import { withMixpanel } from '../../../Common/withMixpanel.js';

const styles = theme => ({
    disScrollig: {
        overflow: 'hidden'
    },
    dialogTitle: {
        fontSize: 22,
        lineHeight: 1.09,
    },
});

class MustReadListModal extends PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            checked: [],
            allSelected: false,
            disabled: true,
            notAskedUsers: [],
            loading: true,
        };

        this.loadData = loadData.bind(this);

        // Remind users
        this.remindUser = remindUser.bind(this);
        this.remindAllUsers = remindAllUsers.bind(this);

        // Select new users
        this.handleToggle = handleToggle.bind(this);
        this.handleSelectAll = handleSelectAll.bind(this);
        this.handleSave = handleSave.bind(this);
    }

    componentDidMount() {
        const { document, users, userUid } = this.props;

        var askedUsersIds = document.mustReadUsers ? document.mustReadUsers : [];
        var confirmedUsersIds = document.mustReadUsersApproved ? document.mustReadUsersApproved : [];

        let readUsers = users.filter(user => confirmedUsersIds.includes(user.auth0_id))
        let askedUsers = users.filter(user => askedUsersIds.includes(user.auth0_id) && !confirmedUsersIds.includes(user.auth0_id) && user.auth0_id != userUid)

        this.setState({ askedUsers, readUsers })
    }

    handleClose = (e) => {
        if (this.state.isNeedUpdate) {
            this.props.dispatch({ type: 'DATA_UPDATE' });
        }

        this.props.handleClose(e);
    }

    render() {
        const { open, handleClose, classes, t } = this.props;
        const { checked, readUsers, askedUsers, notAskedUsers, loading, allSelected } = this.state;

        return (
            <Fragment>
                <Dialog
                    open={open}
                    onEnter={this.loadData}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('Must read management')}</DialogTitle>
                    <DialogContent className={classes.disScrollig}
                        style={{ width: 600, flexShrink: 0, overflowY: 'auto', paddingTop: 0, paddingBottom: 0 }}>
                        <div data-intrcm-id="MustReadListModal_3my7ue6a"></div>
                    </DialogContent>
                    {!loading ? <DialogContent style={{ padding: 0 }} >
                        <ConfirmedUsersList
                            confirmedUsers={readUsers}
                            title={t("Asked to read and confirmed", { count: readUsers.length })}
                        />

                        <SelectedUsersList
                            askedUsers={askedUsers}
                            title={t("Asked but didn’t confirm", { count: askedUsers.length })}
                            buttonAllTitle={t("Remind All")}
                            buttonTitle={t("Remind")}
                            handleClick={this.remindUser}
                            handleAllClick={this.remindAllUsers}
                        />

                        <NotSelectedUsersList
                            notSelectedUsers={notAskedUsers}
                            title={t("Were not asked to read", { count: notAskedUsers.length })}
                            isSelectAllbutton={true}
                            handleToggle={this.handleToggle}
                            handleSelectAll={this.handleSelectAll}
                            checked={checked}
                            allSelected={allSelected}
                        />
                    </DialogContent>
                        : <DialogContent>
                            <Spinner classes={classes} />
                        </DialogContent>}
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={() => { this.props.mixpanelTrack('Create must read', {}); this.handleSave(); }} color="primary">
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    userUid: state.userData.profileData.auth0_id,
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('mustReadListModal'),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MustReadListModal);