import React, { PureComponent } from 'react';

export default class KanbanIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="5" y="5" width="5" height="7" rx="1" stroke={color} stroke-width="1.5"/>
            <rect x="5" y="15" width="5" height="4" rx="1" stroke={color} stroke-width="1.5"/>
            <rect x="14" y="12" width="5" height="7" rx="1" stroke={color} stroke-width="1.5"/>
            <rect x="14" y="5" width="5" height="4" rx="1" stroke={color} stroke-width="1.5"/>
            </svg>
        )
    }
}
export const KanbanPXIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34961 7.275C4.34961 6.15662 5.25623 5.25 6.37461 5.25H8.99902H10.3496H13.6502H14.999H17.6252C18.7436 5.25 19.6502 6.15662 19.6502 7.275V11.775C19.6502 12.8934 18.7436 13.8 17.6252 13.8H14.999V16.7246C14.999 17.8429 14.0924 18.7496 12.974 18.7496H11.024C9.90565 18.7496 8.99902 17.8429 8.99902 16.7246V15.6H6.37461C5.25623 15.6 4.34961 14.6934 4.34961 13.575V7.275ZM6.37461 6.6H8.99902V14.25H6.37461C6.00182 14.25 5.69961 13.9478 5.69961 13.575V7.275C5.69961 6.90221 6.00182 6.6 6.37461 6.6ZM10.3496 15.6H10.349V16.7246C10.349 17.0974 10.6512 17.3996 11.024 17.3996H12.974C13.3468 17.3996 13.649 17.0974 13.649 16.7246V6.60005H10.3496V15.6ZM15.0002 12.45V6.6H17.6252C17.998 6.6 18.3002 6.90221 18.3002 7.275V11.775C18.3002 12.1478 17.998 12.45 17.6252 12.45H15.0002Z" fill="#F97316"/>
    </svg>    
}