import * as React from "react"

const StopTimer = ({color = "#8645F9", withCircle = true }) => (
  <svg  width={32} height={32} >
     { withCircle ? (
        <g fill={color} fillRule="evenodd">
        <circle opacity={0.1} cx={16} cy={16} r={16} />
      <rect
        y={0.778}
        width={10.667}
        height={10.667}
        rx={0.889}
        transform="translate(10.667 9.889)"
        stroke={color}
        strokeWidth={0.889}
      />
      </g>
    ) : (
      <g fill={color} fillRule="evenodd">
        <rect
          y={0.778}
          width={10.667}
          height={10.667}
          rx={0.889}
          transform="translate(10.667 9.889)"
          stroke={color}
          strokeWidth={0.889}
        />
       </g>
    )}
    
  </svg>
)

export default StopTimer