import React, { Component, contextTypes } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
// import './spinner.scss'
import { withStyles } from '@material-ui/core';


const styles = theme => ({
  container: {
    width: '170px',
    height: '170px',
    paddingTop: '350px',
    margin: '0 auto',

  }
});
class Spinner extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
      query: ''
    };
  }

  render() {

     // Change background style
     document.documentElement.style.backgroundColor = "#fff"

    return (<div data-intrcm-id="spinner_4cn12pg4" className={this.props.classes.container}>
      <div data-intrcm-id="spinner_nhijivb0" >
        <img src="/system/10.gif" width={170} height={170} style={{ borderRadius: '50%' }} />
      </div>
    </div>);
  }
}


export default withStyles(styles)(Spinner);
