import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import NewGroupIcon from '../../../../Common/Icons/NewGroupIcon';

const styles = (theme) => ({
    container: {
        width: theme.constants.searchInputWidth - 40,
        height: 56,
        marginRight: 12,
        borderRadius: 4,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        '&:last-child': {
            marginRight: 0
        },
        display: 'flex',
        flexDirection: 'row',
    },
    pic: {
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 56,
        backgroundColor: '#f0ecfb'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        fontFamily: 'Rubik',
        fontSize: 15,
        color: theme.customColors.primary.main,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'start',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '8645f9'
        },
        marginLeft: 16,
    }
})

function NewGroup(props) {
    const {classes, onNewGroup, translations} = props

    return (
        <div data-intrcm-id="NewGroup_blkzxfov" className={classes.container} >
            <div data-intrcm-id="NewGroup_s4ahxpk0" alt="new_group" className={classes.pic} >
                <NewGroupIcon />
            </div>
            <div data-intrcm-id="NewGroup_hduztoyx" onClick={onNewGroup} className={classes.title}>{translations.newGroup}</div>
        </div>
    )
}

export default compose(
    withStyles(styles, {withTheme: true})
)(NewGroup)

