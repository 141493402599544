import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CategoryFeedView from '../../components/App/CategoryFeedView.js';
import ResultsList from '../../components/App/PageComponents/ResultsList.js';
import Spinner from '../../components/Common/spinner/spinner';
import FeedDataItem from '../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem.js';
import { getTagInfo, filterData, IsQueryExistInCollections, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../search/searchUtils';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { loadGroupInfo } from '../groups/groupUtils.js';
import { DATA_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import TrendingTagsWidget from '../../components/Widgets/RightWidgets/Feed/trendingTagsWidget.js';



const styles = theme => ({
    feedAndWidgetContainer: {

        marginTop: 16,
        "margin": "0 auto",
        "width": "fit-content"
    },
    container: {
        [theme.breakpoints.down(1680)]: {

            marginRight: 0,
        },
        [theme.breakpoints.up(1680)]: {
            marginRight: 328
        },
        height: '100%',
        padding: '0px 32px',
        marginLeft: 328
    },
    feedContainer: {
        marginRight: 32,
        width: 512,
        flex: '0 0 auto'
    }
});
const filterShortcuts = (shortcuts = []) => shortcuts.filter(s => s.type === 'channel')

class TagPageFeed extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            relatedTagsQuery: [],
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.getTagInfo = getTagInfo.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    updateTagInfo = (nextProps, update = false) => {
        const {urlQuery: prevUrlQuery} = this.props
        const {urlQuery} = nextProps
        if(update && urlQuery === prevUrlQuery) return

        this.getTagInfo(nextProps, prevUrlQuery);
    }
    updateRelatedTags = (nextProps, update) => {
        const {urlQuery: prevUrlQuery} = this.props
        const {urlQuery, searchQuery, queryService, dispatch} = nextProps
        if(update && urlQuery === prevUrlQuery) return

        queryService.searchShortcuts(searchQuery.query.slice(1)).then(relatedItems => {
            const filtered = filterShortcuts(relatedItems)
            dispatch({
                type: 'RESULT_RELATED_UPDATE',
                relatedTagsQuery: filtered.map(t => ({key: t.tag}))
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        this.updateTagInfo(nextProps, true)
        this.updateRelatedTags(nextProps, true)
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps, undefined, true)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps, true);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    renderItem = (idx, result) => {
        return (
            <FeedDataItem
                key={result.Key}
                docId={result.Key}
                result={result.Value.Source}
            />
        )
    }


    componentDidMount() {
        const {dispatch} = this.props
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { dataTypes: [], page: 0} })
        dispatch({ type: DATA_UPDATE });
        dispatch({ type: DATA_FILTER });
        this.updateTagInfo(this.props, false)
        this.updateRelatedTags(this.props, false)
    }


    render() {
        const {results, isLoading, isLoadingMore, hasMore } = this.state;

        const resultsProps = {
            results,
            isLoading,
            isLoadingMore,
            hasMore,
            loadMore: this.loadMoreQuery,
            isSearch: true,
            Header: null,
            isUniqeSearch: true,
            showInput: true,
            renderItem: this.renderItem,
        }



        return <CategoryFeedView rightWidgets={<TrendingTagsWidget/>}>
            {!isLoading ? 
                <ResultsList {...resultsProps} /> :
                <Spinner isAbsolutePosition />
            }
        </CategoryFeedView>
    }

}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...state.services,
    oldUiData: state.oldUiData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TagPageFeed);