import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  ClickAwayListener,
  withStyles,
} from '@material-ui/core';
import React from 'react'
import compose from 'recompose/compose';


const styles = theme => ({
    root: {marginTop: 16},
    input: {
        transition: theme.transitions.create('box-shadow', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),
          '&.Mui-focused': { boxShadow: 'rgb(145 158 171 / 24%) 0px 8px 16px 0px' },
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${'#919EAB'} !important`,
          }
    }
});

function ChatContactSearch({
  query,
  onChange,
  onFocus,
  onClickAway,
  classes
}) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div data-intrcm-id="ChatContactSearch_v2w8r49l" className={classes.root}>
        <FormControl fullWidth size="small">
          <OutlinedInput
            className={classes.input}
            value={query}
            onFocus={onFocus}
            onChange={onChange}
            placeholder={`Search discusions & people...`}
            startAdornment={
              <InputAdornment position="start">
                {/* <div data-intrcm-id="ChatContactSearch_1ty8jd0z" component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} /> */}
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </ClickAwayListener>
  );
}
export default compose(withStyles(styles, { withTheme: true }))(ChatContactSearch);