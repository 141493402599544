import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { DELETE_CUSTOM_INPUT_MAPPING, GLOBAL_INPUT_MAPPINGS_LOAD } from '../../../../../actionTypes/actionTypes';
import { showConfirm, showSnackbar } from '../../../../../actions/appActions';
import HelpButtonTooltip from '../../../../components/Common/HelpButton/HelpButtonTooltip';
import { defaultDataTypes } from '../../../../components/Common/Utils/defaultDataTypes.js';
import AdminSettingsRow from '../../Common/AdminSettingsRow.js';
import AdminSettingsTitle from '../../Common/AdminSettingsTitle.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../../actions/upgradeSubscriptionActions';
import { withTranslation } from 'react-i18next';
import { OldPagesWrap } from '../../newway/OldPagesWrap';


const styles = theme => ({
    content: {
        marginTop: '16px!important'
    },
    paper: {
        padding: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        margin: 16
    }
})


export class CustomInputTemplatesPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: 'a',
            scores: [],
            storageUse: 0,
            docsCount: 0,
            isLoading: true,
            activity: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        const { deleteMappingUid, inputMappings, dispatch } = nextProps

        const templateToDelete = deleteMappingUid && inputMappings.find(mapping => mapping.id == deleteMappingUid)

        if (templateToDelete) {
            dispatch({ type: DELETE_CUSTOM_INPUT_MAPPING, data: { deleteMappingUid: '' } })
            this.delete(templateToDelete)
        }
    }

    handleChange = (value) => {
        this.setState({
            value: value,
        });
    };

    editMapping = (mapping) => () => {
        const { goTo, dispatch } = this.props;

        dispatch({ type: 'CUSTOM_INPUT_EDIT_MAPPING', currentMapping: mapping })
        // Goto creating page
        goTo({ pathname: '/admin/custominputmappings/create' })
    }

    canAddCustomInput = () => {
        const { subscription, inputMappings } = this.props;

        if (subscription.subscriptionType === 0 && inputMappings.length >= 2) {
            return false
        }

        if (subscription.subscriptionType === 1 && inputMappings.length >= 2) {
            return false
        }

        return true

    }

    openSubscriptionDialog = () => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({ reason: 'customInput', mode: 'existed' }))
        dispatch(openSubscriptionModal())
    }

    addMapping = () => {
        const { goTo, dispatch } = this.props;

        if (!this.canAddCustomInput()) {
            this.openSubscriptionDialog()
            return false
        }


        dispatch({
            type: 'CUSTOM_INPUT_EDIT_MAPPING',
            currentMapping: {
                title: '',
                isEnableFiles: false,
                description: '',
                icon: '',
                color: '',
                customDataMapping: { customFieldsMappings: [], dataTypeFilter: 'dataType_' + new Date().getTime(), }
            }
        })

        goTo({ pathname: '/admin/custominputmappings/create' })
    }
    onDeleteTemplate = (template) => (event) => {
        const { dispatch } = this.props


        const action = { type: DELETE_CUSTOM_INPUT_MAPPING, data: { deleteMappingUid: template.id } }
        dispatch(showConfirm("Are you sure you want to delete this? this action can't be undone", action))

        event.stopPropagation()
        return false
    }

    delete = (template) => {
        const { customInputService, inputMappings, dispatch } = this.props

        dispatch(showSnackbar('Deleting...'));


        customInputService.deleteCustomInputMapping(template.id).then(res => {
            if (res.ok) {
                let newMappings = inputMappings.filter(mapping => mapping.id != template.id)
                newMappings = newMappings.map(mapping => ({ ...mapping }))

                dispatch({ type: GLOBAL_INPUT_MAPPINGS_LOAD, inputMappings: newMappings });
                dispatch(showSnackbar('Deleted!'));
            } else {
                dispatch(showSnackbar('ERROR occurred Template Not Deleted!'));
            }
        })
    }

    editDefaultMapping = (dataType) => () => {
        this.props.goTo({ pathname: `/admin/custominputmappings/edit-default-input-template/${dataType}` })
    }
    applyPatch = (uid) => {
        const { adminService } = this.props;
    
        adminService.applyPatch(uid).then(() => {
          
        });
      };

    render() {

        const { classes, inputMappings, subscription, t } = this.props;

        return (<OldPagesWrap classNameContent={classes.content} >
            <HelpButtonTooltip link='articles/5387620-data-types'>
                <AdminSettingsTitle text={t('Default Data Types')} />
            </HelpButtonTooltip>

            <Paper className={classes.paperStyle}>
                <List>
                    {
                        defaultDataTypes().map((mapping, idx) => (
                            <AdminSettingsRow
                                icon={mapping.icon}
                                key={mapping.customDataMapping.dataTypeFilter}
                                onClick={this.editDefaultMapping(mapping.customDataMapping.dataTypeFilter)}
                                primary={mapping.title}
                            />
                        ))
                    }
                </List>
            </Paper>


            <AdminSettingsTitle text={t('Mappings')} />
            <Paper className={classes.paperStyle}>
                <List>
                    {
                        inputMappings.map((mapping, idx) => (
                            <AdminSettingsRow
                                t={t}
                                icon={mapping.icon}
                                key={mapping.customDataMapping.dataTypeFilter}
                                onClick={this.editMapping(mapping)}
                                onDelete={this.onDeleteTemplate(mapping)}
                                primary={mapping.title}
                            />
                        ))
                    }
                </List>

                <Button
                    className={classes.button}
                    variant="raised"
                    onClick={this.addMapping}>
                    {t('New Mapping')}
                </Button>
            </Paper>

            <Button onClick={() => { this.applyPatch("fixRelated") }}>
                {t('fix related')}
            </Button>
        </OldPagesWrap>);
    }

}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    subscription: state.globalData.currentSubscription,
    customInputService: state.services.customInputService,
    deleteMappingUid: state.confirmDialog.deleteMappingUid,
    ...state.services,
})

const mapDispatchToProps = dispatch => {
    return {
        goTo: path => { dispatch(push(path)) },
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('admin'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CustomInputTemplatesPage);

