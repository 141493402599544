import { withStyles } from '@material-ui/core'
import React from 'react'

const styles = (theme) => ({
    container: {
        cursor: 'pointer',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: 36,
        backgroundColor: '#fff',
        borderRadius: 18,
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.customColors.primary.main,
            borderColor: theme.customColors.primary.main,
        },
    },
    inner: {
        margin: '10px 16px'
    },
    text: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'inherit',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'inherit',
    },
    selected: {
        border: `solid 1.5px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
    },
    nonSelected: {
        border: `solid 1.5px #979aaa`,
        color: '#73778f',
        fontWeight: 'normal'
    }
})

const WidgetSingleOption = (props) => {
    const { classes, option, onClick, optionStyle } = props

    const _onClick = () => {
        onClick(option.key)
    }
    return (
        <div data-intrcm-id="WidgetSingleOption_b5f7wpcm" onClick={_onClick} className={`${classes.container} ${optionStyle ?? ''} ${option.selected ? classes.selected : classes.nonSelected}`}>
            <div data-intrcm-id="WidgetSingleOption_5kv59gc4" className={classes.inner}>
                <div data-intrcm-id="WidgetSingleOption_pcd0737e" className={classes.text}>{option.label}</div>
            </div>
        </div>
    )
}

export default withStyles(styles, {withTheme: true})(WidgetSingleOption)