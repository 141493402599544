import { withStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import React from 'react'

const styles = (theme) => ({
    container: {
        cursor: 'pointer',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: 36,
        backgroundColor: '#fff',
        borderRadius: 18,
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.customColors.primary.main,
            borderColor: theme.customColors.primary.main,
        },
    },
    inner: {
        margin: 4,
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'inherit',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'inherit',
    },
    selected: {
        border: `solid 1.5px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
    },
    nonSelected: {
        border: `solid 1.5px #979aaa`,
        color: '#73778f',
        fontWeight: 'normal',
    },
    selectedIcon: {
        border: `solid 0.5px ${theme.customColors.primary.main}`,
        backgroundColor: theme.customColors.primary.main,
        borderRadius: '50%',
        marginRight: 6,
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        border: `solid 0.5px #979aaa`,
        backgroundColor: 'transparent',
        borderRadius: '50%',
        marginRight: 6,
        width: 22,
        height: 22,
    },
    check: {
        fontSize: 17,
        marginTop: 2,
        color: '#fff'
    }
})

const WidgetMultipleOption = (props) => {
    const { classes, option, onClick, optionStyle } = props

    const _onClick = () => {
        onClick(option.key)
    }
    return (
        <div data-intrcm-id="WidgetMultipleOption_9qgt0kli" onClick={_onClick} className={`${classes.container} ${optionStyle ?? ''} ${option.selected ? classes.selected : classes.nonSelected}`}>
            <div data-intrcm-id="WidgetMultipleOption_630wuzxk" className={classes.inner}>
                <div data-intrcm-id="WidgetMultipleOption_shqw1nsd" className={option.selected ? classes.selectedIcon : classes.icon}>
                    {option.selected && <CheckIcon className={classes.check} />}
                </div>
                <div data-intrcm-id="WidgetMultipleOption_5ww08edg" className={classes.text}>{option.label}</div>
            </div>
        </div>
    )
}

export default withStyles(styles, {withTheme: true})(WidgetMultipleOption)