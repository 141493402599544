import React from 'react'
import {Divider, withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Close from '@material-ui/icons/Close'
import { closeStory } from '@tuqqi/common'

const styles = (theme) => ({
    header: {
        width: 'inherit',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    cancelIconOpen: {
        cursor: 'pointer',
        fontSize: 33,
        color: '#979aaa',
        margin: '23px 0 19px 26px',
    },
    cancelIconClose: {
        cursor: 'pointer',
        margin: '55px 0 19px 33px',
        fontSize: 25,
        color: '#979aaa'
    },
    storiesTitle: {
        fontSize: 27,
        fontWeight: 500,
        marginLeft: 32,
        marginBottom: 28,
        color: '#21212d',
        lineHeignt: 0.67,
    },
    divider: {
        backgroundColor: '#e0e0e0',
    },
})

const HeaderNavigation = (props) => {
    const {classes, dispatch, isDrawerOpen} = props

    const onClose = () => {
        dispatch(closeStory())
    }

    const Header = (
        <>
            <div data-intrcm-id="HeaderNavigation_tyqs0xib" className={classes.header}>
                <Close onClick={onClose} className={isDrawerOpen ? classes.cancelIconOpen : classes.cancelIconClose} />
            </div>
            {isDrawerOpen && <div data-intrcm-id="HeaderNavigation_bcdwtfzc" className={classes.storiesTitle}>Activities</div>}
            <Divider className={classes.divider}/>
        </>
    )

    return Header
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(HeaderNavigation)