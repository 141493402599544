import i18n from 'i18next';

const defaultDataTypes = (t = undefined) => ([
    {
        backgroundUrl: '',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'post',
        },
        description: 'Post',
        icon: "subject",
        tag: 'post',
        title: 'Post'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/file.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'media',
        },
        description: 'Media',
        icon: "camera_roll",
        tag: 'media',
        title: 'Media'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/link.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'event',
        },
        description: 'Event',
        icon: "event",
        tag: 'event',
        title: 'Event'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/link.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'bookmark',
        },
        description: 'Link',
        icon: "link",
        tag: 'link',
        title: 'Link'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/file.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'file',
        },
        description: 'File',
        icon: "attach_file",
        tag: 'file',
        title: 'File'
    },
    // {
    //     backgroundUrl: 'https://app.tuqqi.com/covers/note.png',
    //     color: '',
    //     creationDateTime: '',
    //     customDataMapping: {
    //         dataTypeFilter: 'wysiwyg',
    //     },
    //     description: 'Article',
    //     icon: "edit",
    //     tag: 'wysiwyg',
    //     title: 'Article'
    // },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/note.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'htmlwysiwyg',
        },
        description: 'Article',
        icon: "edit",
        tag: 'htmlwysiwyg',
        title: t ? t('Article') : 'Article'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/queation_answerd.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'question',
        },
        description: 'Question',
        icon: "question_answer",
        tag: 'question',
        title: 'Question'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/code.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'code',
        },
        description: 'Code',
        icon: "code",
        tag: 'code',
        title: 'Code'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/mail.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'mail',
        },
        description: 'Mail',
        icon: "mail",
        tag: 'mail',
        title: 'Mail'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/code.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'contact',
        },
        description: 'Contact',
        icon: "account_circle",
        tag: 'contact',
        title: 'Contact'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/code.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'location',
        },
        description: 'Location',
        icon: "location_on",
        tag: 'location',
        title: 'Location'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/code.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'poll',
        },
        description: 'Poll',
        icon: "poll",
        tag: 'poll',
        title: 'Poll'
    },
    {
        backgroundUrl: 'https://app.tuqqi.com/covers/code.png',
        color: '',
        creationDateTime: '',
        customDataMapping: {
            dataTypeFilter: 'company',
        },
        description: 'Company',
        icon: "business",
        tag: 'company',
        title: 'Company'
    },
])

let getDataType = function (dataType) {
    return defaultDataTypes().find( type => type.customDataMapping.dataTypeFilter == dataType);
}

export { defaultDataTypes, getDataType }