import { Grid, Icon, IconButton, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DELETE_LANE } from '../../../../actionTypes/actionTypes.js';
import { showConfirm } from '../../../../actions/appActions.js';
import EnhancedMenu from '../../Common/EnhancedMenu.js';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions.js';

const ListHeaderMenuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="ListHeaderMenuIcon__a" d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(4 10)">
                <mask id="ListHeaderMenuIcon__b" fill="#fff">
                    <use href="#ListHeaderMenuIcon__a" />
                </mask>
                <use fill="#1D1D1D" fill-rule="nonzero" href="#ListHeaderMenuIcon__a" />
                <g fill="#979AAA" mask="url(#ListHeaderMenuIcon__b)">
                    <path d="M0 0H24V24H0z" transform="translate(-4 -10)" />
                </g>
            </g>
        </svg>
    )

}

const styles = theme => ({
    container: {
        margin: 'auto',
        width: 244,//'100%',
        height: 38,
        backgroundColor: 'transparent',

        borderRadius: 2,
    },
    title: {
        fontFamily: "'Roboto'",
        fontSize: 14,
        // marginLeft: 10,
        fontWeight: '400',
        fontStyle: 'normal',
        fontStretch: 'normal',
        color: 'rgba(0,0,0,0.87)',
        lineHeight: 'normal',
        letterSpacing: '0.3px',
    },
    colorCount: {
        color: theme.newUI.color.gray
    },
    assigned: {
        color: '#ff863a'
    },
    unassigned: {
        color: theme.customColors.primary.main
    },
    listName: {
        ...theme.newUI.text.body2,
        color: theme.newUI.color.commonBlack
    },
    flagIcon: {
        fontSize: 16
    },
    iconButton: {
        padding: 12
    }
})

const incomingName = "incoming"
const outgoingName = "outgoing"

class ListHeaderMenu extends React.Component {

    state = {
        openMenu: false,
        anchorEl: null,
    }

    componentDidUpdate(prevProps) {
        const { deleteLaneid, dispatch, list } = this.props;
        const prevDeleteLaneid = prevProps.deleteLaneid;

        if (prevDeleteLaneid !== deleteLaneid && deleteLaneid === list.id) {
            dispatch({ type: DELETE_LANE, data: { deleteLaneid: '' } })
            this.deleteLane()
        }
    }


    deleteLane = () => {
        this.props.deleteList(this.props.list.id)
        this.props.closeMenu()
    }

    onDeleteLane = () => {
        const { list, dispatch } = this.props
        const action = { type: DELETE_LANE, data: { deleteLaneid: list.id } }

        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), action))
    }

    isChangedAble = () => {
        return this.props.list.id !== -1
    }
    canAddRule = () => {
        const { subscription, list } = this.props;

        const rules = list.rules || []
        const incomingRules = rules.filter(r => r.triggerType === incomingName);

        if (subscription.subscriptionType === 0 && incomingRules.length >= 2) {
            return false
        }
        if (subscription.subscriptionType === 1 && incomingRules.length >= 2) {
            return false
        }
        return true
    }
    openSubscriptionDialog = () => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({ reason: 'incomingRules', mode: 'existed' }))
        dispatch(openSubscriptionModal())
    }
    openListRuleModal = (incomingName) => {
        const { openListRuleModal } = this.props;
        if (!this.canAddRule()) {
            this.openSubscriptionDialog()
            return
        }
        openListRuleModal(incomingName)()
    }

    getAdminMenuItems = () => {
        const { t, list, openListRuleModal, openListColorModal } = this.props;

        const rules = list.rules
            ? list.rules.map(rule => {
                return {
                    key: rule.id,
                    caption: rule.title,
                    onClick: openListRuleModal(rule.triggerType, rule),
                    triggerType: rule.triggerType
                }
            })
            : []

        const incomingRules = rules.filter(r => r.triggerType === incomingName);
        // const outgoingRules = rules.filter(r => r.triggerType === outgoingName);

        return [{
            key: 'laneColor',
            caption: t('Set lane color'),
            onClick: openListColorModal
        },

        {
            key: incomingName,
            caption: t('Incoming rules'),
            subMenuItems: [
                ...incomingRules,
                { key: "new_incoming", caption: `${t("Create")} +`, onClick: () => this.openListRuleModal(incomingName) },
            ]
        },
        ]
    }

    getUnassignedColumnMenuItems = () => {
        const { t, groupInfo: { collectionGroup }, openListColorModal, sortBy } = this.props;

        return [{
            key: 'laneColor',
            caption: t('Set lane color'),
            onClick: openListColorModal
        }]
    }

    getMenuItems = () => {
        const { t, groupInfo: { collectionGroup }, userData, sortBy } = this.props;

        const isAdmin = collectionGroup && collectionGroup.adminIds && collectionGroup.adminIds.includes(userData.profileData.id)
        const adminMenuItems = isAdmin ? this.getAdminMenuItems() : []
        const sortMenu = {
            key: 'sortItemsInLane',
            caption: t('Sort by'),
            subMenuItems: [
                { key: "byTimestampDesc", caption: `${t("Date created (newest first)")}`, onClick: () => sortBy("docTimestamp", "desc") },
                { key: "byTimestampAsc", caption: `${t("Date created (oldest first)")}`, onClick: () => sortBy("docTimestamp", "asc") },
                { key: "byDueDateDesc", caption: `${t("Due date (closest first)")}`, onClick: () => sortBy("dueDateUTC", "desc") },
                { key: "byDueDateAsc", caption: `${t("Due date (farthest first)")}`, onClick: () => sortBy("dueDateUTC", "asc") },
                { key: "byNameAsc", caption: `${t("Name (alphbetically)")}`, onClick: () => sortBy("title", "asc") },
                { key: "byRatingAsc", caption: `${t("Rating (highest first)")}`, onClick: () => sortBy("rating", "desc") },
            ]
        }


        return [
            sortMenu,
            ...adminMenuItems,
            { key: "delete", caption: t('Delete'), onClick: this.onDeleteLane },
        ]
    }

    render() {

        const { classes, list, t, isOpenMenu, anchorEl, isUnassigned } = this.props;

        return (
            <Fragment>

                <Grid container style={{ width: 'initial' }} alignItems='center'>
                    {list.rules && list.rules.length ? <Icon className={classes.flagIcon} >flag</Icon> : null}
                    <IconButton
                        className={classes.iconButton}
                        onClick={this.props.openMenu}
                        aria-label="More"
                        aria-haspopup="true" >
                        <ListHeaderMenuIcon />
                    </IconButton>
                </Grid>
                <EnhancedMenu
                    open={isOpenMenu}
                    menuItems={isUnassigned ? this.getUnassignedColumnMenuItems() : this.getMenuItems()}
                    anchorElement={anchorEl}
                    onClose={this.props.closeMenu}
                    PaperProps={{ style: { maxHeight: 270, width: 200, } }}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    deleteLaneid: state.confirmDialog.deleteLaneid,
    subscription: state.globalData.currentSubscription,
    groupInfo: state.results.channel,
    userData: state.userData,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('kanbanTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ListHeaderMenu);
