import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OptionLeft from './OptionLeft';
import { DirectMessages, Discussions, GroupMessages } from '../../../../../views/chat/chatUtils';
import { formatDistanceToNowStrict } from 'date-fns';
import Actions from './Actions';
import Reply from './Reply';
import { getDateFnsLocale } from '../../../../../../utils/dateFnsLocale';
import { managerTableKeepGlobalData } from '@tuqqi/web';

const styles = (theme) => ({
    outter: {
        borderBottomColor: '#E8EBFD',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        '&:last-child': {
            borderBottomWidth: 0
        },
    },
    dateTime : {
        color: '#737373',
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: 0.4,
        whiteSpace: 'nowrap'
    },
    leftWrap: {
        display: 'flex', 
        alignItems: 'center',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        marginLeft: 16
    },
    mainWrap: {
        display: 'flex', 
        alignItems: 'center',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        marginLeft: 12,
        marginTop: 8,
        width: '-webkit-fill-available',
        marginRight: 16,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    replyContainer: {
        minHeight: 56
    },
    defaultContainer: {
        height: 56
    },
    privateBubbleContainer: {
        marginTop: 2,
        borderRadius: 5,
        backgroundColor: '#9cc3ff',
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 4,
        marginRight: 'auto',
        justifyContent: 'center',
        height: 16,
    },
    privateBubbleContainerGroup: {
        backgroundColor: '#fbc97b',

    },
    privateBubbleTextGroup: {
        color: '#21212d!important',
    },
    privateBubbleText: {
        fontSize: 12,
        color: '#1e66d5'
    },
    itemTitle: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.24,
        color: '#0C0C0D',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 253,
        overflow: 'hidden',
    },
    counterText: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#FFF',
    },
    itemSubtitle: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.4',
        color: '#737373',
        maxWidth: 263,
    },
    itemSubtitleCut: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.4',
        color: '#737373',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 263,
        overflow: 'hidden',
    },
    titlesWrap: {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-between'
    },
    counter: {
        display: 'flex',
        marginRight: 0,
        marginLeft: 'auto',
        backgroundColor: '#55A252',
        borderRadius: 9,
        height: 16,
        minWidth: 16,
        paddingRight: 4,
        paddingLeft: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const ChatOption = (props) => {
    const {classes, option, onPress, users, userId, lang} = props
    const [isHover, setIsHover] = useState(false)
    const [isReply, setIsReply] = useState(false)
    const leftElement = <OptionLeft chat={option.chat} docId={option.docId} userProfile={option.userProfile} chatType={option.chatType} document={option.document} />

    const isSameUser = useMemo(() => {
        if(!userId || !option.lastUserId || userId !== option.lastUserId) return false
        return true    
    }, [option.lastUserId, userId])

    const newComments = useMemo(() => {
        if(isSameUser) return 0
        if(!option.newComments) return 0
        return option.newComments || 0
    }, [option.newComments, isSameUser])
    
    const dateFormat = useMemo(() => {
        const d = new Date()
        const d1 = new Date(option.commentTime)
        if((d.getTime() - d1.getTime()) < 60000) return 'Now'
        return formatDistanceToNowStrict(d1, {
            locale: getDateFnsLocale(lang),
            addSuffix: false
          })
    }, [option.commentTime, lang])

    const [title, subtitle, username] = useMemo(() => {
        let title;
        let subtitle = option.commentText;
        let userName;
        const user = users.find(user => user.auth0_id === option.lastUserId);
                if (user) {
                    userName = `${user.firstname} ${user.lastname}`;
                }

        switch (option.chatType) {
            case Discussions:
                subtitle = option.commentText;
                title = option.document.title || 'Untitled';
                break;
            case DirectMessages:
                const { firstname, lastname } = option.userProfile;
                title = `${firstname} ${lastname}`;
                subtitle = option.commentText;
                break;
            case GroupMessages:
                const col = !option?.chat?.chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === option?.chat?.chatUId)
                title = !col?.title ? '' : `${col.title ?? ''} ${managerTableKeepGlobalData.tTable("Chat")}`
                subtitle = option.commentText;
                break;
        }
        const isAttachment = !!option.lastCommentAttachmentType && option.lastCommentAttachmentType !== 'default'

        if (isAttachment) {
            let moreText = ''

            if (option.lastCommentAttachmentType.startsWith('image')) {
                moreText = 'photo'
            } else if (option.lastCommentAttachmentType.startsWith('video')) {
                moreText = 'video'
            } else moreText = 'file'

            subtitle = `attached ${moreText}`
        }

        return [title, subtitle, userName]
    }, [option, users])
    
    const _onPress = () => {
        onPress(option)
    }

    const Private = option.userProfile && option.chatType === DirectMessages
            ? <div data-intrcm-id="ChatOption_fjcbawrp" className={classes.privateBubbleContainer} >
                <div data-intrcm-id="ChatOption_pn8ikloj" className={classes.privateBubbleText} >Private</div>
            </div>
            : null
    const Group = option.chatType === GroupMessages
            ? <div data-intrcm-id="ChatOption_fjcbawrp" className={`${classes.privateBubbleContainer} ${classes.privateBubbleContainerGroup}`} >
                <div data-intrcm-id="ChatOption_pn8ikloj" className={`${classes.privateBubbleText} ${classes.privateBubbleTextGroup}`} >Group</div>
            </div>
            : null

    const NewComments = !newComments || newComments <= 0 ? 
        null :
        <div data-intrcm-id="ChatOption_rl87g93k" className={classes.counter}>
            <div data-intrcm-id="ChatOption_lmlgohmx" className={classes.counterText}>{newComments}</div>
        </div>

    return <div data-intrcm-id="ChatOption_ulab324p" className={classes.outter}><div data-intrcm-id="ChatOption_r219h3ez" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={`${classes.container} ${isReply ? classes.replyContainer : classes.defaultContainer}`} onClick={_onPress}>
            <div data-intrcm-id="ChatOption_yrj0dper" className={classes.leftWrap}>{leftElement}</div>
            <div data-intrcm-id="ChatOption_pw0wvoch" className={classes.mainWrap}>
                <div data-intrcm-id="ChatOption_8hvzm6g3" className={classes.titlesWrap}>
                    <div data-intrcm-id="ChatOption_9zuaxyw1" className={classes.itemTitle}>{title}</div>
                    {Private}
                    {Group}
                    <div data-intrcm-id="ChatOption_chbutpoa" className={classes.dateTime}>{dateFormat}</div>
                </div>
                <div data-intrcm-id="ChatOption_j63s9izt" className={classes.titlesWrap}>
                    <span className={isReply ? classes.itemSubtitle : classes.itemSubtitleCut}>{username}: <span style={{color: '#0C0C0D'}} className={classes.itemSubtitle}>{subtitle}</span></span>
                    {NewComments}
                </div>
            </div>
            {!!isHover && !isReply && <Actions setIsReply={setIsReply} option={option} />}
    </div >
        {!!isReply && <Reply option={option} setIsReply={setIsReply} />}
    </div>
}

const mapStateToProps = (state) => {
    return {
        users: state.globalData.users,
        userId: state.data?.userData?.userDetails?.data?.auth0_id,
        lang: state.data?.userData?.userDetails?.data?.lang ?? '',
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('chat'),
    connect(mapStateToProps, mapDispatchToProps),
)(ChatOption)