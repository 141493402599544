import { updateGroupAllowedInputsActionTypes, updateGroupDefaultViewActionTypes, updateGroupDisplayedViewsActionTypes } from '@tuqqi/common';
import localforage from 'localforage';
import { DRAWER_TOGGLE, USER_DATA_LOAD, USER_DATA_REFRESH, USER_PROFILE_UPDATE, USER_LOGIN, USER_LOGOUT, USER_SUBSCRIBED_LOAD, USER_COLLECTION_LOAD, USER_FAVORITES_LOAD, USER_NOTIFICATION_REFRESH, COLLECTION_LABELS_UPDATE, USER_NOTIFICATION_ADD, FULL_RESET_STORE, ADD_RECENT_GROUP, ADD_RECENT_ITEM, ADD_RECENT_USER, REMOVE_RECENT_GROUP, REMOVE_RECENT_ITEM, REMOVE_RECENT_USER, } from '../actionTypes/actionTypes'

const initialState = {
  notifications: [],
  collections: [],
  recentItems: [],
  recentGroups: [],
  recentUsers: [],
  userDataLoaded: false,
  profileData: {
    org: {}
  }
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case USER_DATA_LOAD:
      return Object.assign({}, state, {
        ...action.data,
        userDataLoaded: true,
        userDataRefresh: false

      });
    case 'USER_SEEN_NOTIFICATION': {
      const { id, seenOnDateTime } = action
      if (!id) return state

      const notifications = state.notifications.map(x => x.id === id ? { ...x, seenOnDateTime } : x)
      return {
        ...state,
        notifications
      }
    }
    case 'PUSH_DESKTOP_NOTIFICATION': {
      const { notif } = action

      return {
        ...state,
        notifications: [...state.notifications, notif]
      }
    }
    case 'USER_SEEN_ALL_NOTIFICATIONS': {

      return {
        ...state,
        profileData: {
          ...state.profileData,
          lastSawNotification: new Date().toISOString()
        }
      }
    }

    // case 'USER_NOTIFICATIONS_LOAD':
    //   return Object.assign({}, state, {
    //     notifications: action.notifications

    //   });
    case USER_DATA_REFRESH:
      return Object.assign({}, state, {
        // profileData: action.profileData,
        userDataRefresh: true,

      });
    case 'UPDATE_PARTIAL_USER_BY_UID': {
      const successAction = action
      if (!successAction.auth0_id || !successAction.result || !Object.keys(successAction.result).length) return state
      if (!state?.profileData?.auth0_id) return state
      if (state?.profileData?.auth0_id !== successAction.auth0_id) return state


      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...successAction.result
        }
      }
    }

    case USER_PROFILE_UPDATE:
      return Object.assign({}, state, {
        profileData: Object.assign({}, state.profileData, action.profileData)
      });
    case USER_NOTIFICATION_REFRESH:
      return Object.assign({}, state, {
        notifications: action.notifications
      });
    case USER_NOTIFICATION_ADD:
      return Object.assign({}, state, {
        notifications: [...action.notifications, ...state.notifications]
      });
    case USER_LOGIN:
      return Object.assign({}, state, {
        ...action.authResult,
        isAuthenticated: true

      });
    case USER_LOGOUT:
      // Clear localStorage
      // TODO: move to actions?
      // localStorage.removeItem('access_token');
      // localStorage.removeItem('id_token');
      // localStorage.removeItem('expires_at');
      localforage.clear()

      return Object.assign({}, state, {
        loggedId: false,
        userData: null,
        userDataLoaded: false,
        userDataLoaded_secondWave: false,
        isAuthenticated: false

      });
    case USER_SUBSCRIBED_LOAD:
      return Object.assign({}, state, {
        subscribedTags: action.subscribedTags
      });
    case USER_COLLECTION_LOAD:
      return Object.assign({}, state, {
        collections: action.collections
      });
    case USER_FAVORITES_LOAD:
      return Object.assign({}, state, {
        subscribedTags: action.favorites
      });
    case COLLECTION_LABELS_UPDATE:
      {
        const collection = state.collections.find(collection => collection.id == action.collectionId)

        if (collection) {
          collection.collectionLabels = action.collectionLabels.map(label => ({ ...label }))
        }
        return {
          ...state
        }
      }
    case updateGroupDefaultViewActionTypes.start: {
      const startAction = action

      if (!startAction?.additionalData?.collectionId) return state
      const { defaultView, collectionId } = startAction?.additionalData
      if (!defaultView || !collectionId) return state

      const collections = state.collections.map(collection => {
        if (collection.id !== collectionId) return collection
        return {
          ...collection,
          defaultView: defaultView
        }
      })

      return {
        ...state,
        collections
      }
    }
    case updateGroupDisplayedViewsActionTypes.start: {
      const startAction = action

      if (!startAction?.additionalData?.collectionId) return state
      const { availableViews, collectionId } = startAction?.additionalData
      if (!availableViews || !collectionId) return state

      const collections = state.collections.map(collection => {
        if (collection.id !== collectionId) return collection
        return {
          ...collection,
          availableViews: availableViews
        }
      })

      return {
        ...state,
        collections
      }
    }
    case updateGroupAllowedInputsActionTypes.start: {
      const startAction = action

      if (!startAction?.additionalData?.collectionId) return state
      const { allInputsAllowed, allowedInputs, collectionId } = startAction?.additionalData
      if (!collectionId) return state

      const collections = state.collections.map(collection => {
        if (collection.id !== collectionId) return collection
        return {
          ...collection,
          allInputsAllowed,
          settings: {
            ...(collection?.settings ?? {}),
            allowedInputs
          },
        }
      })

      return {
        ...state,
        collections
      }
    }

    case ADD_RECENT_GROUP: {
      const { uid } = action
      let items = []
      if (state.recentGroups) {
        items = state.recentGroups.filter(x => x.key !== uid)
      }
      return {
        ...state,
        recentGroups: [{ key: uid, timestamp: new Date().getTime() }, ...items]
      }

    }


    case ADD_RECENT_USER: {
      const { uid } = action

      let items = []
      if (state.recentUsers) {
        items = state.recentUsers.filter(x => x.key !== uid)
      }
      // const items = state.recentUsers.filter(x => x.key !== uid)
      return {
        ...state,
        recentUsers: [{ key: uid, timestamp: new Date().getTime() }, ...items]
      }
    }
    case ADD_RECENT_ITEM: {
      const { uid } = action
      let items = []
      if (state.recentItems) {
        items = state.recentItems.filter(x => x.key !== uid)
      }
      return {
        ...state,
        recentItems: [{ key: uid, timestamp: new Date().getTime() }, ...items]
      }
    }
    case REMOVE_RECENT_GROUP: {
      const { uid } = action
      return {
        ...state,
        recentGroups: state.recentGroups.filter(x => x.key !== uid)
      }
    }
    case REMOVE_RECENT_ITEM: {
      const { uid } = action
      return {
        ...state,
        recentItems: state.recentItems.filter(x => x.key !== uid)
      }
    }
    case REMOVE_RECENT_USER: {
      const { uid } = action
      return {
        ...state,
        recentUsers: state.recentUsers.filter(x => x.key !== uid)
      }
    }
    default:
      return state
  }
}

export default userData;