import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import HasTagIcon from '../../../Common/Icons/ChannelIcons/hashTagIcon';
import WidgetSingleOption from '../../../Common/WidgetOptions/WidgetSingleOption';
import {applySearchQuery} from '../../../../views/search/searchUtils';
import { getUniqueBy } from '../../../../../utils/commonHelpers';
import { withMixpanel } from '../../../../components/Common/withMixpanel';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        width: 15,
        height: 15,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginLeft: 8,
        marginBottom: 8
    }
});

const MAX_TAGS = 5

const tagsToOptions = (tags = []) => tags.map(t => ({
    selected: false,
    key: t.Key.startsWith("#") || t.Key.startsWith("@") ? t.Key.slice(1) : t.Key,
    label: t.Key.startsWith("#") || t.Key.startsWith("@") ? t.Key.slice(1) : t.Key,
}))
const RelatedTagsWidget = (props) => {
    const { classes, t, query, searchQuery, dispatch, mixpanelTrack, relatedTags, pathname } = props;
    const [options, setOptions] = useState([])
    const initialSetRef = useRef(false)

    const onOptionClick = (key) => {
        mixpanelTrack(`Related tags widget`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            o.selected = false
            return o
        })
        setOptions(newOptions)
        const selected = newOptions.filter(o => o.selected).map(o => o.key)
        const newQuery = {...searchQuery, tags: selected}
        applySearchQuery(newQuery, dispatch)   
    }

    useEffect(() => {
        initialSetRef.current = false
    }, [pathname])

    useEffect(() => {
        if(initialSetRef.current) return
    
        const sorted = relatedTags.sort((a, b) => b.DocCount - a.DocCount)
        const options = tagsToOptions(sorted)
        const unique = getUniqueBy('key', options.slice(0, MAX_TAGS))
        setOptions(unique)
        unique.length && (initialSetRef.current = true)
    }, [query, relatedTags])

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="RelatedTagsWidget_ez18u90x" className={classes.containerInner}>
                <div data-intrcm-id="RelatedTagsWidget_54pmhmh7" className={classes.titleSection}>
                        <HasTagIcon className={classes.titleIcon} />
                        <div data-intrcm-id="RelatedTagsWidget_jagjgsuy" className={classes.title}>{t("Tags")}</div>
                </div>
                <div data-intrcm-id="RelatedTagsWidget_5kz1vl5a" className={classes.options}>
                    {options.map(o => (
                        <WidgetSingleOption optionStyle={classes.option} key={o.key} option={{...o, label: `#${o.label}`}} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    pathname: state.wrapper.pathname,
    query: state.search.query,
    searchQuery: state.search,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps),
)(RelatedTagsWidget);