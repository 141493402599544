import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../../actionTypes/actionTypes.js';
import Spinner from '../../../../components/Common/spinner.js';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import SeeAll from '../../GroupLeftDrawer/SeeAll.js';


const styles = theme => ({
    container: {
        "borderRadius": "8px",
        "backgroundColor": "#f3f6fb",
        "marginBottom": "26px"
    },
    headerContainer: {
        padding: '16px'
    },
    title: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        "color": theme.newUI.color.commonBlack
    },
    seeAll: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "color": theme.newUI.color.primary,
        cursor: 'pointer'
    },
    divider: {
        backgroundColor: '#daddee'
    },
    spinner: {
        margin: 'auto',
        minHeight: 100
    }
});

class WidgetContainer extends React.Component {
    state = {
        groupMembersDialogOpen: false
    }
    onSeeAllClick = () => {
        const { dataType, search, dispatch } = this.props;
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { ...search, page: 0, dataTypes: [dataType] } })

        dispatch({ type: DATA_UPDATE });
    }

    toggleGroupMembersDialog = () => this.setState({ groupMembersDialogOpen: !this.state.groupMembersDialogOpen })

    render() {
        const { classes, title, children, isLoading, t, hideSeeAll } = this.props;

        return (

            <Grid item container className={classes.container} direction='column'>
                <Grid className={classes.headerContainer} item container direction='row' justify='space-between'>
                    <Typography className={classes.title}>{title}</Typography>
                    {hideSeeAll ? null : <SeeAll onClick={this.onSeeAllClick} />}
                </Grid>
                <Divider className={classes.divider} />
                {
                    isLoading ?
                        <Spinner isAbsolutePosition isSpinnerRelativePosition classes={{ spinner: classes.spinner }} /> :
                        children
                }
            </Grid>

        )
    }

}

const mapStateToProps = (state) => ({
    search: state.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(WidgetContainer);