import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, ListItemIcon, TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { StatusColorMap, customStatusColors, defaultStatuses, defaultStatusesColors, setOrgStatuses } from "@tuqqi/common";
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { confirmLoading, hideConfirm, showConfirm, showSnackbar } from '../../../../actions/appActions.js';
import { globalDataLoad } from '../../../../actions/globalDataAction.js';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';


const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paperStyle: {
        marginBottom: 40
    },
    button: {
        margin: 16
    }
})


const CreateNewModal = (props) => {
    const { handleClose, open, handleClickSave, t } = props

    const [labelName, setLabelName] = useState('');
    const [labelDesc, setLabelDesc] = useState('');
    const [color, setColor] = useState(null);

    const reset = () => {
        setLabelName('')
        setLabelDesc('')
        setColor(null)
    }

    const onClose = () => {
        handleClose()
        reset()
    }

    const colorsWithoutUsed = customStatusColors.filter(x => !props.labels.map(x => x.taskStatusColor).includes(x))

    return <Dialog open={open} onRequestClose={handleClose}>
        <DialogTitle>{t('Create new status color')}</DialogTitle>
        <DialogContent>
            <TextField fullWidth label={t("Name")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelName} margin="normal"
                onChange={(value) => { setLabelName(event.target.value) }} />
            <TextField fullWidth label={t("Description")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelDesc} margin="normal"
                onChange={(value) => { setLabelDesc(event.target.value) }} />
            <Grid container>
                {colorsWithoutUsed.map((_color, idx) => (
                    <Grid container alignContent='center' justify='center' item
                        onClick={() => { setColor(_color) }}
                        key={idx}

                        style={{ backgroundColor: StatusColorMap[_color], width: 52, height: 52, margin: '0px 10px 10px 0', borderRadius: 2, cursor: 'pointer' }}>
                        {color === _color ? <Icon style={{ color: '#1B1E2A' }}>{t('check')}</Icon> : null}
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                {t('Cancel')}
            </Button>
            <Button onClick={() => { if (!color || !labelName) return; handleClickSave({ title: labelName, taskStatusColor: color, description: labelDesc, statusType: 2 }); reset() }} color="primary" >
                {t('Save')}
            </Button>
        </DialogActions>
    </Dialog>
}

const EditLabelModal = (props) => {
    const { handleClose, open, handleClickSave, t, label } = props

    const [labelName, setLabelName] = useState(label.title);
    const [labelDesc, setLabelDesc] = useState(label.description);
    const [color, setColor] = useState(label.taskStatusColor);

    const reset = () => {
        setLabelName('')
        setLabelDesc('')
        setColor(null)
    }

    const onClose = () => {
        handleClose()
        handleClickSave({ ...label, title: labelName, description: labelDesc, taskStatusColor: color, statusType: 2 });
        reset()
    }

    return <Dialog open={open} onRequestClose={onClose} onBackdropClick={onClose}>
        <DialogTitle>{t('Edit status color')}</DialogTitle>
        <DialogContent>
            <TextField fullWidth label={t("Name")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelName} margin="normal"
                onChange={(value) => { setLabelName(event.target.value) }} />
            <TextField fullWidth label={t("Description")} id="topic_name" InputLabelProps={{ shrink: true }} value={labelDesc} margin="normal"
                onChange={(value) => { setLabelDesc(event.target.value) }} />
            <Grid container>
                {customStatusColors.map((_color, idx) => (
                    <Grid container alignContent='center' justify='center' item
                        onClick={() => { setColor(_color) }}
                        key={idx}

                        style={{ backgroundColor: StatusColorMap[_color], width: 52, height: 52, margin: '0px 10px 10px 0', borderRadius: 2, cursor: 'pointer' }}>
                        {color === _color ? <Icon style={{ color: '#1B1E2A' }}>{t('check')}</Icon> : null}
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
        {/* <DialogActions>
            <Button onClick={onClose} color="primary">
                {t('Cancel')}
            </Button>
            <Button onClick={() => { if (!color) return; handleClickSave({ ...label, title: labelName, description: labelDesc, taskStatusColor: color, statusType: 2 }); reset() }} color="primary" >
                {t('Save')}
            </Button>
        </DialogActions> */}
    </Dialog>
}

class _OrganizationStatusColors extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            labels: [],
            loaded: false,
            modalOpen: false,
            editModalOpen: false,
            editLabel: null,
        }
    }

    componentDidMount() {
        const { adminService } = this.props
        this.setState({ labels: [], loaded: true })
        adminService.getAllOrgStatus().then(labels => this.setState({ labels, loaded: true }))
    }

    edit = (label) => {
        this.setState({ editModalOpen: true, editLabel: label })
    }

    save = (label) => {
        const { adminService, dispatch } = this.props;

        adminService.createOrgStatus(label).then(() => {
            this.setState({ modalOpen: false })
            adminService.getAllOrgStatus().then(labels => {
                dispatch(globalDataLoad({ orgStatuses: labels }));
                dispatch(setOrgStatuses(labels))
                this.setState({ labels, loaded: true })
            })

        })
    }

    saveEdit = (label) => {
        const { adminService, dispatch } = this.props;

        adminService.editOrgStatus(label).then(() => {
            this.setState({ editModalOpen: false, editLabel: null })
            adminService.getAllOrgStatus().then(labels => {
                dispatch(globalDataLoad({ orgStatuses: labels }));
                dispatch(setOrgStatuses(labels))
                this.setState({ labels, loaded: true })
            })
        })
    }

    delete = (label) => {
        const { adminService, dispatch } = this.props;

        adminService.deleteOrgStatus(label.id)
            .then(() => {
                adminService.getAllOrgStatus().then(labels => {
                    dispatch(globalDataLoad({ orgStatuses: labels }));
                    dispatch(setOrgStatuses(labels))
                    dispatch(hideConfirm())
                    this.setState({ labels, loaded: true })
                })
            })

    }

    showConfirm = (label) => {

        const { dispatch, t } = this.props;

        dispatch(showConfirm(t("You will not be able to undo this action; By clicking delete, you remove this status."), () => {
            dispatch(confirmLoading())
            dispatch(showSnackbar("Deleting..."))
            this.delete(label)
        }, false))
    }


    getDefaultStatuses = () => {
        return defaultStatuses.map(x => ({ ...x, title: this.props.t(x.title), description: this.props.t(x.description) }))
    }

    isDefaultStatus = (color) => {
        return defaultStatusesColors.includes(color)
    }


    render() {
        const { classes, t } = this.props;
        const { labels: _labels, loaded, modalOpen, editModalOpen, editLabel } = this.state;

        const spinner = <div data-intrcm-id="OrgStatusColorsSettings_xltcm0w4" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>
        let content = spinner;

        const labels = [...this.getDefaultStatuses(), ..._labels].sort((a, b) => b.id - a.id)


        if (loaded) {
            content = <List>
                {
                    labels.map((label, idx) => (
                        <ListItem
                            key={idx}
                            button
                            disabled={this.isDefaultStatus(label.taskStatusColor)}
                            onClick={() => { if (this.isDefaultStatus(label.taskStatusColor)) return; this.edit(label) }} >
                            <div data-intrcm-id="OrgStatusColorsSettings_4xgfn01v" style={{ width: 20, height: 20, border: 'none', background: StatusColorMap[label.taskStatusColor] || '#B1B1B3', borderRadius: 10 }} />
                            <ListItemText
                                primary={label.title}
                                secondary={label.description ?? ''} />
                            {!this.isDefaultStatus(label.taskStatusColor) && <ListItemIcon >
                                <Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.showConfirm(label) }} >{'clear'}</Icon>                                
                            </ListItemIcon>}
                        </ListItem>
                    ))
                }
            </List>
        }



        return <div data-intrcm-id="OrgStatusColorsSettings_pk7fzi7r">
            {/* <HelpButtonTooltip link='articles/6075070-how-to-use-labels-in-the-system'> */}
            <AdminSettingsTitle text={t('Statuses colors')} />
            {/* </HelpButtonTooltip> */}

            <Paper className={classes.paperStyle}>

                {content}
                <Button
                    className={classes.button}
                    color="primary"
                    variant="raised"
                    onClick={() => { this.setState({ modalOpen: !modalOpen }) }}>
                    {t('Create new status color')}
                </Button>

                <CreateNewModal t={t} open={modalOpen} handleClose={() => { this.setState({ modalOpen: false }) }} handleClickSave={this.save} labels={labels} />
                {!editLabel ? null : <EditLabelModal t={t} label={editLabel} open={editModalOpen} handleClose={() => { this.setState({ editModalOpen: false, editLabel: null }) }} handleClickSave={this.saveEdit} />}
            </Paper>
        </div>

    }
}

const OrganizationStatusColors = (props) => {
    return <_OrganizationStatusColors {...props} />
}
const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    deleteCertificationId: state.confirmDialog.deleteCertificationId,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('admin'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(OrganizationStatusColors);