import React, { Component } from 'react';
import { Dialog, withStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DATA_FEED_UPDATE, RESULT_CHANNEL_EMPTY } from '../../../../actionTypes/actionTypes';
import { push } from 'react-router-redux';


// export default TemplateInitModal;

const styles = theme => ({


});

class TemplateInitModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
            templateUID: '',
            templateLoaded: false,
            templateBeingApplied: false,
            templateApplied: false

        }
    }

    loadTemplateInfo = (templateUID) => {

        this.props.adminService.getTemplate(templateUID).then((result) => {

            this.setState({
                open: true,
                template: { ...result },
                templateLoaded: true
            })
        });

    }


    checkTheIfTemplateState = () => {
        let templateUID = this.props.location.query.template
        if (templateUID && !this.state.templateLoaded) {
            this.loadTemplateInfo(templateUID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkTheIfTemplateState()
    }

    componentDidMount() {
        this.checkTheIfTemplateState()
    }
    goHome = () => {
        const { dispatch } = this.props;
        this.setState({
            open: false,
            templateUID: '',
            templateLoaded: false,
            templateBeingApplied: false,
            templateApplied: false

        })
        dispatch(push('/feed'));
    }

    handleApply = () => {
        this.setState({ templateBeingApplied: true })
        this.props.adminService.applyTemplate(this.state.template).then((result) => {
            // Reload the page without a template param                        
            this.goHome()
            window.location.reload();

        })
    }

    handleClose = () => {
        this.setState({ open: false });
        this.goHome()
    }

    render() {

        const { open, templateUID, templateBeingApplied } = this.state;
        const { name, author, classes, location, t } = this.props;


        if (open) {
            return <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {t('Apply template')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('You have chosen a ')}  <b> {this.state.template.name}</b>  {t(' template')}
                        <br />
                        {t('Do you want to apply this template to your organization?')}<br />
                        {t('New Groups, Custom inputs and Items may be created.')}<br/>

                        {templateBeingApplied ? <b>Loading template</b> : null}
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} aria-label="delete" className={classes.button}>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.handleApply} aria-label="save" className={classes.button}>
                        {t('Apply')}
                    </Button>
                </DialogActions>
            </Dialog>

        }
        return null
    }
}



const mapStateToProps = (state, ownProps) => ({
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TemplateInitModal);