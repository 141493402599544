import React, { Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Divider, TextField, Icon, DialogActions, DialogContent, Button } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Label from './Label.js';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    textField: { margin: '8px 0 24px 0px' }
});




class ChooseLabel extends React.PureComponent {
    state = {
        search: ''
    };

    search = (e) => {
        this.setState({ search: e.target.value })
    }

    labels = () => {
        const { collectionLabels } = this.props;
        const { search } = this.state

        let res = []
        if (search.length == 0) {
            res = collectionLabels
        } else {
            res = collectionLabels.filter(label => label.name.toLowerCase().includes(search.toLowerCase()))
        }

        return res
    }

    render() {
        const { collectionLabels, createNewLabel, docId, docCollectionLabels, edit, classes, disabled, t, isAdmin } = this.props;
        if (!collectionLabels) { return null }

        return (
            <Fragment>
                <DialogContent style={{ width: 332 }}>
                    <Grid container >
                        <TextField
                            id="standard-search"
                            label={t("Search labels")}
                            type="search"
                            fullWidth={true}
                            className={classes.textField}
                            value={this.state.searchText}
                            onChange={this.search} />
                        <Grid container direction='column' wrap='nowrap'  >
                            {this.labels().map((label, idx) => <Label disabled={disabled} load={this.props.load} isAdmin={isAdmin} changeLabels={this.props.changeLabels} edit={edit} docCollectionLabels={docCollectionLabels} docId={docId} label={label} key={idx} />)}
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createNewLabel} size='small' color='primary'>
                        {t('Create a new label')}
                    </Button>
                </DialogActions>
            </Fragment>)
    }
}


const mapStateToProps = (state) => ({
    collectionUid: state.results.channel.collectionUid,
    collectionLabels: state.userData.collections.find(collection => collection.collectionUid == state.results.channel.collectionUid).collectionLabels,
    ...state.services
})


export default compose(withStyles(styles),withTranslation('labelMenu'),
    connect(
        mapStateToProps,
        null
    ))(ChooseLabel);