import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer';
import { NewUiWrapper, managerGroupChat } from '@tuqqi/web';
import {store} from '../../../../index'
import { themeConstants } from '../../../../theme';
import { MockForSetTableGlobalData } from '../tableNew/MockForSetTableGlobalData.js';
import { FromGroupChat } from '../../chat/chat.js';
import { clearChatGroup, putChatGroup } from '../../chat/chatRecognize.js';

const styles = theme => ({
    wrap: {

    },
    table: {
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: 'transparent',
    },
    table1: {
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: 'transparent',
    },
})

export class GroupChatView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {
        putChatGroup(this.props.groupUid, this.props.collectionUid)
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerGroupChat.fetch(this.props.groupUid, this.props.collectionUid)
    }

    componentWillUnmount() {
        clearChatGroup()
    }

    componentDidUpdate(prevProps) {
        if(this.props.collectionUid === prevProps.collectionUid && this.props.groupUid === prevProps.groupUid) return

        if(!this.props.collectionUid || !this.props.groupUid) return

        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        putChatGroup(this.props.groupUid, this.props.collectionUid)
        managerGroupChat.fetch(this.props.groupUid, this.props.collectionUid)
    }

    render() {
        const { classes, isDrawerOpen,groupUid, collectionUid } = this.props;

        const callbacks = {
        }

        const show = groupUid && collectionUid
     
        return <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="GroupTableNewView_3b644a2o" className={isDrawerOpen ? classes.table : classes.table1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            <MockForSetTableGlobalData />
                            {show && <div className={classes.wrap} style={{width: `calc(100vw - ${isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth : themeConstants.navigationDrawerSmallPXWidth}px)`}}>
                                <FromGroupChat {...this.props} groupUid={groupUid} />
                            </div>}
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    search: state.search,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading Chat, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupChatView);