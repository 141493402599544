export default class EventService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    getHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token
        });
    }

    //todo add property in order to know that it's not from google
    //todo return new events
    createEvent(eventObj) {
        eventObj.source = 'userpost'
        return fetch(process.env.API_SERVER + '/calendar/add', {
            method: 'PUT',
            body: JSON.stringify(eventObj),
            headers: this.postHeaders()
        }).then(res => res.json());
    }

    getCalendar(size = 3) {

        return fetch(`${process.env.API_SERVER}/calendar/?page=${0}&size=${3}`, {
            method: 'GET',
            headers: this.getHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // updateEvent(eventObj) {
    //     return fetch(process.env.API_SERVER + '/event/update', {
    //         method: 'POST',
    //         body: JSON.stringify(group),
    //         headers: this.postHeaders()
    //     }).then(res => res.json());
    // }

    // deleteGroup(eventId) {
    //     return fetch(process.env.API_SERVER + '/event?id=' + eventId,
    //         {
    //             method: 'DELETE',
    //             headers: this.getHeaders()
    //         })
    //         .then(function (res) {
    //             if(res.status === 401){                                
                // console.log(res);
            //     this.onUnauthorized();
            // }
                        
            // return res.json();
    //         });
    // }
}