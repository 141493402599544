import React, { Component } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import renderInput from './inputComponent'
import renderSuggestionsContainer from './suggestionContainerComponent'
import renderSuggestion from './suggestionComponent'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    containerClose: {
        boxShadow: 'none'
    },
    container: {
        flexGrow: 1,
        position: 'relative',
        // height: 200,
    },
    suggestionsContainerOpen: {
        zIndex: 10000,
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 3,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04);',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    textField: {
        width: '100%',
    },
});

class IntegrationAutosuggest extends React.PureComponent {
    state = {
        value: '',
        suggestionSelected: false,
        suggestions: [],
        userMentions: [],
    };

    getSuggestions = value => {
        this.query = value;
        return this.props.queryService.autosuggest(value.toString());
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        var valueForSuggest = value;

        //split the space and the line break from texk
        var vals =  value.split(/\s/gm)// value.split(" ");
        if (vals.length > 1) {
            valueForSuggest = vals[vals.length - 1];
        }
        if (valueForSuggest.charAt(0) === '#') {
            this.getSuggestions(valueForSuggest).then((values) => { this.setState({ suggestions: values }); })
        } else if (valueForSuggest.charAt(0) === '@' && valueForSuggest.length >1) {
            const slicedValueForSuggest = valueForSuggest.slice(1)
            this.setState({
                suggestions: (this.state.userMentions ?? []).filter(x => x.displayName.includes(slicedValueForSuggest)).slice(0, 10)
            });
        } else if(this.state.suggestions.length){
            // Empty the suggestion
            this.setState({ suggestions: []});
        }
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getSuggestionValue = suggestion => {
        const suggestionText = suggestion.tag || suggestion.value;

        if (suggestionText == null) {
            return suggestion.query || this.props.value || "";  
        } else{
            const splitedQuery = this.props.value.split(" ")
      
            // Split the last word from the other words
            const otherWords = splitedQuery.length > 1 ? (splitedQuery.slice(0, -1).join(' ') + ' ') : ''
            // const lastWord = splitedQuery[splitedQuery.length - 1]
      
            // The prefix of the last word
            let prefix = suggestion.prefix || "" 
      
            const res = otherWords + prefix + suggestionText
            
            return res
        }
    };

    onKeyPress = (event) => {
        const { allowRows, onSubmit } = this.props
        
        if (event.key.toLowerCase() === 'enter') { // enter key pressed
            if(!allowRows || this.state.suggestionSelected) {
                 event.preventDefault();
            }

            if (this.state.suggestionSelected) {
                this.setState({ suggestionSelected: false });
            } else {
                onSubmit && onSubmit()
            }
        }
    }

    componentDidMount() {
        var users = [];
        this.props.users.forEach((user) => {

            // Check if user name is initiated
            if (typeof user.firstname !== 'undefined' && typeof user.lastname !== 'undefined') {
                let tempUser = {
                    prefix:'@',
                    displayName: (user.firstname + user.lastname).toLowerCase(),
                    description: user.firstname + ' ' + user.lastname,
                    tag: user.tag.toLowerCase(),
                    type: 'Person',
                    avatar: user.profileImageUrl
                }
                users.push(tempUser);
            }
        })
        this.setState({ userMentions: users })
    }
    onSuggestionSelected = () => { this.setState({ suggestionSelected: true }); }

    render() {
        const { classes, value, onChange, onKeyPress, placeholder, t, allowRows } = this.props;

        return (
            <Autosuggest
                theme={{
                    container: classes.container,
                    suggestionsContainer: classes.containerClose,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                
                renderInputComponent={renderInput}
                suggestions={this.state.suggestions}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    t,
                    id:'autosuggest',
                    placeholder,
                    classes,
                    autoFocus: true,
                    value: value,
                    onChange: onChange,
                    onKeyPress: this.onKeyPress,
                    allowRows
                }}
            />

        );
    }
}

IntegrationAutosuggest.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        searchQuery: state.search,
        users: state.globalData.activeUsers,
        ...state.services,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
withErrorCatcher(),
withTranslation('renderInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(IntegrationAutosuggest);