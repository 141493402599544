import React from 'react'
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core';
import { required, confirmation, length } from 'redux-form-validators'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const validations = {
    Firstname: [required(), length({ max: 100 })],
    Lastname: [required(), length({ max: 100 })],
    // Email: [required(), email()],
    // Title:[length({ max: 100 })],
    Username: [required()],
    Password: [required()],
    password2: [confirmation({ field: 'Password', fieldLabel: 'Password' })]

}

const validate = values => {
    
    const errors = {}
    for (let field in validations) {
        let value = values[field]
        errors[field] = validations[field].map(validateField => {
            return validateField(value, values)
        }).find(x => x)
    }
    return errors
}
const styles = theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 16,
        marginRight: theme.spacing.unit,
        width: '95%',
    },
    menu: {
        width: 200,
    },
    avatarTuqqi : {
        borderRadius:0
    },
    text : {
        fontSize:20,
        fontWeight:theme.typography.button.fontWeight
        
    },
    buttonDiv : {
        textAlign:'right',
        marginRight:20
    }



});

const warn = values => {
    const warnings = {}
    if (values.age < 19) {
        warnings.age = 'Hmm, you seem a bit young...'
    }
    return warnings
}

// Convert userName to be loewr-case
const convertTagToLowerCase = (input) => {
    if(input.name == "Username" && input.value){
        input.value = input.value.replace(/\s/g, '').toLowerCase()
    }
}

const renderField = ({ input, label, type, name, className, meta }) => {

    convertTagToLowerCase(input)

    const  { touched, error, warning } = meta
    return <TextField
        error={touched && error}
        id={name}
        label={label}
        {...input}
        type={type}
        className={className}
        helperText={touched && error ? error : 'Please enter ' + label}
        margin="normal"
    />
}


const asyncValidate = (values) => {
    return IsUserTagExists(values.Username).then(res => {
        if(res){
            throw { Username: 'This username already exists' }
        }
    })
  }

const IsUserTagExists = (userTag) => {

    var myHeaders = new Headers({
        'Content-Type': 'application/json'
    });

    return fetch(`${process.env.API_SERVER}/users/IsUserTagExists/?usertag=${userTag}` , {
        method: 'GET',
        headers: myHeaders
    })
        .then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        })
        .catch((error) => {
            return true;
    });
}


const SyncValidationForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, classes,onSubmit } = props

    return (<div data-intrcm-id="verifyForm_2cvbifkp">
        <List>
        <ListItem>
            <Avatar className={classes.avatarTuqqi} src="./system/Tuqqi_logo_trans_WhiteLabel3232.png"/>
            
            <ListItemText classes={classes}  primary={'Tuqqi user signup verification form'} />
            

        </ListItem>
        <Divider/>
    </List>
        <form  onSubmit={handleSubmit(onSubmit)}>

            {/* <Field name="Email" type="email" component={renderField} className={classes.textField} label="Email" /> */}
            <Field name="Firstname" type="text" component={renderField} className={classes.textField} label="First name" />
            <Field name="Lastname" type="text" component={renderField} className={classes.textField} label="Last name" />
            <Field name="Username" type="text" component={renderField} className={classes.textField} label="Username (Tag)" />
            {/* <Field name="Title" type="text" component={renderField} className={classes.textField} label="Title" />
            <Field name="ProfileImageUrl" type="text" component={renderField} className={classes.textField} label="ProfileImageUrl" /> */}

            <Field name="Password" type="password" component={renderField} className={classes.textField} label="Password" />
            <Field name="password2" type="password" component={renderField} className={classes.textField} label="Verify password" />
            {/* <Field name="orgUid" type="text" component={renderField} className={classes.textField} label="Organization name" /> */}
            <FormControlLabel className={classes.textField}
                control={
                    <Checkbox
                        // checked={this.state.checkedB}
                        
                        value="checkedB"
                        color="primary"
                    />
                }
                label={<a href="#"  onClick={props.handleModal} >I accept Tuqqi privacy policy</a>}
            />
            <div data-intrcm-id="verifyForm_uf3hysye" className={classes.buttonDiv}>
                <Button variant="raised" type="submit" disabled={submitting} >Submit</Button>
                {/* <button type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button> */}
            </div>
        </form>
        <br/>

        </div>
    )
}

export default compose(withStyles(styles), reduxForm({
    form: 'syncValidation',  // a unique identifier for this form
    validate,                // <--- validation function given to redux-form
    warn     ,                // <--- warning function given to redux-form
    asyncValidate,
    asyncBlurFields: [ 'Username' ]
}))(SyncValidationForm)