import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import DataTypeSelection from "./DataTypeSelection";
import FieldsSelection from './FieldsSelection';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import ExportMenu from './ExportMenu';
import MenuButton from '../MenuButton';
import SavedReportSelection from './SavedReportSelection';
import SettingsIcon from '../../../../components/Common/Icons/AppInUse/SettingsIcon';
import SaveIcon from '../../../../components/Common/Icons/AppInUse/SaveIcon';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const styles = theme => ({
    container: {
        marginBottom: 24,
        flex: '0 0 auto'
    },
    containerOfButtons: {

        flex: '0 0 auto'
    },
    leftSideContainer: {
        width: 'initial'
    },
    button: {
        width: 301

    },
    settingsIcon: {
        margin: 'auto'
    },
    buttonContainer: {
        marginRight: 16,
        cursor: 'pointer',
        width: '44px',
        "height": "44px",
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray400}`,
        "backgroundColor": theme.newUI.color.gray50
    },

});

class EnhancedTableToolbar extends Component {
    state = {
        open: false,
        text: ''
    }

    onSaveReport = () => {
        this.props.saveReport(this.state.text)
        this.setState({ open: false, text: '' });
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    isAllowSaveReport = () => {
        const {text} = this.state
        const {savedReports} = this.props
        const names = savedReports?.map(r => r.reportName) || []
        if(!text || names.includes(text)) return false
        return true
    }


    render() {
        const { classes, onSelectFields, totalResultSize, columns, userData, groupInfo, savedReports, t, isFromMyPageTasks, firstAllResultsLength } = this.props;
        let adminIds = []
        if (groupInfo.collectionGroup) {
            adminIds = groupInfo.collectionGroup.adminIds;
        }

        return (
            <Grid container justify='space-between' alignContent='center' className={classes.container} wrap='nowrap'>
                <Grid item>
                    <Grid container alignContent='center' className={classes.containerOfButtons} wrap='nowrap'>
                        <DataTypeSelection firstAllResultsLength={firstAllResultsLength} />
                        {savedReports ? <div data-intrcm-id="EnhancedTableToolbar_nz2n29x6" style={{ marginLeft: 8 }}><SavedReportSelection savedReports={savedReports} /></div> : null}
                    </Grid>
                </Grid>

                <Grid item container className={classes.leftSideContainer}>

                    {savedReports ?
                        <Tooltip
                            classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                            title={t("Save")}
                            placement="bottom"
                        ><Grid item container alignItems='center' onClick={this.handleClickOpen} className={classes.buttonContainer}>
                                <SaveIcon fill='#979aaa' className={classes.settingsIcon} isTowTone={false} />
                            </Grid>
                        </Tooltip> : null}


                    <FieldsSelection onSelectFields={onSelectFields} />
                    {adminIds.includes(userData.profileData.id) || isFromMyPageTasks? <ExportMenu
                        firstAllResultsLength={firstAllResultsLength}
                        totalResultSize={totalResultSize}
                        columns={columns}
                    /> : null}
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{t("Save report")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Type the report name")}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t("Report name")}
                            fullWidth
                            value={this.state.text}
                            onChange={(e) => { console.log(event.target.value); this.setState({ text: event.target.value }) }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t("Cancel")}
                        </Button>
                        <Button disabled={!this.isAllowSaveReport()} onClick={this.onSaveReport} color="primary">
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }

}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    groupInfo: state.results.channel,

})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('myReportsView'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(EnhancedTableToolbar);