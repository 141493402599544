import { Divider, withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import React, { Component } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { compose } from 'recompose';
import UserListItemCustom from '../../../Common/UserListItemCustom';
import SubTitle from './SubTitle';
import moment from 'moment'
import { withTranslation } from 'react-i18next';

const styles = () => ({
    rightText: {
        fontFamily: 'Roboto',
        fontSize: 14,
        color: '#3ca647',
    },
    listItem: {
        paddingRight: 0,
        paddingLeft: 0
    }
});

class ConfirmedUsersList extends Component {
    isToday = (dateTime) => {
        let today = new Date();

        return dateTime.getFullYear() === today.getFullYear() &&
        dateTime.getMonth() === today.getMonth() &&
        dateTime.getDate() === today.getDate();
    }

    getTime = (dateTime) => {
        let momentDateTime = moment(dateTime);

        if( this.isToday(new Date(momentDateTime)) ){
            return `${momentDateTime.format("HH:mm")}`
        }

        return `${momentDateTime.format("DD.MM.YYYY HH:mm")}`
    }

    render() {
        const { confirmedUsers, classes, title} = this.props;

        return confirmedUsers && confirmedUsers.length 
            ? <div data-intrcm-id="ConfirmedUsersList_078bzlk6" style={{ paddingBottom: 18 }}>
                {title ? <SubTitle subTitle={title} /> : <div data-intrcm-id="ConfirmedUsersList_tijw147y" />}
                <Divider />
                <List style={{ paddingLeft: 24, paddingRight: 24 }}>
                    {confirmedUsers.map((user, idx) => (
                        <UserListItemCustom
                            disabled={false}
                            isButton={false}
                            user={user}
                            key={idx}
                            classes={classes}
                            rightElement={<span style={{
                                fontFamily: 'Roboto',
                                fontSize: 14,
                                color: '#3ca647',
                                paddingRight: 8,
                            }}>{this.getTime(user.dateTime)}</span>}
                        />
                    ))}
                </List>
            </div>
            : <div data-intrcm-id="ConfirmedUsersList_ymm31gi7" />
    }
}

export default compose(withStyles(styles), withTranslation('mustReadListModal'))(ConfirmedUsersList);