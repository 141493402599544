import { Icon, IconButton, Tooltip, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { changeCollectionInputInfo, openInput } from '../../../actions/inputActions.js';
import { getDataType } from '../Common/Utils/defaultDataTypes.js';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { getPublicDocumentPermission, getGroupDocumentPermission } from '@tuqqi/common';
import { FULLVIEW_CLOSE } from '../../../actionTypes/actionTypes.js';

const styles = theme => ({
    selected: {
        color: 'black',
        fontWeight: 'bolder'
    }

});

export const ItemOpenerGroup = {
    groupUid: ''
}

class OtehrInput extends PureComponent {


    onClick = () => {
        const { oldFullview, dispatch, dataType, oldUiInput, userData, searchQuery, inputMappings, collectionInputInfo, changeCollectionInputInfo, isCustomInput } = this.props

        const schedule = {
            dateTimeFrom: oldFullview?.dateTimeFrom,
            dateTimeTo: oldFullview?.dateTimeTo,
            dueDateUTC: oldFullview?.dueDateUTC,
        }

        let groupUid = searchQuery.groupUid
        if(!!ItemOpenerGroup?.groupUid) {
            groupUid = ItemOpenerGroup.groupUid
        }

        if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
            dispatch({ type: FULLVIEW_CLOSE })

            const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
            let newDoc = { html: '', title: '', permissions, dataType: dataType, ...schedule }
            if (collectionInputInfo.kanbanListId) {
                newDoc.kanbanListId = collectionInputInfo.kanbanListId+'';
            }
            if (collectionInputInfo.ganttListId) {
                newDoc.ganttListId = collectionInputInfo.ganttListId+'';
            }

            dispatch(openNewUiInput(newDoc, dataType))
        } else {
            openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)

            if (collectionInputInfo.kanbanListId) {
                changeCollectionInputInfo({ kanbanListId: collectionInputInfo.kanbanListId+'' })
            }
        }
    }

    changedAbleTypes = ['post', 'bookmark', 'question', 'poll']

    render() {
        const { classes, dispatch, title, dataType, icon, onClick, currDataType, boldWhenSelected } = this.props

        if (!this.changedAbleTypes.includes(currDataType)) {
            return null
        }

        let inputTitle = title || dataType
        let inputIcon = icon

        const defaultDataType = getDataType(dataType)

        if (defaultDataType) {
            inputTitle = defaultDataType.title
            inputIcon = defaultDataType.icon
        }

        return (
            <Tooltip title={inputTitle} placement="bottom" >
                <IconButton onClick={onClick || this.onClick}>
                    <Icon className={(currDataType == dataType ? classes.selected : '')}>{inputIcon}</Icon>
                </IconButton>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => ({
    searchQuery: state.search,
    groupInfo: state.results.channel,
    userData: state.userData,
    currDataType: state.oldUiInput.document.dataType,
    oldUiInput: state.oldUiInput.document,
    collectionInputInfo: state.oldUiInput.collectionInputInfo,
    inputMappings: state.globalData.inputMappings,
    profileData: state.userData.profileData,
    oldFullview: state.fullView.item
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
})

export default compose(withStyles(styles),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(OtehrInput);

