
export default function parseImgeUrl(cloudfront, imageUrl, width = 50, defaultImageUrl = '') {
    if (!imageUrl || imageUrl === '/7266b08ebe7579ce6e1e.png') {
        return defaultImageUrl
    }

    let processedImageUrl = imageUrl
    if (cloudfront && cloudfront.signed && imageUrl && !imageUrl.startsWith('http')) {
        let replacedImageUrl = imageUrl.replace("fileId=", '')        
        processedImageUrl = cloudfront.signed.replace('*', 'fit-in/' + width + 'x' + 0 + '/' + encodeURIComponent(replacedImageUrl));
    }

    return processedImageUrl
}



