import React, { useState } from "react";
import { ViewMode } from "gantt-task-react";
import { withStyles,Checkbox } from '@material-ui/core'
import { compose } from 'recompose';
import { useTranslation } from "react-i18next";

const styles = theme => ({
  viewContainer: {   
       display: 'flex',
       flexDirection: 'row',
       justifyContent: 'space-between',
       alignItems: 'center',
       padding: 16,
    },
    buttonsContainer: {
    },
    button: {
      fontSize: 14,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: '#8645F9',
      backgroundColor: '#FFFFFF',
      border: '1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    buttonActive: {
      fontSize: 14,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: 'white',
      backgroundColor: '#8645F9',
      border: ' 1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    firstButton: {
      fontSize: 14,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: '#8645F9',
      backgroundColor: '#FFFFFF',
      border: '1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    firstButtonActive: {
      fontSize: 14,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: 'white',
      backgroundColor: '#8645F9',
      border: ' 1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    lastButton: {
      fontSize: 14,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: '#8645F9',
      backgroundColor: '#FFFFFF',
      border: '1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    lastButtonActive: {
      fontSize: 14,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      fontFamily: 'Rubik',
      padding: `4px 10px 4px 10px`,
      color: 'white',
      backgroundColor: '#8645F9',
      border: ' 1px solid #8645F9',
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
    taskListLabel: {
      fontFamily: 'Rubik',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.09842514246702194,
    },
})

const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  classes,
}) => {
  const [selected, setSelected] = useState(ViewMode.Day)

  const [t] = useTranslation('gantt');

  const handleViewMode = (viewMode) => {
    setSelected(viewMode)
    onViewModeChange(viewMode)
  }

  return (
    <div data-intrcm-id="ViewSwitcher_9kmo347v" className={classes.viewContainer}>
      <div data-intrcm-id="ViewSwitcher_avocpd28" className={classes.buttonsContainer}>
      <button
        className={selected === ViewMode.QuarterDay ? classes.firstButtonActive : classes.firstButton}
        onClick={() => handleViewMode(ViewMode.QuarterDay)}>
        {t('Quarter of Day')}
      </button>
      <button
        className={selected === ViewMode.HalfDay ? classes.buttonActive : classes.button}
        onClick={() => handleViewMode(ViewMode.HalfDay)}>
         {t('Half of Day')}
      </button>
      <button
        className={selected === ViewMode.Day ? classes.buttonActive : classes.button}
        onClick={() => handleViewMode(ViewMode.Day)}>
          {t('Day')} 
      </button>
      <button
        className={selected === ViewMode.Week ? classes.buttonActive : classes.button}
        onClick={() => handleViewMode(ViewMode.Week)}>
          {t('Week')} 
      </button>
      <button
        className={selected === ViewMode.Month ? classes.lastButtonActive : classes.lastButton}
        onClick={() => handleViewMode(ViewMode.Month)}>
       {t('Month')} 
      </button>
      </div>
      <div data-intrcm-id="ViewSwitcher_74acgw7w" className={classes.taskListLabel}>
             <Checkbox 
                    style={{ color: isChecked && '#8645F9'  }}
                    data-testid='checkbox'
                    checked={isChecked}
                    onChange={() => onViewListChange(!isChecked)} 
                    />
         {t('Show Task List')} 
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(ViewSwitcher);