import { extractDisplayedViews, managerDrawerOption } from '@tuqqi/web';
import { push } from 'react-router-redux';
import { managerLastGroupsView } from '../../managers/managerLastGroupsView';

export default (collectionTitle, dispatch, collectionUid, groupUid, collection, originPathname) => {
    let view = collection && collection.defaultView ? collection.defaultView : ''
    let availableViews = collection?.availableViews ?? ''

    const lastGroupViews = managerLastGroupsView.getKeep() ?? []
    if(lastGroupViews.length) {
        const x = lastGroupViews.find(y => y.collectionUid === collectionUid)
        if(!!x?.view) {
            const opts = extractDisplayedViews(availableViews, view)
            if(opts.includes(x.view)) {
                view = x.view
            }
        }
    }
    let path = `/group/${view}`
    if (originPathname) {
        path = originPathname
    }
    // if(path === '/group/calendar') path = '/group/calendarnew'
    let title = collectionTitle ? collectionTitle.substring(1) : ''
    if (collection && collection.title) {
        title = collection.title
    }


    return (cb) => {
        if(!!collection && collection.defaultView === 'knowledge') {
            managerDrawerOption.setOption({
                prop: 'category',
                uid: groupUid
            })
            dispatch(push({ pathname: '/library'}))
            return
        }
        dispatch({
            type: 'SEARCH_QUERY', query: {
                query: '',
                page: 0,
                collectionUid: collectionUid ? collectionUid : null,
                groupUid: groupUid,
                collection: collection,
                collectionTitle: title,
                isCollection: true,
            }
        })
        dispatch({ type: 'DATA_UPDATE' });
        // dispatch({ type: 'RESULT_CHANNEL_EMPTY' })

        dispatch(push({ pathname: path, search: '?searchquery=' + encodeURIComponent("/" + collectionUid) }))
        if(cb && typeof cb === 'function'){
            cb()
        }
    }
}
