import {  SHOW_VIDEO_CHAT, HIDE_VIDEO_CHAT, FULL_RESET_STORE } from '../actionTypes/actionTypes'

const initialState = []
const snackBar = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case SHOW_VIDEO_CHAT:
      return {
        ...state,
        isOpen: true,
        docId: action.docId,
        title: action.title,
      }
    case HIDE_VIDEO_CHAT:
      return {
        ...state,
        isOpen: false,
        docId: '',
        title: '',
      }
    default:
      return state
  }
}

export default snackBar