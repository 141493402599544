import { withStyles } from '@material-ui/core'
import { isDiscussions, updateLastUserEnterChatDiscussions, updateLastUserEnterChatDM, updateNewCommentsChat } from '@tuqqi/common'
import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { compose } from 'recompose'
import { SERVER_API } from '../../../../../../utils/serverAPI'
import { createCustomTheme } from '../../../../../../utils/theming'
import { DirectMessages, Discussions, GroupMessages } from '../../../../../views/chat/chatUtils'
import { QuickReplyIcon } from '../../../../Common/Icons/Chat/QuickReplyIcon'
import { ViewInChatIcon } from '../../../../Common/Icons/Chat/ViewInChatIcon'

const styles = (theme) => ({
    linear: {
        width: 73,
        height: 40,
        position: 'absolute',
        right: 50,
        background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)'
    },
    container: {
        background: '#fff',
        position: 'absolute',
        right: 0,
        paddingRight: 8,
        marginRight: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    viewInChat: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        paddingLeft: 12,
        marginRight: 8
    },
    quickReply: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    icon: {

    },
    text: {
        marginTop: 4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 9,
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: theme.customColors.primary.main,
    }
})
const _Actions = (props) => {
    const {classes, t, brandingColor, option, dispatch, setIsReply} = props
    const colors = createCustomTheme(brandingColor);

    const chatUid = useMemo(() => {
        switch (option.chatType) {
            case DirectMessages:
            case GroupMessages:
                return option?.chat?.chatUId ?? ''
            case Discussions:
                return option?.document?.docId ?? ''
        
            default:
                return ''
        }
    }, [option])

    const openChat = useCallback(() => {
          if (!chatUid) {
            dispatch({type: 'BLUR_CHATS_MODAL'})
            dispatch(push({ pathname: "chat" }))
            return
          }
          dispatch({type: 'BLUR_CHATS_MODAL'})
          dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: option
          })
          dispatch(push(`/chat?chatid=${chatUid}`))
    }, [chatUid, option])

    const updateCommentsDifference = useCallback(() => {
        const id = isDiscussions(option.chatType) ? option.document.docId : option.chat.chatUId
        const commentsCounter = isDiscussions(option.chatType) ? option.document.commentsCounter + option.newComments : 0
        dispatch(updateNewCommentsChat(id, 0, commentsCounter))
        switch (option.chatType) {
            case Discussions:
                dispatch(updateLastUserEnterChatDiscussions(SERVER_API)(option.document.docId))
                break;
            case DirectMessages:
                dispatch(updateLastUserEnterChatDM(SERVER_API)(option.chat.chatUId))
                break;
            case GroupMessages:
                dispatch(updateLastUserEnterChatDM(SERVER_API)(option.chat.chatUId))
                break;
        }
    }, [option])

    const reply = useCallback(() => {
        updateCommentsDifference()
        setIsReply(true)
    }, [chatUid, option, updateCommentsDifference])


    const ViewInChat = <div data-intrcm-id="Actions_bhslfqjr" onClick={openChat} className={classes.viewInChat}>
        <div data-intrcm-id="Actions_nwxvmipn" className={classes.linear} />
        <ViewInChatIcon className={classes.icon} color={colors.primary.main} />
        <div data-intrcm-id="Actions_bxuq2dip" className={classes.text}>{t("View chat")}</div>
    </div>

    const QuickReply = <div data-intrcm-id="Actions_8127323e" onClick={reply} className={classes.quickReply}>
        <QuickReplyIcon className={classes.icon} color={colors.primary.main} />
        <div data-intrcm-id="Actions_qxomz9mv" className={classes.text}>{t("Quick reply")}</div>
    </div>
    return <div data-intrcm-id="Actions_7ifmubcd" className={classes.container}>
        {ViewInChat}
        {QuickReply}
    </div>
}


const mapStateToProps = (state) => {
    return {
        brandingColor: state.userData.profileData.org.brandingColor,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('chat'),
    connect(mapStateToProps, mapDispatchToProps),
)(_Actions)