import { Grid, Menu, MenuItem, withStyles, Checkbox, Divider, Button } from '@material-ui/core';
import React, { Component } from 'react';
import classnames from "classnames";
import { withTranslation } from 'react-i18next';

import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';

const styles = theme => ({
    menuItemsContainer: {
        maxHeight: 395,
        overflowY: 'auto',
        ...theme.newUI.scrollbar
    },
    menuItem: {
        flex: '0 0 auto',
        height: 60,
        padding: '0px 16px 0px 4px',
        '&:hover': {
            backgroundColor: '#f0f3ff'
        },
        '&:focus': {
            backgroundColor: '#daddee'
        }
    },
    menuItemSelected: {
        backgroundColor: '#f0e6fe !important'
    },
    menuItemText: {
        marginLeft: 16,
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.75",
        "letterSpacing": "0.5px",
        "color": theme.newUI.color.commonBlack
    },
    checked: {
        color: `${theme.newUI.color.primary} !important`
    },
    checkbox: {
        marginRight: 4
    },
    divider: {
        backgroundColor: theme.newUI.color.gray400
    },
    footer: {
        height: 40,
        padding: '0px 8px'
    },
    menu: {
        overflow: 'hidden',
        marginTop: -100
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    buttonText: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "color": theme.newUI.color.primary
    },

    button: {
        textTransform: 'initial'
    },
    selectAll: {
        "color": theme.newUI.color.commonBlack
    },
    title: {
        fontFamily: 'Rubik',
        height: 40,
        padding: '0px 16px',
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    disabled: {
        color: `${theme.newUI.color.gray} !important`
    },
    disableFocus: {
        outline: 'none'
    }
});

class ExportMenuDataTypeSelector extends Component {
    onSave = () => {
        const { onSave, onClose } = this.props;
        onSave()
        onClose()
    }

    onChoose = (selectedOption) => () => {
        const { onChoose, allowMultiple, onClose } = this.props;

        if (allowMultiple) {
            onChoose(selectedOption, allowMultiple, false)
            return
        }
        onChoose(selectedOption, allowMultiple, true)
        onClose()
    }

    render() {
        const { classes, t, anchorEl, allowMultiple, title, showMenu, options, onClose } = this.props;
        const isSelectedPresented = !!options.filter(o => o.selected).length

        return (
            <Menu
                dir='auto'
                MenuListProps={{
                    className: classes.list
                }}
                classes={{
                    paper: classes.menu,
                }}
                id="customized-menuuiyoiuyiuy"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={onClose}
                open={showMenu} >
                    <Grid container alignItems='center' className={classnames(classes.title, classes.disableFocus)} >{title}</Grid>
                    <Divider className={classnames(classes.divider, classes.disableFocus)} />
                    <Grid container direction='column' className={classes.menuItemsContainer} wrap='nowrap'>
                        {
                            options.map((option) => (
                                <MenuItem
                                    key={option.key}
                                    selected={option.selected}
                                    onClick={this.onChoose(option)}
                                    value={option.key}
                                    classes={{
                                        root: classes.menuItem,
                                        selected: classes.menuItemSelected,
                                    }} >
                                    <Grid container wrap='nowrap' alignItems='center'>
                                        {
                                            allowMultiple && (
                                                <Checkbox checked={option.selected}
                                                    classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                    }} />)
                                        }
                                        <span className={classes.menuItemText}>{t(option.label)}</span>
                                    </Grid>
                                </MenuItem>
                            ))
                        }
                    </Grid>

                    <Divider className={classnames(classes.divider, classes.disableFocus)} />
                    <Grid container wrap='nowrap' alignItems='center'
                        className={classnames(classes.footer, classes.disableFocus)}>
                        <Button classes={{
                            root: classes.button,
                            label: classes.buttonText
                        }}
                            onClick={onClose}
                            style={{ marginLeft: 'auto' }}>
                            {t('Cancel')}
                        </Button>
                        {
                            allowMultiple && (
                                <Button disabled={!isSelectedPresented} classes={{
                                    root: classnames(classes.button, classes.buttonText),
                                    disabled: classes.disabled
                                }}
                                    onClick={this.onSave}
                                    style={{ marginLeft: 8 }}>
                                    {t('Save')}
                                </Button>)
                        }
                    </Grid>
                </Menu>
        )
    }

}

export default compose(withStyles(styles),
    withTranslation('menuTableToolbarTranslate'),
    withErrorCatcher())(ExportMenuDataTypeSelector);