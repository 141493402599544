import React, { PureComponent } from 'react';

export const ViewInChatIcon = class ViewInChatIcon extends PureComponent {

    render() {
        const { color, className } = this.props
        return (
            <svg className={className} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.25 0C1.46403 0 0 1.46403 0 3.25V12.75C0 14.536 1.46403 16 3.25 16H16.75C18.536 16 20 14.536 20 12.75V3.25C20 1.46403 18.536 0 16.75 0H3.25ZM3.25 1.5H16.75C17.725 1.5 18.5 2.27497 18.5 3.25V3.80273L10 8.39746L1.5 3.80273V3.25C1.5 2.27497 2.27497 1.5 3.25 1.5ZM1.5 5.50781L9.64355 9.91016C9.75305 9.9693 9.87555 10.0003 10 10.0003C10.1245 10.0003 10.2469 9.9693 10.3564 9.91016L18.5 5.50781V12.75C18.5 13.725 17.725 14.5 16.75 14.5H3.25C2.27497 14.5 1.5 13.725 1.5 12.75V5.50781Z" fill={color}/>
            </svg>
        )
    }
}