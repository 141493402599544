import { managerGanttRef } from "@tuqqi/web";
import { isValid } from "date-fns";

export const resolveStartEndDateApi = (record, changes) => {
    if(!record.data?.isItemRow || !record.id) return
    const ok = !!('startDate' in changes) || !!('endDate' in changes)
    if(!ok) return

    let dateTimeFrom = new Date(record.startDate)
    let dateTimeTo = new Date(record.endDate)

    if('startDate' in changes) {
        dateTimeFrom = changes.startDate.value
    }

    if('endDate' in changes) {
        dateTimeTo = changes.endDate.value
    }

    const startValue = isValid(dateTimeFrom) ? dateTimeFrom.toISOString() : ''
    const endValue = isValid(dateTimeTo) ? dateTimeTo.toISOString() : ''

    managerGanttRef.updateStartEndApi(record, startValue, endValue, true)

}

export const resolveDepsApi = (record, changes) => {
    if(!record.data?.isItemRow || !record.id) return
    const ok = !!('deps' in changes)
    if(!ok) return

    const deps = changes?.deps?.value ?? []
    
    managerGanttRef.updateDepsApi(record, deps)

}

export const resolvePercentDoneApi = (record, changes) => {
    if(!record.data?.isItemRow || !record.id) return
    const ok = !!('percentDone' in changes)
    if(!ok) return

    const percentDone = changes?.percentDone?.value ?? 0
    
    managerGanttRef.updateProgressApi(record, percentDone)

}
