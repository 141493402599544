import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import GeneralButton from '../../../Common/AllButton/AllButton';
import { createCustomTheme } from '../../../../../utils/theming';
import { ReadIcon } from '../../../Common/Icons/Notifications/ReadIcon';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils';
import { NotifsSettingsIcon } from '../../../Common/Icons/Notifications/NotifsSettingsIcon';
import { withMixpanel } from '../../../Common/withMixpanel';
import { push } from 'react-router-redux';
import NotificationsList from './NotificationsList';
import { goToNotifications } from '../../../../actions/goToNotifications';
import { isNotificationDateSeen } from '../../../../../utils/notifications';

const styles = (theme) => ({
    header: {
        height: 52,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 16,
        marginLeft: 16,
    },
    headerTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: 0.24763,
        color: '#0C0C0D',
    },
    icon: {
        marginLeft: 8
    },
    buttonContainer: {
        marginRight: 0,
        paddingRight: 9,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main
        },
    },
    buttonText: {
        marginBottom: -1,
        fontSize: 12,
        fontWeight: '400',
        letterSpacing: 0.4,
    },
    settings: {
        cursor: 'pointer',
        display: 'flex',
        marginLeft: 16,
        marginRight: 'auto',
        alignItems: 'center',
        flexDirection: 'row'
    },
    settingsTitle: {
        marginLeft: 8,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: theme.customColors.primary.main,
    }
})

const NotificationsContent = (props) => {
    const {notificationService, classes, t, brandingColor, dispatch, mixpanelTrack, notifications, isFocused, lastSawNotification} = props
    const colors = createCustomTheme(brandingColor);

    const notificationsToDisplayLength = useMemo(() => {
        const filteredIds = notifications
            .filter(n => !n.isChatNotification && !isNotificationDateSeen(n.created, n.seenOnDateTime, lastSawNotification))

        return filteredIds.length
    }, [notifications, lastSawNotification])

    const onReadNotifs = () => {
        notificationService.sawAllNotifications()

        dispatch({type: 'USER_SEEN_ALL_NOTIFICATIONS'})
    }
    const onGoToSettings = useCallback(() => {
        dispatch({type: 'TOGGLE_NOTIFICATIONS_MODAL'})
        goToNotifications(dispatch)
        mixpanelTrack('Go to Notification Settings', {});
    }, [])

    const isDisabled = useMemo(() => {
        return notificationsToDisplayLength < 1
    }, [notificationsToDisplayLength])

    const ReadButton = <GeneralButton disabled={isDisabled} iconAfterText onClick={onReadNotifs} icon={<ReadIcon className={classes.icon} color={colors.primary.main} />} containerStyle={classes.buttonContainer} textStyle={classes.buttonText} title={t("Read all")} />

    const Settings = <div data-intrcm-id="NotificationsContent_h0gzqdr0" onClick={onGoToSettings} className={classes.settings}>
        <NotifsSettingsIcon color={colors.primary.main} />
        <div data-intrcm-id="NotificationsContent_2xpsd7tj" className={classes.settingsTitle}>{t("Settings")}</div>
    </div>

    const Header = <div data-intrcm-id="NotificationsContent_4plfyoko" className={classes.header}>
        <div data-intrcm-id="NotificationsContent_a3x2ftn5" className={classes.headerTitle}>{t("Notifications")}</div>
        {Settings}
        {ReadButton}
    </div>

    if(!isFocused) return null

    return (
        <div data-intrcm-id="NotificationsContent_x9flfi38" className={classes.content}>
            {Header}
            <NotificationsList onGoToSettings={onGoToSettings} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        notificationService: state.services.notificationService,
        isFocused: state.notificationsModal.isFocused,
        brandingColor: state.userData.profileData.org.brandingColor,
        notifications: state.userData.notifications,
        lastSawNotification: state.userData.profileData?.lastSawNotification ?? '',
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withMixpanel,
    withStyles(styles, {withTheme: true}),
    withTranslation('notificationsDrawer'),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationsContent)