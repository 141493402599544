import {
  INPUT_OPEN, INPUT_EDIT_OPEN, INPUT_CLOSE, INPUT_CHANGE, INPUT_JSON_CHANGE, INPUT_AGGREGATIONS_CHANGE,
  INPUT_LINKS_CHANGE, INPUT_OPEN_FILE, INPUT_COLLECTION_INFO_CHANGE,
  INPUT_ADD_LINKED, INPUT_REMOVE_LINK_BY_DATA_TYPE, INPUT_REMOVE_LINK_BY_ID, INPUT_EDIT_LINK_UNIQE_DATA_TYPE,
  INPUT_CHANGE_POLL, FULLVIEW_CLOSE, FULL_RESET_STORE
} from '../actionTypes/actionTypes'
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'



const initialState = [];

const emptyCollectionInputInfo = {
  kanbanListId: '',
  ganttListId: '',
}

const emptyPoll = {
  options: []
}

const emptyInput = {
  // Open state
  inputOpen: false,
  editOpen: false,
  // Model state
  hasAttachments: false,
  postText: '',
  hasPreview: false,
  attachments: [],
  tags: [],
  title: '',
  docText: '',
  jsonData: '',
  parsedJsonData: {},
  aggregations: {},
  links: [],

  isPrivate: false,
  permissions: [],
  collectionLabels: [],
  alreadyExists: false,
  isCustomInput: false,
  // Preview fields
  previewTitle: '',
  previewDescription: '',
  previewImgUrl: '',
  link: '',
  // item data type
  dataType: '',
  mimeType: '',
  // Input state
  canSubmit: false,
  // Input Type
  formTitle: '',
  linkedDocs: [],
  classification: 0,
  docId: ''
}

var closedInputs = {
  codeInputOpen: false,
  contactInputOpen: false,
  fileInputOpen: false,
  linkInputOpen: false,
  wysiwygInputOpen: false
};

const input = (state = [], action) => {



  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case INPUT_OPEN:

      var allInputs = { ...closedInputs }
      Object.assign(allInputs, action.input)

      return {
        ...allInputs,
        document: {
          ...emptyInput,
          ...action.model,
          inputOpen: true
        },
        collectionInputInfo: {
          ...emptyCollectionInputInfo,
        },
        poll: {
          ...emptyPoll,
        }
      }
    case INPUT_EDIT_OPEN:
      // TODO: Temporary solution - need to implement viewModel for document
      var model = {
        // Model state
        // hasAttachments: action.model.hasAttachments,
        // postText: action.model.postText,
        // hasPreview: action.model.hasPreview,
        // attachments: action.model.attachments,
        // tags: action.model.tags,
        // title: action.model.title,
        // docText: action.model.docText,
        // jsonData: action.model.jsonData,
        // isPrivate: action.model.isPrivate,
        // // Preview fields
        // previewTitle: action.model.previewTitle,
        // previewDescription: action.model.previewDescription,
        // previewImgUrl: action.model.previewImgUrl,
        // link: action.model.link,
        // // item data type
        // dataType: action.model.dataType,
        // mimeType: action.model.mimeType,
        // formTitle: action.model.formTitle,

        // // docId - for edit
        // docId: action.model.docId,
        ...action.model,
        canSubmit: true,

      }

      var allInputs = { ...closedInputs }

      Object.assign(allInputs, action.input)

      return Object.assign({}, state, {

        document: {
          ...model,
        },
        ...allInputs,
        canSubmit: true,
        editOpen: true
      })

    case FULLVIEW_CLOSE:
    case ON_GO_TO_CHAT:
    case ON_GO_TO_ITEM_NOTIF:
    case INPUT_CLOSE: // Also clean

      return {
        // ...state,
        ...closedInputs,
        document: {
          ...emptyInput
        },
        collectionInputInfo: {
          ...emptyCollectionInputInfo
        },
        poll: {
          ...emptyPoll
        }
      }
    case INPUT_CHANGE:
      return {
        ...state,
        document: {
          ...state.document,
          ...action.model
        }
      }
    case INPUT_COLLECTION_INFO_CHANGE:
      return {
        ...state,
        collectionInputInfo: {
          ...state.collectionInputInfo,
          ...action.collectionInputInfo
        }
      }
    case INPUT_JSON_CHANGE:
      return {
        ...state,
        document: {
          ...state.document,
          parsedJsonData: { ...state.document.parsedJsonData, ...action.parsedJsonData },
          isCustomInput: true
        }
      }
    case INPUT_AGGREGATIONS_CHANGE:
      return {
        ...state,
        document: {
          ...state.document,
          aggregations: { ...state.document.aggregations, ...action.aggregations },
          isCustomInput: true
        }
      }
    case INPUT_LINKS_CHANGE:
      return {
        ...state,
        document: {
          ...state.document,
          links: [...action.links],
          isCustomInput: true
        }
      }
    // case 'INPUT_SET_PREVIEW':
    //   return {
    //     ...state,
    //     ...action.preview
    //   }

    case INPUT_OPEN_FILE:
      return {
        ...state,
        ...state.document,
        imageAddOpen: action.imageAddOpen
      }

    case INPUT_ADD_LINKED:
      return {
        ...state,
        document: {
          ...state.document,
          linkedDocs: [
            ...state.document.linkedDocs,
            action.model
          ]
        }
      }
    case INPUT_EDIT_LINK_UNIQE_DATA_TYPE:
      const oldUiData = state.document.linkedDocs.find(link => link.dataType === action.model.dataType) || {}
      const newData = { ...oldUiData, ...action.model }
      return {
        ...state,
        document: {
          ...state.document,
          linkedDocs: [
            ...state.document.linkedDocs.filter(link => link.dataType != action.model.dataType).concat(newData),
          ]
        }
      }

    case INPUT_REMOVE_LINK_BY_DATA_TYPE:
      return {
        ...state,
        document: {
          ...state.document,
          linkedDocs: [
            ...state.document.linkedDocs.filter(link => link.dataType != action.dataType),
          ]
        }
      }
    case INPUT_REMOVE_LINK_BY_ID:
      return {
        ...state,
        document: {
          ...state.document,
          linkedDocs: [
            ...state.document.linkedDocs.filter(link => link.docId != action.id),
          ]
        }
      }

    case INPUT_CHANGE_POLL:
      return {
        ...state,
        poll: {
          ...state.poll,
          ...action.poll
        }
      }


    default:
      return state
  }
}

export default input