import React, {useCallback, useState, useRef} from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { debounce } from '../../../../../utils/debounce';
import GeneralButton from '../../../Common/AllButton/AllButton';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils.js';
import AutosizeInput from 'react-input-autosize';

const styles = (theme) => ({
    input: {
        border: 'none',
        outline: 'none',
        maxWidth: 330,
        paddingLeft: 12,
        paddingRight: 12,
        transition: 'width 0.25s',
        backgroundColor: '#fff',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 2,
        textTransform: 'uppercase',
        textAlign: "start",
        marginTop: "-10px",
        color: '#0C0C0D',
        "&::placeholder": {
            color: '#737373',
        },
    },
    container: {
        marginTop: 26,
        position: 'relative',
        border: `solid 1px ${theme.customColors.primary.main}`,
        boxSizing: 'border-box',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center'
    },
    titleWrap: {
        position: 'absolute',
        top: -10,
        left: 12,
        backgroundColor: 'transparent',
    },
    options: {
        height: 84,
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    save: {
        marginRight: 0,
        marginLeft: 24,
    },
    cancel: {
        marginRight: 0,
        marginLeft: 12,
    },
    buttonText: {
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0.4,
    },
    buttonContainer: {
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main,
        },
    },
    pressedButtonContainer: {
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: '#fff',
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main,
        },
    },
})

function _LaunchpadEditNameCategory(props) {
    const {
        option,
        classes, 
        dispatch, 
        adminService,
    } = props

    const [title, setTitle] = useState(option.customTitle)
    const inputRef = useRef(null)
    const [t] = useTranslation('search')
    const [isEnterCancel, setIsEnterCancel] = useState(false)
    const [isEnterSave, setIsEnterSave] = useState(false)

    const handleChange = useCallback((e) => {
        setTitle(e.target.value)
    }, []);

    const EditCategory = useCallback(() => {
        if(!title.trim()) return

        const item = {
            ...option,
            customTitle: title,
        }

        adminService.updateLaunchpadMenuItem(item).then(res => {
            if(!res) return

            dispatch({type: 'IS_EDIT_NAME_CATEGORY', editCategory: 0})
            dispatch({
                type: 'UPDATE_LAUNCHPAD_OPTION',
                item: {
                    ...item,
                }
            })
            setTitle("")
        })
    }, [title, option ]);

    const debouncedEditCategory = useCallback(debounce(EditCategory, 500), [EditCategory])

    const onKeyDown = useCallback((e) => {
        const enterKeyCode = 13;
        if (e.keyCode === enterKeyCode) {
            e.stopPropagation()
            debouncedEditCategory();
            return false
        }
        return true
    }, [debouncedEditCategory]);

    const onCancel = useCallback(() => {
        dispatch({type: 'IS_EDIT_NAME_CATEGORY', editCategory: 0})
    }, [])

    const CancelButton = <GeneralButton setIsEnter={setIsEnterCancel} onClick={onCancel} icon={null} containerStyle={`${classes.buttonContainer} ${classes.cancel}`} textStyle={classes.buttonText} title={t("Cancel")} />
    const SaveButton = <GeneralButton disabled={!title.trim()} setIsEnter={setIsEnterSave} onClick={debouncedEditCategory} icon={null} containerStyle={`${classes.pressedButtonContainer} ${classes.save}`} textStyle={classes.buttonText} title={t("Save")} />

    return (
        <div data-intrcm-id="LaunchpadEditNameCategory_erz23iqu" className={classes.container} >
            <div data-intrcm-id="LaunchpadEditNameCategory_rnu344r8" className={classes.titleWrap}>
                <AutosizeInput
                    inputClassName={classes.input}
                    inputRef={inputRef}
                    type='text'
                    autoFocus
                    onChange={handleChange}
                    placeholder={t("Enter category name")}
                    onKeyDown={onKeyDown}
                    value={title}
                />
            </div>
            <div data-intrcm-id="LaunchpadEditNameCategory_qxat9o2u" className={classes.options} >
                {SaveButton}
                {CancelButton}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
})
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export const LaunchpadEditNameCategory = compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(_LaunchpadEditNameCategory)
