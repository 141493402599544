import React  from 'react';

const UnsubscribeIcon = (props) => {
        const { color } = props

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4 8H12" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
}

export default UnsubscribeIcon