import React, { Component } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';


// Apply some reset
const styles = theme => ({
    root: {

    }
});


class TuqqiSnackBar extends Component {
    handleClick = state => () => {
        this.setState({ open: true, ...state });
    };

    handleRequestClose = () => {
        this.props.dispatch({ type: 'HIDE_SNACKBAR' })
    };
    render() {

        const { snackBar, dispatch, classes } = this.props;
        return <Snackbar
            dir='auto'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackBar.snackBarOpen}
            onClose={this.handleRequestClose}
            // onRequestClose={this.handleRequestClose}
            autoHideDuration={snackBar.autoHide || 4000}
            SnackbarContentProps={{
                'aria-describedby': 'message-id',
            }}
            classes={classes}
            message={<span id="message-id">{snackBar.snackBarText}</span>}
        />
    }

}
const mapStateToProps = (state) => ({
    snackBar: state.snackBar

    // urlQuery:ownProps.location.query.searchquery
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TuqqiSnackBar);

// export default withStyles(styles)(App);
