import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withOpenFullview } from '../../../../../../new-ui/withOpenFullview'
import withErrorCatcher from '../../../../Common/withErrorCatcher'
import { parseImageUrl } from '@tuqqi/common'
import goTo from '../../../../../actions/goTo'
import { getPhotoForSponsoredContent, getTextForSponsoredContent, getTitleForSponsoredContent } from '../../../../../../utils/sponsoredContent/sponsoredContent'
import { initProcessStringConfig, processTextString } from '@tuqqi/web'

const _styles = {
    link: {
        color: `#7B4FF0!important`,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    container: {
        marginTop: 16,
        marginBottom:16,
        // minHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight + 16 + 16}px)`,
        width: 300 + 16,
        background: 'rgba(255, 255, 255, 1)',
        boxShadow: 'rgba(140, 140, 140, 0.2) 0px 0px 0px 1px',
        borderRadius: 12,
        paddingBottom:16
        // border: 'solid 1px rgba(0, 0, 0, 0.9)'
    },
    img: {
        cursor: 'pointer',
        maxWidth: '100%',
        width: '100%',
        objectFit: 'contain',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
    },
    emptyImage:{
        cursor: 'pointer',
        maxWidth: '100%',
        width: '100%',
        objectFit: 'contain',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        height:200,
        width: '100%',
        backgroundColor:'rgba(0,0,0,0.03)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        filter:'grayscale'
    },
    author: {
        cursor: 'pointer',
        height: 44,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: 16,
        marginRight: 16,
    },
    userTitle: {
        marginLeft: 8,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#0C0C0D',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#0C0C0D'
        },
    },
    userImg: {
        background: '#EDEDF0',
        cursor: 'pointer',
        width: 16,
        height: 16,
        borderRadius: 8
    },
    contentText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#4A4A4F',
    },
    contentTextPost: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 16,
        lineHeight: '28px',
        letterSpacing: 0.5,
        color: '#0C0C0D',
    },
    title: {
        marginRight: 16,
        marginLeft: 16,
    },
    titleText: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#0C0C0D'
        },
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '20px',
        color: '#0C0C0D',
    },
    content: {
        margin: 16,
        marginTop: 0
    },
    field: {
        marginBottom: 12 
    },
    fieldName: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        overflowWrap: 'break-word',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#737373',
    },
    fieldValue: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        overflowWrap: 'break-word',
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#0C0C0D',
    },
    more: {
        cursor: 'pointer',
        margin: 16,
        marginTop: 0,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#7B4FF0',
    },
    premoteText : {
        marginLeft: 12,
        fontSize: 13,
        fontWeight: 400,
        backgroundColor: "antiquewhite",
        width: "max-content",
        padding: "2px 6px 2px 6px",
        borderRadius: 5,
        marginTop:8,
        marginBottom:4
    }
}

const useStyles = makeStyles(_styles)

const _SponsoredContent = (props) => {
    const classes = useStyles()
    const {doc, cloudfront, users, dispatch, customMappings, openFullview, logo} = props
    const [t] = useTranslation("feedWidgets")
    const parseUrl = parseImageUrl(cloudfront.data);
    const [expanded, setExpanded] = useState(false)
    const [isNeededMore, setIsNeededMore] = useState(false)

    const user = useMemo(() => users.find(u => u.auth0_id === doc.userUUID), [doc.userUUID, users])
    const userImg = useMemo(() => !user ? '' : parseUrl(user.profileImageUrl), [user, parseUrl])
    const userTitle = useMemo(() => !user ? t("Unknown") : `${user.firstname} ${user.lastname}`, [user])

    const mediaUrl = useMemo(() => {
        return getPhotoForSponsoredContent(doc)
    }, [doc])

    const [isText, data, isPost] = useMemo(() => {
        return getTextForSponsoredContent(doc, customMappings, t)
    }, [doc, customMappings])

    const title = useMemo(() => {
        return getTitleForSponsoredContent(doc, t)
    }, [doc])

    const openDoc = () => {
        openFullview(doc, doc.docId, true)
    }

    useEffect(() => {
        if(typeof data === 'string') {
            setIsNeededMore(data.length > 200 || title.length > 200)
            return
        }
        if(Array.isArray(data)) {
            const iii = data.some(field => field.value.length > 50)
            setIsNeededMore(title.length > 200 || iii)
            return
        }
        setIsNeededMore(false)
    }, [isText, data, isPost, title])
    

    const getCuttedText = useCallback((data, cut) => {
        if(!isNeededMore) return data
        return expanded ? data : `${data.slice(0, cut)}${data.length > data.slice(0, cut).length ? '...' : ''}`
    }, [expanded, isNeededMore])

    const uri = useMemo(() => {
        if(!mediaUrl) return ''
        return parseUrl(mediaUrl)
    }, [mediaUrl, parseUrl])

    const goToUser = (e) => {
        e.preventDefault(); 
        e.stopPropagation();
        if(!user) return
        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
    }

    const onOpenHashtag = (hashtag) => {
        goTo(hashtag, dispatch)();
    }
    const onOpenLink = (link) => {
        window.open(link, '_blank')
    }

    const getTextParsed = useCallback((data, className = '') => {
        if(!data || typeof data !== 'string') return data
        const processConfig = initProcessStringConfig({
            linkClassname: `${className} ${classes.link}`,
            tagClassname: `${className} ${classes.link}`,
            onLinkClick: onOpenLink,
            onTagClick: onOpenHashtag
        })
        return processTextString(data, processConfig)
    }, [])

    const onExpand = () => {
        setExpanded(prev => !prev)
    }
    const ImagePreview = !uri ? <div onClick={openDoc} className={classes.emptyImage}><img style={{filter:"grayscale(25)"}} src={logo}></img></div> : <img onClick={openDoc} alt='img' src={uri} className={classes.img} />

    // const AuthorPreview = !!user && <div data-intrcm-id="SponsoredContent_shcjxd90" onClick={openDoc} className={classes.author}>
    //     <img onClick={goToUser} alt='imguser' src={userImg} className={classes.userImg} />
    //     <div data-intrcm-id="SponsoredContent_ghzxwdjf" onClick={goToUser} className={classes.userTitle}>{userTitle}</div>
    // </div>



    const renderField = (field, i) => {
        if(!field.value) return null
        const val = field.value
        const cutted = getCuttedText(val, 50)
        return <div data-intrcm-id="SponsoredContent_ufvay093" key={i} className={classes.field}>
            <div data-intrcm-id="SponsoredContent_spvw4wl2" className={classes.fieldName}>{field.fieldName}</div>
            <div data-intrcm-id="SponsoredContent_9f5v8cjb" className={classes.fieldValue}>{getTextParsed(cutted, classes.fieldValue)}</div>
        </div>
    }

    const ContentPreview = !!data && <div data-intrcm-id="SponsoredContent_jn8bs8iz" className={classes.content}>
        {!!isText && !!data && <div data-intrcm-id="SponsoredContent_8kkruiqv" className={isPost ? classes.contentTextPost : classes.contentText}>{getTextParsed(getCuttedText(data, 200), isPost ? classes.contentTextPost : classes.contentText)}</div>}
        {!isText && Array.isArray(data) && !!data.length && data.map((field, i) => renderField(field, i))}
    </div>

    const TitlePreview = !!title && <div data-intrcm-id="SponsoredContent_4xpqoqdw" onClick={openDoc} className={classes.title}>
        <div data-intrcm-id="SponsoredContent_k5voyax3" className={classes.titleText}>{getTextParsed(getCuttedText(title, 200), classes.titleText)}</div>
    </div>

    const NeedMore = !isNeededMore ? null : <div data-intrcm-id="SponsoredContent_5x1cb2pq" onClick={onExpand} className={classes.more}>{expanded ? t("Show less") : t("Show more")}</div>

    return <div data-intrcm-id="SponsoredContent_hebtw9qj" className={classes.container}>
        {ImagePreview}
        {/* {AuthorPreview} */}
        <div data-intrcm-id="SponsoredContedsant_k5voysadax3" className={classes.premoteText}>📌 Promoted</div>
        {TitlePreview}
        {ContentPreview}
        {NeedMore}
    </div>
}

const mapStateToProps = (state) => ({
    cloudfront: state.data.globalData.cloudfront,
    customMappings: state.data.globalData.customInputMappings?.data ?? [],
    logo: state.userData?.profileData?.org.logo,
    users: state.data.globalData.users?.data ?? [],
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})
export default compose(
    withOpenFullview,
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(_SponsoredContent);
