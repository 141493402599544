import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, InputBase, Grid, Icon } from '@material-ui/core';
import { compose } from 'recompose';
import PickFileComponent from '../../../../Common/autosuggestField/PickFileComponent.js';
import { connect } from 'react-redux';
import parseImgeUrl from '../../../../Common/parseImagUrl.js';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    container: {
        overflow: 'hidden',
        borderRadius: 4,
        border: 'solid 1px rgba(151, 154, 170, 0.3)',
        //cursor: 'pointer',
        marginBottom: 16,
    },
    leftSideContainer: {
        width: 56,
        flexGrow: 0,
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex",
        borderRight: 'solid 1px rgba(151, 154, 170, 0.3)'
    },
    rightSideContainer: {
        justifyContent: 'center',
        display: "flex",
        alignItems: 'center',
        padding: 8,
        height: 64,
        width: 72
    },
    centerContainer: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 8,
        paddingBottom: 8,

        overflow: 'hidden',
        width: '100%'
    },
    input:{
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#21212d',
    },
    inputRoot: {
        width: '100%'
    },
    optionContent: {
        flexDirection: 'row', 
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: 8, 
        alignItems: 'flex-end'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    },
    text: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#21212d',
        paddingRight: 16,
    },
    addIcon: {
        fontSize: 24,
        color: '#979aaa'
    },
    photoIcon: {
        fontSize: 24,
        color: '#979aaa',
        padding: 12,
        borderRadius: 4,
        border: 'solid 1px rgba(151, 154, 170, 0.3)',
        cursor: 'pointer',
        '&:hover': {
            color: '#21212d',
        }
    }
})

const NUMBER_OF_CHARACTERS = 118;
const IconPickerStyle = "cursor: pointer; margin-bottom: 0px; font-size: 24px; color: #979aaa; border-radius: 4px; border: solid 1px rgba(151, 154, 170, 0.3); padding: 4px 12px; margin-top: -12px; margin-right: 36px;"
const ImagePickerStyle = "cursor: pointer; margin-top: -12px; margin-right: 36px; height: 48px; width: 48px; object-fit: contain; border-radius: 4px;"

class PollAddOption extends PureComponent {
    state = {
        text: '',
        img: ''
    }

    onChange = (e) => {
        let text = e.target.value;
        
        if( text.length > NUMBER_OF_CHARACTERS ){
            text = text.substr(0, NUMBER_OF_CHARACTERS)
        }

        this.setState({ text })
    }

    addOption = (event) => {
        const { text, img } = this.state;
        
        if(text){
            this.props.addOption(text, img);

            this.setState({ text: '', img: '' })
        }

        event.stopPropagation();
    }

    addImage = (file) => {
        try{
            const parsedFile = JSON.parse(file);

            const img = parsedFile.fileId;

            this.setState({ img })
        }
        catch (e){
            
        }
    }

    render() {
        const { classes, isAddOwn, userData, cloudfront, t } = this.props;
        const { text, img } = this.state;
        
        if ( !isAddOwn ){
            return null;
        }

        const parsedImg = parseImgeUrl(cloudfront, encodeURIComponent(img), 512)

        const addClickableStyle = {
            color: '#21212d',
            cursor: 'pointer'
        }

        return <Grid container wrap='nowrap' direction='row' className={classes.container} >
            <Grid item className={classes.leftSideContainer} onClick={this.addOption} >
                <Icon className={classes.addIcon} style={text ? addClickableStyle : {}}>add</Icon> 
            </Grid>
            <Grid item className={classes.centerContainer} dir="auto" xs='auto'>
                <InputBase dir='auto' 
                    onChange={this.onChange}  
                    placeholder={t('Add option')}
                    value={text}
                    onClick={event => event.stopPropagation()}
                    classes={{ input: classes.input, root: classes.inputRoot }}
                />
            </Grid>
            <Grid item className={classes.rightSideContainer} >
               <PickFileComponent
                    onLoad={this.addImage} 
                    iconName='add_a_photo' 
                    iconStyle={IconPickerStyle}
                    className={'imagePickerPollWrapper'}
                    image={parsedImg}
                    imagePickerStyle={ImagePickerStyle}
                    renderFilePond={true}
                    acceptedFileTypes={['image/*', 'video/*']}
                />
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
    ...state.services,
})

export default compose(withStyles(styles), withTranslation('pollTemplate'), connect(mapStateToProps))(PollAddOption)