
class Manager {
    #keep = []

    #getLocalLastGroupView = (userUid) => {
        if(!userUid) return []

        try {
            const x = localStorage.getItem(`${userUid}_lastGroupViews`)
            if(!x) return []

            return JSON.parse(x) ?? []
        } catch (err) {
            return []
        }
    }
    #setLocalLastGroupView = (userUid, data) => {
        if(!userUid) return 

        try {
            localStorage.setItem(`${userUid}_lastGroupViews`, JSON.stringify(data))
        } catch (err) {
        }
    }

    init = (userUid) => {
        const x = this.#getLocalLastGroupView(userUid)
        this.#keep = x
    }

    getKeep = () => this.#keep

    push = (collectionUid, view, userUid) => {
        if(!collectionUid || !view) return
        const updated = [...this.#keep.filter(x => x.collectionUid !== collectionUid), {collectionUid, view}]
        this.#keep = updated

        this.#setLocalLastGroupView(userUid, updated)
    }
}

export const managerLastGroupsView = new Manager()