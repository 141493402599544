import { Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, Slide, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { updateSubscription } from '../../../actions/globalDataAction';
import { HIDE_UPGRADE_FROM_FREE } from '../../../actionTypes/actionTypes';
import YouNeedToUpdateSubscriptionIcon from '../../views/admin/customInputTemplates/pages/YouNeedToUpdateSubscription/YouNeedToUpdateSubscriptionIcon';



const styles = theme => ({
    paper: {
        width: 600
    },
    closeIcon: {
        color: '#979AAA',
        padding: '16px 16px 0 0'

    },
    title: {
        "fontFamily": "Rubik",
        "fontSize": "24px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        color: theme.newUI.color.commonBlack,
        marginBottom: 24

    },
    titleContainer: {
        padding: 0
    },
    subscriptionIcon: {
        padding: '24px 0 0 24px'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32
    },
    description: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        marginBottom: 44,
        color: theme.newUI.color.commonBlack


    },
    featuresContainer: {
        margin: '0 -47px -16px 0'
    },
    featureContainer: {
        flex: '0 0 50%',
        // width: 'initial',
        padding: '0 47px 16px 0'
    },
    featureIcon: {
        fontSize: 18,
        color: theme.newUI.color.primary
    },
    featureText: {
        marginLeft: 16,
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        color: theme.newUI.color.commonBlack
    },
    actionsContainer: {
        margin: '0 0 16px 0'
    },
    upgradeButton: {
        "margin": "0",
        backgroundColor: `${theme.newUI.color.primary} !important`,
        "textTransform": "none",
        "padding": "8px"
    },
    upgradeText: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "textAlign": "center",
        "color": "#ffffff"
    },
    learnMoreButton: {
        "textTransform": "none",
        "padding": "8px",
        "margin": "0 24px 0 16px"
    },
    learnMoreText: {
        "textTransform": "none",
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "textAlign": "center",
        "color": theme.newUI.color.primary
    },
    root: {
        minWidth: 768,//1024,
        borderRadius: 16,
        minHeight: 700,
        overflowX: 'hidden'
    },
    paperFullWidth: {
        maxWidth: 840
    }

});
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

// // Apply some reset
// const styles = theme => ({
// root: {
//     minWidth: 768,//1024,
//     borderRadius: 16,
//     minHeight: 700,
//     overflowX: 'hidden'
// },
// paperFullWidth: {
//     maxWidth: 840
// }
// });


class UnsupportedResolution extends PureComponent {

    constructor(props, context) {
        super(props, context);

        const resolutionNotificationIsEnabled = localStorage.getItem('resolutionNotificationIsEnabled')
        const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

        if (resolutionNotificationIsEnabled === 'true') {
            if ((window.screen.height < 766 || window.screen.width < 1366) && (zoom > 95)) {
                this.state = {
                    isOpen: true,
                }
            } else {
                this.state = {
                    isOpen: false,
                }
            }
        } else {
            const resolutionAdjust = localStorage.getItem('resolutionAdjustEnabled')

            if(resolutionAdjust === 'true'){
                if ((window.screen.height < 766 || window.screen.width < 1366) && (zoom > 95)) {
                    this.adjustScreen()
                }
                
            }
            this.state = { isOpen: false }
        }




    };

    onChange = (event) => {
        this.setState({ usersNumber: event.target.value })
    }

    learnMore = () => {
        window.open('https://intercom.help/tuqqi/he/articles/5169777-כיצד-תוכלו-להגדיר-את-התצוגה-המייטבית-לעבודה-במערכת', '_blank')
        this.onClose()
    }

    adjustScreen = ()=>{
        document.body.style.zoom = "60%" 
        localStorage.setItem('resolutionNotificationIsEnabled', 'false')
        localStorage.setItem('resolutionAdjustEnabled', 'true')

        this.setState({ isOpen: false })
    }


    onClose = () => {
        localStorage.setItem('resolutionNotificationIsEnabled', 'false')
        this.setState({ isOpen: false })
    }

    render() {
        const { classes, userData } = this.props;
        const { isOpen } = this.state;

        if (isOpen) {
            return (<Dialog
                open={isOpen}
                classes={{
                    paper: classes.paper
                }}
                scroll={'body'}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className={classes.titleContainer}>
                    <Grid container justify='space-between' >
                        <Grid item >

                        </Grid>
                        <Grid>
                            <YouNeedToUpdateSubscriptionIcon className={classes.subscriptionIcon} themeOption={userData.profileData.org.brandingColor} />
                        </Grid>
                        <Grid>


                            <Icon onClick={() => { }} className={classes.closeIcon}>close</Icon>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <span className={classes.title}>This resolution is not supported</span>
                    <span className={classes.description}>We support minimum 1366x768 resolution. For more information <a onClick={this.learnMore}>learn more</a>.</span>
                    <span className={classes.description}>To adjust screen <a onClick={this.adjustScreen}>click here</a>.</span>
                </DialogContent>

                <DialogActions className={classes.actionsContainer}>
                    <Button onClick={this.onClose} color="primary"
                        classes={{
                            root: classes.upgradeButton,
                            label: classes.upgradeText
                        }}>
                        Dismiss
                    </Button>
                    {/* <Button onClick={this.learnMore}
                        classes={{
                            root: classes.learnMoreButton,
                            label: classes.learnMoreText
                        }}
                    >
                        Learn more
                    </Button> */}
                    <Button onClick={this.adjustScreen}
                        classes={{
                            root: classes.learnMoreButton,
                            label: classes.learnMoreText
                        }}
                    >
                        Adjust
                    </Button>

                </DialogActions>
            </Dialog>)
        } else { return null }
    }




}


const mapStateToProps = (state) => ({
    userData: state.userData,
    upgradeFromFree: state.upgradeFromFree,
    profileData: state.userData.profileData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        goTo: path => { dispatch(push(path)) }

    }
}
export default compose(withStyles(styles),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(UnsupportedResolution);

