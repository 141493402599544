import React, { PureComponent } from "react";
import { SvgIcon } from "@material-ui/core";

export default class IconRightMinimize extends PureComponent {
  render() {
    return (
      <svg
        width="9px"
        height="16px"
        viewBox="0 0 9 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"

      >
        <g
          id="Feed"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Stories---Open-+-minimize"
            transform="translate(-164.000000, -223.000000)"
            fill="#979AAA"
            fill-rule="nonzero"
          >
            <g id="Left-Side" transform="translate(120.000000, 198.000000)">
              <g
                id="icon---arrow-right-(minimize)"
                transform="translate(48.500000, 33.000000) rotate(-180.000000) translate(-48.500000, -33.000000) translate(44.000000, 25.000000)"
              >
                <path
                  d="M8.465,1.2425 C8.0975,0.875 7.505,0.875 7.1375,1.2425 L0.905,7.475 C0.6125,7.7675 0.6125,8.24 0.905,8.5325 L7.1375,14.765 C7.505,15.1325 8.0975,15.1325 8.465,14.765 C8.8325,14.3975 8.8325,13.805 8.465,13.4375 L3.035,8 L8.4725,2.5625 C8.8325,2.2025 8.8325,1.6025 8.465,1.2425 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
