import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "recompose";
import { showSnackbar } from "../../../actions/appActions";
import {
  dataUpdate,
  finishedUpdatingSpecificItem,
  updateSpecificItem,
} from "../../../actions/dataActions";
import { DATA_FILTERED, DATA_UPDATED } from "../../../actionTypes/actionTypes";
import withErrorCatcher from "../../components/Common/withErrorCatcher.js";
import KnowledgeView from "../../components/Knowledge/knowledgeView";
import GroupKanbanPlaceholder from "../../components/Placeholders/GroupKanbanPlaceholder";
import {
  filterKanbanResults,
  loadGroupInfo,
  loadKanbanData,
} from "./groupUtils";
import CategoryViewOnlyLeftDrawer from "../../components/App/CategoryViewOnlyLeftDrawer";
import {
  getChannelName,
} from "../../components/Common/Pusher/pusherUtils";
import withPusher from "../../components/Common/Pusher/withPusher";

const styles = (theme) => ({
  kanban: {
    marginLeft: 385,
  },
  kanban1: {
    marginLeft: 123,
  },
});

export class GroupKnowledge extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dataReloadInidicator: 0,
      results: [],
      isLoading: true,
      hadEmptyResult: false,
      openCardDialog: false,
      groupInfoLoaded: false,
      isLoadingGroupAndData: false,
      lanes: [],
      actionCounter: 0,
    };

    // Bind the utils
    this.loadData = loadKanbanData.bind(this);
    this.filterKanbanResults = filterKanbanResults.bind(this);
    this.loadGroupInfo = loadGroupInfo.bind(this);
  }

  componentWillUnmount() {
    if (this.props.searchQuery.collectionUid) {
      this.props.unbindChannel(this.props.searchQuery.collectionUid);
    }
  }

  componentDidMount() {
    const { urlQuery, dispatch, userData } = this.props;

    if (urlQuery !== "") {
      dispatch({ type: "DATA_UPDATE" });

      const { searchQuery } = this.props;
      if (searchQuery.collectionUid) {
        this.props.initChannel(
          searchQuery.collectionUid,
          "update_kanban",
          (data) => {

            if (data.userId !== userData.profileData.auth0_id) {
              dispatch(dataUpdate());
            }
          }
        );
      }

      const channelName = getChannelName(userData.profileData.auth0_id);
      const eventName = "notification";
      this.props.initChannel(channelName, eventName, (data) => {
        dispatch(updateSpecificItem(data.docId));
      });
    } else {
      dispatch(push("feed"));
    }
  }

  //check it
  getView = () => {
    return (this.props.collectionInfo.collectionViews || []).find(
      (c) => c.collectionViewType === "Kanban"
    );
  };

  buildKnowledge = (allGroupItems) => {
    let view = this.getView();
    if (!view) return;

    const columns = view.itemLists
      .map((list) => ({
        id: list.id,
        title: list.title,
        order: list.order,
        description: list.description,
        listColor: list.listColor,
        isUnassigned: false,
        rules: list.rules,
        cards: [],
      }))
      .sort(this.sortOrder);

    const unassignedList = {
      id: -1,
      title: "Unassigned",
      cards: [],
      order: -1,
      isUnassigned: true,
    };
    allGroupItems.forEach((groupItem) => {
      let card = null;
      for (let column of columns) {
        const listItems = view.itemLists.find((list) => list.id === column.id)
          .items;
        const listItem = listItems.find(
          (listItem) => listItem.documentId === groupItem.id
        );

        if (listItem) {
          card = this.buildCard(groupItem, listItem.order);
          column.cards.push(card);
          break;
        }
      }
      if (!card) {
        const time = new Date(groupItem.document.docTimestamp).getTime();
        unassignedList.cards.push(this.buildCard(groupItem, -time));
      }
    });
    columns.unshift(unassignedList);
    columns.forEach(
      (column) => (column.cards = column.cards.sort(this.sortOrder))
    );

    this.setState({ lanes: columns });
  };

  sortOrder(a, b) {
    return a.order - b.order;
  }

  buildCard(item, order) {
    return {
      doc: item.document,
      id: item.id,
      order,
      title: item.document.title,
    };
  }

  updateSpecificItemInState(props, docId) {
    const { dispatch, queryService } = props;
    const { lanes } = this.state;

    const laneIndex = lanes.findIndex((lane) =>
      lane.cards.some((card) => card.id === docId)
    );

    if (laneIndex > -1) {
      queryService.searchById(docId).then((results) => {
        if (results.data.length) {
          const lane = {
            ...lanes[laneIndex],
            cards: [...lanes[laneIndex].cards],
          };

          const cardIndex = lane.cards.findIndex((card) => card.id === docId);

          lane.cards[cardIndex] = {
            ...lane.cards[cardIndex],
            doc: results.data[0].Value.Source,
          };
          lanes[laneIndex] = lane;
          this.updateLists(lanes);
        }
        this.setState({ isLoadingGroupAndData: false });
        dispatch(finishedUpdatingSpecificItem(docId));
      });
    } else {
      dispatch(finishedUpdatingSpecificItem(docId));
    }
  }

  getAllData = () => {
    const { searchQuery, queryService } = this.props;
    const _searchQuery = { ...searchQuery, page: 0 };

    return queryService.GetKanbanData(_searchQuery);
  };

  componentDidUpdate(prevProps) {
    const { oldUiData, dispatch } = this.props;

    if (this.state.isLoadingGroupAndData) {
      return;
    }

    if (
      oldUiData.needsUpdate ||
      (!oldUiData.isFiltering && oldUiData.needsFilter)
    ) {
      this.setState({ isLoadingGroupAndData: true });
 
      Promise.all([this.loadGroupInfo(this.props), this.getAllData()]).then(
        (results) => {
          this.buildKnowledge(results[1]);
          dispatch({ type: DATA_UPDATED });
          dispatch({ type: DATA_FILTERED });
          this.setState({ isLoadingGroupAndData: false });
        }
      );
    } else if (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate) {
      this.setState({ isLoadingGroupAndData: true });
      this.updateSpecificItemInState(this.props, oldUiData.itemId);
    }
  }

  reloadDataWithoutLoader = () => {
    this.loadGroupInfo(this.props).then(() => {
      this.buildKnowledge([]);
    });
  };

  // important function update the data in the state
  updateLists = (updatedLanes) => {
    if (updatedLanes) {
      this.setState({
        lanes: [...updatedLanes],
        actionCounter: this.state.actionCounter + 1,
      });
    }
  };

  moveList = (newList) => {
    this.updateLists(newList);

    const { groupService, collectionInfo } = this.props;
    const collection = {
      id: collectionInfo.id,
      collectionUid: collectionInfo.collectionUid,
      collectionViews: [
        {
          id: this.getView().id,
          itemLists: (newList || this.state.lanes)
            .filter((l) => l.id !== -1)
            .map((l) => ({
              id: l.id,
              order: l.order,
            })),
        },
      ],
    };

    groupService.moveLane(collection);
  };

  getSnackbarText = (rulesCount) => {
    if (rulesCount === 1) {
      return this.props.t("Rule was applied on your item");
    } else {
      return this.props.t("Rules were applied on your item");
    }
  };

  updateDataMoveCard = ({
    card,
    sourceListId,
    targetLIstId,
    newPosition,
    moveBack,
    rules,
  }) => {
    const { groupService, collectionInfo, dispatch } = this.props;

    return groupService
      .moveCard({
        collectionId: collectionInfo.collectionUid,
        collectionViewType: "Kanban",
        cardId: card.id,
        sourceLaneId: sourceListId,
        targetLaneId: targetLIstId,
        position: newPosition,
        docTitle: card.doc ? card.doc.title : card.title,
      })
      .then((res) => {
        if (res.ok && sourceListId !== targetLIstId) {
          // if there is rules from the sourceListId and to targetLIstId
          if (rules.length) {
            dispatch(showSnackbar(this.getSnackbarText(rules.length)));
            dispatch(updateSpecificItem(card.id));
          }
        }
        return res;
      });
  };

  editTitleList = (listId, title) => {
    const { groupService } = this.props;
    const { lanes } = this.state;
    const laneIndex = lanes.findIndex((lane) => lane.id === listId);

    const currList = { ...lanes[laneIndex], title };
    lanes[laneIndex] = currList;
    this.updateLists(lanes);
    groupService.updateLane(currList);
  };

  editListColor = (listId, listColor) => {
    const { groupService } = this.props;
    const { lanes } = this.state;
    const laneIndex = lanes.findIndex((lane) => lane.id === listId);

    const currList = { ...lanes[laneIndex], listColor: listColor };
    lanes[laneIndex] = currList;
    this.updateLists(lanes);

    groupService.updateLane(currList);
  };

  deleteList = (listId) => {
    const { groupService, collectionInfo, dispatch, t } = this.props;
    let lanes = [...this.state.lanes];
    const currList = lanes.find((l) => l.id === listId);

    const collection = {
      id: collectionInfo.id,
      collectionUid: collectionInfo.collectionUid,
      collectionViews: [
        {
          id: this.getView().id,
          itemLists: [currList],
        },
      ],
    };
    dispatch(showSnackbar(t("Please wait while deleting the lane!")));

    groupService.deleteLane(collection).then((res) => {
      if (res.ok) {
        // move the cards from the deleted lane to unassigned lane

        const unassignedLaneIndex = lanes.findIndex((l) => l.id === -1);

        const unassignedLane = {
          ...lanes[unassignedLaneIndex],
          cards: [...lanes[unassignedLaneIndex].cards],
        };

        // the index of the deleted lane
        const deletedLaneIndex = lanes.findIndex((l) => l.id === listId);
        lanes[deletedLaneIndex].cards.forEach((c) => {
          c.order = unassignedLane.cards.length;
          unassignedLane.cards.push(c);
        });
        lanes.splice(deletedLaneIndex, 1);

        lanes[unassignedLaneIndex] = unassignedLane;
        this.updateLists(lanes);
      } else {
        dispatch(showSnackbar(t("Error occured while deleting the lane!")));
      }
    });
  };

  addList = (title, description) => {
    const { dispatch, t } = this.props;
    dispatch(showSnackbar(t("Please wait while the lane added!")));

    let laneDTO = { title, description, collectionViewId: this.getView().id };

    this.props.groupService.addLane(laneDTO).then((newLane) => {
      const addedLane = {
        cards: [],
        id: newLane.id,
        order: newLane.order,
        title: newLane.title,
        description: newLane.description,
      };
      this.updateLists([...this.state.lanes, addedLane]);
    });
  };

  func = {
    editTitleList: this.editTitleList,
    editListColor: this.editListColor,
    deleteList: this.deleteList,
    loadMore: this.loadMoreQuery,
    addList: this.addList,
  };

  render() {
    const { groupInfoLoaded, lanes } = this.state;
    const { classes, isDrawerOpen } = this.props;

    let content = <div data-intrcm-id="groupKnowledge_xrirhv97" />;
    if (!groupInfoLoaded || !lanes || !lanes.length) {
      content = (
        <CircularProgress
          style={{ position: "fixed", left: "50%", right: "50%", top: "27%" }}
        />
      );
    } else {
      content = (
        <KnowledgeView
          className={isDrawerOpen ? classes.kanban : classes.kanban1}
          func={this.func}
          editTitleList={this.editTitleList}
          editListColor={this.editListColor}
          deleteList={this.deleteList}
          addList={this.addList}
          reloadDataWithoutLoader={this.reloadDataWithoutLoader}
          moveList={this.moveList}
          updateDataMoveCard={this.updateDataMoveCard}
          updateLists={this.updateLists}
          lists={lanes}
          placeholder={<GroupKanbanPlaceholder isFavorites={false} />}
        />
      );
    }

    return (
      <CategoryViewOnlyLeftDrawer >
        {content}
      </CategoryViewOnlyLeftDrawer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.services,
  collectionInfo: state.results.channel,
  oldUiData: state.oldUiData,
  searchQuery: state.search,
  userData: state.userData,
  results: state.results.channel,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default compose(
  withStyles(styles),
  withPusher,
  withTranslation("knowledgeTranslate"),
  withErrorCatcher("Failed Loading Knowledge, Please Contact Tuqqi Support"),
  connect(mapStateToProps, mapDispatchToProps)
)(GroupKnowledge);