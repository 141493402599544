import { FormControl, Icon, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { icons } from '../customInputTemplateUtils.js';

const styles = theme => ({
    formControl: {
        margin: '0 5px',
        display: 'flex'
    },
    selectMenu: { display: 'flex', alignItems: 'center' },
    icon: {
        paddingLeft: 15,
        marginLeft: 'auto'
    },
})

class IconSelecter extends React.Component {
    render() {
        const { classes, icon, onChange,t } = this.props;
        
        return <FormControl className={classes.formControl}>
            <InputLabel htmlFor="icon">{t('Icon')}</InputLabel>
            <Select
                classes={{ selectMenu: classes.selectMenu }}
                value={icon}
                onChange={onChange}
                inputProps={{ name: 'icon', id: 'icon', }}
            >
                <MenuItem value=""><em>{t('None')}</em></MenuItem>
                {icons.map((icon, idx) => (
                    <MenuItem value={icon}>
                        {icon}
                        <Icon classes={{ root: classes.icon }} className={classes.icon}>{icon}</Icon>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }
}

export default compose(withStyles(styles),withTranslation('admin'))(IconSelecter);