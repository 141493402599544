
import { useEffect, useState } from 'react'
import intervalToDuration from 'date-fns/intervalToDuration'

export const useTimer = (startDate, withHours = true) => {
    const [result, setResult] = useState();
        useEffect(() => {
            if(startDate) {
                const interval = setInterval(() => {
                        const duration =  intervalToDuration({
                            start: new Date(startDate),
                            end:  new Date(),
                              })
                            const hours =   !withHours && duration.hours === 0 ? '' : [0,1,2,3,4,5,6,7,8,9].includes(duration.hours) ? `0${duration.hours}:` : `${duration.hours}:`; 
                            const minutes = [0,1,2,3,4,5,6,7,8,9].includes(duration.minutes) ? `0${duration.minutes}:` : `${duration.minutes}:`
                            const seconds = [0,1,2,3,4,5,6,7,8,9].includes(duration.seconds) ? `0${duration.seconds}` : `${duration.seconds}`
                            const result =  `${hours}${minutes}${seconds}`
                            setResult(result)  
                }, [400])
                return () => {
                    clearInterval(interval)
                }
            } 
        }, [startDate])
        return result
    }