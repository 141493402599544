import React from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { Icon } from '@material-ui/core';
import { getDataType } from '../Utils/defaultDataTypes.js';

export default function (type, inputMappings = []) {
    const dataType = getDataType(type) || getInputMapping(type, inputMappings);

    if (!type) {
        return 'Item'
    } 
    else if (dataType){
        return dataType.title
    }
    else{
        return capitalizeFirstLetter(type)
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInputMapping (type, inputMappings){
    return inputMappings.find(mapping => mapping.customDataMapping.dataTypeFilter === type)
}

export function IconDictionary(props) {
    const { style, className, type = '', inputMappings } = props;
    const dataType = getDataType(type) || getInputMapping(type, inputMappings);

    return <Icon style={style} className={className}>{dataType ? dataType.icon : 'edit'}</Icon>
}