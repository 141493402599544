import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import Text from "./Text";


const styles = theme => ({
    container: {
        overflow: 'hidden',
        margin: '0px 16px',
        borderRadius: '2px', 
        cursor: 'pointer'
    },
    textContainer: {
        overflow:'hidden',
        backgroundColor: '#f7f7f7',
        padding: '12px',
        flexGrow: 1, flexShrink: 1
    },
    imgContainer:{ width: 110, height: 110, flexGrow: 0, flexShrink: 0 },
    img: {
        height:'100%',
        width: '100%',
        objectFit: 'cover',
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909",
        'word-break': 'break-all',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    }

});

class CustomInputTemplateLeftImage extends PureComponent {



    render() {
        const { classes, result, mappings, openItem, imageUrl } = this.props;

        const { docText, title, dataType } = result;

        return (
            <Grid container wrap='nowrap' direction='row' onClick={openItem} className={classes.container} >
                 <Grid item className={classes.imgContainer} >
                    <img src={imageUrl} className={classes.img}/>
                </Grid>
                <Grid item container wrap='nowrap' direction='column' className={classes.textContainer}>
                <Text dataType={dataType} mappings={mappings} title={title} />
                <span className={classes.previewDescription} dir="auto" >
                        {docText}
                    </span>
                </Grid>
            </Grid>
        )
        
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(CustomInputTemplateLeftImage);
