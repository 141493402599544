import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FULLVIEW_OPEN_LOAD } from '../../../../../actionTypes/actionTypes.js';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import FileIcon from '../../../../components/FeedViewComponents/ContentPreview/DataTypeTemplates/FileIcon.js';
import { getSearchQueryItemsByDataType } from '../../groupUtils.js';
import AddItem from './AddItem.js';
import WidgetContainer from "./WidgetContainer";
import { withTranslation } from 'react-i18next';
import { canUserAddItemToGroup } from '../../../../components/Common/Utils/userPermissionsUtils.js';

const styles = theme => ({
    fileIcon: {
        width: 40,
        height: 40,
        marginRight: 16,
    },
    fileSize: {
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        color: theme.newUI.color.gray
    },
    title: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        color: theme.newUI.color.commonBlack,
        "whiteSpace": "nowrap",
        "overflowX": "hidden",
        "textOverflow": "ellipsis",
        "maxWidth": "100%",
        "&:hover": {
            "textDecoration": "underline"
        }
    },
    textContainer: {
        "overflow": "hidden"
    },
    fileContainer: {
        padding: '16px 16px 0px 16px',
        cursor: 'pointer',
        minHeight: 56

    },
    addItemContainer: {
        padding: '16px 16px 0px 16px',
    },
    filesContainer: {
        "marginRight": "8px",
        "maxWidth": "calc(100% - 8px)",
        "maxHeight": "225px",
        "overflowY": "scroll",
        ...theme.newUI.scrollbar
    },

    widgetContainer: {
        "paddingBottom": "16px",
    }
});


const FileItem = (withStyles(styles)((props) => {
    const { classes, onClick, file } = props
    const { mimeType, title, previewDescription } = file
    return (
        <Grid className={classes.fileContainer} item container wrap='nowrap' direction='row' onClick={onClick} >
            <FileIcon className={classes.fileIcon} mimeType={mimeType} />
            <Grid item container direction='column' className={classes.textContainer} >
                <Typography className={classes.fileSize}>{previewDescription?.replace(/size:|\s/ig, '')}</Typography>
                <Typography className={classes.title}>{title}</Typography>
            </Grid>
        </Grid>)
}))

class FileWidget extends React.Component {

    state = {
        isLoading: true,
        fileData: []
    }

    componentDidMount() {
        const { queryService, search } = this.props
        getSearchQueryItemsByDataType(search, queryService, 'file').then(result => {
            this.setState({ fileData: result.data, isLoading: false })
        })
    }

    canAddFile() {
        const { userCollections, groupInfo, profileData } = this.props;

        if (!groupInfo.collectionUid) {
            return false
        }

        const isUserMemberInCollection = canUserAddItemToGroup(profileData, userCollections, groupInfo.collectionUid)

        const isFileAllowedInGroup = groupInfo.allInputsAllowed || groupInfo.settings.allowedInputs.includes('file')

        return isUserMemberInCollection && isFileAllowedInGroup
    }

    openFile = (docId) => () => {
        const { dispatch, collaborationService } = this.props;

        collaborationService.logClick(docId);

        dispatch({ type: FULLVIEW_OPEN_LOAD, itemId: docId })
    }



    render() {
        const { classes, groupInfo, t } = this.props;

        const { fileData, isLoading } = this.state;

        if (!isLoading && !fileData.length && !this.canAddFile()) {
            return null
        }

        return (
            <WidgetContainer title={t('Files')} isLoading={isLoading} dataType='file' classes={{ container: classes.widgetContainer }}>
                <Grid direction='column' wrap='nowrap' container item className={classes.filesContainer}>
                    {
                        fileData.length ? fileData.slice(0, 10).map(file => (
                            <FileItem onClick={this.openFile(file.Key)} key={file.Key} file={file.Value.Source} />)) :
                            <AddItem dataType='file' classes={{ container: classes.addItemContainer }} />
                    }
                </Grid>
            </WidgetContainer>)
    }

}

const mapStateToProps = (state, ownProps) => ({
    collaborationService: state.services.collaborationService,
    groupInfo: state.results.channel,
    userCollections: state.userData.collections,
    profileData: state.userData.profileData,
    queryService: state.services.queryService,
    search: state.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('groupWidgetTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FileWidget);