export const getSubscriptionColors = (subscriptionType) => {
    switch (subscriptionType) {
        case 0:
            return {
                main: '#8645f9',
                secondary: '#f5f0ff'
            }
        case 1:
            return {
                main: '#8645f9',
                secondary: '#f5f0ff'
            }
        case 2:
            return {
                main: '#eb8d3c',
                secondary: '#fff6eb'
            }
        default:
            return {
                main: '#8645f9',
                secondary: '#f5f0ff'
            }
    }
}