import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    buttonAll: {
        cursor: 'pointer',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: 88,
        height: 42,
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        border: 'solid 2px #e0e0e0',
        backgroundColor: '#f5f5f5',
        alignSelf: 'center',
        alignItems: 'center',
        color: '#000',
        '&:hover': {
            boxShadow: '1px 1px 1px #EAEAEA',
            background: '#F7F7F7',
        },
        '&:active': {
            boxShadow: 'inset 1px 1px 1px #DFDFDF'
        }
    },
    disabled: {
        cursor: 'inherit',
        opacity: 0.5,
        pointerEvents: 'none'
    },
    allText: {
        fontFamily: 'Rubik',
        fontSize: 16,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.75,
        letterSpacing: 'normal',
    },
});

const AllButton = (props) => {
    const {refBtn, iconAfterText, classes, containerStyle, textStyle, title, onClick, disabled, icon, setIsEnter, onMouseEnter, onMouseLeave} = props
    
    const _onMouseEnter = () => {
        onMouseEnter?.()
        setIsEnter?.(true)
    }

    const _onMouseLeave = () => {
        onMouseLeave?.()
        setIsEnter?.(false)
    }

    return (
        <div data-intrcm-id="AllButton_l0ebjdrb" ref={refBtn} onMouseEnter={_onMouseEnter} onMouseLeave={_onMouseLeave} onClick={!disabled && onClick} className={[classes.buttonAll, containerStyle, disabled ? classes.disabled : ''].join(" ")}>
            {!iconAfterText && !!icon ? icon : null}
            <div data-intrcm-id="AllButton_jnin0sv0" className={[classes.allText, textStyle].join(" ")}>{title || 'ALL'}</div>
            {!!iconAfterText & !!icon ? icon : null}
        </div>
    )
}

AllButton.defaultProps = {
    disabled: false,
    containerStyle: '',
    textStyle: ''
}

export default withStyles(styles, {withTheme: true})(AllButton);


