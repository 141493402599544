import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, CircularProgress, withStyles, IconButton } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import OnErrorDialog from '../../components/Common/OnErrorDialog';
import UserListItemCustom from '../../components/Common/UserListItemCustom';
import withErrorCatcher from '../../components/Common/withErrorCatcher';
import ChatBubbleIcon from '@material-ui/icons/Chat';
import { withTranslation } from 'react-i18next';
import { CHAT_GROUP_INFO } from './chatRecognize';
import { managerGroupChat } from '@tuqqi/web';

const styles = theme => ({
    userItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    root: {
        minWidth: 475
    },
    progress: {
        margin: 'auto'
    },
    chatBubbleIcon: {
        color: theme.customColors.primary.b600
    },
})

class ChatUsersDialog extends React.Component {
    getUsers = () => {
        const fullname = x => x.firstname + ' ' + x.lastName
        const usersFromGlobal = this.props.usersToShow
        .map(user => this.props.users.find(x => x.id === user.id))
        .sort((a, b) => fullname(a) > fullname(b) ? 1 : -1)   
        

        if(!!CHAT_GROUP_INFO.groupUid && !!CHAT_GROUP_INFO.isGroup && managerGroupChat.getKeepGroup()?.collectionGroup?.groupUid) {

            return managerGroupChat.onFilterUsers(usersFromGlobal)
        }
        return usersFromGlobal; 
    }
    render() {
        const { open, onClose, classes, listItemOnClick, isLoading, userData, secondaryText, disabled, isButton, title, getSecondaryText, rightElementOnClick,t } = this.props
        
        const usersFromGlobal = this.getUsers()
        
        return <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>{title}</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.root}>
                { isLoading ? <CircularProgress className={classes.progress} />
                    :<List>
                        {usersFromGlobal.map(user => {
                            const rightElement = user.auth0_id === userData.profileData.auth0_id 
                                ? null 
                                : <IconButton onClick={() => rightElementOnClick(user)} >
                                    <ChatBubbleIcon fontSize="small" className={classes.chatBubbleIcon} />
                                </IconButton>

                            return <UserListItemCustom 
                                user={user} 
                                // userData={userData}
                                key={user.tag} 
                                onClick={() => listItemOnClick(user)} 
                                rightElement={rightElement}
                                disabled={disabled} 
                                isButton={isButton} 
                                secondaryText={secondaryText || getSecondaryText(user)}
                                classes={classes} 
                            />
                        })}
                    </List> 
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.users,
    userData: state.userData,
})

export default compose(
    withStyles(styles, { withTheme: true }), 
    withTranslation("chat"),
    withErrorCatcher(OnErrorDialog),
    connect(mapStateToProps))(ChatUsersDialog)