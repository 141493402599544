import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import GeneralButton from '../../../Common/AllButton/AllButton';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils';
import { USER_PROFILE_UPDATE } from '../../../../../actionTypes/actionTypes';
import { push } from 'react-router-redux';
import ChatsListWrap from './ChatsListWrap';

const styles = (theme) => ({
    header: {
        height: 52,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 16,
        marginLeft: 16,
    },
    headerTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: 0.24763,
        color: '#0C0C0D',
    },
    buttonContainer: {
        marginRight: 0,
        paddingRight: 9,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main
        },
    },
    buttonText: {
        marginBottom: -1,
        fontSize: 12,
        fontWeight: '400',
        letterSpacing: 0.4,
    },
})

const ChatsContent = (props) => {
    const {classes, t, dispatch, isFocused} = props


    const onViewAll = () => {
        dispatch({type: 'BLUR_CHATS_MODAL'})
        dispatch({ type: USER_PROFILE_UPDATE, profileData: { lastEnterChat: new Date() } });
        dispatch(push('/chat'));
    }

    const ViewAll = <GeneralButton onClick={onViewAll} containerStyle={classes.buttonContainer} textStyle={classes.buttonText} title={t("View all")} />

    const Header = <div data-intrcm-id="ChatsContent_bfsr8a1j" className={classes.header}>
        <div data-intrcm-id="ChatsContent_p99x2wl9" className={classes.headerTitle}>{t("Chats")}</div>
        {ViewAll}
    </div>

    return (
        <div data-intrcm-id="ChatsContent_78kz2c0n" className={classes.content}>
            {Header}
            {!!isFocused && <ChatsListWrap />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFocused: state.chatsModal.isFocused,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('chat'),
    connect(mapStateToProps, mapDispatchToProps),
)(ChatsContent)