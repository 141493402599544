import React, { useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { defaultDataTypes } from '../../../components/Common/Utils/defaultDataTypes';
import TooltipMembers from './TooltipMembers';

const styles = theme => ({
    tooltip: {
        background: '#4A4A4F',
        position: 'relative'
    },
    tooltipTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#FFFFFF',
    },
    tooltipSubTitle: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#D7D7DB',
    },
    triangle: {
        position: 'absolute',
        top: '100%',
        left: 8,
        marginTop: -3.5,
        transform: 'rotate(-135deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
});

const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings
})

export const mapTime = (date) => {
    const t = moment(date).format("h:mm a")
    const [time, zone] = t.split(" ")
    const [hh, mm] = time.split(":")
    const newTime = mm === '00' ? `${hh}` : time
    return `${newTime}${zone}`
}

const CalendarTooltip = (props) => {
    const {classes, event, inputMappings} = props
    const {title, extendedProps, start, end} = event
    const {dataType = '', laneStatus, members} = extendedProps
    
    const input = useMemo(() => {
        if(!dataType) return ''
        const allInputs = defaultDataTypes().concat(inputMappings)
        const x = allInputs?.find?.(input => input.customDataMapping.dataTypeFilter === dataType)
        return x?.title ?? ''
    }, [dataType, inputMappings])

    const timeStr = useMemo(() => {
        const startTime = mapTime(start)
        const endTime = mapTime(end)
        if(!end) return`${startTime}`
        return `${startTime}-${endTime}`
    }, [start, end])

    const laneStr = useMemo(() => {
        return laneStatus?.title ?? ''
    }, [laneStatus])

    return <>
        <div data-intrcm-id="CalendarTooltip_wjkql6xf" className={classes.tooltipTitle}>{title || 'Untitled'}</div>
        <div data-intrcm-id="CalendarTooltip_u4pgrshs" className={classes.tooltipSubTitle}>{!end ? '' :timeStr} {!end ? '' : '·'} {input} · {laneStr}</div>
        {!!members && !!members?.length && <div data-intrcm-id="CalendarTooltip_osonrid7" style={{marginTop: 8}}>
            <TooltipMembers docMembers={members} />
        </div>}
        <div data-intrcm-id="CalendarTooltip_rj5zy200" className={classes.triangle} />
    </>
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    withErrorCatcher(),
)(CalendarTooltip)
