
const TYPE_PARAMETR = 'parametr'
const TYPE_PARAMETR_CONSTANT = 'parametr_constant'
const BRACKET = 'bracket'
const TYPE_OPERATOR = 'operator'

const VIEW_PARAMS_BRACKET = 'view_params_bracket'
const VIEW_OPERATORS = 'view_operators'

export const KEYS = {
    [TYPE_PARAMETR]: TYPE_PARAMETR,
    [TYPE_PARAMETR_CONSTANT]: TYPE_PARAMETR_CONSTANT,
    [BRACKET]: BRACKET,
    [TYPE_OPERATOR]: TYPE_OPERATOR,
}

export const VIEWS = {
    [VIEW_PARAMS_BRACKET]: VIEW_PARAMS_BRACKET,
    [VIEW_OPERATORS]: VIEW_OPERATORS,
}

export const operators = ['+', '-', '*', '/']

export const mapNumberFields = (fields = []) => {
    return fields.map(f => ({sourceField: f.sourceField, fieldName: f.fieldName}))
}

//[nextView, nextRequired]
export const getNextView = (currentStep = -1, lastKey, lastValue) => {
    if(currentStep === -1) return [VIEWS.view_params_bracket]
    if(lastKey === KEYS.bracket && lastValue === ')') return [VIEWS.view_operators]
    if(lastKey === KEYS.bracket) return [VIEWS.view_params_bracket]
    if(lastKey === KEYS.operator) return [VIEWS.view_params_bracket]
    if(lastKey === KEYS.parametr) return [VIEWS.view_operators]
    if(lastKey === KEYS.parametr_constant) return [VIEWS.view_operators]
}

//{key, order, value, label}[]
export const getOpenBracket = (parts = []) => {
    return '('
}

//{key, order, value, label}[]
export const getCloseBracket = (parts = [], order) => {
    const opened = parts.filter(x => x.key === KEYS.bracket && x.value === '(')
    const closed = parts.filter(x => x.key === KEYS.bracket && x.value === ')')
    if(!opened.length && !closed.length) return ''
    if(opened.length === closed.length) return ''

    const sorted = parts.sort((a, b) => a.order - b.order)

    const x = sorted.find(x => x.order === (order))
    if(!!x && x.value === '(') return ''

    return ')'
}

//{key, order, value, label}[]
const checkBrackets = (parts = []) => {
    const opened = parts.filter(x => x.key === KEYS.bracket && x.value === '(')
    const closed = parts.filter(x => x.key === KEYS.bracket && x.value === ')')
    if(!opened.length && !closed.length) return true
    return opened.length === closed.length
}
//{key, order, value, label}[]
const checkAtLeastOneOperator = (parts = []) => {
    const operators = parts.filter(x => x.key === KEYS.operator)
    return operators.length >= 1
}
//{key, order, value, label}[]
const checkAtLeastTwoParametrs = (parts = []) => {
    const params = parts.filter(x => x.key === KEYS.parametr || KEYS.parametr_constant)
    return params.length >= 2
}

//{key, order, value, label}[]
export const validateFormula = (parts = []) => {
    const sorted = parts.sort((a, b) => a.order - b.order)
    if(!sorted.length) return false
    if(!checkBrackets(sorted)) return false
    
    const last = sorted.slice(-1)[0]
    if(last.key === KEYS.operator) return false
    if(!checkAtLeastOneOperator(sorted)) return false
    if(!checkAtLeastTwoParametrs(sorted)) return false
    return true
}

const DEFAULT_JSON_CALC = { params: [], template: "", operations: [], calculationUnits: '' }

//{key, order, value, label}[]
export const prepareBeforeSave = (parts = [], calculationUnits = '') => {
    const sorted = parts.sort((a, b) => a.order - b.order)
    let lastPos = 0
    let poss = {

    }

    const initial = { params: [], template: "", operations: [], calculationUnits: '' }

    const x = sorted.reduce((accum, current, i) => {
        if(current.key === KEYS.parametr) {
            if(!(current.value in poss)) {
                poss[current.value] = lastPos
                lastPos++
            }
            accum.params.push(current.value)
            accum.template += ` {${poss[current.value]}}`
            return accum
        }
        accum.template += ` ${current.value}`
        return accum
    }, initial)
    x.operations = parts
    x.calculationUnits = calculationUnits
    return JSON.stringify(x)
}

export const prepareBeforeStartOperations = (json = `${DEFAULT_JSON_CALC}`) => {
    try {
        const x = JSON.parse(json)
        return x?.operations ?? []
    } catch (err) {
        return []
    }
}

export const prepareBeforeStartUnits = (json = `${DEFAULT_JSON_CALC}`) => {
    try {
        const x = JSON.parse(json)
        return x?.calculationUnits ?? ''
    } catch (err) {
        return ''
    }
}