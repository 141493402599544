import React from 'react'
import DatePicker from '../../Header/SubheaderToolbar/Filters/DatePicker';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { Paper, Typography, InputLabel, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { getDueDate, getDueDateStyling } from '../../../../utils/taskDetailsUtils';
import withErrorCatcher from '../withErrorCatcher';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 24
    },
    label: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "rgba(151, 154, 170, 0.8)"
    },
    statusText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d",
        borderRadius: 2,
        backgroundColor: '#e3e3e3',
        padding: 8,
        marginTop: 6,
        width: 'fit-content'
    },
    dueDateWrapper: {
        marginLeft: 24
    },
    dueDateText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#ffffff",
        borderRadius: 2,
        backgroundColor: '#ffb632',
        padding: 8,
        marginTop: 6,
        width: 'fit-content'
    },
    noDueDateText: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d",
        borderRadius: 2,
        backgroundColor: '#e3e3e3',
        padding: 8,
        marginTop: 6,
        width: 'fit-content',
    },
    checkBoxColorSecondaryWhite: {
        color: 'white',
    }
})

class TaskDetails extends React.Component {

    constructor(props) {
        super(props)
    }

    getStatus = () => {
        const { groupInfo, docId, t } = this.props

        if (!groupInfo || !groupInfo.collectionViews) {
            return null
        }

        const kanbanInfo = groupInfo.collectionViews.find(x => x.collectionViewType === 'Kanban')
        if (!kanbanInfo) {
            return null
        }

        const lanes = kanbanInfo.itemLists
        for (let i = 0; i < lanes.length; i++) {
            const lane = lanes[i]
            if (lane.items.find(x => x.documentId === docId)) {
                return lane.title
            }
        }

        return null
    }



    render() {
        const { item, classes, t } = this.props
        const status = this.getStatus()
        const dueDate = getDueDate(item)
        const styling = getDueDateStyling(item.dueDateUTC, item.taskDueDateDone)

        if (!dueDate && !status) {
            return null
        }

        return <div data-intrcm-id="TaskDetails_9lsnu5qx" className={classes.wrapper}>
            {status && <div data-intrcm-id="TaskDetails_e2rfiiyx">
                <Typography className={classes.label}>{t('Status')}</Typography>
                <Typography variant="body1" className={classes.statusText}>{status}</Typography>
            </div>}
            {dueDate && <div data-intrcm-id="TaskDetails_71llwq15" className={status && classes.dueDateWrapper}>
                <Typography className={classes.label}>{t('Due Date')}</Typography>
                <Typography variant="caption" className={classes.dueDateText} style={{ backgroundColor: styling.backgroundColor, color: styling.textColor }}>
                    {dueDate}
                </Typography>
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => ({
    groupInfo: state.results.channel,
    profileData: state.userData.profileData,
})

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    withTranslation('itemFullViewModal'),
    connect(mapStateToProps))(TaskDetails)