import { Grid, ListItem, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from "../../../../actions/appActions";
import SplitedText from '../../Comments/splitedText';
import AutosuggestField from '../../Common/autosuggestField/autosuggestField';
import { TodoRemoveButton, TodoToggleButton } from '../../Common/Task/toggleButtons';
import withErrorCatcher from '../../Common/withErrorCatcher.js'

const styles = theme => ({
    item: {
        display: 'flex',
        alignItems: 'center'
    },
    doneTaskText: {
        textDecorationStyle: 'solid',
        textDecorationColor: 'black',
        textDecorationLine: 'line-through',
    }
});

class TaskListItem extends Component {
    state = {
        hover: false,
        active: false,
        newTaskText: ''
    }

    toggleHover = (hover) => {
        this.setState({ hover })
    }

    toggleActive = () => {
        const { collaborationService, dispatch, onTaskUpdate, isNewTask, task, userUid } = this.props;

        task.isDone = !task.isDone
        task.modifierId = userUid

        onTaskUpdate(isNewTask, task)

        if (!isNewTask) {
            collaborationService.updateTask(task)
        }
    }

    delete = () => {
        const { collaborationService, dispatch, onTaskDeleted, isNewTask, task, t } = this.props;
        onTaskDeleted(isNewTask, task)

        if (!isNewTask) {
            dispatch(showSnackbar(t('Deleting task')));

            collaborationService.deleteTask(task).then(() => {
                dispatch(showSnackbar(t('Task deleted!')));
            })
        }
    }

    saveNewTask = () => {
        const { saveNewTask, task } = this.props;
        if (task.text) {
            saveNewTask(task.checklistUid)
        }
    }

    handleNewTaskChanged = (event, { newValue, method }) => {
        const { handleNewTaskChanged, task } = this.props;
        handleNewTaskChanged(task.checklistUid, newValue)
    }

    onMouseLeave = () => {
        this.toggleHover(false)
    }

    onMouseEnter = () => {
        this.toggleHover(true)
    }

    render() {
        const { classes, dispatch, addTask, task, isNewTask, t, isChangeable, isTemplate } = this.props;

        return (
            <ListItem disableGutters
                onMouseLeave={this.onMouseLeave}
                onMouseEnter={this.onMouseEnter}
                style={{ borderBottom: '1px dashed rgb(0, 0, 0, 0.54)', opacity: (this.state.hover ? 1 : 0.8) }}>
                <Grid container wrap='nowrap'>
                    {isTemplate
                        ? null
                        : <Grid item style={{ width: 24 }} className={classes.item} onClick={this.toggleActive}>
                            <TodoToggleButton active={task.isDone} />
                        </Grid>
                    }

                    <Grid item style={{ margin: '0px 8px', width: '100%' }} className={[classes.item, (task.isDone ? classes.doneTaskText : '')].join(' ')}>
                        {
                            isChangeable ?
                                <AutosuggestField
                                    value={task.text}
                                    onChange={this.handleNewTaskChanged}
                                    onSubmit={this.saveNewTask}
                                    placeholder={t('Add New Task')} /> :
                                <SplitedText text={task.text} />}
                    </Grid>

                    <Grid item style={{ width: 24 }} className={classes.item} onClick={this.delete}>
                        <TodoRemoveButton hover={this.state.hover} />
                    </Grid>
                </Grid>

            </ListItem>
        )



    }
}

const mapStateToProps = (state) => ({
    userUid: state.userData.profileData.auth0_id,
    collaborationService: state.services.collaborationService,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('taskListItem'),

    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TaskListItem);