import { CircularProgress, withStyles } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../Common/withErrorCatcher';
import '../../../../Placeholders/css/mainFeedPlaceholder.css';
import SponsoredContent from './SponsoredContent';

const styles = theme => ({
});

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

class SponsoredContentWidget extends Component {


    state = {
        isLoading: true,
        docIds: [],
        result: {}
    }

    getAll = () => {
        const query = {
            // itemsIds: ids,
            page: 0,

            // size: ids.length,
            AggType: 'FLAT',
        }

        this.props.adminService.getSponsoredContent().then((docIds) => {

            if (docIds && docIds.length) {
                query.itemsIds = docIds;
                query.size = docIds.length;
                this.props.queryService.SearchByIds(query)
                    .then((results) => {

                        const numberRandom = getRandomInt(docIds.length - 1)
                        this.setState({
                            docIds: docIds,
                            result: results.data[numberRandom],
                            isLoading: false,
                            chosenDoc: numberRandom
                        })
                    })
            } else {
                this.setState({ isLoading: false })
            }
        })
    }

    componentDidMount() {
        this.getAll()
    }

    render() {
        const { classes } = this.props;

        const spinner = <div data-intrcm-id="SponsoredContentWidget_f9mwackm" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>

        let content = spinner;
        if (!this.state.isLoading) {

            let doc = {};

            if (!this.state.docIds || !this.state.result || !this.state.result.Value) {
                doc = {
                    title: 'No sponsored content',
                    description: 'No sponsored content',
                    mediaUrl: '',
                    user: {
                        tag: ''
                    }
                }
            }else{
                doc = this.state.result.Value.Source
            }

            

            content = <SponsoredContent doc={doc} />
        }

        return content;
    }

}


const mapStateToProps = (state) => ({
    ...state.services
})
export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(mapStateToProps))(SponsoredContentWidget);
