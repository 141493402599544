import { combineReducers, applyMiddleware, compose, createStore } from 'redux';
// import { reduxReactRouter, routerStateReducer, ReduxRouter } from 'redux-router';
import { createHistory } from 'history';
import { routerMiddleware, push } from 'react-router-redux'
import thunk from 'redux-thunk'
import MixpanelMiddleware from './mixpanelMiddleware'
import mixpanel from 'mixpanel-browser'
import reducers, {needPersistReducers} from '../reducers/combinedReducers'
import { persistReducer, persistStore } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  whitelist: needPersistReducers
}

mixpanel.init('1409896fdef42a29161bb6c10c38add2')
const mixpanelMiddleware = new MixpanelMiddleware(mixpanel)


const configureStore = (history, preloadedState) => {

  const persistedReducer = persistReducer(persistConfig, reducers)


  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk, routerMiddleware(history), mixpanelMiddleware),


    )

  );

  const persistor = persistStore(store, {manualPersist: true})


  return { store, persistor }
}

export default configureStore
