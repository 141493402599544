import { Tooltip, withStyles } from '@material-ui/core'
import { moveDocumentSuccessLane } from '@tuqqi/common'
import { ItemPreviewMoreMenuNew, NewUiWrapper, showSnackbar, updateSpecificItem } from '@tuqqi/web'
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { store } from '../../../../../../../..'
import { SERVER_API } from '../../../../../../../../utils/serverAPI'
import { defaultDataTypes } from '../../../../../../Common/Utils/defaultDataTypes'
import { CompleteIcon } from './CompleteIcon'
import { SignIcon } from './SignIcon'
import { UnassignIcon } from './UnassignIcon'

const styles = (theme) => ({
    tooltip: {
        fontStyle: 'normal',
        fontfamily: 'Rubik',
        fontWeight: 400,
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 12,
    },
    moreMenu: {
        alignSelf: 'flex-start',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: 24,
        height: 24,
        borderRadius: 4,
        '&:hover': { 
            backgroundColor: '#fff', 
        }
    },
    linear: {
        width: 32,
        height: 'inherit',
        position: 'absolute',
        right: 27,
        background: 'linear-gradient(270deg, #FAF8FE 33.33%, rgba(123, 79, 240, 0) 100%)'
    },
    container: {
        background: '#fff',
        position: 'absolute',
        right: 16,
        paddingRight: 0,
        height: 'inherit',
        marginRight: 0,
    },
    container1: {
        background: 'rgba(123, 79, 240, 0.04)',
        right: 0,
        width: '100%',
        justifyContent: 'flex-end',
        paddingRight: 0,
        height: 'inherit',
        marginRight: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    container1PX: {
        background: '#FBFBFC!important',
        borderRadius: 6
    },
    btn: {
        width: 24,
        cursor: 'pointer',
        height: 24,
        marginLeft: 8,
        background: '#fff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        border: '1px solid #EDEDF0',
    },
    btnWrap: {
        width: 24,
        cursor: 'pointer',
        height: 24,
        marginLeft: 8,
        background: '#fff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // borderRadius: 4,
        // border: '1px solid #EDEDF0',
    },
})
const _ItemActions = (props) => {
    const {wrapHeight, classes, t, dispatch, userSettingsService, coll, exists, actionCenterView, item, customInputs, onTitle, onCloseMenu, onOpenMenu, isPX} = props

    const mappingTitle = useMemo(() => {
        const def = defaultDataTypes()
        const allDataTypes = [...def, ...customInputs]
        const x = allDataTypes.find(x => x.customDataMapping.dataTypeFilter === item.dataType)
        if(!x && !!item.dataType) return item.dataType 
        if(!x) return 'Untitled'
        return x.title || 'Untitled'
    }, [customInputs, item.dataType])

    const onCallAction = React.useCallback((docId, actionCenterViewType) => {
        switch (actionCenterViewType) {
            case 'sign': {
                dispatch(showSnackbar(t("Saving changes", "Saving changes...")))
                userSettingsService.confirmRequest(docId).then(({val}) => {
                    if(!val) return
                    dispatch(updateSpecificItem(docId))
                    dispatch(showSnackbar(t("Changes saved")))
                })
                return
            }
            case 'lostMember': {
                dispatch(showSnackbar(t("Saving changes", "Saving changes...")))
                userSettingsService.removeMeFromMembersDocument(docId).then(({val}) => {
                    if(!val) return
                    dispatch(updateSpecificItem(docId))
                    dispatch(showSnackbar(t("Changes saved")))
                })
                return
            }
            case 'complete': {
                if(!exists || !coll.collectionUid) return
                const data = {
                    collectionId: coll.collectionUid,
                    collectionViewType: 'Kanban',
                    cardId: docId,
                    sourceLaneId: 0,
                    targetLaneId: 0,
                    position: 0,
                    docTitle: ''
                }
                dispatch(moveDocumentSuccessLane({...SERVER_API, updateItem: (docId) => {dispatch(updateSpecificItem(docId))}})(data))
                return
            }
            default:
        }
    }, [exists, coll])

    const AdditionalActions = useMemo(() => {
        const Items = []
        if('mustRead' === actionCenterView) {
            Items.push(<div data-intrcm-id="ItemActions_vagmlox6" className={classes.btnWrap}>
                <div data-intrcm-id="ItemActions_dmw7l0b5" onClick={onTitle} style={{height: wrapHeight}} className={classes.linear} />
                <Tooltip classes={{tooltip: classes.tooltip}} title={`${t('This {{mappingTitle}} is awaiting your approval', {mappingTitle})}. ${t("Sign it")}.`} placement="top">
                    <div data-intrcm-id="ItemActions_twofw8qz" style={{marginLeft: 0}} onClick={() => onCallAction(item.docId, 'sign')} className={classes.btn}>
                        <SignIcon />
                    </div>
                </Tooltip>
            </div>)
            return Items
        }
        Items.push(<div data-intrcm-id="ItemActions_m1mahjq2" className={classes.btnWrap}>
            <div data-intrcm-id="ItemActions_ow5aintj" onClick={onTitle} style={{height: wrapHeight}} className={classes.linear} />
            <Tooltip classes={{tooltip: classes.tooltip}} title={`${t('Move this {{mappingTitle}} to the next green status on the process', {mappingTitle})}`} placement="top">
                <div data-intrcm-id="ItemActions_l3t1s0eu" onClick={() => onCallAction(item.docId, 'complete')} style={{marginLeft: 0}} className={classes.btn}>
                    <CompleteIcon />
                </div>
            </Tooltip>
        </div>)
        Items.push(<Tooltip classes={{tooltip: classes.tooltip}} title={`${t('Remove yourself as an assignee of this {{mappingTitle}}', {mappingTitle})}`} placement="top">
        <div data-intrcm-id="ItemActions_hgapo6bp" onClick={() => onCallAction(item.docId, 'lostMember')} className={classes.btn}>
            <UnassignIcon />
        </div>
        </Tooltip>)
        return Items
    }, [actionCenterView, onCallAction, item.docId, wrapHeight, mappingTitle])

    const Menu =  <Tooltip classes={{tooltip: classes.tooltip}} title={t("More actions")} placement="top">
    <div data-intrcm-id="ItemActions_gyttkvd5" className={classes.btn}>
        <NewUiWrapper store={store} callbacks={{}}>
            <ItemPreviewMoreMenuNew onOpen={onOpenMenu} onClose={onCloseMenu} iconClassName={classes.moreMenu} view={'actions'} document={item}  />
        </NewUiWrapper>
    </div>
    </Tooltip>

    return <div data-intrcm-id="ItemActions_qh9rkgem" style={{height: wrapHeight}} className={classes.container}>
        <div data-intrcm-id="ItemActions_ijxhdyoi" style={{height: wrapHeight}} className={`${classes.container1} ${isPX ? classes.container1PX : ''}`}>
        <>{AdditionalActions}</>
        {Menu}
        </div>
    </div>
}


const mapStateToProps = (state) => {
    return {
        userSettingsService: state.services.userSettingsService,
        customInputs: state.data.globalData.customInputMappings.data ?? [],
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('actionCenter'),
    connect(mapStateToProps, mapDispatchToProps),
)(_ItemActions)