import React, { PureComponent } from 'react';
import withErrorCatcher from './withErrorCatcher.js'
import { Typography, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose';

// Apply some reset
const styles = theme => ({
    container: {
        marginBottom: 15
    }
});

class NoResultsFound extends PureComponent{
    render(){
        const { t, classes } = this.props;

        return (
            <Typography variant="title" className={classes.container}>
                {t('No results found.')}
                </Typography>
        )
    }
}
export default compose(withStyles(styles),
withTranslation('noResults'))(NoResultsFound);


