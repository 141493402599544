
import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import Text from "./Text";

const styles = theme => ({
    container: {
        overflow: 'hidden',
        margin: '0px 16px',
        borderRadius: '2px',
        cursor: 'pointer'
    },
    textContainer: {
        backgroundColor: '#f7f7f7',
        padding: '12px',
    },
    img: {
        maxHeight: 240,
        width: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909",
        "overflow": "hidden",
        "wordBreak": "break-all",
        "whiteSpace": "nowrap",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
    }
});


class CustomInputTemplateTopImage extends PureComponent {

    render() {
        const { classes, result, mappings, openItem, imageUrl } = this.props;

        const { docText, title, dataType } = result;

        return (
            <Grid container wrap='nowrap' direction='column' onClick={openItem} className={classes.container} >
                <img src={imageUrl} className={classes.img} />
                <Grid container direction='column' wrap='nowrap' item className={classes.textContainer} >
                    <Text dataType={dataType} mappings={mappings} title={title} />
                    <span className={classes.previewDescription}  dir="auto" >
                        {docText}
                    </span>
                </Grid>
            </Grid>)

    }



}


export default compose(
    withStyles(styles)
)(CustomInputTemplateTopImage);
