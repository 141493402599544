import React, { Component } from 'react';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { CardGiftcard } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import red from '@material-ui/core/colors/red';

// Apply some reset
const styles = theme => ({
    clickableTitle: theme.clickableTitle,

    card: {
        width: 400
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class PreviewPrize extends Component {

    state = {
        open: false
    };

    openLink = () => {
        window.open(this.props.link)
    }
    render() {
        const { classes, plaseIndex, title, image, description, openDialog, onClose } = this.props;
        const content = (
            <div data-intrcm-id="previewPrize_fmzmjamt">
                <Card className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Recipe" className={classes.avatar}>
                                <CardGiftcard />
                            </Avatar>
                        }
                        title={<span className={classes.clickableTitle} onClick={this.openLink}>{plaseIndex + ' Place'}</span>}
                        subheader={<span className={classes.clickableTitle} onClick={this.openLink}>{title}</span>}
                        
                    />
                    <CardMedia onClick={this.openLink}
                        className={classes.media}
                        image={image}
                        mediaStyle={{ backgroundSize: 'contain' }}
                    />
                    <CardContent>
                        <Typography component="p" onClick={this.openLink}>
                            {description}
                        </Typography>
                    </CardContent>
                    <DialogActions style={{margin:0, padding:'8px 4px'}}>
                        <Button onClick={onClose} color="primary">
                            Close
            </Button>
                    </DialogActions>
                </Card>
            </div>

        )
        return (<div data-intrcm-id="previewPrize_bxq0lmkj">
            <Dialog
                open={openDialog}
                onClose={onClose}
                aria-labelledby="form-dialog-title">
                {/* <DialogContent> */}
                {content}
                {/* </DialogContent> */}
                {/* <div data-intrcm-id="previewPrize_pb2bmva9" className={classes.dialogSize}>
                    <Grid container spacing={16} className={classes.header} spacing={16}>
                        <Grid item>
                            <div data-intrcm-id="previewPrize_0h4ltc3h" className={classes.avatar}>
                                <ListItemIcon>
                                    <CardGiftcard style={{ color: 'red', height: 48, width: 48 }} />
                                </ListItemIcon>
                            </div>
                        </Grid>
                        <Grid item className={classes.clickableTitle} onClick={this.openLink}>
                            <Typography variant="body2"  >
                                {plaseIndex} Place
                        </Typography>
                            <Typography variant="body1">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid md={3}>
                        </Grid>
                        <Grid item alignItems={'flex-end'}>
                        </Grid>
                    </Grid>
                    <img src={image} className={classes.img} />
                    <Typography variant="body1" className={classes.description}>
                        {description}
                    </Typography>
                </div> */}

            </Dialog>
        </div>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(PreviewPrize);