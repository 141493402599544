import { Button, List, Paper, withStyles } from '@material-ui/core'
import React, {useCallback, useEffect, useMemo} from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withErrorCatcher from '../../../../components/Common/withErrorCatcher'
import {FeedNavigationItemRow} from './FeedNavigationItemRow'
import AdminSettingsTitle from '../../../admin/Common/AdminSettingsTitle'
import {deleteFeedMenuItem, setFeedMenuItems, setOpenedFeedMenuItem} from '../../../../../actions/feedNavigation'
import { getMenuItemType } from '../../../../../utils/feedNavigation/getMenuItemType'
import { push } from 'react-router-redux'
import { showConfirm } from '../../../../../actions/appActions'
import { withTranslation } from 'react-i18next'
import { OldPagesWrap } from '../../../admin/newway/OldPagesWrap'
const styles = (theme) => ({
    content: {
        marginBottom: 16,
    },
    container: {

    },
    paperStyle: {

    },
    button: {
        margin: 16
    }
})

const __FeedNavigationSettingsItem = (props) => {
    const { orgMenuItems, classes, dispatch, adminService,t } = props

    useEffect(() => {
        adminService.getMenuItems().then(res => {
            dispatch(setFeedMenuItems(res))
        })
    }, [])
    const onClickNew = () => {
        dispatch(setOpenedFeedMenuItem(null, true))
        dispatch(push(`/admin/navigation/create-feed-navigation-item`))
    }

    const onGoToMenuItem = (item) => {
        dispatch(setOpenedFeedMenuItem(item, false))
        dispatch(push(`/admin/navigation/edit-feed-navigation-item`))
    }

    const onDelete = (item) => {
        adminService.deleteMenuItem(item.id).then((res) => {
            if(!res) return
            dispatch(deleteFeedMenuItem(item.id))
        })
    }
    const onDeleteConfirm = (item) => (e) => {
        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), () => onDelete(item)))

        e.stopPropagation()
        return false
    }

    const firstLayer = useMemo(() => {
        return orgMenuItems.filter((x) => x.categoryId === 0 || x.type !== 0 && !orgMenuItems.find(_x => _x.id === x.categoryId))
    }, [orgMenuItems])

    const renderSubItems = useCallback((item) => {
        if(item.type !== 0) return null
        const subs = orgMenuItems.filter((it) => it.type !== 0 && it.categoryId === item.id)
        if(!subs.length) return null

        return subs.map((subItem) => {
            return <FeedNavigationItemRow
                isSub
                onDelete={onDeleteConfirm(subItem)}
                icon={subItem.customIcon ?? subItem.type === 2 ? 'collectibles' : 'public'}
                key={`${subItem.categoryId}_${subItem.referenceUid}`}
                onClick={() => onGoToMenuItem(subItem)}
                primary={subItem.customTitle}
                secondary={getMenuItemType(subItem.type)} />
        })
    }, [classes, orgMenuItems])


    const New = <Button className={classes.button} variant="raised" onClick={onClickNew} >{t('New menu item')}</Button>

    const ListContent = firstLayer.map((item) => (
        <>
        <FeedNavigationItemRow
            onDelete={onDeleteConfirm(item)}
            icon={item.customIcon ?? item.type === 2 ? 'collectibles' : 'public'}
            key={`${item.categoryId}_${item.referenceUid}`}
            onClick={() => onGoToMenuItem(item)}
            primary={item.customTitle}
            secondary={getMenuItemType(item.type)}
        />
        {renderSubItems(item)}
        </>
    ))

    return <OldPagesWrap classNameContent={classes.content} >
        <AdminSettingsTitle text={t('Feed navigation')} />
        <Paper className={classes.paperStyle}>
            <List>
                {ListContent}
            </List>
            {New}
        </Paper>
    </OldPagesWrap>
}

const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    orgMenuItems: state.feedNavigation.orgMenuItems
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const FeedNavigationSettings = compose(
    withStyles(styles, {withTheme: true}),
    withTranslation("admin"),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(__FeedNavigationSettingsItem)