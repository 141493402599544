import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import EventIcon from '@material-ui/icons/Event'
import WidgetSingleOption from '../../../Common/WidgetOptions/WidgetSingleOption';
import {applySearchQuery} from '../../../../views/search/searchUtils';
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import moment from 'moment';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto auto',
        display: 'flex',
        flexWrap: 'wrap',
    },
    option: {
        marginLeft: 8,
        marginBottom: 8,
    }
});

const getOptions = t => ([
    {label: t('Today'), key: 'today', selected: false},
    {label: t('Week'), key: 'week', selected: false},
    {label: t('Month'), key: 'month', selected: false},
    {label: t('Year'), key: 'year', selected: false},
])

const TimeDateWidget = (props) => {
    const { classes, t, dispatch, searchQuery, mixpanelTrack } = props;
    const [options, setOptions] = useState(getOptions(t))

    const onOptionClick = (key) => {
        mixpanelTrack(`Time Date widget - ${key}`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            o.selected = false
            return o
        })
        setOptions(newOptions)
        const selected = newOptions.filter(o => o.selected).map(o => o.key)
        const newQuery = {...searchQuery}
        if(!selected || !selected.length) {
            newQuery.dateFrom = ''
            newQuery.dateTo = ''
        } else {
            const key = selected[0]
            const [dateFrom, dateTo] = getDateRange(key)

            newQuery.dateFrom = dateFrom
            newQuery.dateTo = dateTo
        }
        applySearchQuery(newQuery, dispatch)
    }

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="TimeDateWidget_mewwkmxw" className={classes.containerInner}>
                <div data-intrcm-id="TimeDateWidget_2c8ji4x6" className={classes.titleSection}>
                        <EventIcon className={classes.titleIcon} />
                        <div data-intrcm-id="TimeDateWidget_laxuoefg" className={classes.title}>{t("Date filter")}</div>
                </div>
                <div data-intrcm-id="TimeDateWidget_hy6ycpj2" className={classes.options}>
                    {options.map(o => (
                        <WidgetSingleOption optionStyle={classes.option} key={o.key} option={o} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    queryService: state.services.queryService,
    searchQuery: state.search,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withTranslation('searchPage'),
    withMixpanel,
    connect(mapStateToProps, mapDispatchToProps),
)(TimeDateWidget);

const getDateRange = (key) => {
    const to = moment()
    let from = moment()

    switch(key) {
        case 'today':
            from = from.subtract(1, 'day')
            break;
        case 'week':
            from = from.subtract(7, 'day')
            break;
        case 'month':
            from = from.subtract(1, 'month')
            break;
        case 'year':
            from = from.subtract(1, 'year')
            break;
    }
    return [from.toISOString(), to.toISOString()]
}