import { Grid, Icon, Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';


// Apply some reset
const styles = theme => ({
    icon: {
        marginRight: 16,
        color: 'rgba(0, 0, 0, 0.62)',
        width: 'auto',
        marginBottom: 'auto',
        marginTop: 19,

    },
    fieldIcon: {
        // paddingRight: 12,
        // paddingTop: 13,
        color: 'rgba(0, 0, 0, 0.62)'
    },

});

class ContactFullViewField extends PureComponent {

    render() {
        const { icon, title, value, classes } = this.props;


        return <div data-intrcm-id="ContactFullViewField_4pgg0zc8" style={{ margin: 8, paddingTop: 8, paddingBottom: 8 }}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid container item className={classes.icon}>
                    <Icon >{icon}</Icon>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="primary" style={{ marginBottom: 2 }}>
                        {title}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </div>

    }

}

export default withStyles(styles)(ContactFullViewField)