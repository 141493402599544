import React, {useEffect, useRef, useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Channel from './ChannelSearchItem';
import { connect } from 'react-redux';
import goTo from '../../../../../actions/goTo';
import NoItemsPlaceholder from '../../../../../views/knowledge/Library/NoItemsPlaceholder/NoItemsPlaceholder';
import { showSnackbar } from '../../../../../../actions/appActions';
import { createTagPayload } from '../../../../../../utils/tagHelpers';
import { isQueryInString } from '../../../../../../utils/searchNavigation/searchHelpers';
import { getUniqueBy } from '../../../../../../utils/commonHelpers';
import NewChannel from './NewChannel';

const styles = (theme) => ({
    container: {
        marginTop: 28,
        width: theme.constants.searchInputWidth - 40,
        maxWidth: `calc(${theme.constants.searchInputWidth}px - 40px)`,
        height: 'fit-content',
        maxHeight: '80vh',
        marginRight: 20,
        marginLeft: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.8,
        letterSpacing: 0.31,
        color: '#21212d',
    },
    seeAll: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 13,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.23,
        letterSpacing: 0.5,
        textAlign: 'right',
        color: theme.customColors.primary.main,
        cursor: 'pointer'
    },
    trending: {
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    subscribedChannels: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    allChannels: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        marginTop: 24,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 2,
        color: '#979aaa'
    },
    noChannels: {
    }
})

const SHOW_TRENDING_LENGTH = 2;

const mapTrends = (trends, {withSlice = false, maxLen = 0} = {withSlice: false, maxLen: 0}) => {
    maxLen = withSlice ? maxLen : trends.length
    const sorted = trends.sort((a, b) => b.value - a.value)
    const items = sorted.map(trend => createTagPayload(trend.key, null, 'user_tag'))
    return items.slice(0, maxLen);
}

const mapTopics = (subscribed, topics) => {
    const topicTags = topics.map(topic => topic.tag)

    return subscribed.map((channel) => {
        if(!topicTags.includes(channel.tag)) return channel
        const topic = topics.find(topic => topic.tag === channel.tag)

        if(!topic || !topic.topicBackgroungUrl) return channel

        return {...channel, icon: topic.topicBackgroungUrl}
    })

}

const filterLocalChannels = (query, {withSlice = false, maxLen = 0, trends = [], subscribed = []} = {withSlice: false, maxLen: 0, trends: [], subscribed: []}) => {
    const q = query.slice(1).toLowerCase();
    const filteredMerged = [...trends, ...subscribed].filter(channel => {
        const {tag = '', description = '', displayName = ''} = channel;
        return isQueryInString(q, tag || '') || isQueryInString(q, description || '') || isQueryInString(q, displayName || '')
    })
    const uniq = getUniqueBy('tag', filteredMerged)

    maxLen = withSlice ? maxLen : uniq.length
    return uniq.slice(0, maxLen);
}
const filterSuggestedChannels = (suggested, {withSlice = false, maxLen = 0, filteredLocal = []} = {withSlice: false, maxLen: 0, filteredLocal: []}) => {
    const filteredSuggested = suggested.filter(suggest => suggest.suggestType === 'channel')
    const items = filteredSuggested.map(suggest => createTagPayload(suggest.title, null, 'user_tag', suggest.description))
    const merged = [...items, ...filteredLocal]
    const uniq = getUniqueBy('tag', merged)

    maxLen = withSlice ? maxLen : uniq.length
    return uniq.slice(0, maxLen);
}
const initFilterChannels = {
    subscribed: [],
    trending: [],
    withSlice: [],
}

const initNewTag = {
    name: '',
    description: '',
    error: false
}

function ChannelsSection(props) {
    const {
        classes, 
        t, 
        dispatch, 
        subscribedChannels,
        query, 
        withTrending, 
        withSubscribe, 
        withSubscribed, 
        withSeeAll,
        withAddNew,
        withSlice,
        withFilter,
        trends,
        userSettingsService,
        adminService,
        queryService,
        filterMode,
        withKeysNavigation
    } = props

    const [trending, setTrending] = useState(mapTrends(trends ?? [], {withSlice, maxLen: SHOW_TRENDING_LENGTH}))
    const [subscribed, setSubscribed] = useState(subscribedChannels)
    const [filteredChannels, setFilteredChannels] = useState([])
    const [allUserTags, setAllUserTags] = useState([])
    const [newTagModal, setNewTagModal] = useState(false)
    const [newTag, setNewTag] = useState(initNewTag)
    const {name: newTagName} = newTag

    const abortControllerRef = useRef(new window.AbortController())
    const [isLoading, setIsLoading] = useState(false)
    const filterRef = useRef(initFilterChannels)

    useEffect(() => {
        filterRef.current.trending = trending;
        filterRef.current.withSlice = withSlice;
        filterRef.current.subscribed = subscribed;
    }, [trending, subscribed, withSlice])

    useEffect(() => {
        adminService.getAllUserTags().then(userTags => {
            setAllUserTags(userTags.map(userTag => userTag.tag))
        })
    }, [adminService])

    useEffect(() => {
        if(!withFilter || query === '') return;

        abortControllerRef.current.abort()
        abortControllerRef.current = new window.AbortController()

        const local = filterLocalChannels(query, {
            withSlice: filterRef.current.withSlice, 
            maxLen: SHOW_TRENDING_LENGTH, 
            trends: filterRef.current.trending,
            subscribed: filterRef.current.subscribed
        })
        queryService.autosuggest(query, abortControllerRef.current.signal).then(res => {
            const filtered = filterSuggestedChannels(res || [], {
                withSlice: filterRef.current.withSlice, 
                maxLen: SHOW_TRENDING_LENGTH, 
                filteredLocal: local,
            })
            if(filterMode === 'suggestion') {
                setFilteredChannels(filtered.slice(0, SHOW_TRENDING_LENGTH))
                return
            }
            setFilteredChannels(filtered)
        }).catch(err => {
            console.log(err);
        })
    }, [query, withFilter, filterMode])

    useEffect(() => {
        setTrending(mapTrends(trends ?? [], {withSlice, maxLen: SHOW_TRENDING_LENGTH}))
    }, [trends, withSlice])

    useEffect(() => {
        if(!newTagModal) return;
        setNewTag(prev => ({...prev, error: allUserTags.includes(newTagName)}))
    }, [allUserTags, newTagName, newTagModal])

    useEffect(() => {
        adminService.getAllTopics().then((topics) => {
            setSubscribed(mapTopics(subscribedChannels, topics))

        }).catch(() => {
            setSubscribed(subscribedChannels)
        })
    }, [subscribedChannels, adminService])

    const hideSearchNavigation = () => {
        dispatch({
            type: 'SEARCH_INPUT_FOCUSED',
            isFocused: false
        })
    }

    const openChannel = (e, channel) => {
        hideSearchNavigation()
        const title = channel.tag.startsWith('#') ? channel.tag : `#${channel.tag}`
        goTo(title, dispatch)()
    }

    const goToSearchChannels = () => {
        dispatch({
            type: 'SEARCH_QUERY', query: {query: '#'}
        })
    }

    const subscribe = (tagInfo) => {
        if(isLoading) return
    
        setIsLoading(true)
        dispatch(showSnackbar('subscribing...'));
        userSettingsService
            .subscribe(tagInfo)
            .then((_) => {
                userSettingsService.getUserData().then((userData) => {
                    dispatch(showSnackbar('Subscribed successfully!'));
                    dispatch({
                        type: 'USER_DATA_LOAD', data: {
                            ...userData
                        }
                    });
                    setIsLoading(false)
                })
            });
    }
    const unsubscribe = (channelTag) => {
        if(isLoading) return
        const subscribedTag = subscribedChannels.find(tag => tag.tag.toLowerCase() === channelTag.toLowerCase());
        setIsLoading(true)
        dispatch(showSnackbar('unsubscribing...'));
        userSettingsService.unsubscribe(subscribedTag.id).then(_ => {
            userSettingsService.getUserData().then((userData) => {
                dispatch(showSnackbar('Unsubscribed successfully!'));
                dispatch({
                    type: 'USER_DATA_LOAD', data: {
                        ...userData
                    }
                });
                setIsLoading(false)
            })
        })
    }

    const onNewChannel = () => {
        setNewTagModal(true)
    }

    const renderHeader = () => {
        return (
            <div data-intrcm-id="ChannelsSection_zy8veb2g" className={classes.header}>
                <div data-intrcm-id="ChannelsSection_q0g309c3" className={classes.title}>{t('Channels')}</div>
                {withSeeAll && <div data-intrcm-id="ChannelsSection_d5gc2xzx" onClick={goToSearchChannels} className={classes.seeAll}>{t('See all channels').toUpperCase()}</div>}
                {withAddNew && <div data-intrcm-id="ChannelsSection_gvclw8mt" onClick={onNewChannel} className={classes.seeAll}>{t('+ Add New Channel').toUpperCase()}</div>}
            </div>
        )
    }

    const renderTrending = () => {
        if(!withTrending || withFilter) return
        const showAddChannel = (!trending.length || trending.length === 1) && !query.startsWith('#')

        return (
            <>
                <div data-intrcm-id="ChannelsSection_sjtj1jpf" className={classes.section}>{t("Trending").toUpperCase()}</div>
                <div data-intrcm-id="ChannelsSection_ehmzwu8a" className={classes.trending}>
                    {trending.map(channel => <Channel withKeysNavigation={withKeysNavigation} subscribe={subscribe} unsubscribe={unsubscribe} withSubscribe={withSubscribe} type="trend" openChannel={openChannel} key={`${channel.key}-trend`} channel={channel} />)}
                    {showAddChannel && <NewChannel onNewChannel={onNewChannel} translations={{newChannel: t('+ Add New Channel')}} />}
                </div>
            </>
        )
    }

    const renderFilteredChannels = () => {
        if(!withFilter) return
        if(!filteredChannels.length) return <NoItemsPlaceholder containerStyle={classes.noChannels} placeholder={t("No channels available")} />
        const showAddChannel = (filteredChannels.length === 1) && !query.startsWith('#')

        return (
            <>
                <div data-intrcm-id="ChannelsSection_sb4ld7ga" className={classes.section}>{t("Found").toUpperCase()}</div>
                <div data-intrcm-id="ChannelsSection_c23xqvx9" className={classes.subscribedChannels}>
                    {filteredChannels.map(channel => <Channel withKeysNavigation={withKeysNavigation} subscribe={subscribe} unsubscribe={unsubscribe} withSubscribe={withSubscribe} type="trend" openChannel={openChannel} key={`${channel.key}-filter`} channel={channel} />)}
                    {showAddChannel && <NewChannel onNewChannel={onNewChannel} translations={{newChannel: t('+ Add New Channel')}} />}
                </div>
            </>
        )
    }

    const renderSubscribed = () => {
        if(!withSubscribed || !subscribed.length || withFilter) return
        return (
            <>
                <div data-intrcm-id="ChannelsSection_iin4oai5" className={classes.section}>{t("Subscribed").toUpperCase()}</div>
                <div data-intrcm-id="ChannelsSection_bv4o1rz5" className={classes.subscribedChannels}>
                    {subscribed.map(channel => <Channel withKeysNavigation={withKeysNavigation} subscribe={subscribe} unsubscribe={unsubscribe} withSubscribe={withSubscribe} type="subscribed" openChannel={openChannel} key={`${channel.key}-subscribed`} channel={channel} />)}
                </div>
            </>
        )
    }
    const onCloseNewTagModal = () => {
        setNewTagModal(false)
        setNewTag(initNewTag)
    }
    const onSaveNewTagModal = () => {
        const {name, description} = newTag
        const tag = {
            Tag: name.replace(/[ ]/g, "_"),
            Description: description
        }

        adminService.addUserTag(tag).then(() => {
            dispatch(showSnackbar('Saved!'));
        })
        dispatch(showSnackbar('Saving...'));
        onCloseNewTagModal()
    }
    const onChange = (name, event) => {
        const val = event.target.value
        setNewTag((prev) => ({...prev, [name]: val}))
    }


    const renderAddNewTag = () => {
        const { name, description, error } = newTag;

        return (
            <Dialog open={newTagModal} onRequestClose={onCloseNewTagModal}>
                <DialogTitle>New User Tag</DialogTitle>
                    {error && <div data-intrcm-id="ChannelsSection_tj76wwhc" style={{ color: 'red', paddingLeft: 25 }}>The tag {name} is already exist</div>}
                    <DialogContent>
                        <TextField fullWidth label="Name" InputLabelProps={{ shrink: true }} value={name} margin="normal"
                            onChange={e => onChange("name", e)} />
                        <TextField fullWidth label="Description" id="description" InputLabelProps={{ shrink: true }} value={description} margin="normal"
                            onChange={e => onChange("description", e)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseNewTagModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={onSaveNewTagModal} color="primary" disabled={name === '' || error}>
                            Save
                        </Button>
                    </DialogActions>
            </Dialog>
        )
    }

    const renderNoChannels = () => {
        if(!!trending.length || withFilter) return;

        return <NoItemsPlaceholder containerStyle={classes.noChannels} placeholder={t("No channels available")} />
    }
    if(filterMode === 'suggestion' && withFilter && !filteredChannels.length) return null;

    return (
        <div data-intrcm-id="ChannelsSection_5k4fg9r6" className={classes.container} >
            {renderHeader()}
            {renderNoChannels()}
            {renderSubscribed()}
            {renderTrending()}
            {renderFilteredChannels()}
            {renderAddNewTag()}
            <div data-intrcm-id="ChannelsSection_by0d30zz" style={{height: 15}}/>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      subscribedChannels: state.userData.subscribedTags,
      trends: state.globalData.trends,
      query: state.search.query,
      searchNavigation: state.searchNavigation,
      userSettingsService: state.services.userSettingsService,
      queryService: state.services.queryService,
      adminService: state.services.adminService,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChannelsSection)

