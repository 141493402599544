import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { managerGanttRef, managerTableKeepGlobalData } from '@tuqqi/web'
import { isRTLValue } from '@tuqqi/web'
import { useTranslation } from 'react-i18next'
import { formatNumberThousandStr, getNumberRe, hexToRGB, isEndWithSep, isEndWithSepZero, removeSep } from '@tuqqi/common'

const styles = theme => ({
    input: {
        padding: 0,
        paddingRight: 8,
        paddingLeft: 8,
        outline: 0,
        border: 0,
        backgroundColor: '#EDEDF0',
        color: theme.palette.common.black,
        width: '100%',
        resize: 'none',
        ...theme.typography.body2,
        fontSize: 13, 
        'font-feature-settings': "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    inputWrap: {
        width: 'fit-content',
        background: '#EDEDF0',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 12,
        marginRight: 12,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        border: `1px solid transparent`,
    },
    singleLine: {
        height: 28,
        alignItems: 'center',
    },
    errorInput: {
        border: `1px solid ${'#ff4848'}`,
        boxShadow: `0px 0px 4px ${hexToRGB('#ff4848', 0.16)}`
    },
    activeInput: {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 4px ${hexToRGB(theme.palette.primary.main, 0.16)}`
    },
})

const getActualWidth = (record) => {
    if(!managerGanttRef.gantt) return 10
    const column = managerGanttRef.gantt.instance.columns.visibleColumns.find(x => x.type === 'percentdone')
    if(!column) return 10
    if(typeof column.width !== 'number') return 10
    let gap = 15

    return column.width - gap - 10
}

export const isNumeric = (val) => {
    var _val = +val;
    return (val !== val + 1)
        && (_val === +val)
        && (typeof val !== 'object')
}

export const getStringAsNum = (val) => {
    let defa = val ? parseFloat(val) : 0
    let x = defa
    try {
        const y = val ? eval(val) : 0
        x = isNumeric(y) ? y : defa
    } catch (e) {
        x = defa
    }
    return x
}

export const getCorrectN = (val) => {
    if(val === '0') return 0

    //@ts-ignore
    if(!val && val !== 0) {
        //@ts-ignore
        return ''
    }
    const x = getStringAsNum(val)
    return x
}



const Edit = memo((props) => {
    const [activeInput, setActiveInput] = useState(false)
    const refInput = useRef(null);
    const {classes, value} = props
    const [t] = useTranslation("gantt")
    const label = managerTableKeepGlobalData.tTable('Title')
    const actualWidth = getActualWidth(props.record)
    const numberReRef = useRef(getNumberRe())

    useEffect(() => {
        refInput.current?.focus();
    }, []);

    const wrapStyle = useMemo(() => {
        return {
            width: actualWidth
        }
    }, [actualWidth])
  
    const onChange = useCallback((event) => {
        const val = event.target.value

        if(val === '0') {
            props.setValue('0')
            return
        }
        if(val === '' || val === '-' || isEndWithSep(val) || isEndWithSepZero(val)) {
            const x = val
                .replace('.', '')
                .replace(',', '')
                .replace('-', '')
            props.setValue(x)
            return
        } 
        const x1 = removeSep(val)
        let value = x1?.match(numberReRef.current)?.[0] ?? ''
        if(!value) return
        if(value === Number(value) + '' && Number(value) > 100) {
            value = '100'
        }
        
        const val1 = formatNumberThousandStr(value)

        props.setValue(val1)
    }, [])

    const onFocusInput = useCallback(() => {
        setActiveInput(true)
    }, [])

    const onBlurInput = useCallback(() => {
        setActiveInput(false)
    }, [])

    const placeholder = `${t("Add")} ${label.toLowerCase()}...`

    const dir = useMemo(() => {
        if(!value && isRTLValue(placeholder)) return 'rtl'
        if(isRTLValue(value)) return 'rtl'
        return 'auto'
    }, [placeholder, value])

    const onClickAway = () => {
        props.onClose()
    }


    const onKeyDownHadnler = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation()
            props.onClose()
            return false
        }
        return true
    }


    const Input = <div style={wrapStyle} data-intrcm-id="CellRenderTitleEdit_xthyeepp" dir={dir} className={`${classes.inputWrap} ${classes.singleLine} ${activeInput ? classes.activeInput : ''}`}>
        <input
            dir={dir}
            type="text"
            placeholder={placeholder}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            ref={refInput}
            min={0}
            max={100}
            maxLength={3}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDownHadnler}
            className={classes.input}
        />
    </div>
  
      return <ClickAwayListener onClickAway={onClickAway} >
        {Input}
      </ClickAwayListener>
})

export default withStyles(styles, {withTheme: true})(Edit)