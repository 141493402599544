import { withStyles } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import moment from "moment";
import React, { PureComponent } from 'react';

// Apply some reset
const styles = theme => ({
    icon: {
        "width": "18px",
        "height": "18px",
        "fill": "#979AAA",
        cursor: 'pointer'
    },
    container:{
        cursor:'pointer',
        "display": "inline-flex",
        "alignItems": "center"
    },
});

class HelpButtonIcon extends PureComponent {
    getLang = () => {
        let lang = 'en'
        const exsistLang = ['he', 'en']
        const userLang = moment.lang()

        if(exsistLang.includes(userLang)){
            lang = userLang
        }

        return lang
    }

    openLink = () => {
        const lang = this.getLang()
        const link = !!this.props.customLink ? this.props.customLink : `https://intercom.help/tuqqi/${lang}/${this.props.link}`
        window.open(link)
    }

    render() {
        const { classes, disable, children } = this.props;
        const icon = <HelpOutline className={classes.icon} onClick={!children && this.openLink} />
        
        if(children){
            return (
                <div data-intrcm-id="HelpButtonIcon_1d3knh7r" className={classes.container} onClick={this.openLink}>
                    {icon}
                    {children}
                </div>
            )
        }
        
        return icon
    }
}


export default withStyles(styles)(HelpButtonIcon);
