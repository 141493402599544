import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import GeneralButton from '../../Common/AllButton/AllButton';

import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { CreateIcon } from '../../Common/Icons/CreateIcon';

import AddIcon from '@material-ui/icons/Add';
import {getLaunchpadKeysLabel} from '../../../../utils/launchpadNavigation/launchpadUtils'
import { hexToRGB } from '../../../../utils/hexToRGB';
import withErrorCatcher from '../../Common/withErrorCatcher';
import { shouldAutoOpen } from '../../../views/groups/groupUtils';
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { openInput } from '../../../../actions/inputActions';

// const allowedCreate = ['/', '/feed', '/mypage/actioncenter', '/orgscheduler', '/group/table', '/group/chat', '/group/kanban']

const styles = theme => {
    const whiteOpacity016 = hexToRGB('#FFFFFF', 0.16)
return ({
    createButton: { 
        margin: 0,
        padding:0,
        
        "&:hover": {
            backgroundColor: 'inherit',
          },
      
    },

    createIconContainer: {
        margin: 0,
        padding:0,
         
         "&:hover": {
            backgroundColor: 'inherit',
          },
          
    },
    icon : {
        color:"white"
    },
    buttonContainer: {
        paddingRight: 9,
        paddingLeft: 9,
        marginRight: 8,
        marginLeft: 24,
        borderRadius: 8,
        boxShadow: 'none',
        width: 'fit-content',
        height: 26,
        backgroundColor: 'rgba(255, 255, 255, 0.87)',
        color: '#fff',
        border: `solid 1px ${"rgba(255, 255, 255, 0.16)"}`,
        '&:hover': {
            boxShadow: 'none',
            color: '#fff',
            backgroundColor: 'rgba(255, 255, 255, 0.40)',

        },
    },
    buttonText: {
        marginLeft: 7,
        fontSize: 14,
        fontWeight: '400',
        color: 'rgba(0,0,0,0.87)',
    },

    createIconContainer: {
        height: 28,
        width:28,
        borderRadius: 16,
        "&:hover": {
            backgroundColor: whiteOpacity016,
          },
      },
      icon : {
        color:"rgba(255,255,255,0.87)",
        height:18,
        width:18
    },
    btnCreate: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        background: "#FFFFFF08",
        borderRadius: 6,
        width: 'fit-content',
        height: 28,
        cursor: 'pointer',
        border: '1px solid #FFFFFF2F',
        paddingRight: 9,
        paddingLeft: 9,
        '&:hover': {
            boxShadow: 'none',
            color: '#fff',
            background: hexToRGB(theme.customColors.primary.b500, 0.16),
        },
        marginRight: 8,

    },
    btnCreateText: {
        color: '#FFFFFF8F',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: '500',
        lineHeight: '16px',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 12,
        marginRight: 4,
    },
    wrap: {
        display: 'flex',
        alignItems: 'center'
    }
})
}

const ArrowIcon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77571 6.17574C5.01003 5.94142 5.38993 5.94142 5.62424 6.17574L7.99998 8.55147L10.3757 6.17574C10.61 5.94142 10.9899 5.94142 11.2242 6.17574C11.4586 6.41005 11.4586 6.78995 11.2242 7.02426L7.99998 10.2485L4.77571 7.02426C4.5414 6.78995 4.5414 6.41005 4.77571 6.17574Z" fill="white" fill-opacity="0.56"/>
    </svg>    
}

class CreateNewToolbar extends PureComponent {

    toggleModal = () => {
        const { dispatch } = this.props;
        dispatch({type: 'TOGGLE_CREATENEW_MODAL'})
    }
    onClick = (e) => {
        const { dispatch, inputMappings, oldUiInput, userData, search, groupInfo } = this.props
        if(window.location.pathname.includes('group') && this.props.collectionUid) {
            const item = groupInfo.collectionUid === this.props.collectionUid ? groupInfo : undefined

            if(!item) return
            const groupUid = item.collectionGroup.groupUid
            if (shouldAutoOpen(item)) {
                const dataType = item.settings.allowedInputs[0]
                let isCustomInput = false
                inputMappings.map((x) => {
                    if (x.customDataMapping.dataTypeFilter === dataType) {
                        isCustomInput = true
                    }
                })
                if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                    const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                    let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: "" }
                    dispatch(openNewUiInput(newDoc, dataType))
                } else {
                    openInput(dataType, userData, search, oldUiInput, inputMappings).forEach(dispatch)
                }
            } else {
                this.toggleModal()
            }
            return
        }

        this.toggleModal()
    }

    onKeyUp = (e) => {
        if(e.which === 13){ 
            e.preventDefault();
            return false
        }
    }

    render() {
        const { classes, t, pathname } = this.props;

        // if(!allowedCreate.includes(pathname)) return null;
        return (
            <div className={classes.wrap}>
                {/* <div style={{width:"1px",height:"16px", backgroundColor:"#B7B8BD12",marginRight:16,marginLeft:16}}/> */}
                    <div className={classes.btnCreate} onClick={this.onClick}>
                            <div className={classes.btnCreateText}>{t('Create')}</div>
                            <ArrowIcon />
                    </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    isFocused: state.createNewModal.isFocused,
    inputMappings: state.globalData.inputMappings,
    pathname: state.wrapper.pathname,
    oldUiInput: state.oldUiInput.document,
    groupInfo: state.results.channel,
    search: state.search,
    userData: state.userData,
    collectionUid: state.group?.collectionUidUI ?? "",
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher('Launchpad crashed'),
    withStyles(styles),
    withTranslation('feedToolbar'),
    connect(mapStateToProps, mapDispatchToProps)
)(CreateNewToolbar);