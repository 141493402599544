import { DECREASE_ACTIONS_CENTER_COUNT, SET_COUNT_AGENDA_ITEMS, SET_COUNT_MUSTREAD_ITEMS, SET_COUNT_TODO_ITEMS } from "../actions/actionsCenterActions"

export const initialActionsCenterState = {
    countAgenda: 0,
    countMustRead: 0,
    countToDo: 0
}

export const actionsCenterReducer = (state = initialActionsCenterState, action) => {
    switch (action.type) {
        case DECREASE_ACTIONS_CENTER_COUNT: {
            const {view} = action
            switch (view) {
                case 'agenda':
                    return {...state, countAgenda: state.countAgenda - 1}
                case 'todo':
                    return {...state, countToDo: state.countToDo - 1}
                case 'mustRead':
                    return {...state, countMustRead: state.countMustRead - 1}
                default:
                    return state
            }
        }
        case SET_COUNT_AGENDA_ITEMS: {
            const {count} = action
            return {...state, countAgenda: count}
        }
        case SET_COUNT_TODO_ITEMS: {
            const {count} = action
            return {...state, countToDo: count}
        }
        case SET_COUNT_MUSTREAD_ITEMS: {
            const {count} = action
            return {...state, countMustRead: count}
        }
        default:
            return state
    }
}