import { ADD_INPUT_RELATED_DOC, FULLVIEW_CLOSE, FULL_RESET_STORE, INPUT_CLOSE, INPUT_REMOVE_LINK_BY_DATA_TYPE, INPUT_REMOVE_LINK_BY_ID, REMOVE_INPUT_RELATED_DOC } from "../actionTypes/actionTypes"

const initialState = null;
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState;

        case ADD_INPUT_RELATED_DOC: {
            const { source, docs } = action
            const newState = { ...state }
            newState[source.docId] = {
                source,
                docs
            }
            return newState
        }

        case REMOVE_INPUT_RELATED_DOC:
        case INPUT_REMOVE_LINK_BY_ID: {
            const newState = { ...state }
            delete newState[action.id]
            return newState
        }

        case INPUT_REMOVE_LINK_BY_DATA_TYPE: {
            const newState = { ...state }
            Object.keys(newState).forEach(id => {
                const { source } = newState[id]
                if (source.dataType === action.dataType) {
                    delete newState[id]
                }
            })
            return newState
        }

        case INPUT_CLOSE:
        case FULLVIEW_CLOSE: {
            return null
        }

        default:
            return state
    }
}

export default reducer