import { getCollectionByGroupUid, sortItemsByCreatingDate, sortItemsByDate, sortItemsByLaneColor, sortItemsByRating } from "./helpers";

const mockCollection = (document) => {
    if (document.isPrivate) {
        return {
            collectionUid: 'privateuid',
            title: 'Private'
        }
    } else if (document.isLimitedToMembers) {
        return {
            collectionUid: 'limiteduid',
            title: 'Limited'
        }
    }

    return {
        collectionUid: 'publicuid',
        title: 'Public'
    }
} 

const commonSort = (data) => {
    return data
        .sort(sortItemsByLaneColor)
        .sort(sortItemsByDate)
        .sort(sortItemsByRating)
}

const mustReadSort = (data) => {
    return data
        .sort(sortItemsByCreatingDate)
}

export const buildToDoTasks = (results, allCollections, favourites, isMustRead = false) => {
    const favouritesUids = favourites.map(f => f.collectionUid)
    const lanes = {}
    results.forEach(res => {
        const { groupUid } = res
        let collection = getCollectionByGroupUid(groupUid, allCollections)
        if(!collection) {
            collection = mockCollection(res)
        }

        if(!lanes[collection.collectionUid]) {
            lanes[collection.collectionUid] = {
                title: collection.title,
                collectionUid: collection.collectionUid,
                data: [res]
            }
            return
        }
        const data = [...(lanes[collection.collectionUid].data ?? []), res]
        // if(isMustRead) console.log('fvfvfvfvfv', 'before', data);
        const sorted = isMustRead ? mustReadSort(data) : commonSort(data)
        // if(isMustRead) console.log('fvfvfvfvfv', 'after', sorted);
        lanes[collection.collectionUid] = {
            ...lanes[collection.collectionUid],
            data: sorted
        } 
    });
    const values = Object.values(lanes)
    const sortedArray = [
        ...values.filter(({collectionUid}) => favouritesUids.includes(collectionUid)),
        ...values.filter(({collectionUid}) => !favouritesUids.includes(collectionUid)),
    ];
    return sortedArray
}