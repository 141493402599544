import { Grid, IconButton, withStyles } from '@material-ui/core';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import HelpButtonTooltip from '../../../components/Common/HelpButton/HelpButtonTooltip';
import ChatUsersDialog from '../ChatUsersDialog';
import ChatContactSearch from './ChatContactSearch'
import editFill from '@iconify/icons-eva/edit-fill';
import { withTranslation } from 'react-i18next';

const EditOutlineIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="editOutlineIcon__a" d="M11.06 6.02l.92.92L2.92 16H2v-.92l9.06-9.06zM14.66 0c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41L15.37.29c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L0 14.25V18h3.75L14.81 6.94l-3.75-3.75z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(3 3)">
                <mask id="editOutlineIcon__b" fill="#fff">
                    <use href="#editOutlineIcon__a"/>
        </mask>
                <g mask="url(#editOutlineIcon__b)">
                    <path fill="#6200EE" d="M0 0H24V24H0z" transform="translate(-3 -3)" />
                </g>
            </g>
        </svg>

    )
}
// Apply some reset
const styles = theme => ({
    textField: {
        borderRadius: 1,
        //boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)'
    },
    inputLabelStyle: {
        fontSize: 12,
    },
    inputStyle: {
        height: 24, paddingTop: 0, paddingBottom: 2
    },
    addDmIcon: {
        marginTop: 8, marginLeft: 24, marginRight: 8, padding: 0
    },
    title: {
        "fontFamily": "Rubik",
        "fontSize": "20px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "normal",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    container:{
        padding: '0px 6px 0 16px', 
        backgroundColor: '#f3f6fb', 
        height:56,
        position: 'sticky',
        top: 0,
        'z-index': 12,
    }
});

class ChatDrawerHeader extends Component {
    state = {
        usersDialogOpen: false,
        isLoading: false,
        isSearchFocused: false,
        searchQuery: ''
    }

    openUsers = () => this.setState({ usersDialogOpen: true })
    closeUsers = () => this.setState({ usersDialogOpen: false })

    handleClickAwaySearch = () => {
        this.setState({isSearchFocused: false, searchQuery: ''});
    };
    handleChangeSearch = async (event) => {
        try {
          const { value } = event.target;
          this.setState({searchQuery: value});
          if (value) {
          } else {
            // setSearchResults([]);
          }
        } catch (error) {
          console.error(error);
        }
      };

      handleSearchFocus = () => {
        this.setState({isSearchFocused: true});
      };

    openNewChat = (userUId) => {
        const { closeAddChatModal, openChat } = this.props;

        this.setState({ isLoading: true })

        this.props.chatService.openNewChat(userUId).then(res => {
            this.setState({ isLoading: false });
            closeAddChatModal(res)
            this.closeUsers();

            openChat(res)
        })
    }

    render() {
        const { classes, users, userData, openSidebar, setOpenSidebar, searchQuery,t } = this.props;

        return <Fragment>
            <Grid container direction='row' alignItems='center' justify='space-between' className={classes.container}>
                {openSidebar && <HelpButtonTooltip link='articles/3869099'>
                    <span className={classes.title} >{t('Tuqqi talk')}</span>
                </HelpButtonTooltip>}
                <IconButton style={{marginRight: -182}} onClick={() => setOpenSidebar(!openSidebar)}>
                    <Icon width={20} height={20} icon={openSidebar ? arrowIosBackFill : arrowIosForwardFill} /> 
                </IconButton>
                {openSidebar && <IconButton onClick={this.openUsers}><Icon icon={editFill} width={20} height={20} /></IconButton>}
            </Grid>
            {/* {openSidebar && <Grid container direction="row" alignItems="center" justify="center">
                    <ChatContactSearch
                        query={searchQuery}
                        onFocus={this.handleSearchFocus}
                        onChange={this.handleChangeSearch}
                        onClickAway={this.handleClickAwaySearch}
                    />
            </Grid>} */}
            <ChatUsersDialog
                title={t("New chat")}
                open={this.state.usersDialogOpen}
                onClose={this.closeUsers}
                usersToShow={users.filter(user => user.auth0_id != userData.profileData.auth0_id && user.status === 0)}
                listItemOnClick={(user) => this.openNewChat(user.auth0_id)}
                rightElement={null}
                getSecondaryText={user => user.title}
                disabled={false}
                isButton={true}
                isLoading={this.state.isLoading}
            />
        </Fragment>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    ...state.wrapper,
    channel: state.results.channel,
    searchQuery: state.search,
    inputMappings: state.globalData.inputMappings,
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation("chat"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatDrawerHeader);