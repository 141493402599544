export const isObject = obj => obj instanceof Object && obj.constructor === Object;
export const sortObjByKey = obj =>
  Object.keys(obj)
    .sort()
    .reduce((accum, val) => ({...accum, [val]: obj[val]}), {});

export const isEqualObjJson = (ent1, ent2) => JSON.stringify(sortObjByKey(ent1)) === JSON.stringify(sortObjByKey(ent2));
export const isEqualJson = (ent1, ent2) => {
  if (isObject(ent1) && isObject(ent2)) return isEqualObjJson(ent1, ent2);

  return JSON.stringify(ent1) === JSON.stringify(ent2);
};
export const deepMerge = (source, target)  => {
  return void Object.keys(target).forEach(key => {
      source[key] instanceof Object && target[key] instanceof Object
          ? source[key] instanceof Array && target[key] instanceof Array
              ? void (source[key] = Array.from(new Set(source[key].concat(target[key]))))
              : !(source[key] instanceof Array) && !(target[key] instanceof Array)
                  ? void deepMerge(source[key], target[key])
                  : void (source[key] = target[key])
          : void (source[key] = target[key]);
  }) || source;
}
export const chunk =
(size, xs) => 
  xs.reduce(
    (segments, _, index) =>
      index % size === 0 
        ? [...segments, xs.slice(index, index + size)] 
        : segments, 
    []
  );

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
export const getUniqueBy = (prop, list) => {
  // const objUniq = list.reduce((res, item) => ({ ...res, [item[prop]]: item }), {})
  let tempMap = new Map();

  list.map(x=> tempMap.set(x[prop], x));


  // debugger
  // return Object.keys(objUniq).map(item => objUniq[item])
  return Array.from(tempMap.values())
}

export function parseVideoUrl(cloudfront, filename) {
  if (cloudfront && cloudfront.signedFiles && filename) {
      const replacedUrl = filename.replace("fileId=", '')
      return cloudfront.signedFiles.replace('*', encodeURIComponent(replacedUrl))
  }
}