import parseImagUrl from "../../ui/components/Common/parseImagUrl";
import groupPlaceholder from "../../assets/img/group_placeholder.png";

export const mapCollectionToGroupNavigation = (collection) => ({
    groupUid: collection.collectionGroup.groupUid,
    collectionUid: collection.collectionUid,
    title: collection.title,
    description: collection.description || '',
    numNewItems: collection.numNewItems || 0,
    backgroundUrl: collection.backgroundUrl,
    timestamp: 0
});

export const initGroups = (collections, userId, cloudfront, favouriresCollections) => {
    const managedGroups = []
    const subscribedGroups = []
    const favouritesGroups = []

    const favouritesUids = favouriresCollections.map(col => col.collectionUid)
    collections.forEach(collection => {
        const backgroundUrl = parseImagUrl(cloudfront, collection.backgroundUrl, 100, groupPlaceholder);
        if (collection.collectionGroup.adminIds.includes(userId)) {
            managedGroups.push(mapCollectionToGroupNavigation({ ...collection, backgroundUrl }))
        } else {
            subscribedGroups.push(mapCollectionToGroupNavigation({ ...collection, backgroundUrl }))
        }
        if (!favouritesUids.includes(collection.collectionUid)) return;
        favouritesGroups.push(mapCollectionToGroupNavigation({ ...collection, backgroundUrl }))
    })    
    return { managedGroups, subscribedGroups, favouritesGroups }
}

export const sortGroupsByTitle = groups => groups.sort((a, b) => {if(a.title > b.title){return 1} else if(a.title < b.title){return -1} return 0})
export const sortGroupsByTimestamp = groups => groups.sort((a, b) => (a.timestamp < b.timestamp) - (a.timestamp > b.timestamp))
export const MAX_LATEST_GROUPS_LENGTH = 4
export const filterVisibleLatestGroups = (latest, starred) => {
    const starredUids = starred.map(gr => gr.groupUid)
    const groups = latest.filter(gr => !starredUids.includes(gr.groupUid) && gr.timestamp !== 0)
    return sortGroupsByTimestamp(groups)
}