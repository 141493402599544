import { withStyles } from '@material-ui/core';
import moment from "moment";
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import { COLUMN_DATA_TYPE_ATTACHMENTS, COLUMN_DATA_TYPE_COLLECTION_LABELS, COLUMN_DATA_TYPE_COMMENTS_COUNTER, COLUMN_DATA_TYPE_DOC_TIMESTAMP, COLUMN_DATA_TYPE_DUE_DATE_UTC, COLUMN_DATA_TYPE_LAST_MODIFED_TIME, COLUMN_DATA_TYPE_MEMBERS, COLUMN_DATA_TYPE_RELATED_DOCUMENTS, COLUMN_DATA_TYPE_SEEN_COUNTER, COLUMN_DATA_TYPE_STATUS, COLUMN_DATA_TYPE_TASK_COUNTER, COLUMN_DATA_TYPE_TITLE } from '../tableUtil';
import MenuTableToolbar from './MenuTableToolbar';
import exportTableToCsv from './exportToCsv';
import exportTableToExcel from './exportToExcel';
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import { getOptionsForInputMapping } from './generateRelatedColumns';
import ExportMenuDataTypeSelector from './ExportMenuDataTypeSelector';
import { MAX_DATATYPE_SELECTED } from '../../../../../reducers/exportTableDataTypeReducer';
import { getAllCollection } from '../../groupUtils';

const ExportIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="ExportIcon__a" d="M11.59 6H10V1c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v5H2.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71zM0 16c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(5 3)">
            <mask id="ExportIcon__b" fill="#fff">
                <use href="#ExportIcon__a" />
            </mask>
            <g fill="#979AAA" mask="url(#ExportIcon__b)">
                <path d="M0 0H24V24H0z" transform="translate(-5 -3)" />
            </g>
        </g>
    </svg>
)

const styles = theme => ({
    menu: {
        width: 301
    },
    settingsIconContainer: {
        marginLeft: 8,
        "width": "44px",
        "height": "44px",
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray400}`,
        "backgroundColor": theme.newUI.color.gray50,
        justifyContent: 'center'
    }
});

const SLICE_LENGTH = 200

class ExportMenu extends PureComponent {
    labels = (docLabels) => {
        const { collectionUid, userCollections, openCollections } = this.props

        const labels = []
        if (collectionUid) {
            // Take the label from the "state.userData.collections" not from the "state.results.channel" 
            //  because the channel doesn't hold the new labels (if exist)
            const cuuCollection = userCollections.concat(openCollections)
                .find(collection => collection.collectionUid === collectionUid)


            docLabels.forEach(docLabel => {
                const [docCollectionUid, docLabelUid] = docLabel.split(':')
                if (docCollectionUid === collectionUid) {
                    const collectionLabel = cuuCollection.collectionLabels.find(collectionLabel => collectionLabel.labelUid === docLabelUid)
                    collectionLabel && labels.push(collectionLabel)
                }
            })
            return labels
        }
        const collections = getAllCollection(userCollections, openCollections)

        docLabels.forEach(docLabel => {
            const [docCollectionUid, docLabelUid] = docLabel.split(':')
            const coll = collections.find(col => col.collectionUid === docCollectionUid)
            if(!coll) return
            const collectionLabel = coll.collectionLabels.find(collectionLabel => collectionLabel.labelUid === docLabelUid)
            collectionLabel && labels.push(collectionLabel)
        })

        return labels
    }

    closeMenuExportDataTypeSelector = () => {
        const {dispatch} = this.props
        dispatch({type: 'SET_MENU_EXPORT_DATATYPE', showMenu: false, anchorEl: null})
    }

    openMenuExportDataTypeSelector = (currentTarget) => {
        const {dispatch} = this.props
        dispatch({type: 'SET_MENU_EXPORT_DATATYPE', showMenu: true, anchorEl: currentTarget})
    }

    onChoose = (selected, e = null) => {
        switch (selected.key) {
            case 'csv':
                return this.exportTableToCsv()
            case 'xlsx':
                return this.openMenuExportDataTypeSelector(e?.currentTarget ?? null)
        }
    }

    chooseRelatedDataType = (selectedOption, allowMultiple, finish) => {
        const { exportTableDataType: {dataTypesOptions}, dispatch } = this.props;
        const {key} = selectedOption
        let newOptions = []
        const noneOpt = dataTypesOptions.find(d => d.key === 'None')
        const isNoneSelected = noneOpt?.selected ?? true

        if(key === 'None' && !isNoneSelected && allowMultiple) {
            newOptions = dataTypesOptions.map(d => ({...d, selected: d.key === 'None'}))
        } else {
            newOptions = dataTypesOptions.map((o, i, arr) => {
                if(o.key === key) {
                    if(!o.selected && allowMultiple && arr.filter(d => !!d.selected).length >= MAX_DATATYPE_SELECTED){
                        return {...o}
                    }
                    o.selected = allowMultiple ? !o.selected : true
                    o.timestamp = new Date().getTime()
                    return {...o}
                }
                if(o.key === 'None') (o.selected = false)
                if(allowMultiple) return o
                o.selected = false
                return o
            })
        }
        dispatch({type: 'UPDATE_MENU_EXPORT_DATATYPE_OPTIONS', dataTypesOptions: newOptions})
        if(!finish) return
        this.saveRelatedDataType(newOptions)
    }

    saveRelatedDataType = (newOptions) => {
        const { exportTableDataType: {dataTypesOptions} } = this.props;

        const selectedDataTypes = (newOptions ?? dataTypesOptions).filter(o => o.selected)
        const sortedSelected = selectedDataTypes.sort((a, b) => a.timestamp - b.timestamp)
        const dataTypesKeys = sortedSelected.map(o => o.key)
        if(!dataTypesKeys.length) return

        const withRelated = dataTypesKeys.some(d => d !== 'None')
        const relatedDataTypes = dataTypesKeys.filter(d => d !== 'None')
        this.exportTableToExcel(withRelated, relatedDataTypes)
    }

    cutText(text = '', maxLength = 60) {
        const suffix = text.length > maxLength ? '...' : ''

        return text.substr ? (text.substr(0, maxLength) + suffix) : text
    }


    generateExcelLink = (linkText, hyperlink, defaultColor) => {

        let text = linkText ? linkText : hyperlink

        const res = {
            excelValue: {
                text,
                hyperlink,
                Tooltip: text,
            },
            style: {
                font: {
                    'color': { argb: 'FF0000FF' },
                    underline: true,
                }
            }
        }
        if (defaultColor) {
            delete res.style.font.color
        }

        return res
    }


    renderCell = (row, column) => {
        const data = column.extractData(row)
        if (!data && column.type !== COLUMN_DATA_TYPE_ATTACHMENTS) {
            return {}
        }

        switch (column.type) {
            case 'text':
            case 'paragraph':
            case 'select':
            case 'postText':
            case 'location':
            case 'checkbox':
            case 'mailFrom':
            case 'contactFullName':
            case 'contactCompany':
            case 'contactEmail':
            case 'contactPhoneNumber':
            case 'contactAddress':
            case 'contactBirthday':
            case 'contactJobTitle':
            case 'contactAdditionalInfo':
            case 'companyFullName':
            case 'companyID':
            case 'companyEmail':
            case 'companyPhoneNumber':
            case 'companyType':
            case 'companyAddress':
            case 'companyFoundingYear':
            case 'companyCEO':
            case 'companyNumberOfEmployees':
            case 'companyAdditionalInfo':
            case 'eventLocation':
            case 'eventDescription':
            case 'questionStatus':
            case 'questionAnswer':
            case 'questionWhoAnswered':
            case 'phone':
            case 'email':
            case 'eventGuests':
                return { value: data, t: 's' }
            case COLUMN_DATA_TYPE_MEMBERS: {
                let memberNames = ""
                data.map((member) => {
                    const user = this.props.users.find(u => u.auth0_id == member.userUUID)
                    if (!user) return
                    memberNames += `${user.firstname} ${user.lastname}, `
                })
                return { value: memberNames, t: 's' }
            }
            case COLUMN_DATA_TYPE_STATUS:

                if (data && data.props && data.props.children && data.props.children.props && data.props.children.props.text)
                    return { value: data.props.children.props.text, t: 's' }
                else
                    return { value: '', t: 's' }
                break;
            case COLUMN_DATA_TYPE_TITLE: {
                const itemLink = `${location.origin}/feed?openItemId=${row.docId}`
                return {
                    ...this.generateExcelLink(data, itemLink, data, true),
                    value: data,
                    t: 's'
                }

            }
            case 'number':
                return { value: data.view, t: 's'}
            case 'calculation':
                    return { value: data.view, t: 's'}
            case 'multiSelect':
                const value = data && data.length ? data.join(", ") : ''
                return { value, t: 's' }
                break;

            case COLUMN_DATA_TYPE_DOC_TIMESTAMP:
            case COLUMN_DATA_TYPE_LAST_MODIFED_TIME:
            case 'eventDate&Time':
            case COLUMN_DATA_TYPE_DUE_DATE_UTC:
                return { value: data && moment(data).format('MMMM Do, YYYY, HH:mm'), t: 'd', excelValue: data && new Date(data), numFmt: 'dd/mm/yyyy, HH:mm' }
            case COLUMN_DATA_TYPE_SEEN_COUNTER:
            case COLUMN_DATA_TYPE_COMMENTS_COUNTER:
                return { value: data, t: 'n' }

            case COLUMN_DATA_TYPE_TASK_COUNTER:
                return { value: data.taskDoneCounter + "/" + data.taskCounter, t: 's' }

            case COLUMN_DATA_TYPE_RELATED_DOCUMENTS:
                return { value: data.length, t: 'n' }
            case COLUMN_DATA_TYPE_ATTACHMENTS: {
                const mapping = this.props.inputMappings.find(m => m?.customDataMapping?.dataTypeFilter === row?.dataType)
                const data = column.extractData(row, mapping)

                return { value: data?.length || '', t: 'n' }
            }


            case 'date':
                return { value: data && moment(data).format('DD/MM/YYYY'), t: 'd', excelValue: data && new Date(data), numFmt: 'dd/mm/yyyy' }
            case 'dateTime':
                return { value: data && moment(data).format('DD/MM/YYYY, HH:mm'), t: 'd', excelValue: data && new Date(data), numFmt: 'dd/mm/yyyy, HH:mm' }
            case 'links':

                if (!data) {
                    return {}
                } else {
                    return {
                        value: data.map(l => l.link).join('\n'),
                        ...(data.length === 1 && this.generateExcelLink(data[0].title, data[0].link))
                    }
                }
            case 'bookmark':
                return {
                    value: data.link,
                    ...this.generateExcelLink(data.previewTitle, data.link)
                }

            case 'contactWebsite':
            case 'contactFacebook':
            case 'contactLinkedin':
            case 'companyWebsite':
            case 'companyFacebook':
            case 'companyLinkedin':
            case 'eventGoToEvent':
                return {
                    value: data,
                    ...this.generateExcelLink(data, data)
                }

            case COLUMN_DATA_TYPE_COLLECTION_LABELS:
                {
                    const labels = this.labels(data)
                    return {
                        value: labels.map(l => l.name).join(', '), t: 's',
                        excelValue: {
                            richText: labels.map((label, idx) => ({
                                text: label.name + (idx < labels.length - 1 ? ', ' : ''),
                                font: { color: { 'argb': label.color.replace('#', '') } }
                            }))
                        }
                    }
                }

                break;
            default:
                return { value: data }
        }
    }




    exportTableToCsv = () => {
        const { columns, mixpanelTrack, collection } = this.props

        this.getAllData().then(data => {
            exportTableToCsv(data, columns, this.renderCell, collection.title)
            mixpanelTrack('Export to CSV', {})
        })
    }

    getRelatedMap = async (data, pickDatatype) => {
        const { queryService } = this.props
        
        const allRelatedIds = []
        const dataRelated = data.reduce((accum ,item, i) => {
            const {docId: originDocId, relatedDocuments} = item
            const filteredRelated = relatedDocuments
                .filter(d => d.dataType === pickDatatype)
                .map(d => d.relatedDocumentId)
            allRelatedIds.push(...filteredRelated)
            return {
                ...accum,
                [originDocId]: filteredRelated
            }
        }, {})
        const uniqueIds = [...new Set(allRelatedIds)]
        const relatedQuery = {
            size: uniqueIds.length > SLICE_LENGTH ? SLICE_LENGTH : uniqueIds.length,
            page: 0,
            itemsIds: uniqueIds
        }
        const itemsEntries = {}
        const {data: relD = []} = await queryService.SearchByIds(relatedQuery) ?? {data: []}
        for (const originDocId in dataRelated) {
             const relatedIds = dataRelated[originDocId]
             relatedIds.forEach(id => {
                const el = relD.find(d => d.Key === id)
                if(!el) return
                if(originDocId in itemsEntries) {
                    itemsEntries[originDocId] = [...itemsEntries[originDocId], el.Value.Source]
                } else {
                    itemsEntries[originDocId] = [el.Value.Source]
                }
             })
        }
        return Object.entries(itemsEntries)
    }

    exportTableToExcel = async (withRelated, relatedDataTypes) => {
        const { columns, mixpanelTrack, collection, inputMappings } = this.props        
        const data = await this.getAllData(withRelated)

        if(!withRelated) {
            exportTableToExcel(
                data,
                columns,
                this.renderCell,
                collection.title,
                collection.title,
                withRelated,
                []
            )
            mixpanelTrack('Export to Excel', {})
            return
        }
        const dataTypeEntriesPromise = relatedDataTypes.map(async (pickDatatype) => {
            const itemsEntries = await this.getRelatedMap(data, pickDatatype)
            const opts = getOptionsForInputMapping(pickDatatype, inputMappings)
            return [pickDatatype, {columns: opts, items: itemsEntries}]
        })
        const dataTypesEntries = await Promise.all(dataTypeEntriesPromise)

        exportTableToExcel(
            data,
            columns,
            this.renderCell,
            collection.title,
            collection.title,
            withRelated,
            dataTypesEntries
        )
        mixpanelTrack('Export to Excel', {})
    }

    isLocalFilterPresented = () => {
        const { localFilter = {} } = this.props
        return Object.keys(localFilter.filters).length > 0
    }

    localFilter = (results) => {
        const { localFilter, searchQuery: {dataTypes}, inputMappings } = this.props;
        if(!dataTypes.length) return results

        const selectedFields = getOptionsForInputMapping(dataTypes[0], inputMappings)

        let filteredContent = results

        const filteredColumns = selectedFields.filter(x => !!localFilter.filters[x.sourceField])
        if (filteredColumns && filteredColumns.length > 0) {
            filteredColumns.forEach(column => {
                filteredContent = filteredContent.filter(res => {
                    const fieldValue = column.extractData(res.Value.Source)

                        if(Array.isArray(fieldValue)){
                            for (let index = 0; index < fieldValue.length; index++) {
                                const element = fieldValue[index];
                                if(localFilter.filters[column.sourceField].data.indexOf(element) > -1){
                                    return true
                                }
                            }
                        }

                        if (Array.isArray(localFilter.filters[column.sourceField].data) && localFilter.filters[column.sourceField].data.indexOf(fieldValue) > -1 ) {
                            return true
                        }
                
                        if(localFilter.filters[column.sourceField].type === 'date') {
                            const fromToAgeValues = localFilter.filters[column.sourceField].data
                            if(fieldValue && fromToAgeValues.fromAge && fromToAgeValues.toAge) {
                               return new Date(fieldValue) > new Date(fromToAgeValues.fromAge) && new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
    
                            if(fieldValue && fromToAgeValues.fromAge) {
                                return new Date(fieldValue) > new Date(fromToAgeValues.fromAge)
                            }
    
                            if(fieldValue && fromToAgeValues.toAge) {
                                return new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
                        }
                    return false
                })
            })
        } 
        return filteredContent.map(result => result.Value.Source)
    }

    getAllData = async (withSlice = false) => {
        const { searchQuery, queryService, totalResultSize, firstAllResultsLength } = this.props
        const withLocalFilter = this.isLocalFilterPresented()
        const size = (withLocalFilter && firstAllResultsLength.init) ? firstAllResultsLength.value : withSlice ? SLICE_LENGTH : totalResultSize
        const _searchQuery = { ...searchQuery, page: 0, size }

        const resultsData = await queryService.Search(_searchQuery, null, true)
        const data =  await resultsData?.data ?? []

        const items = withLocalFilter ? this.localFilter(data) : data.map(res => res.Value.Source)
        return items
    }


    render() {
        const { classes, t, exportTableDataType } = this.props;
        const { showMenu: showExportMenuDataType, anchorEl, dataTypesOptions } = exportTableDataType;

        const options = [
            {
                label: t('Export to Excel'),
                key: 'xlsx'
            },
            {
                label: t('Export to CSV'),
                key: 'csv'
            }
        ]

        return (
            <>
            <MenuTableToolbar
                title={t('Select export type')}
                classes={{ menu: classes.menu, container: classes.settingsIconContainer }}
                button={<ExportIcon />}
                options={options}
                onChoose={this.onChoose}
            />
            <ExportMenuDataTypeSelector
                anchorEl={anchorEl}
                allowMultiple
                title={t("Select Related dataType")}
                showMenu={showExportMenuDataType}
                onClose={this.closeMenuExportDataTypeSelector}
                onChoose={this.chooseRelatedDataType}
                onSave={this.saveRelatedDataType}
                options={dataTypesOptions}
            />
            </>
        )
    }

}

const mapStateToProps = (state, ownProps) => ({
    searchQuery: state.search,
    exportTableDataType: state.exportTableDataType,
    queryService: state.services.queryService,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections,
    collectionUid: state.results.channel.collectionUid,
    localFilter: state.localFilter,
    users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    collection: state.results.channel
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles), withTranslation('exportMenuTranslate'),
    withErrorCatcher(),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ExportMenu);