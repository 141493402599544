import { push } from "react-router-redux"
import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions"
import { myPageContext } from "../../utils/drawerNavigation/myDrawerNavigationHelpers"

export const goToEditProfile = (dispatch) => {
    dispatch(selectDrawerNavigationOption(myPageContext.profile.id))
    dispatch(push('/mypage/profile'))
}
export const goToMyFavourites = (dispatch) => {
    dispatch(selectDrawerNavigationOption(myPageContext.favourites.id))
    dispatch(push('/mypage/favorites'))
}