import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import withErrorCatcher from '../../../components/Common/withErrorCatcher'
import SuggestedGroup from './GroupItem'
import { FeedGroupIcon } from '../../../components/Common/Icons/Feed/FeedGroupIcon'
import { ScrollDownIcon } from '../../../components/Common/Icons/Feed/ScrollDownIcon'
import { ArrowSectionIcon } from './ArrowSectionIcon'
import { useEffect } from 'react'

const _styles = (theme) => ({
    container: {
        marginTop: 60,
        marginBottom: 72,
        width: 512,
    },
    groups: {
        marginTop: 8,
        display: 'flex'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    btns: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    disabledArrow: {
        cursor: 'initial!important',
    },
    left: {
        cursor: 'pointer',
        transform: 'rotate(180deg)',
        marginRight: 16
    },
    right: {
        cursor: 'pointer',
    },
    title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0.147063,
        color: '#21212D',
    },
    dividerWrap: {
        position: 'relative',
        width: '100%',
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40
    },
    dividerLine: {
        width: '100%',
        height: 1,
        top: '50%',
        position: 'absolute',
        background: '#D7D7DB'
    },
    divider: {
        height: 30,
        background: '#EDEDF0',
        border: '1px solid #D7D7DB',
        boxShadow: '0px 0px 8px rgba(12, 12, 13, 0.04)',
        borderRadius: 12,
        position: 'absolute',
        paddingRight: 8,
        paddingLeft: 8,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dividerText: {
        marginLeft: 6,
        marginRight: 8,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        color: '#0C0C0D',
    },
})

const newQuery = {
    AggType: "FLAT",
    page: 0,
    query: "",
    size: 50,
}

const _SuggestedGroupsSection = (props) => {
    const [t] = useTranslation("feedWidgets")
    const {classes, openCollections, userCollections, queryService} = props
    const [step, setStep] = useState(0)
    const [uids, setUids] = useState([])

    useEffect(() => {
        queryService.Search(newQuery, null, false)
            .then((result) => {
                const groups = result?.aggregations?.groups?.Items ?? []
                const ids = groups.map(g => g.Key)
                setUids(ids)
            })
            .catch((err) => {
                setUids([])
            })
    }, [])

    const groups = useMemo(() => {
        const all = [...openCollections, ...userCollections]
        return uids
        .map((uid) => {
            return all.find(g => g.collectionGroup.groupUid === uid)
        })
        .filter(g => !!g)
    }, [openCollections, userCollections, uids])

    const maxStep = Math.round(groups.length / 2)
    const denyNext = step >= maxStep - 1
    const denyPrev = step <= 0

    const goNext = () => {
        if(denyNext) return
        setStep(prev => prev + 1)
    }

    const goPrev = () => {
        if(denyPrev) return
        setStep(prev => prev - 1)
    }
    
    const Btns = <div data-intrcm-id="SuggestedGroupsSection_o91pbx1i" className={classes.btns}>
        <ArrowSectionIcon color={denyPrev ? '#D7D7DB' : undefined} onClick={goPrev} className={`${classes.left} ${denyPrev ? classes.disabledArrow : ''}`} />
        <ArrowSectionIcon color={denyNext ? '#D7D7DB' : undefined} onClick={goNext} className={`${classes.right} ${denyNext ? classes.disabledArrow : ''}`} />
    </div>

    const Header = <div data-intrcm-id="SuggestedGroupsSection_n5fvvrr5" className={classes.header}>
        <div data-intrcm-id="SuggestedGroupsSection_h8pasdgm" className={classes.title}>{t("Suggested groups")}</div>
        {Btns}
    </div>


    const parted = useMemo(() => {
        const x = step * 2
        return groups.slice(x, x + 2)
    }, [step, groups])

    const Groups = <div data-intrcm-id="SuggestedGroupsSection_98fr238p" className={classes.groups}>
        {parted.map((g) => <SuggestedGroup key={g.collectionUid} option={g} />)}
    </div>

    const Divider = <div data-intrcm-id="SuggestedGroupsSection_5ml0qjzq" className={classes.dividerWrap}>
        <div data-intrcm-id="SuggestedGroupsSection_o07vlswd" className={classes.dividerLine} />
        <div data-intrcm-id="SuggestedGroupsSection_1fsasxv6" className={classes.divider} >
            <FeedGroupIcon />
            <div data-intrcm-id="SuggestedGroupsSection_7voakotr" className={classes.dividerText}>{t("Take a look on this groups")}</div>
            <ScrollDownIcon />
        </div>
    </div>

    if(!groups?.length) return null
    return <div data-intrcm-id="SuggestedGroupsSection_6irjs9pe" className={classes.container}>
        {Divider}
        {Header}
        {Groups}
    </div>
}

const mapStateToProps = (state) => ({
    openCollections: state.data.globalData?.openCollections?.data ?? [],
    userCollections: state.data.userData?.userCollections?.data ?? [],
    queryService: state.services.queryService
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})
export default compose(
    withStyles(_styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(_SuggestedGroupsSection);
