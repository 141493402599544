import React, { useEffect, useState } from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import ScenesIndicator from '../Indicator/ScenesIndicator'
import SceneVideo from './SceneVideo'
import SceneHeader from './SceneHeader'
import { useResolveRunTimeApplies } from './runTimeApplies/RunTimeApplies'

const styles = (theme) => ({
    container: {
        borderRadius: 8,
        height: 840,
        marginTop: -50,
        margin: 'auto',
        width: 478,
        position: 'relative',
        display: 'flex',
        backgroundColor: '#f5f5f5'
    },
    slider: {
        opacity: 0,
        willChange: 'transform, opacity',
        transition: 'none 0s ease 0s',
        transform: 'translateY(-20%)',
        overflow: 'hidden'
    },
    active: {
        opacity: 1,
        transition: 'opacity 300ms ease 0s, transform 300ms ease 0s',
        transform: 'translateY(0%)',
    },
})

const StorySceneAnim = (props) => {
    const {classes, wrapClasses, currentScene} = props
    const [wrapClass, setWrapClass] = useState(`${classes.container} ${classes.slider}`)
    const [RunTimeApplies] = useResolveRunTimeApplies(currentScene)

    useEffect(() => {
        setWrapClass(`${classes.container} ${classes.slider} ${classes.active}`)
    }, [])

    return (<div data-intrcm-id="StorySceneAnim_gi50jix5" className={wrapClass}>
                <ScenesIndicator wrapClass={wrapClasses.indicator} />
                <SceneHeader wrapClass={wrapClasses.header} />
                <SceneVideo />
                {RunTimeApplies}
            </div>)
}

export default compose(
    withStyles(styles),
)(StorySceneAnim)