export const CheckEditDate = {
    preRegisteredReload: () => {},
    registeredReload: () => {},
    scheduleInfo: null,
    denied: false,
    projectStartDate: '',
    onShowConfirm: () => {}
}

export const allowChangeProjectDate = (value) => {
    if(!value || !CheckEditDate.projectStartDate) {
        CheckEditDate.denied = false
        return true
    }
    const newVal = new Date(value).getTime()
    const projectVal = new Date(CheckEditDate.projectStartDate).getTime()

    if(newVal <= projectVal) {
        CheckEditDate.denied = true
        return false
    }
    CheckEditDate.denied = false
    return true
}

export const extractScheduleFromRecord = (record) => {
    const {originalData, id} = record
    let dependencies = record?.deps ?? []
    let assignedResource = record?.assignedResource?.[0]?.id ?? null
    let dateTimeFrom = new Date(record.startDate)
    let dateTimeTo = new Date(record.endDate)
    let scheduleUpdatePayload = {}

    if(originalData.scheduleUpdate) {
        scheduleUpdatePayload.dueDateUTC = originalData.scheduleUpdate.dueDateUTC
        scheduleUpdatePayload.taskDueDateDone =  originalData.scheduleUpdate.taskDueDateDone
    }
  
    if(assignedResource){
        scheduleUpdatePayload.assignedResource = assignedResource
    }
    if(dependencies){
        scheduleUpdatePayload.dependencies = dependencies
    }
    if(dateTimeFrom){
        scheduleUpdatePayload.dateTimeFrom =dateTimeFrom
    }
    if(dateTimeTo){
        scheduleUpdatePayload.dateTimeTo = dateTimeTo
    }

    return scheduleUpdatePayload
}