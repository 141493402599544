import { COLLECTION_SETTINGS_UPDATE, COLLECTION_GROUP_UPDATE, COLLECTION_UPDATE } from "../actionTypes/actionTypes";

export function updateCollectionSettings(newData) {
    return {
        type: COLLECTION_SETTINGS_UPDATE,
        settings: newData
    }
}

export function updateCollectionGroupSettings(newData) {
    
    return {
        type: COLLECTION_GROUP_UPDATE,
        group: newData
    }
}

export function updateCollection(newData) {
    
    return {
        type: COLLECTION_UPDATE,
        collection: newData
    }
}