import { NewUiWrapper, managerOrgScheduler, SchedulerOrg } from '@tuqqi/web';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { store } from '../../../index.js';
import { compose } from 'recompose';
import { withMixpanel } from '../../components/Common/withMixpanel';
import { withTranslation } from 'react-i18next';
import { useNewTable } from '../../../utils/useNewTable.js';
import { FULLVIEW_POST, INPUT_OPEN } from '../../../actionTypes/actionTypes.js';
import { MockForSetTableGlobalDataSch } from '../groups/scheduler/MockForSetTableGlobalDataSch.js';

class NewOrgSch extends Component {
    componentDidMount() {
        // if(!useNewTable(this.props.orgUid)) return
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        managerOrgScheduler.preFetch()
    }


    openOldInput = (memberUid, dataType, text, startDate, endDate) => {
        const { dispatch } = this.props
        const payload = { 
            postText: '', 
            dataType, 
            permissions: [], 
            dateTimeFrom: new Date(startDate), 
            dateTimeTo: new Date(endDate), 
        }

        if(!!memberUid) {
            payload.members = [{userUUID: memberUid}]
        }

        dispatch({
            type: FULLVIEW_POST,
            openPost: true,
            item: payload
        })
        dispatch({ type: INPUT_OPEN, model: payload });
    }


    render() {
        const callbacks = {
            onOpenOldDataType: this.openOldInput
        }

        if(!useNewTable(this.props.orgUid)) return null

        return <NewUiWrapper store={store} callbacks={callbacks}>
            <MockForSetTableGlobalDataSch />
            <SchedulerOrg />
        </NewUiWrapper>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(
    withMixpanel,
    withTranslation('newGroupDialog'),
    connect(mapStateToProps, mapDispatchToProps),
)(NewOrgSch);
