import React, { PureComponent } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ReactFilestack from 'filestack-react';
import OtherInput from "./otherInput";
import { withTranslation } from 'react-i18next'
import { handleFilestackPicker, filesFromSources } from '../../../utils/filesUtils.js';

class FileInput extends PureComponent {
    _handleFilestackPicker = (res) => {
        const { dispatch, collectionResult, searchQuery, inputDocument, inputService, t, oldUiInput } = this.props
        handleFilestackPicker(res.filesUploaded, dispatch, collectionResult, searchQuery, inputDocument, inputService, t, oldUiInput)
    }

    input = (onClick) => {
        return <OtherInput key={-4}
            icon='attach_file'
            title={this.props.t('File')}
            boldWhenSelected={true}
            dataType='file'
            onClick={onClick}
        />
    }

    renderInput = ({ onPick }) => {
        const { renderInput } = this.props
        if(renderInput){
            return renderInput(onPick)
        }
        return this.input(onPick)
    }
    
    render() {
        const { attachments, filestack } = this.props

        if (attachments.length || !filestack.write.policy) {
            return null
        } else {
            const maxFilesLimit = 100

            return (
                <ReactFilestack
                
                    apikey={'AG1Zm0R1vQ3yoXm9IzMcxz'}
                    options={{
                        maxFiles: maxFilesLimit,
                        storeTo: { location: 's3' },
                        fromSources: filesFromSources
                    }}
                    security={filestack.write}
                    onSuccess={this._handleFilestackPicker}
                    render={this.renderInput}
                />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    inputDocument: state.oldUiInput.document,
    oldUiInput: state.oldUiInput,
    inputService: state.services.inputService,
    attachments: state.oldUiInput.document.attachments,
    filestack: state.globalData.filestack,
    collectionResult: state.results.channel,
    searchQuery: state.search,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
    withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(FileInput);