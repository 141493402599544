export default class QueryService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
        
        this.getHeader = new Headers({
            'Authorization': 'Bearer ' + this.token
        })

        this.postHeader = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        })

        this.url = "/integrations";
    }

    saveGoogleAuth(code) {
        var myHeaders = this.postHeader;

        return fetch(process.env.API_SERVER + this.url + '/newGoogle/', {
            method: 'POST',
            body: JSON.stringify(code),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    outlookAuthSave(code){        
        return fetch(process.env.API_SERVER + this.url + '/newMS', {
            method: 'POST',
            body: JSON.stringify({code: code, url: process.env.NODE_ENV === 'production'? 'https://app.tuqqi.com/msComplete.html': 'http://localhost:3000/msComplete.html'}),
            headers:  this.postHeader
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
    
    disconnectCalendar() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + this.url, {
            method: 'DELETE',
            headers: myHeaders
        }).then(res => res);
    }

    getConnectionType(){
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(process.env.API_SERVER + this.url + "/getConnectionType", {
            method: 'GET',
            headers: myHeaders
        }).then(res => res.json());
    }
}