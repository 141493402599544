import { Divider, withStyles, Button, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  clearDrawerNavigation,
  selectDrawerNavigationOption,
  setDrawerNavigationMode,
} from "../../../../actions/drawerNavigationActions";
import { getUserDrawerFromPath } from "../../../../utils/drawerNavigation/userDrawerNavigationHelpers";
import DrawerContainer from "../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer";
import { useDrawerNavigation } from "../../../components/Common/Hooks/useDrawerNavigation/useDrawerNavigation";
import withErrorCatcher from "../../../components/Common/withErrorCatcher";
import { DrawerSwitcherNew } from "./DrawerSwitcher";
import { SelectedUserInfoDrawer } from "../../../components/SelectedUserInfo/UserInfoDrawer";
import { withMixpanel } from '../../../components/Common/withMixpanel';
import {themeConstants} from '../../../../theme';
import { useGetUserAdditionalOptions } from '../../../components/Common/Hooks/useDrawerNavigation/useGetUserAdditionalOptions';
import { createCustomTheme } from "../../../../utils/theming";

const styles = (theme) => ({
  container: {
    paddingTop: 24,
},
  divider: {
    backgroundColor: "#daddee",
    margin: 16,
    marginTop: 0,
    marginBottom: 0,
  },
  dividerSmall: {
    margin: 16,
    marginTop: 0,
    marginLeft:  12,
    marginRight: 12,
  },
  navigation: {
    margin: 16,
  },
  navigationSmall: {
  },
  paper: {
    width: themeConstants.navigationDrawerWidth,
    height: 'inherit!important',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
});

const __UserPageLeftDrawerNew = (props) => {
  const { query, classes, dispatch, drawerNavigation, allUsers, userData, userSettingsService, chatService, mixpanelTrack, isDrawerOpen, handleSwitchDrawerStatus,profileData, activeUsers } = props;

  const [t] = useTranslation("groupDrawerTranslate");
  const [selectedUser, setSelectedUser] = useState([]);
  const paperStyle = isDrawerOpen ? {} : { width: themeConstants.navigationDrawerSmallWidth, overflowX: 'hidden' };
  const isRemoved = selectedUser?.[0]?.status === 5
  const themeOption = profileData.org?.brandingColor ?? 'default'
  const theme = createCustomTheme(themeOption)

  useEffect(() => {
    const { pathname } = window.location;
    const [mode, id] = getUserDrawerFromPath(pathname);

    dispatch(setDrawerNavigationMode(mode));
    dispatch(selectDrawerNavigationOption(id));

    return () => {
      dispatch(clearDrawerNavigation());
    };
  }, []);

  useEffect(() => {
    const selectedUser = allUsers.filter(item => item.tag === query.slice(1, query.length))
    setSelectedUser(selectedUser)
  }, [allUsers, query])

  const [additionalOptions] = useGetUserAdditionalOptions({
    t,
    user: selectedUser[0],
    userData,
    isRemoved,
    activeUsers,
    dispatch,
    userSettingsService,
    chatService,
  })

  const [Navigation] = useDrawerNavigation({
    drawerNavigation,
    isDrawerOpen,
    query,
    t,
    profileData,
    // additionalOptions,
  });
  const NavigationSection = (
    <div data-intrcm-id="UserPageLeftDrawerNew_guq3q36s"
      className={isDrawerOpen ? classes.navigation : classes.navigationSmall}
    >
      {Navigation}
    </div>
  );

  return (
    <DrawerContainer containerStyle={`${classes.container} ${classes.paper}`} PaperProps={{ style: paperStyle }} open={isDrawerOpen}>
        <DrawerSwitcherNew
          isDrawerOpen={isDrawerOpen}
          onClick={handleSwitchDrawerStatus}
        />
        {selectedUser.length && <SelectedUserInfoDrawer isRemoved={isRemoved} query={query} isDrawerOpen={isDrawerOpen} selectedUser={selectedUser} additionalOptions={additionalOptions} theme={theme} />}
        <Divider className={ isDrawerOpen ? classes.divider : classes.dividerSmall } />
        {NavigationSection}
    </DrawerContainer>
  );
};

const mapStateToProps = (state) => ({
  allUsers: state.globalData.users,
  activeUsers: state.globalData.activeUsers,
  profileData: state.userData.profileData,
  drawerNavigation: state.drawerNavigation,
  userData: state.userData,
  userSettingsService: state.services.userSettingsService,
  chatService: state.services.chatService
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export const UserPageLeftDrawerNew = compose(
  withStyles(styles),
  withTranslation('widgetLibrary'),
  withMixpanel,
  withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
  connect(mapStateToProps, mapDispatchToProps)
)(__UserPageLeftDrawerNew);
