import { finishedUpdatingSpecificItem } from '../../../actions/dataActions';
import { USER_DATA_LOAD } from '../../../actionTypes/actionTypes';
import { showSnackbar } from '../../../actions/appActions';
import { loadUserData } from '@tuqqi/common/build';
import { getBusinessIdFielName, withBusinessId } from '../../../utils/withBusinessId';
import { getStringAsNum } from '@tuqqi/common';
import { managerAggregationsForDataType, managerGroupGantt } from '@tuqqi/web';

export const getSearchQueryItemsByDataType = (searchQuery, queryService, dataType) => {
    const _searchQuery = { ...searchQuery, page: 0, dataTypes: [dataType] }
    return queryService.Search(_searchQuery).then(result => {
        return result
    })
}

export const isUserMember = (members, auth0_id) => {
    const membersIds = members.map(member => member.auth0_id)
    return membersIds.includes(auth0_id)
}

export const emptyReq = {
    query: '',
    isUnseen: false,
    tags: [],
    users: [],
    dataTypes: [],
    labels: [],
    colors: [],
    groups: [],
    dateFrom: null,
    dateTo: null,
    isAuthor: false,
    page: 0,
    isMyItem: false,
    isTrending: false,
    hasLocation: false,
    sortField: '',
    sortOrder: null,
    sortType: null,
    confirmed: false,
    mustread: false,
    mustReadMyRequests: false,
}

export const allCollectionMembers = (collection) => {
    let members = []

    if (collection.collectionGroup) {
        if (!collection.limitedGroup || !collection.limitedGroup.groupUsers.length) {
            members = collection.collectionGroup.groupUsers
        } else {
            const dictionaryMembers = new Map()

            // One user can appear in the 2 groups
            // for example the admin of the collection is admin of the 2 groups
            // so in order to avoid this duplicate we need to use dictionary
            collection.collectionGroup.groupUsers
                .concat(collection.limitedGroup.groupUsers)
                .forEach(user => {
                    if (!dictionaryMembers.has(user.id)) {
                        dictionaryMembers.set(user.id, user)
                    }
                })

            members = Array.from(dictionaryMembers.values())
        }
    }

    return members
}

export const shouldAutoOpen = (groupInfo) => {

    if (!groupInfo || !groupInfo.settings) {
        return false
    }

    const { allowedInputs } = groupInfo.settings

    if (allowedInputs && allowedInputs.length === 1) {
        const dataType = allowedInputs[0]
        const autoOpenExceptions = ["link", "post", "file", "question"]
        if (autoOpenExceptions.includes(dataType)) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}

export function getAllCollection(openCollections, closedCollections) {
    
    const filteredOpenCollections = []
    openCollections.forEach(element => {
        const coll = closedCollections.find(x => x.collectionUid === element.collectionUid)
        if(!coll){
            filteredOpenCollections.push(element)
        }
    });
    
    const allCollections = filteredOpenCollections.concat(closedCollections)
    
    return allCollections
}
export let updateSpecificItemInState = function (props, itemId) {
    const { dispatch, queryService } = props;

    const query = {
        itemsIds: [itemId],
        page: 0,
        filter: {},
        tags: [],
        size: 1,
        AggType: 'FLAT',
    }

    queryService.SearchByIds(query).then((results) => {
        var oldlanes = [...this.state.lanes]

        var lane = oldlanes.find(lane => lane.cards.find(card => card.id == itemId))
        if(lane) {
        // Getting the index of the item in state in order to replace him with the new one
            let itemIndex = lane.cards.findIndex(item => item.id === itemId)

            lane.cards[itemIndex].doc = results.data[0].Value.Source;

            this.setState({ lanes: oldlanes })
        }
        dispatch(finishedUpdatingSpecificItem(itemId));
    })
}

export const joinToPublicGroup = (groupUid, searchQuery, dispatch, queryService, groupService, userSettingsService, cb) => {

    dispatch(showSnackbar('joining...'));

    groupService.joinToPublicGroup(groupUid)
        .then((res) => {
            if (res.ok) {
                dispatch(showSnackbar('Joined successfully!'));
                loadGroupInfo({ searchQuery, dispatch, queryService })
                userSettingsService.getUserData().then((userData) => {
                    dispatch({ type: USER_DATA_LOAD, data: { ...userData } });
                    dispatch(loadUserData({ API_URL: process.env.API_SERVER }))
                    cb && cb()
                })
            }

        })
}

export let loadGroupInfo = function (props = this.props) {


    const { searchQuery, dispatch, queryService } = props

    if (searchQuery.collectionUid) {

        return queryService.getCollectionInfo(searchQuery.collectionUid).then((result) => {
            if(!result) {
                this && this.setState && this.setState({ groupInfoLoaded: true })
                return
            }
            dispatch({
                type: 'RESULT_CHANNEL_UPDATE', results: {
                    channel: result,
                }
            })
            if(window?.location?.pathname?.includes?.('gantt')) {
                managerGroupGantt.updateKeepGroup(result)
            } else {
                this && this.setState && this.setState({ groupInfoLoaded: true })
            }
        })
    }

    return false
}

export let getCollection = function (props, collectionUid) {
    const { queryService } = props;

    if (collectionUid) {
        return queryService.getCollectionInfo(collectionUid);
    }
}

export const IsQueryExistInCollections = function (searchQuery, collections) {
    const query = searchQuery.query.slice(1)
    const collection = collections.find(coll => coll.title == query || coll.collectionUid == query)

    return collection
}

const mergeDataTypes = (dataTypes, aggs) => {
    aggs.forEach(x => {
        if (dataTypes[x.key]) {
            dataTypes[x.key] += x.docCount
        } else {
            dataTypes[x.key] = x.docCount
        }
    })
}

export let loadKanbanData = function (dataReloadInidicator, props) {
    const { searchQuery, dispatch, queryService } = props;

    // Set the page = 0
    let _searchQuery = { ...searchQuery, page: 0 }
    dispatch({ type: 'SEARCH_QUERY_UPDATE', query: { page: 0 } });

    return queryService.GetKanbanData(_searchQuery).then((kanbanResult) => {
        const hasMore = kanbanResult.unassigned.length < kanbanResult.total
        // const { aggDataTypes, sumAssignedAndUnassigned } = kanbanResult

        // const  dataTypes = aggDataTypes.map(x => ({ Key: x.key, DocCount: x.docCount }))
        const assigned = kanbanResult.assigned || []
        const unassigned = kanbanResult.unassigned || []
        const unassignedActualLength = kanbanResult.total
        this.setState({
            assigned,
            unassigned,
            total: unassignedActualLength,
            hasMore,
            // dataTypes
        })

        dispatch({ type: 'DATA_UPDATED' });
        // dispatch({
        //     type: 'RESULT_FILTERS_UPDATE', results: {
        //         dataTypes: [{
        //             Key: 'All',
        //             DocCount: sumAssignedAndUnassigned,
        //         }, ...dataTypes],
        //     }
        // });
    })
}

export const filterKanbanResults = function (props) {
    const { searchQuery, queryService } = props;

    return queryService.GetKanbanData(searchQuery).then(results => {
        const hasMore = results.unassigned.length < results.total
        const { assigned, unassigned } = results
        this.setState({ assigned, unassigned, hasMore })
    })
}





export let buildTable = function () {
    const { searchQuery, dispatch, queryService, userData } = this.props;

    let collectionViews = [...this.props.collectionInfo.collectionViews];

    let tableItems = [];

    const assignes = []
    if (collectionViews) {
        collectionViews.forEach(collectionView => collectionView.itemLists.map(itemList =>
            itemList.items.forEach(item => {
                assignes.push({ id: item.documentId, title: itemList.title })
            })))
    }

    this.state.results.forEach(data => {

        const item = assignes.find(item => item.id == data.Key)
        const itemState = item ? item.title : 'New'

        const newDoc = { ...data.Value.Source, id: data.Key, itemState };
        tableItems.push(newDoc)
    })


    return tableItems;
}

// export let buildKanban = function () {
//     const { searchQuery, dispatch, queryService, userData } = this.props;

//     let collectionViews = [...this.props.collectionInfo.collectionViews];

//     // Load assigne items
//     if (collectionViews) {
//         collectionViews.forEach(collectionView => collectionView.itemLists.map(itemList =>
//             itemList.items.forEach(item => {
//                 var doc = this.state.assigned.find(data => data.id === item.documentId);
//                 if (doc) {
//                     item.title = doc.document.title
//                     item.doc = doc.document
//                 }
//             })))
//     }

//     // Generate unassigned items
//     var unassignedItems = this.state.unassigned.map((unassigned, idx) => {
//         return {
//             documentId: unassigned.id,
//             title: unassigned.document.title,
//             order: idx,
//             doc: unassigned.document
//         }
//     })

//     // Add Unassigned list
//     // collectionViews.unshift({Items:unassignedItems, title:'Unassigned'})

//     // Load kanban data - cards and lanes placement
//     var columns = [];

//     if (collectionViews) {
//         this.view = collectionViews.find(c => c.collectionViewType === "Kanban");
//         this.view.itemLists.unshift({ title: 'Unassigned', description: 'Unassigned items', order: -1, items: unassignedItems, id: -1 });
//         columns = this.view.itemLists;
//     }

//     var data = { lanes: [] }

//     columns.forEach(column => {
//         var cards = column.items.map(item => {
//             return { id: item.documentId, title: item.title, order: item.order, doc: item.doc, style: cardStyle }
//         }).sort((card1, card2) => {
//             return card1.order - card2.order
//         })

//         data.lanes.push({
//             id: column.id,
//             title: column.title,
//             cards: [...cards/*, emptyCard(column.id)*/],
//             order: (column.collectionViewId == this.view.id) ? column.order : -1,
//             style: laneStyle,
//             isUnassigned: (column.collectionViewId != this.view.id),

//             // this function run before the nature scroll of kanban-trello and able to ovrride it
//             onScroll: (e) => {
//                 const { unassigned, total } = this.state

//                 // If the lane is assigned
//                 if (column.collectionViewId == this.view.id ||
//                     // If the lane is unassigned but all card loaded
//                     unassigned.length >= total) {
//                     e.stopPropagation();
//                 }
//             }
//         })
//     })
//     data.lanes.sort((a, b) => a.order - b.order)
//     // return data;
//     this.setState({ data: data });
// }

export function emptyCard(laneId) {
    return { isEmptyCard: true, id: `-${laneId}`, title: ' ', order: 100000000, doc: {}, style: cardStyle }
}

const laneStyle = { backgroundColor: '#f8f9fc', margin: '0px 5px' }
const cardStyle = {
    padding: 0, marginBottom: 12, border: 'none', width: 244, backgroundColor: 'transparent', marginRight: 'auto',
    marginLeft: 'auto'
}


// Kanban functions
// Add,Remove,Edit,Move cards and lanes

export let addLane = function (newData) {

    // update the lane in view
    const createdLane = newData.lanes[newData.lanes.length - 1]
    createdLane.order = newData.lanes.length - 2
    createdLane.style = laneStyle
    createdLane.isTempLane = true
    // createdLane.cards.push(emptyCard(createdLane.id))
    this.updateLanes(newData.lanes)

    const oldLaneId = createdLane.id
    let laneDTO = { title: createdLane.title, collectionViewId: this.view.id }

    this.props.groupService.addLane(laneDTO).then(newLane => {

        // chnged the lane to be like the lane in DB
        const oldLane = newData.lanes.find(l => l.id == oldLaneId && l.isTempLane)
        oldLane.id = newLane.id
        oldLane.order = newLane.order
        oldLane.title = newLane.title
        oldLane.isTempLane = false

        this.updateLanes(newData.lanes)
    })
}


export let moveCard = function (props, viewType, cardId, sourceLaneId, targetLaneId, position) {
    const { data, assigned, unassigned, from } = this.state

    const sourceLane = data.lanes.find(l => l.id == sourceLaneId)
    const targetLane = data.lanes.find(l => l.id == targetLaneId)
    const card = targetLane && targetLane.cards.find(c => c.id == cardId)

    // if card.isEmptyCard => the card is fake card and exist only for ux reason
    if (!sourceLane || !targetLane || !card) {
        return
    }
    else if (sourceLane.isUnassigned && targetLane.isUnassigned) {
        return
    }
    // Deprecate condition
    else if (card.isEmptyCard) {
        // move the empty card to the last plase 
        this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: targetLane.cards.length })
    }

    // if targetLaneId.isTempLane => the lane isn't sync with server side and have not the correct id 
    else if (targetLane.isTempLane) {
        // move back the card 
        this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: position })
    } else {

        // update the total if the target or the source is unassigned
        // both of them can be unassigned if the user move the card in the same unassigned-Lane
        if (sourceLane.isUnassigned) {
            const oldCard = unassigned.find(card => card.id == cardId)

            const index = unassigned.indexOf(oldCard)
            unassigned.splice(index, 1)

            assigned.push({ ...oldCard })
            this.setState({ total: this.state.total - 1, assigned: [...assigned], unassigned: [...unassigned], from: (from - 1) })

        } else if (targetLane.isUnassigned) {
            const oldCard = assigned.find(card => card.id == cardId)
            const index = assigned.indexOf(oldCard)
            assigned.splice(index, 1)
            unassigned.push({ ...oldCard })
            this.setState({ total: this.state.total + 1, assigned: [...assigned], unassigned: [...unassigned]/*, from: (form + 1)*/ })
        }

        card.order = position


        this.updateLanes(this.state.data.lanes)

        props.groupService.moveCard({
            CollectionId: props.collectionInfo.collectionUid,
            CollectionViewType: viewType,
            CardId: cardId,
            SourceLaneId: sourceLaneId,
            TargetLaneId: targetLaneId,
            Position: position,
            docTitle: card.title
        }).then((res) => {
            if (!res.ok) {
                this.eventBus.publish({ type: 'MOVE_CARD', fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId, index: targetLane.cards.length })
            }
        })
    }
}

export function updateLane(laneId, newObj) {
    const { groupService, collectionInfo } = this.props
    const { lanes } = this.state.data

    const collection = {
        id: collectionInfo.id,
        collectionViews: [
            {
                id: getKanbanCollection(collectionInfo).id,
                itemLists: lanes
                    .filter(l => l.id == laneId)
                    .map(l => ({
                        id: l.id,
                        title: newObj.title,
                        description: newObj.description
                    }))
            }
        ]
    }
    groupService.updateLane(collection)
}

export function deleteLane(laneId, newObj) {
    const { groupService, collectionInfo } = this.props
    const { lanes } = this.state.data

    const collection = {
        id: collectionInfo.id,
        collectionViews: [
            {
                id: getKanbanCollection(collectionInfo).id,
                itemLists: lanes
                    .filter(l => l.id == laneId)
                    .map(l => ({
                        id: l.id
                    }))
            }
        ]
    }

    groupService.deleteLane(collection)

    // move the cards from the deleted lane to unassigned lane

    // the index of the deleted lane
    const deletedLaneIndex = lanes.findIndex(l => l.id == laneId)

    if (deletedLaneIndex != -1) {
        const unassignedLane = lanes.find(l => l.order == -1)
        lanes[deletedLaneIndex].cards.forEach(c => {
            if (!c.isEmptyCard) {
                c.order = unassignedLane.cards.length
                unassignedLane.cards.push(c)
            }
        })

        // remove the lane
        lanes.splice(deletedLaneIndex, 1)
    }

    // update the data in the board 
    this.updateLanes(lanes)

}

export function moveLane(laneId, newPosition, laneObj) {

    // if it's the "Unassigned" lane
    if (laneObj.order == -1 || newPosition == 0) {
        return
    }

    const { lanes } = this.state.data
    const oldPosition = laneObj.order + 1

    // if the lane move to the left side
    if (newPosition < oldPosition) {
        var t = lanes.filter(l => (l.order + 1) >= newPosition && l.order < laneObj.order)
        t.forEach(l => l.order++)
    } else { // if the lane move to the right side
        var t = lanes.filter(l => l.order > laneObj.order && (l.order + 1) <= newPosition)
        t.forEach(l => l.order--)
    }

    // change the position of the moved lane
    laneObj.order = newPosition - 1

    // sort the lanes and init the data in order that 
    // the board component know that the order changed
    lanes.sort((a, b) => a.order - b.order)

    this.updateLanes(lanes)

    // change the order in server
    const { groupService, collectionInfo } = this.props
    const collection = {
        id: collectionInfo.id,
        collectionViews: [
            {
                id: getKanbanCollection(collectionInfo).id,
                itemLists: lanes.filter(l => l.order != -1).map(l => ({
                    id: l.id, order: l.order
                }))
            }
        ]
    }

    groupService.moveLane(collection)
}

function getKanbanCollection(collectionInfo) {
    const kanban = collectionInfo.collectionViews.find(c => c.collectionViewType == "Kanban")

    return kanban
}

export const getJsonData = (data) => {
    if (data.parsedData) {
        return data.parsedData
    }

    let parsedData = {}
    try {
        parsedData = data.jsonData && JSON.parse(data.jsonData)

        data.parsedData = parsedData
    } catch (error) {

    }
    return parsedData
}

export const findInputMappingByDataType = (inputMappings, selectedDataType) => {
    return inputMappings.find(inputMapping =>
        inputMapping.customDataMapping.dataTypeFilter === selectedDataType)
}

export const getOptionsForInputMapping = (inputMappings, selectedDataType) => {
    const inputMapping = findInputMappingByDataType(inputMappings, selectedDataType)

    if (!inputMapping) {
        return []
    }

    let allowedFieldTypes = ['title', 'text', 'paragraph', 'select', 'multiSelect', 'date', 'dateTime', 'number', 'location', 'checkbox', 'phone', 'email', 'calculation']

    const fields = inputMapping.customDataMapping.customFieldsMappings
        .filter(field => allowedFieldTypes.includes(field.type))
        .map(field => ({
            ...field,
            // type: field.sourceField,
            extractData: (row) => {
                let value = undefined
                const isAggregation = managerAggregationsForDataType.useAggregationsOnField(field)
                if(isAggregation) {
                    const aggregations = row?.aggregations ?? {}
                    value = aggregations[field.aggregateField]
                } else {
                    value = getJsonData(row)[field.sourceField]
                }

                if (field.type === 'calculation') {
                    const val = !value || value === 'error' ? '' : value
                    return getStringAsNum(val)
                }
                if (field.type === 'number') {
                    // return number
                    if (value) {
                        return getStringAsNum(value)
                    } else {
                        return 0
                    }

                }
                return value
            }
        }))

    if (inputMapping.isEnableLinks) {
        fields.push({
            fieldName: 'Link',
            sourceField: 'links',
            type: 'links',
            extractData: (row) => row.links

        })
    }

    if (withBusinessId(inputMapping)) {
        fields.push({
            fieldName: getBusinessIdFielName(inputMapping),
            sourceField: 'businessId',
            type: 'businessId',
            extractData: (row) => row.businessId ?? ''

        })
    }

    return fields
}