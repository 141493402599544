import { Button, Card, CardActions, CardContent, CardMedia, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ItemPreviewImgOrIcon from '../../Common/ItemPreviewImgOrIcon';
import dataTypesDictionary from '../../Common/userCompatibleNames/dataTypesDictionary';
import { withTranslation } from 'react-i18next';
import UserAvatar from '../../Common/UserAvatar';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';

const styles = theme => ({
    wrapper: {
        "overflow": "visible",
        "marginRight": "16px",
        "marginBottom": "6px",
        "marginTop": "6px",
        minWidth: 184,
        maxWidth: 184,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
        
    },
    firstItem: {
        marginLeft: "3px"
    },
    userName: {
        "fontFamily": '"Rubik"',
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        "color": "#21212D",
        marginTop: 8
    },
    timeAgo: {
        "fontFamily": '"Rubik"',
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        "color": "#979AAA",
        "margin-left": "13px"
    },
    view: {
        "fontFamily": "Rubik",
        "fontSize": "13px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.23",
        "letterSpacing": "1.16px",
        "color": theme.customColors.primary.main,
        "textAlign": "right",
        "textTransform": "lowercase",
    },
    postedIn: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": "#21212D",


        'text-align': 'left',

    },
    actionsWrapper: {
        justifyContent: "space-between"
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    }
})

class MustReadItem extends Component {
    constructor(props) {
        super(props)

        const { data, users, userData, inputMappings } = this.props
        const { userUUID, userName, userPicUrl, userTag, docTimestamp, dataType, groupUid } = data

        const user = users.find(x => x.auth0_id === userUUID)
        const docTime = moment(docTimestamp).fromNow();
        const typeName = dataTypesDictionary(dataType, inputMappings)
        const vowel = ['a', 'e', 'i', 'o', 'u']
        const prefix = vowel.includes(typeName.toLowerCase()[0]) ? 'an' : 'a'

        let postedIn = ''
        const isPublic = groupUid === userData.profileData.organizationUid
        if (!isPublic) {
            const group = isPublic ? null : userData.collections.find(x => x.collectionGroup.groupUid === groupUid)
            if (group) {
                postedIn = props.t('in') + ' ' + group.title
            }
        }

        this.state = {
            userName,
            userPicUrl,
            userLevel: user.level,
            shared: `${props.t('Shared')} ${props.t('prefix', { prefix })} ${typeName} ${postedIn}`,
            docTime,
            dataType
        }
    }

    logClick = () => {
        this.props.collaborationService.logClick(this.props.docId)
    }

    openItem = () => {
        const { dispatch, data, docId, openFullview } = this.props
        openFullview(data, docId, false)
    }

    render() {
        const { classes, data, index, t } = this.props
        const { userName, userPicUrl, userLevel, shared, docTime } = this.state

        return (<Card className={`${classes.wrapper} ${index === 0 ? classes.firstItem : ''}`} >
            <CardContent>
                <CardMedia className={classes.header}>
                    <UserAvatar userUUID={data.userUUID} hideLevel={true} />
                    <ItemPreviewImgOrIcon document={data} />
                </CardMedia>
                <Typography className={classes.userName}>{userName}</Typography>
                <Typography className={classes.postedIn} dir='auto'>{shared}</Typography>
            </CardContent>
            <CardActions className={classes.actionsWrapper}>
                <Typography dir="auto" className={classes.timeAgo}>{docTime}</Typography>
                <Button className={classes.view} onClick={this.openItem}>{t('View')}</Button>
            </CardActions>
        </Card>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    cloudfront: state.globalData.cloudfront,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withOpenFullview,
    withTranslation('mustReadWidget'),
    connect(mapStateToProps))(MustReadItem)