import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import {managerGroupGantt, useRowStatus, managerRowStatus, SelectStatusPopover} from '@tuqqi/web'
import ReactDOM from 'react-dom';

const styles = theme => ({
})


const KEY_ENTER = 'Enter';
const UID_ROW_STATUS = 'UID_ROW_STATUS'

const StatusEdit = (props) => {
    const {value} = props
    const [ready, setReady] = useState(false);
    const indexForKeys = useRef(0)
    
    const {isFetching, statuses} = useRowStatus(UID_ROW_STATUS, true)

    useEffect(() => {
        managerRowStatus.useCacheStatuses(managerGroupGantt.getCurrentColUid())
    }, [])

    useEffect(() => {
        const ind = statuses.findIndex(x => x.id === props.value.id)
        indexForKeys.current = ind === -1 ? 0 : ind
    }, [props.value?.id, statuses])

    const checkAndToggleMoodIfLeftRight = useCallback((event) => {
        if(!ready) return
        if(!statuses.length) return

        if(event.key === 'Tab') {
            // props.api.tabToNextCell()
            event.stopPropagation();
            return
        }
        
        if(event.key === KEY_ENTER) {
            const status = statuses.find(x => x.id === value.id)
            
            props.onChange(status ?? null, true)
            event.stopPropagation();
            return
        }
    
        if(event.key === 'ArrowDown') {
            let index = indexForKeys.current + 1
            const x = statuses?.[index]
            if(!x) {
                index = 0
                indexForKeys.current = 0
                props.onChange(statuses?.[index], false)
            } else {
                indexForKeys.current = indexForKeys.current + 1
                props.onChange(x, false)
            }
            event.preventDefault()
            event.stopPropagation();
            return
        }

        if(event.key === 'ArrowUp') {
            let index = indexForKeys.current - 1
            const x = statuses?.[index]
            if(!x) {
                index = statuses.length - 1
                indexForKeys.current = statuses.length - 1
                props.onChange(statuses?.[index], false)
            } else {
                indexForKeys.current = indexForKeys.current - 1
                props.onChange(x, false)
            }
            event.preventDefault()
            event.stopPropagation();
            return
        }
    }, [value, ready, statuses]);

    useEffect(() => {
        (ReactDOM.findDOMNode(props.containerRef.current)).focus();
        setReady(true);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.addEventListener('keydown', checkAndToggleMoodIfLeftRight);
        }, 50)
  
        return () => {
            clearTimeout(timeout)

            window.removeEventListener('keydown', checkAndToggleMoodIfLeftRight);
        };
    }, [checkAndToggleMoodIfLeftRight, ready]);
  

    const _onChange = (value) => {
        if(isFetching) return
        const status = statuses.find(x => x.id === value)
        if(!status) return
        props.onChange(status, true)
    }
  
    const onClose = () => {
        props.onClose()
    }

    return <SelectStatusPopover
        isLoading={isFetching}
        popoverMaxHeight={44 * 5}
        focused={true}
        isPinned={false}
        onClose={onClose}
        refContainer={props.containerRef}
        itemHeight={46}
        value={value.id} 
        statuses={statuses}
        onChange={_onChange} />
}

export default withStyles(styles, {withTheme: true})(StatusEdit)