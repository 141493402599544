import { withStyles } from '@material-ui/core';
import React, {useCallback, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {allFluencyIconsNames, allFluencyIconsData, getDefaultTuqqiIcon} from '@tuqqi/common'
import SVG from 'react-inlinesvg';
import GeneralButton from '../../../Common/AllButton/AllButton';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils.js';

const styles = (theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12
    },
    customIcon: {
        zIndex: 2,
        marginLeft: 12,
    },
    texts: {
        zIndex: 2,
        maxWidth: 245,
        marginLeft: 8,
        marginRight: 'auto',
        flexDirection: 'column'
    },
    title: {
        zIndex: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#0C0C0D',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.24
    },
    subTitle: {
        zIndex: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#737373',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.4
    },
    buttonText: {
        zIndex: 2,
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0.4,
    },
    buttonContainer: {
        zIndex: 2,
        marginRight: 12,
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: '#fff',
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main,
        },
    },
    buttonContainerSelected: {
        zIndex: 2,
        marginRight: 12,
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main,
        },
    },
    hoverItem: {
        backgroundColor: '#F9F9FA!important'
    },
    hover: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'transparent',
        borderRadius: 8,
        width: 380,
        height: 48,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
})

const icon = 'public'
function _SuggestedDocItem(props) {
    const {classes, item, dispatch, selectedCategory, adminService, allowedOptions} = props
    const [t] = useTranslation("search")
    const [isHover, setIshover] = useState(false)

    const isSelected = useMemo(() => {
        return !!allowedOptions.find(o => o.type === 2 && o.categoryId === selectedCategory && item.docId === o.referenceUid)
    }, [allowedOptions, selectedCategory, item])

    const renderIcon = useCallback((icon) => {
        const rightIcon = 'collectibles'
        const ind = allFluencyIconsNames.findIndex(x => x === rightIcon)
        const path = (ind === -1) ? getDefaultTuqqiIcon() : allFluencyIconsData[ind]

        return <SVG onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={classes.customIcon} width={32} height={32} src={path} />
    }, [classes])

    const onClickBack = useCallback(() => {
        dispatch({
            type: 'SET_LAUNCHPAD_PAGE_TYPE',
            pageType: 'edit',
            selectedCategory: 0
        })
    }, [])

    const onAdd = useCallback(() => {
        const _item = {
            referenceUid: item.docId,
            customTitle: item.title,
            customIcon: icon,
            categoryId: selectedCategory,
            type: 2,
            order: 0,
        }
        adminService.createLaunchpadMenuItem(_item).then(res => {
            if(!res) return
            dispatch({
                type: 'ADD_LAUNCHPAD_OPTION',
                item: {
                    ..._item,
                    id: res.id
                }
            })
            onClickBack()
        })
    }, [item, selectedCategory, onClickBack])

    const onRemove = useCallback(() => {
        const _item = allowedOptions.find(o => o.type === 2 && o.categoryId === selectedCategory && item.docId === o.referenceUid)
        if(!_item) return

        adminService.deleteLaunchpadMenuItem(_item.id).then(res => {
            if(!res) return
            dispatch({
                type: 'DELETE_LAUNCHPAD_OPTION',
                id: _item.id
            })
            onClickBack()
        })   
    }, [selectedCategory, item, onClickBack])

    const onSelect = useCallback(() => {
        if(!!isSelected) {
            onRemove()
            return
        }
        onAdd()
    }, [isSelected, onRemove, onAdd])

    const Texts = <div data-intrcm-id="SuggestedDocItem_vvsbjzde" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={classes.texts}>
        <div data-intrcm-id="SuggestedDocItem_tfc5xu70" className={classes.title}>{item.title}</div>
    </div>

    const SelectButton = <GeneralButton onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} onClick={onSelect} containerStyle={!isSelected ? classes.buttonContainerSelected : classes.buttonContainer} textStyle={classes.buttonText} title={isSelected ? t("Unselect") : t("Select")} />

    return (
        <div data-intrcm-id="SuggestedDocItem_fce4x76l" className={classes.container} >
            {renderIcon(icon)}
            {Texts}
            {SelectButton}
            <div data-intrcm-id="SuggestedDocItem_q5mmlys6" onMouseEnter={() => setIshover(true)} onMouseLeave={() => setIshover(false)} className={`${classes.hover} ${isHover ? classes.hoverItem : ''}`} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    allowedOptions: state.launchpadNavigation.allowedOptions,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export const SuggestedDocItem = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_SuggestedDocItem)

