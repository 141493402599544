import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paper: {
        padding: 16
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paperStyle: {
        marginBottom: 40
    },
});

class RewardsSettings extends Component {

    state = {
        isLoading: true,
        open: false
    };

    componentDidMount(){
        this.getAll();
    }

    getAll = () => {
        this.props.adminService.getAllRewards().then((rewards) => {
            this.setState({
                rewards: rewards,
                isLoading: false
            })
        })
    }

    OpenEdit = (reward) => {

        this.setState({ open: true, reward: reward });

        // this.props.adminService.editReward().then((reward) => {
        //     this.getAll();
        // })
    }

    onChange = (reward, value) => {
        this.setState({
            reward: { ...reward, points: value }
        });
    }

    handleClickSave = () => {
        const { reward } = this.state;
        const { adminService } = this.props;

        adminService.editReward(reward).then(() => {
            this.getAll();
        })

        this.handleClose();
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    getModal = () => {
        const { classes,t } = this.props;
        const { reward } = this.state;

        return reward && <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {reward.systemAction.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {reward.description}
                    </DialogContentText>
                    <TextField
                        id="text-field-level"
                        label={t("Points")}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        value={this.state.reward.points ? this.state.reward.points : 1}
                        onChange={event => this.onChange(reward, event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} aria-label="delete" className={classes.button}>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.handleClickSave} aria-label="save" className={classes.button}>
                       {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
    }

    render() {
        const { classes,t } = this.props;
        const { rewards } = this.state;

        const spinner = <div data-intrcm-id="RewardsSettings_o9bka4ya" className={classes.spinner}>
            <CircularProgress className={classes.progress} />
        </div>
        let content = spinner;

        if(!this.state.isLoading){
            content = <List className={classes.listStyle}>
                {rewards.map(reward => {
                    return <ListItem divider button onClick={() => this.OpenEdit(reward)}>
                        {/* <Avatar> {reward.points < 0 ? reward.points :"+"+reward.points} </Avatar> */}
                        <ListItemText
                            primary={reward.systemAction.title}
                            secondary={reward.description}/>
                        <ListItemSecondaryAction style={{paddingRight: 16}}>
                            <div data-intrcm-id="RewardsSettings_tx6w06s1">
                                <span>{`${reward.points} ${t("pt")}.`}</span>
                            </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                })}
            </List>
        }

        return <div data-intrcm-id="RewardsSettings_m4x7v3a1" >
            <Typography variant="title" style={{ marginBottom: '15px' }}>
                {t('Points System')}
            </Typography>
            <Paper className={classes.paperStyle}>
                {content}
            </Paper>
            {this.getModal()}
        </div>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),withTranslation('admin'), connect(mapStateToProps, mapDispatchToProps))(RewardsSettings);
