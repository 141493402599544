import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import NotificationParsedText from "../../../App/Drawers/DrawerComponents/Notifications/NotificationParsedText";

const styles = (theme) => ({
  container: {
    padding: "6px 18px",
    borderRadius: 6,
    zIndex: 2,
    backgroundColor: "#0e0e14",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center !important",
    boxSizing: "border-box",
    wordBreak: "break-all",
  },
  containerBigText: {
    "-webkit-line-clamp": 3,
  },
  text: {
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
    fontFamily: "Rubik",
    fontSize: 44,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.32,
    letterSpacing: 0,
    color: "#fff",
  },
  textSmall: {
    fontSize: "15px !important",
  },
});
const DocTitleLinked = (props) => {
  const {
    styles,
    classes,
    currentScene,
  } = props;
  const [docTitle, setDocTitle] = useState("");
  const [isTextBig, setIsTextBig] = useState(false);

  useEffect(() => {
    currentScene.messageTemplateParams.length > 3 && setDocTitle(currentScene.messageTemplateParams[2])
}, [currentScene])

  useEffect(() => {
      if (
        docTitle.length > 15 &&
        (styles.container.height < 90 || styles.container.maxHeight < 90)
      ) {
        setIsTextBig(true);
      } else {
        setIsTextBig(false);
      }
  }, [docTitle, styles]);

  if (!docTitle) return null;

  return (
    <div data-intrcm-id="DocTitleLinked_0cgtrwn0"
      style={styles.container}
      className={
        isTextBig
          ? `${classes.container} ${classes.containerBigText}`
          : classes.container
      }
    >
      <div data-intrcm-id="DocTitleLinked_isqlq83v"
        style={styles.text}
        className={
          isTextBig ? `${classes.text} ${classes.textSmall}` : classes.text
        }
      >
        {docTitle}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DocTitleLinked);
