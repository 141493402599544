import React, { Component, Fragment } from 'react'
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles, Icon, Avatar } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    icon: {
        height: 18,
        fontFamily: 'Material Icons',
        color: '#868686',
        fontSize: 18,
        padding: 0,
        paddingRight: 5,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    classButton: {
        paddingLeft: 2,
        paddingRight: 3
    },
    textStyle: {
        fontFamily: '"Roboto"',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.3,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'inline',
    },
})

class ItemClassification extends Component {

    getGroupInfo() {
        const { item, userData, openCollections, t } = this.props;
        const privateText = t('Private')
        const publicText = t('Public')
        const getGroupLink = (groupUid) => `/group?searchquery=${encodeURIComponent("/" + groupUid)}`

        let groupDisplayName = '';
        const orgUid = userData.profileData.org.orgUid;
        let permissionIcon = '';
        let isGroupClickable = false
        let groupLink = null
        const allCollections = openCollections.concat(userData.collections)

        if (item.isPrivate) {
            groupDisplayName = privateText;
            permissionIcon = 'lock_outline'
        } else if (item.isLimitedToMembers) {
            groupDisplayName = 'Limited To Members'
            permissionIcon = 'supervised_user_circle'
        } else if (item.groupUid && item.groupUid != orgUid) {
            const currCollection = allCollections.find(c => c.collectionGroup.groupUid == item.groupUid)
            groupLink = currCollection ? getGroupLink(currCollection.collectionUid) : ''
            isGroupClickable = true
            groupDisplayName = currCollection ? currCollection.title : publicText;
            permissionIcon = currCollection ? currCollection.icon : publicText;
        } else {
            groupDisplayName = publicText;
            permissionIcon = 'public'
        }

        return { groupDisplayName, permissionIcon, groupLink, isGroupClickable };
    }

    getClassificationDisplayName() {
        const { classifications, item, seperator } = this.props
        if (!item.classification) {
            return ''
        }

        const classification = classifications.find(x => x.level === item.classification)
        return `${seperator} ${classification.displayName} - level: ${classification.level}`
    }

    render() {
        const { seperator, classes } = this.props
        const { permissionIcon, groupDisplayName, groupLink, isGroupClickable } = this.getGroupInfo()

        const rowStyle = {
            flexDirection: 'row', display: 'flex'
        }

        const innerContent = (<Fragment>
            <Icon classes={{ root: classes.icon }} className={classes.icon}>{permissionIcon}</Icon>
            <div data-intrcm-id="ItemClassification_a515m0ju">{groupDisplayName} {this.getClassificationDisplayName()} {seperator}</div>
        </Fragment>)


        if (isGroupClickable) {
            return (<a href={groupLink} style={{ ...rowStyle, textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }} className={classes.textStyle + ", " + classes.classButton}>
                {innerContent}
            </a>)
        }

        return (<div data-intrcm-id="ItemClassification_srdxs8na" style={{ ...rowStyle }} className={classes.textStyle + ", " + classes.classButton}>
            {innerContent}
        </div>)
    }
}

const mapStateToProps = (state) => ({
    classifications: state.globalData.classifications,
    openCollections: state.globalData.openCollections,
    userData: state.userData
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}


export default compose(withStyles(styles),
    withTranslation('itemFullViewModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ItemClassification);