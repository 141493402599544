import { sortGroupsByTitle } from "../groupsNavigation/collectionToGroupNavigation";

export const mapLandingUsers = (users, maxLen) => {
    const sorted = users.sort((a, b) => b.points - a.points);
    const cutted = sorted.slice(0, maxLen);

    return cutted.map((user) => ({
        firstname: user.firstname,
        lastname: user.lastname,
        tag: user.tag,
        auth0_id: user.auth0_id,
        avatar: user.profileImageUrl? user.profileImageUrl.replace('fit-in/50x0', 'fit-in/70x0'):''
    }))
}

export const mapSubscribedUsers = (subscribedTags, users) => {
    const tags = subscribedTags.map(tag => tag.tag);
    const filtered = users.filter(user => tags.includes(user.tag));
    const sorted = filtered.sort((a, b) => b.points - a.points);
    return sorted.map((user) => ({
        firstname: user.firstname,
        lastname: user.lastname,
        tag: user.tag,
        auth0_id: user.auth0_id,
        avatar: user.profileImageUrl? user.profileImageUrl.replace('fit-in/50x0', 'fit-in/70x0'):''
    }))
}

export const searchGroupsOptions = {
    starred: [],
    withFilterStarred: false, 
    withSlice: true, 
    maxLen: 0, 
    withPlaceholder: false
}

export const mapSearchGroups = (groups, {
    starred = [], 
    withFilterStarred = false,
    withSlice = true,
    maxLen = 0,
    withPlaceholder = false
} = searchGroupsOptions) => {
    maxLen = withSlice ? maxLen : groups.length;
    const len = withPlaceholder ? maxLen - 1 : maxLen;

    let sorted = sortGroupsByTitle(groups)
    if(withFilterStarred) {
        const uids = starred.map(group => group.groupUid)
        sorted = sorted.filter(group => !uids.includes(group.groupUid))
    }
    // return sorted.length <= len ? sorted : sorted.slice(0, len)
    return sorted
}

export const isQueryInString = (q = '', str = '') => {
    const keys = q.split("")
    return keys.every(k => str.toLowerCase().includes(k))
}

export const sortRecentsRequestsByTimestamp = recents => recents.sort((a, b) => b.timestamp - a.timestamp)
