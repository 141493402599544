import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Codemirror from 'react-codemirror';

require('codemirror/mode/javascript/javascript');
require('codemirror/mode/xml/xml');
require('codemirror/addon/selection/active-line');
require('codemirror/mode/markdown/markdown');
require('codemirror/mode/sql/sql');

const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 50,
    header: {}
};



// Apply some reset
const styles = theme => ({

});



class CodeInput extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: 'javascript',
        };
    };

    render() {
        const { classes, dispatch, item, open } = this.props;

        var options = {
            lineNumbers: true,
            lineWrapping: true,
            readOnly: true,
            styleActiveLine: true,
            mode: item.mimeType,
            viewportMargin: Infinity
        };

        return (<div data-intrcm-id="codeFull_uoecyue0">
            <Typography variant="title" gutterBottom dir='auto'>
                {item.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {item.mimeType}
            </Typography>
            <Codemirror ref="editor"
                className={'codeInput'}
                value={item.docText}
                options={options}
                autoFocus={true} />
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    searchQuery: state.search,
    ...state.fullView,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CodeInput);
// export default withStyles(styles)(InputFAB);

