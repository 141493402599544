export default (searchQuery) => {
    
    return () => {
        var postDefaultInput = searchQuery.query;
        if (searchQuery.query === '') {
            postDefaultInput = '@' + this.props.userProfile.tag;
        }

        return postDefaultInput;
    }
}
