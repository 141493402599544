import React from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import { goTo } from '../../../../actions/goToActions'

const styles = theme => ({

    chip: {
        margin: 5
    }
});

class AlertDialog extends React.Component {
    state = {
        open: false,
        results: [],
        isLoading: true,
        isLoadingMore: false,
        hasMore: false,
        page: 0
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };




    tagWithPrefix(tag = ''){
        let res = tag.startsWith('@') ? tag : `#${tag}`
        
        return res
    }

    render() {
        const { open, handleClose, document, classes, dispatch } = this.props;

        return (
            <div data-intrcm-id="itemTagsModal_r1g5qo4w">
                {/* <Button onClick={this.ha}>Open alert dialog</Button> */}
                <Dialog
                    open={open}
                    onEnter={() => { }}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={false}

                >
                    <DialogTitle id="alert-dialog-title">{((document.title == null || typeof document.title === 'undefined'?"":document.title).length > 65 ? document.title.substring(0, 65) + '...' : document.title) + " : tags"}</DialogTitle>
                    <DialogContent>
                        <div data-intrcm-id="itemTagsModal_a8u5u82n" style={{ width: 750 }}>
                            {
                                document.tags.filter(tag => tag && tag.length).map((data, idx) => {
                                    const tag = this.tagWithPrefix(data)

                                    return (
                                        <Chip
                                            key={idx}
                                            label={tag}
                                            onClick={goTo(tag, dispatch)}
                                            className={classes.chip}
                                        />
                                    );
                                
                            })}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AlertDialog);


// export default AlertDialog;