import React, { PureComponent } from 'react';
import FileMessage from './FileMessage/FileMessage';
import PhotoMessage from './PhotoMessage/PhotoMessage';
import VideoMessage from './VideoMessage/VideoMessage';
import withErrorCatcher from '../../components/Common/withErrorCatcher';


export class ChatAttachment extends PureComponent {

    data = {
        status: {
            click: false,
            loading: 0,
            download: true
        }
    }

    render (){

        const { classes, attachment } = this.props
        const { fileType, fileUid } = attachment


        let item = null
        if (fileType.startsWith("image")) {

            item = (
                <PhotoMessage
                    onOpenLightbox={this.props.onOpenLightbox}
                    attachment={attachment}
                    width={this.props.width}
                    height={this.props.height}
                    data={this.data}
                />)
        }
        else if (fileType.startsWith("video")) {

            item = (
                <VideoMessage
                    attachment={attachment}
                    data={this.data}
                    width={this.props.width}
                    height={this.props.height}
                />
            )
        }
        else {
            item = <FileMessage attachment={attachment} />
        }

        return <div data-intrcm-id="ChatAttachment_llyibfvf" key={attachment.fileUid} style={{ marginTop: 16 }}>{item}</div>
    }
}



export default withErrorCatcher()(ChatAttachment);
