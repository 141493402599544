import { PREVIEW_SHOW_ANALYZE, PREVIEW_CLOSE_ANALYZE, FULL_RESET_STORE  } from '../actionTypes/actionTypes'
  
  const initialState = {
    showAnalyze: false,
    docId: ''
  }
  
  const preview = (state = initialState, action) => {
  
  
  
    switch (action.type) {
      case FULL_RESET_STORE:
        return initialState;

      case PREVIEW_SHOW_ANALYZE:
  
        return {
          ...state,
          showAnalyze: true,
          ...action.item
        }
        case PREVIEW_CLOSE_ANALYZE:
  
        return {
            ...state,
            showAnalyze: false,
            docId: ''
        }
      default:
      return state
    }
  }
  
  export default preview