import { Grid, Icon, IconButton, InputBase, withStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';


const styles = theme => ({
    input: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.71",
        "letterSpacing": "0.1px",
        "color": theme.newUI.color.commonBlack
    }
})

class InputTitle extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            title: props.list.title,
        }
    }

    edit = (e) => {
        this.setState({ title: e.target.value })
    }

    onBlur = () => {
        const { list, editTitleList, onBlur } = this.props

        // if the title changed
        if (list.title !== this.state.title) {
            editTitleList(list.id, this.state.title)
        }
        onBlur()
    }
    onClick = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation()
            this.onBlur()
            return false
        }
        return true
    }

    render() {

        const { classes } = this.props;


        return (
            <InputBase
                onKeyDown={this.onClick}
                autoFocus
                value={this.state.title}
                onChange={this.edit}
                onBlur={this.onBlur}
                className={classes.input} />
        )
    }
}

export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    withErrorCatcher())(InputTitle);
