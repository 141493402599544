import { Icon, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import GroupSelectorMenuItem from '../FullView/GroupSelectorMenuItem.js';

const styles = theme => ({
    avatar: {
        width: 36,
        height: 36
    },
    buttonClass: {
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        marginTop: 8
    },
});


class MenuPermission extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            anchorEl: null,
            privacyMenuOpen: false,
            openSideMenu: false,
            anchorSideEl: null
        };
    };

    closeInput = () => {
        const { dispatch } = this.props
        dispatch({
            type: 'FULLVIEW_POST',
            openPost: false,
            item: {}
        })
    }
    openSideMenu = (e) => {
        // open the side menu and init the anchor
        this.setState({
            openSideMenu: !this.state.openSideMenu,
            anchorSideEl: e.target.children[0]
        })
    }

    toggleSideMenu = () => {
        this.setState({ openSideMenu: !this.state.openSideMenu })
    }

    openMenu = (event) => {
        this.togglePrivacyMenu()
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    togglePrivacyMenu = () => {
        this.setState({ privacyMenuOpen: !this.state.privacyMenuOpen })
    }


    handlePrivate = isPrivate => () => {
        this.props.dispatch({ type: "INPUT_CHANGE", model: { isPrivate: isPrivate, permissions: [], groupUid: '' } })
        this.setState({ privacyMenuOpen: false })
    }

    handleCollection = (value) => () => {
        this.props.dispatch({ type: "INPUT_CHANGE", model: { isPrivate: false, permissions: [value] } })
        this.setState({
            openSideMenu: false,
            privacyMenuOpen: false
        })
    }


    classificationOrPrivate = (item) => {
        const { profileData, t, collections } = this.props;
        const privateText = t('Private')
        const publicText = t('Public')

        let classTitle = '';
        const orgUid = profileData.org.orgUid;
        let permissionIcon = '';
        let onClick = null;


        if (item.isPrivate) {
            classTitle = privateText;
            permissionIcon = 'lock_outline'

        } else if (item.permissions && item.permissions.length && item.permissions[0] != orgUid) {
            const currCollection = collections.find(c => c.collectionGroup.groupUid == item.permissions[0])
            classTitle = currCollection ? currCollection.title : publicText;
            permissionIcon = currCollection ? currCollection.icon : publicText;
            onClick = (e) => { this.openGroup(e, currCollection) };

        } else if (item.groupUid && item.groupUid != orgUid) {
            const currCollection = collections.find(c => c.collectionGroup.groupUid == item.groupUid)
            classTitle = currCollection ? currCollection.title : publicText;
            permissionIcon = currCollection ? currCollection.icon : publicText;
            onClick = (e) => { this.openGroup(e, currCollection) };

        } else {
            classTitle = publicText;
            permissionIcon = publicText
        }

        return { classTitle, permissionIcon, onClick };
    }

    render() {
        const { classes, collections, t, oldUiInput } = this.props

        const button = this.classificationOrPrivate(oldUiInput);

        return (
            <Fragment>
                <Icon style={{ fontSize: 18 }}>arrow_right</Icon>
                <Typography color='primary' onClick={this.openMenu} style={{ cursor: 'pointer' }} inline component='span' variant="body2" >{button.classTitle}</Typography>

                <Menu anchorEl={this.state.anchorEl} open={this.state.privacyMenuOpen}
                    onClose={this.togglePrivacyMenu} >

                    <GroupSelectorMenuItem
                        key='public'
                        dataType={oldUiInput.dataType}
                        permission='public'
                        disableIfDataTypeIsNotPermitted
                        onClick={this.handlePrivate(false)}
                        title={t('Public')}
                    />

                    <GroupSelectorMenuItem
                        key='private'
                        onClick={this.handlePrivate(true)}
                        title={t('Private')}
                    />


                    <MenuItem onClick={this.openSideMenu}>{t('Groups')}
                        <div data-intrcm-id="menuPermission_16q8k4eg" id='demo' name='demo' />
                        <Icon>keyboard_arrow_right</Icon>
                    </MenuItem>
                </Menu>
                {
                    !collections.length ? null
                        : <Menu anchorEl={this.state.anchorSideEl} id="simple-menu2"
                            disableAutoFocusItem
                            open={this.state.openSideMenu && this.state.privacyMenuOpen && this.state.anchorSideEl}
                            onClose={this.toggleSideMenu} >
                            {
                                collections.map((collection) => (
                                    <GroupSelectorMenuItem
                                        dataType={oldUiInput.dataType}
                                        permission={collection.collectionGroup.groupUid}
                                        disableIfDataTypeIsNotPermitted
                                        value={collection.collectionUid}
                                        key={collection.collectionUid}
                                        onClick={this.handleCollection(collection.collectionGroup.groupUid)}
                                        title={collection.title}
                                    />
                                ))
                            }
                        </Menu>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    collections: state.userData.collections,
    oldUiInput: state.oldUiInput.document,

})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MenuPermission);

