import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import ReactSVG from 'react-svg';
import mimeTypes from "mime-types";

const styles = theme => ({
    container: {
        width: 60,
        flexGrow: 0,
        flexShrink: 0,
        "minHeight": "60px",
        "height": "100%",
        "backgroundColor": "#e8e8e8"
    },
    img: {
        "borderRadius": "2px",
    }
});

class LeftImage extends PureComponent {


    render() {
        const { classes, src, height, children } = this.props;

        return <Grid item container className={classes.container} alignItems='center' justify='center'>
            {children}
            {/* <img onError={this.onErrorInImg} src={src} className={classes.img} style={{height:height}}/> */}
        </Grid>
    }
}

export default compose(withStyles(styles))(LeftImage);
