import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ItemFullViewModal from '@components/FullView/itemFullViewModal';
import { withStyles, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import SnackBarOldUi from '../Common/snackBar/snackBar';
import NotificationsSnackBar from '../Common/snackBar/NotificationsSnackBar.js';

import ConfirmCloseModal from '../FullView/confirmCloseModal';
import Header from '../Header/header';
import InputFullViewModal from '../Input/inputFullViewModal';
import GroupDialog from '../Input/GroupDialog';
import classnames from "classnames";
import { checkIfChannel } from '../../views/search/searchUtils.js';
import DropZone from './DropZone.js';
import { Snackbar as SnackbarNewUi, NewUiWrapper, FullviewDialog as NewUiFullViewDialog, InputDialog, PreviewDialog, allowCloseGroup, openedGroupActions, managerAdminDrawer, SetAggregationsData, managerTableKeepGlobalData, managerACReportView, managerLastReportGroups, shouldUseScheduler, managerShouldUseScheduler, managerShouldUseGantt } from '@tuqqi/web';
import {store} from '../../../index';
import VideoChatWindow from './videoChatWindow.js';
import UserProfileModal from './UserProfileModal.js';
import UpgradeFromFreeModal from './UpgradeFromFreeModal.js';
import goToCollection from '../../actions/goToCollection.js';
import goTo from '../../actions/goTo.js';
import { getAllCollection } from '../../views/groups/groupUtils.js';
import { closeFullview } from '@tuqqi/web/dist/actions/fullview-actions/fullviewActions';
import TemplateInitModal from '../Common/Templates/TemplateInitModal.js';
import UnsupportedResolution from './UnsupportedResolution.js';
import YouNeedToUpdateSubscriptionWrapper from '../../views/admin/customInputTemplates/pages/YouNeedToUpdateSubscription/YouNeedToUpdateSubscriptionWrapper';
import getTheme from '../../../theme';
import { clearStories, fetchCloudfrontActionTypes, getDirectMessages, getDiscussionsRooms, getGroupForDocumentActionTypes, mapNotificationsToStories } from '@tuqqi/common';
import StoriesWindow from '../StoriesNavigation/StoriesWindow.js';
import {openFullview, CheckInitialPageAC} from '@tuqqi/web';
import { mixpanelTrack } from '../Common/Utils/mixpanelUtils.js';
import { push } from 'react-router-redux';
import { allowChangeProjectDate, CheckEditDate } from '../../views/groups/GanttBryntum/utils/checkChangeDate.js';
import { managerLastGroupsView } from '../../../managers/managerLastGroupsView.js';
import { goToEditProfile } from '../../actions/goToEditProfile.js';
import { useNewTable } from '../../../utils/useNewTable.js';
import parseImagUrl from "../../../ui/components/Common/parseImagUrl";
import { globalDataLoad, userDataLoad } from '../../../actions/globalDataAction.js';
import { addRecentItem, initLandingUsers, initSearchFavouritesGroups, initSearchManagedGroups, initSearchSubscribedGroups, initSearchSubscribedUsers } from '../../../actions/searchNavigationActions.js';
import { SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import { selectDrawerNavigationOption } from '../../../actions/drawerNavigationActions.js';
import { myPageContext } from '../../../utils/drawerNavigation/myDrawerNavigationHelpers.js';
import { fixImage } from '../../../utils/loadOrganizationData.js';
import { initGroups } from '../../../utils/groupsNavigation/collectionToGroupNavigation.js';
import { setManagedGroups, setSubscribedGroups } from '../../../actions/groupsNavigationActions.js';

const styles = theme => ({
    kanbanScroll: {
        "&::-webkit-scrollbar": {
            visibility: 'visible!important',
            width: 0,
            height: 12,
            display: "block",
            backgroundColor: '#D7D7DB',
        },
        "&::-webkit-scrollbar-thumb": {
            visibility: 'visible!important',
            opacity: 0.5,
            backgroundColor: '#B1B1B3',
            borderRadius: 16,
        },
    },
    root: {
        alignItems: 'stretch',
        width: '100%',
    },
    container: {
        height: '100vh',
    },
    defaultContainer: {
        // paddingTop: theme.constants.defaultToolbarHeight + theme.constants.marginFromToolbar - 21,
        paddingTop: theme.constants.defaultToolbarHeight,

    },
    defaultContainerOffsetToolbar: {
        paddingTop: `${theme.constants.defaultToolbarHeight}px!important`
    },
    defaultContainerAdmin: {
        paddingTop: `${theme.constants.defaultToolbarHeight}px!important`
    },
    searchTabscontainer: {
        paddingTop: theme.constants.defaultToolbarHeight + theme.constants.searchTabsHeight + theme.constants.marginFromToolbar
    },
    newLeftDrawer: {
    position: 'fixed',
        [theme.breakpoints.up(1280)]: {
            zIndex: 1,
            height: 'calc(100% - 100px)',
        },
        overflowY: 'auto'
    },
    showFullSreen: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
    },
    withoutMarginContent: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('xs')]: {
            marginLeft: 32,
            marginRight: 32,
        }
    },
    libraryContent: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
    },
    adminContent: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
    },
    kanbanContent: {
        height: 'calc(100% - 130px)',
        paddingTop: `${theme.constants.defaultToolbarHeight}px!important`
    },
    content: {
        // backgroundColor: "#ffffff",//theme.palette.background.default,
    },
    fullViewWithDrawer: {
        marginRight: 27,
        marginLeft: theme.constants.leftDrawerWidth,
        width: `calc(100% - ${theme.constants.leftDrawerWidth}px)`,
    },
    searchView: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
    },
    marginContent: {
        [theme.breakpoints.down(theme.constants.feedWidth + (2 * theme.constants.leftDrawerWidth))]: {
            marginLeft: theme.constants.leftDrawerWidth,
            marginRight: 32,
            width: theme.constants.feedWidth - 32,
        },
        [theme.breakpoints.up(theme.constants.feedWidth + (2 * theme.constants.leftDrawerWidth))]: {
            marginLeft: 'calc(50vw - ' + theme.constants.feedWidth / 2 + 'px)',
            marginRight: 'calc(50vw - ' + theme.constants.feedWidth / 2 + 'px)',

            width: theme.constants.feedWidth,
        },
    },

    feedContainer: {
        margin: '0px auto',
        overflowX: 'hidden',
        // marginTop: 5,
    },
    // Apply some reset
    '@global': {
        html: {
            background: "#ffffff",//theme.palette.background.default,
            fontFamily: theme.typography.fontFamily,

            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
        },
        body: {
            margin: 0,
        },
    },
});

export const LAST_SCROLL_POS = {
    value: 0
}

class Wrapper extends Component {

    constructor(props) {
		super(props);

		this.state = { theme: {}, brandingColor: ''  }
	}


    refWrapper = null;

    getChildrenClass = () => {
        const { classes, canOpenMenu, fullViewWithDrawer, showFullSreen, isFeed } = this.props;

        let childrenClass;

        if (isFeed) {
            childrenClass = classes.feedContainer
        } else if (this.isShowSearchTabs()) {
            childrenClass = classes.searchView
        } else if (fullViewWithDrawer) {
            childrenClass = classes.fullViewWithDrawer
        } else if (canOpenMenu) {
            childrenClass = classes.marginContent
        } else if (showFullSreen) {
            childrenClass = classes.showFullSreen
        } else if (this.isLibrary() || this.isOrgSch()) {
            childrenClass = classes.libraryContent
        } else if (this.isAdmin()) {
            childrenClass = classes.adminContent
        } else {
            childrenClass = classes.withoutMarginContent
        }

        return childrenClass
    }
    // getIdProp = () => {
    //     const { isFeed } = this.props;
    //     if(isFeed || location.pathname.includes('search')) return {
    //         id: 'main_feed_scroll'
    //     }
    //     return {}
    // }
    isLibrary = () =>location.pathname.includes('library')
    isOrgSch = () =>location.pathname.includes('orgscheduler')
    isOngoing = () =>location.pathname.includes('ongoing')
    isAdmin = () =>location.pathname.includes('admin')
    isGantt = () =>location.pathname.includes('gantt')


    isKanban = () =>location.pathname.includes('kanban')
    // isTableGroup = () => location.pathname.includes('group/table') && useNewTable(this.props.orgUid)
    isCalendarNew = () => shouldUseScheduler(this.props.orgUid) && (location.pathname.includes('group/calendar') || location.pathname.includes('mypage/calendar') ||location.pathname.includes('user/calendar') )
    isTableGroup = () => this.isCalendarNew() || (location.pathname.includes('group/scheduler') && useNewTable(this.props.orgUid)) || (location.pathname.includes('group/table') && useNewTable(this.props.orgUid)) || location.pathname.includes('mypage/actioncenter')
    isHomeGroup = () => location.pathname.includes('group/home')

    isShowSearchTabs = () => {
        const { location, searchQuery } = this.props
        return (location.pathname.includes('search') && !checkIfChannel(searchQuery))
    }

    initLastGroupView = () => {
        const { userData } = this.props

        managerLastGroupsView.init(userData?.profileData?.auth0_id ?? '')
    }
    initLastACView = () => {
        const { userData } = this.props

        managerACReportView.mountLastView(userData?.profileData?.auth0_id ?? '')
    }
    initLastGroupTableReportView = () => {
        const { userData } = this.props

        managerLastReportGroups.init(userData?.profileData?.auth0_id ?? '')
    }
    initAGKey = () => {
        setTimeout(() => {
            managerTableKeepGlobalData.setAGKey(process.env.REACT_APP_AG_KEY)
        }, 300)
    }
    componentWillMount() {
        this.listenUseScheduler({subscription: {subscriptionType: undefined}})
        this.initLastACView()
        this.initLastGroupTableReportView()

        managerTableKeepGlobalData.setOpenGroup((groupUid) => { this._goToCollection(groupUid) })
    }
    componentDidMount() {
        const { profileData, subscription: {subscriptionType} } = this.props

        const muiTheme = createMuiTheme(
            getTheme({
                brandingColor: profileData.org.brandingColor,
                subscriptionType
            })
        );

        this.setState({theme: muiTheme, brandingColor: profileData.org.brandingColor})
        this.listenSharedItem({searchPath: location.host})
        this.listenCloseGroup({searchPath: ''})
        this.listenUseScheduler({subscription: {subscriptionType: undefined}})

        this.initLastGroupView()
        this.initAGKey()
        // Change background style
        document.documentElement.style.backgroundColor = "#f4f2ee"

        managerTableKeepGlobalData.setOpenGroup((groupUid) => { this._goToCollection(groupUid) })
    }

    listenBrandingColor(prevProps) {
        const { subscription: {subscriptionType} } = this.props
        if(this.props.userData.profileData.org.brandingColor !== this.state.brandingColor || subscriptionType !== prevProps.subscription.subscriptionType) {
            const muiTheme = createMuiTheme(
                getTheme({
                    brandingColor: prevProps.profileData.org.brandingColor,
                    subscriptionType
                })
            );

            this.setState({theme: muiTheme, brandingColor: this.props.profileData.org.brandingColor});
        }
    }
    
    listenUseScheduler(prevProps) {
        const subscriptionType = this.props?.subscription?.subscriptionType
        const subscriptionTypePrev = prevProps?.subscription?.subscriptionType

        if(subscriptionType === subscriptionTypePrev) return
        managerShouldUseScheduler.provideSubscriptionType(subscriptionType)
        managerShouldUseGantt.provideSubscriptionType(subscriptionType)
    }
    

    listenNotifications = (prevProps) => {
        const { dispatch, notifications = []} = this.props
        const {notifications: prevNotifications = []} = prevProps

        if(!notifications.length) {
            dispatch(clearStories())
            return;
        }

        if(notifications.length === prevNotifications.length) return
        const setGroupType = (group) => {
            if (group.collection) {
                if(group.systemActionTypeUid === 'user_add_item' || 
                    group.systemActionTypeUid === 'user_add_comment' || 
                    group.systemActionTypeUid === 'user_add_task' ||
                    group.systemActionTypeUid  === 'user_done_task' 
                ){
                    return 'Group'
                } else {
                    return 'Public'
                }
            } else {
                return 'Public'
            }
        }
        const notifs = notifications.map(n => ({...n, dataType: n.dataType || 'travel', groupType: setGroupType(n)}))
        dispatch(mapNotificationsToStories(notifs))
    }

    getUrlParams = (path) => new URLSearchParams(path);

    isAppearsSharedItem = (prevPath, path) => {
        return path.includes("openItemId") && !prevPath.includes("openItemId")
    }
    isDisappearsSharedItem = (prevPath, path) => {
        return !path.includes("openItemId") && prevPath.includes("openItemId")
    }
    listenSharedItem = (prevProps) => {
        const {searchPath: prevPathname} = prevProps
        const {searchPath: pathname, dispatch} = this.props
        if(pathname === prevPathname) return

        if(this.isAppearsSharedItem(prevPathname, pathname)) {
            const url = new URL(pathname);
            const docId = url.searchParams.get("openItemId");
            dispatch(openFullview(docId, false))
            dispatch(addRecentItem(docId))
        } else if(this.isDisappearsSharedItem(prevPathname, pathname)) {
            dispatch({
                type: 'CLOSE_FULLVIEW'
            })
        }
    } 
    listenResetCurrentSavedReport = (prevProps) => {
        const {pathname: prevPathname} = prevProps
        const {pathname, dispatch} = this.props
        if(pathname === prevPathname) return

        const firstView = (pathname?.slice(1) ?? '').split("/")?.[0] ?? ''
        const prevFirstView = (prevPathname?.slice(1) ?? '').split("/")?.[0] ?? ''
        if(firstView === prevFirstView) return

        dispatch({
            type: 'SET_CURRENT_SAVED_REPORT',
            currentSavedReport: null
        })
    } 

    
    listenOpenFullview = (prevProps) => {
        const {searchPath, fullview: {open, mainDocId}} = this.props
        const {fullview: {open: prevOpen}} = prevProps
        if(open === prevOpen) return;

        const url = new URL(searchPath);
        if(open && !prevOpen) {
            url.searchParams.set("openItemId", mainDocId)
            history.pushState({}, null, url.href);
        } else if(!open && prevOpen) {
            url.searchParams.delete("openItemId")
            history.pushState({}, null, url.href);
            document.body.style.overflow = 'auto'
        }
    }

    listenCloseGroup = (prevProps) => {
        const {searchPath, needCloseGroup, rehydrated } = this.props
        const {searchPath: prevSearchPath} = prevProps

        if(!rehydrated) return
        if(searchPath === prevSearchPath) return;
        if(!needCloseGroup || !allowCloseGroup()) return

        this.props.dispatch(openedGroupActions.closeGroup())
    }

    listenCloseAdmin = (prevProps) => {
        const {pathname } = this.props
        const {pathname: prevPathname} = prevProps

        if(pathname === prevPathname) return;
        if(!pathname.includes('/admin'))

        managerAdminDrawer.setOption({prop: 'home', uid: 'home'})
    }

    postEffectCloudfront = (cloudfront) => {
        if(!cloudfront) return
        const {userData, dispatch, userSettingsService} = this.props

        Promise.all([userSettingsService.getUserDetails(), userSettingsService.getUsers()])
            .then(([userDetails, users]) => {
                const activeUsers = users.filter(x => x.status === 0);
                fixImage(cloudfront, userDetails)
                users.forEach(_userDetails => fixImage(cloudfront, _userDetails))
                activeUsers.forEach(_userDetails => fixImage(cloudfront, _userDetails))
                
                const newGlobal = {}
                
                newGlobal.cloudfront = cloudfront
                newGlobal.users = users
                newGlobal.activeUsers = activeUsers
                newGlobal.profileData = userDetails
        
                dispatch(globalDataLoad({
                    ...newGlobal,
                }));

                dispatch(userDataLoad({
                    ...userData,
                    profileData: userDetails,
                }));            

                this.props.dispatch({type: fetchCloudfrontActionTypes.success, result: cloudfront})
                const {managedGroups, subscribedGroups, favouritesGroups} = initGroups(userData.collections, userDetails.id, cloudfront, userData.favoriteCollections || [])

                dispatch(initSearchFavouritesGroups(favouritesGroups))
                dispatch(initLandingUsers(activeUsers))
                dispatch(initSearchSubscribedUsers(activeUsers, userData.subscribedTags))
                dispatch(initSearchSubscribedGroups(subscribedGroups))
                dispatch(initSearchManagedGroups(managedGroups))
                dispatch(setManagedGroups(managedGroups))
                dispatch(setSubscribedGroups(subscribedGroups))
            }).catch(err => {
                console.log('fvdfvdfvdfvdfvd', err);
            })
    }

    refetchCloudfront = () => {
        const {inputService} = this.props

        this.props.dispatch({type: 'REFETCH_CLOUDFRONT_START'})
        inputService.getCloudFrontSignature()
            .then(cloudfront => {
                if(!cloudfront) {
                    this.props.dispatch({type: 'REFETCH_CLOUDFRONT_ERROR'})
                    return
                }
                this.props.dispatch({type: 'REFETCH_CLOUDFRONT_DONE'})
                this.postEffectCloudfront(cloudfront)
            })
            .catch(err => {
                this.props.dispatch({type: 'REFETCH_CLOUDFRONT_ERROR'})
            })
    }

    hours10MS = 36000000
    listenCloudfrontExpire = (prevProps) => {
        const {pathname, cloudfrontAdditional } = this.props
        const {pathname: prevPathname} = prevProps
        const {fullview: {open}} = this.props
        const {fullview: {open: prevOpen}} = prevProps

        if(pathname === prevPathname && open === prevOpen) return;
        if(!!cloudfrontAdditional?.isFetching || !cloudfrontAdditional?.lastFetch) return
        const potential = cloudfrontAdditional.lastFetch + this.hours10MS
        if(new Date().getTime() <= potential) return

        this.refetchCloudfront()
    }

    listenKanbanScrollStyle = (prevProps) => {
        const {pathname, classes } = this.props
        const {pathname: prevPathname} = prevProps

        if(pathname === prevPathname) return;

        if(this.isKanban()) {
            window.document.body.classList.add(classes.kanbanScroll)
            return
        }
        window.document.body.classList.remove(classes.kanbanScroll)
    }

    listenUserChangeAvatar = (prevProps) => {
        const activeUsers = this.props?.activeUsers ?? []

        const users = this.props?.users ?? []
        const usersPrev = prevProps?.users ?? []

        const user = users.find(x => x.auth0_id === this.props.userUid)
        const user1 = usersPrev.find(x => x.auth0_id === this.props.userUid)

        if(user?.profileImageUrl === user1?.profileImageUrl) return
        if(!user?.profileImageUrl) return
        if(!!user.profileImageUrl.startsWith('https://')) return

        users.forEach(_userDetails => {
            if(_userDetails.auth0_id !== this.props.userUid) return
            _userDetails.profileImageUrl = parseImagUrl(this.props.cloudfront, _userDetails.profileImageUrl)
        })

        activeUsers.forEach(_userDetails => {
            if(_userDetails.auth0_id !== this.props.userUid) return
            _userDetails.profileImageUrl = parseImagUrl(this.props.cloudfront, _userDetails.profileImageUrl)
        })
        
        this.props.dispatch(globalDataLoad({
            users,
            activeUsers
        }))

        this.props.dispatch(initLandingUsers(activeUsers))

    }

    componentDidUpdate(prevProps) {
        this.listenUseScheduler(prevProps)
        this.listenBrandingColor(prevProps)
        this.listenOpenFullview(prevProps)
        this.listenKanbanScrollStyle(prevProps)
        this.listenCloseGroup(prevProps)
        this.listenCloseAdmin(prevProps)
        this.listenCloudfrontExpire(prevProps)
        this.listenUserChangeAvatar(prevProps)
        this.listenResetCurrentSavedReport(prevProps)
    }
     

    handleUserProfileUpdate = () => {

        const { userData } = this.props;

        window.intercomSettings = {
            app_id: "z5hl4hx5",
            name: userData.profileData.firstname + ' ' + this.props.userData.profileData.lastname,
            email: userData.profileData.email, // Email address
            "role": userData.profileData.role,
            "tag": userData.profileData.tag,
            "lang": userData.profileData.lang,
            company: {
                id: userData.profileData.org.orgUid,
                name: userData.profileData.org.title,
                created_at: userData.profileData.org.creationDateTime,
                // (optional): Insert name of the plan current company is on                
                "TemplateName": userData.profileData.org.TemplateName,
                plan: this.props.subscription.subscriptionType,
                // (optional): Insert amount current company spends a month
                monthly_spend: 10,
                // (optional): Add any custom attributes, e.g., 
                upgraded_at: 1424941688
            },
            created_at: "1312182000" // Signup date as a Unix timestamp
        };

        window.Intercom('update')
    }

    _goToCollection = (groupUid) => {
        const { collections, openCollections, dispatch } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid === groupUid)
        if (!collection) {
            return
        }

        goToCollection('/' + collection.title, dispatch, collection.collectionUid, groupUid, collection)();

        dispatch(closeFullview())
    }

    goToUser = (userUid) => {
        const { users, dispatch, fullview } = this.props

        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }

        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
        fullview.open && dispatch(closeFullview())
    }
    onOpenHashtag = (hashtag) => {
        const { dispatch, fullview } = this.props
        goTo(hashtag, dispatch)();
        fullview.open && dispatch(closeFullview())
    }
    onOpenLink = (link) => {
        window.open(link, '_blank')
    }

    updateData = () => {
    }

    allowRenderNewUI = () => {
        return !this.props.suspendedError
    }

    onUserMessage = (auth0_id) => {
        if(!auth0_id) return
        const { dispatch, fullview, chatService } = this.props
        fullview.open && dispatch(closeFullview())

        chatService.openNewChat(auth0_id).then(res => {
          const { chat } = res
          if (!chat || !chat.chatUId) {
            dispatch(push({ pathname: "chat" }))
            return
          }
          dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: res
          })
          mixpanelTrack(`User page view - message`, {})
          dispatch(push(`/chat?chatid=${chat.chatUId}`))
        })
    }

    onUserEditProfile = () => {
        const { dispatch, fullview } = this.props
        fullview.open && dispatch(closeFullview())

        mixpanelTrack('My page view edit profile', {})
        goToEditProfile(dispatch)
    }

    onCheckGanttChangeDate = (date) => {
        const {searchPath} = this.props
        if(!date) return true
        if(!searchPath?.includes?.('gantt')) return true

        const allow = allowChangeProjectDate(date)
        return allow
    }

    ganttChangeDate = ({schedule, id}) => {  
        const scheduleInfo = {}
        scheduleInfo.scheduleUpdatePayload = schedule
        scheduleInfo.id = id
        CheckEditDate.scheduleInfo = scheduleInfo

        CheckEditDate.onShowConfirm()
        return false
    }
    goToSignedItems = () => {
        this.props.dispatch({
            type: 'SET_CURRENT_SAVED_REPORT',
            currentSavedReport: {
                key: 2,
                selected: true
            }
        })
        this.props.dispatch(selectDrawerNavigationOption(myPageContext.tasks.id))
        this.props.dispatch({ type: SEARCH_QUERY_UPDATE, query: { confirmed: true} })
        this.props.dispatch(push({ pathname: '/mypage/tasks', search: '?reportId=2' }))
    }

	onNewGroup = () => {
        dispatch({
            type: 'TOGGLE_CREATENEW_MODAL',
            isFocused: false
        })
        dispatch(push({ pathname: '/creategroup' }))
    }

    render() {
        const { children, classes, location, profileData, showFullHightSreen, userData } = this.props;
        // const muiTheme = createMuiTheme(
        //     getTheme(userData.profileData.org.brandingColor)
        // );
        const childrenClass = this.getChildrenClass();
    
        const callbacks = {
            onDocumentClick: () => { },
            onUserClick: this.goToUser,
            ganttChangeDate: this.ganttChangeDate,
            onCheckGanttChangeDate: this.onCheckGanttChangeDate,
            onGroupClick: this._goToCollection,
            onUpdateClick: this.updateData(),
            onOpenHashtag: this.onOpenHashtag,
            onOpenLink: this.onOpenLink,
            onUserEditProfile: this.onUserEditProfile,
            onUserMessage: this.onUserMessage,
            goToSignedItems: this.goToSignedItems,
			onNewGroup: this.onNewGroup,
        }
        return (
            <div data-intrcm-id="wrapper_h0jevvjq" className={classes.root}>
                <MuiThemeProvider theme={this.state.theme} >
                    <Header location={location} />
                    <DropZone>
                        <Grid container wrap='nowrap' className={classnames([classes.container, (!showFullHightSreen && classes.defaultContainer),((this.isKanban() || this.isTableGroup() || this.isGantt() || this.isHomeGroup()) && classes.kanbanContent ), (this.isLibrary() || this.isOngoing() || this.isOrgSch() && classes.defaultContainerOffsetToolbar), (this.isAdmin() && classes.defaultContainerAdmin)])}>
                            <div data-intrcm-id="wrapper_831asdge" id={'main_feed_scroll'} className={classnames(childrenClass, classes.content)} ref={(ref) => this.refWrapper = ref}>
                                {React.Children.map(children, child =>
                                    React.cloneElement(child, {
                                        wrapperRef: this.refWrapper
                                    })
                                )}
                            </div>
                            {/* <Drawers /> */}

                            <UserProfileModal open={!profileData.tag || profileData?.status === 1} onUserProfileDataUpdate={this.handleUserProfileUpdate} />
                        </Grid>
                    </DropZone>
                    <InputFullViewModal />
                    <ConfirmCloseModal />
                    <ItemFullViewModal />
                    {/* <GroupDialog /> */}
                    <StoriesWindow />
                    <YouNeedToUpdateSubscriptionWrapper />
                    {/* <SnackBarOldUi /> */}
                    {/* newUiStore */}
                    {this.allowRenderNewUI() && <NewUiWrapper store={store} callbacks={{}}>
                        <SetAggregationsData />
                    </NewUiWrapper>}
                    {this.allowRenderNewUI() && <NewUiWrapper store={store} callbacks={{goToSignedItems: this.goToSignedItems}}>
                        <SnackbarNewUi />
                    </NewUiWrapper>}
                    {this.allowRenderNewUI() &&<NewUiWrapper store={store} callbacks={callbacks}>
                        <PreviewDialog />
                    </NewUiWrapper>}
                    {this.allowRenderNewUI() &&<NewUiWrapper store={store} callbacks={callbacks}>
                        <NewUiFullViewDialog />
                    </NewUiWrapper>}
                    {this.allowRenderNewUI() &&<NewUiWrapper store={store} callbacks={callbacks}>
                        <InputDialog />
                    </NewUiWrapper>}
                    {this.allowRenderNewUI() &&<NewUiWrapper store={store} callbacks={{}}>
                        <CheckInitialPageAC />
                    </NewUiWrapper>}
                    <UpgradeFromFreeModal />
                    <UnsupportedResolution isOpen={true} />
                    <NotificationsSnackBar />
                    <TemplateInitModal location={location} />
                    <VideoChatWindow />
                </MuiThemeProvider>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    userUid: state.userData?.profileData?.auth0_id ?? '',
    searchQuery: state.search,
    userData: state.userData,
    profileData: state.userData.profileData,
    users: state.globalData.users,
    cloudfront: state.globalData.cloudfront,
    cloudfrontAdditional: state.globalData.cloudfrontAdditional,
    openCollections: state.globalData.openCollections,
    collections: state.userData.collections,
    subscription: state.globalData.currentSubscription,
    fullview: state.fullview,
    ...state.wrapper,
    notifications: state.userData.notifications,
    searchPath: location.href,
    activeUsers: state.globalData.activeUsers ?? [],
    needCloseGroup: !!state.group?.isOpen || !!state.group?.item,
    rehydrated: state.globalData.rehydrated,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withErrorCatcher(),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Wrapper);