import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { Link,browserHistory } from 'react-router';
//import SearchBar from '../search/searchBar';
// import { Grid, Col, Row, Button, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Navigation} from 'react-bootstrap';
//  import logo from '../../logo.svg';
//  import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
//import './App.css';
import './messages.scss';





class NotFound extends Component {
  constructor(props, context) {
    super(props, context);


    this.state = {

      searchQuery: this.props.location.query.searchquery

    };


  };

  handleChange = (searchQuery) => {
    // Go to /some/path.
    browserHistory.push('/search?searchquery=' + searchQuery);
  }
  render() {
    return (

      <div data-intrcm-id="notFound_8j0eh2f1">
        
        <div data-intrcm-id="notFound_yo2icalc">

          <div data-intrcm-id="notFound_gp4d81jl" id='wrapper'>

            <div data-intrcm-id="notFound_89k5hwyv" id="sidebar-wrapper">

            </div>

            <div data-intrcm-id="notFound_kyompo9h" id='page-content-wrapper' style={({ marginLeft: '-50px' })} className='container'>
            <div data-intrcm-id="notFound_ch5lc40l">
                    <br />
                    <br />
                    <br />
                    <span className="messageHeader">No results found… :(</span>
                    <br />
                    <br />
                    <br />
                    <span className="messageText">Sorry, but the page you are looking for has note been found. Click <Link to="/">here</Link> to go back home.</span>

                  </div>

              {/* <Row>
                <Col md={4}></Col>
                <Col xs={12} md={4} className="text-center"  >
                  
                </Col>
                <Col md={3}></Col>
              </Row> */}


            </div>

          </div>




        </div>
      </div>

    );
  }
}

export default NotFound;
