import { columnDurationRenderer } from "./lib/duration/DurationColumn";
import { columnPercentRenderer } from "./lib/percentDone/PercentColumn";
import { columnEndRendererCell, columnStartRendererCell } from "./lib/startEnd/startEndRender";
import { columnTitleRenderer } from "./lib/title/TitleColumn"
import zipcelx from 'zipcelx';
import {ganttSortHelpers} from '@tuqqi/web'

export const ganttColumns = (t) =>  [
    { id: "drag-col", sortable: false, type: 'drag', editor: false, width: 64, maxWidth: 34, minWidth: 34, draggable: false, resizable: false, },
    { id: "wbs-col", type: 'wbs', text: t("№"), editor: false, width: 34, maxWidth: 64, minWidth: 64, draggable: false, resizable: false, },
    { id: "name-col", type: 'name', invalidAction: 'revert', width: 250, text: t('Title'), renderer: columnTitleRenderer, editor: false },
    { id: "statusTask-col", type: 'statusTask', width: 200, text: t('Status'), editor: false, sortable: ganttSortHelpers.customGanttSorters.statusTask },
    { id: "resourceassign-col", type: 'resourceassign', width: 130, text: t("Assignees"), editor: false, sortable: ganttSortHelpers.customGanttSorters.assignedResource},
    { id: "startdate-col", type: 'startdate', text: t("Start Date"), width: 160, renderer: columnStartRendererCell, editor : false},
    { id: "enddate-col", type: 'enddate', text: t("End Date"), width: 160, renderer: columnEndRendererCell, editor : false},
    { id: "percentdone-col", type: "percentdone", text: t("Progress"), field : 'percentDone', showValue : true, width : 100, renderer: columnPercentRenderer, editor: false},

    { id: "duration-col", text: t("Duration"), type: 'duration', editor: false, renderer: columnDurationRenderer(t) },
    { id: "successor-col", sortable: false, text: t("Successors"), type: 'successor', width : 112, editor: false },
]

export const taskStoreObserverFields = ['wbsValue', 'expanded', 'name', 'duration', 'startDate', 'endDate', 'scheduleUpdate', 'assignedResource', 'deps', 'order','constraintType', 'statusTask']

const ganttLabelsFeature = {
    left: {
        field  : 'name',
        renderer: ({taskRecord}) => {
            if((taskRecord?.name ?? '').length <= 60) return taskRecord.name ?? ''
            return `${(taskRecord?.name ?? '').slice(0, 60)}...`
        },
        editor : false
    }
}

export const getBryntumFeatures = (t) => ({
    columnLines: true,
    labelsFeature: ganttLabelsFeature,
    rollupsFeature: { disabled: true },
    baselinesFeature: { disabled: true },
    progressLineFeature: { disabled: true },
    cellEditFeature: { disabled: false, editNextOnEnterPress: false, continueEditingOnCellClick: true },
    criticalPathsFeature: { disabled: true },
    projectLinesFeature: { disabled: true },
    taskEditFeature: { disabled: true },
    taskDragCreateFeature: { disabled: true },
    taskMenuFeature: { disabled: true },
    excelExporterFeature: {
        dateFormat: 'YYYY-MM-DD HH:mm',
        zipcelx,
    },
    // pdfExportFeature: {
    //     exportServer: "https://pdfexport.tuqqi.com",
    // },
    dependencyEditFeature: true,
    timeRangesFeature: { showCurrentTimeLine : true, name : t('Today'), currentDateFormat: 'DD/MM/YY, HH:mm', cls: 'current-date' },
    filterFeature: false,
    sortFeature: {
        multiSort: false,
        prioritizeColumns: false,
        toggleOnHeaderClick: true,
    },
    subGridConfigs: {
        locked: {
            flex : 3
        },
        normal: {
            flex : 4
        }
    },
})