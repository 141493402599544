import React from 'react';
import withErrorCatcher from '../components/Common/withErrorCatcher.js'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';


export default (tag,dispatch) => {
    return () => {
        dispatch({ type: 'SEARCH_QUERY', query: { query: tag, page: 0, isChannel: true } })
        dispatch({ type: 'FULLVIEW_CLOSE' })
        dispatch({ type: 'RESULT_CHANNEL_EMPTY' })
        dispatch({ type: 'DATA_UPDATE' });

        if(tag.startsWith('#')) {
            dispatch(push({ pathname: '/tag', search: '?searchquery=' + encodeURIComponent(tag) }))
        }  else if(tag.startsWith('@')){
            dispatch(push({ pathname: '/user', search: '?searchquery=' + encodeURIComponent(tag) }))
        }
        else {
            dispatch(push({ pathname: '/search', search: '?searchquery=' + encodeURIComponent(tag) }))
        }
        
    }
}
