import React, { useState } from 'react'
import { withStyles,Tooltip, } from '@material-ui/core';
import { compose } from 'recompose';
import { themeConstants } from '../../../../theme';
import ExpandLefrDrawerMenuLeftIcon from '../../../components/Common/Icons/LeftDrawer/ExpandLefrDrawerMenuIcon';
import ExpandLefrDrawerMenuRigthIcon from '../../../components/Common/Icons/LeftDrawer/ExpandLefrDrawerMenuRigthIcon';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { connect } from 'react-redux';
import { createCustomTheme } from '../../../../utils/theming';
import { withTranslation } from 'react-i18next';
import { CollapseRight } from '../../../../icons/CollapseRight';


const styles = theme => ({
    drawerHeader: {
        position: 'fixed',
        zIndex: 1099
    },
    drawerHeaderBottom: {
        left: 8,
        bottom: 12,
    },
    drawerHeaderOpenedBottom: {
        left: themeConstants.navigationDrawerBigPXWidth - 36,
        bottom: 16,
    },
    drawerHeaderLeft: {
        left: themeConstants.navigationDrawerWidth - 12,
        top: 90,
    },
    drawerHeaderRight: {
        top: 0,
        position: 'relative',
        left: 17,
    },
    drawerHeaderRightTop: {
        top: 75,
        position: 'fixed',
        left: 30,
    },
    icon: {
        color:  "#737373",
        '&:hover': {
            color: theme.customColors.primary.b300,
        },
    },
    tooltipPX: {
        marginTop: 38
    },
    tooltipPXOffset: {
        marginTop: 0
    },
    tooltip: {
        borderRadius: 6,
        fontSize: 12,
        backgroundColor: '#4A4A4F',
        padding: '4px 8px',
        borderRadius: 4,
        fontfamily: 'Rubik',
        lineHeight: '16px',
    },
    iconDrawerLeftHolder: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        border: '1px solid #D7D7DB',
        borderRadius: '100%',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            border: `1px solid ${theme.customColors.primary.b300}`,
        },
    },
    iconDrawerLeftHolderPX: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 34,
        height: 34,
    },
    iconPxRotate: {
        transform: 'rotate(-180deg)',
    },
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -3,
        marginTop: -3.5,
        transform: 'rotate(-45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
})

const useTooltipOffset = (pathname) => {
    return pathname.includes("kanban") ||
    pathname.includes("feed") ||
    pathname.includes("map") ||
    pathname.includes("dashboard") ||
    pathname.includes("home")
}

const DrawerSwitcherComponent = ({onClick, isDrawerOpen, classes, isFromTags = false, isFromSearch = false,profileData,t, pxSwitcher, pathname}) => {
    const [isHoverOn, setIsHoverOn] = useState(false)
    const [isHiddenTooltip,  setIsHiddenTooltip] = useState(false)
    const themeOption = profileData.org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)
    const defaultSvgColor = pxSwitcher ? "#6B6F7A" : '#737373'

    const drawerHolderClass = 
    pxSwitcher ?
    isDrawerOpen ? `${classes.drawerHeader} ${classes.drawerHeaderOpenedBottom} ${classes.iconPxRotate}` : `${classes.drawerHeader} ${classes.drawerHeaderBottom}` :
    isDrawerOpen ? `${classes.drawerHeader} ${classes.drawerHeaderLeft}` : `${classes.drawerHeader} ${isFromTags || isFromSearch ? classes.drawerHeaderRightTop : classes.drawerHeaderRight}`

    const IconComponent = pxSwitcher ? 
        isDrawerOpen ? <div data-intrcm-id="DrawerSwitcher_fw58ps6i" className={pxSwitcher ? classes.iconDrawerLeftHolderPX : classes.iconDrawerLeftHolder}><CollapseRight  color={defaultSvgColor}  /></div> :<div data-intrcm-id="DrawerSwitcher_jnath2wd" className={pxSwitcher ? classes.iconDrawerLeftHolderPX : classes.iconDrawerLeftHolder}> <CollapseRight color={defaultSvgColor}   /></div> : 
        isDrawerOpen ? <div data-intrcm-id="DrawerSwitcher_fw58ps6i" className={pxSwitcher ? classes.iconDrawerLeftHolderPX : classes.iconDrawerLeftHolder}><ExpandLefrDrawerMenuLeftIcon  color={isHoverOn ? theme.primary.b300 : defaultSvgColor}  /></div> :<div data-intrcm-id="DrawerSwitcher_jnath2wd" className={pxSwitcher ? classes.iconDrawerLeftHolderPX : classes.iconDrawerLeftHolder}> <ExpandLefrDrawerMenuRigthIcon color={isHoverOn ? theme.primary.b300 : defaultSvgColor}   /></div>
    return(
       
            <div data-intrcm-id="DrawerSwitcher_itd9r7qk" className={drawerHolderClass} onClick={() => {
                 onClick(!isDrawerOpen)
                 setIsHiddenTooltip(true)
            }}   
                onMouseEnter={() => {
                    if(isDrawerOpen) return
                     setIsHoverOn(true)
                     setIsHiddenTooltip(false)
                }}
                onMouseLeave={() => setIsHoverOn(false)}>
                    {!isHiddenTooltip ?  <Tooltip
                        placement="right" 
                        disableHoverListener={pxSwitcher ? isDrawerOpen : false}
                        title={<>
                        { isDrawerOpen ? t('Hide menu') :  t('Show menu') }
                        <div data-intrcm-id="DrawerSwitcher_1yveqy9z" className={classes.triangle}></div>
                        </> }                    
                        classes={{tooltip: `${classes.tooltip} ${pxSwitcher ? `${classes.tooltipPX} ${useTooltipOffset(pathname) ? classes.tooltipPXOffset : ""}` : ""}`}}>
                {IconComponent} 
               
                </Tooltip>
                :   <>
                {IconComponent} 
                </>}
            </div>
     
    )
}


const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    pathname: state?.wrapper?.pathname ?? '',

})

export const DrawerSwitcherNew = compose(
    withStyles(styles, { withTheme: true }),
    withTranslation('groupDrawerTranslate'),
    withErrorCatcher("Failed loading Group Navigation, Please Contact Tuqqi Support"),
    connect(mapStateToProps)
)(DrawerSwitcherComponent);