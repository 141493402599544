import { ADD_RECENT_GROUP, ADD_RECENT_ITEM, ADD_RECENT_USER, REMOVE_RECENT_GROUP, REMOVE_RECENT_ITEM, REMOVE_RECENT_USER } from "../actionTypes/actionTypes";
import { INIT_SEARCH_FAVOURITES_GROUPS, INIT_LANDING_USERS, INIT_SEARCH_MANAGED_GROUPS, INIT_SEARCH_SUBSCRIBED_GROUPS, INIT_SEARCH_SUBSCRIBED_USERS, MARK_STARRED_GROUP, UNMARK_STARRED_GROUP, ADD_RECENT_REQUEST, UPDATE_RECENT_REQUEST_TIMESTAMP } from "../actionTypes/searchNavigationTypes";

export const initLandingUsers = (users) => ({ 
    type: INIT_LANDING_USERS, 
    users,
})

export const initSearchSubscribedUsers = (users, tags) => ({ 
    type: INIT_SEARCH_SUBSCRIBED_USERS, 
    users,
    tags
})

export const initSearchSubscribedGroups = (groups) => ({ 
    type: INIT_SEARCH_SUBSCRIBED_GROUPS, 
    groups,
})

export const initSearchManagedGroups = (groups) => ({ 
    type: INIT_SEARCH_MANAGED_GROUPS, 
    groups,
})

export const initSearchFavouritesGroups = (groups) => ({ 
    type: INIT_SEARCH_FAVOURITES_GROUPS, 
    groups,
})

export const markStarredGroup = (collectionUid) => ({ 
    type: MARK_STARRED_GROUP, 
    collectionUid,
})

export const unmarkStarredGroup = (collectionUid) => ({ 
    type: UNMARK_STARRED_GROUP, 
    collectionUid,
})

export const addRecentRequest = (query) => ({
    type: ADD_RECENT_REQUEST,
    query
})
export const updateRecentRequestTimestamp = (query) => ({
    type: UPDATE_RECENT_REQUEST_TIMESTAMP,
    query
})


export const addRecentGroup = (uid) => ({
    type: ADD_RECENT_GROUP,
    uid
})

export const addRecentItem = (uid) => ({
    type: ADD_RECENT_ITEM,
    uid
})

export const addRecentUser = (uid) => ({
    type: ADD_RECENT_USER,
    uid
})

export const removeRecentGroup = (uid) => ({
    type: REMOVE_RECENT_GROUP,
    uid
})

export const removeRecentUser = (uid) => ({
    type: REMOVE_RECENT_USER,
    uid
})

export const removeRecentItem = (uid) => ({
    type: REMOVE_RECENT_ITEM,
    uid
})