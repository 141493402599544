

import React from 'react';

const ExpandLefrDrawerMenuIcon = (props) => {
    const { color } = props
    
    return <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10 4L6 8L10 12" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export default ExpandLefrDrawerMenuIcon
