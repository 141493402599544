import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { openInput } from '../../../../actions/inputActions.js';
import CategoryFeedView from '../../../components/App/CategoryFeedView.js';
import ResultsList from '../../../components/App/PageComponents/ResultsList.js';
import Spinner from '../../../components/Common/spinner';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import FeedDataItem from '../../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem.js';
import FeedInput from '../../../components/Input/FeedInput';
import GroupFeedPlaceholder from '../../../components/Placeholders/GroupFeedPlaceholder.js';
// import SponsoredContentWidget from '../../../components/Widgets/RightWidgets/Feed/sponsoredContent/SponsoredContentWidget.js';
import { filterData, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../../search/searchUtils';
import { IsQueryExistInCollections, loadGroupInfo, shouldAutoOpen } from './../groupUtils';
import FileWidget from './GroupFeedRightWidget/FileWidget.js';
import MediaWidget from './GroupFeedRightWidget/MediaWidget.js';
import { FULLVIEW_CLOSE } from '../../../../actionTypes/actionTypes';
import TrendingWidget from './GroupFeedRightWidget/TrendingWidget.js';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common'

const styles = theme => ({
    feedAndWidgetContainer: {

        marginTop: 16,
        "margin": "0 auto",
        "width": "fit-content"
    },
    container: {
        [theme.breakpoints.down(1680)]: {

            marginRight: 0,
        },
        [theme.breakpoints.up(1680)]: {
            marginRight: 360
        },
        height: '100%',
        padding: '0px 32px',
        marginLeft: 360
    },
    feedContainer: {
        marginRight: 32,
        width: 512,
        flex: '0 0 auto'
    }
});

class GroupFeed extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    componentDidMount() {
        const { urlQuery, searchQuery, dispatch, userData } = this.props;

        // todo bad check 
        // 1. what if urlQuery is undefind ?
        // 2. what if this class load before the urlQuery init in the groupView class ?
        if (urlQuery !== '') {
            dispatch({ type: 'DATA_UPDATE' })
        } else {
            dispatch(push('feed'));
        }
    }

    onOpenInput = (defaultOpen) => () => {
        const { dispatch, groupInfo, inputMappings } = this.props

        if (shouldAutoOpen(groupInfo)) {
            const dataType = groupInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })
                const groupUid = groupInfo?.collectionGroup?.groupUid ?? '' 
                const permissions = !!groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                this.redirectToInput(groupInfo.settings.allowedInputs[0])
            }
        } else {
            defaultOpen()
        }
    }

    redirectToInput = (dataType) => {
        const { inputMappings, oldUiInput, userData, searchQuery, dispatch } = this.props
        openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps);
            this.loadGroupInfo(nextProps);
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore(nextProps);
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    renderFeedInput() {
        const { userData, groupInfo } = this.props;

        const isUserMemberInCollection = userData.collections.some(collection => collection.collectionUid === groupInfo.collectionUid)

        return isUserMemberInCollection ? <FeedInput key={-1} onClick={this.onOpenInput} /> : null
    }


    renderItem = (idx, result) => {
        return (
            <FeedDataItem
                key={result.Key}
                docId={result.Key}
                result={result.Value.Source}
            />
        )
    }
    shouldShowWidget() {
        const { dataTypes } = this.props.search;

        let show = false
        if (!dataTypes.length) {
            show = true
        } else if (dataTypes.some(dataType =>
            dataType !== 'file' &&
            dataType !== 'media')) {
            show = true
        }

        return show
    }

    getWidgets = () => {
        let widgets = [<TrendingWidget relatedTags={this.state.relatedTags} />]

        if (this.shouldShowWidget()) {
            widgets.push(<FileWidget />)
            widgets.push(<MediaWidget />)
        }

        return widgets
    }


    render() {
        const { classes, searchQuery, filters, userData, groupInfo } = this.props;
        const { results, isLoading, isLoadingMore, hasMore, relatedTags } = this.state;
        let content;

        if (isLoading) {
            return <Spinner isAbsolutePosition />
        }

        return <CategoryFeedView
            rightWidgets={this.getWidgets()}
        >

            <ResultsList
                results={results}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                hasMore={hasMore}
                loadMore={this.loadMoreQuery}
                isSearch={true}
                showInput
                Header={this.renderFeedInput()}
                renderItem={this.renderItem} />
            {/* {results.length < 4 && !isLoading ? <GroupFeedPlaceholder orgType={userData.profileData.org.TemplateName} /> : null} */}

        </CategoryFeedView>


    }

}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...state.services,
    oldUiData: state.oldUiData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupFeed);