// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import '../../../../../new-ui/externalInputDescriptionStyleFix.css'
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
const FroalaEditorComponent = require('react-froala-wysiwyg').default;
const Froalaeditor = require('froala-editor')

const resources = {
    root: '/resources',
    fileTypesIcons: '/resources/file-types',
    froalaIcons: '/resources/froala'
}

const styles = (theme) => ({
    desc: {
        color: '#979AAA',
        padding: 0,
        fontSize: '1rem',
        fontFamily: 'Rubik',
        lineHeight: 1,
        transform: 'translate(0, 1.5px) scale(0.75)',
        transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transformOrigin: 'top left',
        marginBottom: 5,
        marginTop: 10
    },
    textHelper: {
        color: '#979AAA',
        padding: 0,
        fontSize: '1rem',
        fontFamily: 'Rubik',
        lineHeight: 1,
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        marginTop: 5
    },
})

var changeDirection = function (this1, dir, align) {
    // Wrap block tags.
    this1.selection.save();
    this1.html.wrap(true, true, true, true);
    this1.selection.restore();

    // Get blocks.
    var elements = this1.selection.blocks();

    // Save selection to restore it later.
    this1.selection.save();

    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element != this1.el) {
            this1.$(element)
                .css('direction', dir)
                .css('text-align', align)
                .removeClass('fr-temp-div');
        }
    }

    // Unwrap temp divs.
    this1.html.unwrap();

    // Restore selection.
    this1.selection.restore();
}

function __EditDescription(props) {
    const { value, onChange, token, t, classes, isFinishMessage = false } = props
    const ref = useRef(null)
    useEffect(() => {
        if (ref && ref.current) {
            Froalaeditor.DefineIcon('rightToLeft', { SRC: `${resources.froalaIcons}/RTL.svg`, template: 'image', ALT: 'Rtl' });
            Froalaeditor.RegisterCommand('rightToLeft', {
                title: 'RTL',
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                    changeDirection.apply(this, ['rtl', 'right']);
                }
            })

            Froalaeditor.DefineIcon('leftToRight', { SRC: `${resources.froalaIcons}/LTR.svg`, template: 'image', ALT: 'Ltr' });
            Froalaeditor.RegisterCommand('leftToRight', {
                title: 'LTR',
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                    changeDirection.apply(this, ['ltr', 'left']);
                }
            })
        }
    }, [ref])


    let buttons = ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'clearFormatting'];

    return ( <>
    <div data-intrcm-id="EditDescription_yjv13dq1" className={classes.desc}>{!isFinishMessage ? t("Form Description *") : t("Customize the submitted message")}</div>
    <FroalaEditorComponent ref={ref}
        model={value}
        onModelChange={(newHtml) => onChange && onChange(newHtml)}
        config={{
            key: '9KH3cC4D4E4E3B2C2zsH-7xD-17bF6nablA3C11A8C6D2B4C4G2C3J4==',
            autofocus: true,
            typingTimer: 0,
            zIndex: 2501,
            heightMin: 230,
            widthMin: 960,
            linkAlwaysBlank: true,
            requestHeaders: {
                Authorization: 'bearer ' + token
            },
            toolbarButtons: {
                'moreText': {
                    'buttons': buttons,
                    'buttonsVisible': 4
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'leftToRight', 'rightToLeft', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR'],
                    'buttonsVisible': 2
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                },
            }
        }} />
        <div data-intrcm-id="EditDescription_0fie0qyn" className={classes.textHelper} style={{marginTop: !isFinishMessage ? 5 : 8,marginBottom: !isFinishMessage ? 0 : 8}}>{!isFinishMessage ? t("What is the form purpose") : t("This will be the finish message of your Form")}</div>
        </>
    )
}

const mapStateTotProps = state => ({
    token: state.data.auth.token
})

export const EditDescription = withStyles(styles, {withTheme: true})(connect(mapStateTotProps)(__EditDescription))