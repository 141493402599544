import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'

import wrapper from './wrapperReducer'
import oldUiInput from './inputReducer'
// import viewOptions from './resultsViewOptionsReduce'
import snackBar from './snackBarReducers'
import notificationSnackBar from './notificationsSnackBarReducer'
import userData from './userReducer'
// import userProfile from './userProfileReducer'
import group from './groupReducer'
import services from './servicesReducer'
import oldUiData from './dataReducer'
import globalData from './globalDataReducer'
import customMappings from './customMappingReducer'
import customInputMappingReducer from './customInputMappingReducer'

import search from './searchReducer'
// import view from './viewReducer'
import resultReducer from './resultReducer'
// import eventReducer from './eventReducer'
// import libraryReducer from './libraryReducer'
import collectionReducer from './collectionReducer'
import fullView from './fullViewReducer'
import topicView from './topicViewReducer'
import confirmDialogReducer from './confirmDialogReducer'
import previewItem from "./previewItemReducer";
import inputRelatedDocs from './inputRelatedDocsReducer'
import videoChat from './videoChatReducers'
import upgradeFromFreeReducer from './upgradeFromFreeReducer'
import LocalFilterReducer from './localFilterReducer';
// import visibilityFilter from './visibilityFilter'
import { newUiReducers } from '@tuqqi/web';
import authModule from './authModule'
import upgradeSubscription from './upgradeSubscriptionReducer'
import timeTracker from './TimeTrackerReducer';

import groupsNavigationReducer from './groupsNavigationReducer'
import searchNavigationReducer from './searchNavigationReducer'
import feedNavigationReducer from './feedNavigation'
import { launchpadNavigationReducer } from './launchpadNavigationReducer';
import { userNavigationReducer } from './userNavigationReducer';
import { notificationsModalReducer } from './notificationsModalReducer';
import exportTableDataTypeReducer from './exportTableDataTypeReducer'
import drawerNavigationReducer from './drawerNavigationReducer'
import chatRoomReducer from './chatRoomReducer'
import myTodoReducer from './myTodoReducer'
import {actionsCenterReducer} from './actionsCenterReducer'
import { chatsModalReducer } from './chatsModalReducer';
import { launchpadNavigationOrgMenuReducer } from './launchpadNavigationOrgMenuReducer';
import { createNewModalReducer } from './CreateNewModalReducer';


const tuqqiReducers = combineReducers({
  wrapper,
  oldUiInput,
  // viewOptions,
  snackBar,
  notificationSnackBar,
  userData,
  oldUiData,
  services,
  globalData,
  // userProfile,
  customMappings,
  customInputMapping: customInputMappingReducer,
  routing: routerReducer,
  search: search,
  form: formReducer,
  results: resultReducer,
  // view: view,
  group,
  // event: eventReducer, // TODO: when we will work on events again we will need to return this, probably?
  // library: libraryReducer,
  collection: collectionReducer,
  confirmDialog: confirmDialogReducer,
  fullView: fullView,
  topicView,
  previewItem,
  inputRelatedDocs,
  videoChatWindow: videoChat,
  upgradeFromFree: upgradeFromFreeReducer,
  localFilter: LocalFilterReducer,
  notificationsModal: notificationsModalReducer,
  createNewModal: createNewModalReducer,
  authModule,
  upgradeSubscription,
  groupsNavigation: groupsNavigationReducer,
  searchNavigation: searchNavigationReducer,
  feedNavigation: feedNavigationReducer,
  drawerNavigation: drawerNavigationReducer,
  chatRoom: chatRoomReducer,
  exportTableDataType: exportTableDataTypeReducer,
  myTodo: myTodoReducer,
  timeTracker: timeTracker,
  launchpadNavigation: launchpadNavigationReducer,
  launchpadNavigationOrgMenu: launchpadNavigationOrgMenuReducer,
  actionsCenter: actionsCenterReducer,
  userNavigation: userNavigationReducer,
  chatsModal: chatsModalReducer,
  ...newUiReducers,
})


export default tuqqiReducers
export const needPersistReducers = [
  'myTodo',
  'drawerNavigation',
  'data',
  'globalData',
  'userData',
  'wrapper',
  'snackbar',
  'notificationSnackBar',
  'oldUiData',
  'customMappings',
  'customInputMapping',
  'routing',
  'form',
  // 'results',
  'group',
  // 'collection',
  'confirmDialog',
  'fullView',
  'topicView',
  // 'previewItem',
  // 'inputRelatedDocs',
  // 'videoChatWindow',
  'upgradeFromFree',
  // 'localFilter',
  'feedItems',
  'snackbar',
  // 'input',
  'feedEvents',
  'feedTasks',
  'groupsNavigation',
  'searchNavigation',
  'feedNavigation',
  // 'actionsCenter',
  'launchpadNavigation'

  // 'timeTracker'
]