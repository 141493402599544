import { FULL_RESET_STORE } from "../actionTypes/actionTypes"
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = {
    isFocused: false,
    selectedCategory: -1,
    allowedOrgMenuItemsUids: [],
}

export const launchpadNavigationOrgMenuReducer = (state = initialState, action) => {
    switch(action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_CHAT:
        case ON_GO_TO_ITEM_NOTIF:
            return initialState
        case "FOCUS_LAUNCHPAD_ORG_CATEGORY":
            return { ...state, isFocused: true, selectedCategory: action.selectedCategory, allowedOrgMenuItemsUids: action.allowedOrgMenuItemsUids }
        case "UPDATE_LAUNCHPAD_ORG_CATEGORY":
            return { ...state, isFocused: true, selectedCategory: action.selectedCategory }
        case "BLUR_LAUNCHPAD_ORG_CATEGORY":
        case "BLUR_LAUNCHPAD":
        case "SET_LAUNCHPAD_PAGE_TYPE":
        case "TOGGLE_USER_MODAL":
        case "TOGGLE_CREATENEW_MODAL":
        case "TOGGLE_CHATS_MODAL":
        case "TOGGLE_NOTIFICATIONS_MODAL":
        case 'SEARCH_INPUT_FOCUSED': 
            return initialState
        default:
            return state
    }
}