import React from 'react';
import {withStyles} from '@material-ui/core';
import { compose } from 'recompose';

const styles = (theme) => ({
    icon: {
        position: 'absolute',
        zIndex:1,
        right: 47,
        top: -55
    },
})

const BusinessClassPlaneView = (props) => {
    const {classes, planeClasses} = props

    return (
        <img className={`${classes.icon} ${planeClasses}`} alt="plain" src={"/resources/subscription/airplane-business-class.svg"}/>
    )
}

export const BusinessClassPlane = compose(
    withStyles(styles, {withTheme: true}),
)(BusinessClassPlaneView)