import React, { Component, Children } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';


// Apply some reset
const styles = theme => ({
    container: {
        paddingTop: 15

    },

});

class SingleColumnView extends Component {

    
    render() {

        const { classes, leftWidgets, children, isFull, isDrower } = this.props;


        let gridSize = {
            lg:8,
            xl:7,
            sm:9
        }

        if(isFull){
            gridSize = {
                xs:12,
                lg:12,
                sm:12
            }
        }

        return <Grid container spacing={16}
            spacing={24} justify="center">
            <Grid item {...gridSize} >
                {children}
            </Grid>

        </Grid>
    }

}



export default withStyles(styles)(SingleColumnView);
