import React from 'react';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js'
import { withStyles, FormControl, FormControlLabel, Switch } from '@material-ui/core';

const styles = theme => ({
	formControl: {
        margin: '0 5px',
        display: 'flex'
	}
})

class FormControlLabelSwitch extends React.Component {
    render() {
        const { classes, label, onChange, checked, disabled } = this.props;
        
        return (<FormControl className={classes.formControl} disabled={disabled} >
            <FormControlLabel
                control={
                    <Switch checked={checked}
                        onChange={onChange}
                    />}
                label={label}
            />
        </FormControl>)
	}
}

export default withStyles(styles)(FormControlLabelSwitch);