import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import WidgetMultipleOption from '../../../Common/WidgetOptions/WidgetMultipleOption';
import { applySearchQuery } from '../../../../views/search/searchUtils';
import { withMixpanel } from '../../../../components/Common/withMixpanel';

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginLeft: 8,
        marginBottom: 8,
    }
});

const getOptions = (t) => ([
    {
        label: t("Unviewed items"),
        key: 'isUnseen',
        selected: false,
    },
    {
        label: t("My items"),
        key: 'isMyItem',
        selected: false,
    },
    {
        label: t("Is Trending"),
        key: 'isTrending',
        selected: false,
    },
    {
        label: t("Must confirm"),
        key: 'mustread',
        selected: false,
    },
    {
        label: t("Confirmed"),
        key: 'confirmed',
        selected: false,
    }
])

const AdditionalOptionsWidget = (props) => {
    const { classes, t, query, searchQuery, dispatch, mixpanelTrack } = props;
    const [options, setOptions] = useState(getOptions(t))

    const onOptionClick = (key) => {
        mixpanelTrack(`Additional options widget`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            return o
        })
        setOptions(newOptions)
        const additionalQuery = getAdditionalQuery(newOptions) 
        const newQuery = {...searchQuery, ...additionalQuery}
        applySearchQuery(newQuery, dispatch)
    }

    useEffect(() => {
        setOptions(getOptions(t))
    }, [query])

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="AdditionalOptionsWidget_i14v9c4a" className={classes.containerInner}>
                <div data-intrcm-id="AdditionalOptionsWidget_kktzxqwb" className={classes.titleSection}>
                        <div data-intrcm-id="AdditionalOptionsWidget_u3b197ju" className={classes.title}>{t("Additional")}</div>
                </div>
                <div data-intrcm-id="AdditionalOptionsWidget_18dvjhth" className={classes.options}>
                    {options.map(o => (
                        <WidgetMultipleOption optionStyle={classes.option} key={o.key} option={o} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    query: state.search.query,
    searchQuery: state.search,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps),
)(AdditionalOptionsWidget);

const getAdditionalQuery = (options = []) => {
    return options.reduce((accum, o) => {
        return {...accum, [o.key]: o.selected}
    }, {})
}
