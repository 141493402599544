import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import NoResultFound from '../../components/Common/noResultFound.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { filterData, loadData, loadMore, loadMoreQuery } from "../search/searchUtils";
import { buildTable, loadGroupInfo } from './groupUtils';
import TableView from "./table/TableView";
import { defaultDataTypes as getDefaultDataTypes } from '../../components/Common/Utils/defaultDataTypes';
import { themeConstants } from '../../../theme.js';
import { setShowOldTable, useNewTableOrig } from '../../../utils/useNewTable.js';



// Apply some reset
const styles = theme => ({

    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    container: {
        height: '100%',
        // maxHeight: 'calc(100vh - 50px)',
        overflow: 'hidden',
        marginLeft: themeConstants.navigationDrawerWidth
    },
    container1: {
        height: '100%',
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'hidden',
        marginLeft: themeConstants.navigationDrawerSmallWidth
    },
    noResultFound: {
        marginLeft: themeConstants.navigationDrawerWidth
    }
});
export class GroupTableView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            feedPage: 0,
            results: [],
            assigned: [],
            unassigned: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            hadEmptyResult: false,
            data: { lanes: [] },
            openCardDialog: false,
            view: {},
            groupInfoLoaded: false,
            openCardDialog: false,
            docCardClicked: null,
            tableItems: [],
            searchResultCount: 0,
            firstAllResultsLength: {
                init: false,
                value: 0,
            },
            dataTypes: [],
        }


        // Bind the utils
        this.loadData = loadData.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this)
        this.loadMoreQuery = loadMoreQuery.bind(this)

        this.buildTable = buildTable.bind(this)
        this.filterData = filterData.bind(this)
        this.loadMore = loadMore.bind(this)
    }

    calcDataTypes = (resultCount) => {
        const {customInputs, collectionInfo} = this.props
        const allowedInputs = collectionInfo?.settings?.allowedInputs ?? []
        const allInputsAllowed = collectionInfo?.allInputsAllowed ?? true
        const defaultDataTypes = getDefaultDataTypes()
        const allDataTypes = [...defaultDataTypes, ...customInputs]
            .map((m) => ({
                Key: m.customDataMapping.dataTypeFilter,
                DocCount: 0
            }))
        if(allInputsAllowed) return [{ Key: 'All', DocCount: resultCount }, ...allDataTypes]
    
        const dataTypes = allDataTypes.filter((d) => allowedInputs.includes(d.Key))
        return [{ Key: 'All', DocCount: resultCount }, ...dataTypes]
    }


    initTable = () => {
        const tableItems = this.buildTable()
        this.setState({ tableItems, isLoading: false });
    }
    isLocalFilterPresented = (props) => {
        const { localFilter = {} } = props
        return Object.keys(localFilter.filters).length > 0
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.setState({ isLoading: true });
            const withLocalFilter = this.isLocalFilterPresented(nextProps)

            Promise.all([this.loadData(nextProps.oldUiData.dataUpdate, nextProps, null, true, withLocalFilter), this.loadGroupInfo(nextProps)])
                .then(this.initTable)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true });
            const withLocalFilter = this.isLocalFilterPresented(nextProps)
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps, true, withLocalFilter)
                .then(this.initTable);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore(nextProps, null, true).then(this.initTable);
        }
    }

    componentDidMount() {
        const { urlQuery, searchQuery, dispatch, userData } = this.props;
        this.loadReports();
        if (urlQuery !== '') {
            dispatch({ type: SEARCH_QUERY_UPDATE, query: { size: 50 } })
            dispatch({ type: DATA_UPDATE })
        } else {
            dispatch(push('feed'));
        }
    }


    createData(doc) {
        const { id, title, members, dataType, itemState, docTimestamp } = doc;

        return { id, title, members, dataType, itemState, docTimestamp: Date.parse(docTimestamp) };
    }

    loadReports = () => {
        const { userSettingsService, collectionInfo } = this.props;
        userSettingsService.getUserSavedReportsForMyPage().then((result) => {
            if (!result) return
            const savedReports = result.filter(r => r.collectionId === collectionInfo.id)
            this.props.dispatch({
                type: 'SET_SAVED_REPORTS',
                savedReports
            })
        })
    }

    saveReport = (reportName) => {
        const { userSettingsService, searchQuery, localFilter, collectionInfo } = this.props;
        const {selectedFieldSources, filters = {}} = localFilter
        let resQuery = searchQuery
        resQuery.filters = filters
        if(!!selectedFieldSources && !!selectedFieldSources.length) {
            resQuery = {
                query: searchQuery,
                selectedFieldSources,
                filters,
            }
        }
        const report = {
            reportName: reportName,
            reportJSON: JSON.stringify(resQuery),
            collectionId: collectionInfo.id
        }

        userSettingsService.saveUserReportForMyPage(report).then((result) => {
            this.loadReports();
        })

    }

    showNewTable = () => {
        setShowOldTable(false, this.props.dispatch)
    }

    render() {
        const { isLoading, searchResultCount, isLoadingMore, firstAllResultsLength } = this.state;
        const { classes, t, isDrawerOpen, savedReports} = this.props;

        // if (!isLoading && !this.state.results.length) {
        //     return <NoResultFound classes={{ container: classes.noResultFound }} />
        // }

        return (
            <CategoryViewOnlyLeftDrawer >
                {useNewTableOrig(this.props.orgUid) && <button style={{marginLeft: 100}} onClick={this.showNewTable}>Show new table</button>}
                <div data-intrcm-id="groupTableView_im0katho" className={isDrawerOpen ? classes.container : classes.container1}>
                    <TableView 
                        savedReports={savedReports}
                        saveReport={this.saveReport} 
                        firstAllResultsLength={firstAllResultsLength} 
                        isDrawerOpen={isDrawerOpen} 
                        isLoading={isLoading} 
                        results={this.state.results} 
                        searchResultCount={searchResultCount} 
                        loadMore={this.loadMoreQuery} 
                        isLoadingMore={isLoadingMore} />
                </div>
            </CategoryViewOnlyLeftDrawer>)
    }
}

const mapStateToProps = (state) => ({

    userData: state.userData,
    router: state.router,
    ...state.services,
    viewOptions: state.viewOptions,
    wrapper: state.wrapper,
    classifications: state.globalData.classifications,
    knowledgelevels: state.globalData?.knowledgelevels ?? [],
    globalData: state.globalData,
    collectionInfo: state.results.channel,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    oldUiData: state.oldUiData,
    searchQuery: state.search,
    localFilter: state.localFilter,
    savedReports: state.localFilter?.savedReports ?? [],
    customInputs: state.data.globalData.customInputMappings.data,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupTableView);

