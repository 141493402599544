
import { browserHistory } from 'react-router'
import fileDownload from 'react-file-download'


export default class CollaborationService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getComments(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/comments?docId=' + docId, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    getMustRead() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        return fetch(this.backendUrl + '/mustRead/user/', {
            method: 'GET',
            headers: myHeaders
        }).then(function (res) {
            return res.json()
        }).catch(e => {

        });
    }

    getActivities(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/activities?docId=' + docId, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    getDocumentActivityLogs(docId) {
        if (docId !== '') {
            return fetch(this.backendUrl + '/activities/document?docId=' + docId, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }


    addLink(link) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(`${this.backendUrl}/links?docId=${link.docId}&link=${link.link}`, {
            method: 'POST',
            headers: myHeaders
        }).then(function (res) {
            return res.json && res.json();
        });
    }

    deleteLinked(link) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(`${this.backendUrl}/links?docId=${link.docId}&link=${link.link}`, {
            method: 'DELETE',
            headers: myHeaders
        }).then(function (res) {
            return res.ok
        });
    }

    getTimeTrackingReport() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });
        return fetch(this.backendUrl + '/tasks/report-token', {
            method: 'GET',
            headers: myHeaders,
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    getCurrentTimeTracking() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(this.backendUrl + '/timetracking', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }



    startTimeTracking(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/timetracking/start?docId=' + docId, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    editTimeTracking(taskId, taskName) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(this.backendUrl + '/timetracking/update?taskId=' + taskId + '&taskName=' + taskName, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    stopTimeTracking() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });


        return fetch(this.backendUrl + '/timetracking/stop', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }


    getTasks(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/tasks?docId=' + docId, {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    addTask(task) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (task.docId !== '') {
            return fetch(this.backendUrl + '/tasks', {
                method: 'POST',
                body: JSON.stringify(task),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    updateTask(task) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/tasks', {
            method: 'PUT',
            body: JSON.stringify(task),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
    updateItemTask(docId, newTaskState) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/content/update-schedule?id=' + encodeURIComponent(docId), {
            method: 'POST',
            body: JSON.stringify({
                taskDueDateDone: newTaskState
            }),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    deleteTask(task) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/tasks', {
            method: 'DELETE',
            body: JSON.stringify(task),
            headers: myHeaders
        })
    }
    commentDocument(comment) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (comment.docId !== '') {
            return fetch(this.backendUrl + '/comments', {
                method: 'POST',
                body: JSON.stringify(comment),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    deleteComment(commentId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (commentId) {
            return fetch(this.backendUrl + '/comments?id=' + commentId, {
                method: 'DELETE',
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    upgradeComment(comment) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (comment) {
            return fetch(this.backendUrl + '/comments/upgrade', {
                method: 'POST',
                body: JSON.stringify(comment),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    downgradeComment(comment) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (comment) {
            return fetch(this.backendUrl + '/comments/downgrade', {
                method: 'POST',
                body: JSON.stringify(comment),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    chooseComment(comment) {
        const headers = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (comment) {
            return fetch(`${this.backendUrl}/comments/choose?commentId=${comment.key}&docId=${comment.docId}`, {
                method: 'POST',
                headers
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    tagDocument(tag, docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (tag !== '') {
            return fetch(this.backendUrl + '/tags/tag?tag=' + tag + '&documentId=' + docId, {
                method: 'GET',
                // body: form,
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    logClick(docId) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/click?docId=' + docId, {
                method: 'GET',
                // body: form,
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }

    }

    addChecklist(docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + `/checklist/add?docId=${docId}`, {
            method: 'POST',
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    updateChecklist(checklist) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/checklist/update', {
            method: 'PUT',
            body: JSON.stringify(checklist),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });

    }

    deleteChecklist(checklist) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/checklist/delete', {
            method: 'DELETE',
            body: JSON.stringify(checklist),
            headers: myHeaders
        })

    }

    remindUsersToReadItem(usersIds, document, docId) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var body = {
            UsersIds: usersIds,
            Document: document
        }

        return fetch(this.backendUrl + '/mustRead/remind/' + encodeURIComponent(docId), {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getReadTime(docId, document) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        if (docId !== '') {
            return fetch(this.backendUrl + '/mustRead/activities?docId=' + encodeURIComponent(docId), {
                method: 'PUT',
                body: JSON.stringify(document),
                headers: myHeaders
            }).then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
        }
    }

    // getGanttTasks() {
    //     var myHeaders = new Headers({
    //         'Authorization': 'Bearer ' + this.token,
    //     });
    //         return fetch(this.backendUrl + '/gantt', {
    //             method: 'GET',
    //             headers: myHeaders
    //         }).then(function (res) {
    //             if(res.status === 401){                                
                // console.log(res);
            //     this.onUnauthorized();
            // }
                        
            // return res.json();
    //         });
    // }

    getGanttTasks(searchQuery) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        var address = process.env.API_SERVER + '/search/gantt';

        return fetch(address, {
            method: 'POST',
            body: JSON.stringify(searchQuery),
            headers: myHeaders
        })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }

    updateTaskDependencies(docId, assignedResourse) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(this.backendUrl + '/content/update-resourse?id=' + encodeURIComponent(docId), {
            method: 'POST',
            body: JSON.stringify({
                taskDueDateDone: assignedResourse
            }),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
}

