import { DRAWER_TOGGLE, FULL_RESET_STORE, MAPPING_EDIT_MAPPING, MAPPING_EDIT_SOURCE, MAPPING_EDIT_CLASSIFICATION, MAPPING_EDIT_USER, MAPPING_EDIT_MAPPING_CHANGE, MAPPING_EDIT_MAPPING_ROW, MAPPING_EDIT_CUSTOM_FIELD_ROW, MAPPING_EDIT_CERTIFICATION } from '../actionTypes/actionTypes'

const getIndexOfDisplayMapping = (mappings, fieldId) => {
  for (var index = 0; index < mappings.length; index++) {
    var mapping = mappings[index];
    if (mapping)
      if (mapping.templateFieldId === fieldId)
        return index;

  }
}

const getIndexOfCustomFieldMapping = (mappings, fieldId) => {
  for (var index = 0; index < mappings.length; index++) {
    var mapping = mappings[index];
    if (mapping)
      if (mapping.id === fieldId)
        return index;

  }
}
const initialState = []
const wrapper = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE: 
      return initialState

    case MAPPING_EDIT_MAPPING:
      return {
        ...state,
        currentMapping: { ...action.currentMapping }
      }
    case MAPPING_EDIT_SOURCE:
      return {
        ...state,
        currentSource: { ...action.currentSource }
      }
      case MAPPING_EDIT_CERTIFICATION:
      return {
        ...state,
        currentCertification: { ...state.currentCertification, ...action.currentCertification }
      }
    case MAPPING_EDIT_CLASSIFICATION:
      return {
        ...state,
        currentClassification: { ...action.currentClassification }
      }
    case MAPPING_EDIT_USER:
      return {
        ...state,
        currentUser: { ...action.currentUser }
      }
    case MAPPING_EDIT_MAPPING_CHANGE:
      return {
        ...state,
        currentMapping: Object.assign({}, state.currentMapping, {

          ...action.mapping

        })
      }
    case MAPPING_EDIT_MAPPING_ROW:
      var templateArray = [...state.currentMapping.displayMappings];

      var index = getIndexOfDisplayMapping(templateArray, action.fieldId);
      if (index == null) {
        templateArray.push(action.row);
      } else {
        templateArray[index] = Object.assign({}, templateArray[index], {

          ...action.row

        })
      }

      return {
        ...state,
        currentMapping: Object.assign({}, state.currentMapping, {

          displayMappings: templateArray

        })
      }
    case MAPPING_EDIT_CUSTOM_FIELD_ROW:
      var templateArray = [...state.currentMapping.customFieldsMappings];

      // var index = getIndexOfCustomFieldMapping(templateArray, action.fieldId);
      // if (index == null) {
      //   templateArray.push(action.row);
      // } else {

      // }

      templateArray[action.fieldId] = Object.assign({}, templateArray[action.fieldId], {

        ...action.row

      })
      
      return {
        ...state,
        currentMapping: Object.assign({}, state.currentMapping, {

          customFieldsMappings: templateArray

        })
      }

    default:
      return state
  }
}

export default wrapper