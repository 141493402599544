import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

const DataTypeSelectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="DataTypeSelectionIcon__a" d="M.71 1.71L3.3 4.3c.39.39 1.02.39 1.41 0L7.3 1.71C7.93 1.08 7.48 0 6.59 0H1.41C.52 0 .08 1.08.71 1.71z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(8 10)">
            <mask id="DataTypeSelectionIcon__b" fill="#fff">
                <use href="#DataTypeSelectionIcon__a" />
            </mask>
            <g fill="#21212D" mask="url(#DataTypeSelectionIcon__b)">
                <path d="M0 0H24V24H0z" transform="translate(-8 -10)" />
            </g>
        </g>
    </svg>
)
const styles = theme => ({
    menuTitle: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        marginRight: 8,
        "color": theme.newUI.color.gray
    },
    selectedValue: {
        "fontFamily": "Rubik",
        "fontSize": "16px",
        "fontWeight": "500",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.5",
        "letterSpacing": "0.15px",
        "color": theme.newUI.color.commonBlack
    },
    button: {
        padding: '0px 8px',
    }
});

class MenuButton extends Component {
  
    render() {
        const { classes, menuTitle, selectedValue, onClick } = this.props;


        return (
            <Grid dir='auto' onClick={onClick} container className={classes.button} justify='space-between' alignItems='center'>
                <Grid item>
                    <span className={classes.menuTitle}>{menuTitle}</span>
                    <span className={classes.selectedValue}>{selectedValue}</span>
                </Grid>
                <DataTypeSelectionIcon />
            </Grid>
        )
    }

}
export default withStyles(styles)(MenuButton);