import { Typography, withStyles, Tooltip } from '@material-ui/core';
import React from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher';
import Star from '@material-ui/icons/Star';
import moment from "moment";
import { connect } from 'react-redux';
import EmailIcon from '../Common/Icons/ProfileIcons/EmailIcon';
import LanguageIcon from '../Common/Icons/ProfileIcons/LanguageIcon';
import NicknameIcon from '../Common/Icons/ProfileIcons/NicknameIcon';
import BirthdayIcon from '../Common/Icons/ProfileIcons/BirthdayIcon';
import OnelineDateIcon from '../Common/Icons/ProfileIcons/OnelineDateIcon';
import { hexToRGB } from '../../views/groups/Calendar/event-utils';
import parseImagUrl from "../../../ui/components/Common/parseImagUrl";

const styles = theme => {
    const primaryColor = theme.customColors.primary.b300
    const lighterColor = hexToRGB(primaryColor, 0.1)

return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 12,
        marginBottom: 0,
        marginTop: 0,
        padding: '0 16px',
    },
    containerSmall: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarImgHolderSmall: {
        width: '100%',
        textAlign: 'center'
    },
    avatarImgHolder: {
        width: 88,
        height: 88,
        marginBottom: 16
    },
    img: {
        width: 88,
        height: 88,
        borderRadius: '50%',
    },
    imgSmall: {
        width: 40,
        height: 40,
        borderRadius: '100%',
        marginTop: 12,
    },
    title: {
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.commonBlack,
        display: 'block',
        textAlign: 'center',
    },
    subtitle: {
        margin: '8px 0',
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 11,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.4px',
        color: theme.newUI.color.gray,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
    pointsContainer: {
        marginTop: -5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 14,
    },
    starIcon: {
        display: 'flex',
        color: '#efc03e',
        width: 19,
        height: 19,
        fontSize: 19,
        marginRight: 6,
        marginTop: -1
    },
    points: {
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: '0.4px',
        color: 'rgb(240, 204, 48)',
    },
    infoContainer: {
        borderRadius: 6,
        display: 'flex',
        border: 'solid 2px #e7eaed',
        width: '100%',
        height: 'fit-content',
        padding: '12px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    infoContainerTooltipe: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: -10,
    },
    infoContainerSmall: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        marginTop: 24
    },
    infoTitle: {
        width: '95%',
        fontFamily: 'Rubik',
        fontSize: 11,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: '0.4px',
        color: theme.newUI.color.commonBlack,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,

        '&:last-child': {
            marginBottom: 0
        },
    },
    infoTitleTooltipe: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'light',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: '0.4px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
        position: 'relative',

        '&:last-child': {
            marginBottom: 0
        },
    },
    emailHolder: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    emailHolderBig: {
        width: '195px',
    },
    infoIconHolder: {
        marginRight: 10,
        color: '#979aaa',
    },
    infoIconHolderSmall: {
        color: '#979aaa',
    },
    infoIconHolderToolTipe: {
        color: '#fff',
        marginRight: 5,
        fontSize: 15
    },
    tooltip: {
        fontSize: 12,
        backgroundColor: '#4A4A4F',
        padding: '4px 8px',
        borderRadius: 4,
        fontfamily: 'Rubik',
        lineHeight: '16px',
    },
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -3,
        marginTop: -3.5,
        transform: 'rotate(-45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
    settingsContainer: {
        marginTop: 24, 
    },
    settingSmallContainer: {
        marginTop: 19,
    },
    settingsButton: {
        height: 28,
        border: `0.5px solid ${theme.customColors.primary.b500}`,
        textTransform: 'none',
        fontFamily: 'Rubik',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.4,
        color: theme.customColors.primary.b500,
        "&:hover": {
            backgroundColor:  lighterColor,
        },
    },
    settingsIcon: {
        width: 32,
        height: 28,
        border: `1px solid ${theme.customColors.primary.b500}`,
        textAlign: 'center',
        marginRight: 8,
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 4,
        "&:hover": {
            backgroundColor:  lighterColor,
        },
    },
    editButtonHolder: {
        
    },

})
}

const __UserInfoDrawer = (props) => {
    const {profile, classes, isDrawerOpen} = props
    const { profileImageUrl, auth0_id, firstname, lastname, tag, title, points, email, lang, creationDateTime, birthday } = profile

    const themeOption = profile.org?.brandingColor ?? 'default'

    const Points = (
        <div data-intrcm-id="UserInfoDrawer_18a3nu9b" className={classes.pointsContainer}>
            <Star className={classes.starIcon}/>
            <Typography className={classes.points}>{`${points} pts.`}</Typography>
        </div>
    )

    const Info = ({content = false}) =>  {
        const titleStyles = content ? classes.infoTitleTooltipe : classes.infoTitle
        const iconHolderStyles = content ? classes.infoIconHolderToolTipe : classes.infoIconHolder
        const iconColor =  content ? '#fff': '#979aaa'
        
        return (<div data-intrcm-id="UserInfoDrawer_jkgeyjfi" className={content ? classes.infoContainerTooltipe : classes.infoContainer}>
                    {birthday && <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <BirthdayIcon iconColor={iconColor}/>
                        </span>
                        {moment(birthday).format('LL') }
                    </Typography>}
                    <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <OnelineDateIcon iconColor={iconColor}/>
                        </span>
                        {moment(creationDateTime).format('LL') }
                    </Typography>
                    <Typography className={titleStyles} noWrap={true}>
                        <span className={iconHolderStyles}>
                            <EmailIcon iconColor={iconColor}/>
                        </span>
                        {<span className={`${classes.emailHolder} ${classes.emailHolderBig}`}>{email}</span>}
                    </Typography>
                    <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <NicknameIcon iconColor={iconColor}/>
                        </span>
                    {tag}
                    </Typography>
                    <Typography className={titleStyles}>
                        <span className={iconHolderStyles}>
                            <LanguageIcon iconColor={iconColor}/>
                        </span>
                        {lang}
                    </Typography>
                    {content && <div data-intrcm-id="UserInfoDrawer_9tbxmo04" className={classes.triangle}></div>}
                </div>)
    }

    const content = <Info content/>

    let photo = profileImageUrl ?? ''

    if(!photo.startsWith('https://')) {
        photo = parseImagUrl(props.cloudfront, photo, 120)
    }
   
    if(photo.includes("fit-in/50x0")) {
        photo = photo.replace("fit-in/50x0", "fit-in/120x0")
    }

    return (
        <div data-intrcm-id="UserInfoDrawer_ks0i9l1b" className={isDrawerOpen ? classes.container : classes.containerSmall}>
            <Tooltip placement="right" 
                 title={content}
                 interactive
                 disableHoverListener={isDrawerOpen ? true : false} 
                 classes={{tooltip: classes.tooltip}}
            >
                <div data-intrcm-id="UserInfoDrawer_jvhjbhq8" className={!isDrawerOpen ? classes.avatarImgHolderSmall : classes.avatarImgHolder}><img alt={`user-image-${auth0_id}`} src={photo} className={isDrawerOpen ? classes.img : classes.imgSmall} /></div>
            </Tooltip>
            {isDrawerOpen && 
            <>
                <Typography className={classes.title}>{firstname} {lastname}</Typography>
                <Typography dir='auto' className={classes.subtitle}>
                        @{tag} · {title}
                </Typography>
                {Points}
            </>
            }
            {isDrawerOpen ? <Info/> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    myAuth0_id: state.userData.profileData.auth0_id,
    cloudfront: state.globalData.cloudfront,
})

export const UserInfoDrawer = compose(
    withStyles(styles, { withTheme: true }),
    withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, null)
)(__UserInfoDrawer);