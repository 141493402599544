import React, { useMemo } from 'react'
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTimer } from '../../../../utils/hooks/timerCustomHook';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import StopTimer from "../../Common/Icons/StopTimerIcon";
import { showSnackbar } from '../../../../actions/appActions';
import { stopTimeTracking, disabledTimer, setCurrentTime, setTimeTrackingTask } from '@tuqqi/common';
import { SERVER_API } from '../../../../utils/serverAPI';
import withErrorCatcher from '../../Common/withErrorCatcher';
import { isUserOrgAdmin } from '../../Common/Utils/userPermissionsUtils';
import { createCustomTheme } from '../../../../utils/theming';
import { hexToRGB } from '../../../../utils/hexToRGB';

const styles = theme => ({
    timerToolbar: {
        width: 85,
        
    },
    timerContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 24,
        borderRadius: 8,
        backgroundColor: theme.customColors.primary.b200,
        "&:hover": {
            backgroundColor: theme.customColors.primary.b300,
        }
    },
    timerTooltip: {
        padding: 0,
        margin: 0,
    },
    timerStopButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: 'transparent',
        }
    },
    time: {
        fontFamily: 'Rubik',
        color: 'white',
        fontSize: 12,
        marginRight: 2,
    },

});

const TimeTracker = (props) =>  {
    const { classes, dispatch, isTimerOn, currentTime, timerTrackingTask,subscription,profileData} = props;
    const timeToDisplay = useTimer(currentTime, false);

    const themeOption = profileData.org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)
    const lighterColor = hexToRGB(theme.primary.b500, 0.1)

    const whiteOpacity012 = hexToRGB('#FFFFFF', 0.12)
    const isUpgrade = useMemo(() => isUserOrgAdmin(profileData) &&  (subscription.subscriptionState === 3 || subscription.subscriptionState === 0))

    const timeTrackerStopHandler = () => {
        dispatch(disabledTimer())
        dispatch(stopTimeTracking(SERVER_API))
        dispatch(setTimeTrackingTask(null))
        dispatch(setCurrentTime(null))
        dispatch(showSnackbar('Stop timer...'));
    }

    return (
        <div data-intrcm-id="TimeTracker_0ss9iqwh" 
        style={{marginRight: isUpgrade ? 15 : 0}} 
        className={classes.timerToolbar}>
        { isTimerOn ? (
          <div data-intrcm-id="TimeTracker_52y5wxgo"  className={classes.timerContainer}>
            <Tooltip className={classes.timerTooltip}
             title={ timerTrackingTask ? timerTrackingTask[0]?.title : '' } 
              placement="bottom">
                <IconButton data-intrcm-id="TimeTracker_0l2zvy54" className={classes.timerStopButton} aria-label="Notifications" onClick={timeTrackerStopHandler} >      
                        <StopTimer color='#FFFFFF' withCircle={false}  /> 
                        <div data-intrcm-id="TimeTracker_kwsix3sj" className={classes.time}>{timeToDisplay ?? ''}</div>
                    </IconButton>
            </Tooltip>
          </div>
          ): null}
          </div>
    )
}

const mapStateToProps = (state) => ({
    isTimerOn: state.data.timeTracking.isTimerOn,
    currentTime: state.data.timeTracking.currentTime,
    timerTrackingTask: state.data.timeTracking.timerTrackingTask,

    subscription: state.globalData.currentSubscription,
    profileData: state.userData.profileData
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(connect(mapStateToProps, mapDispatchToProps),
 withErrorCatcher(),
 withStyles(styles, {withTheme: true}))(TimeTracker)