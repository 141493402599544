import React from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import DrawerListItem from './drawerListItem';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { FluencyIcons } from '@tuqqi/common'
import SVG from 'react-inlinesvg';
import { useNewActionCenter } from '../../../../../utils/useNewTable';
import { OrgSchedulerIcon } from './OrgSchedulerIcon';
import { shouldUseScheduler, useShouldUseScheduler } from '@tuqqi/web';

const feedIconPath = FluencyIcons.fluencyIconNews.data
const chartIconPath = FluencyIcons.fluencyIconStackedOrganizationalChart.data
const floatIconPath = FluencyIcons.fluencyIconFloat.data
const libraryIconPath = FluencyIcons.fluencyIconLibrary.data
const orgSchIconPath = FluencyIcons.fluencyIconLibrary.data
const mypageIconPath = FluencyIcons.fluencyIconCustomer.data
const actionsIconPath = FluencyIcons.fluencyIconToDo.data
const myreportsIconPath = FluencyIcons.fluencyIconReportFile.data
const favoritesIconPath = FluencyIcons.fluencyIconFavoriteFolder.data
const groupsIconPath = FluencyIcons.fluencyIconBrainstorm.data

const styles = (theme) => ({
})

const _LeftDrawerDefaultItems = (props) => {
    const {t, dispatch, orgUid} = props
    
    const {value: useScheduler} = useShouldUseScheduler('left-drawer-sch', true)

    const goToFeed = () => {
        dispatch(push('/feed'));
        dispatch({ type: "DATA_FEED_UPDATE" });
        dispatch({ type: 'RESULT_CHANNEL_EMPTY' });
    }

    const goToMyPage = () => {
        dispatch(push('/mypage'));
    }
    const goToActions = () => {
        dispatch(push('/mypage/actioncenter'));
    }
    const goToOrgSch = () => {
        dispatch(push('/orgscheduler'));
    }

    const goToMyReports = () => {
        dispatch(push('/mypage/tasks'));
    }

    const goToMyFavorites = () => {
        dispatch(push('/mypage/favorites'));
    }

    const goToLibrary = () => {
        dispatch(push('/library'));
    }

    const goToOrgChart = () => {
        dispatch(push('/orgchart'));
    }
    const goToGroups = () => {
        dispatch({
            type: 'SEARCH_QUERY', query: {query: '/', isFocused: true}
        })
    }
    const goToHelpCenter = () => {
        window.open(t("helpLink"), "_blank")
    }

    const isActionCenterDisabled = !useNewActionCenter(orgUid)


    const List = <>
        <DrawerListItem
            id="feed_item"
            selectedCondition
            onClick={goToFeed}
            // icon={<SVG src={feedIconPath} />}
            text={t('📰  News Feed')} />
        <DrawerListItem
            id="actions_item"
            isSoon
            disabled={isActionCenterDisabled}
            selectedCondition={false}
            onClick={goToActions}
            icon={<SVG src={actionsIconPath} />}
            text={t('🧮  Action Center')} />
        {/* <DrawerListItem
            id="mypage_item"
            selectedCondition={false}
            onClick={goToMyPage}
            icon={<SVG src={mypageIconPath} />}
            text={t(' My Page')} /> */}
        {/* <DrawerListItem
            id="myreports_item"
            selectedCondition={false}
            onClick={goToMyReports}
            icon={<SVG src={myreportsIconPath} />}
            text={t('Reports')} /> */}
        {/* <DrawerListItem
            id="favorites_item"
            selectedCondition={false}
            onClick={goToMyFavorites}
            icon={<SVG src={favoritesIconPath} />}
            text={t('Favorites')} /> */}
        <DrawerListItem
            id="library_item"
            selectedCondition={false}
            onClick={goToLibrary}
            icon={<SVG src={libraryIconPath} />}
            text={t('📖  Library')} />
        {useScheduler && <DrawerListItem
            id="org_sch_item"
            selectedCondition={false}
            onClick={goToOrgSch}
            icon={<OrgSchedulerIcon />}
            text={t('📆  Org Scheduler')} />}
        {/* <DrawerListItem
            id="chart_item"
            selectedCondition={false}
            onClick={goToOrgChart}
            icon={<SVG src={chartIconPath} />}
            text={t('  Org Chart')} /> */}
        {/* <DrawerListItem
            id="help_center_item"
            selectedCondition={false}
            onClick={goToHelpCenter}
            icon={<SVG src={floatIconPath} />}
            text={t('  Help Center')} /> */}
        <DrawerListItem
            id="groups_item"
            selectedCondition={false}
            onClick={goToGroups}
            icon={<SVG src={groupsIconPath} />}
            text={t('🗃️  Group')} />
    </>

    return <>
        {List}
    </>
}

const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    withErrorCatcher(),
    withStyles(styles, {withTheme: true}),
    withTranslation('drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps)
)(_LeftDrawerDefaultItems);
