import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';
import InputTitle from './InputTitle';
import ListHeaderContainer from './ListHeaderContainer.js';
import ListHeaderMenu from './ListHeaderMenu.js';
import ListTitle from './ListTitle.js';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = theme => ({
    container: {
        marginBottom: 16
    }, iconButton: {
        padding: 12
    },
    confetti: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    }
})

class ListHeader extends React.Component {

    state = {
        isFocused: false,
        showNewTooltip: false
    }

    onFocusToggle = () => {
        this.setState({ isFocused: !this.state.isFocused })
    }

    onKeyDown = () => { }
    render() {

        const {
            classes,
            list,
            isOpenMenu,
            anchorEl,
            editTitleList,
            openMenu,
            closeMenu,
            openListRuleModal,
            deleteList,
            openListColorModal,
            dragHandleProps,
            sortBy
        } = this.props;


        const isUnassignedLane = list.id === -1
        const total = isUnassignedLane ? list.total : list.cards.length

        const listHeaderGreenProps = list.listColor !== 20 ? {} : {
            id: `list_header_green_id_${list.id}`
        }

        return (
            <ListHeaderContainer
                classes={{ container: classes.container }}
                color={list.listColor}
                otherProps={{
                    ...(this.state.isFocused ? {} : { ...dragHandleProps }),
                    onKeyDown: this.onKeyDown
                }}>
                <div data-intrcm-id="ListHeader_a5pchjnw" className={classes.confetti} {...listHeaderGreenProps}>
                    {
                        this.state.isFocused ?
                            <InputTitle onBlur={this.onFocusToggle} editTitleList={editTitleList} list={list} /> :
                            <ListTitle isChangedAble={true} onEdit={this.onFocusToggle} list={list} />
                    }
                    {

                        <ListHeaderMenu
                            list={list}
                            isUnassigned={isUnassignedLane}
                            deleteList={deleteList}
                            openListRuleModal={openListRuleModal}
                            closeMenu={closeMenu}
                            anchorEl={anchorEl}
                            isOpenMenu={isOpenMenu}
                            openMenu={openMenu}
                            sortBy={sortBy}
                            openListColorModal={openListColorModal}
                        />
                    }
                </div>
            </ListHeaderContainer>
        )
    }
}



export default compose(withStyles(styles), withErrorCatcher())(ListHeader);