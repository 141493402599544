import { FULLVIEW_POST, FULL_RESET_STORE, FULLVIEW_CHANGE, FULLVIEW_NEW, FULLVIEW_EDIT, FULLVIEW_EDIT_LOAD, FULLVIEW_OPEN, FULLVIEW_OPEN_LOAD, FULLVIEW_CLOSE, FULLVIEW_LOADED, FULLVIEW_LOADING } from "../actionTypes/actionTypes";
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = []
const emptyInput = {
  // Open state
  open: false,
  fullEditOpen: false,
  // Model state
  hasAttachments: false,
  postText: '',
  hasPreview: false,
  attachments: [],
  tags: [],
  title: '',
  docText: '',
  jsonData: '',
  parsedJsonData: {},
  aggregations: {},
  links: [],
  isPrivate: false,
  permissions: [],
  alreadyExists: false,
  collectionLabels: [],

  // Preview fields
  previewTitle: '',
  previewDescription: '',
  previewImgUrl: '',
  link: '',
  // item data type
  dataType: '',
  mimeType: '',
  // Input state
  canSubmit: false,
  // Input Type
  formTitle: '',

  classification: 0,
  
}



const input = (state = initialState, action) => {

  switch (action.type) {

    case FULL_RESET_STORE: 
      return initialState;

    case FULLVIEW_CHANGE:
      return {
        ...state,
        item: { ...action.item },
      }
      case FULLVIEW_POST:
      return {
        ...state,
        openPost:action.openPost,
        open: false,
        edit: true,
        isNew: true,
        item: { ...action.item },
        isLoaded: true,
        itemId: null
      }

    case FULLVIEW_NEW:
      return {
        ...state,
        openPost:false,

        open: true,
        edit: true,
        isNew: true,
        item: { ...action.item },
        isLoaded: true,
        itemId: null
      }


    case FULLVIEW_EDIT:
      return {
        ...state,
        open: true,
        edit: true,
      }

    case FULLVIEW_EDIT_LOAD:
      return {
        ...state,
        item: { ...action.item },
        itemId: action.itemId,
        open: true,
        edit: true,
        isLoaded: false,
      }
    // View only
    case FULLVIEW_OPEN:
      return {
        ...state,
        item: { ...action.item },
        itemId: action.itemId,
        open: true,
        isLoaded: true,
        edit: false,
        isNew: false
      }
    case FULLVIEW_OPEN_LOAD:
      return {
        ...state,
        item: { ...action.item },
        itemId: action.itemId,
        open: true,
        edit: false,
        isLoaded: false,
        inFavorites: action.inFavorites
      }
    case FULLVIEW_CLOSE:
    case ON_GO_TO_CHAT:
    case ON_GO_TO_ITEM_NOTIF:
      return {
        ...state,
        item: { ...emptyInput },
        itemId: null,
        open: false,
        edit: false,
        isLoaded: false,
        openPost:false,

        isNew: false,
      }
    case FULLVIEW_LOADED:
      return {
        ...state,
        item: { ...action.item },
        isLoaded: true,
      }

    case FULLVIEW_LOADING:
      return {
        ...state,
        // item: { ...action.item },
        isLoaded: false,
      }

    default:
      return state
  }
}

export default input