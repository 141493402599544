import { Divider, Grid, ListItem, withStyles, Icon } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import ChatListItemLeftElement from './ChatListItemLeftElement.js';
import ChatListItemRightElement from './ChatListItemRightElement.js';
import { DirectMessages, GroupMessages, Discussions, getChatId, SavedMessages } from '../chatUtils.js';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { managerTableKeepGlobalData } from '@tuqqi/web';

// Apply some reset
const styles = theme => ({
    secondary: {
        lineHeight: '1.8 !important',
    },
    privateBubbleContainer: {
        borderRadius: 5,
        backgroundColor: '#9cc3ff',
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 7,
        justifyContent: 'center',
        height: 15,
        marginTop: 1
    },
    privateBubbleContainerGroup: {
        backgroundColor: '#fbc97b',
    },
    privateBubbleText: {
        fontSize: 12,
        
        color: '#1e66d5'
    },
    privateBubbleTextGroup: {
        color: '#21212d!important',
    },
    gridText: {
        overflow: 'hidden',
        flex: 1,
    },
    text: {
        
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    itemTitle: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: 0.1,
        color: '#21212d',
        textTransform: 'initial',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        whiteSpace: 'break-spaces'
    },
    itemSubtitle: {
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: 'normal',
        color: 'rgba(0, 0, 0, 0.38)',
        whiteSpace:'nowrap',
        textTransform: 'initial',
        paddingRight: 4
    },
    
        root: {
            selected: {
                backgroundColor:'rgba(0, 0, 0, 0.7)'
            }
            
        }
        
            
        
    
});


class ChatDrawerListItem extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    privateBubble = () => {
        return this.props.userProfile && this.props.chatType === DirectMessages
            ? <div data-intrcm-id="chatDrawerListItem_w35r9ueh" className={this.props.classes.privateBubbleContainer} >
                <div data-intrcm-id="chatDrawerListItem_jvgfkqht" className={this.props.classes.privateBubbleText} >Private</div>
            </div>
            : <div data-intrcm-id="chatDrawerListItem_0ema941f" />
    }
    groupBubble = () => {
        return this.props.chatType === GroupMessages
            ? <div data-intrcm-id="chatDrawerListItem_w35r9ueh" className={`${this.props.classes.privateBubbleContainer} ${this.props.classes.privateBubbleContainerGroup}`} >
                <div data-intrcm-id="chatDrawerListItem_jvgfkqht" className={`${this.props.classes.privateBubbleText} ${this.props.classes.privateBubbleTextGroup}`} >Group</div>
            </div>
            : null
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps) {
        const { chat, docId, commentText, chatType } = this.props;
        const { 
            chat: prevChat, 
            userProfile: prevUserProfile, 
            docId: prevDocId, 
            commentText: prevCommentText,
            chatType: prevChatType
        } = prevProps;

        const isChangedType = chatType !== prevChatType;

        if(isChangedType){
            this.updateState()
        }
        else{
            const isDM = chatType === DirectMessages;
            const isGM = chatType === GroupMessages;
            const isDMNeedUpdate = isDM && (prevChat.chatUId !== chat.chatUId || !prevUserProfile);
            const isGMNeedUpdate = isGM && (prevChat.chatUId !== chat.chatUId || !prevUserProfile);
            const isDiscussionNeedUpdate = prevDocId !== docId
            const isOnlyTextUpdated = prevCommentText !== commentText

            if (isGMNeedUpdate || isDMNeedUpdate || isDiscussionNeedUpdate || isOnlyTextUpdated) {
                this.updateState()
            }
        }
    }

    updateState = () => {
        const { chat, chatType, document, userProfile, commentText, lastUserId, users, commentType,t } = this.props;

        let title;
        let subtitle = commentText;
        let userName;

        switch (chatType) {
            case Discussions:
                let user = users.find(user => user.auth0_id === lastUserId);
                if (user) {
                    userName = `${user.firstname} ${user.lastname}: `;
                    subtitle = userName + commentText;
                }

                title = document.title;

                break;
            case DirectMessages:
                const { firstname, lastname } = userProfile;
                title = firstname + " " + lastname;

                break;
            case GroupMessages:
                let user1 = users.find(user => user.auth0_id === lastUserId);
                if (user1) {
                    userName = `${user1.firstname} ${user1.lastname}: `;
                    subtitle = userName + commentText;
                }
                const col = !chat?.chatUId ? undefined : managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === chat?.chatUId)
                title = !col?.title ? '' : `${col?.title ?? ''} ${managerTableKeepGlobalData.tTable("Chat")}`

                break;
            case SavedMessages:
                title = t('Saved Messages')
                subtitle = commentText === '' ? t('File attached') : commentText
                break;
        }

        const isRightCommentType = commentType && commentType !== Discussions && commentType !== DirectMessages && commentType !== 'Group'

        if (isRightCommentType) {
            let icon = ''
            let moreText = ''

            if (commentType.startsWith('image')) {
                icon = 'image'
                moreText = 'photo'
            } else if (commentType.startsWith('video')) {
                icon = 'videocam'
                moreText = 'video'
            }

            subtitle = <div data-intrcm-id="chatDrawerListItem_mp8r0v4t" style={{ flexDirection: 'row', display: 'flex' }}>
                <div data-intrcm-id="chatDrawerListItem_vqbo7wfp" style={{ paddingTop: 2 }}>{userName}</div>
                <Icon style={{ color: "#6200ee", marginRight: 3, marginLeft: 2 }} >{icon}</Icon>
                <div data-intrcm-id="chatDrawerListItem_38uagix9" style={{ paddingTop: 2 }}>{moreText}</div>
            </div>
        }

        this.setState({ title, subtitle })
    }

    render() {
        const { classes, selected, onClick, newComments, chat, docId, chatType, document, commentTime, idx, userProfile, className, isOpenSidebar, lastUserId } = this.props
        const { title, subtitle } = this.state;

        let leftElement = <ChatListItemLeftElement chat={chat} docId={docId} userProfile={userProfile} chatType={chatType} document={document} />
        let rightElement = <ChatListItemRightElement lastUserId={lastUserId} newComments={newComments} commentTime={commentTime} />
        let isSelected = chatType === SavedMessages ? selected === userProfile.auth0_id : selected === getChatId(chatType, chat, docId);

        return <div data-intrcm-id="chatDrawerListItem_nvmufd8v">
            <ListItem style={{minHeight: 70, paddingTop: 2, paddingBottom: 2}} className={classNames(classes.selected, className)} selected={isSelected} onClick={onClick} button  key={idx}>
                <Grid container style={{alignItems: 'center'}} >
                    <Grid item xs={2}>
                        {leftElement}
                    </Grid>
                    {isOpenSidebar && (
                    <React.Fragment>
                        <Grid item className={classes.gridText}>
                            <div data-intrcm-id="chatDrawerListItem_pcadxcuc" style={{ display: 'flex' }}>
                                <div data-intrcm-id="chatDrawerListItem_gqpporsp" className={[classes.itemTitle, classes.text].join(" ")}>{title}</div>
                                <div data-intrcm-id="chatDrawerListItem_ccezxku2" style={{ marginTop: 0 }}>{this.privateBubble()}</div>
                                <div data-intrcm-id="chatDrawerListItem_ccezxku2" style={{ marginTop: 0 }}>{this.groupBubble()}</div>
                            </div>
                            <div data-intrcm-id="chatDrawerListItem_ixe0qi88" style={{ display: 'flex' }}>
                            <span className={[classes.itemSubtitle, classes.text].join(" ")}>{subtitle}</span>
                            </div>
                        </Grid>
                        <Grid item  style={{display: 'flex', marginTop: -25, marginLeft: 4}}>
                            {rightElement}
                        </Grid>
                    </React.Fragment>
                    )}
                </Grid>
            </ListItem >
            {/* <Divider style={{ marginLeft: '27%' }} /> */}
        </div>

    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    ...state.wrapper,
    channel: state.results.channel,
    searchQuery: state.search,
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }), withErrorCatcher(),
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatDrawerListItem);