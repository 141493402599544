import React, {useEffect, useState} from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { SearchLandingPage } from './SearchLanding/SearchLandingPage';
import { SearchUsersPage } from './SearchUsers/SearchUsersPage';
import { SearchGroupsPage } from './SearchGroups/SearchGroupsPage';
import { SearchChannelsPage } from './SearchChannels/SearchChannelsPage';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const styles = (theme) => ({
    container: {
        zIndex: '1202!important',
        position: 'absolute',
        width: theme.constants.searchInputWidth,
        maxWidth: theme.constants.searchInputWidth,
        height: 'fit-content',
        right: 0,
        bottom: 0,
        top: 8,
        backgroundColor: '#f7f7fc'
    },
    containerDark: {
        zIndex: '1201!important',
        position: 'fixed',
        width: '100vw',
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
        left: 0,
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        '-webkit-backdrop-filter': 'blur(2px)',
        'backdrop-filter': 'blur(2px)',
        'background-color': 'rgba(0, 0, 0, 0.6)'
    },
    hidden: {
        zIndex: -1,
        display: 'none',
    }
})


const SEARCH_RIGHT_OFFSET  = 316;

const SearchNavigation = (props) => {
    const {query, isFocused, classes, searchLeftOffset} = props
    const [pageType, setPageType] = useState('landing')

    useEffect(() => {
        setPageType(detectPageType(query))
    }, [query])
    const renderPage = () => {
        switch (pageType) {
            case 'landing':
            return <SearchLandingPage withSuggestion={false} />
            case 'users':
            return <SearchUsersPage />
            case 'groups':
            return <SearchGroupsPage />
            case 'channels':
            return <SearchChannelsPage />
            case 'suggest':
            return <SearchLandingPage withSuggestion />
            default:
                return null
        }
    }

    const wrapperClassname = [classes.containerDark, !isFocused ? classes.hidden : ''].join(" ");
    return (
        <div data-intrcm-id="SearchNavigation_9bwdjh59" id="search-navigation-wrapper" className={wrapperClassname}>
            <Paper id="search-navigation-dialog" style={{right: SEARCH_RIGHT_OFFSET}} className={classes.container} >
                {renderPage()}
            </Paper>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      searchLeftOffset: state.search.searchLeftOffset || 344,
      isFocused: state.search.isFocused,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(SearchNavigation)

// page types: landing | users | groups | channels | suggest
const detectPageType = (input) => {
    if(!input ||  input.trim() === '') return 'landing';
    else if(input.startsWith("/")) return 'groups';
    else if(input.startsWith("#")) return 'channels';
    else if(input.startsWith("@")) return 'users';
    else return 'suggest';
}