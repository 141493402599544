import { FormControl, Icon, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';





const styles = theme => ({

    formControl: {
        display: 'flex'
    },
    selectMenu: { display: 'flex', alignItems: 'center' }
})

class SelectIcon extends React.PureComponent {

    icons = ['headset_mic', 'help_outline',
        'group_add', 'perm_phone_msg', 'perm_contact_calendar', 'contact_mail',
        'mail',
        'radio', 'multiline_chart',
        'account_circle', 'card_travel',
        'account_balance', 'gavel',
        'bug_report', 'code', 'devices',
        'important_devices', 'desktop_mac',
        'android', 'phonelink_setup',
        'done', 'description', 'list_alt',
        'face', 'supervisor_account',
        'vpn_key', 'monetization_on',
        'touch_app', 'compare_arrows',
        'format_paint', 'wallpaper', 'color_lens',
        'vpn_lock', 'screen_lock_portrait',
        'public', 'star_border', 'textsms'
    ]

   

    render() {
        const { classes, onChange, icon,t } = this.props;

        return (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="icon">{t('Icon')}</InputLabel>
                <Select
                    classes={{ selectMenu: classes.selectMenu }}
                    value={icon}
                    onChange={onChange}
                    inputProps={{ name: 'icon', id: 'icon', }}
                >
                    <MenuItem value=""><em>{t('None')}</em></MenuItem>
                    {this.icons.map((icon, idx) => (
                        <MenuItem value={icon}>
                            {icon}
                            <Icon classes={{ root: classes.icon }} className={classes.icon}>{icon}</Icon>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>)
    }
}

export default compose(withStyles(styles),	withTranslation('admin'))(SelectIcon);