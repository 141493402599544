import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import Header from '../../../components/App/PageComponents/pageContentHeader';



const styles = theme => ({
    saveButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 15
    }
})

class EditDefaultInputTemplate extends React.Component {

    componentDidMount() {
        const { mappings, goTo,t } = this.props;

    }

    static defaultProps = {
        avatar: <ListItemIcon><AddCircle style={{ height: 48, width: 48 }} /></ListItemIcon>,
        btnSaveText: 'Save changes'
    }

    render() {
        const { classes, title, subtitle, avatar, description, onSave, btnSaveText,t } = this.props;

        return (
            <Grid container spacing={16} justify="space-between">
                <Grid item md={8}>
                    <Header
                        title={title}
                        subtitle={subtitle}
                        avatar={avatar}
                        description={description} />
                </Grid>
                <Grid md={4}>
                    <Button variant="raised" onClick={onSave} className={classes.saveButton}>
                        {t(btnSaveText)}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}


export default compose(withStyles(styles),
withTranslation('admin'))(EditDefaultInputTemplate);
