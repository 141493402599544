import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import GanttChart from './Gantt/Gantt'
import { finishedUpdatingSpecificItem } from '../../../actions/dataActions.js';
import { DATA_FILTERED, DATA_UPDATED } from '../../../actionTypes/actionTypes.js';
import { getOptionsForInputMapping, loadGroupInfo } from './groupUtils.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../actions/upgradeSubscriptionActions.js';
import { themeConstants } from '../../../theme.js';

const styles = theme => ({
    container: {
 
        marginLeft: themeConstants.navigationDrawerSmallWidth + 15,
        marginTop: -30,
        paddingRight: 16,
        paddingTop: 16,
    },
    containerWithDrawer: {
        marginLeft: themeConstants.navigationDrawerWidth + 15,
        marginTop: -30,
        paddingRight: 16,
        paddingTop: 16,
    },
});
class GroupGanttView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            results: [],
            isLoading: true,
            isLoadingGroupAndData: false,
            actionCounter: 0,
            tasksFromKanban: [],
            lanes: [],
        }
        this.loadGroupInfo = loadGroupInfo.bind(this)
    }

    emptyReq = {
        query: '',
        isUnseen: false,
        tags: [],
        users: [],
        dataTypes: [],
        labels: [],
        colors: [],
        groups: [],
        dateFrom: null,
        dateTo: null,
        isAuthor: false,
        page: 0,
        isMyItem: false,
        isTrending: false,
        hasLocation: false,
        sortField: '',
        sortOrder: null,
        sortType: null,
        confirmed: false,
        mustread: false,
        mustReadMyRequests: false,
      }

    isCollectionChanged(prevSearchQuery) {
        const { collectionUid: nextCollectionUid } = this.props.searchQuery
        const {collectionUid: prevCollectionUid} = prevSearchQuery;
        return prevCollectionUid !== nextCollectionUid
    }
    listenCloseFullview = (prevProps) => {
        const {fullview: {open}} = this.props
        const {fullview: {open: prevOpen, mainDocId}} = prevProps
        if(open === prevOpen || (open && !prevOpen)) return {updateRequired: false, docId: ''}

        return {updateRequired: true, docId: mainDocId}
    }

    isLocalFilterPresented = () => {
        const { localFilter = {} } = this.props
        return Object.keys(localFilter.filters).length > 0
    }

    updateLists = (updatedLanes) => {
        if (updatedLanes) {
            this.setState({
                lanes: [...updatedLanes],
                actionCounter: this.state.actionCounter + 1
            });
        }
    }


    getView = () => {
        const { collectionInfo } = this.props;

        return (collectionInfo.collectionViews || []).find(c => c.collectionViewType === "Kanban");
    }

    buildCard(item, order) {
        return {
            doc: item.document,
            id: item.id,
            order,
            title: item.document.title
        }
    }

    buildKanban = (allGroupItems, fromIndicator) => {
        if(!allGroupItems.length && fromIndicator) return;
  
        let view = this.getView();

        if (!view) return

        const columns = view.itemLists.map(list => ({
            id: list.id,
            title: list.title,
            order: list.order,
            listColor: list.listColor,
            isUnassigned: false,
            rules: list.rules,
            cards: []
        }))
            .sort(this.sortOrder)

        const unassignedList = {
            id: -1,
            title: 'Unassigned',
            cards: [],
            order: -1,
            isUnassigned: true,
        }
        allGroupItems.forEach(groupItem => {
            let card = null
            for (let column of columns) {
                const listItems = view.itemLists.find(list => list.id === column.id).items
                const listItem = listItems.find(listItem => listItem.documentId === groupItem.id)

                if (listItem) {
                    card = this.buildCard(groupItem, listItem.order)
                    column.cards.push(card)
                    break
                }
            }
            if (!card) {
                const time = new Date(groupItem.document.docTimestamp).getTime()
                unassignedList.cards.push(this.buildCard(groupItem, -time))
            }
        })
        columns.unshift(unassignedList)
        columns.forEach(column => column.cards = column.cards.sort(this.sortOrder))

        this.setState({ lanes: columns });
    }

    updateSpecificItemInState(props, docId) {
        const { dispatch, queryService,collaborationService,searchQuery } = props;
        const { lanes } = this.state;

        const laneIndex = lanes.findIndex(lane => lane.cards.some(card => card.id === docId))
        if (laneIndex > -1) {
            queryService.searchById(docId).then((results) => {
                if (results.data.length) {
             
                    const lane = {
                        ...lanes[laneIndex],
                        cards: [...lanes[laneIndex].cards]
                    }

                    const cardIndex = lane.cards.findIndex(card => card.id === docId)

                    lane.cards[cardIndex] = {
                        ...lane.cards[cardIndex],
                        doc: results.data[0].Value.Source
                    }
                    lanes[laneIndex] = lane
                    this.updateLists(lanes)
                    const _searchQuery = { ...searchQuery, ...this.emptyReq }
                        collaborationService.getGanttTasks(_searchQuery).then((res) => { 
                            this.setState({ isLoadingGroupAndData: false,tasksFromKanban: res })
                            dispatch({ type: DATA_UPDATED })
                            dispatch({ type: DATA_FILTERED });
                    })    
                }
                this.setState({ isLoadingGroupAndData: false })
                dispatch(finishedUpdatingSpecificItem(docId));
            })
        } else {
            this.setState({ isLoadingGroupAndData: false })
            dispatch(finishedUpdatingSpecificItem(docId));
        }
    }



    getAllData = () => {
        const { searchQuery, queryService } = this.props
        const _searchQuery = { ...searchQuery, page: 0 }

        return queryService.GetKanbanData(_searchQuery)
    }

    componentDidMount() {
        const { subscription , dispatch} = this.props

        if(!this.canUseGantt()) {
            this.openSubscriptionDialog()
            return
        }

        const { searchQuery,collaborationService } = this.props
        const _searchQuery = { ...searchQuery, ...this.emptyReq }

        const withLocalFilter = this.isLocalFilterPresented()
        const getAllData = withLocalFilter ? this.getAllDataWithLocalFilter : this.getAllData

        Promise.all([this.loadGroupInfo(this.props), getAllData()])
                    .then((results) => {
                        const items = withLocalFilter ? this.localFilter(results[1]) : results[1]
                        this.buildKanban(items)
                        const _searchQuery = { ...searchQuery, ...this.emptyReq }
                        collaborationService.getGanttTasks(_searchQuery).then((res) => { 
                            this.setState({ isLoadingGroupAndData: false,tasksFromKanban: res })
                            dispatch({ type: DATA_UPDATED })
                            dispatch({ type: DATA_FILTERED });
                    })    
                 })

        collaborationService.getGanttTasks(_searchQuery).then((res) => { 
            this.setState({  tasksFromKanban: res })
    })    
    }

     openSubscriptionDialog = () => {
        const { dispatch} = this.props
        dispatch(initSubscriptionModal({reason: 'gantt', mode: 'existed', allowClose: false}))
        dispatch(openSubscriptionModal())
    } 

    canUseGantt = () => {
        const { subscription} = this.props
        if([0, 1, 2, 6].includes(subscription.subscriptionType)) return false
        return true
    }

    localFilter = (results) => {
        const { localFilter, searchQuery: {dataTypes}, inputMappings } = this.props;
        if(!dataTypes.length) return results

        const selectedFields = getOptionsForInputMapping(inputMappings, dataTypes[0])
        let filteredContent = results
        const filteredColumns = selectedFields.filter(x => !!localFilter.filters[x.sourceField])
        if (filteredColumns && filteredColumns.length > 0) {
            filteredColumns.forEach(column => {
                filteredContent = filteredContent.filter(res => {
                    
                    const fieldValue = column.extractData(res.Value.Source)

                        if(Array.isArray(fieldValue)){
                            for (let index = 0; index < fieldValue.length; index++) {
                                const element = fieldValue[index];
                                if(localFilter.filters[column.sourceField].data.indexOf(element) > -1){
                                    return true
                                }
                            }
                        }

                        if (Array.isArray(localFilter.filters[column.sourceField].data) && localFilter.filters[column.sourceField].data.indexOf(fieldValue) > -1 ) {
                            return true
                        }
                
                        if(localFilter.filters[column.sourceField].type === 'date') {
                            const fromToAgeValues = localFilter.filters[column.sourceField].data
                            if(fieldValue && fromToAgeValues.fromAge && fromToAgeValues.toAge) {
                               return new Date(fieldValue) > new Date(fromToAgeValues.fromAge) && new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
    
                            if(fieldValue && fromToAgeValues.fromAge) {
                                return new Date(fieldValue) > new Date(fromToAgeValues.fromAge)
                            }
    
                            if(fieldValue && fromToAgeValues.toAge) {
                                return new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
                        }
                    return false
                })
            })
        } 
        return filteredContent.map(result => ({id: result.Key, document: result.Value.Source}))
    }
   

     
    componentDidUpdate(prevProps) {
        const { oldUiData, dispatch,searchQuery,collaborationService } = this.props

        const closedUpdate = this.listenCloseFullview(prevProps)
        if (!this.isCollectionChanged(prevProps.searchQuery) && this.state.isLoadingGroupAndData) {
            return
        }

        if ((oldUiData.needsUpdate) ||
            (!oldUiData.isFiltering && oldUiData.needsFilter)) {
                this.setState({ isLoadingGroupAndData: true})

                const withLocalFilter = this.isLocalFilterPresented()
                const getAllData = withLocalFilter ? this.getAllDataWithLocalFilter : this.getAllData
    
                Promise.all([this.loadGroupInfo(this.props), getAllData()])
                    .then((results) => {
                        const items = withLocalFilter ? this.localFilter(results[1]) : results[1]
                        this.buildKanban(items)
                        const _searchQuery = { ...searchQuery, ...this.emptyReq }
                        collaborationService.getGanttTasks(_searchQuery).then((res) => { 
                            this.setState({ isLoadingGroupAndData: false,tasksFromKanban: res })
                            dispatch({ type: DATA_UPDATED })
                            dispatch({ type: DATA_FILTERED });
                    })    
                 })
        }
        else if (closedUpdate.updateRequired || (oldUiData.specificItemUpdate && !prevProps.specificItemUpdate)) {
            this.setState({ isLoadingGroupAndData: true})
            this.updateSpecificItemInState(this.props, closedUpdate.updateRequired ? closedUpdate.docId : oldUiData.itemId)
        }
    }

    render() {
        const { classes, isDrawerOpen } = this.props;
        const { tasksFromKanban } = this.state
        return (
            <div data-intrcm-id="groupGanttView_f3q8wa5b" className={ isDrawerOpen ? classes.containerWithDrawer : classes.container}>
                <GanttChart  onPress={this.onPress} listenCloseFullview={this.listenCloseFullview }  tasksFromKanban={tasksFromKanban}/>
            </div>
        )
    }
  

}



const mapStateToProps = (state) => ({
    oldUiData: state.oldUiData,
    ...state.services,
    searchQuery: state.search,
    openCollections: state.globalData.openCollections,
    collectionUid: state.results.channel.collectionUid,
    subscription: state.globalData.currentSubscription,
    collectionInfo: state.results.channel,
    localFilter: state.localFilter,
    inputMappings: state.globalData.inputMappings,
    fullview: state.fullview

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}


export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupGanttView);

