import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CategoryFeedView from '../../../components/App/CategoryFeedView.js';
import ResultsList from '../../../components/App/PageComponents/ResultsList.js';
import Spinner from '../../../components/Common/spinner';
import FeedDataItem from '../../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem.js';
import { filterData, IsQueryExistInCollections, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../../search/searchUtils';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import { loadGroupInfo } from '../../groups/groupUtils.js';
import { DATA_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../actionTypes/actionTypes.js';
import TimeDateWidget from '../../../components/Widgets/RightWidgets/Feed/TimeDateWidget.js';
import RelatedUsersWidget from '../../../components/Widgets/RightWidgets/Feed/RelatedUsersWidget.js';
import RelatedGroupsWidget from '../../../components/Widgets/RightWidgets/Feed/RelatedGroupsWidget.js';
import RelatedTagsWidget from '../../../components/Widgets/RightWidgets/Feed/RelatedTagsWidget.js';
import LaneColorsWidget from '../../../components/Widgets/RightWidgets/Feed/LaneColorsWidget.js';
import AdditionalOptionsWidget from '../../../components/Widgets/RightWidgets/Feed/AdditionalOptionsWidget.js';


const styles = theme => ({
    // feedAndWidgetContainer: {

    //     marginTop: 16,
    //     "margin": "0 auto",
    //     "width": "fit-content"
    // },
    // container: {
    //     [theme.breakpoints.down(1680)]: {

    //         marginRight: 0,
    //     },
    //     [theme.breakpoints.up(1680)]: {
    //         marginRight: 328
    //     },
    //     height: '100%',
    //     padding: '0px 32px',
    //     marginLeft: 328
    // },
    // feedContainer: {
    //     marginRight: 32,
    //     width: 512,
    //     flex: '0 0 auto'
    // },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
});

class SearchPageDatatype extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    renderItem = (idx, result) => {
        return (
            <FeedDataItem
                key={result.Key}
                docId={result.Key}
                result={result.Value.Source}
            />
        )
    }

    startLoad = () => {
        const [datatype] = location.pathname.split("/").slice(-1)
        const { dispatch } = this.props
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { dataTypes: [datatype], page: 0 } })
        dispatch({ type: DATA_UPDATE });
        dispatch({ type: DATA_FILTER });
    }

    componentDidMount() {
        this.startLoad()
    }

    isPathnameChanged = (prevPathname) => {
        return prevPathname !== this.props.pathname
    }
    componentDidUpdate(prevProps) {
        if (this.isPathnameChanged(prevProps.pathname)) this.startLoad()
    }

    renderRightWidgets = () => {
        const { experts, relatedTags, isLoading } = this.state;

        return [
            <TimeDateWidget />,
            <RelatedUsersWidget experts={experts} />,
            <RelatedGroupsWidget />,
            <RelatedTagsWidget relatedTags={relatedTags ?? []} />,
            <LaneColorsWidget />,
            <AdditionalOptionsWidget />,
        ]
    }

    render() {
        const { results, isLoading, isLoadingMore, hasMore } = this.state;

        const resultsProps = {
            results,
            isLoading,
            isLoadingMore,
            hasMore,
            loadMore: this.loadMoreQuery,
            isSearch: true,
            Header: null,
            isUniqeSearch: true,
            showInput: true,
            renderItem: this.renderItem,
        }



        return <CategoryFeedView rightWidgets={this.renderRightWidgets()}>
            {!isLoading ?
                <ResultsList {...resultsProps} /> :
                <Spinner classes={this.props.classes} />
            }
        </CategoryFeedView>
    }

}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...state.services,
    oldUiData: state.oldUiData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    urlQuery: ownProps.location.query.searchquery,
    pathname: state.wrapper.pathname,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(SearchPageDatatype);