import { Grid, Location, Override, ColumnResize, Sort, DomHelper } from '@bryntum/gantt';
import { managerGanttRef } from '@tuqqi/web';

const ALLOW_ARROWS = {
    value: true
}
const __allowArrows = () => {
    return ALLOW_ARROWS.value
}

export const setDenyArrows = () => {
    ALLOW_ARROWS.value = false
}
export const setAllowArrows = () => {
    ALLOW_ARROWS.value = true
}
class GridOverride {
    static get target() {
        return {
            class      : Grid,
            product    : 'grid',
            minVersion : '1.0',
            maxVersion : '5.0.7'
        };
    }

    onArrowDown(keyEvent) {
        if(!__allowArrows()) return
        keyEvent.preventDefault();
        this.focusCell(Location.DOWN);
    }
    onArrowUp(keyEvent) {
        if(!__allowArrows()) return
        keyEvent.preventDefault();
        this.focusCell(Location.DOWN);
    }
    onArrowLeft(keyEvent) {
        if(!__allowArrows()) return
        keyEvent.preventDefault();
        this.focusCell(Location.DOWN);
    }
    onArrowRight(keyEvent) {
        if(!__allowArrows()) return
        keyEvent.preventDefault();
        this.focusCell(Location.DOWN);
    }
}

Override.apply(GridOverride);

class ColumnResizeOverride {
    static get target() {
        return {
            class      : ColumnResize,
            product    : 'grid',
            minVersion : '1.0',
            maxVersion : '5.0.7'
        };
    }

    onResize({ context }) {
        const
            { grid } = this,
            { column } = context;

        grid.element.classList.remove('b-column-resizing');
        
        if (context.valid) {
            if (this.liveResize) {
                grid.resizingColumns = false;
                grid.afterColumnsResized();
            }
            else {
                column.width = context.newWidth;
            }
        }
        if((column.type === 'name' || column.type === 'percentdone') && managerGanttRef.gantt) {
            managerGanttRef.gantt.instance.refreshColumn(column)
        }
    }
}

Override.apply(ColumnResizeOverride);

const resolveWhenSingle = (store, column) => {
    const sorterCurrent = (store?.sorters ?? [])?.find(x => x.field === column.field)

    if(!sorterCurrent) {
        const sortBy = {columnOwned: true, field: column.field, ascending: true}
        store.sort(sortBy, null, false);
        return
    }
    if(!!sorterCurrent?.ascending) {
        const sortBy = {...sorterCurrent, columnOwned: true, field: column.field, ascending: false}
        store.sort(sortBy, null, false);
        return
    }
    if(!sorterCurrent?.ascending) {
        store.clearSorters();
        store.sort({initial: true, fn: (a, b) => a.order - b.order})
        return
    }
}



class SortOverride {
    static get target() {
        return {
            class      : Sort,
            product    : 'grid',
            minVersion : '1.0',
            maxVersion : '5.0.7'
        };
    }

    populateHeaderMenu({ column, items }) {
    }


    onElementClick(event) {
        const
            me     = this,
            store  = me.store,
            target = event.target,
            header = DomHelper.up(target, '.b-grid-header.b-sortable'),
            field  = header?.dataset.column;

        if (me.ignoreRe.test(target.className) || me.disabled) {
            return;
        }

        const column = !header || !field ? undefined : me.grid.columns.getById(header.dataset.columnId)
        if(!column) return
        const isSingle = !store.sorters.length || !!store.sorters.find(x => x.initial)
        if(isSingle) {
            resolveWhenSingle(store, column)
            return
        }
        resolveWhenSingle(store, column)
        // resolveWhenMulti(store, column)
    }

}

Override.apply(SortOverride);

export const allowDragWhenSort = () => {
    if(!managerGanttRef.gantt) return true
    const store = managerGanttRef.gantt.instance.eventStore
    if(!store) return true
    const sorters = (store?.sorters ?? []).filter(x => !x.initial)
    return !sorters.length
}

export const runOnInitCols = () => {
    const name = (managerGanttRef?.gantt?.instance?.columns?.topColumns ?? []).find(x => x.type === 'name')
    const percent = (managerGanttRef?.gantt?.instance?.columns?.topColumns ?? []).find(x => x.type === 'percentdone')

    if(!managerGanttRef.gantt) return

    name && managerGanttRef.gantt.instance.refreshColumn(name)
    percent && managerGanttRef.gantt.instance.refreshColumn(percent)
}