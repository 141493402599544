import React from 'react'

export const OrgSchedulerIcon = (props) => {
    return <svg width={props.width ?? "24"} height={props.height ?? "24"} className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M4.99218 3.53906C4.64809 3.4786 4.30434 3.4786 3.96093 3.53906C3.95313 3.1169 3.96094 2.69502 3.98437 2.27344C4.31249 1.83594 4.64062 1.83594 4.96874 2.27344C4.99218 2.69502 4.99996 3.1169 4.99218 3.53906Z" fill="#64717C"/>
    <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M9.77343 2.46093C12.6055 2.43786 15.4336 2.46129 18.2578 2.53125C18.3458 2.60357 18.4161 2.68951 18.4687 2.78906C18.5 4.24218 18.5 5.69531 18.4687 7.14843C18.4319 7.29295 18.346 7.39448 18.2109 7.45312C15.3828 7.48439 12.5547 7.48439 9.72655 7.45312C8.93596 6.74067 8.19379 5.98289 7.49999 5.17968C7.46076 5.00179 7.47637 4.82995 7.54687 4.66406C8.28412 3.91895 9.02634 3.18458 9.77343 2.46093Z" fill="#4FE6FF"/>
    <path opacity="0.983" fill-rule="evenodd" clip-rule="evenodd" d="M4.99222 3.53906C5.77878 3.8931 6.09912 4.50248 5.95315 5.36719C5.80198 5.86987 5.48169 6.21361 4.99222 6.39844C4.64778 6.52012 4.30403 6.52012 3.96097 6.39844C3.14946 6.057 2.82915 5.44762 3.00003 4.57031C3.15264 4.06626 3.47296 3.72251 3.96097 3.53906C4.30437 3.4786 4.64813 3.4786 4.99222 3.53906Z" fill="#AAB3BD"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96094 6.39844C4.304 6.52012 4.64775 6.52012 4.99219 6.39844C4.99219 7.78908 4.99219 9.17967 4.99219 10.5703C4.64605 10.4534 4.3023 10.4534 3.96094 10.5703C3.96094 9.17967 3.96094 7.78908 3.96094 6.39844Z" fill="#64717C"/>
    <path opacity="0.999" fill-rule="evenodd" clip-rule="evenodd" d="M9.82031 9.44532C13.7579 9.43749 17.6954 9.44532 21.6328 9.46876C21.8125 9.52346 21.9297 9.64065 21.9844 9.82032C22.0156 11.2578 22.0156 12.6953 21.9844 14.1328C21.9297 14.3125 21.8125 14.4297 21.6328 14.4844C17.6953 14.5156 13.7578 14.5156 9.82031 14.4844C9.75783 14.4531 9.6953 14.4219 9.63281 14.3906C8.93752 13.6953 8.24217 13 7.54688 12.3047C7.45312 12.0859 7.45312 11.8672 7.54688 11.6484C8.28553 10.8862 9.04336 10.1519 9.82031 9.44532Z" fill="#0077D4"/>
    <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M4.99219 10.5703C5.64539 10.803 5.97352 11.2718 5.97656 11.9766C5.97352 12.6814 5.64539 13.1501 4.99219 13.3828C4.6458 13.4998 4.30205 13.4998 3.96094 13.3828C3.30775 13.1501 2.97962 12.6814 2.97656 11.9766C2.97962 11.2718 3.30775 10.803 3.96094 10.5703C4.3023 10.4534 4.64605 10.4534 4.99219 10.5703Z" fill="#ABB4BD"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96094 13.3828C4.30205 13.4998 4.6458 13.4998 4.99219 13.3828C4.99219 14.7735 4.99219 16.164 4.99219 17.5547C4.64706 17.4357 4.30331 17.4357 3.96094 17.5547C3.96094 16.164 3.96094 14.7735 3.96094 13.3828Z" fill="#64717C"/>
    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M7.61716 18.5859C8.17563 18.098 8.71469 17.5823 9.23435 17.0391C9.2561 17.0112 9.26393 16.98 9.25779 16.9453C9.39058 16.7735 9.54682 16.6251 9.72654 16.5C12.4179 16.4535 15.1054 16.4692 17.789 16.5469C17.8616 16.6038 17.9163 16.6741 17.9531 16.7578C17.9993 18.2775 17.9837 19.7931 17.9062 21.3047C17.8322 21.3769 17.7462 21.4316 17.6484 21.4688C15.0234 21.5 12.3984 21.5 9.77341 21.4688C9.01694 20.7592 8.27477 20.0326 7.54685 19.2891C7.43472 19.0351 7.45816 18.8007 7.61716 18.5859Z" fill="#199BE2"/>
    <path opacity="0.984" fill-rule="evenodd" clip-rule="evenodd" d="M4.9922 17.5547C5.85826 17.9393 6.16295 18.5956 5.90626 19.5234C5.73067 19.9569 5.42598 20.2537 4.9922 20.4141C4.64811 20.4745 4.30436 20.4745 3.96095 20.4141C3.1741 20.0594 2.85378 19.45 3.00001 18.5859C3.15119 18.0832 3.4715 17.7395 3.96095 17.5547C4.30333 17.4357 4.64707 17.4357 4.9922 17.5547Z" fill="#AAB3BD"/>
    <path opacity="0.573" fill-rule="evenodd" clip-rule="evenodd" d="M9.25781 16.9453C9.26395 16.98 9.25612 17.0112 9.23438 17.0391C8.71472 17.5823 8.17566 18.098 7.61719 18.5859C8.14842 18.0234 8.69531 17.4765 9.25781 16.9453Z" fill="#21BBEB"/>
    <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M3.96093 20.4141C4.30434 20.4745 4.64809 20.4745 4.99218 20.4141C4.99996 20.8362 4.99218 21.2581 4.96874 21.6797C4.64062 22.1172 4.31249 22.1172 3.98437 21.6797C3.96094 21.2581 3.95313 20.8362 3.96093 20.4141Z" fill="#64717C"/>
    </svg>    
    
}