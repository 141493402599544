import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer';
import { NewUiWrapper, EditProfile } from '@tuqqi/web';
import { themeConstants } from '../../../theme';
import { store } from '../../../index.js';
import i18n from '../../../i18n.js';

const styles = theme => ({
    settings: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerWidth,
        width: 'fit-content',
        backgroundColor: '#f5f5f5',
    },
    settings1: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerSmallWidth,
        width: 'fit-content',
        backgroundColor: '#f5f5f5',
    },
})

class MyPageProfileView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    onChangedLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    render() {
        const { classes, isDrawerOpen, userUid } = this.props;

        const callbacks = {
            onChangedLang: this.onChangedLang
        }
     
        return <CategoryViewOnlyLeftDrawer >
            <div data-intrcm-id="MyPageProfilex_e4zhjttd" className={isDrawerOpen ? classes.settings : classes.settings1}>
                <NewUiWrapper store={store} callbacks={callbacks}>
                    <EditProfile drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerWidth  : themeConstants.navigationDrawerSmallWidth} userUid={userUid} />
                </NewUiWrapper>
            </div>
        </CategoryViewOnlyLeftDrawer>
    }
}
const mapStateToProps = (state) => ({
    userUid: state.userData.profileData?.auth0_id ?? '',
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    withErrorCatcher("Failed Loading Settings, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(MyPageProfileView);