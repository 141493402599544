import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import AccessLevel from './AccessLevel.js';
import CollectionPermissionType from './CollectionPermissionType.js';


const styles = theme => ({

})


export default class CollectionPermissionSettings extends PureComponent {
    render() {

        return (
            <Grid container direction='column'>
                <AccessLevel/>
                <CollectionPermissionType />
            </Grid>
        )

    }
}

