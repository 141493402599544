import { Collapse, List, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { compose } from 'recompose'
import KanbanCard from '../../../../components/Kanban/Card/KanbanCard'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { getColorByStatusId } from '../../../../components/Kanban/ListColorModal/ListColorModal'
import { lighten } from '@tuqqi/web/node_modules/@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { format } from 'date-fns';
import { StatusColorMap, getDueDateStatus, getDueDateStyling } from '@tuqqi/common'

const cardHeight = 55
const defaultStatusColor = getColorByStatusId(-1)
const defaultBackgroundColor = lighten(defaultStatusColor, 0.95)
const CardPaddingTop = 16
const CardPaddingBottom = 8
const CardPaddingLeft = 16
const CardPaddingRight = 16

const styles = (theme) => ({
    container: {
        position: 'relative',
        display: 'block',
        width: 'inherit',
        minWidth: 'inherit',
        height: 'fit-content',
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0
        },
        borderRadius: 5,
        "border": `solid 1px #B6B9CA`,
        "&:hover": {
            "boxShadow": "0 2px 4px -1px rgba(33, 33, 45, 0.2), 0 1px 10px 0 rgba(33, 33, 45, 0.12), 0 4px 5px 0 rgba(33, 33, 45, 0.14)",
            "backgroundColor": "#f3f6fb"
        }

    },
    containerCard: {
        cursor: 'pointer',
        overflow: 'hidden',
        padding: `${CardPaddingTop}px ${CardPaddingRight}px ${CardPaddingBottom}px ${CardPaddingLeft}px`,
        "width": 264,
        "borderRadius": "4px",
        "backgroundColor": "#ffffff",
        '& .menu': {
            display: 'none'
        },
        '&:hover': {
            '& .menu': {
                display: 'initial'
            }
        }
    },
    header: {
        height: cardHeight,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 15,
        paddingLeft: 15,
        backgroundColor: defaultBackgroundColor,
        borderRadius: 4,
        zIndex: 1
    },
    statusColor: {
        height: cardHeight,
        position: 'absolute',
        top: 0,
        left: -1,
        width: 5,
        zIndex: 2,
        borderRadius: 4,
        borderLeftStyle: 'solid',

    },
    arrowUp: {
        cursor: 'pointer',
        transition: 'transform .2s ease-in-out',
        color: '#ABADBA',
        fontSize: 25,
        marginRight: -4
    },
    arrowDown: {
        cursor: 'pointer',
        transition: 'transform .2s ease-in-out',
        transform: 'rotate(180deg)',
        color: theme.customColors.primary.main,
        fontSize: 25,
        marginRight: -4
    },
    title: {
        maxWidth: 200,
        display: 'block',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        fontFamily: 'Rubik',
        fontWeight: '500',
        fontSize: 13,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212C',
        minHeight: 14
    },
    date: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 9,
        marginTop: 6,
        fontFamily: 'Rubik',
        fontWeight: '400',
        fontSize: 10,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.gray
    },
    dueDate: {
        marginLeft: 4,
        "fontFamily": "Rubik",
        fontWeight: '400',
        fontSize: 10,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.newUI.color.gray
    },

    headerInfo: {
        width: '100%',
    }, 
    dot: {
        width: 3,
        height: 3,
        borderRadius: '50%',
        backgroundColor: theme.newUI.color.gray,
        marginLeft: 4,
    },

})
const OngoingCard = (props) => {
    const {classes, card} = props
    const {doc} = card
    const [open, setOpen] = useState(false)
    const [t] = useTranslation('userOngoing')

    const dateAgo = moment(doc.docTimestamp).fromNow(false)

    const toggleOpen = () => {
        setOpen(prev => !prev)
    }
    const statusColor = StatusColorMap[doc.TaskStatusColor]
    const statusColorStyle = {
        borderBottomLeftRadius: open ? 0 : 4,
        borderLeftColor: statusColor,
    }
    const headerStyle = {
        backgroundColor: lighten(statusColor, 0.95)
    }
    const styling = getDueDateStyling(doc.dueDateUTC, doc.taskDueDateDone)
    const dueDateStatus = getDueDateStatus(doc.dueDateUTC, doc.taskDueDateDone)

    const Header = (
        <>
        <div data-intrcm-id="OngoingCard_ojayj5ge" style={statusColorStyle} className={classes.statusColor}/>
        <div data-intrcm-id="OngoingCard_90toln3r" style={headerStyle} onClick={toggleOpen} className={classes.header}>
            <div data-intrcm-id="OngoingCard_4sgvgmr9" className={classes.headerInfo}>
                <div data-intrcm-id="OngoingCard_0uuhd8ou" className={classes.title}>{doc.title || t('Untitled')}</div>
               <div data-intrcm-id="OngoingCard_ygvwlo9k" className={classes.date}>
                    <div data-intrcm-id="OngoingCard_b4ihzmc7" className={classes.dateAgo}>{dateAgo}</div>
                    {doc.dueDateUTC ?  (
                    <>
                        <div data-intrcm-id="OngoingCard_le1em4im" className={classes.dot} ></div>
                        <div data-intrcm-id="OngoingCard_k51m35el" style={{color: dueDateStatus === "regular"  ? '#0C0C0D' : styling.backgroundColor}} className={classes.dueDate}>{`Due ${format(new Date(doc.dueDateUTC), 'dd/MM/yyyy' )}`}</div> 
                    </>)
                     : null}
                </div>
            </div>
            {open ?
                <KeyboardArrowDown className={classes.arrowDown} /> :
                <KeyboardArrowDown className={classes.arrowUp} />}
        </div>
        </>

    )

    return (
        <div data-intrcm-id="OngoingCard_ppwky4qp" className={`${classes.container} ongoing-card`}>
            {Header}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <KanbanCard 
                        withContainerClassName 
                        containerClassName={classes.containerCard}
                        card={card} 
                        isDraggingOver={false} />
                </List>
            </Collapse>
        </div>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(OngoingCard)