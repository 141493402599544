import React, { Component, Fragment } from 'react';
import './OrgCharts.css';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import NodeItem from './NodeItem';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import HelpButtonIcon from '../../../components/Common/HelpButton/HelpButtonIcon';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    container: {
        overflow: 'auto',
        width: '100%',
        height: 'calc(100% - 102px)'
    },
    helpButtonText: {
        marginRight: 8,
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
    helpButtonContainer: {
        flexDirection: 'row-reverse',
        margin: '58px 0 0 16px'
    }
})

class OrgCharts extends Component {


    constructor(props) {
        super(props)

        this.state = {
            nodes: [],
            users: []
        }
        this.containerRef = React.createRef()
    }


    componentDidMount() {
        if (this.containerRef.current) {
            const { scrollWidth, offsetWidth } = this.containerRef.current

            const middleScreen = (scrollWidth - offsetWidth) / 2

            this.containerRef.current.scroll({
                top: 0,
                left: middleScreen,
                behavior: 'smooth'
            });
        }
    }
    findRoot = (users) => {
        const firstAdmin = users.filter(u => u.role === "Admin").sort((a, b) => Date.parse(a.creationDateTime) - Date.parse(b.creationDateTime))[0]

        let currentUser = firstAdmin
        const findQuery = u => u.id === currentUser.superiorId
        while (currentUser.superiorId !== -1) {
            currentUser = users.find(findQuery)
        }

        return currentUser
    }

    componentWillMount() {
        const users = this.props.users.map(u => ({ ...u }))

        users.forEach(user => {
            user.children = users.filter(r => r.superiorId === user.id)
        });



        users.forEach(user => {
            this.count(user, user)
        });

        const root = this.findRoot(users)

        const unassignedUsers = users.filter(u => u.superiorId === -1 && u.id !== root.id)
        root.children = root.children.concat(unassignedUsers)

        this.setState({ nodes: root, users })
    }

    count(source, current) {
        source.sumChildren = source.sumChildren || 0
        source.sumChildren += current.children.length

        current.children.map(child => this.count(source, child))
    }

    closeNode = (node) => {
        node.children = []

        const nodes = this.initNode(this.state.nodes)
        this.setState({ nodes })
    }

    openNode = (node) => {
        node.children = []

        const originNode = this.findNode(this.state.nodes, node.id)
        node.children = originNode.children
        const nodes = this.initNode(this.state.nodes)
        this.setState({ nodes })
    }


    initNode = (node) => {
        node.children = node.children || []

        const res = { ...node }

        res.children = node.children.map(n => this.initNode(n))

        return res

    }



    findNode = (node, id) => {
        node.children = node.children || []

        if (node.id === id) {
            return node
        } else {
            const found = node.children.find(n => n.id === id)
            if (found) {
                return found
            } else {
                node.children.map(n => this.findNode(n, id))
            }
        }


    }

    renderComponent = ({ node }) => {
        return <NodeItem node={node} />
    };

    render() {
        const { classes, t } = this.props

        return (
            <Fragment>
                <HelpButtonIcon link='articles/3914465' classes={{
                    container: classes.helpButtonContainer
                }}>
                    <div data-intrcm-id="OrgCharts_ipkyizpk" className={classes.helpButtonText}>{t("Learn more")}</div>
                </HelpButtonIcon>
                <div data-intrcm-id="OrgCharts_m0fzcrn9" id="initechOrgChart" ref={this.containerRef} className={classes.container}>
                    <OrgChart tree={this.state.nodes} NodeComponent={this.renderComponent} />
                </div>
            </Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    users: state.globalData.activeUsers
})



export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading User, Please Contact Tuqqi support"),
    withTranslation('orgCharts'),
    connect(
        mapStateToProps
    ))(OrgCharts);
