import React from "react";

export const TooltipContent = ({ task, fontSize, fontFamily, classes,t }) => {
      const style = {
        fontSize,
        fontFamily,
      };
      return (
        <div data-intrcm-id="TooltipContent_cozceh5m"
         className={classes.tooltipContainer} 
         style={style}>
             <div data-intrcm-id="TooltipContent_003unf0g" className={classes.tooltipTitle}>
                  {task.name}
              </div>
                    {task.end.getTime() - task.start.getTime() !== 0 && (
            <p 
            className={classes.tooltiParagraph}
            >
                {`${t('Duration:')} ${~~(
              (task.end.getTime() - task.start.getTime()) /
              (1000 * 60 * 60 * 24)
            )} ${t('day(s)')} (${task.start.getDate()}.${
              task.start.getMonth() + 1
          }.${task.start.getFullYear()} - ${task.end.getDate()}.${
              task.end.getMonth() + 1
          }.${task.end.getFullYear()})`}</p>
          )}
    
          <p 
          className={classes.tooltiParagraph}
          >
            {!!task.progress && `${t('Progress:')} ${task.progress} %`}
          </p>
        </div>
      );
    };
  