import { FULL_RESET_STORE } from '../actionTypes/actionTypes';

const initialState = {
    chatRoomFromUserPage: false,
    room: null
};

const chatRoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
                return initialState
        case 'SET_CHAT_ROOM': 
            return {
                ...state, 
                chatRoomFromUserPage: action.chatRoomFromUserPage, 
                room: action.chatRoomFromUserPage ? action.room : null
            }
        default:
            return state
    }
}

export default chatRoomReducer