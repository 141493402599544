import React, { Component } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { push, goBack } from 'react-router-redux';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

// Apply some reset
const styles = theme => ({
    drawer: {
        position: 'fixed',
        left: 0,
        top: 60,
        zIndex: 5
    },
    withDrawer: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 280
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    withoutDrawer: {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    fullViewWithDrawer:{
        marginRight: 27,
        width: `calc(100% - ${theme.constants.leftDrawerWidth}px)`,
        marginLeft: theme.constants.leftDrawerWidth,
    },
    left:{
        minWidth: 280
    },
    noLeft:{

    }
});


class PageContentWrapper extends Component {
    render() {
        const { classes, children, drawer, isFull } = this.props;

        return (
            <div data-intrcm-id="pageContentWrapper_petho3wk" className={isFull ? classes.fullViewWithDrawer : ''}>
                <span className={classes.drawer}>
                    {drawer}
                </span>
                <Grid container>
                    {isFull ? <div data-intrcm-id="pageContentWrapper_cay5qvwo"></div> : <Grid xs={3} item className={drawer ? classes.left: classes.noLeft}/>}
                    <Grid xs={isFull ? 12 : 6} >
                        {children}
                    </Grid>
                    {isFull ? <div data-intrcm-id="pageContentWrapper_c7f190ul"/> : <Grid xs={3} item/>}

                </Grid>
                {/* <div data-intrcm-id="pageContentWrapper_f46oc1u7" className={drawer ? classes.withDrawer : classes.withoutDrawer}>
                {children}
                </div> */}
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(PageContentWrapper);
// export default withStyles(styles)(InputFAB);

