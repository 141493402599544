import { Menu, MenuItem,Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import MoreIcon from '../../../../../components/Common/Icons/Gantt/ThreeDotsGanttIcon';
import withErrorCatcher from '../../../../../components/Common/withErrorCatcher';
import { compose } from 'recompose';
import { showConfirm, showSnackbar } from '../../../../../../actions/appActions';

const styles = {
    ww: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        cursor: 'pointer',
        marginRight: 4,
        marginLeft: 4,
        width: 20,
        height: 20,
        '&:hover': {
            background: '#EDEDF0!important'
        }
    },
    icon: {
        transform: 'rotate(90deg)',
        scale: 0.85,
        display: 'flex',
        // marginLeft: 8,
        cursor: 'pointer',
    },
    menu: {
        width: 255,
        marginTop: 12
    }
}

const useStyles = makeStyles(styles)

export const LaneMenu = (props) => {
    const classes = useStyles()
    const { onClickAddCard,dispatch, deleteLane,isAdmin, isRootRow, isLane, isDefaultLane } = props
    const [t] = useTranslation("gantt")

    const styleObserverRef = useRef(null)
    const [anchor, setAnchor] = useState(null)


    const confirmedDeleteLane = useCallback(() => {
        dispatch(showSnackbar('Deleting...'));
        deleteLane()
        setAnchor(null)
    }, [deleteLane])

    const handleClickAddCard = () => {
        onClickAddCard()
        setAnchor(null)
    }
    const handleDeleteLane = () => {
        dispatch(showConfirm((!isLane ? t("Are you sure you want to delete this task? this action can't be undone") : t("Are you sure you want to delete this group of tasks? this action can't be undone")), confirmedDeleteLane))
    } 


    const renderAddCard = () => {
        return <div data-intrcm-id="LaneMenu_5exnxpmp" onClick={handleClickAddCard} >
                <MenuItem>
                    <Typography variant='body2'>{isLane ? t("Add task +") : t('Add group of tasks +')}</Typography>
                </MenuItem>
            </div>
    
    }
  
   const renderDelete = () => {
    return   <div data-intrcm-id="LaneMenu_ltzv386b" onClick={handleDeleteLane}>
        <MenuItem>
                <Typography variant='body2'>{t('Delete')}</Typography>
            </MenuItem>
        </div>
    }
    
    const clearObserver = () => {
        if(!styleObserverRef.current) return
        styleObserverRef.current.disconnect()
        styleObserverRef.current = null
    }

    useEffect(() => {
        if(!anchor) {
            clearObserver()
            return
        }
        return () => {
            clearObserver()
        }
    }, [anchor])

    const handleOpen = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setAnchor(e.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null)
    }

    return <><div data-intrcm-id="LaneMenu_9ztlyihp">
        <div className={classes.ww} onClick={handleOpen}>
        <div data-intrcm-id="LaneMenu_oziuqtwq" id={props.id} className={classes.icon}>
                <MoreIcon />
        </div>
        </div>
        <Menu 
            classes={{ paper: classes.menu }}
            anchorEl={anchor}
            open={!!anchor}
            onClose={handleClose}
            elevation={8} >
            {renderAddCard()}
            {!isRootRow && !isDefaultLane && renderDelete()}
        </Menu>
    </div>
       </> 
}

export default compose(withErrorCatcher())(LaneMenu);

    
