import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    dialogSubTitle: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
        fontSize: 14,
        letterSpacing: 0.4,
        textAlign: 'center'
    },
});

class NotSelectedUsersList extends Component {
    render(){
        const { subTitle, button, classes } = this.props;

        return <Grid container justify='space-between' >
            <Grid item style={{ paddingLeft: 32, paddingBottom: 8 }}>
                <span className={[classes.textBasicStyle, classes.dialogSubTitle].join(" ")}>
                    {subTitle}
                </span>
            </Grid>
            {button ? <Grid item style={{ paddingRight: 24, paddingBottom: 8 }}>
                {button}
            </Grid> : <div data-intrcm-id="SubTitle_75wz5eb7" />}
        </Grid>
    }
}

export default compose(withStyles(styles),withTranslation('mustReadListModal'))(NotSelectedUsersList);