import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { themeConstants } from '../../../theme.js';



// Apply some reset
const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    container: {
        height: '100%',
        marginLeft:  themeConstants.navigationDrawerWidth+ 5,
    },
    container1: {
        height: '100%',
        marginLeft: themeConstants.navigationDrawerSmallWidth + 5,
    },
    noResultFound: {
        marginLeft: themeConstants.navigationDrawerWidth + 5,
    }
});
class MyPageDashboardView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            feedPage: 0,
            results: [],
            assigned: [],
            unassigned: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            hadEmptyResult: false,
            data: { lanes: [] },
            openCardDialog: false,
            view: {},
            groupInfoLoaded: false,
            openCardDialog: false,
            docCardClicked: null,
            tableItems: [],
            searchResultCount: 0,
            dataTypes: [],
        }
    }


    componentDidMount() {
        const { userSettingsService  } = this.props;
        userSettingsService.getUserDashboardToken().then((res) => {

            window.Cumulio.addDashboard({
                dashboardId: '411d1edf-355d-44eb-b50e-fc11c7c027e5',
                container: '#my-dashboard',
                key: res.id,
                token: res.token,
                language: 'en'
            });
        })
    }


    render() {
        const { isLoading, results } = this.state;
        const { classes, isDrawerOpen } = this.props;

        return (
            <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="MyPageDashboardx_yxc5pw00" className={isDrawerOpen ? classes.container : classes.container1}>
                    <div data-intrcm-id="MyPageDashboardx_m2ackekq" id={"my-dashboard"}>

                    </div>
                </div>
            </CategoryViewOnlyLeftDrawer>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    router: state.router,
    ...state.services,
    viewOptions: state.viewOptions,
    wrapper: state.wrapper,
    classifications: state.globalData.classifications,
    knowledgelevels: state.globalData?.knowledgelevels ?? [],
    globalData: state.globalData,
    collectionInfo: state.results.channel,
    oldUiData: state.oldUiData,
    searchQuery: state.search,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MyPageDashboardView);

