import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import LaunchpadIcon from "../../Common/Icons/launchpad/LaunchpadIcon";
import {getLaunchpadKeysLabel} from '../../../../utils/launchpadNavigation/launchpadUtils'
import { hexToRGB } from '../../../../utils/hexToRGB';
import withErrorCatcher from '../../Common/withErrorCatcher';
import UserAvatar from '../../Common/UserAvatar';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => {
return ({
    launchpadButton: { 
        marginRight: 0,
        padding:0
    },

    launchpadIconContainer: {
        height: 0,
        width: 0,
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    avatar: {
        width: 30,
        height: 30,
        border: '0px solid #FFFFFF',
    },
    // badge: {
    //     maxWidth: 20,
    //     padding: 0,
    //     top: 22,
    //     right: 2
    // },
    marginAvatar: {
        // marginRight: 24
    },
})
}

class SettingsToolbar extends PureComponent {

    oponUserSettings = () => {
        const { dispatch } = this.props;
        dispatch({ type: 'TOGGLE_USER_MODAL' })
    }

    render() {
        const { classes, t, profileData } = this.props;

        return (
            <Tooltip title={t('Settings')} placement="bottom">
                <IconButton onClick={this.oponUserSettings} data-intrcm-id="SettingsToolbar_d8778s8s"  classes={{  root: classes.launchpadButton }} aria-label="Settings">

                {/* <IconButton onClick={this.oponUserSettings} data-intrcm-id="SettingsToolbar_d8778s8s"  classes={{ label: classes.launchpadIconContainer, root: classes.launchpadButton }} aria-label="Settings"> */}
                    {/* <UserAvatar
                        useNewui
                        onClick={this.oponUserSettings}
                        userUUID={profileData.auth0_id}
                        variant='header'
                        classes={{ avatar: classes.avatar,  margin: classes.marginAvatar }} /> */}
                        <Avatar className={classes.avatar} src={profileData.profileImageUrl} />

                        
                </IconButton>
            </Tooltip>

        )
    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher('Launchpad crashed'),
    withStyles(styles),
    withTranslation('feedToolbar'),
    connect(mapStateToProps, mapDispatchToProps)
)(SettingsToolbar);