import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { OldPagesWrap } from '../newway/OldPagesWrap';


const styles = theme => ({
    content: {
        marginTop: '0px!important',
        marginRight: '32px!important',
        marginLeft: '32px!important',
    },
    container: {
        maxWidth: 'none!important'
    },
})

class TimeTrackingReport extends React.Component {

    componentDidMount() {
        // this.loadData();
        this.props.collaborationService.getTimeTrackingReport().then((res) => {            

            window.Cumulio.addDashboard({
                loader: {
                    background: '#fff',
                },
                dashboardId: 'a168c486-0f3f-473f-9208-e7d2ae7b2e5b',
                container: '#my-dashboard',
                key: res.id,
                token: res.token,
                language: 'en'
            });
        })
    }

    render() {
        const { classes } = this.props;

        return <OldPagesWrap className={`admindashboardtuqqi ${classes.container}`} classNameContent={classes.content} >
            <div data-intrcm-id="TasksReport_kr3xmbpq" id={"my-dashboard"} />
        </OldPagesWrap>
    }
}
const mapStateToProps = (state) => ({
    ...state.services,
    templates: state.globalData.templates,
    token: state.userData.idToken,

})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TimeTrackingReport);