import React from 'react'
import StoryNext from './StoryNext'
import StoryPrev from './StoryPrev'

const StoryControls = (props) => {
    const {prevClass, nextClass} = props

    return <>
        <StoryNext wrapClass={nextClass} />
        <StoryPrev wrapClass={prevClass} />
    </>
}

export default StoryControls