import { withStyles } from '@material-ui/core';
import { GroupSettings, NewUiWrapper, setLastCreatingGroup } from '@tuqqi/web';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { store } from '../../../..';
import { themeConstants } from '../../../../theme';
import {CreateGroupDrawer} from './LeftDrawer';
import { showSnackbar } from '../../../../actions/appActions';
import { withMixpanel } from '../../../components/Common/withMixpanel';
import { withTranslation } from 'react-i18next';
import parseImagUrl from "../../../components/Common/parseImagUrl";
import groupPlaceholder from "../../../../assets/img/group_placeholder.png"
import { mapCollectionToGroupNavigation } from '../../../../utils/groupsNavigation/collectionToGroupNavigation';
import { loadUserData } from '@tuqqi/common';
import { SERVER_API } from '../../../../utils/serverAPI';
import { userDataLoad } from '../../../../actions/globalDataAction';
import { goToCollection } from "../../../../actions/goToActions";
import { goBack } from 'react-router-redux';


const styles = theme => ({
    container: {
        width: 'auto',
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        overflow: 'hidden',
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
        marginTop: -12,
    },
})

class CreateGroupView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isDrawerOpen: false
        }
    }

    onCreateGroup = (newCollection) => {
        const { userSettingsService, groupService, dispatch, mixpanelTrack, t } = this.props;
        dispatch(showSnackbar(t('Creating')));

        mixpanelTrack('Create group', {})

        groupService.createCollection(newCollection).then((updatedCollection) => {
            setLastCreatingGroup(updatedCollection?.collectionGroup?.groupUid, updatedCollection?.collectionUid ?? '')
            const backgroundUrl = parseImagUrl(this.props.cloudfront, updatedCollection.backgroundUrl, 100, groupPlaceholder);
            dispatch({
                type: 'ADD_SEARCH_MANAGED_GROUP',
                group: mapCollectionToGroupNavigation({ ...updatedCollection, backgroundUrl })
            })

            dispatch({
                type: 'RESULT_CHANNEL_UPDATE', results: {
                    channel: updatedCollection,
                }
            })

            dispatch(showSnackbar(t('Created!')));
            goToCollection('/' + updatedCollection.title, dispatch, updatedCollection.collectionUid, updatedCollection.collectionGroup.groupUid, updatedCollection)();

            userSettingsService.getUserData().then((userData) => {
                dispatch(loadUserData(SERVER_API))
                dispatch(userDataLoad(userData))
                dispatch({ type: 'RESULT_FILTERS_UPDATE', results: { dataTypes: [],  relatedDocs: [], groups: [], experts: [] } });
            })
        })    
    }

    onCancelCreateGroup = () => {
        const { dispatch } = this.props;
        dispatch(goBack())
    }

    handleSwitchDrawerStatus = (status) => {
        this.setState({isDrawerOpen: status})
    }

    render() {
        const {classes} = this.props
        const {isDrawerOpen} = this.state

        const callbacks = {
            onCreateGroup: this.onCreateGroup,
            onCancelCreateGroup: this.onCancelCreateGroup,
        }

        return <Fragment>
            <CreateGroupDrawer handleSwitchDrawerStatus={this.handleSwitchDrawerStatus} isDrawerOpen={isDrawerOpen}/>
            <div data-intrcm-id="CreateGroupView_lk4q7l9f" className={classes.container} style={{marginLeft: isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth  : themeConstants.navigationDrawerSmallPXWidth}}>
                <NewUiWrapper store={store} callbacks={callbacks}>
                    <GroupSettings drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth  : themeConstants.navigationDrawerSmallPXWidth} isNewGroup />
                </NewUiWrapper>
            </div>
        </Fragment>
    }



    componentDidMount() {

    }

}

const mapStateToProps = (state, ownProps) => ({
    groupService: state.services.groupService,
    queryService: state.services.queryService,
    userSettingsService: state.services.userSettingsService,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withStyles(styles), 
    withMixpanel,
    withTranslation('newGroupDialog'),
    connect(mapStateToProps,mapDispatchToProps)
)(CreateGroupView);