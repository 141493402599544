import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { managerGanttRef, managerTableKeepGlobalData } from '@tuqqi/web'
import { isRTLValue } from '@tuqqi/web'
import { useTranslation } from 'react-i18next'
import { hexToRGB } from '@tuqqi/common'

const styles = theme => ({
    input: {
        padding: 0,
        paddingRight: 8,
        paddingLeft: 8,
        outline: 0,
        border: 0,
        backgroundColor: '#EDEDF0',
        color: theme.palette.common.black,
        width: '100%',
        resize: 'none',
        ...theme.typography.body2,
        fontSize: 13, 
        'font-feature-settings': "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    inputWrap: {
        width: 'fit-content',
        background: '#EDEDF0',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 12,
        marginRight: 12,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        border: `1px solid transparent`,
    },
    singleLine: {
        height: 28,
        alignItems: 'center',
    },
    errorInput: {
        border: `1px solid ${'#ff4848'}`,
        boxShadow: `0px 0px 4px ${hexToRGB('#ff4848', 0.16)}`
    },
    activeInput: {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 4px ${hexToRGB(theme.palette.primary.main, 0.16)}`
    },
})

const getActualWidth = (record) => {
    if(!managerGanttRef.gantt) return 10
    const column = managerGanttRef.gantt.instance.columns.visibleColumns.find(x => x.type === 'name')
    if(!column) return 10
    if(typeof column.width !== 'number') return 10
    let gap = 50
    if(record.data.isRootRow) {
        gap = 30
    }
    if(record.data.isLane) {
        gap = 30
    }
    return column.width - gap - 10 - 24
}


const Edit = memo((props) => {
    const [activeInput, setActiveInput] = useState(false)
    const refInput = useRef(null);
    const {classes, value} = props
    const [t] = useTranslation("gantt")
    const label = managerTableKeepGlobalData.tTable('Title')
    const actualWidth = getActualWidth(props.record)

    useEffect(() => {
        refInput.current?.focus();
    }, []);

    const wrapStyle = useMemo(() => {
        return {
            width: actualWidth
        }
    }, [actualWidth])
  
    const onChange = useCallback((event) => {
        props.setValue(event.target.value)
    }, [])

    const onFocusInput = useCallback(() => {
        setActiveInput(true)
    }, [])

    const onBlurInput = useCallback(() => {
        setActiveInput(false)
    }, [])

    const placeholder = `${t("Add")} ${label.toLowerCase()}...`

    const dir = useMemo(() => {
        if(!value && isRTLValue(placeholder)) return 'rtl'
        if(isRTLValue(value)) return 'rtl'
        return 'auto'
    }, [placeholder, value])

    const onClickAway = () => {
        props.onClose()
    }


    const onKeyDownHadnler = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation()
            props.onClose()
            return false
        }
        return true
    }


    const Input = <div style={wrapStyle} data-intrcm-id="CellRenderTitleEdit_xthyeepp" dir={dir} className={`${classes.inputWrap} ${classes.singleLine} ${activeInput ? classes.activeInput : ''}`}>
        <input
            dir={dir}
            type="text"
            placeholder={placeholder}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            ref={refInput}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDownHadnler}
            className={classes.input}
        />
    </div>
  
      return <ClickAwayListener onClickAway={onClickAway} >
        {Input}
      </ClickAwayListener>
})

export default withStyles(styles, {withTheme: true})(Edit)