import { Icon, withStyles } from '@material-ui/core';
import mimeTypes from 'mime-types';
import React, { Component } from 'react';
import withErrorCatcher from './withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import parseImageUrl from './parseImagUrl';
import { getDataType } from './Utils/defaultDataTypes.js';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    container: {
        width: 40,
        height: 40,
        borderRadius: 4,
        backgroundColor: '#e8e8e8',
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)"
    },
    img: {
        width: 40, 
        height: 40, 
        marginBottom: 0, 
        borderRadius: 4
    },
    event: {
        color: theme.customColors.primary.main
    },
    mail:{
        color: '#c81e1e'
    },
    file: {
        width: 20, height: 24
    },
    icon: {
        color: theme.customColors.primary.b300
    }
})

export function getFileImg(mimeType) {
    let existsExtentions = [
        'AAC', 'AI', 'BMP', 'CDR', 'DOC', 'DOCX', 'EPS', 'GIF', 'JPG', 'MKV',
        'MOV', 'MP3', 'MPEG', 'MPG', 'PDF', 'PNG', 'PPT', 'PS', 'PSD', 'SVG',
        'TRF', 'TXT', 'WMA', 'XLS', 'XLXS', 'ZIP', 'sketch'
    ].map(e => e.toLowerCase())

    let extension = 'file'
    if (mimeType && mimeType.length) {
        extension = mimeTypes.extension(mimeType) || 'file'
        if (!existsExtentions.includes(extension)) {
            extension = 'file'
        }
    }

    const url = `https://app.tuqqi.com/system/fileicons/${extension}.png`
    return url
}

class ItemPreviewImgOrIcon extends Component {
    render() {
        const { document, inputMappings, cloudfront, classes, t } = this.props;
        const { previewImgUrl, dataType, mimeType, link } = document;
        let icon = '';
        let imageUrl = previewImgUrl ? previewImgUrl : '';
        let content = <div data-intrcm-id="ItemPreviewImgOrIcon_7zphenl5" />;

        const docDataType = getDataType(dataType, t);

        // types without preview image or default icon
        switch (dataType) {
            case 'media':
                if (mimeType.includes("video")) {
                    icon = "videocam";
                }
                else {
                    imageUrl = link;
                }
                break;
            case 'file': content = <img src={getFileImg(document.mimeType)} className={classes.file} />; break;
            case 'event': content = <Icon className={classes.event}>{docDataType.icon}</Icon>; break;
            case 'mail': content = <Icon className={classes.mail} >{docDataType.icon}</Icon>; break;
            default:
                const inputMapping = inputMappings.find(mapping => mapping.customDataMapping.dataTypeFilter == dataType)
                icon = inputMapping ? inputMapping.icon : ''
                break;
        }

        if (imageUrl) {
            const processedImageUrl = parseImageUrl(cloudfront, imageUrl, 50)
            content = <img className={classes.img} src={processedImageUrl} />
        }
        else if (icon || (docDataType && docDataType.icon)) {
            content = <Icon className={classes.icon} >{icon || docDataType.icon}</Icon>
        }

        return <div data-intrcm-id="ItemPreviewImgOrIcon_a4pxr9qq" className={classes.container}>
            {content}
        </div>
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    inputMappings: state.globalData.inputMappings,
})

export default compose(withStyles(styles, { withTheme: true }), 
    withTranslation('inputTypes'), 
    connect(mapStateToProps)
)(ItemPreviewImgOrIcon)