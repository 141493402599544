import { withStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/KeyboardArrowLeft';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import GeneralButton from '../../../Common/AllButton/AllButton';
import { hexToRGB } from '../../../../views/groups/Calendar/event-utils.js';

const styles = (theme) => ({
    container: {
        height: 56,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        paddingLeft: 16,
        borderBottom: '#D7D7DB 1px solid'
    },
    title: {
        marginLeft: 16,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.2,
        color: '#0C0C0D'
    },
    back: {
        cursor: 'pointer',
        fontSize: 20,
        color: '#737373',
    },
    icon: {
        fontSize: 16,
        marginRight: 8,
        marginTop: -2,
    },
    icon1: {
        color: theme.customColors.primary.main
    },
    icon2: {
        color: theme.customColors.primary.main
    },
    buttonText: {
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0.4,
    },
    buttonContainer: {
        marginRight: 0,
        paddingRight: 12,
        paddingLeft: 12,
        width: 'fit-content',
        height: 30,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.16),
            color: theme.customColors.primary.main,
        },
    },
})

function _EditLaunchpadHeader(props) {
    const {classes, dispatch, isAddingCategory, editCategory} = props
    const [t] = useTranslation("search")
    const [isEnterAdd, setIsEnterAdd] = useState(false)

    const onClickBack = useCallback(() => {
        dispatch({
            type: 'SET_LAUNCHPAD_PAGE_TYPE',
            pageType: 'landing',
            selectedCategory: 0
        })
    }, [])

    const onAdd = useCallback(() => {
        dispatch({
            type: 'IS_ADDING_CATEGORY',
        })
    }, []) 

    const AddButton = <GeneralButton disabled={isAddingCategory || editCategory !== 0} setIsEnter={setIsEnterAdd} onClick={onAdd} icon={<div data-intrcm-id="EditLaunchpadHeader_xbbahc4d" className={`${classes.icon} ${isEnterAdd ? classes.icon2 : classes.icon1}`} >+</div>} containerStyle={classes.buttonContainer} textStyle={classes.buttonText} title={t("Add new category")} />

    return (
        <div data-intrcm-id="EditLaunchpadHeader_hr9pjq06" className={classes.container} >
            <ArrowBack onClick={onClickBack} className={classes.back} />
            <div data-intrcm-id="EditLaunchpadHeader_vw72n0g4" className={classes.title}>{`${t("Edit launchpad")}`}</div>
            {AddButton}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAddingCategory: state.launchpadNavigation.isAddingCategory,
    editCategory: state.launchpadNavigation.editCategory,
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export const EditLaunchpadHeader = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(_EditLaunchpadHeader)

