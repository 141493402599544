import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions"
import { goToPath } from "./navigationHelpers"
import { navOptions, getDatatypesState } from "./navigationHelpers"
import {mixpanelTrack} from '../../ui/components/Common/Utils/mixpanelUtils'

export const userContext = {
    mode: navOptions.USER_FOUND,
    overview: {
        id: 0,
        pathname: '/user/overview'
    },
    calendar: {
        id: 1,
        pathname: '/user/calendar'
    },
    ongoing: {
        id: 2,
        pathname: '/user/ongoing'
    },
    statistics: {
        id: 3,
        pathname: '/user/statistics'
    },
    
    datatype: (prop) => `/user/${prop}`
}

export const getUserNavigationOpts = (t, dispatch) => [
    {
        id: userContext.overview.id,
        icon: 'Overview',
        title: t("Feed"),
        onClick: () => {
            mixpanelTrack(`User page view ${userContext.overview.pathname}`, {})
            dispatch(selectDrawerNavigationOption(userContext.overview.id))
            goToPath(userContext.overview.pathname, dispatch) 
        }
    },
    {
        id: userContext.calendar.id,
        icon: 'Calendar',
        title: t("Calendar"),
        isDisabled: false,
        onClick: () => {
            mixpanelTrack(`User page view ${userContext.calendar.pathname}`, {})
            dispatch(selectDrawerNavigationOption(userContext.calendar.id))
            goToPath(userContext.calendar.pathname, dispatch) 
        }
    },
    {
        id: userContext.ongoing.id,
        icon: 'Ongoing',
        title: t("Ongoing Tasks"),
        isDisabled: false,
        onClick: () => {
            mixpanelTrack(`User page view ${userContext.ongoing.pathname}`, {})
            dispatch(selectDrawerNavigationOption(userContext.ongoing.id))
            goToPath(userContext.ongoing.pathname, dispatch) 
        }
    },
    {
        id: userContext.statistics.id,
        icon: 'Statistics',
        title: t("Statistics"),
        isDisabled: true,
        onClick: () => {
            mixpanelTrack(`User page view ${userContext.statistics.pathname}`, {})
            dispatch(selectDrawerNavigationOption(userContext.statistics.id))
            goToPath(userContext.statistics.pathname, dispatch)
        }
    },
]


export const getUserDrawerFromPath = (pathname = '') => {
    const mode = userContext.mode

    if(pathname.includes(userContext.overview.pathname)) return [mode, userContext.overview.id]
    if(pathname.includes(userContext.calendar.pathname)) return [mode, userContext.calendar.id]
    if(pathname.includes(userContext.ongoing.pathname)) return [mode, userContext.ongoing.id]
    if(pathname.includes(userContext.statistics.pathname)) return [mode, userContext.statistics.id]

    return [mode, userContext.overview.id]
}

export const getMoreDatatypes = () => {
    const dataTypes = getDatatypesState()
    return dataTypes.map(d => d.Key).filter(key => key !== 'All')
}