import React, { PureComponent } from 'react';

export default class NicknameIcon extends PureComponent {

    render() {
        const { iconColor } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4.66699 12V11.3333C4.66699 9.4924 6.15938 8 8.00033 8C9.84126 8 11.3337 9.4924 11.3337 11.3333V12" stroke={iconColor} stroke-linecap="round"/>
            <path d="M8 8C9.1046 8 10 7.1046 10 6C10 4.89543 9.1046 4 8 4C6.8954 4 6 4.89543 6 6C6 7.1046 6.8954 8 8 8Z" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 2.4V13.6C14 13.8209 13.8209 14 13.6 14H2.4C2.17909 14 2 13.8209 2 13.6V2.4C2 2.17909 2.17909 2 2.4 2H13.6C13.8209 2 14 2.17909 14 2.4Z" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }
}