import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, IconButton, Icon } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { isUserOrgAdmin } from "../Common/Utils/userPermissionsUtils";
class DeleteButton extends Component {

    render() {
        const { profileData, creatorId, onDelete } = this.props;

        if (creatorId !== profileData.auth0_id &&
            !isUserOrgAdmin(profileData)) {
            return null
        } else {
            return (
                <div data-intrcm-id="deleteButton_rq7qyign" style={{ paddingLeft: 9 }}><span>{'· '}</span>
                    <IconButton style={{ padding: 0 }} onClick={onDelete} aria-label="delete">
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.38)' }} >delete_outline </Icon>
                    </IconButton>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData
})

export default compose(
    connect(
        mapStateToProps
    ))(DeleteButton);