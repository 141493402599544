import React, { useEffect, useRef, useState } from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { closeStory, nextStory, nextStoryScene, getStoryControlNextAction, STORY_CONTROL_TYPES } from '@tuqqi/common'
import useBackground from './useBackground'
import defaultBackground from '../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Public.png'

const styles = (theme) => ({
    video: {
        position: 'relative',
        margin: 'auto',
        objectFit: 'cover',
        height: 840,
        width: 478,
    },
      thumbWrap: {
        overflow: 'hidden',
        width: 'inherit',
        height: 'inherit',
        position: 'absolute',
      },
      viewContainer: {
        zIndex: -1,
        width: 478,
        height: 840,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
})

const SCENE_TIMEOUT = 10000

const SceneVideo = (props) => {
    const {classes, storiesNavigation, dispatch} = props
    const timeOutRef = useRef(null)
    const [currentStory, setCurrentStory] = useState(null)
    const storiesRef = useRef([])
    const {stories, currentStoryId, currentScene} = storiesNavigation
    const {id: sceneId = '', backgroundUrl: sceneLink} = currentScene
    const { backgroundUrl } = useBackground(sceneLink)

    useEffect(() => {
        clearTimeout(timeOutRef.current)
        timeOutRef.current = setTimeout(() => {
            onEndedScene()
        }, SCENE_TIMEOUT)

        return () => {
            clearInterval(timeOutRef.current);
        };

    }, [sceneId, onEndedScene])

    useEffect(() => {
        storiesRef.current = stories
    }, [stories])

    useEffect(() => {
        const story = storiesRef.current.find(s => s.user.auth0_id === currentStoryId)
        setCurrentStory(story)
    }, [currentStoryId])

    const onEndedScene = () => {
        const actionType = getStoryControlNextAction({
            sceneId, 
            currentStoryId,
            stories
        })
        switch (actionType) {
            case STORY_CONTROL_TYPES.NEXT_SCENE:
                return dispatch(nextStoryScene())
            case STORY_CONTROL_TYPES.NEXT_STORY:
                return dispatch(nextStory())
            case STORY_CONTROL_TYPES.NEXT_HIDE:
            default:
                return dispatch(closeStory())
        }
    }

    if(!currentStory) return null;


    return <div data-intrcm-id="SceneVideo_7yo1aw3c" className={classes.viewContainer}>
            <div data-intrcm-id="SceneVideo_2huss3zz" className={classes.thumbWrap}>
                <img src={backgroundUrl || defaultBackground} className={classes.video} />
            </div>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
    cloudfront: state.globalData.cloudfront
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(SceneVideo)