import { InputBase, withStyles } from '@material-ui/core'
import { postChatMessage, postComment } from '@tuqqi/common'
import React, { useCallback, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { randomID } from '../../../../../../utils/randomID'
import { SERVER_API } from '../../../../../../utils/serverAPI'
import { DirectMessages, Discussions, GroupMessages } from '../../../../../views/chat/chatUtils'
import { hexToRGB } from '../../../../../views/groups/Calendar/event-utils'
import GeneralButton from '../../../../Common/AllButton/AllButton';

const styles = (theme) => ({
    container: {
        background: '#fff',
        marginTop: 8,
        marginBottom: 8,
        marginRight: 16,
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonContainer: {
        marginRight: 0,
        paddingRight: 9,
        paddingLeft: 12,
        width: 'fit-content',
        height: 26,
        backgroundColor: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: '#fff',
        '&:hover': {
            background: hexToRGB(theme.customColors.primary.main, 0.86),
        },
    },
    buttonText: {
        marginBottom: -1,
        fontSize: 12,
        fontWeight: '400',
        letterSpacing: 0.4,
    },
    input: {
        paddingLeft: 6,
        paddingRight: 6,
        backgroundColor: '#EDEDF0',
        width: '100%',
        marginRight: 8,
        height: 28,     
        borderRadius: 4,
        fontFamily: 'Rubik',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
    },
    inputInner: {
        padding: 0,
    },
})
const _Reply = (props) => {
    const {classes, t, option, dispatch, setIsReply, userData} = props
    const [answer, setAnswer] = useState('')

    const edit = useCallback((e) => {
        setAnswer(e.target.value)
    }, [])

    const addComment = useCallback((docId, text) => {
        const tempId = randomID()
        if(!docId) return

        setTimeout(() => dispatch(postComment(SERVER_API)(docId, text, [], tempId)), 100)
    }, [])

    const addMessage = useCallback((chatUId, text) => {
        const tempId = randomID()
        if(!chatUId) return

        setTimeout(() => dispatch(postChatMessage(SERVER_API)(chatUId, text, userData.profileData, [], tempId)), 100)
    }, [userData.profileData])

    const addGroupMessage = useCallback((chatUId, text) => {
        const tempId = randomID()
        if(!chatUId) return

        setTimeout(() => dispatch(postChatMessage(SERVER_API)(chatUId, text, userData.profileData, [], tempId, {isGroupChat: true})), 100)
    }, [userData.profileData])

    const handleAddAnswer = useCallback(() => {
        if(!answer.trim()) return

        switch (option.chatType) {
            case Discussions:
                addComment(option?.document?.docId ?? '', answer.trim())
                break;
            case DirectMessages:
                addMessage(option?.chat?.chatUId ?? '', answer.trim())
                break;
            case GroupMessages:
                addGroupMessage(option?.chat?.chatUId ?? '', answer.trim())
                break;
        }
        setIsReply(false)
        setAnswer('')
    }, [answer, option, addComment, addMessage, addGroupMessage])

   const onKeyDown = useCallback((e) => {
        const enterKeyCode = 13
        if (e.keyCode === enterKeyCode) {
            handleAddAnswer()
        }
    }, [handleAddAnswer])

    const Input = <InputBase
        inputProps={{style: {color: '#0C0C0D'}}}
        autoFocus={false} //important  
        classes={{
            root: classes.input,
            input: classes.inputInner,
        }} 
        placeholder={`${t('Enter quick answer')}...`}
        value={answer}
        onChange={edit}
        onKeyDown={onKeyDown}
    />

    const Send = <GeneralButton onClick={handleAddAnswer} containerStyle={classes.buttonContainer} textStyle={classes.buttonText} title={t("Send")} />

    return <div data-intrcm-id="Reply_f9inzrxg" className={classes.container}>
        {Input}
        {Send}
    </div>
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('chat'),
    connect(mapStateToProps, mapDispatchToProps),
)(_Reply)