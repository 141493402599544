import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import MyPageLeftDrawer from '../search/Components/MyPageLeftDrawer';
import { MyPageLeftDrawerNew } from '../search/Components/MyPageLeftDrawerNew';
import { checkIfChannel, checkIfQuestion, filterData, getTagInfo, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../search/searchUtils';


const styles = theme => ({

})

class MyPageView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isDrawerOpen: true
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.getTagInfo = getTagInfo.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.checkIfChannel = checkIfChannel.bind(this);
        this.checkIfQuestion = checkIfQuestion.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    handleSwitchDrawerStatus = (status) => {
        this.setState({isDrawerOpen: status})
    }

    render() {
        const { children } = this.props

        return <Fragment>
                    <MyPageLeftDrawerNew handleSwitchDrawerStatus={this.handleSwitchDrawerStatus} isDrawerOpen={this.state.isDrawerOpen}/>
                    {React.cloneElement(children, {isDrawerOpen: this.state.isDrawerOpen})}
                </Fragment>
    }



    componentDidMount() {

    }

}

const mapStateToProps = (state, ownProps) => ({
    ...state.wrapper,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    ...state.services,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles), connect(
    mapStateToProps,
    mapDispatchToProps
))(MyPageView);