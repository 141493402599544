import React from 'react'
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './css/mainFeedPlaceholder.css'
import { useTranslation } from 'react-i18next';

const styles = theme => ({

    header: {
        margin: 0,
        paddingTop:16,
        paddingBottom: 16
    },
    button: {
        textTransform: 'unset',
        marginTop: '10px'
    },
    shareItems: {
        color: theme.customColors.primary.b700,
    },
});

export default withStyles(styles, { withTheme: true })((props) => {
    const { classes } = props;

    const { t } = useTranslation('feedWidgets');

    return <Grid container className={classes.header} >

        <Grid item >
            <div data-intrcm-id="TrendingWidgetPlaceholder_5t4r8nk0" className={"mainfeedplaceholder"}>
                <div data-intrcm-id="TrendingWidgetPlaceholder_rydyojkl" className="whatsgoingon">
                    <div data-intrcm-id="TrendingWidgetPlaceholder_788rtwoy" className="rectanglecopy4">
                    </div>
                    <div data-intrcm-id="TrendingWidgetPlaceholder_im08qpg8" className="afteryouu2019llsharei">
                        <span className="span1">{t('After you’ll')}</span><a href='https://help.tuqqi.com/en/articles/5387267-types-of-items' target="_blank"><span className={classes.shareItems}>{t('share items')}</span></a><span className="span3">,{t('the system will automaticlly')}<a href='https://intercom.help/tuqqi/en/articles/3918201-introduction-to-the-knowledge-channel-tag' target="_blank"><span className="span4">{t('tag')}</span></a>{t('them, the most common tags will be shown here')}.</span>
                        
                    </div>
                    <div data-intrcm-id="TrendingWidgetPlaceholder_1p65rf9d" className="trendingup">
                        <img alt="Image" src="/resources/placeholders/img/mypage-placeholder-path-3@2x.png" className="path" />
                        <img alt="Image" src="/resources/placeholders/related_channels_icon.svg" className="ud83dudd39iconcolor" />
                    </div>
                </div>
            </div>
        </Grid>



    </Grid>
})