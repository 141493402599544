import { Divider, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import React, { Component } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { compose } from 'recompose';
import UserListItemCustom from "../../../Common/UserListItemCustom";
import SubTitle from './SubTitle';
import Checkbox from '@material-ui/core/Checkbox'
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "../../../Common/spinner";
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    button: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSspacing: 1.1,
        textAlign: 'center',
        color: theme.customColors.primary.b600,
    },
    listItem: {
        paddingRight: 0,
        paddingLeft: 0
    }
});

class NotSelectedUsersList extends Component {
    state = {
        page:0
    }
    selectAllLabel = () =>{
        const { t, allSelected } = this.props;
        return allSelected ? t("Clear") : t("Select All")
    }

    render() {
        const { notSelectedUsers, classes, title, isSelectAllbutton, handleToggle, checked, handleSelectAll} = this.props;
        const delta = 20;

        let selectAllButton = isSelectAllbutton 
            ? <Button onClick={handleSelectAll} classes={{root : classes.button}}>
                {this.selectAllLabel()}
            </Button> : null;

        return notSelectedUsers && notSelectedUsers.length 
            ? <div data-intrcm-id="NotSelectedUsersList_sglqtark">
                <SubTitle subTitle={title} button={selectAllButton}/>
                <Divider />
                <List style={{ paddingLeft: 24, paddingRight: 24 , maxHeight: 700, overflow: 'auto' }}>
                    <InfiniteScroll
                        useWindow={false}
                        pageStart={0}
                        loadMore={(page) => { this.setState({ page: this.state.page + 1 }) }}
                        hasMore={(this.state.page * delta) < notSelectedUsers.length}
                        loader={<Spinner classes={classes} />}
                    >

                        {notSelectedUsers.slice(0, (this.state.page) * delta).map((user, idx) => (
                            <UserListItemCustom 
                                disabled={false}
                                isButton={true}
                                user={user}
                                key={idx}
                                onClick={() => handleToggle(user.auth0_id)}
                                classes={classes}
                                rightElement={<Checkbox 
                                    tabIndex={-1} 
                                    disableRipple 
                                    onChange={() => handleToggle(user.auth0_id)}
                                    checked={checked.indexOf(user.auth0_id) !== -1} 
                                />} 
                            />
                        ))}
                    </InfiniteScroll>
                </List>
            </div>
            : <div data-intrcm-id="NotSelectedUsersList_9gmww0e4" />
    }
}

export default compose(withStyles(styles, { withTheme: true }),withTranslation('mustReadListModal'),)(NotSelectedUsersList);