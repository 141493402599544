import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import UserAvatar from './UserAvatar';
import withErrorCatcher from './withErrorCatcher.js';
import PropTypes from 'prop-types'

class SwappingMembersAvatar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentUserIndex: 0,
            interval: null
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    componentDidMount() {
        const changeIconEvery = 3000
        const interval = setInterval(() => {
            const { currentUserIndex } = this.state
            if (currentUserIndex === this.props.userUUIDs.length - 1) {
                this.setState({ currentUserIndex: 0 })
            } else {
                this.setState({ currentUserIndex: currentUserIndex + 1 })
            }
        }, changeIconEvery)
        this.setState({ interval })
    }

    render() {
        const { userUUIDs, userAvatarProps, classes } = this.props
        const { currentUserIndex } = this.state

        return <UserAvatar {...userAvatarProps}
            classes={classes}
            userUUID={userUUIDs[currentUserIndex]} />
    }
}

SwappingMembersAvatar.propTypes = {
    userUUIDs: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.object,
    userAvatarProps: PropTypes.userAvatarProps
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    users: state.globalData.users
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(
    withErrorCatcher("Failed Loading User, Please Contact Tuqqi support"),
    connect(mapStateToProps, mapDispatchToProps))(SwappingMembersAvatar);