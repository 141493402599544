import React, { Component, PureComponent } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { showSnackbar, showConfirm } from '../../../../actions/appActions';
import { USER_DATA_LOAD, DATA_UPDATE } from "../../../../actionTypes/actionTypes";
import { updateSpecificItem } from '../../../../actions/dataActions.js';


class Favourites extends PureComponent {

    addToFavourites = () => {
        const { dispatch, favouritesService, docId, userSettingsService, onFavourites, onClick, t } = this.props;

        favouritesService.addToFavourites(docId).then(() => {
            userSettingsService.getUserData().then((userData) => {
                dispatch({ type: USER_DATA_LOAD, data: { ...userData } });

                let snackbarMassage = "Added to favorites!"
                onFavourites && onFavourites();
                dispatch(showSnackbar(t(snackbarMassage)));
                dispatch(updateSpecificItem(docId));
                onClick()
            })
        });
    }

    removeFromFavourites = () => {
        const { dispatch, favouritesService, docId, userSettingsService, onFavourites, onClick, t } = this.props;

        dispatch(showSnackbar(t('Removing')));

        favouritesService.removeFromFavourites(docId).then((massage) => {
            userSettingsService.getUserData().then((userData) => {
                dispatch({ type: USER_DATA_LOAD, data: { ...userData } });
                onFavourites && onFavourites();
                dispatch(showSnackbar(t('Removed!')));
                dispatch({ type: DATA_UPDATE });
                onClick()
            })
        });
    }

    render() {
        const { savedItems, docId, t, render, textToAdd, textToRemove } = this.props;

        const isAlreadyFavourites = savedItems.includes(docId)

        return React.cloneElement(render, {
            onClick: isAlreadyFavourites ? this.removeFromFavourites : this.addToFavourites
        }
            , isAlreadyFavourites ? textToRemove || t('Remove from favorites') : textToAdd || t('Add to favorites')
        )

    }
}

Favourites.defaultProps = {
    onClick: () => { }
};

const mapStateToProps = (state) => ({
    savedItems: state.userData.savedItems,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withTranslation('itemPreviewCardHeaderMenu'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Favourites);

