import { LOCATION_CHANGE } from 'react-router-redux';
import {  CLEAN_FILTER, FULL_RESET_STORE,  SEARCH_QUERY, SEARCH_QUERY_UPDATE,SET_RELATED_DOC_IDS } from '../actionTypes/actionTypes';
import {ON_GO_TO_CHAT} from '@tuqqi/web'

export const SORT_ORDER = {
    Ascending: 'asc',
    Descending: 'desc'
}
export const SORT_TYPE = {
    Default: 'default',
    Str: 'str',
    ArrLength: 'arrLength'
}

export const initFilter = () => ({
    isUnseen: false,
    tags: [],
    users: [],
    dataTypes: [],
    labels: [],
    colors: [],
    groups: [],
    dateFrom: null,
    dateTo: null,
    isAuthor: false,
    page: 0,
    isOrgCalendar: false,
    isMyItem: false,
    isTrending: false,
    hasLocation: false,
    sortField: '',
    sortOrder: null,
    sortType: null,
    confirmed: false,
    mustread: false,
    mustReadMyRequests: false,
    relatedDocIds: [],
    laneStatusTitle: [],

})
const initialState = {
    recentItems: [],
    recentGroups: [],
    recentUsers: []
};
const initialState1 = {
    ...initFilter(),
    isCollection: false,
    isMyPage: false,
    isFocused: false,
    originText: '',
    selectedReportName: '',
    collectionUid: '',
    collectionLabel: '',
    collection: null,
    isUserPage: false,
    collectionTitle: '',
    groupUid: '',
    isChannel: false,
    isMyItem: false,
    isOrgCalendar: false,
    query: '',
    page: 0,

};

const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState;
        case ON_GO_TO_CHAT:
            return {...state, ...initialState1};
        case SEARCH_QUERY:
            // Clear query filter
            action.query.filter = {};
            return {
                ...state,
                ...action.query,
            }

        case SEARCH_QUERY_UPDATE:
            return {
                ...state,
                ...action.query,
            }



        case CLEAN_FILTER:
            return {
                ...state,
                ...initFilter(),
                selectedReportName: ''
            }

        case LOCATION_CHANGE:
            if (action.payload.pathname === 'feed' || action.payload.pathname === '/feed') {
                return {
                    ...state,

                    // TODO 
                    // bug : when user go out form collection those params are not inited 
                    // the temporary solution is init when the pathname changed to feed 
                    // but the real solution is to init them when go out from collection anywhere
                    collectionUid: '',
                    collection: null,
                    collectionLabel: '',
                    collectionTitle: '',
                    groupUid: '',
                    isChannel: false,
                    isUserPage: false,
                    isCollection: false,
                    isMyPage: false,
                    isOrgCalendar: false,
                    page: 0,
                    query: '',
                    ...initFilter(),
                    selectedReportName: ''
                }
            } if (action.payload.pathname.includes('mypage')) {

                let newState = {
                    ...state,
                    ...initFilter(),
                    selectedReportName: '',
                    collectionUid: '',
                    collectionLabel: '',
                    collection: null,
                    isUserPage: false,
                    collectionTitle: '',
                    groupUid: '',
                    isChannel: false,
                    isCollection: false,
                    isMyPage: true,
                    isMyItem: true,
                    isOrgCalendar: false,
                    query: '',
                    
                }

                if (action.payload.pathname.includes('tasks')) {
                    newState.colors = [30, 40]
                }

                return newState
            }
            if (action.payload.pathname.includes('user')) {

                let newState = {
                    ...state,
                    ...initFilter(),
                    selectedReportName: '',
                    isUserPage: true,
                    collectionUid: '',
                    collectionLabel: '',
                    collection: null,
                    collectionTitle: '',
                    groupUid: '',
                    isChannel: false,
                    isCollection: false,
                    isMyPage: false,
                    isMyItem: false,
                    isOrgCalendar: false,
                }
                return newState
            }
            if (action.payload.pathname.includes('orgcalendarview')) {

                let newState = {
                    ...state,
                    ...initFilter(),
                    selectedReportName: '',
                    isUserPage: false,
                    collectionUid: '',
                    collectionLabel: '',
                    collection: null,
                    collectionTitle: '',
                    groupUid: '',
                    isChannel: false,
                    isCollection: false,
                    isMyPage: false,
                    isMyItem: false,
                    isOrgCalendar: true,
                }
                return newState
            }
            else {
                return {
                    ...state,
                    ...initFilter()
                }
            }
        case 'SEARCH_LEFT_OFFSET_CHANGE': {
            return {
                ...state,
                searchLeftOffset: action.searchLeftOffset
            }
        }
        case 'SEARCH_INPUT_FOCUSED': {
            return {
                ...state,
                isFocused: action.isFocused
            }
        }
        case 'TOGGLE_INPUT_FOCUSED': {
            return {
                ...state,
                isFocused: !state.isFocused
            }
        }
        case "TOGGLE_USER_MODAL":
        case "TOGGLE_CREATENEW_MODAL":
        case "TOGGLE_CHATS_MODAL":
        case "TOGGLE_NOTIFICATIONS_MODAL":
        case "TOGGLE_LAUNCHPAD": {
            return {
                ...state,
                isFocused: false
            }
        }
        case 'SEARCH_REPORT_NAME': {
            return {
                ...state,
                selectedReportName: action.reportName
            }
        }
        case 'SEARCH_QUERY_REMOVE_MY_PAGE': {
            return {
                ...state,
                isMyPage: false
            }
        }
        case 'SEARCH_QUERY_INPUT': {
            return {
                ...state,
                query: action.query
            }
        }
        case 'SEARCH_QUERY_ORIGIN_TEXT': {
            return {
                ...state,
                originText: action.originText
            }
        }
        case 'SEARCH_QUERY_REMOVE_GROUP': {
            return {
                ...state,
                isCollection: false,
                collectionUid: null,
                groupUid: null,
                isMyPage: false,
            }
        }

        // case 'SEARCH_START':
        //     return {
        //         ...state,
        //         startSearch: true
        //     }
        // case 'SEARCH_END':
        //     return {
        //         ...state,
        //         startSearch: false
        //     }

        default:
            return state
    }
}

export default SearchReducer