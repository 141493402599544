import { selectDrawerNavigationOption, setDrawerNavigationSubMenu } from "../../actions/drawerNavigationActions"
import { goToPath } from "./navigationHelpers"
import { navOptions, getDatatypesState, getInputMappingsState } from "./navigationHelpers"
import {mixpanelTrack} from '../../ui/components/Common/Utils/mixpanelUtils'
import { defaultDataTypes } from "../../ui/components/Common/Utils/defaultDataTypes"
import i18n from "../../i18n"

export const searchContext = {
    mode: navOptions.SEARCH,
    home: {
        id: 0,
        pathname: '/search/all'
    },
    media: {
        id: 1,
        pathname: '/search/media'
    },
    files: {
        id: 2,
        pathname: '/search/file'
    },
    post: {
        id: 3,
        pathname: '/search/post'
    },
    location: {
        id: 4,
        pathname: '/search/location'
    },
    link: {
        id: 5,
        pathname: '/search/bookmark'
    },
    more: {
        id: 6,
        pathname: '/search/datatype'
    },
    datatype: (prop) => `/search/${prop}`
}
const getExistedDatatypes = t => ([t("Media"), t("Files"), t("Post"), t("Location"), t("Link")])

export const getSearchNavigationOpts = (t, dispatch) => [
    {
        id: searchContext.home.id,
        icon: 'Search',
        title: t("All"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.home.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.home.id))
            goToPath(searchContext.home.pathname, dispatch) 
        }
    },
    {
        id: searchContext.media.id,
        icon: 'Media',
        title: t("Media"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.media.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.media.id))
            goToPath(searchContext.media.pathname, dispatch) 
        }
    },
    {
        id: searchContext.files.id,
        icon: 'File',
        title: t("Files"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.files.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.files.id))
            goToPath(searchContext.files.pathname, dispatch) 
        }
    },
    {
        id: searchContext.post.id,
        icon: 'Post',
        title: t("Post"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.post.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.post.id))
            goToPath(searchContext.post.pathname, dispatch)
        }
    },
    {
        id: searchContext.location.id,
        icon: 'Locations',
        title: t("Location"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.location.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.location.id))
            goToPath(searchContext.location.pathname, dispatch)
        }
    },
    {
        id: searchContext.link.id,
        icon: 'Link',
        title: t("Link"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.link.pathname}`, {})
            dispatch(selectDrawerNavigationOption(searchContext.link.id))
            goToPath(searchContext.link.pathname, dispatch)
        }
    },
    {
        id: searchContext.more.id,
        icon: 'More',
        title: t("More"),
        onClick: () => {
            mixpanelTrack(`Search page view ${searchContext.more.pathname}`, {})
            const dataTypes = getMoreDatatypes()
            dispatch(setDrawerNavigationSubMenu(buildSubMenu(dataTypes, t, dispatch, searchContext.datatype), searchContext.more.id))
            dispatch(selectDrawerNavigationOption(searchContext.more.id))
        }
    }
]

const buildSubMenu = (arrList, t, dispatch, getDatatype) => {
    const inputMappings = getInputMappingsState()
    const allMappings = [...inputMappings]
    const existedTitles = getExistedDatatypes(t)
    return arrList
        .filter(prop => {
            const mapping = allMappings.find(m => m.customDataMapping.dataTypeFilter === prop)
            return !!mapping && !existedTitles.includes(prop)
        })
        .map((prop, i) => {
            const mapping = allMappings.find(m => m.customDataMapping.dataTypeFilter === prop)
            const o = {
                dataType: prop,
                icon: null,
                id: `sub-${i}`,
                onClick: () => {
                    mixpanelTrack(`Search page view ${getDatatype(prop)}`, {})
                    goToPath(getDatatype(prop), dispatch)
                }
            }
            o.icon = mapping.icon
            o.title = i18n.t(`inputTypes:${mapping.title}`)
        
            return o
        })
}

export const getSearchDrawerFromPath = (pathname = '') => {
    const mode = searchContext.mode

    if(pathname.includes(searchContext.media.pathname)) return [mode, searchContext.media.id]
    if(pathname.includes(searchContext.files.pathname)) return [mode, searchContext.files.id]
    if(pathname.includes(searchContext.post.pathname)) return [mode, searchContext.post.id]
    if(pathname.includes(searchContext.location.pathname)) return [mode, searchContext.location.id]
    if(pathname.includes(searchContext.link.pathname)) return [mode, searchContext.link.id]
    if(pathname.includes(searchContext.more.pathname)) return [mode, searchContext.more.id]
    return [mode, searchContext.home.id]
}

export const getMoreDatatypes = () => {
    const dataTypes = getDatatypesState()
    return dataTypes.map(d => d.Key).filter(key => key !== 'All')
}