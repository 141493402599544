import { makeStyles } from '@material-ui/styles'
import { NewUiWrapper, NotificationPreviewMoreMenu } from '@tuqqi/web'
import React, { useMemo } from 'react'
import { store } from '../../../../..'

const styles = (theme) => ({
    moreMenu: {
        alignSelf: 'flex-start',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: 24,
        height: 24,
        borderRadius: 4,
        '&:hover': { 
            backgroundColor: '#fff', 
        }
    },
    linear: {
        width: 72,
        top: 0,
        height: 'inherit',
        position: 'absolute',
        right: -12,
        background: 'linear-gradient(270deg, #eae5fb 33.33%, rgba(123, 79, 240, 0) 100%)'
    },
    container: {
        background: '#fff',
        position: 'absolute',
        right: 12,
        paddingRight: 0,
        height: 'inherit',
        marginRight: 0,
    },
    container1: {
        background: '#eae5fb',
        right: 0,
        width: '100%',
        justifyContent: 'flex-end',
        paddingRight: 0,
        height: 'inherit',
        marginRight: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    btn: {
        marginTop: 8,
        width: 24,
        cursor: 'pointer',
        height: 24,
        marginLeft: 39,
        background: '#fff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
    },
    btnWrap: {
        marginTop: 8,
        width: 24,
        cursor: 'pointer',
        height: 24,
        marginLeft: 8,
        background: 'transparent',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

const useStyles = makeStyles(styles)

export const NotificationActions = (props) => {
    const {wrapHeight, notification, onCloseMenu} = props
    const classes = useStyles()

    
    const callbacks = useMemo(() => ({
        markAsReadNotification: props.markAsReadNotification,
        onUserNotificationsSettings: props.onGoToSettings,
    }), [props.onGoToSettings, props.markAsReadNotification])

    const Menu = <div data-intrcm-id="NotificationActions_r26g01p5" className={classes.linear}>
        <div data-intrcm-id="NotificationActions_qbmufrh5" className={classes.btn}>
            <NewUiWrapper store={store} callbacks={callbacks}>
                <NotificationPreviewMoreMenu onClose={onCloseMenu} iconClassName={classes.moreMenu} notification={notification}  />
            </NewUiWrapper>
        </div>
    </div>

    return <div data-intrcm-id="NotificationActions_8chq36f0" style={{height: wrapHeight}} className={classes.container}>
        <div data-intrcm-id="NotificationActions_fsqqc1ul" style={{height: wrapHeight}} className={classes.container1}>
            {Menu}
        </div>
    </div>
}