import { enUS, he, es, ru, ar, it, pl, ptBR, pt, uk, fr, de } from 'date-fns/locale'


export const getDateFnsLocale = (lang) => {
    switch (lang) {
        case 'en-US':
            return enUS
        case 'he-IL':
            return he
        case 'es-AR':
            return es
        case 'ru':
            return ru
        case 'ar':
            return ar
        case 'it':
            return it
        case 'pl':
            return pl
        case 'gr':
            return enUS
        case 'pt-BR':
            return ptBR
        case 'pt':
            return pt
        case 'uk-UA':
            return uk
        case 'fr':
            return fr
        case 'de':
            return de
    
        default:
            return enUS
    }
}
