import { FULL_RESET_STORE, TOPIC_MAIN_VIEW } from '../actionTypes/actionTypes'

const initialState = {
  mainView : 'items',
}

const topicView = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case TOPIC_MAIN_VIEW:
      return {
        ...state,
        ...initialState,
        mainView:action.mainView,
    }
    default:
    return state

    // return {
    //     ...state,
    //     ...initialState,
    // }
  }
}

export default topicView