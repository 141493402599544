import { withStyles } from '@material-ui/core';
import React from 'react'
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import { connect } from 'react-redux';
import { ArrowExternal } from './ArrowExternal';


// Apply some reset
const styles = theme => ({
    container: {
        marginTop: 4,
        background: '#fef9f9',
        border: '1px solid #fdd5d4',
        padding: '8px 12px',
        paddingBottom: 12,
        borderRadius: 4
    },
    title: {
        color: '#B91C1C',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
    },
    subTitle: {
        marginTop: 4,
        color: '#B91C1C',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '16px',
    },
    lineup: {
        marginTop: 12,
        position: 'relative',
        background: '#F871711A',
        height: 8,
        borderRadius: 2,
        width: '100%',
    },
    breakup: {

    },
    count25: {
        left: 0,
        position: 'absolute',
        background: '#F871714D',
        height: 8,
        borderRadius: 2,
        width: '25%',
    },
    count50: {
        left: 0,
        position: 'absolute',
        background: '#F871714D',
        height: 8,
        borderRadius: 2,
        width: '50%',
    },
    count75: {
        left: 0,
        position: 'absolute',
        background: '#F871714D',
        height: 8,
        borderRadius: 2,
        width: '75%',
    },
    count100: {
        left: 0,
        position: 'absolute',
        background: '#F871714D',
        height: 8,
        borderRadius: 2,
        width: '100%',
    },
    count0: {

    },
    btn: {
        cursor: 'pointer',
        display: "flex",
        marginTop: 12,
        background: '#EF4444',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between",
        padding: '8px 12px',
        borderRadius: 6,
        height: 28,
        width: '77%'
    },
    btnText: {
        color: '#FFFFFF',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        cursor: 'pointer',
        marginRight: 12,
        whiteSpace: 'noWrap',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '16px',
    },
    arrow: {
        minWidth: 20
    }
});


const _TuqqiSetup = (props) => {
    const {classes, t, count, } = props

    const countClass = count === 25 ? classes.count25 :
        count === 50 ? classes.count50 :
            count === 75 ? classes.count75 :
            count === 100 ? classes.count100 : classes.count0

    return <div className={classes.container}>
        <div className={classes.title}>{t("Tuqqi Setup")}</div>
        <div className={classes.subTitle}>{
            <Trans i18nKey='drawerListItemTerms' values={{count}}>
                You’ve completed <strong>{{count}}%</strong> of your setup. Let’s finish it all!
            </Trans>
}
        </div>
        <div className={classes.lineup}>
            <div className={`${classes.breakup} ${countClass}`}/>
        </div>
        <div className={classes.btn}>
            <div className={classes.btnText}>{t("Onboarding Center")}</div>
            <div className={classes.arrow}>
                <ArrowExternal />
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({dispatch})

export const TuqqiSetup = compose(
    withStyles(styles, { withTheme: true }),
    withErrorCatcher(),
    withTranslation('drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps)
)(_TuqqiSetup);