import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import NoResultFound from '../../components/Common/noResultFound.js';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { filterData, loadData, loadMore, loadMoreQuery } from "../search/searchUtils";
import { buildTable, loadGroupInfo } from './groupUtils';
import TableView from "./table/TableView";
import { UserSuccessLaneStats } from './GroupDashboard/UserSuccessLaneStats'
import { themeConstants } from '../../../theme.js';



// Apply some reset
const styles = theme => ({

    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'
    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    container: {
        height: '100%',
        marginLeft:  themeConstants.navigationDrawerBigPXWidth+ 5,
    },
    container1: {
        height: '100%',
        marginLeft: themeConstants.navigationDrawerSmallPXWidth + 5,
    },
    noResultFound: {
        marginLeft: themeConstants.navigationDrawerBigPXWidth + 5,
    }
});
class GroupDashboardView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            dataReloadInidicator: 0,
            feedPage: 0,
            results: [],
            assigned: [],
            unassigned: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            hadEmptyResult: false,
            data: { lanes: [] },
            openCardDialog: false,
            view: {},
            groupInfoLoaded: false,
            openCardDialog: false,
            docCardClicked: null,
            tableItems: [],
            searchResultCount: 0,
            dataTypes: [],
        }


        // Bind the utils
        this.loadData = loadData.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this)
        this.loadMoreQuery = loadMoreQuery.bind(this)

        this.buildTable = buildTable.bind(this)
        this.filterData = filterData.bind(this)
        this.loadMore = loadMore.bind(this)
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.setState({ isLoading: true });

            Promise.all([this.loadData(nextProps.oldUiData.dataUpdate, nextProps), this.loadGroupInfo(nextProps)])
                .then(this.initTable)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true });
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps)
                .then(this.initTable);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore(nextProps).then(this.initTable);
        }
    }

    componentDidMount() {
        const { urlQuery, searchQuery, dispatch, userData, collectionInfo } = this.props;
        if (urlQuery !== '') {
            dispatch({ type: SEARCH_QUERY_UPDATE })
            dispatch({ type: DATA_UPDATE })
        } else {
            dispatch(push('feed'));
        }

        // Load data
        this.props.groupService.getGroupPremiumDashboardToken(collectionInfo.id).then((res) => {
            window.Cumulio.addDashboard({
                dashboardId: res.dashboardUid,
                container: '#my-dashboard',
                key: res.id,
                token: res.token,
                language: 'en'
            });
        }).catch(() => {
            this.props.groupService.getGroupDashboardToken(collectionInfo.collectionUid).then((res) => {

                window.Cumulio.addDashboard({
                    dashboardId: '6b6ef11e-1189-4ded-9848-0e79742013ef',
                    container: '#my-dashboard',
                    key: res.id,
                    token: res.token,
                    language: 'en'
                });
            })
        })




    }

    loadChartsData = () => {

    }



    render() {
        const { isLoading, results } = this.state;
        const { classes, isDrawerOpen } = this.props;

        return (
            <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="groupDashboardView_p1jwfjon" className={isDrawerOpen ? classes.container : classes.container1}>
                    <div data-intrcm-id="groupDashboardView_9cpxbcjp" id={"my-dashboard"}>

                    </div>
                </div>
            </CategoryViewOnlyLeftDrawer>)
    }
}

const mapStateToProps = (state) => ({

    userData: state.userData,
    router: state.router,
    ...state.services,
    viewOptions: state.viewOptions,
    wrapper: state.wrapper,
    classifications: state.globalData.classifications,
    knowledgelevels: state.globalData?.knowledgelevels ?? [],
    globalData: state.globalData,
    collectionInfo: state.results.channel,
    oldUiData: state.oldUiData,
    searchQuery: state.search,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}


export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GroupDashboardView);

