import React, { memo, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import AssignEdit from './AssignEdit'
import { isEqualJson } from '@tuqqi/common'
import { Faces } from './Faces'
import { TableFieldReadOnlyTooltip, managerGanttRef } from '@tuqqi/web'

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    containerEdit: {
        outline: 'auto!important',
        outlineColor: '#7B4FF0!important',
    },
    container: {
        height: 40,
        position: 'relative',
        boxShadow: 'none',
        marginRight: -4,
        marginLeft: -4,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: 'initial',
        outline: 'none',
        width: '-webkit-fill-available',
    },
})

const checkApi = (record, originalValue = [], newValue = []) => {
    const originalIds = originalValue.map(x => x.uid)
    const newIds = newValue.map(x => x.uid)
    if(isEqualJson(originalIds, newIds)) return

    if(!record) return
    record.set('assignedResource', newValue)
    managerGanttRef.updateMembersApi(record, newValue)
}

const AssignUsers = memo((props) => {
    const [open, setOpen] = useState(false)
    const {classes} = props
    const [valueLocal, setValueLocal] = useState(props.value)
    const containerRef = useRef()

    const disabled = !props.record?.data?.editable?.common

    useEffect(() => {
        setValueLocal(props.value)
    }, [props.value])
    
    const handleOpenMembers = (e) => {
        if(!!disabled) return
        if(open) return
        setOpen(true)
    }

    const onClose = () => {
        if(!open) return
        checkApi(props.record, props.value, valueLocal)
        setOpen(false)
    }
    

    const X = <div onClick={handleOpenMembers} ref={containerRef} className={`${classes.container} ${open ? classes.containerEdit : ''} ${!disabled ? classes.cursor : ''}`}>
        <Faces value={valueLocal} />
        {open && <AssignEdit value={valueLocal} containerRef={containerRef} onChange={setValueLocal} onClose={onClose} />}
    </div>

    if(disabled) return <TableFieldReadOnlyTooltip component={X} />

    return X
})

export default withStyles(styles, {withTheme: true})(AssignUsers)