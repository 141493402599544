import { Button, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../Common/withErrorCatcher.js';

// Apply some reset
const styles = theme => ({
    container: {
        height: 28,
        paddingBottom: 16
    },
    text: {
        color: '#17394d',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 500,
    },
    button: {
        textTransform: 'capitalize'
    },
    titleContainer: {
        width: 'initial',
        // flex:'1 1 auto'
    }
});

class ChecklistHeader extends Component {

    state = {
        isInEditMode: false,
        newTitle: ''
    }

    editTitle = () => {
        this.setState({ isInEditMode: !this.state.isInEditMode })
    }

    render() {
        const { classes, changeTitle, title, resetTasks, t } = this.props;

        return (
            <Grid container>
                <Grid className={classes.titleContainer} container alignItems='center' item xs={8} onDoubleClick={this.editTitle} >
                    {
                        this.state.isInEditMode ?
                            <TextField
                                InputProps={{ fullWidth: true }}
                                onBlur={this.state.isInEditMode && this.editTitle}
                                fullWidth={true}
                                placeholder='Title'
                                value={title}
                                onChange={changeTitle} /> :
                            <Typography component='h3' className={classes.text}>
                                {title || "Checklist"}
                            </Typography>
                    }
                </Grid>
                <Grid item xs={4} className={classes.addTask} style={{ marginLeft: 'auto', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button className={classes.button} onClick={resetTasks}>
                        {t('Reset')}
                    </Button>
                    <Button className={classes.button} onClick={this.props.addTask}>
                        {t('Add Task')}
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    item: state.fullView.item,
    collaborationService: state.services.collaborationService,
    resetChecklistUid: state.confirmDialog.resetChecklistUid,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('taskList'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChecklistHeader);