import React from 'react'
import { Column, ColumnStore } from '@bryntum/gantt';
import { DragIcon } from './DragIcon';

export default class DragColumn extends Column {
    static get $name() {
        return 'DragColumn';
    }

    static get type() {
        return 'drag';
    }

    static get isGanttColumn() {
        return true;
    }

    static get defaults() {
        return {
            field      : 'drag',
            text       : '',
            editor     : false,
            cellCls    : '',
            htmlEncode : false,
        };
    }


    renderer({ record }) {
        // const {assignedResource, originalData: {isItemRow }, project: { resourceStore }} = record;

        return <DragIcon />
    }
}

ColumnStore.registerColumnType(DragColumn);
