import { Divider, withStyles, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ItemAuthor from '../../FullView/ModalComponents/Author/ItemAuthor';
import RelatedItemsReport from '../../RelatedReport/RelatedItemsReport';
import Spinner from '../../Common/spinner'
import Favourites from './Favourites';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
        cursor: 'pointer'
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: 24,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
        paddingBottom: 10
    },
    titleText: {
        fontWeight: 'bold'
    },
    content: {
        overflowY: 'hidden',
        height: '100%',
        padding: 0
    },
    title: {
        paddingBottom: 16
    },
    spinner: {
        textAlign: 'center',
        marginTop: 30
    },
    addToFavorites: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontStretch: 'normal',
        fontWeight: 'normal',
        marginTop: 8,
        backgroundColor: theme.customColors.primary.b600,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.4,
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none'
    },
    paper: {
        maxWidth: '65%',
        height: 'calc(100% - 96px)'
    }
});

class AlertDialog extends React.Component {
    state = {
        open: false,
        results: [],
        isLoading: true,
        isLoadingMore: false,
        hasMore: false,
        page: 0,
        experts: [],
        relatedTags: [],
        duplicates: [],
        bestResults: [],
        resultByDataTypes: [],
        filterTags: []
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    // loadMore = () => {

    //     const { queryService, docId, document } = this.props;

    //     queryService.getRelated(docId, document, this.state.page + 1)
    //         .then((result) => {
    //             var hasMore = this.state.hasMore;
    //             if (result.count < (this.state.page + 1) * 5) {
    //                 hasMore = false;
    //             }
    //             this.setState({ hasMore: hasMore, searchResultCount: result.count, page:this.state.page + 1, results: this.state.results.concat(result.data), isLoadingMore: false, isLoading: false })
    //             // this.props.dispatch({ type: 'DATA_UPDATED' })
    //         })
    // }

    loadData = () => {


        const { docId, document, dispatch, queryService, users } = this.props;
        if (docId !== '') {
            this.setState({ isLoading: true })

            queryService.getRelated(docId, this.state.filterTags)
                .then((results) => {
                    const experts = [];


                    results.expert.forEach((expert, idx) => {
                        expert.user = users.find(user => user.tag == expert.key)
                        if (expert.user) {
                            experts.push(expert)
                        }
                    })

                    this.setState({
                        bestResults: results.bestResult,
                        duplicates: results.duplicate,
                        experts: experts.sort((a, b) => b.docCount - a.docCount).slice(0, 4),
                        resultByDataTypes: results.resultByDataTypes,
                        linkedItems: results.linkedItems,
                        results: results.data,
                        searchResultCount: results.count,
                        searchResultTime: results.took,
                        relatedTags: results.relatedTags,
                        isLoading: false,
                        hasMore: false,
                    });


                })
        }
    }


    onClose = (e) => {
        this.setState({
            open: false,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: false,
            page: 0,
            experts: [],
            filterTags: []
        })
        this.props.handleClose(e)
    }

    handleTagFilterClick = tag => {
        const filterTags = [...this.state.filterTags]
        const tagIndex = filterTags.findIndex(x => x === tag)
        if (tagIndex === -1) {
            filterTags.push(tag)
        } else {
            filterTags.splice(tagIndex, 1)
        }
        this.setState({ filterTags })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterTags.length !== this.state.filterTags.length) {
            this.loadData()
        }
    }

    filterTag = tag => {
        const hasNumber = str => /\d/.test(str)
        if (hasNumber(tag)) {
            const whitelistWords = ['version', 'root', 'parent', 'project']
            return whitelistWords.findIndex(x => tag.toLowerCase().includes(x)) !== -1
        }

        return true
    }

    render() {
        const { open, handleClose, document, users, classes, dispatch, t } = this.props;

        if (!document) {
            return null
        }
        const { results, isLoading, isLoadingMore, hasMore, experts, relatedTags, tagInfo, dataTypes, duplicates, bestResults, resultByDataTypes, linkedItems } = this.state;
        const resultsProps = {
            results, isLoading, isLoadingMore, hasMore, loadMore: () => { }, isSearch: true, Title: ''
        }

        const headerText = document.title || document.postText

        let title = ((headerText == null || typeof headerText === 'undefined'
            ? ""
            : headerText).length > 120 ? headerText.substring(0, 120) + '...' : headerText)

        return (
            <div data-intrcm-id="RelatedItemsModal_9evx4xqg">
                <Dialog
                    open={open}
                    onEnter={this.loadData}
                    onClose={this.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={open}
                    // maxWidth={'sm'}
                    classes={{ paper: classes.paper }}
                >
                    <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
                        <div data-intrcm-id="RelatedItemsModal_zasiyjvj" className={classes.text} ><span className={classes.titleText} >{t('Report for')} :</span> “{title}“</div>

                        <Grid container justify='space-between' alignItems='flex-end'>
                            <Grid item> <ItemAuthor item={document} /> </Grid>
                            <Favourites
                                render={<Button variant="contained" size="small" color="primary" className={this.props.classes.addToFavorites}>{this.props.textToAdd}</Button>}
                                docId={this.props.docId}
                                textToRemove={t('Remove item from favorites')}
                                textToAdd={t('Add item to favorites')} />
                        </Grid>

                    </DialogTitle>
                    <Divider color={'#302f47'} />
                    <DialogContent classes={{ root: classes.content }}>
                        {isLoading ? <Spinner classes={classes} /> :
                            <RelatedItemsReport
                                linkedItems={linkedItems}
                                relatedTags={document.tags.filter(this.filterTag)}
                                experts={experts.sort((x, y) => y.percent - x.percent)}
                                bestResults={bestResults}
                                duplicatedItems={duplicates}
                                resultsByDataTypes={resultByDataTypes}
                                onTagFilterClicked={this.handleTagFilterClick}
                                filterTags={this.state.filterTags} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary" autoFocus>
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch

    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withErrorCatcher(),
    withTranslation('relatedModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AlertDialog);