import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { defaultDataTypes } from '../../../Common/Utils/defaultDataTypes'

const styles = (theme) => ({
    container: {
    },
    containerForLongText: {
        width: 180,
    },
    text: {
        opacity: 0.95,
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
        fontFamily: 'Roboto Slab',
        fontSize: 36,
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.56,
        letterSpacing: 0,
        color: '#fff'
    },
    textSmall: {
        fontSize: '18px !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
    }
})
const DataTypeTitleLinked = (props) => {
    const {currentScene, styles, classes } = props
    const [dataTypeTitle, setDataTypeTitle] = useState('')

    useEffect(() => {
        currentScene.messageTemplateParams.length > 3 && setDataTypeTitle(currentScene.messageTemplateParams[1])
    }, [currentScene])

    return <div data-intrcm-id="DataTypeTitleLinked_g6lkf53k" style={styles.container} className={dataTypeTitle.length > 12 ? classes.containerForLongText : ''}>
        <div data-intrcm-id="DataTypeTitleLinked_ohok9ksp" style={styles.text} className={dataTypeTitle.length > 12 ? `${classes.text} ${classes.textSmall}` : classes.text}>
            {dataTypeTitle}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DataTypeTitleLinked)