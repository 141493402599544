import React, { memo, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import Edit from './Edit';
import View from './View';
import { isEqualJson } from '@tuqqi/common';
import { setAllowArrows } from '../grid/GridOverride';
import { formatNumberView } from '@tuqqi/web';

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    containerItem: {
    },
    container: {
        height: 40,
        position: 'relative',
        boxShadow: 'none',
        marginRight: -4,
        marginLeft: -4,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: 'initial',
        outline: 'none',
        width: '-webkit-fill-available',
    },
})


const checkApi = (record, originalValue = 0, newValue = 0) => {
    if(record?.data?.isRootRow) return

    if(isEqualJson(originalValue, newValue)) return

    if(!record) return

    if(!newValue) {
        record.set('percentDone', 0)
        return
    }
    if(Number(newValue) + '' !== newValue) return

    record.set('percentDone', Number(newValue))
}

const format = (percentDone) => {
    return formatNumberView(percentDone).replace(',', '.')
}

const CellRenderPercent = memo((props) => {
    const [open, setOpen] = useState(false)
    const {classes, record, percentDone} = props
    const [valueLocal, setValueLocal] = useState(format(percentDone))
    const localRef = useRef(valueLocal)

    const disabled = !props.record?.data?.editable?.common

    useEffect(() => {
        setValueLocal(format(percentDone))
    }, [percentDone])
    
    useEffect(() => {
        localRef.current = valueLocal
    }, [valueLocal])
    

    const onClose = () => {
        if(!open) return
        setAllowArrows()
        checkApi(props.record, props.title, localRef.current)
        setOpen(false)
    }
    

    const X = <div className={`${classes.container} ${record.data.isItemRow ? classes.containerItem : ''} ${!disabled ? classes.cursor : ''}`}>
        {open ? 
            <Edit value={valueLocal} setValue={setValueLocal} record={record} onClose={onClose} /> :
            <View setOpen={setOpen} percentDone={valueLocal} record={record} />}
    </div>

    return X
})

export default withStyles(styles, {withTheme: true})(CellRenderPercent)