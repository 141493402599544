import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import smilingFaceFill from '@iconify/icons-eva/smiling-face-fill';
import { withStyles } from '@material-ui/core/styles';
import {  IconButton, ClickAwayListener } from '@material-ui/core';

import React from 'react'
import compose from 'recompose/compose';

const styles = theme => ({
    root: {
        position: 'relative'
    },
    picker: {
        bottom: 40,
        overflow: 'hidden',
        position: 'absolute',
        left: theme.spacing.unit * (-2),
        borderRadius: 10,
        '& .emoji-mart': {
            borderRadius: 10,
            marginLeft: 5,
            marginBottom: 5,
        },
        '& .emoji-mart-anchor-bar': {
            backgroundColor: `${theme.customColors.primary.b700} !important`
        },
        '& .emoji-mart-anchor': {
            color: theme.palette.text.disabled,
            '&:hover, &:focus, &.emoji-mart-anchor-selected': {
            color: `${theme.customColors.primary.b600} !important`
            }
        }
    }
});


function EmojiPicker({
  disabled,
  value,
  setValue,
  alignRight = false,
  classes,
  ...other
}) {
  const [emojiPickerState, SetEmojiPicker] = useState(false);

  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        color={'white'}
        title="Pick your emoji…"
        emoji="point_up"
        onSelect={(emoji) => setValue(value + (emoji && emoji.native))}
      />
    );
  }

  const triggerPicker = (e) => {
    e.preventDefault();
    SetEmojiPicker(!emojiPickerState);
  };

  const handleClickAway = () => {
    SetEmojiPicker(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div data-intrcm-id="EmojiPicker_zivbsnb3" className={classes.root} {...other}>
        <div data-intrcm-id="EmojiPicker_vkbeeeuq" className={classes.picker}
          style={{
            ...(alignRight && {
              right: -2,
              left: 'auto !important'
            })
          }}
        >
          {emojiPicker}
        </div>
        <IconButton disabled={disabled} size="small" onClick={triggerPicker}>
          <Icon icon={smilingFaceFill} width={20} height={20} />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
}
export default compose(
    withStyles(styles, { withTheme: true }), 
)(EmojiPicker);