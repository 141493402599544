import { IconButton, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classnames from "classnames";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import HelpButtonIcon from '../../Common/HelpButton/HelpButtonIcon.js';
import { withTranslation } from 'react-i18next';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions.js';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const drawerWidth = '232px';
const drawerHeight = 'auto';

// Apply some reset
const styles = theme => ({
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        height: drawerHeight,
        // backgroundColor: theme.palette.background.default,
        border: 0
    },
    text: {
        ...theme.newLeftDrawerClasses.text,
        fontSize: 14,
        cursor: "pointer"
    },
    helpButtonContainer: {
        "display": "flex",
        "alignItems": "center"
    },
    helpButtonText: {
        marginLeft: 10
    },
    limitItem: {
        pointerEvents: 'none'
    },
    iconButton: {
        padding: 6,
        position: 'absolute',
        right: 0,
        opacity: 0.8,
        pointerEvents: 'auto'
    }
});


class adminSettingsDrawer extends Component {
    state = {
        isTitle: false,
    }

    openSubscriptionDialog = (reason) => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        dispatch(openSubscriptionModal())
    }

  

    canUseTimeTracking = () => {
        const { subscription } = this.props;
        if([0, 1].includes(subscription.subscriptionType)) return false
        return true
    }
    canUseContentManagement = () => {
        const { subscription } = this.props;
        if([0, 1].includes(subscription.subscriptionType)) return false
        return true
    }
    onClickUpgrage = (reason) => {
        if(reason === 'timeTracking') this.openSubscriptionDialog(reason)
        else if(reason === 'contentManagement') this.openSubscriptionDialog(reason)
    } 

    renderUpgradePlan = (mode) => {
        const { classes } = this.props;
        
        return (
            <IconButton
                className={classes.iconButton}
                onClick={() => this.onClickUpgrage(mode)}
                aria-label="More"
                aria-haspopup="true" >
                <HelpOutlineIcon />
            </IconButton>
        )
    }

    render() {
        const { classes, dispatch, selected, t } = this.props;
        const allowContentManagement = this.canUseContentManagement()
        const allowTimeTracking = this.canUseTimeTracking()

        const drawer = (
            <Drawer className={this.props.className}
                variant="persistent"
                classes={{ paper: classes.drawerPaper }}
                open={true}
            >
                <List>
                    <ListItem
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_jmvvumdp" style={{ padding: 0 }} className={classes.text}>{t('Home')}</div>
                    </ListItem>
                    <ListItem
                        selected={selected == "dashboard"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/dashboard')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_517ue5qn" style={{ padding: 0 }} className={classes.text}>{t('Dashboard')}</div>
                    </ListItem>

                    <ListItem
                        selected={selected == "sources"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/sources')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_voses4dt" style={{ padding: 0 }} className={classes.text}>{t('Impersonalization')}</div>

                    </ListItem>

                    <ListItem
                        selected={selected == "custominputmappings"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/custominputmappings')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_of6p7usq" style={{ padding: 0 }} className={classes.text}>{t('Data types')}</div>
                    </ListItem>

                    <ListItem
                        selected={selected == "classifications"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/classifications')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_u685adv7" style={{ padding: 0 }} className={classes.text}>{t('Classifications')}</div>

                    </ListItem>

                    <ListItem
                        selected={selected == "certifications"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/certifications')) }}>
                        <div data-intrcm-id="adminSettingsDrawer_ezsk8tn0" style={{ padding: 0 }} className={classes.text}>{t('Certifications')}</div>

                    </ListItem>
                    <ListItem
                        selected={selected == "users"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/users')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_qugn3ves" style={{ padding: 0 }} className={classes.text}>{t('Manage Users')}</div>

                    </ListItem>

                    <ListItem
                        selected={selected == "organization"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/organizationsettings')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_njl2mnx2" style={{ padding: 0 }} className={classes.text}>{t('Organization settings')}</div>

                    </ListItem>
                    <ListItem
                        selected={selected == "archive"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/archive')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_ssziyjx8" style={{ padding: 0 }} className={classes.text}>{t('Archive')}</div>

                    </ListItem>
                    <ListItem
                        selected={selected == "logs"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/logs')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_5gr99kpp" style={{ padding: 0 }} className={classes.text}>{t('Logs')}</div>

                    </ListItem>
                    <ListItem
                        selected={selected == "scoreboard"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/scoreBoardSettings')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_7n0x6nlv" style={{ padding: 0 }} className={classes.text}>{t('Scoreboard')}</div>

                    </ListItem>

                    <ListItem
                        selected={selected == "topics_and_usertags"}
                        classes={{ selected: classes.selectedItem }}
                        className={[classes.listItem, !allowContentManagement ? classes.limitItem : ''].join(", ")}
                        onClick={() => { allowContentManagement && dispatch(push('/admin/topics_and_usertags')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_2k184pks" style={{ padding: 0 }} className={classes.text}>{t('Content management')}</div>
                        {!allowContentManagement && this.renderUpgradePlan('contentManagement')}

                    </ListItem>
                    {/* <ListItem
                        selected={selected == "knowledge_graph"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/graph')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_u29yzf2m" style={{   padding: 0 }} className={classes.text}>Knowledge Graph</div>

                    </ListItem> */}
                    <ListItem
                        selected={selected == "time_tracking"}
                        classes={{ selected: classes.selectedItem }}
                        className={[classes.listItem, !allowTimeTracking ? classes.limitItem : ''].join(", ")}
                        onClick={() => { allowTimeTracking && dispatch(push('/admin/timetracking')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_yx99m9sn" style={{ padding: 0 }} className={classes.text}>{t('Time tracking')}</div>
                        {!allowTimeTracking && this.renderUpgradePlan('timeTracking')}

                    </ListItem>
                    <ListItem
                        selected={selected == "subscription"}
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}
                        onClick={() => { dispatch(push('/admin/billing')) }}>

                        <div data-intrcm-id="adminSettingsDrawer_5uy3jttx" style={{ padding: 0 }} className={classes.text}>{t('Billing')}</div>

                    </ListItem>
                    <ListItem
                        classes={{ selected: classes.selectedItem }}
                        className={classes.listItem}>
                        <HelpButtonIcon link='articles/5387601-admin-settings' classes={{
                            container: classes.helpButtonContainer
                        }}>
                            <div data-intrcm-id="adminSettingsDrawer_3ewd7amz" className={classnames(classes.text, classes.helpButtonText)}>{t('Learn more')}</div>
                        </HelpButtonIcon>

                    </ListItem>
                </List>
            </Drawer>
        );

        return drawer;
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    subscription: state.globalData.currentSubscription,

    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('admin'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(adminSettingsDrawer);