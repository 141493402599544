import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class NewGroupIcon extends PureComponent {

    render() {
        return (
            <SvgIcon className={this.props.className} viewBox="0 0 28 28" style={{ height: 24, width: 36 }}>
                <defs>
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                        <stop stop-color="#8645F9" offset="0%"/>
                        <stop stop-color="#BB7BFD" offset="100%"/>
                    </linearGradient>
                </defs>
                <path d="M36 31.5c4.005 0 12 2.01 12 6V42H24v-4.5c0-3.99 7.995-6 12-6zm12.795 1.245c2.82.99 5.205 2.58 5.205 4.755V42h-3v-4.5c0-1.95-.87-3.51-2.205-4.755zM57 22.5v3h3v3h-3v3h-3v-3h-3v-3h3v-3h3zM36 18c3.315 0 6 2.685 6 6s-2.685 6-6 6-6-2.685-6-6 2.685-6 6-6zm6.765.075C45.705 18.45 48 20.94 48 24c0 3.06-2.295 5.55-5.235 5.925C44.145 28.335 45 26.265 45 24c0-2.265-.855-4.335-2.235-5.925z" transform="translate(-24 -18)" fill="url(#a)" fill-rule="nonzero"/>
            </SvgIcon>)
    }
}