import { CircularProgress, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import { filterData, IsQueryExistInCollections, loadData, loadMore, loadMoreQuery, updateSpecificItemInState } from '../search/searchUtils';
import { loadGroupInfo } from '../groups/groupUtils.js';
import FeedDataItem from '../../components/FeedViewComponents/PreviewCardTypes/FeedDataItem.js';
import CategoryFeedView from '../../components/App/CategoryFeedView.js';
import ResultsList from '../../components/App/PageComponents/ResultsList.js';
import { DATA_FILTER, DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../actionTypes/actionTypes.js';
import UserGroupsWidget from '../../components/Widgets/RightWidgets/Feed/UserPofileWidget/UserGroupsWidget.js';
import UserTeamWidget from '../../components/Widgets/RightWidgets/Feed/UserPofileWidget/UserTeamWidget.js';
import TrendingTagsWidget from '../../components/Widgets/RightWidgets/Feed/trendingTagsWidget.js';


const styles = theme => ({

});

class UserPageAll extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                firstname: '',
                auth0_id: '',
                tag: ''
            },
            dataReloadInidicator: 0,
            results: [],
            isLoading: true,
            isLoadingMore: false,
            hasMore: true,
            relatedTags: [],
            experts: [],
            dataTypes: [],
            tagInfo: { tag: '' },
            open: false
        }

        this.loadData = loadData.bind(this);
        this.loadMore = loadMore.bind(this);
        this.filterData = filterData.bind(this);
        this.loadMoreQuery = loadMoreQuery.bind(this);
        this.loadGroupInfo = loadGroupInfo.bind(this);
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.updateSpecificItemInState = updateSpecificItemInState.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.urlQuery !== this.props.urlQuery) {
            this.updateUser(nextProps)
        }
        if (nextProps.oldUiData.dataUpdate > this.state.dataReloadInidicator) {
            this.loadData(nextProps.oldUiData.dataUpdate, nextProps)
        }

        if (!nextProps.oldUiData.isFiltering && nextProps.oldUiData.needsFilter) {
            this.setState({ isLoading: true })
            this.filterData(nextProps.oldUiData.dataUpdate, nextProps);
        }

        if (nextProps.oldUiData.loadMore) {
            this.loadMore();
        }

        if (nextProps.oldUiData.specificItemUpdate) {
            this.updateSpecificItemInState(nextProps.oldUiData.itemId)
        }
    }

    renderItem = (idx, result) => {
        return (
            <FeedDataItem
                key={result.Key}
                docId={result.Key}
                result={result.Value.Source}
            />
        )
    }

    updateUser = (props) => {
        const {activeUsers, dispatch} = props
        const u = new URL(location.href)
        const userTagParam = u.searchParams.get("searchquery")
        const userTag = (userTagParam ?? '').startsWith('@') ? userTagParam.slice(1) : userTagParam
        const user = activeUsers.find(u => u.tag === userTag)
        this.setState({user})
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { dateScheduleFrom: null, dateScheduleTo: null, users: user?.auth0_id ? [user.auth0_id] : [], page: 0, query: ' '} })
        dispatch({ type: DATA_UPDATE });
        dispatch({ type: DATA_FILTER });
    }

    componentDidMount() {
        this.updateUser(this.props)
    }

    render() {
        const { results, isLoading, isLoadingMore, hasMore } = this.state;

        if (isLoading) {
            return <CircularProgress style={{ position: 'fixed', left: '50%', right: '50%', top: '27%' }} />
        }


        const resultsProps = {
            results,
            isLoading,
            isLoadingMore,
            hasMore,
            loadMore: this.loadMoreQuery,
            isSearch: true,
            Header: null,
            isUniqeSearch: true,
            showInput: true,
            renderItem: this.renderItem,
        }



        return <CategoryFeedView
            rightWidgets={[<UserTeamWidget isMyUser={false} userProfile={this.state.user}/>, <UserGroupsWidget isMyUser={false} userProfile={this.state.user} />, <TrendingTagsWidget/>]}
        >

            <ResultsList
                {...resultsProps} />


        </CategoryFeedView>

    }
}

const mapStateToProps = (state, ownProps) => ({
    activeUsers: state.globalData.users,
    userData: state.userData,
    ...state.services,
    oldUiData: state.oldUiData,
    search: state.search,
    filters: state.results.dataTypes,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    oldUiInput: state.oldUiInput.document,})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(UserPageAll);