
import { withStyles } from '@material-ui/core';
import { StatusColorMap, getStringAsNum } from '@tuqqi/common';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SORT_TYPE } from '../../../../../reducers/searchReducer';
import SettingsIcon from '../../../../components/Common/Icons/AppInUse/SettingsIcon';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import { KanbanLabel } from '../../../../components/Kanban/Card/KanbanLabels';
import { managerAggregationsForDataType, openFullview } from '@tuqqi/web';
import {
    COLUMN_DATA_TYPE_COLLECTION_LABELS,
    COLUMN_DATA_TYPE_COMMENTS_COUNTER, COLUMN_DATA_TYPE_DOC_TIMESTAMP,

    COLUMN_DATA_TYPE_DUE_DATE_UTC, COLUMN_DATA_TYPE_LAST_MODIFED_TIME, COLUMN_DATA_TYPE_MEMBERS,
    COLUMN_DATA_TYPE_GROUP,
    COLUMN_DATA_TYPE_RATING,


    COLUMN_DATA_TYPE_RELATED_DOCUMENTS, COLUMN_DATA_TYPE_SEEN_COUNTER, COLUMN_DATA_TYPE_STATUS,

    COLUMN_DATA_TYPE_TASK_COUNTER, COLUMN_DATA_TYPE_TITLE, COLUMN_DATA_TYPE_READ_CONFIRM, COLUMN_DATA_TYPE_BUSINESS_ID, COLUMN_DATA_TYPE_ATTACHMENTS
} from '../tableUtil';
import MenuTableToolbar from './MenuTableToolbar';
import { withOpenFullview } from '../../../../../new-ui/withOpenFullview';
import { getAllCollection } from '../../groupUtils';
import { getBusinessIdFielName, withBusinessId } from '../../../../../utils/withBusinessId';

const styles = theme => ({
    menu: {
        width: 301
    },
    settingsIconContainer: {
        "width": "44px",
        "height": "44px",
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray400}`,
        "backgroundColor": theme.newUI.color.gray50
    },
    settingsIcon: {
        margin: 'auto'
    }
});

class FieldsSelection extends Component {

    findInputMappingByDataType = (selectedDataType) => {
        return this.props.inputMappings.find(inputMapping =>
            inputMapping.customDataMapping.dataTypeFilter === selectedDataType)
    }

    getOptionsForInputMapping(selectedDataType) {
        const inputMapping = this.findInputMappingByDataType(selectedDataType)

        if (!inputMapping) {
            return []
        }

        let allowedFieldTypes = ['title', 'text', 'paragraph', 'select', 'multiSelect', 'date', 'dateTime', 'number', 'location', 'checkbox', 'phone', 'email', 'calculation']

        const fields = inputMapping.customDataMapping.customFieldsMappings
            .filter(field => allowedFieldTypes.includes(field.type))
            .map(field => ({
                ...field,
                // type: field.sourceField,
                extractData: (row) => {
                    let value = undefined
                    const isAggregation = managerAggregationsForDataType.useAggregationsOnField(field)
                    if(isAggregation) {
                        const aggregations = row?.aggregations ?? {}
                        value = aggregations[field.aggregateField]
                    } else {
                        value = this.getJsonData(row)[field.sourceField]
                    }

                    if (field.type === 'calculation') {
                        const val = !value || value === 'error' ? '' : value
                        return {
                            view: val, 
                            value: getStringAsNum(val)
                        }
                    }
                    if (field.type === 'number') {
                        // return number
                        if (value) {
                            return {view: value, value: getStringAsNum(value)}
                        } else {
                            return {view: '0', value: 0}
                        }

                    }
                    return value
                }
            }))

        if (inputMapping.isEnableLinks) {
            fields.push({
                fieldName: 'Link',
                sourceField: 'links',
                type: 'links',
                extractData: (row) => row.links

            })
        }

        if (withBusinessId(inputMapping)) {
            fields.push({
                fieldName: getBusinessIdFielName(inputMapping),
                sourceField: 'businessId',
                type: 'businessId',
                extractData: (row) => row.businessId ?? ''
            })
        }

        return fields
    }

    getStatusLaneByDocId = (row) => {

        const { docId, TaskStatusColor, CurrentStatus, groupUid } = row
        const { collectionViews, collection, dispatch } = this.props



        if (!collectionViews || (collectionViews && !collectionViews[0].collectionUid)) {
            if(!!CurrentStatus && CurrentStatus !== 'New' && CurrentStatus !== 'Unassigned') {
                return <div data-intrcm-id="FieldsSelection_3q1oznsc" onClick={() => { this.props.openFullview(row, docId, false) }}><KanbanLabel text={CurrentStatus} color={StatusColorMap[TaskStatusColor]} /></div>
            }

            const { collections, openCollections } = this.props

            const col = getAllCollection(collections, openCollections)
                .find(coll => coll.collectionGroup.groupUid === groupUid)

            const title = !!col?.newColumnTitle ? col.newColumnTitle : 'New'
            const color = !!col?.groupDefaultStatusColor ? col.groupDefaultStatusColor : TaskStatusColor.Grey

            return <div data-intrcm-id="FieldsSelection_3q1oznsc" onClick={() => { this.props.openFullview(row, docId, false) }}><KanbanLabel text={title} color={StatusColorMap[color]} /></div>
        }

        const lanes = collectionViews.find(x => x.collectionViewType === 'Kanban').itemLists

        const lane = lanes.find(lane => lane.items.find(x => x.documentId === docId))

        if (lane) {

            if (collection) {
                const collectionUid = collection.collectionUid
                return <div data-intrcm-id="FieldsSelection_7h1x4bdn" onClick={() => { dispatch(openFullview(docId, false, collectionUid)) }}><KanbanLabel text={lane.title} color={StatusColorMap[lane.listColor]} /></div>
            } else {



                return <div data-intrcm-id="FieldsSelection_b885cej0" onClick={() => { dispatch(openFullview(docId, false)) }}><KanbanLabel text={lane.title} color={StatusColorMap[lane.listColor]} /></div>
            }
        } else {
            if(docId === 'AYqNmPPSZFT_ZqfSwvZZ') console.log('vdfvdfvfdvfvf');

            const { collections, openCollections } = this.props

            let col1 = collection
            if(!col1) {
                col1 = getAllCollection(collections, openCollections)
                    .find(coll => coll.collectionGroup.groupUid === groupUid)
            }

            const title = !!col1?.newColumnTitle ? col1.newColumnTitle : 'Unassigned'
            const color = !!col1?.groupDefaultStatusColor ? col1.groupDefaultStatusColor : TaskStatusColor.Grey

            return <div data-intrcm-id="FieldsSelection_jcql164l" onClick={() => { dispatch(openFullview(docId, false)) }}><KanbanLabel text={title} color={StatusColorMap[color]} /></div>
        }

    }

    getCollectionTitle = (row) => {

        const { docId, taskStatusColor, status, groupUid } = row
        const { collections, openCollections } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid === groupUid)
        if (collection) {
            return collection.title
        } else {
            return 'Public'
        }



    }

    getDefaultOptions = () => {
        const columns = [
            {
                fieldName: 'Title',
                sortField: 'title.keyword',
                sortType: SORT_TYPE.Default,
                sourceField: 'title.keyword',
                type: COLUMN_DATA_TYPE_TITLE,
                extractData: (row) => row.title,
            },
            {
                fieldName: 'Status',
                extractData: (row) => this.getStatusLaneByDocId(row),
                sortField: 'currentStatus.keyword',
                sourceField: 'currentStatus.keyword',
                sortType: SORT_TYPE.Default,
                type: COLUMN_DATA_TYPE_STATUS
            },
            {
                fieldName: 'Assignees',
                sortField: 'members.userId',
                sortType: SORT_TYPE.ArrLength,
                sourceField: 'members.keyword',
                type: COLUMN_DATA_TYPE_MEMBERS,
                extractData: (row) => row.members,
            },
            {
                fieldName: 'Group',
                extractData: (row) => this.getCollectionTitle(row),
                sortField: 'groupUid',
                sourceField: 'groupUid.keyword',
                sortType: SORT_TYPE.Default,
                type: COLUMN_DATA_TYPE_GROUP
            },
            {
                fieldName: 'Time created',
                dataType: 'dateTime',
                sortField: 'docTimestamp',
                sortType: SORT_TYPE.Default,
                sourceField: 'docTimestamp.keyword',
                extractData: (row) => row.docTimestamp,
                type: COLUMN_DATA_TYPE_DOC_TIMESTAMP
            },
            {
                fieldName: 'Time modified',
                dataType: 'dateTime',
                sortField: 'lastModifiedTime',
                sortType: SORT_TYPE.Default,
                sourceField: 'lastModifiedTime.keyword',
                extractData: (row) => row.lastModifiedTime,

                type: COLUMN_DATA_TYPE_LAST_MODIFED_TIME
            },
            {
                fieldName: 'Labels',
                sortField: 'collectionLabels.keyword',
                sortType: SORT_TYPE.ArrLength,
                sourceField: 'collectionLabels.keyword',
                extractData: (row) => row.labels ? row.labels : row.collectionLabels,
                type: COLUMN_DATA_TYPE_COLLECTION_LABELS,

            },
            {
                fieldName: 'Rating',
                sortField: 'rating',
                sortType: SORT_TYPE.Default,
                sourceField: 'rating.keyword',
                extractData: (row) => row.rating ? row.rating : 0,
                type: COLUMN_DATA_TYPE_RATING,
            },

            {
                fieldName: 'Due date',
                dataType: 'dateTime',
                sortField: 'dueDateUTC',
                sortType: SORT_TYPE.Default,
                sourceField: 'dueDateUTC.keyword',
                extractData: (row) => row.dueDateUTC,

                type: COLUMN_DATA_TYPE_DUE_DATE_UTC
            },
            {
                fieldName: 'Task finished on',
                dataType: 'dateTime',
                sortField: 'dueDateUTCDone',
                sortType: SORT_TYPE.Default,
                sourceField: 'dueDateUTCDone.keyword',
                extractData: (row) => row.dueDateUTCDone,

                type: COLUMN_DATA_TYPE_DUE_DATE_UTC
            },
            {
                fieldName: 'Views',
                sortField: 'seenCounter',
                sortType: SORT_TYPE.Default,
                sourceField: 'seenCounter.keyword',
                extractData: (row) => row.seenCounter,

                type: COLUMN_DATA_TYPE_SEEN_COUNTER
            },
            {
                fieldName: 'Comments',
                sortField: 'commentsCounter',
                sortType: SORT_TYPE.Default,
                sourceField: 'commentsCounter.keyword',
                extractData: (row) => row.commentsCounter,

                type: COLUMN_DATA_TYPE_COMMENTS_COUNTER
            },
            {
                fieldName: 'Tasks',
                sortField: 'taskCounter',
                sortType: SORT_TYPE.Default,
                sourceField: 'taskCounter.keyword',
                extractData: (row) => ({
                    taskCounter: row.taskCounter,
                    taskDoneCounter: row.taskDoneCounter
                }),

                type: COLUMN_DATA_TYPE_TASK_COUNTER
            },
            {
                fieldName: 'Read & Confirm',
                sortField: 'mustReadUsers',
                sortType: SORT_TYPE.ArrLength,
                sourceField: 'readConfirm.keyword',
                extractData: (row) => ({
                    mustReadUsers: row.mustReadUsers ? row.mustReadUsers.length : 0,
                    mustReadUsersApproved: row.mustReadUsersApproved ? row.mustReadUsersApproved.length : 0
                }),

                type: COLUMN_DATA_TYPE_READ_CONFIRM
            },
            {
                fieldName: 'Linked items',
                sortField: 'relatedDocuments.relatedDocumentId.keyword',
                sortType: SORT_TYPE.ArrLength,
                sourceField: 'relatedDocuments.keyword',
                extractData: (row) => row.relatedDocuments,
                type: COLUMN_DATA_TYPE_RELATED_DOCUMENTS
            },
            {
                fieldName: 'Attached files',
                sortField: 'attachments.fileUid.keyword',
                sortType: SORT_TYPE.ArrLength,
                sourceField: 'attachments.keyword',
                extractData: (row, mapping) => {
                    if(!mapping || !('isEnableFiles' in mapping)) return row?.attachments ?? []
                    if(!mapping.isEnableFiles) return []
                    return row?.attachments ?? []
                },
                type: COLUMN_DATA_TYPE_ATTACHMENTS
            },
        ]

        return columns
    }
    getContactOptions() {

        const contactOptions = []

        contactOptions.push({
            fieldName: 'Avatar',
            type: 'contactAvatar',
            sourceField: 'contactAvatar.keyword',
            extractData: (row) => row.previewImgUrl,

        })

        contactOptions.push({
            isJsonData: false,
            fieldName: 'Name',
            sourceField: 'contactFulName.keyword',
            type: 'contactFullName',
            sortField: 'title',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.title
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Company',
            sourceField: 'contactCompany.keyword',
            type: 'contactCompany',
            extractData: (row) => {
                return this.getJsonData(row).company
            }
        })
        contactOptions.push({
            isJsonData: true,
            fieldName: 'Job title',
            sourceField: 'contactJobTitle.keyword',
            type: 'contactJobTitle',
            extractData: (row) => {
                return this.getJsonData(row).jobtitle
            }
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Email',
            sourceField: 'contactEmail.keyword',
            type: 'contactEmail',
            extractData: (row) => {
                return this.getJsonData(row).email
            }
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Phone number',
            sourceField: 'contactPhoneNumber.keyword',
            type: 'contactPhoneNumber',
            extractData: (row) => {
                return this.getJsonData(row).phone
            }
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Address',
            sourceField: 'contactAddress.keyword',
            type: 'contactAddress',
            extractData: (row) => {
                return this.getJsonData(row).address
            }
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Birthday',
            sourceField: 'contactBirthday.keyword',
            type: 'contactBirthday',
            extractData: (row) => {
                return this.getJsonData(row).birthday
            }
        })

        contactOptions.push({
            isJsonData: true,
            fieldName: 'Website',
            sourceField: 'contactWebsite.keyword',
            type: 'contactWebsite',
            extractData: (row) => {
                return this.getJsonData(row).websiteurl
            }
        })
        contactOptions.push({
            isJsonData: true,
            fieldName: 'Facebook',
            sourceField: 'contactFacebook.keyword',
            type: 'contactFacebook',
            extractData: (row) => {
                return this.getJsonData(row).facebookurl
            }
        })
        contactOptions.push({
            isJsonData: true,
            fieldName: 'Linkedin',
            sourceField: 'contactLinkedin.keyword',
            type: 'contactLinkedin',
            extractData: (row) => {
                return this.getJsonData(row).linkedinurl
            }
        })
        contactOptions.push({
            isJsonData: true,
            fieldName: 'Additional info',
            sourceField: 'contactAdditionalInfo.keyword',
            type: 'contactAdditionalInfo',
            extractData: (row) => {
                return this.getJsonData(row).additional
            }
        })

        return contactOptions
    }

    getEventOptions() {

        const eventOptions = []

        eventOptions.push({
            isJsonData: false,
            fieldName: 'Date & Time',
            sourceField: 'dateTimeFrom.keyword',
            type: 'eventDate&Time',
            extractData: (row) => row.dateTimeFrom

        })

        eventOptions.push({
            isJsonData: false,
            sortField: 'members.userId',
            sortType: SORT_TYPE.ArrLength,
            sourceField: 'eventGuests.keyword',
            fieldName: 'Guests',
            type: 'eventGuests',
            extractData: (row) => row.members && row.members.length

        })

        eventOptions.push({
            isJsonData: true,
            //TODO: test the location
            sourceField: 'eventLocation.keyword',
            fieldName: 'Location',
            type: 'eventLocation',
            extractData: (row) => {
                return this.getJsonData(row).location
            }
        })

        eventOptions.push({
            isJsonData: true,
            sourceField: 'eventDescription.keyword',
            fieldName: 'Description',
            type: 'eventDescription',
            extractData: (row) => {
                return this.getJsonData(row).description
            }
        })

        eventOptions.push({
            isJsonData: false,
            sourceField: 'eventGotToEvent.keyword',
            fieldName: 'Go to event',
            type: 'eventGoToEvent',
            extractData: (row) => row.link

        })

        return eventOptions
    }

    getCompanyOptions() {

        const companyOptions = []
        companyOptions.push({
            isJsonData: false,
            fieldName: 'Avatar',
            sourceField: 'companyAvatar.keyword',
            type: 'companyAvatar',
            extractData: (row) => row.previewImgUrl

        })

        companyOptions.push({
            isJsonData: false,
            fieldName: 'Name',
            sourceField: 'companyFulName.keyword',
            type: 'companyFullName',
            sortField: 'title',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.title

        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'ID',
            sourceField: 'companyID.keyword',
            type: 'companyID',
            extractData: (row) => {
                return this.getJsonData(row).id
            }
        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'Email',
            sourceField: 'companyEmail.keyword',
            type: 'companyEmail',
            extractData: (row) => {
                return this.getJsonData(row).email
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'Phone number',
            sourceField: 'companyPhoneNumber.keyword',
            type: 'companyPhoneNumber',
            extractData: (row) => {
                return this.getJsonData(row).phone
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'Type',
            sourceField: 'companyType.keyword',
            type: 'companyType',
            extractData: (row) => {
                return this.getJsonData(row).type
            }
        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'Address',
            sourceField: 'companyAddress.keyword',
            type: 'companyAddress',
            extractData: (row) => {
                return this.getJsonData(row).address
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'Founding year',
            sourceField: 'companyFoundingYear.keyword',
            type: 'companyFoundingYear',
            extractData: (row) => {
                return this.getJsonData(row).foundingyear
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'CEO',
            sourceField: 'companyCEO.keyword',
            type: 'companyCEO',
            extractData: (row) => {
                return this.getJsonData(row).ceo
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'Number of employees',
            sourceField: 'companyNumberOfEmployees.keyword',
            type: 'companyNumberOfEmployees',
            extractData: (row) => {
                return this.getJsonData(row).numberOfEmployees
            }
        })

        companyOptions.push({
            isJsonData: true,
            fieldName: 'Website',
            sourceField: 'companyWebsite.keyword',
            type: 'companyWebsite',
            extractData: (row) => {
                return this.getJsonData(row).websiteurl
            }
        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'Facebook',
            sourceField: 'companyFacebook.keyword',
            type: 'companyFacebook',
            extractData: (row) => {
                return this.getJsonData(row).facebookurl
            }
        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'Linkedin',
            sourceField: 'companyLinkedin.keyword',
            type: 'companyLinkedin',
            extractData: (row) => {
                return this.getJsonData(row).linkedinurl
            }
        })
        companyOptions.push({
            isJsonData: true,
            fieldName: 'Additional info',
            sourceField: 'companyAdditionalInfo.keyword',
            type: 'companyAdditionalInfo',
            extractData: (row) => {
                return this.getJsonData(row).additional
            }
        })
        return companyOptions

        //TODO:  Contacts fiels
    }
    getMailOptions() {

        const mailOptions = []
        mailOptions.push({
            isJsonData: true,
            fieldName: 'From',
            sourceField: 'userName.keyword',
            // type: 'mailFrom',
            sortField: 'userName',
            sortType: SORT_TYPE.Str,
            extractData: (row) => {
                return this.getJsonData(row).mailFrom
            }
        })

        return mailOptions
    }

    getJsonData(data) {
        if (data.parsedData) {
            return data.parsedData
        }

        let parsedData = {}
        try {
            parsedData = data.jsonData && JSON.parse(data.jsonData)

            data.parsedData = parsedData
        } catch (error) {

        }
        return parsedData
    }

    getBookmarkOptions() {

        const bookmarkOptions = []
        bookmarkOptions.push({
            isJsonData: false,
            fieldName: 'Link',
            sourceField: 'previewTitle.keyword',
            type: 'bookmark',
            sortField: 'previewTitle',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.previewTitle
        })

        return bookmarkOptions
    }
    getPostOptions() {

        const postOptions = []
        postOptions.push({
            isJsonData: false,
            fieldName: 'Post',
            sourceField: 'postText.keyword',
            type: 'postText',
            sortField: 'postText',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.postText
        })

        return postOptions
    }

    getFileOptions() {

        const fileOptions = []
        fileOptions.push({
            isJsonData: false,
            fieldName: 'Link',
            sourceField: 'previewTitle.keyword',
            type: 'bookmark',
            sortField: 'previewTitle',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.previewTitle

        })

        return fileOptions
    }
    getSelectedDataType() {
        const { selectedDataTypes } = this.props;

        const dataTypeSelected = selectedDataTypes.length === 1 && selectedDataTypes[0]

        return dataTypeSelected
    }

    getQuestionOptions() {
        const questionOptions = []

        questionOptions.push({
            isJsonData: false,
            fieldName: 'Answer Status',
            sourceField: 'questionStatus.keyword',
            type: 'questionStatus',
            extractData: (row) => {
                let text = ''

                if (row.commentsCounter < 2) {
                    text = 'No'
                }
                else if (!row.selectedComment) {
                    text = 'Tyring to answer'
                }
                else {
                    text = 'Yes'
                }
                return text
            }

        })

        questionOptions.push({
            isJsonData: false,
            fieldName: 'Answer',
            sourceField: 'selecteeComment.keyword',
            type: 'questionAnswer',
            sortField: 'selectedComment',
            sortType: SORT_TYPE.Str,
            extractData: (row) => row.selectedComment
        })

        questionOptions.push({
            isJsonData: false,
            fieldName: 'Who answered',
            type: 'questionWhoAnswered',
            sourceField: 'questionWhoAnswered.keyword',
            extractData: (row) => {
                let text = ''

                const ansewredUid = this.getJsonData(row).ansewred
                const user = this.props.users.find(u => u.auth0_id === ansewredUid)
                if (user) {
                    text = `${user.firstname} ${user.lastname}`
                }
                return text
            }
        })

        return questionOptions
    }

    buildOptions() {
        const { optionalDataTypes, inputMappings, t } = this.props;

        let options = []
        let allowedFields = []
        const selectedDataType = this.getSelectedDataType()

        switch (selectedDataType) {
            case 'contact':
                options = this.getContactOptions()
                break;
            case 'event':
                options = this.getEventOptions()
                break;
            case 'company':
                options = this.getCompanyOptions()
                break;
            case 'mail':
                options = this.getMailOptions()
                break;
            case 'bookmark':
                options = this.getBookmarkOptions()
                break;

            case 'file':
                options = this.getFileOptions()
                break;
            case 'question':
                options = this.getQuestionOptions()
                break;

            default:
                options = this.getOptionsForInputMapping(selectedDataType)
                break;
        }
        const dataTypesWithPost = [
            'media', 'bookmark', 'file', 'post', 'poll'
        ]

        if (dataTypesWithPost.includes(selectedDataType)) {
            options = options.concat(this.getPostOptions())
        }


        const defaultOptions = this.getDefaultOptions()
            .filter(defaultOption =>
                options.every(option => option.sourceField !== defaultOption.sourceField))
            .map(field => ({
                ...field,
                isCustomInput: false
            }))

        return defaultOptions.concat(options).map(field => ({
            ...field,
            label: t(field.fieldName),
            key: field.fieldName,
            selected: true
        }))
    }


    state = {
        options: []
    }
    setOptions(options) {
        let updatedOptions = [...options]
        this.setState({ options: updatedOptions })
        this.props.onSelectFields(updatedOptions)
        this.props.dispatch({
            type: 'SET_SELECTED_FIELD_SOURCES',
            selectedFieldSources: options.filter(o => o.selected).map(o => o.sourceField)
        })
    }
    componentDidUpdate(prevProps) {
        const {selectedFieldSources, ignoreSelectedFields} = this.props
        if ((this.props.selectedDataTypes !== prevProps.selectedDataTypes)) {
            const options = this.buildOptions()
            const res = !!selectedFieldSources?.length && !ignoreSelectedFields ? options.map(o => {
                const selected = selectedFieldSources.includes(o.sourceField)
                return {...o, selected}
            }) : options
            this.setOptions(res)
        }
    }

    componentDidMount() {
        const options = this.buildOptions()
        this.setOptions(options)
    }

    onSelectFields = (selectedOptions) => {
        this.setOptions(selectedOptions)
    }
    render() {
        const { classes, t, onSelectFields } = this.props;
        const { options } = this.state;

        return (
            <MenuTableToolbar
                allowMultiple
                title={t('Select fields')}
                classes={{ menu: classes.menu, container: classes.settingsIconContainer }}
                button={<SettingsIcon fill='#979aaa' className={classes.settingsIcon} isTowTone={false} />}
                options={options}
                onChoose={this.onSelectFields}
            />
        )
    }

}

const mapStateToProps = (state, ownProps) => ({
    selectedDataTypes: state.search.dataTypes,
    resultDataTypes: state.results.dataTypes,
    defaultInputTemplates: state.globalData.defaultInputTemplates,
    inputMappings: state.globalData.inputMappings,
    collectionViews: state.results.channel.collectionViews,
    collection: state.results.channel,
    users: state.globalData.users,
    selectedFieldSources: state.localFilter.selectedFieldSources,
    ignoreSelectedFields: state.localFilter.ignoreSelectedFields,
    openCollections: state.globalData.openCollections,
    collections: state.userData.collections,

})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles), withTranslation('myReportsView'),
    withErrorCatcher(),
    withOpenFullview,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FieldsSelection);