import React, { PureComponent } from 'react';

export default class KanbanIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.75 2.5C6.6 2.5 5.58977 3.09988 5.00977 4.00488C5.08977 3.99988 5.17 4 5.25 4H16.75C18.82 4 20.5 5.68 20.5 7.75V15.75C20.5 15.83 20.5001 15.9102 20.4951 15.9902C21.4001 15.4102 22 14.4 22 13.25V7.75C22 4.855 19.645 2.5 16.75 2.5H7.75ZM5.25 5C3.46403 5 2 6.46403 2 8.25V15.75C2 17.536 3.46403 19 5.25 19H5.5V21.2676C5.5 22.2432 6.69636 22.8534 7.48535 22.2793L11.9941 19H16.25C18.036 19 19.5 17.536 19.5 15.75V8.25C19.5 6.46403 18.036 5 16.25 5H5.25ZM5.25 6.5H16.25C17.225 6.5 18 7.27497 18 8.25V15.75C18 16.725 17.225 17.5 16.25 17.5H11.75C11.5914 17.5 11.4368 17.5502 11.3086 17.6436L7 20.7773V18.25C6.99998 18.0511 6.92096 17.8603 6.78031 17.7197C6.63966 17.579 6.44891 17.5 6.25 17.5H5.25C4.27497 17.5 3.5 16.725 3.5 15.75V8.25C3.5 8.12812 3.51206 8.00904 3.53516 7.89453C3.69682 7.093 4.39685 6.5 5.25 6.5Z" fill={color}/>
            </svg>
        )
    }
}

export class ChatPXIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.14565 14.1764C6.32998 13.9679 6.62332 13.894 6.88443 13.9901C7.61626 14.2595 8.4068 14.4 9.22505 14.4C12.6201 14.4 15.3 11.9757 15.3 9C15.3 6.02435 12.6201 3.6 9.22505 3.6C5.83003 3.6 3.15005 6.02435 3.15005 9C3.15005 10.0897 3.50939 11.1302 4.17552 12.0141C4.27098 12.1408 4.31878 12.297 4.31055 12.4554C4.27518 13.1356 3.94832 14.0619 3.33471 15.2764C4.66959 15.1725 5.59738 14.7963 6.14565 14.1764ZM2.9179 16.6503C2.72998 16.656 2.54381 16.6127 2.37767 16.5248C1.84857 16.2446 1.64676 15.5886 1.92691 15.0595C2.50641 13.965 2.84134 13.1335 2.936 12.6031C2.19905 11.5369 1.80005 10.2959 1.80005 9C1.80005 5.2469 5.11295 2.25 9.22505 2.25C13.3371 2.25 16.65 5.2469 16.65 9C16.65 12.7531 13.3371 15.75 9.22505 15.75C8.39884 15.75 7.59321 15.6295 6.83131 15.3962C5.93422 16.1895 4.62099 16.5985 2.9179 16.6503Z" fill="#6B6F7A"/>
            </svg>
        )
    }
}