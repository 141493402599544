import { withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { BottomToolbarSection } from '../Common/BottomToolbarSection';
import { LandingOptionsSection } from './LandingOptionsSection';

const styles = (theme) => ({
    container: {
        ...theme.launchpadPageWrap,
    },
})

function LaunchpadLanding(props) {
    const {classes} = props

    return (
        <div data-intrcm-id="LaunchpadLandingPage_dirkhfly" className={classes.container} >
            <LandingOptionsSection />
            <BottomToolbarSection />
        </div>
    )
}

export const LaunchpadLandingPage = compose(
    withStyles(styles, {withTheme: true})
)(LaunchpadLanding)

