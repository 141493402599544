import React from 'react' 
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { hexToRGB } from '@tuqqi/common'
import { ClearIcon } from './ClearIcon'

const styles = (theme) => ({
    container: {
        height: 28,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        marginTop: 8,
        paddingRight: 8,
        paddingLeft: 8,
        background: hexToRGB(theme.customColors.primary.main, 0.16),
        borderRadius: 8,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    label: {
        fontSize: 14,
        height: 20,
        lineHeight: '20px',
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.24,
        color: '#1B1E2A',
    },
    x: {
        marginLeft: 8,
        cursor: 'pointer'
    }
})

const __Chip = (props) => {
    const {label, index, classes, isLast, onClear} = props

    const _onClear = () => {
        onClear(index)
    }

    return <div data-intrcm-id="Chip_ah9b9ixk" className={classes.container}>
        <div data-intrcm-id="Chip_i872d1nq" className={classes.row}>
            <div data-intrcm-id="Chip_lpsdfbiz" className={classes.label}>{label}</div>
            {isLast && <div data-intrcm-id="Chip_8hwwle2h" onClick={_onClear} className={classes.x}><ClearIcon /></div>}
        </div>
    </div>
}

export const ChipOp = compose(
    withStyles(styles, {withTheme: true})
)(__Chip)