import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import Text from "./Text";

const styles = theme => ({
    container: {
        overflow:'hidden',
        backgroundColor: '#f7f7f7',
        padding: '12px', margin: '0px 16px',
        borderRadius: '2px', cursor: 'pointer'
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909",
        'word-break': 'break-all',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
    }

});

class CustomInputTemplateNoImage extends PureComponent {

    render() {
        const { classes, result, mappings, openItem } = this.props;
        const { docText, title, dataType } = result;

        return (
            <Grid container direction='column'  wrap='nowrap' className={classes.container} onClick={openItem}>
                <Text dataType={dataType} mappings={mappings} title={title} />
                <span className={classes.previewDescription} dir="auto" >
                        {docText}
                    </span>
            </Grid >)

    }
}

export default compose(withStyles(styles))(CustomInputTemplateNoImage);
