import React from 'react'
import exportTableToCsv from '../../groups/table/EnhancedTableToolbar/exportToCsv'
import exportTableToExcel from '../../groups/table/EnhancedTableToolbar/exportToExcel'

export const useExportLogs = () => {

    const renderCell = (row, column) => {
        const data = column.extractData(row, column.type)
        switch (column.type) {
            case 'itemTitle': {
                return { value: data?.text ?? 'Untitled', t: 's' }
            }
            case 'user':  {
                return { value: data?.text ?? 'Unknown', t: 's' } 
            }
            case 'date':
            case 'type':  {
                return { value: data?.text ?? '', t: 's' } 
            }
            default:
                return { value: '' }
            }
        }

    const onClikExcel = (payload) => {
        exportTableToExcel(payload.data, payload.columnsWithoutData, renderCell, payload.fileName, payload.fileName, false, [])
    }

    const onClikCsv = (payload) => {
        exportTableToCsv(payload.data, payload.columnsWithoutData, renderCell, payload.fileName)
    }

    return [onClikExcel, onClikCsv]
}