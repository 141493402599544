import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, IconButton, Checkbox, Button } from '@material-ui/core';
import { StarBorder, Delete } from '@material-ui/icons';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/goToActions'

import moment from 'moment';
import urlRegex from 'url-regex';

import DeleteButton from "./deleteButton";
import SplitedText from "./splitedText";
import ImgComment from "./imgComment";
import DateFrom from "./dateFrom";

const styles = theme => (theme.comment)

class HelpfulButton extends Component {
    state = {
        isRated: false,
        score: 0,
    }

    componentWillMount() {
        const { comment, userData } = this.props;
        let commentRates = this.props.comment.commentRates;

        this.setState({
            score: comment.score || 0,
            isRated: commentRates ? commentRates.some(rate => rate.userId === userData.profileData.auth0_id) : false
        })
    }



    onUpgrade = () => {
        const { comment, collaborationService } = this.props;

        this.setState({ isRated: true, score: this.state.score + 1 })

        collaborationService.upgradeComment(comment);
    }


    render() {
        const { classes } = this.props;
        const { isRated, score } = this.state;

        let color = isRated ? '' : 'rgba(70, 57, 242, 0.7)';

        return <div data-intrcm-id="helpfulButton_35v3x4os" style={{paddingRight:7}}><Button style={{}} disabled={isRated} disableRipple onClick={this.onUpgrade} classes={{ root: classes.helpfulButton }}
            style={{ color: color }}>
            <StarBorder style={{ fontSize: 14 }} />
            helpful ({score})
        </Button>
                    <span>{ ' ·' }</span></div>

    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    collaborationService: state.services.collaborationService
})
export default compose(withStyles(styles),
    connect(mapStateToProps))(HelpfulButton);