import { Dialog, DialogContent, Button, DialogTitle, Grid, withStyles, DialogActions, TextField } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withErrorCatcher from '../../../../../components/Common/withErrorCatcher';
import OnErrorDialog from '../../../../../components/Common/OnErrorDialog';
import AutosuggestChip from '../../../../../components/Header/SubheaderToolbar/Filters/AutosuggestChip';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    width: {
        width: 508,
        maxWidth: 508
    },
    content: {
        marginBottom: -10,
        marginTop: 10
    },
    datePicker: {
        margin: '0px 0px 0px 32px'
    },
    startDate: {
        margin: 0,
    },
    button: {
        marginRight: 16,
        textTransform: 'capitalize',
    },
})

class CreateLaneModal extends React.Component {
    state = {
        name: ''
    }

    handleChange = (event) => {
        const newName = event.target.value
        this.setState({ name: newName })
    }

    onSave = () => {
        this.setState({ name: '' })
        this.props.onSave(this.state.name)
    }
    onClose = () => {
        this.setState({ name: '' })
        this.props.onClose()
    }

    render() {
        const { open, classes, t } = this.props
        const isError = !this.state.name?.trim?.()
        return <Dialog
            open={open}
            onClose={this.onClose}>
            <DialogTitle className={classes.width}>
                <Grid container justify='space-between'>
                    <Grid item>{t('Create group of tasks')}</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={`${classes.width} ${classes.content}`}>
                <Grid container>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={this.state.name}
                        label={t("Group of tasks name")}
                        fullWidth
                        onChange={this.handleChange}
                        className={classes.textField}
                        error={isError}
                        helperText={isError ? t("Must be filled") : ''}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container style={{ padding: '16px 0px' }} justify='flex-end'>
                    <Button color="primary" onClick={this.onClose} className={classes.button}>
                        {t("Close")}
                    </Button>
                    <Button id='publish-new-post' color='primary' variant="raised" onClick={this.onSave} style={{ marginRight: 18 }} className={classes.button}>
                        {t("Save")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = (state) => ({
})

export default compose(
    withTranslation('gantt'),
    withStyles(styles), 
    withErrorCatcher(OnErrorDialog),
    connect(mapStateToProps))(CreateLaneModal)