import React, { Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Divider, TextField, Icon, DialogActions, DialogContent, Button, Typography } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { COLLECTION_LABELS_UPDATE } from '../../../../../../actionTypes/actionTypes.js';
import { withTranslation } from 'react-i18next'
import { withMixpanel } from '../../../../Common/withMixpanel.js';

const styles = theme => ({
    textField: { margin: '24px 0px' },
    subtitle: { marginBottom: 16 },
    color: { width: 52, height: 52, margin: '0px 10px 10px 0', borderRadius: 2, cursor: 'pointer' },
    checkIcon: {
        color: '#ffffff'
    }

});

class CreateLabel extends React.PureComponent {
    state = {
        name: '',
        color: '',
        isEdit: false,
        id: -1
    };

    changeName = (e) => {
        const value = e.target.value
        const maxLabelNameLength = 15
        if (value.length > maxLabelNameLength) {
            return
        }
        this.setState({ name: value })
    }
    changeColor = (color) => () => {
        this.setState({ color })
    }
    save = () => {
        const { goBack, groupService, collectionId, dispatch, collectionLabels, docId, changeLabels, load, mixpanelTrack } = this.props
        const { color, name, id, isEdit } = this.state

        if (isEdit) {
            mixpanelTrack('Label edited', {})
            groupService.editLabel({ color, name, collectionId, id }).then(label => {
                const newLabels = collectionLabels.map(l => {
                    if (l.labelUid == label.labelUid) {
                        return label
                    } else {
                        return l
                    }

                })
                this.updateLabels(newLabels)
            })
        } else {
            mixpanelTrack('Label created', {})
            groupService.createLabel({ color, name, collectionId }).then(newLabel => {
                const newLabels = [...collectionLabels, newLabel]

                this.updateLabels(newLabels)
                load(true)
                groupService.addLabelToItem(newLabel, docId).then(res => {
                    if (res) {
                        changeLabels(res)
                    }
                    load(false)
                })
            })
        }

        goBack()
    }

    updateLabels(newLabels) {
        const { dispatch, collectionId } = this.props
        dispatch({ type: COLLECTION_LABELS_UPDATE, collectionLabels: newLabels, collectionId })
    }

    disable = () => {
        const { color, name } = this.state

        let disabled = true
        if (color && name.length && !this.isNameAlreadyExist()) {
            disabled = false
        }

        return disabled
    }

    isNameAlreadyExist = () => {
        const { id } = this.state
        const name = this.state.name.trim()

        const isSame = (label) => label.id != id && label.name.trim() == name

        return this.props.collectionLabels.some(isSame)
    }

    colors = () => {
        const colors = ['#a654c6', '#ffb632', '#f3d20b', '#ff5c3a', '#3ece5a', '#00caeb', '#f959da', '#4145d3', '#434849', '#955e1f','red', 'green', 'blue', 'brown', 'black'];
        return colors
    }

    componentDidMount() {
        const { editedLabel } = this.props

        if (editedLabel) {
            const { name, color, id } = editedLabel
            this.setState({ name, color, id, isEdit: true })
        }

    }
    render() {
        const { close, classes, disabled, t } = this.props;

        return (
            <Fragment>
                <DialogContent style={{ width: 332 }}>
                    <Grid container >

                        <TextField
                            inputProps={{ style: { color: '#000000', opacity: 0.62 } }}
                            className={classes.textField}
                            InputProps={{ fullWidth: true }}
                            fullWidth={true}
                            label='Label Name'
                            value={this.state.name}
                            onChange={this.changeName}
                            variant='outlined' />

                        <Grid container direction='column' wrap='nowrap'>
                            <Typography variant='subtitle1' className={classes.subtitle}>{t('SelectColor')}</Typography>

                            <Grid container>
                                {
                                    this.colors().map((color, idx) => (
                                        <Grid container alignContent='center' justify='center' item
                                            onClick={this.changeColor(color)}
                                            key={idx} item
                                            className={classes.color}
                                            style={{ backgroundColor: color }}>
                                            {this.state.color == color ? <Icon className={classes.checkIcon}>check</Icon> : null}
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.disable()} onClick={this.save} size='small' color='primary'>
                        {this.state.isEdit ? t('Save') : t('Create')}
                    </Button>
                </DialogActions>
            </Fragment>)
    }
}


const mapStateToProps = (state) => ({
    collectionId: state.results.channel.id,
    collectionLabels: state.userData.collections.find(collection => collection.collectionUid == state.results.channel.collectionUid).collectionLabels,

    ...state.services
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('labelMenu'),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CreateLabel);