export default class GroupService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    AddCollectionToFavorites(collectionUid) {
        return fetch(this.backendUrl + `/collections/AddCollectionToFavorites?collectionUid=${collectionUid}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }

    RemoveCollectionFromFavorites(collectionUid) {
        return fetch(this.backendUrl + `/collections/RemoveCollectionFromFavorites?collectionUid=${collectionUid}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    getHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token
        });
    }

    getOpenCollections() {
        return fetch(this.backendUrl + '/collections/getOpenCollections',
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    getItemsStatus() {
        return fetch(this.backendUrl + '/collections/itemsStatus',
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    getAllLabels() {
        return fetch(this.backendUrl + '/collections/allLabels',
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }


    createGroup(group) {
        if (group) {
            return fetch(this.backendUrl + '/groups/new',
                {
                    method: 'POST',
                    body: JSON.stringify(group),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }

    updateCollection = (collection) => {
        var myHeaders = this.postHeaders();

        if (collection) {
            return fetch(this.backendUrl + '/collections/update',
                {
                    method: 'POST',
                    body: JSON.stringify(collection),
                    headers: myHeaders
                }).then(res => res.json());
        }
    }

    createCollection = (collection) => {
        if (collection) {
            return fetch(this.backendUrl + '/collections/new',
                {
                    method: 'POST',
                    body: JSON.stringify(collection),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }


    updateGroup(group) {
        if (group) {
            return fetch(this.backendUrl + '/groups/update',
                {
                    method: 'POST',
                    body: JSON.stringify(group),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }

    deleteGroup(groupId) {
        if (groupId !== '') {
            return fetch(this.backendUrl + '/groups?id=' + groupId,
                {
                    method: 'DELETE',
                    headers: this.getHeaders()
                })
                .then(function (res) {
                    if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
                });
        }
    }

    leaveGroup(collectionUid) {
        if (collectionUid) {
            return fetch(this.backendUrl + '/collections/removeUser',
                {
                    method: 'DELETE',
                    body: JSON.stringify(collectionUid),
                    headers: this.postHeaders()
                }).then(res => res);
        }
    }

    joinToPublicGroup(groupUid) {
        return fetch(`${this.backendUrl}/groups/addUser`,
            {
                method: 'POST',
                body: JSON.stringify(groupUid),
                headers: this.postHeaders()
            })

    }
    updateUserRole(groupUid, userId, userRole = 0) {
        return fetch(`${this.backendUrl}/groups/updateUserRole`,
            {
                method: 'POST',
                body: JSON.stringify({ groupUid, userId, userRole }),
                headers: this.postHeaders()
            })
    }
    updateUserClassification(groupUid, userId, isLimited = false) {
        return fetch(`${this.backendUrl}/groups/updateUserGroupClassification`,
            {
                method: 'POST',
                body: JSON.stringify({ groupUid, userId, isLimited }),
                headers: this.postHeaders()
            })
    }

    addUserToGroup(groupUid, userId, isAdmin = false, isLimited = false) {
        return fetch(`${this.backendUrl}/groups/addOtherUser`,
            {
                method: 'POST',
                body: JSON.stringify({ groupUid, userId }),
                headers: this.postHeaders()
            })
    }

    removeUserFromGroup(groupUid, userId, isAdmin = false, isLimited = false) {
        return fetch(`${this.backendUrl}/groups/removeOtherUser`,
            {
                method: 'POST',
                body: JSON.stringify({ groupUid, userId }),
                headers: this.postHeaders()
            })
    }


    addDocumentToGroup(groupId, docId) {
        if (groupId && docId) {
            return fetch(this.backendUrl + '/groups/addDocToColl',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        groupId,
                        docId
                    }),
                    headers: this.postHeaders()
                }).then(res => res);
        }
    }

    removeItemFromGroup(groupId, docId) {
        if (groupId && docId) {
            return fetch(this.backendUrl + '/groups/remove?groupId=' + groupId + '&itemId=' + encodeURIComponent(docId),
                {
                    method: 'GET',
                    headers: this.getHeaders()
                }).then(res => res.json());
        }
    }

    // Kanban
    addLane(lane) {
        if (lane) {
            return fetch(this.backendUrl + '/collections/newKanbanLane',
                {
                    method: 'POST',
                    body: JSON.stringify(lane),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }
    addGanttLane(lane) {
        if (lane) {
            return fetch(this.backendUrl + '/gantt/newGanttCategory',
                {
                    method: 'POST',
                    body: JSON.stringify(lane),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }

    moveLane(collection) {
        return fetch(this.backendUrl + '/collections/moveLane/', {
            method: 'PUT',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        }).then(res => res.json ? res.json() : res);
    }

    moveGanttCardInLane(moveInfo) {
        return fetch(this.backendUrl + '/gantt/ChangeTasksOrder/', {
            method: 'PUT',
            body: JSON.stringify(moveInfo),
            headers: this.postHeaders()
        }).then(res => res.json ? res.json() : res);
    }

    moveGanttLane(collection) {
        return fetch(this.backendUrl + '/gantt/moveCategory/', {
            method: 'PUT',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        }).then(res => res.json ? res.json() : res);
    }

    updateLane(collection) {
        return fetch(this.backendUrl + '/collections/updateLane/', {
            method: 'PUT',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        })/*.then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });*/
    }

    updateGanttLane(lane) {
        return fetch(this.backendUrl + '/gantt/updateCategory/', {
            method: 'PUT',
            body: JSON.stringify(lane),
            headers: this.postHeaders()
        })/*.then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });*/
    }

    updateNewLane(collId, lane) {
        return fetch(this.backendUrl + '/collections/updateNewLane?collId=' + collId, {
            method: 'PUT',
            body: JSON.stringify(lane),
            headers: this.postHeaders()
        })/*.then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });*/
    }

    deleteLane(collection) {
        return fetch(this.backendUrl + '/collections/deleteLane/', {
            method: 'DELETE',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        });
    }
    deleteGanttLane(laneId) {
        if(!laneId) return

        return fetch(this.backendUrl + '/gantt/deleteCategory/', {
            method: 'DELETE',
            body: JSON.stringify(laneId),
            headers: this.postHeaders()
        });
    }

    moveCard(movedCard) {
        return fetch(this.backendUrl + '/collections/moveCard/', {
            method: 'PUT',
            body: JSON.stringify(movedCard),
            headers: this.postHeaders()
        })
    }

    moveGanttCard(movedCard) {
        return fetch(this.backendUrl + '/gantt/moveTaskBetweenCategories/', {
            method: 'PUT',
            body: JSON.stringify(movedCard),
            headers: this.postHeaders()
        })
    }


    createLabel(label) {
        return fetch(this.backendUrl + '/collectionLabels/create',
            {
                method: 'POST',
                body: JSON.stringify(label),
                headers: this.postHeaders()
            })
            .then(res => res.json());
    }

    deleteLabel(labelId) {
        return fetch(this.backendUrl + `/collectionLabels/delete?labelId=${labelId}`,
            {
                method: 'DELETE',
                headers: this.postHeaders()
            })
    }

    editLabel(label) {
        return fetch(this.backendUrl + '/collectionLabels/edit',
            {
                method: 'POST',
                body: JSON.stringify(label),
                headers: this.postHeaders()
            })
            .then(res => res.json());
    }

    groupMailSetting(collectionId, enableEmail) {


        return fetch(this.backendUrl + `/collections/setupmail?collectionId=${collectionId}&enableEmail=${enableEmail}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    addLabelToItem(label, docId) {
        return fetch(this.backendUrl + `/collectionLabels/tagItem?docId=${docId}`,
            {
                method: 'POST',
                body: JSON.stringify(label),
                headers: this.postHeaders()
            })
            .then(res => res.ok ? res.json() : null);
    }

    removeLabelFromItem(label, docId) {
        return fetch(this.backendUrl + `/collectionLabels/removeItem?docId=${docId}`,
            {
                method: 'POST',
                body: JSON.stringify(label),
                headers: this.postHeaders()
            })
            .then(res => res.ok ? res.json() : null);
    }

    getGroupUserDoneStats(groupUid, laneId) {
        return fetch(this.backendUrl + `/group-analytics/users-done-stats?groupUid=${groupUid}&laneId=${laneId}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    getGroupPremiumDashboardToken(collectionId) {
        return fetch(this.backendUrl + `/premiumdashboard?collectionId=${collectionId}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    getGroupDashboardToken(collectionUid) {
        return fetch(this.backendUrl + `/collections/dashboard-token?collectionUid=${collectionUid}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    createExternalInput(collectionId, dataMappingId, title, description, fileid, fieldsToShow = [],finishMessage) {
        const fieldsToShowStr = fieldsToShow.join(';')
        return fetch(this.backendUrl + `/collections/createExternalInput`,
            {
                body: JSON.stringify({
                    collectionId, dataMappingId, title, description, fileid, fieldsToShow: fieldsToShowStr,finishMessage
                }),
                method: 'POST',
                headers: this.postHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }
    editExternalInput(eiUid, collectionId, dataMappingId, title, description, fileid, fieldsToShow = [],finishMessage) {
        const fieldsToShowStr = fieldsToShow.join(';')
        return fetch(this.backendUrl + `/collections/updateExternalInput?uid=${eiUid}`,
            {
                body: JSON.stringify({
                    collectionId, dataMappingId, title, description, fileid, fieldsToShow: fieldsToShowStr,finishMessage
                }),
                method: 'PUT',
                headers: this.postHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }


    deleteExternalInput(collectionId, externalInputId) {
        return fetch(this.backendUrl + `/collections/deleteExternalInput?collectionId=${collectionId}&inputId=${externalInputId}`,
            {
                method: 'DELETE',
                headers: this.getHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }
    getExternalInputInfo(uid) {
        return fetch(this.backendUrl + `/collections/externalInputInfo?externalInputUid=${uid}`,
            {
                method: 'GET',
                headers: this.getHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }


    deleteCollection(collectionUid) {
        return fetch(this.backendUrl + `/collections/deleteCollection?collectionUid=${collectionUid}`,
            {
                method: 'DELETE',
                headers: this.getHeaders()
            }).then(res => res.json()).catch((e) => console.log(e));

    }

}