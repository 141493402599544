import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ChatOption from './chatOption/ChatOption';
import NotChats from './NotChats';

const styles = (theme) => ({
    content: {
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${theme.constants.defaultToolbarHeight}px - 24px - 52px)`,
    },
    spinner: {
        position: 'fixed',
        right: '50vw',
        top: '50vh',
        zIndex: 1301,
    }
})

const ChatsList = (props) => {
    const {results, classes} = props

    const sorted = useMemo(() => {
        const sorted = results.sort((resA, resB) => new Date(resB.commentTime) - new Date(resA.commentTime))
        return sorted
    }, [results])

    const onPressItem = (room) => {}

    if(!sorted.length) return <NotChats />

    return <div data-intrcm-id="ChatsList_7s8mu4wf" className={classes.content}>
        {sorted.map((item, i) => <ChatOption key={i} onPress={onPressItem} option={item}/>)}
    </div>
}

const mapStateToProps = (state) => {
    return {
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('chat'),
    connect(mapStateToProps, mapDispatchToProps),
)(ChatsList)