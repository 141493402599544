import React from 'react'
import { Badge, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import withErrorCatcher, { getDefaultErrorMessage } from './withErrorCatcher.js'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames'
import LevelBadge from '../../../utils/levleBadge.js';
import goTo from '../../actions/goTo.js';
import PropTypes from 'prop-types'
import parseImagUrl from "../../../ui/components/Common/parseImagUrl";

const styles = theme => ({
    badge: {
        top: 35,
        right: 0,
    },
    avatar_default: {
        width: variants.default.size,
        height: variants.default.size,
    },
    avatar_small: {
        width: variants.small.size,
        height: variants.small.size,
    },
    avatar_big: {
        width: variants.big.size,
        height: variants.big.size,
    },
    avatar_extraSmall: {
        width: variants.extraSmall.size,
        height: variants.extraSmall.size
    },
    avatar_chatFeed: {
        width: variants.chatFeed.size,
        height: variants.chatFeed.size
    },
    avatar_header: {
        width: variants.header.size,
        height: variants.header.size
    },
    pointer: {
        cursor: 'pointer'
    }
});

const variants = {
    default: {
        size: 40
    },
    header: {
        size: 24
    },
    small: {
        size: 30
    },
    big: {
        size: 78
    },
    extraSmall: {
        size: 20
    },
    chatFeed: {
        size: 48
    }
}


class UserAvatar extends React.PureComponent {

    constructor(props) {
        super(props)
        const { userUUID, users } = this.props
        this.state = {
            user: this.getUserDetails(userUUID, users)
        }
    }

    getVariant = () => variants[this.props.variant ? this.props.variant : 'default']

    getUserDetails = (userUUID, users) => {
        if(this.props.useNewui) {
            users = this.props.usersNewui
        }

        const user = users.find(x => x.auth0_id === userUUID)
        if (!user) {
            const defaultUser = {
                username: 'Tuqqi Robot',
                tag: 'tuqqirobot',
                profileImageUrl: './system/Tuqqi_logo_trans_WhiteLabel4040.png',
                level: 0
            }
            return defaultUser
        }
        let profileImageUrl = user.profileImageUrl
        if(this.props.useNewui) { 
            profileImageUrl = parseImagUrl(this.props.cloudfront, user.profileImageUrl)
        }

        return {
            userUUID,
            username: `${user.firstname} ${user.lastname}`,
            profileImageUrl: profileImageUrl,
            level: user.level,
            tag: user.tag
        }
    }

    openUser = () => {
        const { tag } = this.state.user
        let userTagFormat = ''
        if (!tag.startsWith('@')) {
            userTagFormat = `@`
        }
        userTagFormat += tag.replace(' ', '').toLowerCase()

        goTo(userTagFormat, this.props.dispatch)()
    }

    isSameImage = (prevProps) => {
        if(this.useNewui) {
            const users = this.props?.usersNewui ?? []
            const usersPrev = prevProps.usersNewui ?? []
        
            const user = users.find(x => x.auth0_id === this.props.userUUID)
            const user1 = usersPrev.find(x => x.auth0_id === this.props.userUUID)
            return user?.profileImageUrl === user1?.profileImageUrl
        }

        const users = this.props?.users ?? []
        const usersPrev = prevProps?.users ?? []
    
        const user = users.find(x => x.auth0_id === this.props.userUUID)
        const user1 = usersPrev.find(x => x.auth0_id === this.props.userUUID)
        return user?.profileImageUrl === user1?.profileImageUrl
    }

    componentDidUpdate(prevProps, prevState) {
        const oldUid = this.props.userUUID === prevProps.userUUID
        const oldUserImg = this.isSameImage(prevProps)
        if (oldUid && oldUserImg) {
            return
        }

        const { userUUID, users } = this.props
        this.setState({ user: this.getUserDetails(userUUID, users) })
    }

    render() {
        const { classes, hideLevel, withShadow, onClick, variant, userData, withoutBadge } = this.props
        const { profileImageUrl, level, username, tag } = this.state.user

        const avatar = <Avatar alt={username}
            className={classNames([classes[`avatar_${variant || 'default'}`], classes.avatar, classes.pointer, (withShadow ? classes.shadow : '')])}
            src={profileImageUrl}
            onClick={onClick ? onClick : this.openUser} />

        if (hideLevel) {
            return avatar
        }

        return <Badge className={classes.margin} badgeContent={!!withoutBadge ? null : <LevelBadge level={level} themeOption={userData.profileData.org.brandingColor}/>} classes={{ badge: classes.badge }}>
            {avatar}
        </Badge>
    }
}

UserAvatar.propTypes = {
    userUUID: PropTypes.string,
    variant: PropTypes.string,
    hideLevel: PropTypes.bool,
    withShadow: PropTypes.bool,
    onClick: PropTypes.func,
    classes: PropTypes.object
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    cloudfront: state.globalData.cloudfront,
    users: state.globalData.users,
    usersNewui: state.data.globalData.users?.data ?? [],
})


const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading User, Please Contact Tuqqi support"),
    connect(mapStateToProps, mapDispatchToProps))(UserAvatar);