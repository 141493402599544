import React, { PureComponent } from 'react'
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles  } from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from 'recompose';
import KanbanLabels from './KanbanLabels.js';


const styles = theme => ({
    container: {
        width: 188
    },
    text: {
        lineHeight: 1.34,
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "letterSpacing": "0.4px",
        "color": "#ffffff"
    }
})


class KanbanLabelsWrapper extends PureComponent {

   

    render() {
        const { userCollections, openCollections, collectionUid, classes, docLabels,adminService,orgUid,orgLabels } = this.props

        
        return <KanbanLabels
            userCollections={userCollections}
            openCollections={openCollections}
            collectionUid={collectionUid}
            classes={classes}
            docLabels={docLabels}
            adminService={adminService}
            orgUid={orgUid}
            orgLabels={orgLabels}
        />
    }
}

const mapStateToProps = (state) => ({
    userCollections: state.data.userData.userCollections.data,
    openCollections: state.data.globalData.openCollections.data,
    collectionUid: state.results.channel.collectionUid,
    adminService: state.services.adminService,
    orgUid: state.data.userData.userDetails.data.organizationUid,
    orgLabels: state.data.globalData.labels.labels
})



export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(mapStateToProps, null))(KanbanLabelsWrapper);

