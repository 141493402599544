import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { ExitToApp, Help, VerifiedUser } from '@material-ui/icons';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PrivacyPolicy from '../../components/Common/privacyPolicy';
import withErrorCatcher from '../../components/Common/withErrorCatcher';

// Apply some reset
const styles = theme => ({
    root: {
        minWidth: '360px',
        background: theme.palette.background.paper,
    },
});

const TranslatedListItemSettingsText = withTranslation('userSettingsDrawer')((props) => {
    const { primary, secondary, t } = props

    return <ListItemText inset primary={t(primary)} secondary={t(secondary)} />
})

class UserDrawer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
            inviteValue: '',
            isValid: false,
            hasError: false
        }
    }

    render() {
        const { classes, t } = this.props;

        const helpSenterLink = t('drawerListItemTerms:helpLink')

        return <Drawer open={this.props.userSettingsOpen}
            anchor="right"

            onClose={(open) =>
                this.props.dispatch({
                    type: "DRAWER_TOGGLE",
                    toggled: { userSettingsOpen: false }
                })
            }
        >
            <List className={classes.root} >
                {<ListSubheader>{t('SETTINGS')}</ListSubheader>}
                <Divider />

                <ListItem button onClick={() => { window.open(helpSenterLink, '_blank'); }}>
                    <ListItemIcon>
                        <Help />
                    </ListItemIcon>
                    <TranslatedListItemSettingsText inset primary="Help Center" secondary="Tuqqi help center" />
                </ListItem>
                <ListItem button onClick={() => { this.setState({ openDialogPrivacy: true }) }}>
                    <ListItemIcon>
                        <VerifiedUser />
                    </ListItemIcon>
                    <TranslatedListItemSettingsText inset primary="Privacy policy" secondary="Tuqqi privacy policy" />
                </ListItem>

                <PrivacyPolicy
                    open={this.state.openDialogPrivacy}
                    close={() => { this.setState({ openDialogPrivacy: false }) }}
                />

                <Divider />
                <ListItem button onClick={() => { this.props.dispatch({ type: 'USER_LOGOUT' }); this.props.auth0.logout({ returnTo: process.env.AUTH0_REDIRECT }) }}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <TranslatedListItemSettingsText inset primary="Sign out" secondary="Click to sign out" />
                </ListItem>
            </List>
        </Drawer>
    }

}

const mapStateToProps = (state) => ({
    ...state.wrapper,
    userData: state.userData,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    withAuth0,
    withTranslation('userSettingsDrawer', 'drawerListItemTerms'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(UserDrawer);

