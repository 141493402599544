import React, { PureComponent } from 'react';

export default class SearchMoreIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="4" y="4" width="16" height="16" rx="8" stroke={color} stroke-width="1.5"/>
            <rect x="8.5" y="11" width="1" height="2" fill={color}/>
            <rect x="11.5" y="11" width="1" height="2" fill={color}/>
            <rect x="14.5" y="11" width="1" height="2" fill={color}/>
            </svg>
        )
    }
}