import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js';
import { Button, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PollAddOption from './PollAddOption.js';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    moreOptions: {
        border: 'solid 1px rgba(151, 154, 170, 0.3)',
        width: '100%',
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: '#979aaa',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#21212d',
        }
    }
})

class PollMoreOptions extends PureComponent {
    render(){
        const { classes, secondOptionsGroup, moreOpen, optionsLength, isAddOwn, renderOption, numberToShow, showMore, addOption, t } = this.props;

        if(optionsLength <= numberToShow){
            return <PollAddOption isAddOwn={isAddOwn} addOption={addOption} />
        }
        else {
            if(!moreOpen){
                return <Button className={classes.moreOptions} color="primary" onClick={showMore} >
                    {t('more options', { optionsNumber: optionsLength - numberToShow })}
                </Button>
            }
            else{
                return <div data-intrcm-id="PollMoreOptions_wzv6cu3m">
                    {secondOptionsGroup.map((option, idx) => {
                        return renderOption(idx + numberToShow, option);
                    })}
                    
                    <PollAddOption isAddOwn={isAddOwn} addOption={addOption} />
                </div>
            }
        }
    }
}

export default compose(withStyles(styles), withTranslation('pollTemplate'),)(PollMoreOptions)