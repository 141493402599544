import { AppBar, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { alignOptions, colorOptions, variantOptions } from '../customInputTemplateUtils';


const styles = theme => ({
  paper: {
    padding: 30
  },
  divid: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  buttonIc: {
    marginTop: '8px',
    fontSize: '14px'
  },
  icon: {
    paddingLeft: 15,
    marginLeft: 'auto'
  },
  fieldContainer:{
    margin:'24px 0'
  },
  formControl:{
    margin:'0 5px'
  },
    root: {
        width: 700,
        borderRadius: 16,
        minHeight: 350
    },
    textField: {
        marginRight: 20
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        paddingBottom: 15,
        fontWeight: 'bold'
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class EditAllFieldStyles extends React.Component {
  	constructor(props, context) {
		super(props, context);
		this.state = {
            content: { align: 'inherit', color: 'default', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false,
            },
            title: { align: 'inherit', color: 'default', variant: '', gutterBottom: false, inline: false,
                internalDeprecatedVariant: false, noWrap: false, paragraph: false,
            },
            tab: 'title'
    	}
    }

    onAdd = () => {
        const { onClose } = this.props;
        const { content, title } = this.state;

        onClose({ titleStyle: title, contentStyle: content })
    }

    handleChangeStyle = (isTitle, name, isChecked) => (e) => {
        let value = isChecked ? e.target.checked : e.target.value;

        if(isTitle) {
            let title = {...this.state.title};
            title[name] = value

            this.setState({ title })
        }
        else{
            let content = {...this.state.content};
            content[name] = value

            this.setState({ content })
        }
    }

    renderStyleSelection = (inputLabel, value, fieldName, options, formHelperText, isTitle) => {
        const { classes } = this.props;

        return <FormControl className={classes.formControl} fullWidth >
            <InputLabel >{inputLabel}</InputLabel>
            <Select
                value={value}
                onChange={this.handleChangeStyle(isTitle, fieldName)}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                { options.map((option, idx) => (
                    <MenuItem value={option}>
                        {option}
                    </MenuItem>
                )) }
            </Select>
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderStyleCheckBox = (inputLabel, value, fieldName, formHelperText, isTitle) => {
        const { classes } = this.props;

        return <FormControl className={classes.formControl} fullWidth>
            <FormControlLabel
                control={
                    <Switch checked={value} 
                        onChange={this.handleChangeStyle(isTitle, fieldName, true)} 
                    />}
                label={inputLabel}
            />
            <FormHelperText id="component-helper-text">{formHelperText}</FormHelperText>
        </FormControl>
    }

    renderStyle = (isTitle) => {
        const { classes } = this.props;
        const { content, title } = this.state;

        const { align, color, variant, gutterBottom, inline, internalDeprecatedVariant, noWrap, paragraph } = isTitle ? title : content;

        return <div data-intrcm-id="editAllFieldStyles_frg9req1">
            <Typography classes={{root: classes.title}}>{isTitle ? 'Title' : 'Content'} Style</Typography>

            {this.renderStyleSelection('Align', align, 'align', alignOptions, 'Set the text-align on the component.', isTitle)}
            {this.renderStyleSelection('Color', color, 'color', colorOptions, 'The color of the component. It supports those theme colors that make sense for this component.', isTitle)}
            {this.renderStyleSelection('Variant', variant, 'variant', variantOptions, 'Applies the theme typography styles. Use body1 as the default value with the legacy implementation and body2 with the new one.', isTitle)}
            
            {this.renderStyleCheckBox('Gutter Bottom', gutterBottom, 'gutterBottom', 'If true, the text will have a bottom margin.', isTitle)}
            {this.renderStyleCheckBox('Inline', inline, 'inline', 'Controls whether the Typography is inline or not.', isTitle)}
            {this.renderStyleCheckBox('Internal Deprecated Variant', internalDeprecatedVariant, 'internalDeprecatedVariant', 
                <div data-intrcm-id="editAllFieldStyles_luv7gz1g">
                    <div data-intrcm-id="editAllFieldStyles_916zh905">A deprecated variant is used from an internal component.</div> 
                    <div data-intrcm-id="editAllFieldStyles_rj894kuf">Users don't need a deprecation warning here if they switched to the v2 theme.</div> 
                    <div data-intrcm-id="editAllFieldStyles_kjenmjv3">They already get the mapping that will be applied in the next major release.</div>
                </div>
            , isTitle)}
            {this.renderStyleCheckBox('No Wrap', noWrap, 'noWrap', 'If true, the text will not wrap, but instead will truncate with an ellipsis.', isTitle)}
            {this.renderStyleCheckBox('Paragraph', paragraph, 'paragraph', 'If true, the text will have a bottom margin.', isTitle)}
        </div>
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ tab: newValue })
    }

 	render() {
        const { open, onClose, classes,t } = this.props;
        const { tab } = this.state;

		return <Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            transition={Transition}
			classes={{ paper: classes.root }}
		>
            <DialogContent>
                <AppBar position="static" color="default">
                    <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={this.handleChangeTab} >
                        <Tab label={t("Title")} value='title'/>
                        <Tab label={t("Content")} value='content'/>
                    </Tabs>
                </AppBar>
                {this.renderStyle(tab === 'title')}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={this.onAdd} color="primary">
                    {t('Update')}
                </Button>
            </DialogActions>
		</Dialog>
  	}
}

const mapStateToProps = (state) => ({
	customInputService: state.services.customInputService,
	globalData: state.globalData,
	mappings: state.customInputMapping.currentMapping
})

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch,
		goTo: path => { dispatch(push(path)) }
  	}
}

export default compose(withStyles(styles),
    withTranslation("admin"),
  	connect(mapStateToProps, mapDispatchToProps)
)(EditAllFieldStyles);