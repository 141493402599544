import { withStyles, Grid } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';




const styles = theme => ({

});


class CategoryViewOnlyLeftDrawer extends Component {
    render() {
        const { classes, leftWidgets, children } = this.props;

        return (
            <Fragment >
                {children}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    ...state.wrapper,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CategoryViewOnlyLeftDrawer);