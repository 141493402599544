// export const ADD_TODO = 'ADD_TODO'
// export const TOGGLE_TODO = 'TOGGLE_TODO'
// export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'


// Wrapper
export const DRAWER_TOGGLE = 'DRAWER_TOGGLE';
export const USE_FULL_SCREEN = 'USE_FULL_SCREEN';

// Input action types
export const INPUT_OPEN = 'INPUT_OPEN';
export const INPUT_EDIT_OPEN = 'INPUT_EDIT_OPEN';
export const INPUT_CLOSE = 'INPUT_CLOSE';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const INPUT_JSON_CHANGE = 'INPUT_JSON_CHANGE';
export const INPUT_AGGREGATIONS_CHANGE = 'INPUT_AGGREGATIONS_CHANGE';
export const INPUT_LINKS_CHANGE = 'INPUT_LINKS_CHANGE';
export const INPUT_OPEN_FILE = 'INPUT_OPEN_FILE';
export const INPUT_ADD_LINKED = "INPUT_ADD_LINKED"
export const INPUT_REMOVE_LINK_BY_DATA_TYPE = 'INPUT_REMOVE_LINK_BY_DATA_TYPE'
export const INPUT_REMOVE_LINK_BY_ID = 'INPUT_REMOVE_LINK_BY_ID'
export const INPUT_EDIT_LINK_UNIQE_DATA_TYPE = 'INPUT_EDIT_LINK_UNIQE_DATA_TYPE'
export const INPUT_COLLECTION_INFO_CHANGE = 'INPUT_COLLECTION_INFO_CHANGE'

export const INPUT_CHANGE_POLL = 'INPUT_CHANGE_POLL'

// Snackbar
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SHOW_NOTIFICATION_SNACKBAR = 'SHOW_NOTIFICATION_SNACKBAR';
export const HIDE_NOTIFICATION_SNACKBAR = 'HIDE_NOTIFICATION_SNACKBAR';

// User data
export const USER_DATA_LOAD = 'USER_DATA_LOAD';
export const USER_DATA_REFRESH = 'USER_DATA_REFRESH';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SUBSCRIBED_LOAD = 'USER_SUBSCRIBED_LOAD';
export const USER_COLLECTION_LOAD = 'USER_COLLECTION_LOAD';
export const USER_FAVORITES_LOAD = 'USER_FAVORITES_LOAD';
export const USER_NOTIFICATION_REFRESH = 'USER_NOTIFICATION_REFRESH'
export const USER_NOTIFICATION_ADD = 'USER_NOTIFICATION_ADD';

// Data 
export const FEED_NEW_ITEMS = "FEED_NEW_ITEMS";
export const DATA_UPDATE = 'DATA_UPDATE';
export const DATA_LOADMORE = 'DATA_LOADMORE';
export const DATA_FEED_UPDATE = 'DATA_FEED_UPDATE';
export const DATA_UPDATED = 'DATA_UPDATED';
export const DATA_UPDATE_SPECIFIC_ITEM = 'DATA_UPDATE_SPECIFIC_ITEM';
export const DATA_FILTER = 'DATA_FILTER';
export const DATA_BEING_FILTERED = 'DATA_BEING_FILTERED'
export const DATA_FILTERED = 'DATA_FILTERED';
export const DATA_WIDGET = 'DATA_WIDGET';
export const DATA_WIDGET_LOADED = 'DATA_WIDGET_LOADED';
export const FINISHED_UPDATING_ITEM = 'FINISHED_UPDATING_ITEM';
// Global data
export const GLOBAL_UPDATE = 'GLOBAL_UPDATE';
export const GLOBAL_LOAD = 'GLOBAL_LOAD';
export const GLOBAL_TRENDS_LOAD = 'GLOBAL_TRENDS_LOAD';
export const GLOBAL_USERS_LOAD = 'GLOBAL_USERS_LOAD';
export const GLOBAL_SOURCES_LOAD = 'GLOBAL_SOURCES_LOAD';
export const GLOBAL_TEMPLATES_LOAD = 'GLOBAL_TEMPLATES_LOAD';
export const GLOBAL_INPUT_MAPPINGS_LOAD = 'GLOBAL_INPUT_MAPPINGS_LOAD';
export const GLOBAL_CLASSIFICATIONS_LOAD = 'GLOBAL_CLASSIFICATIONS_LOAD';
export const GLOBAL_FAVORITES_LOAD = 'GLOBAL_FAVORITES_LOAD';
export const GLOBAL_VIEWS_LOAD = 'GLOBAL_VIEWS_LOAD';
export const GLOBAL_SCORES_LOAD = 'GLOBAL_SCORES_LOAD';
export const GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE = 'GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE';
export const GLOBAL_SUBSCRIPTION_LOAD = 'GLOBAL_SUBSCRIPTION_LOAD';

// Custom mapping
export const MAPPING_EDIT_MAPPING = 'MAPPING_EDIT_MAPPING';
export const MAPPING_EDIT_SOURCE = 'MAPPING_EDIT_SOURCE';
export const MAPPING_EDIT_CLASSIFICATION = 'MAPPING_EDIT_CLASSIFICATION';
export const MAPPING_EDIT_USER = 'MAPPING_EDIT_USER';
export const MAPPING_EDIT_MAPPING_CHANGE = 'MAPPING_EDIT_MAPPING_CHANGE';
export const MAPPING_EDIT_MAPPING_ROW = 'MAPPING_EDIT_MAPPING_ROW';
export const MAPPING_EDIT_CUSTOM_FIELD_ROW = 'MAPPING_EDIT_CUSTOM_FIELD_ROW';
export const MAPPING_EDIT_CERTIFICATION = 'MAPPING_EDIT_CERTIFICATION';


// Custom input mapping?
export const CUSTOM_INPUT_EDIT_MAPPING = 'CUSTOM_INPUT_EDIT_MAPPING';

// Search
export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_QUERY_UPDATE = 'SEARCH_QUERY_UPDATE';
export const CLEAN_FILTER = 'CLEAN_FILTER';
export const SEARCH_SPECIFIC_ITEM = 'SEARCH_SPECIFIC_ITEM'
export const APPLY_LOCAL_FILTER = 'APPLY_LOCAL_FILTER'
export const CLEAN_LOCAL_FILTER = 'CLEAN_LOCAL_FILTER';

export const ADD_RECENT_GROUP = 'ADD_RECENT_GROUP'
export const ADD_RECENT_USER = 'ADD_RECENT_USER'
export const ADD_RECENT_ITEM = 'ADD_RECENT_ITEM'
export const REMOVE_RECENT_ITEM = 'REMOVE_RECENT_ITEM'
export const REMOVE_RECENT_GROUP = 'REMOVE_RECENT_GROUP'
export const REMOVE_RECENT_USER = 'REMOVE_RECENT_USER'

// Result actions
export const RESULT_CHANNEL_EMPTY = 'RESULT_CHANNEL_EMPTY';
export const RESULT_FILTERS_UPDATE = 'RESULT_FILTERS_UPDATE';
export const RESULT_CHANNEL_UPDATE = 'RESULT_CHANNEL_UPDATE';
export const RESULT_CHANNEL_FILTER_UPDATE = 'RESULT_CHANNEL_FILTER_UPDATE';

// Group
export const GROUP_UPDATE = 'GROUP_UPDATE';
export const COLLECTION_INIT = 'COLLECTION_INIT';
export const COLLECTION_UPDATE = 'COLLECTION_UPDATE';
export const TOGGLE_COLLECTION_DIALOG = 'TOGGLE_COLLECTION_DIALOG';
export const COLLECTION_LABELS_UPDATE = 'COLLECTION_LABELS_UPDATE';
export const COLLECTION_SETTINGS_UPDATE = 'COLLECTION_SETTINGS_UPDATE';
export const COLLECTION_GROUP_UPDATE = 'COLLECTION_GROUP_UPDATE';
export const ADD_EXTERNAL_INPUT = 'ADD_EXTERNAL_INPUT';
export const EDIT_EXTERNAL_INPUT = 'EDIT_EXTERNAL_INPUT';
export const REMOVE_EXTERNAL_INPUT = 'REMOVE_EXTERNAL_INPUT';


// FullView
export const FULLVIEW_POST = 'FULLVIEW_POST';
export const FULLVIEW_CHANGE = 'FULLVIEW_CHANGE';
export const FULLVIEW_NEW = 'FULLVIEW_NEW';
export const FULLVIEW_EDIT = 'FULLVIEW_EDIT';
export const FULLVIEW_EDIT_LOAD = 'FULLVIEW_EDIT_LOAD';
export const FULLVIEW_OPEN = 'FULLVIEW_OPEN';
export const FULLVIEW_OPEN_LOAD = 'FULLVIEW_OPEN_LOAD';
export const FULLVIEW_CLOSE = 'FULLVIEW_CLOSE';
export const FULLVIEW_LOADED = 'FULLVIEW_LOADED';
export const FULLVIEW_LOADING = 'FULLVIEW_LOADING';

// preview
export const PREVIEW_SHOW_ANALYZE = 'PREVIEW_SHOW_ANALYZE';
export const PREVIEW_CLOSE_ANALYZE = 'PREVIEW_CLOSE_ANALYZE';

// Topic
export const TOPIC_MAIN_VIEW = 'TOPIC_MAIN_VIEW';

// Confirm Dialog
export const CONFIRM_DIALOG_OPEN = 'CONFIRM_DIALOG_OPEN';
export const CONFIRM_DIALOG_CLOSE = 'CONFIRM_DIALOG_CLOSE';
export const CONFIRM_IS_LOADING = 'CONFIRM_IS_LOADING'
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_LANE = 'DELETE_LANE';
export const DELETE_CERTIFICATION = 'DELETE_CERTIFICATION';
export const DELETE_LANE_RULE = 'DELETE_LANE_RULE';
export const DELETE_CUSTOM_INPUT_MAPPING = 'DELETE_CUSTOM_INPUT_MAPPING';
export const INIT_CONFIRM_DIALOG = 'INIT_CONFIRM_DIALOG';
export const DELETE_LABEL = 'DELETE_LABEL';

// Video chat
export const SHOW_VIDEO_CHAT = 'SHOW_VIDEO_CHAT';
export const HIDE_VIDEO_CHAT = 'HIDE_VIDEO_CHAT';
export const HIDE_UPGRADE_FROM_FREE = 'HIDE_UPGRADE_FROM_FREE';
export const SHOW_UPGRADE_FROM_FREE = 'SHOW_UPGRADE_FROM_FREE';



//Input Related Docs
export const ADD_INPUT_RELATED_DOC = 'ADD_INPUT_RELATED_DOC'
export const REMOVE_INPUT_RELATED_DOC = 'REMOVE_INPUT_RELATED_DOC'

export const FULL_RESET_STORE = 'FULL_RESET_STORE'
export const REMOVE_LOCAL_FILTER = 'REMOVE_LOCAL_FILTER'

// Time Tracker
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME'
export const SET_TIME_TRACKING_TASK = 'SET_TIME_TRACKING_TASK'
export const ACTIVATE_TIMER = 'ACTIVATE_TIMER'
export const DISABLED_TIMER  = 'DISABLED_TIMER '
export const SET_ACTIVE_TASK_ID = 'SET_ACTIVE_TASK_ID'