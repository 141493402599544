import { updateGroupDefaultViewActionTypes, updateGroupDisplayedViewsActionTypes } from '@tuqqi/common';
import {
    DRAWER_TOGGLE,
    FULL_RESET_STORE,
    GLOBAL_UPDATE,
    GLOBAL_LOAD,
    GLOBAL_TRENDS_LOAD,
    GLOBAL_USERS_LOAD,
    GLOBAL_SOURCES_LOAD,
    GLOBAL_TEMPLATES_LOAD,
    GLOBAL_INPUT_MAPPINGS_LOAD,
    GLOBAL_CLASSIFICATIONS_LOAD,
    GLOBAL_FAVORITES_LOAD,
    GLOBAL_VIEWS_LOAD,
    GLOBAL_SCORES_LOAD,
    GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE,
    GLOBAL_SUBSCRIPTION_LOAD
} from '../actionTypes/actionTypes'

const initialState = {
    tuqqiVersion: process.env.CURRENT_VERSION,
    trends: [],
    scores: [],
    templates: {full: [], preview: []},
    inputMappings: [],
    profileData: undefined,
    users: [],
    activeUsers: [],
    sourceFields: [],
    filestack: {view: {policy: ''}, write: {policy: ''}},
    classifications: [],
    knowledgelevels: [],
    currntChallengeStatus: {scores: []},
    cloudfront: {ip: '', signed: '', signedFiles: ''},
    cloudfrontAdditional: {
        lastFetch: 0,
        isFetching: false
    },
    openCollections: [],
    defaultInputTemplates: [],
    mostActivityUsersInCompany: [],
    currentSubscription: {},
    rehydrated: false
};

const global = (state = initialState, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if(!action.payload || action.error) return {...state, rehydrated: false}
            return {...action.payload.globalData, rehydrated: true}
        }
        case FULL_RESET_STORE:
            return initialState;

        case GLOBAL_UPDATE:
            return {
                ...state,
                dataGLOBALUpdate: state.dataGLOBALUpdate + 1
            }

        case GLOBAL_LOAD:
            return {
                ...state,
                ...action.data,

            }

        // Remove these
        case GLOBAL_TRENDS_LOAD:
            return {
                ...state,
                trends: action.trends
            }
        case GLOBAL_USERS_LOAD:
            return {
                ...state,
                users: action.users
            }
        case GLOBAL_SOURCES_LOAD:
            return {
                ...state,
                sources: action.sources
            }
        case GLOBAL_TEMPLATES_LOAD:
            return {
                ...state,
                templates: action.templates
            }
        case GLOBAL_INPUT_MAPPINGS_LOAD:
            return {
                ...state,
                inputMappings: action.inputMappings
            }
        case GLOBAL_CLASSIFICATIONS_LOAD:
            return {
                ...state,
                classifications: action.classifications
            }
        case GLOBAL_FAVORITES_LOAD:
            return {
                ...state,
                favorites: action.favorites
            }
        case GLOBAL_VIEWS_LOAD:
            return {
                ...state,
                views: action.views
            }
        case GLOBAL_SCORES_LOAD:
            return {
                ...state,
                scores: action.scores
            }
        case 'REFETCH_CLOUDFRONT_START':
            return {
                ...state,
                cloudfrontAdditional: {
                    ...state.cloudfrontAdditional,
                    isFetching: true
                }
            }
        case 'REFETCH_CLOUDFRONT_ERROR':
            return {
                ...state,
                cloudfrontAdditional: {
                    ...state.cloudfrontAdditional,
                    isFetching: false
                }
            }
        case 'REFETCH_CLOUDFRONT_DONE':
            return {
                ...state,
                cloudfrontAdditional: {
                    lastFetch: new Date().getTime(),
                    isFetching: false
                }
            }
        case GLOBAL_SUBSCRIPTION_LOAD:
            return {
                ...state,
                currentSubscription: action.currentSubscription
            }
        case updateGroupDefaultViewActionTypes.start: {
            const startAction = action
        
            if(!startAction?.additionalData?.collectionId) return state
            const {defaultView, collectionId} = startAction?.additionalData
            if(!defaultView || !collectionId) return state
        
            const openCollections = state.openCollections.map(collection => {
                if(collection.id !== collectionId) return collection
                return {
                    ...collection,
                    defaultView: defaultView
                }
            })
        
            return {
                ...state,
                openCollections
            }
        }
        case updateGroupDisplayedViewsActionTypes.start: {
            const startAction = action
        
            if(!startAction?.additionalData?.collectionId) return state
            const {availableViews, collectionId} = startAction?.additionalData
            if(!availableViews || !collectionId) return state
        
            const openCollections = state.openCollections.map(collection => {
                if(collection.id !== collectionId) return collection
                return {
                    ...collection,
                    availableViews: availableViews
                }
            })
        
            return {
                ...state,
                openCollections
            }
        }
        case 'UPDATE_PARTIAL_USER_BY_UID': {
            const successAction = action
            if(!successAction.auth0_id || !successAction.result || !Object.keys(successAction.result).length) return state

            const users = state.users.map(u => u.auth0_id === successAction.auth0_id ? {...u, ...successAction.result} : u)
            const activeUsers = state.activeUsers.map(u => u.auth0_id === successAction.auth0_id ? {...u, ...successAction.result} : u)

            return {
                ...state,
                users,
                activeUsers
            }
        }

        case GLOBAL_DEFAULT_INPUT_TEMPLATE_UPDATE:
            const oldDefaultInputTemplates = state.defaultInputTemplates
                .filter(template => template.id !== action.updatedTemplate.id)

            return {
                ...state,
                defaultInputTemplates: [...oldDefaultInputTemplates, action.updatedTemplate]
            }

        default:
            return state
    }
}

export default global