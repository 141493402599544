import React from 'react';
import { Divider, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

const styles = (theme) => ({
    container: {
        position: 'relative',
        width: theme.constants.searchInputWidth - 40,
        height: 56,
        marginRight: 12,
        borderRadius: 4,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginRight: 0
        },
        display: 'flex',
        flexDirection: 'row',
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.23,
        borderRadius: 6,
        width: theme.constants.searchInputWidth - 40 + 10,
        height: 56 + 20 - 1,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    pic: {
        zIndex: 2,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: 80,
        height: 56,
    },
    texts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    title: {
        minHeight: 20,
        maxWidth: 320,
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'start',
        color: '#21212d',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: 'rgba(0,0,0,1)'
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginLeft: 16,
        zIndex: 2
    },
    description: {
        minHeight: 20,
        maxWidth: 320,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        textAlign: 'start',
        color: '#979aaa',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginLeft: 16,
        zIndex: 2
    },
    icon: {
        display: 'flex',
        alignSelf: 'center',
        cursor: 'pointer',
        zIndex: 2
    },
    iconWrap: {
        display: 'flex',
        position: 'relative',
        marginRight: 0,
        marginLeft: 'auto'
    },
    divider: {
        marginTop: 8,
        marginBottom: 8,
        color: '#dddee6',
        '&:last-child': {
            marginTop: 0,
            display: 'none',
            marginBottom: 0
        },
    }
})

function Group(props) {
    const {group, classes, openGroup, isFavourite, markStarredGroup, unmarkStarredGroup, withKeysNavigation} = props
    const {title, collectionUid, groupUid} = group
    const keysNavigationProps = !withKeysNavigation ? {} : {
        suggestCase: JSON.stringify({
            group: {
                title,
                collectionUid,
                groupUid
            },
            suggestType: "group"
        }),
        suggest: "suggest-find"
    }
    return (
        <>
        <div data-intrcm-id="Group_qw6vcf8b" className={classes.container} >
            <img alt="group" className={classes.pic} src={group.backgroundUrl} />
            <div data-intrcm-id="Group_661zua8f" className={classes.texts}>
                <div data-intrcm-id="Group_m8q6gcj4" onClick={(e) => openGroup(e, group)} className={classes.title}>{group.title || ''}</div>
                <div data-intrcm-id="Group_ajudtt06" className={classes.description} >{group.description || ''}</div>
            </div>
            <div data-intrcm-id="Group_dc8kvxzw" className={classes.iconWrap}>
                {isFavourite ? 
                    <Star onClick={() => unmarkStarredGroup(collectionUid)} className={classes.icon}/> :
                    <StarBorder onClick={() => markStarredGroup(collectionUid)} className={classes.icon}/>}
            </div>
            <div data-intrcm-id="Group_ms2a9119" {...keysNavigationProps} className={classes.suggest} />
        </div>
        <Divider className={classes.divider} />
        </>
    )
}

export default compose(
    withStyles(styles, {withTheme: true})
)(Group)

