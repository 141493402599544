import React, { useEffect, useState, useRef } from 'react'
import {withStyles} from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { prevStory, prevStoryScene, getStoryControlPrevAction, STORY_CONTROL_TYPES } from '@tuqqi/common'
import ArrowLeftIcon from '../../../components/Common/Icons/StoriesIcons/ArrowLeftIcon'

const styles = (theme) => ({
})

const defaultCurrentScene = {id: -1}

const StoryPrev = (props) => {
    const {classes, storiesNavigation, dispatch, wrapClass} = props
    const storiesRef = useRef([])
    const currentStoryIdRef = useRef('')
    const {currentScene, currentStoryId, stories} = storiesNavigation
    const [actionType, setActionType] = useState(STORY_CONTROL_TYPES.PREV_HIDE)
    const {id: sceneId}  = currentScene ?? defaultCurrentScene
    
    useEffect(() => {
        storiesRef.current = stories
    }, [stories])

    useEffect(() => {
        currentStoryIdRef.current = currentStoryId
    }, [currentStoryId])

    useEffect(() => {
        const actionType = getStoryControlPrevAction({
            sceneId, 
            currentStoryId: currentStoryIdRef.current,
            stories: storiesRef.current
        })
        setActionType(actionType)
    }, [sceneId])

    const onClick = () => {
        switch (actionType) {
            case STORY_CONTROL_TYPES.PREV_SCENE:
                return dispatch(prevStoryScene())
            case STORY_CONTROL_TYPES.PREV_STORY:
                return dispatch(prevStory())
            default:
                return
        }
    }
    if(actionType === STORY_CONTROL_TYPES.PREV_HIDE) return null

    return <div data-intrcm-id="StoryPrev_z2vlzf14" onClick={onClick} className={wrapClass}>
            <ArrowLeftIcon/>
        </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(StoryPrev)