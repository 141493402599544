let confetti = document.getElementById('kanban_green_lane_confetti');
let confettiCtx = confetti?.getContext('2d');
let container, confettiElements = [], clickPosition;

const rand = (min, max) => Math.random() * (max - min) + min;

const OFFSET = 56
// params to play with
const confettiParams = {
    // number of confetti per "explosion"
    number: 60,
    // min and max size for each rectangle
    size: { x: [3, 12], y: [6, 10.8] },
    // power of explosion
    initSpeed: 30,
    // defines how fast particles go down after blast-off
    gravity: 0.7,
    // how wide is explosion
    drag: 0.2,
    // how slow particles are falling
    terminalVelocity: 12,
    // how fast particles are rotating around themselves
    flipSpeed: 0.017,
};
const colors = [
    { front : '#3B870A', back: '#235106' },
    { front : '#B96300', back: '#6f3b00' },
    { front : '#E23D34', back: '#88251f' },
    { front : '#CD3168', back: '#7b1d3e' },
    { front : '#664E8B', back: '#3d2f53' },
    { front : '#394F78', back: '#222f48' },
    { front : '#008A8A', back: '#005353' },
];

function Conf() {
    this.randomModifier = rand(-1, 1);
    this.colorPair = colors[Math.floor(rand(0, colors.length))];
    this.dimensions = {
        x: rand(confettiParams.size.x[0], confettiParams.size.x[1]),
        y: rand(confettiParams.size.y[0], confettiParams.size.y[1]),
    };
    this.position = {
        x: clickPosition[0],
        y: clickPosition[1]
    };
    this.rotation = rand(0, 2 * Math.PI);
    this.scale = { x: 1, y: 1 };
    this.velocity = {
        x: rand(-confettiParams.initSpeed, confettiParams.initSpeed) * 0.4,
        y: rand(-confettiParams.initSpeed, confettiParams.initSpeed)
    };
    this.flipSpeed = rand(0.2, 1.5) * confettiParams.flipSpeed;

    if (this.position.y <= container.h) {
        this.velocity.y = -Math.abs(this.velocity.y);
    }

    this.terminalVelocity = rand(1, 1.5) * confettiParams.terminalVelocity;

    this.update = function () {
        this.velocity.x *= 0.98;
        this.position.x += this.velocity.x;

        this.velocity.y += (this.randomModifier * confettiParams.drag);
        this.velocity.y += confettiParams.gravity;
        this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
        this.position.y += this.velocity.y;

        this.scale.y = Math.cos((this.position.y + this.randomModifier) * this.flipSpeed);
        this.color = this.scale.y > 0 ? this.colorPair.front : this.colorPair.back;
    }
}

async function updateConfetti () {
    if(!confettiCtx) return
    confettiCtx.clearRect(0, 0, container.w, container.h);
    const asyncRunner1 = (c) => {
        c.update();
        confettiCtx.translate(c.position.x, c.position.y);
        confettiCtx.rotate(c.rotation);
        const width = (c.dimensions.x * c.scale.x);
        const height = (c.dimensions.y * c.scale.y);
        confettiCtx.fillStyle = c.color;
        confettiCtx.fillRect(-0.5 * width, -0.5 * height, width, height);
        confettiCtx.setTransform(1, 0, 0, 1, 0, 0)
    }
    const asyncRunner2 = (c, idx) => {
        if (c.position.y > container.h ||
            c.position.x < -0.5 * container.x ||
            c.position.x > 1.5 * container.x) {
            confettiElements.splice(idx, 1)
        }
    }
    const promises1 = confettiElements.map(async (c) => asyncRunner1(c))
    await Promise.all(promises1)
    const promises2 = confettiElements.map(async (c, ind) => asyncRunner2(c, ind))
    await Promise.all(promises2)

    window.requestAnimationFrame(updateConfetti);
}

function setupCanvas() {
    container = {
        w: confetti.clientWidth,
        h: confetti.clientHeight
    };
    confetti.width = container.w;
    confetti.height = container.h;
}

export async function addConfetti(e) {
    if(!e) return
    confetti = document.getElementById('kanban_green_lane_confetti');
    if(!confetti) return
    confettiCtx = confetti.getContext('2d');
    confetti.style.display = 'block'
    setupCanvas();
    updateConfetti();

    const canvasBox = confetti.getBoundingClientRect();
    const elementBox = e.getBoundingClientRect();
    
    const pushCof = () => { confettiElements.push(new Conf())}
    clickPosition = [
        elementBox.left - canvasBox.left,
        elementBox.top - canvasBox.top + OFFSET
    ];

    const promises = new Array(confettiParams.number)
        .fill(0)
        .map(async () => pushCof())

    setTimeout(() => {
        hideConfetti()
    }, 900)
    await Promise.all(promises)
}

function hideConfetti() {
    if(confetti) {
        confetti.style.display = 'none'
        confetti = undefined
    }
    window.cancelAnimationFrame(updateConfetti)
    container = undefined
    clickPosition = []
    confettiElements = [];
    confettiCtx = undefined
}