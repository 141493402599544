import { sortItemsByDate } from "./helpers";
import i18n from '../../i18n';
import { isValid, isWithinInterval } from "date-fns";
import { getRangeForDueDateUid } from "../../hooks/useSearchItems/useSearchACQueryLocal";

const getDueDateOrders = () => {
    return ['Past Due', 'Today', 'Tomorrow', 'This week', 'This month', 'This year', 'Future due dates', 'Not Set']
}

const getSectionTitleDueDate = (title, t) => {
    switch (title) {

        case 'Past Due':
            return t("Past Due")
        case 'Today':
            return t("Today")
        case 'Tomorrow':
            return t("Tomorrow")
        case 'Later This month':
            return t("This month")
        case 'Later This week':
            return t("This week")
        case 'Later This year':
            return t("This year")
        case 'Not Set':
            return t("Not set")
        case 'Future due dates':
            return t("Far Future")

        default:
            return title
    }
}

export const invalidISOStringDates = ['0001-01-01T00:00:00Z', '0001-01-01T00:00:00.000Z', '0001-01-01T01:00:00Z']
export const getDateCorrect = (date) => {
    if (!date || invalidISOStringDates.includes(date)) return ''
    const d = new Date(date)
    if (!isValid(d)) return ''
    return d.toISOString()
}

export const infiniteStartRange = 'Fri, 31 Dec 1999 22:00:00 GMT'
export const infiniteEndRange = 'Thu, 30 Dec 9999 22:00:00 GMT'

export const dateZeroTime = (date1) => {
    const date = new Date(date1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)

    return date
}
export const date2359Time = (date1) => {
    const date = new Date(date1)
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(0)

    return date
}


const usersToDocs = (users) => users
    .map(u => {
        const date = new Date()
        const bd = new Date(u.birthday)
        bd.setFullYear(date.getFullYear())

        return {
            isUser: true,
            points: u.points,
            docId: u.auth0_id,
            title: `${u.firstname} ${u.lastname}`,
            groupUid: null,
            dueDateUTC: bd.toISOString(),
            birthday: u.birthday,
            tag: u.tag,
            profileImageUrl: u.profileImageUrl
        }
    })
    .sort((a, b) => b.points - a.points)
// .slice(0, 10)

const filterUsersBD = (users) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0);
    const year = date.getFullYear()

    const next = new Date(date)

    next.setMonth(date.getMonth() + 1);

    const prevTime = date.getTime()
    const nextTime = next.getTime()

    return users.filter((u) => {
        if (!u.birthday) return false
        const d = new Date(u.birthday)
        if (d.toString() === 'Invalid Date') return false
        d.setFullYear(year)

        const time = d.getTime()
        return time >= prevTime && time <= nextTime
    })
}

export const getUsersForAgenda = (users = []) => {
    const filteredUsers = filterUsersBD(users)
    const usersAsDoc = usersToDocs(filteredUsers)
    return usersAsDoc
}

const getUsersForSection = (uid, usersAsDoc, usedPeople) => {
    if (uid === 'Not Set') return []
    return usersAsDoc.filter(item => {
        if (usedPeople.includes(item.docId)) return false
        const newVal = getDateCorrect(item?.dueDateUTC ?? '')
        const range = getRangeForDueDateUid(uid)
        if (!range.length) return false
        try {
            if (new Date(range[1]).getTime() <= new Date(range[0]).getTime()) return false
            const inInterval = isWithinInterval(new Date(newVal), { start: range[0], end: range[1] })

            if (inInterval) {
                usedPeople.push(item.docId)
                return true
            }
        } catch (error) {
            return false
        }
        return false
    })
}

export const buildAgendaTasks = (results = [], usersAsDoc = [], tt) => {
    const usedPeople = []
    const t = tt ?? i18n.getFixedT(i18n.language, 'actionCenter')

    const sortedSections = results.sort((a, b) => getDueDateOrders().indexOf(a.uid) - getDueDateOrders().indexOf(b.uid))
    const res = sortedSections.map(x => {
        const newTitle = getSectionTitleDueDate(x.sectionTitle, t)
        const data = [...x.items, ...getUsersForSection(x.uid, usersAsDoc, usedPeople)].sort(sortItemsByDate)
        return {
            title: newTitle,
            collectionUid: x.uid,
            data,
            sectionTitle:x.sectionTitle
        }
    })

    return res
}