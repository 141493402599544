


import React, { PureComponent } from 'react';

export default class DrawerEditTagIcon extends PureComponent {

    render() {
        return (  
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2774 2.72266C14.0472 1.49251 12.0528 1.49251 10.8226 2.72266L3.30229 10.243C3.21284 10.3324 3.15375 10.4407 3.125 10.5551L1.82356 15.3482C1.76016 15.5817 1.82659 15.8313 1.99768 16.0024C2.16877 16.1735 2.41834 16.2399 2.65185 16.1765L7.45664 14.8719C7.57363 14.8401 7.67764 14.7782 7.75999 14.6948L15.2774 7.17744C16.5075 5.94728 16.5075 3.95281 15.2774 2.72266ZM5.97893 13.8742L4.12582 12.0211L3.43511 14.565L5.97893 13.8742ZM7.27976 13.2659L14.3228 6.22284C15.0257 5.5199 15.0257 4.3802 14.3228 3.67726C13.6198 2.97431 12.4801 2.97431 11.7772 3.67726L4.73418 10.7203L7.27976 13.2659Z" fill="#6B6F7A"/>
		</svg>
        )
    }
}