import { Divider, withStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clearDrawerNavigation, selectDrawerNavigationOption, setDrawerNavigationMode } from '../../../../actions/drawerNavigationActions';
import { getGroupDrawerFromPath } from '../../../../utils/drawerNavigation/groupDrawerNavigationHelpers';
import { getGroupCategoryDrawerFromPath } from '../../../../utils/drawerNavigation/groupCategoryDrawerNavigationHelpers';
// import { getGanttDrawerFromPath } from '../../../../utils/drawerNavigation/groupGanttDrawerNavigationHelpers';
import DrawerContainer from '../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer';
import { useDrawerNavigation } from '../../../components/Common/Hooks/useDrawerNavigation/useDrawerNavigation';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import { GroupInfoDrawer } from '../../../components/GroupInfo/GroupInfoDrawer';
import {DrawerSwitcherNew} from '../../search/Components/DrawerSwitcher';
import {themeConstants} from '../../../../theme';
import { useGetGroupAdditionalOptions } from '../../../components/Common/Hooks/useDrawerNavigation/useGetGroupAdditionalOptions'
// import { getSchedulerDrawerFromPath } from '../../../../utils/drawerNavigation/groupSchedulerDrawerNavigationHelpers';
import { extractDisplayedViews, NewUiWrapper, useShouldUseScheduler } from '@tuqqi/web';
import { store } from '../../../..';
// import { Edit } from '@material-ui/icons';
// import LeaveGroup from './LeaveGroup';
// import EditCollection from '../../../components/Widgets/RightWidgets/Search/editCollection';

const styles = theme => ({
    container: {
        paddingTop: 12,
    },
    dividerTop: {
        margin: 12,
        marginRight: 0,
        marginLeft: 0,
        backgroundColor: '#daddee',
    },
    empty: {
        height: 8
    },
    divider: {
        margin: 12,
        marginRight: 0,
        marginLeft: 0,
        backgroundColor: '#daddee',
    },
    dividerPX: {
        width: '150%',
        marginLeft: '-12px!important'
    },
    dividerSmall: {
        marginRight: 0,
        marginLeft: 0,
        marginTop: 12,
    },
    dividerSmallTop: {
        margin: 8,
        marginTop: 24,
        marginRight: 0,
        marginLeft: 0,
    },
    navigation: {
        marginBottom: 12,
        marginLeft: 12,
        marginRight: 12,
    },
    paper: {
        height: 'inherit!important',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      footer: {
        position: "absolute",
        width: "100%",
        bottom: 24,
      },
      collapseText: {
        cursor: 'pointer',
        marginLeft: 24,
        color:  "#6B6F7A",
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
      
})

const emptyObj = {}

const __GroupLeftDrawerNew = (props) => {
    const {
        classes, 
        dispatch, 
        profileData, 
        drawerNavigation, 
        routing, 
        groupInfo, 
        isDrawerOpen, 
        handleSwitchDrawerStatus,
        starredGroups,
        groupService,
        userSettingsService,
        pathname,
        queryService,
        orgUid,
        searchQuery,
        currentGroup,
    } = props
    const [t] = useTranslation('groupDrawerTranslate')
    const [isAdmin, setIsAdmin] = useState(false)
    const [isCanLeave, setIsCanLeave] = useState(false)
    const [isCanJoin, setIsCanJoin] = useState(false)
    const path = routing.locationBeforeTransitions.pathname;

    const { collectionGroup, defaultView, availableViews } = groupInfo;
    const isDefaultGroup = path !== "/group/knowledge" && !path.includes("category") && 
        !path.includes('gantt') && defaultView !== 'gantt' &&
        !path.includes('scheduler') && defaultView !== 'scheduler'

    // const isGantt = defaultView === 'gantt';
    const {value: useScheduler} = useShouldUseScheduler('group-left-drawer-sch', true)
    // const isScheduler = !useScheduler ? false : defaultView === 'scheduler';
    const isKnowledge = defaultView === 'knowledge';
    
    const isConnectors = path.includes('connectors')
    const isSettings = path.includes('settings')
    const paperStyle = isDrawerOpen ? { width: themeConstants.navigationDrawerBigPXWidth, } : { width: themeConstants.navigationDrawerSmallPXWidth, overflowX: 'hidden' }

    const visibleOpts = useMemo(() => {
        return extractDisplayedViews(availableViews, defaultView)
    }, [availableViews, defaultView])

    const handleCollapse = () => {
        handleSwitchDrawerStatus(false);
    }

    useEffect(() => {
        const {pathname} = window.location
 
        let [mode, id] = getGroupDrawerFromPath(pathname)

        // if(isGantt) {
        //     [mode, id] = getGanttDrawerFromPath(pathname)
        // }
        // if(isScheduler) {
        //     [mode, id] = getSchedulerDrawerFromPath(pathname)
        // }

        if(isKnowledge) {
            [mode, id] = getGroupCategoryDrawerFromPath(pathname)
        }
        
        if(isConnectors) {
            id = 8
        }
        if(isSettings) {
            id = 7
        }
           
        dispatch(setDrawerNavigationMode(mode))
        dispatch(selectDrawerNavigationOption(id))

        return () => {
            dispatch(clearDrawerNavigation())
        }
    }, [isDefaultGroup, isConnectors, isSettings, isKnowledge])

    useEffect(() => {
        const adminIds = collectionGroup?.adminIds || []
        const memberId = profileData.id

        if(adminIds.includes(memberId)) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }

    },[profileData?.id, collectionGroup?.adminIds ])

    const [additionalOptions, joinGroup] = useGetGroupAdditionalOptions({
        t,
        groupInfo,
        setIsCanLeave,
        setIsCanJoin,
        starredGroups,
        pathname,
        dispatch,
        teams: props.teams,
        groupService,
        userSettingsService, 
        profileData,
        searchQuery,
        queryService,
    })

    const Navigations = useDrawerNavigation({pxSwitcher: true, useScheduler, drawerNavigation, isDrawerOpen, t, additionalOptions: additionalOptions.filter(el => el.title !== "Join group"),profileData, settings: {useScheduler, visibleOpts}})
    const NavigationSection = (
        <div data-intrcm-id="GroupLeftDrawerNew_iik3jbi8" className={isDrawerOpen ? classes.navigation : classes.navigationSmall}>
            {Navigations.map((Navigation, i) => 
                <>
                {0 !== i && !isDrawerOpen && <div className={classes.empty} />}
                {Navigation}
                {Navigations.length -1 !== i && <Divider className={ `${classes.dividerPX} ${isDrawerOpen ? classes.divider : classes.dividerSmall}` } />}
                </>
            )}
        </div>
    )

    const ignoreMenuKeys = useMemo(() => {
        const keys = []
        if(!isAdmin) keys.push("inviteMembers")
        if(!isCanLeave) keys.push("leaveGroup")
        if(pathname.includes('group/settings')) keys.push("inviteMembers")
        return keys
    }, [isAdmin, isCanLeave, pathname])

    if (!collectionGroup) return null
    
    return (
        <NewUiWrapper store={store} callbacks={emptyObj}>
        <DrawerContainer PaperProps={{style: paperStyle}} containerStyle={`${classes.container} ${classes.paper}`} open={true}>
            <DrawerSwitcherNew pxSwitcher isDrawerOpen={isDrawerOpen} onClick={handleSwitchDrawerStatus}/>
            <GroupInfoDrawer joinGroup={joinGroup} isCanJoin={isCanJoin} ignoreMenuKeys={ignoreMenuKeys} additionalOptions={additionalOptions} isAdmin={isAdmin} groupInfo={groupInfo} isDrawerOpen={isDrawerOpen} />
            <Divider className={ isDrawerOpen ? classes.dividerTop : classes.dividerSmallTop } />
            {NavigationSection}
            {isDrawerOpen && <div className={classes.footer}>
                <Divider className={ `${classes.dividerPX} ${isDrawerOpen ? classes.divider : classes.dividerSmall}` } />
                <div className={classes.empty} />
                <div onClick={handleCollapse} className={classes.collapseText}>{t("Collapse Menu")}</div>
            </div>}
        </DrawerContainer>
        </NewUiWrapper>
    )
}

const mapStateToProps = (state) => ({
    teams: state.data.globalData.teams?.teams ?? [],
    currentGroup:  state.group,
    groupInfo: state.results.channel,
    profileData: state.userData.profileData,
    pathname: state.wrapper.pathname,
    drawerNavigation: state.drawerNavigation,
    routing: state.routing,
    groupService: state.services.groupService,
    starredGroups: state.searchNavigation?.starredGroups ?? [],
    userSettingsService: state.services.userSettingsService,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    queryService: state.services.queryService,
    searchQuery: state.search,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const GroupLeftDrawerNew = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading Group Navigation, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__GroupLeftDrawerNew);