import { Typography, withStyles } from '@material-ui/core';
import React from 'react'
import { compose } from 'recompose';
import withErrorCatcher from '../Common/withErrorCatcher';
import { connect } from 'react-redux';
import HashHeaderIcon from '../Common/Icons/ChannelIcons/hashHeaderIcon';
import TagChannelIcon from '../Common/Icons/SearchIcons/TagChannelIcon';
import DrawerEditTagIcon from '../Common/Icons/NewDesign/DrawerEditTagIcon';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subtitle: {
        marginTop: 8,
        display: 'block',
        fontFamily: 'Rubik',
        fontSize: 11,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.4px',
        color: theme.newUI.color.gray,
    },
    iconHolder: {
        // margin: '0 auto',
    },
    iconHolderSmall: {
        marginTop: 12,
        textAlign: 'center',
    },
    icon: {
        width: 88, 
        height: 88,
        backgroundColor: '#eeeef3',
        borderRadius: '100%'
    },
    iconSmall: {
        width: 40,
        height: 40,
        backgroundColor: '#eeeef3',
        borderRadius: '100%'
    },
	texts: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		marginLeft: 8,
	},
	title: {
		fontFamily: "Rubik",
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "24px",
		zIndex: 2,
		color: '#18181B',
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: 'nowrap',
		width: 160,
	},
	description: {
		fontFamily: "Rubik",
		fontSize: 12,
		fontWeight: 400,
		zIndex: 2,
		color: '#6B6F7A',
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: 'nowrap',
		width: 160,
	},
	channelIconContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: 36,
		height: 36,
		backgroundColor: "#818CF8",
		borderRadius: 10,
	 },
	 row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: 224,
		maxWidth:  224,
	 },
	 blockTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: 32,
		boxSizing: 'border-box',
		justifyContent: 'space-between',
		width: 224,
		margin: "8px 8px 0 8px",
		padding: "0 7px 0 8px",
		fontFamily: "Rubik",
		fontSize: 14,
		fontWeight: 500,
		lineHeight: "20px",
		marginTop: 24,
	 },
	  aboutBlock: {
		alignSelf: "flex-start",
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: "rgba(129, 140, 248, 0.1)",
		color: "#3F4046",
		fontSize: 13,
		fontWeight: 400,
		lineHeight: "16px",
		margin: "0 16px 8px 16px",
		padding: 8,
		borderRadius: 8,
	  },
	  editIcon: {
		cursor: "pointer"
	  }
})
const __TagsInfoDrawer = (props) => {
    const {classes, query, openEditModal,isDrawerOpen, currentTagInfo,descriptionText, setDescriptionText,} = props
    const {tagType, description, previewDescription} = currentTagInfo

    return (
        <div data-intrcm-id="TagsInfoDrawer_006zcd0j" className={isDrawerOpen ? classes.container : classes.containerMinimize}>
			<div className={classes.row}>
            <div data-intrcm-id="TagsInfoDrawer_3gqn577n" className={isDrawerOpen ? classes.iconHolder : classes.iconHolderSmall}>
				{/* <HashHeaderIcon className={isDrawerOpen ? classes.icon : classes.iconSmall}/> */}
				<div className={classes.channelIconContainer}>
					<TagChannelIcon color="#FFFFFF" width={20} height={20} />
				</div>
			</div>
            {isDrawerOpen && 
		    <div data-intrcm-id="Item_o9jntzpv" className={classes.texts}>
                    <div data-intrcm-id="Item_ikl39uvp" onClick={(e) => {}} className={classes.title}>
						{query}
					</div>
                    <div data-intrcm-id="Item_jaq3fb9b" className={classes.description}>
						{description}
					</div>
        	</div> 
			}
			</div>
			<div className={classes.blockTitle}>
				 <div>About</div>
				 <div className={classes.editIcon} onClick={openEditModal}>
				 	<DrawerEditTagIcon />
				 </div>
			</div>
			{ description && <div className={classes.aboutBlock}>
				{description}
			</div> }
		</div>
    )
}

const mapStateToProps = (state) => ({
    myAuth0_id: state.userData.profileData.auth0_id,
})

export const TagsInfoDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading User Info, Please Contact Tuqqi Support"),
    connect(mapStateToProps, null)
)(__TagsInfoDrawer);