import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer.js';
import withPusher from '../../../components/Common/Pusher/withPusher.js';
import { NewUiWrapper, TableGroup, managerSchResource, managerGroupScheduler, SchedulerGroup, managerTableKeepGlobalData } from '@tuqqi/web';
import {store} from '../../../../index'
import { themeConstants } from '../../../../theme.js';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import { MockForSetTableGlobalDataSch } from './MockForSetTableGlobalDataSch.js';
import GroupTableView from '../groupTableView.js';
import { setShowOldTable, useNewTable } from '../../../../utils/useNewTable.js';
import { FULLVIEW_POST, INPUT_OPEN } from '../../../../actionTypes/actionTypes.js';
const styles = theme => ({
    table: {
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
    table1: {
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: '#EDEDF0',
    },
})

export class GroupSchedulerNewView extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {
        if(!useNewTable(this.props.orgUid)) return
        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        // managerSchResource.putFromFetch(this.props.groupUid)
        managerGroupScheduler.preFetch(this.props.groupUid, this.props.collectionUid)
    }

    componentDidUpdate(prevProps) {
        if(!useNewTable(this.props.orgUid)) return
        if(this.props.collectionUid === prevProps.collectionUid && this.props.groupUid === prevProps.groupUid) return

        if(!this.props.collectionUid || !this.props.groupUid) return

        this.props.dispatch({ type: 'SEARCH_QUERY_UPDATE', query: {query: '', originText: ''} })
        // managerSchResource.putFromFetch(this.props.groupUid)
        managerGroupScheduler.preFetch(this.props.groupUid, this.props.collectionUid)
    }

    showOldTable = () => {
        setShowOldTable(true, this.props.dispatch)
    }

    openOldInput = (memberUid, dataType, text, startDate, endDate) => {
        const { dispatch, groupUid } = this.props
        const payload = { 
            postText: '', 
            dataType, 
            permissions: [groupUid], 
            dateTimeFrom: new Date(startDate), 
            dateTimeTo: new Date(endDate), 
        }

        if(!!memberUid) {
            payload.members = [{userUUID: memberUid}]
        }

        dispatch({
            type: FULLVIEW_POST,
            openPost: true,
            item: payload
        })
        dispatch({ type: INPUT_OPEN, model: payload });
    }

    render() {
        const { classes, isDrawerOpen,groupUid, collectionUid } = this.props;

        const callbacks = {
            onOpenOldDataType: this.openOldInput
        }

        const show = groupUid && collectionUid

        if(!useNewTable(this.props.orgUid)) return null
     
        return <CategoryViewOnlyLeftDrawer >
                <div className={isDrawerOpen ? classes.table : classes.table1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            <MockForSetTableGlobalDataSch />
                            {show && <SchedulerGroup drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth : themeConstants.navigationDrawerSmallPXWidth} groupUid={groupUid} />}
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    search: state.search,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Table, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupSchedulerNewView);