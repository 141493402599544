import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from "react";
import {getDateStyle, dateValidationHelper, DateIconForCell, AddRoundedIcon} from '@tuqqi/web'
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    more: {
        color: '#475467',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: 0.4,
    },
    item: {
        height: 28,
        padding: '2px 8px 2px 8px',
        borderRadius: 4,
        border: '1px solid #D7D7DB',
        background: '#F9F9FA',
        display: 'flex',
        alignItems: 'center',
        'mix-blend-mode': 'multiply',
    },
    noVal: {
        display: 'flex',
        whiteSpace: 'pre',
        overflow: 'hidden',
        height: 28,
        border: '1px solid transparent',
        borderRadius: 4,
        'mix-blend-mode': 'multiply',
        padding: '2px 8px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F9F9FA',
    },
    inputWrapPointer: {
        cursor: 'pointer'
    },
    text: {
        whiteSpace: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#344054',
        lineHeight: '18px',
        fontWeight: 400,
        fontSize: 12,
    },
    valueWrap: {
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    valueWrapOuter: {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    icon: {
        marginRight: 2,
        display: 'flex',
        minWidth: 16,
        padding: 0,
        margin: 0
    },
    wrap: {
        width: 'inherit',
        height: 'inherit',
        marginTop: 'auto',
        marginBottom: 'auto',
        overflow: 'hidden',
    }
}))

const placeholder = 'dd.mm.yyyy'
const placeholderTime = 'dd.mm.yyyy --:--'


export const DateView = (props) => {
    const classes = useStyles()

    const isDueDate = false
    const value = props.value
    const [t] = useTranslation('table')

    const disabled = props.disabled
    const withTime = true

    const allowHover = !disabled
    const taskDueDateDone = false

    const [valid] = useMemo(() => {
        if(!value) return [false]
        if(!dateValidationHelper(value)) return [false]

        return [true]
    }, [value])
    const {displayValue} = getDateStyle(value, isDueDate, taskDueDateDone, valid, withTime, t)


    const renderPlus = useCallback(() => {
        return <div data-intrcm-id="CellRenderDateText_x1regff0" className={`${classes.noVal}`} >
            <AddRoundedIcon className={classes.more} />
        </div>
    }, [])

    const renderSimpleDate = () => {
        return <div data-intrcm-id="CellRenderDateText_fvakl3zg" className={classes.item}>
            <div data-intrcm-id="CellRenderDateText_illxt83q" className={classes.icon}>
                <DateIconForCell color="black" />
            </div>
            <Typography dir="auto" className={`${classes.text}`} component='span' variant='body2' >{valid ? displayValue : (withTime ? placeholderTime : placeholder)}</Typography>
        </div>
    }

    const NoValue = <div data-intrcm-id="CellRenderDateText_1j7uuhvv" className={`${classes.wrap} ${allowHover ? classes.inputWrapPointer : ''}`} >
        <div data-intrcm-id="CellRenderDateText_8rwmrax5" className={`${classes.valueWrapOuter}`}>
            <div data-intrcm-id="CellRenderDateText_kmyyjv8l" className={`${classes.valueWrap}`} >
                    {renderPlus()}
            </div>
        </div>
    </div>

    const Value = <div data-intrcm-id="CellRenderDateText_ef1ugx0z" className={`${classes.wrap} ${allowHover ? classes.inputWrapPointer : ''}`}>
        <div data-intrcm-id="CellRenderDateText_uk2m1j29" className={`${classes.valueWrapOuter}`}>
            {renderSimpleDate()}
        </div>
    </div>
  
    const X = !!value ? Value : NoValue
    return X
}