import { Grid, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showSnackbar } from '../../../actions/appActions';
import ListHeader from "./ListHeader/ListHeader";
import ListRuleModal from "./ListRules/ListRuleModal";
import CardAdder from "./cardAdder";
import Cards from "./cards";
import withErrorCatcher from '../Common/withErrorCatcher';
import ListColorModal from './ListColorModal/ListColorModal';
import { withTranslation } from 'react-i18next';
import { withMixpanel } from '../Common/withMixpanel';

const styles = theme => ({
	listWrapper: {
		height: '100%'
	},
	listContainer: {
		width: 286,
		height: '100%',
		marginLeft: 0
	},
	listHeaderContainer: {
		flex: '0 0 auto'
	},
});

const distantFuture = new Date(8640000000000000)

class List extends Component {
	state = {
		listColorModalOpen: false,
		sortBy: '',
		sortOrder: 'desc'
	}

	sortBy = (sortBy, sortOrder) => {

		this.setState({ sortBy: sortBy, sortOrder: sortOrder })
		this.props.mixpanelTrack("Lane - Sort by", { field: sortBy })

	}

	sortCards = (a, b) => {

		const { sortBy, sortOrder } = this.state

		if (sortOrder === 'desc') {
			if(sortBy === 'dueDateUTC') {
				let dateA = a.doc[sortBy] ? new Date(a.doc[sortBy]) : distantFuture
  				let dateB = b.doc[sortBy] ? new Date(b.doc[sortBy]) : distantFuture
 				return dateA.getTime() - dateB.getTime()
			}
			if (a.doc[sortBy] > b.doc[sortBy]) {
				return -1
			} else if (a.doc[sortBy] < b.doc[sortBy]) {
				return 1
			}
			return 0
		} else {
			if(sortBy === 'dueDateUTC') {
				let dateA = a.doc[sortBy] ? new Date(a.doc[sortBy]) : distantFuture
  				let dateB = b.doc[sortBy] ? new Date(b.doc[sortBy]) : distantFuture
 				return dateB.getTime() - dateA.getTime()
			}
			if (a.doc[sortBy] < b.doc[sortBy]) {
				return -1
			} else if (a.doc[sortBy] > b.doc[sortBy]) {
				return 1
			}
			return 0
		}
	}

	openListRuleModal = (triggerType, rule) => () => {
		this.setState({ triggerType, listRuleOpen: true, rule })
	}

	openMenu = (event) => {
		this.setState({ openMenu: true, anchorEl: event.currentTarget })
	}

	closeMenu = () => {
		this.setState({ openMenu: false, anchorEl: null })
	}

	handleCloseRuleModal = (snackbarText) => {
		if (snackbarText && typeof snackbarText === "string") {
			this.props.dispatch(showSnackbar(snackbarText))
		}

		this.props.reloadDataWithoutLoader()
		this.closeMenu();
		this.setState({ listRuleOpen: false });
	}

	render = () => {
		const { list, index, editTitleList, editListColor, deleteList, classes, mixpanelTrack } = this.props;
		const { triggerType, listRuleOpen, rule } = this.state;

		return (
			<Fragment>
				<Draggable
					draggableId={list.id + ''}
					index={index}
					disableInteractiveElementBlocking
				>
					{(provided, snapshot) => (
						<Fragment>
							<div data-intrcm-id="list_euebpbis" ref={provided.innerRef}
								{...provided.draggableProps}
								className={classnames("list-wrapper", classes.listWrapper)}>
								<Grid wrap='nowrap' container direction='column' className={classes.listContainer}>
									<ListHeader
										dragHandleProps={provided.dragHandleProps}
										list={list}
										editTitleList={editTitleList}
										deleteList={deleteList}
										openListRuleModal={this.openListRuleModal}
										closeMenu={this.closeMenu}
										anchorEl={this.state.anchorEl}
										isOpenMenu={this.state.openMenu}
										openMenu={this.openMenu}
										sortBy={this.sortBy}
										openListColorModal={() => { this.setState({ listColorModalOpen: true }) }}
									/>
									<Cards list={list} applySort={this.state.sortBy + this.state.sortOrder} sort={this.sortCards} />
									<CardAdder list={list} />
								</Grid>
							</div>
							{provided.placeholder}
						</Fragment>
					)}
				</Draggable>

				<ListRuleModal
					list={list}
					triggerType={triggerType}
					rule={listRuleOpen ? rule : null}
					open={listRuleOpen}
					handleClose={this.handleCloseRuleModal}
				/>
				<ListColorModal
					t={this.props.t}
					listTaskColor={list.listColor}
					choose={(color) => { editListColor(list.id, color); mixpanelTrack("Changed lane color", { color: color }) }}
					open={this.state.listColorModalOpen}
					handleClose={() => { this.setState({ listColorModalOpen: false }) }}
				/>
			</Fragment>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch: dispatch,
	}
}

export default compose(withStyles(styles), withTranslation('listRules'),
	withMixpanel, connect(mapDispatchToProps), withErrorCatcher())(List);
