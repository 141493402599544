import { Button, FormControlLabel, List, ListItem, ListItemSecondaryAction, ListItemText, withStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import React, { Component, PureComponent } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollection } from '../../../../actions/collectionActions.js';
import withErrorCatcher from '../withErrorCatcher.js';
import Switch from '@material-ui/core/Switch';
import { updateOrganizationData } from '../../../../utils/loadOrganizationData.js';
import { push } from 'react-router-redux';
import { showConfirm } from '../../../../actions/appActions.js';
import { TOGGLE_COLLECTION_DIALOG } from '../../../../actionTypes/actionTypes.js';
import { NewIconSelector } from '../NewIconSelector.js';
import { ConfirmDialog, NewUiWrapper } from '@tuqqi/web';
import { persistor, store } from '../../../../index.js';

registerPlugin(FilePondPluginImagePreview)

const styles = theme => ({
    textField: {
        marginRight: theme.spacing.unit,
    },
    menu: {
        width: 200,
    },
    basicWarning: {
        paddingLeft: 20,
        paddingTop: 30,
        fontSize: 14
    },
    imageLabel: {
        "display": "block",
        "color": "rgba(0, 0, 0, 0.54)",
        "padding": "0",
        "fontSize": "1rem",
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "lineHeight": "1",
        "transform": "translate(0, 1.5px) scale(0.75)",
        "transformOrigin": "top left",
        "margin": "6px 0 3px"
    }
})

const GroupMailSwitch = (props) => {
    const [t] = useTranslation('newGroupDialog')
    const { updateMailSettings, isChecked} = props;
    return <FormControlLabel
        control={
            <Switch
                checked={isChecked}
                onChange={() => { updateMailSettings(!isChecked) }}
                color="primary"
            />
        }
        label={t("Enable group mailbox")}
    />
}


class CollectionBasicDetailsStep extends PureComponent {


    onChange = name => event => {
        if(name === 'icon') {
            this.props.dispatch(updateCollection({ [name]: event }))
            return    
        }
        this.props.dispatch(updateCollection({ [name]: event.target.value }))
    }

    onLoadImg = (res) => {
        const file = JSON.parse(res);
        this.props.dispatch(updateCollection({ backgroundUrl: file.link }))
    }

    deleteCollection = (uid) => {
        const { groupService, results, dispatch, services, globalData, userData, fullKanbanLength } = this.props;
        // dispatch(showConfirm((`Are you sure you want to delete this group? This action can't be undone. ${fullKanbanLength} items going to be deleted.`), () => {
            groupService.deleteCollection(results.channel.collectionUid).then(() => {
                updateOrganizationData(null, dispatch, services, globalData, userData).then(() => {
                    persistor.persist()
                }).catch(err => {

                })
                dispatch(push('/feed'));
                dispatch({ type: TOGGLE_COLLECTION_DIALOG });


            });
        // }))


    }

    uploadImg = () => {


        return <FilePond
            labelIdle={this.props.t("filesUploader:d&dLabel")}
            acceptedFileTypes={['image/*']}
            allowMultiple='false'
            server={
                {
                    url: process.env.API_SERVER + '/files/s3',
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                    process: {
                        headers: {
                            'Authorization': 'Bearer ' + this.props.userData.idToken,
                        },
                        onload: this.onLoadImg
                    }
                }
            }
        >
        </FilePond>
    }

    icons = ['headset_mic', 'help_outline',
        'group_add', 'perm_phone_msg', 'perm_contact_calendar', 'contact_mail',
        'mail',
        'radio', 'multiline_chart',
        'account_circle', 'card_travel',
        'account_balance', 'gavel',
        'bug_report', 'code', 'devices',
        'important_devices', 'desktop_mac',
        'android', 'phonelink_setup',
        'done', 'description', 'list_alt',
        'face', 'supervisor_account',
        'vpn_key', 'monetization_on',
        'touch_app', 'compare_arrows',
        'format_paint', 'wallpaper', 'color_lens',
        'vpn_lock', 'screen_lock_portrait',
        'public', 'star_border', 'textsms'
    ];

    render() {
        const { classes, collection, results, t, groupService, fullKanbanLength } = this.props;

        let hasGroupId = false;
        let groupId = ''
        if (results && results.channel && results.channel.collectionGroup) {
            if (results.channel.collectionGroup) {
                hasGroupId = true

                groupId = results.channel.collectionGroup.groupUid
            }
        }

        return <div data-intrcm-id="CollectionBasicDetailsStep_fgmlqxv7">
            <TextField className='new-group-title' label={t("Title *")} helperText={t("This will be the title of your group")} id="gr_title" placeholder={t("Enter group title")}
                onChange={this.onChange("title")} value={collection.title} InputLabelProps={{ shrink: true }} margin="normal" fullWidth />

            <TextField className='new-group-description' label={t("Description *")} helperText={t("What is the group's purpose")} id="gr_description" placeholder={t("Enter group description")}
                onChange={this.onChange("description")} value={collection.description} InputLabelProps={{ shrink: true }} margin="normal" fullWidth multiline rows="3" />

            <TextField select label={t("Default View")} fullWidth className={`${classes.textField} default-view-select`} value={collection.defaultView || 'feed'} margin="normal"
                onChange={this.onChange("defaultView")} SelectProps={{ MenuProps: { className: classes.menu } }} helperText={t("Choose default view")} >
                <MenuItem className="default-view-option" value="feed">{t('Feed')}</MenuItem>
                <MenuItem className="default-view-option" value="table">{t('Table')}</MenuItem>
                <MenuItem className="default-view-option" value="kanban">{t('Kanban')}</MenuItem>
                <MenuItem className="default-view-option" value="knowledge">{t('Knowledge Group')}</MenuItem>
                <MenuItem className="default-view-option" value="gantt">{t('Gantt')}</MenuItem>
            </TextField>

            <div data-intrcm-id="CollectionBasicDetailsStep_pqe7hjul" style={{marginTop: 8, marginBottom: 8}}><NewIconSelector description={t("Choose your icon from the list")} label={t("Icon *")} disabled={false} icon={collection.icon ?? 'public'} onChange={this.onChange("icon")} /></div>

            <p className={classes.imageLabel}>{t("Image")}</p>
            {this.uploadImg()}
            {hasGroupId ? <TextField label={t("Group ID")} helperText={t("Inner group Id to be used in integrations")} id="gr_innerid"
                disabled value={groupId} InputLabelProps={{ shrink: true }} margin="normal" fullWidth /> : null}

            {hasGroupId ? <GroupMailSwitch isChecked={!!collection.mailAddressUid} updateMailSettings={(isEnabled) => {
                groupService.groupMailSetting(collection.id, isEnabled).then(({ result }) => {

                    this.props.dispatch(updateCollection({ "mailAddressUid": result }))
                })

            }} /> : null}
            <br />


            {collection.mailAddressUid ? <TextField label={t("Group Mail")} helperText={t("Mailbox - members of the group can insert data to the group via mail")} id="gr_innerid"
                disabled value={collection.mailAddressUid + "@mail.tuqqi.com"} InputLabelProps={{ shrink: true }} margin="normal" fullWidth /> : null}
            {!hasGroupId ?
                null :

                <NewUiWrapper store={store} callbacks={{}}>
                <ConfirmDialog title={<Trans t={t} i18nKey="Delete group & items forever?">
                    {t('Delete group')} & <b>{{ fullKanbanLength }}</b> {t('items forever?')}
                    </Trans>}
                    message={t("You will not be able to undo this action; By clicking delete, you remove all the group details and items from the organization", "You will not be able to undo this action; By clicking delete, you remove all the group details and items from the organization.")}
                    actionButtonText={t("Delete")}
                    isRedConfirmColor
                    onConfirmed={this.deleteCollection}>
                        <Button variant='contained' color="secondary" >{t("Delete group")}</Button>
                </ConfirmDialog>
                </NewUiWrapper>
            }
            <div data-intrcm-id="CollectionBasicDetailsStep_u4m5tnk0" className={classes.basicWarning}>
                {t("* required")}
            </div>
        </div>
    }


}
const mapStateToProps = (state) => ({
    userData: state.userData,
    collection: state.collection,
    results: state.results,
    ...state.services,
    services: state.services,
    globalData: state.globalData,

})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withErrorCatcher(),
    withTranslation('newGroupDialog'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(CollectionBasicDetailsStep);