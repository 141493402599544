import React, { useCallback, useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import {isExistsTuqqiIcon, getDefaultTuqqiIconName} from '@tuqqi/common'
import {allFluencyIconsNames, allFluencyIconsData, getDefaultTuqqiIcon} from '@tuqqi/common'
import SVG from 'react-inlinesvg';
import {DarkTextPosTooltip, GroupTooltipPos, LaunchpadItemMenu, NewUiWrapper, managerTableKeepGlobalData} from '@tuqqi/web'
import { connect } from 'react-redux';
import {store} from '../../../../../index'
import { OrgSchedulerIcon } from '../../../App/Drawers/LeftDrawer/OrgSchedulerIcon';

const styles = (theme) => ({
    container: {
        position: 'relative',
        width: 92,
        maxWidth: 92,
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    containerActive: {
        cursor: 'pointer',
    },
    containerDisabled: {
        filter: 'opacity(0.5)',
    },
    icon: {
        marginBottom: 20,
        zIndex: 2,
    },
    title: {
        position: 'absolute',
        bottom: 0,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 'normal',
        textAlign: 'center',
        letterSpacing: 0.4,
        color: '#1B1E2A',
        maxWidth: 65,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        zIndex: 2,
    },
    titleActive: {
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.customColors.primary.main
        },
    },
    titleHoverText: {
        color: theme.customColors.primary.main
    },
    hover: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'transparent',
        borderRadius: 8,
        width: 88,
        height: 88,
        transform: 'translate(-50%, -50%)',
        top: '55%',
        left: '50%',
        zIndex: 1
    },
    hoverItem: {
        backgroundColor: '#F9F9FA'
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.15,
        borderRadius: 8,
        width: 88,
        height: 88,
        transform: 'translate(-50%, -50%)',
        top: '55%',
        left: '50%',
        zIndex: 1
    },
    menu: {
        position: 'absolute'
    }
})

function isTruncated(el) {
    if (!el) return false
    return el.scrollHeight > el.clientHeight
}

const darkStyleWrap = {width: '100%', display: 'flex', justifyContent: 'center'}
const darkStyle = {width: '100%', position: 'absolute', bottom: 0}
function LaunchpadOption(props) {
    const {classes, option, onClick, editable, adminService, dispatch, disabled, isOrgMenu} = props
    const [anchor, setAnchor] = useState(null)
    const [isHover, setIshover] = useState(false)
    const textRef = useRef(null)
    const [isCut, setIsCut] = useState(isTruncated(textRef.current))
    const [collection, setCollection] = useState(null)

    const keysNavigationProps = !!disabled ? {} : {
        suggestCase: `${!!isOrgMenu}||${option.type === 0 ? option.id : option.referenceUid}||${option.type === 0}`,
        suggest: "suggest-find"
    }

    const handleOpen = useCallback((e) => {
        setAnchor(e.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setAnchor(null)
    }, [])

    const onClickEditItem = useCallback((e) => {
        if(!editable) return
        handleOpen(e)
    }, [handleOpen, editable])

    const onDeleteItem = useCallback((item, onClose) => {
        adminService.deleteLaunchpadMenuItem(item.id).then(res => {
            if(!res) return
            dispatch({
                type: 'DELETE_LAUNCHPAD_OPTION',
                id: item.id
            })
            onClose?.()
        })
    }, [])

    const menuActions = useMemo(() => {
        return {
            onDelete: onDeleteItem
        }
    }, [onDeleteItem])

    const onClickOpt = () => {
        if(!!disabled) return
        onClick?.(option)
    }

    const onMouseEnterOpt = () => {
        if(option?.type === 1 && option?.referenceUid && !collection) {
            const col = managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionGroup.groupUid === option.referenceUid)
            setCollection(col ?? null)
        }

        setIsCut(isTruncated(textRef.current))
        if(!!disabled) return
        setIshover(true)
    }

    const onMouseLeaveOpt = () => {
        setIsCut(isTruncated(textRef.current))
        if(!!disabled) return
        setIshover(false)
    }

    const renderIcon = useCallback((icon) => {
        if(icon === 'orgscheduler') return <OrgSchedulerIcon className={classes.icon} width={32} height={32} />
        let rightIcon = isExistsTuqqiIcon(icon) ? icon : getDefaultTuqqiIconName()
        if(option.type === 2) {
            rightIcon = 'collectibles'
        }
        const ind = allFluencyIconsNames.findIndex(x => x === rightIcon)
        const path = (ind === -1) ? getDefaultTuqqiIcon() : allFluencyIconsData[ind]

        return <SVG onClick={!!editable ? onClickEditItem : onClickOpt} onMouseEnter={onMouseEnterOpt} onMouseLeave={onMouseLeaveOpt} className={classes.icon} width={32} height={32} src={path} />
    }, [classes, option.type, editable, onClick, onClickEditItem])

    const Title = <div data-intrcm-id="LaunchpadOption_ib96ekwc" ref={textRef} onClick={!!editable ? onClickEditItem : onClickOpt} onMouseEnter={onMouseEnterOpt} onMouseLeave={onMouseLeaveOpt} className={`${classes.title} ${disabled ? '' : classes.titleActive} ${!disabled && isHover ? classes.titleHoverText : ''}`}>{option.customTitle}</div>

    const TitleInner = !!editable ?
        Title :
        option?.type === 1 && collection ?
            <GroupTooltipPos style={darkStyle} styleWrap={darkStyleWrap} collection={collection} component={Title} /> :
            !!isCut ?  
                <DarkTextPosTooltip style={darkStyle} styleWrap={darkStyleWrap} component={Title} text={option.customTitle} /> :
                Title

    return (
        <>
        <div data-intrcm-id="LaunchpadOption_9ee2lfak" className={`${classes.container} ${!!disabled ? classes.containerDisabled : classes.containerActive}`} >
            {renderIcon(option.customIcon || 'public')}
            {TitleInner}
            <div data-intrcm-id="LaunchpadOption_fpy7losr" {...keysNavigationProps} className={classes.suggest} />
            <div data-intrcm-id="LaunchpadOption_e8drf4fv" onMouseEnter={onMouseEnterOpt} onMouseLeave={onMouseLeaveOpt} onClick={!!editable ? onClickEditItem : onClickOpt} className={`${classes.hover} ${isHover ? classes.hoverItem : ''}`} />
            {!!editable && <div data-intrcm-id="LaunchpadOption_10xjsmk1" className={classes.menu}>
                <NewUiWrapper callbacks={{}} store={store}>
                    <LaunchpadItemMenu 
                        anchor={anchor}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        actions={menuActions}
                        item={option}
                    />
                </NewUiWrapper>
            </div>}
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, {withTheme: true})
)(LaunchpadOption)

