import React, { PureComponent } from 'react';

export default class ClassifiedPermissionIcon extends PureComponent {

    render() {
        const { className } = this.props
        return (
            <svg className={className} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                        <stop stopColor="#8645F9" offset="0%"/>
                        <stop stopColor="#BB7BFD" offset="100%"/>
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <rect fill="#4146D3" width="40" height="40" rx="20"/>
                    <circle stroke="url(#a)" strokeWidth=".2" fill="#FFF" fillRule="nonzero" cx="20" cy="20" r="7"/>
                </g>
            </svg>
        )
    }
}
