import { getDirectMessagesData, getDiscussionsData } from "@tuqqi/common"
import { SERVER_API } from "../../../utils/serverAPI"

export const getNotificationData = (docId, chatId, dispatch, type) => {
    if (chatId) {
        dispatch(getDirectMessagesData(SERVER_API)(chatId, type))
    }
    else {
        dispatch(getDiscussionsData(SERVER_API)(docId, type))
    }
}