import { push } from "react-router-redux";
import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions";
import { myPageContext } from "../drawerNavigation/myDrawerNavigationHelpers";

export const onClickDefaultLaunchpadOption = (referenceUid, dispatch) => {
    if(!referenceUid.includes('default:')) return
    const [, type] = referenceUid.split(":")

    switch (type) {
        case 'feed':
            dispatch(push('/feed'));
            dispatch({ type: "DATA_FEED_UPDATE" });
            dispatch({ type: 'RESULT_CHANNEL_EMPTY' });
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'mypage':
            dispatch(push('/mypage'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'actioncenter':
            dispatch(selectDrawerNavigationOption(myPageContext.actionCenter.id))
            dispatch(push({ pathname: myPageContext.actionCenter.pathname })) 
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'mypage_tasks':
            dispatch(push('/mypage/tasks'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'favorites':
            dispatch(push('/mypage/favorites'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'library':
            dispatch(push('/library'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'orgscheduler':
            dispatch(push('/orgscheduler'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'orgchart':
            dispatch(push('/orgchart'));
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        case 'groups':
            dispatch({ type: 'SEARCH_QUERY', query: {query: '/', isFocused: true} })
            dispatch({ type: 'BLUR_LAUNCHPAD' })
            break;
        default:
            break;
    }
}