import React, { memo }  from 'react'
import { withStyles } from '@material-ui/core'
import { DoNotDisturbOff } from '@material-ui/icons';
import { managerGanttRef } from '@tuqqi/web';

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    container: {
        minWidth: 80,
        display: 'flex',
    },
    title: {
        color: '#21212D',
        fontSize: 14,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: '500',
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
    },
})

const __onRemove = (record) => {
    if(!managerGanttRef.gantt) return
    const docId = record.originalData.id
    const data = managerGanttRef.gantt.instance.dependencyStore?.records ?? []

    const dataForRemove = data
        .filter(x => x.fromEvent.id === docId)



    record.set('deps', [])
    dataForRemove?.length && managerGanttRef.gantt.instance.dependencyStore.remove(dataForRemove)
}

const DocRelations = memo((props) => {
    const {record, deps} = props


    if(!deps?.length) return null

    const onRemove = () => {
        __onRemove(record)
    }

    return <DoNotDisturbOff onClick={onRemove} nativeColor='#ED6647' style={{height:24, width:24, display: 'flex', margin: 'auto', cursor: 'pointer'}} />
})

export default withStyles(styles, {withTheme: true})(DocRelations)