import { Grid, Icon, TextField, Typography, withStyles } from '@material-ui/core';
import { proccessLabelColor } from '@tuqqi/common';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { allCollectionMembers } from '../../../views/groups/groupUtils';
import UserListItem from '../../Common/userListItem';
import InfinityUsersList from '../../Common/Users/InfinityUsersList';
import Label from '../../FeedViewComponents/ItemPreviewComponents/Menu/LabelMenu/Label';

// Apply some reset
const styles = theme => ({
    container: { marginBottom: 8 },
    color: {
        width: 260,
        minHeight: 32,
        borderRadius: 2,
        position: 'relative',
    },
    name: {
        color: '#1B1E2A',
        fontWeight: 500,
        textTransform: 'capitalize',
        marginLeft: 14
    },
    icon: {
        color: '#1B1E2A',
        position: 'absolute',
        right: 4
    },
    edit: { width: 32, height: 32 }
});

class LabelRuleTemplate extends Component {
    state = {
        labels: []
    }
    componentDidMount() {
        this.props.data.labels ? this.setState({ labels: [...this.props.data.labels] }) : this.setState({ labels: [] })
    }

    labels = () => {
        const { collectionLabels, orgLabels } = this.props;
        const { search } = this.state

        const allLabels = [...collectionLabels, ...orgLabels]
        let res = []
        if (!search || search.length === 0) {
            res = allLabels
        } else {
            res = allLabels.filter(label => label.name.toLowerCase().includes(search.toLowerCase()))
        }

        return res
    }

    islabelExistOnItem = (label) => {
        const { labels } = this.state;

        return labels.indexOf(label.labelUid) > -1
    }

    choose = (label) => {
        this.props.updateData({ labels: [...this.state.labels, label.labelUid] })

        this.setState({ labels: [...this.state.labels, label.labelUid] })

    }

    removeLabel = (label) => {

        this.props.updateData({ labels: this.state.labels.filter(x => x !== label.labelUid) })
        this.setState({ labels: this.state.labels.filter(x => x !== label.labelUid) })
        // this.props.updateData({ labels: [...this.state.labels, label.labelUid] })

        // this.setState({ labels: [...this.state.labels, label.labelUid] })

    }

    render() {
        const { classes, t } = this.props;
        const { labels } = this.state;



        return (
            <div data-intrcm-id="LabelRuleTemplate_1eudjr0s" >
                <Grid container >
                    <Grid container direction='column' wrap='nowrap'  >
                        {this.labels().map((label, idx) => <Grid container alignContent='center' alignItems='center' justify='left' wrap='nowrap' item onClick={() => { !this.islabelExistOnItem(label) ? this.choose(label) : this.removeLabel(label) }}
                            className={classes.color}
                            style={{ backgroundColor: proccessLabelColor(label.color) /* boxShadow: (key % 2 == 0 ? `-8px 0 ${label.color}` : '')*/ }}>
                            <Typography variant='caption' className={classes.name}>{label.name}</Typography>
                            {
                                this.islabelExistOnItem(label) ? <Icon className={classes.icon} >check</Icon> : null
                            }
                        </Grid>)}
                    </Grid>

                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // collections: state.userData.collections,
    // profileData: state.userData.profileData,
    // inputService: state.services.inputService,
    // userSettingsService: state.services.userSettingsService,
    // queryService: state.queryService,
    // users: state.globalData.users,
    // search: state.search,
    // groupUid: state.search.groupUid,
    collectionUid: state.results.channel.collectionUid,
    collectionLabels: state.userData.collections.find(collection => collection.collectionUid === state.results.channel.collectionUid).collectionLabels,
    orgLabels: state.globalData.orgLabels,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('labelMenu'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LabelRuleTemplate);
// export default compose(withStyles(styles))(MemberRuleTemplate);