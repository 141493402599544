import { FULL_RESET_STORE } from "../actionTypes/actionTypes";
import { SELECT_DRAWER_NAVIGATION_OPTION, SET_DRAWER_NAVIGATION_MODE, CLEAR_DRAWER_NAVIGATION, SET_DRAWER_NAVIGATION_SUBMENU, CLEAR_DRAWER_NAVIGATION_SUBMENU } from "../actionTypes/drawerNavigationTypes";

const initialSubMenu = {
    listOptions: [],
    option: -1
}
const initialState = {
    mode: '',
    submenu: initialSubMenu,
    selectedId: -1
}

const drawerNavigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
        case CLEAR_DRAWER_NAVIGATION:
            return initialState
        case SET_DRAWER_NAVIGATION_MODE:
            return {...state, mode: action.mode}
        case SELECT_DRAWER_NAVIGATION_OPTION:
            return {...state, selectedId: action.selectedId}
        case SET_DRAWER_NAVIGATION_SUBMENU:
            return {...state, submenu: {listOptions: action.listOptions, option: action.option}}
        case CLEAR_DRAWER_NAVIGATION_SUBMENU:
            return {...state, submenu: initialSubMenu}
        default:
        return state
    }
}

export default drawerNavigationReducer