import { Button, DialogActions, Divider, Grid, Icon, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { BusinessClassPlane } from '../../../../../components/Common/Subscription/UpgradePlan/Planes/BusinessClassPlane';
import { EconomyClassPlane } from '../../../../../components/Common/Subscription/UpgradePlan/Planes/EconomyClassPlane';
import { FirstClassPlane } from '../../../../../components/Common/Subscription/UpgradePlan/Planes/FirstClassPlane';
import withErrorCatcher from '../../../../../components/Common/withErrorCatcher';

// Apply some reset
const styles = theme => ({
    backdrop: {
        '-webkit-backdrop-filter': 'blur(2px)',
        'backdrop-filter': 'blur(2px)',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    paper: {
        width: 496,
        minHeight: 655,
        backgroundColor: '#fff',
        borderRadius: 6
    },
    closeIcon: {
        color: '#979AAA',
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
    },
    backIcon: {
        color: '#979AAA',
        position: 'absolute',
        left: 20,
        top: 20,
        cursor: 'pointer',
    },
    title: {
        marginRight: 80,
        marginLeft: 80,
        marginTop: 32,
        fontFamily: "Rubik",
        fontSize: 24,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        color: theme.newUI.color.commonBlack,
        color: '#21212d',
        textAlign: 'center'
    },
    titleContainer: {
        height: 48,
        padding: 0,
        position: 'relative'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    description: {
        textAlign: 'center',
        marginRight: 30,
        marginLeft: 30,
        marginTop: 16,
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#a2a5b8'
    },
    featuresContainer: {
        display: 'grid',
        'grid-template-columns': 'auto auto',
        flexWrap: 'unset!important'
    },
    featureContainer: {
        marginBottom: 24,
        minHeight: 24,
        alignItems: 'flex-start',
        '&:nth-child(odd)': {
            marginRight: 30
        },
    },
    featureIcon: {
        fontSize: 23,
        color: theme.subscriptionColors.main
    },
    featureText: {
        maxWidth: 190,
        marginLeft: 14,
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        color: '#21212d'
    },
    actions: {
        position: 'relative',
        height: 108
    },
    actionsContainer: {
        position: 'absolute',
        bottom: 20,
        flexDirection: 'column',
        width: 496
    },
    upgradeButton: {
        backgroundColor: `${theme.subscriptionColors.main} !important`,
        textTransform: "none",
        width: 328,
        height: 54,
        borderRadius: 8,
        boxShadow: '0 8px 16px 0 rgba(150, 49, 255, 0.25)'
    },
    upgradeText: {
        fontFamily: "Rubik",
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        fontSize: 16,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#ffffff"
    },
    learnMoreButton: {
        marginTop: 18,
        textTransform: "none",
    },
    learnMoreText: {
        textTransform: "none",
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: theme.subscriptionColors.main
    },
    formContainer: {
        marginTop: 0,
        position: 'relative',
        width: 96,
        height: 96,
        borderRadius: '50%',
        display: 'flex',
        alignSelf: 'center',
        backgroundColor: theme.subscriptionColors.secondary
    },
    planeEconomy: {
        transform: 'scaleX(-1) scale(1.2)',
        top: 24,
        left: 8
    },
    planeBusiness: {
        transform: 'scaleX(-1) scale(1.25)',
        top: 2,
        left: -9
    },
    planeFirst: {
        transform: 'scaleX(-1) scale(1.1)',
        top: 4,
        left: -17
    },
    divider: {
        color: '#d8dbeb',
        marginTop: 28,
        marginBottom: 28
    }
});

const Feature = withStyles(styles)((props) => {
    const { text, classes } = props

    return (
        <Grid className={classes.featureContainer} container alignItems='center'>
            <Icon className={classes.featureIcon}>done</Icon>
            <span className={classes.featureText}>{text}</span>
        </Grid>
    )
})

class YouNeedToUpdateSubscriptionModal extends PureComponent {

    upgrade = () => {
        const { onClose, goTo } = this.props;

        onClose();
        goTo('/admin/billing')
    }

    learnMore = () => {
        window.open("https://tuqqi.com/contact-sales", '_blank').focus()
    }

    handleClose=()=>{

        this.props.onClose()
    }

    renderPlane = () => {
        const {classes, subscription: {subscriptionType}} = this.props

        return (
            <div data-intrcm-id="YouNeedToUpdateSubscriptionModal_vwvzm7jx" className={classes.formContainer}>
                {getPlane(classes, subscriptionType)}
            </div>
        )
    }

    renderFeatures = () => {
        const { features, classes } = this.props;

        return (
            <Grid className={classes.featuresContainer}>
                {features.map(feature => <Feature text={feature} key={feature}/>)}
            </Grid>
        )
    }
    
    render() {
        const { open, onClose, onHome, title, description, classes, allowClose, t } = this.props;

        return (
            <Dialog
                open={open}
                classes={{
                    paper: classes.paper
                }}
                onClose={this.handleClose}
                BackdropProps={{classes: {root: classes.backdrop}}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className={classes.titleContainer}>
                    {allowClose ? 
                        <Icon onClick={onClose} className={classes.closeIcon}>close</Icon> :
                        <Icon onClick={onHome} className={classes.backIcon}>keyboard_arrow_left</Icon>
                    }
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    {this.renderPlane()}
                    <span className={classes.title}>{title}</span>
                    <span className={classes.description}>{description}</span>
                    <Divider className={classes.divider} />
                    {this.renderFeatures()}
                </DialogContent>
                <div data-intrcm-id="YouNeedToUpdateSubscriptionModal_ywfpecua" className={classes.actions}>
                    <DialogActions className={classes.actionsContainer}>
                        <Button variant="contained" onClick={this.upgrade}
                            classes={{
                                root: classes.upgradeButton,
                                label: classes.upgradeText
                            }} >
                            {t("Upgrade")}
                        </Button>
                        <Button onClick={this.learnMore}
                            classes={{
                                root: classes.learnMoreButton,
                                label: classes.learnMoreText
                            }} >
                        {t("Learn more")}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>)
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch,
    goTo: path => { dispatch(push(path)) }
})

const mapStateToProps = (state) => ({
    userData: state.userData,
})

export default compose(
    withStyles(styles),
    withTranslation("admin"),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(YouNeedToUpdateSubscriptionModal);


const getPlane = (classes, subscriptionType) => {
    switch (subscriptionType) {
        case 0:
            return <EconomyClassPlane planeClasses={classes.planeEconomy}/> 
        case 1:
            return <BusinessClassPlane planeClasses={classes.planeBusiness}/>
        case 2:
            return <FirstClassPlane planeClasses={classes.planeFirst}/>
        default:
            return <EconomyClassPlane planeClasses={classes.planeEconomy}/>
    }
}
