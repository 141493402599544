import { LOCATION_CHANGE } from 'react-router-redux';
import { REMOVE_LOCAL_FILTER, APPLY_LOCAL_FILTER, CLEAN_FILTER, CLEAN_LOCAL_FILTER, FULL_RESET_STORE, SEARCH_QUERY, SEARCH_QUERY_UPDATE } from '../actionTypes/actionTypes';
import {ON_GO_TO_CHAT} from '@tuqqi/web'


export const initFilter = () => ({
    filters: {},
    savedReports: [],
    currentSavedReport: null,
    selectedFieldSources: [],
    ignoreSelectedFields: false
})
const initialState = [];
const LocalFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FULL_RESET_STORE:
            return initialState;

        case APPLY_LOCAL_FILTER:
            // Clear query filter        
            return {
                ...state,
                filters: { ...state.filters, ...action.filter },
                indicator: state.indicator + 1
            }
        case 'SET_SELECTED_FIELD_SOURCES':
            return {
                ...state,
                ignoreSelectedFields: false,
                selectedFieldSources: action.selectedFieldSources,
            }
        case 'SET_SAVED_REPORTS':
            return {
                ...state,
                savedReports: action.savedReports,
            }
        case 'SET_CURRENT_SAVED_REPORT':
            return {
                ...state,
                currentSavedReport: action.currentSavedReport,
            }
        case 'SET_IGNORE_SELECTED_FIELD':
            return {
                ...state,
                ignoreSelectedFields: action.ignoreSelectedFields,
            }
        case REMOVE_LOCAL_FILTER:
                const {name} = action
                const newFilters = {...state.filters}
                delete newFilters[name];
                return {
                    ...state,
                    filters: newFilters,
                    indicator: state.indicator + 1
                }

        case SEARCH_QUERY:
        case ON_GO_TO_CHAT:
        case CLEAN_LOCAL_FILTER:
            return {
                ...state,
                filters: {},
                selectedFieldSources: []
            }

        default:
            return state
    }
}

export default LocalFilterReducer