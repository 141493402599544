import React, { Component, Fragment, PureComponent } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'


import { withStyles, Grid, LinearProgress } from '@material-ui/core';

// import Task from "./task";
import Task from "../../Comments/task";
import { showSnackbar } from "../../../../actions/appActions";

// Apply some reset
const styles = theme => ({
    percentage: {
        color: '#6b808c',
        fontSize: 14,
        lineHeight: '10px',
        textAlign: 'center'
    },
    iconContainer: {
        flex: '0 0 36px',
        marginRight: 16,
        width: 36,
        textAlign: 'center'
    },
    colorPrimary: {
        backgroundColor: '#f9dd87'
    },
    root: {
        height: 10,
        borderRadius: 30,
        width: '100%'
    },
});

class TaskHeader extends PureComponent {

    

    render() {
        const { done, allTasks, classes } = this.props;

        const percentage = done * (100 / allTasks)

        const liniarValue = Math.floor(percentage) || 0

        return (
            <Grid container wrap='nowrap' >
                <Grid item alignItems='center' justify='center' className={classes.iconContainer}>
                    <span className={classes.percentage}>
                        {liniarValue}%
                    </span>
                </Grid>
                <Grid container alignContent='center' alignItems='center' item >
                    <LinearProgress
                        variant='determinate'
                        value={liniarValue}
                        classes={classes}
                    />
                </Grid>
            </Grid>
        )
    }
}



export default compose(withStyles(styles))(TaskHeader);