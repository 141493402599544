import React, { Component } from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => ({
    progress: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        textAlign: 'center',
        align: 'center'

    },
    spinner: {
        textAlign: 'center',
        paddingTop: 16
    },
    paper: {
        padding: 16
    },
    listStyle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paperStyle: {
        marginBottom: 40
    },
});

class ScoreboardSettings extends Component {

    state = {
        isLoading: true
    };

    render() {

        const { classes, knowledgelevels,t } = this.props;

        var content = <List className={classes.listStyle}>
            {knowledgelevels.map(knowledgelevel => {
                return <ListItem divider>
                    {/* <Avatar src={profileImageUrl} /> */}
                    <Avatar> {knowledgelevel.level} </Avatar>
                    <ListItemText
                        primary={knowledgelevel.displayName}
                        secondary={`${t("From")} ${knowledgelevel.startNumber} ${t("points")}`}/>
                    <ListItemSecondaryAction style={{paddingRight: 16}}>
                        <div data-intrcm-id="KnowledgeLevelsSettings_vn0hgkyb" className={classes.admin}>
                            <span className={classes.adminText}>{`${knowledgelevel.count} ${t("users")}`}</span>
                        </div>
                    </ListItemSecondaryAction>
                </ListItem>
            })}
        </List>
        
        return <div data-intrcm-id="KnowledgeLevelsSettings_71dx8ta4">
            <AdminSettingsTitle text={t('Knowledge levels')} />
            <Paper className={classes.paperStyle}>
                {content}
            </Paper>
        </div>
    }

}

const mapStateToProps = (state) => ({
    userData: state.userData,
    knowledgelevels: state.globalData?.knowledgelevels ?? [],
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),withTranslation('admin'), connect(mapStateToProps, mapDispatchToProps))(ScoreboardSettings);
