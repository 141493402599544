import React, { PureComponent } from 'react';

import withErrorCatcher from '../withErrorCatcher.js'
import { withStyles, Icon, Typography, Tooltip } from '@material-ui/core';
import { compose } from 'recompose';

const styles = theme => ({
    pin: {
        color: 'red', 
        
        // this setting are important in order to set the edge of the pin on the correct place
        position: 'absolute', 
        top: -21, left: -12
    }
});

class MapPin extends PureComponent {
    render() {
        const { text, classes, onClick } = this.props

        const content = <Icon className={classes.pin} onClick={onClick}>location_on</Icon>
        
        if(text){
            return <Tooltip placement='top' title={text} children={content}/>
        } else {
            return content
        }
    }
}

export default compose(withStyles(styles),
    withErrorCatcher())(MapPin);

