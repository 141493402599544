import moment from "moment"

const distantFuture = new Date(8640000000000000)

export const getCollectionByGroupUid = (groupUid, allCollections) => {
    return allCollections.find(coll => coll.collectionGroup.groupUid === groupUid)
}

export const sortItemsByDate = (a, b) => {
    let dateA = a.dueDateUTC ? new Date(a.dueDateUTC) : distantFuture
  	let dateB = b.dueDateUTC ? new Date(b.dueDateUTC) : distantFuture
 	return dateA.getTime() - dateB.getTime()
}

export const sortItemsByCreatingDate = (a, b) => {
 	return moment(a.docTimestamp) - moment(b.docTimestamp)
}

export const sortItemsByRating = (a, b) => { 
    if (a.rating > b.rating) {
        return -1
    } else if (a.rating < b.rating) {
        return 1
    }
    return 0
}   

export const sortItemsByLaneColor = (a, b) => {
    return (a.TaskStatusColor ?? 0) - (b.TaskStatusColor ?? 0)
}

export const sortTasksByItemsLen = (tasks = []) => {
    return tasks.sort((a, b) => {
        return b.data.length - a.data.length
    })
}