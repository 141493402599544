import { Collapse, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { openFullview } from '@tuqqi/web';
import React, { useCallback, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import goToCollection from '../../../../actions/goToCollection';
import { getAllCollection } from '../../../../views/groups/groupUtils';
import withErrorCatcher from '../../../Common/withErrorCatcher';
import DrawerListItem from './drawerListItem';
import {isExistsTuqqiIcon, getDefaultTuqqiIconName} from '@tuqqi/common'
import {allFluencyIconsNames, allFluencyIconsData, getDefaultTuqqiIcon} from '@tuqqi/common'
import SVG from 'react-inlinesvg';
import { processNavigationLink } from '../../../../../utils/feedNavigation/proceesNavigationLink';
import { addRecentItem } from '../../../../../actions/searchNavigationActions';

const styles = (theme) => ({
    customIcon: {

    },
    divider: {
        backgroundColor: '#e0e0e0',
    },
    arrowDown: {
        cursor: 'pointer',
        position: 'absolute',
        top: 4,
        left: 181,
        transition: 'transform .2s ease-in-out',
        // transform: 'rotate(-90deg)',
        color: '#6B6F7A',
        width:20,
        // color: theme.customColors.primary.main,
        zIndex:100
    },
    arrowRight: {
        cursor: 'pointer',
        position: 'absolute',
        zIndex:100,
        top: 4,
        left: 181,
        transform: 'rotate(180deg)',
        transition: 'transform .2s ease-in-out',
        // color: theme.customColors.primary.main,
        color: '#6B6F7A',
        width:20
    },
    entered: {
        marginBottom: 4
    },
    subItem: {
        borderLeftColor: '#D7D7DB',
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
    }
})

const _LeftDrawerCustomItemCategory = (props) => {
    const {classes, dispatch, orgMenuItems, openCollections, collections, item, allowedItemsUids} = props
    const allGroups = useMemo(() => getAllCollection(openCollections, collections), [openCollections, collections])
    const [collapsed, setCollapsed] = useState(true)
    const [iconHover, setIconHover] = useState(false)

    const toggleCollapsed = () => {
        setCollapsed(prev => !prev)
    }

    const isGroupExists = useCallback((groupUid) => {
        const group = allGroups.find(col => col.collectionGroup.groupUid === groupUid)
        return !!group
    }, [allGroups])

    const isItemExists = useCallback((uid) => {
        return allowedItemsUids.includes(uid)
    }, [allowedItemsUids])

    const goToGroup = useCallback((groupUid) => {
        const group = allGroups.find(col => col.collectionGroup.groupUid === groupUid)
        if(!group) return
        goToCollection('/' + group.title, dispatch, group.collectionUid, group.collectionGroup.groupUid, group)();
    }, [allGroups])

    const openItem = useCallback((docId) => {
        dispatch(openFullview(docId, true))
        dispatch(addRecentItem(docId))
    }, [])

    const openLink = useCallback((link) => {
        processNavigationLink(link, dispatch)
    }, [])

    const goToItem = useCallback((item) => {
        switch(item.type){
            case 1:
                return goToGroup(item.referenceUid)
            case 2:
                return openItem(item.referenceUid)
            case 10:
                return openLink(item.referenceUid)
            default:
                return
        }
    }, [goToGroup, openItem, openLink])

    const getisDisabled = useCallback((item) => {
        switch(item.type){
            case 1:
                return !isGroupExists(item.referenceUid)
            case 2:
                return !isItemExists(item.referenceUid)
            case 10:
                return false
            default:
                return false
        }
    }, [isGroupExists, isItemExists])

    const renderIcon = useCallback((icon, type) => {
        const rightIcon = isExistsTuqqiIcon(icon) ? icon : type === 2 ? 'collectibles' : getDefaultTuqqiIconName()
        const ind = allFluencyIconsNames.findIndex(x => x === rightIcon)
        const path = (ind === -1) ? getDefaultTuqqiIcon() : allFluencyIconsData[ind]

        return <SVG className={classes.customIcon} width={24} height={24} src={path} />
    }, [classes])

    const clickCategory = useCallback(() => {
        if(item.type === 0) {
            toggleCollapsed()
            return
        }
        goToItem(item)
    }, [item, goToItem])

    const Arrow = item.type !== 0 ? null : !collapsed ?
        <ExpandMoreIcon onMouseLeave={() => setIconHover(false)} onMouseEnter={() => setIconHover(true)} onClick={clickCategory} className={classes.arrowRight} /> :
        <ExpandMoreIcon onMouseLeave={() => setIconHover(false)} onMouseEnter={() => setIconHover(true)} onClick={clickCategory} className={classes.arrowDown} />

    const renderSubItems = useCallback((item) => {
        if(item.type !== 0) return null
        const subs = orgMenuItems.filter((it) => it.type !== 0 && it.categoryId === item.id)
        if(!subs.length) return null

        return subs.map((subItem) => {
            if(getisDisabled(subItem)) return null
            return <div data-intrcm-id="LeftDrawerCustomItemCategory_f2ztn064" className={classes.subItem} style={{marginLeft: 18}}>
                <DrawerListItem
                    id={`${subItem.id}_custom_item`}
                    disabled={getisDisabled(subItem)}
                    isCustom
                    isSub
                    disableHover={subItem.type === 0}
                    key={`${subItem.referenceUid}_${subItem.id}`}
                    selectedCondition={false}
                    onClick={() => goToItem(subItem)}
                    icon={renderIcon(subItem.customIcon, item.type)}
                    text={subItem.customTitle} />
                </div>
        })
    }, [classes, orgMenuItems])
    

    if(getisDisabled(item)) return null

    return <div data-intrcm-id="LeftDrawerCustomItemCategory_mmgvh2dl" style={{position: 'relative'}}>
        {Arrow}
        <DrawerListItem
            id={`${item.id}_custom_item`}
            disabled={getisDisabled(item)}
            isCustom
            // disableHover={item.type === 0}
            key={`${item.referenceUid}_${item.id}`}
            selectedCondition={false}
            iconHover={iconHover}
            onClick={clickCategory}
            icon={renderIcon(item.customIcon, item.type)}
            text={item.customTitle} />
        <Collapse in={!collapsed} classes={{entered: classes.entered}} timeout="auto" unmountOnExit={false}>
        {renderSubItems(item)}
        </Collapse>
    </div>
}

const mapStateToProps = (state) => ({
    orgMenuItems: state.feedNavigation.orgMenuItems,
    openCollections: state.data.globalData.openCollections.data,
    collections: state.data.userData.userCollections.data,
})

const mapDispatchToProps = dispatch => ({dispatch})

export default compose(
    withErrorCatcher(),
    withStyles(styles, {withTheme: true}),
    withTranslation('drawerListItemTerms'),
    connect(mapStateToProps, mapDispatchToProps)
)(_LeftDrawerCustomItemCategory);
