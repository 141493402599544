export const OuterOperations = {
    onChange: (ops = []) => {},
    setUnits: (units) => {}
}

export const setOuterChangeOperations = (cb) => {
    OuterOperations.onChange = cb
}

export const setOuterUnitsCalculation = (cb) => {
    OuterOperations.setUnits = cb
}