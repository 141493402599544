import React, { Component, Fragment } from 'react'
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles, Icon, Avatar } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import dataTypesDictionary from '../../../Common/userCompatibleNames/dataTypesDictionary';
import moment from 'moment';
import goToGroup from '../../../../actions/goToGroup'
import { goTo } from '../../../../../actions/goToActions'
import TimeToRead from '../TimeToRead'
import ItemClassification from '../ItemClassification'
import SwappingMembersAvatar from '../../../Common/SwappingMembersAvatar';

const styles = theme => ({
    avatar: {
        marginRight: 8,
        cursor: 'pointer',
    },
    header: {
        margin: 0
    },
    textStyle: {
        fontFamily: '"Roboto"',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.3,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'inline',
    },
    icon: {
        height: 18,
        fontFamily: 'Material Icons',
        color: '#868686',
        fontSize: 18,
        padding: 0,
        paddingRight: 5,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    classButton: {
        paddingLeft: 2,
        paddingRight: 3
    },
})

class ItemAuthor extends Component {


    getAuthors = (userName, members) => {
        if (!members || !members.length) {
            return userName;
        }
        else {
            const membersNumToDesplay = 3

            let users = members.slice(0, membersNumToDesplay).map(member => member.userName).join(", ")

            if (members.length > membersNumToDesplay) {
                let more = members.length - membersNumToDesplay

                users += " & " + more + (more > 1 ? " others" : " other")
            }

            return users
        }
    }

    openUser = userTag => () => {
        if (userTag) {

            let tag = ''

            // After this section the tag value is '@username'

            // Push @ before the tag (if it isn't exist there)
            if (!userTag.startsWith('@')) {
                tag = '@'
            }

            tag += userTag.replace(' ', '').toLowerCase()

            goTo(tag, this.props.dispatch)()
        }
    }

    calculateInfoToPresent = (item) => {
        const { users, inputMappings, t } = this.props;
        const { userName, userPicUrl, userTag, dataType, userUUID, docTimestamp, members } = item;

        let level = 0;
        let title = '';
        const user = users.find(user => user.auth0_id === userUUID)

        if (user) {
            level = user.level;
            title = user.title;
        }

        return {
            userName: userName,
            userPicUrl: user ? user.profileImageUrl : userPicUrl,
            userTag: userTag ? userTag : (userName.replace(' ', '')).toLowerCase(),
            title,
            level,
            sharedTime: moment(docTimestamp).format('MMMM Do YYYY'),
            dataType: dataTypesDictionary(dataType, inputMappings),
            members
        }
    }

    render() {

        const { item, showTimeToRead, classes, t } = this.props
        const { sharedTime, dataType, userName, userPicUrl, userTag, members, level, title } = this.calculateInfoToPresent(item)

        const rowStyle = {
            flexDirection: 'row', display: 'flex'
        }
        const seperator = '·'

        return (<div data-intrcm-id="ItemAuthor_oe4yccz8" className={classes.header}>
            <div data-intrcm-id="ItemAuthor_vlir7nbw" style={{ ...rowStyle, paddingBottom: 10 }} className={classes.textStyle}>
                {showTimeToRead ? <TimeToRead item={item} seperator={seperator} /> : null}
                <ItemClassification item={item} seperator={seperator} />

                <div data-intrcm-id="ItemAuthor_7pquhgky" >{dataType} · {sharedTime} ({t('created date')})</div>
            </div>
            <div data-intrcm-id="ItemAuthor_n2rvutnm" style={rowStyle} className={classes.textStyle}>
                <SwappingMembersAvatar userUUIDs={item.members ? item.members.map(x => x.userUUID) : []}
                    userAvatarProps={{ hideLevel: true, variant: 'extraSmall' }}
                    classes={{ avatar: classes.avatar }} />
                <div data-intrcm-id="ItemAuthor_f9blyisk" style={{ width: '100%' }}>
                    {this.getAuthors(userName, members)}
                    {members && members.length > 1 ? '' : `(${t('Level')} ${level}) · ${title}`}
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    inputMappings: state.globalData.inputMappings,
    classifications: state.globalData.classifications
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    withTranslation('itemFullViewModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemAuthor);