import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PreviewPrize from './previewPrize';

const maxNumPrizes = 3
const styles = theme => ({
    footer: {
        display: 'flex',
        padding: '0 24px 24px'
    },
    leftButton: {
        marginLeft: 'auto'
    },
    paperStyle: {
        marginBottom: 40
    },
})

class Prize extends Component {
    state = {
        openPreview: false
    }

    removePrize = () => {
        const { removePrize, index } = this.props;
        removePrize(index)
    }
    previewPrize() {

    }
    componentWillMount() {
        const { data = {}, index } = this.props;
        let plaseIndex = '';
        switch (index) {
            case 0:
                plaseIndex = 'First'
                break;
            case 1:
                plaseIndex = 'Second'
                break;
            case 2:
                plaseIndex = 'Third'
                break;
            default:
                break;
        }

        this.setState({
            plaseIndex: plaseIndex,
            title: data.title,
            link: data.link,
            image: data.image,
            description: data.description,
        })

    }
    handleChange = (key) => (event) => {
        let value = event.target.value
        this.setState({ [key]: value })
        this.props.handlePrizeChenged(key, value)
    }
    render() {
        const { classes, removePrize, index,t } = this.props;
        return (
            <div data-intrcm-id="Prize_z585alc3" className={classes.page}>
                <Typography variant="body2" gutterBottom>
                    {this.state.plaseIndex} {t('Place')}
                                </Typography>
                <Paper className={classes.paperStyle}>
                    <List>
                        {
                            ["Title", "Link", "Image", "Description"].map((item, idx) => {
                                let lowerItem = item.toLowerCase()
                                let value = this.state[lowerItem]
                                return (
                                    <ListItem>
                                        <TextField key={idx}  margin="dense" value={this.state[lowerItem]} label={item} type="text" fullWidth
                                            onChange={this.handleChange(lowerItem)} />
                                    </ListItem>)
                            })
                        }
                    </List>

                    <div data-intrcm-id="Prize_f3dad3hu" className={classes.footer}>
                        <Button onClick={() => this.setState({ openPreview: true })}
                            aria-label="edit"
                            color="primary"
                            variant={'raised'}>
                            {t('Preview')}
            </Button>
                        <PreviewPrize onClose={() => this.setState({ openPreview: false })} openDialog={this.state.openPreview} plaseIndex={this.state.plaseIndex} link={this.state.link} title={this.state.title} image={this.state.image} description={this.state.description} />
                        <Button className={classes.leftButton} color="primary" variant="raised" onClick={this.removePrize}>
                            {t('Remove')}
                    </Button>
                    </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
    return { dispatch: dispatch }
}

export default compose(withStyles(styles),
withTranslation('admin'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Prize);