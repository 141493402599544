import { OPEN_SUBSCRIPTION_MODAL, CLOSE_SUBSCRIPTION_MODAL, INIT_SUBSCRIPTION_MODAL } from '../actions/upgradeSubscriptionActions';
import { FULL_RESET_STORE } from '../actionTypes/actionTypes';
import { getReasonData } from '../utils/subscription/reasonData';
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = {
  open: false,
  title: '',
  description: '',
  features: [],
  onClose: undefined,
  allowClose: true,
}
const upgradeSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
    case ON_GO_TO_CHAT:
    case ON_GO_TO_ITEM_NOTIF:
      return {...initialState};

    case OPEN_SUBSCRIPTION_MODAL:
      return {
        ...state,
        open: true,       
      }
    case CLOSE_SUBSCRIPTION_MODAL:
      return {...initialState};
    
    case INIT_SUBSCRIPTION_MODAL: {
        const {reason, mode, title, description, features, onClose, allowClose} = action
        const common = {
          ...state,
          ...(onClose === undefined ? {} : {onClose}),
          ...(allowClose === undefined ? {} : {allowClose}),
        }
        if(mode === 'manual') return {
            ...common,
            title,
            description,
            features
        }
        const data = getReasonData(reason)
        return {
            ...common,
            title: data.title,
            description: data.description,
            features: data.features
        }
    }
    default:
      return state
  }
}

export default upgradeSubscriptionReducer