import React from 'react'

const  MoreIcon  = (props) =>  {
       return <svg className={props?.className} xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
       <path d="M12.5 2C12.5 2.39782 12.658 2.77936 12.9393 3.06066C13.2206 3.34196 13.6022 3.5 14 3.5C14.3978 3.5 14.7794 3.34196 15.0607 3.06066C15.342 2.77936 15.5 2.39782 15.5 2C15.5 1.60217 15.342 1.22064 15.0607 0.939339C14.7794 0.658034 14.3978 0.499999 14 0.499999C13.6022 0.499999 13.2206 0.658034 12.9393 0.939339C12.658 1.22064 12.5 1.60217 12.5 2ZM6.5 2C6.5 2.39782 6.65804 2.77936 6.93934 3.06066C7.22064 3.34196 7.60218 3.5 8 3.5C8.39782 3.5 8.77936 3.34196 9.06066 3.06066C9.34196 2.77936 9.5 2.39782 9.5 2C9.5 1.60217 9.34196 1.22064 9.06066 0.939339C8.77936 0.658034 8.39782 0.499999 8 0.499999C7.60218 0.499999 7.22064 0.658035 6.93934 0.939339C6.65804 1.22064 6.5 1.60217 6.5 2ZM0.5 2C0.5 2.39782 0.658035 2.77936 0.93934 3.06066C1.22064 3.34196 1.60218 3.5 2 3.5C2.39782 3.5 2.77936 3.34196 3.06066 3.06066C3.34196 2.77936 3.5 2.39782 3.5 2C3.5 1.60217 3.34196 1.22064 3.06066 0.939339C2.77936 0.658035 2.39782 0.5 2 0.499999C1.60218 0.5 1.22064 0.658035 0.93934 0.939339C0.658035 1.22064 0.5 1.60217 0.5 2Z" fill="#4A4A4F"/>
       </svg>
}

export default MoreIcon





