import { useState, useEffect } from "react";

import defaultBackground from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Public.png";
import addCommentGroupBg from "../../../../assets/img/stories/user_add_comment/user_add_comment-Group.png";
import addCommentPublicBg from "../../../../assets/img/stories/user_add_comment/user_add_comment-Public.png";
import addItemGroupBg from "../../../../assets/img/stories/user_add_item/user_add_item-Group.png";
import addItemPublicBg from "../../../../assets/img/stories/user_add_item/user_add_item-Public.png";

import addTaskGroupBg from "../../../../assets/img/stories/user_add_task/user_add_task-Group.png";
import addTaskPublicBg from "../../../../assets/img/stories/user_add_task/user_add_task-Public.png";

import addAskedToReadBg from "../../../../assets/img/stories/user_askedtoread_item/user_askedtoread_item-Default.png";

import doneTaskGroupBg from "../../../../assets/img/stories/user_done_task/user_done_task-Group.png";
import doneTaskPublicBg from "../../../../assets/img/stories/user_done_task/user_done_task-Public.png";

import linkItemPublicBg from "../../../../assets/img/stories/user_link_item/user_link_item-Public.png";
import readItemPublicBg from "../../../../assets/img/stories/user_read_item/user_read_item-Public.png";

import moveItemGreenGreenBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Green-Green.png";
import moveItemGreenGreyBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Green-Grey.png";
import moveItemGreenOrangeBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Green-Orange.png";
import moveItemGreenRedBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Green-Red.png";
import moveItemGreyGreenBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Grey-Green.png";
import moveItemGreyRedBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Grey-Red.png";
import moveItemGreyYellowBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Grey-Yellow.png";
import moveItemGreyOrangeBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Grey-Orange.png";
import moveItemOrangeGreenBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Orange-Green.png";
import moveItemOrangeGreyBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Orange-Grey.png";
import moveItemOrangeRedBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Orange-Red.png";
import moveItemPurpleOrangeBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Purple-Orange.png";
import moveItemOrangeOrangeBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Orange-Orange.png";
import moveItemRedGreenBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Red-Green.png";
import moveItemRedGreyBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Red-Grey.png";
import moveItemRedOrangeBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Red-Orange.png";
import moveItemRedRedBg from "../../../../assets/img/stories/user_move_item_lane/user_move_item_lane-Red-Red.png";

const useBackground = (sceneLink) => {
  const [backgroundUrl, setBackgroudUrl] = useState("");
  useEffect(() => {
    sceneLink = sceneLink.replace("Turquoise", 'Orange')
    switch (sceneLink) {
      case "/user_add_item/user_add_item-Public.png":
        setBackgroudUrl(addItemPublicBg);
        break;
      case "/user_add_item/user_add_item-Group.png":
        setBackgroudUrl(addItemGroupBg);
        break;
      case "/user_add_comment/user_add_comment-Group.png":
        setBackgroudUrl(addCommentGroupBg);
        break;
      case "/user_add_comment/user_add_comment-Public.png":
        setBackgroudUrl(addCommentPublicBg);
        break;
      case "/user_add_task/user_add_task-Group.png":
        setBackgroudUrl(addTaskGroupBg);
        break;
      case "/user_add_task/user_add_task-Public.png":
        setBackgroudUrl(addTaskPublicBg);
        break;
      case "/user_askedtoread_item/user_askedtoread_item-Default.png":
        setBackgroudUrl(addAskedToReadBg);
        break;
      case "/user_done_task/user_done_task-Group.png":
        setBackgroudUrl(doneTaskGroupBg);
        break;
      case "/user_done_task/user_done_task-Public.png":
        setBackgroudUrl(doneTaskPublicBg);
        break;
      case "/user_link_item/user_link_item-Public.png":
        setBackgroudUrl(linkItemPublicBg);
        break;
      case "/user_read_item/user_read_item-Public.png":
        setBackgroudUrl(readItemPublicBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Green-Grey.png":
        setBackgroudUrl(moveItemGreenGreyBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Green-Orange.png":
        setBackgroudUrl(moveItemGreenOrangeBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Green-Red.png":
        setBackgroudUrl(moveItemGreenRedBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Grey-Green.png":
        setBackgroudUrl(moveItemGreyGreenBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Grey-Red.png":
        setBackgroudUrl(moveItemGreyRedBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Green-Green.png":
        setBackgroudUrl(moveItemGreenGreenBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Grey-Yellow.png":
        setBackgroudUrl(moveItemGreyYellowBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Grey-Orange.png":
        setBackgroudUrl(moveItemGreyOrangeBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Orange-Green.png":
        setBackgroudUrl(moveItemOrangeGreenBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Orange-Grey.png":
        setBackgroudUrl(moveItemOrangeGreyBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Orange-Red.png":
        setBackgroudUrl(moveItemOrangeRedBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Orange-Orange.png":
        setBackgroudUrl(moveItemOrangeOrangeBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Purple-Orange.png":
        setBackgroudUrl(moveItemPurpleOrangeBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Red-Green.png":
        setBackgroudUrl(moveItemRedGreenBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Red-Grey.png":
        setBackgroudUrl(moveItemRedGreyBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Red-Orange.png":
        setBackgroudUrl(moveItemRedOrangeBg);
        break;
      case "/user_move_item_lane/user_move_item_lane-Red-Red.png":
        setBackgroudUrl(moveItemRedRedBg);
        break;
      default:
        setBackgroudUrl(defaultBackground);
    }
  }, [sceneLink]);

  return { backgroundUrl };
};

export default useBackground;
