import { withStyles } from '@material-ui/core'
import React from 'react'

const styles = (theme) => ({
    container: {
        cursor: 'pointer',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: 36,
        backgroundColor: '#fff',
        borderRadius: 18,
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.customColors.primary.main,
            borderColor: theme.customColors.primary.main,
        },
    },
    inner: {
        margin: 4,
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'inherit',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'inherit',
    },
    selected: {
        border: `solid 1.5px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
    },
    nonSelected: {
        border: `solid 1.5px #979aaa`,
        color: '#73778f',
        fontWeight: 'normal'
    },
    icon: {
        borderRadius: '50%',
        marginRight: 6,
        width: 28,
        height: 28,
    },
    icon1: {
        borderRadius: '50%',
        marginRight: 6,
        width: 22,
        height: 22,
    },
})

const WidgetSingleImgOption = (props) => {
    const { classes, option, onClick, optionStyle } = props

    const _onClick = () => {
        onClick(option.key)
    }
    return (
        <div data-intrcm-id="WidgetSingleImgOption_eejwyxw4" onClick={_onClick} className={`${classes.container} ${optionStyle ?? ''} ${option.selected ? classes.selected : classes.nonSelected}`}>
            <div data-intrcm-id="WidgetSingleImgOption_xn11gj6d" className={classes.inner}>
                { option.isImage ? 
                    <img alt={option.key} src={option.icon} className={classes.icon} /> :
                    <div data-intrcm-id="WidgetSingleImgOption_18ohm0mv" style={{backgroundColor: option.icon}} className={classes.icon1} />
                }
                <div data-intrcm-id="WidgetSingleImgOption_wtzuyvg7" className={classes.text}>{option.label}</div>
            </div>
        </div>
    )
}

export default withStyles(styles, {withTheme: true})(WidgetSingleImgOption)