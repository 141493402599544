export default class FavouritesService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    postHeaders(){
        return {
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        }
    }

    // Create custom mapping
    addToFavourites(docId) {
        return fetch(process.env.API_SERVER + '/savedItems/addItem/', {
            method: 'POST',
            body: JSON.stringify(docId),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    removeFromFavourites(docId) {
        return fetch(process.env.API_SERVER + '/savedItems/removeItem/', {
            method: 'POST',
            body: JSON.stringify(docId),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getFavouritesInfo(){
        return fetch(process.env.API_SERVER + '/savedItems/info', {
            method: 'GET',
            headers: this.getHeader()
        }).then(res => res.json());
    }

    loadData(query){
        return fetch(process.env.API_SERVER + '/savedItems/get/', {
            method: 'POST',
            body: JSON.stringify(query),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    getKanbanData(query){
        return fetch(process.env.API_SERVER + '/savedItems/kanban', {
            method: 'POST',
            body: JSON.stringify(query),
            headers: this.postHeaders()
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    addLane(lane) {
        if (lane) {
            return fetch(process.env.API_SERVER + '/savedItems/newKanbanLane',
                {
                    method: 'POST',
                    body: JSON.stringify(lane),
                    headers: this.postHeaders()
                }).then(res => res.json());
        }
    }

    moveCard(movedCard){
        return fetch(process.env.API_SERVER + '/savedItems/moveCard/', {
            method: 'PUT',
            body: JSON.stringify(movedCard),
            headers: this.postHeaders()
        })
    }

    moveLane(collection){
        return fetch(process.env.API_SERVER + '/savedItems/moveLane/', {
            method: 'PUT',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        }).then(res => res.json());
    }

    updateLane(collection){
        return fetch(process.env.API_SERVER + '/savedItems/updateLane/', {
            method: 'PUT',
            body: JSON.stringify(collection),
            headers: this.postHeaders()
        })
    }

    deleteLane(deletedLaneId, collectionViewId){
        return fetch(process.env.API_SERVER + `/savedItems/deleteLane?deletedLaneId=${deletedLaneId}&collectionViewId=${collectionViewId}`, {
            method: 'DELETE',
            headers: this.postHeaders()
        });
    }
}