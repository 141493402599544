import { Grid, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../components/Common/Arrows/Arrows';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';

const styles = theme => ({
    container: {
        width: 'initial',
        marginRight:14
    },
    disableIcon:{
        fill: theme.newUI.color.gray
    },
    activeIcon:{
        fill: theme.newUI.color.commonBlack
    },
    text: {
        marginRight: 32,
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.commonBlack
    },
})

class Paging extends Component {

    isActiveIcon(direction){
        const { classes, rowsPerPage, totalResults, currPage } = this.props;
        let isActive
        const from = (currPage * rowsPerPage)
        const to = (currPage + 1) * rowsPerPage

        // if((to + rowsPerPage) < )
        const maxPage = Math.ceil(totalResults / rowsPerPage)
        const nextPage = currPage + direction
        if(nextPage <= maxPage  && nextPage >= 0){
            isActive = true
        }
        else{
            isActive = false
        }

        return isActive
    }

    render() {
        const { classes, rowsPerPage, totalResults, onPaging, currPage, t } = this.props;
        const from = ((currPage * rowsPerPage) + 1)
        const to = Math.min(((currPage + 1) * rowsPerPage) + 1, totalResults)

        return (
            <Grid item container className={classes.container} wrap='nowrap' alignItems='center'>
                <Grid item className={classes.text}>{from}-{to} {t("of")} {totalResults}</Grid>
                <ArrowLeftIcon 
                onClick={from > 1 && onPaging(-1)}
                className={from > 1 ? classes.activeIcon: classes.disableIcon}/>
                <ArrowRightIcon 
                onClick={to < totalResults && onPaging(1)}
                className={to < totalResults ? classes.activeIcon: classes.disableIcon}/>
            </Grid>
        )
    }

}


export default compose(withStyles(styles),
withErrorCatcher(),
    withTranslation('groupTable'))(Paging);