export const INITIAL_VIEW_MY_CALENDAR = 'timeGridWeek'
export const getMyUserCalendarQuery = (auth0_id, dateScheduleFrom, dateScheduleTo) => ({ dateScheduleFrom, dateScheduleTo, page: 0, size: 200, users: [auth0_id], isMyItem: false, labels: [], sortType: 'default' })

export function getUpcomingSunday() {
    const date = new Date();
    const today = date.getDate();
    const currentDay = date.getDay();
    const newDate = date.setDate(today - currentDay + 7);

    const n = new Date(newDate);
    n.setHours(23,59,0,0)
    return n
}

export function getLastSunday() {
    const date = new Date();
    const today = date.getDate();
    const currentDay = date.getDay();
    const newDate = date.setDate(today - (currentDay || 7));
    const n = new Date(newDate);
    n.setHours(0,0,0,0)
    return n
}
export function getBefore4days(date) {
    const date1 = new Date(date)
    date1.setDate(date1.getDate() - 4)
    date1.setHours(0,0,0,0)
    return date1
}
export function getAfter4days(date) {
    const date1 = new Date(date)
    date1.setDate(date1.getDate() + 4)
    date1.setHours(23,59,0,0)
    return date1
}

export const mapLangToCalendar = (lang) => {
    switch (lang) {
        case 'de':
            return 'de'
        case 'en-US':
            return 'en'
        case 'he-IL':
            return 'he'
        case 'es-AR':
            return 'es'
        case 'ru':
            return 'ru'
        case 'ar':
            return 'ar'
        case 'it':
            return 'it'
        case 'pl':
            return 'pl'
        case 'pt-BR':
            return 'pt-br'
        case 'pt':
            return 'pt'
        case 'uk-UA':
            return 'uk'
        case 'uk_UA':
            return 'uk'
        case 'fr':
            return 'fr'
    
        default:
            return 'en'
    }
}