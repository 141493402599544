import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Icon } from '@material-ui/core';
import { compose } from 'recompose'
import ReactSVG from 'react-svg';
import mimeTypes from "mime-types";
import LeftImage from "./LeftImage";
import classnames from "classnames";
import Container from "./IconTitleTextContainer/Container";
import TitleText from "./IconTitleTextContainer/TitleText";
import FileIcon from './FileIcon.js';

const styles = theme => ({
    container: {
        margin: '0px 16px',
        borderRadius: '2px',
        backgroundColor: '#f7f7f7',
    },
    title: {
        marginBottom: 4,

        "fontFamily": "Roboto",
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909"
    },
    iconContainer: {
        flexGrow: 0,
        flexShrink: 0,
        padding: 12,
        cursor: 'pointer',
    },
    icon: {
        fontSize: 18,
        color: theme.customColors.primary.main,
    },

    textContainer: {
        padding: 12,
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',

    },
    oneRow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    img: {
        "borderRadius": "2px",
        height: 40
    }
});

class FileTemplate extends PureComponent {
  
    render() {
        const { classes, result, openFile } = this.props;
        const { link, previewDescription, title, previewImgUrl, mimeType } = result;

        return (
            <Container >
                <LeftImage>
                    <FileIcon mimeType={mimeType} className={classes.img}/>                    
                </LeftImage>

                <TitleText title={title} text={previewDescription} />
                <Grid item className={classes.iconContainer} onClick={openFile} >
                    <Icon className={classes.icon}>get_app</Icon>
                </Grid>

            </Container>)

    }
}

export default compose(withStyles(styles, { withTheme: true }))(FileTemplate);
