export default class QueryService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }
    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    // Create custom mapping
    createClassification(classification) {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/classifications/new/', {
            method: 'POST',
            body: JSON.stringify(classification),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

    // Custom input mappings
    getClassifications() {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
        });

        return fetch(process.env.API_SERVER + '/classifications/all',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    // Create custom mapping
    editClassification(classification) {

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/classifications/class/' + classification.uId, {
            method: 'PUT',
            body: JSON.stringify(classification),
            headers: myHeaders
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }

}