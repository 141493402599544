
import { Grid, withStyles, Typography, Divider } from '@material-ui/core';
import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import MenuWithSearch from '../../../../components/Common/Menu/MenuWithSearch';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';


const styles = theme => ({
    divider: {
        margin: '24px 0px'
    },
    menuContainer: {
        marginTop: 16,
        height: 500
    },
    menuWrapper: {
        marginRight: 32
    }
});

class AutomaticTitleOrderCreateCustomInput extends PureComponent {

    getSelectedValue(index) {
        const selectedItem = this.props.customFieldsMappings.find(a => a.automaticTitleOrder === index)

        return selectedItem ? selectedItem.fieldName : ''
    }


    onSelect = (index) => (fieldName) => {
        const { onChange, customFieldsMappings } = this.props

        const oldFieldSelected = customFieldsMappings.find(customFieldsMapping => customFieldsMapping.automaticTitleOrder === index)
        oldFieldSelected && onChange(oldFieldSelected.sourceField, null)

        if (fieldName !== null) {
            const fieldSelected = customFieldsMappings.find(customFieldsMapping => customFieldsMapping.fieldName === fieldName)
            onChange(fieldSelected.sourceField, index)
        }
    }


    getOptions(allOptions) {
        const allowedFieldTypes = ['paragraph', 'text', 'checkbox', 'select', 'multiSelect', 'number', 'title', 'date', 'dateTime', 'datatype', 'datatypeUnique', "businessId", "phone", "email", "calculation"]

        const allowedOptions = this.props.customFieldsMappings.filter(field => allowedFieldTypes.includes(field.type))

        const unselectedOptions = allowedOptions
            .filter(field => field.automaticTitleOrder === null || field.automaticTitleOrder === undefined).map(field => field.fieldName)

        return {
            numOfAllOptions: allowedOptions.length,
            unselectedOptions
        }
    }


    render() {
        const { classes,t} = this.props

        const { numOfAllOptions, unselectedOptions } = this.getOptions()

        const maxOptions = 4

        return (
            <Fragment>
                <Divider className={classes.divider} />
                <Typography variant='h4'>{t('Order in automatic title')}</Typography>
                <Grid container className={classes.menuContainer}>
                    {
                        [...Array(Math.min(numOfAllOptions, maxOptions))]
                            .map((e, idx) => (
                                <Grid item className={classes.menuWrapper}>
                                    <MenuWithSearch
                                        label={`Option ${idx + 1}`}
                                        key={idx}
                                        selectedValue={this.getSelectedValue(idx)}
                                        options={unselectedOptions}
                                        onSelect={this.onSelect(idx)}
                                    />
                                </Grid>
                            ))
                    }
                </Grid>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    mappings: state.customInputMapping.currentMapping,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles),
    withTranslation("admin"),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(AutomaticTitleOrderCreateCustomInput);
