import { CircularProgress, Drawer, List, ListItem, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ChatDrawerHeader from './ChatDrawerHeader';
import ChatDrawerListItem from "./chatDrawerListItem";
import { getChatId, GroupMessages, SavedMessages } from '../chatUtils';
import {getDirectMessages, getDirectMessagesActionTypes, getDiscussionsRooms, getDocsUserInCommentsRoomsActionTypes, isDiscussions, updateSavedRoom} from '@tuqqi/common'
import { persistor } from '../../../..';
import { isEqualJson } from '../../../../utils/commonHelpers';
import { ClearIcon, SearchIcon, TextField, debounce, managerGroupChat, managerTableKeepGlobalData } from '@tuqqi/web';
import { withTranslation } from 'react-i18next';
import { SERVER_API } from '../../../../utils/serverAPI';
import { CHAT_GROUP_INFO } from '../chatRecognize';

// Apply some reset
const styles = theme => ({
    drawerPaper: {
        position: 'relative',
        width: 385,
        transition: theme.transitions.create('width'),
        backgroundColor: 'white',
    },
    progress: {
        margin: 'auto'
    },
    listContainer:{
        textTransform: 'capitalize', 
        paddingTop: 0
    },
    fieldCollapsed: {
        visibility: 'hidden',
        pointerEvents: 'none'
    },
    inputWrap: {
        top: 56,
        height: 50,
        padding: '0px 16px 0 16px',
        zIndex: 12,
        position: 'sticky',
        backgroundColor: '#fff',
        paddingTop: 8,
        paddingRight: 16,
        paddingLeft: 16,
    },
    searchIcon: {
        marginLeft: 12,
        display: 'flex',
    },
    input: {
        maxWidth: 296,
        marginTop: 1,
        paddingRight: '28px!important',
        paddingLeft: '6px!important',
    },
    focused: {
        border: '1px solid #7B4FF0!important',
        boxShadow: '0px 0px 4px rgba(123, 79, 240, 0.16)!important',
    },
    rootInput: {
        position: 'relative',
        marginTop: 4,
        background: '#EDEDF0',
        width: '100%',
        border: '1px solid transparent',
        height: 34,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
    },
    clear: {
        cursor: 'pointer',
        position: 'absolute',
        right: 8,
        top: 7
    }
});
const defaultSavedRoom = {
    chat: {chatUId: ""},
        chatType: 'SavedMessages',
        commentText: "",
        commentTime: new Date().toDateString(),
        commentType: null,
        docId: "",
        document: null,
        lastCommentAttachmentType: "default",
        newComments: 0,
        userProfile: null
}

export const proccessSingleDocResponseNew = (response) => {
    return {
        document: {
            title: response.document.title,
            dataType: response.document.dataType,
            source: response.document.source,
            mimeType: response.document.mimeType,
            docId: response.docId,
            tags: response.document.tags ?? response.document.taggedUsers,
            commentsCounter: response.document.commentsCounter
        },
        docId: response.docId,
        commentTime: response.commentTime,
        commentText: response.commentText,
        commentType: null,
        newComments: response.newComments ?? 0,
        lastUserId: response.lastUserId,
        chatType: 'Discussions',
        userProfile: null,
        lastCommentAttachmentType: response.lastCommentAttachmentType ?? 'default',
        chat: {            
            chatUId: ''
        }
    }
}
class ChatDrawer extends Component {
    state = {
        searchValue: '',
        isFocused: false,
    }

    componentDidMount() {
        if(!this.props.savedRoom) {
            persistor.purge().then(_ => {
                this.updateRoom({...defaultSavedRoom, userProfile: this.props.userData.profileData})
            })
            return
        }
        const room = {
            ...defaultSavedRoom,
            userProfile: this.props.userData.profileData
        }
        if(this.props.savedRoom && !isEqualJson(this.props.userData.profileData, this.props.savedRoom.userProfile)) this.props.dispatch(updateSavedRoom(room))
    }
    updateRoom = (room) => {
        this.props.dispatch(updateSavedRoom(room))
    }
    listenSavedMessages(prevProps) {
        if(!this.props.isCorrectFromGroup()) return
        const userId = this.props.userData.profileData && this.props.userData.profileData.auth0_id
        if(!userId) return

        const newSavedMessagesState = this.props.savedMessagesState.data[userId]
        const prevSavedMessagesState = prevProps.savedMessagesState.data[userId]

        if(!newSavedMessagesState || !prevSavedMessagesState) return

        const newSavedMessages = newSavedMessagesState.savedMessages || []
        const prevSavedMessages = prevSavedMessagesState.savedMessages || []

        if(newSavedMessages.length === prevSavedMessages.length) return
        const {item: lastItem} = newSavedMessages.sort((a,b) => new Date(a.item.dateTime).getTime() - new Date(b.item.dateTime).getTime()).slice(-1)[0]

        const updatedRoom = {
            ...this.props.savedRoom,
            commentText: lastItem.messageType === 0 ? (lastItem.comment && lastItem.comment.text || '') : (lastItem.message && lastItem.message.messageText || ''),
            commentTime: lastItem.dateTime
        }
        this.updateRoom(updatedRoom)
    }

    respData = (data = [], dmChats = []) => {
        this.props.dispatch({
            type: getDocsUserInCommentsRoomsActionTypes.success,
            additionalData: {
                messagesPage: 0,
            },
            result: data.map(proccessSingleDocResponseNew)
        })
        this.props.dispatch({
            type: getDirectMessagesActionTypes.success,
            additionalData: {
                messagesPage: 0,
            },
            result: dmChats
        })
    }

    restart = () => {
        if(this.props.isCorrectFromGroup()) {
            managerGroupChat.refetchGroupChats()
            return
        }
        this.props.dispatch(getDiscussionsRooms(SERVER_API)(0))
        this.props.dispatch(getDirectMessages(SERVER_API)(0, {includeGroups: 1}));
    }

    getNewOptions = (val) => {
        const text = val.toLowerCase().trim()

        const dmChats = this.props.results.filter(x => {
            if(!!isDiscussions(x.chatType)) return false
            const title = `${x?.userProfile?.firstname ?? ''} ${x?.userProfile?.lastname ?? ''}`
            const exists = `${title.toLowerCase()}`.includes(text)
            return exists
        })

        if(!text.length) {
            this.restart()
            return
        }

        this.props.chatService.searchInComments(text).then((data) => {
            if(this.props.isCorrectFromGroup()) {
                const dis = (data ?? []).filter(x => x.document?.groupUid === CHAT_GROUP_INFO.groupUid)
                const dms = managerGroupChat.onFilterDms(dmChats ?? [])
                this.respData(dis, dms)
                return
            }
            this.respData(data, dmChats)
        }).catch(err => {
            this.restart()
        })
    }

    debouncedSearch = debounce(this.getNewOptions, 400)

    listenSearchValue = (prevProps, prevState) => {
        if(prevState.searchValue === this.state.searchValue) return

        this.debouncedSearch(this.state.searchValue)
    }

    componentDidUpdate(prevProps, prevState) {
        this.listenSearchValue(prevProps, prevState)
        this.listenSavedMessages(prevProps)
        if(this.props.savedRoom && !isEqualJson(this.props.userData.profileData, this.props.savedRoom.userProfile)) this.props.dispatch(updateSavedRoom({...defaultSavedRoom, userProfile: this.props.userData.profileData}))
    }

    getChatGroupEmpty = () => {
        if(!this.props.isCorrectFromGroup()) return []
        return [{
            chat: {
                chatType: 2,
                chatUId: CHAT_GROUP_INFO.collectionUid,
                creationDateTime: "",
                docId: null,
                id: 0,
                lastModification: "",
                title: null
            },
            chatType: GroupMessages,
            commentText: "",
            commentTime: "",
            docId: "",
            document: null,
            lastUserId: "",
            newComments: 0,
            userProfile: managerTableKeepGlobalData.getUserDetails()
        }]
    }

    getList = () => {
        const { results } = this.props;
        const sortedResults = results.sort((resA, resB) => new Date(resB.commentTime) - new Date(resA.commentTime))
        if(!this.props.isCorrectFromGroup()) {
            return sortedResults
        }

        const cur = sortedResults.find(x => !!CHAT_GROUP_INFO.collectionUid && x?.chat?.chatUId === CHAT_GROUP_INFO.collectionUid)
        const rest = sortedResults.filter(x => x.chatType !== GroupMessages)
        const top = !!cur ? [cur] : this.getChatGroupEmpty()

        return [...top, ...rest]
    }

    renderList = () => {
        const { selected, onClick, savedRoom, openSidebar } = this.props;

        const dataWithSavedRooom = savedRoom && !this.props.isCorrectFromGroup() ? [savedRoom, ...this.getList()] : this.getList()

        return dataWithSavedRooom.map((result, i) => {
            const { chat, docId, chatType } = result;

            return <ChatDrawerListItem 
                isOpenSidebar={openSidebar}
                idx={chatType === SavedMessages ? -1 : getChatId(chatType, chat, docId)} 
                {...result}
                selected={selected}
                onClick={() => onClick(result)}
            />
        })
    }

    renderLoader = () => {
        const { classes } = this.props;

        return <ListItem><CircularProgress className={classes.progress} /></ListItem>
    }

    renderHeader = () => {
        const { onClick, closeAddChatModal, openSidebar, setOpenSidebar } = this.props;

        return <ChatDrawerHeader openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} openChat={onClick} closeAddChatModal={closeAddChatModal} />
    }

    onClear = () => {
        this.setState({searchValue: ''})
    }

    _onBlur = () => {
        this.setState({isFocused: false})
    }

    _onFocus = () => {
        this.setState({isFocused: true})
    }

    onChangeText = (e) => {
        this.setState({searchValue: e?.target?.value ?? ''})
    }
    expand = (e) => {
        this.props.setOpenSidebar(true)
        setTimeout(() => {
            this?.inputRef?.current?.focus?.()
        }, 100)
    }

    inputRef = React.createRef()

    renderSearch = () => {
        const { classes, t, openSidebar } = this.props;
        const { isFocused, searchValue } = this.state;

        const SearchIcon1 = <div data-intrcm-id="DrawerSearchPages_o4ovb444" className={classes.searchIcon}>
            {<SearchIcon />}
        </div>

        const ClearIcon1 = !searchValue.trim() ? null : <div data-intrcm-id="DrawerSearchPages_rm6mjko1" onClick={this.onClear} className={classes.clear}>
            <ClearIcon />
        </div>

        return <div onClick={openSidebar ? undefined : this.expand} data-intrcm-id="UnassignedList_j47rxe52" className={classes.inputWrap}><div data-intrcm-id="UnassignedList_a0hsnr3c" className={`${classes.rootInput} ${isFocused ? classes.focused : ''}`}>
        {SearchIcon1}
        <TextField
            inputRef={this.inputRef}
            value={searchValue}
            onChange={this.onChangeText}
            onBlur={this._onBlur}
            onFocus={this._onFocus}
            InputProps={{disableUnderline: true}}
            inputProps={{ 'data-testid': 'autocomplete-input', className: `${classes.input} ${!openSidebar ? classes.fieldCollapsed: ''}`, dir: 'auto' }}
            autoFocus={false}
            placeholder={t("Search a chat")}
            fullWidth />
        {ClearIcon1}
    </div></div>
    }

    render() {
        const { classes, className, loading, openSidebar } = this.props;
        const paperStyle = openSidebar ? {} : { width: 80 }
        return (
            <Drawer
                className={className}
                variant={"persistent"}
                open={true}
                PaperProps={{style: paperStyle}}
                classes={{ paper: classes.drawerPaper }}>
                
                <List className={classes.listContainer}>
                    {this.renderHeader()}        
                    {this.renderSearch()}
                    { loading ? this.renderLoader() : this.renderList() }
                </List>
            </Drawer>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    globalData: state.globalData,
    savedRoom: state.data.chatData.chatFeed.savedRoom,
    savedMessagesState: state.data.chatData.savedMessages,
    ...state.wrapper,
    channel: state.results.channel,
    searchQuery: state.search,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ChatDrawer);