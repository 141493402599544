import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from "react";
import { parseImageUrl } from "@tuqqi/common";
import { managerTableKeepGlobalData } from '@tuqqi/web';

const useStyles = makeStyles((theme) => ({
    iconWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%'
    },
    iconInner: {
        filter: 'brightness(0.8) contrast(100%)',
        'mix-blend-mode': 'luminosity',
    },
    persons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    avatar: {
        objectFit: 'cover',
        display: 'flex',
        backgroundColor: '#F2F4F7',
        alignItems: 'center',
        justifyContent: 'center',
    },
    more: {
        paddingRight: 4,
        paddingLeft: 4,
        color: '#475467',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: 0.4,
    },
    size: {
        width: 25,
        height: 25,
        borderRadius: 25 + 2,
        border: '2px solid #ffffff',
    },
    sizeMore: {
        backgroundColor: '#F2F4F7',
        minWidth: 25,
        width: 'auto'
    },
    inputWrapPointer: {
        cursor: 'pointer'
    },
    valueWrap: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
    },
    valueWrapOuter: {
        height: 'inherit',
        display: 'flex',
        overflow: 'hidden',
        marginTop: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 'auto',
    },
}))


const showLen = 5

export const Faces = (props) => {
    const classes = useStyles()
    const {value} = props
    const cloudfront = managerTableKeepGlobalData.getCloudfront()
    const offset = 6

    const renderPerson = useCallback((person, i) => {
        let personLink = person.url
        const userLink = personLink && !!cloudfront ? parseImageUrl(cloudfront)(personLink) : ''

        const avatarProps = {
            key: person.uid + i,
            className: `${classes.avatar} ${classes.size}`,
            style: {
                marginLeft: i === 0 ? 0 : -1 * offset,
                zIndex: i
            }
        }
        return !userLink ? <div data-intrcm-id="MembersFromInput_x5x820hy" {...avatarProps} /> : <img {...avatarProps} alt="person" src={userLink} />
    }, [cloudfront])


    const renderMore = useCallback(() => {
        const avatarProps = {
            className: `${classes.avatar} ${classes.size} ${classes.sizeMore}`,
            style: {
                marginLeft: -1 * offset
            }
        }

        return <div data-intrcm-id="MembersFromInput_ct6a85uk" {...avatarProps} >
            <div data-intrcm-id="MembersFromInput_njwlirsk" className={classes.more} >{`+${value.length - value.slice(0, showLen).length}`}</div>
        </div>
    }, [value, value.length])

    const Members = !value.length ? null : (
        <div data-intrcm-id="MembersFromInput_c1nitdqk" className={`${classes.persons}`}>
            {value.slice(0, showLen).map((person, i) => person.isTeam ? null : renderPerson(person, i))}
            {value.length > value.slice(0, showLen).length ? renderMore() : null}
        </div>
    )

    const Value = <div data-intrcm-id="MembersFromInput_yxk981lf" className={`${classes.valueWrapOuter} ${classes.inputWrapPointer}`}>
        <div data-intrcm-id="MembersFromInput_qbk7ambh" className={`${classes.valueWrap}`} >
            {Members}
        </div>
    </div>
  

    return Value
}