import { Tooltip, withStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'

const styles = (theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 28,
        lineHeight: 'normal',
        color: '#21212C'
    },
    btns: {
        marginLeft: 16,
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'middle',
    },
    selectedBtn: {
        backgroundColor: `${theme.customColors.primary.main}!important`,
        color: '#fff!important',
    },
    middle: {
        borderRadius: '0px!important',
    },
    left: {
        borderTopRightRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
    },
    right: {
        borderTopLeftRadius: '0px !important',
        borderBottomLeftRadius: '0px !important',
    },
    btn: {
        paddingRight: 12,
        paddingLeft: 12,
        height: 36,
        width: 'auto',
        color: `${theme.customColors.primary.main}`,
        border: `1px solid ${theme.customColors.primary.main}!important`,
        borderRadius: 6,
        fontFamily: 'Rubik',
        fontSize: 16,
        lineHeight: 1.5,
        cursor: 'pointer',
        backgroundColor: '#fff',
        textTransform: 'capitalize',
    },
    tooltip: {
        borderRadius: 4,
        marginTop: 10,
        backgroundColor: '#4A4A4F',
    },
    triangle: {
        position: 'absolute',
        top: 1,
        left: '50%',
        marginTop: -3.5,
        transform: 'rotate(45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
    tooltipeTitle: {
        fontSize: 12,
        fontfamily: 'Rubik',
        lineHeight: '16px',
        color: '#FFFFFF',
    },
})
const UserOngoingHeader = (props) => {
    const {classes, setSortBy, sortBy} = props
    const [t] = useTranslation('userOngoing')

    const onClick = (type) => {
        setSortBy(type)
    }

    const isSelected = (type) => {
        return type === sortBy
    }

    const renderTitle = (text) => {
        return <>
            <div data-intrcm-id="OngoingHeader_0h090oc7" className={classes.tooltipeTitle}>{text}</div>
            <div data-intrcm-id="OngoingHeader_dpmd33aw" className={classes.triangle}></div>
        </>
    }

    const Buttons = <div data-intrcm-id="OngoingHeader_3760mtp7" className={`${classes.btns} ongoing-btns`}>
        <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={renderTitle(t(`Sort by due date (closest first)`))} placement="bottom">
            <button onClick={() => onClick('dueDate')} type="button" aria-pressed="false" className={`${classes.btn} ${classes.left} ${isSelected('dueDate') ? classes.selectedBtn : ''}`}>{t("Due date")}</button>
        </Tooltip>
        <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={renderTitle(t(`Sort by rating (highest first)`))} placement="bottom">
            <button onClick={() => onClick('rating')} type="button" aria-pressed="false" className={`${classes.btn} ${classes.middle} ${isSelected('rating') ? classes.selectedBtn : ''}`}>{t("Rating")}</button>
        </Tooltip>
        <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={renderTitle(t(`Sort by status (red first)`))} placement="bottom">
            <button onClick={() => onClick('status')} type="button" aria-pressed="false" className={`${classes.btn} ${classes.right} ${isSelected('status') ? classes.selectedBtn : ''}`}>{t("Status")}</button>
        </Tooltip>
    </div>

    return (
        <div data-intrcm-id="OngoingHeader_lijp5xrc" className={classes.container}>
            <div data-intrcm-id="OngoingHeader_hoqbbg3a" className={classes.title}>{t('Ongoing task', 'Ongoing task')}</div>
            {Buttons}
        </div>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(UserOngoingHeader)