import React, { PureComponent } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Linkify from 'react-linkify';

const styles = theme => ({
    postText: {
        display:'block',
        width: '100%',
        padding: '11px 16px',
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#1d1d1d",
        whiteSpace: 'pre-line',
    }
});

class PostText extends PureComponent {

    allowedDataTypes = [
        'media', 'bookmark', 'file', 'post', 'poll'
    ]

    showPostText = ()=> {
        const { postText, dataType, isFullView } = this.props;

        let show;
        if (!postText || !this.allowedDataTypes.includes(dataType)) {
            show = false
        } else{
            show = true
        }

        return show
    }

    render() {
        const { classes, postText, dataType, isFullView } = this.props;

        if(!this.showPostText()){
            return null
        }

        const  newPostText = postText.length < 280 ? postText : (isFullView ? postText : (postText.substring(0, 280) + "..."))

        // split rows in order that every row could be seperated dirction
        const rows = newPostText.split('\n')


        return (
        <Grid container className={`${classes.postText} post-text`}>
            <Linkify style={{display:'block'}}  properties={{dir:'auto', target: '_blank', style: { "textOverflow": "ellipsis", wordBreak: 'break-all', color: 'blue'  } }}>
                <div data-intrcm-id="PostText_e4kd51jt" style={{width:'100%'}}>{rows.map((row, idx) => <div data-intrcm-id="PostText_2ftmjufl" key={idx} dir="auto">{row}</div>)}</div>
            </Linkify>
        </Grid >)
    }
}

const mapStateToProps = (state) => ({
    isFullView: state.fullView.open
})

export default compose(withStyles(styles),
    connect(mapStateToProps, null))(PostText);
