import React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';
// Base components
import App from './ui/components/App/app';
import NewOrganization from './ui/components/App/NewOrganization';
import Organization from './ui/components/App/organization';
import ListArticles from "./ui/components/Knowledge/listArticles";
import CreateCustomInputTemplate from './ui/views/admin/customInputTemplates/pages/createCustomInputTemplate';
//Custom Input Mapping
import CustomInputTemplates from './ui/views/admin/customInputTemplates/pages/customInputTemplates';
import EditDefaultInputTemplate from './ui/views/admin/customInputTemplates/pages/EditDefaultInputTemplate';
// Admin pages
import Dashboard from './ui/views/admin/dashboard';
import CreateIntegration from './ui/views/admin/Integrations/CreateIntegration';
import EditIntegration from './ui/views/admin/Integrations/EditIntegration';
import IntegrationsList from './ui/views/admin/Integrations/IntegrationsList';
import AdminScoreBoard from './ui/views/admin/Scoreboard/adminScoreBoard';
import NewChallenge from './ui/views/admin/Scoreboard/Challenge/newChallenge';
import Tasks from './ui/views/admin/Tasks/TasksReport';
import AdminUserTags from './ui/views/admin/UserTags/adminUserTags';
import Chat from './ui/views/chat/chat';
// Organization views
import FeedPage from './ui/views/feed/feedPage';
// import NewFeedPage from './ui/views/feed/newFeedPage';
import GroupCalendarView from './ui/views/groups/groupCalendarNewView';
// import GroupCalendarView from './ui/views/groups/groupCalendarView';
import GroupDashboardView from './ui/views/groups/groupDashboardView';
import GroupFeedView from './ui/views/groups/GroupFeed/GroupFeedView';
import GroupGanttView from './ui/views/groups/groupGanttView';
import GroupGanttViewNew from './ui/views/groups/groupGanttViewNew';
// import GroupKanbanView from './ui/views/groups/groupKanban';
import GroupConnectorsViewNew from './ui/views/groups/Connectors/groupConnectorsNew';
import GroupKnowledge from "./ui/views/groups/groupKnowledge";
import GroupMapView from './ui/views/groups/groupMapView';
import GroupKanbanViewNew from './ui/views/groups/Kanban/groupKanbanNew';
import GroupSettingsViewNew from './ui/views/groups/Settings/groupSettingsNew';
// Group views
import GroupPage from './ui/views/groups/groupView';
import ManageGroups from './ui/views/groups/manageGroups';
// Library
import NewLibrary from './ui/views/knowledge/newLibrary';
import OrgCharts from './ui/views/knowledge/OrgCharts/OrgCharts';
import Scoreboard from './ui/views/knowledge/scoreboard';
import NoMobileSupport from './ui/views/messages/pages/noMobileSupport';
import NewOrgSch from './ui/views/orgScheduler/OrgSchedulerView';
// import MyPageCalendar from './ui/views/mypage/MyPageCalendar';
import MyPageCalendar from './ui/views/mypage/MyPageCalendarNew';
import MyPageDashboardView from './ui/views/mypage/MyPageDashboard';
import MyPageFavorites from './ui/views/mypage/MyPageFavorites';
import MyPageFeed from './ui/views/mypage/MyPageFeed';
import MyPageMap from './ui/views/mypage/MyPageMap';
import MyPageOngoing from './ui/views/mypage/MyPageOngoing';
import { default as AllTasksView, default as MyPageTasks } from './ui/views/mypage/MyPageTasks';
import MyPageView from './ui/views/mypage/MyPageView';
import NewOrganizationPage from './ui/views/NewOrganization/NewOrganizationPage';
import SearchPageAll from './ui/views/search/SearchNavigation/SearchPageAll';
import SearchPageBookmark from './ui/views/search/SearchNavigation/SearchPageBookmark';
import SearchPageDatatype from './ui/views/search/SearchNavigation/SearchPageDatatype';
import SearchPageFiles from './ui/views/search/SearchNavigation/SearchPageFiles';
import SearchPageMap from './ui/views/search/SearchNavigation/SearchPageMap';
import SearchPageMedia from './ui/views/search/SearchNavigation/SearchPageMedia';
import SearchPagePost from './ui/views/search/SearchNavigation/SearchPagePost';
import SearchPageView from './ui/views/search/SearchNavigation/SearchPageView';
import ClassificationsGroups from './ui/views/settings/classificationsgroups';
import QueryViews from './ui/views/settings/queryViews';
import UserDashboard from './ui/views/settings/userDashboard';
// Sign up pages 
// import SignUp from './ui/views/signup/signup';
import Verify from './ui/views/signup/verify';
import TagPageFeed from './ui/views/tag/TagPageFeed';
import TagPageView from './ui/views/tag/TagPageView';
import UserPageAll from './ui/views/user/UserPageAll';
// import UserPageCalendar from './ui/views/user/UserPageCalendar';
import OrgCalendarView from './ui/views/orgCalendar/OrgCalendarView';
import UserPageCalendar from './ui/views/user/UserPageCalendarNew';
import UserPageStatistics from './ui/views/user/UserPageStatistics';
import UserPageTasks from './ui/views/user/UserPageTasks';
import UserPageView from './ui/views/user/UserPageView';
import { requireAuthentication } from './utils/auth/authCheck';
// Authentication services
import { OrganizationProfile } from '@tuqqi/web';
import AdminMainNew from './ui/views/admin/newway/AdminMainNew';
import AdminNew from './ui/views/admin/newway/AdminNew';
import LogsView from './ui/views/admin/newway/LogsView';
import ManageTeams from './ui/views/admin/newway/ManageTeams';
import ManageUsersView from './ui/views/admin/newway/ManageUsersView';

import RecycleBinView from './ui/views/admin/newway/RecycleBinView';
import GroupChatView from './ui/views/groups/chat/GroupChatView';
import CreateGroupView from './ui/views/groups/CreateGroup/CreateGroupView';
import GroupHomeView from './ui/views/groups/Home/groupHomeView';
import GroupSchedulerNewView from './ui/views/groups/scheduler/GroupSchedulerNewView';
import GroupTableNewView from './ui/views/groups/tableNew/GroupTableNewView';
import MyPageNotificationsView from './ui/views/mypage/MyNotificationsView';
import MyPageActionCenter from './ui/views/mypage/MyPageActionCenter';
import MyPageProfile from './ui/views/mypage/MyPageProfile';
import { FeedNavigationSettings } from './ui/views/settings/forms/feedNavigation/feedNavigationSettings';
import { FeedNavigationSettingsItem } from './ui/views/settings/forms/feedNavigation/FeedNavigationSettingsItem';
import AuthHandler from './utils/auth/authHandler';
import { isGanttNew } from './utils/ganttSwitcher';
// Admin new 
import BillingView from './ui/views/admin/newway/BillingView';
import ImportantContentView from './ui/views/admin/newway/ImportantContentView';
import MenuView from './ui/views/admin/newway/MenuView';
import OrganizationProfileView from './ui/views/admin/newway/OrganizationProfileView';

const normalRouting = () => <Route path="/" component={App} onChange={() => window.scrollTo(0, 0)} >
    <Route component={requireAuthentication(Organization)}>
        <IndexRoute component={FeedPage} />
        <Route path="chat" component={Chat} />
        <Route path="feed" component={FeedPage} />
        {/* <Route path="settings" component={UserSettingsPage} /> */}
        <Route path="userdashboard" component={UserDashboard} />
        <Route path="classificationsgroups" component={ClassificationsGroups} />
        <Route path="queryviews" component={QueryViews} />
        <Route path="managegroups" component={ManageGroups} />
        {/* <Route path="notifications" component={NotificationSettings} /> */}
        
        <Route path="scoreboard" component={Scoreboard} />
        <Route path="orgcalendarview" component={OrgCalendarView} />

        <Route path="search" component={SearchPageView}>
            <IndexRoute component={SearchPageAll} />
            <Route path="all" component={SearchPageAll} />
            <Route path="media" component={SearchPageMedia} />
            <Route path="file" component={SearchPageFiles} />
            <Route path="post" component={SearchPagePost} />
            <Route path="bookmark" component={SearchPageBookmark} />
            <Route path="location" component={SearchPageMap} />
            <Route path="*" component={SearchPageDatatype} />
        </Route>

        <Route path="tag" component={TagPageView}>
            <IndexRoute component={TagPageFeed} />
            <Route path="feed" component={TagPageFeed} />    
        </Route>

        <Route path="user" component={UserPageView}>
            <IndexRoute component={UserPageAll} />
            <Route path="overview" component={UserPageAll} />
            <Route path="calendar" component={UserPageCalendar} />
            <Route path="ongoing" component={UserPageTasks} />
            <Route path="statistics" component={UserPageStatistics} />       
        </Route>

        <Route path="orgchart" component={OrgCharts} />
        <Route path="tasks" component={AllTasksView} />

        <Route path="library" component={NewLibrary}  >
            <IndexRoute component={NewLibrary} />
        </Route>
        <Route path="orgscheduler" component={NewOrgSch}  >
            <IndexRoute component={NewOrgSch} />
        </Route>

        <Route path="group" component={GroupPage}  >
            <IndexRoute component={GroupFeedView} />
            <Route path="feed" component={GroupFeedView} />
            <Route path="home" component={GroupHomeView} />
            <Route path="kanban" component={GroupKanbanViewNew} />
            <Route path="chat" component={GroupChatView} />
            <Route path="settings" component={GroupSettingsViewNew} />
            <Route path="connectors" component={GroupConnectorsViewNew} />
            {/* <Route path="table" component={GroupTableView} /> */}
            <Route path="table" component={GroupTableNewView} />
            <Route path="scheduler" component={GroupSchedulerNewView} />
            <Route path="dashboard" component={GroupDashboardView} />
            <Route path="calendar" component={GroupCalendarView} />
            <Route path="gantt" component={isGanttNew ? GroupGanttViewNew : GroupGanttView} />
            <Route path="map" component={GroupMapView} />
            <Route path="knowledge" component={GroupKnowledge} />
            <Route path="category/:id" component={ListArticles} />
        </Route>
        <Route path="creategroup" component={CreateGroupView} />

        <Route path="mypage" component={MyPageView}  >
            <IndexRoute component={MyPageFeed} />
            <Route path="feed" component={MyPageFeed} />
            <Route path="favorites" component={MyPageFavorites} />
            <Route path="actioncenter" component={MyPageActionCenter} />
            <Route path="tasks" component={MyPageTasks} />
            <Route path="ongoing" component={MyPageOngoing} />
            <Route path="dashboard" component={MyPageDashboardView} />
            <Route path="map" component={MyPageMap} />
            <Route path="notifications" component={MyPageNotificationsView} />
            <Route path="calendar" component={MyPageCalendar} />
            <Route path="profile" component={MyPageProfile} />
        </Route>
        <Route component={AdminNew} path="admin">
            <IndexRoute component={AdminMainNew} />
            <Route path="users">
                <IndexRoute component={ManageUsersView} />
                <Route path="edit" component={ManageUsersView} />
            </Route>
            <Route path="teams">
                <IndexRoute component={ManageTeams} />
                <Route path='*' component={ManageTeams} />
                {/* <Route path="create-team" component={ManageTeams} /> */}
            </Route>
            <Route path="archive" component={RecycleBinView} />
            <Route path="logs" component={LogsView} />
            <Route path="dashboards" component={Dashboard} />
            <Route path="timetracking" component={Tasks} />
            <Route path="billing" component={BillingView} />
			<Route path="important-content" component={ImportantContentView} />
			<Route path="organizationsettings" component={OrganizationProfileView} />
			<Route path="menu" component={MenuView} />
			<Route path="analytics" component={Dashboard} />
            <Route path="custominputmappings" >
                <IndexRoute component={CustomInputTemplates} />
                <Route path="manage" component={CustomInputTemplates} />
                <Route path="create" component={CreateCustomInputTemplate} />
                <Route path="edit-default-input-template/:dataType" component={EditDefaultInputTemplate} />
            </Route>
            <Route path="topics_and_usertags" component={AdminUserTags} />
            <Route path="scoreBoardSettings">
                <IndexRoute component={AdminScoreBoard} />
                <Route path="newChallenge/:type/:id" component={NewChallenge} />
            </Route>
            <Route path="navigation">
                <IndexRoute component={FeedNavigationSettings} />
                <Route path="create-feed-navigation-item" component={FeedNavigationSettingsItem} />
                <Route path="edit-feed-navigation-item" component={FeedNavigationSettingsItem} />
            </Route>
            <Route path="integrations">
                <IndexRoute component={IntegrationsList} />
                <Route path="manage" component={IntegrationsList} />
                <Route path="create" component={CreateIntegration} />
                <Route path="edit" component={EditIntegration} />
            </Route>
        </Route>

      
        {/* <Route component={OrganizationAdmin} path="admin"   >
            <IndexRoute component={AdminHome} />
            <Route path="dashboard" component={Dashboard} />
            <Route path="custominputmappings">
                <IndexRoute component={CustomInputTemplates} />
                <Route path="manage" component={CustomInputTemplates} />
                <Route path="create" component={CreateCustomInputTemplate} />
                <Route path="edit-default-input-template/:dataType" component={EditDefaultInputTemplate} />
            </Route>
            <Route path="sources">
                <IndexRoute component={Sources} />
                <Route path="manage" component={Sources} />
                <Route path="create" component={CreateSource} />
                <Route path="edit" component={EditUserSourceMapping} />
            </Route>
            <Route path="classifications">
                <IndexRoute component={ClassificationsMapping} />
                <Route path="manage" component={ClassificationsMapping} />
                <Route path="create" component={CreateClassification} />
                <Route path="edit" component={EditClassification} />
            </Route>
            <Route path="certifications">
                <IndexRoute component={CertificationsList} />
                <Route path="manage" component={CertificationsList} />
                <Route path="create" component={CreateCertification} />
                <Route path="edit" component={EditCertification} />
            </Route>
            <Route path="integrations">
                <IndexRoute component={IntegrationsList} />
                <Route path="manage" component={IntegrationsList} />
                <Route path="create" component={CreateIntegration} />
                <Route path="edit" component={EditIntegration} />
            </Route>
            <Route path="users">
                <IndexRoute component={UsersManagement} />
                <Route path="manage" component={UsersManagement} />
                <Route path="edit" component={UserManagement} />
            </Route>

            <Route path="organizationsettings">
                <IndexRoute component={OrganizationSettings} />
                <Route path="create-feed-navigation-item" component={FeedNavigationSettingsItem} />
                <Route path="edit-feed-navigation-item" component={FeedNavigationSettingsItem} />
            </Route>
            <Route path="archive" component={Archive} />
            <Route path="billing" component={Subscription} />
            <Route path="timetracking" component={Tasks} />
            <Route path="premium" component={Ashtrom} />
            <Route path="tuqqi" component={TuqqiReport} />
            <Route path="graph" component={Graph} />
            <Route path="logs" component={Logs} />
            <Route path="scoreBoardSettings">
                <IndexRoute component={AdminScoreBoard} />
                <Route path="newChallenge/:type/:id" component={NewChallenge} />
            </Route>
            <Route path="topics_and_usertags" component={AdminUserTags} />
        </Route> */}
    </Route>

    {/* <Route path='signup' component={SignUp} /> */}
    <Route path='verify' component={Verify} />
    <Route path='callback' component={AuthHandler} />
    <Redirect from='*' to='feed' />
</Route>

const createOrganization = (user) => <Route path="/" component={App}  >
    <Route component={requireAuthentication(NewOrganization, { user })}>
        <IndexRoute component={NewOrganizationPage} />
        <Redirect from='*' to='/' />
    </Route>
    <Route path='callback' component={AuthHandler} />
</Route>

const Routing = ({ isMobile, user }) => {
    var mobileRouting = <Route path="/" component={App}  >
        <IndexRoute component={NoMobileSupport} />
        {/* <Route path='signup' component={SignUp} /> */}
        <Route path='verify' component={Verify} />
        <Route path='*' component={NoMobileSupport} />

    </Route>

    if (isMobile) {
        return mobileRouting
    }

    // if(!user && !user.verifiedMail){
    //     return <Route path="/" component={App} >
    //         <IndexRoute component={requireAuthentication(Verify)} />
    //         {/* <Redirect from='*' to='/' /> */}
    //     </Route>
    // }

    if (user && !user.org) {
        return createOrganization(user)
    }

    return normalRouting()
}

export default Routing; 
