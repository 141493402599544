import React, { Component, createRef } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { Chip, Grid, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import CancelIcon from '../../../ui/components/Common/Icons/SearchIcons/CancelIcon'
import { SearchlIcon } from './SearchIcon.js';
// Apply some reset
const styles = theme => ({
  wrapperExpanded: {
    minWidth: '318px!important',
    width: '24vw!important',
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    borderRadius: 4,
    height:32,
    minWidth: '14vw',
    width: '14vw',
    '& $input': {
      transition: theme.transitions.create('width'),
    },
    flexWrap: 'unset',
    color: '#ffffff'
  },
  search: {
    paddingLeft: 12,
    paddingRight: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    "&:hover": {
      backgroundColor: 'transparent',
    }
  },
  inputExpanded: {
    maxWidth: "calc(24vw - 100px)!important",
  },
  input: {
    maxWidth: "calc(14vw - 100px)",
    font: 'inherit',
    marginLeft: 3,
    padding: 0,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: 'inherit',
    height: 32,
    "fontFamily": '"Rubik"',
    "fontSize": "14px",
    "fontWeight": "normal",
    "fontStretch": "normal",
    "fontStyle": "normal",
    "lineHeight": "1.75",
    "letterSpacing": "0.5px",
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      "fontFamily": 'Rubik',
      "fontSize": "14px",
      "fontWeight": "normal",
      "fontStretch": "normal",
      "fontStyle": "normal",
      "lineHeight": "20px",
      "color": '#FFFFFF52',
    }
  },
  chipGroup: { 
    cursor: "pointer" ,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 1,
    marginRight: 4,
    marginLeft: 4,
    height: 24,
    backgroundColor: '#6366F1',
    borderRadius: 6,
   
  },
  chipMy: {
    cursor: "pointer" ,
    marginBottom: 1,
    marginRight: 2,
    height: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 6,
  },
  chipText: {
    // marginBottom: -2,
    fontFamily: 'Rubik', 
    fontSize: 14, 
    letterSpacing: 0.4,
    color: theme.customColors.primary.main, 
    lineHeight: '16px',
  },
  chipTextPX: {
    // marginBottom: -2,
    fontFamily: 'Rubik', 
    fontSize: 13, 
    fontWeight: '500',
    color: "#fff", 
    lineHeight: '16px',
  },
  cancelIconContainer: {
    width: 15, 
    height: 15,
    marginLeft: -10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const KeyHelpers = () => {
  return <svg width="50" height="27" viewBox="0 0 50 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="1" width="20" height="20" rx="5" fill="white" fill-opacity="0.03"/>
  <path d="M3.32222 18.3834L3.54497 18.27L3.32222 18.3835C3.82555 19.3713 4.6287 20.1744 5.61655 20.6778C6.19245 20.9712 6.84643 21.1117 7.69565 21.181C8.53958 21.25 9.59805 21.25 10.9883 21.25H11H15H15.0117C16.402 21.25 17.4604 21.25 18.3043 21.181C19.1536 21.1117 19.8076 20.9712 20.3834 20.6778C21.3713 20.1744 22.1744 19.3713 22.6778 18.3834C22.9712 17.8076 23.1117 17.1536 23.181 16.3043C23.25 15.4604 23.25 14.402 23.25 13.0117V13V9V8.98828C23.25 7.59805 23.25 6.53958 23.181 5.69565C23.1117 4.84643 22.9712 4.19245 22.6778 3.61655C22.1744 2.6287 21.3713 1.82555 20.3835 1.32222L20.27 1.54497L20.3834 1.32222C19.8076 1.02878 19.1536 0.888335 18.3043 0.818951C17.4604 0.749999 16.4019 0.749999 15.0117 0.75H15H11H10.9883C9.59805 0.749999 8.53959 0.749999 7.69565 0.818951C6.84643 0.888335 6.19245 1.02878 5.61655 1.32222C4.6287 1.82555 3.82555 2.6287 3.32222 3.61655C3.02878 4.19245 2.88834 4.84643 2.81895 5.69565C2.75 6.53959 2.75 7.59805 2.75 8.98829V9V13V13.0117C2.75 14.4019 2.75 15.4604 2.81895 16.3043C2.88834 17.1536 3.02878 17.8076 3.32222 18.3834Z" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
  <path d="M3.5 9C3.5 7.59162 3.50039 6.5654 3.56646 5.75673C3.63205 4.95389 3.75989 4.40959 3.99047 3.95704C4.4219 3.11031 5.11031 2.4219 5.95704 1.99047C6.40959 1.75989 6.95389 1.63205 7.75673 1.56646C8.5654 1.50039 9.59162 1.5 11 1.5H15C16.4084 1.5 17.4346 1.50039 18.2433 1.56646C19.0461 1.63205 19.5904 1.75989 20.043 1.99047C20.8897 2.4219 21.5781 3.11031 22.0095 3.95704C22.2401 4.40959 22.3679 4.95389 22.4335 5.75673C22.4996 6.5654 22.5 7.59162 22.5 9V13C22.5 14.4084 22.4996 15.4346 22.4335 16.2433C22.3679 17.0461 22.2401 17.5904 22.0095 18.043C21.5781 18.8897 20.8897 19.5781 20.043 20.0095C19.5904 20.2401 19.0461 20.3679 18.2433 20.4335C17.4346 20.4996 16.4084 20.5 15 20.5H11C9.59162 20.5 8.5654 20.4996 7.75673 20.4335C6.95389 20.3679 6.40959 20.2401 5.95704 20.0095C5.11031 19.5781 4.4219 18.8897 3.99047 18.043C3.75989 17.5904 3.63205 17.0461 3.56646 16.2433C3.50039 15.4346 3.5 14.4084 3.5 13V9Z" stroke="#B7B8BD" stroke-opacity="0.07"/>
  <g filter="url(#filter0_dddii_780_71094)">
  <path d="M3 9C3 6.19974 3 4.79961 3.54497 3.73005C4.02433 2.78924 4.78924 2.02433 5.73005 1.54497C6.79961 1 8.19974 1 11 1H15C17.8003 1 19.2004 1 20.27 1.54497C21.2108 2.02433 21.9757 2.78924 22.455 3.73005C23 4.79961 23 6.19974 23 9V13C23 15.8003 23 17.2004 22.455 18.27C21.9757 19.2108 21.2108 19.9757 20.27 20.455C19.2004 21 17.8003 21 15 21H11C8.19974 21 6.79961 21 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3 17.2004 3 15.8003 3 13V9Z" fill="black" fill-opacity="0.01" shape-rendering="crispEdges"/>
  </g>
  <path d="M10.5613 15C9.44528 15 8.72528 14.292 8.72528 13.188C8.72528 12.024 9.42128 11.34 10.7893 11.34H11.4973V10.02H10.7893C9.42128 10.02 8.72528 9.336 8.72528 8.172C8.72528 7.068 9.44528 6.36 10.5613 6.36C11.9053 6.36 12.3373 7.2 12.3373 8.208V9.204H13.6573V8.208C13.6573 7.2 14.0893 6.36 15.4333 6.36C16.5493 6.36 17.2693 7.068 17.2693 8.172C17.2693 9.336 16.5733 10.02 15.2053 10.02H14.4973V11.34H15.2053C16.5733 11.34 17.2693 12.024 17.2693 13.188C17.2693 14.292 16.5493 15 15.4333 15C14.0893 15 13.6573 14.16 13.6573 13.152V12.156H12.3373V13.152C12.3373 14.16 11.9053 15 10.5613 15ZM14.4973 8.184V9.204H15.2053C16.0573 9.204 16.4173 8.856 16.4173 8.172C16.4173 7.464 15.9973 7.188 15.4333 7.188C14.8093 7.188 14.4973 7.572 14.4973 8.184ZM10.7893 9.204H11.4973V8.184C11.4973 7.572 11.1853 7.188 10.5613 7.188C9.99728 7.188 9.57728 7.464 9.57728 8.172C9.57728 8.856 9.93728 9.204 10.7893 9.204ZM12.3373 11.34H13.6573V10.02H12.3373V11.34ZM10.5613 14.172C11.1853 14.172 11.4973 13.788 11.4973 13.176V12.156H10.7893C9.93728 12.156 9.57728 12.504 9.57728 13.188C9.57728 13.896 9.99728 14.172 10.5613 14.172ZM14.4973 13.176C14.4973 13.788 14.8093 14.172 15.4333 14.172C15.9973 14.172 16.4173 13.896 16.4173 13.188C16.4173 12.504 16.0573 12.156 15.2053 12.156H14.4973V13.176Z" fill="white" fill-opacity="0.56"/>
  <rect x="27" y="1" width="20" height="20" rx="5" fill="white" fill-opacity="0.03"/>
  <path d="M27.3222 18.3834L27.545 18.27L27.3222 18.3835C27.8256 19.3713 28.6287 20.1744 29.6165 20.6778C30.1924 20.9712 30.8464 21.1117 31.6957 21.181C32.5396 21.25 33.598 21.25 34.9883 21.25H35H39H39.0117C40.402 21.25 41.4604 21.25 42.3043 21.181C43.1536 21.1117 43.8076 20.9712 44.3834 20.6778C45.3713 20.1744 46.1744 19.3713 46.6778 18.3834C46.9712 17.8076 47.1117 17.1536 47.181 16.3043C47.25 15.4604 47.25 14.402 47.25 13.0117V13V9V8.98828C47.25 7.59805 47.25 6.53958 47.181 5.69565C47.1117 4.84643 46.9712 4.19245 46.6778 3.61655C46.1744 2.6287 45.3713 1.82555 44.3835 1.32222L44.27 1.54497L44.3834 1.32222C43.8076 1.02878 43.1536 0.888335 42.3043 0.818951C41.4604 0.749999 40.4019 0.749999 39.0117 0.75H39H35H34.9883C33.5981 0.749999 32.5396 0.749999 31.6957 0.818951C30.8464 0.888335 30.1924 1.02878 29.6165 1.32222C28.6287 1.82555 27.8256 2.6287 27.3222 3.61655C27.0288 4.19245 26.8883 4.84643 26.819 5.69565C26.75 6.53959 26.75 7.59805 26.75 8.98829V9V13V13.0117C26.75 14.4019 26.75 15.4604 26.819 16.3043C26.8883 17.1536 27.0288 17.8076 27.3222 18.3834Z" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
  <path d="M27.5 9C27.5 7.59162 27.5004 6.5654 27.5665 5.75673C27.6321 4.95389 27.7599 4.40959 27.9905 3.95704C28.4219 3.11031 29.1103 2.4219 29.957 1.99047C30.4096 1.75989 30.9539 1.63205 31.7567 1.56646C32.5654 1.50039 33.5916 1.5 35 1.5H39C40.4084 1.5 41.4346 1.50039 42.2433 1.56646C43.0461 1.63205 43.5904 1.75989 44.043 1.99047C44.8897 2.4219 45.5781 3.11031 46.0095 3.95704C46.2401 4.40959 46.3679 4.95389 46.4335 5.75673C46.4996 6.5654 46.5 7.59162 46.5 9V13C46.5 14.4084 46.4996 15.4346 46.4335 16.2433C46.3679 17.0461 46.2401 17.5904 46.0095 18.043C45.5781 18.8897 44.8897 19.5781 44.043 20.0095C43.5904 20.2401 43.0461 20.3679 42.2433 20.4335C41.4346 20.4996 40.4084 20.5 39 20.5H35C33.5916 20.5 32.5654 20.4996 31.7567 20.4335C30.9539 20.3679 30.4096 20.2401 29.957 20.0095C29.1103 19.5781 28.4219 18.8897 27.9905 18.043C27.7599 17.5904 27.6321 17.0461 27.5665 16.2433C27.5004 15.4346 27.5 14.4084 27.5 13V9Z" stroke="#B7B8BD" stroke-opacity="0.07"/>
  <g filter="url(#filter1_dddii_780_71094)">
  <path d="M27 9C27 6.19974 27 4.79961 27.545 3.73005C28.0243 2.78924 28.7892 2.02433 29.73 1.54497C30.7996 1 32.1997 1 35 1H39C41.8003 1 43.2004 1 44.27 1.54497C45.2108 2.02433 45.9757 2.78924 46.455 3.73005C47 4.79961 47 6.19974 47 9V13C47 15.8003 47 17.2004 46.455 18.27C45.9757 19.2108 45.2108 19.9757 44.27 20.455C43.2004 21 41.8003 21 39 21H35C32.1997 21 30.7996 21 29.73 20.455C28.7892 19.9757 28.0243 19.2108 27.545 18.27C27 17.2004 27 15.8003 27 13V9Z" fill="black" fill-opacity="0.01" shape-rendering="crispEdges"/>
  </g>
  <path d="M34.433 15C34.353 15 34.285 14.972 34.229 14.916C34.173 14.86 34.145 14.792 34.145 14.712V6.9C34.145 6.812 34.173 6.74 34.229 6.684C34.285 6.628 34.353 6.6 34.433 6.6H35.489C35.577 6.6 35.649 6.628 35.705 6.684C35.761 6.74 35.789 6.812 35.789 6.9V9.708L38.501 6.816C38.533 6.776 38.585 6.732 38.657 6.684C38.737 6.628 38.841 6.6 38.969 6.6H40.157C40.221 6.6 40.277 6.624 40.325 6.672C40.381 6.72 40.409 6.78 40.409 6.852C40.409 6.908 40.393 6.956 40.361 6.996L37.061 10.62L40.589 14.592C40.629 14.64 40.649 14.692 40.649 14.748C40.649 14.82 40.621 14.88 40.565 14.928C40.517 14.976 40.461 15 40.397 15H39.161C39.025 15 38.917 14.972 38.837 14.916C38.765 14.852 38.717 14.808 38.693 14.784L35.789 11.604V14.712C35.789 14.792 35.761 14.86 35.705 14.916C35.649 14.972 35.577 15 35.489 15H34.433Z" fill="white" fill-opacity="0.56"/>
  <defs>
  <filter id="filter0_dddii_780_71094" x="0" y="1" width="26" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="3" operator="erode" in="SourceAlpha" result="effect1_dropShadow_780_71094"/>
  <feOffset dy="6"/>
  <feGaussianBlur stdDeviation="3"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect2_dropShadow_780_71094"/>
  <feOffset dy="3"/>
  <feGaussianBlur stdDeviation="1.5"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="effect1_dropShadow_780_71094" result="effect2_dropShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="0.5" operator="erode" in="SourceAlpha" result="effect3_dropShadow_780_71094"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="0.5"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="effect2_dropShadow_780_71094" result="effect3_dropShadow_780_71094"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_780_71094" result="shape"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect4_innerShadow_780_71094"/>
  <feOffset/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"/>
  <feBlend mode="normal" in2="shape" result="effect4_innerShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"/>
  <feBlend mode="normal" in2="effect4_innerShadow_780_71094" result="effect5_innerShadow_780_71094"/>
  </filter>
  <filter id="filter1_dddii_780_71094" x="24" y="1" width="26" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="3" operator="erode" in="SourceAlpha" result="effect1_dropShadow_780_71094"/>
  <feOffset dy="6"/>
  <feGaussianBlur stdDeviation="3"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect2_dropShadow_780_71094"/>
  <feOffset dy="3"/>
  <feGaussianBlur stdDeviation="1.5"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="effect1_dropShadow_780_71094" result="effect2_dropShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="0.5" operator="erode" in="SourceAlpha" result="effect3_dropShadow_780_71094"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="0.5"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
  <feBlend mode="normal" in2="effect2_dropShadow_780_71094" result="effect3_dropShadow_780_71094"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_780_71094" result="shape"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect4_innerShadow_780_71094"/>
  <feOffset/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"/>
  <feBlend mode="normal" in2="shape" result="effect4_innerShadow_780_71094"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"/>
  <feBlend mode="normal" in2="effect4_innerShadow_780_71094" result="effect5_innerShadow_780_71094"/>
  </filter>
  </defs>
  </svg>  
}

class SearchInput extends Component {
  inputRef = createRef()

  searchNavigationListener = (e) => {
    const { setIsFocused } = this.props;

    const ignoreElement = document.getElementById('search-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    if (!isClickInsideElement && this.inputRef.current) {
      this.inputRef.current.blur()
      setIsFocused(false)
      return;
    }
    return false;
  }

  launchpadNavigationListener = (e) => {
    const { dispatch } = this.props;

    const ignoreElement = document.getElementById('launchpad-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    if (!isClickInsideElement) {
      dispatch({ type: 'BLUR_LAUNCHPAD' })
      return;
    }
    return false;
  }
  userNavigationListener = (e) => {
    const { dispatch } = this.props;

    const ignoreElement = document.getElementById('user-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    if (!isClickInsideElement) {
      dispatch({ type: 'BLUR_USER_MODAL' })
      return;
    }
    return false;
  }
  chatsNavigationListener = (e) => {
    const { dispatch } = this.props;

    const ignoreElement = document.getElementById('chats-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    if (!isClickInsideElement) {
      dispatch({ type: 'BLUR_CHATS_MODAL' })
      return;
    }
    return false;
  }

  createNewNavigationListener = (e) => {
    const { dispatch } = this.props;
    

    const ignoreElement = document.getElementById('createNew-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    console.log('isClickInsideElement', isClickInsideElement)
    if (!isClickInsideElement) {      
      dispatch({ type: 'BLUR_CREATENEW_MODAL' })
      return;
    }
    return false;
  }

  notificationsNavigationListener = (e) => {
    const { dispatch } = this.props;

    const ignoreElement = document.getElementById('notifications-navigation-dialog');
    const isClickInsideElement = ignoreElement.contains(e.target);

    console.log('isClickInsideElement', isClickInsideElement)

    if (!isClickInsideElement) {
      dispatch({ type: 'BLUR_NOTIFICATIONS_MODAL' })
      return;
    }
    return false;
  }

  componentDidMount() {
    const Search = document.getElementById('search-navigation-wrapper');
    const Launchpad = document.getElementById('launchpad-navigation-wrapper');
    const UserModal = document.getElementById('user-navigation-wrapper');
    const NotificationsModal = document.getElementById('notifications-navigation-wrapper');
    const ChatsModal = document.getElementById('chats-navigation-wrapper');
    const CreateNewModal = document.getElementById('createNew-navigation-wrapper');
    

    !!Search && Search.addEventListener('click', this.searchNavigationListener)
    !!Launchpad && Launchpad.addEventListener('click', this.launchpadNavigationListener)
    !!UserModal && UserModal.addEventListener('click', this.userNavigationListener)
    !!NotificationsModal && NotificationsModal.addEventListener('click', this.notificationsNavigationListener)
    !!ChatsModal && ChatsModal.addEventListener('click', this.chatsNavigationListener)    
    !!CreateNewModal && CreateNewModal.addEventListener('click', this.createNewNavigationListener)
  }

  componentDidUpdate(prevProps){
    const {isFocused, dispatch} = this.props
    const {prevIsFocused} = prevProps
    if(prevIsFocused === isFocused) return
    
    isFocused ? 
      this.inputRef.current.focus() :
      this.inputRef.current.blur()
  }

  componentWillUnmount() {    
    const Search = document.getElementById('search-navigation-wrapper');
    const Launchpad = document.getElementById('launchpad-navigation-wrapper');
    const UserModal = document.getElementById('user-navigation-wrapper');
    const NotificationsModal = document.getElementById('notifications-navigation-wrapper');
    const ChatsModal = document.getElementById('chats-navigation-wrapper');
    const CreateNewModal = document.getElementById('createNew-navigation-wrapper');


    Search && Search.removeEventListener('click', this.searchNavigationListener)
    Launchpad && Launchpad.removeEventListener('click', this.launchpadNavigationListener)
    UserModal && UserModal.removeEventListener('click', this.userNavigationListener)
    NotificationsModal && NotificationsModal.removeEventListener('click', this.notificationsNavigationListener)
    !!ChatsModal && ChatsModal.removeEventListener('click', this.chatsNavigationListener)
    CreateNewModal && CreateNewModal.removeEventListener('click', this.createNewNavigationListener)


  }

  onFocus = () => {
    const {setIsFocused} = this.props;
    setIsFocused(true);
  }
  
  onOpenGroupsNavigation = () => {
    this.props.dispatch({
      type: 'SEARCH_QUERY', query: {query: '/', isFocused: true}
    })
  }

  render() {
    const { classes, onSearch, group, onRemoveChip, isMyPage, t,theme, useExpandedSearch, showFilters  } = this.props;

    return <Grid container className={`${classes.wrapper} ${useExpandedSearch ? classes.wrapperExpanded : ''}`} alignItems='center'>
      {!group && <Grid item>
        <IconButton disableRipple={true} className={classes.search} onClick={onSearch} aria-label="Search">
          <SearchlIcon />
        </IconButton>
      </Grid>}
      {group ? <Grid item >
        <Chip  onClick={this.onOpenGroupsNavigation} deleteIcon={<div data-intrcm-id="searchInput_lycmvswp" className={classes.cancelIconContainer}><CancelIcon color={"#fff"} /></div>} label={group.label} classes={{ label: classes.chipTextPX }} className={classes.chipGroup} onDelete={onRemoveChip}  />
      </Grid> : null}
      {isMyPage ? <Grid item>
        <Chip deleteIcon={<div data-intrcm-id="searchInput_2z1v4qhs" className={classes.cancelIconContainer}><CancelIcon color={theme.primary.b500} /></div>} label={t('My Page')}    classes={{ label: classes.chipText }} className={classes.chipMy}   onDelete={onRemoveChip} />
      </Grid> : null}
      <Grid item>
        <input autoComplete="off" ref={ref => (this.inputRef.current = ref)} onFocusCapture={this.onFocus} id="docsearch-input" {...this.props} className={`${classes.input} ${useExpandedSearch ? classes.inputExpanded : ''}`} style={group?{width:'10vw'}:{width:510}} />
      </Grid>
      <Grid item style={{position: 'absolute', right: showFilters ? 38 : 4, marginTop: 9}}>
        <KeyHelpers />
      </Grid>
    </Grid>

  }

}

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
  withStyles(styles, { withTheme: true }), 
  withTranslation('search'),
  connect(
      null,
      mapDispatchToProps,
  )
)(SearchInput);