import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const styles = (theme) => ({
})

const MoveItemStatusText= (props) => {
    const {styles, storiesNavigation, from, to } = props
    const {currentScene} = storiesNavigation
    const [statusText, setStatusText] = useState('')

    useEffect(() => {
        const params = currentScene.messageTemplateParams
        if(params.length) {
            if(from) {
                setStatusText(params[2])
            } else if (to) {
                setStatusText(params[3])
            }
        }
    },[currentScene, from, to])

    return <div data-intrcm-id="MoveItemStatusText_a95ze77s" style={styles.container}>
        <div data-intrcm-id="MoveItemStatusText_1kq8xsh6" style={styles.text}>
            {statusText}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    storiesNavigation: state.data.storiesNavigation
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(MoveItemStatusText)