import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import parseImageUrl from '../../../../Common/parseImagUrl';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { sortGroupsByTitle } from '../../../../../../utils/groupsNavigation/collectionToGroupNavigation';
import { allCollectionMembers, getAllCollection } from '../../../../../views/groups/groupUtils';
import { withMixpanel } from '../../../../../components/Common/withMixpanel';
import goToCollection from '../../../../../actions/goToCollection';
import groupPlaceholder from "../../../../../../assets/img/group_placeholder.png";

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom: 16,
        wordBreak: 'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        listStyle: 'none',
        marginLeft: 0,
        marginBottom: 0,
        paddingLeft: 0,
    },
    work: {
        height: 34,
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'flex-start',
    },
    groupInfoHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleWork: {
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0,
        color: '#21212d',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 204,
        whiteSpace: 'nowrap',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline'
        }
    },
    description: {
        marginTop: -3,
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: ' normal',
        color: '#979aaa',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 204,
        whiteSpace: 'nowrap',
    },
    imageGroup: {
        marginRight: 12,
        width: 40,
        height: 34,
        objectFit: 'cover'
    },
    seeAll: {
        textAlign: 'center',
        color: theme.customColors.primary.main,
        fontSize: 13,
        textTransform: 'uppercase',
        fontWeight: 500,
        cursor: 'pointer'
    }
});

const UserGroupsWidget = (props) => {
    const { classes, dispatch, isMyUser, userProfile, userCollections, openCollections, cloudfront, mixpanelTrack } = props;
    const { firstname, auth0_id } = userProfile
    const [collections, setCollections] = useState([])
    const { t } = useTranslation('userGroupsWidget')

    useEffect(() => {
        const allCollections = userCollections
        const collections = allCollections.filter(col => {
            const groupUsers = allCollectionMembers(col)
            const usersUids = groupUsers.map(u => u.auth0_id)
            return usersUids.includes(auth0_id)
        })
        const sorted = sortGroupsByTitle(collections)
        setCollections(sorted)

    }, [openCollections, userCollections, auth0_id])

    const titleGroupHandler = (item) => {
        const collection = getAllCollection(userCollections, openCollections).find(col => col.collectionUid === item.collectionUid)
        mixpanelTrack(`User works widget - go to group ${item.title}`, {})
        goToCollection('/' + item.title, dispatch, item.collectionUid, item.groupUid, collection)();
    }

    const seeAllHandler = () => {
        mixpanelTrack('User works widget - see all works', {})
        dispatch({
            type: 'SEARCH_QUERY', query: { query: '/', isFocused: true }
        })
    }

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="UserGroupsWidget_rg22vmht" className={classes.containerInner}>
                <div data-intrcm-id="UserGroupsWidget_wyj4b185" className={classes.titleSection}>
                    <div data-intrcm-id="UserGroupsWidget_aonlc71v" className={classes.title}>{isMyUser ? t("My Groups") : firstname + t("'s Groups")}</div>
                </div>
                <ul className={classes.options}>
                    {collections.length && collections.slice(0, 4).map(item => {
                        const imageUrl = item.backgroundUrl ? parseImageUrl(cloudfront, item.backgroundUrl, 56, groupPlaceholder) : groupPlaceholder
                        return <li className={classes.work} key={item.id}>
                            <img alt={item.title} src={imageUrl} className={classes.imageGroup} />
                            <div data-intrcm-id="UserGroupsWidget_c7wbsyyi" className={classes.groupInfoHolder}>
                                <span className={classes.titleWork} onClick={() => titleGroupHandler(item)}>{item.title}</span>
                                <span className={classes.description}>{item.description}</span>
                            </div>
                        </li>
                    })}
                </ul>
                {collections.length > 4 ? <div data-intrcm-id="UserGroupsWidget_cf47m8lg" className={classes.seeAll} onClick={seeAllHandler}>See All</div> : null}
            </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    openCollections: state.globalData.openCollections,
    userCollections: state.userData.collections,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('userGroupsWidget'),
    connect(mapStateToProps, mapDispatchToProps),
)(UserGroupsWidget);