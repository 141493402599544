import React, { PureComponent } from 'react';

export default class OnelineDateIcon extends PureComponent {

    render() {
        const { iconColor } = this.props
        return (
        <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.545 0H1.455C.655 0 0 .75 0 1.669v2.494c0 .459.227.834.627.834s.628-.375.628-.834V2.286c0-.459.327-.834.727-.834h12.036c.4 0 .727.375.727.834v10.436c0 .46-.327.835-.727.835H1.982c-.4 0-.727-.376-.727-.835v-1.885c0-.459-.228-.834-.628-.834S0 10.378 0 10.837v2.511C0 14.258.647 15 1.44 15h13.105c.8 0 1.455-.75 1.455-1.669V1.67C16 .75 15.345 0 14.545 0zM7.891 10.128 9.92 7.8a.457.457 0 0 0 0-.592L7.89 4.88c-.225-.267-.617-.083-.617.292v1.494H.727c-.4 0-.727.375-.727.834 0 .459.327.834.727.834h6.546v1.494c0 .375.392.559.618.3z" fill={iconColor} fill-rule="nonzero"/>
        </svg>
        )
    }
}