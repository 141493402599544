import React, { Component } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import GoogleMap from 'google-map-react';
import MapPin from './MapPin.js';
import moment from "moment";

const styles = theme => ({
    container: { height: '100vh', width: '100vw' },
});

class Map extends Component {
    constructor(props) {
        super(props)

        const { draggable, fullscreenControl } = props
        this.options = { scrollwheel: false, minZoom: 3, maxZoom: 30, draggable, fullscreenControl }
    }
    
    static defaultProps = {
        draggable: true,
        fullscreenControl: true,

        // default center TLV
        center: [32.085300, 34.781769],
        zoom: 15,
    };

    bootstrapURLKeys = {
        key: "AIzaSyDD3QYxMcjgg0FNa2dZmIWanra_VVlUVpk",
        libraries: 'places'
    }

    render() {
        const { center, zoom, classes, children, onClick, userLang, onChange } = this.props


        this.bootstrapURLKeys.language = userLang

        return (
            <div data-intrcm-id="Map_brxjkz3p" className={classes.container}>
                <GoogleMap
                    // The onChange called when the drag end
                    onChange={onChange}
                    onGoogleApiLoaded={this.props.onMapMounted}
                    options={{ ...this.options, }}
                    onCenterChanged={this.props.onCenterChanged}
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={this.bootstrapURLKeys}
                    onClick={onClick}
                    center={center}
                    zoom={zoom}>
                    {children}
                </GoogleMap>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userLang: state.userData.profileData.lang
})



export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(mapStateToProps))(Map);
