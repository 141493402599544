import { withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { EditLaunchpadHeader } from './EditLaunchpadHeader';
import { LaunchpadEditOptionsSection } from './LaunchpadEditOptions';

const styles = (theme) => ({
    container: {
        ...theme.launchpadPageWrap,
    },
})

function LaunchpadEdit(props) {
    const {classes} = props

    return (
        <div data-intrcm-id="LaunchpadEdit_xsqe0nov" className={classes.container} >
            <EditLaunchpadHeader />
            <LaunchpadEditOptionsSection />
        </div>
    )
}

export const LaunchpadEditPage = compose(
    withStyles(styles, {withTheme: true})
)(LaunchpadEdit)

