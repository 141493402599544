
import { Grid, withStyles, Typography, Divider, Button } from '@material-ui/core';
import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { randomID } from '../../../../../utils/randomID';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import DefaultChecklistModal from './DefaultChecklistModal';

const styles = theme => ({
    divider: {
        margin: '24px 0px'
    },
    menuContainer: {
        padding: 10,
        marginTop: 16,
        height: 'fit-content',
    },
    buttonIc: {
        marginTop: 8,
        fontSize: 14,
        display: 'block',
        textTransform: 'none'
    },
    addChecklist: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    checkLists: {

    }
});

class DefaultChecklistsCustomInput extends PureComponent {
    state = {
        openModal: false,
        checklists: [],
        currentChecklist: {},
        currentChecklistId: '',
    }

    handleUpdateChecklists = (checklists) => {
        const {handleChangeChecklists} = this.props
        const newChecklists = checklists.map(([_, c]) => c)
        handleChangeChecklists(newChecklists)
    }

    initChecklists = () => {
        const {initialChecklists} = this.props

        const checklists = initialChecklists.map(c => [randomID(), c])
        this.setState({checklists})
    }

    componentDidMount() {
        this.initChecklists()
    }

    onAddNewChecklist = () => {
        this.setState({currentChecklist: {}, currentChecklistId: randomID(), openModal: true})
    }

    onCloseModal = () => {
        this.setState({ openModal: false, currentChecklist: {}, currentChecklistId: '' })
    }

    applyChecklists = (checklists) => {
        this.setState({checklists})
        this.onCloseModal()
        this.handleUpdateChecklists(checklists)
    }

    onDeleteChecklist = (id) => {
        const {checklists} = this.state
        const filtered = checklists.filter(([checklistId]) => id !== checklistId)
        this.applyChecklists(filtered)
    }

    onSaveChecklist = (checklist, id) => {
        const {checklists} = this.state
        const ind = checklists.findIndex(([checklistId]) => id === checklistId)
        if(ind === -1) {
            const newChecklists = [...checklists, [id, checklist]]
            this.applyChecklists(newChecklists)
            return
        }
        const newChecklists = [...checklists]
        newChecklists[ind] = [id, checklist]
        this.applyChecklists(newChecklists)
    }

    openChecklist = (id) => {
        const {checklists} = this.state
        const entry = checklists.find(([checklistId]) => id === checklistId)
        if(!entry) return

        this.setState({currentChecklist: entry[1], openModal: true, currentChecklistId: entry[0]})
    }
    isEdit() {
        return this.state.checklists.findIndex(([checklistId]) => this.state.currentChecklistId === checklistId) !== -1
    }
    
    render() {
        const { classes,t } = this.props
        const { openModal, currentChecklist, checklists, currentChecklistId } = this.state

        return (
            <Fragment>
                <Divider className={classes.divider} />
                <Typography variant='h4'>{t('Default Checklists')}</Typography>
                <Grid container className={classes.menuContainer}>
                    <Grid item className={classes.checkLists}>
                        {checklists.map(([id, c]) => (
                            <Button key={id} variant="raised" className={classes.buttonIc} onClick={() => this.openChecklist(id)} >
                                {c.title}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item className={classes.addChecklist}>
			            <Button variant="raised" aria-label={t("Add")} color="primary" className={classes.buttonIc} onClick={this.onAddNewChecklist} >
				            {t('Add Checklist')}
			            </Button>
                    </Grid>
                </Grid>
                <DefaultChecklistModal
                    isEdit={this.isEdit()}
                    open={openModal}
                    checklistId={currentChecklistId}
                    checklist={currentChecklist}
                    onClose={this.onCloseModal}
                    onSave={this.onSaveChecklist}
                    onDelete={this.onDeleteChecklist}
                />
            </Fragment>
        )
    }
}

export default compose(withStyles(styles),
    withTranslation('admin'),
    withErrorCatcher(),
)(DefaultChecklistsCustomInput);
