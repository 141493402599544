import { Avatar, Grid, withStyles } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Launch from '@material-ui/icons/Launch';
import { ConfirmDialog } from '@tuqqi/web';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import FaForward from 'react-icons/lib/fa/mail-forward';
import IoDoneAll from 'react-icons/lib/io/android-done-all';
import MdIosTime from 'react-icons/lib/md/access-time';
import MdCheck from 'react-icons/lib/md/check';
import { compose } from 'recompose';
import SplitedText from '../../../components/Comments/splitedText.js';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import { ChatAttachment } from '../ChatAttachment.js';
import LocationMessage from '../LocationMessage/LocationMessage';
import SystemMessage from '../SystemMessage/SystemMessage';
import './MessageBox.css';

const moment = require('moment');
const classNames = require('classnames');

const styles = theme => ({
    container: {
        marginTop: -5,
        marginBottom: 8,
        boxShadow: 'none',
        paddingRight: 16,
        paddingLeft: 16,
        borderRadius: 10,
        paddingBottom: 0,
        background: '#F3F6FB',
        marginLeft: 55
    },
    time: {
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        "textAlign": "right",
        "color": "#979aaa",
        "cursor": 'auto'
    },
    timeContainer: {
        margin: '8px 0px', padding: 0
    },
    icon: {
        '-webkit-filter': 'none',
        filter: 'none',
        fill: '#F3F6FB',
    },
    leftIcon: {
        cursor: 'pointer', width: 20, height: 20, left: 'auto', right: 0, color: '#8d8d8d', marginRight: -25, marginTop: '-3px', position: 'absolute'
    },
    rightIcon: {
        cursor: 'pointer', width: 20, height: 20, left: 'auto', right: 0, color: '#8d8d8d', marginRight: -25, marginTop: '-3px', position: 'absolute'
    }
});

class MessageBox extends Component {
    MessageRef = createRef(null)
    state = {
        onCommentHover: false
    }

    getStatusIcon() {
        if (!this.props.status) {
            return null
        }

        let icon = null
        switch (this.props.status) {
            case 'waiting':
                icon = <MdIosTime />
                break;
            case 'sent':
                icon = <MdCheck />
                break;
            case 'received':
                icon = <IoDoneAll />
                break;
            case 'read':
                icon = <IoDoneAll color='#4FC3F7' />
                break;
            default:
                break;
        }

        return <span className='rce-mbox-status'>{icon} </span>
    }
    get iconClassName() {
        const {position, classes} = this.props;
        return position === 'right' ? classes.rightIcon : classes.leftIcon
    }
    get iconStyle() {
        const {position} = this.props;
        const offset = this.MessageRef.current ? this.MessageRef.current.offsetWidth + 5 : -25
        return position === 'right' ? {marginRight: offset} : {}
    }
    get iconDeleteStyle() {
        const {position} = this.props;
        const offset = this.MessageRef.current ? this.MessageRef.current.offsetWidth + 25 : -25
        return position === 'right' ? {marginRight: offset} : {}
    }

    getGoToItemIcon() {
        const {comment, onClickOpenDocument} = this.props;
        const {typeItem} = comment
        const className = this.iconClassName

        if (this.state.onCommentHover && typeItem.isSavedMessage && typeItem.messageType === 0) {
            let icon = <Launch onClick={onClickOpenDocument} className={className} />
            return icon
        }
        return null
    }
    getSaveIcon() {
        const {comment, onClickSave} = this.props;
        const {typeItem} = comment
        const className = this.iconClassName
        const style = this.iconStyle

        if (this.state.onCommentHover && !typeItem.isSavedMessage) {
            let icon = <BookmarkIcon onClick={onClickSave} style={style} className={className} />
            return icon
        }
        return null
    }

    getDeleteIcon() {
        const {onClickDelete, showDeleteComment, t, classes} = this.props;

        const className = classes.rightIcon
        const style = this.iconDeleteStyle

        if (this.state.onCommentHover && showDeleteComment) {
            let icon = <DeleteIcon style={style} className={className} />
            return <ConfirmDialog title={t('Delete title')}
                message={t("Are you sure you want to delete this comment? this action can’t be undone")}
                actionButtonText={t("Delete")}
                onConfirmed={onClickDelete}>
                    {icon}
            </ConfirmDialog>
        }
        return null
    }

    onEnterComment = () => {
        this.setState({onCommentHover: true})
    }
    onEnterComment = () => {
        this.setState({onCommentHover: true})
    }
    onLeaveComment = () => {
        this.setState({onCommentHover: false})
    }

    render() {
        const { comment, classes } = this.props
        const { text, type } = comment

        var positionCls = classNames('rce-mbox', { 'rce-mbox-right': this.props.position === 'right' });
        var thatAbsoluteTime = !(type === 'location' && text);

        return (
            <div data-intrcm-id="MessageBox_rbmrg2s2"
                onMouseLeave={this.onLeaveComment}
                onMouseEnter={this.onEnterComment}
                style={{marginBottom: 10}}
                className={classNames('rce-container-mbox', this.props.className)}
                // onClick={this.props.onClick}
                >
                {
                    this.props.renderAddCmp instanceof Function &&
                    this.props.renderAddCmp()
                }
                {
                                    ((this.props.title || this.props.avatar) && this.props.position === 'left') &&
                                    <div data-intrcm-id="MessageBox_qoq2bxge"
                                        style={this.props.titleColor ? { color: this.props.titleColor, marginLeft: 16, textDecoration: 'none', alignItems: 'flex-start', cursor: 'auto' } : {marginLeft: 16, textDecoration: 'none', alignItems: 'flex-start', cursor: 'auto'}}
                                        className={classNames('rce-mbox-title', {
                                            'rce-mbox-title--clear': this.props.type === 'text',
                                        })}>
                                        {
                                            this.props.avatar &&
                                            <Avatar onClick={this.props.onTitleClick} src={this.props.avatar} style={{ width: 30, height: 30, marginRight: 10, cursor: 'pointer' }} />
                                        }
                                        {
                                            this.props.title &&
                                            <div data-intrcm-id="MessageBox_pxrt2szg" style={{cursor: 'pointer'}} onClick={this.props.onTitleClick}>{this.props.title}, <span className={classes.time}>{moment(new Date(comment.createdAt)).fromNow(false)}</span></div>
                                        }
                                    </div>
                }
                {
                    this.props.position === 'right' && (
                        <Grid item style={{marginRight: 22, marginBottom: 10}} className={classes.time}>{moment(new Date(comment.createdAt)).fromNow(false)}</Grid>
                    )
                }
                {
                    type === 'system' ?
                        <SystemMessage text={text} />
                        :
                        <div data-intrcm-id="MessageBox_3p0knoxs"
                            ref={this.props.position === 'right' && this.MessageRef}
                            style={{borderTopRightRadius: 10,
                                borderTopLeftRadius: 10,background: this.props.position === 'left' ? '#F3F6FB' : 'rgba(181, 0, 255,0.15)'}}
                            className={classNames(
                                positionCls,
                                { 'rce-mbox--clear-padding': thatAbsoluteTime },
                                // { 'rce-mbox--clear-notch': !this.props.notch },
                                classes.container
                            )}>
                                    <Grid item>{this.getGoToItemIcon()}</Grid>
                                    <Grid item>{this.getSaveIcon()}</Grid>
                                    <Grid item>{this.getDeleteIcon()}</Grid>
                            <div data-intrcm-id="MessageBox_oja2q3gm"
                                className='rce-mbox-body'
                                onContextMenu={this.props.onContextMenu}>
                                {
                                    this.props.forwarded === true &&
                                    <div data-intrcm-id="MessageBox_xuk2gbu4"
                                        className={classNames(
                                            'rce-mbox-forward',
                                            { 'rce-mbox-forward-right': this.props.position === 'left' },
                                            { 'rce-mbox-forward-left': this.props.position === 'right' }
                                        )}
                                        onClick={this.props.onForwardClick}>
                                        <FaForward />
                                    </div>
                                }

                                {text && <SplitedText withParsingText style={{paddingTop: 10}} className="rce-mbox-text" text={text} />}

                                {
                                    comment.attachments.map(attachment => <ChatAttachment onOpenLightbox={this.props.onOpenLightbox} attachment={attachment} />)
                                }

                                {
                                    type === 'location' &&
                                    <LocationMessage
                                        onOpen={this.props.onOpen}
                                        data={this.props.data}
                                        target={this.props.target}
                                        href={this.props.href}
                                        apiKey={this.props.apiKey}
                                        src={this.props.src}
                                        zoom={this.props.zoom}
                                        markerColor={this.props.markerColor}
                                        text={<SplitedText withParsingText className="rce-mbox-text rce-mbox-location-text" text={text} />} />
                                }

                                <Grid container direction='row' wrap='nowrap' justify='space-between'
                                    className={classes.timeContainer}
                                >
                                    <Grid item>{this.getStatusIcon()}</Grid>
                                </Grid>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

MessageBox.defaultProps = {
    position: 'left',
    type: 'text',
    text: '',
    title: null,
    titleColor: null,
    onTitleClick: null,
    onForwardClick: null,
    date: new Date(),
    data: {},
    onClick: null,
    onOpen: null,
    onDownload: null,
    onLoad: null,
    forwarded: false,
    status: null,
    dateString: null,
    notch: true,
    avatar: null,
    renderAddCmp: null,
    copiableDate: false,
    onContextMenu: null,
};

export default compose(withTranslation("chat"), withStyles(styles), withErrorCatcher())(MessageBox);
