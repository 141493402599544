import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { LockOutline, People, Public } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { goToGroup } from "../../../../actions/goToActions";
import DateFrom from "../../Comments/dateFrom";
import dataTypesDictionary from '../../Common/userCompatibleNames/dataTypesDictionary';
import Authors from "./Authors";
import ItemPreviewCardHeaderMenu from './itemPreviewCardHeaderMenu';
import SwappingMembersAvatar from '../../Common/SwappingMembersAvatar';
import { withTranslation } from 'react-i18next';

// Apply some reset
const styles = theme => ({
    title: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "500",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "rgba(0, 0, 0, 0.87)",
        cursor: 'pointer'
    },
    subTitle: {
        'font-family': '"Roboto"',
        'font-size': 14,
        'font-weight': 'normal',
        'font-style': 'normal',
        'font-stretch': 'normal',
        'line-height': 1.43,
        'letter-spacing': '0.6px',
        'text-align': 'left',
        color: 'rgba(151, 154, 170, 0.8)',
        cursor: 'text'
    },
    tag: {
        ...theme.typography.body1,
        color: theme.typography.caption.color,
        cursor: 'pointer'
    },
    header: {
        padding: 16,
        paddingBottom: 10,
        position: 'relative',

    },
    pointer: {
        cursor: 'pointer'
    },
    rightHeaderAvatar: {
        paddingLeft: 0
    },
    colorCollection: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        position: 'absolute',
        left: '-20px',
        top: '18px',
    },
    button: {
        fontSize: theme.typography.body1.fontSize,
        color: theme.typography.display1.color,
        width: 36,
        height: 'auto',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        paddingBottom: 0,
        paddingTop: 0,
    },
    icon: {
        width: theme.typography.body1.fontSize,
        height: theme.typography.body1.fontSize,
    },
    groupIcon: {
        width: 'auto',
        height: 'auto',
        fontFamily: 'Material Icons'
    },
    ...theme.feed,
    spaceLeft: {
        marginLeft: 21,
    },
    spaceRight: {
        margin: '-4px -12px 0 auto',
    }
});

class ItemPreviewCardHeader extends Component {
    state = {
    }

    openGroup = (e, group) => {
        goToGroup('/' + group.title, this.props.dispatch, group.collectionUid)();
        e.stopPropagation()
    }

    dataTypeTitle = () => {
        const { classes, result, classifications, userData, groupInfo, inputMappings, t } = this.props;

        const dataTypeTitle = dataTypesDictionary(result.dataType, inputMappings) || ' '

        const vowel = ['a', 'e', 'i', 'o', 'u']

        let prefix
        if (vowel.includes(dataTypeTitle[0].toLowerCase())) {
            prefix = 'an'
        } else {
            prefix = 'a'
        }

        return `${t('shared')} ${t(prefix)} ${dataTypeTitle}`
    }

    getPermissionItem = () => {
        const { classes, result, classifications, userData, groupInfo, inputMappings } = this.props;
        const dataTypeTitle = this.dataTypeTitle()

        let permissionIcon = null;
        let currCollection;

        let author = <span className={classes.subTitle}> {dataTypeTitle} </span>

        if (result.isPrivate) {
            permissionIcon = <Tooltip id="tooltip-bottom-end" title='Private' placement="bottom">
                <IconButton size="small" className={classes.button} aria-label="Share">
                    <LockOutline className={[classes.icon, classes.subTitle].join(' ')} />
                </IconButton>
            </Tooltip>
        } else if (result.isLimitedToMembers) {
            permissionIcon = <Tooltip id="tooltip-bottom-end" title='Limited To Members' placement="bottom">
                <IconButton size="small" className={classes.button} aria-label="Share">
                    <Icon fontSize='small'>{'supervised_user_circle'}</Icon>
                </IconButton>
            </Tooltip>
        }
        // If the feed-item belong to group and the group exist
        else if (result.groupUid != userData.profileData.org.orgUid &&
            (currCollection = userData.collections.find(c => c.collectionGroup.groupUid == result.groupUid))) {
            permissionIcon = (
                <Tooltip id="tooltip-bottom-end" title={currCollection.title} placement="bottom">
                    <IconButton className={classes.button} aria-label={currCollection.title} >
                        <Icon className={classes.subTitle + ' ' + classes.groupIcon}>
                            <People className={[classes.icon, classes.subTitle].join(' ')} />

                        </Icon>
                    </IconButton>
                </Tooltip>)

            // If current page is group page show the item as a regular item 
            // else show the group that the item belong to
            if (!groupInfo.title) {
                author = (<Fragment>
                    <span className={classes.subTitle}> {dataTypeTitle} in </span>
                    <span style={{ marginLeft: 2, display: 'inline-flex', alignItems: 'center' }} className={classes.title} onClick={(e) => { this.openGroup(e, currCollection) }}>
                        {currCollection.title}</span>
                </Fragment>)
            }
            // If the feed-item is public
        } else {
            permissionIcon = <Tooltip id="tooltip-bottom-end" title='Public' placement="bottom">
                <IconButton className={classes.button} aria-label="Share" >
                    <Public className={[classes.icon, classes.subTitle].join(' ')} />
                </IconButton>
            </Tooltip>
        }

        if (result.classification) {
            let classification = classifications.find(c => c.level === result.classification);
            permissionIcon = <span>{permissionIcon} {classification && classification.displayName}</span>
        }

        return { author, permissionIcon }
    }

    render() {
        const { classes, DataTypeTitle, docId, result, relatedDocuments } = this.props;
        const { author, permissionIcon } = this.getPermissionItem()

        return (
            <Grid container className={classes.header} wrap='nowrap' alignItems='center'>
                <SwappingMembersAvatar userUUIDs={result.members ? result.members.map(x => x.userUUID) : []} />
                <Grid item lg={10} md={10} sm={8} wrap='nowrap' xs='8' className={classes.spaceLeft} >

                    <Authors UserTag={result.userTag} author={author} members={result.members} />
                    <div data-intrcm-id="itemPreviewCardHeader_w7yel7m2" className={classes.subTitle} style={{ overflow: 'hidden', lineHeight: 1, marginTop: 4 }}>
                        <span>{DataTypeTitle}</span>
                        <DateFrom dateTime={result.docTimestamp} />
                        <span>{permissionIcon}</span>
                    </div>
                </Grid>
                <div data-intrcm-id="itemPreviewCardHeader_pxc68vmt" className={classes.spaceRight} >
                    <ItemPreviewCardHeaderMenu docId={docId} result={result} relatedDocuments={relatedDocuments} />
                </div>
            </Grid>
        )
    }
}

ItemPreviewCardHeader.propTypes = {
    UserName: PropTypes.string.isRequired,
    UserPicUrl: PropTypes.string.isRequired,
    UserTag: PropTypes.string.isRequired,
    DataTypeTitle: PropTypes.string.isRequired,
    actionDate: PropTypes.any.isRequired,
    docId: PropTypes.any.isRequired,
    document: PropTypes.any.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    privateCollections: state.userData.privateCollections,
    classifications: state.globalData.classifications,
    groupInfo: state.results.channel,
    inputMappings: state.globalData.inputMappings,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withTranslation('itemPreviewCardHeaderTranslate'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemPreviewCardHeader);