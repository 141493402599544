import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../components/Common/withErrorCatcher.js';
import withPusher from '../../components/Common/Pusher/withPusher';
import { NewUiWrapper, TableActionCenter, managerActionCenterTable, managerTableKeepGlobalData } from '@tuqqi/web';
import {store} from '../../../index'
import { themeConstants } from '../../../theme';
import { withOpenFullview } from '../../../new-ui/withOpenFullview.js';
import CategoryViewOnlyLeftDrawer from '../../components/App/CategoryViewOnlyLeftDrawer.js';
import { MockForSetTableGlobalData } from '../groups/tableNew/MockForSetTableGlobalData.js';
import { closeFullview } from '@tuqqi/web/dist/actions/fullview-actions/fullviewActions.js';
import { goToEditProfile } from '../../actions/goToEditProfile.js';
import { mixpanelTrack } from '../../components/Common/Utils/mixpanelUtils.js';
import { push } from 'react-router-redux';
import { getAllCollection } from '../groups/groupUtils.js';
import goToCollection from '../../actions/goToCollection.js';

const styles = theme => ({
    table: {
        marginLeft: themeConstants.navigationDrawerWidth,
        width: 'fit-content',
        // background: hexToRGB('#EDEDF0', 0.5),
    },
    table1: {
        marginLeft: themeConstants.navigationDrawerSmallWidth,
        width: 'fit-content',
        // background: hexToRGB('#EDEDF0', 0.5),
    },
})

export class MyPageActionCenter extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentDidMount() {

        (async () => {
            // console.log('vdfvvdffvdvdfvfd', managerTableKeepGlobalData.isInitialPageAC);
            // if(!managerTableKeepGlobalData.isInitialPageAC) {
                managerActionCenterTable.preFetch()
                managerTableKeepGlobalData.setIsInitialPageAc(false)
                managerActionCenterTable.fetch(false)
                return
            // }
            // managerActionCenterTable.preFetch()
            // setTimeout(() => {
            //     managerActionCenterTable.fetch(true)
            // }, 2000)
        })()
    }

    addCardNewGroupTable = () => {

    }


    goToUser = (userUid) => {
        const { users, dispatch, fullview } = this.props

        const user = users.find(u => u.auth0_id === userUid)
        if (!user) {
            return
        }

        const userTag = '@' + user.tag

        goTo(userTag, dispatch)()
        fullview.open && dispatch(closeFullview())
    }
    
    onUserEditProfile = () => {
        const { dispatch, fullview } = this.props
        fullview.open && dispatch(closeFullview())

        mixpanelTrack('My page view edit profile', {})
        goToEditProfile(dispatch)
    }

    onUserMessage = (auth0_id) => {
        if(!auth0_id) return
        const { dispatch, fullview, chatService } = this.props
        fullview.open && dispatch(closeFullview())

        chatService.openNewChat(auth0_id).then(res => {
          const { chat } = res
          if (!chat || !chat.chatUId) {
            dispatch(push({ pathname: "chat" }))
            return
          }
          dispatch({
            type: 'SET_CHAT_ROOM',
            chatRoomFromUserPage: true,
            room: res
          })
          mixpanelTrack(`User page view - message`, {})
          dispatch(push(`/chat?chatid=${chat.chatUId}`))
        })
    }
    
    _goToCollection = (groupUid) => {
        const { collections, openCollections, fullview, dispatch } = this.props

        const collection = getAllCollection(collections, openCollections)
            .find(coll => coll.collectionGroup.groupUid === groupUid)
        if (!collection) {
            return
        }

        goToCollection('/' + collection.title, dispatch, collection.collectionUid, groupUid, collection)();

        fullview.open && dispatch(closeFullview())
    }

    render() {
        const { classes, isDrawerOpen, } = this.props;

        const callbacks = {
            onClickAddItemFromTable: this.addCardNewGroupTable,
            onUserClick: this.goToUser,
            onUserEditProfile: this.onUserEditProfile,
            onUserMessage: this.onUserMessage,
            onGroupClick: this._goToCollection,
        }

        return <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="MyPageActionCenterx_8n971h67" className={isDrawerOpen ? classes.table : classes.table1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            <MockForSetTableGlobalData />
                            <TableActionCenter drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerWidth : themeConstants.navigationDrawerSmallWidth} />
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    token: state.data.auth.token ?? '',
    inputMappings: state.globalData.inputMappings,
    oldUiInput: state.oldUiInput.document,
    userData: state.userData,
    chatService: state.services.chatService,
    openCollections: state.globalData.openCollections,
    collections: state.userData.collections,
    search: state.search,
    fullview: state.fullview,
    users: state.globalData.users,
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Table, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(MyPageActionCenter);