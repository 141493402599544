import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose'
import parseImagUrl from '../Common/parseImagUrl';

const styles = theme => (theme.comment)

class ImgComment extends Component {

    render() {
        const { classes, src, containerStyle, imgStyle, userId, users, cloudfront } = this.props;
        
        let imgUrl = src;
        let user = users.find(user => user.auth0_id === userId);

        if (user){
            imgUrl = user.profileImageUrl //parseImagUrl(cloudfront, user.profileImageUrl);
        }

        return <div data-intrcm-id="imgComment_03xe3tsa" className={classes.imgContainer} style={containerStyle}>
            <img className={classes.img} src={imgUrl} style={imgStyle} />
        </div>
    }
}


const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
    users: state.globalData.users
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ImgComment);