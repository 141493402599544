import React, { PureComponent } from 'react';

export default class LanguageIcon extends PureComponent {

    render() {
        const { iconColor } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M1.33301 8.00016C1.33301 11.682 4.31777 14.6668 7.99967 14.6668C11.6815 14.6668 14.6663 11.682 14.6663 8.00016C14.6663 4.31826 11.6815 1.3335 7.99967 1.3335C4.31777 1.3335 1.33301 4.31826 1.33301 8.00016Z" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.66699 1.36621C8.66699 1.36621 10.667 3.99996 10.667 7.99993C10.667 11.9999 8.66699 14.6337 8.66699 14.6337" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.33301 14.6337C7.33301 14.6337 5.33301 11.9999 5.33301 7.99993C5.33301 3.99996 7.33301 1.36621 7.33301 1.36621" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.75293 10.3335H14.2468" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.75293 5.6665H14.2468" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }
}