import { updateSpecificItem } from "@tuqqi/web";
import { moveLane, updateKanbanLane } from "@tuqqi/web/dist/actions/group-actions/groupActions";
import { showSnackbar } from "../../../../actions/appActions";
import { DATA_UPDATE } from "../../../../actionTypes/actionTypes";

export let saveRule = function (title, ruleType, data, props) {
    const { list, rule, triggerType, collectionListRulesService, handleClose, dispatch, t, groupInfo} = props;

    const ruleId = rule ? rule.id : 0;

    const newRule = {
        id: ruleId,
        title,
        ruleDataType: ruleType,
        triggerType,
        jsonData: JSON.stringify(data)
    }

    const listDTO = {
        id: list.id,
        title: list.title
    }

    dispatch(showSnackbar(t("Saving rule")));

    collectionListRulesService.createRule(listDTO, newRule,groupInfo.id).then(res => {
        newRule.id = res.id
        if(!rule) {
            list.rules =[...list.rules, newRule]
        } else {
            const newRules =  list.rules.filter( r => r.id !==res.id)
            list.rules = [...newRules, newRule]
        }
        
        dispatch(updateKanbanLane(list))
        handleClose(t("Rule was saved!"));
    })
}

export let initRule = function (rule) {
    let title = '', ruleType = '', data = null;

    if (rule) {
        title = rule.title;
        ruleType = rule.ruleDataType;

        try {
            data = JSON.parse(rule.jsonData)
        }
        catch (e) {

        }
    }

    return { ruleType, title, data }
}

export let deleteRule = function (props) {
    const { list, collectionListRulesService, handleClose, rule, dispatch, t, groupInfo } = props;

    dispatch(showSnackbar(t("Deleting rule")))

    collectionListRulesService.deleteRule(list.id, rule.id, groupInfo.id).then(res => {
        list.rules = list.rules.filter( r => r.id !==rule.id)
        dispatch(updateKanbanLane(list))
        handleClose(t("Rule was deleted!"));
    })
}

export let updateTasks = function (checklist, tasks) {
    const newChecklist = { ...checklist, tasks }

    return newChecklist;
}

export let updateChecklistTitle = function (checklist, title) {
    const newChecklist = { ...checklist, title }

    return newChecklist;
}