import moment from 'moment'
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider/*, TimePicker*/, DatePicker, } from 'material-ui-pickers';
import withErrorCatcher from '../../../Common/withErrorCatcher.js'
import { compose } from 'recompose'

// without this line exception will throw "TypeError: _this.props.utils.getStartOfMonth is not a function"
//see https://github.com/vascofg/react-admin-date-inputs/issues/26#issuecomment-464702811
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth


const styles = theme => ({
  datePicker:{ margin:'0px 0px 0px 32px' }
});

class MaterialUIPickers extends React.PureComponent {

  nullDate = '0001-01-01T00:00:00Z'
  render() {
    const { classes, selectedDate, onChange, label, minDate, maxDate } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          clearable
          className={classes.datePicker}
          keyboard
          margin="normal"
          label={label}
          autoOk
          defaultValue
          value={selectedDate === this.nullDate ? null : selectedDate}
          onChange={onChange}
          format='dd/MM/yyyy'
          minDate={minDate || '0000-01-01'}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

MaterialUIPickers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles),
    withErrorCatcher("Failed Loading Date Picker, Please Contact Tuqqi Support"))
    (MaterialUIPickers);