import AdminService from '../services/adminService';
import calendarService from '../services/calendarService';
import ChallengeService from '../services/challengeService';
import chatService from '../services/chatService';
import classificationsService from '../services/classificationsService';
import CollaborationService from '../services/collaborationService';
import CollectionListRulesService from '../services/collectionListRulesService.js';
import CustomInputService from '../services/customInputService';
import favouritesService from '../services/favouritesService';
import groupService from '../services/groupService';
import InputService from '../services/inputService';
import integrationsService from '../services/integrationsService';
import LinkPreviewService from '../services/linkPreviewService';
import notificationService from '../services/notificationService';
import QueryService from '../services/queryService';
import scoreboardService from '../services/scoreboardService';
import sourceService from '../services/sourceService';
import UserSettingsService from '../services/userSettingsService';
import PollService from '../services/pollService';
import DefaultInputService from '../services/defaultInputService';
import BillingService from '../services/billingService';
import { fetchDocuments, getCurrentTimeTracking, loadUserData,activateTimer, disabledTimer, setActiveTaskId, setCurrentTime, setTimeTrackingTask } from '@tuqqi/common';
import { SERVER_API } from './serverAPI';
import i18n from 'i18next';
import moment from "moment";
import parseImagUrl from "../ui/components/Common/parseImagUrl";
import { globalDataLoad, userCollectionLoad, userDataLoad, userSubscribedTagsLoad } from '../actions/globalDataAction';
import { isEqualJson } from './commonHelpers';
import { initGroups } from './groupsNavigation/collectionToGroupNavigation';
import { setManagedGroups, setSubscribedGroups } from '../actions/groupsNavigationActions';
import { setFeedMenuItems } from '../actions/feedNavigation';
import { initLandingUsers, initSearchFavouritesGroups, initSearchManagedGroups, initSearchSubscribedGroups, initSearchSubscribedUsers } from '../actions/searchNavigationActions';
import { getAllCollection } from '../ui/views/groups/groupUtils';
import {buildOngoingTasks} from '../ui/views/user/ongoing/userOngoingUtils'
import {managerAdminSubscriptionType, managerFetchedCollections, managerShouldUseScheduler, managerShouldUseGantt, managerTableKeepGlobalData} from '@tuqqi/web'

const isLibrary = () => window.location?.pathname?.includes?.('library')

const getDefaultSubscriptionOnError = () => {
    const _today = new Date()
    const today = _today.toISOString()
    const _throwMonth = new Date(today)
    const _throw3days = new Date(today)

    _throwMonth.setMonth(_throwMonth.getMonth() + 1)
    const throwMonth = _throwMonth.toISOString()
    _throw3days.setDate(_throw3days.getDate() + 3)
    const throw3days = _throw3days.toISOString()

    return {
        billingDate: throwMonth,
        billingInterval: 1,
        invoices: [],
        numberOfUsers: 5,
        paymentMethod: {paymentToken: null, paymentGateway: null, cardholderName: null, cardNumber: null, cardExp: null},
        subscriptionRenewal: throw3days,
        subscriptionStart: today,
        subscriptionState: 0,
        subscriptionType: 2,
        subscriptionValidUntil: throwMonth
    }
}

export const initServices = (token, _onUnauthorized) => {

    const onUnauthorized = () => {

        _onUnauthorized()
    }

    const services = {
        loaded: true,
        queryService: new QueryService(token, onUnauthorized),
        collaborationService: new CollaborationService(token,onUnauthorized),
        inputService: new InputService(token, onUnauthorized),
        userSettingsService: new UserSettingsService(token, onUnauthorized),
        linkPreviewService: new LinkPreviewService(token, onUnauthorized),
        customInputService: new CustomInputService(token,onUnauthorized),
        adminService: new AdminService(token,onUnauthorized),
        notificationService: new notificationService(token,onUnauthorized),
        sourceService: new sourceService(token,onUnauthorized),
        classificationsService: new classificationsService(token,onUnauthorized),
        integrationsService: new integrationsService(token,onUnauthorized),
        challengeService: new ChallengeService(token,onUnauthorized),
        scoreboardService: new scoreboardService(token,onUnauthorized),
        groupService: new groupService(token,onUnauthorized),
        calendarService: new calendarService(token,onUnauthorized),
        favouritesService: new favouritesService(token,onUnauthorized),
        chatService: new chatService(token,onUnauthorized),
        collectionListRulesService: new CollectionListRulesService(token,onUnauthorized),
        pollService: new PollService(token,onUnauthorized),
        defaultInputService: new DefaultInputService(token,onUnauthorized),
        billingService: new BillingService(token,onUnauthorized)
    };

    return services
}

const sortScoresDesc = (a, b) => {

    if (a.points > b.points) {
        return -1;
    }
    if (a.points < b.points) {
        return 1;
    }
    // a must be equal to b
    return 0;

}

const initialQuery = {colors: [30, 40], page: 0, size: 100, AggType: 'FLAT'}
const getQuery = (user) => ({ ...initialQuery, users: [user?.auth0_id ?? ''], query: user?.tag ? '@' + user.tag : ''})

export const fixImage = (cloudfront, user) => {
    if (user && user.profileImageUrl) {
        user.profileImageUrl = parseImagUrl(cloudfront, user.profileImageUrl)
    }
    if (user && user.org && user.org.logo && !user.org.logo.includes('app.tuqqi.com')) {
        user.org.logo = parseImagUrl(cloudfront, user.org.logo, 145)
    }
}

export const loadOrganizationData = async (token, dispatch) => {
    const services = initServices(token)
    dispatch({ type: 'SERVICES_INIT', services });
    dispatch(loadUserData(SERVER_API))
 

    return new Promise((resolve, reject) => {
    Promise.all([
        services.queryService.getTrends(), // 0

        services.userSettingsService.getUserData(), // 1                       
        services.userSettingsService.getUserDetails(), // 2
        services.userSettingsService.getUsers(), // 3
        [],// services.queryService.getScores(), // 4
        services.notificationService.getNotifications(),//5
        // services.queryService.getSubscriptionUpdates(), // 5
        services.customInputService.getDisplayTemplates(), // 6
        services.customInputService.getSourceFields(), // 7
        services.inputService.getFilestackUploadPolicy(), // 8
        services.inputService.getFilestackViewPolicy(), // 9
        services.classificationsService.getClassifications(), // 10
        services.adminService.getKnowledgelevels(), // 11
        services.scoreboardService.getCurrntChallengeStatus(),// 12
        services.customInputService.getCustomInputMapping(), //13
        services.inputService.getCloudFrontSignature(), //14
        services.groupService.getOpenCollections(), //15
        services.defaultInputService.getAllTemplates(), //16           
        services.billingService.getCurrentSubscription(), // 17
        services.collaborationService.getCurrentTimeTracking(), // 18
        services.adminService.getAllOrgLabel(), // 19,
        services.adminService.getMenuItems(), //20
        services.adminService.getLaunchpadMenuItems(), //20
        
    ])
        .then((values) => {

            const trends = values[0];
            const userData = values[1];
            const userDetails = values[2];
            const users = values[3] || []


            const scoresResult = [];
            const notifications = values[5];
            const displayTemplates = values[6];
            const sourceFields = values[7];
            const uploadPolicy = values[8];
            const readPolicy = values[9];
            const classifications = values[10];
            const knowledgelevels = values[11];
            const currntChallengeStatus = values[12];
            const inputMappings = values[13] || [];
            const cloudfront = values[14];
            const openCollections = values[15]
            const defaultInputTemplates = values[16]
            const mostActivityUsersInCompany = []//values[17]
            const currentSubscription = values[17]
            const activeUsers = values[3].filter(x => x.status === 0);
            const timeTracking = values[18]
            const orgLabels = values[19] // 19
            const menuItems = values[20] //20
            const launchpadMenuItems = values[21] //21

            if(timeTracking) {

                    if(timeTracking.status === "Not tracking") {
                        /// reset time tracker 
                        dispatch(disabledTimer())
                        dispatch(setCurrentTime(null))
                        dispatch(setTimeTrackingTask(null))
                        /// 
                        } else {
                            dispatch(fetchDocuments(SERVER_API)([timeTracking.docId]))
                            dispatch(activateTimer())
                            dispatch(setCurrentTime(new Date(timeTracking.start)))
                            dispatch(setActiveTaskId(timeTracking.docId))
                            dispatch(setTimeTrackingTask(null))
                        }
            }

            fixImage(cloudfront, userDetails)
            users.forEach(_userDetails => fixImage(cloudfront, _userDetails))
            activeUsers.forEach(_userDetails => fixImage(cloudfront, _userDetails))

            if (userDetails.lang && userDetails.lang !== i18n.language) {
                i18n.changeLanguage(userDetails.lang)
                moment.locale(userDetails.lang)
            }

            // if(userDetails) {
            //     managerTableKeepGlobalData.setUserCollections(userData?.collections ?? [])
            //     managerTableKeepGlobalData.setOpenCollections(openCollections ?? [])
            // }

            if(userDetails) {
                const query = getQuery(userDetails)
                services.queryService.Search(query, null, false).then((result) => {
                    const data = result?.data ?? []
                    dispatch({type: 'SET_TODO_COUNT', count: result?.count ?? 0})
                    const allCollections = getAllCollection(userData.collections, openCollections);
                    const todoTasks = buildOngoingTasks(data, allCollections)
                    dispatch({type: 'SET_TODO_TASKS', todoTasks})
                })
            }
            if (!currntChallengeStatus.scores || currntChallengeStatus.scores.length === 0) {

                services.queryService.getScores().then(scores => {
                    let scoresResult = scores.sort(sortScoresDesc)
                    dispatch(globalDataLoad({
                        scores: scoresResult,
                    }));
                })
            }

        const {managedGroups, subscribedGroups, favouritesGroups} = initGroups(userData.collections, userDetails.id, cloudfront, userData.favoriteCollections || [])

        dispatch(initSearchFavouritesGroups(favouritesGroups))
        dispatch(initLandingUsers(activeUsers))
        dispatch(initSearchSubscribedUsers(activeUsers, userData.subscribedTags))
        dispatch(initSearchSubscribedGroups(subscribedGroups))
        dispatch(initSearchManagedGroups(managedGroups))

        dispatch(setFeedMenuItems(menuItems))
        dispatch({
            type: 'SET_LAUNCHPAD_OPTIONS',
            allowedOptions: launchpadMenuItems
        })

        //needs move logic to searchNavigation
        dispatch(setManagedGroups(managedGroups))
        dispatch(setSubscribedGroups(subscribedGroups))
        dispatch({
            type: 'SEARCH_REPORT_NAME',
            reportName: ''
        })
        const subsc = currentSubscription ?? getDefaultSubscriptionOnError()
        managerShouldUseScheduler.provideSubscriptionType(subsc.subscriptionType)
        managerShouldUseGantt.provideSubscriptionType(subsc.subscriptionType)
        managerAdminSubscriptionType.setSubscriptionType(subsc.subscriptionType)
            // TODO: implement redux batch

            dispatch(globalDataLoad({
                tuqqiVersion: process.env.CURRENT_VERSION,
                trends: trends,
                scores: scoresResult,
                // sources: sources.source.items,
                templates: displayTemplates,
                inputMappings,
                profileData: userDetails,
                users: users,
                activeUsers: activeUsers,
                sourceFields: sourceFields,
                filestack: { view: readPolicy, write: uploadPolicy },
                classifications: classifications.sort((a, b) => a.level < b.level),
                knowledgelevels: knowledgelevels,
                currntChallengeStatus: currntChallengeStatus,
                cloudfront,
                cloudfrontAdditional: {
                    lastFetch: new Date().getTime(),
                    isFetching: false
                },
                openCollections,
                defaultInputTemplates,
                mostActivityUsersInCompany,
                currentSubscription: subsc,
                orgLabels
            }));

            dispatch(userDataLoad({
                profileData: userDetails,
                notifications: notifications,
                subscribedTags: [],
                ...userData
            }));            

            services.notificationService.subscribedTags(userDetails.lastVisit).then(tags => {
                dispatch(userSubscribedTagsLoad(tags));
            })

            services.notificationService.collections(userDetails.lastVisit).then(groups => {
                if (groups) {
                    groups.forEach(g => {
                        const collection = userData.collections.find(c => c.collectionGroup.groupUid === g.groupUid)
                        collection && (collection.numNewItems = g.numNewItems)
                    })
                    dispatch(userCollectionLoad(userData.collections))
                    // { type: 'USER_COLLECTION_LOAD', collections: [...userData.collections] });
                }
            })
            dispatch({type: "CLEAR_SUSPENDED_ERROR"})

            !isLibrary() && managerFetchedCollections.fetch()

            return resolve()
        }).catch(x => {
            return reject(x);
        })
    })
}

export const updateOrganizationData = async (auth, dispatch, services, globalData, userDataGlobal) => {
    dispatch(loadUserData(SERVER_API))

    return new Promise((resolve, reject) => {
    Promise.all([
        services.queryService.getTrends(), // 0

        services.userSettingsService.getUserData(), // 1                       
        services.userSettingsService.getUserDetails(), // 2
        services.userSettingsService.getUsers(), // 3
        [],// services.queryService.getScores(), // 4
        services.notificationService.getNotifications(),//5
        // services.queryService.getSubscriptionUpdates(), // 5
        services.customInputService.getDisplayTemplates(), // 6
        services.customInputService.getSourceFields(), // 7
        services.inputService.getFilestackUploadPolicy(), // 8
        services.inputService.getFilestackViewPolicy(), // 9
        services.classificationsService.getClassifications(), // 10
        services.adminService.getKnowledgelevels(), // 11
        services.scoreboardService.getCurrntChallengeStatus(),// 12
        services.customInputService.getCustomInputMapping(), //13
        services.inputService.getCloudFrontSignature(), //14
        services.groupService.getOpenCollections(), //15
        services.defaultInputService.getAllTemplates(), //16           
        services.billingService.getCurrentSubscription(), // 17
        services.collaborationService.getCurrentTimeTracking(), // 18
        services.adminService.getAllOrgLabel(), // 19
        services.adminService.getMenuItems(), //20
        services.adminService.getLaunchpadMenuItems(), //21
    ])
        .then((values) => {

            const trends = values[0];
            const userData = values[1];
            const userDetails = values[2];
            const users = values[3] || []
            const notifications = values[5];
            const displayTemplates = values[6];
            const sourceFields = values[7];
            const uploadPolicy = values[8];
            const readPolicy = values[9];
            const classifications = values[10];
            const knowledgelevels = values[11];
            const currntChallengeStatus = values[12];
            const inputMappings = values[13] || [];
            const cloudfront = values[14];
            const openCollections = values[15]
            const defaultInputTemplates = values[16]
            const mostActivityUsersInCompany = []//values[17]
            const currentSubscription = values[17]
            const activeUsers = values[3].filter(x => x.status === 0);

            const timeTracking = values[18]
            const orgLabels = values[19]
            const menuItems = values[20] //20
            const launchpadMenuItems = values[21] //21

            if(timeTracking) {
    
                    if(timeTracking.status === "Not tracking") {
                        /// reset time tracker 
                        dispatch(disabledTimer())
                        dispatch(setCurrentTime(null))
                        dispatch(setTimeTrackingTask(null))
                        /// 
                        } else {
                            dispatch(fetchDocuments(SERVER_API)([timeTracking.docId]))
                            dispatch(activateTimer())
                            dispatch(setCurrentTime(new Date(timeTracking.start)))
                            dispatch(setActiveTaskId(timeTracking.docId))
                            dispatch(setTimeTrackingTask(null))
                        }
            }

            fixImage(cloudfront, userDetails)
            users.forEach(_userDetails => fixImage(cloudfront, _userDetails))
            activeUsers.forEach(_userDetails => fixImage(cloudfront, _userDetails))

            if (userDetails.lang && userDetails.lang !== i18n.language) {
                i18n.changeLanguage(userDetails.lang)
                moment.locale(userDetails.lang)
            }

            // if(userDetails) {
            //     managerTableKeepGlobalData.setUserCollections(userData?.collections ?? [])
            //     managerTableKeepGlobalData.setOpenCollections(openCollections ?? [])
            // }


            if(userDetails) {
                const query = getQuery(userDetails)
                services.queryService.Search(query, null, false).then((result) => {
                    const data = result?.data ?? []
                    dispatch({type: 'SET_TODO_COUNT', count: result?.count ?? 0})
                    const allCollections = getAllCollection(userData.collections, openCollections);
                    const todoTasks = buildOngoingTasks(data, allCollections)
                    dispatch({type: 'SET_TODO_TASKS', todoTasks})
                })
            }
            if (!currntChallengeStatus.scores || currntChallengeStatus.scores.length === 0) {

                services.queryService.getScores().then(scores => {
                    let scoresResult = scores.sort(sortScoresDesc)
                    if(isEqualJson(globalData.scores.sort(sortScoresDesc), scoresResult)) return
                    dispatch(globalDataLoad({
                        scores: scoresResult,
                    }));
                })
            }
            const newGlobal = {}
            
            if(!isEqualJson(globalData.tuqqiVersion, process.env.CURRENT_VERSION)) {
                newGlobal.tuqqiVersion = process.env.CURRENT_VERSION;
            }
            if(!isEqualJson(globalData.trends, trends)) {
                newGlobal.trends = trends;
            }
            if(!isEqualJson(globalData.templates, displayTemplates)) {
                newGlobal.templates = displayTemplates;
            }
            if(!isEqualJson(globalData.inputMappings, inputMappings)) {
                newGlobal.inputMappings = inputMappings;
            }
            if(!isEqualJson(globalData.profileData, userDetails)) {
                newGlobal.profileData = userDetails;
            }
            if(!isEqualJson(globalData.users, users)) {
                newGlobal.users = users;
            }
            if(!isEqualJson(globalData.activeUsers, activeUsers)) {
                newGlobal.activeUsers = activeUsers;
            }
            if(!isEqualJson(globalData.sourceFields, sourceFields)) {
                newGlobal.sourceFields = sourceFields;
            }
            if(!isEqualJson(globalData.filestack, {view: readPolicy, write: uploadPolicy})) {
                newGlobal.filestack = {view: readPolicy, write: uploadPolicy};
            }
            if(!isEqualJson(globalData.classifications, classifications.sort((a, b) => a.level < b.level))) {
                newGlobal.classifications = classifications.sort((a, b) => a.level < b.level);
            }
            if(!isEqualJson(globalData.knowledgelevels, knowledgelevels)) {
                newGlobal.knowledgelevels = knowledgelevels ?? [];
            }
            if(!isEqualJson(globalData.currntChallengeStatus, currntChallengeStatus)) {
                newGlobal.currntChallengeStatus = currntChallengeStatus;
            }
            if(!isEqualJson(globalData.cloudfront, cloudfront)) {
                newGlobal.cloudfront = cloudfront;
                newGlobal.cloudfrontAdditional = {
                    lastFetch: new Date().getTime(),
                    isFetching: false
                }
            }
            if(!isEqualJson(globalData.openCollections, openCollections)) {
                newGlobal.openCollections = openCollections;
            }
            if(!isEqualJson(globalData.defaultInputTemplates, defaultInputTemplates)) {
                newGlobal.defaultInputTemplates = defaultInputTemplates;
            }
            if(!isEqualJson(globalData.mostActivityUsersInCompany, mostActivityUsersInCompany)) {
                newGlobal.mostActivityUsersInCompany = mostActivityUsersInCompany;
            }
            const subsc = currentSubscription ?? getDefaultSubscriptionOnError()
            managerShouldUseScheduler.provideSubscriptionType(subsc.subscriptionType)
            managerShouldUseGantt.provideSubscriptionType(subsc.subscriptionType)
            managerAdminSubscriptionType.setSubscriptionType(subsc.subscriptionType)
            if(!isEqualJson(globalData.currentSubscription, subsc)) {
                newGlobal.currentSubscription = subsc;
            }
            if(!isEqualJson(globalData.orgLabels,  orgLabels)) {
                newGlobal.orgLabels = orgLabels;
            }
           
            const {managedGroups, subscribedGroups, favouritesGroups} = initGroups(userData.collections, userDetails.id, cloudfront, userData.favoriteCollections || [])

            dispatch(setFeedMenuItems(menuItems))
            dispatch({
                type: 'SET_LAUNCHPAD_OPTIONS',
                allowedOptions: launchpadMenuItems
            })

            dispatch(initSearchFavouritesGroups(favouritesGroups))
            dispatch(initLandingUsers(activeUsers))
            dispatch(initSearchSubscribedUsers(activeUsers, userData.subscribedTags))
            dispatch(initSearchSubscribedGroups(subscribedGroups))
            dispatch(initSearchManagedGroups(managedGroups))
    
            //needs move logic to searchNavigation
            dispatch(setManagedGroups(managedGroups))
            dispatch(setSubscribedGroups(subscribedGroups))

            dispatch(globalDataLoad({
                ...newGlobal,
            }));
            const newUserData = {}

            if(!isEqualJson(userDataGlobal.profileData, userDetails)) {
                newUserData.profileData = userDetails;
            }
            if(!isEqualJson(userDataGlobal.notifications, notifications)) {
                newUserData.notifications = notifications;
            }
            newUserData.subscribedTags = [];
            
            dispatch(userDataLoad({
                ...newUserData,
                ...userData
            }));

            services.notificationService.subscribedTags(userDetails.lastVisit).then(tags => {
                dispatch(userSubscribedTagsLoad(tags));
            })

            services.notificationService.collections(userDetails.lastVisit).then(groups => {
                if (groups) {
                    groups.forEach(g => {
                        const collection = userData.collections.find(c => c.collectionGroup.groupUid === g.groupUid)
                        collection && (collection.numNewItems = g.numNewItems)
                    })
                    if(isEqualJson(userDataGlobal.collections, userData.collections)) return
                    dispatch(userCollectionLoad(userData.collections))
                }
            })
            dispatch({type: "CLEAR_SUSPENDED_ERROR"})
            !isLibrary() && managerFetchedCollections.fetch()

            return resolve()
        }).catch(x => {
            return reject(x);
        })
    })
}
