import React, { Component } from 'react';
import { connect } from 'react-redux'

import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'

import './PhotoMessage.css';

import FaCloudDownload from 'react-icons/lib/fa/cloud-download';
import FaError from 'react-icons/lib/fa/exclamation-triangle';
import { getFileLinkFromCloundfront } from '../../../../utils/filesUtils.js';
import { Typography } from '@material-ui/core';

const ProgressBar = require('react-progress-bar.js');
const Circle = ProgressBar.Circle;

export class VideoMessage extends Component {
    onOpen = () => {
        const { cloudfront, attachment } = this.props
        const  { fileUid } = attachment

        const fullUrl = getFileLinkFromCloundfront(cloudfront, fileUid)

        window.open(fullUrl, '_blank')
    }

    render() {
        const { cloudfront, attachment } = this.props
        const  { fileName, fileUid } = attachment

        const fullUrl = getFileLinkFromCloundfront(cloudfront, fileUid)
     



        var progressOptions = {
            strokeWidth: 2.3,
            color: '#efe',
            trailColor: '#aaa',
            trailWidth: 1,
            step: (state, circle) => {
                circle.path.setAttribute('trail', state.color);
                circle.path.setAttribute('trailwidth-width', state.width);

                var value = Math.round(circle.value() * 100);
                if (value === 0)
                    circle.setText('');
                else
                    circle.setText(value);
            }
        };

        const error = this.props.data.status && this.props.data.status.error === true;

        return (
            <div data-intrcm-id="VideoMessage_5ysi3k0n" className="rce-mbox-photo">
                <div data-intrcm-id="VideoMessage_x9jrg38m"
                    className="rce-mbox-photo--img"
                    style={this.props.data.width && this.props.data.height && {
                        width: this.props.data.width,
                        height: this.props.data.height,
                    }}>
                    <video
                        style={{ maxWidth: 500 }}
                        alt={this.props.data.alt}
                        onClick={this.onOpen}
                        onLoad={this.props.onLoad}                        
                        controls="controls"                        
                        src={fullUrl}/>
                    {
                        error &&
                        <div data-intrcm-id="VideoMessage_jkzm5vz6" className="rce-mbox-photo--img__block">
                            <span
                                className="rce-mbox-photo--img__block-item rce-mbox-photo--error">
                                <FaError/>
                            </span>
                        </div>
                    }
                    {
                        !error &&
                        this.props.data.status &&
                        !this.props.data.status.download &&
                        <div data-intrcm-id="VideoMessage_2mlkdbar" className="rce-mbox-photo--img__block">
                            {
                                !this.props.data.status.click &&
                                <button
                                    onClick={this.props.onDownload}
                                    className="rce-mbox-photo--img__block-item rce-mbox-photo--download">
                                    <FaCloudDownload/>
                                </button>
                            }
                            {
                                typeof this.props.data.status.loading === 'number' &&
                                this.props.data.status.loading !== 0 &&
                                <Circle
                                    progress={this.props.data.status.loading}
                                    options={progressOptions}
                                    initialAnimate={true}
                                    containerClassName={'rce-mbox-photo--img__block-item'} />
                            }
                        </div>
                    }
                </div>
                <Typography style={{fontSize: 14, maxWidth: 500, textAlign: 'center', marginTop: 8}} >{fileName}</Typography>
            </div>
        );
    }
}

VideoMessage.defaultProps = {
    text: '',
    data: {},
    onDownload: null,
    onOpen: null,
    onLoad: null,
};

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})


export default connect(mapStateToProps)(withErrorCatcher()(VideoMessage));
