import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';

const maxSize = 10
const MemberImageSize = 16

const styles = theme => ({
    container: {
        display: 'inline-flex',
    },
    avatarContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: MemberImageSize,
        height: MemberImageSize,
        borderRadius: '50%',
        boxSizing: "border-box",
        marginLeft: -8,
        '&:first-child': {
            marginLeft: 0,
        },
    },
    avatar: {
        width: '100%',
        height: '100%',
        display: 'block',
    },
    plusAvatarsContainer: {
        minWidth: MemberImageSize,
        height: MemberImageSize,
        cursor: 'pointer',
        marginLeft: -8,
        zIndex: 2,
        borderRadius: '50%',
        background: '#daddee',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    text: {
        paddingLeft: 4,
        paddingRight: 4,
        color: theme.customColors.primary.main,
        fontWeight: 500,
        fontSize: 8,
        fontFamily: 'Rubik',
    }
})

class ToolTipMembers extends PureComponent {
    members = () => {
        const { docMembers, users } = this.props
        const members = []

        docMembers.forEach(member => {
            const user = users.find(u => u.auth0_id == member.userUUID)
            if (user) {
                members.push({
                    userName: member.userName,
                    profileImageUrl: user.profileImageUrl
                })
            }
        })
        return members
    }

    render() {
        const { classes, docMembers, users, } = this.props

        let members = docMembers.slice(0, maxSize).map(member => {
            const user = users.find(u => u.auth0_id === member.userUUID)
            return (
                <span key={member.userUUID} className={classes.avatarContainer}>
                    <img key={member.userUUID} className={classes.avatar} src={user.profileImageUrl} alt={member.userName} />
                </span>
            )
        })

        return (
            <Grid className={classes.container}>
                {members}
                {maxSize && docMembers.length - maxSize > 0 ?
                    <span className={classes.plusAvatarsContainer}>
                        <div data-intrcm-id="TooltipMembers_4du601hp" className={classes.text}>{'+'+(docMembers.length - maxSize)}</div>
                    </span>
                    : null}
            </Grid>
        )
    }
}


const mapStateToProps = (state) => ({
    users: state.globalData.users
})

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        null
    ))(ToolTipMembers);