export default class DefaultInputService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    
        const Authorization = `Bearer ${token}`

        this.getHeaders = new Headers({
            Authorization        
        });

        this.postHeaders = new Headers({
            Authorization,        
            'Content-Type': 'application/json'
        });

        this.controllerUrl = `${process.env.API_SERVER}/default-input`
    }

    getAllTemplates = () => {

        return fetch(`${this.controllerUrl}/all`,
            {
                method: 'GET',
                headers: this.getHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });
    }

    updateTemplate = (updateTemplate) => {

        return fetch(`${this.controllerUrl}/update-template`,
            {
                method: 'POST',
                headers: this.postHeaders,
                body: JSON.stringify(updateTemplate)
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            })
    }
}