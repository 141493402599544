import { Icon, Tooltip, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import FeedIcon, { FeedPXIcon } from '../Icons/GroupIcons/FeedIcon';
import KanbanIcon, { KanbanPXIcon } from '../Icons/GroupIcons/KanbanIcon';

import FavouritesIcon from '../Icons/MyPageIcons/FavouritesIcon';
import PersonIcon from '../Icons/MyPageIcons/PersonIcon';
import ReportIcon, { ReportPXIcon } from '../Icons/MyPageIcons/ReportIcon';

import SearchMediaIcon from '../Icons/SearchIcons/SeachMediaIcon';
import SearchAllIcon from '../Icons/SearchIcons/SearchAllIcon';
import SearchFileIcon from '../Icons/SearchIcons/SearchFileIcon';
import SearchLinkIcon from '../Icons/SearchIcons/SearchLinkIcon';
import SearchLocationIcon, { MapPXIcon } from '../Icons/SearchIcons/SearchLocation';
import SearchMoreIcon from '../Icons/SearchIcons/SearchMoreIcon';
import SearchPostIcon from '../Icons/SearchIcons/SearchPostIcon';

import { Edit, Timeline } from '@material-ui/icons';
import CalendarIcon, { CalendarPXIcon } from '../Icons/UserIcons/CalendarIcon';
import LeaveGroupIcon from '../Icons/UserIcons/LeaveGroupIcon';
import MessageIcon from '../Icons/UserIcons/MessageIcon';
import OngoingIcon from '../Icons/UserIcons/OngoingIcon';
import SettingsIcon, { SettingsPXSwitcher } from '../Icons/UserIcons/SettingsIcon';
import StatisticsIcon, { StatisticsPXIcon } from '../Icons/UserIcons/StatisticsIcon';
import SubscribeIcon from '../Icons/UserIcons/SubscribeIcon';

import { withTranslation } from 'react-i18next';
import { createCustomTheme } from '../../../../utils/theming';
import { hexToRGB } from '../../../views/groups/Calendar/event-utils';
import GanttIcon, { GanttPXIcon } from '../Icons/AppInUse/GanttIcon';
import KnowledgeIcon from '../Icons/AppInUse/KnowledgeIcon';
import HashTagIcon from '../Icons/ChannelIcons/hashTagIcon';
import ChatIcon, { ChatPXIcon } from '../Icons/GroupIcons/ChatIcon';
import HomeIcon, { HomePXIcon } from '../Icons/GroupIcons/HomeIcon';
import SchedulerIcon, { SchedulerPXIcon } from '../Icons/GroupIcons/SchedulerIcon';
import { NotificationsIcon } from '../Icons/MyPageIcons/Notifications';
import TagChannelIcon from '../Icons/SearchIcons/TagChannelIcon';
import ConnectorsIcon, { ConnectorsPXIcon } from '../Icons/UserIcons/ConnectorsIcon';
import { ProfileIcon } from '../Icons/UserIcons/ProfileIcon';

const styles = theme => { 
    const primaryColor = theme.customColors.primary.b300
    const lighterColor = hexToRGB(primaryColor, 0.08)

    return ({
    subscribedIcon: {
        transform: 'rotate(45deg)'
    },
    list: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'fit-content',
        background: 'transparent',
        alignItems: 'flex-start'
    },
    item: {
        borderRadius: 6,
        width: 192,
        height: 40,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.newUI.color.commonBlack,
        '&:hover': {
            backgroundColor: lighterColor,
            color: primaryColor
        }
    },
    itemPX: {
        borderRadius: 6,
        width: 216,
        height: 32,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: "#6B6F7A",
        '&:hover': {
            backgroundColor: "#E8EBFD",
        }
    },
    iconSvgHover: {
        backgroundColor: lighterColor 
    },
    iconSvg: {
        
    },
    itemSmall: {
        width: 40,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        height: 40,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: lighterColor 
        }
    },
    itemSmallPX: {
        width: 34,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        height: 34,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: "#E8EBFD",
        }
    },
    disabledTooltipItem: {
        '&:hover': {
            backgroundColor: 'unset!important',
            color: `${theme.newUI.color.commonBlack}!important`
        }
    },
    itemSmallSelected: {
        width: 40,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        height: 40,
        borderRadius: 8,
        backgroundColor: lighterColor 
    },
    itemSmallSelectedPX: {
        width: 34,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        height: 34,
        borderRadius: 8,
        backgroundColor: lighterColor 
    },
    itemHashTagSmall: {
        width: '100%',
        padding: '10px 15px',
        fontSize: 16,
    },
    itemTitle: {
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: 1.25,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    topLabel: {
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: '20px',
        marginTop: 4,
        marginBottom: 8,
        marginLeft: 12,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
    },
    itemTitlePX: {
        fontFamily: 'Rubik',
        fontSize: 14,
        color: "#6B6F7A",
        lineHeight: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    icon: {
		minWidth: 24,
        margin: 8,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
	iconTag: {
		display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
		marginRight: '8px'
	},
    iconHolderClassSmall: {
        color: '#fff',
        fontSize: 14,
    },
    iconSmall: {
        width: 40,
        height: 40,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    iconSmallPX: {
        width: 34,
        height: 34,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    iconSmallHashTag: {
        width: 40,
        height: 40,
        cursor: 'pointer',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '6px auto',
        '&:hover': {
            backgroundColor: lighterColor 
        }
    },
    selectedIcon: {
        cursor: 'pointer',
    },
    selectedItem: {
        backgroundColor: lighterColor,
        color:  primaryColor
    },
    selectedItemPX: {
        backgroundColor: 'rgba(183, 184, 189, 0.2)',
        color:  "#6B6F7A",
    },
    submenuList: {
        marginTop: 0,
        paddingLeft: 46,
        listStyle: 'none',
        width: '80%',
    },
    submenuListTooltipe: {
        margin: 0,
        padding: 0,
    },
    submenuTolltipeItem: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 15,
        fontSize: 15,
        color: '#fff',
        textTransform: 'capitalize',
        listStyle: 'none',
        cursor: 'pointer',
    },
    submenuTolltipeItemActive: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 15,
        fontSize: 15,
        color: '#fff',
        textTransform: 'capitalize',
        listStyle: 'disc',
        fontWeight: 'bold',
        cursor: 'pointer',
        
    },
    tooltip: {
        borderRadius: 6,
        backgroundColor: '#4A4A4F',
        padding: 0,
        borderRadius: 4,
      
    },
    tooltipDisabled: {
        maxWidth: 'fit-content!important'
    },
    tooltipeTitle: {
        fontSize: 12,
        padding: '4px 8px',
        fontfamily: 'Rubik',
        lineHeight: '16px',
        color: '#FFFFFF',
    },
    // optionTooltipeHolder: {
    //     color: '#fff'
    // },
    triangle: {
        position: 'absolute',
        top: '50%',
        left: -3,
        marginTop: -3.5,
        transform: 'rotate(-45deg)',
        border: `solid 3px #4A4A4F`,
        borderRight: `3px solid transparent`,
        borderBottom: `3px solid transparent`,
        zIndex: '9999'
    },
    optionSmallHolder: {
        width: '90px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
    },
	showAll: {
		display: 'flex',
		flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
		margin: '9px 8px 0 8px',
	},
	showAllText: {
		fontFamily: 'Rubik',
		fontSize: 14,
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: "20px",
		color: '#6B6F7A',
		marginLeft: 8,
	},
	divider: {
		backgroundColor: "rgba(183, 184, 189, 0.15)",
		height: '1px',
		width: '100%',
	},
	channelItem: {
		display: "flex",
		flexDirection: 'row',
		alignItems: 'center',
		boxSizing: 'border-box',
		width: 224,
		height: 32,
		margin: '1px 8px 0 8px',
		padding: "6px 0px",
		borderRadius: 8,
		marginLeft: 16,
		color: "#6B6F7A",
		"&:hover": {
			backgroundColor: 'rgba(183, 184, 189, 0.15)',
			color: "#18181B",
		}
	},
	channelListTitle: {
		fontFamily: 'Rubik',
		fontSize: 14,
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: "20px",
		color: "#18181B",
		margin: "14px 16px 6px 16px",
	},
    })
}

const __DrawerNavigation = (props) => {
    const {
        options,
        classes,
        selectedOptionId,
        submenu,
        showSubmenu,
        handleClick,
        handleOptionClick,
        paramsQuery,
        isDrawerOpen = true,
        pxSwitcher = false,
        topLabel = undefined,
        currentTags,
        query,
        selectChannelHandler,
        profileData,
        isChannel,
		t
    } = props

    const { listOptions } = submenu
    const [listCurrentOptions, setListCurrentOptions] = useState([])
    const [isChannelsOption, setIsChannelsOptions] = useState(false)
	const [isAllTags, setIsAllTags] = useState(false)
    const [listCurrentTrends, setListCurrentTrends] = useState([])
    const themeOption = profileData.org?.brandingColor ?? 'default'
    const theme = createCustomTheme(themeOption)
    const defaultSvgColor = pxSwitcher ? "#6B6F7A" : '#737373'


    useEffect(() => {
        setListCurrentOptions(listOptions)
    }, [listOptions])


    useEffect(() => {
        if (query && query.startsWith('#')) {
            setIsChannelsOptions(true)
            let listTrends = currentTags.map(item => item.key)
            listTrends.unshift(query.slice(1, query.length))
            setListCurrentTrends([...new Set(listTrends)])
        } else {
            setIsChannelsOptions(false)
            setListCurrentTrends([])
        }

    }, [query, currentTags])

    if (  !options || !options.length  &&!isChannel ) return null

    const getTooltipText = (optionKey, optionTitle) => {
        switch (optionKey) {
            case 'table':
                return t("Table")
        
            default:
                return optionTitle
        }
    }

    const renderIcon = (option, isSelected, isHoverOn) => {
        let color = isSelected || isHoverOn ? theme.primary.b300 : defaultSvgColor 

        switch (option) {
            // icons from search screen 
            case 'Search':
                return <SearchAllIcon color={color} />
            case 'Chat':
                if(pxSwitcher) return <ChatPXIcon color={color} />
                return <ChatIcon color={color} />
            case 'Media':
                return <SearchMediaIcon color={color} />
            case 'File':
                return <SearchFileIcon color={color} />
            case 'Post':
                return <SearchPostIcon color={color} />
            case 'Locations':
                if(pxSwitcher) return <MapPXIcon />
                return <SearchLocationIcon color={color} />
            case 'Link':
                return <SearchLinkIcon color={color} />
            case 'More':
                return <SearchMoreIcon color={color} />
            //
            // icons to delete ?
            case 'EditTag':
                return <Edit style={{color: '#555563'}} />
            case 'Message':
                return <MessageIcon color={color} />
            case 'Subscribe':
                return <SubscribeIcon color={color} />
            case 'Unsubscribe':
                return <SubscribeIcon className={classes.subscribedIcon} color={color} />
            case 'Timeline':
                return <Timeline style={{ color: color, width: 22, height: 22 }} />
            //

                
            // new icons
            case 'Feed':
                if(pxSwitcher) return <FeedPXIcon />
                return <FeedIcon color={color} />
            case 'Overview':
                if(pxSwitcher) return <FeedPXIcon />
                return <FeedIcon color={color} />
            case 'Home':
                if(pxSwitcher) return <HomePXIcon color={color} />
                return <HomeIcon color={color} />
            case 'Kanban':
                if(pxSwitcher) return <KanbanPXIcon />
                return <KanbanIcon color={color} />
            case 'Scheduler':
                if(pxSwitcher) return <SchedulerPXIcon />
                return <SchedulerIcon color={color} />
            case 'Gantt':
                if(pxSwitcher) return <GanttPXIcon />
                return <GanttIcon color={color} />
            case 'Knowledge':
                return <KnowledgeIcon color={color} />
            // copy 
            case 'View on Map':
                if(pxSwitcher) return <MapPXIcon />
                return <SearchLocationIcon color={color} />
            case 'Map':
                if(pxSwitcher) return <MapPXIcon />
                return <SearchLocationIcon  color={color} />
            case 'Calendar':
                if(pxSwitcher) return <CalendarPXIcon />
                return <CalendarIcon color={color} />
            // case 'Favourites':
            //     return <FavouritesIcon color={color} />
            case 'Notifications':
                return <NotificationsIcon color={color} />
            case 'DateRange':
                return <CalendarIcon color={color} />
            case 'Reports':
                if(pxSwitcher) return <ReportPXIcon />
                return <ReportIcon color={color} />
            case 'List':
                if(pxSwitcher) return <ReportPXIcon />
                return <ReportIcon color={color} />
            //
            case 'Person':
                return <PersonIcon color={color} />
            case 'Favorite':
                return <FavouritesIcon color={color} /> 
            case 'Ongoing':
                return <OngoingIcon color={color} />
            case 'Statistics':
                if(pxSwitcher) return <StatisticsPXIcon />
                return <StatisticsIcon color={color} />
            case 'Profile':
                return <ProfileIcon color={color} />
            // icon that most likely need to be upated
            // case 'Dashboard':
            //     return <StatisticsIcon color={color} />
            case 'LeaveGroup':
                return <LeaveGroupIcon color={color} />
            case 'Settings':
                if(pxSwitcher) return <SettingsPXSwitcher color={color} />
                return <SettingsIcon isDrawer color={color} />
            //
            case 'Connectors':
                if(pxSwitcher) return <ConnectorsPXIcon color={color} />
                return <ConnectorsIcon color={color} />
            default:
                return null
        }
    }

    const renderSubmenuIcon = (icon, isSelected) => {
        if (!icon) return null
        const iconClass = isSelected ? classes.iconColorIsSelected : classes.iconColorCommon;

        return <Icon className={iconClass}>{icon}</Icon>
    }

    const Option = ({ content = false, option, isSelected,isHoverOn, iconHolderClass, showSubmenu, listOptions = [] }) => {

        return (
            <>
                {!content && <div data-intrcm-id="DrawerNavigation_00qjuyuk" 
                 className={iconHolderClass}>{renderIcon(option.icon, isSelected,isHoverOn)}</div>}
                <div data-intrcm-id="DrawerNavigation_p9ytlny2" className={`${!!content ? classes.tooltipeTitle : ""} ${pxSwitcher ? classes.itemTitlePX : classes.itemTitle}`} >{getTooltipText(option.key, option.title)}</div>
                {content && showSubmenu && option.title === 'More' && listOptions.length ?
                    <ul className={classes.submenuListTooltipe}>
                        {listOptions.map(o => {
                            const classItem = paramsQuery.substring(8, paramsQuery.length) === o.title ? classes.submenuTolltipeItemActive : classes.submenuTolltipeItem
                            return <li key={o.id} className={classItem} onClick={isSelected ? undefined : () => { handleOptionClick(o) }}>{o.title}</li>
                        })}
                    </ul>
                    : null}
                {content && <div data-intrcm-id="DrawerNavigation_4rvqcwgk" className={classes.triangle}></div>}
            </>
        )
    }

    const OptionDisabledTooltip = ({ content = false, option, isSelected,isHoverOn, iconHolderClass, showSubmenu, listOptions = [] }) => {
        const Opt = <div style={{display: 'flex', alignItems: 'center'}}>
        {!content && <div data-intrcm-id="DrawerNavigation_00qjuyuk" 
         className={iconHolderClass}>{renderIcon(option.icon, false,false)}</div>}
        <div data-intrcm-id="DrawerNavigation_p9ytlny2" className={`${!!content ? classes.tooltipeTitle : ""} ${pxSwitcher ? classes.itemTitlePX : classes.itemTitle}`} >{option.title}</div>
        {content && showSubmenu && option.title === 'More' && listOptions.length ?
            <ul className={classes.submenuListTooltipe}>
                {listOptions.map(o => {
                    const classItem = paramsQuery.substring(8, paramsQuery.length) === o.title ? classes.submenuTolltipeItemActive : classes.submenuTolltipeItem
                    return <li key={o.id} className={classItem} onClick={isSelected ? undefined : () => { handleOptionClick(o) }}>{o.title}</li>
                })}
            </ul>
            : null}
        {content && <div data-intrcm-id="DrawerNavigation_4rvqcwgk" className={classes.triangle}></div>}
        </div>
        return <Tooltip 
            placement="right"
            interactive
            title={<Option content option={{title: option.disabledTooltipText, icon: option.icon}} isSelected={isSelected} isHoverOn={isHoverOn} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listOptions} />}
            classes={{ tooltip: `${classes.tooltip} ${classes.tooltipDisabled}` }}
        >
        {Opt}
        </Tooltip>
    }

    const SubOption = ({ option, isSelected, iconHolderClass }) => {

        return (
            <>
                <div data-intrcm-id="DrawerNavigation_id85y3hs" className={iconHolderClass}>{renderSubmenuIcon(option.icon, isSelected)}</div>
                <div data-intrcm-id="DrawerNavigation_mazp7cji" className={pxSwitcher ? classes.itemTitlePX : classes.itemTitle}>{option.title}</div>
            </>
        )
    }

    const OptionSmall = ({ option, isSelected, iconHolderClass,isHoverOn }) => {
        return (
            <Tooltip 
                placement="right"
                interactive
                title={<Option content option={option} isSelected={isSelected} isHoverOn={isHoverOn} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listOptions} />}
                classes={{ tooltip: classes.tooltip }}
            >
                <div data-intrcm-id="DrawerNavigation_6js5osxw" className={classes.optionSmallHolder}>
                    <div data-intrcm-id="DrawerNavigation_nhyuskqg" className={iconHolderClass}>{renderIcon(option.icon, isSelected,isHoverOn)}</div>
                </div>
            </Tooltip>
        )
    }

    const OptionSmallDisabledTooltip = ({ option, isSelected, iconHolderClass,isHoverOn }) => {
        return (
            <Tooltip 
                placement="right"
                interactive
                title={<Option content option={{title: option.disabledTooltipText, icon: option.icon, key: option.key}} isSelected={isSelected} isHoverOn={isHoverOn} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listOptions} />}
                classes={{ tooltip: `${classes.tooltip} ${classes.tooltipDisabled}` }}
            >
                <div data-intrcm-id="DrawerNavigation_6js5osxw" className={classes.optionSmallHolder}>
                    <div data-intrcm-id="DrawerNavigation_nhyuskqg" className={`${iconHolderClass}`}>{renderIcon(option.icon, false,false)}</div>
                </div>
            </Tooltip>
        )
    }

    const Channel = ({ item, itemClass, iconHolderClass, isSelected, content }) => {
        const [isHoverOn ,setIsHoverOn] = useState(false)
        const color = isSelected || isHoverOn? theme.primary.b300 : defaultSvgColor

        return (
            <div data-intrcm-id="DrawerNavigation_ewqxk2u3" className={itemClass}
             onClick={() => selectChannelHandler(item)}
             onMouseEnter={() => setIsHoverOn(true)}
             onMouseLeave={() => setIsHoverOn(false)}
             >
                <div data-intrcm-id="DrawerNavigation_zwbcuq7k" className={!content ? iconHolderClass : classes.iconHolderClassSmall}>
                    {!content && <TagChannelIcon color={color} />}
                </div>
                <div data-intrcm-id="DrawerNavigation_e0pl55aj"  className={`${!!content ? classes.tooltipeTitle : ""} ${pxSwitcher ? classes.itemTitlePX : classes.itemTitle}`}>{item}</div>
                {content && <div data-intrcm-id="DrawerNavigation_cgsmyxrr" className={classes.triangle}></div>}
            </div>)
    }

    const ChannelSmall = ({ item, itemClass, iconHolderClass, isSelected }) => {
        const [isHoverOn ,setIsHoverOn] = useState(false)
        const color = isSelected || isHoverOn ? theme.primary.b300 : defaultSvgColor
        const lighterColor = hexToRGB(theme.primary.b300, 0.08)
        return (
            <div data-intrcm-id="DrawerNavigation_vtj5853s" 
        
            onClick={() => selectChannelHandler(item)}
            onMouseEnter={() => setIsHoverOn(true)}
            onMouseLeave={() => setIsHoverOn(false)}
            >
                <Tooltip placement="right"
                    interactive
                    title={<Channel item={item} itemClass={itemClass} iconHolderClass={iconHolderClass} isSelected={isSelected} content={true} />}
                    classes={{ tooltip: classes.tooltip }}
                >
                    <div data-intrcm-id="DrawerNavigation_dzf6u3m1" className={iconHolderClass}     style={{backgroundColor: isSelected || isHoverOn ?  lighterColor : 'transparent' }}><HashTagIcon color={color} /></div>
                </Tooltip>
            </div>
        )
    }
   
    const DrawerItem = ({option,iconHolderClass,index, itemClass, isSelected}) => {
        const [isHoverOn ,setIsHoverOn] = useState(false)
        const disabledTooltip = option.isDisabled && !!option.disabledTooltipText
        const pointerEvents = option.isDisabled && !disabledTooltip ? 'none' : 'auto'
        return (
            <div data-intrcm-id="DrawerNavigation_1tdqhb8h"
            id="option-label"
            aria-controls="option-menu"
            aria-haspopup="true"
            aria-expanded={showSubmenu ? 'true' : undefined}
            onClick={(e) =>
                isSelected ? undefined : option.isDisabled ? e.preventDefault() : handleClick(e, option)
            }
            onMouseEnter={() => setIsHoverOn(true)}
            onMouseLeave={() => setIsHoverOn(false)}
            key={index}
            className={`${itemClass} ${disabledTooltip ? classes.disabledTooltipItem : ''}`} style={{ opacity: option.isDisabled ? 0.6 : 1, pointerEvents}}>
            {isDrawerOpen
                ? disabledTooltip ?
                    <OptionDisabledTooltip option={option} isSelected={isSelected} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listCurrentOptions} isHoverOn={isHoverOn}/> :
                    <Option option={option} isSelected={isSelected} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listCurrentOptions} isHoverOn={isHoverOn}/>
                :  disabledTooltip ?
                    <OptionSmallDisabledTooltip option={option} isSelected={isSelected} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listCurrentOptions} isHoverOn={isHoverOn} /> :
                    <OptionSmall option={option} isSelected={isSelected} iconHolderClass={iconHolderClass} showSubmenu={showSubmenu} listOptions={listCurrentOptions} isHoverOn={isHoverOn} />}
        </div>
        )
    }

	const tagsToShow = !isAllTags ? listCurrentTrends.slice(0, 3) : listCurrentTrends;

    return (
        <>
        {isDrawerOpen && pxSwitcher &&  <div className={classes.topLabel}>{topLabel}</div>}
        <div data-intrcm-id="DrawerNavigation_zadu9utn" className={classes.list}>
            {!!options.length && options.map((option, index) => {

                const isSelected = selectedOptionId === option.id && option.icon !== 'More'

                const itemClass = isDrawerOpen ?
                    isSelected ? `${pxSwitcher ? classes.itemPX : classes.item} ${pxSwitcher ? classes.selectedItemPX : classes.selectedItem}` : pxSwitcher ? classes.itemPX : classes.item
                    : pxSwitcher ? isSelected ?  classes.itemSmallSelectedPX : classes.itemSmallPX :
                    isSelected ?  classes.itemSmallSelected : classes.itemSmall
                const iconHolderClass = isDrawerOpen ?
                    isSelected ? `${classes.icon} ${classes.selectedIcon}` : classes.icon
                    : pxSwitcher ? isSelected ? `${classes.iconSmallPX} ${classes.selectedIcon}` : classes.iconSmallPX :
                    isSelected ? `${classes.iconSmall} ${classes.selectedIcon}` : classes.iconSmall
             
                return (
                    <>
                    <DrawerItem classes={classes} option={option} isSelected={isSelected} iconHolderClass={iconHolderClass} index={index} itemClass={itemClass}/>
                   
                        {showSubmenu && option.title === 'More' && isDrawerOpen ?
                            <ul className={classes.submenuList}>
                                {listCurrentOptions.map(o => {
                                    const isSelected = paramsQuery.substring(8, paramsQuery.length) === o.dataType

                                    const itemClass = isDrawerOpen ?
                                        isSelected ? `${pxSwitcher ? classes.itemPX : classes.item} ${pxSwitcher ? classes.selectedItemPX : classes.selectedItem}` : pxSwitcher ? classes.itemPX : classes.item
                                        : pxSwitcher ? isSelected ? classes.itemSmallSelectedPX : classes.itemSmallPX :
                                        isSelected ? classes.itemSmallSelected : classes.itemSmall
                                    const iconHolderClass = isDrawerOpen ?
                                        isSelected ? `${classes.icon} ${classes.selectedIcon}` : classes.icon
                                        : pxSwitcher ? isSelected ? `${classes.iconSmallPX} ${classes.selectedIcon}` : classes.iconSmallPX :
                                        isSelected ? `${classes.iconSmall} ${classes.selectedIcon}` : classes.iconSmall

                                    return <div data-intrcm-id="DrawerNavigation_3txtm5j8" onClick={isSelected ? undefined : () => handleOptionClick(o)} key={o.id} className={itemClass} >
                                        <SubOption option={o} isSelected={isSelected} iconHolderClass={iconHolderClass} />
                                    </div>
                                })}
                            </ul>
                            : null}
                    </>
                )
            })}
			{isChannelsOption && <div className={classes.channelListTitle}>{t("Other Tags")}</div>}
            {isChannelsOption &&  tagsToShow.map((item) => {
                const isSelected = item === query.slice(1, query.length)

                const itemClass = isDrawerOpen ?
                    isSelected ? `${pxSwitcher ? classes.itemPX : classes.item} ${pxSwitcher ? classes.selectedItemPX : classes.selectedItem}` : pxSwitcher ? classes.itemPX : classes.item
                    : isSelected ? classes.itemHashTagSmall : classes.itemHashTagSmall
                const iconHolderClass = isDrawerOpen ?
                    isSelected ? `${classes.iconTag} ${classes.selectedIcon}` : classes.iconTag
                    : isSelected ? `${classes.iconSmallHashTag} ${classes.selectedIcon}` : classes.iconSmallHashTag

                return (
                    <> {isDrawerOpen ? <Channel item={item} itemClass={classes.channelItem} iconHolderClass={iconHolderClass} isSelected={isSelected} /> : <ChannelSmall item={item} itemClass={itemClass} iconHolderClass={iconHolderClass} isSelected={isSelected} />}</>
                )
            })}
			{isChannelsOption && !isAllTags && <><div  className={classes.divider} style={{ marginTop: 9 }} /><div className={classes.showAll} style={{marginLeft: 16}} onClick={setIsAllTags.bind(null, true)}>
				<TagChannelIcon />
				<div className={classes.showAllText} >
					{t("Browse All Tags")}
				</div>
			</div></> }
        </div>
        </>
    )
}


export const DrawerNavigation = compose(
    withStyles(styles, { withTheme: true }),
	withTranslation('widgetLibrary'),
)(__DrawerNavigation)