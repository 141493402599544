
import { browserHistory } from 'react-router'
import fileDownload from 'react-file-download'
// import fetchJsonp from 'fetch-jsonp'


export default class QueryService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    getCustomInputMapping(){

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            // 'Content-Type': 'application/json'
        });

        return fetch(process.env.API_SERVER + '/custominput',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });

    }

    // Create custom mapping
    createCustomInputMapping = (mapping) => {
        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });

        // if (link.url !== '') {
        return fetch(process.env.API_SERVER + '/custominput', {
            method: 'POST',
            body: JSON.stringify(mapping),
            headers: myHeaders
        });
        // }
    }

        // Update custom mapping
        updateCustomInputMapping = (mapping) => {
    
            var myHeaders = new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json'
            });
    
            // if (link.url !== '') {
            return fetch(process.env.API_SERVER + '/custominput', {
                method: 'PUT',
                body: JSON.stringify(mapping),
                headers: myHeaders
            });
            // }
        }

        // Delete custom mapping
        deleteCustomInputMapping = (id) => {
    
            var myHeaders = new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json'
            });

            return fetch(`${process.env.API_SERVER}/custominput?templateId=${id}`, {
                method: 'DELETE',
                headers: myHeaders
            });
        }

    getDisplayTemplates() {
        // Archive item

        var myHeaders = new Headers({
            'Authorization': 'Bearer ' + this.token,
            // 'Content-Type': 'application/json'
        });


        return fetch(process.env.API_SERVER + '/datatypes/templates',
            {
                method: 'GET',
                headers: myHeaders
            })
            .then(function (res) {
                if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
            });


    }


    getSourceFields() {

        const customField = {
            "name": "Custom",
            "id": 14// change ids
        };
        const constantField = {
            "name": "Constant",
            "id": 15// change ids
        };

        // this will be swapped with http service
        var arrayTmp = [
            {
                "name": "title",
                "id": 1
            },
            {
                "name": "docText",
                "id": 2
            },
            {
                "name": "postText",
                "id": 3
            },
            {
                "name": "docTimestamp",
                "id": 4
            },
            // {
            //     "name": "hasPreview",
            //     "id": 16
            // },
            // {
            //     "name": "hasAttachments",
            //     "id": 16
            // },
            {
                "name": "link",
                "id": 5
            },
            {
                "name": "previewTitle",
                "id": 6
            },
            {
                "name": "previewDescription",
                "id": 7
            },
            {
                "name": "previewImgUrl",
                "id": 8
            },
            // {
            //     "name": "docSize",
            //     "id": 16
            // },
            // {
            //     "name": "orgUUID",
            //     "id": 16
            // },
            // {
            //     "name": "userUUID",
            //     "id": 16
            // },
            {
                "name": "userPicUrl",
                "id": 9
            },
            {
                "name": "userName",
                "id": 10
            },
            {
                "name": "userTag",
                "id": 11
            },
            {
                "name": "dataType",
                "id": 12
            },
            {
                "name": "mimeType",
                "id": 13
            },
            // {
            //     "name": "jsonData",
            //     "id": 14
            // },
            // {
            //     "name": "isPrivate",
            //     "id": 16
            // },
            // {
            //     "name": "permissions",
            //     "id": 16
            // },
            // {
            //     "name": "lastModifiedTime",
            //     "id": 16
            // },
            // {
            //     "name": "modifiedUserName",
            //     "id": 16
            // },
            // {
            //     "name": "indexDate",
            //     "id": 16
            // },
            // {
            //     "name": "seenCounter",
            //     "id": 16
            // },
            // {
            //     "name": "source",
            //     "id": 16
            // },
            // {
            //     "name": "sourceType",
            //     "id": 16
            // },
            // {
            //     "name": "commentsCounter",
            //     "id": 16
            // },
            // {
            //     "name": "timeDims",
            //     "id": 16
            // },
            // {
            //     "name": "attachments",
            //     "id": 16
            // },
            // {
            //     "name": "tags",
            //     "id": 16
            // },
            // {
            //     "name": "taggedUsers",
            //     "id": 16
            // },
            // {
            //     "name": "ignoredTags",
            //     "id": 16
            // },
            // {
            //     "name": "seen",
            //     "id": 16
            // },
            // {
            //     "name": "PERSON",
            //     "id": 16
            // },
            // {
            //     "name": "LOCATION",
            //     "id": 16
            // },
            // {
            //     "name": "ORGANIZATION",
            //     "id": 16
            // },
            // {
            //     "name": "pageId",
            //     "id": 16
            // }
        ]

        arrayTmp.push(customField)
        arrayTmp.push(constantField)

        return arrayTmp
    }
}