import { Checkbox, FormControlLabel, Grid, TextField, withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateCollectionSettings } from '../../../../../actions/collectionActions';
import withErrorCatcher from '../../withErrorCatcher';

const styles = theme => ({
    content: {
        padding: 20
    },
    textField: {
        width: 300
    }
})


class DaysToAlertOnUnusedItemSettings extends PureComponent {

    isChecked = () => {
        const { daysToAlertOnUnusedItem } = this.props
        const isChecked = daysToAlertOnUnusedItem > 0 || daysToAlertOnUnusedItem === ''

        return isChecked
    }

    onToggle = (e) => {
        const value = this.isChecked() ? 0 : 5
        this.props.dispatch(updateCollectionSettings({ daysToAlertOnUnusedItem: value }))
    }

    onChange = (e) => {
        const { value } = e.target

        if ((!isNaN(value) && value > 0) || value === '') {
            this.props.dispatch(updateCollectionSettings({ daysToAlertOnUnusedItem: value }))
        }
    }
    
    onBlur = () => {
        const { daysToAlertOnUnusedItem, dispatch } = this.props

        if (daysToAlertOnUnusedItem === '') {
            dispatch(updateCollectionSettings({ daysToAlertOnUnusedItem: 0 }))
        }
    }

    render() {
        const { t, daysToAlertOnUnusedItem, classes } = this.props

        const checked = this.isChecked()

        return (
            <Fragment>
                <Grid container direction='column' wrap='nowrap'
                    onBlur={this.onBlur}
                    className={classes.content}>
                    <FormControlLabel
                        onMouseDownCapture={this.onToggle}
                        control={
                            <Checkbox
                                checked={checked}
                            />}
                        label={t("Show indicator on unused items")}
                    />
                    {
                        checked && <div data-intrcm-id="DaysToAlertOnUnusedItemSettings_rt9mrl1r" className={classes.textField}>
                            <TextField
                                fullWidth
                                value={daysToAlertOnUnusedItem}
                                InputProps={{ min: 0 }}
                                type="number"
                                label={t("Number of days to indicate")}
                                onChange={this.onChange} /></div>
                    }


                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    daysToAlertOnUnusedItem: state.collection.settings.daysToAlertOnUnusedItem,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
    withStyles(styles),
    withErrorCatcher(),
    withTranslation('newGroupDialog'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DaysToAlertOnUnusedItemSettings);