import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import DaysToAlertOnUnusedItemSettings from './DaysToAlertOnUnusedItemSettings';
import AllowedInputsSettings from './AllowedInputsSettings';
import ExternalInputSettings from './ExternalInputSettings';


export default class CollectionSettingsStep extends PureComponent {

    render() {
        return (
            <Grid container direction='column'>
                <DaysToAlertOnUnusedItemSettings />

                <ExternalInputSettings />
                <AllowedInputsSettings />
            </Grid>
        )
    }
}