import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DATA_UPDATE, SEARCH_QUERY_UPDATE } from '../../../../actionTypes/actionTypes';
import { SORT_ORDER } from '../../../../reducers/searchReducer';
import { defaultDataTypes } from '../../../components/Common/Utils/defaultDataTypes';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import EnhancedTableToolbar from "./EnhancedTableToolbar/EnhancedTableToolbar";
import Table from './Table';
import Paging from './TableFooter/Paging';
import RowPerPage from './TableFooter/RowPerPage';
import { COLUMN_DATA_TYPE_DOC_TIMESTAMP } from './tableUtil';

const defaultDataTypesFilters = defaultDataTypes().map(d => d.customDataMapping.dataTypeFilter)

const styles = theme => ({
    container: {
        overflow: 'hidden',
        padding: '0px 32px',
        height: '100%'
    },
    tableContainer: {
        flex: '1 1 auto',
        "overflow": "auto",
        "backgroundColor": "#ffffff",
        ...theme.newUI.scrollbar,
        '&::-webkit-scrollbar-thumb:vertical': {
            borderTop: '53px solid transparent',
            backgroundClip: 'content-box'
        },
        '&::-webkit-scrollbar-thumb:horizontal': {
            height: 8
        }
    },
    tableFooterContainer: {
        flex: '1 1 auto',
        marginBottom: 32,
        overflow: 'hidden',
        "borderRadius": "4px",
        "border": `solid 0.5px ${theme.newUI.color.gray}`
    },
    footerContainer: {
        flex: '0 0 auto',
        "height": "52px",
        "borderRadius": "4px",
        "border-top": `solid 0.5px ${theme.newUI.color.gray}`,
        "backgroundColor": "#ffffff",
        paddingLeft: 32
    }
})

class TableView extends Component {

    state = {
        isRowsPerPageUpdating: false,
        page: 0,
        rowsPerPage: 25,
        selectedFields: [],
        results: [],
        sortedColumn: null,
        sortOrder: '',
        isSorting: false
    };

    updateRelatedDataTypes = () => {
        const {results, dispatch, inputMappings, searchQuery: {dataTypes}} = this.props
        if(!dataTypes || !dataTypes.length || defaultDataTypesFilters.includes(dataTypes[0])) {
            dispatch({type: 'CLEAR_MENU_EXPORT_DATATYPE_OPTIONS'})
            return
        }

        const datatypes = []
        results.forEach((r) => {
            const {relatedDocuments = []} = r.Value.Source
            const d = relatedDocuments.map(d => d.dataType)
            datatypes.push(...d)
        })
        const unique = [...new Set(datatypes)]
        dispatch({type: 'SET_MENU_EXPORT_DATATYPE_OPTIONS', dataTypes: unique, inputMappings})
    }

    componentDidUpdate(prevProps) {
        if ((this.props.searchQuery.dataTypes !== prevProps.searchQuery.dataTypes)) {
            this.setState({ page: 0 })
        }
        if (this.props.results !== prevProps.results) {
            this.updateRelatedDataTypes()
            // Parse local data


            const sortedResults = this.localSort(this.state.sortedColumn, this.state.sortOrder, this.props.results, this.props.results)
            const filteredResults = this.localFilter(sortedResults)
            this.setState({ results: filteredResults, isSorting: false })
            !this.state.isRowsPerPageUpdating && this.setState({ searchResultCount: filteredResults.length, isRowsPerPageUpdating: false })
        }


        if (this.props.localFilter.indicator !== prevProps.localFilter.indicator) {
            const results = this.localFilter(this.props.results)
            this.setState({ results: results, searchResultCount: results.length })
        }

        if (this.props.searchResultCount !== prevProps.searchResultCount) {
            this.setState({ searchResultCount: this.props.searchResultCount })

        }
    }

    localFilter = (results) => {
        const { localFilter } = this.props;
        const { selectedFields } = this.state;

        let filteredContent = results
        const filteredColumns = selectedFields.filter(x => !!localFilter.filters[x.sourceField])
        if (filteredColumns && filteredColumns.length > 0) {
            filteredColumns.forEach(column => {
                filteredContent = filteredContent.filter(res => {
                    
                    const fieldValue = column.extractData(res.Value.Source)

                        if(Array.isArray(fieldValue)){
                            for (let index = 0; index < fieldValue.length; index++) {
                                const element = fieldValue[index];
                                if(localFilter.filters[column.sourceField].data.indexOf(element) > -1){
                                    return true
                                }
                            }
                        }

                        if (Array.isArray(localFilter.filters[column.sourceField].data) && localFilter.filters[column.sourceField].data.indexOf(fieldValue) > -1 ) {
                            return true
                        }
                
                        if(localFilter.filters[column.sourceField].type === 'date') {
                            const fromToAgeValues = localFilter.filters[column.sourceField].data
                            if(fieldValue && fromToAgeValues.fromAge && fromToAgeValues.toAge) {
                               return new Date(fieldValue) > new Date(fromToAgeValues.fromAge) && new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
    
                            if(fieldValue && fromToAgeValues.fromAge) {
                                return new Date(fieldValue) > new Date(fromToAgeValues.fromAge)
                            }
    
                            if(fieldValue && fromToAgeValues.toAge) {
                                return new Date(fieldValue) < new Date(fromToAgeValues.toAge)
                            }
                        }
                    return false
                })
            })
        } 
        return filteredContent
    }

    onPaging = (page) => () => {
        const newPage = this.state.page + page
        this._loadMore(newPage, this.state.rowsPerPage)

        this.setState({ page: newPage })
    }

    _loadMore = (page, rowsPerPage) => {
        const { results, searchResultCount, loadMore, dispatch } = this.props;

        const rowsToToShow = page * rowsPerPage
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { size: rowsPerPage } });

        if (rowsToToShow < searchResultCount && rowsToToShow >= results.length) {
            loadMore()
        }
    }

    _loadData = (page, rowsPerPage) => {
        const { dispatch } = this.props;
        this.setState({isRowsPerPageUpdating: true, page: 0})
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { size: rowsPerPage } });
        dispatch({type: 'DATA_UPDATE'})
    }

    onRowsPerPageChange = (rowsPerPage) => {

        this._loadData(this.state.page, rowsPerPage)

        this.setState({ rowsPerPage })
    }

    onSelectFields = (options) => {

        this.setState({
            selectedFields: options.filter(option => option.selected),
            page: 0
        })
    }

    sort = (column) => {
        const { dispatch } = this.props
        const { sortedColumn, sortOrder } = this.state;


        let newSortedColumn = column
        let newSortField = column.sortField
        let newSortOrder

        if (sortedColumn === column) {
            if (sortOrder === SORT_ORDER.Ascending) {
                newSortedColumn = null
                newSortOrder = ''
                newSortField = ''
            }
            else {
                newSortOrder = SORT_ORDER.Ascending
            }
        }
        else {
            newSortOrder = SORT_ORDER.Descending
        }

        this.setState({
            sortedColumn: newSortedColumn,
            sortOrder: newSortOrder,
            page: 0,
            isSorting: Boolean(column.sortField)
        })
        dispatch({ type: SEARCH_QUERY_UPDATE, query: { page: 0, sortField: newSortField, sortOrder: newSortOrder, sortType: column.sortType } })

        if (column.sortField) {
            dispatch({ type: DATA_UPDATE });
        }
        else {
            const sortedResults = this.localSort(newSortedColumn, newSortOrder, [], this.state.results)
            this.setState({ results: [...sortedResults] })
        }
    }

    localSort = (column, sortOrder, sortedRresults = [], results) => {
        if (!column || column.sortField) {

            return results
        }
        else {
            const sortedRresultsId = sortedRresults.map(res => res.Key)
            const unsortedRresults = results.filter(res => !sortedRresultsId.includes(res.Key))

            const mappedColumnData = new Map()
            unsortedRresults.forEach(res => {
                const data = column.extractData(res.Value.Source)
                mappedColumnData.set(res.Key, data)
            })

            unsortedRresults.sort((row1, row2) => {
                let res = mappedColumnData.get(row2.Key) > mappedColumnData.get(row1.Key) ? 1 : -1

                if (sortOrder === SORT_ORDER.Ascending) {
                    res *= -1
                }
                if (mappedColumnData.get(row2.Key) === mappedColumnData.get(row1.Key) && this.props.defaultSort) {
                    res = this.props.defaultSort(row1, row2)
                }

                return res
            })

            // this.setState({ results: [...sortedRresults, ...unsortedRresults], isSorting: false })

            return [...sortedRresults, ...unsortedRresults]
        }

    }
    static defaultProps = {
        defaultSort: (row1, row2) => {
            return (row2.Value.Source[COLUMN_DATA_TYPE_DOC_TIMESTAMP] >
                row1.Value.Source[COLUMN_DATA_TYPE_DOC_TIMESTAMP]) ? 1 : -1
        }
    }

    render() {
        const { classes, isLoadingMore, isLoading, collectionViews, savedReports, searchQuery, isDrawerOpen, isFromMyPageTasks = false, firstAllResultsLength } = this.props;
        const { page, rowsPerPage, selectedFields, searchResultCount, results, sortedColumn, sortOrder, isSorting } = this.state;
        const {dataTypes} = searchQuery
        const showTableFooter = dataTypes && Array.isArray(dataTypes) && dataTypes.length !== 0

        return (
            <Grid container direction='column' wrap='nowrap' className={classes.container}>
                <EnhancedTableToolbar
                    firstAllResultsLength={firstAllResultsLength}
                    onSelectFields={this.onSelectFields}
                    totalResultSize={searchResultCount}
                    columns={selectedFields}
                    savedReports={savedReports}
                    saveReport={this.props.saveReport} 
                    isFromMyPageTasks
                    />
                <Grid container direction='column' wrap='nowrap'
                    className={classes.tableFooterContainer}
                >
                    <div data-intrcm-id="TableView_foa2zxtn" className={classes.tableContainer}>
                    
                        <Table
                            isDrawerOpen={isDrawerOpen}
                            isLoading={!isSorting && (isLoadingMore || isLoading)}
                            showTableFooter={showTableFooter}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            columns={selectedFields}
                            results={results}
                            sortedColumn={sortedColumn}
                            sortOrder={sortOrder}
                            isGroup={!!collectionViews}
                            onSort={this.sort} />

                    </div>

                    <Grid container alignItems='center' justify='flex-start' className={classes.footerContainer}>
                        <RowPerPage
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={this.onRowsPerPageChange} />
                        <Paging
                            currPage={page}
                            rowsPerPage={rowsPerPage}
                            totalResults={searchResultCount}
                            onPaging={this.onPaging}
                        />
                    </Grid>

                </Grid>
            </Grid>

        )
    }

}


const mapStateToProps = (state, ownProps) => ({
    searchQuery: state.search,
    collectionViews: state.results.channel.collectionViews,
    inputMappings: state.globalData.inputMappings,
    localFilter: state.localFilter,
    ...state.services,

})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('groupTable'),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(TableView);