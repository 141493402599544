import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions"
import { goToPath } from "./navigationHelpers"
import { navOptions } from "./navigationHelpers"
import { mixpanelTrack } from '../../ui/components/Common/Utils/mixpanelUtils'

export const groupContext = {
    mode: navOptions.GROUP,
    home: {
        id: 0,
        key: 'feed',
        pathname: '/group/feed'
    },
    home1: {
        id: -1,
        key: 'home',
        pathname: '/group/home'
    },
    kanban: {
        id: 1,
        key: 'kanban',
        pathname: '/group/kanban'
    },
    tasks: {
        id: 2,
        key: 'table',
        pathname: '/group/table'
    },
    scheduler: {
        id: -2,
        key: 'scheduler',
        pathname: '/group/scheduler'
    },
    calendar: {
        id: 3,
        key: 'calendar',
        pathname: '/group/calendar'
    },
    gantt: {
        id: 4,
        key: 'gantt',
        pathname: '/group/gantt'
    },
    map: {
        id: 5,
        key: 'map',
        pathname: '/group/map'
    },
    dashboard: {
        id: 6,
        key: 'dashboard',
        pathname: '/group/dashboard'
    },
    chat: {
        id: -7,
        key: 'chat',
        pathname: '/group/chat'
    },
}

export const getGroupNavigationOpts = (t, dispatch,additionalOptions, settings) => {
    let x = [
    {
        id: groupContext.home1.id,
        key: groupContext.home1.key,
        icon: 'Home',
        title: t("Home"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.home1.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.home1.id))
            goToPath(groupContext.home1.pathname, dispatch)
        }
    },
    {
        id: groupContext.home.id,
        key: groupContext.home.key,
        icon: 'Overview',
        title: t("Feed"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.home.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.home.id))
            goToPath(groupContext.home.pathname, dispatch)
        }
    },
    {
        id: groupContext.chat.id,
        key: groupContext.chat.key,
        icon: 'Chat',
        title: t("Chat"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.chat.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.chat.id))
            goToPath(groupContext.chat.pathname, dispatch) 
        }
    },
    {
        id: groupContext.gantt.id,
        key: groupContext.gantt.key,
        icon: 'Gantt',
        title: t("Gantt"),
        onClick: () => {
            mixpanelTrack(`Group gantt view`, {})
            dispatch(selectDrawerNavigationOption(groupContext.gantt.id))
            goToPath(groupContext.gantt.pathname, dispatch) 
        }
    },
    {
        id: groupContext.kanban.id,
        key: groupContext.kanban.key,
        icon: 'Kanban',
        title: t("Kanban"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.kanban.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.kanban.id))
            goToPath(groupContext.kanban.pathname, dispatch)
        }
    },
    {
        id: groupContext.tasks.id,
        key: groupContext.tasks.key,
        icon: 'Reports',
        title: t("Reports"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.tasks.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.tasks.id))
            goToPath(groupContext.tasks.pathname, dispatch)
        }
    },
    {
        id: groupContext.calendar.id,
        key: groupContext.calendar.key,
        icon: 'Calendar',
        title: t("Calendar"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.calendar.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.calendar.id))
            goToPath(groupContext.calendar.pathname, dispatch)
        }
    },
    {
        id: groupContext.scheduler.id,
        key: groupContext.scheduler.key,
        icon: 'Scheduler',
        title: t("Scheduler"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.scheduler.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.scheduler.id))
            goToPath(groupContext.scheduler.pathname, dispatch)
        }
    },
    {
        id: groupContext.map.id,
        key: groupContext.map.key,
        icon: 'View on Map',
        title: t("View on Map"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.map.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.map.id))
            goToPath(groupContext.map.pathname, dispatch)
        }
    },
    {
        id: groupContext.dashboard.id,
        key: groupContext.dashboard.key,
        icon: 'Statistics',
        title: t("Statistics"),
        onClick: () => {
            mixpanelTrack(`Group page view ${groupContext.dashboard.pathname}`, {})
            dispatch(selectDrawerNavigationOption(groupContext.dashboard.id))
            goToPath(groupContext.dashboard.pathname, dispatch)
        }
    },
    
    ]
    if(!settings?.useScheduler) x = x.filter(y => y.id !== groupContext.scheduler.id)

    if(Array.isArray(settings?.visibleOpts)) {
        x = x.filter(y => settings.visibleOpts.includes(y.key))
    }

    return x.concat(additionalOptions)
}

export const getGroupDrawerFromPath = (pathname = '') => {
    const mode = groupContext.mode

    if (pathname.includes(groupContext.home1.pathname)) return [mode, groupContext.home1.id]
    if (pathname.includes(groupContext.kanban.pathname)) return [mode, groupContext.kanban.id]
    if (pathname.includes(groupContext.tasks.pathname)) return [mode, groupContext.tasks.id]
    if (pathname.includes(groupContext.scheduler.pathname)) return [mode, groupContext.scheduler.id]
    if (pathname.includes(groupContext.calendar.pathname)) return [mode, groupContext.calendar.id]
    if (pathname.includes(groupContext.gantt.pathname)) return [mode, groupContext.gantt.id]
    if (pathname.includes(groupContext.chat.pathname)) return [mode, groupContext.chat.id]
    if (pathname.includes(groupContext.map.pathname)) return [mode, groupContext.map.id]
    if (pathname.includes(groupContext.dashboard.pathname)) return [mode, groupContext.dashboard.id]
    return [mode, groupContext.home.id]
}