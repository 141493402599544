export const COLUMN_DATA_TYPE_TITLE = 'title'
export const COLUMN_DATA_TYPE_MEMBERS = 'members'
export const COLUMN_DATA_TYPE_DOC_TIMESTAMP = 'docTimestamp'
export const COLUMN_DATA_TYPE_LAST_MODIFED_TIME = 'lastModifiedTime'
export const COLUMN_DATA_TYPE_COLLECTION_LABELS = 'collectionLabels'
export const COLUMN_DATA_TYPE_STATUS = 'status'
export const COLUMN_DATA_TYPE_GROUP = 'groupUid'
export const COLUMN_DATA_TYPE_RATING = 'rating'
export const COLUMN_DATA_TYPE_DUE_DATE_UTC = 'dueDateUTC'
export const COLUMN_DATA_TYPE_SEEN_COUNTER = 'seenCounter'
export const COLUMN_DATA_TYPE_COMMENTS_COUNTER = 'commentsCounter'
export const COLUMN_DATA_TYPE_TASK_COUNTER = 'taskCounter'
export const COLUMN_DATA_TYPE_READ_CONFIRM = 'readConfirm'

export const COLUMN_DATA_TYPE_RELATED_DOCUMENTS = 'relatedDocuments'
export const COLUMN_DATA_TYPE_ATTACHMENTS = 'attachments'
export const COLUMN_DATA_TYPE_BUSINESS_ID = 'businessId'

export const isNumber = value => typeof value === 'number' && isFinite(value);

export const calculateAggregationSummary = (results, column, inputMappings = []) => {
    if (results.length === 0) return ''
    const res = results.reduce((accum, obj) => {
        const value = column.extractData(obj.Value.Source)
        let numberValue = value

        if (column.type === COLUMN_DATA_TYPE_MEMBERS || column.type === COLUMN_DATA_TYPE_COLLECTION_LABELS || COLUMN_DATA_TYPE_RELATED_DOCUMENTS === column.type) {
            numberValue = Array.isArray(value) ? value.length : 0
        }
        if(column.type === COLUMN_DATA_TYPE_ATTACHMENTS) {
            const mapping = inputMappings.find(m => m?.customDataMapping?.dataTypeFilter === obj.Value.Source?.dataType)
            const value = column.extractData(obj.Value.Source, mapping)
            numberValue = Array.isArray(value) ? value.length || '' : ''
        }
        if(column.type === 'number') {
            numberValue = value.value
        }
        if(column.type === 'calculation') {
            numberValue = value.value
        }
        if (column.type === COLUMN_DATA_TYPE_TASK_COUNTER) {
            numberValue = value.taskDoneCounter || 0
        }
        if (column.type === COLUMN_DATA_TYPE_READ_CONFIRM) {
            numberValue = value.mustReadUsersApproved || 0
        }
        if (column.type === 'companyID' || column.type === 'companyFoundingYear') {
            numberValue = 0
        }
        return accum += (isNumber(numberValue) ? numberValue : 0)
    }, 0)
    return res === 0 ? '' : res
}
export const calculateAggregationAverage = (results, column, inputMappings = []) => {
    if (results.length === 0) return ''
    let count = 0
    const res = results.reduce((accum, obj) => {
        const value = column.extractData(obj.Value.Source)
        let numberValue = value

        if (column.type === COLUMN_DATA_TYPE_MEMBERS || column.type === COLUMN_DATA_TYPE_COLLECTION_LABELS || COLUMN_DATA_TYPE_RELATED_DOCUMENTS === column.type) {
            numberValue = Array.isArray(value) ? value.length : ''
        }
        if(column.type === COLUMN_DATA_TYPE_ATTACHMENTS) {
            const mapping = inputMappings.find(m => m?.customDataMapping?.dataTypeFilter === obj.Value.Source?.dataType)
            const value = column.extractData(obj.Value.Source, mapping)
            numberValue = Array.isArray(value) ? value.length || '' : ''
        }
        if(column.type === 'number') {
            numberValue = value.value
        }
        if(column.type === 'calculation') {
            numberValue = value.value
        }
        if (column.type === COLUMN_DATA_TYPE_TASK_COUNTER) {
            numberValue = value.taskDoneCounter || ''
        }
        if (column.type === COLUMN_DATA_TYPE_READ_CONFIRM) {
            numberValue = value.mustReadUsersApproved || ''
        }
        if (column.type === 'companyID' || column.type === 'companyFoundingYear') {
            numberValue = ''
        }
        if (isNumber(numberValue)) {
            count = count + 1
        }
        return accum += (isNumber(numberValue) ? numberValue : 0)
    }, 0)
    return res === 0 ? '' : (res / count).toFixed(2)
}
export const calculateAggregationBounds = (results, column, minOrMax, inputMappings = []) => {
    if (results.length === 0) return ''
    const res = results.reduce((accum, obj) => {
        const value = column.extractData(obj.Value.Source)
        let numberValue = value

        if (column.type === COLUMN_DATA_TYPE_MEMBERS || column.type === COLUMN_DATA_TYPE_COLLECTION_LABELS || COLUMN_DATA_TYPE_RELATED_DOCUMENTS === column.type) {
            numberValue = Array.isArray(value) ? value.length : ''
        }
        if(column.type === COLUMN_DATA_TYPE_ATTACHMENTS) {
            const mapping = inputMappings.find(m => m?.customDataMapping?.dataTypeFilter === obj.Value.Source?.dataType)
            const value = column.extractData(obj.Value.Source, mapping)
            numberValue = Array.isArray(value) ? value.length || '' : ''
        }
        if(column.type === 'number') {
            numberValue = value.value
        }
        if(column.type === 'calculation') {
            numberValue = value.value
        }
        if (column.type === COLUMN_DATA_TYPE_TASK_COUNTER) {
            numberValue = value.taskDoneCounter || ''
        }
        if (column.type === COLUMN_DATA_TYPE_READ_CONFIRM) {
            numberValue = value.mustReadUsersApproved || ''
        }
        if (column.type === 'companyID' || column.type === 'companyFoundingYear') {
            numberValue = ''
        }
        if (isNumber(numberValue)) {
            return [...accum, numberValue]
        }
        return accum
    }, [])
    if (res.length === 0) return ''
    return minOrMax === 'min' ? Math.min(...res) : Math.max(...res)
}
export const calculateAggregation = (aggregationType, results, column, inputMappings) => {
    switch (aggregationType.toLowerCase()) {
        case 'summary':
            return calculateAggregationSummary(results, column, inputMappings)
        case 'average':
            return calculateAggregationAverage(results, column, inputMappings)
        case 'minimum':
            return calculateAggregationBounds(results, column, 'min', inputMappings)
        case 'maximum':
            return calculateAggregationBounds(results, column, 'max', inputMappings)
        default:
            return ''
    }
}