import { SHOW_SNACKBAR, DRAWER_TOGGLE, CONFIRM_DIALOG_CLOSE, CONFIRM_DIALOG_OPEN, CONFIRM_IS_LOADING, HIDE_SNACKBAR, SHOW_NOTIFICATION_SNACKBAR, HIDE_NOTIFICATION_SNACKBAR, SHOW_VIDEO_CHAT, HIDE_VIDEO_CHAT } from '../actionTypes/actionTypes'

export function showSnackbar(text, autoHide = 4000) {
    return { type: SHOW_SNACKBAR, value: text, message: text, autoHide }
}

export function showVideoChat(docId, title, idToken) {
    return { type: SHOW_VIDEO_CHAT, docId, title, idToken }
}

export function hideVideoChat() {
    return { type: HIDE_VIDEO_CHAT }
}

export const hideSnackbar = () => ({ type: HIDE_SNACKBAR })

export function showNotificationSnackbar(notifications) {
    return { type: SHOW_NOTIFICATION_SNACKBAR, notifications }
}

export const hideNotificationSnackbar = () => ({ type: HIDE_NOTIFICATION_SNACKBAR })

export function drawerToggle(toggled) {
    return { type: DRAWER_TOGGLE, toggled: toggled }
}

export function showConfirm(message, confirmAction, closeImmediately = true, onlyClose = false, declineAction, isGanttReload = false) {
    return { type: CONFIRM_DIALOG_OPEN, isGanttReload, message: message, confirmAction: confirmAction, closeImmediately, onlyClose: onlyClose, declineAction }
}

export function hideConfirm() {
    return { type: CONFIRM_DIALOG_CLOSE }
}

export function confirmLoading() {
    return { type: CONFIRM_IS_LOADING }
}

