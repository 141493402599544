import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    addComment, closeAddChatModal, getChatFunctions, loadComments, loadMessages, loadMoreComments, addMessage, loadMoreMessages, Discussions, DirectMessages, addSavedMessage, loadSavedMessages, getChatCommentData, findChatIndex
} from '../../../../views/chat/chatUtils';
import withPusher from '../../../../components/Common/Pusher/withPusher';
import { getChannelName } from '../../../../components/Common/Pusher/pusherUtils';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';
import { getDirectMessages, getDiscussionsRooms, isDiscussions, updateDataResult, updateNewCommentsChat, uppendDataResult } from '@tuqqi/common';
import { isEqualJson } from '../../../../../utils/commonHelpers';
import { SERVER_API } from '../../../../../utils/serverAPI'
import { NewUiWrapper } from '@tuqqi/web';
import {store} from '../../../../../index';
import { withTranslation } from 'react-i18next';
import ChatsList from './ChatsList'
import { getNotificationData } from '../../../../views/chat/chatNotificationsUtils';

const updateResults = function (comment, results, dispatch, isOwn) {
    if(comment.sender && comment.receiver) return
    const chatCommentData = getChatCommentData(comment, dispatch)
    const oldResults = [...results]
    const resIndex = results.findIndex(res => findChatIndex(res, chatCommentData.commentChatId))

    if (resIndex >= 0) {
        let { newComments } = oldResults[resIndex];
        let counts = isDiscussions(chatCommentData.chatType) ? oldResults[resIndex].document.commentsCounter : 0

        if (isOwn) {
            newComments = 0
            chatCommentData.updateTime()
        } else {
            newComments = newComments + 1;
        }
        const newResult = {
            ...oldResults[resIndex],
            commentTime: chatCommentData.commentTime,
            commentText: chatCommentData.text,
            commentType: chatCommentData.chatType,
            lastUserId: chatCommentData.userUid,
            newComments,
            ...(isDiscussions(chatCommentData.chatType) ? { document: { ...oldResults[resIndex].document, commentsCounter: counts } } : {})
        }
        dispatch(updateDataResult(chatCommentData.commentChatId, newResult))
        return
    }

    getNotificationData(comment && comment.docId, comment.chatUId, dispatch, 'push')
}

class ChatsListWrap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            loadingDocuments: true,
            commentsLoading: false,
        }

        this.getChatFunctions = getChatFunctions.bind(this);

        this.loadMoreComments = loadMoreComments.bind(this);
        this.loadMoreMessages = loadMoreMessages.bind(this);
        this.loadComments = loadComments.bind(this);
        this.addComment = addComment.bind(this);

        this.loadMessages = loadMessages.bind(this)
        this.loadSavedMessages = loadSavedMessages.bind(this)
        this.closeAddChatModal = closeAddChatModal.bind(this)
        this.addMessage = addMessage.bind(this)
        this.addSavedMessage = addSavedMessage.bind(this)
        this.newChatRef = createRef()
        this.newInstantMessageRef = createRef({needReplace: false, tempId: ''})
    }

    componentWillUnmount() {
        const { auth0_id, tag } = this.props.userData.profileData;
        this.props.unbindChannel(getChannelName(auth0_id))
        this.props.unbindChannel(getChannelName(tag))
    }

    componentDidMount() {
        this.initChatChannel();
        this.loadDisscusions();
    }

    getSavedItemInd = (items, tempId) => items.findIndex(el => {
            const {item} = el
            if(item.messageType === 2) return el.item.message.tempId === tempId
            return false
    })
    getCommentItemInd = (items, tempId) => items.findIndex(el => {
            const {item} = el
            return item.tempId === tempId
    })
    get allowInstantReplace() {
        return this.newInstantMessageRef.current && this.newInstantMessageRef.current.needReplace
    }

    componentDidUpdate(prevProps) {
        this.listenResults(prevProps)
        this.listenDocumentCommentsLoading(prevProps)
    }
    listenDocumentCommentsLoading = (prevProps) => {
        if(!this.props.chatFeed) return
        
        const [newDocsLoading, prevDocsLoading] = [this._getFetchingDocumentComments(this.props, Discussions), this._getFetchingDocumentComments(prevProps, Discussions)];
        const [newDMLoading, prevDMLoading] = [this._getFetchingDocumentComments(this.props, DirectMessages), this._getFetchingDocumentComments(prevProps, DirectMessages)];
        if (prevDocsLoading !== newDocsLoading || prevDMLoading !== newDMLoading) {
          const loading = newDocsLoading && newDMLoading;
          this.setState({loadingDocuments: loading})
        }
    }

    initChatChannel = () => {
        const channelName = getChannelName(this.props.userData.profileData.auth0_id);
        const eventName = 'chat';

        this.props.initChannel(channelName, eventName, this.handleChatPusher);
    }

    handleChatPusher = (comment) => {
        if(comment.isChatNotification) return

        const senderUid = comment?.sender?.auth0_id || comment.userId
        const isOwn = senderUid === this.props.userData?.profileData?.auth0_id

        const { results } = this.state;

        this.updateFeedResults(comment, results, isOwn)
    }

    loadDisscusions = async () => {
        const { dispatch } = this.props;
        dispatch(getDiscussionsRooms(SERVER_API)(0))
        dispatch(getDirectMessages(SERVER_API)(0, {includeGroups: 1}));
    }

    listenResults = (prevProps) => {
        if(!this.props.chatFeed) return

        const [prevResults, newResults] = [this.getResults(prevProps), this.getResults(this.props)]
        if(isEqualJson(prevResults, newResults) && isEqualJson(newResults, this.state.results)) return;

        this.setState({results: newResults})
    }

    updateFeedResults = (comment, oldResults, isOwn) => {
        const { dispatch } = this.props;
        updateResults(comment, oldResults, dispatch, isOwn);
    }

    getRoomWithChatType = (props, chatType) => {
        return props.chatFeed[isDiscussions(chatType) ? 'documentsComments' : 'directMessages']
    }
    _getFetchingDocumentComments = (props, chatType) => (this.getRoomWithChatType(props, chatType) && this.getRoomWithChatType(props, chatType).isFetching) || false;

    getResults = (props) => props.chatFeed.results

    render() {
        const results = this.getResults(this.props)
        return (
        <NewUiWrapper callbacks={{}} store={store}>
            <ChatsList results={results} />
        </NewUiWrapper>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    chatFeed: state.data.chatData.chatFeed,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        uppendDataResult: (result) => dispatch(uppendDataResult(result)),
    }
}

export default compose(
    withTranslation('chat'),
    withPusher,
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(ChatsListWrap);