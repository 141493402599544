
import { DRAWER_TOGGLE, CUSTOM_INPUT_EDIT_MAPPING, FULL_RESET_STORE } from '../actionTypes/actionTypes'
const getIndexOfDisplayMapping = (mappings, fieldId) => {
  for (var index = 0; index < mappings.length; index++) {
    var mapping = mappings[index];
    if (mapping)
      if (mapping.templateFieldId === fieldId)
        return index;

  }
}

const getIndexOfCustomFieldMapping = (mappings, fieldId) => {
  for (var index = 0; index < mappings.length; index++) {
    var mapping = mappings[index];
    if (mapping)
      if (mapping.id === fieldId)
        return index;

  }
}
const initialState = []
const wrapper = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE: 
      return initialState

    case CUSTOM_INPUT_EDIT_MAPPING:
      return {
        ...state,
        currentMapping: { ...action.currentMapping }
      }
    // case 'EDIT_CUSTOM_INPUT_FIELD_ROW':
    //   var templateArray = [...state.currentMapping.customFieldsMappings];

    //   // var index = getIndexOfCustomFieldMapping(templateArray, action.fieldId);
    //   // if (index == null) {
    //   //   templateArray.push(action.row);
    //   // } else {

    //   // }

    //   templateArray[action.fieldId] = Object.assign({}, templateArray[action.fieldId], {

    //     ...action.row

    //   })

    //   return {
    //     ...state,
    //     currentMapping: Object.assign({}, state.currentMapping, {

    //       customFieldsMappings: templateArray

    //     })
    //   }
    default:
      return state
  }
}

export default wrapper