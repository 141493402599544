import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import parseImgeUrl from "../../../Common/parseImagUrl";
import { compose } from "recompose";

const styles = (theme) => ({
  container: {
    padding: "6px 18px",
    borderRadius: 6,
    zIndex: 2,
    backgroundColor: "#0e0e14",
  },
  text: {
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
    fontFamily: "Rubik",
    fontSize: 44,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.32,
    letterSpacing: 0,
    textAlign: "center",
    color: "#fff",
  },
});
const UserAvatar = (props) => {
  const { styles, docId, cloudfront, documents, queryService } = props;
  const [userAvatarUrl, setUserAvatarUrl] = useState("");

  useEffect(() => {
    if (!docId) return;

    const doc = documents[docId];
    if (!!doc?.document?.userPicUrl) {
      setUserAvatarUrl(doc.document.userPicUrl);
      return;
    }

    queryService.searchById(docId).then((results) => {
      if (!results?.data?.length) return;
      const doc = results.data[0].Value.Source;
      doc.document?.userPicUrl && setUserAvatarUrl(doc.document.userPicUrl);
    });
  }, [documents, queryService, docId]);

  if(!userAvatarUrl.length) {
    return null
  }

  return (
    <img
      src={parseImgeUrl(cloudfront, userAvatarUrl)}
      style={styles.container}
      alt="author article avatar"
    />
  );
};

const mapStateToProps = (state) => ({
  storiesNavigation: state.data.storiesNavigation,
  cloudfront: state.globalData.cloudfront,
  documents: state.data.documents.data ?? {},
  queryService: state.services.queryService,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UserAvatar);
