import { Divider, Grid, withStyles, Typography } from '@material-ui/core';
import Slider from '@material-ui/lab/Slider';
import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import DiscoveryList from '../../views/knowledge/Library/Discovery/discoveryList';
import TopicExpert from '../../views/knowledge/Library/TopicView/topicExpert';
import MustReadItem from '../Widgets/MustReadWidget/MustReadItem';
import RelatedItemsSection from './RelatedItemsSection'
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    leftSide: {
        backgroundColor: 'white',
        padding: 24
    },
    rightSide: {
        width: '100%',
        padding: 24,
        paddingTop: 31,
        // minHeight: 881,
        // maxHeight: 881,
        overflowY: 'auto'
    },
    chip: {
        margin: 6,
        cursor: 'pointer',
        border: 'solid 1px rgba(151, 154, 170, 0.8)'
    },
    chipSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    chipNotSelected: {
        backgroundColor: 'white',
    },
    chipText: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.14,
        letterSpacing: 0.5,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#21212d',
    },
    slider: {
        padding: '10px 0px',
    },
    trackBefore: {
        opacity: 1,
        backgroundColor: theme.customColors.primary.main
    },
    resultsSection: {
        marginTop: 24
    },
    withoutScroll: {
        '-webkit-overflow-scrolling': 'touch',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    chipWrapper: {

    },
    footer: {
        fontStyle: "italic",
        position: 'absolute',
        bottom: 0,
        width: '25%',
        marginBottom: 80,
        color: "rgba(0, 0, 0, 0.54)"
    }
});

class RelatedItemsReport extends Component {
    tagWithPrefix = (tag = '') => {
        const res = tag.startsWith('@') ? tag : `#${tag}`
        return res
    }

    handleTagClick = key => () => {
        this.props.onTagFilterClicked(key.replace('#', ''))
    }

    orderTags = () => {
        const { filterTags, relatedTags } = this.props
        const isInFilterTags = tag => filterTags.findIndex(x => x === tag) === -1
        const relatedTagsWithoutFilterTags = relatedTags.filter(isInFilterTags)
        return [...filterTags, ...relatedTagsWithoutFilterTags].filter(tag => tag !== null && tag !== '')
    }

    render() {
        const { classes, experts, duplicatedItems, dispatch, bestResults, resultsByDataTypes, linkedItems, filterTags, t } = this.props;
        const tags = this.orderTags()
        const events = resultsByDataTypes['event']
        const links = resultsByDataTypes['bookmark']
        const articles = resultsByDataTypes['htmlwysiwyg']

        return (
        <Grid  container style={{flexDirection:'column', height:'100%'}} wrap='nowrap' >
            <Grid container wrap='nowrap' style={{ overflowX: 'auto', padding: 5, flex:'0 0 auto' }} className={`${classes.withoutScroll} ${classes.chipWrapper}`}>
                <DiscoveryList withoutArrow={true}>
                    {tags.map((key, idx) => {
                        const tag = this.tagWithPrefix(key)

                        if (filterTags.find(x => x === key)) {
                            return <Chip key={idx} label={tag} variant="outlined"
                                onClick={this.handleTagClick(tag)}
                                className={`${classes.chip} ${classes.chipSelected}`}
                                classes={{ label: classes.chipText }}
                                icon={<DoneIcon />} />
                        } else {
                            return (<Chip key={idx} label={tag} variant="outlined"
                                onClick={this.handleTagClick(tag)}
                                className={`${classes.chip} ${classes.chipNotSelected}`}
                                classes={{ label: classes.chipText }} />);
                        }
                    })}
                </DiscoveryList>
            </Grid>
            <Grid container wrap='nowrap' style={{flex:'1 1 auto', overflow:'hidden'}}>

                <Grid item style={{ maxWidth: '30%', minWidth: 360 }} className={classes.leftSide}>

                    <div data-intrcm-id="RelatedItemsReport_8c34iecj" >
                        <Slider
                            classes={{
                                container: classes.slider,
                                trackBefore: classes.trackBefore, trackAfter: classes.trackBefore
                            }}
                            value={40}
                            aria-labelledby="label"
                        // onChange={this.handleChange}
                        />
                        <div data-intrcm-id="RelatedItemsReport_fyxb6ytx" className={classes.text} style={{ flexDirection: 'row', display: 'flex' }} >
                            <div data-intrcm-id="RelatedItemsReport_dncxv9da" style={{ flex: 1, fontWeight: 500 }}>{t('Popular')}</div>
                            <div data-intrcm-id="RelatedItemsReport_kmkycs2x" style={{ textAlign: 'right', flex: 1, fontWeight: 500 }}>{t('Relevant')}</div>
                        </div>
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_0jdewlbs" style={{ marginTop: 36 }}>
                        <span className={classes.text} >{t('Experts that can assist you')}</span>
                        <Divider style={{ marginTop: 10, marginBottom: 18, color: '#302f47' }} />
                        {experts.map(expert => <TopicExpert key={expert.key} user={expert.user} percent={expert.percent} />)}
                        <Typography className={classes.footer}>{t('This report was generated by Tuqqi and was tailored to you using AI & Machine Learning')}</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.rightSide} style={{}} >
                    <div data-intrcm-id="RelatedItemsReport_tyzo8tn8">
                        <RelatedItemsSection items={bestResults} title={t("Best results")} />
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_ovyw8nby" className={classes.resultsSection}>
                        <RelatedItemsSection items={duplicatedItems} title={t("Duplicated items")} />
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_h28hoef1" className={classes.resultsSection}>
                        <RelatedItemsSection items={linkedItems} title={t("Linked Items")} />
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_qm7n4y5d" className={classes.resultsSection}>
                        <RelatedItemsSection items={articles} title={t("Articles")} />
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_rbf6asy4" className={classes.resultsSection}>
                        <RelatedItemsSection items={links}  title={t("Links")} />
                    </div>
                    <div data-intrcm-id="RelatedItemsReport_k0dikxys" className={classes.resultsSection}>
                        <RelatedItemsSection items={events}  title={t("Events")} />
                    </div>
                </Grid>
            </Grid>
        </Grid>)
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    users: state.globalData.users,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles, { withTheme: true }),
withTranslation('relatedModal'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(RelatedItemsReport);