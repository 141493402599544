import { saveCommentActionTypes, saveMessageActionTypes } from '@tuqqi/common'
import { SHOW_SNACKBAR, HIDE_SNACKBAR, FULL_RESET_STORE } from '../actionTypes/actionTypes'

const initialState= [];
const snackBar = (state = initialState, action) => {
  switch (action.type) {
    case FULL_RESET_STORE:
      return initialState;

    case SHOW_SNACKBAR:
      return {
        ...state,
        snackBarOpen: true,
        snackBarText: action.value,
        autoHide: action.autoHide
      }
    //   case saveCommentActionTypes.start: {
    //     return { snackBarText: 'Saving ...', autoHide: 1000, snackBarOpen: true}
    // }
    // case saveCommentActionTypes.success: {
    //   return { snackBarText: 'Comment saved', autoHide: 1000, snackBarOpen: true}
    // }
    case saveMessageActionTypes.start: {
      return { snackBarText: 'Saving ...', autoHide: 1000, snackBarOpen: true}
  }
  case saveMessageActionTypes.success: {
    return { snackBarText: 'Comment saved', autoHide: 1000, snackBarOpen: true}
  }
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackBarOpen: false
      }
    default:
      return state
  }
}

export default snackBar