import React, { PureComponent } from 'react';

export default class EmailIcon extends PureComponent {

    render() {
        const { iconColor } = this.props
        
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4.66699 6L8.00033 8.33333L11.3337 6" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.33301 11.3335V4.66683C1.33301 3.93045 1.92996 3.3335 2.66634 3.3335H13.333C14.0694 3.3335 14.6663 3.93045 14.6663 4.66683V11.3335C14.6663 12.0699 14.0694 12.6668 13.333 12.6668H2.66634C1.92996 12.6668 1.33301 12.0699 1.33301 11.3335Z" stroke={iconColor}/>
            </svg>
        )
    }
}