import React, { PureComponent } from 'react';

export const NoChatsIcon = class NoChatsIcon extends PureComponent {

    render() {
        return (
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0C2.92806 0 0 2.92806 0 6.5V22.5V28.5352C0 30.4868 2.38991 31.7051 3.96875 30.5586C3.9694 30.5586 3.97005 30.5586 3.9707 30.5586L8.29102 27.416C9.11496 30.0616 11.5943 32 14.5 32H27.0117L36.0293 38.5586C36.0299 38.5586 36.0306 38.5586 36.0312 38.5586C37.61 39.7051 40 38.4868 40 36.5352V30.5V14.5C40 10.9281 37.0719 8 33.5 8H32V6.5C32 2.92806 29.0719 0 25.5 0H6.5ZM6.5 3H25.5C27.4501 3 29 4.54994 29 6.5V17.5C29 19.4501 27.4501 21 25.5 21H12.5C12.1828 21 11.8737 21.1005 11.6172 21.2871L3 27.5547V22.5V6.5C3 4.54994 4.54994 3 6.5 3ZM32 11H33.5C35.4501 11 37 12.5499 37 14.5V30.5V35.5547L28.3828 29.2871C28.1263 29.1005 27.8172 29 27.5 29H14.5C12.5499 29 11 27.4501 11 25.5V25.4453L12.9883 24H25.5C29.0719 24 32 21.0719 32 17.5V11Z" fill="#DADDEE"/>
            </svg>
        )
    }
}