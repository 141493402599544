import React, { useCallback, useEffect, useRef, useState } from 'react'
import { hideConfirm, showConfirm } from '../../../../../actions/appActions';
import { CheckEditDate } from './checkChangeDate';
import { closeFullview } from '@tuqqi/web/dist/actions/fullview-actions/fullviewActions';
import { managerGanttRef } from '@tuqqi/web';


export const useConfirmChangeProjectStartDate = (opts) => {
    const {dispatch, fullviewIsOpen} = opts
    const refOpen = useRef(fullviewIsOpen)

    useEffect(() => {
        refOpen.current = fullviewIsOpen
    }, [fullviewIsOpen])

    const confirmed = async () => {
        refOpen.current && dispatch(closeFullview())

        if(!CheckEditDate.scheduleInfo?.id) {
            CheckEditDate.scheduleInfo = null
            CheckEditDate.denied = false
            dispatch(hideConfirm())
            return
        }
        try {
            CheckEditDate?.preRegisteredReload?.()
            dispatch(hideConfirm())
            await managerGanttRef.updateStartEndApiPromise(CheckEditDate.scheduleInfo?.id, CheckEditDate?.scheduleInfo?.scheduleUpdatePayload?.dateTimeFrom ?? '', CheckEditDate?.scheduleInfo?.scheduleUpdatePayload?.dateTimeTo ?? '',)
            CheckEditDate.scheduleInfo = null
            CheckEditDate.denied = false
            CheckEditDate?.registeredReload?.()
        } catch (err) {
            CheckEditDate.scheduleInfo = null
            CheckEditDate.denied = false
            dispatch(hideConfirm())
            CheckEditDate?.registeredReload?.()
        }
    }

    const denied = () => {
        CheckEditDate.denied = false
    }

    const onShowConfirm = useCallback(() => {
        dispatch(showConfirm("Refresh required - It is needed to refresh the page to set the project start date earlier", confirmed, false, false, denied, true))
    }, [])

    useEffect(() => {
        CheckEditDate.onShowConfirm = onShowConfirm
    }, [onShowConfirm])

    const onChangeProjectStartDate = (date) => {
        CheckEditDate.projectStartDate = date
    }

    return onChangeProjectStartDate
}