import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'recompose'
import classnames from "classnames";
import QuestionMark from './QuestionMark';
import ImgFab from './ImgFab';
import QuestionText from './QuestionText';
import QuestionStatus from './QuestionStatus';
import { withTranslation } from 'react-i18next';
const styles = theme => ({
    container: {
        // opacity: 0.6,
        "height": "204px",
        // "backgroundImage": "linear-gradient(250deg, #ffcccc, rgba(255, 0, 0, 0.56) 46%, #ff0000)",
        "backgroundImage": "linear-gradient(235deg, #ffcccc, rgba(255, 0, 0, 0.56) 46%, #ff0000)",
        "position": "relative"
    },
    question: {
        "width": "314px",
        "fontFamily": "Roboto",
        "fontSize": "24px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "0.2px",
        "textAlign": "center",
        "color": "#ffffff"
    }
});

class QuestionTemplateNoComment extends PureComponent {

    status = () => {
        const { t } = this.props;
        const num = this.props.result.taggedUsers.length;

        if (num == 0) {
            return ''
        } else if (num == 1) {
            return '1 ' + t('people were asked to answer')
        } else {
            return `${num} ${t('people were asked to answer')}`
        }
    }

    render() {

        const { classes, result, openItem, isInput } = this.props;
        const { taggedUsers, postText } = result;

        return (
            <Grid onClick={openItem} container wrap='nowrap' direction='column' className={classes.container} alignItems='center'   >
                <QuestionMark />
                <ImgFab taggedUsers={taggedUsers} isInput={isInput} />
                {taggedUsers.length ? <QuestionStatus status={this.status()} /> : null}
                <QuestionText text={postText} />
            </Grid>
        )
    }
}

export default compose(withStyles(styles), withTranslation('postInput'))(QuestionTemplateNoComment);
