import { withStyles } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import DrawerContainer from '../../../components/App/Drawers/SearchLeftDrawer/DrawerContainer';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import {DrawerSwitcherNew} from '../../search/Components/DrawerSwitcher';
import {themeConstants} from '../../../../theme';
import {CreateGroupInfoDrawer } from './CreateGroupInfoDrawer'

const styles = theme => ({
    container: {
        paddingTop: 24,
    },
    divider: {
        margin: 16,
        marginTop: 24,
        backgroundColor: '#daddee',
    },
    dividerSmall: {
        margin: 16,
        marginTop: 24,
        marginLeft:  12,
        marginRight: 12,
    },
    navigation: {
        marginBottom: 12,
        marginLeft: 12,
        marginRight: 12,
    },
    paper: {
        height: 'inherit!important',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      
})

const __CreateGroupDrawer = (props) => {
    const {
        classes, 
        isDrawerOpen, 
        handleSwitchDrawerStatus,
    } = props

    const paperStyle = isDrawerOpen ? {} : { width: themeConstants.navigationDrawerSmallWidth, overflowX: 'hidden' }

    return (
        <DrawerContainer PaperProps={{style: paperStyle}} containerStyle={`${classes.container} ${classes.paper}`} open={true}>
            <DrawerSwitcherNew  isDrawerOpen={isDrawerOpen} onClick={handleSwitchDrawerStatus}/>
            <CreateGroupInfoDrawer isDrawerOpen={isDrawerOpen} />
        </DrawerContainer>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const CreateGroupDrawer = compose(
    withStyles(styles),
    withErrorCatcher("Failed loading Creating Group, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps)
)(__CreateGroupDrawer);