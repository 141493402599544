import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { updateLatestGroupTimestamp } from '../../../actions/groupsNavigationActions';
import { DATA_UPDATE, SEARCH_QUERY } from '../../../actionTypes/actionTypes';
// import GroupLeftDrawer from "./GroupLeftDrawer/GroupLeftDrawer";
import {GroupLeftDrawerNew} from "./GroupLeftDrawer/GroupLeftDrawerNew";
import { IsQueryExistInCollections, getCollection } from './groupUtils';
import { openedGroupActions } from '@tuqqi/web';
import { getGroupViewFromPath } from '../../../utils/groupsNavigation/getGroupViewFromPath';
import { managerLastGroupsView } from '../../../managers/managerLastGroupsView';

const styles = theme => ({
    fullViewWithDrawer: {
        marginLeft: 328,
        height: '100%',
        overflow: 'hidden'
    }
})

class GroupView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '/group',
            isDrawerOpen: false
        }
        this.IsQueryExistInCollections = IsQueryExistInCollections.bind(this);
        this.getCollection = getCollection.bind(this);
    }


    handleSwitchDrawerStatus = (status) => {
        this.setState({isDrawerOpen: status})
    }

    // componentDidUpdate(prevProps, prevState){
    //     // console.log('prevvvvState', prevState.isDrawerOpen)
    //     // console.log(prevState.isDrawerOpen !== prevProps.isDrawerOpen)
    //     if (prevState.isDrawerOpen !== prevProps.isDrawerOpen) {
    //         // console.log('window.location.pathname prevState',prevState.isDrawerOpen)
    //         console.log('window.location.pathname',window.location.pathname)
    //         if(window.location.pathname !== "/group/feed") return 
    //         else {
    //             this.setState({isDrawerOpen: true })  
    //         } 
    //     }
    // }

    render() {
        const { filters, classes, children, location, pathname } = this.props

  
        return <Fragment>
                    <GroupLeftDrawerNew handleSwitchDrawerStatus={this.handleSwitchDrawerStatus} isDrawerOpen={this.state.isDrawerOpen}/>
                    {React.cloneElement(children, {isDrawerOpen: this.state.isDrawerOpen})}
               </Fragment>
    }

    updateSearchQuery = (collectionToOpen) => {
        const { urlQuery, dispatch, location, queryService } = this.props;
   
        if (!collectionToOpen) {
            dispatch(push('feed'));
        }
        else {
            let path = location.pathname.replace(/\/group(\/?)/i, '')

            if (!path) {
                path = collectionToOpen.defaultView || 'feed'
                dispatch(push({ pathname: `/group/${path}`, search: '?searchquery=' + encodeURIComponent(`/${collectionToOpen.collectionUid}`) }))
            }


            dispatch({
                type: SEARCH_QUERY, query: {
                    query: '',
                    collectionUid: collectionToOpen.collectionUid,
                    groupUid: collectionToOpen.collectionGroup.groupUid,
                    collectionLabel: collectionToOpen.title,
                    isCollection: true,
                    page: 0,
                    view: path
                }
            })
            queryService.getCollectionInfo(collectionToOpen.collectionUid).then((result) => {
                if(!result) return
                dispatch({
                    type: 'RESULT_CHANNEL_UPDATE', results: {
                        channel: result,
                    }
                })
            })
            // dispatch({ type: DATA_UPDATE })
        }
    }
    isCategory = () => {
        const {pathname} = this.props;
        return pathname && pathname.includes('/group/category')
    }

    isKanban = (pathname) => {
        return pathname && pathname.includes('/group/kanban')
    }

    isSettings = (pathname) => {
        return pathname && pathname.includes('/group/settings')
    }

    isConnectors = (pathname) => {
        return pathname && pathname.includes('/group/connectors')
    }

    componentDidMount() {
        const { urlQuery, searchQuery, dispatch, userCollections, openCollections, pathname } = this.props;

        let collectionToOpen;
        if (!urlQuery) {
            !this.isCategory() && dispatch(push('feed'));
        }
        else {
            this.listenGroupPath({pathname, urlQuery}, true)
            const query = urlQuery.slice(1);
            const collection = userCollections.concat(openCollections)
                .find(coll => coll.title === query || coll.collectionUid === query)

            if (collection) {
                collectionToOpen = collection;
            }
            else if (searchQuery.collection) {
                collectionToOpen = searchQuery.collection;
            }
            else {
                this.getCollection(this.props, query).then(collectionToOpen => {
                    if(!collectionToOpen) return
                    this.updateSearchQuery(collectionToOpen)
                    return
                })
            }
            this.updateSearchQuery(collectionToOpen)
        }
    }
    updateLatest = (groupUid) => {
        const { dispatch } = this.props
        dispatch(updateLatestGroupTimestamp(groupUid))
    }

    persistLastGroupView = (prevProps) => {
        try {
            const prevPath = prevProps.pathname
            const pathname = this.props.pathname
            if(prevPath === pathname) return
            const url = this.props.urlQuery
            if(!url) return

            const collectionUid = url.slice(1);
            const view = getGroupViewFromPath(pathname)
            
            managerLastGroupsView.push(collectionUid, view, this.props.userUid)
        } catch (error) {
            
        }
    }

    componentDidUpdate(prevProps) {
        this.persistLastGroupView(prevProps)
    }

    listenGroupPath = (nextProps, isMount = false) => {
    
        const {dispatch} = this.props
        const prevPath = this.props.pathname
        const path = nextProps.pathname
        const prevUrl = isMount ? '' : this.props.urlQuery
        const url = nextProps.urlQuery

        const needContinue = !!this.isKanban(path) || !!this.isSettings(path) || !!this.isConnectors(path)

        if(!url || !needContinue) return
        if(!isMount && url === prevUrl && prevPath === path) return
        const query = url.slice(1);
        this.getCollection(this.props, query).then(collectionToOpen => {
         
            if(!collectionToOpen) return
            dispatch(openedGroupActions.changeGroup(collectionToOpen.collectionGroup.groupUid))
            return
        })
    }

    componentWillReceiveProps(nextProps) {
        const { collectionTitle: nextCollectionTitle, collection: nextCollection, collectionUid: nextCollectionUid } = nextProps.searchQuery
        const { searchQuery: {collectionTitle: prevCollectionTitle, collectionUid: prevCollectionUid} } = this.props;
        
        this.listenGroupPath(nextProps)
        if(prevCollectionTitle === nextCollectionTitle || prevCollectionUid === nextCollectionUid) return;
        //changed collection
        nextCollection && this.updateSearchQuery(nextCollection);
    }

}

const mapStateToProps = (state, ownProps) => ({
    ...state.wrapper,
    urlQuery: ownProps.location.query.searchquery,
    searchQuery: state.search,
    ...state.services,
    userUid: state.userData.profileData.auth0_id,
    lastGroupViews: state.groupsNavigation.lastGroupViews,
    userCollections: state.userData.collections,
    openCollections: state.globalData.openCollections
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles), connect(
    mapStateToProps,
    mapDispatchToProps
))(GroupView);