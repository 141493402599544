import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import { shouldAutoOpen } from '../groupUtils';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer';
import withPusher from '../../../components/Common/Pusher/withPusher';
import { loadAggData } from '../../search/searchUtils';
import { NewUiWrapper, Kanban } from '@tuqqi/web';
import {store} from '../../../../index'
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common';
import { openInput, changeCollectionInputInfo  } from '../../../../actions/inputActions.js';
import { openInput as openNewUiInput } from '@tuqqi/web'
import { FULLVIEW_POST, INPUT_OPEN,  FULLVIEW_CLOSE } from '../../../../actionTypes/actionTypes';
import { themeConstants } from '../../../../theme';
import ListRuleModal from '../../../components/Kanban/ListRules/ListRuleModal.js';
import { addConfetti } from '../../../../ui/components/Kanban/confetti';
import { withOpenFullview } from '../../../../new-ui/withOpenFullview.js';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions.js';
import { showSnackbar } from '../../../../actions/appActions.js';
import { canUserAddItemToGroup } from '../../../components/Common/Utils/userPermissionsUtils';

const styles = theme => ({
    kanban: {
        paddingTop: 6 + 11,
        marginLeft: themeConstants.navigationDrawerBigPXWidth + 24,
        width: 'fit-content',
        backgroundColor: 'rgb(244, 242, 238)',
    },
    kanban1: {
        paddingTop: 6 + 11,
        marginLeft: themeConstants.navigationDrawerSmallPXWidth + 24,
        width: 'fit-content',
        backgroundColor: 'rgb(244, 242, 238)',
    },
})
export class GroupKanbanNew extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
        this.loadAggData = loadAggData.bind(this)
    }
    // componentWillReceiveProps(nextProps) {
    //     const { fullview: { open } } = nextProps
    //      !open && loadAggData(nextProps.searchQuery, nextProps.dispatch, nextProps.queryService)
    // }

    async componentDidMount() {
        const { dispatch, searchQuery, queryService } = this.props;
      
        loadAggData(searchQuery, dispatch, queryService)
    }

      addCard = (id) => {
        const { dispatch, currentGroup,groupUid, inputMappings, oldUiInput, userData, searchQuery, changeCollectionInputInfo } = this.props
        const { item } = currentGroup
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }

        const kanbanListId = id === -1 ? "" : id + ''

        if (shouldAutoOpen(item)) {
            const dataType = item.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                dispatch({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: kanbanListId }
                dispatch(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, userData, searchQuery, oldUiInput, inputMappings).forEach(dispatch)
            }
        } else {
            dispatch({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            dispatch({ type: INPUT_OPEN, model: payload });
        }

        changeCollectionInputInfo({ kanbanListId })
    }

    renderListRuleModal = (
        kanbanLane,
        listRuleOpen,
        triggerType,
        rule,
        handleCloseRuleModal) => (
        <ListRuleModal
            list={kanbanLane}
            triggerType={triggerType}
            rule={listRuleOpen ? rule : null}
            open={listRuleOpen}
            handleClose={handleCloseRuleModal}
        />
      )

      canAddRule = (incomingRules) => {
        const { subscription } = this.props;
  
        if (subscription.subscriptionType === 0 && incomingRules.length >= 2) {
            return false
        }
        if (subscription.subscriptionType === 1 && incomingRules.length >= 2) {
            return false
        }
        return true
    }
    openSubscriptionDialog = () => {
        const { dispatch } = this.props;
        dispatch(initSubscriptionModal({ reason: 'incomingRules', mode: 'existed' }))
        dispatch(openSubscriptionModal())
    }

    showSnackbarForRules = (snackbarText) => {
        const { dispatch } = this.props;
        dispatch(showSnackbar(snackbarText))
    }
      
    hasPermissionToAdd = () => {
        const { collectionUid, userData } = this.props

        return canUserAddItemToGroup(userData.profileData, userData.collections, collectionUid)
    }

    render() {
        const { classes, isDrawerOpen,currentGroup ,groupUid,oldUiData,openFullview } = this.props;

        const callbacks = {
            addCard: this.addCard ,
            renderListRuleModal: this.renderListRuleModal,
            addConfetti: addConfetti,
            openUnsupportedFullview: openFullview,
            openSubscriptionDialog: this.openSubscriptionDialog,
            canAddRule: this.canAddRule,
            showSnackbarForRules: this.showSnackbarForRules,
            hasPermissionToAdd: this.hasPermissionToAdd,
            initChannel:  this.props.initChannel
        }
     
        return <CategoryViewOnlyLeftDrawer >
                <div data-intrcm-id="groupKanbanNew_l9bgkbia" className={isDrawerOpen ? classes.kanban : classes.kanban1}>
                    <NewUiWrapper store={store} callbacks={callbacks}>
                            { !!currentGroup.isOpen &&
                            <Kanban        
                            /> 
                            }
                    </NewUiWrapper>
                </div>
            </CategoryViewOnlyLeftDrawer>
        
    }
}
const mapStateToProps = (state) => ({
    ...state.services,
    oldUiData: state.oldUiData,
    inputMappings: state.globalData.inputMappings,
    searchQuery: state.search,
    userData: state.userData,
    fullview: state.fullview,
    collectionUid: state.results.channel.collectionUid,
    groupUid: state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
    oldUiInput: state.oldUiInput.document,
    subscription: state.globalData.currentSubscription,
    currentGroup:  state.group,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        changeCollectionInputInfo: (collectionInputInfo) => dispatch(changeCollectionInputInfo(collectionInputInfo))
    }
}
export default compose(withStyles(styles),
    withPusher,
    withTranslation('kanbanTranslate'),
    withOpenFullview,
    withErrorCatcher("Failed Loading Kanban, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupKanbanNew);