import React from 'react'
import DocStatus from './DocStatus'

import { Column, ColumnStore } from '@bryntum/gantt';

export default class StatusTaskColumn extends Column {
    static get type() {
        return 'statusTask';
    }

    static get isGanttColumn() {
        return true;
    }

    static get defaults() {
        return {
            field      : 'statusTask',
            cellCls    : 'b-status-column-cell',
        };
    }

    renderer(xxx) {
        const { record, value } = xxx
        const { id, originalData: {isItemRow,  }} = record;
        if(!isItemRow || !id) return ''

        return <DocStatus record={record} value={value} />
    }
}

ColumnStore.registerColumnType(StatusTaskColumn);
