import { usePrevious, managerRemovedItemsLocal } from '@tuqqi/web'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const initialSearchQuery = {
    page: 0,
    size: 100,
    query: '',
    AggType: 'FLAT'
}

const uniqueDocumentItems = (items) => {
    const presentedIds = []
    const filtered = items.reduce((accum, item) => {
        if(presentedIds.includes(item.docId)) return accum
        presentedIds.push(item.docId)
        return [...accum, item]
    }, [])
    return filtered
}

export const useSearchItemsQueryLocal = (ctx) => {
    const {returnFullData, clearBeforeLoad, filterResponse, queryService, queryFiltersUpdateType, queryFiltersItemId} = ctx

    const abortControllerRef = useRef(new AbortController())
    const initialCountRef = useRef({
        init: false,
        count: 0
    })
    const searchQueryRef = useRef(initialSearchQuery)
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [hasMore, setHasMore] = useState(false)

    const cancelRequest = useCallback(() => {
        if(!abortControllerRef.current) return
        abortControllerRef.current.abort()
    }, [])

    const checkHasMore = useCallback((count) => {
        let hasMore = true
        if (count <= (searchQueryRef.current.page + 1) * searchQueryRef.current.size) {
            hasMore = false;
        }
        setHasMore(hasMore)
    }, [])

    useEffect(() => {
        return () => {
            cancelRequest()
        }
    }, [])

    const prevQueryFiltersUpdateType = usePrevious(queryFiltersUpdateType)
    
    useEffect(() => {
        if(queryFiltersUpdateType === 'delete' && prevQueryFiltersUpdateType !== 'delete' && queryFiltersItemId) {
            setItems(prev => prev.filter(x => !managerRemovedItemsLocal.getItems().includes(x.docId)))
        }
    }, [queryFiltersItemId, queryFiltersUpdateType])

    const resolveArg = useCallback((arg) => {
        switch (arg) {
            case "isLoading":
                setIsLoading(false)
                return
            case "isLoadingMore":
                setIsLoadingMore(false)
                return
        }
    }, [])

    const resolveFetch = useCallback(async (searchQuery, arg) => {
        const newQuery = {...searchQuery}

        queryService.Search(newQuery, null, returnFullData).then((result) => {
            let resultItems = (result?.data ?? []).map(i => i.Value.Source)
            if(!!filterResponse) {
                resultItems = filterResponse(resultItems)
            }
            let returnItems = []

            if(!initialCountRef.current.init) {
                initialCountRef.current = {init: true, count: result.count}
            }

            if(newQuery.page === 0) {
                returnItems = resultItems
            } else {
                const data = [...items, ...resultItems]
                returnItems = uniqueDocumentItems(data)
            }

            setItems(returnItems.filter(x => !managerRemovedItemsLocal.getItems().includes(x.docId)))
            checkHasMore(result.count)
            resolveArg(arg)
        })
    }, [abortControllerRef.current, checkHasMore, items, returnFullData, resolveArg, filterResponse])

    const loadData = useCallback((_searchQuery) => {
        abortControllerRef.current = new AbortController()
        searchQueryRef.current = _searchQuery
        setIsLoading(true)
        !!clearBeforeLoad && setItems([])
        
        resolveFetch(_searchQuery, 'isLoading')
    }, [abortControllerRef.current, resolveFetch, clearBeforeLoad])

    const loadMore = useCallback(async() => {
        if(isLoadingMore) return
        const _searchQuery = {...searchQueryRef.current, page: searchQueryRef.current.page + 1}

        setIsLoadingMore(true)
        resolveFetch(_searchQuery, 'isLoadingMore')
    }, [isLoadingMore, resolveFetch])

    return {
        isLoading, 
        isLoadingMore, 
        hasMore, 
        items, 
        loadMore,
        loadData, 
        abortData: cancelRequest,
    }
}
