import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, IconButton, Checkbox, Button } from '@material-ui/core';
import { StarBorder, Delete, Image } from '@material-ui/icons';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/goToActions'

import moment from 'moment';
import urlRegex from 'url-regex';

import { showSnackbar, showConfirm } from '../../../actions/appActions'

import DeleteButton from "./deleteButton";
import SplitedText from "./splitedText";
import ImgComment from "./imgComment";
import DateFrom from "./dateFrom";
import HelpfulButton from "./helpfulButton";
import { DELETE_COMMENT } from '../../../actionTypes/actionTypes';
import parseImgeUrl from '../Common/parseImagUrl.js';
import { getFileLinkFromCloundfront } from '../../../utils/filesUtils.js';
import FileMessage from '../../views/chat/FileMessage/FileMessage.js';

const styles = theme => ({
    // tag: {
    //     color: 'blue',
    //     cursor: 'pointer'
    // },
    // text: {
    //     fontFamily: '"Roboto"',
    //     fontSize: 14,
    //     fontWeight: 'normal',
    //     fontStyle: 'normal',
    //     fontStretch: 'normal',
    //     lineHeight: 1.43,
    //     letterSpacing: '0.1px',
    //     textAlign: 'left',
    //     color: 'rgba(0, 0, 0, 0.87)',
    // },
    // grayDot: {
    //     width: '3.2px',
    //     height: '3.2px',
    //     backgroundColor: '#b2b2b2',
    //     borderRadius: '50%',
    //     position: 'relative',
    //     marginLeft: 5,
    //     marginRight: 3,
    //     display: 'inline-block',
    //     top: -3
    // },
    media: {
        display: 'inherit',
        // margin: 'auto',
        borderRadius: 5,
        maxWidth: '50%',
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0
    },
    commentTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,

        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',

        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    commentSubTitle2: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.3,
        color: 'rgba(0, 0, 0, 0.38)',
        display: 'flex'
    },
    iconButton: {
        root: {
            fontSize: 14
        }
    },
    image: {
        cursor: 'pointer',
        width: '50%',
        maxHeight: 300,
        objectFit: 'cover',
        borderRadius: 18,
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.35)",
        marginTop: 6
    },
    fileContainer:{
        margin: '8px 0'
    },
    ...theme.comment,
})

class Comment extends Component {
    state = {
        choosen: false
    }




    delete = () => {
        const { comment, collaborationService, onCommentDelete, dispatch } = this.props;

        dispatch(showSnackbar('Deleting comment...'));

        collaborationService.deleteComment(comment.key).then(() => {
            onCommentDelete(comment);
            dispatch(showSnackbar('Comment deleted!'));
        })
    }



    componentWillReceiveProps(nextProps) {
        const { deleteCommentUid, comment, dispatch } = nextProps
        if (deleteCommentUid === comment.key) {
            dispatch({ type: DELETE_COMMENT, data: { deleteCommentUid: '' } })
            this.delete()
        }
    }

    onDelete = () => {
        const { comment, dispatch } = this.props
        const action = { type: DELETE_COMMENT, data: { deleteCommentUid: comment.key } }

        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), action))
    }

    onComment = () => {
        const { comment, dispatch, collaborationService, view } = this.props;

        dispatch(showSnackbar('Choosing answer...'));

        collaborationService.chooseComment(comment).then(res => {
            dispatch(showSnackbar('Answer choosed!'));
            this.setState({ choosen: true })

            dispatch({
                type: 'VIEW_OPEN',
                model: { ...view, selectedComment: comment.text }
            })
        });
    }

    renderAttachment = (attachment) => {

        const { classes, cloudfront } = this.props
        const  { fileType, fileUid } = attachment

        
        if (fileType.startsWith("image")) {
            const imgUrl = parseImgeUrl(cloudfront, "fileId=" + fileUid, 100)
            return <img key={fileUid} src={imgUrl} className={classes.image} onClick={x => window.open(imgUrl)} />
        }
        if (fileType.startsWith("video")) {

            const videoUrl = getFileLinkFromCloundfront(cloudfront, fileUid)

            return <video key={fileUid} className={classes.media} controls  onError={(e) => { this.setState({ error: true }) }} >
                <source src={videoUrl} type="video/mp4" />
            </video>
        }

        return <FileMessage attachment={attachment} fileContainerClassName={classes.fileContainer}/>
    }


    render() {
        const { classes, comment, last, isChoose } = this.props;
        const { userPicUrl, userDisplayName, dateTime, userId, text, type, attachments } = comment;

        return (
            <div data-intrcm-id="Comment_3bg2xu1j" className={classes.container}  >
                <ImgComment userId={userId} src={userPicUrl} />
                <div data-intrcm-id="Comment_77oosxsi" className={classes.containerContentTime} style={{ borderBottomStyle: last ? 'none' : 'solid' }}>
                    <div data-intrcm-id="Comment_2cxhl2z0" className={classes.content}>
                        <div data-intrcm-id="Comment_xm57gni4" className={classes.commentTitle}>{userDisplayName}</div>
                        <div data-intrcm-id="Comment_as5kptev" className={`${classes.commentSubTitle} comment-content`}>
                            <SplitedText text={text} style={{ whiteSpace: 'pre-line' }} />
                            {attachments.map(this.renderAttachment)}

                        </div>
                        <div data-intrcm-id="Comment_qdl8v0xg" className={classes.commentSubTitle2} style={{ overflow: 'hidden', height: 'auto', lineHeight: 1, paddingTop: 2 }}>
                            <HelpfulButton comment={comment} />
                            <DateFrom dateTime={dateTime} />
                            <DeleteButton creatorId={userId} onDelete={this.onDelete} />
                        </div>
                    </div>

                    {isChoose
                        ? <Checkbox
                            onChange={this.onComment}
                            checked={this.state.choosen}
                            tabIndex={-1}
                            disableRipple
                        />
                        : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    view: state.view,
    deleteCommentUid: state.confirmDialog.deleteCommentUid,
    cloudfront: state.globalData.cloudfront,
    ...state.services
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Comment);