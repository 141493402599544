import React from 'react'
import * as Sentry from '@sentry/browser';

export const getDefaultErrorMessage = (additionalInfo = null) => {
    if (additionalInfo) {
        return <div data-intrcm-id="withErrorCatcher_51qp58ar">{`Error: ${additionalInfo}, please contact Tuqqi support`}</div>
    }

    return <div data-intrcm-id="withErrorCatcher_a9f4k251">Error: please contact Tuqqi support</div>
}

const withErrorCatcher = CustomError => (WrappedComponent) => {

    return class ErrorCatcher extends React.Component {

        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }

        componentDidCatch(error, info) {
            Sentry.captureException(error);
            this.setState({ hasError: true, error, info });
        }

        renderError = () => {
            const { error, info } = this.state

            if (!CustomError) {
                return getDefaultErrorMessage()
            }

            if (typeof CustomError === "string") {
                return <div data-intrcm-id="withErrorCatcher_sv75cter">{CustomError}</div>
            }

            return <CustomError errorCatcherInfo={{ error, info }} />
        }

        render() {

            if (this.state.hasError) {
                return this.renderError()
            }
            return <WrappedComponent {...this.props} />
        }

    }
}

export default withErrorCatcher