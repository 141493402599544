import { withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';

const styles = theme => ({
    title: {
        width: 202,
        'max-width': 218,
        'word-break': 'break-word',
        "maxHeight": "48px",
        "overflow": "hidden",
        "WebkitLineClamp": "2",
        "WebkitBoxOrient": "vertical",
        "display": "-webkit-box",
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "400",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "color": '#21212D'
    }
})


class KanbanCardTitle extends PureComponent {
  
    render() {
        const { classes, title } = this.props

        return <div data-intrcm-id="KanbanCardTitle_p325o4fs" dir='auto' className={classes.title}>{title}</div>
    }
}

export default withStyles(styles)(KanbanCardTitle);