import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { LaunchpadCategory } from '../Common/LaunchpadCategory';
import { LaunchpadNewCategory } from '../Common/LaunchpadNewCategory';
import { LaunchpadEditNameCategory } from './LaunchpadEditNameCategory'

const styles = (theme) => ({
    container: {
        padding: '24px 16px',
        paddingTop: 0,
    },
})

function LaunchpadEditOptions(props) {
    const {
        allowedOptions,
        classes, 
        isAddingCategory,
        editCategory,
    } = props
    
    const firstLayer = useMemo(() => {
        return allowedOptions.filter((x) => x.categoryId === 0 && x.type === 0)
    }, [allowedOptions])

    const lastOrder = useMemo(() => {
        const orders = !!firstLayer.length ? firstLayer.map((i) => i.order) : [0] 
        return (Math.max(...orders) + 1)
    }, [firstLayer])

    return (
        <div data-intrcm-id="LaunchpadEditOptions_o1j2f8rl" className={classes.container} >
            {firstLayer.map((item) => {
                if(editCategory !== 0 && editCategory === item.id) return <LaunchpadEditNameCategory option={item} />

                return <LaunchpadCategory editable key={item.id} item={item} />
            })}
            {!!isAddingCategory && <LaunchpadNewCategory lastOrder={lastOrder} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      editCategory: state.launchpadNavigation.editCategory,
      allowedOptions: state.launchpadNavigation.allowedOptions,
      isAddingCategory: state.launchpadNavigation.isAddingCategory
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export const LaunchpadEditOptionsSection = compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
)(LaunchpadEditOptions)
