import { DialogContent, DialogContentText, withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import urlRegex from 'url-regex';
import AutosuggestField from '../Common/autosuggestField/autosuggestField';
import Spinner from "../Common/spinner";
import InputPreview from './InputPreview';
import QuestionChip from './QuestionChip';

const styles = theme => ({
    suggestionsContainerOpen: {
        position: 'fixed',
        left: 'auto',
        right: 'auto',
        maxWidth: 588,
        width: 'calc(100% - 127px)',
        minWidth: 250
    },

    inputContainer: {
        outline: 'none',
        userSelect: 'text',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    text: {
        fontSize: 14,
        color: '#1d2129',
        lineHeight: 1.34,
        unicodeBidi: 'embed',
    },
    link: {
        color: '#365899',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    dialogContent: {
        padding: '0 16px',
        minHeight: 87,
        maxHeight: '40vh'
    },
    spinner: {
        margin: '32px auto'
    },
    disabledDataType:{
        height: 16
    }
});


class ContentInputFullView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            postText: '',
            loading: false,
            isErrorInImage: false,
            isQuestionCanceled: false,
        };
    };


    handleLink = (text) => {
        const { queryService, linkPreviewService, dispatch, oldUiInput } = this.props

        if (this.state.loading) {
            return
        }

        // Get the  url itself
        const linkUrl = (text.match(urlRegex()))[0]

        this.setDefaultLink(linkUrl)


        this.setState({ loading: true })

        Promise.all([queryService.checkLink(linkUrl), linkPreviewService.getLinkPreview(linkUrl)])
            .then((results) => {

                const [resCheckLink, resLinkPreview] = results

                // link model
                const link = {
                    link: linkUrl,
                    previewTitle: resLinkPreview.title,
                    previewDescription: resLinkPreview.description,
                    previewImgUrl: resLinkPreview.image
                }

                // if the link already exist
                if (resCheckLink.length > 0) {
                    dispatch({ type: "INPUT_CHANGE", model: { ...link, hasPreview: true, title: link.previewTitle, alreadyExists: true, existsTag: resCheckLink[0].key } })

                } else {
                    // Check results are returned
                    if (resLinkPreview.title == '') {
                        link.previewTitle = text.substring(0, text.indexOf('/', "https://".length) + 1);
                        link.previewDescription = text;
                    }

                    dispatch({ type: "INPUT_CHANGE", model: { hasPreview: true, ...link, title: link.previewTitle, alreadyExists: false } })
                }

                // Set preview data to state
                this.setState({ loading: false, isErrorInImage: false })
            }).catch((reason) => {
                this.setDefaultLink(linkUrl)
                this.setState({ loading: false, isErrorInImage: true })
            });

    }



    setDefaultLink(linkUrl) {
        const link = {
            link: linkUrl,
            previewTitle: linkUrl,
            previewDescription: '',
            previewImgUrl: ''
        }
        this.props.dispatch({ type: "INPUT_CHANGE", model: { ...link, hasPreview: true, title: link.previewTitle, alreadyExists: false } })
    }


    setQuestionCanceled = () => {
        this.setState({ isQuestionCanceled: true })
    }

    onChange = (event, { newValue, method }) => {
        this.changeInput(newValue)
    }

    changeInput = (newValue) => {
        const { isQuestionSelected, dispatch, oldUiInput, isDataTypeEnableInPermission } = this.props

        const newInput = {
            postText: newValue
        }

        if (oldUiInput.dataType === 'post') {
            if (isDataTypeEnableInPermission('bookmark') && urlRegex().test(newValue)) {
                newInput.dataType = 'bookmark'
                newInput.mimeType = 'bookmark'
                this.handleLink(newValue)
            } else if ((!isQuestionSelected && !this.state.isQuestionCanceled) &&
                newValue.includes('?') &&
                isDataTypeEnableInPermission('question')) {
                newInput.dataType = 'question'
            }
        }

        dispatch({
            type: 'INPUT_CHANGE', model: newInput
        })
    }

    

    render() {
        const { classes, oldUiInput, t, isDataTypeEnableInPermission } = this.props

        if (!isDataTypeEnableInPermission(oldUiInput.dataType)) {
            return <div data-intrcm-id="contentInputFullView_075fvi3r" className={classes.disabledDataType}/>
        }

        return (

            <Fragment>
                <DialogContent className={`${classes.dialogContent} new-post-input-container`}>
                    <QuestionChip setQuestionCanceled={this.setQuestionCanceled} />
                    <DialogContentText style={{ height: 87 }}>
                        <AutosuggestField
                            placeholder={t('Start writing here')}
                            allowRows={true}
                            onChange={this.onChange}
                            value={oldUiInput.postText}
                            classes={{ suggestionsContainerOpen: classes.suggestionsContainerOpen }} />
                    </DialogContentText>
                </DialogContent>
                {
                    this.state.loading ? <Spinner classes={classes} /> : <InputPreview />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    oldUiInput: state.oldUiInput.document,
    inputService: state.services.inputService,
    queryService: state.services.queryService,
    linkPreviewService: state.services.linkPreviewService,
    filestack: state.globalData.filestack
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withTranslation('postInput'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ContentInputFullView);

