import { Button, FormControlLabel, Radio, Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';

const styles = theme => ({
    primary: {
        fontSize: 16
    },
    secondry: {
        fontSize: 14
    },
    disabled: {},
    withUpgradePlan: {
        border: `solid 3px ${theme.subscriptionColors.main}`,
        borderRadius: 8,
    },
    label: {
        position: 'relative',
        width: 915
    },
    iconButton: {
        padding: 12,
        position: 'absolute',
        right: 0,
        marginTop: -50
    },
    upgradeButton: {
        position: 'absolute',
        textTransform: "uppercase",
        top: 6,
        right: 15
    },
    upgradeText: {
        textTransform: "uppercase",
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: theme.subscriptionColors.main
    },
})

class CollectionPermissionSettingsOption extends PureComponent {
    static defaultProps = {
        withUpgradePlan: false,
        onClickUpgrage: () => {},
        upgradeText: ''
    }
    renderUpgradePlan = () => {
        const {classes, onClickUpgrage, upgradeText} = this.props;

        return (
            <Button onClick={onClickUpgrage}
                classes={{
                    root: classes.upgradeButton,
                    label: classes.upgradeText
                }} >
            {upgradeText}
            </Button>
        )
    }
    render() {
        const {
            classes,
            value,
            secondry,
            primary,
            disabled,
            withUpgradePlan,
            ...materialLabelProps
        } = this.props;

        return (
            <FormControlLabel className={[disabled && classes.disabled, classes.label, withUpgradePlan && classes.withUpgradePlan].join(" ")} value={value} disabled={disabled} control={<Radio  color="default"  />}
                label={
                    <React.Fragment>
                    <div data-intrcm-id="CollectionPermissionSettingsOption_9s1r3yaa" style={disabled ? {opacity: 0.5} : {}}>
                        <Typography className={classes.primary}>{primary} </Typography>
                        <Typography className={classes.secondry} dir='auto'>{secondry}</Typography>
                    </div>
                    {withUpgradePlan && this.renderUpgradePlan()}
                    </React.Fragment>
                } 
                {...materialLabelProps}
                />
        )
    }
}

export default compose(withStyles(styles))(CollectionPermissionSettingsOption);