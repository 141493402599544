import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { DarkTextPosTooltip, OpenItemIcon, TableFieldReadOnlyTooltip, managerGanttRef, managerGroupGantt, managerTableKeepGlobalData } from '@tuqqi/web'
import { isRTLValue } from '@tuqqi/web'
import { useTranslation } from 'react-i18next'
import CreateLaneModal from './CreateLaneModal'
import { LaneMenu } from './LaneMenu'
import { setDenyArrows } from '../grid/GridOverride'
import { shouldAutoOpen } from '../../../groupUtils'
import { FULLVIEW_CLOSE, FULLVIEW_POST, INPUT_OPEN } from '../../../../../../actionTypes/actionTypes'
import { getGroupDocumentPermission, getPublicDocumentPermission } from '@tuqqi/common'
import { openInput as openNewUiInput } from '@tuqqi/web'
import { changeCollectionInputInfo, openInput } from '../../../../../../actions/inputActions'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const styles = theme => ({
    inputWrapPointer: {
        cursor: 'pointer'
    },
    valueWrapOuterRTL: {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right'
    },
    text: {
        whiteSpace: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 13,
        'font-feature-settings': "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    textLTR: {
        paddingLeft: 8  
    },
    textRTL: {
        paddingRight: 8  
    },
    valueWrap: {
        marginLeft: 12,
        marginRight: 12,
        position: 'relative',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
    },
    valueWrapOuter: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    input: {
        padding: 0,
        paddingRight: 8,
        paddingLeft: 8,
        outline: 0,
        border: 0,
        backgroundColor: '#EDEDF0',
        color: '#a9a9a9',
        width: '100%',
        resize: 'none',
        ...theme.typography.body2,
        fontSize: 13, 
        'font-feature-settings': "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    inputWrap: {
        marginLeft: 12,
        marginRight: 12,
        width: 'fit-content',
        background: '#EDEDF0',
        marginTop: 'auto',
        marginBottom: 'auto',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        border: `1px solid transparent`,
    },
    singleLine: {
        height: 24,
        alignItems: 'center',
    },
    wrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'inherit',
        height: 'inherit'
    },
    openItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        cursor: 'pointer',
        marginRight: 4,
        marginLeft: 4,
        width: 20,
        height: 20,
        '&:hover': {
            background: '#EDEDF0!important'
        }
    },
})

function isTruncated(el) {
    if (!el) return false
    return el.scrollWidth > el.clientWidth
}

const getActualWidth = (record) => {
    if(!managerGanttRef.gantt) return 10
    const column = managerGanttRef.gantt.instance.columns.visibleColumns.find(x => x.type === 'name')
    if(!column) return 10
    if(typeof column.width !== 'number') return 10

    let gap = 50
    if(record.data.isRootRow) {
        gap = 43
    }
    if(record.data.isLane) {
        gap = 63
    }
    return column.width - gap
}

const getIsAdmin = () => {
    return managerGroupGantt.isUserGroupAdmin(managerTableKeepGlobalData.getUserDetails().id)
}

const _deleteLane = (record) => {
    managerGanttRef.deleteGroupOfTaskNameApi(record)
}
const _addLane = (name) => {
    managerGanttRef.addGroupOfTaskNameApi(name)
}

const View = memo((props) => {
    const {classes, title, record} = props

    const value = title

    const textRef = useRef(null)
    const [isCut, setIsCut] = useState(isTruncated(textRef.current))
    const [showCreateLane, setShowCreateLane] = useState(false)
    const [t] = useTranslation("gantt")
    const actualWidth = getActualWidth(record)
    
    const disabled = record.data?.isItemRow && !props.record?.data?.editable?.common

    const label = managerTableKeepGlobalData.tTable('Title')
    const [isHoverValue, setIsHoverValue] = useState(false)
    const allowHover = !disabled && !!isHoverValue
    const isRTl = useMemo(() => isRTLValue(value), [value])

    const addCard = () => {
        const groupUid = managerGroupGantt.getCurrentGroupUid()
        const payload = { postText: '', dataType: 'post', permissions: [groupUid] }
        const collectionInfo = managerGroupGantt.getKeepGroup()
        const ganttListId = record.id === -1 ? '' : record.id + ''

        const inputMappings = managerTableKeepGlobalData.getMappings()

        if (shouldAutoOpen(collectionInfo)) {
            const dataType = collectionInfo.settings.allowedInputs[0]
            let isCustomInput = false
            inputMappings.map((x) => {
                if (x.customDataMapping.dataTypeFilter === dataType) {
                    isCustomInput = true
                }
            })
            if (dataType === 'htmlwysiwyg' || dataType === 'company' || dataType === 'contact' || isCustomInput) {
                managerTableKeepGlobalData.dispatchF?.({ type: FULLVIEW_CLOSE })

                const permissions = groupUid ? getGroupDocumentPermission(groupUid) : getPublicDocumentPermission()
                let newDoc = { html: '', title: '', permissions, dataType: dataType, kanbanListId: '', ganttListId}
                managerTableKeepGlobalData.dispatchF?.(openNewUiInput(newDoc, dataType))
            } else {
                openInput(dataType, {profileData: managerTableKeepGlobalData.getUserDetails()}, {query: ''}, props.oldUiInput, inputMappings).forEach(managerTableKeepGlobalData.dispatchF)
            }
        } else {
            managerTableKeepGlobalData.dispatchF({
                type: FULLVIEW_POST,
                openPost: true,
                item: payload
            })
            managerTableKeepGlobalData.dispatchF({ type: INPUT_OPEN, model: payload });
        }
        managerTableKeepGlobalData.dispatchF(changeCollectionInputInfo({ kanbanListId: '', ganttListId }))
    }

    
    const onMouseEnter = useCallback(() => {
        setIsCut(isTruncated(textRef.current))
        if(!!allowHover) return
        setIsHoverValue(true)
    }, [allowHover])

    const onMouseLeave = useCallback(() => {
        setIsCut(isTruncated(textRef.current))
        setIsHoverValue(false)
    }, [])

    const wrapStyle = useMemo(() => {
        return {
            width: actualWidth - 24 - (isHoverValue && record.data.isItemRow ? 28 : 0)
        }
    }, [actualWidth, isHoverValue])

    const wrapStyleValue = useMemo(() => {
        return {
            width: actualWidth - 40 - (isHoverValue && record.data.isItemRow ? 28 : 0)
        }
    }, [actualWidth, isHoverValue])
    
    const wrapStyleValueWrap = useMemo(() => {
        return {
            maxWidth: actualWidth - 40 - (isHoverValue && record.data.isItemRow ? 28 : 0),
            overflow: 'hidden',
            whiteSpace: 'inherit',
            textOverflow: 'ellipsis',
        }
    }, [actualWidth, isHoverValue])

    const onClickEdit = useCallback(() => {
        if(record.data.isRootRow) return
        if(record.data.isLane && record.id === -1) return
        if(disabled) return

        props.setOpen(true)
        setDenyArrows()
    }, [disabled])

    const onClickOpenItem = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()

        if(!record.data.id || !record.data.isItemRow) return
        managerTableKeepGlobalData.openItem(record.data.id + '')
    }, [record])

    const onClickAdd = useCallback(() => {
        if(!record.data.isLane && !record.data.isRootRow) return
        if(record.data.isRootRow) {
            setShowCreateLane(true)
            return
        }
        addCard()
    }, [])

    const onCloseCreateLane = useCallback((name) => {
        setShowCreateLane(false)
    }, [])


    const onSaveCreateLane = (name) => {
        setShowCreateLane(false)
        _addLane(name)
    }

    const deleteLane = () => {
        _deleteLane(record)
    }

        const OpenItem = !isHoverValue || !record.data?.isItemRow ? null : <div data-intrcm-id="CellRenderTitleText_69pm4pob" onClick={onClickOpenItem} className={`${classes.openItem}`}>
            <OpenItemIcon />
        </div>

        const RootMore = !record.data?.isRootRow && !record.data.isLane ? null : <>
            <LaneMenu id={`lane-menu-${record.id}`} isDefaultLane={!!record.data.isLane && record.id === -1} isLane={!!record.data.isLane} isRootRow={!!record.data.isRootRow} deleteLane={deleteLane } dispatch={managerTableKeepGlobalData.dispatchF} isAdmin={getIsAdmin()} onClickAddCard={onClickAdd}/>
            {record.data.isRootRow && <CreateLaneModal onSave={onSaveCreateLane} onClose={onCloseCreateLane} open={showCreateLane} />}
        </>

        const NoValue = <div data-intrcm-id="CellRenderTitleText_bir9ggsf" className={`${classes.wrap} ${allowHover ? classes.inputWrapPointer : ''}`} onClick={onClickEdit} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div data-intrcm-id="CellRenderTitleText_zsowtjq0" dir="auto" style={wrapStyle} className={`${classes.inputWrap} ${classes.singleLine}`}>
            <div data-intrcm-id="CellRenderTitleText_ypm1gp21" className={classes.input}>{`${t("Add")} ${label.toLowerCase()}...`}</div>
        </div>
        {OpenItem}    
    </div>

    const Ttt = <div data-intrcm-id="CellRenderTitleText_tayjumx4" ref={textRef} style={wrapStyleValue} dir="auto" className={`${classes.text} ${isRTl ? classes.textRTL : classes.textLTR}`} >{value}</div>

    const Value = <div data-intrcm-id="CellRenderTitleText_zj7819q3" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`${classes.wrap} ${allowHover ? classes.inputWrapPointer : ''}`}>
    <div data-intrcm-id="CellRenderTitleText_1owzujmp" className={`${classes.valueWrapOuter} ${isRTl ? classes.valueWrapOuterRTL : ''}`}>
        <div onClick={onClickEdit} data-intrcm-id="CellRenderTitleText_96z6xrx1" className={`${classes.valueWrap} `} >
        {!isCut || disabled ? 
            Ttt :
            <DarkTextPosTooltip 
                styleWrap={wrapStyleValueWrap} 
                text={value} 
                component={Ttt} />
        }
        </div>
        </div>
        {OpenItem}    
        {RootMore}
    </div>

    const X = !!title?.trim?.() ? Value : NoValue
    if(disabled) return <TableFieldReadOnlyTooltip component={X} />


      return X
})

const mapStateToProps = (state) => ({
    oldUiInput: state.oldUiInput.document,
})


export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, null)
)(View)