import transitions from "@material-ui/core/styles/transitions";
import { createCustomTheme } from "./utils/theming";
import { getSubscriptionColors } from "./utils/subscription/subscriptionColors";

const defaultOptions = {brandingColor: 'default', subscriptionType: 0}

const launchpadWidth = 412
const leftDrawerWidth = 225
const leftDrawerPXWidth = 236
const leftDrawerSmallWidth = 216
const userNavigationWidth = 372
const notificationsNavigationWidth = 390
const chatsNavigationWidth = 390
const defaultToolbarHeight = 44
export const themeConstants = {
  leftDrawerWidth,
  leftDrawerPXWidth,
  leftDrawerSmallWidth,
  defaultToolbarHeight: 44,
  searchTabsHeight: 48,
  marginFromToolbar: 22,
  feedWidth: 864,
  feedItemWith: 512,
  rightWidget: 320,
  navigationDrawerWidth: 216,
  navigationDrawerWidthUser: 276,
  leftMarginWhenNavigationDrawerOpen: 216 + 24,
  navigationDrawerSmallWidth: 64,
  navigationDrawerSmallPXWidth: 48,
  navigationDrawerBigPXWidth: 240,
  navigationDrawerPXOffset: 16,
  userNavigationWidth,
  chatsNavigationWidth,
  notificationsNavigationWidth,
  searchInputWidth: 512
}

export default (options = defaultOptions) => {
  const themeColors = createCustomTheme(options.brandingColor);
  const subscriptionColors = getSubscriptionColors(options.subscriptionType)

  return {
    launchpadPageWrap: {
      width: launchpadWidth,
      height: 'fit-content',
      borderRadius: 12,
      backgroundColor: '#fff',
      maxHeight: `calc(100vh - ${defaultToolbarHeight}px - 24px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
          display: 'none'
      }
    },
    subscriptionColors,
    newUI: {
      scrollbar: {
        "&::-webkit-scrollbar": {
          width: 8,
          height: 8,
          display: "block",
        },
        "&::-webkit-scrollbar-thumb": {
          opacity: 0.5,
          backgroundColor: "#daddee",
          borderRadius: 4,
        },
      },
      scrollbarOLD: {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&:hover": {
          "&::-webkit-scrollbar": {
            display: "block",
            width: 4,
            height: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            opacity: 0.5,
            backgroundColor: "#daddee",
            borderRadius: 4,
          },
        },
      },
      tooltip: {
        container: {
          padding: "8px",
          fontFamily: "Rubik",
          fontSize: "12px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.34",
          letterSpacing: "0.4px",
          textAlign: "center",
          color: "#ffffff",
          borderRadius: "4px",
          backgroundColor: "#5b5e6c",
        },
        popper: {
          opacity: 1,
        },
        title: {
          fontFamily: "Rubik",
          fontSize: "12px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.33",
          letterSpacing: "0.4px",
          textAlign: "center",
          color: "#ffffff",
        },
      },
      text: {
        headline4: {
          fontFamily: "Rubik",
          fontSize: "30px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "0.25px",
        },
        caption: {
          fontFamily: "Rubik",
          fontSize: "12px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.33",
          letterSpacing: "0.4px",
        },
        body1: {
          fontFamily: "Rubik",
          fontSize: "16px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.75",
          letterSpacing: "0.5px",
        },
        subtitle1: {
          fontFamily: "Rubik",
          fontSize: "16px",
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.5",
          letterSpacing: "0.15px",
        },
        body2: {
          fontFamily: "Rubik",
          fontSize: "14px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.43",
          letterSpacing: "0.25px",
        },
        buttonCenter: {
          fontFamily: "Rubik",
          fontSize: "13px",
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.23",
          letterSpacing: "1.16px",
          textAlign: "center",
        },
      },
      buttonOutineActivePrimary: {
        "border-radius": 4,
        padding: "4px 8px",
        border: `solid 1px ${themeColors.primary.main}`,
      },
      color: {
        gray: "#979aaa",
        gray50: "#f3f6fb",
        gray400: "#b6b9ca",
        commonBlack: "#21212D",
        primary: themeColors.primary.main,
      },
    },
    typography: {
      // Use the system font instead of the default Roboto font.

      fontFamily: ["Rubik"].join(","),
      colorTextSecondary: "#979AAA",
      subheading: {
        fontSize: 14,
      },
      body1: {
        fontSize: 13,
      },
    },

    text: {
      oneRow: {
        "white-space": "nowrap",
        "overflow-x": "hidden",
        "text-overflow": "ellipsis",
      },
    },

    design: {
      HLine: {
        "background-color": "black",
        width: "100%",
        height: "0.9px",
        position: "absolute",
        margin: "0",
        padding: "0",
        border: "none",
        "z-index": "50",
      },
      VLine: {
        "background-color": "black",
        height: "100%",
        width: "0.9px",
        position: "absolute",
        margin: "0",
        padding: "0",
        border: "none",
        "z-index": "50",
      },
    },
    feedHeader: {
      title: {
        marginLeft: 16,
        marginBottom: 32,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      name: {
        height: 32,
        fontFamily: "Lucida Grande",
        fontSize: 16,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.32,
        letterSpacing: "0.15px",
        textAlign: "left",
        color: "#302F47",
      },
    },
    itemTemplate: {
      titleText: {
        fontFamily: '"Rubik"',
        fontSize: 16,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.5,
        letterSpacing: "0.2px",
        color: "#000000",
        overflow: "hidden",
      },

      content: {
        marginLeft: 0,
        height: 116,
        borderRadius: 5,
        backgroundColor: "#fafbfc",
        boxShadow: "0px 1px 6px 0 rgba(85, 85, 85, 0.17)",
        marginBottom: 0,
        flexWrap: "nowrap",
        position: "relative",

        wordBreak: "break-all",
        overflow: "hidden",
      },

      contentText: {
        fontFamily: '"Rubik"',
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.3px",
        color: "rgba(0, 0, 0, 0.64)",
        overflow: "hidden",
      },

      img: {
        flex: "0 0 104px",
        margin: "6px 0 6px 6px",
      },
    },

    constants: {
      leftDrawerWidth: 232, //old 288
      // toolbarHeight: 80,
      defaultToolbarHeight,
      launchpadWidth,
      userNavigationWidth,
      notificationsNavigationWidth,
      chatsNavigationWidth,
      searchTabsHeight: 48, //old 52
      marginFromToolbar: 32,
      feedWidth: 864, //1080,//1112
      feedItemWith: 512,
      rightWidget: 320,
      searchInputWidth: 588,
      

      themeColorBack:`${themeColors.primary.main}`
    },
    feed: {
      cardNewUI: {
        boxShadow: "none",
        borderRadius: 4,
        background: "#fff",
      },
      previewNewUI: {
        marginBottom: 24,
        border: "solid 1px #B6B9CA",
        maxWidth: 512,
      },
      rightSpace: {
        marginRight: 30,
      },
      leftSpace: {
        marginLeft: 44,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 1025,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
    comment: {
      container: {
        width: "auto",
        display: "flex",
        flexFlow: "nowrap",
      },
      imgContainer: {
        flex: "0 1 0px",
      },
      img: {
        margin: "18px 0 auto 16px",
        marginRight: "16px",
        width: 36,
        height: 36,
        backgroundColor: "#707070",
        borderRadius: "50%",
      },
      containerContentTime: {
        flex: "1 2 calc(100% - 110px)",
        flexFlow: "nowrap",
        marginTop: 14,
        display: "flex",
        paddingBottom: 10,
        borderBottom: "1px solid rgba(175, 175, 176, 0.3)",
      },
      content: {
        flex: "1 1 auto",
        minHeight: 40,
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        flexDirection: "column",
      },
      name: {
        fontFamily: '"Rubik"',
        fontSize: 14,
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.1px",
        textAlign: "left",
        color: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        fontFamily: '"Rubik"',
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.1px",
        textAlign: "left",
        color: "rgba(0, 0, 0, 0.87)",
      },
      time: {
        minWidth: 100,
        flex: "1 10 auto",
        marginRight: 26,
        fontFamily: "Rubik",
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: 0.3,
        color: "rgba(0, 0, 0, 0.38)",
      },
      helpfulButton: {
        textTransform: "none",
        fontSize: 14,
        padding: 0,
        alignItems: "center",
        marginTop: -4,
        minHeight: 0,
      },
    },
    clickableTitle: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    rightDrawerClasses: {
      titleWidget: {
        // opacity: 0.4,
        fontFamily: '"Rubik"',
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.67,
        letterSpacing: "0.1px",
        textAlign: "left",
        color: "rgba(0, 0, 0, 0.4)",
      },
      headerWidget: {
        name: {
          fontFamily: "Montserrat",
          fontWeight: "bold",
          fontSize: 16,
          lineHeight: 1.9,
          letterSpacing: 0.2,
          color: "#302f47",
          display: "block",
          fontStyle: "normal",
          fontStretch: "normal",
          marginBottom: 2,
        },
        title: {
          fontFamily: "LucidaGrande",
          fontWeight: "normal",
          fontSize: 12,
          lineHeight: 1.33,
          letterSpacing: 0.4,
          color: "rgba(0, 0, 0, 0.38)",
          display: "block",
          fontStyle: "normal",
          fontStretch: "normal",
          paddingBottom: 22,
        },
      },
      userList: {
        score: {
          width: 20,
          height: 20,
          borderRadius: "50%",
          fontFamily: '"Rubik"',
          fontSize: 10,
          fontWeight: "normal",
          fontStyle: "normal",
          fontStretch: "normal",
          lineHeight: 4,
          letterSpacing: 0.2,
          textAlign: "left",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifySelf: "end",
        },
        font: {
          fontFamily: '"Rubik"',
          fontSize: 13,
          fontWeight: "normal",
          fontStyle: "normal",
          fontStretch: "normal",
          lineHeight: 1.4,
          letterSpacing: 0.1,
          textAlign: "left",
          color: "#2d2d2d",
          margin: 0,
        },
        points: {
          opacity: 0.5,
        },
        userListContainer: {
          padding: "10px 0",
        },

        item: {
          margin: 0,
          flexWrap: "nowrap",
          padding: "16px 0px;",
        },
      },
    },
    newLeftDrawerClasses: {
      font: {
        fontFamily: '"Rubik"',
        fontSize: 13,
        lineHeight: 2.15,
        letterSpacing: "0.4px",
        textAlign: "left",
      },
      text: {
        fontSize: 14,
        opacity: 0.85,
        fontFamily: '"Rubik"',
        fontSize: 16,
        lineHeight: 1.75,
        letterSpacing: "0.5px",
        textAlign: "left",
        textOverflow: "ellipsis",

        whiteSpace: "nowrap",
        overflow: "hidden",

        color: "#21212d",
      },
      smallText: {
        opacity: 0.64,
      },
      title: {
        fontFamily: '"Rubik"',
        fontSize: 13,
        lineHeight: 2.15,
        letterSpacing: "0.4px",
        textAlign: "left",

        margin: 0,
        color: "rgba(0, 0, 0, 0.38)",

        padding: "0 0 0 29px",
      },
      leftItems: {
        // marginRight: 12//16
      },
      listItem: {
        //old padding: '0 0 0 24px',
        padding: "0px 12px 0px 20px",

        height: 48,

        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.87) ",
        "&:hover": {
          color: "rgba(26, 115, 232, 1) !important",
        },
        // width: 178
      },
      nestedListItem: {
        padding: "0 0 0 24px",
        height: 42,

        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.63)",
        "&:hover": {
          color: "rgba(26, 115, 232, 1) !important",
        },
      },

      selectedItem: {
        // width: 264,
        // fontWeight: '500 !important',
        // color: '#3f51b5 !important',
        // backgroundColor: '#e7e7e7 !important',
        // borderRadius: '0px 50px 50px 0px'
        color: "rgba(0, 0, 0, 0.87)  !important",
        backgroundColor: "rgba(151, 154, 170, 0.3) !important",
        borderRadius: 4,
      },

      icon: {
        fontSize: 24,
      },
      iconColor: {
        color: "#757575",
        marginRight: 22,
        overflow: "visible",
        width: 20,
      },
      iconColorSelected: {
        color: "unset",
        marginRight: 22,
        overflow: "visible",
        width: 20,
      },
      numNewItems: {
        right: 32,
      },
      nestedNotification: {
        fontFamily: '"Rubik"',
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "0.2px",
        color: "rgba(0, 0, 0, 0.63)",
        textAlign: "center",
        width: 12,
      },
      smallListItem: {
        height: 38,
      },
    },
    mainContent: {
      // width: 1300,
      maxWidth: 1112,
      marginLeft: "auto",
      marginRight: "auto",
    },
    searchContainer: {
      // width: 720,
      // maxWidth: 720,
      // marginLeft: 'auto',
      // marginRight: 'auto'
    },
    wrapper: {
      paddingTop: 56,
    },
    lines: {
      // These classes create lines on the screen in order to help pixel-perfect
      HLine: {
        "background-color": "black",
        width: "100%",
        height: "0.9px",
        position: "absolute",
        margin: "0",
        padding: "0",
        border: "none",
        "z-index": "50",
      },
      VLine: {
        "background-color": "black",
        height: "100%",
        width: "0.9px",
        position: "absolute",
        margin: "0",
        padding: "0",
        border: "none",
        "z-index": "50",
      },
    },

    palette: {
      background: {
        // Removed - TODO: Follow 0.31
        default: "#f7f7f7",
        paper: "#fff",
        appBar: "#f5f5f5",
        contentFrame: "#eeeeee",
        chip: "#e0e0e0",
        avatar: "#bdbdbd",
      },
      text: {
        primary: "#21212d",
        secondary: "#979AAA",
      },
      primary: {
        main: themeColors.primary.main
      }
    },

    overrides: {
      codePreview: {
        CodeMirror: { CodeMirrorWrap: { "border-left": "4px solid red" } },
      },
      MuiPaper: {
        // Name of the styleSheet
        elevation1: {
          "border-radius": "4px",
          "background-color": "#ffffff",
          "box-shadow": "0px 2px 6px 0 rgba(115, 106, 212, 0.15)",

          // boxShadow: '0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04)',
          transition: " box-shadow 0.2s ease-in-out",
          transition: transitions.create(["background-color", "box-shadow"], {
            duration: 200,
            easing: transitions.easing.easeInOut,
          }),

          "&:hover": {
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
            // transition: ' box-shadow 0.2s ease-in-out',
            // transition:transitions.create(['box-shadow']),
            transition: transitions.create(["background-color", "box-shadow"], {
              duration: 200,
              easing: transitions.easing.easeInOut,
            }),

            // transitionDelay: '0.1s'
          },
        },
        elevation2: {
          boxShadow: "0 2px 6px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04)",
        },
        //old elevation1
        elevation3: {
          boxShadow: "0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04)",
          transition: " box-shadow 0.2s ease-in-out",
          transition: transitions.create(["background-color", "box-shadow"], {
            duration: 200,
            easing: transitions.easing.easeInOut,
          }),

          "&:hover": {
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
            // transition: ' box-shadow 0.2s ease-in-out',
            // transition:transitions.create(['box-shadow']),
            transition: transitions.create(["background-color", "box-shadow"], {
              duration: 200,
              easing: transitions.easing.easeInOut,
            }),

            // transitionDelay: '0.1s'
          },
        },
      },
    },
    customColors: themeColors,
  };
};
