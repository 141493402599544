import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core'
import {StartEndPopover} from '@tuqqi/web'
import { isValid } from 'date-fns'

const styles = theme => ({
})


const CellRenderStartEndEdit = (props) => {
    const {value} = props

    const _onChange = useCallback((value) => {
        const [startDate = '', endDate = ''] = value
        let s = startDate
        let e = endDate
        if(!!startDate) {
            const temp = new Date(startDate)
            s = isValid(temp) ? temp.toISOString() : ''
        } else {
            s = ''
        }
        if(!!endDate) {
            const temp = new Date(endDate)
            e = isValid(temp) ? temp.toISOString() : ''
        } else {
            e = ''
        }
        props.onChange([s, e])
    }, [])

    const onClose = () => {
        props.onClose()
    }

    return <StartEndPopover
        focused={true}
        isPinned={false}
        onClose={onClose}
        refContainer={props.containerRef}
        value={value} 
        onChange={_onChange} />
}

export default withStyles(styles, {withTheme: true})(CellRenderStartEndEdit)