
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getUniqueBy } from '../../../../../utils/commonHelpers';
import { mapCollectionToGroupNavigation } from '../../../../../utils/groupsNavigation/collectionToGroupNavigation';
import { mapSearchGroups } from '../../../../../utils/searchNavigation/searchHelpers';
import MenuWithSearch from '../../../../components/Common/Menu/MenuWithSearch';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher';


const styles = theme => ({
});

const initFilterGroups = {
    openGroups: [],
    managedGroups: [],
    favouriresGroups: [],
    myGroups: []
}

const filterGroups = (managedGroups = [], myGroups = [], favouriresGroups = [], openGroups = []) => {
    const all = getUniqueBy('groupUid', [...managedGroups, ...favouriresGroups, ...myGroups, ...openGroups])

    return all
}

const PickDefaultGroup = ({ openCollections, searchNavigation, defaultCollectionUid, setDefaultCollectionUid }) => {
    const { managedGroups: managed, subscribedGroups: subscribed, starredGroups: starred } = searchNavigation

    const [t] = useTranslation('admin')
    const [collectionTitle, setCollectionTitle] = useState('')
    const [myGroups, setMyGroups] = useState(subscribed)
    const [favouritesGroups, setFavouriesGroups] = useState(starred)
    const [openGroups, setOpenedGroups] = useState([])
    const [managedGroups, setManagedGroups] = useState(managed)
    const [filteredGroups, setFilteredGroups] = useState([])




    useEffect(() => {
        const col = filteredGroups.find(g => g.groupUid === defaultCollectionUid)
        setCollectionTitle(col?.title ?? '')
    }, [filteredGroups, defaultCollectionUid])

    useEffect(() => {


        const all = filterGroups(managedGroups, myGroups, favouritesGroups, openGroups)


        setFilteredGroups(all)
    }, [myGroups, favouritesGroups, managedGroups, openGroups])

    useEffect(() => {
        const groups = openCollections.map((collection) => {
            return mapCollectionToGroupNavigation({ ...collection })
        })
        setOpenedGroups(groups)
    }, [openCollections])


    useEffect(() => {
        const myGroups = mapSearchGroups(subscribed, {
            maxLen: subscribed.length,
            withPlaceholder: false,
            starred,
            withFilterStarred: true,
            withSlice: false
        });
        setMyGroups(myGroups)
    }, [subscribed, starred])

    useEffect(() => {
        const managedGroups = mapSearchGroups(managed, {
            maxLen: managed.length,
            withPlaceholder: false,
            starred,
            withFilterStarred: true,
            withSlice: false
        });
        setManagedGroups(managedGroups)
    }, [managed, starred])

    useEffect(() => {
        const starredGroups = mapSearchGroups(starred, {
            maxLen: starred.length,
            withPlaceholder: false,
            withSlice: false
        });
        setFavouriesGroups(starredGroups)
    }, [starred])

    const onSelectOptionKey = (key) => {
        console.log(key, setDefaultCollectionUid);
        setDefaultCollectionUid(key)
    }
    return (
        <MenuWithSearch
            withAbsolute
            label={t('Select default group')}
            selectedValue={collectionTitle}
            deepOptions={filteredGroups.map(g => ({ key: g.groupUid, title: g.title }))}
            withDeepOptions
            onSelectOptionKey={onSelectOptionKey}
        />
    )
}


const mapStateToProps = (state) => ({
    searchNavigation: state.searchNavigation,
    openCollections: state.globalData.openCollections,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps)
)(PickDefaultGroup);
