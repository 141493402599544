import React, { Component } from 'react'
import withErrorCatcher from '../../Common/withErrorCatcher.js'
import { Grid, withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next';
import { push } from 'react-router-redux';
import { SEARCH_QUERY_UPDATE } from '../../../../actionTypes/actionTypes.js';
import MustReadWarningIcon from '../../Common/Icons/mustRead/MustReadWarningIcon.js';
import { managerACReportView } from '@tuqqi/web';
import { useNewTable } from '../../../../utils/useNewTable.js';
import { selectDrawerNavigationOption } from '../../../../actions/drawerNavigationActions.js';
import { myPageContext } from '../../../../utils/drawerNavigation/myDrawerNavigationHelpers.js';
// import { ACConfig } from '../RightWidgets/Feed/UserPofileWidget/actionCenter/control/gg.js';

const styles = theme => ({
    viewAllButton: {
        textTransform: 'none',
        marginRight: 16,
        height: 28,
        backgroundColor: '#C4423B',
        "&:hover":{
            backgroundColor: "rgba(196, 66, 59, 0.1)"
        },
        color: '#FFFFFF',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.4px',
        lineHeight: '16px',
    },
    alertText: {
        marginLeft: 14,
        color: '#C4423B',
        fontFamily: 'Rubik',
        fontSize: '16px',
        letterSpacing: '0.15px',
        lineHeight: '24px',
    },
    warning: {
    },
    warningContainer: {
        marginLeft: 18,
        marginTop: 3
    },
    container: {
        height: 56,
        width: '100%',
        borderRadius: 8,
        backgroundColor: 'rgba(196, 66, 59, 0.08)',
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center'
    },
    itemBtn: {
        marginRight: '0px',
        marginLeft: 'auto',
    }
})

class MustReadWidget extends Component {

    state = {
        items: []
    }

    componentDidMount() {

        this.props.collaborationService.getMustRead()
            .then(data => {
                this.setState({ items: data })
            })
    }
    componentDidUpdate(prevProps) {
        if(prevProps.queryFiltersItemId === this.props.queryFiltersItemId || !this.props.queryFiltersItemId) return
        this.props.collaborationService.getMustRead()
            .then(data => {
                this.setState({ items: data })
            })
    }
    renderWarning = () => {
        const { classes } = this.props

        return (<div data-intrcm-id="MustReadWidgets_0racq4ip" className={classes.warningContainer}>
            <MustReadWarningIcon className={classes.warning} />
        </div>)
    }
    viewAll = () => {
        // ACConfig.goToMustRead()
        if(!useNewTable(this.props.orgUid)) {
        this.props.dispatch({ type: SEARCH_QUERY_UPDATE, query: { mustread: true} })
        this.props.dispatch(push({ pathname: '/mypage/tasks', search: '?reportId=1' }))
        return
        }

        managerACReportView.setupOtherDefaultView('sign')
        this.props.dispatch(selectDrawerNavigationOption(myPageContext.actionCenter.id))
        this.props.dispatch(push({ pathname: myPageContext.actionCenter.pathname })) 

    }
    render() {
        const { classes, t } = this.props
        const { items } = this.state

        if (!items || !items.length) {
            return null
        }
        return (
            <Grid item className={classes.container} direction="row">
                <Grid item>
                    {this.renderWarning()}
                </Grid>
                <Grid item>
                    <div data-intrcm-id="MustReadWidgets_zj6bimyn" className={classes.alertText}>{t('you_have')} {items.length} {t('items_to_read_confirm')}</div>
                </Grid>
                <Grid item className={`${classes.itemBtn}`}>
                    <Button onClick={this.viewAll} className={classes.viewAllButton}>
                        {t('View all')}
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    collaborationService: state.services.collaborationService,
    queryFiltersItemId: state.queryFilters.itemId,
})

export default compose(withStyles(styles, { withTheme: true }), withTranslation('mustReadWidget'), withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps))(MustReadWidget)