import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher';
import AggregationType from './TableFooter/AggregationType';

const styles = theme => ({
    container: {
        backgroundColor: '#F4F5FB',
        position: 'sticky',
        bottom: 0,
        padding:0,
        "height": "53px",
        paddingLeft: 32,
        cursor:'pointer',
        '&:last-child':{
            paddingRight: 16,
            width: '100%'
        },
    },
    titleText: {
        "fontFamily": "Rubik",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.43",
        "letterSpacing": "0.25px",
        "color": theme.newUI.color.gray
    },
    icon: {
        "color": theme.newUI.color.gray,
        fontSize: 14,
        marginLeft: 4
    }
})

class FooterTableCell extends Component {
    render() {
        const { classes, t, column, isFirst, aggregationType, onAggregationTypeChange } = this.props;
        const { value } = column;
        return (
            <th className={classes.container}>
                <Grid container alignItems='center' item wrap='nowrap'>
                    {isFirst ? (
                        <AggregationType t={t} aggregationType={aggregationType} onAggregationTypeChange={onAggregationTypeChange}  />

                    ) : <span className={classes.titleText} dir='auto'>{value}</span>
                    }
                </Grid>
            </th>
        )
    }

}



export default compose(withStyles(styles),
withErrorCatcher(),
    withTranslation('footerTableCellTranslate'))(FooterTableCell);