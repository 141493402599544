import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { FilePond } from 'react-filepond'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { EDIT_EXTERNAL_INPUT } from '../../../../../actionTypes/actionTypes'
import { EditDescription } from './EditDescription'

const _EditSelectedEI = (props) => {
    const {selected, inputs, groupService, show, onHide, collectionId, dispatch, idToken} = props

    const [t] = useTranslation("newGroupDialog")
    const [selectedTitle, setSelectedTitle] = useState('')
    const [selectedFinishMessage, setSelectedFinishMessage] = useState('')
    const [selectedFileId, setSelectedFileId] = useState('')
    const [selectedDescription, setSelectedDescription] = useState('')
    const [selectedDataMapping, setSelectedDataMapping] = useState(-1)
    const [selectedFieldsMapping, setSelectedFieldsMapping] = useState([])

    useEffect(() => {
        if(!show || !selected.uid) {
            setSelectedFileId("")
            setSelectedDataMapping(-1)
            setSelectedFieldsMapping([])
            setSelectedDescription("")
            setSelectedTitle("")
            setSelectedFinishMessage("")
            return
        }
        setSelectedTitle(selected.title)
        setSelectedFinishMessage(selected.finishMessage)
        setSelectedDescription(selected.description)
        setSelectedFileId("")
        groupService.getExternalInputInfo(selected.uid).then(x => {
            setSelectedFieldsMapping(!!x.fieldsToShow ? x.fieldsToShow.split(';') : [])
            setSelectedDataMapping(x?.type?.id)
        })
    }, [show, selected])

    const handleChange = event => {
        const val = event.target.value
        setSelectedFieldsMapping([])
        setSelectedDataMapping(val)
    };

    const handleChangeMappingFields = event => {
        const value = event.target.value
        if(value.some((v) => JSON.stringify(v) === '[]')) {
            setSelectedFieldsMapping([])
            return
        }
        const fields = event.target.value.filter(v => JSON.stringify(v) !== '[]')
        setSelectedFieldsMapping(fields)
    };

    const showMappingFields = (inputs) => {
        if(!inputs.length || selectedDataMapping === -1) return false
        const mapping = inputs.find(i => i.customDataMapping.id === selectedDataMapping)
        return !!mapping
    }

    const getMappingFields = (inputs) => {
        if(!showMappingFields(inputs)) return null
        const mapping = inputs.find(i => i.customDataMapping.id === selectedDataMapping)
        return mapping.customDataMapping.customFieldsMappings
    }

    const editExternalInput = () => {
        groupService.editExternalInput(selected.uid, collectionId, selectedDataMapping, selectedTitle, DOMPurify.sanitize(selectedDescription), selectedFileId, selectedFieldsMapping,DOMPurify.sanitize(selectedFinishMessage)).then(res => {
            dispatch({
                type: EDIT_EXTERNAL_INPUT,
                input: { type: null, uid: selected.uid, title: selectedTitle, description: selectedDescription,finishMessage: selectedFinishMessage }
            })
            onHide(selected.uid)
            const node = window.document.getElementById("external-inputs-list")
            if(!!node) node.scrollIntoView({behavior: 'smooth'})
        })
    }

    const onLoadImg = (res) => {
        const file = JSON.parse(res);
        setSelectedFileId(file.fileId)
    }

    if(!show) return null
    return <>
        <div data-intrcm-id="EditSelectedEI_z62rlplk">
            <Select
                value={selectedDataMapping}
                onChange={handleChange}
                displayEmpty
                name="dataMapping"
                placeholder={t("Choose mapping")}
            >
                <MenuItem value={-1}>{t("Choose mapping")}</MenuItem>
                    {inputs.length && inputs.map((input) => {
                        return <MenuItem value={input.customDataMapping.id}>{input.title}</MenuItem>
                    })}
            </Select>
        </div>
        {showMappingFields(inputs) && <div data-intrcm-id="EditSelectedEI_qsdeuyny" style={{marginTop: 10}}>
            <Select
                multiple
                displayEmpty
                value={selectedFieldsMapping}
                label={selectedFieldsMapping.length ? t("Choose mapping fields") : `${selectedFieldsMapping.length} ${t("selected")}`}
                onChange={handleChangeMappingFields}
                name="dataMappingFields"
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return t("Choose mapping fields")
                    }
                    return `${selected.length} ${t("selected")}`
                }} >
                    <MenuItem value={[]}>{t("Choose mapping fields")}</MenuItem>
                        {getMappingFields(inputs).map((field) => {
                            return <MenuItem value={field.sourceField}>{field.fieldName} ({field.type}) {field.required ? '*' : ''}</MenuItem>
                    })}
            </Select>
        </div>}
        <TextField className='new-group-title' label={t("Form Title *")} helperText={t("This will be the title of your Form")} id="gr_title_edit" placeholder={t("Enter form title")}
            onChange={(event) => { setSelectedTitle(event.target.value) }} value={selectedTitle} InputLabelProps={{ shrink: true }} margin="normal" fullWidth />
        <EditDescription t={t} value={selectedDescription} onChange={(newValue) => { setSelectedDescription(newValue) }} />
        <br />
        <FilePond
            labelIdle={t("filesUploader:d&dLabel")}
            acceptedFileTypes={['image/*']}
            allowMultiple='false'
            server={
                {
                    url: process.env.API_SERVER + '/files/s3',
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                    process: {
                        headers: {
                            'Authorization': 'Bearer ' + idToken,
                        },
                        onload: onLoadImg
                    }
                }
            }
        >
        </FilePond>
        <EditDescription t={t} isFinishMessage value={selectedFinishMessage} onChange={(newValue) => {setSelectedFinishMessage(newValue) }} />
        <Button variant="contained" disabled={selectedDataMapping === -1} onClick={editExternalInput}>
            {t("Save changes")}
        </Button>
        <br />
        <br />
    </>
}

const mapStateToProps = (state) => ({
    groupService: state.services.groupService,
    collectionId: state.collection.id,
    idToken: state.userData.idToken,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(_EditSelectedEI);