import React, { useEffect } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { LaunchpadLandingPage } from './LaunchpadLanding/LaunchpadLandingPage';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LaunchpadAddItemPage } from './LaunchpadAddItem/LaunchpadAddItem';
import { LaunchpadEditPage } from './LaunchpadEdit/LaunchpadEdit';
import { LaunchpadOrgCategory } from './LaunchpadOrgCategory/LaunchpadOrgCategory';
import { setFeedMenuItems } from '../../../../actions/feedNavigation';
import * as Sentry from '@sentry/browser';
import withErrorCatcher from '../../Common/withErrorCatcher';
import { NewUiWrapper } from '@tuqqi/web';
import { store } from '../../../..';

const styles = (theme) => ({
    container: {
        borderRadius: 12,
        zIndex: '1202!important',
        position: 'absolute',
        width: theme.constants.launchpadWidth,
        maxWidth: theme.constants.launchpadWidth,
        height: 'fit-content',
        right: 16,
        left: 'auto',
        bottom: 0,
        top: -2,
        backgroundColor: '#F3F6FB'
    },
    containerDark: {
        zIndex: '1201!important',
        position: 'fixed',
        width: '100vw',
        height: `calc(100vh - ${theme.constants.defaultToolbarHeight}px)`,
        left: 0,
        right: 0,
        bottom: 0,
        top: theme.constants.defaultToolbarHeight,
        'background-color': 'rgba(0, 0, 0, 0.45)'
    },
    hidden: {
        zIndex: -1,
        visibility: 'hidden',
    }
})
const callbacks = {}
const resolver = (adminService, dispatch) => {
    try {
        adminService.getLaunchpadMenuItems()
            .then((launchpadMenuItems) => {
                dispatch({
                    type: 'SET_LAUNCHPAD_OPTIONS',
                    allowedOptions: launchpadMenuItems
                })
            }).catch((error) => {
                Sentry.captureException(error);
            })
        adminService.getMenuItems()
            .then((menuItems) => {
                dispatch(setFeedMenuItems(menuItems))
            }).catch((error) => {
                Sentry.captureException(error);
            })
    } catch (error) {
        Sentry.captureException(error);
    }
}

const LaunchpadNavigation = (props) => {
    const {isFocused, classes, pageType, isFocusedOrgCategory, dispatch, adminService} = props


    useEffect(() => {
        if(!isFocused) return
        resolver(adminService, dispatch)
    }, [isFocused])


    const renderPage = () => {
        if(isFocusedOrgCategory) return <LaunchpadOrgCategory />
        switch (pageType) {
            case 'landing':
                return <LaunchpadLandingPage />
            case 'add_item':
                return <LaunchpadAddItemPage />
            case 'edit':
                return <LaunchpadEditPage />
            default:
                return null
        }
    }

    const wrapperClassname = [classes.containerDark, !isFocused ? classes.hidden : ''].join(" ");
    return (
        <div data-intrcm-id="LaunchpadNavigation_9ya9d1wn" id="launchpad-navigation-wrapper" className={wrapperClassname}>
            <Paper id="launchpad-navigation-dialog" className={classes.container} >
                <NewUiWrapper store={store} callbacks={callbacks}>
                    {renderPage()}
                </NewUiWrapper>
            </Paper>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      adminService: state.services.adminService,
      isFocused: state.launchpadNavigation.isFocused,
      isFocusedOrgCategory: state.launchpadNavigationOrgMenu.isFocused,
      pageType: state.launchpadNavigation.pageType,
    }  
}
  
const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(
    withErrorCatcher(),
    withStyles(styles, {withTheme: true}),
    withTranslation('search'),
    connect(mapStateToProps, mapDispatchToProps),
)(LaunchpadNavigation)