import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import CategoryViewOnlyLeftDrawer from '../../../components/App/CategoryViewOnlyLeftDrawer';
import { NewUiWrapper, GroupSettings } from '@tuqqi/web';
import { themeConstants } from '../../../../theme';
import { persistor, store } from '../../../../index.js';
import { updateOrganizationData } from '../../../../utils/loadOrganizationData.js';
import { push } from 'react-router-redux';
import { initSubscriptionModal, openSubscriptionModal } from '../../../../actions/upgradeSubscriptionActions.js';

const styles = theme => ({
    settings: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerBigPXWidth,
        width: 'fit-content',
        backgroundColor: '#f4f6fc',
    },
    settings1: {
        marginTop: -11,
        marginLeft: themeConstants.navigationDrawerSmallPXWidth,
        width: 'fit-content',
        backgroundColor: '#f4f6fc',
    },
})

export class GroupSettingsNew extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    onDeleteGroup = (colUid) => {
        const { groupService, dispatch, services, globalData, userData } = this.props;
        if(!colUid) return

        groupService.deleteCollection(colUid).then(() => {
            updateOrganizationData(null, dispatch, services, globalData, userData).then(() => {
                persistor.persist()
            }).catch(err => {

            })
            dispatch(push('/feed'));

        });
    }

    onShowSubscriptionModal = (reason) => {
        if(!reason) return
        this.props.dispatch(initSubscriptionModal({reason, mode: 'existed'}))
        this.props.dispatch(openSubscriptionModal())
    }

    render() {
        const { classes, isDrawerOpen,currentGroup, groupUid } = this.props;

        const callbacks = {
            onDeleteGroup: this.onDeleteGroup,
            onShowSubscriptionModal: this.onShowSubscriptionModal
        }
     
        return <CategoryViewOnlyLeftDrawer >
            <div data-intrcm-id="groupSettingsNew_8quqq254" className={isDrawerOpen ? classes.settings : classes.settings1}>
                <NewUiWrapper store={store} callbacks={callbacks}>
                    { !!currentGroup.isOpen && <GroupSettings drawerWidth={isDrawerOpen ? themeConstants.navigationDrawerBigPXWidth  : themeConstants.navigationDrawerSmallPXWidth} isNewGroup={false} groupUid={groupUid ?? ''} /> }
                </NewUiWrapper>
            </div>
        </CategoryViewOnlyLeftDrawer>
    }
}
const mapStateToProps = (state) => ({
    services: state.services,
    userData: state.userData,
    globalData: state.globalData,
    groupService: state.services.groupService,
    groupUid: state.group?.groupUid,
    currentGroup:  state.group,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    }
}
export default compose(withStyles(styles),
    withTranslation('kanbanTranslate'),
    withErrorCatcher("Failed Loading Settings, Please Contact Tuqqi Support"),
    connect(mapStateToProps, mapDispatchToProps))
(GroupSettingsNew);