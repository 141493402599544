import React, { Component } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { withStyles, Avatar, Typography, Tooltip, Grid, IconButton } from '@material-ui/core';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { TrendingUp } from '@material-ui/icons';
import { withTranslation } from 'react-i18next'

// Apply some reset
const styles = theme => {
    return {
        button: {
            width: 'auto',
            fontSize: theme.typography.body1.fontSize,
            color: theme.typography.display1.color,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        icon: {
            width: '1.1rem',
            height: '1.1rem',
        },
        avatar: {
            width: 25,
            height: 25,
            marginLeft: -5,
        },
        padding: {
            padding: '0px 0px 12px'
        },
        header: {
            'align-items': 'center'
        },
        seenContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
        },
        plus: {
            margin: '0 2px 0 9px'
        }, 
        subTitle: {
            fontFamily: '"Roboto"',
            fontSize: 14,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 0.6,
            textAlign: 'left',
            color: 'rgba(151, 154, 170, 0.8)'
        },
        seen: {
            alignItems: 'center',
            display: 'flex'
        },
        spaceRight: {
            marginRight: 33,
        }
    }
};

const numFacePile = 5

class ItemFullViewSocial extends Component {
    checkIfTrending = () => {
        const { item, classes } = this.props;

        if (item.seen.length > 3 && item.seenCounter > 20) {
            return <TrendingUp className={[classes.subTitle, classes.icon].join(' ')} />
        }
    }

    facePile() {
        const { item, users, classes } = this.props;
        const seen = item.seen.slice(0, numFacePile)

        const avatars = users.map((user, idx) => {
            const fullName = `${user.firstname} ${user.lastname}`
            if (seen.includes(fullName)) {
                return  <Avatar key={idx} alt={fullName} src={user.profileImageUrl} className={classes.avatar} />
            }
        })

        return avatars
    }

    seenTitle() {
        const { item, t } = this.props;

        let seen = <div data-intrcm-id="itemFullViewSocial_yx1m48ye">{t('No views')}</div>


        if (item.seenCounter > 0) {
            seen = <div data-intrcm-id="itemFullViewSocial_2zs0mp14">
                {item.seen.map((user, idx) => {
                    return <div data-intrcm-id="itemFullViewSocial_brv9buuq">
                        {user}
                        <br />
                    </div>
                })}
            </div>
        }

        return seen
    }

    getplural = (text, number) => {
        const { t } = this.props
        const suffix = number == 1 ? '' : 's'
        const res = ` ${t(text + suffix)}`

        return  res
    }

    socialDetails = () => {
        const { item } = this.props;
        const { commentsCounter, seenCounter, relatedDocuments, taskCounter, taskDoneCounter } = item;

        let taskDoneText = taskDoneCounter ? taskDoneCounter : 0;


        return commentsCounter + this.getplural("Comment", commentsCounter)
            + (seenCounter > 0 ? " · " + seenCounter + this.getplural("View", seenCounter) : "")
            + (relatedDocuments && relatedDocuments.length > 0 ? " · " + relatedDocuments.length + this.getplural("Link", relatedDocuments.length) : "")
            + (taskCounter > 0 ? " · " + taskDoneText + "/" + taskCounter + this.getplural("Task", taskCounter) : "")
    }

    render() {
        const { classes, item } = this.props;
        const seen = this.seenTitle()
        const facePile = this.facePile()

        return <div data-intrcm-id="itemFullViewSocial_ycsdl69d">
            <Grid container wrap='nowrap' className={classes.header} >
                <Grid item xs={7} md={5} wrap='nowrap' className={classes.padding}>
                    <div data-intrcm-id="itemFullViewSocial_l80w3anr" className={classes.button} style={{ paddingLeft: 30, flexDirection: 'row', display: 'flex' }}>
                        {this.checkIfTrending()} &nbsp;
                        <Typography dir='auto' variant="caption" className={classes.subTitle} >
                            { this.socialDetails() }
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={3} className={[classes.seenContainer, classes.spaceRight, classes.padding].join(' ')} >
                    <Typography variant="caption" className={classes.seen}>
                        <Tooltip id="tooltip-bottom-end" title={seen} placement="bottom">
                            <IconButton style={{ padding: 0, backgroundColor: 'transparent' }} size="small" aria-label="Share" disableRipple={true} disableFocusRipple={true} focusRipple={false}>
                                {facePile}
                                {
                                    item.seen.length > numFacePile ?
                                        <span className={classes.subTitle}>
                                            <span className={classes.plus}>+</span>
                                            {item.seen.length - numFacePile}
                                        </span> : null
                                }
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    ...state.services,
    ...state.fullView,
    users: state.globalData.users,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
withTranslation('socialData'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ItemFullViewSocial);