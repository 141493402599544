import React, { PureComponent } from 'react';

export default class ReportIcon extends PureComponent {

    render() {
        const { color } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="4" y="5" width="16" height="14" rx="2" stroke={color} stroke-width="1.5"/>
            <path d="M11 9H17" stroke={color}/>
            <path d="M11 12H17" stroke={color}/>
            <path d="M11 15H17" stroke={color}/>
            <circle cx="8" cy="9" r="1" fill={color}/>
            <circle cx="8" cy="12" r="1" fill={color}/>
            <circle cx="8" cy="15" r="1" fill={color}/>
            </svg>
        )
    }
}
export const ReportPXIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.7251 5.25C5.86114 5.25 4.3501 6.76104 4.3501 8.625V9.975V14.025V15.375C4.3501 17.239 5.86114 18.75 7.7251 18.75H16.2751C18.1391 18.75 19.6501 17.239 19.6501 15.375V14.025V9.975V8.625C19.6501 6.76104 18.1391 5.25 16.2751 5.25H7.7251ZM18.3001 9.3V8.625C18.3001 7.50662 17.3935 6.6 16.2751 6.6H7.7251C6.60672 6.6 5.7001 7.50662 5.7001 8.625V9.3H9.67517H18.3001ZM5.7001 10.65V13.35H9.00017V10.65H5.7001ZM5.7001 14.7V15.375C5.7001 16.4934 6.60672 17.4 7.7251 17.4H9.00017V14.7H5.7001ZM10.3502 17.4H16.2751C17.3935 17.4 18.3001 16.4934 18.3001 15.375V14.7H10.3502V17.4ZM18.3001 13.35H10.3502V10.65H18.3001V13.35Z" fill="#14B8A2"/>
    </svg>    
}