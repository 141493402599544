import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withErrorCatcher from '../../../../components/Common/withErrorCatcher.js';
import FileInput from '../../../../components/Input/fileInput.js';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    fileIcon: {
        width: 40,
        height: 40,
        marginRight: 16,
    },

    

    icon: {
        padding: 11,
        cursor: 'pointer',
        marginRight: 16,
        borderRadius: 4,
        "border": `1px ${theme.newUI.color.gray} dashed`,
    },
    text: {
        "fontFamily": "Rubik",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStretch": "normal",
        "fontStyle": "normal",
        "lineHeight": "1.33",
        "letterSpacing": "0.4px",
        color: theme.newUI.color.gray,
    }
});

const AddFileIcon = (props) => {
    const { onClick, className } = props
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="aaprefix__a" d="M16.714 10.286h-6.428v6.428C10.286 17.421 9.707 18 9 18c-.707 0-1.286-.579-1.286-1.286v-6.428H1.286C.579 10.286 0 9.707 0 9c0-.707.579-1.286 1.286-1.286h6.428V1.286C7.714.579 8.293 0 9 0c.707 0 1.286.579 1.286 1.286v6.428h6.428C17.421 7.714 18 8.293 18 9c0 .707-.579 1.286-1.286 1.286z" />
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(3 3)">
                <mask id="aaprefix__b" fill="#fff">
                    <use href="#aaprefix__a" />
                </mask>
                <g fill="#979AAA" mask="url(#aaprefix__b)">
                    <path d="M0 0H24V24H0z" transform="translate(-3 -3)" />
                </g>
            </g>
        </svg>)
}



class AddItem extends React.PureComponent {


    renderAddFile = (onClick) => {
        return <AddFileIcon onClick={onClick} className={this.props.classes.icon} />
    }

    render() {
        const { classes, dataType, t } = this.props;

        return (
            <Grid container item direction='row' className={classes.container} alignItems='center'>
                <FileInput renderInput={this.renderAddFile} />
                <Typography className={classes.text}>{t(`Add new ${dataType} to the group`)}</Typography>
            </Grid>)

    }

}

export default compose(withStyles(styles),
withTranslation('groupWidgetTranslate'),
    withErrorCatcher())(AddItem);