import { SvgIcon } from "@material-ui/core";
import React, { PureComponent } from "react";

export default class HashHeaderIcon extends PureComponent {
  render() {
    return (
      <SvgIcon className={this.props.className} width="96" height="96" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
          <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
                  <stop stop-color="#8645F9" offset="0%"/>
                  <stop stop-color="#BB7BFD" offset="100%"/>
              </linearGradient>
              <circle id="a" cx="48" cy="48" r="48"/>
          </defs>
          <g fill="none" fill-rule="evenodd">
              <g mask="url(#b)" fill="url(#c)" fill-rule="nonzero" stroke="url(#c)" stroke-width="3">
                  <path d="m64.892 53.506-7.126 1.217V38.94l7.48-1.278a.89.89 0 0 0 .74-1.028.909.909 0 0 0-1.053-.722l-7.167 1.225v-7.249a.9.9 0 0 0-.91-.888.9.9 0 0 0-.91.888v7.56l-15.892 2.714v-6.896a.9.9 0 0 0-.91-.889.9.9 0 0 0-.91.889v7.207l-7.44 1.27a.89.89 0 0 0-.739 1.029.904.904 0 0 0 1.053.722l7.126-1.217V58.06l-7.48 1.278a.89.89 0 0 0-.74 1.028.904.904 0 0 0 1.053.722l7.167-1.225v7.249a.9.9 0 0 0 .91.888.9.9 0 0 0 .91-.888v-7.56l15.892-2.714v6.896a.9.9 0 0 0 .91.888.9.9 0 0 0 .91-.888v-7.207l7.44-1.271a.89.89 0 0 0 .739-1.028.909.909 0 0 0-1.053-.722zm-24.838 4.243V41.966l15.892-2.715v15.783l-15.892 2.715z"/>
              </g>
          </g>
      </SvgIcon>
    );
  }
}
