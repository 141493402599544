import { Button, Divider, FormControl, Grid, Icon, ListItemIcon, Paper, TextField, withStyles } from '@material-ui/core'
import React, {useEffect, useMemo, useState} from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withErrorCatcher from '../../../../components/Common/withErrorCatcher'
import Header from '../../../../components/App/PageComponents/pageContentHeader';
import { NewIconSelector } from '../../../../components/Common/NewIconSelector'
import { SelectMenuItemType } from './SelectMenuItemType'
import { ItemGroupType } from './ItemGroupType'
import { ItemDocumentType } from './ItemDocumentType'
import { ItemExternalLinkType } from './ItemExternalLinkType'
import { SelectMenuItemCategory } from './SelectMenuItemCategory'
import {addFeedMenuItem, updateFeedMenuItem} from '../../../../../actions/feedNavigation'
import { push } from 'react-router-redux'
import FormControlLabelSwitch from '../../../admin/customInputTemplates/components/FormControlLabelSwitch'
import { useTranslation } from 'react-i18next'
import { OldPagesWrap } from '../../../admin/newway/OldPagesWrap'
import { managerAdminDrawer } from '@tuqqi/web'

const styles = (theme) => ({
    container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
    formControl: {
        margin: '0 5px',
        display: 'flex'
    },
	paper: {
		padding: 30
	},
	section: {
		padding: 15,
		marginBottom: 5
	},
	divid: {
		marginTop: 20,
		marginBottom: 20,
		backgroundColor: 'rgba(0, 0, 0, 0.1)'
	},
	saveButton: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 15
	},
	buttonIc: {
		marginTop: 8,
		fontSize: 14,
		display: 'block',
		textTransform: 'none'
	},
	editButton: {
		marginLeft: 'auto',
		width: 'fit-content'
	},
})

const getPageHeaderInfo = (isNew, item,t) => ({
        title: isNew ? t('New item') : t('Edit ') + item?.customTitle ?? '',
        subtitle: isNew ? t('Create new item') : t('Edit'),
        avatar: <ListItemIcon><Icon>{item?.customIcon || t('public')}</Icon></ListItemIcon>,
        description: ''
}
)
const getDummyItem = () => ({
    referenceUid: '',
    customTitle: '',
    customIcon: 'public',
    categoryId: 0,
    type: 10,
    order: 0,
    subItems: []
})

const __FeedNavigationSettingsItem = (props) => {
    const { openedItem, isNewOpened, classes, dispatch, adminService } = props
    const [t] = useTranslation('admin')

    const [currentItem, setCurrentItem] = useState(openedItem ?? getDummyItem())

    const isDisabledSave = useMemo(() => {
        if(!currentItem.referenceUid || !currentItem.customTitle?.trim?.() || typeof currentItem.order === 'number' ? currentItem.order < 0 : !currentItem.order?.trim?.()) return true
        return false
    }, [currentItem])

    useEffect(() => {
        managerAdminDrawer.setOption({
            prop: 'navigation',
            uid: 'item'
        })
    }, [])

    const onClickSaveChanges = () => {
        if(isDisabledSave) return

        if(!isNewOpened) {
            adminService.updateMenuItem({
                ...currentItem,
            }).then(res => {
                if(!res) return
                dispatch(updateFeedMenuItem(currentItem))
                dispatch(push('/admin/navigation'))
            })
            return
        }
        adminService.createMenuItem({
            referenceUid: currentItem.referenceUid,
            customTitle: currentItem.customTitle,
            customIcon: currentItem.customIcon,
            categoryId: currentItem.categoryId,
            type: currentItem.type,
            order: currentItem.order,
        }).then(res => {
            dispatch(addFeedMenuItem({
                referenceUid: res.referenceUid,
                customTitle: res.customTitle,
                customIcon: res.customIcon,
                categoryId: res.categoryId,
                type: res.type,
                order: res.order,
            }))
            dispatch(push('/admin/navigation'))
        })
    }

    useEffect(() => {
        if(currentItem.type === 2) {
            setCurrentItem(prev => ({...prev, customIcon: 'collectibles'}))
            return
        }
        setCurrentItem(prev => ({...prev, customIcon: 'public'}))
    }, [currentItem.type])

    const onChangeIcon = (customIcon = 'public') => {
        setCurrentItem(prev => ({...prev, customIcon}))
    }

    const onChangeIsCategory = () => {
        const newVal = !(currentItem.type === 0)
        if(newVal) {
            setCurrentItem((prev) => ({...prev, type: 0, referenceUid: ''}))
            return
        }
        setCurrentItem((prev) => ({...prev, type: 1, referenceUid: ''}))
    }

    const onChangeReferenceUid = (referenceUid) => {
        setCurrentItem(prev => ({...prev, referenceUid}))
    }

    const _onChangeTitle = (customTitle) => {
        setCurrentItem(prev => ({...prev, customTitle}))
    }

    const onChangeTitle = (e) => {
        e.persist()
        _onChangeTitle(e.target.value)
    }

    const onChangeType = (type) => {
        setCurrentItem(prev => ({...prev, type, referenceUid: ''}))
    }

    const onChangeCategoryId = (categoryId) => {
        setCurrentItem(prev => ({...prev, categoryId}))
    }

    const onChangeOrder = (e) => {
        e.persist()
        setCurrentItem(prev => ({...prev, order: e.target.value}))
    }

    const headerInfo = useMemo(() => getPageHeaderInfo(isNewOpened, currentItem,t), [isNewOpened, currentItem])

    const HeaderView =  <Grid container spacing={16} justify="space-between">
        <Grid item md={8}>
            <Header {...headerInfo} classes={classes} />
        </Grid>
        <Grid md={4}>
            <Button disabled={isDisabledSave} variant="raised" onClick={onClickSaveChanges} className={classes.saveButton}>
                {t('Save changes')}
            </Button>
        </Grid>
    </Grid>

    const renderAdditionalByType = () => {
        switch (currentItem.type) {
            case 1:
                return <ItemGroupType onChangeTitle={_onChangeTitle} onChangeIcon={onChangeIcon} onChangeReferenceUid={onChangeReferenceUid} referenceUid={currentItem.referenceUid} />
            case 2:
                return <ItemDocumentType onChangeTitle={_onChangeTitle} onChangeReferenceUid={onChangeReferenceUid} referenceUid={currentItem.referenceUid} />
            case 10:
                return <ItemExternalLinkType onChangeReferenceUid={onChangeReferenceUid} referenceUid={currentItem.referenceUid} />
            default:
                return null
        }
    }

    return <OldPagesWrap className={classes.container} classNameContent={classes.content} >
                <Paper className={classes.paper} elevation={1}>
                    {HeaderView}
                    <Divider className={classes.divid} />
                    <form className={classes.root} autoComplete="off">
                        <FormControlLabelSwitch
                            checked={currentItem.type === 0}
                            onChange={onChangeIsCategory}
                            label={t("Is category")}
                            disabled={!isNewOpened} />
                        {currentItem.type !== 0 && <SelectMenuItemCategory categoryId={currentItem.categoryId} onChange={onChangeCategoryId} />}
                        {currentItem.type !== 0 && <SelectMenuItemType type={currentItem.type} onChange={onChangeType} />}
                        {renderAdditionalByType()}
                        <FormControl className={classes.formControl}>
					        <TextField label={t('Item title')} required error={!!currentItem.customTitle ? false : true} onChange={onChangeTitle} value={currentItem.customTitle} margin='dense' />
				        </FormControl>
                        <div data-intrcm-id="FeedNavigationSettingsItem_1a3x8zt6" style={{margin: 5}}><NewIconSelector disabled={currentItem.type === 1} icon={currentItem.customIcon} onChange={onChangeIcon} /></div>
                        <FormControl className={classes.formControl}>
					        <TextField label={t('Item order')} required error={currentItem.order !== undefined && currentItem.order !== null && currentItem.order !== "" && currentItem.order >= 0 ? false : true} onChange={onChangeOrder} value={typeof currentItem.order === 'number' ? `${currentItem.order}` : currentItem.order} margin='dense' />
				        </FormControl>
                    </form>
                </Paper>
    </OldPagesWrap>
}

const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    isNewOpened: state.feedNavigation.isNewOpened,
    openedItem: state.feedNavigation.openedItem,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export const FeedNavigationSettingsItem = compose(
    withStyles(styles, {withTheme: true}),
    withErrorCatcher(),
    connect(mapStateToProps, mapDispatchToProps),
)(__FeedNavigationSettingsItem)