export const getOptionFromPath = (path = '') => {
    if(path === '/admin') return ''
    if(path.includes('/admin/analytics')) return 'Usage Dashboard'
    if(path.includes('/admin/timetracking')) return 'time-tracking'
    if(path.includes('/admin/archive')) return 'recycle-bin'
    if(path.includes('/admin/logs')) return 'logs'
    if(path.includes('/admin/billing')) return 'billing'
	if(path.includes('/admin/important-content')) return 'important-content'
	if(path.includes('/admin/forms')) return 'forms'
	if(path.includes('/admin/navigation')) return 'menu'
    // if(path.includes('/admin/custominputmappings')) return 'input-settings'
    if(path.includes('/admin/custominputmappings')) return 'forms'
    if(path.includes('/admin/topics_and_usertags')) return 'content-managment'
    if(path.includes('/admin/scoreBoardSettings')) return 'scoreboard'
    if(path.includes('/admin/organizationsettings')) return 'org-preferences'
    if(path.includes('/admin/users')) return 'manage-users'
    if(path.includes('/admin/teams')) return 'teams'
    if(path.includes('/admin/integrations')) return 'integrations'
    return ''
}