import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { allCollectionMembers } from '../../../views/groups/groupUtils';
import UserListItem from '../../Common/userListItem';
import InfinityUsersList from '../../Common/Users/InfinityUsersList';

// Apply some reset
const styles = theme => ({
    checklist: {

    }
});

class MemberRuleTemplate extends Component {
    state = {
        checked: []
    }
    componentDidMount() {
        this.props.data.members ? this.setState({ checked: [...this.props.data.members] }) : this.setState({ checked: [] })
    }
    getOptionalUsersToAdd() {
        const { users, collections, groupUid, profileData } = this.props;

        let optionalUsersToAdd;
        const currCollection = collections.find(c => c.collectionGroup.groupUid === groupUid);

        if (currCollection) {
            optionalUsersToAdd = allCollectionMembers(currCollection)
        } else {
            optionalUsersToAdd = users;
        }

        return optionalUsersToAdd
    }

    getUsersToAdd() {
        const optionalUsersToAdd = this.getOptionalUsersToAdd();

        // const creator = optionalUsersToAdd.find(user => user.auth0_id === this.props.document.userUUID)
        const usersToAdd = []
        // if (creator) {
        //     usersToAdd.push(creator)
        // }
        usersToAdd.push(...optionalUsersToAdd)

        return usersToAdd
    }

    renderUser = (user, onChange) => {
        const { classes } = this.props;

        return (
            <UserListItem
                disabled={false}
                user={user}
                key={user.tag}
                onClick={() => {
                    this.handleToggle(user.auth0_id)
                    onChange()
                }}
                classes={classes}
                checked={this.state.checked.indexOf(user.auth0_id) !== -1} />)


    }

    handleToggle = value => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });

        this.props.updateData({ members: [...newChecked] })
    };

    render() {
        const { classes } = this.props;

        const usersToAdd = this.getUsersToAdd()

        return (
            <div data-intrcm-id="MemberRuleTemplate_kdt81zs8" >
                <InfinityUsersList
                    users={usersToAdd}
                    renderUser={this.renderUser}
                    checked={this.state.checked}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    collections: state.userData.collections,
    profileData: state.userData.profileData,
    inputService: state.services.inputService,
    userSettingsService: state.services.userSettingsService,
    queryService: state.queryService,
    users: state.globalData.users,
    search: state.search,
    groupUid: state.search.groupUid,
    ...state.services
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default compose(withStyles(styles),
    withTranslation('addMembersToItem'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(MemberRuleTemplate);
// export default compose(withStyles(styles))(MemberRuleTemplate);