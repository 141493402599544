import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import NoResultFound from '../../Common/noResultFound.js';

class NoResults extends React.PureComponent {
    render() {
        const { classes, isSearch, isUniqeSearch, t } = this.props;

        return isSearch ? <Grid item>
            {isUniqeSearch ?
                <NoResultFound/>
                // <Typography variant="title" style={{ marginBottom: 15 }}>
                    
                //     {t('No results found')} 

                // </Typography>
                :
                <div data-intrcm-id="NoResults_myg85lbm">
                    <Typography variant="title" style={{ marginBottom: 20 }}>
                       {t('No results found Let others help you…')} 
                    </Typography>                    
                </div>
            }
        </Grid> : null
    }
}

export default  withTranslation('noResults')(NoResults)

