import { showSnackbar } from '../../../../../actions/appActions';
import { updateSpecificItem } from '../../../../../actions/dataActions';

export let loadData = function () {
    const { docId, collaborationService, users, userUid, document } = this.props;
    const { askedUsers } = this.state;

    let readUsers = [...this.state.readUsers];

    collaborationService.getReadTime(docId, document).then(res => {
        readUsers.map(readUser => {
            let log = res.find(r => r.userId == readUser.auth0_id);
            if (log) {
                readUser.dateTime = log.dateTime
            }

        })

        let notAskedUsers = users.filter(user =>
            !readUsers.concat(askedUsers).find(readUser => readUser.auth0_id == user.auth0_id) &&
            user.auth0_id != userUid
        )

        this.setState({ notAskedUsers, loading: false, readUsers })
    })
}

export let handleToggle = function (value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
        newChecked.push(value);
    } else {
        newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
}

export let handleSelectAll = function () {
    const { allSelected, notAskedUsers } = this.state;

    let userIds = allSelected ? [] : notAskedUsers.map(u => u.auth0_id);

    this.setState({ allSelected: !allSelected, checked: userIds })
}

export let handleSave = function () {
    const { inputService, docId, document, dispatch, handleClose, t } = this.props;
    const { checked } = this.state;

    var newDoc = { ...document }

    if (checked) {
        let userStr = checked.length > 1 ? 'users' : 'user';
        let wasStr = checked.length > 1 ? 'were' : 'was';

        newDoc.mustReadUsers = checked.concat(document.mustReadUsers || []);

        dispatch(showSnackbar(t("Asking to read this item", { userStr })));

        inputService.addMustReadUsers(docId, newDoc).then((userData) => {
            dispatch(showSnackbar(userStr.charAt(0).toUpperCase() + userStr.slice(1) + ' ' + wasStr + ' ' + t('asked to read this item!')));

            dispatch({ type: 'USER_DATA_LOAD', data: { ...userData } });
            dispatch(updateSpecificItem(docId));

            handleClose();
        });
    }
}

export let remindAllUsers = function () {
    let usersIds = this.state.askedUsers.map(user => user.auth0_id);
    remindUsers(this.props, usersIds, this).then(() => {
        this.setState({ isNeedUpdate: true })
    });
}

export let remindUser = function (userId) {
    remindUsers(this.props, [userId]).then(() => {
        this.setState({ isNeedUpdate: true })
    });
}

function remindUsers(props, users) {
    const { collaborationService, docId, document, dispatch } = props;
    let userStr = users.length > 1 ? 'users' : 'user';
    let isStr = users.length > 1 ? 'are' : 'is';

    dispatch(showSnackbar("Reminding " + userStr + "..."));

    return collaborationService.remindUsersToReadItem(users, document, docId).then(res => {
        dispatch(showSnackbar(userStr.charAt(0).toUpperCase() + userStr.slice(1) + " " + isStr + " reminded!"));
    })
}