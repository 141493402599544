export default class PollService {
    constructor(token, onUnauthorized) {
        this.token = token
        this.onUnauthorized = onUnauthorized;
        this.backendUrl = process.env.API_SERVER
    }

    getHeader() {
        return {
            'Authorization': 'Bearer ' + this.token
        };
    }

    postHeaders() {
        return new Headers({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
        });
    }

    vote(pollOption, docId) {
        return fetch(process.env.API_SERVER + `/pollOption/vote/${docId}`,
            {
                method: 'PUT',
                body: JSON.stringify(pollOption),
                headers: this.postHeaders()
            }).then(res => res.json());
    }

    addOption(pollReq){
        return fetch(process.env.API_SERVER + `/pollOption/addMyOwn`,
            {
                method: 'POST',
                body: JSON.stringify(pollReq),
                headers: this.postHeaders()
            }).then(res => res.json());
    }

    getPoll(docId) {
        return fetch(process.env.API_SERVER + `/poll/?docId=${docId}`, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + this.token
            }
        }).then(function (res) {
            if(res.status === 401){                                
                // console.log(res);
                this.onUnauthorized();
            }
                        
            return res.json();
        });
    }
}