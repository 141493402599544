import React, { Component, Fragment } from 'react';
import withErrorCatcher from '../withErrorCatcher.js'
import { Motion, spring } from "react-motion";

import { withStyles } from '@material-ui/core';


class SvgButton extends Component {
    style = {
        container: {
            cursor: 'pointer'
        },
        inner: {
            fill: 'none',
            stroke: 'currentColor',
            strokeWidth: '3',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }
    }

    render() {

        return (
            <svg style={this.style.container} height="1.5em" width="1.5em" viewBox="0 0 24 24">
                <g style={this.style.inner}>
                    {this.props.children}
                </g>
            </svg>
        )
    }
}


class TodoToggleButton extends Component {
    render() {
        const totalLength = 72
        const circleLength = 50
        const checkedLength = -24

        const defaultSpring = -totalLength
        const circleSpring = spring(circleLength, { stiffness: 60, damping: 11 })
        const checkedSpring = spring(checkedLength, { stiffness: 120, damping: 13.8 })

        return (
            <SvgButton>
                <Motion
                    defaultStyle={{ offset: defaultSpring }}
                    style={{ offset: this.props.active ? circleSpring : checkedSpring }}
                >
                    {({ offset }) =>
                        <path
                            strokeDasharray={`${totalLength} ${totalLength}`}
                            strokeDashoffset={offset}
                            d="M20 6.7L9.3 17.3 4 12c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8"
                        />
                    }
                </Motion>
            </SvgButton>
        )
    }
}
class TodoRemoveButton extends Component {
    render() {
        return (
            <SvgButton>
                <Motion
                    style={{
                        opacity: this.props.hover ? spring(1) : spring(0),
                        translate: this.props.hover ? spring(0) : spring(1),
                        leftOffset: this.props.hover ? spring(0) : spring(-100),
                        rightOffset: this.props.hover ? spring(0) : spring(100)
                    }}
                >
                    {({ opacity, translate, leftOffset, rightOffset }) =>
                        <g style={{
                            opacity: `${opacity}`,
                            transform: `translateX(${translate}em)`
                        }}
                        >
                            <path d="M4 4l16 16" strokeDasharray="100% 100%" strokeDashoffset={`${leftOffset}%`} />
                            <path d="M20 4L4 20" strokeDasharray="100% 100%" strokeDashoffset={`${rightOffset}%`} />
                        </g>
                    }
                </Motion>
            </SvgButton>
        )
    }
}

export { TodoRemoveButton, TodoToggleButton }