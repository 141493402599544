import React, { Component } from 'react';
import { connect } from 'react-redux';
import MobileWrapper from '../../mobile/components/MobileApp/mobileWrapper';
import Spinner from '../Common/spinner/spinner';
import mixpanel from 'mixpanel-browser'
import { setMixpanel } from '@tuqqi/web'
import { loadOrganizationData, updateOrganizationData } from '../../../utils/loadOrganizationData';
import { persistor } from '../../..';
import WrapperOutter from './WrapperOutter';

function isClientMobile() {
    let mql = window.matchMedia('(max-width: 750px)');
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches) {
        return true;
    }
    return false;
}


class Organization extends Component {


    constructor(props, context) {
        super(props, context);
        var channel = this.props.location.query.searchquery;
        this.state = {
            isLoading: false,
            searchQuery: this.props.location.query.searchquery ? this.props.location.query.searchquery : channel,
            channel: channel,
            drawerOpen: false,
            query: "",
            user: null,
            initAnalyzers: true,
        };
    };


    componentDidUpdate(prevProps, prevState) {
        const userLoggedOut = !this.props.userData.userDataLoaded && prevProps.userData.userDataLoaded
        if (userLoggedOut) {
            this.loadAllData(this.props.authService, this.props.dispatch);
        }
        if (this.props.userData && this.props.userData.userDataLoaded && this.state.initAnalyzers) {

            this.setState({ initAnalyzers: false })
            // Check if it's prod            
            if (window.location.hostname === 'app.tuqqi.com' || window.location.hostname === 'desktop.tuqqi.com') {
                mixpanel.identify(this.props.userData.profileData.email);
                mixpanel.add_group('company', this.props.userData.profileData.org.orgUid);
                mixpanel.add_group('subscription', this.props.globalData && this.props.globalData.currentSubscription ? this.props.globalData.currentSubscription.subscriptionType : 0)
                mixpanel.people.set({ "Name": this.props.userData.profileData.firstname + ' ' + this.props.userData.profileData.lastname, });
                mixpanel.track("Web - User logged in", { action: 'login' })
                setMixpanel(mixpanel);
            }

            window.intercomSettings = {
                app_id: "z5hl4hx5",
                name: this.props.userData.profileData.firstname + ' ' + this.props.userData.profileData.lastname,
                email: this.props.userData.profileData.email, // Email address
                "role": this.props.userData.profileData.role,
                "tag": this.props.userData.profileData.tag,
                "lang": this.props.userData.profileData.lang,
                company: {
                    id: this.props.userData.profileData.org.orgUid,
                    name: this.props.userData.profileData.org.title,
                    created_at: this.props.userData.profileData.org.creationDateTime,
                    // (optional): Insert name of the plan current company is on
                    plan: this.props.subscription.subscriptionType,
                    "TemplateName": this.props.userData.profileData.org.TemplateName,
                    subscriptionState: this.props.subscription.subscriptionState,
                    // (optional): Insert amount current company spends a month
                    monthly_spend: 10,
                    // (optional): Add any custom attributes, e.g., 
                    upgraded_at: 1424941688
                },
                created_at: "1312182000" // Signup date as a Unix timestamp
            };
            window.Intercom('boot', {
                app_id: 'z5hl4hx5',
                email: this.props.userData.profileData.email, // Email address
                name: this.props.userData.profileData.firstname + ' ' + this.props.userData.profileData.lastname,
                created_at: new Date(),
                user_id: this.props.userData.profileData.auth0_id
            });

            window.Alvis("identify", this.props.userData.profileData.auth0_id);


            document.title = 'Tuqqi - ' + this.props.userData.profileData.org.title

            

        }
    }

    componentDidMount() {

        const { userData } = this.props;
        const { userDataLoaded, profileData } = userData;
        const firstLogin = !profileData

        if (!userDataLoaded || firstLogin) {
            this.loadAllData();
        } else {
            this.updateAllData()
        }
    }

    getStaticUsers = () => {
        return [
            {
                auth0_id: "tuqqirobot",
                creationDateTime: "2017-01-30T04:17:42",
                email: "i@tuqqi.com",
                firstname: "Robot",
                id: 1,
                lastModification: "2017-01-30T04:17:42",
                lastVisit: "2017-12-10T03:06:16",
                lastname: "Tuqqi",
                org: {},
                pinnedTags: [],
                profileImageUrl: "./tuqqi_small_logo_white_margin.png",
                queryViews: [],
                role: null,
                subscribedTags: [],
                tag: null,
                title: "The Robot",
            }
        ]
    }

    loadAllData = () => {
        const { userData, dispatch } = this.props;
        loadOrganizationData(userData.idToken, dispatch).then(() => {
            persistor.persist()
        }).catch(err => {            
        })
    }
    updateAllData = () => {
        const { dispatch, authService, services, globalData, userData } = this.props;
        setTimeout(() => {
            updateOrganizationData(authService, dispatch, services, globalData, userData).then(() => {
                persistor.persist()
            }).catch(err => {                
            })
        }, 1000)
    }


    render() {
        const { userData, children, routerLocation } = this.props;

        let searchQuery = '';

        var pageBody = <Spinner />
        if (userData.userDataLoaded) {
            if (isClientMobile()) {
                pageBody = <MobileWrapper location={routerLocation}>
                    {children}
                </MobileWrapper>
            } else {
                pageBody = <WrapperOutter routerLocation={routerLocation} >
                    {children}
                </WrapperOutter>
            }

        }

        return pageBody
    }
}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    authService: state.authModule.authService,
    subscription: state.globalData.currentSubscription,
    globalData: state.globalData,
    routerLocation: ownProps.location,
    services: state.services,
    router: state.router
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Organization);
