import React, { PureComponent } from "react";
import { withStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import { connect } from "react-redux";
import { FULLVIEW_CLOSE } from "../../../actionTypes/actionTypes";
import { withTranslation } from "react-i18next";
import { changeCollectionInputInfo } from "../../../actions/inputActions.js";
import { canUserAddItemToGroup } from "../Common/Utils/userPermissionsUtils.js";
import {
  getGroupDocumentPermission,
  getPublicDocumentPermission,
} from "@tuqqi/common";
import { openInput as openNewUiInput } from "@tuqqi/web";
import withErrorCatcher from "../Common/withErrorCatcher.js";

const styles = (theme) => ({
  container: {
    margin: "16px 0px 6px",
    width: "100%",
    height: "197px",
    borderRadius: "4px",
    border: `solid 1px  #B6B9CA`,
    backgroundColor: "#ffffff",
    display: "block",

    "&:hover": {
      backgroundColor: "rgba(33, 33, 45, 0.04)",
    },
  },
  addTextPlus: {
    fontSize: "70px",
    lineHeight: 1,
    fontWeight: 500,
    color: "#979AAA",
    display: "block",
  },
  addText: {
    fontSize: "22px",
    fontWeight: 500,
    paddingLeft: "10px",
    textTransform: "capitalize",
    color: "#979AAA",
  },
});

class CardAdder extends PureComponent {
  addCard = () => {
    const { dispatch, groupUid, list, changeCollectionInputInfo } = this.props;

    const kanbanListId = list.id === -1 ? "" : list.id + '';

    dispatch({ type: FULLVIEW_CLOSE });

    const permissions = groupUid
      ? getGroupDocumentPermission(groupUid)
      : getPublicDocumentPermission();
    let newDoc = {
      html: "",
      title: "",
      permissions,
      dataType: "htmlwysiwyg",
      kanbanListId: kanbanListId,
    };
    dispatch(openNewUiInput(newDoc, "htmlwysiwyg"));

    changeCollectionInputInfo({ kanbanListId });
  };

  hasPermissionToAdd = (groupUid) => {
    const { collectionUid, userData } = this.props;

    return canUserAddItemToGroup(
      userData.profileData,
      userData.collections,
      collectionUid
    );
  };

  render() {
    const { classes, groupUid,t } = this.props;
    if (!groupUid || !this.hasPermissionToAdd(groupUid)) {
      return null;
    }

    return (
      <Button className={classes.container} onClick={this.addCard}>
        <Typography className={classes.addTextPlus}>
          <AddIcon style={{ color: "#b9b9bd", fontSize: "70px" }} />
        </Typography>
        <Typography className={classes.addText}>{t('Add New Article')}</Typography>
      </Button>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionUid: state.results.channel.collectionUid,
  groupUid:
    state.results.channel.collectionGroup &&
    state.results.channel.collectionGroup.groupUid,
  groupInfo: state.results.channel,
  groups: state.userData.groups,
  inputMappings: state.globalData.inputMappings,
  oldUiInput: state.oldUiInput.document,
  userData: state.userData,
  searchQuery: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  changeCollectionInputInfo: (collectionInputInfo) =>
    dispatch(changeCollectionInputInfo(collectionInputInfo)),
});

export default compose(
  withStyles(styles),
  withTranslation("knowledgeTranslate"),
  withErrorCatcher("Failed Loading, Please Contact Tuqqi Support"),
  connect(mapStateToProps, mapDispatchToProps)
)(CardAdder);
