import { CLOSE_GROUPS_NAVIGATION, OPEN_GROUPS_NAVIGATION, SET_MANAGED_GROUPS, SET_SUBSCRIBED_GROUPS, PUSH_MANAGED_GROUPS, PUSH_SUBSCRIBED_GROUPS, UPDATE_MANAGED_GROUPS_BY_IDS, UPDATE_SUBSCRIBED_GROUPS_BY_IDS, UPDATE_LATEST_GROUP_TIMESTAMP } from "../actionTypes/groupsNavigationTypes";

export const setManagedGroups = (managed) => ({ 
    type: SET_MANAGED_GROUPS, 
    managed,
})

export const setSubscribedGroups = (subscribed) => ({ 
    type: SET_SUBSCRIBED_GROUPS, 
    subscribed,
})
export const pushManagedGroups = (managed) => ({ 
    type: PUSH_MANAGED_GROUPS, 
    managed,
})
export const updateManagedGroupsByIds = (managed) => ({ 
    type: UPDATE_MANAGED_GROUPS_BY_IDS, 
    managed,
})
export const updateSubscribedGroupsByIds = (subscribed) => ({ 
    type: UPDATE_SUBSCRIBED_GROUPS_BY_IDS, 
    subscribed,
})

export const pushSubscribedGroups = (subscribed) => ({ 
    type: PUSH_SUBSCRIBED_GROUPS, 
    subscribed,
})
export const openGroupsNavigation = () => ({ 
    type: OPEN_GROUPS_NAVIGATION,
})
export const closeGroupsNavigation = () => ({ 
    type: CLOSE_GROUPS_NAVIGATION,
})

export const updateLatestGroupTimestamp = (groupUid) => ({ 
    type: UPDATE_LATEST_GROUP_TIMESTAMP, 
    groupUid,
})
