import React from 'react';
import { Divider, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import GeneralButton from '../../../../Common/AllButton/AllButton';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        height: 60,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    suggest: {
        display: 'none',
        position: 'absolute',
        backgroundColor: 'transparent',
        opacity: 0.23,
        borderRadius: 6,
        width: theme.constants.searchInputWidth - 40 + 10,
        height: 60 + 20 - 1,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    avatar: {
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: 60,
        height: 60,
        zIndex: 2
    },
    name: {
        height: 18,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.88,
        letterSpacing: 'normal',
        textAlign: 'start',
        color: '#21212d',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#21212d'
        },
        maxWidth: 400,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        marginLeft: 16,
        zIndex: 2
    },
    subsribeButtomContainer: {
        width: 112,
        height: 32,
        backgroundColor: 'transparent',
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: theme.customColors.primary.main,
        '&:hover': {
            background: theme.customColors.primary.main,
            color: '#fff',
        },
    },
    subsribeButtomText: {
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 1.23,
        letterSpacing: 0.5,
    },
    unsubsribeButtomContainer: {
        width: 112,
        height: 32,
        backgroundColor: theme.customColors.primary.main,
        border: `solid 1px ${theme.customColors.primary.main}`,
        color: '#fff',
        '&:hover': {
            background: 'transparent',
            color: theme.customColors.primary.main,
        },
    },
    subscribe: {
        zIndex: 2,
        marginRight: 0,
        marginLeft: 'auto'
    },
    divider: {
        marginTop: 8,
        marginBottom: 8,
        color: '#dddee6',
        '&:last-child': {
            marginTop: 0,
            display: 'none',
            marginBottom: 0
        },
    }
})

function User(props) {
    const {user, classes, openUser, subscribe, unsubscribe, isSubscribed, withKeysNavigation, t} = props

    const onClickGeneral = () => {
        if(isSubscribed) {
            unsubscribe(user.tag)
            return
        }
        subscribe(user.auth0_id)
    }

    const keysNavigationProps = !withKeysNavigation ? {} : {
        suggestCase: JSON.stringify({tag: user.tag, suggestType: "user"}),
        suggest: "suggest-find"
    }

    const renderSubscribeButton = () => {
        const title = isSubscribed ? t('UNSUBSCRIBE') : t('SUBSCRIBE')
        const containerStyle = isSubscribed ? classes.unsubsribeButtomContainer : classes.subsribeButtomContainer

        return <GeneralButton onClick={onClickGeneral} containerStyle={containerStyle} textStyle={classes.subsribeButtomText} title={title} />
    }

    return (
        <>
            <div data-intrcm-id="User_m8gnr6la" className={classes.container} >
                <img alt="user" className={classes.avatar} src={user.avatar} />
                <div data-intrcm-id="User_hqp0piaq" onClick={(e) => openUser(e, user.tag)} className={classes.name}>{user.firstname} {user.lastname}</div>
                <div data-intrcm-id="User_ucu52p3m" className={classes.subscribe}>{renderSubscribeButton()}</div>
                <div data-intrcm-id="User_kkocppzk" {...keysNavigationProps} className={classes.suggest} />
            </div>
            <Divider className={classes.divider} />
        </>
    )
}

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation('groupDrawerTranslate'),
)(User)

