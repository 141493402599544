import { useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
// material
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import React from 'react'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import parseImageUrl from '../../../ui/components/Common/parseImagUrl';
import { hexToRGB } from '../groups/Calendar/event-utils';

const styles = theme => ({
    title: {
        color: '#fff',
    },
    root: {
        backdropFilter: 'blur(3px)',
        backgroundColor: hexToRGB('#161C24', 0.3),
        // opacity: 0.88,
  
        '& .ril__image': {
          backgroundColor: '#fff',
          borderRadius: 24
        },
          // Toolbar
        '& .ril__toolbar': {
          height: 'auto !important',
          padding: (theme.spacing.unit * 2, theme.spacing.unit * 3),
          backgroundColor: 'transparent'
        },
        '& .ril__toolbarLeftSide': { display: 'none' },
        '& .ril__toolbarRightSide': {
          height: 'auto !important',
          padding: 0,
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          '& li': {
            display: 'flex',
            alignItems: 'center'
          },
          '& li:first-child': {
            flexGrow: 1
          },
          '& li:not(:first-child)': {
            width: theme.spacing.unit * 5,
            height: theme.spacing.unit * 5,
            justifyContent: 'center',
            marginLeft: theme.spacing.unit * 1,
            borderRadius: theme.shape.borderRadius
          }
        },
  
        // Button
        '& button:focus': { outline: 'none' },
        '& .ril__toolbarRightSide button': {
          width: '100%',
          height: '100%',
        },
        '& .ril__navButtons': {
          padding: theme.spacing.unit * 3,
          borderRadius: 10,
          '&.ril__navButtonPrev': {
            left: theme.spacing.unit * 2,
            right: 'auto',
          },
          '&.ril__navButtonNext': {
            right: theme.spacing.unit * 2,
            left: 'auto',
          }
        }
      }
  });


function LightboxModal({
  images,
  photoIndex,
  setPhotoIndex,
  isOpen,
  onClose,
  classes,
  cloudfront,
  ...other
}) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const showIndex = (
    <Typography className={classes.title} variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>
  );

  const toolbarButtons = [showIndex];
  const customStyles = {
    overlay: {
      zIndex: 9999
    }
  };
  const rightImages = images.map(uid => parseImageUrl(cloudfront, uid, 2000))
  return (
    <React.Fragment>
      {isOpen && (
        <Lightbox
          mainSrc={rightImages[photoIndex]}
          nextSrc={rightImages[(photoIndex + 1) % rightImages.length]}
          prevSrc={rightImages[(photoIndex + rightImages.length - 1) % rightImages.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + rightImages.length - 1) % rightImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % rightImages.length)}
          toolbarButtons={toolbarButtons}
          reactModalStyle={customStyles}
          wrapperClassName={classes.root}
          {...other}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = dispatch => ({
        dispatch
})

export default compose(withStyles(styles, {withTheme: true}),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LightboxModal);