import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthService from './AuthService';
import { push } from 'react-router-redux';
// import Spinner from '../../../components/spinner/spinner';

class Callback extends Component {

    constructor(props, context) {
        super(props, context);
        this.auth = new AuthService();
    }

    // componentWillMount() {
    //     this.authenticate();
    // }

    // componentWillReceiveProps(nextProps) {
    //     this.authenticate();
    // }

    authenticate = () => {
        // if (!this.props.isAuthenticated) {
        //     let redirectAfterLogin = this.props.location.pathname;
        //     this.props.dispatch(pushState(null, `/login?next=${redirectAfterLogin}`));
        // }
        
        this.auth.handleAuthentication((authResult)=>{
            this.props.dispatch({ type: 'USER_LOGIN', authResult: authResult });            
        });
        



    }


    render() {

        // alert(this.props.userData.userDataLoaded)
        this.authenticate();

        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
        }

        return (
            <div style={style}>
                {/* <Spinner /> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    router: state.router
})


const mapDispatchToProps = dispatch => {
    return {


        dispatch: dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Callback);


// export default Callback;