import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import GroupIcon from '@material-ui/icons/Group'
import WidgetSingleImgOption from '../../../Common/WidgetOptions/WidgetSingleImgOption';
import { applySearchQuery } from '../../../../views/search/searchUtils';
import parseImagUrl from '../../../Common/parseImagUrl'
import { withMixpanel } from '../../../../components/Common/withMixpanel';
import groupPlaceholder from "../../../../../assets/img/group_placeholder.png";

const styles = theme => ({
    container: {
        backgroundColor: '#f3f6fb',
        width: 320,
        borderRadius: 8,
        marginBottom:16,
        wordBreak:'break-word'
    },
    containerInner: {
        margin: 18
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
        color: '#21212d',
    },
    titleIcon: {
        color: '#21212d',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 17,
        marginRight: 10
    },
    options: {
        marginTop: 18,
        gridTemplateColumns: 'auto auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginLeft: 8,
        marginBottom: 8,
    }
});

const MAX_GROUPS = 4

const groupsToOptions = (groups = [], cloudfront) => groups.map(g => ({
    selected: false,
    key: g.groupUid,
    label: g.title,
    icon: parseImagUrl(cloudfront, g.backgroundUrl, 70, groupPlaceholder) ?? groupPlaceholder,
    isImage: true
}))
const RelatedGroupsWidget = (props) => {
    const { classes, t, searchQuery, dispatch, searchNavigation, cloudfront, mixpanelTrack } = props;
    const { starredGroups } = searchNavigation

    const [options, setOptions] = useState([])

    const onOptionClick = (key) => {
        mixpanelTrack(`Related groups widget`, {})
        const newOptions = options.map(o => {
            if(o.key === key) {
                o.selected = !o.selected
                return o
            }
            o.selected = false
            return o
        })
        setOptions(newOptions)
        const selected = newOptions.filter(o => o.selected).map(o => o.key)
        const newQuery = {...searchQuery, groups: selected}
        applySearchQuery(newQuery, dispatch)
    }

    useEffect(() => {
        setOptions(groupsToOptions(starredGroups, cloudfront))
    }, [starredGroups, cloudfront])

    return (
        <Grid container direction='column' className={classes.container}>
            <div data-intrcm-id="RelatedGroupsWidget_tftcfvhk" className={classes.containerInner}>
                <div data-intrcm-id="RelatedGroupsWidget_zmo5c0a1" className={classes.titleSection}>
                        <GroupIcon className={classes.titleIcon} />
                        <div data-intrcm-id="RelatedGroupsWidget_s3r63exf" className={classes.title}>{t("Groups")}</div>
                </div>
                <div data-intrcm-id="RelatedGroupsWidget_88j2n9dh" className={classes.options}>
                    {options.map(o => (
                        <WidgetSingleImgOption optionStyle={classes.option} key={o.key} option={o} onClick={onOptionClick} />
                    ))}
                </div>
           </div>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    searchQuery: state.search,
    searchNavigation: state.searchNavigation,
    cloudfront: state.globalData.cloudfront,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
})

export default compose(
    withStyles(styles),
    withMixpanel,
    withTranslation('searchPage'),
    connect(mapStateToProps, mapDispatchToProps),
)(RelatedGroupsWidget);