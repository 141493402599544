import React from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { push } from 'react-router-redux';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles, Icon, ListItemIcon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppContent from '@components/App/appContent';
import Paper from '@material-ui/core/Paper';
import PageContentWrapper from '@components/App/PageComponents/pageContentWrapper';
import Typography from '@material-ui/core/Typography';
import AdminSettingsDrawer from '../../../components/App/Drawers/adminSettingsDrawer';
import { MAPPING_EDIT_CERTIFICATION, DELETE_CERTIFICATION } from '../../../../actionTypes/actionTypes.js';
import { showSnackbar, showConfirm } from '../../../../actions/appActions.js';
import { editCertification, deleteCertification } from '../../../../actions/customMappingAction.js';
import AdminSettingsTitle from '../Common/AdminSettingsTitle.js';
import { OldPagesWrap } from '../newway/OldPagesWrap.js';

const styles = theme => ({
    content: {
        marginBottom: 16,
    },
    paperStyle: {
        marginBottom: 40
    },
    button: {
        margin: 16
    }
})

export class Integrations extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            integrations: [],
            loaded: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { deleteLaneid, dispatch, adminService } = this.props;
        const prevDeleteLaneid = nextProps.deleteLaneid;

        // if (nextProps.deleteCertificationId && this.props.deleteCertificationId !== nextProps.deleteCertificationId) {
        //     dispatch(showSnackbar('Deleting...'));
        //     dispatch( deleteCertification(''))
        //     adminService.deleteCertification(nextProps.deleteCertificationId).then(res => {
        //         if (res.ok) {
        //             const newCertification = this.state.certifications.filter(c => c.id != nextProps.deleteCertificationId)
        //             this.setState({ integrations: newCertification })
        //             dispatch(showSnackbar('Delete'));
        //         } else {
        //             dispatch(showSnackbar('Error occured while deleting the certification!'));
        //         }
        //     })
        // }
    }

    componentDidMount() {
        const { adminService } = this.props
        adminService.getAllIntegrations().then(integrations => this.setState({ integrations, loaded: true }))
    }

    createNew = () => {
        const { dispatch } = this.props;

        // Goto editing page
        dispatch(push('/admin/integrations/create'))
    }

    edit = (certification) => () => {
        const { dispatch, goTo } = this.props;

        const { icon, title, description, color, id, uid } = certification
        
        // Set mapping as currently editted
        dispatch(editCertification( { icon, title, description, color, id, uid }))
        // Goto editing page
        dispatch(push('/admin/integrations/edit'))
    }

    onDeleteCertification = (deleteCertificationId) => (event) => {
        const { dispatch } = this.props

        dispatch(showConfirm(("Are you sure you want to delete this? this action can't be undone"), deleteCertification(deleteCertificationId)))
        event.stopPropagation()
    }
    render() {
        const { classes } = this.props;
        const { integrations, loaded } = this.state;


        if (!loaded) {
            return null
        }

        return <OldPagesWrap classNameContent={classes.content} >
                <AdminSettingsTitle text='Manage integrations' />
                    <Paper className={classes.paperStyle}>
                        <List>
                            {
                                integrations.map((integration, idx) => (
                                    <ListItem
                                        key={idx}
                                        button
                                        onClick={this.edit(integration)} >
                                        <Icon>{integration.icon}</Icon>
                                        <ListItemText
                                            primary={integration.title}
                                            secondary={integration.uid} />
                                        {/* <ListItemIcon >
                                            <Icon onClick={this.onDeleteCertification(certification.id)}>clear</Icon>
                                        </ListItemIcon> */}
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Button
                            className={classes.button}
                            variant="raised"
                            onClick={this.createNew}>
                            New integration
                        </Button>
                    </Paper>
        </OldPagesWrap>
    }
}
const mapStateToProps = (state) => ({
    adminService: state.services.adminService,
    deleteCertificationId: state.confirmDialog.deleteCertificationId,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

export default compose(withStyles(styles),
    withErrorCatcher(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(Integrations);