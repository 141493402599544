import { selectDrawerNavigationOption } from "../../actions/drawerNavigationActions"
import { goToPath } from "./navigationHelpers"
import { navOptions } from "./navigationHelpers"
import {mixpanelTrack} from '../../ui/components/Common/Utils/mixpanelUtils'
import groupMapView from "../../ui/views/groups/groupMapView"

export const groupCategoryContext = {
    mode: navOptions.GROUP_CATEGORY,
    home: {
        id: 0,
        pathname: '/group/knowledge'
    }
}

export const getKnowledgeNavigationOpts = (t, dispatch,additionalOptions) => [
    {
        id: groupCategoryContext.home.id,
        icon: 'Knowledge',
        title: t("Knowledge"),
        onClick: () => {
            mixpanelTrack(`Group knowledge view`, {})
            dispatch(selectDrawerNavigationOption(groupCategoryContext.home.id))
            goToPath(groupCategoryContext.home.pathname, dispatch) 
        }
    }
].concat(additionalOptions)

export const getGroupCategoryDrawerFromPath = (pathname = '') => {
    const mode = groupCategoryContext.mode

    if(pathname.includes(groupCategoryContext.home.pathname)) return [mode, groupCategoryContext.home.id]
    return [mode, groupCategoryContext.home.id]
}