import { withStyles, Divider } from '@material-ui/core';
import React from 'react';
import CreateCustomInputEnableSettings from './CreateCustomInputEnableSettings';
import CreateCustomInputSettings from './CreateCustomInputSettings';
import CreateCustomInputWorkflow from './CreateCustomInputWorkflow';

const styles = theme => ({
    divid: {
		marginTop: 20,
		marginBottom: 20,
		backgroundColor: 'rgba(0, 0, 0, 0.1)'
	},
})

class CreateCustomInputAllSettings extends React.Component {
    render() {
        const { classes, handleChange, onLoadImg, changeEnable, changeAboveWorkflow, changeWorkflow,
            title, dataTypeFilter, description, icon, backgroundColor, enableSettings, quickActionText, triggeredCustomInput, isBusinessIdFieldEnabled, defaultCollectionUid, specificGroupsUids, setDefaultCollectionUid, setSpecificGroupsUids
        } = this.props;

		return <div data-intrcm-id="CreateCustomInputAllSettings_g9oxh4ni">
			<CreateCustomInputSettings
				setDefaultCollectionUid={setDefaultCollectionUid}
				defaultCollectionUid={defaultCollectionUid}
				specificGroupsUids={specificGroupsUids}
				setSpecificGroupsUids={setSpecificGroupsUids}
				handleChange={handleChange}
				title={title}
				dataTypeFilter={dataTypeFilter}
				description={description}
				icon={icon}
				backgroundUrl={backgroundColor}
				onLoadImg={onLoadImg}
			/>

			<Divider className={classes.divid} />

			<CreateCustomInputEnableSettings
				isBusinessIdFieldEnabled={isBusinessIdFieldEnabled}
				enableSettings={enableSettings}
				changeEnable={changeEnable} />

			<Divider className={classes.divid} />

			<CreateCustomInputWorkflow
				handleChange={handleChange}
				enableSettings={enableSettings}
				changeAboveWorkflow={changeAboveWorkflow}
				changeWorkflow={changeWorkflow}
				quickActionText={quickActionText}
				triggeredCustomInput={triggeredCustomInput}
			/>
		</div>
    }
}

export default withStyles(styles)(CreateCustomInputAllSettings);