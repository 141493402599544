import React from 'react';
import { Typography } from '@material-ui/core';
import { UptodateIcon } from './uptodateIcon'
import SuggestedGroupsSection from './suggestedGroup/SuggestedGroupsSection';

export const RenderUpToDateNew = (props) => {
    const { results, renderItem } = props;

    return <div data-intrcm-id="RenderUpToDateNew_ch6a7o50">
        {results.map((result, idx) => renderItem(idx, result, result.Key))}
        <div data-intrcm-id="RenderUpToDateNew_mfu3i3ms" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <UptodateIcon style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 24,
                marginBottom: 24,
            }} />
            <Typography style={{
                textAlign: 'center',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 'bold',
            }}>
                You're All Caught Up
            </Typography>
            <Typography style={{
                textAlign: 'center',
                color: '#979aaa',
                fontSize: 11,
                marginTop: 0,
                marginBottom: 16
            }}>
                You've seen all new posts from the past 3 days
            </Typography>

        </div>
        <SuggestedGroupsSection />
        <div>
            <Typography style={{
                textAlign: 'center',
                color: '#979aaa',
                fontSize: 11,
                marginTop: 0,
                marginBottom: 16
            }}>
                Previous Updates
            </Typography>

        </div>
    </div >
}