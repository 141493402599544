import { Icon, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { Component } from 'react';
import { compose } from 'recompose';



const styles = theme => ({

    listContainer: {
        overflow:'auto',
        // overflow:'hidden',
        position: 'relative'
    },
    side: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        "display": "flex",
        "opacity": "0",
        "position": "absolute",
        "WebkitTransition": "opacity 300ms ease-in-out,visibility 0s 2s",
        "transition": "opacity 300ms ease-in-out,visibility 0s 2s",
        "visibility": "hidden",
        "width": "60px",
        "zIndex": "1000",
        "WebkitBoxAlign": "center",
        "boxAlign": "center",
        "WebkitAlignItems": "center",
        "alignItems": "center",
        "pointerEvents": "none",
    },
    visible: {
        opacity: 1,
        '-webkit-transition-delay': '0s',
        'transition-delay': '0s',
        'visibility': 'visible',
        "pointerEvents": "auto",
    },
    rightSide: {
        background: 'linear-gradient(to left,#f7f7f7,rgba(255,255,255,0))',
        right: 0,
        justifyContent: 'flex-end'
    },
    leftSide: {
        background: 'linear-gradient(to right,#f7f7f7, rgba(255,255,255,0))',
        left: 0,
        justifyContent: 'flex-start'
    },
    arrowContainer: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',


        "WebkitBorderRadius": "50%",


        "WebkitBoxShadow": "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)",
        "boxShadow": "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)",

        "cursor": "pointer",
        "fill": "#fff",
        "position": "relative",
        "textAlign": "center",
        "zIndex": "4000"
    },
    cardsContainer: {
        height: '100%',
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        flexWrap: 'nowrap',
        // '-webkit-overflow-scrolling':'touch',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },

})



class DiscoveryList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            reachRightEnd: false,
            reachLeftEnd: false,
        }
        this.listRef = React.createRef();

        this.isIPad = navigator.userAgent.match(/iPad/i) != null;
    }

    hideRightArrow() {
        const { scrollWidth, offsetWidth, scrollLeft } = this.listContainer

        const reachEnd = scrollWidth - (offsetWidth + scrollLeft) < 10

        return reachEnd
    }

    hideLeftArrow() {
        const reachEnd = this.listContainer.scrollLeft < 10

        return reachEnd
    }

    intervId = 0;
    scrollToRight = (e) => {
        this.scrollTo(1)
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    componentWillUnmount() {
        clearInterval(this.intervId)
    }
    scrollTo(factor) {
        let scrollWidth = (!this.props.infinity && this.listContainer && this.listContainer.firstElementChild && this.listContainer.firstElementChild.offsetWidth) || 210
        clearInterval(this.intervId)

        this.intervId = setInterval(() => {
            if (scrollWidth > 0) {
                this.listContainer.scrollBy(factor * 3, 0)
                scrollWidth -= 3
            } else {
                clearInterval(this.intervId)
            }
        }, 1)
    }
    scrollToLeft = (e) => {
        this.scrollTo(-1)
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    onScroll = () => {
        if (this.isIPad) {
            return true
        }

        const reachRightEnd = this.hideRightArrow()
        const reachLeftEnd = this.hideLeftArrow()

        if (this.state.reachLeftEnd != reachLeftEnd ||
            this.state.reachRightEnd != reachRightEnd) {
            this.setState({ reachLeftEnd, reachRightEnd })
        }

        return true
    }

    initReachEnd = () => {
        const { scrollWidth, offsetWidth } = this.listContainer

        if (scrollWidth == offsetWidth || this.isIPad) {
            this.setState({ reachLeftEnd: true, reachRightEnd: true })
        } else {
            this.setState({ reachLeftEnd: true, reachRightEnd: false })
        }
    }

    componentDidUpdate(preProps) {
        if (!preProps.infinity && this.listRef.current && preProps.children.length != this.props.children.length) {
            this.initReachEnd()
        }
    }

    componentDidMount() {
        this.listContainer = this.props.infinity ? this.listRef.current.firstElementChild : this.listRef.current
        this.initReachEnd()
    }


    render() {
        const { classes, children, infinity, withoutArrow } = this.props;
        const { reachLeftEnd, reachRightEnd } = this.state;


        return (
            <div data-intrcm-id="discoveryList_3neqy40u" className={classes.listContainer} >
                <div data-intrcm-id="discoveryList_nwty6als" className={classnames(classes.side, classes.rightSide, (reachRightEnd ? '' : classes.visible))} onClick={this.scrollToRight}>
                    {withoutArrow? null:<div data-intrcm-id="discoveryList_u2gr5ep1" className={classes.arrowContainer} onClick={this.scrollToRight}>
                        <Icon >keyboard_arrow_right</Icon>
                    </div>}
                </div>

                <div data-intrcm-id="discoveryList_vaw852a2" className={classes.cardsContainer} ref={this.listRef} onScroll={this.onScroll}>
                    {
                        infinity ?
                            React.Children.map(children, child =>
                                React.cloneElement(child, {
                                    width: this.listRef.current ? this.listRef.current.offsetWidth : 1000,
                                    height: this.listRef.current ? this.listRef.current.offsetHeight : 251,
                                })
                            ) : children

                    }
                </div>
                <div data-intrcm-id="discoveryList_zp5aj0y9" className={classnames(classes.side, classes.leftSide, (reachLeftEnd ? "" : classes.visible))} onClick={this.scrollToLeft}>
                {withoutArrow? null:<div data-intrcm-id="discoveryList_wmo37f1y" className={classes.arrowContainer} onClick={this.scrollToLeft}>
                        <Icon >keyboard_arrow_left</Icon>
                    </div>}
                </div>
            </div>
        )
    }
}





export default compose(withStyles(styles))(DiscoveryList);