// const auth = new AuthService('GvlPc9GZiU3g4DeZsmVXaNDAf2CkqLC5', 'tuqqi.auth0.com');

import { initServices } from "./utils/loadOrganizationData";

export default (auth) => ({
    wrapper: {
        drawerOpen: false,
        leftWidgetDrawer: true,
    },
    oldUiInput: {
        document: {
            // Input data 
            inputOpen: false,
            hasAttachments: false,
            checklists: [],
            // preview: emptyPreview,
            canSubmit: false,
            open: false,
            hasPreview: false,
            attachments: [],
            tags: [],
            title: '',
            docText: '',
            jsonData: '',
            parsedJsonData: {},
            aggregations: {},
            links: [],
            isPrivate: false,
            // Preview fields
            previewTitle: '',
            previewDescription: '',
            previewImgUrl: '',
            link: '',
            classification: 0,
            // Input type
            dataType: '',
            mimeType: '',
            formTitle: ''
        },
        collectionInputInfo: {},
        poll: {
            options: []
        },
    },
    viewOptions: {
        viewType: 'card',
        sortField: 'docTimestamp'
    },
    snackBar: {
        snackBarOpen: false,
        snackBarText: '',
    },
    videoChatWindow: {
        isOpen: false,
    },
    upgradeFromFree: {
        isOpen: false,
    },
    notificationSnackBar: {
        notificationSnackBarOpen: false,
        notifications: []
    },
    userData: {
        userDataLoaded: false,
        userDataRefresh: false,
        profileData: null,
        notifications: [],
        subscribedTags: [],
        savedItems: [],
    },
    oldUiData: {
        // input - update feed on add
        dataUpdate: 0
    },
    globalData: {
    },
    search: {
        query: '',
        page: 0,
        size: 10,
        tags: [],
        filter: {
            'tags.keyword': []
        },
        AggType: 'FLAT',
        sortField: 'docTimestamp',
    },
    results: {
        dataTypes: [],
        relatedDocs: [],
		groups: [],
		experts: [],
        channel: {
            type: ''
        }
    },
    view: {
        inputOpen: false,
        hasAttachments: false,
        // preview: emptyPreview,
        checklists: [],
        canSubmit: false,
        open: false,
        hasPreview: false,
        attachments: [],
        tags: [],
        title: '',
        docText: '',
        jsonData: '',
        parsedJsonData: {},
        aggregations: {},
        links: [],
        isPrivate: false,
        // Preview fields
        previewTitle: '',
        previewDescription: '',
        previewImgUrl: '',
        link: '',
        userName: '',
        // Input type
        dataType: '',
        mimeType: '',
        formTitle: ''
    },
    customMappings: {
        currentMapping: {
            organization: {},
            previewDisplayTemplate: { id: 0, fields: [], title: 'Choose Preview' },
            fullDisplayTemplate: { id: 3, fields: [], title: 'Choose Full view' },
            title: '',
            icon: '',
            sourceFilter: "",
            dataTypeFilter: '',
            displayMappings: []
        }
    },
    fullView: {
        open: false,
        item: { title: '' }
    },
    confirmDialog: {
        open: false
    },
    localFilter: {
        filters: {},
        indicator: 0
    },
    authModule: {
        authService: auth
    },
    services: {}//initServices(auth)

})