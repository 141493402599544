import { Avatar, Card, Grid, withStyles } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';
import withErrorCatcher from '../Common/withErrorCatcher.js'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PlaceHolder from './placeHolder';
import { isUserOrgViewer } from '../Common/Utils/userPermissionsUtils.js';
import { getDefaultPostText } from './inputUtils.js';

const styles = theme => ({
    text: {
        fontSize: 'initial',
        display: 'flex',
        color: '#bdbdbd',
        marginLeft: 8,
        alignItems: 'center',
        userSelect: 'none'
    },
    avatar: {
        width: 36,
        height: 36
    },
    card: {
        width: '100%',
        backgroundColor: '#ffffff',
        // marginLeft: 22,
        // marginRight: 12,
        display: 'flex',
        padding: 16,
        maxWidth: 650,
    },

    container: {
        // maxWidth: 800,
        cursor: 'pointer',
        // marginBottom: 24,


        width: 'calc(100% - 32px)',
        margin: '0 auto 24px',
    },
    containerNewUI:{
        ...theme.feed.cardNewUI,
        ...theme.feed.previewNewUI,
        cursor: 'pointer',
        display: 'flex',
        padding: 16,
        boxSizing: 'border-box'

    }
});


class FeedInput extends PureComponent {

    hasPermissionToAdd = (groupUid) => {
        const { groups } = this.props
        if (groups.some(g => g.groupUid == groupUid)) {
            return true
        }

        return false
    }

    openInput = () => {
        const { dispatch, search } = this.props
        const { query, groupUid } = search

        const payload = { postText: getDefaultPostText(query), dataType: 'post' }
        if (groupUid && this.hasPermissionToAdd(groupUid)) {
            payload.permissions = [groupUid]
        }
        dispatch({
            type: 'FULLVIEW_POST',
            openPost: true,
            item: payload
        })
        dispatch({ type: 'INPUT_OPEN', model: payload });

    }
    render() {
        const { classes, onClick, profileData } = this.props

        if (isUserOrgViewer(profileData)) {
            return null
        }

        return (
            <div data-intrcm-id="FeedInput_s536gn7f" className={classes.containerNewUI}  onClick={onClick ? onClick(this.openInput) : this.openInput}>
                <Avatar src={profileData.profileImageUrl} className={classes.avatar} />
                <PlaceHolder />
            </div>
        )       
    }
}

const mapStateToProps = (state) => ({
    profileData: state.userData.profileData,
    search: state.search,
    groups: state.userData.groups
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(FeedInput);

