import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showConfirm, showSnackbar } from '../../../actions/appActions';
import { openInput } from '../../../actions/inputActions.js';
import { shouldAutoOpen } from '../../views/groups/groupUtils.js';
import UserAvatar from '../Common/UserAvatar';
import { getDataType } from '../Common/Utils/defaultDataTypes.js';
import { isDataTypeEnableInPermission } from '../Common/Utils/inputUtils.js';
import ContentInputFullView from "./contentInputFullView";
import FileInput from "./fileInput";
import MenuPermission from "./menuPermission";
import OtherInput, { ItemOpenerGroup } from "./otherInput";
import { withMixpanel } from '../Common/withMixpanel';
import {definedSpecificGroups, getGroupInfoFromCreation} from '@tuqqi/common'
import { updateSpecificItem } from '@tuqqi/web';
import { DATA_UPDATE } from '../../../actionTypes/actionTypes';


const styles = theme => ({
    avatar: {
        width: 40,
        height: 40,
    },
    font: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontStretch: 'normal',
        fontWeight: 'normal',
    },
    button: {
        marginRight: 16,
        textTransform: 'capitalize'
    },
    titleRoot: {
        padding: '22px 0px 0px 24px',
    },
    icons: {
        borderBottom: '1px solid #f5f5f5',
        padding: '0px',
    },
    paperFullWidth: {
        maxWidth: 620
    }
});

export const mimeTypesMapping = {
    code: "javascript"
}

class InputFullViewModal extends PureComponent {

    state = {
        isQuestionSelected: false,
        isSaving: false
    }

    openSingleInput = () => {
        const { dispatch, inputDocument, userData, searchQuery, inputMappings, collections, fullView } = this.props
        if (fullView.open && fullView.edit) {
            return
        }
        const currCollection = collections.find(c => c.collectionGroup.groupUid === inputDocument.permissions[0])
        const autoOpen = shouldAutoOpen(currCollection);

        if (autoOpen) {
            const dataType = currCollection.settings.allowedInputs[0];
            openInput(dataType, userData, searchQuery, inputDocument, inputMappings).forEach(dispatch)
        }
    }


    componentDidUpdate(prevProps) {
        const { inputDocument: { permissions } } = this.props;
        const prevPermmisions = prevProps.inputDocument.permissions;

        if (permissions && permissions.length) {
            if (prevPermmisions && prevPermmisions.length) {
                if (permissions[0] !== prevPermmisions[0]) {
                    this.openSingleInput()
                }
            }
            else {
                this.openSingleInput()
            }
        }
    }

    isInputChanged = () => {
        const { link, previewImgUrl, postText, attachments } = this.props.inputDocument

        let res = false
        if (link || previewImgUrl || postText || attachments.length) {
            res = true
        }

        return res
    }

    isSaveButtonDisabled = () => {
        // if (this.state.isSaving) {
        //     return true
        // }

        return !this.isInputChanged()
    }

    closeInput = (askConfirm) => {
        const { dispatch, t } = this.props


        const action = { type: 'FULLVIEW_CLOSE' }

        if (askConfirm && this.isInputChanged()) {
            dispatch(showConfirm(t("Changes you have made will be lost Are you sure you want to close?"), action))
        } else {
            dispatch(action)
        }

    }

    copyPostTextToTitle() {
        const { dataType, postText, title, previewTitle } = this.props.inputDocument;
        let newTitle = this.props.inputDocument.title

        // if is post or question etc. insert the postText into the title
        var allowedDataTypes = ["post", "question", "media", "poll"]

        if (allowedDataTypes.includes(dataType) && postText && !title) {

            newTitle = postText.substring(0, Math.min(postText.length, 100));
        }

        // if posttext is empty for media, set title
        if (postText === '') {
            if (dataType === 'media') {
                newTitle = previewTitle
            }

        }



        return newTitle
    }

    
    save = () => {
        const { inputService, dispatch, inputDocument, t, oldUiInput, mixpanelTrack,fullView,updateAfterCreating } = this.props;
  
        this.setState({ isSaving: true })
        dispatch(showSnackbar(t('Saving')));

        const newTitle = this.copyPostTextToTitle()

        const mockCreation = {
            permissions: {
                groupUid: inputDocument?.permissions?.[0] ?? null
            },
            kanbanListId: oldUiInput?.collectionInputInfo?.kanbanListId ?? '',
            ganttListId: oldUiInput?.collectionInputInfo?.ganttListId ?? ''
        }
        const groupInfo = getGroupInfoFromCreation(mockCreation)

        dispatch({ type: 'INPUT_CLOSE' })
        dispatch({ type: 'FULLVIEW_CLOSE' })

        inputService.postItem({ ...oldUiInput, document: { ...inputDocument, title: newTitle } }).then((res) => {
            !!groupInfo.groupUid && dispatch({
                type: 'MANUAL_ADD_GROUP_ITEM_AFTER_CREATION',
                additionalData: {
                    laneId: groupInfo.laneId,
                    groupUid: groupInfo.groupUid,
                },
                result: res.document
            })

            dispatch(updateSpecificItem(res.key ?? null,'add'));
            dispatch({ type: DATA_UPDATE })

            dispatch(showSnackbar(t('Saved!')));
            mixpanelTrack('Created item', { dataType: inputDocument.dataType })
        }).catch(x => {
            dispatch(showSnackbar(t('An error occurred while saving')));
        }).finally(() => {
            this.setState({ isSaving: false })
        })

    }


    handleQuestion = () => {
        this.props.dispatch({
            type: 'INPUT_CHANGE', model: {
                dataType: 'question'
            }
        })
        this.setState({ isQuestionSelected: true })
    }

    handlePoll = () => {
        this.props.dispatch({
            type: 'INPUT_CHANGE', model: {
                dataType: 'poll'
            }
        })
    }

    initState = () => {
        this.setState({ isQuestionSelected: false })
    }

    isPublicView = () => {
        return this.props.pathname === '/' || this.props.pathname === '/feed'
    }

    isGroupView = () => {
        return this.props.pathname.includes('group')
    }

    isSpecificPublicExists = (dataType) => {
        const { inputMappings, groupUid } = this.props
        const mapping = inputMappings.find(m => m.customDataMapping.dataTypeFilter === dataType)
        if(!mapping) return true
        if(!mapping.specificGroupsUids || typeof mapping.specificGroupsUids !== 'string') return true
        const keys = mapping.specificGroupsUids.split(";")
        if(!keys.length) return true

        if(this.isPublicView()) return keys.includes(definedSpecificGroups.public)
        if(this.isGroupView()) return keys.includes(groupUid)
        
        return true
    }


    _isDataTypeEnableInPermission = (dataType) => {
        const { inputDocument, inputMappings, defaultInputTemplates, collections } = this.props
        const { permissions, isPrivate } = inputDocument

        if (isPrivate) {
            return true
        }
        const permission = permissions && permissions[0] || 'public'
        let allowUsage = isDataTypeEnableInPermission(inputMappings, defaultInputTemplates, collections, dataType, permission)
        if(!this.isSpecificPublicExists(dataType)) {
            allowUsage = false
        }
        return allowUsage
    }

    defaultDataTypesInputs = [
        { dataType: 'question', onClick: this.handleQuestion },
        { dataType: 'htmlwysiwyg' },
        { dataType: 'code' },
        { dataType: 'poll', onClick: this.handlePoll },
        { dataType: 'contact' },
        { dataType: 'company' }
    ]

    onCloseOldSelection = (prevProps) => {
        if(!!prevProps?.fullView?.openPost && !this.props.fullView?.openPost) {
            ItemOpenerGroup.groupUid = ''
        }
    }

    componentDidUpdate(prevProps) {
        this.onCloseOldSelection(prevProps)
    }

    render() {
        const { classes, fullView, profileData, t, inputMappings, dispatch } = this.props
        const { firstname, lastname } = profileData;
        const { isQuestionSelected } = this.state;

        if(!fullView.openPost){
            return null;
        }

        return (
            <Dialog
                onEnter={this.initState}
                classes={{ paperFullWidth: classes.paperFullWidth }}
                fullWidth={true}
                open={fullView.openPost}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onClose={this.closeInput}
            >
                <DialogTitle style={{ padding: '16px 16px 12px 16px' }} >
                    <Grid container justify="flex-start" alignItems='center'>
                        <UserAvatar 
                            userUUID={profileData.auth0_id}
                            withShadow={true} />
                        <Typography inline component='span' variant="body2" style={{ marginLeft: 11 }}>{firstname} {lastname}</Typography>
                        <MenuPermission />
                    </Grid>
                </DialogTitle>

                <ContentInputFullView
                    isQuestionSelected={isQuestionSelected}
                    isDataTypeEnableInPermission={this._isDataTypeEnableInPermission} />

                <DialogContent className={classes.icons} >
                    {this._isDataTypeEnableInPermission('file') && <FileInput />}
                    {
                        this.defaultDataTypesInputs
                            .filter(input => this._isDataTypeEnableInPermission(input.dataType))
                            .map(input => {
                                const systemDataType = getDataType(input.dataType)
                                return (
                                    <OtherInput
                                        key={input.dataType}
                                        dataType={input.dataType}
                                        title={t(systemDataType.title)}
                                        icon={systemDataType.icon}
                                        onClick={input.onClick}
                                    />)
                            })
                    }
                    {
                        inputMappings
                            .filter(inputMapping => this._isDataTypeEnableInPermission(inputMapping.customDataMapping.dataTypeFilter))
                            .map((inputMapping, idx) => (
                                <OtherInput
                                    key={inputMapping.customDataMapping.dataTypeFilter}
                                    icon={inputMapping.icon}
                                    title={inputMapping.title}
                                    dataType={inputMapping.customDataMapping.dataTypeFilter}
                                    isCustomInput />))
                    }

                </DialogContent>

                <DialogActions style={{ margin: '8px 0px' }}>

                    <Button color="primary" onClick={this.closeInput} className={classes.button}>
                        {t('Cancel')}
                    </Button>
                    <Button id='publish-new-post' disabled={this.isSaveButtonDisabled()} color='primary' variant="raised" onClick={this.save} className={classes.button}>
                        {t('Publish')}
                    </Button>
                </DialogActions>


            </Dialog>
        )
    }
}

const mapStateToProps = (state) => ({
    fullView: state.fullView,
    profileData: state.userData.profileData,
    inputDocument: state.oldUiInput.document,
    oldUiInput: state.oldUiInput,
    inputMappings: state.globalData.inputMappings,
    searchQuery: state.search,
    inputService: state.services.inputService,
    collections: state.userData.collections,
    userData: state.userData,
    defaultInputTemplates: state.globalData.defaultInputTemplates,
    pathname: state.wrapper.pathname,
    groupUid: state.results?.channel?.collectionGroup?.groupUid ?? '',
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default compose(withStyles(styles),
    withTranslation('postInput'),
    withMixpanel,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(InputFullViewModal);

