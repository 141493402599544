import { Grid, withStyles } from '@material-ui/core';
import classnames from "classnames";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFileLinkFromCloundfront } from '../../../../utils/filesUtils.js';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js';
import FileIcon from '../../../components/FeedViewComponents/ContentPreview/DataTypeTemplates/FileIcon.js';


const styles = theme => ({
    container: {
        width: 368,
        padding: '9px 16px 9px 12px',
        paddingRight: 16,
        cursor: 'pointer',
        borderRadius: 10,
        // border: 'solid 1px #b6b9ca',
        backgroundColor: '#f3f6fb'
    },
    img: {
        width: 24,
        // height: 24,
        marginRight: 16,
        alignSelf: 'center'
    },
    text: {
        alignSelf: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.25px',
        color: '#21212d',
        wordBreak: 'break-all',
        minHeight: 20,
        margin: '2px 0px'

    }
});

class FileMessage extends Component {
    onClick = () => {
        const { cloudfront, attachment } = this.props
        const { fileUid } = attachment

        const fullUrl = getFileLinkFromCloundfront(cloudfront, fileUid)

        window.open(fullUrl)
    }

    render() {
        const { classes, attachment, fileContainerClassName } = this.props

        const { fileName, fileSize, fileType } = attachment

        return (
            <Grid
                onClick={this.onClick}
                container
                className={classnames(fileContainerClassName, classes.container)}
                direction='row'
                wrap='nowrap'
            >
                <FileIcon className={classes.img} mimeType={fileType} />
                <Grid item className={classes.text}> {fileName} </Grid>
            </Grid>)
    }
}

const mapStateToProps = (state) => ({
    cloudfront: state.globalData.cloudfront,
})

export default compose(connect(mapStateToProps), withStyles(styles), withErrorCatcher())(FileMessage);