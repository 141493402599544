import React from 'react';
import withErrorCatcher from '../../../components/Common/withErrorCatcher.js'
import { compose } from 'recompose'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Grid, TextField, withStyles } from '@material-ui/core';
import { showSnackbar } from '../../../../actions/appActions'
// import CretificationFields from "./CretificationFields";
import { MAPPING_EDIT_CERTIFICATION } from '../../../../actionTypes/actionTypes.js';
import AdminEditContainer from '../AdminEditContainer.js';
import SelectIcon from '../../../components/Common/Icons/SelectIcon.js';
import { OldPagesWrap } from '../newway/OldPagesWrap.js';
const styles = theme => ({
  container: {
		minWidth: 605,
	},
	content: {
		marginTop: 12,
		marginBottom: 12,
    },
  paper: {
    padding: 30
  },
  divid: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  buttonIc: {
    marginTop: '8px',
    fontSize: '14px'
  }
})

export class EditIntegration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {

    }
  }

  componentWillMount() {
    const { certification, dispatch } = this.props

    if (!certification) {
      dispatch(push('/admin/certifications'))
    }
  }

  save = () => {

    const { adminService, dispatch, certification } = this.props;
    dispatch(showSnackbar('Saving...'));
    dispatch(showSnackbar('Error occured while saving the integration!'));
    dispatch(push('/admin/integrations'))
    // adminService.editCertification(certification).then(res => {
    //   if (res.ok) {
    //     dispatch(showSnackbar('Save'));
    //     dispatch(push('/admin/certifications'))
    //   } else {

    //   }
    // })
  }




  onChangeComplete = (color) => {
    this.setState({ shwoColor: false })
    this.props.changeColor(color.hex)
  }

  render() {
    const { classes, certification } = this.props;
    if (!certification) {
      return null
    }
    const { icon, title, uid, description, color } = certification

    return <OldPagesWrap className={classes.container} classNameContent={classes.content} >
    <AdminEditContainer isNew save={this.save} title='New certification' subtitle='Create New certification' description='' >

      <Grid container direction='column'>
        <TextField
          className={classes.item}
          fullWidth
          value={title}
          label="Title"
        />

        <TextField
          className={classes.item}
          fullWidth
          value={uid}
          label="uid"
        />




      </Grid>
    </AdminEditContainer>
    </OldPagesWrap>
  }

}

const mapStateToProps = (state) => ({
  adminService: state.services.adminService,
  certification: state.customMappings.currentCertification
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default compose(withStyles(styles),
  withErrorCatcher(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(EditIntegration);