import { useTableSetGlobalData, useGetMappingByDatatype, managerCardAdder } from '@tuqqi/web'
import React, { useEffect } from 'react'

export const MockForSetTableGlobalDataSch = () => {
    const {getMapping} = useGetMappingByDatatype()
    
    useEffect(() => {
        managerCardAdder.setGetMapping(getMapping)
    }, [getMapping])

    useTableSetGlobalData()
    return null
}