import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Collapse,
  withStyles
} from '@material-ui/core';

import { pxToRem } from '../../../../utils/pxToRem';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Scrollbar from '../../../components/Common/Scrollbar';
import { withTranslation } from 'react-i18next';
import { GroupMembersList, managerTableKeepGlobalData, managerRowMembers } from '@tuqqi/web';


const HEIGHT = 55;
const styles = theme => ({
    collapsedButton: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        height: 40,
        borderRadius: 0,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
        justifyContent: 'space-between',
        color: theme.palette.text.disabled,
        flexDirection: 'column', paddingTop: 12
    },
    list: {
        transition: theme.transitions.create('max-height')
    },
    options: {
      width: 320,
      outline: 'none'
  },
    participant: {
        height: HEIGHT, padding: '8px 20px'
    },
    participantAvatar: {
        position: 'relative', width: 40, height: 40
    },
    collapsedContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    collapseIcon: {
        flexDirection: 'column', right: 10, position: 'absolute', color: 'rgba(0, 0, 0, 0.38)'
    },
    hint: {
      marginRight: 16,
      marginLeft: 16,
      height: 40,
      maxHeight: 40,
      overflow: 'hidden',
      color: '#4A4A4F',
      'font-feature-settings': "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.148
    }
  });

  const getMembers = (colUid) => {
    const group = managerTableKeepGlobalData.getUserCollections().concat(managerTableKeepGlobalData.getOpenCollections()).find(x => x.collectionUid === colUid)
    if(!group) return []
    return managerRowMembers.collectGroupMembersAndTeam(group, {withTeams: true, ignoreMe: true, })
  }

function ChatRoomGroupMembers({
  colUid,
  isCollapse,
  onCollapse,
  classes,
  openSidebar,
  t,
}) {
    const withTeams = true
    const [members, setMembers] = useState(getMembers(colUid))

    useEffect(() => {
        setMembers(getMembers(colUid))
    }, [colUid])

    const popoverMaxHeight = 48 * 5
    const listStyle = !!popoverMaxHeight ? {
      maxHeight: popoverMaxHeight,
      marginTop: -8,
      marginBottom: 8,
      overflow: 'hidden'
  } : {}


    const onClickMember = useCallback((uid) => {
    }, [])

const onClickTeam = useCallback((team) => {
    if(!team.isTeam) return
}, [withTeams, members])


  const Content = <div data-intrcm-id="SelectMembersPopover_rre90bew" className={classes.options} style={listStyle}>
        <GroupMembersList fromChat disabled onClickTeam={onClickTeam} onClickUser={onClickMember} sectionWidth={320} isEmptySearchValue={true} data={members} />
    </div>

  return (
    <React.Fragment>
      <div data-intrcm-id="ChatRoomGroupParticipantNew_cavlrs8y" className={classes.collapsedContainer}>
        <Button
            className={classes.collapsedButton}
            fullWidth
            disableRipple
            color="inherit"
            onClick={onCollapse}>{managerTableKeepGlobalData.tTable("Members")}
        </Button>
        {openSidebar &&
            <Icon
                className={classes.collapseIcon}
                icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
                width={16}
                height={16}
            />
        }
      </div>
      
      <div data-intrcm-id="ChatRoomGroupParticipantNew_7wwwn6e4" className={classes.list} style={{ maxHeight: isCollapse ? popoverMaxHeight : 0}}>
        <Scrollbar maxHeight={popoverMaxHeight}>
          <Collapse in timeout="auto">
            {Content}
          </Collapse>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    userUid: state?.data?.userData?.userDetails?.data?.auth0_id ?? ''
})

const mapDispatchToProps = dispatch => ({
    dispatch
})
export default compose(
    withStyles(styles, { withTheme: true }), 
    withTranslation('chat'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChatRoomGroupMembers);