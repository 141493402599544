import React, { PureComponent, Fragment } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid, Icon } from '@material-ui/core';
import { compose } from 'recompose'

const styles = theme => ({
    icon:{
        fontSize:18,
        "color": "black",
        "marginRight": "4px"
      },
    dataType: {
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.17",
        "letterSpacing": "0.1px",
        "color": "#000000",
        textTransform: 'capitalize'
      },
    title: {
        margin: '7px 0px 1px 0px',
        "fontFamily": "Roboto",
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d",
        "marginBottom": "4px",
        "wordBreak": "break-all",
        "whiteSpace": "nowrap",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
    },
});

class Text extends PureComponent {

    getIcon = () => {
        const { dataType, mappings } = this.props;
        const inputMapping = mappings ?  mappings.find(mapping => mapping.customDataMapping.dataTypeFilter == dataType) : {}
        const icon = inputMapping ? inputMapping.icon : 'edit'
        const typeNape = inputMapping ? inputMapping.title : dataType

        return {
            icon, typeNape
        }
    }

    render() {
        const { classes, dataType, mappings, docText, title, maxLength } = this.props;

        const icon = this.getIcon()

        return <Fragment>
            <Grid container direction='row' alignContent='center' alignItems='center'  >
            <Icon className={classes.icon}>{icon.icon}</Icon>
                <span className={classes.dataType}>{icon.typeNape}</span>
            </Grid>
            <span className={classes.title} dir="auto" >{title}</span>
        </Fragment>

    }
}

export default compose(withStyles(styles))(Text);
