import React, { PureComponent } from 'react';
import withErrorCatcher from '../../../../Common/withErrorCatcher.js'
import { withStyles, Grid } from '@material-ui/core';
import classnames from "classnames";


const styles = theme => ({

    title: {
        marginBottom: 4,

        "fontFamily": "Roboto",
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "normal",
        "letterSpacing": "normal",
        "color": "#21212d"
    },
    previewDescription: {
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontStretch": "normal",
        "lineHeight": "1.29",
        "letterSpacing": "normal",
        "color": "#090909"
    },
    textContainer: {
        padding: 12,
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },
    oneRow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
});

class TitleText extends PureComponent {

    render() {
        const { classes, title, text } = this.props;

        return (
            <Grid item className={classes.textContainer} >
                <div data-intrcm-id="TitleText_pdlc43vc" className={classnames(classes.title, classes.oneRow)} dir="auto">{title}</div>
                <div data-intrcm-id="TitleText_jkbipa3g" className={classnames(classes.previewDescription, classes.oneRow)} dir="auto">
                    {text}
                </div>
            </Grid>)

    }
}

export default withStyles(styles)(TitleText);
