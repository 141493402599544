import * as React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { ControlOption } from './ControlOption';
import { selectDrawerNavigationOption } from '../../../../../../../../actions/drawerNavigationActions';
import { myPageContext } from '../../../../../../../../utils/drawerNavigation/myDrawerNavigationHelpers';
import { push } from 'react-router-redux';
import { GoToFullActionCenterIcon } from './GoToFullActionCenterIcon';
import { Tooltip,Divider } from '@material-ui/core';
import { useNewActionCenter } from '../../../../../../../../utils/useNewTable';
import { managerACReportView } from '@tuqqi/web';

const _styles = {
    container: {
        position: 'relative',
        margin: 8,
        flexWrap: 'nowrap',
        marginTop:8,
        padding: 2,
        flexDirection: 'row',
        display: 'flex',
        boxShadow: `
        0px 1px 1px 0px rgba(107, 111, 122, 0.06) inset,
        0px 0px 1px 0.5px rgba(107, 111, 122, 0.06) inset`,
        backgroundColor:'rgba(0,0,0,0.03)',
        borderRadius:8,
        height:32,
        marginBottom: 12,
        

    },
    title: {
        width: 'fit-content',
        padding: 16,
        paddingBottom: 0,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: 8,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.246063,
        color: '#0C0C0D'

    },
    row: {

    },
    hoverTitle: {
        cursor: 'pointer',
        textDecoration: 'underline',
        textDecorationColor: '#21212d'
    },
    fullIcon: {
        cursor: 'pointer',
        position: 'absolute',
        top: 30,
        right: 26
    },
    divider: {
        marginTop: 8,
        marginBottom: 8,
        backgroundColor: '#E9E9EC',
    },
    sperator1: {
        height:12,
        marginTop:10,
        marginBottom:12,
        width:1,
        backgroundColor:"rgba(215, 215, 219, 1)",
        position:'absolute',
        right:188
    },
    sperator2: {
        height:12,
        marginTop:10,
        marginBottom:12,
        width:1,
        backgroundColor:"rgba(215, 215, 219, 1)",
        position:'absolute',
        right:95
    },
};

const useStyles = makeStyles(_styles)

function ActionsCenterWidgetControl(props) {
    const {actionsCenterView, setActionsCenterView, countAgenda, countMustRead, countToDo, dispatch, orgUid} = props
    const [hover, setHover] = React.useState(false)

    const allowActionCenter = useNewActionCenter(orgUid)
    
    const styles = useStyles()
    const [t] = useTranslation("actionCenter")
    const options = React.useMemo(() => {
        return [
            {view: 'todo', count: countToDo},
            {view: 'mustRead', count: countMustRead},
            {view: 'agenda', count: countAgenda},
        ]
    }, [countAgenda, countMustRead, countToDo])

    const onPressView = React.useCallback((view) => {
        setActionsCenterView(view)
    }, [])

    const onMouseEnter = React.useCallback(() => {
        if(!allowActionCenter) return
        setHover(true)
    }, [allowActionCenter])

    const onMouseLeave = React.useCallback(() => {
        if(!allowActionCenter) return
        setHover(false)
    }, [allowActionCenter])

    const onGoActionCenter = React.useCallback(() => {
        if(!allowActionCenter) return
        setHover(false)
        let vv = actionsCenterView
        if(vv === 'mustRead') vv = 'sign'

        managerACReportView.setupOtherDefaultView(vv)
        dispatch(selectDrawerNavigationOption(myPageContext.actionCenter.id))
        dispatch(push({ pathname: myPageContext.actionCenter.pathname })) 
    }, [allowActionCenter, actionsCenterView])

    const FullIcon = !allowActionCenter || !hover ? null : <div data-intrcm-id="ActionCenterControl_1y1d0rde" onClick={onGoActionCenter} className={styles.fullIcon}>
        <GoToFullActionCenterIcon />
    </div>

    const Title = <div data-intrcm-id="ActionCenterControl_6yddjyzx" onClick={onGoActionCenter} className={`${styles.title} ${hover ? styles.hoverTitle : ''}`}>{t("Action Center")}</div>
    const TitleWrap = !allowActionCenter ? Title : <Tooltip
            interactive
            open={hover}
            title={t("Open your full action center")}
            placement="center-top" >
        {Title}
        </Tooltip>

    return <>
        <div data-intrcm-id="ActionCenterControl_o12z8eh3" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={styles.row}>
            {TitleWrap}
            {FullIcon}
        </div>
        
        <div data-intrcm-id="ActionCenterControl_dzexnepe" className={styles.container}>
            <ControlOption selected={actionsCenterView} view={options[2].view} count={options[2].count} onPressView={onPressView} />
            <div className={styles.sperator1}></div>
            <ControlOption selected={actionsCenterView} view={options[0].view} count={options[0].count} onPressView={onPressView} />
            <div className={styles.sperator2}></div>
            <ControlOption selected={actionsCenterView} view={options[1].view} count={options[1].count} onPressView={onPressView} />
        </div>
        <Divider className={styles.divider} />
    </>
}
const mapStateToProps = (state) => ({
    orgUid: state.userData?.profileData?.org?.orgUid ?? '',
    countAgenda: state.actionsCenter.countAgenda,
    countMustRead: state.actionsCenter.countMustRead,
    countToDo: state.actionsCenter.countToDo,
})

export default compose(
    connect(mapStateToProps),
)(ActionsCenterWidgetControl);