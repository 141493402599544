import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { drawerToggle } from '../../../../actions/appActions';
import UserAvatar from '../../Common/UserAvatar';
import ChatToolbar from './ChatToolbar';
import NotificationToolbar from './NotificationToolbar';
import ToolbarLeftSide from './ToolbarLeftSide';
import Search from '../search';
import UpgradeButton from './UpgradeButton';
import TimeTracker from './TimeTracker';
import LaunchpadToolbar from './LaunchpadToolbar';
import withErrorCatcher from '../../Common/withErrorCatcher';
import SettingsToolbar from './SettingsToolbar';
import CreateNewToolbar from './CreateNewToolbar';
import { root } from 'postcss';


// Apply some reset
const styles = theme => {
    // const background = theme.customColors.primary.b500 === '#000000' ? '#000' : `linear-gradient(45deg, #000, ${theme.customColors.primary.b500})`
    const background = theme.customColors.primary.b500 === '#000000' ? '#000' : `linear-gradient(90deg, #000000 0%, ${theme.customColors.primary.b500} 124.18%)`
    return {
    toolbar: {
        background,
        height: theme.constants.defaultToolbarHeight,
        minHeight: theme.constants.defaultToolbarHeight,
        padding: 0,
        justifyContent: 'space-between',
        boxShadow:"0px 12px 12px -6px rgba(107, 111, 122, 0.06),        0px 6px 6px -3px rgba(107, 111, 122, 0.06),        0px 3px 3px -1.5px rgba(107, 111, 122, 0.06),        0px 1px 1px -0.5px rgba(107, 111, 122, 0.06),        0px 4px 4px -4px rgba(107, 111, 122, 0.06),        0px 0px 0px 1px rgba(255, 255, 255, 0.02) inset,        0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset"
    },
    sideContainer: { flex: '0 0 auto', width: 'auto', minWidth: 250,justifyContent:"flex-start" },
    rightSideContainer :{ width: 'auto', minWidth: 250,marginRight:24,paddingTop:8,paddingBottom:8,direction:"row", justifyContent:"flex-end", alignItems:"center" },
}};

class HeaderToolbar extends PureComponent {

    render() {
        const { classes } = this.props;

        return (
            <Toolbar className={classes.toolbar}>
                <ToolbarLeftSide classes={{ container: classes.sideContainer }} />
                <Search />
                <Grid container  classes={{container: classes.rightSideContainer}} >
                    {/* <TimeTracker /> */}
                    <div style={{width:"1px",height:"16px", backgroundColor:"#B7B8BD12",marginRight:16,marginLeft:16}}/>
                    <Grid item><CreateNewToolbar/></Grid>

                    <Grid  item><LaunchpadToolbar /></Grid>
                    <div style={{width:"0px",height:"16px",marginRight:4,marginLeft:4}}/>

                    <Grid  item><ChatToolbar /></Grid>
                    <div style={{width:"0px",height:"16px",marginRight:4,marginLeft:4}}/>

                    <Grid  item><NotificationToolbar /></Grid>
                    <div style={{width:"1px",height:"16px", backgroundColor:"#B7B8BD12",marginRight:16,marginLeft:16}}/>
                    <Grid item><SettingsToolbar /></Grid>
                </Grid>
            </Toolbar>
        )
    }
}

const mapStateToProps = (state) => ({
    wrapper: state.wrapper,
    ...state.services
})

const mapDispatchToProps = dispatch => ({
    dispatch
})

export default compose(withStyles(styles, { withTheme: true }),
    withErrorCatcher("Main toolbar crashed"),
    withTranslation('feedToolbar'),
    connect(mapStateToProps, mapDispatchToProps))(HeaderToolbar);