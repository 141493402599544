import { FULL_RESET_STORE } from "../actionTypes/actionTypes"
import {ON_GO_TO_CHAT, ON_GO_TO_ITEM_NOTIF} from '@tuqqi/web'

const initialState = {
    isFocused: false,
}

export const chatsModalReducer = (state = initialState, action) => {
    switch(action.type) {
        case FULL_RESET_STORE:
        case ON_GO_TO_ITEM_NOTIF:
        case ON_GO_TO_CHAT:
            return initialState
        case "FOCUS_CHATS_MODAL":
            return { ...state, isFocused: true }
        case "TOGGLE_CHATS_MODAL":
            return { ...state, isFocused: !state.isFocused }
        case "BLUR_CHATS_MODAL":
        case "TOGGLE_USER_MODAL":
        case "TOGGLE_CREATENEW_MODAL":
        case "TOGGLE_NOTIFICATIONS_MODAL":
        case "TOGGLE_LAUNCHPAD":
        case 'SEARCH_INPUT_FOCUSED': 
            return { ...state, isFocused: false }
        default:
            return state
    }
}